import React from 'react';
import ReactTable from 'react-table-v6';
import '../../../../styles/custom.css';
import { makeFirstLetterCapital, CustomNoDataComponent } from '../../../../utility/utility';
import { MagicSpinner, PushSpinner, SwishSpinner, WaveSpinner, ClapSpinner } from 'react-spinners-kit';
import 'react-table-v6/react-table.css';
import { useSelector, useDispatch } from 'react-redux';
import { FaArrowDown, FaArrowUp } from "react-icons/fa";
import * as actions from '../../../../redux/actions/index';
import { userRoles, routes } from '../../../../utility/constants/constants';
import ImageLoader from 'react-imageloader';
import AssociateAccountModal from './associateAccountModal';

const AccountSchedulesTable = (props) => {

    const dispatch = useDispatch();
    const SearchValue = useSelector(state => state.form.searchAccountUsersForm.values);
    const DateGenerate = useSelector(state => state.adminOrSuperAdminReducer.dataGenerated);
    const SearchOrResetAccountUsersClicked = useSelector(state => state.adminOrSuperAdminReducer.SearchOrResetAccountUsersClicked);
    const No_of_pages = useSelector(state => state.adminOrSuperAdminReducer.account_users_page);
    const No_of_rows = useSelector(state => state.adminOrSuperAdminReducer.account_users_row);

    const makeUserAsOwner = (accountId, ownerId, userId) => {
        if(ownerId !== userId) {
            props.makeAccountUserAsOwner(accountId, userId)
        }
    }
    function preloader() {
        return <img style={{ height: '35px', width: '35px', borderRadius: '50px', float: 'left' }} src="/images/gif/giphy.gif" />;
    }

    const data = props.accountUsersList.map(user => {
        if (!user) {

        }
        return {
            image: user.photo_urls && user.photo_urls.small ?
                <ImageLoader
                    imgProps={{ style: { height: '35px', width: '35px', borderRadius: '50px', float: 'left' } }}
                    style={{ height: '35px', width: '35px', borderRadius: '50px', float: 'left' }}
                    src={user.photo_urls.small}
                    wrapper={React.createFactory('div')}
                    preloader={preloader}>
                    Image load failed!
                    </ImageLoader>
                :
                <img src="/customImages/user.png" style={{ height: '35px', width: '35px', borderRadius: '50px', float: 'left' }} />
            // <img style={{ height: '35px', width: '35px', borderRadius: '50px' }} src={user.photo_urls.original ? user.photo_urls.original : "/customImages/user.png"} />
            ,
            contactName: user.first_name + ' ' + user.last_name,
            // lastName: user.user.last_name,
            position: user.position ? user.position.name : '',
            // role: makeFirstLetterCapital(user.user.role),
            // region: user.user.region ? user.user.region.name : '',
            // phonenumber: '810-191-8851',
            email: user.username ? user.username : user.email,
            role: makeFirstLetterCapital(user.role === userRoles.CLIENT ? 'Customer' : user.role),
            emailNotificationAccess: <>
                <span className={user.quote_notifications ? "text_danger" : "text_gray"}>{user.quote_notifications ? <strong>Quotes</strong> : "Quotes"}</span> |
                <span className={user.ticket_notifications ? "text_danger" : "text_gray"}>{user.ticket_notifications ? <strong>Tickets</strong> : "Tickets"}</span> |
                <span className={user.inspection_notifications ? "text_danger" : "text_gray"}>{user.inspection_notifications ? <strong>Inspection</strong> : "Inspection"}</span> |
                <span className={user.qr_notifications ? "text_danger" : "text_gray"}>{user.qr_notifications ? <strong>PegAssure</strong> : "PegAssure"}</span></>,
            pegassure_activity: user.role === userRoles.FRONTLINE ? "Yes" : user.enable_preferences === true && user.pegassure_activity === true ? "Yes" : user.enable_preferences === true && user.pegassure_activity === false ? "No": user.enable_preferences === false ? 'Yes' : 'No',    

            // address: '4909 Murphy Canyon Rd # 200, San Diego, CA 92123',
            actions: user.role === userRoles.SUPER_ADMIN ? null
                :
                <>
                    {/* <span className="mr_20 account_edit_icn" onClick={() => props.editUserClicked(user)}><i class="fa fa-pencil" aria-hidden="true"></i>
                    </span> */}
                   {user.active_status == 'active' ? user.role !== userRoles.CLIENT ? <span onClick={() => makeUserAsOwner(props.accountId, props.account.owner_user_id, user.id)} className={props.account && props.account.owner_user_id && props.account.owner_user_id === user.id ? "mr_20 account_edit_icn account_crown_icn_active ": "mr_20 account_edit_icn account_crown_icn"}><i class={user.role !== 'frontline' ? "fas fa-crown" : "fas fa-crown d-none"} aria-hidden="true"></i></span> : '' : ''}
                   {props.account && props.account.owner_user_id && props.account.owner_user_id === user.id ? <></> 
                   : (<span className="mr_20 account_edit_icn" onClick={() => props.handleClickDeleteModel(user)}><i class="fa fa-trash" style={{fontSize: '22px'}} aria-hidden="true"  data-test="user_trash_icon"></i>
                    </span>)}
                </>
        }
    })


    const columns = [
        {
            Header: '',
            accessor: 'image', // String-based value accessors!,
            width: 55
        }, {
            Header: <><span>Contact Name</span><FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
            accessor: 'contactName', // String-based value accessors!,
            show: props.accountUsersList.length !== 0,
            width: 150
        },
        {
            Header: <><span>Email/Username</span><FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
            accessor: 'email',
            show: props.accountUsersList.length !== 0,
            width: 300
        },
        {
            // id: 'friendName', // Required because our accessor is not a string
            Header: <><span>Role</span><FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
            accessor: 'role',
            show: props.accountUsersList.length !== 0, // Custom value accessors!,
            width: 150
        },
        {
            Header: <><span>Position</span><FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
            accessor: 'position',
            show: props.accountUsersList.length !== 0,
        },
        {
            Header: <><span>Email Notification Access</span><FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
            accessor: 'emailNotificationAccess',
            sortable: false,
            show: props.accountUsersList.length !== 0,
            width: 350
        },
        {
            Header: <><span>SMS Notification</span><FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
            accessor: 'pegassure_activity',
            show: props.accountUsersList.length !== 0,
            width: 135
        },

        {
            Header: ' ',
            accessor: 'actions',
            width: 150,
            show: props.user.role === userRoles.ADMIN ? false : true,
        }
    ]

    const fetchData = (state, instance) => {

        if (SearchOrResetAccountUsersClicked) {
            dispatch(actions.resetSearchOrResetValue())
        } else {

            if (!DateGenerate) {
                const account_users_no_of_rows = state.pageSize;
                const page = state.page + 1;
                const sorted = state.sorted;
                const filtered = state.filtered;
                let SearchValues = {
                    name: SearchValue && SearchValue.name ? SearchValue.name : '',
                    email: SearchValue && SearchValue.email ? SearchValue.email : '',
                    active_status: SearchValue && SearchValue.active_status ? SearchValue.active_status : 'active',
                }

                props.getAccountUsers(
                    props.accountId,
                    SearchValues,
                    account_users_no_of_rows, page,
                    sorted,
                    filtered);
            }
        }
        // }
    }

    return (
        SearchOrResetAccountUsersClicked && props.isAccountUsers ?
            <div className="loader_btn_block">
                <ClapSpinner backColor="#89073c" size={40} frontColor="#89073c8a" />
            </div>
            :
            <ReactTable
                data={data}
                loading={props.isAccountUsers}
                defaultPageSize={No_of_rows}
                minRows={0}
                manual
                onFetchData={fetchData}
                pages={No_of_pages}
                // onPageSizeChange={props.onUserPageSizeChange}
                showPaginationTop={true}
                showPagination={props.accountUsersList.length !== 0}
                columns={columns}
                NoDataComponent={() => CustomNoDataComponent(props.isAccountUsers, 'No Users Found')}
                getTheadThProps={(state, rowInfo, column, instance) => {
                    return {
                        style: {
                            borderTop: 'none',
                            borderBottom: 'none',
                            borderRight: 'none',
                            textAlign: 'center',
                            color: '#566975',
                            fontSize: '14px',
                            fontWeight: '600',
                            whiteSpace: 'nowrap',
                            float: 'left',
                        }
                    }
                }
                }
                LoadingComponent={() =>
                    props.isAccountUsers ?
                        <div style={{
                            display: "block",
                            position: "absolute",
                            left: 0,
                            height: '100%',
                            right: 0,
                            background: "rgba(255,255,255,0.8)",
                            transition: "all .3s ease",
                            top: 0,
                            bottom: 0,
                            textAlign: "center"
                        }}
                            className="loader_btn_block">
                            <ClapSpinner backColor="#89073c" size={40} frontColor="#89073c8a" />
                        </div> : null}
                getTrProps={(state, rowInfo, column, instance) => {
                    // onclick = () => userDetail(rowInfo ? rowInfo.row.user : rowInfo);
                    console.log(column);
                    return {
                        className: 'react-table-tr-element',
                        style: {
                            border: '1px solid #c7cdd1',
                            transition: '.3s ease-in-out',
                            width: '100%',
                            // borderRadius: '4px',
                            // height: '80px',
                            marginTop: '5px',
                            float: 'left'
                        }
                    }

                }}
                getTdProps={(state, rowInfo, column, instance) => {

                    if (column.id == 'image') {
                        return {
                            style: {
                                marginTop: '0px'
                            }
                        }
                    } else {
                        return {
                            className: 'react-td-element',
                        }
                        //  {
                        //   style: {
                        //     borderTop: 'none',
                        //     verticalAlign: 'middle',
                        //     fontSize: '14px',
                        //     color: '#2b3034',
                        //     marginTop: '10px !important',
                        //     textAlign: 'center',
                        //   }
                        // }
                    }
                }
                }
                // getPaginationProps={() => {
                //     return {
                //         style: {
                //             marginTop: '50px',
                //             border: 'none',
                //             boxShadow: 'none'
                //         }
                //     }
                // }
                // }
                getProps={() => {
                    return {
                        style: {
                            border: 'none'
                        }
                    }
                }}
            />
    )
}

export default AccountSchedulesTable;