import React, { useEffect, useState } from 'react'
import TrainingLogForm from '../../../../../component/FormAuditModule/TeamManagement/TrainingLog/newTrainingLog/trainingLogForm'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { addTrainingLog, editTrainingLog, getSingleTrainingLogDetails } from '../../../../../../redux/actions/FormAuditModAction/TeamManagementAction/teamManagementAction'
import moment from 'moment'
import { convertDateTimezoneFormAudit, convertDateTimezoneFormAuditReduxForm, convertDateToUTCFormAudit, ConvertToFormat, tzUTCDateConverterTool_FormAudit, } from '../../../../../../utility/TimezoneOperations/timezoneUtility'

function TrainingLogContainer() {

  const { location: { pathname } } = useHistory()

  const { trainingLog } = useSelector((state) => state.FormAuditModReducer)
  const { logId } = useParams();
  const dispatch = useDispatch()
  const isEdit = Boolean(pathname.split("/")[2] !== "view" && logId)
  const isView = Boolean(pathname.split("/")[2].includes("view"))


  const structureBody = (items) => {
    if (isEdit) {
      return {
        data: items.map((item) => ({
          ...item, date: tzUTCDateConverterTool_FormAudit(ConvertToFormat(item.date), "to"), id: logId,
          user_id: trainingLog.user_id, created_at: trainingLog?.created_at
        }))
      }
    } else {
      return { data: items.map((item) => ({ ...item, date: tzUTCDateConverterTool_FormAudit(ConvertToFormat(item.date), "to") })) }
    }


  }



  const defaultForm = {
    data: [{
      verification_code: isEdit || isView ? trainingLog?.verification_code ? trainingLog.verification_code : "" : "",
      date: isEdit || isView ? trainingLog?.date ? convertDateTimezoneFormAuditReduxForm(trainingLog.date) : "" : "",
      title: isEdit || isView ? trainingLog?.title ? trainingLog.title : "" : "",
      location: isEdit || isView ? trainingLog?.location ? trainingLog.location : "" : "",
      instructor: isEdit || isView ? trainingLog?.instructor ? trainingLog.instructor : "" : "",
      active_status: isEdit || isView ? trainingLog?.active_status ? trainingLog.active_status : "active" : "active",
    }]
  }






  const onSubmit = (data) => {
    if (isEdit) {
      dispatch(editTrainingLog(structureBody(data.data)))

    } else {

      dispatch(addTrainingLog(structureBody(data.data)))
    }
  }

  useEffect(() => {
    if (isEdit || isView && logId) {
      dispatch(getSingleTrainingLogDetails(logId))
    }
    // dispatch(getCustomerInfos())
    // dispatch(getAllJob())
    // dispatch(getManagersList({supervisor : true}))
    // dispatch(getEmployeeList({employee:true}))
  }, [logId])






  return (
    <TrainingLogForm
      elements={defaultForm}
      formSubmittedCallback={onSubmit}
      isEdit={isEdit}
      isView={isView}
      modalUpdate={true}
    />
  )
}

export default TrainingLogContainer;
