import React, { Component } from 'react';
import TimesheetListScreenLayout from '../../../component/TimeTrackerModuleComponent/TimesheetListScreenComponent/TimesheetListScreenLayout';
import storage from '../../../../utility/storage';
import * as actions from '../../../../redux/actions/index';
import { connect } from 'react-redux';
import { reset } from 'redux-form';
import { animateScroll as scroll } from 'react-scroll'
import { tzDateConverterTool_V5, tzUTCDateConverterTool_V2 } from '../../../../utility/TimezoneOperations/timezoneUtility';
var moment = require('moment');
const cloneDeep = require('clone-deep');

class TimeSheetListScreenContainer extends Component {
    constructor(props) {
        super(props);

    }

    componentDidMount() {
        scroll.scrollToTop();
        
        if (this.props && this.props.match && this.props.match.params && this.props.match.params.flUserId) {
            this.props.getFrontline(this.props.match.params.flUserId);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const user = storage.get('user');
        if (user) {
            if (prevProps.user.selected_mode !== user.selected_mode) {
                this.props.timeSheetListInfoPageClicked(0);
            }
        }
        if(this.props.frontlineUser){
            this.props.frontlineUserActiveNameChange(`${this.props.frontlineUser?.first_name} ${this.props.frontlineUser?.last_name} -`)
        }
    }

    componentWillUnmount() {
        this.props.resetTimeTrackerObjects();
    }

    resetClicked = () => {
        const page = 1
        const limit = this.props.timeSheetList_no_of_rows
        let userId = '';
        const timecard = true;
        this.props.reset('searchTimesheetListForm');
        this.props.timeSheetListInfoPageClicked(0);

        if (this?.props?.user?.role == 'frontline') {
            userId = this?.props?.user?.id;
            this.props.getTimeSheetListInfo({ page, limit, userId, timecard });
        } else {
            userId = this?.props?.match?.params?.flUserId;
            this.props.getTimeSheetListInfo({ page, limit, userId, timecard });
        }
    }

    searchTimecardReviewedListClicked = (value) => {

        const values = cloneDeep(value);

        const page = 1
        const limit = this.props.timeSheetList_no_of_rows
        let userId = '';
        const timecard = true;
        let customDate = {
            from: '',
            to: '',
        }

        if (values.fromDate) {
            customDate.from = tzUTCDateConverterTool_V2(values.fromDate, 'from');
        }
        if (values.toDate) {
            customDate.to = tzUTCDateConverterTool_V2(values.toDate, 'to');
        }

        if (customDate.from === '' || customDate.to === '') {
            return false;
        } else {
            if (this?.props?.user?.role == 'frontline') {
                userId = this?.props?.user?.id;
                this.props.getTimeSheetListInfo({ page, limit, userId, timecard, ...customDate }, values);
            } else {
                userId = this?.props?.match?.params?.flUserId;
                this.props.getTimeSheetListInfo({ page, limit, userId, timecard, ...customDate }, values);
            }
        }

    }

    handlingTimeUnits = (unit) => {

        this.props.reset('searchTimesheetListForm');
        this.props.timeSheetListInfoPageClicked(0)

        const page = 1
        const limit = this.props.timeSheetList_no_of_rows
        let userId = '';
        const timecard = true;

        const timeUnits = {
            isWeek: false,
            isMonth: false,
            currentDate: tzDateConverterTool_V5(new Date())
        }

        if (unit == 'week') {
            timeUnits.isWeek = true
        } else if (unit == 'month') {
            timeUnits.isMonth = true
        }

        if (this?.props?.user?.role == 'frontline') {
            userId = this?.props?.user?.id;
            this.props.getTimeSheetListInfo({ page, limit, userId, timecard, ...timeUnits });
        } else {
            userId = this?.props?.match?.params?.flUserId;
            this.props.getTimeSheetListInfo({ page, limit, userId, timecard, ...timeUnits });
        }

    }

    handleDownloadPayrollCsvClick = (values, timeUnitValue) => {
        const propValue = cloneDeep(values);
        let data = {
            page: propValue.timesheet_default_currentPage,
            limit: propValue.timeSheetList_no_of_rows,
        }

        if (this?.props?.user?.role == 'frontline') {
            data['userId'] = this?.props?.user?.id;
        } else {
            data['userId'] = this?.props?.match?.params?.flUserId;
        }

        if (timeUnitValue == 'week') {
            data['isWeek'] = true;
            data['currentDate'] = tzDateConverterTool_V5(new Date());
        } else if (timeUnitValue == 'month') {
            data['isMonth'] = true;
            data['currentDate'] = tzDateConverterTool_V5(new Date());
        }

        if (propValue?.initialValues?.fromDate !== '' && propValue?.initialValues?.toDate !== '') {
            data['from'] = tzDateConverterTool_V5(propValue?.initialValues?.fromDate);
            data['to'] = tzDateConverterTool_V5(propValue?.initialValues?.toDate);
        }

        this.props.triggerExportPayrollCSV({ ...data });
    }

    render() {
        return (
            <>

                <TimesheetListScreenLayout
                    {...this.props}
                    onSubmit={this.searchTimecardReviewedListClicked}
                    resetClicked={this.resetClicked}
                    handlingTimeUnits={this.handlingTimeUnits}
                    handleDownloadPayrollCsvClick={this.handleDownloadPayrollCsvClick}
                />

            </>
        );
    }
}

const mapStateToProps = (state) => {
    
    return {
        user: state.authReducer.user,
        isLoading: state.TimeTrackerModReducer.isLoading,
        isTimeSheetList: state.TimeTrackerModReducer.isTimeSheetList,
        timeSheetList: state.TimeTrackerModReducer.timeSheetList,
        timeSheetList_no_of_rows: state.TimeTrackerModReducer.timeSheetList_no_of_rows,
        timeSheetList_curPage: state.TimeTrackerModReducer.timeSheetList_curPage,
        timeSheetList_total_pages: state.TimeTrackerModReducer.timeSheetList_total_pages,
        timecard: state.TimeTrackerModReducer.timeSheetList_timecard,
        timesheet_default_currentPage: state.TimeTrackerModReducer.timesheet_default_currentPage,
        frontlineUser: state.adminOrSuperAdminReducer.frontlineUser,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        reset: (searchTimesheetListForm) => dispatch(reset(searchTimesheetListForm)),  // requires form name
        resetTimeTrackerObjects: () => dispatch(actions.resetTimeTrackerObjects()),
        timeSheetListInfoPageClicked: (page) => dispatch(actions.timeSheetListInfoPageClicked(page)),
        getTimeSheetListInfo: (params, values) => dispatch(actions.getTimeSheetListInfo(params, values)),
        triggerExportPayrollCSV: (params) => dispatch(actions.triggerExportPayrollCSV(params)),
        getFrontline: (id) => dispatch(actions.getFrontline(id)),
        frontlineUserActiveNameChange:(name)=> dispatch(actions.updateActiveUserName(name))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TimeSheetListScreenContainer);