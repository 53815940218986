import React, { useEffect, useState } from 'react';
import ReactTable from 'react-table-v6';
import '../../../styles/custom.css';
import { CustomNoDataComponent, convertDateToDifferentTZSansUTC, convertDateToDifferentTZ, isString, trimString, makeFirstLetterCapital, convertDateTimezone, getUser, getWeekStartAndEndDay, convertQuotesDataToCsvType, convertInspectionInventaryData } from '../../../utility/utility';
import * as actions from '../../../redux/actions/index';
import { FaArrowDown, FaArrowUp } from "react-icons/fa";
import ReactTooltip from 'react-tooltip';
import { useSelector, useDispatch } from 'react-redux';
import { MagicSpinner, PushSpinner, SwishSpinner, WaveSpinner, ClapSpinner } from 'react-spinners-kit';
import 'react-table-v6/react-table.css';
import { convertInspectionsDataToCsvType } from '../../../utility/utility';
import { CSVLink } from "react-csv";
import { userRoles, TimeZones, routes } from '../../../utility/constants/constants';
import storage from '../../../utility/storage';
import queryString from "query-string";
import $ from 'jquery'
import Oux from '../../../hoc/Oux/Oux';
import { convertDateTimezoneV1 } from '../../../utility/TimezoneOperations/timezoneUtility';
const cloneDeep = require('clone-deep');
var moment = require('moment');
window.exportQuery = null;

const calculateDuration = (startDate, endDate) => {
    let minutes = moment.duration(moment.tz(endDate, TimeZones.PST)
        .diff(moment.tz(startDate, TimeZones.PST))).minutes();
    let hours = moment.duration(moment.tz(endDate, TimeZones.PST)
        .diff(moment.tz(startDate, TimeZones.PST))).asHours();
    // let days = moment.duration(moment.tz(endDate, TimeZones.PST)
    //     .diff(moment.tz(startDate, TimeZones.PST))).days();
    // hours = hours + (days * 24);
    hours = Math.floor(hours);
    if (isNaN(NaN) && isNaN(hours)) {
        return ''
    } else {
        if (minutes === 0 && hours === 0) {
            return 'Half of a minute'
        } else if (hours === 0 && minutes === 1) {
            return minutes + ' ' + 'Minute';
        } else if (hours === 0 && minutes > 1) {
            return minutes + ' ' + 'Minutes';
        } else if (hours === 1 && minutes === 0) {
            return hours + ' ' + 'Hour ';
        } else if (hours === 1 && minutes === 1) {
            return hours + ' ' + 'Hour ' + minutes + ' ' + 'Minute'
        } else if (hours === 1 && minutes > 1) {
            return hours + ' ' + 'Hour ' + minutes + ' ' + 'Minutes'
        } else if (hours > 1 && minutes === 0) {
            return hours + ' ' + 'Hours'
        }
        else {
            return hours + ' ' + 'Hours ' + minutes + ' ' + 'Minutes'
        }
    }

}



let InspectionTable = (props) => { 

    const dispatch = useDispatch();
    useEffect(()=>{
        if(props.convertedData && props.convertedData.length > 0){
            var downloadLink = document.createElement("a");
            downloadLink.href = document.getElementById("downloadcsv").href//$('#export_data_download').attr('href')
            downloadLink.download = `inspection.csv`;
            downloadLink.click();
        }
    }, [props.convertedData])
    const [inspectionData, setInspectionData] = useState([])
    var SearchValue = cloneDeep(useSelector(state => state.adminOrSuperAdminReducer.searchInspectionValues));
    const DateGenerate = useSelector(state => state.adminOrSuperAdminReducer.dataGenerated);
    const inspectionIndex = useSelector(state => state.adminOrSuperAdminReducer.inspectionIndex);
    const SearchOrResetInspectionClicked = useSelector(state => state.adminOrSuperAdminReducer.searchOrResetInspectionClicked);
    const No_of_pages = useSelector(state => state.adminOrSuperAdminReducer.inspection_page);
    const No_of_rows = useSelector(state => state.adminOrSuperAdminReducer.inspection_no_of_rows);
    const isInspectionLoading = useSelector(state => state.adminOrSuperAdminReducer.isInspectionLoading);
    const inspectionPage = useSelector(state => state.adminOrSuperAdminReducer.inspectionCurPage);
    const inspection_rows = useSelector(state => state.adminOrSuperAdminReducer.inspection_rows);
    const inspection_mode = useSelector(state => state.adminOrSuperAdminReducer.inspection_mode);
    const [inspectionMode, setInspectionMode] = useState()
    let kpiIds = null;
    if(props.location.search) {
        kpiIds = queryString.parse(props.location.search);
    }

    const data = props.inspectionsList.map(inspection => {
        const getFormattedZone = (timezone) => {
            let zone = ''
            if (timezone === 'America/Los_Angeles') {
                 zone = 'PST'
            } else if ( timezone === 'America/Chicago') {
                 zone = 'CST'
            }
            return '(' + zone + ')';
          }
        let createdFormatted = '';
        if (inspection.date_filter) {
            createdFormatted = convertDateTimezoneV1(inspection.created_at, inspection.account_info.timezone);
        } else {
            createdFormatted = convertDateTimezoneV1(inspection.created_at, inspection.account_info.timezone);
        }
        console.log('statusOptions', inspection);
        return { 
            id: inspection.id,
            created_at: createdFormatted,
            accountName: makeFirstLetterCapital(inspection.account_info.account_name),
            accountId: inspection.account_info.account_number,
            score: inspection.score !== null ? inspection.score : 'N/A',
            inspector: inspection.inspected_by ? inspection.inspected_by.first_name + ' ' + inspection.inspected_by.last_name : '',
            location: inspection.address && inspection.address.end_location.formatted_address.substring(0, 50),
            inspectionForm: makeFirstLetterCapital(inspection.inspection_form.name),
            duration: inspection.active_status === 'completed' ? calculateDuration(inspection.start_time, inspection.end_time) : null,
            region: inspection.account_info.branch,
            status: makeFirstLetterCapital(inspection.active_status),
            qqv: inspection.feedback_status ? 'Yes' : 'No',
            // created_at: moment(convertDateToDifferentTZSansUTC(inspection.created_at, inspection.address.timezone)).format('MM-DD-YYYY hh:mm A'),
            // updated_at: moment(convertDateToDifferentTZSansUTC(inspection.updated_at, inspection.address.timezone)).format('MM-DD-YYYY hh:mm A'),
            actions: <>
                {inspection.private ? <span data-tip="Private Inspection" className="mr_20 account_edit_icn" ><i class="fa fa-lock cst_icon mr-2" aria-hidden="true"></i></span> : null}
                <span data-tip="View Inspection" className="mr_20 account_edit_icn" onClick={() => props.previewInspcetionClicked(inspection)}><i className="fas fa-eye cst_icon mr-2" aria-hidden="true"></i></span>
               
               {/* <span data-tip="Approve Inspection" data-toggle="modal" data-target="#pendingInspectionApprove" className="mr_20 account_edit_icn" onClick={() => props.ApproveModalClicked(inspection)}><i className="fas fa-thumbs-up cst_icon mr-2" aria-hidden="true"></i></span> */} 
            
                {inspection.inspection_form && inspection.inspection_form.inventory == true ? 
                    <>
                    <span className="mr_20 account_edit_icn" onClick={async() => {
                    await props.inspectionCSvClikedReport(inspection)
                }}>
                <i data-tip="Download CSV" className="fas fa-download" aria-hidden="true" ></i>                  
                </span>
                <CSVLink id="downloadcsv" filename="inspection.csv" style={{color: "#000000", display:"none"}} className="" data={props.convertedData} ></CSVLink>
                </>
                : null}

                <ReactTooltip place="top" type="dark" effect="solid" />
                {((props.user.role === userRoles.REQUESTOR || props.user.role === userRoles.INSPECTOR || props.user.role === userRoles.CLIENT || props.user.role === userRoles.ADMIN) && (inspection.active_status === 'completed' )) ? null :
                    <>
                      {props.user.role === userRoles.CLIENT || props.fromDashboard || inspection.active_status === 'completed'  ? null :
                    <span className="mr_20 account_edit_icn" onClick={() => props.editInspectionClicked(inspection)}><i class="fa fa-pencil" aria-hidden="true"></i></span>}
                        {props.user.role === userRoles.REQUESTOR || props.user.role === userRoles.INSPECTOR || props.user.role === userRoles.CLIENT || props.user.role === userRoles.ADMIN || props.fromDashboard ? null :
                            <span className="mr_20 account_edit_icn" onClick={() => props.handleClickDeleteModel(inspection)}><i class="fa fa-trash" aria-hidden="true"></i></span>
                        }
                    </>
                }
            </>
        }
    })

    const columns = [
        {
            Header: <><span>ID</span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /></>,
            accessor: 'id', // String-based value accessors!,
            show: props.inspectionsList.length !== 0,
            width: 50,
            filterable: false,
        },
        {
            Header: <><span>Date Created</span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
            accessor: 'created_at',
            show: props.inspectionsList.length !== 0,
        },
        {
            Header: <><span>Job Name</span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
            accessor: 'accountName',
            show: props.inspectionsList.length !== 0,
        },
        // {
        //     Header: <><span>Job #</span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
        //     accessor: 'accountId',
        //     show: props.inspectionsList.length !== 0,
        // },
        {
            Header: <><span>Form</span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /></>,
            accessor: 'inspectionForm',
            // width: 135,
            show: props.inspectionsList.length !== 0,
            Filter: ({ filter, onChange }) => (
                <input
                    onChange={event => onChange(event.target.value)}
                    value={filter ? filter.value : ''}
                    placeholder={'Search Account...'}
                    className={'form-control input-modifier'}
                />
            ), // String-based value accessors!
        },
        {
            Header: <><span>Score</span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /></>,
            accessor: 'score', // String-based value accessors!,
            show: props.inspectionsList.length !== 0,
            width: 100,
            filterable: false,
        },
        {
            Header: <><span>Inspector</span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /></>,
            accessor: 'inspector',
            // width: 135,
            show: props.inspectionsList.length !== 0,
        },
        {
            Header: <><span>Region</span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
            accessor: 'region',
            show: props.inspectionsList.length !== 0,
        },
        {
            Header: <><span>Duration</span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
            accessor: 'duration',
            show: props.inspectionsList.length !== 0 && (props.user.role === 'admin' || props.user.role === 'superadmin'),
        },
        {
            Header: <><span>Status</span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
            accessor: 'status',
            show: props.inspectionsList.length !== 0,
        },
        {
            Header: <><span>QQV</span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
            accessor: 'qqv',
            show: props.inspectionsList.length !== 0 && props.user.selected_mode !== 'internal',
        },
        // {
        //     Header: <><span>ID</span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /></>,
        //     accessor: 'id', // String-based value accessors!,
        //     show: props.inspectionsList.length !== 0,
        //     // width: 40,
        //     filterable: false,
        // },
        //   {
        //     Header: <><span>Location</span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
        //     accessor: 'location',
        //     show: props.inspectionsList.length !== 0,
        // },
        // {
        //     Header: <><span>Updated At</span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
        //     accessor: 'updated_at',
        //     show: props.inspectionsList.length !== 0,
        // },
        {
            Header: '',
            accessor: 'actions',
            width: 135,
            filterable: false,
            // show: false
        }
    ]
    const [tablePage, setTablePage] = useState(0)
    const tablePageNew =  React.useRef()

    if(inspectionPage !== tablePageNew.current) {
        tablePageNew.current = inspectionPage;
    }

    const setTablePageFn = (page) => {
        setTablePage(page)
        props.inspectionPageClicked(page)
        tablePageNew.current = page;
    }


    const fetchData = (state, instance) => {
        if (SearchOrResetInspectionClicked) {
            // dispatch(actions.getDropDownAccounts());
            // dispatch(actions.getAllUsers());
            dispatch(actions.resetSearchOrResetValue())
        } else {
            if (!DateGenerate && !isInspectionLoading) {
                // props.inspectionPageClicked(state.page)
                    state.page = 2;
                    const inspection_no_of_rows = state.pageSize;
                    let page;
                    const user = getUser();
                    if(inspection_mode && inspection_mode !== user.mode) {
                        page = 1;
                    } else {
                        page = tablePageNew.current >= 0 ? tablePageNew.current + 1 : inspectionPage + 1;
                    }
                    if(inspection_rows != inspection_no_of_rows) {
                        page = 1;
                        setTablePageFn(0)
                    }
                    // const page = state.page + 1;
                    console.log('page', tablePage, 'reducer', inspectionPage);
                // setTablePage(state.page + 1);
                const sorted = state.sorted;
                const filtered = state.filtered;
                if(sorted) {
                    window.exportQuery = state.sorted;
                }
                if (SearchValue && (SearchValue.fromDate || SearchValue.toDate)) {

                    SearchValue.fromDate = convertDateToDifferentTZ(SearchValue.fromDate, 'from');
                    SearchValue.toDate = convertDateToDifferentTZ(SearchValue.toDate, 'to');
                }
                // if (SearchValue.name && SearchValue.name !== '') {
                //     //  
                //     dispatch(actions.searchInspections(SearchValue, inspection_no_of_rows, page, sorted[0] ? sorted[0].id : '', sorted[0] ? sorted[0].desc : ''));
                // } else {
                // if (!props.inspectionsList || props.inspectionsList.length === 0) {
                let filterValue = SearchValue

                if (props.history.location.pathname === routes.VIEW_FILTERED_INSPECTIONS) {
                    // if (!props.filteredQuotesList) {

                    props.updateWeekMonthYear(props.history.location.state.isWeek, props.history.location.state.isMonth, props.history.location.state.isYear);
                    // props.updateCurrentDate(props.history.location.state.currentDate);
                    if (props.history.location.state.isWeek) {

                        // let startAndEndDate = getWeekStartAndEndDay(props.history.location.state.currentDate);
                       
                        if(SearchValue !== null &&  SearchValue !== undefined && !SearchValue.value) {
                            SearchValue = props.history.location.state;
                            SearchValue.firstday = moment(SearchValue.currentDate).startOf('week').format('MM/DD/YYYY');
                            SearchValue.lastday = moment(SearchValue.currentDate).endOf('week').format('MM/DD/YYYY');
                            SearchValue.currentDate = moment(SearchValue.currentDate).utc().toISOString();
                            SearchValue.value = filterValue;
                            }
                        } else if (props.history.location.state.isMonth || props.history.location.state.isYear) {
                        if(SearchValue !== null &&  SearchValue !== undefined && !SearchValue.value) {
                        SearchValue = props.history.location.state;
                        SearchValue.currentDate = moment(SearchValue.currentDate).utc().toISOString();
                            SearchValue.value = filterValue;
                            }                    
                        }
                    // }
                } else {
                    if(SearchValue !== null &&  SearchValue !== undefined && !SearchValue.value && SearchValue.value !== '') {
                    SearchValue.value = filterValue;
                    }
                }
                if(SearchValue === null || SearchValue === undefined || SearchValue.value === '') {
                    SearchValue = {}
                    SearchValue.value = '';
                }
                if (props.user.role === userRoles.ADMIN || props.user.role === userRoles.REQUESTOR || props.user.role === userRoles.CLIENT || props.user.role === userRoles.INSPECTOR) {
                    if(kpiIds) {
                        SearchValue['kpiIds'] = kpiIds;
                    }
                    const inspectionId = storage.get('inspectionId')
                    if (inspectionIndex && inspectionId) {
                        inspectionIndex.map((ins, i) => {
                            if(inspectionId === parseInt(ins)) {
                                if(i + 1 > 10) {
                                    const user = getUser();
                                    SearchValue.mode = user.mode
                                    props.getPendingInspectionsList(
                                        SearchValue,
                                        inspection_no_of_rows, parseInt(((i + 1) / 10).toFixed()) + 1,
                                        sorted,
                                        filtered);
                                        setTablePageFn(parseInt(((i + 1) / 10).toFixed()))
                                        storage.remove('inspectionId')
                                } else {
                                    const user = getUser();
                                    SearchValue.mode = user.mode
                                    props.getPendingInspectionsList(
                                        SearchValue,
                                        inspection_no_of_rows, 1,
                                        sorted,
                                        filtered);
                                        storage.remove('inspectionId')
                                    }
                            }
                        })
                        
                    } else {
                        const user = getUser();
                        SearchValue.mode = user.mode
                        props.getPendingInspectionsList(
                            SearchValue,
                            inspection_no_of_rows, page,
                            sorted,
                            filtered);
                    }
                } else {
                    if(kpiIds) {
                        SearchValue['kpiIds'] = kpiIds;
                    }
                    const inspectionId = storage.get('inspectionId')
                    if (inspectionIndex && inspectionId) {
                        inspectionIndex.map((ins, i) => {
                            if(inspectionId === parseInt(ins)) {
                                if(i + 1 > 10) {
                                    const user = getUser();
                                    SearchValue.mode = user.mode
                                    props.getPendingInspectionsList(
                                        SearchValue,
                                        inspection_no_of_rows, parseInt(((i + 1) / 10).toFixed()) + 1,
                                        sorted,
                                        filtered);
                                        setTablePageFn(parseInt(((i + 1) / 10).toFixed()))
                                        storage.remove('inspectionId')
                                } else {
                                    const user = getUser();
                                    SearchValue.mode = user.mode
                                    props.getPendingInspectionsList(
                                        SearchValue,
                                        inspection_no_of_rows, 1,
                                        sorted,
                                        filtered);
                                        storage.remove('inspectionId')
                                    }
                            }
                        })
                        
                    } else {
                        const user = getUser();
                        SearchValue.mode = user.mode
                        props.getPendingInspectionsList(
                            SearchValue,
                            inspection_no_of_rows, page,
                            sorted,
                            filtered);
                    }
                }
                
            }
        }
    }

    return (
        SearchOrResetInspectionClicked && isInspectionLoading ?
            <div className="loader_btn_block">
                <ClapSpinner backColor="#89073c" size={40} frontColor="#89073c8a" />
            </div>
            :
            <div>
            <div className="row">
            {props.data && props.data.length === 0 ? null :
            <div className="col-xl-12 col-lg-4 col-md-4 col-sm-12">
            <button className="csv_btn btn_danger " onClick={() => {
                if(window.exportQuery.length > 0) {
                    SearchValue.sort = window.exportQuery;
                }
                props.onInspectionExportClicked(SearchValue)}} >Export CSV</button>
            </div>
        }
    </div>
            <ReactTable
                data={data}
                loading={isInspectionLoading}
                defaultPageSize={isString(No_of_rows) ? No_of_rows.trim() : No_of_rows}
                // pageSize={[5, 10, 20, 50, 100]}
                // pageSize={parseInt(No_of_rows)}
                minRows={0}
                manual
                onFetchData={fetchData}
                pages={No_of_pages}
                gotoPage={inspectionPage}
                page = {
                    inspectionPage
                }
                onPageChange={(page) => setTablePageFn(page)}
                showPaginationTop={true}
                onPageSizeChange={props.onAccountsPageSizeChange}
                NoDataComponent={() => CustomNoDataComponent(isInspectionLoading, 'No Inspections Found')}
                showPagination={props.inspectionsList.length !== 0}
                LoadingComponent={() =>
                    isInspectionLoading ?
                        <div style={{
                            display: "block",
                            position: "absolute",
                            left: 0,
                            height: '100%',
                            right: 0,
                            background: "rgba(255,255,255,0.8)",
                            transition: "all .3s ease",
                            top: 0,
                            bottom: 0,
                            textAlign: "center"
                        }}
                            className="loader_btn_block">
                            <ClapSpinner backColor="#89073c" size={40} frontColor="#89073c8a" />
                        </div> : null}
                // filterable={true}
                columns={columns}

                // style={{marginTop: '10px'}}
                getTheadThProps={(state, rowInfo, column, instance) => {

                    return {
                        style: {
                            borderTop: 'none',
                            borderBottom: 'none',
                            borderRight: 'none',
                            textAlign: 'center',
                            color: '#566975',
                            fontSize: '14px',
                            fontWeight: '600',
                            whiteSpace: 'nowrap',
                            float: 'left',
                        }
                    }
                }
                }
                getTrProps={(state, rowInfo, column, instance) => {
                    // onclick = () => userDetail(rowInfo ? rowInfo.row.user : rowInfo);
                    console.log(column);
                    return {
                        className: rowInfo.original.status === 'Draft' ? 'react-table-tr-element yellow-draft-color' : 'react-table-tr-element',
                        style: {
                            border: '1px solid #c7cdd1',
                            transition: '.3s ease-in-out',
                            width: '100%',
                            // borderRadius: '4px',
                            // height: '80px',
                            marginTop: '5px',
                            float: 'left'
                        }
                    }

                }}
                getTdProps={(state, rowInfo, column, instance) => {
                    // if (column.Header == 'Active' || column.Header == 'Actions') {
                    if (column.id == 'image') {
                        return {
                            style: {
                                marginTop: '0px'
                            }
                        }
                    } else {
                        return {
                            className: 'react-td-element'
                        }
                    }
                    //  {
                    //   style: {
                    //     borderTop: 'none',
                    //     verticalAlign: 'middle',
                    //     fontSize: '14px',
                    //     color: '#2b3034',
                    //     marginTop: '10px !important',
                    //     textAlign: 'center',
                    //   }
                    // }
                }
                }
                // getPaginationProps={() => {
                //     return {
                //         style: {
                //             marginTop: '50px',
                //             border: 'none',
                //             boxShadow: 'none'
                //         }
                //     }
                // }
                // }
                getProps={() => {
                    return {
                        style: {
                            border: 'none'
                        }
                    }
                }}
            />
            </div>
    )
}

export default InspectionTable;