import React, { useEffect, useState } from 'react';
import ImageLoader from 'react-imageloader';
import { convertAreaScheduleDateTime, convertAreaScheduleTime, convertDateAndTimeTimezone, convertDateTimeWithoutZone, convertFeedbackTime, makeFirstLetterCapital } from '../../../utility/utility';
import ModalImageGallery from '../ModalImageGallery/modalImageGallery';

var moment = require('moment');


const PreviewFeedback = (props) => {
    const [isImageGallery, setIsImageGallery] = useState(false);
    const [photoUrls, setPhotoUrls] = useState([]);
    const [startIndex, setStartIndex] = useState(0);
    const [currentIns, setCurrentIns] = useState(0);

    const photoUrlClicked = (urls, i) => {
        setIsImageGallery(true);
        setStartIndex(i);
        setPhotoUrls(urls);
    }

    if (isImageGallery) {
        return <ModalImageGallery
            isImageGallery={isImageGallery}
            setIsImageGallery={setIsImageGallery}
            startIndex={startIndex}
            imageUrls={photoUrls}
        />
    }
    const cancelClicked = () => {
        props.history.goBack()
    }
    function preloader() {
        return <img style={{ width: '100%', height: '100%' }} src="/images/gif/giphy.gif" />;
    }
    return (
        props.feedback ? <main className="app-content wraper_content edit_inspection">

                <div className="cancel_btn text-center w-100 mt-5" style={{cursor: "pointer"}}>
                    <button data-test="cancel_button" style={{cursor: "pointer", float: 'left', backgroundColor:'rgb(150, 45, 57)'}} type="button" className="btn btn-outline-secondary cst_btn" onClick={() => cancelClicked()}>Back</button>
                </div>  
            <header className="text-center my-5">
                <h4 className="h4_title font-weight-bold" style={{ fontSize: "22px", color: "#962d39" }}>Preview Feedback</h4> 
            </header>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-12">
                        <div className="preview_feedback">

                            <div className="row">
                                <div className="col">
                                    <div className="pf_inner text-right">
                                        <article>
                                        <p className="font-weight-bold" style={{ fontSize: "18px" }}>Rating: </p>
                                            <p className="font-weight-bold" style={{ fontSize: "18px" }}>Job Name: </p>
                                            <p className="font-weight-bold" style={{ fontSize: "18px" }}>Comments: </p>
                                            <p className="font-weight-bold" style={{ fontSize: "18px" }}>Area Name: </p>
                                            <p className="font-weight-bold" style={{ fontSize: "18px" }}>Created At: </p>
                                            <p className="font-weight-bold" style={{ fontSize: "18px" }}>Day: </p>
                                            {/* <p className="font-weight-bold" style={{ fontSize: "18px" }}>Attachents: </p> */}
                                        </article>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="pf_inner">
                                        <article>
                                        <p className="font-weight-bold" style={{ fontSize: "18px", color: 'rgb(150, 45, 57)' }}>{props.feedback.rating === 'needservice' ? 'Need Service' : makeFirstLetterCapital(props.feedback.rating)}</p>
                                            <p className="mr-1" style={{ fontSize: "18px" }}>{makeFirstLetterCapital(props.feedback && props.feedback.accounts && props.feedback.accounts.name)}
                                            </p>
                                            <p className="" style={{ fontSize: "18px" }}>{props.feedback && props.feedback.comment ? makeFirstLetterCapital(props.feedback && props.feedback.comment) : '-'}</p>
                                            <p className="" style={{ fontSize: "18px" }}>{makeFirstLetterCapital(props.feedback && props.feedback.area && props.feedback.area.name)}</p>
                                            <p className="" style={{ fontSize: "18px" }}>{convertFeedbackTime(props.feedback && props.feedback.feedback_created_at)}</p>
                                            <p className="" style={{ fontSize: "18px" }}>{makeFirstLetterCapital(props.feedback && props.feedback.day)}</p>

                                        </article>
                                        
                                    </div>
                                </div>
                                <div className='row' style={{margin: 'auto'}}>
                                            {props.feedback.attachments && props.feedback.attachments.map((url, i) => {
                                                    return (
                                                        <div class="col" key={i}>
                                                            <div class="file_view" onClick={() => photoUrlClicked(props.feedback.attachments, i)}>
                                                                <ImageLoader
                                                                    style={{ width: '20%', height: '50%', cursor: 'pointer' }}
                                                                    src={url.photo_urls.small}
                                                                    wrapper={React.createFactory('div')}
                                                                    preloader={preloader}>
                                                                    Image load failed!
                                                            </ImageLoader>
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main> : ''
    )
}
export default PreviewFeedback;
