import React, { Component } from 'react';
import PreviewShiftSwappingRequestInfo from '../../../../component/TimeTrackerModuleComponent/ShiftComponent/PreviewShiftSwapInfoComponent/PreviewShiftSwappingRequestInfo/PreviewShiftSwappingRequestInfo';
import * as actions from '../../../../../redux/actions/index';
import { connect } from 'react-redux';
import { reset } from 'redux-form';
import { animateScroll as scroll } from 'react-scroll'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
class PreviewShiftSwapInfoContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoad: false,
        }

    }

    componentDidMount() {

        this.setState({ isLoad: true });
        setTimeout(() => { this.setState({ isLoad: false }); }, 2000);

        if (this.props && this.props.match && this.props.match.params && this.props.match.params.swapId) {
            this.props.getSpecificShiftSwapInfo(this.props.match.params.swapId);
        }

        scroll.scrollToTop();

    }

    componentWillMount() {
        this.props.resetTimeTrackerObjects();
    }

    handleApproveShiftSwapRequestSanction = (value) => {

        let fetchedValue = {
            data: { status: value.swapReviewedStatus }
        }

        this.props.grantSwapRequestReview(fetchedValue, value.swapId);

    }

    handleRejectShiftSwapRequestSanction = (value) => {

        let fetchedValue = {
            data: { status: value.swapReviewedStatus }
        }

        this.props.grantSwapRequestReview(fetchedValue, value.swapId);

    }

    render() {

        if (this.props && this.props.shiftSwap_allInfo && this.props.shiftSwap_allInfo.id) {

            return (
                <div>

                    <PreviewShiftSwappingRequestInfo
                        {...this.props}
                        isLoad={this.state.isLoad}
                        handleApproveShiftSwapRequestSanction={this.handleApproveShiftSwapRequestSanction}
                        handleRejectShiftSwapRequestSanction={this.handleRejectShiftSwapRequestSanction}
                    />

                </div>
            );

        } else {
            const Field = (h, w) => {
                return (<Skeleton height={h} width={w} />)
            }

            return (
                <div>
                    <main className="app-content add_account_modal add_frontline_pop" style={{ marginTop: '-15px' }}>
                        <div className="appcontent_Inner user_newQuote pt-0 d-flex justify-content-center align-items-center">
                            <div className="container-fluid" style={{ marginTop: '100px', marginLeft: '480px', marginRight: '480px' }}>
                                <div className="row">
                                    <div className="col-sm-12 text-center"> <h4 className="section_title">{Field(30, 200)}</h4> </div>
                                </div>
                                <form>
                                    <div className="quote_form ticket_form">
                                        <div className="addQuote_form">
                                            <div className="cst_tab_content">

                                                <div className="row">
                                                    <div className="col-md-12" style={{ marginTop: '15px' }}> <div className="form-group"> {Field(30)} </div> </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-md-12" style={{ marginTop: '15px' }}> <div className="form-group"> {Field(30)} </div> </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-md-12" style={{ marginTop: '15px' }}> <div className="form-group"> {Field(30)} </div> </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-md-12" style={{ marginTop: '15px' }}> <div className="form-group"> {Field(30)} </div> </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-md-12" style={{ marginTop: '15px' }}> <div className="form-group"> {Field(30)} </div> </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-md-12" style={{ marginTop: '15px' }}> <div className="form-group"> {Field(30)} </div> </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div >
                    </main >
                </div>
            );

        }

    }
}

const mapStateToProps = (state) => {
    return {
        user: state.authReducer.user,
        isLoading: state.TimeTrackerModReducer.isLoading,
        shiftSwap_allInfo: state.TimeTrackerModReducer.specificShiftSwapInfo,

        shiftSwap_fromShiftInfo: state.TimeTrackerModReducer.specificShiftSwapInfo && state.TimeTrackerModReducer.specificShiftSwapInfo.fromShiftDetails ?
            state.TimeTrackerModReducer.specificShiftSwapInfo.fromShiftDetails : {},

        shiftSwap_toShiftInfo: state.TimeTrackerModReducer.specificShiftSwapInfo && state.TimeTrackerModReducer.specificShiftSwapInfo.toShiftDetails ?
            state.TimeTrackerModReducer.specificShiftSwapInfo.toShiftDetails : {},

        isTimeTrackerModalOpen: state.TimeTrackerModReducer.isTimeTrackerModalOpen,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        resetTimeTrackerObjects: () => dispatch(actions.resetTimeTrackerObjects()),
        handleCancelClickAction: () => dispatch(actions.handleCancelClickAction()),
        getSpecificShiftSwapInfo: (id) => dispatch(actions.getSpecificShiftSwapInfo(id)),
        handleFrequentTimeTracker_openModal: (data) => dispatch(actions.handleFrequentTimeTracker_openModal(data)),
        handleFrequentTimeTracker_closeModal: (data) => dispatch(actions.handleFrequentTimeTracker_closeModal(data)),
        grantSwapRequestReview: (body, id) => dispatch(actions.grantSwapRequestReview(body, id)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PreviewShiftSwapInfoContainer);