import React, { Component } from 'react';
import * as actions from '../../../../../redux/actions/index';
import { connect } from 'react-redux';
import { extractExtention } from '../../../../../utility/utility';
import { decode, encode } from 'base64-arraybuffer';
import { animateScroll as scroll} from 'react-scroll'
import AuditLogs from '../../../../component/Inventory/InventoryTicketsComponents/AuditLogs/auditLogs';
const cloneDeep = require('clone-deep');

class AuditLogsContainer extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isImagesEmpty: false,
            imageName: '',
            base64: [],
            AllFileTypes: [],
            fileType: '',
        }
        this.inputOpenFileRef = React.createRef();
        this.onPortfolioFileChange = this.onPortfolioFileChange.bind(this);
    }

    componentDidMount = () => {
        this.props.getSinglePickingTicket(this.props.match.params.ticketId);
        scroll.scrollToTop();
    }

    onPortfolioFileChange = (e) => {
        this.setState({
            faultImages: 0
        });
        console.log(this.state.base64);
        let files = e.target.files;
        let imageName = [], fileType = [], AllFileTypes = [], base64 = [];
        const that = this;
        // for (let i = 0; i < files.length; i++) {
        function readFile(index) {
            if (index < files.length) {
                let reader = new FileReader();
                let file = files[index];
                imageName.push(file.name)
                fileType.push(file.type)
                AllFileTypes.push(file.type)
                console.log(file, "file<<<<<<")
                if (file.type.match('image')) {
                    console.log(reader, "reader<<<<<")
                    reader.onload = (e) => {
                        var image = new Image();
                        image.src = reader.result;
                        image.onload = function () {
                            // alert('width' + image.width + 'height' + image.height);
                            // if (image.width >= MinImageResolution.width && image.height >= MinImageResolution.height) {
                            base64.push(e.target.result);
                            if (index === files.length - 1) {
                                //   
                                that.setState(prevState => ({
                                    imageName: [...prevState.imageName, ...imageName],
                                    fileType: [...prevState.fileType, ...fileType],
                                    AllFileTypes: [...prevState.AllFileTypes, ...AllFileTypes],
                                    // arrayBuffer: [...prevState.arrayBuffer, decode(reader.result)],
                                    base64: [...prevState.base64, ...base64]
                                }))
                            }
                            // }
                            // else {
                            //     that.setState(prevState => ({
                            //         faultImages: prevState.faultImages + 1
                            //     }))
                            // }
                            readFile(index + 1);
                        };

                    }
                }

                // reader.readAsArrayBuffer(file)
                reader.readAsDataURL(file)
            }
        }

        readFile(0);
    }

    
    render() {

        return (
            <AuditLogs
                {...this.props}
                pickingTicketPreviewData={this.props.pickingTicketPreviewData}
                isLoading={this.props.isLoading}
                user={this.props.user}
                onPortfolioFileChange={this.onPortfolioFileChange}
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.authReducer.user,
        isLoading: state.InventoryReducer.isLoading,
        pickingTicketPreviewData: state.InventoryReducer.pickingTicketPreviewData,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        previewTicketClicked: (ticket) => dispatch(actions.previewTicketClicked(ticket)),
        getSinglePickingTicket: (id) => dispatch(actions.getSinglePickingTicket(id)),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(AuditLogsContainer);