import React, { useEffect } from "react";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";
//import { getManagersList } from "../../../../../../redux/actions";
import { getSingleRestRoomDetails, addRestroomDetails, editRestroomDetails } from "../../../../../redux/actions/FormAuditModAction/LogBookAction/LogbookAction";
import { getAllJob, getAllJobNew, getCustomerInfos } from "../../../../../redux/actions/FormAuditModAction/TeamManagementAction/teamManagementAction";
import moment from "moment";
import MonthlyCostSummaryPage from "../../../../component/FormAuditModule/Inventory/MonthlyCostSummaryAnalysis/MOnthlyCostSummaryPage";
import { getAllMonthlyCostReport, getCostSummaryAnalysis } from "../../../../../redux/actions/FormAuditModAction/InventoryAction/InventoryAction";

function MonthlyCostSummaryAnalysisContainer() {
  const {
    location: { pathname },
  } = useHistory();
  const { id } = useParams();
  const dispatch = useDispatch();
  const isEdit = Boolean(pathname.split("/")[2] !== "view" && id)
  const isView = Boolean(pathname.split("/")[2]?.includes("view"))
  const { restroomDetails } = useSelector((state) => state.LogBookReducer)
  const { monthly_cost_report_list } = useSelector((state) => state.InventoryModuleReducer)


  

  useEffect(() => {

    dispatch(getCustomerInfos())
    dispatch(getAllJobNew())
    

  }, [])

  
  const months={
    1: "January",
    2:"Feb",
    3:"March",
    4:"April",
    5:"May",
    6:"June",
    7:"July",
    8:"Aug",
    9:"September",
    10:"October",
    11:"November",
    12:"December"
  }
   
  

  function StructureDefaultForm(form){
    
       if(!form) return []
       if(form.length ==0){
        return []
       }
       
       return form.map((details)=>{
        if(typeof details.month =="number"){
           details.month=months[details.month]
        }
        return details;
       })
  }

  

  const defaultForm = {
    // sections:[{
    //   month: "January",
    //   total_monthly_cost: 1000,
    //   average_cost_per_day: 200,
    //   monthly_cost_per_fte: 500,
    //   cost_per_sq_ft: 300
    // },
    // {
    //   month: "Feb",
    //   total_monthly_cost: 1000,
    //   average_cost_day: 200,
    //   monthly_cost_FTE: 500,
    //   monthly_cost_SF: 300
    // },
    // {
    //   month: "March",
    //   total_monthly_cost: 1000,
    //   average_cost_day: 200,
    //   monthly_cost_FTE: 500,
    //   monthly_cost_SF: 300
    // }, {
    //   month: "April",
    //   total_monthly_cost: 1000,
    //   average_cost_day: 200,
    //   monthly_cost_FTE: 500,
    //   monthly_cost_SF: 300
    // }, {
    //   month: "May",
    //   total_monthly_cost: 1000,
    //   average_cost_day: 200,
    //   monthly_cost_FTE: 500,
    //   monthly_cost_SF: 300
    // }, {
    //   month: "June",
    //   total_monthly_cost: 1000,
    //   average_cost_day: 200,
    //   monthly_cost_FTE: 500,
    //   monthly_cost_SF: 300
    // }, {
    //   month: "July",
    //   total_monthly_cost: 1000,
    //   average_cost_day: 200,
    //   monthly_cost_FTE: 500,
    //   monthly_cost_SF: 300
    // }, {
    //   month: "Aug",
    //   total_monthly_cost: 1000,
    //   average_cost_day: 200,
    //   monthly_cost_FTE: 500,
    //   monthly_cost_SF: 300
    // },
    // {
    //   month: "September",
    //   total_monthly_cost: 1000,
    //   average_cost_day: 200,
    //   monthly_cost_FTE: 500,
    //   monthly_cost_SF: 300
    // },
    // {
    //   month: "October",
    //   total_monthly_cost: 1000,
    //   average_cost_day: 200,
    //   monthly_cost_FTE: 500,
    //   monthly_cost_SF: 300
    // }, {
    //   month: "November",
    //   total_monthly_cost: 1000,
    //   average_cost_day: 200,
    //   monthly_cost_FTE: 500,
    //   monthly_cost_SF: 300
    // }, {
    //   month: "December",
    //   total_monthly_cost: 1000,
    //   average_cost_day: 200,
    //   monthly_cost_FTE: 500,
    //   monthly_cost_SF: 300
    // },


    // ]

  }

  // useEffect(() => {
  //   dispatch(getManagersList({ supervisor: true }))
  //   dispatch(getUsersList())
  // }, []);

  // useEffect(() => {
  //   dispatch(getCostSummaryAnalysis(id));

  // }, [id]);


  const structureBody = (data) => {
    return {
      year: data.year.year,
      account_id: data.account_id
    };
  }


  const onSubmit = (data) => {
    const body = structureBody(data)
    dispatch(getAllMonthlyCostReport(body))


  }


  

  return (
    <MonthlyCostSummaryPage
      elements={defaultForm}
      formSubmittedCallback={onSubmit}
      isEdit={isEdit}
      isView={isView}
      costData={StructureDefaultForm(monthly_cost_report_list)}
    />
    
  );
}

export default MonthlyCostSummaryAnalysisContainer;