import React, { useState, useEffect, useCallback } from "react";
import { ClapSpinner } from 'react-spinners-kit';
import { connect } from 'react-redux';
import * as actions from '../../../../../../redux/actions/index';
import { BrowserQRCodeReader } from '@zxing/library';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import $ from "jquery";
import "../../../AssetsContainer/AssetScansContainer/AssetQr.css";
import SuccessAlert from "../../../../../../utility/successAlert/successAlert";
import errorHandler from "../../../../../../utility/errorHandler/errorHandler";
window.assets = null;

const AssetCheckOutQRScan = (props) => {
  const codeReader = new BrowserQRCodeReader();
  const [loaded, setLoaded] = useState(false);
  const [isScanReady, setScanReady] = useState(false);
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState(null);
  const [selDeviceId, setDeviceId] = useState(null);
  const [accessEnable, setAccess] = useState("init");
  const [alert, setAlert] = useState(false);
  const [readAssetId, setReadAssetId] = useState(null);
  const refreshPage = () => { window.location.reload(false); }

  const searchProductByBarCode = useCallback((barcode_id) => {
    let receivedAssetId_fromQRCode = null;
    if (!loading) {
      setLoading(true);
      if (barcode_id) {
        if (barcode_id.includes("https://") && barcode_id.includes("inventory/assert")) {
          receivedAssetId_fromQRCode = barcode_id.split("/").reverse()[0];
          setReadAssetId(receivedAssetId_fromQRCode);
        } else {
          return setAlert(true);
        };
      }
    }
  }, [loading, result]);

  useEffect(() => {
    if (readAssetId !== null) {
      props.getSingleLogAsset(readAssetId, {page: 1, limit: 10});
    }
  }, [readAssetId]);

  useEffect(() => {
    if (readAssetId != null) {

      setTimeout(() => {
        if (window.assets && window.assets.length > 0) {

          let getLastIndex = window.assets.length - 1
          if (window.assets[getLastIndex].status == 'checkedOut') {
            errorHandler(`${window.assets[getLastIndex].name} already checkedOut`);
            setTimeout(() => {
              refreshPage();
            }, 2000);
            window.assets = null;
          } else {
            props.history.push({
              pathname: `/asset/checkOut/${readAssetId}`
            });
            delete window.assets
          }

        } else {

          props.history.push({
            pathname: `/asset/checkOut/${readAssetId}`
          });
          delete window.assets

        }

      }, 1000);
    }
  }, [readAssetId]);

  useEffect(() => {
    if (!loaded) {
      $('.login100-more').addClass("d-none");
      $('body').addClass("qr-page-body");
      //$('header').addClass("qr-page-header");
      $('.cus_ffoter_block').addClass("qr-page-footer");
      setLoaded(true);
      codeReader.getVideoInputDevices()
        .then((videoInputDevices) => {
          let selectedDeviceId = videoInputDevices[videoInputDevices.length - 1].deviceId;
          setDeviceId(selectedDeviceId);
          setAccess("granted");
        }).catch((err) => {
          console.error(err)
        });
    }
  }, [loaded, codeReader]);

  useEffect(() => {
    if (accessEnable === "granted" && !isScanReady) {
      setScanReady(true);
      codeReader.decodeOnceFromVideoDevice(selDeviceId, 'video').then((result) => {
        codeReader.reset();
        searchProductByBarCode(result.text);
      }).catch((err) => {
        console.log(JSON.stringify(err))
        setAccess("denied");
      });
    }
    return (() => {
      $('.login100-more').removeClass("d-none");
      $('body').removeClass("qr-page-body");
      //$('.app-header').removeClass("qr-page-header");
      $('.cus_ffoter_block').removeClass("qr-page-footer");
    })
  }, [selDeviceId, accessEnable, isScanReady, searchProductByBarCode, codeReader]);

  return (
    <>
      {props.isLoading ? <div className="loader_btn_block">
        <ClapSpinner backColor="#89073c" size={40} frontColor="#89073c8a" />
      </div> : <> {accessEnable === "denied" ? <div className="hl_scan_error_cont padding_lr not_permitted_scan_box"><h6>{accessEnable === "denied" && "Please grant Camera permissions on the Browser settings"}</h6></div> :

        <video id="video" style={{ width: '100%', height: "100vh" }}></video>
      }<div className="qr-shaded-region" id="qr-shaded-region_1" ><div className="div-first"></div><div className="div-second"></div></div>
        <div className="qr-shaded-region" id="qr-shaded-region_2" ><div className="div-first"></div><div className="div-second"></div></div>
        <div className="qr-shaded-region" id="qr-shaded-region_3"><div className="div-first"></div><div className="div-second"></div></div>
        <div className="qr-shaded-region" id="qr-shaded-region_4"><div className="div-first"></div><div className="div-second"></div></div>
        <div className="qr-page btn_block">
          {/* {props.user && props.user.role === "frontline" ? <button type="button" className={`btn w-100 mb-1 cst_btn btn_danger`}  onClick={() => props.history.push("/schedules")}>Mark Unavailable</button> : "" }
        <button type="button" className={`mt-1 btn btn-success w-100 cst_btn ${result ? "" : "disabled-btn"}`} disabled={result ? false : true} onClick={() => this.getAreaByQR()}>Mark Service Completed</button> */}
        </div></>}
      <Dialog open={alert} onClose={props.handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title" style={{ fontSize: '13', color: '#06080f' }}>
          <h3 style={{ color: '#ab385e' }} data-test="modal_delete_title"> Please scan Inventory Asset QR </h3>
        </DialogTitle>
        <DialogActions>
          <button data-test="modal_cancel" style={{ minHeight: '36px', minWidth: '80px' }} onClick={() => { refreshPage(); setAlert(false); }} className={"btn cst_btn btn-outline-secondary"} id="cancel" type="cancel"> OKAY </button>
        </DialogActions>
      </Dialog>
    </>
  )
};

const mapStateToProps = (state) => {
  if (state.InventoryReducer && state.InventoryReducer.assetSingleLogList && state.InventoryReducer.assetSingleLogList.length > 0) {
    window.assets = state.InventoryReducer.assetSingleLogList;
  }

  return {
    user: state.authReducer.user,
    isLoading: state.InventoryReducer.isLoading,
    assets: state.InventoryReducer.assetSingleLogList,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSingleLogAsset: (id, params) => dispatch(actions.getSingleLogAsset(id, params)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AssetCheckOutQRScan);