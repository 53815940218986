import React from "react";
import  moment from "moment";
import { convertAreaScheduleTime, convertDateAndTimeTimezonelll, convertUTCtoTimezone } from "../../../utility/utility";
import { currentData } from "../../../utility/constants/constants";
const momentTZ = require('moment-timezone');

const FrontLineTask = ({areaSearchResult, searchHistory, history, token, user}) => {
  const getFormattedZone = (timezone) => {
    let zone = ''
    const now = momentTZ.tz(timezone);
    zone = now.format('z');
    return '(' + zone + ')';
  }
  const nextSchedule = (last) => {
    let nextScheduleDate;
    let date = moment().format("MM/DD/YYYY");
    //let currentTime = moment(last.scanned_at,"MM-DD-YYYY HH:mm A").subtract(6.5, "hours");
    // currentTime = currentTime > moment() ? currentTime : moment();
     let currentTime = moment().format('llll');
    let nextSchedule = null;
    last.area.timeslots.map(time => {
      const startTime = moment(`${date} ${convertUTCtoTimezone(time.start_time_utc,last.timezone)}`).format('llll');
      const startDay = moment(startTime).format("MM-DD-YYYY");
      const currentDay = moment(currentTime).format("MM-DD-YYYY");
      // if(startDay !== currentDay) {
        if(last.last_sync && last.last_sync.timeslot_id !== time.id) {
          if ( startTime > currentTime && !nextSchedule) {
            // const dateTime = moment().format("MM/DD/YYYY") + " " + time.start_time;
            // const st_time = moment.utc(moment(dateTime)).format("hh:mm A");
            const st_time = moment(moment().format("MM-DD-YYYY") + " " + convertUTCtoTimezone(time.start_time_utc,last.timezone), "MM-DD-YYYY HH:mm:ss").format('MM-DD-YYYY hh:mm A');
            nextSchedule = st_time;
          }
        }
      // }
      return time;
    });
    if (!nextSchedule) {
      let nextDate;
      let currentDay = moment().get("day");
      let nextDay = last.area.days.find(a => (a) > currentDay);
      if (!nextDay) nextDay = last.area.days[0];
      if (nextDay > currentDay){
        nextDate = moment().add(1, 'day').isoWeekday(nextDay);
      } else {
        nextDate = moment().add(1, 'week').isoWeekday(last.area.days[0]);
      }
      date = nextDate.format("MM-DD-YYYY");
      // const dateTime = moment().format("MM/DD/YYYY") + " " + last.area.timeslots[0].start_time;
      // const st_time = moment.utc(moment(dateTime)).format("hh:mm A");
      // const st_time = moment(moment().format("MM-DD-YYYY") + " " + last.area.timeslots[0].start_time_zone, "MM-DD-YYYY HH:mm:ss").format("hh:mm A")
      const date2 =  moment(moment(date).format("MM/DD/YYYY") + " " + convertUTCtoTimezone(last.area.timeslots[0].start_time_utc,last.timezone)).format("MM/DD/YYYY hh:mm:ss A")
      const st_time = moment(date2).format('MM-DD-YYYY hh:mm A');
      nextScheduleDate = st_time;
      console.log("restart next schedule", nextScheduleDate)
    } else {
      nextScheduleDate = nextSchedule;
      console.log("next schedule", nextScheduleDate)
    }
    const nextDate = nextScheduleDate;
    const formattedDate = moment(nextDate).format('lll');
    return `Next Schedule ${formattedDate} ${getFormattedZone(searchHistory.timezone)}`
  };  
  // const lastCleaned = () => {
  //   searchHistory.last_sync.scanned_at = searchHistory.last_sync.scanned_at ? searchHistory.last_sync.scanned_at : searchHistory.last_sync.captured_time;
  //   let time = convertUtc(searchHistory.last_sync.scanned_at);
  // if (moment().diff(time, "hours") < 1) {
  //     let date = convertUtc(searchHistory.last_sync.scanned_at);
  //     var fromNow = date.fromNow();
  //     return   `${fromNow} , at ${time.format("hh:mm A")}`;
  //   }
  //   else return convertUtc(searchHistory.last_sync.scanned_at);;
  // };
  const lastCleaned = () => {
    searchHistory.last_sync.scanned_at = searchHistory.last_sync.scanned_at ? searchHistory.last_sync.scanned_at : searchHistory.last_sync.captured_time;
    let time = convertDateAndTimeTimezonelll(searchHistory.last_sync.scanned_at, searchHistory.timezone);
    debugger
    if (moment().diff(time, "hours") < 1) {
      let date = convertDateAndTimeTimezonelll(searchHistory.last_sync.scanned_at, searchHistory.timezone);
      var fromNow = moment(date).fromNow();
      const dateTime = momentTZ.tz(time, 'MMM D, YYYY h:mm A z', searchHistory.timezone);
      return   `${fromNow} , at ${dateTime.format("hh:mm A")}`;
    }
    else return convertDateAndTimeTimezonelll(searchHistory.last_sync.scanned_at, searchHistory.timezone);
  };
  return (
    <div className="frontline-content history_block mt-3 pt-2" style={{display: 'grid'}}>
     
      <h4 className="mb-2">
      {searchHistory && searchHistory.area ? <span style={{fontWeight: 'bold'}}>{searchHistory.area.account.name}</span> : ''} <span> &gt; </span> {searchHistory && searchHistory.area ? <>{searchHistory.area.name}</> : "" }
      </h4>
      {searchHistory && searchHistory.last_sync ? <>{searchHistory.last_sync.status === "not_cleaned" ? <div className="mt-3 front-line-task-block">Not cleaned at {lastCleaned()}</div> :  (searchHistory.last_sync.status === "not_available" ? <div className="mt-3 front-line-task-block">was In-use at {lastCleaned()}</div> : <div className="mt-3 front-line-task-block">Last cleaned {searchHistory.last_sync.area.account.hide_name ? "" : `by ${searchHistory.last_sync.user.first_name}`} - {lastCleaned()}</div>)}</> : <div className="mt-3 front-line-task-block">Not cleaned yet</div>}
      <div className="mt-1 front-line-task-block" style={{marginBottom: '10px'}}>{nextSchedule(searchHistory)}</div>
      <div style={{marginBottom: '10px'}}>
       {!user ? <><button className="btn btn_danger float-right" onClick={() => history.push(`/pegassure-feedback/${searchHistory.area.id}`)}>Leave Feedback</button>
       </> : "" } 
       </div>
      <div>
      {searchHistory && searchHistory.area && (!user || (user && user.role !== "frontline")) ? <><button className="btn btn_danger float-right" onClick={() => history.push(`/account/${token}/schedule`)}>View Schedule</button></> : "" }
      {!user || (user && user.role !== "frontline") ? <><button className="btn btn_danger desktop-btn-history float-right mr-2" onClick={() => history.push(`/account/${token}`)}>Back</button>
       </> : "" }
      </div>
    </div>
  )
};

export default FrontLineTask;