import React from 'react';
import { currentData } from '../../../../utility/constants/constants';
import { GetFormatedDate, weekStartDayAndWeekEndDay, getMonthAndYear, getYearView } from '../../../../utility/utility';
import { convertDashboardDataToCsvType } from '../../../../utility/utility';
import { CSVLink, CSVDownload } from "react-csv";

var format = require('date-format');

const WeekMonthYearLists = (props) => {

  return (
      <div className="row">
        <div className="col-md-12">
          <div className="app_inner_title">
            <div className="calender_block">
              <div className="filter_cal_box">
                <div className="filter_block">
                  <div className="list_filter">
                    {/* <a className={props.isWeek ? props.isLoading ? "list_link active btn-wait" : "list_link active" : "list_link"} onClick={() => props.viewModeClicked(currentData.WEEK)} href="javascript:void(0)">{props.isWeek ? props.isLoading ? '' : 'Week' : 'Week'}</a> */}
                    {/* <a className={props.isMonth ? props.isLoading ? "list_link active btn-wait" : "list_link active " : "list_link"} onClick={() => props.viewModeClicked(currentData.MONTH)} href="javascript:void(0)">{props.isMonth ? props.isLoading ? '' : 'Month' : 'Month'}</a> */}
                    <a className={props.isYear ? props.isLoading ? "list_link active btn-wait" : "list_link active " : "list_link"} onClick={() => props.viewModeClicked(currentData.YEAR)} href="javascript:void(0)">{props.isYear ? props.isLoading ? '' : 'Year' : 'Year'}</a>
                  </div>
                </div>
                <div className="cal_block">
                  <ul className="calc_content">
                    <li onClick={props.previousWeekMonthYearCliked}><a href="javascript:void(0)" ><i className="fas fa-chevron-left"></i></a></li>
                    <li onClick={props.nextWeekMonthYearCliked}><a href="javascript:void(0)" ><i className="fas fa-chevron-right"></i></a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          {/* <div style={{ float: 'left', marginTop: '20px' }}>
           <CSVLink filename="Dashboard.csv" className="csv_btn btn_danger " data={convertDashboardDataToCsvType(props.dashboardData)} >Export CSV</CSVLink><br /><br /><br />
           </div> */}
          {/* <div style={{ float: 'left', marginTop: '20px' }}>
           <button className="csv_btn btn_danger " onClick={props.viewPdfClicked}>PDF</button><br /><br /><br />
           </div> */}
          <div className="react_times_button time_picker_preview" style={{ textAlign: 'center', width: '200px', WebkitBoxShadow: '2px 2px 15px 0 rgba(0,0,0,.15)', height: '50px' }}>
            <label className=" preview_container without_icon" style={{ backgroundColor: '#e9ecef59', color: '#89073c' }}>
              {getYearView(props.currentDate)}
            </label>
          </div>

        </div>
      </div>
  )
}

export default WeekMonthYearLists;