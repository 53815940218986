import React from 'react';
import Percentage from './Percentage';
import { RatingTypes } from '../../../../utility/constants/constants';
import List from './List';

// import GooglePlacesAutoComplete from '../../GooglePlacesAutoComplete/googlePlacesAutoComplete';
// import UserSkeleton from './userSkeleton';

const conditionalEditRendering = (rating, updateRatingClicked) => {
     
    return (
        <ul className="nav nav-tabs rating_tabs" id="myTab" role="tablist">
            {RatingTypes.Percentage === rating ?
                <li className="nav-item" onClick={() => updateRatingClicked(RatingTypes.Percentage)}>
                    <a className="nav-link active" id="percentage-tab" data-toggle="tab" href="#percentage" role="tab" aria-controls="percentage" aria-selected="true">Percentage</a>
                </li>
                : RatingTypes.List === rating ?
                    <li className="nav-item" onClick={() => updateRatingClicked(RatingTypes.Lists)}>
                        <a className="nav-link" id="list-tab" data-toggle="tab" href="#list" role="tab" aria-controls="list" aria-selected="false">List</a>
                    </li>
                    : null
            }


        </ul>
    )
}

let NewRating = (props) => {

    // const RegionOptions = props.regionList.map(reg => {
    //     return {
    //         id: reg.region.id,
    //         name: reg.region.name
    //     }
    // })

    // const positionOptions = props.positionsList.map(pl => {
    //     return {
    //         id: pl.position.id,
    //         name: pl.position.name
    //     }
    // })
    //  
    return (
        <main className="app-content edit_inspection rating_con">
            <div className="rating_tab_header">
                {props.isEdit ?
                    conditionalEditRendering(props.ratingType, props.updateRatingClicked)
                    :
                    <ul className="nav nav-tabs rating_tabs" id="myTab" role="tablist">
                        <li className="nav-item" onClick={() => props.updateRatingClicked(RatingTypes.Percentage)}>
                            <a className="nav-link active" id="percentage-tab" data-toggle="tab" href="#percentage" role="tab" aria-controls="percentage" aria-selected="true">Percentage</a>
                        </li>
                        <li className="nav-item" onClick={() => props.updateRatingClicked(RatingTypes.List)}>
                            <a className="nav-link" id="list-tab" data-toggle="tab" href="#list" role="tab" aria-controls="list" aria-selected="false">List</a>
                        </li>
                    </ul>
                }

            </div>
            <section className="appcontent_Inner rating_inner">
                <div className="row">
                    <div className="col-md-12">
                        <div className="tab-content" id="myTabContent">
                            {/* <!--percentage tab content--> */}
                            {RatingTypes.Percentage === props.ratingType ?
                                <Percentage
                                    onSubmit={props.addNewRating}
                                    {...props} />
                                : RatingTypes.List === props.ratingType ?
                                    <List
                                        onSubmit={props.addNewRating}
                                        {...props} /> : null}
                            {/* <!-- // percentage tab content--> */}

                            {/* <!--list tab content--> */}

                            {/* <!-- // list tab content--> */}
                        </div>
                    </div>
                </div>
            </section>
        </main>
    )
    // }

}

// const mapStateToProps = (state) => {
//     let rating_options_attributes = [{}, {}]

//     return { initialValues: { rating_options_attributes } }
// }

// NewRating = reduxForm({
//     form: 'newRatingForm',
//     validate,
//     enableReinitialize: true
// })(NewRating);

// NewRating = connect(mapStateToProps)(NewRating)

export default NewRating;

// export default reduxForm({
//     form: 'newUserFormForm',
//     validate
// })(NewUser);