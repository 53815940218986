import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../../../styles/custom.css";
import { routes } from "../../../utility/constants/constants";
import { makeFirstLetterCapital } from "../../../utility/utility";

let HomePage = (props) => {

  const { openTicketCount, acceptedTicketCount } = props

  const [user, setUser] = useState()

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'))
    setUser(user)
  }, []);

  useEffect(() => {

    if (props?.user?.exemption_type == 'exempt') {
      if ((props.isClockInShiftAvailable || props.isClockInShiftExcuse)) {
        if (props?.clockInCheckedInTime == undefined) {
          props.handleFrequentTimeTracker_openDeleteModal();
        } else if (!(props?.clockInCheckedInTime?.length > 0)) {
          props.handleFrequentTimeTracker_openDeleteModal();
        }
      }
    }

  }, [props.isClockInShiftAvailable || props.isClockInShiftExcuse])

  return (
    <div>
      <main className="app-content wraper_content inspection_wraper">
        <div className="container" style={{ marginTop: "100px" }}>
          {+openTicketCount > 0 || +acceptedTicketCount > 0 ? (
            <div className="row">
              <div className="col-md-12 FLH_col">
                <div className="FLH_box_container">
                  <div className="card-body">
                    <p> Hey {makeFirstLetterCapital(user?.first_name)} {makeFirstLetterCapital(user?.last_name)}, you got a PegAssure Ticket </p>
                    <Link style={{ color: "blue" }} to={routes.PRIORITY_ESCALATION_TICKETS}> Please click here </Link>
                  </div>
                </div>
              </div>
            </div>
          ) : null}


          <div className="row">
            <div className="col-md-12 FLH_col">
              <div className="FLH_button_container">
                <button
                  data-test="filter_go_btn"
                  onClick={props.openCamera}
                  className={"btn btn_danger FLH_button"}
                  id="submit"
                  type="submit"
                  disabled={+openTicketCount > 0 || +acceptedTicketCount > 0 ? true : false}
                >
                  {" "}
                  Scan After Cleaning Complete{" "}
                </button>
              </div>
            </div>
          </div>

          {props.user.exemption_type == 'non-exempt' ? (
            <>
              {(props.isClockInShiftAvailable || props.isClockInShiftExcuse) || props.clockInCheckedInTime ? (
                <div className="row">
                  <div className="col-md-12 FLH_col">
                    <div className="text-align-center mt-3 max_width_100">
                      <button className={"btn btn_danger FLH_button"} type="button"  onClick={() => { props.openTimerScreen(); }} block> Proceed to Clock In </button>
                    </div>
                  </div>
                </div>
              ) : null}
            </>
          ) : null}

        </div>
      </main>
    </div>
  );
};

export default HomePage;
