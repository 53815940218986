import React, { forwardRef } from "react";
import { getFormValues, reduxForm } from "redux-form";
import { Input } from "../../../UI/InputElement/InputElement";
import { connect } from "react-redux";
import { DateTimeField } from "../../../UI/DateTimeField/DateTimeField";
import { selectDay } from "../../../../../utility/constants/constants";

let AddAvailability = (props) => {

    const { handleSubmit } = props;

    const fromDate = (date) => {
        if (!date) {
            return 'From'
        } else {
            const formattedDate = new Date(date)
            const month = formattedDate.toLocaleString('en-US', { month: 'long' });
            const year = formattedDate.getFullYear();
            return `${month} ${year}`;
        }
    };

    const FromCustomInput = forwardRef(({ value, onClick }, ref) => (

        <div style={{ marginLeft: "-20px", cursor: 'pointer' }} className="font-weight-bold h6" onClick={onClick} ref={ref}>
            {fromDate(value)} <i style={{ fontSize: "17px" }} className="fa">&#xf107;</i>
        </div>
    ));

    const toDate = (date) => {
        if (!date) {
            return 'To'
        } else {
            const formattedDate = new Date(date)
            const month = formattedDate.toLocaleString('en-US', { month: 'long' });
            const year = formattedDate.getFullYear();
            return `${month} ${year}`;
        }
    };

    const ToCustomInput = forwardRef(({ value, onClick }, ref) => (

        <div style={{ marginLeft: "-20px", cursor: 'pointer' }} className="font-weight-bold h6" onClick={onClick} ref={ref}>
            {toDate(value)} <i style={{ fontSize: "17px" }} className="fa">&#xf107;</i>
        </div>
    ));

    return (
        <main className="app-content wraper_content user_newQuote add_account_modal add_New_Accont newuser">
            <div className="inspection_content_sec appcontent_Inner">
                <div className="container-fluids">
                    <div className="ticket_form"> <h2 className="md_title"> Add Availability </h2> </div>
                    <form onSubmit={handleSubmit} className="form_content">
                        <div className="quote_form availability_form">
                            <div className="addQuote_form scroll_acc_com">

                                <div className="">
                                    <div className="row align-items-center" >

                                        <div className="col-md-2"> <label className="form_title fw-bold"> Date Cycle </label> </div>

                                        <div className="col-md-5">
                                            <div className="form-group">
                                                <label className="form_title fw-bold"> Start Date <sup className="cst_sup_txt text_danger">*</sup></label>
                                                <div className="input-group cst_input_group col-lg-14 pl-0">
                                                    <Input
                                                        datePickerType={"react-datepicker"}
                                                        type="date"
                                                        minDate={new Date()}
                                                        dateFormat={"MM-dd-yyyy"}
                                                        name={`data[start_date]`}
                                                        className="form-control input-modifier"
                                                    // component={<ToCustomInput />}
                                                    //showMonthYearPicker={true}
                                                    />
                                                    <div className="input-group-append cursor-pointer" style={{ float: "right", position: "absolute", right: 0, height: "36px", top: 0 }}>
                                                        <span class="input-group-text" id="basic-addon2"> <i class="fa fa-calendar"></i> </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-5">
                                            <div className="form-group">
                                                <label className="form_title fw-bold"> End Date <sup className="cst_sup_txt text_danger">*</sup></label>
                                                <div className="input-group cst_input_group col-lg-14 pl-0">
                                                    <Input
                                                        datePickerType={"react-datepicker"}
                                                        type="date"
                                                        minDate={props.formStates?.data?.start_date}
                                                        dateFormat={"MM-dd-yyyy"}
                                                        name={`data[end_date]`}
                                                        className="form-control input-modifier"
                                                    // component={<ToCustomInput />}
                                                    //showMonthYearPicker={true}
                                                    />
                                                    <div className="input-group-append cursor-pointer" style={{ float: "right", position: "absolute", right: 0, height: "36px", top: 0 }}>
                                                        <span class="input-group-text" id="basic-addon2"> <i class="fa fa-calendar"></i> </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                {selectDay.map((item, index) => (
                                    <div key={index} className="cst_tab_content">
                                        <div className="row align-items-center">

                                            <div className="col-md-2">
                                                <div className="form-group">
                                                    <div className="checkbox_block radius_checkbox">
                                                        <div className="custom-control custom-checkbox">
                                                            <Input
                                                                type="checkbox"
                                                                name={`data[availability][${index}][day]`}
                                                                className="custom-control-input"
                                                                id={`customCheck${index}`}
                                                                value={item.id}
                                                            />
                                                            <label className="custom-control-label form_title" for={`customCheck${index}`}> {item.name} </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-5">
                                                <div className="form-group">
                                                    <label for="email" className="form_title"> Start Time </label>
                                                    <DateTimeField timeConstraints={{ minutes: { step: 5 } }} datePickerType='react-datetime' pickerType="timePicker" dataFormat="hh:mm A" className="form-control Input-modifier" id="areaName" name={`data[availability][${index}][start_time]`} />
                                                </div>
                                            </div>

                                            <div className="col-md-5">
                                                <div className="form-group">
                                                    <label className="form_title"> End Time </label>
                                                    <DateTimeField timeConstraints={{ minutes: { step: 5 } }} datePickerType='react-datetime' pickerType="timePicker" dataFormat="hh:mm A" className="form-control Input-modifier" id="areaName" name={`data[availability][${index}][end_time]`} />
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                ))}

                                <div className="modal-footer">
                                    <button data-test="cancel_button" type="button" className="btn btn-outline-secondary cst_btn w-25" onClick={() => props.onCancelClicked()}> Cancel </button>
                                    <button data-test="submit_button" type="submit" disabled={props.isLoading} className={props.isLoading ? "btn cst_btn btn_danger mr-2 btn-wait w-25" : "btn cst_btn btn_danger mr-2 w-25"}> {props.isLoading ? "" : "Submit"} </button>
                                </div>

                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </main>
    );
}

const mapStateToProps = (state) => {

    let data = ''

    let clearTime = (value) => {
        return value?.data?.availability?.map((item) => {
            return {
                day: item.day,
                start_time: item.day === true ? item.start_time : '',
                end_time: item.day === true ? item.end_time : ''
            }
        })
    }

    data = {
        availability: clearTime(getFormValues('addAvailabilityForm')(state)),
        end_date: getFormValues('addAvailabilityForm')(state)?.data?.end_date,
        start_date: getFormValues('addAvailabilityForm')(state)?.data?.start_date
    }

    return { initialValues: { data }, formStates: getFormValues('addAvailabilityForm')(state) };

};

AddAvailability = reduxForm({
    form: "addAvailabilityForm",
    // validate,
    enableReinitialize: true,
})(AddAvailability);

AddAvailability = connect(mapStateToProps)(AddAvailability);

export default AddAvailability;