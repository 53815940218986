
export const AuthActionTypes = {
    SIGN_IN: 'SIGN_IN',
    SIGN_IN_PENDING: 'SIGN_IN_PENDING',
    SIGN_IN_FULFILLED: 'SIGN_IN_FULFILLED',

    FORGET_PASSWORD: 'FORGET_PASSWORD',
    FORGET_PASSWORD_PENDING: 'FORGET_PASSWORD_PENDING',
    FORGET_PASSWORD_FULFILLED: 'FORGET_PASSWORD_FULFILLED',

    RESET_PASSWORD: 'RESET_PASSWORD',
    RESET_PASSWORD_PENDING: 'RESET_PASSWORD_PENDING',
    RESET_PASSWORD_FULFILLED: 'RESET_PASSWORD_FULFILLED',

    UPDATE_USER_PASSWORD: 'UPDATE_USER_PASSWORD',
    UPDATE_USER_PASSWORD_PENDING: 'UPDATE_USER_PASSWORD_PENDING',
    UPDATE_USER_PASSWORD_FULFILLED: 'UPDATE_USER_PASSWORD_FULFILLED',

    LOGOUT: 'LOGOUT',
    LOGOUT_PENDING: 'LOGOUT_PENDING',
    LOGOUT_FULFILLED: 'LOGOUT_FULFILLED',

    CHANGE_PASSWORD: 'CHANGE_PASSWORD',
    CHANGE_PASSWORD_PENDING: 'CHANGE_PASSWORD_PENDING',
    CHANGE_PASSWORD_FULFILLED: 'CHANGE_PASSWORD_FULFILLED',
    UPDATE_PROFILE: 'UPDATE_PROFILE',
    UPDATE_PROFILE_PENDING: 'UPDATE_PROFILE_PENDING',
    UPDATE_PROFILE_FULFILLED: 'UPDATE_PROFILE_FULFILLED',

    AUTHORIZE: 'AUTHORIZE',
    UPDATE_USER: 'UPDATE_USER',

    OPEN_PROFILE_MODEL: 'OPEN_PROFILE_MODEL',
    CLOSE_PROFILE_MODEL: 'CLOSE_PROFILE_MODEL',

    OPEN_MODE_MODAL: 'OPEN_MODE_MODAL',
    CLOSE_MODE_MODAL: 'CLOSE_MODE_MODAL',


    GET_PROFILE_PRESIGNED_URL: 'GET_PROFILE_PRESIGNED_URL',
    GET_PROFILE_PRESIGNED_URL_PENDING: 'GET_PROFILE_PRESIGNED_URL_PENDING',
    GET_PROFILE_PRESIGNED_URL_FULFILLED: 'GET_PROFILE_PRESIGNED_URL_FULFILLED',
    

    SEARCH_QUOTE: 'SEARCH_QUOTE',
    SEARCH_QUOTE_PENDING: 'SEARCH_QUOTE_PENDING',
    SEARCH_QUOTE_FULFILLED: 'SEARCH_QUOTE_FULFILLED',

    GET_LATEST_PROFILE: 'GET_LATEST_PROFILE',
    GET_LATEST_PROFILE_PENDING: 'GET_LATEST_PROFILE_PENDING',
    GET_LATEST_PROFILE_FULFILLED: 'GET_LATEST_PROFILE_FULFILLED',

    UPDATE_PREFERENCES: 'UPDATE_PREFERENCES',
    UPDATE_PREFERENCES_PENDING: 'UPDATE_PREFERENCES_PENDING',
    UPDATE_PREFERENCES_FULFILLED: 'UPDATE_PREFERENCES_FULFILLED'
}


