import React, { useEffect, useRef, useState } from 'react'
import withFormHoc from '../../../../../hoc/withReactHookForm/withReactHookForm'
import { Input } from '../../../UI/InputElement/reactFormHookInputField'
import { EDIT, VIEW, routes } from '../../../../../utility/constants/constants'
import { useSelector } from 'react-redux'
import { useWatch } from 'react-hook-form'
import SuccessAlert from '../../../../../utility/successAlert/successAlert'
import AssetsFormSkeleton from '../../../Inventory/Assets/NewAssetsForm/AssetsFormSkeleton'



function NewRequestForm(props) {
    const { isLoading,isUpdateRequestForm,requsetFormDetails } = useSelector((state) => state.TagModuleReducer)
    const { managersList,usersList_with_out_client } = useSelector((state) => state.FormAuditModReducer)
    const err=useRef(props?.errors?.lineItems)
    const trackError=useRef(false)
    const defaultForm = {
        date: null,
        completed: "",
        request: "",
        requester: "",
        given_to: "",
        notes: "",
        lineItems: [],
    }

    

    const checkBoxImagesArray = [{
        name: "complaint",
        url: "/images/thumbnails/complaint.png"
    },
    {
        name: "cleandust",
        url: "/images/thumbnails/cleandust.png"
    },
    {
        name: "supplies",
        url: "/images/thumbnails/supplies.png"
    },
    {
        name: "trashb",
        url: "/images/thumbnails/trashb.png"
    },
    {
        name: "setup",
        url: "/images/thumbnails/setup.png"
    },
    {
        name: "plumbing",
        url: "/images/thumbnails/plumbing.png"
    },
    {
        name: "electrical",
        url: "/images/thumbnails/electrical.png"
    },
    {
        name: "lock",
        url: "/images/thumbnails/lock.png"
    },

    ]

    const watchCheckBoxArray=useWatch({
        control:props.control,
        name:"sections.lineItems"
       })

       const getUsersList = (user) => {
        if (user && user.length > 0) {
          return user && user.map((data) => {
            return {
              value: data.id,
              label: data.first_name + ' ' + data.last_name,
            }
          })
        } else {
          return []
        }
      };

      const [listOfUsers,setListOfUsers]=useState(getUsersList(usersList_with_out_client))

      useEffect(()=>{
        setListOfUsers(getUsersList(usersList_with_out_client))
      },[usersList_with_out_client])

    const handleValidateClick = (data) => {
        const valid = data.some((item) => item.checked)
        if (valid) return true
        else {
            // err.current=true
            return "Please check at least one item"
        }
    }

    props.register(`sections.lineItems`, {
        //type: "custom",
        validate: handleValidateClick,
        message: "please check atleast one item",

    })

    const handleValidateChange=(data)=>{
        
        const valid = data.some((item) => item.checked)
        if (valid) return true
        if(props.errors == undefined){
            return 
          }
        else {
            
             err.current=true
            return "Please check at least one item"
        }
    }

    let checkBoxChecked=handleValidateChange(props.getValues(`sections.lineItems`) ?? [])

    if(isUpdateRequestForm){
        return <AssetsFormSkeleton />
    }

    return (
        <main class="app-content wraper_content chart_section">
            <section class="teammanage_formmain">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="team_manage_head">
                                <h1 class="tm_top_head"> {props?.isEdit ? `${EDIT} Request Form` : props?.isView ? `${VIEW} Request Form` : "Request Form"}</h1>
                            </div>
                        </div>
                        <form class="col-lg-12" noValidate onSubmit={props.handleSubmit((data)=>{
                            
                            props.onSubmit(data)
                        },(errors)=>{
                            trackError.current=true
                        })}>
                            <div class="tm_form_wrapper">


                                <div class="tm_form_wrap" key={props.key}>
                                    <div class="tm_form_innner">
                                        <div class="form">
                                            <div class="row">
                                                <div class="col-md-6 col-sm-12">
                                                    <div class="form-group">
                                                        {/* Date */}
                                                        <Input
                                                            type="date"
                                                            //datePickerType="month"
                                                            formControlClasses="form-control"
                                                            id={`date`}
                                                            placeholder="MM/DD/YYYY"
                                                            control={props.control}
                                                            controller={props.controller}
                                                            selectName={`date`}
                                                            dateFormat="MM/dd/yyyy"
                                                            // minDate={new Date().setDate(current.getDate() - 400)}
                                                            preventReadOnly={false}
                                                            label="Date"
                                                            formControlProps={props.register(`date`, {
                                                                required: "This field is required",
                                                                //   validate:handleValidateDateCompleted
                                                            })}
                                                            validationErrorMessage={props.errors?.date?.message ?? ""}
                                                            disabled={props?.isView}
                                                        />
                                                    </div>
                                                </div>
                                                <div class="col-md-6 col-sm-12">
                                                    <div class="form-group">
                                                        <Input
                                                            type="inputSelect"
                                                            required
                                                            selectLabel="Completed"
                                                            control={props.control}
                                                            controller={props.controller}
                                                            selectName={`completed`}
                                                            id={`completed`}
                                                            placeholder={"Please Select"}
                                                            selectOptions={[{ value: "Yes", label: "Yes" }, { value: "No", label: "No" }]}
                                                            formControlProps={props.register(`completed`, {
                                                                required: "This field is required"
                                                            })}
                                                            selectClasses="form-select"
                                                            validationErrorMessage={props.errors?.completed?.message}
                                                            disabled={props?.isView}

                                                        />
                                                    </div>
                                                </div>
                                                <div class="col-md-6 col-sm-12">
                                                    <div class="form-group mb-0">
                                                        <Input
                                                            type="text"
                                                            id={`request`}
                                                            name={`request`}
                                                            formControlClasses="form-control"
                                                            placeholder="Request"
                                                            required
                                                            label="Request"
                                                            formControlProps={props.register(
                                                                `request`,
                                                                {
                                                                    required: "This field is required",
                                                                    maxLength: 80,
                                                                }
                                                            )}
                                                            validationErrorMessage={
                                                                props.errors?.request
                                                                    ?.message ?? ""
                                                            }
                                                            disabled={props?.isView}
                                                        />
                                                    </div>
                                                </div>
                                                <div class="col-md-6 col-sm-12">
                                                    <div class="form-group ">

                                                    

                                                         <Input
                                                            type="text"
                                                            id={`requester`}
                                                            name={`requester`}
                                                            formControlClasses="form-control"
                                                            placeholder="Requestor"
                                                            required
                                                            label="Requestor"
                                                            formControlProps={props.register(
                                                                `requester`,
                                                                {
                                                                    required: "This field is required",
                                                                    maxLength: 80,
                                                                }
                                                            )}
                                                            validationErrorMessage={
                                                                props.errors?.requester
                                                                    ?.message ?? ""
                                                            }
                                                            disabled={props?.isView}
                                                        />
                                                       
                                                    </div>
                                                </div>

                                                <div class="col-md-6 col-sm-12">
                                                    <div class="form-group mb-0">
                                                    <Input
                                                            type="inputSelect"
                                                            required
                                                            selectLabel="Given To"
                                                            control={props.control}
                                                            controller={props.controller}
                                                            selectName={`given_to_id`}
                                                            id={`given_to_id`}
                                                            placeholder="Given To"
                                                            selectOptions={listOfUsers}
                                                            formControlProps={props.register(
                                                                `given_to_id`,
                                                                {
                                                                    required: "This field is required",
                                                                    maxLength: 80,
                                                                }
                                                            )}
                                                            selectClasses="form-select"
                                                            validationErrorMessage={
                                                                props.errors?.given_to_id
                                                                    ?.message ?? ""
                                                            }
                                                            disabled={props?.isView}

                                                        />
                                                        {/* <Input
                                                            type="text"
                                                            id={`given_to`}
                                                            name={`given_to`}
                                                            formControlClasses="form-control"
                                                            placeholder="Given To"
                                                            required
                                                            label="Given To"
                                                            formControlProps={props.register(
                                                                `given_to`,
                                                                {
                                                                    required: "This field is required",
                                                                    maxLength: 80,
                                                                }
                                                            )}
                                                            validationErrorMessage={
                                                                props.errors?.given_to
                                                                    ?.message ?? ""
                                                            }
                                                            disabled={props?.isView}
                                                        /> */}
                                                    </div>
                                                </div>

                                                <div class="col-md-12 col-sm-12 mt-3">
                                                    <div class="form-group">

                                                        <Input
                                                            type="textarea"
                                                            id={`notes`}
                                                            name={`notes`}
                                                            formControlClasses="form-control"
                                                            placeholder="Type Here"
                                                            required
                                                            label="Notes"
                                                            formControlProps={props.register(
                                                                `notes`,
                                                                {
                                                                    required: "This field is required",
                                                                    maxLength: 80,
                                                                }
                                                            )}
                                                            validationErrorMessage={
                                                                props.errors?.notes
                                                                    ?.message ?? ""
                                                            }
                                                            disabled={props?.isView}
                                                        />


                                                    </div>
                                                </div>





                                                <div class="col-lg-12">
                                                    <div class="team_management_card_wrap">

                                                        <div class="img_chcekbox_wrapper">
                                                            <div class="right_checkbox_wrap flex-grow-1">
                                                                {
                                                                    checkBoxImagesArray.map((item, i) => (


                                                                        <props.controller
                                                                            name={`sections.lineItems[${i}].checked`}
                                                                            control={props.control}
                                                                            render={({ field: { name, ref, onChange, value } }) => {
                                                                                
                                                                                return (
                                                                                    <>
                                                                                        <div class="checkbox_list">
                                                                                            <div
                                                                                                class="cstm_checkbox_wrap_new cstm_checkbox_wrap_new_inline_sm"
                                                                                            >
                                                                                                <label class="cstm_check_container">
                                                                                                    <input type="checkbox"
                                                                                                        disabled={props.isView}
                                                                                                        name={item.name}
                                                                                                        id={name}
                                                                                                        ref={ref}
                                                                                                        checked={Boolean(value)}
                                                                                                        onChange={onChange} />
                                                                                                    <span class="checkmark"></span>
                                                                                                </label>
                                                                                                <div class="left_img_wrap flex-shrink-0">
                                                                                                    <img
                                                                                                        src={item?.url}
                                                                                                        alt="img 1"
                                                                                                    />
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>


                                                                                    </>
                                                                                )
                                                                            }
                                                                            }
                                                                        />



                                                                    ))
                                                                }

                                                            </div>
                                                        </div>
                                                        {
                                                            <p style={{color:"red"}}>{err?.current && trackError.current && checkBoxChecked}</p>
                                                        }
                                                    </div>
                                                </div>






                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-12">
                                        <div class="submitcancel_btn">
                                            <button onClick={() =>  props.resetForm()} type="button" class="btn cst_btn btn-outline-secondary  cancel_btnnew">
                                                Cancel
                                            </button>
                                            {props?.isView ? null : <button type="submit" class={isLoading ? "btn cst_btn btn_danger btn-wait" : "btn cst_btn btn_danger"} >
                                                {isLoading ? "" : props.isEdit ? "Update" : "Submit"}
                                            </button>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        </main>
    )
}

export default withFormHoc(NewRequestForm)
