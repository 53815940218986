import React from 'react';
import { FaArrowDown, FaArrowUp } from "react-icons/fa";
import ReactTable from 'react-table-v6';
import 'react-table-v6/react-table.css';
import '../../../styles/custom.css';
import { convertQuotesReportsToCsvType, convertQuotesData1ToCsvType, convertQuotesData2ToCsvType, convertQuotesData3ToCsvType, convertQuotesData4ToCsvType } from '../../../utility/utility';
import { MagicSpinner, PushSpinner, SwishSpinner, WaveSpinner, ClapSpinner } from 'react-spinners-kit';
import { makeFirstLetterCapital, CustomNoDataComponent } from '../../../utility/utility';
import { CSVLink, CSVDownload } from "react-csv";

const ReportsTable = (props) => {

    const data1 = props.quotesReport.map(q => {
        return {
            account: q.account_name,
            requestorName: q.user,
            totalQuotesCreated: q.total_quotes_created,
            totalQuotesSales: `$` + q.total_sale
        }
    })

    const columns1 = [
        {
            Header: <><span>Job</span><FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
            accessor: 'account', // String-based value accessors!,
            width: 255,
            show: props.quotesReport.length !== 0,
        }, {
            Header: <><span>Requestor Name</span><FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
            accessor: 'requestorName', // String-based value accessors!,
            show: props.quotesReport.length !== 0,
        }, {
            Header: <><span>Total Quotes Created</span><FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
            accessor: 'totalQuotesCreated', // String-based value accessors!,
            show: props.quotesReport.length !== 0,
        }, {
            Header: <><span>Total Quotes Sales</span><FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
            accessor: 'totalQuotesSales', // String-based value accessors!,
            show: props.quotesReport.length !== 0,
        }
    ]

    const data2 = props.quotesReport.map(q => {
        return {
            account: q.account_name,
            requestorName: q.user,
            totalQuotesApprovedByAdmin: q.quotes_approved_by_admin,
            totalQuotesRejectedByAdmin: q.quotes_rejected_by_admin
        }
    })

    const columns2 = [
        {
            Header: <><span>Job</span><FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
            accessor: 'account', // String-based value accessors!,
            width: 255,
            show: props.quotesReport.length !== 0,
        }, {
            Header: <><span>Requestor Name</span><FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
            accessor: 'requestorName', // String-based value accessors!,
            show: props.quotesReport.length !== 0,
        }, {
            Header: <><span>Quotes Approved By Admin</span><FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
            accessor: 'totalQuotesApprovedByAdmin', // String-based value accessors!,
            show: props.quotesReport.length !== 0,
        }, {
            Header: <><span>Quotes Rejected By Admin</span><FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
            accessor: 'totalQuotesRejectedByAdmin', // String-based value accessors!,
            show: props.quotesReport.length !== 0,
        }
    ]

    const data3 = props.quotesReport.map(q => {
        return {
            account: q.account_name,
            requestorName: q.user,
            totalQuotesConfirmedByClinet: q.quotes_confirmed_by_client,
            totalQuotesWaitingForClientApproval: q.quotes_declined_by_client
        }
    })

    const columns3 = [
        {
            Header: <><span>Job</span><FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
            accessor: 'account', // String-based value accessors!,
            width: 255,
            show: props.quotesReport.length !== 0,
        }, {
            Header: <><span>Requestor Name</span><FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
            accessor: 'requestorName', // String-based value accessors!,
            show: props.quotesReport.length !== 0,
            width: 147,
        }, {
            Header: <><span>Quotes Confirmed by Client</span><FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
            accessor: 'totalQuotesConfirmedByClinet', // String-based value accessors!,
            show: props.quotesReport.length !== 0,
            width: 244,
        }, {
            Header: <><span>Quote Waiting For Client Approval</span><FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
            accessor: 'totalQuotesWaitingForClientApproval', // String-based value accessors!,
            show: props.quotesReport.length !== 0,
        }
    ]

    const data4 = props.quotesReport.map(q => {
        return {
            account: q.account_name,
            requestorName: q.user,
            totalApprovedSale: q.confirmed_sales,
            quotesWaitingForApproval: `$` + q.declined_sales
        }
    })

    const columns4 = [
        {
            Header: <><span>Job</span><FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
            accessor: 'account', // String-based value accessors!,
            width: 255,
            show: props.quotesReport.length !== 0,
        }, {
            Header: <><span>Requestor Name</span><FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
            accessor: 'requestorName', // String-based value accessors!,
            show: props.quotesReport.length !== 0,
            width: 188,
        }, {
            Header: <><span>Total Approved Sale</span><FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
            accessor: 'totalApprovedSale', // String-based value accessors!,
            show: props.quotesReport.length !== 0,
            width: 211,
        }, {
            Header: <><span>Quote Waiting For Approval($)</span><FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
            accessor: 'quotesWaitingForApproval', // String-based value accessors!,
            show: props.quotesReport.length !== 0,
        }
    ]

    return (
        props.isLoading ?
            <div className="loader_btn_block">
                <ClapSpinner backColor="#89073c" size={40} frontColor="#89073c8a" />
            </div>
            :
            <>
                <div className="tile chart-box-botL">
                    <div className="tile_ft">
                        <CSVLink style={{}} filename="QuotesReport.csv" className="btn btn_danger cst_btn" data={convertQuotesData1ToCsvType(props.quotesReport)}>Download CSV</CSVLink>
                    </div>
                    <div className="quotes_table_content table-responsive">
                        <div className="table quotes_table user_table_contnet user_react_table">
                            <ReactTable
                                data={data1}
                                // loading={props.isLoading}
                                defaultPageSize={10}
                                minRows={0}
                                // onPageSizeChange={props.onUserPageSizeChange}
                                showPaginationTop={true}
                                showPagination={props.quotesReport.length !== 0}
                                columns={columns1}
                                NoDataComponent={() => CustomNoDataComponent(props.isLoading, 'No Data Found')}
                                getTheadThProps={(state, rowInfo, column, instance) => {
                                    return {
                                        style: {
                                            borderTop: 'none',
                                            borderBottom: 'none',
                                            borderRight: 'none',
                                            textAlign: 'center',
                                            color: '#566975',
                                            fontSize: '14px',
                                            fontWeight: '600',
                                            whiteSpace: 'nowrap',
                                            float: 'left',
                                        }
                                    }
                                }
                                }
                                getTrProps={(state, rowInfo, column, instance) => {
                                    return {
                                        className: 'react-table-tr-element',
                                        style: {
                                            border: '1px solid #c7cdd1',
                                            transition: '.3s ease-in-out',
                                            width: '100%',
                                            marginTop: '5px',
                                            float: 'left'
                                        }
                                    }

                                }}
                                getTdProps={(state, rowInfo, column, instance) => {

                                    if (column.id == 'image') {
                                        return {
                                            style: {
                                                marginTop: '0px'
                                            }
                                        }
                                    } else {
                                        return {
                                            className: 'react-td-element',
                                        }
                                    }
                                }
                                }
                                getProps={() => {
                                    return {
                                        style: {
                                            border: 'none'
                                        }
                                    }
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div className="tile chart-box-botL">
                    <div className="tile_ft">
                        <CSVLink style={{}} filename="QuotesReport.csv" className="btn btn_danger cst_btn" data={convertQuotesData2ToCsvType(props.quotesReport)}>Download CSV</CSVLink>
                    </div>
                    <div className="quotes_table_content table-responsive">
                        <div className="table quotes_table user_table_contnet user_react_table">
                            <ReactTable
                                data={data2}
                                // loading={props.isLoading}
                                defaultPageSize={10}
                                minRows={0}
                                // onPageSizeChange={props.onUserPageSizeChange}
                                showPaginationTop={true}
                                showPagination={props.quotesReport.length !== 0}
                                columns={columns2}
                                NoDataComponent={() => CustomNoDataComponent(props.isLoading, 'No Data Found')}
                                getTheadThProps={(state, rowInfo, column, instance) => {
                                    return {
                                        style: {
                                            borderTop: 'none',
                                            borderBottom: 'none',
                                            borderRight: 'none',
                                            textAlign: 'center',
                                            color: '#566975',
                                            fontSize: '14px',
                                            fontWeight: '600',
                                            whiteSpace: 'nowrap',
                                            float: 'left',
                                        }
                                    }
                                }
                                }
                                getTrProps={(state, rowInfo, column, instance) => {
                                    return {
                                        className: 'react-table-tr-element',
                                        style: {
                                            border: '1px solid #c7cdd1',
                                            transition: '.3s ease-in-out',
                                            width: '100%',
                                            marginTop: '5px',
                                            float: 'left'
                                        }
                                    }

                                }}
                                getTdProps={(state, rowInfo, column, instance) => {

                                    if (column.id == 'image') {
                                        return {
                                            style: {
                                                marginTop: '0px'
                                            }
                                        }
                                    } else {
                                        return {
                                            className: 'react-td-element',
                                        }
                                    }
                                }
                                }
                                getProps={() => {
                                    return {
                                        style: {
                                            border: 'none'
                                        }
                                    }
                                }}
                            />
                        </div>
                    </div>
                </div>

                <div className="tile chart-box-botL">
                    <div className="tile_ft">
                        <CSVLink style={{}} filename="QuotesReport.csv" className="btn btn_danger cst_btn" data={convertQuotesData3ToCsvType(props.quotesReport)}>Download CSV</CSVLink>
                    </div>
                    <div className="quotes_table_content table-responsive">
                        <div className="table quotes_table user_table_contnet user_react_table">
                            <ReactTable
                                data={data3}
                                // loading={props.isLoading}
                                defaultPageSize={10}
                                minRows={0}
                                // onPageSizeChange={props.onUserPageSizeChange}
                                showPaginationTop={true}
                                showPagination={props.quotesReport.length !== 0}
                                columns={columns3}
                                NoDataComponent={() => CustomNoDataComponent(props.isLoading, 'No Data Found')}
                                getTheadThProps={(state, rowInfo, column, instance) => {
                                    return {
                                        style: {
                                            borderTop: 'none',
                                            borderBottom: 'none',
                                            borderRight: 'none',
                                            textAlign: 'center',
                                            color: '#566975',
                                            fontSize: '14px',
                                            fontWeight: '600',
                                            whiteSpace: 'nowrap',
                                            float: 'left',
                                        }
                                    }
                                }
                                }
                                getTrProps={(state, rowInfo, column, instance) => {
                                    return {
                                        className: 'react-table-tr-element',
                                        style: {
                                            border: '1px solid #c7cdd1',
                                            transition: '.3s ease-in-out',
                                            width: '100%',
                                            marginTop: '5px',
                                            float: 'left'
                                        }
                                    }

                                }}
                                getTdProps={(state, rowInfo, column, instance) => {

                                    if (column.id == 'image') {
                                        return {
                                            style: {
                                                marginTop: '0px'
                                            }
                                        }
                                    } else {
                                        return {
                                            className: 'react-td-element',
                                        }
                                    }
                                }
                                }
                                getProps={() => {
                                    return {
                                        style: {
                                            border: 'none'
                                        }
                                    }
                                }}
                            />
                        </div>
                    </div>
                </div>

                <div className="tile chart-box-botL">
                    <div className="tile_ft">
                        <CSVLink style={{}} filename="QuotesReport.csv" className="btn btn_danger cst_btn" data={convertQuotesData4ToCsvType(props.quotesReport)}>Download CSV</CSVLink>
                    </div>
                    <div className="quotes_table_content table-responsive">
                        <div className="table quotes_table user_table_contnet user_react_table">
                            <ReactTable
                                data={data4}
                                // loading={props.isLoading}
                                defaultPageSize={10}
                                minRows={0}
                                // onPageSizeChange={props.onUserPageSizeChange}
                                showPaginationTop={true}
                                showPagination={props.quotesReport.length !== 0}
                                columns={columns4}
                                NoDataComponent={() => CustomNoDataComponent(props.isLoading, 'No Data Found')}
                                getTheadThProps={(state, rowInfo, column, instance) => {
                                    return {
                                        style: {
                                            borderTop: 'none',
                                            borderBottom: 'none',
                                            borderRight: 'none',
                                            textAlign: 'center',
                                            color: '#566975',
                                            fontSize: '14px',
                                            fontWeight: '600',
                                            whiteSpace: 'nowrap',
                                            float: 'left',
                                        }
                                    }
                                }
                                }
                                getTrProps={(state, rowInfo, column, instance) => {
                                    return {
                                        className: 'react-table-tr-element',
                                        style: {
                                            border: '1px solid #c7cdd1',
                                            transition: '.3s ease-in-out',
                                            width: '100%',
                                            marginTop: '5px',
                                            float: 'left'
                                        }
                                    }

                                }}
                                getTdProps={(state, rowInfo, column, instance) => {

                                    if (column.id == 'image') {
                                        return {
                                            style: {
                                                marginTop: '0px'
                                            }
                                        }
                                    } else {
                                        return {
                                            className: 'react-td-element',
                                        }
                                    }
                                }
                                }
                                getProps={() => {
                                    return {
                                        style: {
                                            border: 'none'
                                        }
                                    }
                                }}
                            />
                        </div>
                    </div>
                </div>
            </>
    )
}

export default ReportsTable;