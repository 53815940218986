import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Input } from '../../UI/InputElement/InputElement';
import { connect } from 'react-redux';
import { RatingTypes, PortfolioProperties, routes, userRoles } from '../../../../utility/constants/constants';
import isEmpty from '../../../../utility/IsEmptyValidator';
import * as actions from '../../../../redux/actions/index';
import { reduxForm, FieldArray, formValueSelector, getFormValues, change } from 'redux-form';
import { performInspectionValidator as validate } from '../../../../utility/validator/validator';
import { MagicSpinner, PushSpinner, SwishSpinner, WaveSpinner, ClapSpinner } from 'react-spinners-kit';
import ModalImageGallery from '../../ModalImageGallery/modalImageGallery';
import NewInspectionQuote from '../../../container/QuotesContainer/NewQuoteContainer/NewQuoteContainer';
import * as Scroll from 'react-scroll';
import { Link, Element, Events, animateScroll as scroll } from 'react-scroll';
import { showConfirmAlert } from '../../../../utility/successAlert/ConfirmAlert';
import storage from '../../../../utility/storage';
import ImageLoader from 'react-imageloader';
import { Prompt } from 'react-router-dom';
import SignaturePad from './signaturePad/signaturePad';
import OpenNewTicketModal from '../../Ticket/NewTicket/openNewTicketModal';
import NewTicketContainer from '../../../container/TicketContainer/NewTicektContainer/NewTicketContainer';
import '../../../../styles/custom.css';
const cloneDeep = require('clone-deep');
var scroller = Scroll.scroller;


const lineItemAttributes = (line, lineIndex, sectionIndex, portfolioImages,
    onPortfolioFileChange, references, choosePhotoClicked, imageLoading, removeImage,
    removeUrls, updatedLineAttribute, imageClicked, newInspectionQuoteClicked, newInspectionTicketClicked,
    signaturePadClicked, section_na, isSignatureEmpty, section, props , loaderIndex, setLoaderIndex, dispatch) => {
    const ref = references[`inputOpenFileRef${sectionIndex}${lineIndex}`] ? references[`inputOpenFileRef${sectionIndex}${lineIndex}`] : null;
    const RatingOptionAttributes = line.rating_type.rating_options_attributes.map(ro => {
        return {
            id: ro.id,
            name: ro.name
        }
    });
    const user = storage.get('user');

    function preloader() {
        return <img style={{ width: '100%', height: '100%' }} src="/images/gif/giphy.gif" />;
    }

    const nothingButtonClicked = (e, seci, linei) => {
        setTimeout(() => {
            if(props.formStates.inspection.inspection_form.sections_attributes[seci].line_items_attributes[linei].comment === null) {
                dispatch(change('performInspectionForm', `inspection[inspection_form][sections_attributes][${seci}][line_items_attributes][${linei}][comment]`, ' '))
            } else {
                dispatch(change('performInspectionForm', `inspection[inspection_form][sections_attributes][${seci}][line_items_attributes][${linei}][comment]`, props.formStates.inspection.inspection_form.sections_attributes[seci].line_items_attributes[linei].comment + ' '))
            }
        }, 1000);
    }
    const isNameTrue = line.rating_type.category === RatingTypes.Text && (RatingOptionAttributes && RatingOptionAttributes[0] ? RatingOptionAttributes[0].name : null) === 'Name';
    const isSignatureTrue = line.rating_type.category === RatingTypes.Percentage && (RatingOptionAttributes && RatingOptionAttributes[0] ? RatingOptionAttributes[0].name : null) === 'Signature';

    return (
        <Element className="ins_detail_block" key={`${sectionIndex}${lineIndex}`} name={`inspection[inspection_form][sections_attributes][${sectionIndex}][line_items_attributes][${lineIndex}][comment]`}>
            {
                isNameTrue || isSignatureTrue ?
                    null :
                    updatedLineAttribute && updatedLineAttribute.n_a ? null :
                    
                        line.quote_created_for_inspection && !line.ticket_created_for_inspection ?
                            <div className="create_quote_text">
                                <h5 className="after_popup_text">Quote Created</h5>
                                <button type="button" style={{ float: 'right' }} className="btn danger_light_btn" data-toggle="modal" data-target="#creatTicket" onClick={() => newInspectionTicketClicked(line.id)}>Create Ticket</button>
                            </div>
                            : null
                            
            }
            {
                isNameTrue || isSignatureTrue ?
                    null :
                    updatedLineAttribute && updatedLineAttribute.n_a ? null :
                    
                    !line.quote_created_for_inspection && line.ticket_created_for_inspection ?
                    <div className="create_quote_text">
                    { user && user.role === userRoles.REQUESTOR ? null :
                    <button type="button" className="btn btn_danger" data-toggle="modal" data-target="#addnew_qoutes" onClick={() => newInspectionQuoteClicked(line.id)}>Create Quote</button>
                    }
                    <h5 className="after_popup_text" style={{ float: 'right' }}>Ticket Created</h5>
                </div>
                            : null
                            
            }
            {
                isNameTrue || isSignatureTrue ?
                    null :
                    updatedLineAttribute && updatedLineAttribute.n_a ? null :
                    
                    line.quote_created_for_inspection && line.ticket_created_for_inspection ?
                            <div className="create_quote_text">
                                <h5 className="after_popup_text">Quote Created</h5>
                                <h5 className="after_popup_text" style={{ float: 'right' }}>Ticket Created</h5>
                            </div>
                            : null
                            
            }
            {
                isNameTrue || isSignatureTrue ?
                    null :
                    updatedLineAttribute && updatedLineAttribute.n_a ? null :
                    
                    !line.quote_created_for_inspection && !line.ticket_created_for_inspection ?
                    <>{props.inspection.active_status === 'pending' ? '' : <div className="create_quote_btn">
                    { user && user.role === userRoles.REQUESTOR ? null :
                    <button type="button" className="btn btn_danger" data-toggle="modal" data-target="#addnew_qoutes" onClick={() => newInspectionQuoteClicked(line.id)}>Create Quote</button>
                    }
                    {/* {lineIndex === 0 ? */}
                    <button type="button" style={{ float: 'right' }} className="btn danger_light_btn" data-toggle="modal" data-target="#creatTicket" onClick={() => newInspectionTicketClicked(line.id)}>Create Ticket</button>
                    {/* : null} */}
                    </div>}</>
                            : null
                            
            }
            <div className="row">
                <div className="col-8">
                    <h6 className="h6_title">{line.name}</h6>
                </div>
                {isNameTrue ?
                    <div className="col-12">
                        <div className="checkbox_block radius_checkbox text-right">
                            <div className="custom-control custom-checkbox" >
                                <Input type="checkbox" name={`inspection[inspection_form][sections_attributes][${sectionIndex}][line_items_attributes][${lineIndex}][customer_rep_available]`} className="custom-control-input" id={`customCheck${sectionIndex}${lineIndex}`} />
                                <label className="custom-control-label"  for={`customCheck${sectionIndex}${lineIndex}`}>Customer Representative not present during inspection</label>
                            </div>
                            {/* onClick={(e) => props.customerContactInput(e, sectionIndex, lineIndex, 'checkbox')} */}
                        </div>
                    </div>
                    : isSignatureTrue || section.name.toLowerCase().includes('critical three items') ? null :
                        <div className="col-4">
                            <div className="checkbox_block radius_checkbox text-right">
                                <div className="custom-control custom-checkbox">
                                    {props.inspection.active_status === 'pending' ? <Input type="checkbox" disabled name={`inspection[inspection_form][sections_attributes][${sectionIndex}][line_items_attributes][${lineIndex}][n_a]`} className="custom-control-input" id={`customCheck${sectionIndex}${lineIndex}`} /> :
                                    <Input type="checkbox" name={`inspection[inspection_form][sections_attributes][${sectionIndex}][line_items_attributes][${lineIndex}][n_a]`} className="custom-control-input" id={`customCheck${sectionIndex}${lineIndex}`} />}
                                    <label data-test="card_body_n/a_checkbox" className="custom-control-label" for={`customCheck${sectionIndex}${lineIndex}`}>N/A</label>
                                </div>
                            </div>
                        </div>
                }
            </div>
            {isSignatureTrue ? null :
                line.rating_type.category === RatingTypes.Text ? null :
                <>{
                    props.inspection.active_status === 'pending' ? <Input dataTest="inspection_rating" disabled name={`inspection[inspection_form][sections_attributes][${sectionIndex}][line_items_attributes][${lineIndex}][rating_option_id]`} type="select" className="custom-select bg_gray select_modifier" options={RatingOptionAttributes} />
                    : <Input dataTest="inspection_rating" disabled={section_na ? section_na : updatedLineAttribute ? updatedLineAttribute.n_a : false} name={`inspection[inspection_form][sections_attributes][${sectionIndex}][line_items_attributes][${lineIndex}][rating_option_id]`} type="select" className="custom-select bg_gray select_modifier" options={RatingOptionAttributes} />
                }
                </>                    
            }
            {/* <select className="custom-select bg_gray select_modifier">
                <option selected>pass</option>
                <option value="1">Meets Expectation</option>
            </select> */}
            {isSignatureTrue && line.attachments_attributes.length === 0 &&
                portfolioImages.inspection_form && portfolioImages.inspection_form.sections_attributes[sectionIndex].
                    line_items_attributes[lineIndex].portfolioImages.imageName.length === 0
                ?
                <div className="card bg_gray mt-0 mb-0">
                    <div className="card-body ins_card_body">
                        <div hidden={section_na ? section_na : updatedLineAttribute ? updatedLineAttribute.n_a : false} className="upload_file_block text-right" key={`${sectionIndex}${lineIndex}`} onClick={() => signaturePadClicked(sectionIndex, lineIndex)}>
                            <div id={`file-upload-wrapper${sectionIndex}${lineIndex}`} className="file-upload-wrapper" data-toggle="modal" data-target="#addnew_signature" data-text="Click here to add your signature.">
                                {/* <Input type="file" name={`attachment_paths${sectionIndex}${lineIndex}`} hidden={true} id={`${sectionIndex}${lineIndex}`} className="file-upload-field" multiple={true} onSelect={(e) => onPortfolioFileChange(e, sectionIndex, lineIndex)} fileRef={ref} accept="image/*" /> */}
                                {/* <input name="file-upload-field" type="file"  value="" /> */}
                            </div>
                        </div>
                    </div>
                </div>
                : isSignatureTrue ? null :
                    <div className="card bg_gray mt-0 mb-0">
                        <div className="card-body ins_card_body">

                            <div className="form-group dolo">
                                {isNameTrue ?
                                    <Input dataTest="inspection_rating_comment" disabled={updatedLineAttribute ? updatedLineAttribute.customer_rep_available : false} type="textarea" name={`inspection[inspection_form][sections_attributes][${sectionIndex}][line_items_attributes][${lineIndex}][comment]`} className="form-control textarea_modifier" id="exampleFormControlTextarea10" rows="3"
                                     placeholder={line.name.toLowerCase().includes('customer contact') ? "Enter Customer Name only" : "Type your comment"} onChange={(e) => {props.customerContactInput(e, sectionIndex, lineIndex, 'input')}}/>
                                    : isSignatureTrue ?
                                        null
                                        :
                                        <>{props.inspection.active_status === 'pending' ? <Input dataTest="inspection_rating_comment" disabled type="textarea" name={`inspection[inspection_form][sections_attributes][${sectionIndex}][line_items_attributes][${lineIndex}][comment]`} className="form-control textarea_modifier" id="exampleFormControlTextarea1" rows="3" placeholder={line.name.toLowerCase().includes('customer contact') ? "Enter Customer Name only" : "Type your comment"} onChange={(e) => {props.customerContactInput(e, sectionIndex, lineIndex, 'input')}} /> : 
                                        <Input dataTest="inspection_rating_comment" disabled={section_na ? section_na : updatedLineAttribute ? updatedLineAttribute.n_a : false} type="textarea" name={`inspection[inspection_form][sections_attributes][${sectionIndex}][line_items_attributes][${lineIndex}][comment]`} className="form-control textarea_modifier" id="exampleFormControlTextarea1" rows="3" placeholder={line.name.toLowerCase().includes('customer contact') ? "Enter Customer Name only" : "Type your comment"} onChange={(e) => {props.customerContactInput(e, sectionIndex, lineIndex, 'input')}} />}</>
                                        
                                }
                            </div>
                            {isNameTrue ? null :
                                <>{props.inspection.active_status === 'pending' ? '' : <div hidden={section_na ? section_na : updatedLineAttribute ? updatedLineAttribute.n_a : false} className="upload_file_block text-right remove_block" onClick={() => choosePhotoClicked(sectionIndex, lineIndex)} key={`${sectionIndex}${lineIndex}`} >
                                    <div id={`file-upload-wrapper${sectionIndex}${lineIndex}`} className="file-upload-wrapper inline_block" data-text="Photo attachments.">
                                        {/* upto 10MB each */}
                                        <Input type="file" disabled={imageLoading} name={`attachment_paths${sectionIndex}${lineIndex}`} hidden={true} id={`${sectionIndex}${lineIndex}`} className="file-upload-field" multiple={true} onSelect={(e) => {setLoaderIndex(`${sectionIndex}${lineIndex}`);onPortfolioFileChange(e, sectionIndex, lineIndex)}} fileRef={ref} accept="image/*" />                                        {/* <input name="file-upload-field" type="file"  value="" /> */}
                                        <button style={{display: 'none'}} onClick={(e) => nothingButtonClicked(e, sectionIndex, lineIndex)} id='inspectionNothingButton'></button>
                                    </div>
                                </div>}
                                </>
                            }
                        </div>
                    </div>
            }
            <div className="user_activity_conent">
                <div className="attachment_sec">
                    <div className="attachments_files">
                        {isSignatureTrue && isSignatureEmpty ? <span style={{ color: '#DD2726', fontSize: '13px' }}>{'Please add the Signature to continue'}</span> :
                            line.attachments_attributes.map((url, i) => (
                                // portfolioImages.inspection_form && portfolioImages.inspection_form.sections_attributes[sectionIndex].line_items_attributes[lineIndex].portfolioImages && portfolioImages.inspection_form.sections_attributes[sectionIndex].line_items_attributes[lineIndex].portfolioImages.base64.map((url, i) => (
                                url.photo_urls ?
                                    <div className="attachments__file_box">
                                        <div onClick={() => imageClicked(line.full_attachment_url, line.attachments_attributes, i)}>
                                            <ImageLoader
                                                style={{ width: '100%', height: '82px', cursor: 'pointer' }}
                                                src={url.photo_urls.small}
                                                wrapper={React.createFactory('div')}
                                                preloader={preloader}>
                                                Image load failed!
                                    </ImageLoader>
                                        </div>
                                        {/* <img src={url.photo_urls.medium} alt="Image" /> */}
                                        {props.inspection.active_status === 'pending' ? '' : <div className="cancel_icon">
                                            <img src="/images/icons/icn_delete_white.png" onClick={() => removeUrls(url, i, lineIndex, sectionIndex)} alt="Image" />
                                        </div>}
                                    </div>
                                    : null
                            ))}

                            {line.full_attachment_url ?

                                line.full_attachment_url.map((url, i) => {
                                    return (<div className="attachments__file_box">
                                        <img src={url} alt="Image" style={{ width: '100%', height: '82px', cursor: 'pointer' }} onClick={() => imageClicked(line.full_attachment_url, line.attachments_attributes, i)} />
                                        <div className="cancel_icon">
                                            <img src="/images/icons/icn_delete_white.png" onClick={() => removeImage(url, i, lineIndex, sectionIndex)} alt="Image" />
                                        </div>
                                    </div>)
                                })
                                :
                                null
                        }

                        {imageLoading && portfolioImages.inspection_form && `${sectionIndex}${lineIndex}` === loaderIndex
                            // && !line.attachment_paths
                            ?
                            portfolioImages.inspection_form.sections_attributes[sectionIndex].line_items_attributes[lineIndex].portfolioImages.imageName.map((p, i) => {
                                return (<div className="attachments__file_box">
                                    <img src={'/images/gif/giphy.gif'} alt="Image" />
                                    <div className="cancel_icon">
                                        {/* <img src="/images/icons/icn_delete_white.png" alt="Image" /> */}
                                    </div>
                                </div>)
                            })
                            : '' }

                    </div>
                </div>
            </div>
        </Element >
    )
}

const sectionAttributes = (section, sectionIndex, portfolioImages, onPortfolioFileChange, references,
    choosePhotoClicked, imageLoading, removeImage, removeUrls, UpdatedSectionAttribute, imageClicked,
    newInspectionQuoteClicked, newInspectionTicketClicked, signaturePadClicked, dispatch, isSignatureEmpty, props, loaderIndex, setLoaderIndex ) => {
    //  
    const sectionNaClicked = (id) => {
        if (UpdatedSectionAttribute) {

            if (!UpdatedSectionAttribute.n_a || UpdatedSectionAttribute.n_a === false) {
                UpdatedSectionAttribute.line_items_attributes.forEach((line, i) => {

                    dispatch(change('performInspectionForm', `inspection[inspection_form][sections_attributes][${sectionIndex}][line_items_attributes][${i}][n_a]`, true))
                })
            } else {
                UpdatedSectionAttribute.line_items_attributes.forEach((line, i) => {

                    dispatch(change('performInspectionForm', `inspection[inspection_form][sections_attributes][${sectionIndex}][line_items_attributes][${i}][n_a]`, false))
                })
            }
        }
        //  

    }
    let isNameTrue = false;
    if (section.line_items_attributes.some((line) => line.rating_type.category === RatingTypes.Text && line.rating_type.rating_options_attributes.some((r) => r.name === 'Name'))) {
        isNameTrue = true;
    }
    let isSignatureTrue = false;
    if (section.line_items_attributes.some((line) => line.rating_type.category === RatingTypes.Percentage && line.rating_type.rating_options_attributes.some((r) => r.name === 'Signature'))) {
        isSignatureTrue = true;
    }
    return (
        <div className="card mb-0">
            <div className="card-body ins_card_body">
                <div className="ins_form_btn">
                    <button type="button" className="btn cst_btn btn_danger">{section.name}</button>
                </div>
                {isNameTrue || isSignatureTrue || section.name.toLowerCase().includes('critical three items') ? null :
                    <div class="na_checkbox" key={sectionIndex}>
                        <div class="checkbox_block radius_checkbox text-right">
                            <div class="custom-control custom-checkbox">
                                {props.inspection.active_status === 'pending' ? <Input type="checkbox" disabled handleChange={() => sectionNaClicked(section.id)} name={`inspection[inspection_form][sections_attributes][${sectionIndex}][n_a]`} className="custom-control-input" id={`naCheck1${sectionIndex}`} /> : 
                                <Input type="checkbox" handleChange={() => sectionNaClicked(section.id)} name={`inspection[inspection_form][sections_attributes][${sectionIndex}][n_a]`} className="custom-control-input" id={`naCheck1${sectionIndex}`} />}
                                <label data-test="inspection_n/a_checkbox" className="custom-control-label" for={`naCheck1${sectionIndex}`}>N/A</label>
                            </div>
                        </div>
                    </div>
                }
                {section.line_items_attributes.map((line, lineIndex) => (
                    lineItemAttributes(line, lineIndex, sectionIndex, portfolioImages, onPortfolioFileChange, references,
                        choosePhotoClicked, imageLoading, removeImage, removeUrls,
                        UpdatedSectionAttribute ? UpdatedSectionAttribute.line_items_attributes[lineIndex] : {}, imageClicked,
                        newInspectionQuoteClicked, newInspectionTicketClicked, signaturePadClicked, UpdatedSectionAttribute ? UpdatedSectionAttribute.n_a : false,
                        isSignatureEmpty, section, props, loaderIndex, setLoaderIndex,dispatch )
                ))}

            </div>
        </div>
    )
}

let PerformInspection = (props) => {

    let inspectionFormValues = props.formStates && props.formStates.inspection
    const [loaderIndex, setLoaderIndex] = useState('')
    useEffect (() => {
        const valueArray = [];
        if (props.formStates && props.formStates.inspection) {
            props.formStates.inspection.inspection_form.sections_attributes.map(section => {
                section.line_items_attributes.map(lineAttribute => {
                    if(lineAttribute.name.toLowerCase().includes('customer contact')) {
                        if (lineAttribute.comment && lineAttribute.comment !== ' ' && lineAttribute.comment.length > 0 && lineAttribute.customer_rep_available === false) {
                            valueArray.push('yes');
                        }
                    }
                })
            })
            if (valueArray.includes('yes')) {
                setIsqqvEnabled(true);
            } else {
                setIsqqvEnabled(false);
            }
        }
    }, [props.formStates]) 
    
    useEffect (() => {
        const valueArray = [];
        if (props.formStates && props.formStates.inspection) {
            props.formStates.inspection.inspection_form.sections_attributes.map(section => {
                section.line_items_attributes.map(lineAttribute => {
                    if(lineAttribute.name.toLowerCase().includes('customer contact')) {
                        if ( lineAttribute.customer_rep_available == false) {
                            valueArray.push('yes');
                        }
                    }
                })
            })
            if (valueArray.includes('yes')) {
                setIscustomerEnabled(true);
            } else {
                setIscustomerEnabled(false);
            }
        }
    }, [props.formStates]) 

    useEffect (() => {
        if(props.inspection.feedback && props.inspection.feedback.answer) {
            setQuiz1(parseInt(props.inspection.feedback.answer[0] && props.inspection.feedback.answer[0].answer ? props.inspection.feedback.answer[0].answer : ''));
            setQuiz2(props.inspection.feedback.answer[1] && props.inspection.feedback.answer[1].answer ? props.inspection.feedback.answer[1].answer : '');
            setQuiz3(props.inspection.feedback.answer[2] && props.inspection.feedback.answer[2].answer ? props.inspection.feedback.answer[2].answer : '');
        }
    }, [props.inspection]) 
    //  

    const dispatch = useDispatch();
    //  
    const openQuotesModal = useSelector(state => state.adminOrSuperAdminReducer.openQuotesModal);
    const openTicketModal = useSelector(state => state.adminOrSuperAdminReducer.openTicketModal);
    const sectionId = useSelector(state => state.adminOrSuperAdminReducer.sectionId);

    const openSignaturePad = useSelector(state => state.adminOrSuperAdminReducer.openSignaturePad);
    const lineItemId = useSelector(state => state.adminOrSuperAdminReducer.lineItemId);

    const [isImageGallery, setIsImageGallery] = useState(false);
    const [startIndex, setStartIndex] = useState(0);
    const [photoUrls, setPhotoUrls] = useState([]);
    const [base64, setBase64] = useState([]);
    const [isBlocking, setIsBlocking] = useState(true);
    const [quiz1, setQuiz1] = useState(null);
    const [quiz2, setQuiz2] = useState('');
    const [quiz3, setQuiz3] = useState('');
    const [quiz6, setQuiz6] = useState(''); 
    const [quiz7a, setQuiz7a] = useState('');
    const [quiz7b, setQuiz7b] = useState('');
    const [quiz8a, setQuiz8a] = useState('');
    const [quiz8b, setQuiz8b] = useState('');
    const [isqqvEnabled, setIsqqvEnabled] = useState(true);
    const [iscustomerEnabled, setIscustomerEnabled] = useState(true);

    // const clearQuizForms = () => {
    //     setQuiz1(null);
    //     setQuiz2('');
    //     setQuiz3('');
    //     setQuiz4('');
    // }

    let options =  props.inspection.inspection_form.sections_attributes.map((section, index) => {
                return {
                    id: section.id,
                    name: section.name
                }
            })

    const signaturePadClicked = (secIndex, lineIndex) => {
        const signatureData = { secIndex, lineIndex }

        dispatch(actions.signaturePadClicked(signatureData));
    }

    const newInspectionTicketClicked = (id) => {
        dispatch(actions.newInspectionTicketClicked(id));
        dispatch(actions.removeSignedUrl())
        
    }

    const newInspectionQuoteClicked = (id) => {

        dispatch(actions.newInspectionQuoteClicked(id));
    }
    const { handleSubmit, pristine, reset, submitting, error, portfolioImages, onPortfolioFileChange, onSignatureAdded, references, notesChoosePhotoClicked, choosePhotoClicked, imageLoading, removeImage, removeUrls, removeNotesImage, removeNotesUrls } = props;
    //  


    if (!props.inspection.inspection_form) {
        return (
            <div className="loader_btn_block" >
                <ClapSpinner backColor="#89073c" size={40} frontColor="#89073c8a" />
            </div>
        )
    } else {
        //  
        const imageClicked = (base64, urls, i) => {

            setIsImageGallery(true);
            setStartIndex(i);
            setPhotoUrls(urls);
            setBase64(base64);
        }

        // if (isImageGallery) {
        //     return <ModalImageGallery
        //         isImageGallery={isImageGallery}
        //         setIsImageGallery={setIsImageGallery}
        //         startIndex={startIndex}
        //         base64={base64}
        //         imageUrls={photoUrls}
        //     />
        // } else {

        const cancelInspectionClicked = () => {
            storage.remove('IdsToDelete');
            storage.remove('performInspectionData');
            props.makeCancelInspectionStateFalse();
            props.history.push(routes.INSPECTIONS);
        }

        const blockMessage = (e) => {
            // if (props.cancelClicked && !props.isLoading) {
            //     showConfirmAlert("Are you sure?", 'Your changes are not saved. Do you still want to leave?', () => {
            //         cancelInspectionClicked()
            //         props.history.push(e.pathname);
            //     })
            //     return false
            // }

            return true
        }

        let show_qqv_button = false;
        props.inspection.inspection_form.sections_attributes.forEach(section => {
            section.line_items_attributes.forEach(line => {
                if (line.name.toLowerCase().includes('customer contact') && line.comment && line.comment !== "" && line.comment !== " " && line.customer_rep_available !== true) {
                    show_qqv_button = true;
                }
            })
        })

    function preloader() {
        return <img style={{ width: '100%', height: '100%' }} src="/images/gif/giphy.gif" />;
    }

        return (
            <main className="app-content wraper_content edit_inspection">
                {isImageGallery ? <ModalImageGallery
                    isImageGallery={isImageGallery}
                    setIsImageGallery={setIsImageGallery}
                    startIndex={startIndex}
                    base64={base64}
                    imageUrls={photoUrls} />
                    : null}
                {props.isDataChanged && <Prompt
                    when={isBlocking}
                    message={blockMessage}
                />}
                {openSignaturePad ? <SignaturePad onSignatureAdded={onSignatureAdded} /> : null}

                {openQuotesModal ?
                    <NewInspectionQuote
                        history={props.history}
                        lineItemId={lineItemId}
                    />
                    : null}

                {openTicketModal ?
                    <NewTicketContainer
                        match={props.match}
                        history={props.history}
                        sectionId={sectionId}
                        lineItemId={lineItemId}
                    />
                    : null}

                <div className="tickets_user_profile_body">
                    <form onSubmit={handleSubmit}  id="searchForm">
                        <section className="appcontent_Inner">
                       
                        {/* {props.customerPresent && !props.inspection.feedback_status ? <div class="qqa_btn_blc">             
                            <button type="button" disabled={props.customerPresent && !props.inspection.feedback_status} class="btn danger_light_btn" data-toggle="modal" data-target="#performQQV">Perform QQV</button>
                        </div> : ''} */}
                        {props.mode.mode === 'internal' ? '' : <div class="qqa_btn_blc d-flex align-items-center justify-content-lg-between">   
                        <div className="app-search">
                        <div class="row">
                        <div class="input-group col">
                        <Input className="custom-select input-modifier add_user_select"  type="select" options={options} name="sectionSelect" />
                            <span class="input-group-append">
                                <button class="btn danger_light_btn cursor-pointer" type="submit" >
                                    <i class="fa fa-search"></i>
                                </button>
                            </span>
                            <span class="input-group-append" style={{paddingLeft: '10px'}}>
                                <button class="btn danger_light_btn cursor-pointer" onClick={() => {props.resetSearchForm()}} type="button">
                                    Reset
                                </button>
                            </span>
                             <h4>{props.totalCount}</h4>
                        </div>
                    </div>
                    </div>          
                            <button type="button" onClick={() => props.getCustomerOrInspectorQqvQuestions({type: 'feedback'})} disabled={props.mode.mode === 'internal' || !isqqvEnabled || (props.inspection.feedback_status === true && props.inspection.active_status === 'pending')} style={{fontSize: '20px'}} class="btn danger_light_btn" data-toggle="modal" data-target="#performQQV">Customer Feedback</button>
                        </div>}
                        <div class="modal fade perform_qqv" id="performQQV" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                            <div class="modal-dialog modal-dialog-centered" role="document">
                                <div class="modal-content">                     
                                        <div class="modal-body">
                                            <h3 class="modal_title">Do you want to "Enter Customer Feedback"?</h3>
                                            <div class="btn_block mb-0">
                                                <button class="btn cst_btn btn-secondary" data-dismiss="modal" aria-label="Close">Skip</button>
                                                <button class="btn cst_btn btn_danger" data-dismiss="modal" data-toggle="modal" data-target="#webModalone">Yes</button>
                                            </div>
                                        </div>                        
                                </div>
                            </div>
                        </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card edit_ins_content ins_detail">
                                        <div className="card_header d_flex">
                                            <h5 className="sm_title">{props.inspection.inspection_form.name}</h5>
                                            {/* <div className="dropdown cst_dropdown">
                                                    <a href="javascript:void(0)" className="dropdown-toggle" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></a>
                                                    <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                                                        <a className="dropdown-item" href="#">Action</a>
                                                        <a className="dropdown-item" href="#">Another action</a>
                                                        <a className="dropdown-item" href="#">Something else here</a>
                                                    </div>
                                                </div> */}
                                        </div>
                                        <div className="card-body inner_card ins_card_body">

                                            {props.inspection.inspection_form.sections_attributes.map((section, index) => {
                                                if (props.NameTypeSection.isCustomerName && props.NameTypeSection.isFilled) {
                                                    return (sectionAttributes(section, index, portfolioImages,
                                                        onPortfolioFileChange, references, choosePhotoClicked, imageLoading, removeImage, removeUrls,
                                                        props.formStates && props.formStates.inspection.inspection_form.sections_attributes[index],
                                                        imageClicked, newInspectionQuoteClicked, newInspectionTicketClicked, signaturePadClicked, dispatch, props.isSignatureEmpty, props, loaderIndex, setLoaderIndex))
                                                } else if (props.NameTypeSection.isCustomerName && props.NameTypeSection.secIndex === index) {
                                                    return (sectionAttributes(section, index, portfolioImages,
                                                        onPortfolioFileChange, references, choosePhotoClicked, imageLoading, removeImage, removeUrls,
                                                        props.formStates && props.formStates.inspection.inspection_form.sections_attributes[index],
                                                        imageClicked, newInspectionQuoteClicked, newInspectionTicketClicked, signaturePadClicked, dispatch, props.isSignatureEmpty, props, loaderIndex, setLoaderIndex))
                                                } else if (!props.NameTypeSection.isCustomerName) {
                                                    return (sectionAttributes(section, index, portfolioImages,
                                                        onPortfolioFileChange, references, choosePhotoClicked, imageLoading, removeImage, removeUrls,
                                                        props.formStates && props.formStates.inspection.inspection_form.sections_attributes[index],
                                                        imageClicked, newInspectionQuoteClicked, newInspectionTicketClicked, signaturePadClicked, dispatch, props.isSignatureEmpty, props, loaderIndex, setLoaderIndex))
                                                }
                                            })}

                                        </div>
                                        {props.inspection.active_status === 'pending' ? '' : <div class="card-body inner_card ins_card_body">
                                            <div className="card mb-0">
                                                <div className="card-body ins_card_body">
                                                <h6>Notes:</h6>
                                                    <div className="card-body inner_card ins_card_body">
                                                        <Input type="textarea" name={`inspection[review_notes]`} className="form-control textarea_modifier" id="exampleFormControlTextarea10" rows="3"
                                                            placeholder="Type your notes" />           
                                                            <div className="upload_file_block text-right remove_block" key={`500`} onClick={() => notesChoosePhotoClicked()}>
                                                                <div className="file-upload-wrapper inline_block" data-text="Photo attachments." >
                                                                    <Input type="file" disabled={imageLoading} name={`attachment_paths`} hidden={true} className="file-upload-field" multiple={true} onSelect={(e) => {setLoaderIndex(null);onPortfolioFileChange(e)}} fileRef={props.refs} accept="image/*" />
                                                                    {/* <button style={{display: 'none'}} onClick={(e) => nothingButtonClicked(e)} id='inspectionNothingButton'></button> */}
                                                                </div>
                                                            </div>                                 
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="user_activity_conent">
                                                <div className="attachment_sec">
                                                    <div className="attachments_files">
                                                        {
                                                            props.inspection.attachments && props.inspection.attachments.map((url, i) => {
                                                                return (
                                                                // portfolioImages.inspection_form && portfolioImages.inspection_form.sections_attributes[sectionIndex].line_items_attributes[lineIndex].portfolioImages && portfolioImages.inspection_form.sections_attributes[sectionIndex].line_items_attributes[lineIndex].portfolioImages.base64.map((url, i) => (
                                                                url.photo_urls ?
                                                                    <div className="attachments__file_box">
                                                                        <div onClick={() => imageClicked(props.inspection.full_attachment_url, props.inspection.attachments, i)}>
                                                                            <ImageLoader
                                                                                style={{ width: '100%', height: '82px', cursor: 'pointer' }}
                                                                                src={url.photo_urls.small}
                                                                                wrapper={React.createFactory('div')}
                                                                                preloader={preloader}>
                                                                                Image load failed!
                                                                    </ImageLoader>
                                                                        </div>
                                                                        {/* <img src={url.photo_urls.medium} alt="Image" /> */}
                                                                        <div className="cancel_icon">
                                                                            <img src="/images/icons/icn_delete_white.png" onClick={() => removeNotesUrls(url, i)} alt="Image" />
                                                                        </div>
                                                                    </div>
                                                                    : null
                                                            )})}

                                                            {props.inspection.full_attachment_url ?
                                                            props.inspection.full_attachment_url.map((url, i) => {
                                                                return (<div className="attachments__file_box">
                                                                    <img src={url} alt="Image" style={{ width: '100%', height: '82px', cursor: 'pointer' }}  onClick={() => imageClicked(props.inspection.full_attachment_url, props.inspection.attachments, i)} />
                                                                    <div className="cancel_icon">
                                                                        <img src="/images/icons/icn_delete_white.png" onClick={() => removeNotesImage(url, i)} alt="Image" />
                                                                    </div>
                                                                </div>)
                                                            })
                                                            :
                                                            null
                                                            }

                                                        {imageLoading && portfolioImages && !loaderIndex ?
                                                            portfolioImages.portfolioImages.imageName.map((p, i) => {
                                                                return (<div className="attachments__file_box">
                                                                    <img src={'/images/gif/giphy.gif'} alt="Image" />
                                                                    <div className="cancel_icon">
                                                                        {/* <img src="/images/icons/icn_delete_white.png" alt="Image" /> */}
                                                                    </div>
                                                                </div>)
                                                            })
                                                            : '' }

                                                    </div>
                                                </div>
                                            </div>
                                        </div>}
                                        <div class="modal fade web_modal web_two" id="webModaleight" tabindex="-1" role="dialog" aria-labelledby="webModalone" aria-hidden="true">
                        <div class="modal-dialog modal-lg" role="document" style={{overflowY:"auto",maxHeight:"100vh"}}>
                            <div class="modal-content">
                                <div class="modal-body">
                                    <div class="modal-content">
                                        <h4 class="modal_h4"><a  href="javascript:void(0);" class="back_arrow" data-dismiss="modal" data-toggle="modal" data-target="#webModalseven "><img src="../../images/icons/Expand_More.png" alt="Arrow"/></a>Step 3 of 3</h4>
                                        <div class="web_inn_container">
                                            <figure class="web_logo">
                                                <img src="../../images/icons/web_logo.png" alt="Pegasus"/>
                                            </figure>
                                            <div class="web_inn_con">
                                                <h3 class="web_h3 text-center">{props.qqvQuestion && props.qqvQuestion[2]?.about}</h3>
                                                <div class="post_comment">
                                                <div className="form-group ml-4">
                                                <div className="custom-control custom-radio custom-control-inline">
						                        <input   className="custom-control-input" onChange={(e) => setQuiz8a(e.target.value)}  value={'yes'} type="radio" id="Yes1" name='quiz8' />
						                        <label htmlFor={"Yes1"} for={"Yes1"} className={"custom-control-label"}>Yes</label>
					                            </div>
                                                <div className="custom-control custom-radio custom-control-inline">
						                        <input   className="custom-control-input" onChange={(e) => setQuiz8a(e.target.value)}  value={'no'}  type="radio" id="No1" name='quiz8' />
						                        <label htmlFor={"No1"} for={"No1"} className={"custom-control-label"}>No</label>
					                            </div>
                                                </div>
                                                    <div class="form-group mt-4">
                                                        <textarea value={quiz8b} onChange={(e) => setQuiz8b(e.target.value)} class="form-control textarea_modifier" id="exampleFormControlTextarea3" rows="8" placeholder="Type here"></textarea>
                                                    </div>
                                                    <div class="web_btn_block">
                                                    <button data-test="submit_button" 
                                                   onClick={handleSubmit(values =>
                                                    props.performInspection({ ...values },
                                                        { active_status: props.selectedCriteria === 'quality' || props.selectedCriteria === 'Quality'  ? 'pending' : 'completed' },
                                                    iscustomerEnabled, {quiz6, quiz7a, quiz7b, quiz8a, quiz8b}))}
                                                     type="button" disabled={props.isLoading || imageLoading || (props.NameTypeSection.isCustomerName && !props.NameTypeSection.isFilled) ||quiz8a === ''  || ( quiz8a === 'no' && quiz8b.trim() === '')}
                                                     className={props.submitTrue ? "btn cst_btn btn_danger mr-2 btn-wait" : "btn cst_btn btn_danger mr-2"} >{props.submitTrue ? "" : "Submit"}</button>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                                        <div className="card_footer">
                                            <div className="btn_block text-center">
                                                {props.inspection.active_status === 'pending' ? '' : <button data-test="save_draft_button" onClick={( ) =>
                                                    props.performInspection({inspection: inspectionFormValues}, { active_status: 'draft' }, iscustomerEnabled)} type="button" disabled={props.isLoading || imageLoading || (props.NameTypeSection.isCustomerName && !props.NameTypeSection.isFilled)} className={props.saveAsDraft ? "btn cst_btn btn_danger mr-2 btn-wait" : "btn cst_btn btn_danger mr-2"} >{props.saveAsDraft ? "" : "Save As Draft"}</button>}
                                                {
                                                   props.inspection && props.inspection.inspection_form && props.inspection.inspection_form.tag_follow_up  ? 
                                                   <button data-test="submit_button" className="btn cst_btn btn_danger mr-2" data-toggle="modal" onClick={() => props.getCustomerOrInspectorQqvQuestions({type: 'inspector'})} data-target="#webModalsix">  Submit</button>
                                                   : <button data-test="submit_button" 
                                                   onClick={handleSubmit(values =>
                                                    props.performInspection({ ...values },
                                                        { active_status: props.selectedCriteria === 'quality' || props.selectedCriteria === 'Quality'  ? 'pending' : 'completed' },
                                                    iscustomerEnabled))}
                                                     type="button" disabled={props.isLoading || imageLoading || (props.NameTypeSection.isCustomerName && !props.NameTypeSection.isFilled)}
                                                     className={props.submitTrue ? "btn cst_btn btn_danger mr-2 btn-wait" : "btn cst_btn btn_danger mr-2"} >{props.submitTrue ? "" : "Submit"}</button>
                                                }
                                                <button data-test="cancel_button" type="button" onClick={() => props.history.push(routes.INSPECTIONS)}
                                                    className="btn cst_btn btn-outline-secondary  cancel_btnnew">Cancel</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </form>
                </div>
               <div class="modal fade web_modal" id="webModalone" tabindex="-1" role="dialog" aria-labelledby="webModalone" aria-hidden="true">
               <div class="modal-dialog modal-lg" role="document" style={{overflowY:"auto",maxHeight:"100vh"}}>
                            <div class="modal-content">
                                <div class="modal-body">
                                    <div class="modal-content">
                                            
                                            <div class="web_inn_container">
                                                <figure class="web_logo">
                                                    <img src="../../images/icons/web_logo.png" alt="Pegasus"/>
                                                </figure>
                                                <div class="web_inn_con">
                                                    <h3 class="web_h3">{props.qqvQuestion && props.qqvQuestion[0]?.about}</h3>
                                                    <span class="web_h3" style={{fontSize: '18px', lineHeight: '28px', color: '#252525'}}>How would you rate your experience with our services – this includes your experience with management, the employees, the cleanliness, etc.?</span>
                                                    <nav aria-label="Page navigation example" class="web_pagination_block">
                                                        <ul class="pagination web_pagination">
                                                            <li class={quiz1 === 0 ? "page-item active" : "page-item"} onClick={() => setQuiz1(0)}><a class="page-link link_zero">0</a></li>
                                                            <li class={quiz1 === 1 ? "page-item active" : "page-item"} onClick={() => setQuiz1(1)}><a class="page-link link_one">1</a></li>
                                                            <li class={quiz1 === 2 ? "page-item active" : "page-item"} onClick={() => setQuiz1(2)}><a class="page-link link_two">2</a></li>
                                                            <li class={quiz1 === 3 ? "page-item active" : "page-item"} onClick={() => setQuiz1(3)}><a class="page-link link_three">3</a></li>
                                                            <li class={quiz1 === 4 ? "page-item active" : "page-item"} onClick={() => setQuiz1(4)}><a class="page-link link_four">4</a></li>
                                                            <li class={quiz1 === 5 ? "page-item active" : "page-item"} onClick={() => setQuiz1(5)}><a class="page-link link_five">5</a></li>
                                                            <li class={quiz1 === 6 ? "page-item active" : "page-item"} onClick={() => setQuiz1(6)}><a class="page-link link_six">6</a></li>
                                                            <li class={quiz1 === 7 ? "page-item active" : "page-item"} onClick={() => setQuiz1(7)}><a class="page-link link_seven">7</a></li>
                                                            <li class={quiz1 === 8 ? "page-item active" : "page-item"} onClick={() => setQuiz1(8)}><a class="page-link link_eight">8</a></li>
                                                            <li class={quiz1 === 9 ? "page-item active" : "page-item"} onClick={() => setQuiz1(9)}><a class="page-link link_nine">9</a></li>
                                                            <li class={quiz1 === 10 ? "page-item active" : "page-item"} onClick={() => setQuiz1(10)}><a class="page-link link_ten">10</a></li>
                                                        </ul>
                                                    </nav>
                                                    <div class="page_not_likely">
                                                        <h4 class="web_h4 link_previous">0 - Dissatisfied</h4>
                                                        <h4 class="web_h4 link_next">10 - Satisfied</h4>
                                                    </div>
                                                    <div class="web_btn_block">
                                                        <button type="button" class="btn btn_danger_dark cst_btn" disabled={quiz1 === null} data-dismiss="modal" data-toggle="modal" data-target="#webModaltwo">Next</button>
                                                    </div>
                                                </div>
                                            </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <!-- web modal 2 --> */}
                    <div class="modal fade web_modal web_two" id="webModaltwo" tabindex="-1" role="dialog" aria-labelledby="webModalone" aria-hidden="true">
                    <div class="modal-dialog modal-lg" role="document" style={{overflowY:"auto",maxHeight:"100vh"}}>
                            <div class="modal-content">
                                <div class="modal-body">
                                    <div class="modal-content">
                                        <h4 class="modal_h4"><a  href="javascript:void(0);" class="back_arrow" data-dismiss="modal" data-toggle="modal" data-target="#webModalone"><img src="../../images/icons/Expand_More.png" alt="Arrow"/></a>Step 2 of 3</h4>
                                        <div class="web_inn_container">
                                            <figure class="web_logo">
                                                <img src="../../images/icons/web_logo.png" alt="Pegasus"/>
                                            </figure>
                                            <div class="web_inn_con">
                                                <h3 class="web_h3 text-center">What is the reason for the score of {quiz1}?</h3>
                                                <div class="post_comment">
                                                    <div class="form-group">
                                                        <label class="label_modifier">What do you love about our services?</label>
                                                        <textarea value={quiz2} onChange={(e) => setQuiz2(e.target.value)} class="form-control textarea_modifier" id="exampleFormControlTextarea1" rows="8" placeholder="Type here"></textarea>
                                                    </div>
                                                    <div class="web_btn_block">
                                                        <button type="button" class="btn btn_danger_dark cst_btn" disabled={quiz2 === ''} data-dismiss="modal" data-toggle="modal" data-target="#webModalthree">Next</button>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                     {/* <!-- web modal 3 --> */}
                     <div class="modal fade web_modal web_three" id="webModalthree" tabindex="-1" role="dialog" aria-labelledby="webModalone" aria-hidden="true">
                     <div class="modal-dialog modal-lg" role="document" style={{overflowY:"auto",maxHeight:"100vh"}}>
                            <div class="modal-content">
                                <div class="modal-body">
                                    <div class="modal-content">
                                        <h4 class="modal_h4"><a href="javascript:void(0);" class="back_arrow" data-dismiss="modal" data-toggle="modal" data-target="#webModaltwo"><img src="../../images/icons/Expand_More.png" alt="Arrow"/> </a>Step 3 of 3</h4>
                                        <div class="web_inn_container">
                                            <figure class="web_logo">
                                                <img src="../../images/icons/web_logo.png" alt="Pegasus"/>
                                            </figure>
                                            <div class="web_inn_con">
                                                <h3 class="web_h3 text-center">{props.qqvQuestion && props.qqvQuestion[2]?.about}</h3>
                                                <div class="post_comment">
                                                    <div class="form-group">
                                                        {/* <label class="label_modifier">Where can we improve?</label> */}
                                                        <textarea value={quiz3} onChange={(e) => setQuiz3(e.target.value)} class="form-control textarea_modifier" id="exampleFormControlTextarea1" rows="8" placeholder="Type here"> </textarea>
                                                    </div>
                                                    <div class="web_btn_block">
                                                        <button type="button" class="btn btn_danger_dark cst_btn" disabled={quiz3 === ''} data-dismiss="modal" data-toggle="modal" data-target="#webModalFour" onClick={() => props.submitQuiz(quiz1, quiz2, quiz3, props)}>Next</button>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <!-- web modal 5 --> */}
                    {/* <div class="modal fade web_modal web_three" id="webModalFive" tabindex="-1" role="dialog" aria-labelledby="webModalone" aria-hidden="true">
                        <div class="modal-dialog modal-lg" role="document">
                            <div class="modal-content">
                                <div class="modal-body">
                                    <div class="modal-content">
                                        <h4 class="modal_h4"><a href="javascript:void(0);" class="back_arrow" data-dismiss="modal" data-toggle="modal" data-target="#webModalthree"><img src="../../images/icons/Expand_More.png" alt="Arrow"/> </a>Step 4 of 5</h4>
                                        <div class="web_inn_container">
                                            <figure class="web_logo">
                                                <img src="../../images/icons/web_logo.png" alt="Pegasus"/>
                                            </figure>
                                            <div class="web_inn_con">
                                                <h3 class="web_h3 text-center">Is there any additional feedback?</h3>
                                                <div class="post_comment">
                                                    <div class="form-group">
                                                        <label class="label_modifier">Post your comments</label>
                                                        <textarea value={quiz4} onChange={(e) => setQuiz4(e.target.value)} class="form-control textarea_modifier" id="exampleFormControlTextarea1" rows="8" placeholder="Type here"> </textarea>
                                                    </div>
                                                    <div class="web_btn_block">
                                                        <button type="button" class="btn btn_danger_dark cst_btn" disabled={quiz4 === ''} data-dismiss="modal" data-toggle="modal" data-target="#webModalFour" onClick={() => props.submitQuiz(quiz1, quiz2, quiz3, quiz4, props)}>Next</button>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}

                    {/* <!-- web modal 4 --> */}
                    <div class="modal fade web_modal web_three" id="webModalFour" tabindex="-1" role="dialog" aria-labelledby="webModalone" aria-hidden="true">
                    <div class="modal-dialog modal-lg" role="document" style={{overflowY:"auto",maxHeight:"100vh"}}>
                            <div class="modal-content">
                                <div class="modal-body">
                                    <div class="modal-content">
                                        {/* <h4 class="modal_h4">Step 3 of 1</h4> */}
                                        <div class="web_inn_container">
                                            <figure class="web_logo">
                                                <img src="../../images/icons/web_logo.png" alt="Pegasus"/>
                                            </figure>
                                            <div class="web_inn_con">
                                                <div class="post_comment">
                                                    <h5 class="web_h5">Thank you for your feedback</h5>
                                                    <p class="web_para"> This is important for us to understand where we are succeeding and where we need to get better.</p>
                                                    <p class="web_para">A summary of responses will accompany this inspection report</p>
                                                </div>
                                                <div class="web_btn_block">
                                                <button class="btn btn_danger_dark cst_btn" data-dismiss="modal" aria-label="Close">Close</button>
                                                    </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="modal fade web_modal web_two" id="webModalsix" tabindex="-1" role="dialog" aria-labelledby="webModalone" aria-hidden="true">
                        <div class="modal-dialog modal-lg" role="document">
                            <div class="modal-content">
                                <div class="modal-body">
                                    <div class="modal-content">
                                    <h4 class="modal_h4">Step 1 of 3</h4>
                                        <div class="web_inn_container">
                                            <figure class="web_logo">
                                                <img src="../../images/icons/web_logo.png" alt="Pegasus"/>
                                            </figure>
                                            <div class="web_inn_con">
                                                <h3 class="web_h3 text-center">{props.qqvQuestion && props.qqvQuestion[0]?.about}</h3>
                                                <div class="post_comment">
                                                <div class="form-group">
                                                        <textarea value={quiz6} onChange={(e) => setQuiz6(e.target.value)} class="form-control textarea_modifier" id="exampleFormControlTextarea1" rows="8" placeholder="Type here"></textarea>
                                                    </div>
                                                    <div class="web_btn_block">
                                                        <button type="button" class="btn btn_danger_dark cst_btn" disabled={quiz6.trim() === ''} data-dismiss="modal" data-toggle="modal" data-target="#webModalseven">Next</button>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="modal fade web_modal web_two" id="webModalseven" tabindex="-1" role="dialog" aria-labelledby="webModalone" aria-hidden="true">
                        <div class="modal-dialog modal-lg" role="document" style={{overflowY:"auto",maxHeight:"100vh"}}>
                            <div class="modal-content">
                                <div class="modal-body">
                                    <div class="modal-content">
                                        <h4 class="modal_h4"><a  href="javascript:void(0);" class="back_arrow" data-dismiss="modal" data-toggle="modal" data-target="#webModalsix"><img src="../../images/icons/Expand_More.png" alt="Arrow"/></a>Step 2 of 3</h4>
                                        <div class="web_inn_container">
                                            <figure class="web_logo">
                                                <img src="../../images/icons/web_logo.png" alt="Pegasus"/>
                                            </figure>
                                            <div class="web_inn_con">
                                                <h3 class="web_h3 text-center">{props.qqvQuestion && props.qqvQuestion[1]?.about}</h3>
                                                <div class="post_comment">
                                                <div className="form-group">
                                                <div className="custom-control custom-radio custom-control-inline">
						                        <input   className="custom-control-input" onChange={(e) => setQuiz7a(e.target.value)}  value={'yes'} type="radio" id="Yes" name='quiz7' />
						                        <label htmlFor={"Yes"} for={"Yes"} className={"custom-control-label"}>Yes</label>
					                            </div>
                                                <div className="custom-control custom-radio custom-control-inline">
						                        <input   className="custom-control-input" onChange={(e) => setQuiz7a(e.target.value)}  value={'no'}  type="radio" id="No" name='quiz7' />
						                        <label htmlFor={"No"} for={"No"} className={"custom-control-label"}>No</label>
					                            </div>
                                                </div>
                                                    <div class="form-group mt-4">
                                                        <textarea value={quiz7b} onChange={(e) => setQuiz7b(e.target.value)} class="form-control textarea_modifier" id="exampleFormControlTextarea2" rows="8" placeholder="Type here"></textarea>
                                                    </div>
                                                    <div class="web_btn_block">
                                                        <button type="button" class="btn btn_danger_dark cst_btn" disabled={ quiz7a === ''  || ( quiz7a === 'no' && quiz7b.trim() === '')} data-dismiss="modal" data-toggle="modal" data-target="#webModaleight">Next</button>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                   
            </main>
        )
    }
}
// }

const mapStateToProps = (state, props) => {
    const CurrentInspectonData = storage.get("performInspectionData", null);
    //  
    // CurrentInspectonData && !isEmpty(CurrentInspectonData) ? CurrentInspectonData.inspection :
    let inspection = {}
    inspection['inspection_form'] = {};
    let CurrectInspection = {};

    if (CurrentInspectonData) {
        if (CurrentInspectonData.inspection.inspection_form.id) {
            CurrectInspection = cloneDeep(CurrentInspectonData.inspection);
        }
    } else {
        CurrectInspection = cloneDeep(state.adminOrSuperAdminReducer.inspection);
    }


    if (isEmpty(CurrectInspection)) {

        inspection.inspection_form['sections_attributes'] = [{}]
        inspection.inspection_form.sections_attributes[0]['line_items_attributes'] = [{}, {}]
    } else {
        //  
        CurrectInspection.inspection_form.sections_attributes.map(section => {
            section.line_items_attributes.map(lineAttribute => {
                // lineAttribute['rating_type_id'] = lineAttribute.rating_type.id;
                if (!lineAttribute.portfolioImages) {
                    lineAttribute['portfolioImages'] = PortfolioProperties;
                }

                if (!lineAttribute.rating_option_id && lineAttribute.rating_option) {
                    lineAttribute['rating_option_id'] = lineAttribute.rating_option.id
                }
                let index = lineAttribute.rating_type.rating_options_attributes.findIndex(r => r.default === true);
                if (!lineAttribute.rating_option_id) {
                    if (index >= 0) {
                        lineAttribute['rating_option_id'] = lineAttribute.rating_option ?
                            lineAttribute.rating_option.id : lineAttribute.rating_type.rating_options_attributes[index].id;
                    } 
                    // else {
                    //     lineAttribute['rating_option_id'] = lineAttribute.rating_option ?
                    //         lineAttribute.rating_option.id :
                    //         lineAttribute.rating_type.rating_options_attributes[lineAttribute.rating_type.rating_options_attributes.length - 1].id;
                    // }
                }
            })

        })

        inspection['review_notes'] = props.inspection.review_notes
        inspection.inspection_form = CurrectInspection.inspection_form;
        // inspection_form['account_id']
    }
    //  
    // }

    return { initialValues: { inspection }, formStates: getFormValues('performInspectionForm')(state) }

}


PerformInspection = reduxForm({
    form: 'performInspectionForm',
    validate,
    // enableReinitialize: true,
    onSubmitFail: (errors) => scrollToFirstError(errors),
})(PerformInspection);

PerformInspection = connect(mapStateToProps)(PerformInspection);

export const scrollToFirstError = (errors) => {
    let InspectionErrors = {}
    InspectionErrors['inspection'] = {};
    InspectionErrors.inspection['inspection_form'] = {};
    InspectionErrors.inspection.inspection_form['sections_attributes'] = cloneDeep(Object.values(errors ? errors.inspection.inspection_form.sections_attributes : ''));
    // cloneDeep(errors);
    let secIndex = null;
    let lineIndex = null;
    InspectionErrors.inspection.inspection_form.sections_attributes.map((section, sIndex) => {
        if (secIndex !== null && lineIndex !== null) {
            return false
        }
        section['line_items_attributes'] = cloneDeep(Object.values(errors.inspection.inspection_form.sections_attributes[sIndex].line_items_attributes));
        section.line_items_attributes.map((line, lIndex) => {
            if (secIndex !== null && lineIndex !== null) {
                return false
            }
            if (line.comment && (line.comment !== '' && line.comment !== ' ')) {
                secIndex = sIndex;
                lineIndex = lIndex;
                return false;
            }
            if (line.rating_option_id && ( line.rating_option_id === '' || line.rating_option_id === ' ')) {
                secIndex = sIndex;
                lineIndex = lIndex;
                return false;
            }
        })
    })

    scroller.scrollTo(`inspection[inspection_form][sections_attributes][${secIndex}][line_items_attributes][${lineIndex}][rating_option_id]`, {
        duration: 500,
        delay: 1,
        smooth: true,
        // containerId: 'ContainerElementID',
        offset: 0, // Scrolls to element + 50 pixels down the page
    })

    // Object.keys(errors.inspection.inspection_form.sections_attributes[secIndex[0]].line_items_attributes);
    scroller.scrollTo(`inspection[inspection_form][sections_attributes][${secIndex}][line_items_attributes][${lineIndex}][comment]`, {
        duration: 500,
        delay: 1,
        smooth: true,
        // containerId: 'ContainerElementID',
        offset: 0, // Scrolls to element + 50 pixels down the page
    })
}
export default PerformInspection;