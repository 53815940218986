import React, { useState, useEffect } from 'react';
import { Input } from '../../../UI/InputElement/InputElement';
import { SelectField } from '../../../UI/SelectField/SelectField';
import { DateTimeField } from '../../../UI/DateTimeField/DateTimeField';
//import { newLogbookAreaValidator as validate } from '../../../../../utility/validator/newValidator';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { DAYS } from "../../../../../utility/constants/constants";
const moment = require('moment-timezone');

let AddLogBookAreaForm = (props) => {
  const { handleSubmit, change } = props;
  const initialTime = { hours: "00", minutes: "00", timeDivision: "am" }
  const [timeSlotes, setTimeSlot] = useState(["12:00 AM"]);
  const [isCriticalEnv, setIsCriticalEnv] = useState(false);

  useEffect(() => {
    props.getAllPlaybookPdfLabels();
    setIsCriticalEnv(props?.logbookForm?.AddLogBookAreaForm?.values?.logbook?.critical);
  }, [props?.logbookForm?.AddLogBookAreaForm?.values?.logbook?.critical]);

  const removeTimeSlot = (index) => {
    let times = [...timeSlotes];
    times.splice(index, 1);
    setTimeSlot(times);
    if (times.length) {
      let timeslots_attributes = props.logbookForm.AddLogBookAreaForm.values.logbook.timeslots_attributes;
      timeslots_attributes.splice(index, 1);
      change("logbook[timeslots_attributes]", timeslots_attributes);
    } else {
      change("logbook[timeslots_attributes]", [{ start_time: "" }]);
    }
  };

  const addTimeSlot = () => {
    let times = [...timeSlotes];
    //let timeslots_attributes = props.logbookForm.AddLogBookAreaForm.values.area.timeslots_attributes;
    times.push({
      "start_time": "12:00 AM"
    });
    setTimeSlot(times);
    //props.change("area[timeslots_attributes]",timeslots_attributes)
  };

  const getFormattedZone = (timezone) => {
    let zone = ''
    const now = moment.tz(timezone);
    zone = now.format('z');
    return zone;
  }

  const getPlaybookPdfList = (playbookPdfLabelsList) => {
    if (props.playbookPdfLabelsList && props.playbookPdfLabelsList.length > 0) {
      return playbookPdfLabelsList && playbookPdfLabelsList.map((data) => {
        return {
          value: data.id,
          label: data.title
        }
      })
    } else {
      return []
    }
  };

  const [playbookPdfList, setPlaybookPdfList] = useState(getPlaybookPdfList(props.playbookPdfLabelsList));

  useEffect(() => {
    setPlaybookPdfList(getPlaybookPdfList(props.playbookPdfLabelsList))
  }, [props.playbookPdfLabelsList]);

  return (
    <main className="app-content add_area_modal">
      <div className="appcontent_Inner">
        <div className="add_info_block">
          <h2 className="h2_title" data-test="addArea_head_title">Add an Logbook Area</h2>
          <div className="add_content_block">
            <form className="form_content" onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="accountID" className="label_modifier">Job ID</label>
                <Input type="text" readOnly={true} className="form-control Input-modifier" id="accountID" placeholder="Type here" name="logbook[account_id]" />
              </div>
              <div className="form-group">
                <label htmlFor="areaID" className="label_modifier">Logbook Area ID</label>
                <Input type="text" readOnly={true} className=" form-control Input-modifier" id="areaID" placeholder="Type Here" name="logbook[log_number]" />
              </div>
              <div className="form-group">
                <label htmlFor="areaName" className="label_modifier">Logbook Area Name</label>
                <Input dataTest="area_name_input" type="text" className="form-control Input-modifier" id="areaName" placeholder="Type here" name="logbook[name]" required />
              </div>
              <div className="form-group" include="form-Input-select()">
                <label className="label_modifier">Floor No.</label>
                <SelectField
                  dataTest="floor_number"
                  className="custom-select Input-modifier"
                  options={props.floorList}
                  name="logbook[floor_id]" required>
                </SelectField>
              </div>
              <div className="form-group" include="form-Input-select()">
                <label className="label_modifier">Area Type</label>
                <SelectField
                  dataTest="area_type"
                  className="custom-select Input-modifier"
                  options={props.areaTypes}
                  name="logbook[area_type_id]" required>

                </SelectField>

              </div>
              <div className="form-group">
                <label className="label_modifier">Select Time ({props.account.user ? getFormattedZone(props.account.user.timezone) : ''})</label>
                <button type="button" className="btn btn_danger cst_btn" onClick={() => [addTimeSlot(), setTimeSlot([...timeSlotes, { ...initialTime }])]}>Add</button>
                <div className="w-100" style={{ color: "rgb(221, 39, 38)", fontSize: "13px" }}>{props.areaErrorMessage}</div>
                {
                  timeSlotes.map((time, index) => (
                    <div className="grid_view_block row" key={index}>
                      <div className="col-10">
                        <DateTimeField timeConstraints={{ minutes: { step: 5 } }} datePickerType='react-datetime' pickerType="timePicker" dataFormat="hh:mm A" className="form-control Input-modifier" id="areaName" name={`logbook[timeslots_attributes][${index}][start_time]`} />
                      </div>
                      <div className="col-2">
                        <i className="far fa-trash-alt mt-2" onClick={e => removeTimeSlot(index)}></i>
                      </div>

                    </div>
                  ))
                }
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <div className="checkbox_block radius_checkbox">
                      {
                        DAYS.map((day, index) => <div className="w-25 mr-0 custom-control custom-checkbox custom-control-inline" onClick={() => props.resetDayError()}>
                          <Input type="checkbox" name={`logbook[days][${index}]`} className="custom-control-input" id={`customCheck${index}`} />
                          <label data-test="check_box_input" className="custom-control-label" htmlFor={`customCheck${index}`}>{day}</label>
                        </div>)
                      }
                    </div>
                  </div>
                  <div className="w-100" style={{ color: "rgb(221, 39, 38)", fontSize: "13px" }}>{props.areaDayErrorMessage}</div>
                  <div className="input-group cst_input_group">
                    <label className="form_title ">Start Date</label>
                    <div className="input-group cst_input_group">
                      <Input name="logbook[schedule_at]"
                        minDate={new Date()}
                        preventReadOnly={true}
                        dataTest="calendar_date_picker"
                        dateFormat="MM-dd-yyyy" id="from_date" placeholder="Start Date" datePickerType={"react-datepicker"} type="date" className="form-control from-date-analytics" />
                      <div className="input-group-append cursor-pointer" style={{ float: "right", position: "absolute", right: 0, height: "36px", top: 0 }} onClick={e => document.querySelector(".from-date-analytics").click()}>
                        <span className="input-group-text" id="basic-addon2"><i className="fa fa-calendar" alt="Calendar Icon" ></i></span>
                      </div>
                    </div>
                  </div>
                  <div className="mt-3 checkbox_block radius_checkbox">
                    <div className="custom-control custom-checkbox custom-control-inline">
                      <Input type="checkbox" name="logbook[critical]" className="custom-control-input" id="logbook[critical]" />
                      <label className="custom-control-label" for="logbook[critical]">Critical Environments Logbook</label>
                    </div>
                  </div>
                  {isCriticalEnv ? (
                    <div className="form-group" include="form-Input-select()">
                      <label className="label_modifier"> Select PegAssure Playbook PDF </label>
                      <Input name="logbook[labelsId]" dataTest="select_leave_type" type="inputSelect" options={playbookPdfList} placeholder="Please select" />
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="modal-footer">
                <button data-test="submit_button" type="submit" disabled={props.isLoading} className={props.isLoading ? "btn cst_btn btn_danger mr-2 btn-wait" : "btn btn_danger cst_btn"}>Generate QR Code</button>
                <button type="button" className="btn btn_outline_secondary cst_btn" onClick={() => { props.history.push(`/accounts/${props.match.params.accountId}/account_users?job_logbook=true`) }}>Cancel</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </main>

  )
};

const mapStateToProps = (state, props) => {
  let last_ref;
  if (props.account.user) {
    last_ref = `${props.account.user.name.slice(0, 2)}-${props.account.user.id}-${props.account.user.last_ref + 1}`;
  }
  let logbookAreaForm = {
    name: "",
    floor_id: "",
    area_type_id: "",
    schedule_at: "",
    timeslots_attributes: [
      {
        start_time: "12:00 AM",
        start_time_utc: ""
      }
    ],
  };
  if (state.form.AddLogBookAreaForm && state.form.AddLogBookAreaForm.values) {
    logbookAreaForm = state.form.AddLogBookAreaForm.values.logbook;
  }
  return {
    initialValues:
    {
      logbook:
      {
        ...logbookAreaForm,
        account_id: props.account && props.account.user && props.account.user.account_number,
        log_number: last_ref,
      }
    },
    logbookForm: state.form
  }
};

AddLogBookAreaForm = reduxForm({
  form: 'AddLogBookAreaForm',
  //validate,
  enableReinitialize: true,
})(AddLogBookAreaForm);

AddLogBookAreaForm = connect(mapStateToProps)(AddLogBookAreaForm);

export default AddLogBookAreaForm;