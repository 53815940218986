import React from "react";
import { useDispatch, useSelector } from "react-redux";
import AddStandardMatrix from "./AddStandardMatrix";
import {
  addStandardMatrix,
  updateStandardMatrix,
} from "../../../../../redux/actions/BrmActions/BrmAction";
import errorHandler from "../../../../../utility/errorHandler/errorHandler";

const AddStandardMatrixContainer = ({ isEdit, resetSelectedMatrix,setIsChecked }) => {
  const dispatch = useDispatch();
  const getUpdatedMatrixList = useSelector(
    (state) => state.BRM.updateMatrixList
  );
  let defaultForm = {};

  const MatrixAssigner = {
    tiera: 1,
    tierb: 2,
    tierc: 3,
  };

  if (getUpdatedMatrixList?.name) {
    
    defaultForm = {
      name: getUpdatedMatrixList?.name
        ? getUpdatedMatrixList?.name
        : "quality_control_visits",
      tiera: getUpdatedMatrixList?.frequency
        ? getUpdatedMatrixList?.frequency
        : "",
      active_status:getUpdatedMatrixList?.active_status==="active" ? "1" :"0"
    };
  } else {
    defaultForm = {
      frequency: "",
      tiera: "",
      tierb: "",
      tierc: "",
      type: "default",
    };
  }

  const onSubmit = async(data) => {
    //Validation Check 

    const Filter_TierArray = Object.keys(data).filter((item) =>
      item.includes("tier") 
    );

    if(Filter_TierArray.length){
      const Notvalid=Filter_TierArray.every((item)=>{
        if(data[item]=="none" || data[item]==""){
            return true
        }else return false
    })
    if(Notvalid){
      errorHandler("You cant choose N/A for standard matrix")
      return false;
    }
      
    }
    if (isEdit) {
      debugger;
      const currentDataPost = {
        data: {
          frequency: data.tiera,
          name: data.name,
          matrix: getUpdatedMatrixList.matrix,
          active_status:Boolean(+data.active_status) ? "active" :"inactive"
        },
      };
       dispatch(updateStandardMatrix(currentDataPost, getUpdatedMatrixList?.id));
       
       setTimeout(()=>{
        if(setIsChecked){
          setIsChecked(true)
        }
      },2000)
    } else {
      const currentpost = { data: [] };
      const Filter_TierArray = Object.keys(data).filter((item) =>
        item.includes("tier")
      );

      Filter_TierArray.forEach((item) => {
        const value = data[item];
        if (value && value !== "none") {
          const payload_ForEach = {
            frequency: value,
            name: data.name,
            matrix: MatrixAssigner[item] ? MatrixAssigner[item] : 0,
            type: "default",
            active_status:"active"
          };

          currentpost.data.push(payload_ForEach);
        }
      });
      debugger;
       dispatch(addStandardMatrix(currentpost))
      
      setTimeout(()=>{
        if(setIsChecked){
          setIsChecked(true)
        }
      },2000)
    }
    
  };

  return (
    <AddStandardMatrix
      formSubmittedCallback={onSubmit}
      elements={defaultForm}
      isEdit={isEdit}
      isView={false}
      modalUpdate={true}
      handleClose={resetSelectedMatrix}
    />
  );
};

export default AddStandardMatrixContainer;