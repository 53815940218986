import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import KpiInspectionDashboard from "./KpiInspectionDashboard";
import '../../../styles/custom.css';
import KpiTicketDashboard from "./KpiTicketDashboard";
import { Input } from '../UI/InputElement/InputElement';
import { reduxForm, formValueSelector, getFormValues, change } from 'redux-form';
import { KpiDashboardSearchValidator as validate } from '../../../utility/validator/validator';






const getUsersDropDown = (users) => { 

  return users && users.map(u => {
      return {
          value: u.id,
          label: u.first_name + ' ' + u.last_name
      }
  })
}



let KpiInspection = (props) => {
  let allJobIds = [];
  const dispatch = useDispatch();

  const getAccountsDropDown = props.companyUsersList && props.companyUsersList.map(a => { 
    allJobIds.push(a.id);   
    return {
            value: a.id,
            label: a.name
        }
    })


    const groupedJobsOptions = [
      {
          label: 'All Jobs',
          options: [{
              value: allJobIds,
              label: 'All Jobs'
          }]
      },
      {
          label: 'Job-Wise',
          options: getAccountsDropDown
      }
    ]
    
    const onAccountChange = (e) => {
      const index = e && e.findIndex(c => c.label === 'All Jobs');
      if (index === 0 && e.length > 1) {
          e = e.splice(0, 1);
      } else if (index > 0 && e.length > 1) {
          e = e.splice(0, e.length - 1);
      }
      dispatch(change('searchKpiDashboard', `inspection_account`, []))
  }
  // const [accountList, setAccountList] = useState(getAccountsDropDown(props.companyUsersList));
  const [usersList, setUsersList] = useState(getUsersDropDown(props.dropDownUsersList));



  const trimText = (num) => {
      return Number.parseFloat(num).toFixed(2);
  }
  // useEffect(() => {
  //     setAccountList(getAccountsDropDown(props.companyUsersList))
  // }, [props.companyUsersList])

  useEffect(() => {
      setUsersList(getUsersDropDown(props.dropDownUsersList))
  }, [props.dropDownUsersList])

 

  const noUserOptionsMessage = (a, b) => {
      return 'User not found';
  }

  const noAccountOptionsMessage = (a, b) => {
      return 'Job not found';
  }

  const { handleSubmit, pristine, reset, submitting, error } = props;

  return (

    
    <div className="kpiInspection" id="kpi_dashboard_tabs">
          <>
          <div className="tab_header search_filter_cont inspection_tab_content">
          <form onSubmit={handleSubmit}>
          <div className="row">
          {/* 
          <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6">
              <div className="app-search">
                  <Input name="inspection_name" className="fil_search_input" type="search" placeholder="Search By Inspection Form" />
                  <button className="app-search__button">
                      <i className="fa fa-search" aria-hidden="true"></i>
                  </button>
              </div>
          </div>
          <div className="col-xl-2 col-lg-4 col-md-12 col-sm-12">
          <div className="app-search">
              <Input name="inspection_region" className="fil_search_input" type="search" placeholder="Region" />
              <button className="app-search__button">
                  <i className="fa fa-search" aria-hidden="true"></i>
              </button>
          </div>
      </div>
*/}
          <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12" id="kpiAccountList" >
              <Input name="inspection_account" onChange={onAccountChange} noOptionsMessage={noAccountOptionsMessage} placeholder="Job" isMulti={true} type="inputSelect" options={groupedJobsOptions} />
          </div>
          <div className="col-xl-3 col-lg-4 col-md-12 col-sm-12">
               <Input name="inspection_user" noOptionsMessage={noUserOptionsMessage} placeholder="Inspected By" type="inputSelect" options={usersList} />
           </div> 
          <div class="col-xl-5 col-lg-6 col-sm-6 col-12 text-right">
          <button
          style={{ minWidth: '90px' }}
          data-test="submit_button"
          type="submit"
        //   disabled={props.isLoading || (!props.isEdit && pristine) ? true : false}
          className={
            "btn cst_btn btn_danger mr-2 "
          }
        >
          {"Go"}
        </button>
                  <button style={{ minWidth: '90px' }} onClick={props.resetClicked} className={"btn cst_btn btn-outline-secondary account_btn"} id="reset" type="reset">{"Reset"} </button>
          </div>
         </div> 
        </form>
        </div>
        </>
        <KpiInspectionDashboard
            {...props}
            nextWeekMonthYearCliked={props.nextWeekMonthYearCliked}
            previousWeekMonthYearCliked={props.previousWeekMonthYearCliked}
            viewModeClicked={props.viewModeClicked}
            kpiInspectionData={props.kpiInspectionData}
            kpiInspectionQQvScore={props.kpiInspectionQQvScore}
            kpiInspectionRootcaseData={props.kpiInspectionRootcaseData}
            kpiInspectionDeficencyData={props.kpiInspectionDeficencyData}
            kpiInspectionDateData={props.kpiInspectionDateData}
            kpiTopInspectionData={props.kpiTopInspectionData}
            kpiInspectionAccountData={props.kpiInspectionAccountData}
            kpiInspectionPieChartData={props.kpiInspectionPieChartData}
            kpiInspectionCustomerData={props.kpiInspectionCustomerData}
            kpiInspectionQQvData={props.kpiInspectionQQvData}
            readMoreClicked={props.readMoreClicked}
            dropDownUsersList={props.dropDownUsersList}
            dropDownAccountsList={props.dropDownAccountsList}
            accountsList={props.accountsList ? props.accountsList : []}
            onSubmit={props.goClicked}
            resetClicked={props.resetClicked}
            monthDivideIntoThree={props.monthDivideIntoThree}
            //new kpi changes
            kpiInspectionCount={props.kpiInspectionCount}
            kpiInspectionAverageScore={props.kpiInspectionAverageScore}
            kpiTopInspector={props.kpiTopInspector}
            kpiQualityInspectionData={props.kpiQualityInspectionData}
            isLoading={props.isLoading}
            lineItemClicked={props.lineItemClicked}
            kpiSectionValues={props.kpiSectionValues}
            QualityIdlineItemClicked={props.QualityIdlineItemClicked}
            getAverageGraphInspections={props.getAverageGraphInspections}
          />
      
    </div>
  );
};

const mapStateToProps = (state, props) => { 
  let SelectedAccounts = [];
  let allJobIds = [];

  const getAccountId = (accounts) => {
    return accounts && accounts.map((a) => ({
      value: a.id,
      label: a.name,
    }));
  };
  const getAccountWide = () => {
    return [
      {
          label: 'All Jobs',
          options: [{
              value: allJobIds,
              label: 'All Jobs'
          }]
      },
    ];
  }
    SelectedAccounts = getAccountId(
      state.adminOrSuperAdminReducer.searchInspectionValues
        ? state.adminOrSuperAdminReducer.searchInspectionValues.value.account
        : props.companyUsersList
    );
    if(SelectedAccounts && SelectedAccounts.length === props.companyUsersList.length) {
      SelectedAccounts = getAccountWide()
    }

  let name = state.adminOrSuperAdminReducer.searchInspectionValues && state.adminOrSuperAdminReducer.searchInspectionValues.value && state.adminOrSuperAdminReducer.searchInspectionValues.value.name ? state.adminOrSuperAdminReducer.searchInspectionValues.value.name : "";
  let region = state.adminOrSuperAdminReducer.searchInspectionValues && state.adminOrSuperAdminReducer.searchInspectionValues.value && state.adminOrSuperAdminReducer.searchInspectionValues.value.region ? state.adminOrSuperAdminReducer.searchInspectionValues.value.region : "";
  let account = state.adminOrSuperAdminReducer.searchInspectionValues && state.adminOrSuperAdminReducer.searchInspectionValues.value && state.adminOrSuperAdminReducer.searchInspectionValues.value.account ? state.adminOrSuperAdminReducer.searchInspectionValues.value.account : '';
  let inspection_account = SelectedAccounts;
  let user = state.adminOrSuperAdminReducer.searchInspectionValues && state.adminOrSuperAdminReducer.searchInspectionValues.value && state.adminOrSuperAdminReducer.searchInspectionValues.value.user ? state.adminOrSuperAdminReducer.searchInspectionValues.value.user : "";
  return { initialValues: { name, region, account, user, inspection_account} ,
  formStates: getFormValues('searchKpiDashboard')(state)
}
  
}
KpiInspection = reduxForm({
  form: 'searchKpiDashboard',
  validate,
  enableReinitialize: true
})(KpiInspection);

KpiInspection = connect(mapStateToProps)(KpiInspection)


export default KpiInspection;
