import React, { useEffect, useState } from 'react';
import ReactTable from 'react-table-v6';
//import '../../../../../styles/custom.css';
import { CustomNoDataComponent, convertDateAndTimeTimezone, makeFirstLetterCapital } from '../../../../../utility/utility';
import { ClapSpinner } from 'react-spinners-kit';
import '../../../../../../node_modules/react-table-v6/react-table.css';
import { useSelector, useDispatch } from 'react-redux';
import { FaArrowDown, FaArrowUp } from "react-icons/fa";
import * as actions from '../../../../../redux/actions/index';

const TicketScheduleHistoryTable =(props) => {
    const [pageSize, setPageSize] = useState(10);
    const [openModel, setOpen] = useState(false);
    const dispatch = useDispatch();
    const DateGenerate = useSelector(state => state.adminOrSuperAdminReducer.dataGenerated);
    const isScheduleTicketHistoryLoading = useSelector(state => state.adminOrSuperAdminReducer.isScheduleTicketHistoryLoading);
    const No_of_pages = useSelector(state => state.adminOrSuperAdminReducer.total_no_of_pages);
    const No_of_rows = useSelector(state => state.adminOrSuperAdminReducer.fl_user_rows);
    const scheduleTicketHistoryPage = useSelector(state => state.adminOrSuperAdminReducer.scheduleTicketHistoryCurPage);

    const data = props.scheduleTicketHistory_logList.map(history => {
        const scheduledDate = history.created_at.split('T')
        const timeFormat = scheduledDate[1].split('.')
        const ticketCreatedDate = scheduledDate[0] + ' ' + timeFormat[0]
        return {
            id: history.id,
            account_id: history.account_id,
            entity_id: history.entity_id,
            action: makeFirstLetterCapital(history.action),
            created_at: ticketCreatedDate ? convertDateAndTimeTimezone(ticketCreatedDate, 'America/Los_Angeles') : '',
            updated_at: history.updated_at,
            info: history.info,
            // actions: ''
        }
    })


    const columns = [
        {
            Header: <><span>Id</span><FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
            accessor: 'id',
            show: props.scheduleTicketHistory_logList.length !== 0,
        },
        // {
        //     Header: <><span>Entity</span><FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
        //     accessor: 'entity_id',
        //     show: props.scheduleTicketHistory_logList.length !== 0,
        // },
        {
            Header: <><span>Action</span><FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
            accessor: 'action',
            show: props.scheduleTicketHistory_logList.length !== 0,
        },
        {
            Header: <><span>Date</span><FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
            accessor: 'created_at',
            show: props.scheduleTicketHistory_logList.length !== 0,
        },
        // {
        //     Header: <><span>Updated</span><FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
        //     accessor: 'updated_at',
        //     show: props.scheduleTicketHistory_logList.length !== 0,
        //     width: 350
        // },
        {
            Header: <><span>Info</span><FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
            accessor: 'info',
            show: props.scheduleTicketHistory_logList.length !== 0,
            width: 350
        },
        // {
        // Header: " ",
        // accessor: "actions",
        // show: props.scheduleTicketHistory_logList.length !== 0,
        // width: 50
        // }
    ]

    const [tablePage, setTablePage] = useState(0)
    const tablePageNew =  React.useRef()
    if(scheduleTicketHistoryPage !== tablePageNew.current) {
        tablePageNew.current = scheduleTicketHistoryPage;
    }
    const setTablePageFn = (page) => {
        setTablePage(page)
        props.scheduleTicketHistoryPageClicked(page)
        tablePageNew.current = page;
    }

    const fetchData = (state, instance) => {
        if (isScheduleTicketHistoryLoading) {
            dispatch(actions.resetSearchOrResetValue())
        } else {
            if (!DateGenerate) {
                
                state.page = 2;
                const size = state.pageSize;
                const limit = state.pageSize;
                let page = tablePageNew.current >= 0 ? tablePageNew.current + 1 : scheduleTicketHistoryPage + 1;
                if(No_of_rows != limit) {
                    page = 1;
                    setTablePageFn(0)
                }
                const sorted = state.sorted;
                props.scheduleTicketHistory({ limit, size, page, sorted, fl_users_no_of_rows: No_of_rows }, props.match.params.accountId);
            }
        }
    }

    return (
        isScheduleTicketHistoryLoading && props.isLoading ?
        <div className="loader_btn_block">
            <ClapSpinner backColor="#89073c" size={40} frontColor="#89073c8a" />
        </div>
        :
        <> <ReactTable
            data={data}
            loading={props.isLoading}
            sortable={false}
            // showPageSizeOptions={false}
            defaultPageSize={No_of_rows ? No_of_rows : pageSize}
            minRows={0}
            manual
            onFetchData={fetchData}
            pages={No_of_pages}
            gotoPage={tablePage}
            page = {tablePage}
            onPageChange={(page) => {setTablePageFn(page)}}
            onPageSizeChange={(e) => setPageSize(e)}
            showPaginationTop={true}
            showPagination={props.scheduleTicketHistory_logList.length !== 0}
            columns={columns}
            NoDataComponent={() => CustomNoDataComponent(props.isLoading, 'No Scheduled Ticket Logs found')}
            getTheadThProps={(state, rowInfo, column, instance) => {
            return {
                style: {
                borderTop: 'none',
                borderBottom: 'none',
                borderRight: 'none',
                textAlign: 'center',
                color: '#566975',
                fontSize: '14px',
                fontWeight: '600',
                whiteSpace: 'nowrap',
                float: 'left',
                }
            }
            }
            }
            LoadingComponent={() =>
            props.isLoading ?
                <div style={{
                display: "block",
                position: "absolute",
                left: 0,
                height: '100%',
                right: 0,
                background: "rgba(255,255,255,0.8)",
                transition: "all .3s ease",
                top: 0,
                bottom: 0,
                textAlign: "center"
                }}
                className="loader_btn_block">
                <ClapSpinner backColor="#89073c" size={40} frontColor="#89073c8a" />
                </div> : null}
            getTrProps={(state, rowInfo, column, instance) => {
            return {
                className: 'react-table-tr-element',
                style: {
                border: '1px solid #c7cdd1',
                transition: '.3s ease-in-out',
                width: '100%',
                marginTop: '5px',
                float: 'left'
                }
            }

            }}
            getTdProps={(state, rowInfo, column, instance) => {

            if (column.id == 'image') {
                return {
                style: {
                    marginTop: '0px'
                }
                }
            } else {
                return {
                className: 'react-td-element',
                }
            }
            }
            }
            getProps={() => {
            return {
                style: {
                border: 'none'
                }
            }
            }}
        />
        </>
    )
}

export default TicketScheduleHistoryTable;