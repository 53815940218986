import React, { useEffect } from 'react'
import withFormHoc from '../../../../../hoc/withReactHookForm/withReactHookForm'
import { useWatch } from 'react-hook-form'
import { useSelector } from 'react-redux'

function SummaryForm(props) {
    
    const { isLoading } = useSelector((state) => state.InventoryModuleReducer)
    const watchwdm = useWatch({
        name: "working_days",
        control: props.control
    })

    const watchFTE = useWatch({
        name: "fte",
        control: props.control
    })

    const watchSQF = useWatch({
        name: "square_footage",
        control: props.control
    })

    const Calculate = (num, field) => {
         
        if (!num) props.setValue(field, 0)
          
        props.setValue(field, (props.total / num).toFixed(2))
        

    }

    useEffect(()=>{
       if(watchFTE){
        Calculate(watchFTE,"monthly_cost_per_fte")
       }
    },[watchFTE])
    useEffect(()=>{
        if(watchSQF){
            Calculate(watchSQF,"cost_per_sq_ft")
        }
    },[watchSQF])

    useEffect(()=>{
     if(watchwdm){
        Calculate(watchwdm,"average_cost_per_day")
     }
    },[watchwdm])



    return (
        <form class="row" noValidate onSubmit={props.handleSubmit((data) => {
            props.onSubmit({ ...data, total_monthly_cost: props.total })
        })}>

            <div class="col-md-12">
                <div class="summary_input_wrapper" style={{ padding: "0px 0px" }}>
                    <table>
                        <tbody>
                            <tr>
                                <td class="p-0">
                                    <h2 style={{ color: "black" }}>SUMMARY</h2>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="summary_mu_field">
                                        <p>Total Monthly Cost</p>
                                        <span class="addsymbol_field">
                                            <input type="number" readOnly value={props?.total} style={{color:" #2c2a2a"}} />
                                            <span class="sym_icon"></span>
                                        </span>
                                        <span>&divide;</span>
                                        <input type="number" id="working_days" name='working_days' style={{color:" #2c2a2a"}} {...props.register(`working_days`, {
                                            required: "This field i required"
                                        })} />

                                        <p>Working days this month</p>
                                        <span></span>
                                        <span class="addsymbol_field">
                                            <input type="number" id="average_cost_per_day" style={{color:" #2c2a2a"}} name='average_cost_per_day' readOnly  {...props.register(`average_cost_per_day`, {
                                            required: "This field i required"
                                        })}  />
                                            <span class="sym_icon"></span>
                                        </span>
                                        <p>is the average cost per day</p>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="summary_mu_field">
                                        <p>Total Monthly Cost</p>
                                        <span class="addsymbol_field">
                                            <input style={{color:" #2c2a2a"}} type="number" readOnly value={props?.total} />
                                            <span class="sym_icon"></span>
                                        </span>
                                        <span>&divide;</span>
                                        <input style={{color:" #2c2a2a"}} type="number" id="fte" name='fte' {...props.register(`fte`, {
                                            required: "This field i required"
                                        })} />
                                        <p>FTE this month</p>
                                        <span></span>
                                        <span class="addsymbol_field">
                                            <input style={{color:" #2c2a2a"}} type="number" id='monthly_cost_per_fte' name='monthly_cost_per_fte' readOnly  {...props.register(`monthly_cost_per_fte`, {
                                            required: "This field i required"
                                        })}  />
                                            <span class="sym_icon"></span>
                                        </span>
                                        <p>is the monthly cost per FTE</p>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="summary_mu_field">
                                        <p>Total Monthly Cost</p>
                                        <span class="addsymbol_field" >
                                            <input style={{color:" #2c2a2a"}} type="number" readOnly value={props?.total} />
                                            <span class="sym_icon"></span>
                                        </span>
                                        <span>&divide;</span>
                                        <input style={{color:" #2c2a2a"}} type="number" id="square_footage" name='square_footage' {...props.register(`square_footage`, {
                                            required: "This field i required"
                                        })} />
                                        <p>Square Footage</p>
                                        <span></span>
                                        <span class="addsymbol_field">
                                            <input style={{color:" #2c2a2a"}} type="number" id='cost_per_sq_ft' name='cost_per_sq_ft' readOnly  {...props.register(`cost_per_sq_ft`, {
                                            required: "This field i required"
                                        })} 
                                         />
                                            <span class="sym_icon"></span>
                                        </span>
                                        <p>is the monthly cost per square foot</p>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="col-sm-12">
                <div class="submitcancel_btn">
                    {/* <button type="button" class="btn theme_def_btn2">Cancel</button> */}
                    {
                        <button type="submit" disabled={!props.state?.year && !props.state?.job} className={isLoading ? "btn cst_btn btn_danger btn-wait" : "btn cst_btn btn_danger"}>
                            {isLoading ? "" : "Submit"}
                        </button> 
                    }

                </div>
            </div>
        </form>
    )
}

export default withFormHoc(SummaryForm)
