import React, { useEffect, useState } from 'react';
import SchedulesTaskMyTicketTable from './SchedulesTaskMyTicketTable';
import { formValueSelector, getFormValues, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Input } from '../../../UI/InputElement/InputElement';
import { convertDateToPickerFormat } from '../../../../../utility/utility';
import { userRoles } from '../../../../../utility/constants/constants';

let SchedulesTaskMyTicketLayout = (props) => {

    const { handleSubmit, pristine, reset, submitting, error } = props;

    const statusOptions = [
        {
            value: 'today',
            label: `Today's Tickets`
        }, {
            value: 'upcoming',
            label: 'Upcoming Tickets'
        }, {
            value: 'past',
            label: 'Past Tickets'
        }
    ];


    const statusOptionsStates = [
        {
            value: 'Open',
            label: 'Open'
        },
        // {
        //     id: 'accepted',
        //     name: 'Accepted'
        // }, 
        {
            value: 'resolved',
            label: 'Resolved'
        }
    ];

    return (
        <>
            <main className="app-content wraper_content inspection_wraper">
                <div className="tab_header search_filter_cont inspection_tab_content ticket_filter">
                    <form onSubmit={handleSubmit}>
                        <input type="hidden" value="something" />
                        <div className="row">
                            <div className="col-xl-2 col-lg-4 col-md-6 col-sm-6">
                                <Input name="scheduledTicketStatus" dataTest="select_leave_type" type="inputSelect" options={statusOptions} placeholder="Search by Tickets" />
                            </div>

                            <div className="col-xl-2 col-lg-4 col-md-6 col-sm-6">
                                <Input name="status" dataTest="select_leave_type" type="inputSelect" options={statusOptionsStates} placeholder="Search by Status" />
                            </div>

                            <div className="col-xl-8 col-lg-6 col-sm-3 col-12">
                                <button data-test="filter_go_btn" style={{ minWidth: '90px' }} className={"btn cst_btn btn_danger mr-2  account_btn"} id="submit" type="submit">{"Go"} </button>
                                <button data-test="filter_reset_btn" style={{ minWidth: '90px' }} onClick={props.resetClicked} className={"btn cst_btn btn-outline-secondary account_btn"} id="reset" type="reset">{"Reset"}</button>
                            </div>
                        </div>
                    </form>
                </div>


                <section className="account_sec inspection_tab_content appcontent_Inner">
                    <div className="quotes_table_content accounts_table_contnet table-responsive">
                        <div className="table quotes_table user_react_table">
                            <SchedulesTaskMyTicketTable
                                {...props}
                            />
                        </div>
                    </div>
                </section>
            </main>
        </>
    );
};

const mapStateToProps = (state) => {
    let scheduledTicketStatus = state.TimeTrackerModReducer.stMyTicketsList_searchValues ? state.TimeTrackerModReducer.stMyTicketsList_searchValues?.scheduledTicketStatus ? state.TimeTrackerModReducer.stMyTicketsList_searchValues.scheduledTicketStatus : "today" :""
    let status=state.TimeTrackerModReducer.stMyTicketsList_searchValues && state.TimeTrackerModReducer.stMyTicketsList_searchValues?.status ? state.TimeTrackerModReducer.stMyTicketsList_searchValues.status : "Open"
    return { initialValues: { scheduledTicketStatus,status}, formStates: getFormValues('searchFLUserMyTicketsListForm')(state) }
}

SchedulesTaskMyTicketLayout = reduxForm({
    form: 'searchFLUserMyTicketsListForm',
    enableReinitialize: true
})(SchedulesTaskMyTicketLayout);
SchedulesTaskMyTicketLayout = connect(mapStateToProps)(SchedulesTaskMyTicketLayout)

export default SchedulesTaskMyTicketLayout;