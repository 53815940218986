import React from 'react';
import Chart from 'react-google-charts';
// import { Link } from 'react-router-dom';
// import { routes } from '../../../utility/constants/constants';
import { CSVLink } from "react-csv";
import CHIServysTable from '../../container/CHIServysContainer/CHIServysTable';
import WeekMonthYearLists from '../Dashboards/weekMonthYearLists';
import { dateForChart, ConvertCHIServysToCSVType } from '../../../utility/utility';
import { ClapSpinner } from 'react-spinners-kit';

const CHIServy = (props) => {
    let barChart;
    if (props.chiChartData) {
        barChart = props.chiChartData.bar_chart;
    }

    let isYear = false;

    //Line Chart
    let dateForLineChart = [];
    let valueForLineChart = [];
    let final = [];

    if(props.chiChartData && props.chiChartData.chi_chart){
        props.chiChartData.chi_chart.forEach(date => {
        dateForLineChart.push(new Date(dateForChart(date[0])))
        if (dateForChart(date[0]).length > 8) {
            isYear = true;
        }
        });
    }

    if(props.chiChartData && props.chiChartData.chi_chart){
        props.chiChartData.chi_chart.forEach(date => {
            date[1] ? valueForLineChart.push(parseInt(date[1])) : valueForLineChart.push(parseInt('0'));
        });
    }

    if(dateForLineChart.length > 0){
        final.push(dateForLineChart.map((e, i) => [e, valueForLineChart[i]]));
        final[0].unshift(['Date', 'Value'])
    }

    //AreaChart

    let dateForAreaChat = [];
    let valueForAreaChart = [];
    let dissatifed = [];
    let neutral = [];
    let delighted = [];
    let areaFinal = [];

    if(props.chiChartData && props.chiChartData.chi_response_chart){
        props.chiChartData.chi_response_chart.forEach(date => {
            dateForAreaChat.push(new Date(dateForChart(date[0])))
        });
    }

    if(props.chiChartData && props.chiChartData.chi_response_chart){
        props.chiChartData.chi_response_chart.forEach(value => {
            valueForAreaChart.push(value[1])
        });
        if(valueForAreaChart.length > 0){
           
            valueForAreaChart.forEach(a => {
                if(a.length < 1){  
                    dissatifed.push(parseInt('0'))
                    neutral.push(parseInt('0'))
                    delighted.push(parseInt('0'))
                } else {
                   
                    a.forEach(e => {  
                        if (e && e.status === 'dissatifed'){
                            dissatifed.push(parseInt(e.count))
                        }
                        // else {
                        //     dissatifed.push(parseInt('0'))
                        //     neutral.push(parseInt('0'))
                        //     delighted.push(parseInt('0'))
                        // }

                        if (e && e.status === 'neutral'){
                           neutral.push(parseInt(e.count))
                        }
                        // else {
                        //     neutral.push(parseInt('0'))
                        //     dissatifed.push(parseInt('0'))
                        //     delighted.push(parseInt('0'))
                        // }

                        if (e && e.status === 'delighted'){
                            delighted.push(parseInt(e.count))
                        }
                        // else {
                        //     dissatifed.push(parseInt('0'))
                        //     neutral.push(parseInt('0'))
                        //     delighted.push(parseInt('0'))
                        // }
                    })
                }
            })
        }
    } 

    if(dateForAreaChat.length > 0){
        areaFinal.push(dateForAreaChat.map((e, i) => [e, dissatifed[i], delighted[i], neutral[i]]));
        areaFinal[0].unshift(['Date', 'Dissatisfied', 'Deligted', 'Neutral'])
    }

    return (
      <main class="app-content wraper_content report_wrapper">
        <section
          class="admin_dashboard report_content_block appcontent_Inner"
          id="chiservys_block"
        >
          <div class="report_content chi_content chi_surveys">
            <div class="sub_head">
              <h2 class="h2_title">Overview</h2>
              <div class="head_btn_block">
                <WeekMonthYearLists {...props} />
                {/* <div class="cst_select_block p-0">
                                <select onChange={(event) => props.dateSelected(event)}  class="custom-select">
                                    <option value="1" selected>Week</option>
                                    <option value="2">Month</option>
                                    <option value="3">Year</option>
                                </select>
                            </div> */}
                <div class="clearfix"></div>
                {/* <div class="filter_block">
                                <div class="dropdown">
                                    <button class="btn dropdown-toggle filter_btn" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        Filters
                                    </button>
                                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                        <a class="dropdown-item" href="#">Action</a>
                                        <a class="dropdown-item" href="#">Another action</a>
                                        <a class="dropdown-item" href="#">Something else here</a>
                                    </div>
                                </div>
                            </div> */}
                {/* <div class="export_btn">
                  <button class="btn btn_danger">Export CSV</button>
                </div> */}
              </div>
            </div>
            {/* <!-- chart --> */}
            <div class="chi_grid_view">
              <div class="row">
                <div class="col-md-12 col-xl-7">
                  <div class="grid_view">
                    <div class="grid_card tile">
                  {/*    <h4 class="chart_title">{props.chiChartData.chi_score_average && props.chiChartData.chi_score_average.includes('-') ? props.chiChartData.chi_score_average.replace(/-/gi, " ") :  props.chiChartData.chi_score_average === 'NaN' ? '0' : props.chiChartData.chi_score_average}</h4> */}
                      <h4 class="chart_title">{props.chiChartData.chi_score_average && props.chiChartData.chi_score_average === 'NaN' ? '0' : props.chiChartData.chi_score_average}</h4>
                      <p class="cst_para">Score</p>
                    </div>
                    <div class="grid_card tile">
                      <h4 class="chart_title">
                        {props.chiChartData.chi_total_response}
                      </h4>
                      <p class="cst_para">Total Responses</p>
                    </div>
                    <div class="grid_card tile">
                      <h4 class="chart_title">
                        {props.chiChartData.chi_total_sent}
                      </h4>
                      <p class="cst_para">Total Sent</p>
                    </div>
                    <div class="grid_card tile">
                      <h4 class="chart_title">
                        {props.chiChartData.chi_response_rate}%
                      </h4>
                      <p class="cst_para">Response Rate</p>
                    </div>
                  </div>
                </div>
                <div class="col-xl-5">
                  {props.chiChartData.chi_survey_response_delighted_rate ===
                    0 &&
                  props.chiChartData.chi_survey_response_dissatifed_rate ===
                    0 &&
                  props.chiChartData.chi_survey_response_neutral_rate === 0 ? (
                    ""
                  ) : (
                    <div class="grid_card tile d-flex" style={{ padding: "0" }}>
                      {/* <figure class="chi_scores_graph">
                                        <img src="../images/thumbnails/chi_scores.png" alt="Image" />
                                    </figure> */}
                      <div className="col-md-4" style={{padding: "0"}}>
                        <Chart
                          width={"170px"}
                          height={"170px"}
                          chartType="PieChart"
                          loader={<div>Loading Chart</div>}
                          data={[
                            ["Rate", "Value"],
                            [
                              "Delighted Rate",
                              barChart
                                ? barChart.chi_survey_response_delighted_rate
                                : null,
                            ],
                            [
                              "Neutral Rate",
                              barChart
                                ? barChart.chi_survey_response_neutral_rate
                                : null,
                            ],
                            [
                              "Dissatifed Rate",
                              barChart
                                ? barChart.chi_survey_response_dissatifed_rate
                                : null,
                            ]
                            ,
                          ]}
                          options={{
                            legend: "none",
                            colors: ['green', 'yellow', 'red'],
                            tooltip: {
                              trigger: "none",
                            },
                            // title: 'My Daily Activities',
                          }}
                        />
                        {/* <p className='chiScoreStyle'>CHI Score</p> */}
                      </div>
                      {props.chiChartData.success ? (
                        <div
                          className="col-md-2"
                          style={{
                            marginTop: "10%",
                            lineHeight: "32px",
                            marginRight: "30px",
                          }}
                        >
                          <p className="scoreText">Delighted</p>
                          <p className="scoreText"> Neutral </p>
                          <p className="scoreText"> Dissatisfied</p>
                        </div>
                      ) : (
                        ""
                      )}
                      <div className="col" style={{ marginTop: "5%" }}>
                        <Chart
                          width={"150px"}
                          height={"100px"}
                          chartType="BarChart"
                          loader={<div>Loading Chart</div>}
                          data={[
                            [
                              "Rate",
                              "Value",
                              { role: "style" },
                              {
                                sourceColumn: 0,
                                role: "annotation",
                                type: "string",
                                calc: "stringify",
                              },
                            ],
                            [
                              "",
                              barChart
                                ? barChart.chi_survey_response_delighted_rate
                                : 0,
                              "green",
                              null,
                            ],
                            [
                              "",
                              barChart
                                ? barChart.chi_survey_response_neutral_rate
                                : 0,
                              "yellow",
                              null,
                            ],
                            [
                              "",
                              barChart
                                ? barChart.chi_survey_response_dissatifed_rate
                                : 0,
                              "red",
                              null,
                            ]
                          ]}
                          options={{
                            legend: { position: "none" },

                            width: 150,
                            height: 120,
                            bar: { groupWidth: "25%" },
                            hAxis: {
                              gridlines: {
                                color: "transparent",
                              },
                              textPosition: "none",
                            },
                            vAxis: {
                              gridlines: {
                                color: "transparent",
                              },
                            },
                          }}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div class="graph_tabs">
                        <div class="row">
                            <div class="col-xl-6 chart_cont_box">
                                <div class="tile chart-box-botL">
                                    <h6 class="chart_title">CHI Trends</h6>
                                    <div class="chart-img">
                                        {isYear ? 
                                            <Chart
                                            width={'550px'}
                                            height={'200px'}
                                            chartType="LineChart"
                                            loader={<div>Loading Chart</div>}
                                            data={final[0]}
                                            
                                            options={{
                                                legend: { position: 'none', alignment: 'end' },
                                                width: 512,
                                                height:200,
                                                hAxis: {
                                                format: 'MMM dd YYY',
                                                gridlines: {
                                                    color: 'transparent'
                                                },
                                                },
                                                vAxis: {
                                                // gridlines: {
                                                //     color: 'transparent'
                                                // },
                                                },
                                                curveType: 'function',
                                            }}
                                            /> : <Chart
                                            width={'550px'}
                                            height={'200px'}
                                            chartType="LineChart"
                                            loader={<div>Loading Chart</div>}
                                            data={final[0]}
                                            
                                            options={{
                                                legend: { position: 'none', alignment: 'end' },
                                                width: 500,
                                                height:200,
                                                hAxis: {
                                                format: 'MMM YYY',
                                                gridlines: {
                                                    color: 'transparent'
                                                },
                                                },
                                                vAxis: {
                                                // gridlines: {
                                                //     color: 'transparent'
                                                // },
                                                },
                                                curveType: 'function',
                                            }}
                                            /> }
                                        </div>
                                </div>
                            </div>
                            <div class="col-xl-6 chart_cont_box">
                                <div class="tile chart-box-botL">
                                    <h6 class="chart_title">Response Trends</h6>
                                    <div class="chart-img">
                                        <Chart
                                            width={'500px'}
                                            height={'200px'}
                                            chartType="AreaChart"
                                            loader={<div>Loading Chart</div>}
                                            data={areaFinal[0]}
                                            options={{
                                            hAxis: { },
                                            vAxis: { minValue: 0 },
                                            // For the legend to fit, we make the chart area smaller
                                            chartArea: { width: '50%', height: '70%' },
                                            // lineWidth: 25
                                            series: {
                                               0: {
                                                   color: 'red'
                                               },
                                               1: {
                                                   color: 'green',
                                               },
                                               2: {
                                                   color: 'yellow'
                                               }

                                                },
                                            }
                                        }
                                            />                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            <div class="chi_response">
              <div class="tile">
                <div class="response_head export_chi_btn">
                  <h5 class="h5_title">Responses</h5>
                  <div class="export_btn">
                    <CSVLink
                      data={ConvertCHIServysToCSVType(props.chiServyList)}
                      filename={"CHI List.csv"}
                      target="_blank"
                    >
                      <button class="btn btn_danger">Export CSV</button>
                    </CSVLink>
                  </div>
                  {/* <div class="cst_select_block p-0">
                                    <select class="custom-select">
                                        <option selected>All</option>
                                        <option value="1">One</option>
                                        <option value="2">Two</option>
                                        <option value="3">Three</option>
                                    </select>
                                </div> */}
                </div>

                <CHIServysTable
                  previewChiClicked={props.previewChiClicked}
                  {...props}
                />
              </div>
            </div>
          </div>
        </section>
      </main>
    );
}

export default CHIServy;