import React from 'react';
import '../../../../../styles/custom.css';
import { FaArrowDown, FaArrowUp } from 'react-icons/fa';
import ReactTable from 'react-table-v6';
import { CustomNoDataComponent} from '../../../../../utility/utility';

const TimesheetHoildayType = (props) => {

    const columns = [
        {
            Header: <><span>Job</span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /></>,
            accessor: 'job',
            sortable: false, // Disable sorting for this column
            show:props?.HoursJobs?.length !== 0,
        },
        {
            Header: <><span> Pay </span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /></>,
            accessor: 'holidaypay',
            sortable: false, // Disable sorting for this column
            show:props?.HoursJobs.length !== 0,
        },
      ];

      const data = props?.HoursJobs && props?.HoursJobs.map((item) => {
    return {

      job: item?.account?.name ? item?.account?.name : '−',
      holidaypay: item.status =="clockedOut" ? item?.customer_pay === true ? "Customer Pay" : item?.customer_pay !==null || item?.customer_pay !=="null" && item?.customer_pay=== false  ? "Pegasus Pay" :"-" :"-",
    }

});
    return (
        <>
        <ReactTable
            data={data}
            // loading={props.isTimeSheetList}
            columns={columns}
            // style={{marginTop: '10px'}}
            showPaginationTop={false}
            minRows={props?.HoursJobs?.length}
            showPaginationBottom={false}
            NoDataComponent={() => CustomNoDataComponent(props.isTimeSheetList, 'No Timesheet List Found')}
            getTrProps={(state, rowInfo, column, instance) => {
                console.log(column);
                return {
                    className: 'react-table-tr-element',
                    style: {
                        border: '1px solid #c7cdd1',
                        transition: '.3s ease-in-out',
                        width: '100%',
                        // borderRadius: '4px',
                        // height: '80px',
                        marginTop: '5px',
                        float: 'left'
                    }
                }

            }}
            getTdProps={(state, rowInfo, column, instance) => {

                if (column.id == 'image') {
                    return {
                        style: {
                            marginTop: '0px'
                        }
                    }
                } else {
                    return {
                        className: 'react-td-element',
                    }
                    //  {
                    //   style: {
                    //     borderTop: 'none',
                    //     verticalAlign: 'middle',
                    //     fontSize: '14px',
                    //     color: '#2b3034',
                    //     marginTop: '10px !important',
                    //     textAlign: 'center',
                    //   }
                    // }
                }
            }
            }
            // getPaginationProps={() => {
            //     return {
            //         style: {
            //             marginTop: '50px',
            //             border: 'none',
            //             boxShadow: 'none'
            //         }
            //     }
            // }
            // }
            getProps={() => {
                return {
                    style: {
                        border: 'none'
                    }
                }
            }}
        />
    </>
    )
}


export default TimesheetHoildayType;