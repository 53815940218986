import React, { useState } from 'react';
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import './ImageSlider.css';

class ImageSlider extends React.Component {

    componentWillMount = () => {
        this.fullScreenClickHandler = null;
        this.currentHeight = 450;
    }

    componentDidMount = () => {
        // let elements = document.querySelectorAll(".image-gallery-image img")
        //  
        // for (var i = 0; i<elements.length; i++) {
        //     elements[i].style.visibility = "hidden";
        // }
        // elements.forEach(ele => {
        //     ele.style.height = "450px";
        // })
    }

    imageObjects = (imageUrls, base64) => {
        let imageObjects = [];
        // let images = store.attachments ? store.attachments : [];
        let images = imageUrls.length !== 0 ? imageUrls : [];
        let baseImages = base64 && base64.length !== 0 ? base64 : [];
        // let images = [1,2,3,4,5]
        images.map((ele, index) => {
            return imageObjects.push(
                {
                    original: ele.photo_urls.original,
                    thumbnail: ele.photo_urls.medium,
                    // "/customImages/listing_placeholder.png"
                }
            );
        })

        baseImages.map((ele, i) => {
            return imageObjects.push(
                {
                    original: ele,
                    thumbnail: ele
                }
            );
        })
        return imageObjects;
    }

    imageClicked = (e, val) => {
        console.log("imageClicked");
        // let elements = document.querySelectorAll(".image-gallery-image img")
        // elements.forEach(ele => {
        //     ele.style.height = "450px";
        // })
        // this.fullScreenClickHandler();
        //  
    }

    renderFullscreenButton = (onClick, isFullscreen) => {
        this.fullScreenClickHandler = onClick;
        return (
            <button
                type='button'
                className={
                    `image-gallery-fullscreen-button${isFullscreen ? ' active' : ''}`}
                onClick={this.imageClicked}
            />
        );
    }

    render() {

        return (<ImageGallery items={this.imageObjects(this.props.imageUrls, this.props.base64)}
            showThumbnails={this.props.showThumbnails}
            useBrowserFullscreen={true}
            autoPlay={this.props.autoPlay}
            showPlayButton={false}
            showFullscreenButton={this.props.showFullscreenButton}
            startIndex={this.props.startIndex}
        // renderFullscreenButton={this.renderFullscreenButton} 
        />
        );
    }
}

export default ImageSlider;
