import React, { useEffect } from 'react'
import NewTeamChecklistLogForm from '../../../../../component/FormAuditModule/TeamManagement/TeamChecklistLog/NewTeamChecklistLogForm/newTeamChecklistLogForm'
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useDispatch, useSelector } from 'react-redux';
import { addChecklistLog, editChecklistLog, getManagersList, getSingleChecklistLog, getSingleSupervisorDetails } from '../../../../../../redux/actions/FormAuditModAction/TeamManagementAction/teamManagementAction';
import storage from '../../../../../../utility/storage';

function NewTeamChecklistLogFormContainer(props) {

  const {location:{pathname}}=useHistory()
  const {checklistLog}=useSelector((state)=>state.FormAuditModReducer)
  console.log(checklistLog);
  const {id} = useParams();
  const dispatch=useDispatch()
  const userId = storage.get("user")
    const isEdit=Boolean(pathname.split("/")[2] !=="view" && id)
    const isView=Boolean(pathname.split("/")[2].includes("view"))
   const defaultForm= {
   data:[{
    manager_id: checklistLog && checklistLog.manager ? checklistLog.manager.id : "",
    tc_issued: checklistLog && checklistLog.tc_issued ? checklistLog.tc_issued :"",
    tc_returned:checklistLog && checklistLog.tc_returned ? checklistLog.tc_returned :"",
    returned:checklistLog && checklistLog.returned ? checklistLog.returned :"",
   }]}

   useEffect(()=>{
     if(isEdit || isView && id){
       dispatch(getSingleChecklistLog(id))
     }
     dispatch(getManagersList({supervisor : true}))
   },[])

   const onsubmit = (values) => {
    let value = [...values.data]
    
    const updateReturned = value[0].returned.split("%")[0]
    value[0].returned = updateReturned

    if(isEdit){
      const data =  value.map((item) => ({
            ...item,
            returned:item.returned.split("%")[0],
            id,
            user_id :userId.id
          }
      ))  
      
      dispatch(editChecklistLog({data}))
    }else{
      const data = value.map((item)=>({...item,returned:item.returned.split("%")[0]}))
      dispatch(addChecklistLog({data}))
    }
   }

   
  return (
    <NewTeamChecklistLogForm 
    elements={defaultForm}
    formSubmittedCallback={onsubmit}
    isEdit={isEdit}
    isView={isView}
    modalUpdate={true}
    />
  )
}

export default NewTeamChecklistLogFormContainer