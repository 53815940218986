import React, { Component } from 'react';
import moment from "moment";
import { connect } from 'react-redux';
import * as actions from '../../../../redux/actions/index';
import { closeModel } from '../../../../utility/utility';
import { TimezoneId, routes } from '../../../../utility/constants/constants';
import { reset } from 'redux-form';
import isEmpty from '../../../../utility/IsEmptyValidator';
import $ from "jquery";
import { validateAccountUsers } from '../../../../utility/validator/submit';
import AddAreaForm from '../../../component/Accounts/AccountsArea/AccountAreaForm/AddAreaForm';
import { TimeZones } from '../../../../utility/constants/constants';
import errorHandler from '../../../../utility/errorHandler/errorHandler';

const cloneDeep = require('clone-deep');

class AddArea extends Component {

  constructor(props) {
    super(props);

    this.state = {
      currentAssosciatedUserEmail: '',
      areaErrorMessage: '',
      areaDayErrorMessage: "",
      selectedHolidaysIds: []
    }
    this.resetDayError = this.resetDayError.bind(this);
  }

  componentDidMount() {
    this.props.getAccount(this.props.match.params.accountId);
    if (isEmpty(this.props.areaTypes)) {
      this.props.getAreaTypes();
    }
    if (isEmpty(this.props.floorList)) {
      this.props.getFloors();
    }
    if (!this.props.holidaysList) {
      this.props.getHolidaysList();
    }
    if (isEmpty(this.props.deficiencies)) {
      this.props.getAllDeficiencies();
    }
  }

  handleSubmit = (values, a, b) => {
    console.log('moment', moment.tz("12:00 AM", 'America/Chicago').format('hh:mm A'));
    let isValid = true;
    let tempForm = cloneDeep(values);
    let times = [];
    let duplicateTime = false;
    let days = [];

    // if (tempForm['holiday_ids']) {
    //   tempForm['holiday_ids'] = tempForm['holiday_ids'].map((a, i) => {
    //     return a.value
    //   });
    // }
    tempForm['holiday_ids'] = this.state.selectedHolidaysIds;
    if (!tempForm.area?.name?.trim()) {
      errorHandler('Area Name is required')
      return false
    }
    if (!tempForm.area?.floor_id) {
      errorHandler('Floor Number is required')
      return false
    }
    if (!tempForm.area?.area_type_id) {
      errorHandler('Area Type is required')
      return false
    }


    if (tempForm.area.days && tempForm.area.days.length) {
      tempForm.area.days.map((day, index) => {
        if (day) days.push(index)
      });
      if (days.length) tempForm.area.days = days;
      else {
        isValid = false;
        this.setState({ areaDayErrorMessage: "Please select At least one Day" });
      }
    } else {
      isValid = false;
      this.setState({ areaDayErrorMessage: "Please select At least one Day" });
    }

    if (tempForm.area.timeslots_attributes) {
      tempForm.area.timeslots_attributes.map(e => {
        if (times.includes(e.start_time)) duplicateTime = true;
        else times.push(e.start_time)
      });
      if (tempForm.area.timeslots_attributes.length < 2 && !tempForm.area.timeslots_attributes[0].start_time) delete tempForm.area.timeslots_attributes
    }
    if (duplicateTime) {
      isValid = false;
      this.setState({ areaErrorMessage: "Duplicate time not allowed" });
      setTimeout(() => this.setState({ areaErrorMessage: "" }), 2000)
    }
    else {
      if (tempForm.area.timeslots_attributes) {
        tempForm.area.timeslots_attributes.map(e => {
          const date = moment().format("MM/DD/YYYY") + " " + e.start_time;
          const hours = this.props.account.user.timezone === TimeZones.PST ? 7 : 5;
          // e.start_time_utc = moment(moment().format("MM-DD-YYYY") + " " + e.start_time, "MM-DD-YYYY HH:mm A").add(hours, "hours").format("hh:mm A")
          // e.start_time_utc = moment.utc(moment(date)).format("hh:mm A");
          
          const timezone= this.props?.account?.user?.timezone
          e.start_time_utc=moment.tz(e.start_time,"HH:mm A",timezone).utc().format("hh:mm A")
        });
      }
      if (isValid) {
        tempForm.area.schedule_at_utc = moment.utc(tempForm.area.schedule_at).format("MM-DD-YYYY");
        tempForm.area.schedule_at = moment(tempForm.area.schedule_at).format("MM-DD-YYYY");
        if (tempForm.area.deficiency_ids && tempForm.area.deficiency_ids.length > 0) {
          const defIds = [];
          tempForm.area.deficiency_ids && tempForm.area.deficiency_ids.length > 0 && tempForm.area.deficiency_ids.map((t) => {
            defIds.push(parseInt(t.value))
          })
          // defIds.push(parseInt(1)); //Commented this for getting additional Others area inside the deficiency in production
          tempForm.area.deficiency_ids = defIds;
        }
        this.props.createArea(tempForm, this.props.match.params.accountId);
      }
    }
  };
  resetDayError() {
    this.setState({ areaDayErrorMessage: "" })
  }

  componentWillUnmount() {
    //this.props.resetObjects();
  }

  holidaySelected = (id) => {
    let selectedIds = cloneDeep(this.state.selectedHolidaysIds);

    let searchIndex = this.state.selectedHolidaysIds.findIndex(function (s) {
      return s === id
    });

    if (searchIndex === -1) {
      this.setState({
        selectedHolidaysIds: [...selectedIds, id]
      })
    } else {
      selectedIds.splice(searchIndex, 1);
      this.setState({
        selectedHolidaysIds: selectedIds
      })

    }
  }

  setCheckValue = (id) => {
    let searchIndex = this.state.selectedHolidaysIds.findIndex(function (s) {
      return s === id
    });
    if (searchIndex > -1) {
      return true
    } else {
      return false
    }
  }

  selectAllClicked = (e) => {
    if (e.target.checked) {
      let selectedIds = []
      this.props.holidaysList.map(h => {
        return selectedIds.push(h.id)
      })
      this.setState({
        selectedHolidaysIds: selectedIds,
        selectAll: true,
        selectNone: false,
      })
    } else {
      this.setState({
        selectedHolidaysIds: [],
        selectAll: false
      })
    }
  }

  selectNoneClicked = (e) => {
    if (e.target.checked) {
      this.setState({
        selectedHolidaysIds: [],
        selectNone: true,
        selectAll: false
      })
    } else {
      this.setState({
        selectNone: false
      })
    }
  }

  render() {

    return (
      <AddAreaForm
        {...this.props}
        {...this.state}
        setCheckValue={this.setCheckValue}
        holidaySelected={this.holidaySelected}
        onSubmit={this.handleSubmit}
        resetDayError={this.resetDayError}
        selectAllClicked={this.selectAllClicked}
        selectNoneClicked={this.selectNoneClicked}
        areaDayErrorMessage={this.state.areaDayErrorMessage}
        areaErrorMessage={this.state.areaErrorMessage}
      />
    )
  }
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.adminOrSuperAdminReducer.isLoading,
    account: state.adminOrSuperAdminReducer.account,
    areaTypes: state.adminOrSuperAdminReducer.areaTypes,
    floorList: state.adminOrSuperAdminReducer.floorList,
    holidaysList: state.adminOrSuperAdminReducer.holidaysList,
    isHolidaysLoading: state.adminOrSuperAdminReducer.isHolidaysLoading,
    deficiencies: state.adminOrSuperAdminReducer.deficiencies,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    getHolidaysList: () => dispatch(actions.getHolidaysList()),
    getAreaTypes: () => dispatch(actions.getAreaTypes()),
    getFloors: () => dispatch(actions.getFloors()),
    getAccount: (id) => dispatch(actions.getEditAccount(id)),
    createArea: (form, id) => dispatch(actions.createArea({ body: form, id })),
    getAllDeficiencies: () => dispatch(actions.getAllDeficiencies()),

  }
}


export default connect(mapStateToProps, mapDispatchToProps)(AddArea);