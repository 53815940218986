import { buildingProfileActionTypes } from '../BuildingProfileAction/BuildingProfileTypes';
import * as API from '../../../../API/FormAuditAPI/buildingProfileAPI';
import { routes } from '../../../../utility/constants/constants';
import SuccessAlert from '../../../../utility/successAlert/successAlert';
import history from '../../../../router/history';



export const getSingleJobCardDetails = (id) => {
    return (dispatch, getState) => {
        
        dispatch({
            type: buildingProfileActionTypes.GET_SINGLE_JOB_CARD,
            payload: API.getSingleJobCardAPI(id) 
                .then((response) => {
                    
                    if (response.data.success) {
                        // we need to return data here 
                        
                        return response.data.data
                        
                    };
                })
                .catch((error) => {
                    
                    console.log(error);
                }),
        });
    };
};


export const addJobCardDetails = (data) => {
    
    return (dispatch, getState) => {
        
        dispatch({
            type: buildingProfileActionTypes.ADD_SINGLE_JOB_CARD,
            payload: API.addJobCardAPI(data) 
                .then((response) => {
                    
                    if (response.data.success) {
                        // we need to return data here 
                        SuccessAlert('Job Core Cards Created Succesfully')
                      history.push(routes.JOB_CORE_MAP_CARD);   
                        return response.data; 
                        
                    };
                })
                .catch((error) => {
                    
                    console.log(error);
                }),
        });
    };
};


export const editJobCardDetails = (data) => {
    
    return (dispatch, getState) => {
        
        dispatch({
            type: buildingProfileActionTypes.EDIT_SINGLE_JOB_CARD,
            payload: API.editJobCardAPI(data) 
                .then((response) => {
                    
                    if (response.data.success) {
                        // we need to return data here 
                        SuccessAlert('Job Core Cards Updated Succesfully')
                      history.push(routes.JOB_CORE_MAP_CARD);   
                        return response.data; 
                        
                    };
                })
                .catch((error) => {
                    
                    console.log(error);
                }),
        });
    };
};

export const getAllJobCards = (params) => {
    return (dispatch, getState) => {
        dispatch({
            type: buildingProfileActionTypes.GET_ALL_JOB_CARD,
            payload: API.getAllJobCardAPI(params)
                .then((response) => {
                    if (response.data.success) {
                        
                        return {
                            jobcard_list: response.data.data,
                            jobcard_total_pages: response.data.totalPages,
                            jobcard_no_of_rows: response.data.limit,
                            total_jobcard_count: response.data.total,
                            jobcardCurPage: params.page - 1,
                        };
                    };
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
  };

  export const jobCardPageClicked = (data) => {
    return {
        type: buildingProfileActionTypes.JOB_CARD_PAGE_CLICKED,
        payload: data,
    };
  }

  export const addBuildingProfile = (data) => {
     
    return (dispatch, getState) => {
        
        dispatch({
            type: buildingProfileActionTypes.ADD_SINGLE_BUILDING_PROFILE,
            payload: API.addBuildingProfile(data) 
                .then((response) => {
                    
                    if (response.data.success) {
                        // we need to return data here 
                        SuccessAlert('Building Profile Created Succesfully')
                      history.push(routes.BUILDING_PROFILE);   
                        return response.data; 
                        
                    };
                })
                .catch((error) => {
                    
                    console.log(error);
                }),
        });
    };
};

export const getSingleBuildingProfile = (id) => {
     
    return (dispatch, getState) => {
        
        dispatch({
            type: buildingProfileActionTypes.GET_SINGLE_BUILDING_PROFILE,
            payload: API.getSingleBuildingProfile(id) 
                .then((response) => {
                    
                    if (response.data.success) {
                        // we need to return data here 
                        
                        return response.data.data
                        
                    };
                })
                .catch((error) => {
                    
                    console.log(error);
                }),
        });
    };
};

export const getAllBuildingProfile = (params) => {
    return (dispatch, getState) => {
        dispatch({
            type: buildingProfileActionTypes.GET_ALL_BUILDING_PROFILE,
            payload: API.getAllBuildingProfile(params)
                .then((response) => {
                    if (response.data.success) {
                        return {
                            buildingProfile_list: response.data.data,
                            buildingProfile_total_pages: response.data.totalPages,
                            buildingProfile_no_of_rows: response.data.limit,
                            total_buildingProfile_count: response.data.total,
                            buildingProfileCurPage: params.page - 1,
                        };
                    };
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
  };
  
  export const buildingProfilePageClicked = (data) => {
    return {
        type: buildingProfileActionTypes.BUILDING_PROFILE_PAGE_CLICKED,
        payload: data,
    };
  };

  export const editBuildingProfile = (data) => {
     
    return (dispatch, getState) => {
        
        dispatch({
            type: buildingProfileActionTypes.EDIT_SINGLE_BUILDING_PROFILE,
            payload: API.editBuildingProfile(data) 
                .then((response) => {
                    
                    if (response.data.success) {
                        // we need to return data here 
                        SuccessAlert('Building Profile Updated Succesfully')
                        history.push(routes.BUILDING_PROFILE);   
                        return response.data; 
                        
                    };
                })
                .catch((error) => {
                    
                    console.log(error);
                }),
        });
    };
};
  
  