import React, { Component } from 'react';
import { routes } from '../../../../utility/constants/constants';
import LeaveListLayout from '../../../component/TimeTrackerModuleComponent/LeavesTrackComponent/LeaveListLayout';
import * as actions from '../../../../redux/actions/index';
import { connect } from 'react-redux';
import { reset } from 'redux-form';
import { animateScroll as scroll } from 'react-scroll'
import storage from '../../../../utility/storage';
import { convertDateToDifferentTZ } from '../../../../utility/utility';
import { tzUTCDateConverterTool_V2 } from '../../../../utility/TimezoneOperations/timezoneUtility';

var moment = require('moment');
const cloneDeep = require('clone-deep');

class LeaveListContainer extends Component {
    constructor(props) {

        super(props);
        this.state = {

        }

    }

    componentDidMount() {
        scroll.scrollToTop();
    }

    componentDidUpdate(prevProps, prevState) {
        const user = storage.get('user');
        if (user) {
            if (prevProps.user.selected_mode !== user.selected_mode) {
                this.props.leaveListPageClicked(0);
            }
        }
    }

    resetClicked = () => {
        const page = 1
        const limit = this.props.leavesList_no_of_rows
        this.props.reset('searchLeaveList');
        this.props.leaveListPageClicked(0)
        this.props.getAllLeaveList({ page, limit })
    }

    searchLeavesListClicked = (value) => {
        debugger
        const values = cloneDeep(value)
        const page = 1
        const limit = this.props.leavesList_no_of_rows

        let status = ''
        let leaveTypes = ''
        let fromDate = ''
        let toDate = ''
        // let manufacture = ''
        // let expiration_date = ''
        // let supplier = ''

        let sendGridData = {
            page: page,
            limit: limit,
        }

        if (values.status) {
            values.status = values.status.trim()
            status = values.status
        }
        if (values.leaveTypes) {
            values.leaveTypes = values.leaveTypes && values.leaveTypes.trim()
            leaveTypes = values.leaveTypes
        }
        if (values.fromDate) {
            fromDate = tzUTCDateConverterTool_V2(values.fromDate, 'from');
        }
        if (values.toDate) {
            toDate = tzUTCDateConverterTool_V2(values.toDate, 'to');
        }
        //   if(values.manufacture) {
        //     values.manufacture = values.manufacture.trim()
        //     manufacture = values.manufacture
        //   }
        //   if(values.expiration_date) {
        //     values.expiration_date = convertDateToDifferentTZ(values.expiration_date, 'to');
        //     expiration_date = values.expiration_date.split('T')[0];
        //   }  
        //   if(values.supplier) {
        //     values.supplier = values.supplier.trim()
        //       supplier =  values.supplier 
        //   }

        if (status !== '') {
            sendGridData.status = status;
        }
        if (leaveTypes !== '') {
            sendGridData.type = leaveTypes;
        }
        if (fromDate !== '') {
            sendGridData.from = fromDate;
        }
        if (toDate !== '') {
            sendGridData.to = toDate;
        }

        this.props.getAllLeaveList(sendGridData, values);
    }

    addLeaveRequestFormClicked = () => {
        this.props.history.push(routes.NEW_LEAVE);
    }

    updateLeaveRequestFormClicked = (id) => {
        this.props.history.push(`/leaves/edit-leave/${id}`);
    }

    previewLeaveRequestPageClicked = (id) => {
        this.props.history.push(`/leaves/preview/${id}`);
    }

    deleteLeave = (id) => {
        this.props.deleteLeave(id);
    }

    handleFrequentTimeTracker_openDeleteModal = (value) => {
        this.props.handleFrequentTimeTracker_openDeleteModal(value);
    }

    handleFrequentTimeTracker_closeDeleteModal = () => {
        this.props.handleFrequentTimeTracker_closeDeleteModal();
    }

    render() {
        return (
            <LeaveListLayout
                {...this.props}
                onSubmit={this.searchLeavesListClicked}
                resetClicked={this.resetClicked}
                addLeaveRequestFormClicked={this.addLeaveRequestFormClicked}
                updateLeaveRequestFormClicked={this.updateLeaveRequestFormClicked}
                previewLeaveRequestPageClicked={this.previewLeaveRequestPageClicked}
                deleteLeave={this.deleteLeave}
                handleFrequentTimeTracker_openDeleteModal={this.handleFrequentTimeTracker_openDeleteModal}
                handleFrequentTimeTracker_closeDeleteModal={this.handleFrequentTimeTracker_closeDeleteModal}
            />
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.authReducer.user,
        isLoading: state.TimeTrackerModReducer.isLoading,
        leavesList: state.TimeTrackerModReducer.leavesList,
        isLeavesList: state.TimeTrackerModReducer.isLeavesList,
        leavesList_no_of_rows: state.TimeTrackerModReducer.leavesList_no_of_rows,
        leavesList_curPage: state.TimeTrackerModReducer.leavesList_curPage,

        openFrequentTimeTracker_deleteModal: state.TimeTrackerModReducer.openFrequentTimeTracker_deleteModal,
        deleteFrequentTimeTracker_object: state.TimeTrackerModReducer.deleteFrequentTimeTracker_object,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        reset: (searchLeaveList) => dispatch(reset(searchLeaveList)),  // requires form name
        getAllLeaveList: (params, values) => dispatch(actions.getAllLeaveList(params, values)),
        leaveListPageClicked: (page) => dispatch(actions.leaveListPageClicked(page)),
        deleteLeave: (id) => dispatch(actions.deleteLeave(id)),
        handleFrequentTimeTracker_openDeleteModal: (value) => dispatch(actions.handleFrequentTimeTracker_openDeleteModal(value)),
        handleFrequentTimeTracker_closeDeleteModal: () => dispatch(actions.handleFrequentTimeTracker_closeDeleteModal()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LeaveListContainer);