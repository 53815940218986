import React, { useState } from 'react';
import UserTable from './userTable';
import { Input } from '../UI/InputElement/InputElement';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { userSearchValidator as validate } from '../../../utility/validator/validator';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import { convertUsersDataToCsvType } from '../../../utility/utility';
import { CSVLink, CSVDownload } from "react-csv";
import '../../../styles/custom.css';
import { RolesOptions, SearchRolesOptions, UserModes } from '../../../utility/constants/constants';

let User = (props) => {

    
    console.log('roleOptions', SearchRolesOptions);

    const { handleSubmit, pristine, reset, submitting, error } = props;

    return (
        <>
            <main className="app-content wraper_content inspection_wraper">
                <div className="tab_header search_filter_cont inspection_tab_content appcontent_inner user_filter" id="user_filter">
                    <form onSubmit={handleSubmit}>
                        <input type="hidden" value="something" />
                        <div className="row">
                            <div className="col-xl-2 col-lg-4 col-md-12 media_padding">
                                <div className="app-search">
                                    <Input dataTest="creaters_filter" className="fil_search_input input-modifier" name="name" type="search" placeholder="Creators" />
                                    <button className="app-search__button">
                                        <i class="fa fa-search" aria-hidden="true"></i>
                                    </button>
                                </div>
                            </div>
                            <div className="col-xl-2 col-lg-4 col-md-12 media_padding">
                                <div className="app-search">
                                    <Input dataTest="email_filter" className="fil_search_input input-modifier" name="email" type="search" placeholder="Search By Email" />
                                    <button className="app-search__button">
                                        <i class="fa fa-search" aria-hidden="true"></i>
                                    </button>
                                </div>
                            </div>
                            <div className="col-xl-2 col-lg-4 col-md-12 media_padding">
                            <div className="app-search">
                            <Input dataTest="mode_filter" name="mode" type="select" placeholder="Mode" options={UserModes} className="custom-select input-modifier add_user_select fil_search_input" />
                             <button className="app-search__button">
                                        <i class="fa fa-search" aria-hidden="true"></i>
                                    </button>
                          </div>
                            </div>
                            <div className="col-xl-2 col-lg-4 col-md-6 col-sm-6 media_padding">
                                     <div className="app-search">
                                     <Input dataTest="mode_filter" name="role" type="select" placeholder="Search By Role" options={SearchRolesOptions} className="custom-select input-modifier add_user_select fil_search_input" />
                                      <button className="app-search__button">
                                                 <i class="fa fa-search" aria-hidden="true"></i>
                                             </button>
                                       </div>
                                     </div>

                         <div class="col-xl-2 col-lg-6 col-md-6 col-sm-6 col-6 media_padding">
                            <div class="more_filter_block">
                                
                                <button data-test="users_go_btn" style={{ minWidth: '90px' }} className={"btn cst_btn btn_danger mr-2"} id="submit" type="submit">{"Go"} </button>
                                <button data-test="users_reset_btn" style={{ minWidth: '90px' }} onClick={props.resetClicked} className={"btn cst_btn btn-outline-secondary"} id="reset" type="reset">{"Reset"} </button>
                            </div>
                            </div> 
                        
                           
                            <div className="col-xl-2 col-lg-6 col-md-6 col-sm-6 col-6 text-right new_user">
                                <a data-test="new_user_btn" onClick={props.newUserClicked} href="javascript:void (0)" className="btn btn-secondary cst_btn"><i className="fa fa-plus icn_plus"></i>New User</a>
                            </div>
                        </div>
                      {/*   <div className="more_filter_content">
                         <div id="morefilterBtn" className="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                             <div className="card-body">
                                 <div className="row">
                                     <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                                     <div className="app-search">
                                     <Input dataTest="mode_filter" name="role" type="select" placeholder="Search By Role" options={SearchRolesOptions} className="custom-select input-modifier add_user_select fil_search_input" />
                                      <button className="app-search__button">
                                                 <i class="fa fa-search" aria-hidden="true"></i>
                                             </button>
                                       </div>
                                     </div>
                                     </div>
                                 </div>
                             </div>
                         </div> */}
                    </form>
                </div>


                <section className="account_sec user_screen inspection_tab_content">
                    <div className="theme_switch_btn" style={{display: "inline"}}>
                        <span className="switch_title">In-active</span>
                        <label>                                
                            <input type="checkbox" checked={props.activeUsers} onChange={(e) => props.activeUserClicked(e)}/>
                            <span></span>
                        </label>
                        <span className="ext_title">Active</span>
                    </div>
                    {props.usersList && props.usersList.length === 0 ? null :
                        <> 
                        <button data-test="download_csv_file" className="csv_btn btn_danger" onClick={() => props.onUserExportClicked()} >Export CSV</button>
                        <br /><br /><br /></>}
                    <div className="quotes_table_content table-responsive">
                        <div className="table quotes_table user_table_contnet user_react_table">
                            <UserTable
                                {...props}
                                emailPreferencesClicked={props.emailPreferencesClicked}
                                deleteUser={props.deleteUser}
                                users_rows={props.users_rows}
                                getUsers={props.getUsers}
                                isLoading={props.isLoading}
                                previewUserClicked={props.previewUserClicked}
                                onUserPageSizeChange={props.onUserPageSizeChange}
                                usersList={props.usersList ? props.usersList : []}
                                editUserClicked={props.editUserClicked}
                                openDeleteModel={props.openDeleteModel}
                                handleClickDeleteModel={props.handleClickDeleteModel}
                                activeUsers={props.activeUsers}
                                welcomeMailForUser={props.welcomeMailForUser}
                                />
                        </div>

                    </div>
                </section>
            </main>
            <Dialog
                open={props.openDeleteModel}
                onClose={props.handleClose}
                aria-labelledby="form-dialog-title"
            >
                 <DialogTitle className="closeIcon" id="form-dialog-title" style={{ fontSize: '13', color: '#06080f' }}>
                    <div className="d-flex" style={{float: "right"}}>
                        <button type="button" class="close" onClick={props.handleDeleteClose}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                </DialogTitle>
                <DialogTitle className="sureTxt" id="form-dialog-title" style={{ fontSize: '13', color: '#06080f' }}>
                        <h3 style={{ color: '#ab385e' }}>Are you sure?</h3>
                </DialogTitle>

                <DialogActions>
                    <button data-test="delete_account" style={{ minHeight: '36px', minWidth: '80px' }} onClick={() => props.deleteUser(props.deleteObject.user.id)} disabled={props.isLoading} className={props.isLoading ? "btn cst_btn btn_danger mr-2 btn-wait" : "btn cst_btn btn_danger mr-2"} id="delete" type="delete">{props.isLoading ? "" : "Delete"}</button>
                    <button style={{ minHeight: '36px', minWidth: '80px' }} onClick={props.handleDeleteClose} className={"btn cst_btn btn-outline-secondary"} id="cancel" type="cancel">Cancel</button>
                    {/* <Button onClick={props.handleClose} variant="contained" color="primary">
                        Cancel
                        </Button>


                    <Button onClick={() => props.deleteAccount(props.deleteObject.id)} disabled={props.isLoading} variant="contained" color="secondary">
                        {props.isLoading ? <CircularProgress size={24} /> : 'Delete'}
                    </Button> */}
                </DialogActions>
            </Dialog>
        </>

    )
}

const mapStateToProps = (state) => {
    let email = state.adminOrSuperAdminReducer.searchUserValues && state.adminOrSuperAdminReducer.searchUserValues.email ? state.adminOrSuperAdminReducer.searchUserValues.email : "";
    let name = state.adminOrSuperAdminReducer.searchUserValues && state.adminOrSuperAdminReducer.searchUserValues.name ? state.adminOrSuperAdminReducer.searchUserValues.name : "";
    let mode = state.adminOrSuperAdminReducer.searchUserValues && state.adminOrSuperAdminReducer.searchUserValues.mode ? state.adminOrSuperAdminReducer.searchUserValues.mode : "";
    let role = state.adminOrSuperAdminReducer.searchUserValues && state.adminOrSuperAdminReducer.searchUserValues.role ? state.adminOrSuperAdminReducer.searchUserValues.role : "";

    return { initialValues: { email, name, mode, role } }
}

User = reduxForm({
    form: 'searchUserForm',
    validate,
    enableReinitialize: true
})(User);

User = connect(mapStateToProps)(User)

export default User;