
export const formAuditTagModuleActionTypes = {

    GET_SINGLE_REQUESTFORM_DETAILS:'GET_SINGLE_REQUESTFORM_DETAILS',
    GET_SINGLE_REQUESTFORM_DETAILS_PENDING: 'GET_SINGLE_REQUESTFORM_DETAILS_PENDING',
    GET_SINGLE_REQUESTFORM_DETAILS_FULFILLED: "GET_SINGLE_REQUESTFORM_DETAILS_FULFILLED",   

    ADD_SINGLE_REQUESTFORM_DETAILS:'ADD_SINGLE_REQUESTFORM_DETAILS',
    ADD_SINGLE_REQUESTFORM_DETAILS_PENDING: 'ADD_SINGLE_REQUESTFORM_DETAILS_PENDING',
    ADD_SINGLE_REQUESTFORM_DETAILS_FULFILLED: "ADD_SINGLE_REQUESTFORM_DETAILS_FULFILLED",   
    ADD_SINGLE_REQUESTFORM_DETAILS_REJECTED:"ADD_SINGLE_REQUESTFORM_DETAILS_REJECTED",

    EDIT_SINGLE_REQUESTFORM_DETAILS:'EDIT_SINGLE_REQUESTFORM_DETAILS',
    EDIT_SINGLE_REQUESTFORM_DETAILS_PENDING: 'EDIT_SINGLE_REQUESTFORM_DETAILS_PENDING',
    EDIT_SINGLE_REQUESTFORM_DETAILS_FULFILLED: "EDIT_SINGLE_REQUESTFORM_DETAILS_FULFILLED",  
    EDIT_SINGLE_REQUESTFORM_DETAILS_REJECTED:"EDIT_SINGLE_REQUESTFORM_DETAILS_REJECTED",

    GET_ALL_REQUESTFORM_DETAILS:'GET_ALL_REQUESTFORM_DETAILS',
    GET_ALL_REQUESTFORM_DETAILS_PENDING: 'GET_ALL_REQUESTFORM_DETAILS_PENDING',
    GET_ALL_REQUESTFORM_DETAILS_FULFILLED: "GET_ALL_REQUESTFORM_DETAILS_FULFILLED",  
    
    REQUSET_FORM_PAGE_CLICKED  : 'RESTROOM_PORTERING_PAGE_CLICKED'
}

