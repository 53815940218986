import React, { useEffect, useState } from 'react'
import PerformanceAuditForm from '../../../../../component/FormAuditModule/AuditsComponent/PerformanceAudit/performanceAuditForm/performanceAuditForm'
import { addPerformanceAudit, getAllPerformanceAuditQuestions, getSinglePerformanceAudit, updatePerformanceAudit } from '../../../../../../redux/actions/FormAuditModAction/AuditsAction/AuditsAction'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min'
import errorHandler from '../../../../../../utility/errorHandler/errorHandler'
import { getAllJob } from '../../../../../../redux/actions'

function PerformanceAuditPage() {
  const {  singlePerformanceAuditData } = useSelector((state) => state.auditsReducer)
  const dispatch = useDispatch()
  const { location: { pathname } } = useHistory()
  const { id } = useParams();
  const isEdit = Boolean(pathname.split("/")[2] !== "view" && id)
  const isView = Boolean(pathname.split("/")[2].includes("view"))
  const [auditorId, setAuditorId] = useState([])
  

  useEffect(() => {
    dispatch(getAllPerformanceAuditQuestions())
    dispatch(getAllJob())
  }, [])

  

  const filterQuestions = (data) => {
     const values = [...data]
     const filterdValues = values.filter((item) => {
      const lineItems = Object.keys(item)
      if(isEdit){
        if(lineItems.length === 5){
          return (item[lineItems[3]]?.trim() !== '' && item[lineItems[3]] !== null) || (item[lineItems[4]]?.trim() !== '' && item[lineItems[4]] !== null)
        }else {
          return (item[lineItems[1]]?.trim() !== '' && item[lineItems[1]] !== null) || (item[lineItems[2]]?.trim() !== '' && item[lineItems[2]] !== null)
        }
      } else {
        return (item[lineItems[1]]?.trim() !== '' && item[lineItems[1]] !== null) || (item[lineItems[2]]?.trim() !== '' && item[lineItems[2]] !== null)
      }
       
     })
     if(filterdValues.length){
     const finalValues =  filterdValues.map((item, i) => {
     const lineItems = Object.keys(item)
        if(isEdit){
          if(lineItems.length === 5) {
            return {
              id : item.id,
              auditor_main_question_id : item.mainQuestionId,
              is_checked : item[lineItems[3]],
              notes : item[lineItems[4]],
              sort : i + 1
            }
          }else{
            return {
              auditor_main_question_id : item.mainQuestionId,
              is_checked : item[lineItems[1]],
              notes : item[lineItems[2]],
              sort : i + 1
            }
          }
          
        }else{
          return {
            mainQuestionId : item.mainQuestionId,
            is_checked : item[lineItems[1]],
            notes : item[lineItems[2]],
            sort : i + 1
          }
        }
      })
      return finalValues
     }else {
      return []
     }
  }

  const convertRequestBody = (value) => {
       
       const data = [...value.form]

       const values = data.map((item, i) => {
        const lineItems = Object.keys(item)[1]
        const findAuditId = auditorId.length && auditorId.find((audit) => {
            return audit.auditor_main_table_id == item.mainTableId
        })
        if(isEdit){
          if(findAuditId){
            return {
              id : findAuditId.id,
              auditor_id : id,
              auditor_main_table_id : item.mainTableId,
              auditorSectionsLineItems : filterQuestions(item[lineItems].questions)
            }
          }else {
            return {
              auditor_id : id,
              auditor_main_table_id : item.mainTableId,
              auditorSectionsLineItems : filterQuestions(item[lineItems].questions)
            }
          }
        }else{
          return {
            mainTableId : item.mainTableId,
            auditorSectionsLineItems : filterQuestions(item[lineItems].questions)
    }
        }
       })
      
       return values
  }

  const checkValidation = (data) => {
      return data.filter((item) => item.auditorSectionsLineItems.length)
  }

  useEffect(() => {
    if (isEdit || isView && id) {
        dispatch(getSinglePerformanceAudit(id))
    }
}, [])

  const onSubmit = (value) => {
    
    const body = convertRequestBody(value)
    const requestBody = checkValidation([...body])
    
    if(!requestBody.length){
      errorHandler('Please select atleast one field')
      return false
    }
    if(isEdit){ 
      const data = {
        last_step: null,
        id,
        account_id:value.account_id,
        auditorSections : requestBody.map((item, i) => {
          return {
            ...item,
            sort : i + 1
          }
        }),
      }
      dispatch(updatePerformanceAudit({data}))
    }else{
      
      const data = {
        last_step: null,
        account_id:value.account_id,
        auditorSections : requestBody.map((item, i) => {
          return {
            ...item,
            sort : i + 1
          }
        }),
      }
      dispatch(addPerformanceAudit({data}))
    }
  }
  
  return (
    <PerformanceAuditForm
      elements={singlePerformanceAuditData?.account_id ? {account_id:singlePerformanceAuditData.account_id }: {}}
      onSubmit={onSubmit}
      isEdit={isEdit}
      isView={isView}
      setAuditorId={setAuditorId}
    />
  )
}

export default PerformanceAuditPage 