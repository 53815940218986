import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../../../../redux/actions/index';
import AssetCheckInFormComponent from '../../../../../component/Inventory/Assets/AssetScanExamineFormComponent/AssetCheckInFormComponent/AssetCheckInFormComponent';
import { animateScroll as scroll} from 'react-scroll'
import { getUser } from '../../../../../../utility/utility';
const cloneDeep = require('clone-deep');

class AssetCheckInFormContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
        }
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        const user = getUser();
        this.props.getCommonJobsAccountList(user.mode);
        this.props.getLocation();
        scroll.scrollToTop();
    }

    handleSubmit = (values) => {debugger
        let checkOutItem = cloneDeep(values);
        if(checkOutItem && checkOutItem.scan) {

            if(this.props && this.props.match && this.props.match.params && this.props.match.params.assetId) {
                checkOutItem.scan.asset_id = this.props.match.params.assetId;
            };

            if(checkOutItem.scan.account_id) {
                checkOutItem.scan.account_id = checkOutItem.scan.account_id.value;
            }

            if(checkOutItem.scan.location_id) {
                checkOutItem.scan.location_id = checkOutItem.scan.location_id.value;
            }
            
            this.props.engageAssetCheckOut(checkOutItem);

        }
    };

    // componentWillUnmount() {}

    render() {

        return (
            <AssetCheckInFormComponent
            {...this.props}
            onSubmit={this.handleSubmit}
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        isLoading: state.InventoryReducer.isLoading,
        commonJobsAccountList: state.InventoryReducer.commonJobsAccountList,
        locations: state.InventoryReducer.locations,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getCommonJobsAccountList: (data) => dispatch(actions.getCommonJobsAccountList(data)),
        getLocation: () => dispatch(actions.getLocation()),
        engageAssetCheckOut: (data) => dispatch(actions.engageAssetCheckOut(data)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AssetCheckInFormContainer);