import axios, { API_VERSION } from '../config';
import { getUser } from '../utility/utility';
const pureAxios = require('axios');

const headers = {
    headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
    },
};

export const uploadImageToS3 = (url, arrayBuffer) => {
    return pureAxios.put(url, arrayBuffer);
};

export const getCommonJobsAccountList = (data) => {
    return axios.get(API_VERSION + `accounts/get_all_account/account_mode?mode=` + data, headers);
}

export const getAllInventoryItemsList = (params) => {
    params.sorted = JSON.stringify(params.sorted);
    return axios.get(API_VERSION + `inventory/item`, { params }, headers);
}

export const createInventoryItem = (body) => {
    return axios.post(API_VERSION + `inventory/item`, body, headers);
}

export const updateInventoryItem = (body) => {
    return axios.put(API_VERSION + `inventory/item/${body.id}`, body, headers);
}

export const getSingleInventoryItem = (id) => {
    return axios.get(API_VERSION + `inventory/item/${id}`, headers);
}

export const getSingleInventoryLogItem = (id) => axios.get(API_VERSION + `inventory/item/log/${id}`, headers);

export const deleteInventoryItem = (id) => {
    return axios.delete(API_VERSION + `inventory/item/${id}`, headers);
};

export const getBoxList = (params) => {
    params.sorted = JSON.stringify(params.sorted);
    return axios.get(API_VERSION + `inventory/box`, { params }, headers);
};

export const getSingleBox = (id) => axios.get(API_VERSION + `inventory/box/${id}`, headers);

export const addBox = (body) => axios.post(API_VERSION + `inventory/box`, body, headers);

export const updateBox = (body, id) => {
return axios.put(API_VERSION + `inventory/box/${id}`, body, headers);
}

export const deleteBox = (id) => axios.delete(API_VERSION + `inventory/box/${id}`, headers);

export const getAllAssests = (params) => {
    params.sorted = JSON.stringify(params.sorted);
    return axios.get(API_VERSION + `inventory/item`, { params }, headers);
};

export const createAsset = (body) => {
    return axios.post(API_VERSION + `inventory/item/bulk`, body, headers);
}

export const updateAsset = (body,id) => {
    return axios.put(API_VERSION + `inventory/item/${id}`, body, headers);
}

export const getSingleAsset = (id) => {
    return axios.get(API_VERSION + `inventory/item/${id}`, headers);
}

export const getSingleLogAsset = (id, params) => axios.get(API_VERSION + `inventory/asset/sub/log/${id}`, { params }, headers);

export const deleteAsset = (id) => axios.delete(API_VERSION + `inventory/item/${id}`, headers);

export const getIsolatedOneAssetData = (id) => {
    return axios.get(API_VERSION + `inventory/asset/sub/info/${id}`, headers);
}

export const engageAssetCheckOut = (body) => {
    return axios.post(API_VERSION + `inventory/asset/checkout`, body, headers);
}

export const engageAssetCheckIn = (body, id) => {
    return axios.put(API_VERSION + `inventory/asset/checkin/${id}`, body.checkInItem, headers);
}

export const getAllPickingTickets = (params) => {
    params.sorted = JSON.stringify(params.sorted);
    return axios.get(API_VERSION + `inventory/ticket`, { params }, headers);
};

export const getSinglePickingTicket = (id) => axios.get(API_VERSION + `inventory/ticket/${id}`, headers);

export const approvePickupTicket = (body, id) => {
    return axios.put(API_VERSION + `inventory/ticket/${id}/status`, body, headers);
}

export const createInventoryPullRequestTicket = (body) => {
    return axios.post(API_VERSION + `inventory/ticket`, body, headers);
}

export const getInventoryItemNames = (data) => {
    if(data && data.location_id){
        return axios.get(API_VERSION + 'inventory/item/names' + '?location_id=' + data.location_id , headers);
    }else {
        return axios.get(API_VERSION + 'inventory/item/names' , headers);
    }
    
}

export const getJobUsersInfo = (id) => {
    return axios.get(API_VERSION + `accounts/${id}/account_users_info`, headers);
}

export const getAllPickupsTicketList = (params) => {
    params.sorted = JSON.stringify(params.sorted);
    return axios.get(API_VERSION + `inventory/ticket`, { params }, headers);
};

export const getSinglePickupTicket = (id) => {
    return axios.get(API_VERSION + `inventory/ticket/${id}`, headers);
}

export const getAllInventoryBoxNamesList = (data) => {
    return axios.get(API_VERSION + `inventory/box/names`, headers);
}

export const readyForPickupQRGenerator = (params) => {
    return axios.put(API_VERSION + `inventory/ticket/${params.ticket_id}/status`, params, headers);
};

export const readyForDeliveryQRGenerator = (params) => {
    return axios.put(API_VERSION + `inventory/ticket/${params.ticket.box_id}/status`, params, headers);
};

export const getDeliveryItemScanPresignedUrl = (deliveryItem, extentions) => {
    const body = { ext: extentions };
    return axios.patch(API_VERSION + `inventory/ticket/${deliveryItem.ticket_id}/presigned_photo_url`, body, headers);
};

export const getInventoryItemDeliveredSuccess = (params) => {
    let boxId = params.box_id;
    delete params.box_id;
    delete params.ticket_id;
    return axios.put(API_VERSION + `inventory/ticket/${boxId}/status`, params, headers);
};

export const getDeliveryReturnWarehouse = (boxId, params) => {
    return axios.put(API_VERSION + `inventory/ticket/${boxId}/status`, params, headers);
};

export const getAllInventoryLocationList = (params) => {
    return axios.get(API_VERSION + `inventory/location`, {params}, headers);
}

export const getLocation = () => {
    return axios.get(API_VERSION + `inventory/location`, headers);
};

export const getLocationAssociation = (params) => {
    return axios.get(API_VERSION + `inventory/location`, {params} ,headers);
};

export const addLocation = (body) => axios.post(API_VERSION + `inventory/location`, body, headers);

export const getLocationName = (params) => {
    return axios.get(API_VERSION + `inventory/location`, {params} ,headers);
};

export const getFulfillmentManager = () => {
    return axios.get(API_VERSION + `inventory/users` ,headers);
};

export const addLocationAssociation = (body, id) => axios.put(API_VERSION + `inventory/location/${id}`, body, headers); //For Add and Edit Location Association use same API -- PUT 

export const updateLocation = (body, id) => axios.put(API_VERSION + `inventory/location/${id}`, body, headers);

export const deleteLocation = (id) => {
    return axios.delete(API_VERSION + `inventory/location/${id}`, headers);
};

export const getAllAssestStock = (params, id) => {
    params.sorted = JSON.stringify(params.sorted);
    return axios.get(API_VERSION + `inventory/asset/sub/${id}`, { params }, headers);
};

export const updateAssetStock = (body, id) => {
    return axios.put(API_VERSION + `inventory/asset/sub/${id}`, body, headers);
}

export const getSingleAssetStock = (id) => {
    return axios.get(API_VERSION + `inventory/asset/sub/one/${id}`, headers);
}

export const deleteAssetStock = (id) => axios.delete(API_VERSION + `inventory/asset/sub/${id}`, headers);