import React, {useContext, useState, useEffect, useCallback}  from "react";
import { MagicSpinner, PushSpinner, SwishSpinner, WaveSpinner, ClapSpinner } from 'react-spinners-kit';
import { connect } from 'react-redux';
import * as actions from '../../../redux/actions/index';
import {BrowserQRCodeReader} from '@zxing/library';
import $ from "jquery";
import "./qr.css"
import errorHandler from "../../../utility/errorHandler/errorHandler";
import { routes } from "../../../utility/constants/constants";
const QrCodeReader = (props) => {
  const codeReader = new BrowserQRCodeReader();
  const [loaded, setLoaded] = useState(false);
  const [isScanReady, setScanReady] = useState(false);
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState(null);
  const [selDeviceId, setDeviceId] = useState(null);
  const [accessEnable, setAccess] = useState("init");
  const searchProductByBarCode = useCallback((barcode_id) => {
    if (!loading) {
      setLoading(true);
      if (barcode_id) {debugger
        //alert(`barcode_id ${barcode_id}`)
        if (barcode_id.includes("https://") && barcode_id.includes("areas")){
          let area_id;
          if (barcode_id.includes("https://"))  area_id =barcode_id.split("/").reverse()[0];
          else area_id  = barcode_id;
          props.getAreaByQR(area_id).then(res => {
            //alert(JSON.stringify(res))
            props.history.push(`/account/${res.value.data.area.account.token}/scan-result-history`)
          }).catch(err =>  props.history.push('/'))
        }else {
          errorHandler(`Please contact IT Support (ITsupport@pegasusclean.com) and have Logbook reviewer scan type added`);
          props.history.push(routes.HOME)
        }
      }
    }
  },[loading, result]);

  useEffect(() => {
    if (!loaded) {
      $('.login100-more').addClass("d-none");
      $('body').addClass("qr-page-body");
      //$('header').addClass("qr-page-header");
      $('.cus_ffoter_block').addClass("qr-page-footer");
      if (props.match && props.match.params && props.match.params.areaId) {
        props.getAreaByQR(props.match.params.areaId).then(res => {
          //alert(JSON.stringify(res))
          props.history.push(`/account/${res.value.data.area.account.token}/scan-result-history`)
        })
      }
      setLoaded(true);
        codeReader.getVideoInputDevices()
            .then((videoInputDevices) => {

              let selectedDeviceId = videoInputDevices[videoInputDevices.length - 1].deviceId;
              setDeviceId(selectedDeviceId);
              setAccess("granted");
            }).catch((err) => {
              console.error(err)
            });
    }

  },[loaded, codeReader]);

  useEffect(() => {
    if (accessEnable === "granted" && !isScanReady) {
      setScanReady(true);
      codeReader.decodeOnceFromVideoDevice(selDeviceId, 'video').then((result) => {
        codeReader.reset();
        searchProductByBarCode(result.text);
      }).catch((err) => {
        console.log(JSON.stringify(err))
        setAccess("denied");
      });

    }
    return (() => {
      $('.login100-more').removeClass("d-none");
      $('body').removeClass("qr-page-body");
      //$('.app-header').removeClass("qr-page-header");
      $('.cus_ffoter_block').removeClass("qr-page-footer");
    })
  }, [selDeviceId, accessEnable, isScanReady, searchProductByBarCode, codeReader]);

  return (
      <>
      {props.isLoading ? <div className="loader_btn_block">
        <ClapSpinner backColor="#89073c" size={40} frontColor="#89073c8a" />
      </div> : <> {accessEnable === "denied" ? <div className="hl_scan_error_cont padding_lr not_permitted_scan_box"><h6>{accessEnable === "denied" && "Please grant Camera permissions on the Browser settings"}</h6></div> :

            <video id="video" style={{ width: '100%', height: "100vh" }}></video>
      }<div className="qr-shaded-region" id="qr-shaded-region_1" ><div className="div-first"></div><div className="div-second"></div></div>
      <div className="qr-shaded-region" id="qr-shaded-region_2" ><div className="div-first"></div><div className="div-second"></div></div>
      <div className="qr-shaded-region" id="qr-shaded-region_3"><div className="div-first"></div><div className="div-second"></div></div>
      <div className="qr-shaded-region" id="qr-shaded-region_4"><div  className="div-first"></div><div className="div-second"></div></div>
      <div className="qr-page btn_block">
        {props.user && props.user.role === "frontline" ? <button type="button" className={`btn w-100 mb-1 cst_btn btn_danger`}  onClick={() => props.history.push("/schedules")}>Mark Unavailable</button> : "" }
        <button type="button" className={`mt-1 btn btn-success w-100 cst_btn ${result ? "" : "disabled-btn"}`} disabled={result ? false : true} onClick={() => this.getAreaByQR()}>Mark Service Completed</button>
      </div></> }
      </>
  )
};

//export default ScanBarCode;
//
//import React, { Component } from 'react';
//import { MagicSpinner, PushSpinner, SwishSpinner, WaveSpinner, ClapSpinner } from 'react-spinners-kit';
//
//import { connect } from 'react-redux';
//import * as actions from '../../../redux/actions/index';
//import QrReader from 'react-qr-scanner'
//import {BrowserQRCodeReader} from '@zxing/library';
//import $ from "jquery";
//import "./qr.css"
//
//import ScanSearchResult from "../../component/History/ScanSearchResult";
//class QrCodeReader extends Component {
//  constructor(props) {
//    super(props);
//    this.state = {
//      codeReader: new BrowserBarcodeReader(),
//      result: null,
//      loading: false
//    };
//
//  }
//
//  //componentDidUpdate = (prevProps, prevState) => {
//  //  if (prevState.result !== this.state.result) {
//  //    var pattern = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
//  //    if (this.state.result && pattern.test(this.state.result)) {
//  //      // setTimeout(function () {
//  //      window.location.assign(this.state.result);
//  //      // window.open(
//  //      //     data, "_blank",
//  //      //     "height=200,width=200,modal=yes,alwaysRaised=yes");
//  //      // }, 2000);
//  //    }
//  //  }
//  //}
//
//  handleScan = data => {
//  alert(`data ${JSON.stringify(data)}`)
//    if ( !this.state.loading && data) {
//      this.setState({loading: true, result: data})
//      this.getAreaByQR();
//    }
//  };
//
//  getAreaByQR = () => {
//    if (this.state.result) {
//      let area_id;
//      if (this.state.result.includes("https://"))  area_id = this.state.result.split("/").reverse()[0];
//     else area_id  = this.state.result;
//      props.getAreaByQR(area_id).then(res => {
//        //alert(JSON.stringify(res))
//        props.history.push(`/account/${res.value.data.area.account.token}/scan-result-history`)
//      })
//    }
//  };
//  handleError = err => {
//  alert(`err ${JSON.stringify(err)}`)
//    console.error(err)
//  };
//  componentWillUnmount() {
//    $('.login100-more').removeClass("d-none");
//    $('body').removeClass("qr-page-body");
//    //$('.app-header').removeClass("qr-page-header");
//    $('.cus_ffoter_block').removeClass("qr-page-footer");
//  }
//  componentDidMount() {
//    $('.login100-more').addClass("d-none");
//    $('body').addClass("qr-page-body");
//    //$('header').addClass("qr-page-header");
//    $('.cus_ffoter_block').addClass("qr-page-footer");
//    if (props.match.params.areaId) {
//      props.getAreaByQR(props.match.params.areaId).then(res => {
//        //alert(JSON.stringify(res))
//        props.history.push(`/account/${res.value.data.area.account.token}/scan-result-history`)
//      })
//    }
//  }
//    render() {
//    return (
//        <>
//        {props.isLoading ? <div className="loader_btn_block">
//          <ClapSpinner backColor="#89073c" size={40} frontColor="#89073c8a" />
//        </div> : <><QrReader
//              delay={1000}
//              onError={this.handleError}
//              onScan={this.handleScan}
//              facingMode="rear"
//              className="video-container"
//              style={{ width: '100%', height: "100vh" }}
//              /><div className="qr-shaded-region" id="qr-shaded-region_1" ><div className="div-first"></div><div className="div-second"></div></div>
//        <div className="qr-shaded-region" id="qr-shaded-region_2" ><div className="div-first"></div><div className="div-second"></div></div>
//        <div className="qr-shaded-region" id="qr-shaded-region_3"><div className="div-first"></div><div className="div-second"></div></div>
//        <div className="qr-shaded-region" id="qr-shaded-region_4"><div  className="div-first"></div><div className="div-second"></div></div>
//        <div className="qr-page btn_block">
//          {props.user && props.user.role === "frontline" ? <button type="button" className={`btn w-100 mb-1 cst_btn btn_danger`}  onClick={() => props.history.push("/schedules")}>Mark Unavailable</button> : "" }
//          <button type="button" className={`mt-1 btn btn-success w-100 cst_btn ${this.state.result ? "" : "disabled-btn"}`} disabled={this.state.result ? false : true} onClick={() => this.getAreaByQR()}>Mark Service Completed</button>
//        </div></> }
//        </>
//
//    )
//  }
//}

const mapStateToProps = (state) => {
  return {
    user: state.authReducer.user,
    isLoading: state.adminOrSuperAdminReducer.isLoading,
    isAccountAreas: state.adminOrSuperAdminReducer.isAccountAreas,
    accountAreas: state.adminOrSuperAdminReducer.accountAreas,
    openDeleteModel: state.adminOrSuperAdminReducer.openDeleteModel,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAreaByQR: (id) => dispatch(actions.getAreaByQR(id))
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(QrCodeReader);
//export default QrCodeReader;