import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../../../redux/actions/index';
import { tzUTCDateTimeConverterTool_V1 } from '../../../../../utility/TimezoneOperations/timezoneUtility';

import ClkInClkOutShiftRecapComponent from '../../../../component/TimeTrackerModuleComponent/ClockInClockOutScreenComponent/ClkInClkOutShiftRecapComponent/ClkInClkOutShiftRecapComponent';

var momentTZ = require('moment-timezone');

class ClkInClkOutShiftRecapContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoad: false,
        }

    }

    componentDidMount() {
        this.setState({isLoad: true});
        setTimeout(() => {
            this.setState({isLoad: false});
        }, 2500);

        let setData = { data: {} };
        let fetchedCurrDateTime = momentTZ(new Date()).format('MM-DD-YYYYTHH:mm:ss').split('T');
        let currentTime = tzUTCDateTimeConverterTool_V1(fetchedCurrDateTime[0], fetchedCurrDateTime[1], 'none');
        setData.data.current_time = currentTime;
        this.props.flUserShiftClockChecker(setData);
        setTimeout(() => {
            if (this?.props?.clockedInShiftData?.shift_id) {
                let page, limit, shiftId, date = null;

                page = 1
                limit = 100
                shiftId = this.props.match.params.shiftId;
                let fetchedCurrDateTime = momentTZ(new Date()).format('MM-DD-YYYYTHH:mm:ss').split('T');
                date = tzUTCDateTimeConverterTool_V1(fetchedCurrDateTime[0], fetchedCurrDateTime[1], 'none');
                this.props.getPerFLUserScheduledTaskTickets({ page, limit, shiftId, date });
            }
        }, 1500);
    }
    componentWillUnmount() {
        this.props.resetTimeTrackerObjects();
    }

    render() {
        return (
            <>
                <ClkInClkOutShiftRecapComponent
                    {...this.props}
                    isLoad={this.state.isLoad}
                />
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.authReducer.user,
        isLoading: state.TimeTrackerModReducer.isLoading,
        isClockInShiftAvailable: state.TimeTrackerModReducer.isClockInShiftAvailable,
        clockedInShiftData: state.TimeTrackerModReducer.clockedInShiftData,
        assignedTaskTicketInfo: state.TimeTrackerModReducer.scheduledTask_assignedTicketInfo,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        resetTimeTrackerObjects: () => dispatch(actions.resetTimeTrackerObjects()),
        flUserShiftClockChecker: (data) => dispatch(actions.flUserShiftClockChecker(data)),
        getPerFLUserScheduledTaskTickets: (data) => dispatch(actions.getPerFLUserScheduledTaskTickets(data)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ClkInClkOutShiftRecapContainer);