import React from 'react';
import Aux from '../../../../hoc/Oux/Oux';
import { Field } from 'redux-form';
import { InputField } from '../InputField/InputField';
import { RadioBtn } from '../RadioBtn/RadioBtn';
import { SelectField } from '../SelectField/SelectField';
import { TextAreaField } from '../TextAreaField/TextAreaField';
import { FileInputField } from '../FileInputField/FileInputField';
import { CheckBoxBtn } from '../CheckBoxBtn/CheckBoxBtn';
import { DateTimeField } from '../DateTimeField/DateTimeField';

export const Input = (props) => {
    let inputElement = null;
    switch (props.type) {
        case ('text'):
            inputElement = <InputField
                name={props.name}
                type={props.type}
                label={props.label}
                dataTest={props.dataTest}
                placeholder={props.placeholder}
                maxLength={props.maxLength}
                autoComplete={props.autoComplete}
                className={props.className}
                borderColor={props.borderColor}
                iconClass={props.iconClass} {...props} />;
            break;
        case ('number'):
            inputElement = <InputField
                name={props.name}
                value={props.value}
                type={props.type}
                label={props.label}
                placeholder={props.placeholder}
                maxLength={props.maxLength}
                autoComplete={props.autoComplete}
                className={props.className}
                iconClass={props.iconClass} {...props} />;
            break;
        case ('numberWithIncDec'):
            inputElement = <InputField
                name={props.name}
                value={props.value}
                type={"number"}
                label={props.label}
                disabled={props.disabled}
                placeholder={props.placeholder}
                maxLength={props.maxLength}
                autoComplete={props.autoComplete}
                className={props.className}
                iconClass={props.iconClass} {...props} />;
        break;
        case ('tel'):
            inputElement = <InputField
                name={props.name}
                type={props.type}
                label={props.label}
                placeholder={props.placeholder}
                maxLength={props.maxLength}
                autoComplete={props.autoComplete}
                className={props.className}
                iconClass={props.iconClass} {...props} />;
            break;
        case ('numberFormate'):
            inputElement = <InputField {...props} />
            break;
        case ('email'):
            inputElement = <InputField
                name={props.name}
                type={props.type}
                label={props.label}
                placeholder={props.placeholder}
                maxLength={props.maxLength}
                autoComplete={props.autoComplete}
                className={props.className}
                iconClass={props.iconClass}
                {...props} />;
            break;
        case ('password'):
            inputElement = <InputField
                name={props.name}
                type={props.type}
                label={props.label}
                placeholder={props.placeholder}
                maxLength={props.maxLength}
                autoComplete={props.autoComplete}
                className={props.className}
                iconClass={props.iconClass} {...props} />;
            break;
        case ('textarea'):
            inputElement = <TextAreaField
                name={props.name}
                type={props.type}
                dataTest={props.dataTest}
                label={props.label}
                className={props.className} {...props} />;
            break;
        case ('file'):
            inputElement = <FileInputField {...props}
                dataTest={props.dataTest}
            />;
            break;
        case ('select'):
            inputElement = <SelectField
                name={props.name}
                label={props.label}
                options={props.options}
                dataTest={props.dataTest}
                className={props.className}
                borderColor={props.borderColor} {...props} />
            break;
        case ('inputSelect'):
            inputElement = <SelectField
                name={props.name}
                label={props.label}
                dataTest={props.dataTest}
                instanceId={props.instanceId}
                options={props.options}
                className={props.className}
                CustomOption={props?.CustomOption}
                // selectedValue={props.selectedValue}
                borderColor={props.borderColor} {...props} />
        case ('inputGroupSelect'):
            inputElement = <SelectField
                name={props.name}
                label={props.label}
                dataTest={props.dataTest}
                options={props.options}
                className={props.className}
                borderColor={props.borderColor} {...props} />
            break;
        case ('checkbox'):
            inputElement = <CheckBoxBtn {...props} />
            break;
        case ('date'):
            inputElement = <DateTimeField {...props} />;
            break;
        case ('radio'):
            inputElement = <RadioBtn {...props}
                dataTest={props.dataTest}
            />
            break;
        default:
            inputElement = <InputField {...props} />;
    }

    let content = <Aux>{inputElement}</Aux>;

    if (props.title) {
        content = (
            <fieldset>
                <label>{props.title}</label>
                {inputElement}
            </fieldset>
        );
    }

    return (
        <Aux>
            {content}
        </Aux>
    );
}