import React, { Component } from 'react';
import { routes } from '../../../../../utility/constants/constants';
import InventoryList from '../../../../component/Inventory/InventoryListComponent/InventoryList';
import * as actions from '../../../../../redux/actions/index';
import { connect } from 'react-redux';
import { reset } from 'redux-form';
import { animateScroll as scroll } from 'react-scroll'
import storage from '../../../../../utility/storage';
import { convertDateToDifferentTZ } from '../../../../../utility/utility';
import Shift from '../../../../component/TimeTrackerModuleComponent/ShiftComponent/ShiftComponent/Shift';
import { tzUTCDateConverterTool_V2 } from '../../../../../utility/TimezoneOperations/timezoneUtility';

var moment = require('moment');
const cloneDeep = require('clone-deep');

class ShiftContainer extends Component {

    constructor(props) {
        super(props);

        this.state = {

        }
    }

    componentDidMount() {
        scroll.scrollToTop();
    }

    componentDidUpdate(prevProps, prevState) {
        const user = storage.get('user');
        if (user) {
            if (prevProps.user.selected_mode !== user.selected_mode) {
                this.props.shiftScheduleListPageClicked(0)
            }
        }
    }

    resetClicked = () => {
        const page = 1
        const limit = this.props.shiftList_no_of_rows
        this.props.reset('searchShift');
        this.props.shiftScheduleListPageClicked(0)
        let userId = this.props.user?.id
        this.props.getShift({ page, limit, userId })

    }

    searchInventoryItemsListClicked = (value) => {
        const values = cloneDeep(value)
        let isFuture = null
        let isCurrent = null
        let isPast = null
        let userId = this.props.user?.id

        const page = 1
        const limit = this.props.shiftList_no_of_rows
        if (values.status) {
            if (values.status === 'today') {
                isCurrent = true
            }
            if (values.status === 'upcoming') {
                isFuture = true
            }
            if (values.status === 'past') {
                isPast = true
            }
        }

        let newDate = new Date()
        let currentDate = tzUTCDateConverterTool_V2(newDate, 'to');

        this.props.getShift({ page, limit, isFuture, isCurrent, isPast, currentDate, userId }, values);
    }

    handleSwapRequestSanction = (swapId) => {

        this.props.history.push({
            pathname: `/shift-schedule/swap/${swapId}`,
            // state: {storedRoute: null}
        });

    }

    handleEditTimeCardCorrectionFormClicked = (value) => {
        this.props.history.push({ pathname: `/scheduled-shift/${value.shiftId}/timecard-correction/${value.shiftDetailId}` });
    }

    render() {
        return (
            <Shift
                {...this.props}
                isLoading={this.props.isLoading}
                onSubmit={this.searchInventoryItemsListClicked}
                resetClicked={this.resetClicked}
                shiftList={this.props.shiftList ? this.props.shiftList : []}
                handleSwapRequestSanction={this.handleSwapRequestSanction}
                handleEditTimeCardCorrectionFormClicked={this.handleEditTimeCardCorrectionFormClicked}

            />
        )
    }
}


const mapStateToProps = (state) => {
    return {
        user: state.authReducer.user,
        isLoading: state.TimeTrackerModReducer.isLoading,
        shiftList: state.TimeTrackerModReducer.shiftList,
        isShiftList: state.TimeTrackerModReducer.isShiftList,
        shiftList_no_of_rows: state.TimeTrackerModReducer.shiftList_no_of_rows,
        shiftListCurPage: state.TimeTrackerModReducer.shiftListCurPage,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        reset: (searchInventoryItemsList) => dispatch(reset(searchInventoryItemsList)),  // requires form name
        shiftScheduleListPageClicked: (page) => dispatch(actions.shiftScheduleListPageClicked(page)),
        getShift: (params, values) => dispatch(actions.getShift(params, values)),
        resetObjects: () => dispatch(actions.resetObjects()),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(ShiftContainer);