import React, { useState, useEffect } from 'react';
import { Input } from '../../UI/InputElement/InputElement';
import $ from 'jquery';
import { newQuoteValidator as validate } from '../../../../utility/validator/validator';
import QuotesSkeleton from '../../../component/Quotes/NewQuotes/QuotesSkeleton';
import { connect, useSelector } from 'react-redux';
import { autofill, reduxForm } from 'redux-form';
import { userRoles } from '../../../../utility/constants/constants';
import isEmpty from '../../../../utility/IsEmptyValidator';
import { showConfirmAlert } from '../../../../utility/successAlert/ConfirmAlert';
import ReactQuill ,{ Quill } from 'react-quill'; // ES6
import { DateTimeField } from '../../UI/DateTimeField/DateTimeField';
import ImageLoader from 'react-imageloader';
import ModalImageGallery from '../../ModalImageGallery/modalImageGallery';
import ReactHtmlParser from 'react-html-parser';
var Link = Quill.import('formats/link');

const cloneDeep = require('clone-deep');


const getAccountsDropDown = (props) => {

    if (props.user.role === userRoles.INSPECTOR || props.user.role === userRoles.CLIENT || props.user.role === userRoles.REQUESTOR) {

        return props.userAccountLists && props.userAccountLists.map(account => {
            return {
                value: account.id,
                label: account.name
            }
        })
    } else {
        return props.accountsList && props.accountsList.map(account => {
            return {
                value: account.id,
                label: account.name
            }
        })
    }
}


const getCustomerDropdown = (props) => {
    return props.dropDownCustomerList && props.dropDownCustomerList.map(customer => {
        return {
            value: customer.id,
            label: customer.first_name + ' ' + customer.last_name
        }
    })
}

let NewQuotes = (props) => {
    // const accounts = props.dropDownAccountsList.map(account => {
    //     return {
    //         value: account.id,
    //         label: account.name
    //     }
    // });
    const isAccountLoading = useSelector(state => state.adminOrSuperAdminReducer.isAccountLoading);
    const [accounts, setAccounts] = useState(getAccountsDropDown(props));

    useEffect(() => {
        setAccounts(getAccountsDropDown(props))
    }, [props.accountsList, props.userAccountLists])

    const noOptionsMessage = (a, b) => {
        return 'Job not found';
    }

    const noCustomerMessage = (a, b) => {
        return 'Customer not found';
    }
    const { handleSubmit, pristine, reset, submitting, error } = props;
    const [theme, setTheme] = useState('snow');
    const [descriptionError, setDescriptionError] = useState('');
    const [editorHtmlToText, setEditorHtmlToText] = useState('');
    const [editorHtml, setEditorHtml] = useState('');
    const [sigantureImage, setSigantureImage] = useState('');
    const [customer, setCustomer] = useState(getCustomerDropdown(props));
    useEffect(() => {
        setCustomer(getCustomerDropdown(props))
    }, [props.dropDownCustomerList])

    useEffect(() => {
        if(props.match.params.quoteId) {
            if(props.quote.account) {
                props.accountChanged(props.quote.account.id)
            }
        }
    }, [!props.dropDownCustomerList])

    // useEffect(() => {
    //     if(props.match.params.quoteId && props.quote.description) {
    //         setEditorHtml(props.quote.description);
    //     }
    // }, [!props.quote])

    const EditorModules = {
        toolbar: [
            [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
            [{ size: [] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' },
            { 'indent': '-1' }, { 'indent': '+1' }],
            ['link'],
            // ['link', 'image', 'video'],
            // ['clean']
        ],
        clipboard: {
            // toggle to add extra line breaks when pasting HTML:
            matchVisual: false,
        }
     
    }
    const EditorFormats = [
        'header', 'font', 'size',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
        'link', 'image', 'video'
    ]
    const handleThemeChange = (newTheme) => {
        if (newTheme === "core") newTheme = null;
        setTheme(newTheme);
    }
    const handleChange = (html, recentAdded, b, editorFunctions) => {
    
        setEditorHtml(html);
        if(b === 'api') {
            return
        }
    
        let text = editorFunctions.getText()
        var builtInFunc = Link.sanitize;
        Link.sanitize = function customSanitizeLinkInput(linkValueInput) {
             var urlValue= linkValueInput;
             if (urlValue.indexOf("http://") == 0 || urlValue.indexOf("https://") == 0) {
                console.log(urlValue)
                return urlValue
            }
            else{
                
                urlValue = "https://" + urlValue;
                return urlValue
            }
    
        }
        
        // setEditorHtmlToText(text);
        // recentAdded.ops.forEach((a, i) => {
        //     if (a.insert && a.insert.image) {
        //         setSigantureImage(a.insert.image)
        //     }
        // })
        if (text.length > 20) {
            setDescriptionError('');
        }
       
    }
    function preloader() {
        return <img style={{ width: '50%', height: '50%',margin: '0 auto',display: 'block' }} src="/images/gif/giphy.gif" />;
    }
    const base64Clicked = (i, urlLength) => {
        setIsImageGallery(true);
        setStartIndex((urlLength + i));
    }
    const photoUrlClicked = (i) => {

        setIsImageGallery(true);
        setStartIndex(i);
    }

    const [isImageGallery, setIsImageGallery] = useState(false);
    const [startIndex, setStartIndex] = useState(0);
    if (isImageGallery) {
        return <ModalImageGallery
            isImageGallery={isImageGallery}
            setIsImageGallery={setIsImageGallery}
            startIndex={startIndex}
            base64={props.portfolioImages}
            imageUrls={props.savedPortfolioURls}
        />
    }
    const submitQuote = (values, htmlContent, base64, method) => {
        // props.validate(values);
        // if(values.quote.account_id === '' || values.quote.account_id === null) {

        // }
            // showConfirmAlert("Are you sure that you would like to send this quote to your customer?", 'Please Confirm', () => {
            // submitQuote(values, editorHtml, sigantureImage)
            setDescriptionError('');
            let quote = cloneDeep(values);
            quote.quote['account_id'] = quote.quote['account_id']['value'];
            quote.quote['description'] = htmlContent;
            if(method === 'submitLater') {
                props.handelQuoteSubmitLater(quote);
                
            } else {
                props.handelQuoteSubmitNow(quote)
            }
    }
    return (

        <main className="app-content  wraper_content inspection_wraper user_newQuote">
            <div className="inspection_content_sec appcontent_Inner">
                <div className="container-fluid">
                    {(isAccountLoading) ?
                        <QuotesSkeleton />
                        :
                        <div className="quote_form">
                            <h1 data-test="new_quote_title">{props.match.params.quoteId ? 'Update Quote' : 'Add a External Quote'}</h1>
                            <div className="addQuote_form">
                                <form onSubmit={handleSubmit((values) => submitQuote(values, editorHtml, sigantureImage))}>
                                    <div className="form-group" data-test="quote_job_select">
                                        <label className="form_title">Job</label>
                                        <Input name="quote[account_id]" onChange={(e) => props.accountChanged(e)}  readOnly={props.match.params.quoteId ? true : false} noOptionsMessage={noOptionsMessage} type="inputSelect" options={accounts} />
                                    </div>
                                    {/* <div className="form-group">
                                        <label className="form_title">Tag Opportunities</label>
                                        <Input type="radio" onClick={props.toggleTagOpportunities} labelClass="custom-control-label" forLable={"customRadio1"} value="true" label={"Yes"} className="custom-control-input" id="customRadio1" name="quote[tag_opportunity]" />
                                        <Input type="radio" onClick={props.toggleTagOpportunities} labelClass="custom-control-label" forLable={"customRadio2"} value="false" label={"No"} className="custom-control-input" id="customRadio2" name="quote[tag_opportunity]" />
                                    </div> */}
                                    <div className="desc_block">
                                        <div className="form-group">
                                            <label className="form_title">Amount</label>
                                            <Input type="number" dataTest="quote_amount_input" min={1} name="quote[total_sale]" className="form-control input-modifier" id="total_sale" />

                                            </div>
                                        {/* <div className="form-group">
                                            <label className="form_title">Description</label>
                                            <Input type="textarea" dataTest="quote_description_input" name="quote[description]" className="form-control input-modifier" rows="5" placeholder="Type here" id="comment" />
                                        </div> */}
                                    <div className="form-group">
                                        <label name="quote[quote_customer_ids]" className="form_title">Customer</label>
                                        <Input name="quote[quote_customer_ids]" readOnly={!props.dropDownCustomerList} noOptionsMessage={noCustomerMessage} isMulti={true} type="inputSelect" options={customer} />
                                    </div>

                                        <ReactQuill
                                            style={{ height: '50%' }}
                                            theme={theme}
                                            onChange={handleChange}
                                            defaultValue={editorHtml}
                                            value={editorHtml ? editorHtml : props.quote.description ? props.quote.description : '' }
                                            modules={EditorModules}
                                            formats={EditorFormats}
                                            // bounds={'.app'}
                                            placeholder='Detail work to be completed.'
                                        />
                                         <div className="file_attachment ticket_form" style={{paddingTop:'10px'}}>
                                        <Input type="file" name="attachment_paths" hidden={true} multiple={true} onSelect={props.onPortfolioFileChange} fileRef={props.reference} accept="image/*" />
                                        <a href="javascript:void(0)" onClick={props.choosePhotoClicked} className="file_title"><img src="/images/icons/attachment_icon.png" alt="Icon" /> Attachments</a>
                                        <p className="size_dec">Up to 10 atachments (10MB each).</p>

                                        <div class="upload_file">
                                            <div class="row">
                                           
                                                {props.savedPortfolioURls && props.savedPortfolioURls.map((url, i) => {
                                                    return (
                                                        <div class="col-4 col-sm-4 cst_gutter" key={i}>
                                                            <div class="file_view" onClick={() => photoUrlClicked(i)}>
                                                            <img src={url.photo_urls.small} alt="Image" onClick={() => base64Clicked(i, props.savedPortfolioURls.length)} />
                                                            {props.setLoader ? <ImageLoader
                                                                    style={{margin:'0 auto',display: 'block', width: '50%', height: '50%', cursor: 'pointer' }}
                                                                    src=""
                                                                    wrapper={React.createFactory('div')}
                                                                    preloader={preloader}>
                                                                    Image load failed!
                                                            </ImageLoader> : null}
                                                          
                                                            </div>
                                                            <div class="cancel_icon">
                                                                <img src="/images/icons/icn_delete_white.png" onClick={() => props.removeUrls(url, i)} alt="Image" />
                                                            </div>
                                                        </div>
                                                       
                                                    )
                                                })}
                                                {props.portfolioImages && props.portfolioImages.map((url, i) => {
                                                    return (
                                                        <div class="col-4 col-sm-4 cst_gutter" key={i} >
                                                            <div class="file_view">
                                                                <img src={url} alt="Image" onClick={() => base64Clicked(i, props.savedPortfolioURls.length)} />
                                                                <div class="cancel_icon">
                                                                    <img src="/images/icons/icn_delete_white.png" onClick={() => props.removeImage(url, i)} alt="Image" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                }

                                                )}
                                               {props.setLoader ? <ImageLoader
                                                                    style={{margin:'0 auto',display: 'block', width: '50%', height: '50%', cursor: 'pointer' }}
                                                                    src=""
                                                                    wrapper={React.createFactory('div')}
                                                                    preloader={preloader}>
                                                                    Image load failed!
                                                            </ImageLoader> : null}
                                            </div>
                                        </div>
                                    </div>
                                    
                                        {/* <span style={{ color: '#DD2726', fontSize: '13px' }}>{descriptionError}</span> */}

                                    </div>
                                    <div className="form-group btn_block mb-0">
                                        <button data-test="submit_later_button" disabled={props.state.submitLater || props.state.submitNow || (pristine && !props.match.params.quoteId)} onClick={handleSubmit(values => submitQuote({ ...values }, editorHtml, sigantureImage, 'submitLater'))} className={props.state.submitLater ? "btn cst_btn btn_danger mr-2 btn-wait" : "btn cst_btn btn_danger mr-2"}>{props.state.submitLater ? "" : "Submit Later"}</button>
                                        <button data-test="submit_button" disabled={props.state.submitNow || props.state.submitLater || (pristine && !props.match.params.quoteId)} onClick={handleSubmit(values => submitQuote({ ...values }, editorHtml, sigantureImage, 'submitNow'))} className={props.state.submitNow ? "btn cst_btn btn_danger mr-2 btn-wait" : "btn cst_btn btn_danger mr-2"}>{props.state.submitNow ? "" : "Submit Now"}</button>
                                        {/* <button disabled={props.isLoading} type="submit" className={props.isLoading ? "btn cst_btn btn_danger mr-2 btn-wait" : "btn cst_btn btn_danger mr-2"}>{props.isLoading ? "" : "Submit"}</button> */}
                                        <button data-test="cancel_button" type="button" onClick={
                                            () => props.onCancleClicked()} className="btn cst_btn btn-outline-secondary">Cancel</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </main>


    )
}


const mapStateToProps = (state) => {
    let quote = {}

    if (!isEmpty(state.adminOrSuperAdminReducer.quote)) {

        const getAccount = (account) => {
            return {
                value: account.id,
                label: account.name
            }
        }

        quote = cloneDeep(state.adminOrSuperAdminReducer.quote);
        quote['account_id'] = getAccount(quote.account);
        if (typeof quote.total_sale === 'string') {
            let total_sale = quote.total_sale.replace(/\,/g, '');
            quote['total_sale'] = parseFloat(total_sale);
        }
        quote['quote_customer_ids'] = quote.customer_quote && quote.customer_quote.map((cus) => {
            return {
                value: cus.customer_id ,
                label: cus.user.first_name + ' ' + cus.user.last_name
            }
        })

    } else {
        quote = {
            account_id: '',
            tag_opportunity: "true",
            quote_customer_ids: [],
        };
    }

    return {
        initialValues: { quote }
    }
}

NewQuotes = reduxForm({
    form: 'newQuoteForm',
    validate,
})(NewQuotes);

NewQuotes = connect(mapStateToProps)(NewQuotes);

export default NewQuotes;
// export default reduxForm({
//     form: 'newQuoteForm',
//     validate
// })(NewQuotes);
