import React from 'react'
import { Input } from '../../../../UI/InputElement/reactFormHookInputField'
import { useWatch } from 'react-hook-form'

function BpLineItems(props) {

    const watchFieldCompleted=useWatch({
        control:props.control,
        name:`lineItems.total_sq_footage`
      })
    
      const watchFieldTotalCompleted=useWatch({
        control:props.control,
        name:`lineItems.common_area_sq_footage`
      })
    
      const getTotal = () => {
        
        const total_sq_footage = props.getValues(`lineItems.total_sq_footage`)
        const common_area_sq_footage = props.getValues(`lineItems.common_area_sq_footage`)
        
        if(total_sq_footage && common_area_sq_footage){
            const total = (+total_sq_footage - +common_area_sq_footage)
            props.setValue(`lineItems.clean_tenant_sq_footage`,total)
            return
        }
        else return ""
      }

      const no_of_urinals=useWatch({
        control:props.control,
        name:`lineItems.no_of_urinals`
      })

      const no_of_toilets=useWatch({
        control:props.control,
        name:`lineItems.no_of_toilets`
      })

      const no_of_sinks=useWatch({
        control:props.control,
        name:`lineItems.no_of_sinks`
      })

      const no_of_showers=useWatch({
        control:props.control,
        name:`lineItems.no_of_showers`
      })

      const getRR = () => {
        
        const no_of_urinals = props.getValues(`lineItems.no_of_urinals`) ? props.getValues(`lineItems.no_of_urinals`) : 0
        const no_of_toilets = props.getValues(`lineItems.no_of_toilets`) ? props.getValues(`lineItems.no_of_toilets`) : 0
        const no_of_sinks = props.getValues(`lineItems.no_of_sinks`) ? props.getValues(`lineItems.no_of_sinks`) : 0
        const no_of_showers = props.getValues(`lineItems.no_of_showers`) ? props.getValues(`lineItems.no_of_showers`) : 0
        
        if(no_of_urinals || no_of_toilets || no_of_sinks || no_of_showers){
            const totalRR = +no_of_urinals + +no_of_toilets + +no_of_sinks + +no_of_showers
            props.setValue(`lineItems.no_of_fixtures_in_rr`,totalRR)
            return
        }
        else if(props.isEdit || props.isView) {
            return 0
        } 
        else {
            return ""
        }
      }

    return (
        <>
            <div className="col-md-6 col-sm-12">
                <div className="form-group">
                    <Input
                        type="number"
                        labelClasses="form-label"
                        label={`Total square footage`}
                        placeholder={'Total square footage'}
                        id={`lineItems.total_sq_footage`}
                        formControlProps={props.register(`lineItems.total_sq_footage`, {
                            required: "This field is required"
                        })}
                        validationErrorMessage={props.errors?.lineItems?.total_sq_footage?.message}
                        formControlClasses="form-control"
                        disabled={props.isView}
                    />
                </div>
            </div>
            <div className="col-md-6 col-sm-12">
                <div className="form-group">
                    <Input
                        type="number"
                        labelClasses="form-label"
                        label={`Number of floors`}
                        placeholder={'Number of floors'}
                        id={`lineItems.no_of_floors`}
                        formControlProps={props.register(`lineItems.no_of_floors`)}
                        formControlClasses="form-control"
                        disabled={props.isView}
                    />
                </div>
            </div>
            <div className="col-md-6 col-sm-12">
                <div className="form-group">
                    <Input
                        type="number"
                        labelClasses="form-label"
                        label={`Common area square footage`}
                        placeholder={'Common area square footage'}
                        id={`lineItems.common_area_sq_footage`}
                        formControlProps={props.register(`lineItems.common_area_sq_footage`, {
                            required: "This field is required"
                        })}
                        validationErrorMessage={props.errors?.lineItems?.common_area_sq_footage?.message}
                        formControlClasses="form-control"
                        disabled={props.isView}
                    />
                </div>
            </div>
            <div className="col-md-6 col-sm-12">
                <div className="form-group">
                    <Input
                        type="number"
                        labelClasses="form-label"
                        label={`Number of restrooms`}
                        placeholder={'Number of restrooms'}
                        id={`lineItems.no_of_restrooms`}
                        formControlProps={props.register(`lineItems.no_of_restrooms`)}
                        formControlClasses="form-control"
                        disabled={true}
                    />
                </div>
            </div>
            <div className="col-md-6 col-sm-12">
                <div className="form-group">
                    <Input
                        type="number"
                        labelClasses="form-label"
                        label={`Cleanable tenant square footage`}
                        placeholder={'Cleanable tenant square footage'}
                        id={`lineItems.clean_tenant_sq_footage`}
                        value={getTotal()}
                        formControlProps={props.register(`lineItems.clean_tenant_sq_footage`, {
                           // required: "This field is required"
                        })}
                        //validationErrorMessage={props.errors?.lineItems?.clean_tenant_sq_footage?.message}
                        formControlClasses="form-control"
                        disabled={true}
                    />
                </div>
            </div>
            <div className="col-md-6 col-sm-12">
                <div className="form-group">
                    <Input
                        type="number"
                        labelClasses="form-label"
                        label={`Number fixtures in RR’s`}
                        placeholder={'Number fixtures in RR’s'}
                        id={`lineItems.no_of_fixtures_in_rr`}
                        formControlProps={props.register(`lineItems.no_of_fixtures_in_rr`)}
                        formControlClasses="form-control"
                        value={getRR()}
                        disabled={true}
                    />
                </div>
            </div>
            <div className="col-md-6 col-sm-12">
                <div className="form-group">
                    <Input
                        type="number"
                        labelClasses="form-label"
                        label={`Number of stairwells`}
                        placeholder={'Number of stairwells'}
                        id={`lineItems.no_of_stairwells`}
                        formControlProps={props.register(`lineItems.no_of_stairwells`)}
                        formControlClasses="form-control"
                        disabled={true}
                    />
                </div>
            </div>
            <div className="col-md-6 col-sm-12">
                <div className="form-group">
                    <Input
                        type="number"
                        labelClasses="form-label"
                        label={`Number of toilets`}
                        placeholder={'Number of toilets'}
                        id={`lineItems.no_of_toilets`}
                        formControlProps={props.register(`lineItems.no_of_toilets`)}
                        formControlClasses="form-control"
                        disabled={true}
                    />
                </div>
            </div>
            <div className="col-md-3 col-sm-12">
                <div className="form-group">
                    <Input
                        type="number"
                        labelClasses="form-label"
                        label={`Stair width`}
                        placeholder={'Stair width'}
                        id={`lineItems.stair_width`}
                        formControlProps={props.register(`lineItems.stair_width`, {
                            required: "This field is required"
                        })}
                        validationErrorMessage={props.errors?.lineItems?.stair_width?.message}
                        formControlClasses="form-control"
                        disabled={props.isView}
                    />
                </div>
            </div>
            <div className="col-md-3 col-sm-12">
                <div className="form-group">
                    <Input
                        type="number"
                        labelClasses="form-label"
                        label={`Stair length`}
                        placeholder={'Stair length'}
                        id={`lineItems.stair_length`}
                        formControlProps={props.register(`lineItems.stair_length`, {
                            required: "This field is required"
                        })}
                        validationErrorMessage={props.errors?.lineItems?.stair_length?.message}
                        formControlClasses="form-control"
                        disabled={props.isView}
                    />
                </div>
            </div>
            <div className="col-md-6 col-sm-12">
                <div className="form-group">
                    <Input
                        type="number"
                        labelClasses="form-label"
                        label={`Number of urinals`}
                        placeholder={'Number of urinals'}
                        id={`lineItems.no_of_urinals`}
                        formControlProps={props.register(`lineItems.no_of_urinals`)}
                        formControlClasses="form-control"
                        disabled={true}
                    />
                </div>
            </div>
            <div className="col-md-6 col-sm-12">
                <div className="form-group">
                    <Input
                        type="number"
                        labelClasses="form-label"
                        label={`Stair square feet or #`}
                        placeholder={'Stair square feet or #'}
                        id={`lineItems.stair_sqft`}
                        formControlProps={props.register(`lineItems.stair_sqft`, {
                            required: "This field is required"
                        })}
                        validationErrorMessage={props.errors?.lineItems?.stair_sqft?.message}
                        formControlClasses="form-control"
                        disabled={props.isView}
                    />
                </div>
            </div>
            <div className="col-md-6 col-sm-12">
                <div className="form-group">
                    <Input
                        type="number"
                        labelClasses="form-label"
                        label={`Number of sinks`}
                        placeholder={'Number of sinks'}
                        id={`lineItems.no_of_sinks`}
                        formControlProps={props.register(`lineItems.no_of_sinks`)}
                        formControlClasses="form-control"
                        disabled={true}
                    />
                </div>
            </div>
            <div className="col-md-6 col-sm-12">
                <div className="form-group">
                    <Input
                        type="number"
                        labelClasses="form-label"
                        label={`FTEs`}
                        placeholder={'FTEs'}
                        id={`lineItems.fte`}
                        formControlProps={props.register(`lineItems.fte`, {
                            required: "This field is required"
                        })}
                        validationErrorMessage={props.errors?.lineItems?.fte?.message}
                        formControlClasses="form-control"
                        disabled={props.isView}
                    />
                </div>
            </div>
            <div className="col-md-6 col-sm-12">
                <div className="form-group">
                    <Input
                        type="number"
                        labelClasses="form-label"
                        label={`Number of fountains`}
                        placeholder={'Number of fountains'}
                        id={`lineItems.no_of_fountains`}
                        formControlProps={props.register(`lineItems.no_of_fountains`)}
                        formControlClasses="form-control"
                        disabled={true}
                    />
                </div>
            </div>
            <div className="col-md-6 col-sm-12">
                <div className="form-group">
                    <Input
                        type="number"
                        labelClasses="form-label"
                        label={`Number of elevators`}
                        placeholder={'Number of elevators'}
                        id={`lineItems.no_of_elevators`}
                        formControlProps={props.register(`lineItems.no_of_elevators`, {
                            required: "This field is required"
                        })}
                        validationErrorMessage={props.errors?.lineItems?.no_of_elevators?.message}
                        formControlClasses="form-control"
                        disabled={props.isView}
                    />
                </div>
            </div>
            <div className="col-md-6 col-sm-12">
                <div className="form-group">
                    <Input
                        type="number"
                        labelClasses="form-label"
                        label={`Elevator sq. footage`}
                        placeholder={'Elevator sq. footage'}
                        id={`lineItems.elevator_sq_footage`}
                        formControlProps={props.register(`lineItems.elevator_sq_footage`, {
                            required: "This field is required"
                        })}
                        validationErrorMessage={props.errors?.lineItems?.elevator_sq_footage?.message}
                        formControlClasses="form-control"
                        disabled={props.isView}
                    />
                </div>
            </div>
            <div className="col-md-12 col-sm-12">
                <div className="form-group">
                    <Input
                        type="text"
                        labelClasses="form-label"
                        label={`Type of lobby floor`}
                        placeholder={'Type of lobby floor'}
                        id={`lineItems.type_of_floor`}
                        formControlProps={props.register(`lineItems.type_of_floor`, {
                            required: "This field is required"
                        })}
                        validationErrorMessage={props.errors?.lineItems?.type_of_floor?.message}
                        formControlClasses="form-control"
                        disabled={props.isView}
                    />
                </div>
            </div>
            <div className="col-md-12 col-sm-12">
                <div className="form-group">
                <Input
                        type="text"
                        labelClasses="form-label"
                        label={`Primary carpet type throughout the building`}
                        placeholder={'Primary carpet type throughout the building'}
                        id={`lineItems.primary_carpet_type`}
                        formControlProps={props.register(`lineItems.primary_carpet_type`, {
                            required: "This field is required"
                        })}
                        validationErrorMessage={props.errors?.lineItems?.primary_carpet_type?.message}
                        formControlClasses="form-control"
                        disabled={props.isView}
                    />
                </div>
            </div>
            <div className="col-md-12 col-sm-12">
                <div className="form-group">
                <Input
                        type="text"
                        labelClasses="form-label"
                        label={`Carpet Manufacturer (if known)`}
                        placeholder={'Carpet Manufacturer (if known)'}
                        id={`lineItems.carpet_manufacturer`}
                        formControlProps={props.register(`lineItems.carpet_manufacturer`, {
                            required: "This field is required"
                        })}
                        validationErrorMessage={props.errors?.lineItems?.carpet_manufacturer?.message}
                        formControlClasses="form-control"
                        disabled={props.isView}
                    />
                </div>
            </div>
            <div className="col-md-6 col-sm-12">
                <div className="form-group">
                <Input
                        type="text"
                        labelClasses="form-label"
                        label={`Cut pile over pad`}
                        placeholder={'Cut pile over pad'}
                        id={`lineItems.cut_pile_over_pad`}
                        formControlProps={props.register(`lineItems.cut_pile_over_pad`, {
                            required: "This field is required"
                        })}
                        validationErrorMessage={props.errors?.lineItems?.cut_pile_over_pad?.message}
                        formControlClasses="form-control"
                        disabled={props.isView}
                    />
                </div>
            </div>
            <div className="col-md-6 col-sm-12">
                <div className="form-group">
                <Input
                        type="text"
                        labelClasses="form-label"
                        label={`Glue down`}
                        placeholder={'Glue down'}
                        id={`lineItems.glue_down`}
                        formControlProps={props.register(`lineItems.glue_down`, {
                            required: "This field is required"
                        })}
                        validationErrorMessage={props.errors?.lineItems?.glue_down?.message}
                        formControlClasses="form-control"
                        disabled={props.isView}
                    />
                </div>
            </div>
            <div className="col-md-6 col-sm-12">
                <div className="form-group">
                <Input
                        type="text"
                        labelClasses="form-label"
                        label={`Plush`}
                        placeholder={'Plush'}
                        id={`lineItems.plush`}
                        formControlProps={props.register(`lineItems.plush`, {
                            required: "This field is required"
                        })}
                        validationErrorMessage={props.errors?.lineItems?.plush?.message}
                        formControlClasses="form-control"
                        disabled={props.isView}
                    />
                </div>
            </div>
            <div className="col-md-6 col-sm-12">
                <div className="form-group mb-0">
                <Input
                        type="text"
                        labelClasses="form-label"
                        label={`Interface carpet tile`}
                        placeholder={'Interface carpet tile'}
                        id={`lineItems.carpet_tile`}
                        formControlProps={props.register(`lineItems.carpet_tile`, {
                            required: "This field is required"
                        })}
                        validationErrorMessage={props.errors?.lineItems?.carpet_tile?.message}
                        formControlClasses="form-control"
                        disabled={props.isView}
                    />
                </div>
            </div>
            <div className="col-md-6 col-sm-12">
                <div className="form-group">
                <Input
                        type="text"
                        labelClasses="form-label"
                        label={`Other`}
                        placeholder={'Other'}
                        id={`lineItems.others`}
                        formControlProps={props.register(`lineItems.others`, {
                            required: "This field is required"
                        })}
                        validationErrorMessage={props.errors?.lineItems?.others?.message}
                        formControlClasses="form-control"
                        disabled={props.isView}
                    />
                </div>
            </div>
            <div className="col-md-12 col-sm-12">
                <div className="form-group">
                <Input
                        type="textarea"
                        labelClasses="form-label"
                        label={`Notes`}
                        placeholder={'Type here'}
                        id={`lineItems.notes`}
                        formControlProps={props.register(`lineItems.notes`, {
                            required: "This field is required"
                        })}
                        validationErrorMessage={props.errors?.lineItems?.notes?.message}
                        formControlClasses="form-control"
                        disabled={props.isView}
                    />
                </div>
            </div>
        </>
    )
}

export default BpLineItems