import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../../redux/actions/index';
import { animateScroll as scroll } from 'react-scroll'
import storage from '../../../../utility/storage';
import PreviewTimecardCorrectedComponent from '../../../component/TimeTrackerModuleComponent/TimeCardCorrectionModuleComponent/PreviewTimecardCorrectedComponent';

class PreviewTimecardCorrectedContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isTriggerLoader: false,
        }

    }

    componentDidMount() {

        this.setState({ isTriggerLoader: true });
        setTimeout(() => {
            this.setState({ isTriggerLoader: false });
        }, 2500);

        if (this?.props?.match?.params?.timeCardId) {
            this.props.getSingleTimecardListData(this.props.match.params.timeCardId);
        }

        if (this?.props?.location?.state?.shiftDetailId) {
            this.props.getSingleShifSchedule(this?.props.location.state.shiftDetailId);
        }

        scroll.scrollToTop();

    }

    componentWillUnmount() {
        this.props.resetTimeTrackerObjects();
    }

    render() {
        return (
            <>

                <PreviewTimecardCorrectedComponent
                    {...this.props}
                    isTriggerLoader={this.state.isTriggerLoader}
                />

            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.authReducer.user,
        isLoading: state.TimeTrackerModReducer.isLoading,
        singleShiftScheduleData: state.TimeTrackerModReducer.singleShiftScheduleData,
        singleTimecardCorrectData: state.TimeTrackerModReducer.singleTimecardCorrectData,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        resetTimeTrackerObjects: () => dispatch(actions.resetTimeTrackerObjects()),

        getSingleShifSchedule: (id) => dispatch(actions.getSingleShifSchedule(id)),
        getSingleTimecardListData: (id) => dispatch(actions.getSingleTimecardListData(id)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PreviewTimecardCorrectedContainer);