import React, { useEffect, useState } from 'react'
import withFormHoc from '../../../../../hoc/withReactHookForm/withReactHookForm'
import { Input } from '../../../UI/InputElement/reactFormHookInputField'
import { useFieldArray } from 'react-hook-form'
import MonthlyTable from './MonthlyTable'
import { useSelector } from 'react-redux'
import withFormHocCostSummary from '../../../../../hoc/withReactHookForm/withReactHookFormCostSummary'
import EditCostSummaryModal from './modal/editModal'

function MonthlyTablePageHook(props) {
  const current = new Date()

  const { fields: MonthlyFields } = useFieldArray({
    name: "sections",
    control: props.control
  })
  const { isCostfetched } = useSelector((state) => state.InventoryModuleReducer)

  const [modalData, setModalData] = useState(null)
  const [INDEX, setIndex] = useState(null)
  // isLoadMonthlyCost

  const closeModal = () => {

    const closeButton = document.getElementById("CloseModal")
    closeButton.click()
    
  }




  return (
    <>
      {MonthlyFields.map((month, index) => {
        return (
          <>
            <MonthlyTable key={1} index={index} setIndex={setIndex} data={month} {...props} setModalData={setModalData} />
          </>
        )
      })
      }
      {
        isCostfetched && MonthlyFields.length ==0 ? <p style={{marginLeft:"20rem",fontSize:"larger"}}> Cost summary  is not available for this Year</p> : null
      }

      
      
      <div class="custom_modal_jobcard">
        <div
          class={`modal fade`}
          id="editCost"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"

        >
          <EditCostSummaryModal refresh={props.refresh} modalData={modalData} setModalData={setModalData} index={INDEX} closeModal={closeModal} {...props} />
        </div>
      </div>
    </>

  )
}

export default withFormHocCostSummary(MonthlyTablePageHook)
