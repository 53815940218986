import React, { Component } from 'react';
import { routes } from '../../../../utility/constants/constants';
import InventoryList from '../../../component/Inventory/InventoryListComponent/InventoryList';
import * as actions from '../../../../redux/actions/index';
import { connect } from 'react-redux';
import { reset } from 'redux-form';
import { animateScroll as scroll} from 'react-scroll'
import storage from '../../../../utility/storage';
import { convertDateToDifferentTZ } from '../../../../utility/utility';

var moment = require('moment');
const cloneDeep = require('clone-deep');

class InventoryListContainer extends Component {

    constructor(props) {
        super(props);

        this.state = {
            
        }
    }

    componentDidMount() {
        scroll.scrollToTop();
    }

    componentDidUpdate(prevProps, prevState) {
        const user = storage.get('user');
        if(user) {
            if(prevProps.user.selected_mode !== user.selected_mode) {
                this.props.inventoryItemsListPageClicked(0)
            }
        }
    }

    resetClicked = () => {
        const page = 1
        const limit = this.props.inventoryItemsList_no_of_rows
        this.props.reset('searchInventoryItemsList');
        this.props.inventoryItemsListPageClicked(0)
        this.props.getAllInventoryItemsList({page,limit})

    }

    searchInventoryItemsListClicked = (value) => {
        const values = cloneDeep(value)
        let name = ''
        let manufacture = ''
        let expiration_date = ''
        let supplier = ''
        let os1 = ''
    
        const page = 1
        const limit = this.props.inventoryItemsList_no_of_rows
      if(values.name) {
          values.name = values.name.trim()
          name = values.name
      }
      if(values.manufacture) {
        values.manufacture = values.manufacture.trim()
        manufacture = values.manufacture
      }
      if(values.expiration_date) {
        values.expiration_date = convertDateToDifferentTZ(values.expiration_date, 'to');
        expiration_date = values.expiration_date.split('T')[0];
      }  
      if(values.supplier) {
        values.supplier = values.supplier.trim()
          supplier =  values.supplier 
        }  

        if(values.os1) {
            os1 = values.os1 === 'yes' ? true : false
          }

        this.props.getAllInventoryItemsList({page,limit,name,manufacture,expiration_date,supplier, os1}, values);
    }

    addInventoryItemClicked = () => {
        this.props.history.push(routes.NEW_INVENTORY_ITEM);
    }

    deleteInventoryItem = (id) => {
        this.props.deleteInventoryItem(id);
    }

    handleInventoryOpenDeleteModal = (value) => {
        this.props.handleInventoryOpenDeleteModal(value);
    }

    handleInventoryCloseDeleteModal = () => {
        this.props.handleInventoryCloseDeleteModal();
    }
         
    render() {
        return (
            <InventoryList
                {...this.props}
                isLoading={this.props.isLoading}
                onSubmit={this.searchInventoryItemsListClicked}
                resetClicked={this.resetClicked}
                inventoryItemsList={this.props.inventoryItemsList ? this.props.inventoryItemsList : []}
                addInventoryItemClicked={this.addInventoryItemClicked}
                deleteInventoryItem={this.deleteInventoryItem}
                handleInventoryOpenDeleteModal={this.handleInventoryOpenDeleteModal}
                handleInventoryCloseDeleteModal={this.handleInventoryCloseDeleteModal}
                
            />
        )
    }
}


const mapStateToProps = (state) => {
    return {
        user: state.authReducer.user,
        isLoading: state.InventoryReducer.isLoading,
        inventoryItemsList: state.InventoryReducer.inventoryItemsList,
        isInventoryItemList: state.InventoryReducer.isInventoryItemList,
        inventoryItemsList_no_of_rows: state.InventoryReducer.inventoryItemsList_no_of_rows,
        inventoryItemsListCurPage: state.InventoryReducer.inventoryItemsListCurPage,
        openInventoryDeleteModal: state.InventoryReducer.openInventoryDeleteModal,
        deleteInventoryObject: state.InventoryReducer.deleteInventoryObject,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        reset: (searchInventoryItemsList) => dispatch(reset(searchInventoryItemsList)),  // requires form name
        inventoryItemsListPageClicked: (page) => dispatch(actions.inventoryItemsListPageClicked(page)),
        getAllInventoryItemsList: (params, values) => dispatch(actions.getAllInventoryItemsList(params, values)),
        deleteInventoryItem: (id) => dispatch(actions.deleteInventoryItem(id)),
        handleInventoryOpenDeleteModal: (value) => dispatch(actions.handleInventoryOpenDeleteModal(value)),
        handleInventoryCloseDeleteModal: () => dispatch(actions.handleInventoryCloseDeleteModal()),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(InventoryListContainer);