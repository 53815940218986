import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../../../redux/actions/index';
import { closeModel, endOfWeek, getUser, startOfWeek } from '../../../../../utility/utility';
import { routes, userRoles } from '../../../../../utility/constants/constants';
import { reset } from 'redux-form';
import isEmpty from '../../../../../utility/IsEmptyValidator';
import $ from "jquery";
import { validateAccountUsers } from '../../../../../utility/validator/submit';
import { animateScroll as scroll } from 'react-scroll'
import ShiftCalendarComponent from '../../../../component/TimeTrackerModuleComponent/ShiftComponent/ShiftCalendarComponent/ShiftCalendarComponent';
import { criteria } from '../../../../../utility/validator/validator';
import { startOfMonth, endOfMonth, convertDateFormatForSchedule, convertDateToDifferentTZ } from '../../../../../utility/utility';
import storage from '../../../../../utility/storage';
import { customTime, tzEndOfWeek, tzStartOfWeek, tzUTCDateConverterTool_V2 } from '../../../../../utility/TimezoneOperations/timezoneUtility';
const cloneDeep = require('clone-deep');
var moment = require('moment');

class ShiftCalendarContainer extends Component {

    constructor(props) {
        super(props);

        this.state = {
        }
    }

    componentDidMount() {
        const user = getUser();
        storage.set('liveDay', new Date());

        let firstWeekDate = tzStartOfWeek(customTime(new Date()));
        let lastWeekDate = tzEndOfWeek(customTime(new Date()));

        if (this.props && this.props.user && this.props.user.role && this.props.user.role == userRoles.FRONTLINE) {
            this.props.getShiftCalendarScheduledList(tzUTCDateConverterTool_V2(firstWeekDate, 'from'), tzUTCDateConverterTool_V2(lastWeekDate, 'to'), user.id);
        } else if (this.props && this.props.match && this.props.match.params && this.props.match.params.userId) {
            this.props.getFrontline(this.props.match.params.userId);
            this.props.getShiftCalendarScheduledList(tzUTCDateConverterTool_V2(firstWeekDate, 'from'), tzUTCDateConverterTool_V2(lastWeekDate, 'to'), this.props.match.params.userId);
        }

        scroll.scrollToTop();
    }
    componentDidUpdate() {
        if(this.props.frontlineUser){
            this.props.frontlineUserActiveNameChange(`${this.props.frontlineUser?.first_name} ${this.props.frontlineUser?.last_name} -`)
        }
    }

    handleNewShiftClicked = (userObject) => {
        this.props.history.push({ pathname: routes.FLUSER_NEW_SHIFT, state: userObject });
        this.props.getJobAccountSitesList({ userId: userObject.frontlineUser.id, address: true });
    }

    render() {

        return (
            <ShiftCalendarComponent
                {...this.props}
                handleNewShiftClicked={this.handleNewShiftClicked}
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.authReducer.user,
        isLoading: state.TimeTrackerModReducer.isLoading,
        frontlineUser: state.adminOrSuperAdminReducer.frontlineUser,
        getSingleUserShiftPlannerList: state.TimeTrackerModReducer.getSingleUserShiftPlannerList,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getFrontline: (id) => dispatch(actions.getFrontline(id)),
        getShiftCalendarScheduledList: (startDate, endDate, id) => dispatch(actions.getShiftCalendarScheduledList(startDate, endDate, id)),
        getJobAccountSitesList: (params) => dispatch(actions.getJobAccountSitesList(params)),
        frontlineUserActiveNameChange:(name)=> dispatch(actions.updateActiveUserName(name))
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(ShiftCalendarContainer);