import React, { useEffect, useState } from 'react';
import ReactTable from 'react-table-v6';
import '../../../../../styles/custom.css';
import { CustomNoDataComponent, makeFirstLetterCapital,convertDateToDifferentTZ } from '../../../../../utility/utility';
import { FaArrowDown, FaArrowUp } from "react-icons/fa";
import ReactTooltip from 'react-tooltip';
import * as actions from '../../../../../redux/actions/index';
import { useSelector, useDispatch } from 'react-redux';
import { ClapSpinner } from 'react-spinners-kit';
import 'react-table-v6/react-table.css';
import { routes } from '../../../../../utility/constants/constants';
var moment = require('moment');

let BuildingProfileTable = (props) => {

    const dispatch = useDispatch();
    // const SearchValue = useSelector(state => state.form.searchScheduledTask && state.form.searchScheduledTask.values ? state.form.searchScheduledTask.values : {});
    const DateGenerate = useSelector(state => state.BuildingProfileReducer.dataGenerated);
    // const values = useSelector(state => state.BuildingProfileReducer.scheduledTask_searchValues);
    const No_of_pages = useSelector(state => state.BuildingProfileReducer.buildingProfile_total_pages);
    const No_of_rows = useSelector(state => state.BuildingProfileReducer.buildingProfile_no_of_rows);
    const isBuildingProfile = useSelector(state => state.BuildingProfileReducer.isBuildingProfile);
    const buildingProfileCurPage = useSelector(state => state.BuildingProfileReducer.buildingProfileCurPage);
    const SearchOrResetScheduledTaskListClicked = useSelector(state => state.BuildingProfileReducer.SearchOrResetScheduledTaskListClicked);

    const accountTimezone = 'America/Los_Angeles';

    const data = props.buildingProfile_list && props.buildingProfile_list.map((value) => {
        return {
            building_name : value && value.building_name ? value.building_name : '-',
            address:  value && value.address ? value.address : '-',
            city: value && value.city ? value.city : '-',
            state:  value && value.state ? value.state : '-',
            zip : value && value.zip ? value.zip : '-',
            actions:
                <>
                <span className="mr_20 account_edit_icn" onClick={() => props.history.push(`/building-profile/view/${value.id}`)} ><i class="fas fa-eye" aria-hidden="true" ></i></span>
                {props.isCreateAccess ? <span className="mr_20 account_edit_icn"  onClick={() => props.history.push(`/building-profile/${value.id}`)} ><i class="fa fa-pencil" aria-hidden="true" ></i></span> : null}
                </>
        }
    })

    const columns = [
        {
            Header: <><span> Name of the Building </span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /></>,
            accessor: 'building_name',
            show: props.buildingProfile_list.length !== 0,
            //width: 235,
        },
        {
            Header: <><span> Address </span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /></>,
            accessor: 'address',
            show: props.buildingProfile_list.length !== 0,
            width: 250,
        },
        {
            Header: <><span>City </span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /></>,
            accessor: 'city',
            show: props.buildingProfile_list.length !== 0,
            //width: 135,
        },
        {
            Header: <><span> State</span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /></>,
            accessor: 'state',
            show: props.buildingProfile_list.length !== 0,
            //width: 235,
        },
        {
            Header: <><span> Zip </span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /></>,
            accessor: 'zip',
            show: props.buildingProfile_list.length !== 0,
            //width: 235,
        },
        {
            Header: '',
            accessor: 'actions',
           // width: 135,
            sortable:false,
        }
    ]

    const [tablePage, setTablePage] = useState(0)
    const tablePageNew =  React.useRef()
    if(buildingProfileCurPage !== tablePageNew.current) {
        tablePageNew.current = buildingProfileCurPage;
      }
    const setTablePageFn = (page) => {
        setTablePage(page)
        props.buildingProfilePageClicked(page)
        tablePageNew.current = page;
    }

    const fetchData = (state, instance) => {
        if (SearchOrResetScheduledTaskListClicked) {
            dispatch(actions.resetSearchOrResetInventory())
        } else {
            if (!DateGenerate && !props.isBuildingProfile) {
                state.page = 2;
                const limit = state.pageSize;
                let page = tablePageNew.current >= 0 ? tablePageNew.current + 1 : buildingProfileCurPage + 1;
                const inventoryItemsList_no_of_rows = state.pageSize;
                if(No_of_rows != inventoryItemsList_no_of_rows) {
                    page = 1;
                    setTablePageFn(0)
                }
                
                const order = state.sorted[0]?.id;
                const orderBy = state.sorted && state.sorted.length ? state.sorted[0]?.desc === true ? 'desc' : 'asc' : null;
                // const accountId = SearchValue.id ? SearchValue.id : null;
                // const taskName = SearchValue.name ? SearchValue.name : null;
                // const companyName = SearchValue.company ? SearchValue.company : null;
                // const accountName = SearchValue.job ? SearchValue.job : null;
                // const from = SearchValue.fromDate ? convertDateToDifferentTZ(SearchValue.fromDate, 'from') : null;
                // const to = SearchValue.toDate ? convertDateToDifferentTZ(SearchValue.toDate, 'to') : null;
                props.getAllBuildingProfile({page,limit, order, orderBy})
            }
        }
    }

    return (
        SearchOrResetScheduledTaskListClicked && props.isBuildingProfile ?
            <div  >
                <ClapSpinner backColor="#89073c" size={40} frontColor="#89073c8a" />
            </div>
            :
            <ReactTable
                data={data}
                loading={props.isBuildingProfile}
                minRows={0}
                defaultPageSize={No_of_rows}
                pages={No_of_pages}
                gotoPage={buildingProfileCurPage}
                page = { buildingProfileCurPage }
                onPageChange={(page) => {setTablePageFn(page)}}
                // onPageSizeChange={props.onUserPageSizeChange}
                manual
                onFetchData={fetchData}
                showPaginationTop={true}
                showPaginationBottom={false}
                showPagination={props.buildingProfile_list.length !== 0}
                columns={columns}
                // style={{marginTop: '10px'}}
                NoDataComponent={() => CustomNoDataComponent(isBuildingProfile, 'No Building Profile List Found')}
                LoadingComponent={() =>
                    isBuildingProfile ?
                        <div style={{
                            display: "block",
                            position: "absolute",
                            left: 0,
                            height: '100%',
                            right: 0,
                            background: "rgba(255,255,255,0.8)",
                            transition: "all .3s ease",
                            top: 0,
                            bottom: 0,
                            textAlign: "center"
                        }}
                            className="loader_btn_block">
                            <ClapSpinner backColor="#89073c" size={40} frontColor="#89073c8a" />
                        </div> : null}
                getTheadThProps={(state, rowInfo, column, instance) => {
                    // console.log(column);
                    return {
                        style: {
                            borderTop: 'none',
                            borderBottom: 'none',
                            borderRight: 'none',
                            textAlign: 'center',
                            color: '#566975',
                            fontSize: '14px',
                            fontWeight: '600',
                            whiteSpace: 'nowrap',
                            float: 'left',
                        }
                    }
                }
                }
                getTrProps={(state, rowInfo, column, instance) => {
                    // onclick = () => userDetail(rowInfo ? rowInfo.row.user : rowInfo);
                    console.log(column);
                    return {
                        className: 'react-table-tr-element',
                        style: {
                            border: '1px solid #c7cdd1',
                            transition: '.3s ease-in-out',
                            width: '100%',
                            // borderRadius: '4px',
                            // height: '80px',
                            marginTop: '5px',
                            float: 'left'
                        }
                    }

                }}
                getTdProps={(state, rowInfo, column, instance) => {

                    if (column.id == 'image') {
                        return {
                            style: {
                                marginTop: '0px'
                            }
                        }
                    } else {
                        return {
                            className: 'react-td-element',
                        }
                        //  {
                        //   style: {
                        //     borderTop: 'none',
                        //     verticalAlign: 'middle',
                        //     fontSize: '14px',
                        //     color: '#2b3034',
                        //     marginTop: '10px !important',
                        //     textAlign: 'center',
                        //   }
                        // }
                    }
                }
                }
                // getPaginationProps={() => {
                //     return {
                //         style: {
                //             marginTop: '50px',
                //             border: 'none',
                //             boxShadow: 'none'
                //         }
                //     }
                // }
                // }
                getProps={() => {
                    return {
                        style: {
                            border: 'none'
                        }
                    }
                }}
            />
    )
}

export default BuildingProfileTable;