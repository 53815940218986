import React, { useEffect, useState } from 'react';
import { formValueSelector, getFormValues, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Input } from '../../../UI/InputElement/InputElement';
import { convertDateToPickerFormat } from '../../../../../utility/utility';
import { userRoles } from '../../../../../utility/constants/constants';
import ShiftTable from './ShiftTable';

let Shift = (props) => {

    const { handleSubmit, pristine, reset, submitting, error } = props;

    const statusOptions = [
        {
            id: 'today',
            name: `Today's Shift`
        }, {
            id: 'upcoming',
            name: 'Upcoming Shifts'
        }, {
            id: 'past',
            name: 'Past Shifts'
        }
    ];

    return (
        <>
            <main className="app-content wraper_content inspection_wraper">
                <div className="tab_header search_filter_cont inspection_tab_content ticket_filter">
                    <form onSubmit={handleSubmit}>
                        <input type="hidden" value="something" />
                        <div className="row">
                        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                <Input name="status" dataTest="select_ticket_status" type="select" options={statusOptions} placeholder="Search By Shifts
                                " className="custom-select input-modifier add_user_select" />
                            </div>

                            <div className="col-xl-8 col-lg-6 col-sm-3 col-12 text-right">
                                <button data-test="filter_go_btn" style={{ minWidth: '90px' }} className={"btn cst_btn btn_danger mr-2  account_btn"} id="submit" type="submit">{"Go"} </button>
                                <button data-test="filter_reset_btn" style={{ minWidth: '90px' }} onClick={props.resetClicked} className={"btn cst_btn btn-outline-secondary account_btn"} id="reset" type="reset">{"Reset"}</button>
                            </div>
                        </div>
                    </form>
                </div>


                <section className="account_sec inspection_tab_content appcontent_Inner">
                    {/* <div>
                        <button style={{ float: 'right' }}>Enable Filter</button>
                    </div> */}
                    {/* <CSVLink filename="Accounts.csv" className="csv_btn btn_danger " data={convertAccountsDataToCsvType(props.accountsList)} >Export Csv</CSVLink><br /><br /><br /> */}
                    <div className="quotes_table_content accounts_table_contnet table-responsive">

                        <div className="table quotes_table user_react_table">
                            <ShiftTable
                                {...props}
                            />
                        </div>

                    </div>
                    {/* <div className="clearfix"></div>
                <div className="modal-footer">
                    <button type="button" className="btn btn_danger cst_btn">Save</button>
                    <button type="button" className="btn btn-outline-secondary cst_btn" data-dismiss="modal">Cancel</button>
                </div> */}
                </section>
            </main>
            <Dialog
                open={props.openDeleteModel}
                onClose={props.handleClose}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title" style={{ fontSize: '13', color: '#06080f' }}>
                    <h3 style={{ color: '#ab385e' }} data-test="modal_delete_title">Are you sure?</h3>
                </DialogTitle>

                <DialogActions>
                    <button data-test="modal_delete" style={{ minHeight: '36px', minWidth: '80px' }} onClick={() => props.deleteTicket(props.deleteObject.id)} disabled={props.isLoading} className={props.isLoading ? "btn cst_btn btn_danger mr-2 btn-wait" : "btn cst_btn btn_danger mr-2"} id="delete" type="delete">{props.isLoading ? "" : "Delete"}</button>
                    <button data-test="modal_cancel" style={{ minHeight: '36px', minWidth: '80px' }} onClick={props.handleDeleteClose} className={"btn cst_btn btn-outline-secondary"} id="cancel" type="cancel">Cancel</button>
                </DialogActions>
            </Dialog>
        </>

    )
}

const mapStateToProps = (state) => {
    let status = state.TimeTrackerModReducer.shift_searchValues && state.TimeTrackerModReducer.shift_searchValues.status ? state.TimeTrackerModReducer.shift_searchValues.status : "";

    return { initialValues: { status }, formStates: getFormValues('searchShift')(state) }
}

Shift = reduxForm({
    form: 'searchShift',
    enableReinitialize: true
})(Shift);
Shift = connect(mapStateToProps)(Shift)

export default Shift;