import React, { useState } from 'react';
import { currentData } from '../../../utility/constants/constants';
import { GetFormatedDate, weekStartDayAndWeekEndDay, getMonthAndYear, getYearView, get_next_week_start, getNextYearDate, getNextMonthDate, getPast90Days, get_next_90days, convertDateToDifferentTZ, convertDateAndTimeTimezonelll, getUser, getPast90DaysCall, KpiGetFormatedDate, getNextMonth, weekStartDayAndWeekEndDay1, getPast90DaysStartDayAndEndDay } from '../../../utility/utility';
import { convertDashboardDataToCsvType } from '../../../utility/utility';
import { CSVLink, CSVDownload } from "react-csv";
import { Input } from '../UI/InputElement/InputElement';
import CustomeDateFormate from './CustomeDateFormat';
import cloneDeep from 'clone-deep';
import  moment from "moment";
import { connect, useSelector } from 'react-redux';
import * as actions from '../../../redux/actions/index';
import storage from '../../../utility/storage';
import errorHandler from '../../../utility/errorHandler/errorHandler';

var format = require('date-format');

const WeekMonthYearLists = (props) => {

    const searchKpiValues = useSelector(state => state.adminOrSuperAdminReducer.searchKpiValues);

    const [showDatePicket, setShowDatePicker] = useState(false);
    const customDateClicked = () => {
        setShowDatePicker(true);
    }
    const closeCustomDateClicked = () => {
        setShowDatePicker(false);
    }
    const presentDate = new Date();

    
    const goClicked = (data) => {
        let values = {}
        const user = getUser();
        values['company_id'] = props.match.params.companyId;
        // values['quarter'] = getPast90DaysCall(props.currentDate);
        values['date']=KpiGetFormatedDate(props.currentDate);
        values['from']= KpiGetFormatedDate(data.fromDate);
        values['to']= KpiGetFormatedDate(data.toDate);
        values['mode']= user.mode;
        values['option']=currentData.DATEFILTER;
        values['account_id']= searchKpiValues ? searchKpiValues.account_id : '';
        values['inspected_by_id']= searchKpiValues ? searchKpiValues.inspected_by_id :'';
        // values['account_id']=[user.id];
        storage.set('companyId', props.match.params.companyId)
        const userDetails = getUser();
        props.getKpiInspectionCount(values)
        props.getKpiAvarageScore(values)
        props.getKpiTopInspection(values)
        props.getKpiQualityInspection(values);
        props.getKpiAverageSectionQuality(values);
        props.getKpiAverageSectionSafety(values)
    }

    const customDayDifferent = (values) => {
        var months;
        months = (values.toDate.getFullYear() - values.fromDate.getFullYear()) * 12;
        months -= values.fromDate.getMonth();
        months += values.toDate.getMonth();
        return months <= 0 ? 0 : months;
      }

    return (
        <div className="row">
            <div className="col-md-12">
                <div className="app_inner_title">
                    <div className="calender_block">
                        <div className="filter_cal_box">
                            <div className="filter_block">
                                <div className="list_filter">
                                    {props.showWeek === 'show' ? null : <a className={props.isWeek ? props.isLoading ? "list_link active btn-wait" : "list_link active" : "list_link"} onClick={() => {props.viewModeClicked(currentData.WEEK); closeCustomDateClicked()}} href="javascript:void(0)">{props.isWeek ? props.isLoading ? '' : 'Week' : 'Week'}</a>}
                                    <a className={props.isMonth ? props.isLoading ? "list_link active " : "list_link active " : "list_link"} onClick={() => {props.viewModeClicked(currentData.MONTH); closeCustomDateClicked()}} href="javascript:void(0)">{props.isMonth ? props.isLoading ? 'Month' : 'Month' : 'Month'}</a>
                                    {props.showYear === 'show' ? null : <a className={props.isYear ? props.isLoading ? "list_link active btn-wait" : "list_link active " : "list_link"} onClick={() => {props.viewModeClicked(currentData.YEAR); closeCustomDateClicked()}} href="javascript:void(0)">{props.isYear ? props.isLoading ? '' : 'Year' : 'Year'}</a>}
                                    {props.show90 === 'show' ? <a className={props.is90Days ? props.isLoading ? "list_link active btn-wait" : "list_link active " : "list_link"} onClick={() => {props.viewModeClicked(currentData.THREE_MONTHS); closeCustomDateClicked()}} href="javascript:void(0)">{props.is90Days ? props.isLoading ? '' : '90 Days' : '90 Days'}</a>: null}
                                    {props.showCustomDate === 'show' ? <a className={props.isCustomDate ? props.isLoading ? "list_link active" : "list_link active " : "list_link"} onClick={() => {props.viewModeClicked(currentData.CUSTOM); customDateClicked()}}  href="javascript:void(0)">{props.isCustomDate ? props.isLoading ? 'Custom Date' : 'Custom Date' : 'Custom Date'}</a> : null}
                                    </div>
                            </div>
                            {
                                props.isCustomDate ? null :
                            <div className="cal_block">
                                {!props.isLoading ? <ul className="calc_content">
                                    <li onClick={props.previousWeekMonthYearCliked} >
                                    <a href="javascript:void(0)" >
                                     {props.previousWeekMonthYearCliked ? props.isLoading ? <i class="fa fa-spinner" style={{color : "#151b26"}}></i> :  <i className="fas fa-chevron-left"></i> : <i className="fas fa-chevron-left"></i>}
                                    </a>
                                    </li>
                                    <li style={
                                        props.isWeek && get_next_week_start(props.currentDate) >= get_next_week_start(presentDate) ? { cursor: 'not-allowed', pointerEvents: 'none', opacity: 0.6 }
                                            : props.isMonth && getNextMonth(props.currentDate) >= getNextMonth(presentDate) ? { cursor: 'not-allowed', pointerEvents: 'none', opacity: 0.6 } :
                                            props.is90Days && get_next_90days(props.currentDate) >= get_next_90days(presentDate) ? { cursor: 'not-allowed', pointerEvents: 'none', opacity: 0.6 } :
                                                props.isYear && getNextYearDate(props.currentDate) >= getNextYearDate(presentDate) ? { cursor: 'not-allowed', pointerEvents: 'none', opacity: 0.6 } : null} onClick={props.nextWeekMonthYearCliked}>

                                        <a style={props.isWeek && get_next_week_start(props.currentDate) >= get_next_week_start(presentDate) ? { cursor: 'not-allowed', pointerEvents: 'none', opacity: 0.7 }
                                            : props.isMonth && getNextMonth(props.currentDate) >= getNextMonth(presentDate) ? { cursor: 'not-allowed', pointerEvents: 'none', opacity: 0.6 } :
                                            props.is90Days && get_next_90days(props.currentDate) >= get_next_90days(presentDate) ? { cursor: 'not-allowed', pointerEvents: 'none', opacity: 0.6 } :
                                                props.isYear && getNextYearDate(props.currentDate) >= getNextYearDate(presentDate) ? { cursor: 'not-allowed', pointerEvents: 'none', opacity: 0.6 } : null}
                                            href="javascript:void(0)" >
                                            {props.nextWeekMonthYearCliked ? props.isLoading ? <i class="fa fa-spinner" style={{color : "#151b26"}}></i> :  <i className="fas fa-chevron-right"></i> : <i className="fas fa-chevron-right"></i>}
                                            </a></li>
                                </ul> : <>
                                <ul className="calc_content">
                                    <li> <a href="javascript:void(0)"><i class="fa fa-spinner" style={{color : "#151b26"}}></i></a></li>
                                    <li> <a href="javascript:void(0)"><i class="fa fa-spinner" style={{color : "#151b26"}}></i></a></li>
                                </ul></>}
                            </div>
                                    }
                        </div>
                    </div>
                </div>
                {/* <div style={{ float: 'left', marginTop: '20px' }}>
                    <CSVLink filename="Dashboard.csv" className="csv_btn btn_danger " data={convertDashboardDataToCsvType(props.dashboardData)} >Export CSV</CSVLink><br /><br /><br />
                </div> */}
                {/* <div style={{ float: 'left', marginTop: '20px' }}>
                    <button className="csv_btn btn_danger " onClick={props.viewPdfClicked}>PDF</button><br /><br /><br />
                </div> */}
                {props.isCustomDate ? null :
                <div className="react_times_button time_picker_preview" style={{ textAlign: 'center', width: '200px', WebkitBoxShadow: '2px 2px 15px 0 rgba(0,0,0,.15)', height: '50px' }}>
                    <label className=" preview_container without_icon custom_title" style={{ backgroundColor: '#e9ecef59', color: '#89073c' }}>
                        {props.isWeek ? weekStartDayAndWeekEndDay(props.currentDate) :
                            props.isMonth ? getMonthAndYear(props.currentDate) :
                            props.is90Days ? getPast90DaysStartDayAndEndDay(props.currentDate) :
                            // props.isCustomDate ? getPast90Days(props.currentDate) :
                                getYearView(props.currentDate)}
                    </label>
                </div>
                    } 
                <div className="react_times_button time_picker_preview" style={{ textAlign: 'center', width: '200px', WebkitBoxShadow: '2px 2px 15px 0 rgba(0,0,0,.15)', height: '50px' }}>
                {showDatePicket ? 
                 <>
                 {props.showCustomDatePicker === 'show'  ?       
                 <CustomeDateFormate 
                 isLoading={props.isLoading}
                 onSubmit={goClicked}
                 />
                              : null }
                             </> 
                             :  null }
                 </div>
 
            </div>
        </div>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
         //new kpi chnages
        getKpiInspectionCount: (values) => dispatch(actions.getKpiInspectionCount(values)),
        getKpiAvarageScore: (values) => dispatch(actions.getKpiAvarageScore(values)),
        getKpiTopInspection: (values) => dispatch(actions.getKpiTopInspection(values)),
        getKpiQualityInspection: (values) => dispatch(actions.getKpiQualityInspection(values)),
    }
}

export default connect(null, mapDispatchToProps)(WeekMonthYearLists);
