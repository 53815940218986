import React, { useEffect, useState } from 'react';
import { Input } from '../../UI/InputElement/InputElement';
import { change, getFormValues, reduxForm } from 'redux-form';
import { deficiencyValidation as validate } from '../../../../utility/validator/validator';
import { connect, useDispatch } from 'react-redux';

let NewDeficiency = (props) => {

  const { handleSubmit, pristine, reset, submitting, error } = props;
  return (
      <div className="modal fade request_quote_modal reject_quot associateUser" id="addDeficiency" tabindex="-1" role="dialog" aria-labelledby="rejectQuoteModalTitle" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <label for="exampleInputEmail1" className="cst-label md_title">{props.isEdit ? 'Update Deficiency' : 'New Deficiency'}</label>
              <button type="button" className="close close_icn_pic" data-dismiss="modal" onClick={()=> {props.closePopup(); props.clearDeficiency(); props.clearState()}}><img src="/images/icons/icn_close_gray.png" alt="Close Icon" /></button>
            </div>

            <div className="modal-body">
              <div className="tab-content">
                <div id="profile" className="profile_tab cst_tab_content tab-pane fade show active edit_profile_modal">
                  <form className="form_content" onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group">
                          <label className="label_modifier">Deficiency Type</label>
                          <Input type="text" className="form-control input-modifier" placeholder="" name="deficiency[title]" />
                        </div>
                      </div>
                    </div>
                    <div className="clearfix"></div>
                    <div className="modal-footer">
                      <button type="submit" disabled={props.isLoading || pristine ? true : false} className={props.isLoading ? "btn cst_btn btn_danger mr-2 btn-wait" : "btn cst_btn btn_danger mr-2"} >{props.isLoading ? "" : "Save"}</button>
                      <button onClick={() => {props.closePopup(); props.clearDeficiency();}} type="button" className="btn btn-outline-secondary cst_btn" id='deficiencyClose' data-dismiss="modal">Close</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  )
}

const mapStateToProps = (state) => {

  return { initialValues: {deficiency: state.adminOrSuperAdminReducer.deficiency}, formStates: getFormValues('myDeficiencyForm')(state)  }
}

NewDeficiency = reduxForm({
  form: 'myDeficiencyForm',
  validate,
  enableReinitialize: true
})(NewDeficiency);

NewDeficiency = connect(mapStateToProps)(NewDeficiency)

export default NewDeficiency;