import React, { Component } from 'react';
import * as actions from '../../../redux/actions/index';
import { connect } from 'react-redux';
import { animateScroll as scroll } from 'react-scroll'
import PreviewFeedback from '../../component/PegAssureComponent/PreviewFeedback';
import isEmpty from '../../../utility/IsEmptyValidator';
const cloneDeep = require('clone-deep');
class PreviewFeedbackContainer extends Component {

    componentDidMount = () => {
        if (!this.props.feedback || isEmpty(this.props.feedback)) {
            this.props.getFeedback(this.props.match.params.feedbackId);
        }
        scroll.scrollToTop();

    }

    render() {

        return (
            <PreviewFeedback
                {...this.props}
                feedback={this.props.feedback}
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        feedback: state.adminOrSuperAdminReducer.feedback,
        isLoading: state.adminOrSuperAdminReducer.isLoading,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getFeedback: (id) => dispatch(actions.getFeedback(id)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PreviewFeedbackContainer);