import React, { useEffect, useState } from 'react';
import ReactTable from 'react-table-v6';
import '../../../../../styles/custom.css';
import { CustomNoDataComponent, makeFirstLetterCapital, convertDateToDifferentTZ } from '../../../../../utility/utility';
import { FaArrowDown, FaArrowUp } from "react-icons/fa";
import ReactTooltip from 'react-tooltip';
import * as actions from '../../../../../redux/actions/index';
import { useSelector, useDispatch } from 'react-redux';
import { ClapSpinner } from 'react-spinners-kit';
import 'react-table-v6/react-table.css';
import { routes } from '../../../../../utility/constants/constants';
var moment = require('moment');

let ProjectModuleTable = (props) => {

    const dispatch = useDispatch();
    // const SearchValue = useSelector(state => state.form.searchScheduledTask && state.form.searchScheduledTask.values ? state.form.searchScheduledTask.values : {});
    const DateGenerate = useSelector(state => state.projectModuleReducer.dataGenerated);
    // const values = useSelector(state => state.projectModuleReducer.scheduledTask_searchValues);
    const No_of_pages = useSelector(state => state.projectModuleReducer.restroomPortering_total_pages);
    const No_of_rows = useSelector(state => state.projectModuleReducer.restroomPortering_no_of_rows);
    const isRestroomPortering = useSelector(state => state.projectModuleReducer.isRestroomPortering);
    const restroomPorteringCurPage = useSelector(state => state.projectModuleReducer.restroomPorteringCurPage);
    const SearchOrResetScheduledTaskListClicked = useSelector(state => state.projectModuleReducer.SearchOrResetScheduledTaskListClicked);

    const accountTimezone = 'America/Los_Angeles';

    const data = props.restroomPortering_list && props.restroomPortering_list.map((value) => {
        return {
            location: value && value.account ? value.account.address?.formatted_address : '-',
            building: value && value.building ? value.building : '-',
            area: value && value.area ? value.area : '-',
            manager: value && value.manager ? makeFirstLetterCapital(value.manager?.first_name) + ' ' + makeFirstLetterCapital(value.manager?.last_name) : '-',
            title: value && value.title ? value.title : '-',
            totalMonthCount: value && value.totalMonthCount ? value.totalMonthCount : '-',
            January: value && value.monthlyCounts && value.monthlyCounts.January ? value.monthlyCounts.January : '-',
            February: value && value.monthlyCounts && value.monthlyCounts.February ? value.monthlyCounts.February : '-',
            March: value && value.monthlyCounts && value.monthlyCounts.March ? value.monthlyCounts.March : '-',
            April: value && value.monthlyCounts && value.monthlyCounts.April ? value.monthlyCounts.April : '-',
            May: value && value.monthlyCounts && value.monthlyCounts.May ? value.monthlyCounts.May : '-',
            June: value && value.monthlyCounts && value.monthlyCounts.June ? value.monthlyCounts.June : '-',
            July: value && value.monthlyCounts && value.monthlyCounts.July ? value.monthlyCounts.July : '-',
            August: value && value.monthlyCounts && value.monthlyCounts.August ? value.monthlyCounts.August : '-',
            September: value && value.monthlyCounts && value.monthlyCounts.September ? value.monthlyCounts.September : '-',
            October: value && value.monthlyCounts && value.monthlyCounts.October ? value.monthlyCounts.October : '-',
            November: value && value.monthlyCounts && value.monthlyCounts.November ? value.monthlyCounts.November : '-',
            December: value && value.monthlyCounts && value.monthlyCounts.December ? value.monthlyCounts.December : '-',

            actions:
                <>
                    <span className="mr_20 account_edit_icn"  ><i class="fas fa-eye" aria-hidden="true" ></i></span>
                    {/* <span className="mr_20 account_edit_icn"  onClick={() => props.history.push(`rest-room-portering/${value.id}`)} ><i class="fa fa-pencil" aria-hidden="true" ></i></span> */}
                </>
        }
    })

    const columns = [
        {
            Header: <><span> Location </span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /></>,
            accessor: 'location',
            show: props.restroomPortering_list.length !== 0,
            width: 135,
            sortable: false,
        },
        {
            Header: <><span> Building </span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /></>,
            accessor: 'building',
            show: props.restroomPortering_list.length !== 0,
            width: 235,
            sortable: false,
        },
        {
            Header: <><span> Area</span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /></>,
            accessor: 'area',
            show: props.restroomPortering_list.length !== 0,
            width: 235,
            sortable: false,
        },
        {
            Header: <><span>Manager</span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /></>,
            accessor: 'manager',
            show: props.restroomPortering_list.length !== 0,
            width: 200,
            sortable: false,
        },
        {
            Header: <><span> Project </span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /></>,
            accessor: 'title',
            show: props.restroomPortering_list.length !== 0,
            width: 200,
            sortable: false,
        },
        {
            Header: <><span> Times Per Year </span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /></>,
            accessor: 'totalMonthCount',
            show: props.restroomPortering_list.length !== 0,
            width: 200,
            sortable: false,
        },
        {
            Header: <><span>Jan </span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /></>,
            accessor: 'January',
            show: props.restroomPortering_list.length !== 0,
            sortable: false,
        },
        {
            Header: <><span>Feb </span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /></>,
            accessor: 'February',
            show: props.restroomPortering_list.length !== 0,
            sortable: false,
        },
        {
            Header: <><span> Mar</span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /></>,
            accessor: 'March',
            show: props.restroomPortering_list.length !== 0,
            sortable: false,
        },
        {
            Header: <><span>Apr </span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /></>,
            accessor: 'April',
            show: props.restroomPortering_list.length !== 0,
            sortable: false,
        },
        {
            Header: <><span> May</span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /></>,
            accessor: 'May',
            show: props.restroomPortering_list.length !== 0,
            sortable: false,
        },
        {
            Header: <><span>Jun </span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /></>,
            accessor: 'June',
            show: props.restroomPortering_list.length !== 0,
            sortable: false,
        },
        {
            Header: <><span> Jul</span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /></>,
            accessor: 'July',
            show: props.restroomPortering_list.length !== 0,
            sortable: false,
        },
        {
            Header: <><span> Aug</span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /></>,
            accessor: 'August',
            show: props.restroomPortering_list.length !== 0,
            sortable: false,
        },
        {
            Header: <><span>Sep </span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /></>,
            accessor: 'September',
            show: props.restroomPortering_list.length !== 0,
            sortable: false,
        },
        {
            Header: <><span> Oct</span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /></>,
            accessor: 'October',
            show: props.restroomPortering_list.length !== 0,
            sortable: false,
        },
        {
            Header: <><span> Nov</span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /></>,
            accessor: 'November',
            show: props.restroomPortering_list.length !== 0,
            sortable: false,
        },
        {
            Header: <><span> Dec</span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /></>,
            accessor: 'December',
            show: props.restroomPortering_list.length !== 0,
            sortable: false,
        },
        // {
        //     Header: '',
        //     accessor: 'actions',
        //     width: 135,
        //     sortable: false,
        // }
    ]

    const [tablePage, setTablePage] = useState(0)
    const tablePageNew = React.useRef()
    if (restroomPorteringCurPage !== tablePageNew.current) {
        tablePageNew.current = restroomPorteringCurPage;
    }
    const setTablePageFn = (page) => {
        setTablePage(page)
        props.deepCleanChartPageClicked(page)
        tablePageNew.current = page;
    }

    const fetchData = (state, instance) => {
        if (SearchOrResetScheduledTaskListClicked) {
            dispatch(actions.resetSearchOrResetInventory())
        } else {
            if (!DateGenerate && !props.isRestroomPortering) {
                state.page = 2;
                const limit = state.pageSize;
                let page = tablePageNew.current >= 0 ? tablePageNew.current + 1 : restroomPorteringCurPage + 1;
                const inventoryItemsList_no_of_rows = state.pageSize;
                if (No_of_rows != inventoryItemsList_no_of_rows) {
                    page = 1;
                    setTablePageFn(0)
                }

                const order = state.sorted[0]?.id;
                const orderBy = state.sorted && state.sorted.length ? state.sorted[0]?.desc === true ? 'desc' : 'asc' : null;
                // const accountId = SearchValue.id ? SearchValue.id : null;
                // const taskName = SearchValue.name ? SearchValue.name : null;
                // const companyName = SearchValue.company ? SearchValue.company : null;
                // const accountName = SearchValue.job ? SearchValue.job : null;
                // const from = SearchValue.fromDate ? convertDateToDifferentTZ(SearchValue.fromDate, 'from') : null;
                // const to = SearchValue.toDate ? convertDateToDifferentTZ(SearchValue.toDate, 'to') : null;
                props.getAllDeepCleanChart({ page, limit, order, orderBy })
            }
        }
    }

    return (
        SearchOrResetScheduledTaskListClicked && props.isRestroomPortering ?
            <div  >
                <ClapSpinner backColor="#89073c" size={40} frontColor="#89073c8a" />
            </div>
            :
            <ReactTable
                data={data}
                loading={props.isRestroomPortering}
                minRows={0}
                defaultPageSize={No_of_rows}
                pages={No_of_pages}
                gotoPage={restroomPorteringCurPage}
                page={restroomPorteringCurPage}
                onPageChange={(page) => { setTablePageFn(page) }}
                // onPageSizeChange={props.onUserPageSizeChange}
                manual
                onFetchData={fetchData}
                showPaginationTop={true}
                showPaginationBottom={false}
                showPagination={props.restroomPortering_list.length !== 0}
                columns={columns}
                // style={{marginTop: '10px'}}
                NoDataComponent={() => CustomNoDataComponent(isRestroomPortering, 'No Deep Clean Chart List Found')}
                LoadingComponent={() =>
                    isRestroomPortering ?
                        <div style={{
                            display: "block",
                            position: "absolute",
                            left: 0,
                            height: '100%',
                            right: 0,
                            background: "rgba(255,255,255,0.8)",
                            transition: "all .3s ease",
                            top: 0,
                            bottom: 0,
                            textAlign: "center"
                        }}
                            className="loader_btn_block">
                            <ClapSpinner backColor="#89073c" size={40} frontColor="#89073c8a" />
                        </div> : null}
                getTheadThProps={(state, rowInfo, column, instance) => {
                    // console.log(column);
                    return {
                        style: {
                            borderTop: 'none',
                            borderBottom: 'none',
                            borderRight: 'none',
                            textAlign: 'center',
                            color: '#566975',
                            fontSize: '14px',
                            fontWeight: '600',
                            whiteSpace: 'nowrap',
                            float: 'left',
                        }
                    }
                }
                }
                getTrProps={(state, rowInfo, column, instance) => {
                    // onclick = () => userDetail(rowInfo ? rowInfo.row.user : rowInfo);
                    console.log(column);
                    return {
                        className: 'react-table-tr-element',
                        style: {
                            border: '1px solid #c7cdd1',
                            transition: '.3s ease-in-out',
                            width: '100%',
                            // borderRadius: '4px',
                            // height: '80px',
                            marginTop: '5px',
                            float: 'left'
                        }
                    }

                }}
                getTdProps={(state, rowInfo, column, instance) => {

                    if (column.id == 'image') {
                        return {
                            style: {
                                marginTop: '0px'
                            }
                        }
                    } else {
                        return {
                            className: 'react-td-element',
                        }
                        //  {
                        //   style: {
                        //     borderTop: 'none',
                        //     verticalAlign: 'middle',
                        //     fontSize: '14px',
                        //     color: '#2b3034',
                        //     marginTop: '10px !important',
                        //     textAlign: 'center',
                        //   }
                        // }
                    }
                }
                }
                // getPaginationProps={() => {
                //     return {
                //         style: {
                //             marginTop: '50px',
                //             border: 'none',
                //             boxShadow: 'none'
                //         }
                //     }
                // }
                // }
                getProps={() => {
                    return {
                        style: {
                            border: 'none'
                        }
                    }
                }}
            />
    )
}

export default ProjectModuleTable;