import React from 'react';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import PreviewInventoryTable from './previewInventoryTable';

let PreviewInventory = (props) => {
  
  return (
    <>
      <main className="app-content wraper_content inspection_wraper">

        <section className="account_sec user_screen inspection_tab_content">

          <div className="quotes_table_content table-responsive">

            <div className="table quotes_table user_table_contnet user_react_table account_table">
              <PreviewInventoryTable
                {...props}
                isLoading={props.isLoading}
              />
            </div>

          </div>
        </section>
      </main>

      <Dialog
        open={props.openDeleteModel}
        onClose={props.handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title" style={{ fontSize: '13', color: '#06080f' }}>
          <h3 style={{ color: '#ab385e' }}>Are you sure?</h3>
        </DialogTitle>

        <DialogActions>
          <button style={{ minHeight: '36px', minWidth: '80px' }} onClick={() => props.deleteFrontline(props.deleteObject.id)} disabled={props.isLoading} className={props.isLoading ? "btn cst_btn btn_danger mr-2 btn-wait" : "btn cst_btn btn_danger mr-2"} id="delete" type="delete">{props.isLoading ? "" : "Delete"}</button>
          <button style={{ minHeight: '36px', minWidth: '80px' }} onClick={props.handleDeleteClose} className={"btn cst_btn btn-outline-secondary"} id="cancel" type="cancel">Cancel</button>
        </DialogActions>
      </Dialog>

    </>
  )
}

const mapStateToProps = (state) => {
  let email = "";

  return { initialValues: { email } }
}

PreviewInventory = reduxForm({
  form: 'searchPreviewInventoryForm',
  //validate,
  enableReinitialize: true
})(PreviewInventory);

PreviewInventory = connect(mapStateToProps)(PreviewInventory)

export default PreviewInventory;