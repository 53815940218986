import React, { Component } from 'react';
import Inspections from '../../component/MyInspections/Inspections';
import { connect, useSelector } from 'react-redux';
import { closeModel, convertDateToDifferentTZ, getUser } from '../../../utility/utility';
import * as actions from '../../../redux/actions/index';
import { reset, change } from 'redux-form';
import { routes, userRoles, TimeZones, address_attributes } from '../../../utility/constants/constants';
import errorHandeler from '../../../utility/errorHandler/errorHandler';
import storage from '../../../utility/storage';
import getDetailAddress from '../../../utility/getDetailAddress';
import isEmpty from '../../../utility/IsEmptyValidator';
import { animateScroll as scroll} from 'react-scroll'
var moment = require('moment');
const cloneDeep = require('clone-deep');

class InspectionsContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            openPerformInspectionModal: false,
            lat: null,
            long: null,
            address_attributes: address_attributes,
            isAccountEmpty: false,
            isFormEmpty: false,
            fromDashboard: false,
            pathname: '',
        };
        this.handleAddressSelect = this.handleAddressSelect.bind(this);
        // this.props.history.push({
        //     search: '?newPerformInspection=false'
        // })
    }

    success = (position) => {
        this.setState({
            openPerformInspectionModal: true,
            lat: position.coords.latitude,
            long: position.coords.longitude,
        });
    };

    fail = (error) => {
        errorHandeler('Could not obtain location');
        closeModel();
        this.setState({
            openPerformInspectionModal: false,
        });
        this.props.history.push({
            search: '?newPerformInspection=false',
        });
    };

    newPerformInspectionModalClicked = (event) => {
        if(event.pageX !== 0) {
            let ele = document.getElementById('newperformInspectionTag');
            if (ele) {
                ele.click();
            }
        this.props.history.push({
            search: '?newPerformInspection=true',
        });
        // const user = getUser();
        // if (!this.props.inspectionFormsList) {
        //     this.props.getInspectionForms();
        // } else if (this.props.inspectionFormsList && this.props.inspectionFormsList.length > 0 && this.props.inspectionFormsList[0].inspection_form.mode !== user.mode) {
        //     this.props.getInspectionForms();
        // }

        if (navigator.geolocation) {
            // Call getCurrentPosition with success and failure callbacks

            navigator.geolocation.getCurrentPosition(this.success, this.fail);
        } else {
            errorHandeler('Please enable your location access.');
        }

        if (this.props.user.role !== userRoles.ADMIN && this.props.user.role !== userRoles.SUPER_ADMIN) {
            if (!this.props.userAccountLists) {
                this.props.getUserAccounts(this.props.user.id);
            }
        }
        // else if (this.props.user.role === userRoles.REQUESTOR) {
        //     if (!this.props.userAccountLists) {
        //         this.props.getUserAccounts(this.props.user.id);
        //     }
        // }
        else {
            if (!this.props.accountsList) {
                const userDetail = getUser();
                this.props.getModeAccounts(userDetail.mode);
            }
        }

        // this.setState({
        //     openPerformInspectionModal: true
        // })
    }
    };

    cancelPerformInspectionModalClicked = () => {
        this.props.reset('performingInspectionForm');
        storage.remove('performInspectionData');
        this.setState({
            openPerformInspectionModal: false,
        });
        this.props.history.push({
            search: '?newPerformInspection=false',
        });
        this.props.clearLatestInspection()
    };

    componentDidUpdate(prevProps, prevState) {
        // const user = storage.get('user');
        // if(user) {
        //     if(prevProps.user.selected_mode !== user.selected_mode) {
        //         this.props.inspectionPageClicked(0)
        //     }
        // }
        if (this.props.location.pathname === routes.INSPECTIONS && prevProps.location.pathname === routes.VIEW_FILTERED_INSPECTIONS) {
            // this.props.resetObjects();
            this.props.reset('searchInspection');
            this.props.searchInspections({});
        }
        if (
            prevProps.history.location.pathname !== prevState.pathname &&
            prevProps.history.location.pathname !== this.state.pathname
        ) {
            this.setState({
                fromDashboard: this.props.history.location.pathname.includes('dashboard'),
                pathname: this.props.history.location.pathname,
            });
        }
    }

    componentDidMount() {
        const userDetail = getUser();
        this.props.getModeAccounts(userDetail.mode);
        this.props.getModeAccountUsers(userDetail.mode);
        if (this.props.history) {
            this.setState({
                fromDashboard: this.props.history.location.pathname.includes('dashboard'),
                pathname: this.props.history.location.pathname,
            });
        }
        // if (!this.props.inspectionsList) {
        //     if (this.props.user.role === userRoles.CLIENT || this.props.user.role === userRoles.INSPECTOR) {
        //         this.props.getUserInspcetionsList();
        //     } else {
        //         this.props.getInspectionsList();
        //     }
        // }

        // if (navigator.geolocation) {
        //     // Call getCurrentPosition with success and failure callbacks
        //     navigator.geolocation.getCurrentPosition(this.success, this.fail);
        // } else {
        //     errorHandeler("Sorry, your browser does not support geolocation services.")
        // }
        // this.props.inspectionCSvCliked(this.props.inspection.id)
    scroll.scrollToTop();
    }

    

    goToPerformInspection = (values) => { 
        if (values.inspection.account_id === '') {
            this.setState({
                isAccountEmpty: true,
                isFormEmpty: false,
            });
            return;
        } else if (values.inspection.inspection_form_id === '') {
            this.setState({
                isAccountEmpty: false,
                isFormEmpty: true,
            });
            return;
        }
        closeModel();

        this.setState({
            openPerformInspectionModal: false,
        });
        this.props.history.push({
            search: '?newPerformInspection=false',
        });
        const Inspection = {
            start_time: new Date(),
            inspection: {
                inspection_form_id: values.inspection.inspection_form_id.value,
                account_id: values.inspection.account_id.value,
                create_ticket: values.inspection.ticket,
                private: values.inspection.private,
                addresses_attributes: [
                    {
                        latitude: this.state.lat,
                        longitude: this.state.long,
                    },
                ],
            },
        };
        this.props.setInspectionForm(Inspection);
        // this.props.getLatestInspections(values.inspection.inspection_form_id.value, values.inspection.account_id.value)
    };


    latestInspectionGoClicked = (data) => {        
        this.props.getLatestInspections(data.inspection.inspection_form_id.value, data.inspection.account_id.value)
    };


    resetClicked = () => {
        this.props.reset('searchInspection');
        // this.props.getAccounts();
        let SearchValue = {};
    if (this.props.history.location.pathname === routes.VIEW_FILTERED_INSPECTIONS) {

        this.props.updateWeekMonthYear(this.props.history.location.state.isWeek, this.props.history.location.state.isMonth, this.props.history.location.state.isYear);
        if (this.props.history.location.state.isWeek) {

            SearchValue = this.props.history.location.state;
            SearchValue.value = ''    
            SearchValue.firstday = moment(SearchValue.currentDate).startOf('week').format('MM/DD/YYYY');
            SearchValue.lastday = moment(SearchValue.currentDate).endOf('week').format('MM/DD/YYYY');
            SearchValue.currentDate = moment(SearchValue.currentDate).utc().toISOString();
        } else if (this.props.history.location.state.isMonth || this.props.history.location.state.isYear) {
            SearchValue = this.props.history.location.state;
            SearchValue.currentDate = moment(SearchValue.currentDate).utc().toISOString();
        }
    } 
    this.props.inspectionPageClicked(0)
        if (this.props.user.role === userRoles.ADMIN || this.props.user.role === userRoles.REQUESTOR || this.props.user.role === userRoles.CLIENT || this.props.user.role === userRoles.INSPECTOR) {
            SearchValue.value = ''    
            this.props.getUserMyInspcetionsList(SearchValue, 10, 1, [], []);
        } else {
            SearchValue.value = ''    
            const page = 1;
            this.props.getMyInspectionsList(SearchValue, this.props.inspection_no_of_rows, page);
        }
    };

    previewInspcetionClicked = (inspection) => { 
        this.props.goToPreviewInspection(inspection);
    };
    previewLatestInspcetionClicked = (inspection) => {
        // closeModel(); 
        this.setState({
            openPerformInspectionModal: false,
        });
        this.props.history.push({
            search: '?newPerformInspection=false',
        });
        this.props.goToLatestPreviewInspection(inspection);
    };
    inspectionCSvClikedReport = (inspection) => {
        this.props.inspectionCSvCliked(inspection)
    }
    deleteInspection = (id) => {
        this.props.deleteInspection(id);
    };

    editInspectionClicked = (inspection) => {
        // if (navigator.geolocation) {
        //     // Call getCurrentPosition with success and failure callbacks

        //     navigator.geolocation.getCurrentPosition(this.success, this.fail);
        // } else {
        //     errorHandeler("Please enable your location access.")
        // }
        storage.remove('performInspectionData');
        this.props.editInspectionClicked(inspection);
    };

    onAccountChange = (id) => {
        this.props.getInspectionFormForAccount(id);
        this.props.change('performingInspectionForm', 'inspection[inspection_form_id]', 0);
    };

    goClicked = (inspection) => {
        if(inspection.inspection_form_name) {
            inspection.inspection_form_name = inspection.inspection_form_name.trim()
        }
        if (inspection.region) {
            inspection.region = inspection.region.trim();
        }
        if (inspection.score) {
            inspection.score = inspection.score.trim();
        }
    this.props.inspectionPageClicked(0)
        if(this.props.history.location.state) {
            const values = cloneDeep(inspection);
            if (values.fromDate && values.toDate) {
                values.fromDate = convertDateToDifferentTZ(values.fromDate, 'from');
                values.toDate = convertDateToDifferentTZ(values.toDate, 'to');
            }
            this.props.updateWeekMonthYear(
                this.props.history.location.state.isWeek,
                this.props.history.location.state.isMonth,
                this.props.history.location.state.isYear
            );
            // this.props.updateCurrentDate(this.props.history.location.state.currentDate);
            if (this.props.history.location.state.isWeek) {
                var SearchValue = {};
                SearchValue = this.props.history.location.state;
                SearchValue.firstday = moment(SearchValue.currentDate)
                    .startOf('week')
                    .format('MM/DD/YYYY');
                SearchValue.lastday = moment(SearchValue.currentDate)
                    .endOf('week')
                    .format('MM/DD/YYYY');
                SearchValue.currentDate = moment(SearchValue.currentDate)
                    .utc()
                    .toISOString();
                SearchValue.value = values;
            } else if (this.props.history.location.state.isMonth || this.props.history.location.state.isYear) {
                SearchValue = this.props.history.location.state;
                SearchValue.value = values;
                SearchValue.currentDate = moment(SearchValue.currentDate)
                    .utc()
                    .toISOString();
            }
            this.props.searchInspections(SearchValue);
        } else {
            const values = { value: cloneDeep(inspection) };
            if (values.value.fromDate) {
                values.value.fromDate = convertDateToDifferentTZ(values.value.fromDate, 'from');
            }
            if (values.value.toDate) {
                values.value.toDate = convertDateToDifferentTZ(values.value.toDate, 'to');
            }

            this.props.searchInspections(values);
        }
    };

    handleDeleteClose = () => {
        this.props.handleDeleteClose();
    };

    handleAddressChange = (address) => {
        let addressAttributes = { ...this.state.address_attributes };
        addressAttributes.formatted_address = address;

        this.setState({ address_attributes: addressAttributes, isAddressEmpty: false });
    };

    async handleAddressSelect(address) {
        const addressFields = await getDetailAddress(address);
        this.setState({
            address_attributes: addressFields,
            isAddressEmpty: false
        })
    };

    onInspectionExportClicked = (body) => {
        const user = getUser();
        let data = {}
        data['mode']= user.mode
        Object.keys(body.value).map((key, index) => {
            if(body.value[key] !== '') {
                data[key] = body.value[key]
            }
        })
        if(body.sort) {
            data.sort = body.sort
        }
        this.props.onInspectionExportClicked(data)
    }
    

    render() {
        return (
            <Inspections
                {...this.props}
                user={this.props.user}
                isFormEmpty={this.state.isFormEmpty}
                isAccountEmpty={this.state.isAccountEmpty}
                handleDeleteClose={this.handleDeleteClose}
                getMyInspectionsList={this.props.getMyInspectionsList}
                getUserMyInspcetionsList={this.props.getUserMyInspcetionsList}
                onAccountChange={this.onAccountChange}
                inspectionsList={this.props.inspectionsList ? this.props.inspectionsList : []}
                isLoading={this.props.isLoading}
                editInspectionClicked={this.editInspectionClicked}
                deleteInspection={this.deleteInspection}
                handleClickDeleteModel={this.props.handleClickDeleteModel}
                previewInspcetionClicked={this.previewInspcetionClicked}
                resetClicked={this.resetClicked}
                onSubmit={this.goClicked}
                openDeleteModel={this.props.openDeleteModel}
                deleteObject={this.props.deleteObject}
                goToPerformInspection={this.goToPerformInspection}
                newPerformInspectionModalClicked={this.newPerformInspectionModalClicked}
                openPerformInspectionModal={this.state.openPerformInspectionModal}
                accountsList={this.props.accountsList}
                dropDownAccountsList={this.props.dropDownAccountsList}
                dropDownUsersList={this.props.dropDownUsersList}
                userAccountLists={this.props.userAccountLists}
                inspectionFormsList={this.props.inspectionFormsList ? this.props.inspectionFormsList : []}
                cancelPerformInspectionModalClicked={this.cancelPerformInspectionModalClicked}
                clearLatestInspection={this.props.clearLatestInspection}
                handleAddressChange={this.handleAddressChange}
                handleAddressSelect={this.handleAddressSelect}
                address_attributes={this.state.address_attributes}
                fromDashboard={this.state.fromDashboard}
                onInspectionExportClicked={this.onInspectionExportClicked}
                inspectionCSvClikedReport={this.inspectionCSvClikedReport}
                data_list={this.props.data_list}
                fields={this.props.fields}
                latestInspectionList={this.props.latestInspectionList}
                latestInspectionGoClicked={this.latestInspectionGoClicked}
                previewLatestInspcetionClicked={this.previewLatestInspcetionClicked}
            />
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.authReducer.user,
        inspectionsList: state.adminOrSuperAdminReducer.inspectionsList,
        inspectionCurPage: state.adminOrSuperAdminReducer.inspectionCurPage,
        inspectionFormsList: state.adminOrSuperAdminReducer.inspectionFormsList,
        isLoading: state.adminOrSuperAdminReducer.isLoading,
        userAccountLists: state.adminOrSuperAdminReducer.userAccountLists,
        accountsList: state.adminOrSuperAdminReducer.modeAccountsList,
        deleteObject: state.adminOrSuperAdminReducer.deleteObject,
        openDeleteModel: state.adminOrSuperAdminReducer.openDeleteModel,
        inspection_no_of_rows: state.adminOrSuperAdminReducer.inspection_no_of_rows,
        inspection_page: state.adminOrSuperAdminReducer.inspection_page,
        sort_inspection_column: state.adminOrSuperAdminReducer.sort_inspection_column,
        inspection_order: state.adminOrSuperAdminReducer.inspection_order,
        dropDownAccountsList: state.adminOrSuperAdminReducer.dropDownAccountsList,
        dropDownUsersList: state.adminOrSuperAdminReducer.filterAccountUsers,
        data_list: state.adminOrSuperAdminReducer.data_list,
        fields: state.adminOrSuperAdminReducer.fields,
        convertedData: state.adminOrSuperAdminReducer.convertedData,
        latestInspectionList: state.adminOrSuperAdminReducer.latestInspectionList
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        // resetObjects: () => dispatch(actions.resetObjects()),
        handleDeleteClose: () => dispatch(actions.handleDeleteClose()),
        deleteInspection: (id) => dispatch(actions.deleteInspection(id)),
        change: (performingInspectionForm, inspection_form_id) =>
            dispatch(change(performingInspectionForm, inspection_form_id)),
            getUserMyInspcetionsList: (values, inspection_no_of_rows, page, sorted, filtered) =>
            dispatch(actions.getUserMyInspcetionsList(values, inspection_no_of_rows, page, sorted, filtered)),
            getMyInspectionsList: (values, inspection_no_of_rows, page, sorted) =>
            dispatch(actions.getMyInspectionsList(values, inspection_no_of_rows, page, sorted)),
        getInspectionForms: () => dispatch(actions.getInspectionForms()),
        getUserAccounts: (id) => dispatch(actions.getUserAccounts(id)),
        getAccounts: () => dispatch(actions.getDropDownAccounts()),
        getModeAccounts: (mode) => dispatch(actions.getModeAccounts(mode)),
        setInspectionForm: (inspection) => dispatch(actions.setInspectionForm(inspection)),
        searchInspections: (values) => dispatch(actions.searchMyInspections(values)),
        reset: (searchInspection) => dispatch(reset(searchInspection)), // requires form name
        goToPreviewInspection: (inspection) => dispatch(actions.goToPreviewInspection(inspection)),
        editInspectionClicked: (inspection) => dispatch(actions.editInspectionClicked(inspection)),
        // inspectionCSvClikedReport: (inspection) => dispatch(actions.inspectionCSvClikedReport(inspection)),
        handleClickDeleteModel: (inspection) => dispatch(actions.handleClickDeleteModel(inspection)),
        getModeAccountUsers: (mode) => dispatch(actions.getModeAccountUsers(mode)),
        updateCurrentDate: (date) => dispatch(actions.updateCurrentDate(date)),
        updateWeekMonthYear: (isWeek, isMonth, isYear) =>
            dispatch(actions.updateWeekMonthYear(isWeek, isMonth, isYear)),
        getDashboardInspectionsList: (values, inspection_no_of_rows, page, sorted) =>
            dispatch(actions.getDashboardInspectionsList(values, inspection_no_of_rows, page, sorted)),
        getInspectionFormForAccount: (id) => dispatch(actions.getInspectionFormForAccount(id)),
        inspectionPageClicked: (page) => dispatch(actions.inspectionPageClicked(page)),
        onInspectionExportClicked: (body) => dispatch(actions.onInspectionExportClicked(body)),
        inspectionCSvCliked: (inspection) => dispatch(actions.inspectionCSvCliked(inspection)),
        getLatestInspections: (inspection_form_id, id) => dispatch(actions.getLatestInspections(inspection_form_id, id)),
        goToLatestPreviewInspection: (inspection) => dispatch(actions.goToLatestPreviewInspection(inspection)),
        clearLatestInspection: () => dispatch(actions.clearLatestInspection()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(InspectionsContainer);
