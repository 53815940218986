import React, { useEffect, useState } from 'react';
import ReactTable from 'react-table-v6';
import '../../../../styles/custom.css';
import { convertDateToDifferentTZ, convertLogbookDateTimezone, CustomNoDataComponent, getUser, makeFirstLetterCapital } from '../../../../utility/utility';
import { FaArrowDown, FaArrowUp } from "react-icons/fa";
import ReactTooltip from 'react-tooltip';
import { useSelector, useDispatch } from 'react-redux';
import * as actions from '../../../../redux/actions/index';
import { MagicSpinner, PushSpinner, SwishSpinner, WaveSpinner, ClapSpinner } from 'react-spinners-kit';
import 'react-table-v6/react-table.css';
import ImageLoader from 'react-imageloader';
import { userRoles } from '../../../../utility/constants/constants';
import storage from '../../../../utility/storage';
var moment = require('moment');

const PageJump = (onChange, value, onBlur, onKeyPress, inputType, pageJumpText) => {
    //  
}

const LogBookAreaTable = (props) => {
    const dispatch = useDispatch();
    const SearchOrResetAccountClicked = useSelector(state => state.adminOrSuperAdminReducer.SearchOrResetAccountClicked);
    let SearchValue = useSelector(state => state.adminOrSuperAdminReducer.searchLogbookValues);
    const DateGenerate = useSelector(state => state.adminOrSuperAdminReducer.dataGenerated);
    const isLogbookLogs = useSelector(state => state.adminOrSuperAdminReducer.isLogbookLogs);
    const No_of_pages = useSelector(state => state.adminOrSuperAdminReducer.total_no_of_pages);
    const No_of_rows = useSelector(state => state.adminOrSuperAdminReducer.logbook_rows);
    const logbookAreaCurPage = useSelector(state => state.adminOrSuperAdminReducer.logbookAreaCurPage);
    const user = storage.get('user')

    function preloader() {
        return <img style={{ height: '35px', width: '35px', borderRadius: '50px', float: 'left' }} src="/images/gif/giphy.gif" />;
    }

    const data = props.logbookList.map(account => {

        return {
            id: account && account.id ? account.id : '-',
            job_name: account && account.logbook.account ? makeFirstLetterCapital(account.logbook.account.name) : "",
            logbook_id: account && account.logbook_id ? account.logbook_id : "",
            logbook_name: account && account.logbook ? makeFirstLetterCapital(account.logbook.name) : "",
            status: account && account.status ? account.status === 'quality_reviewed' ? 'Quality Reviewed' : makeFirstLetterCapital(account.status) : '-',
            critical: account && account.logbook?.critical ? "Yes" : "No",
            created_at: account && account.start_time_utc ? convertLogbookDateTimezone(account.start_time_utc, account.logbook.account.timezone).toUpperCase() : '',
            reviewed_at: account && account.reviewed_at ? convertLogbookDateTimezone(account.reviewed_at, account.logbook.account.timezone).toUpperCase() : '-',
            qr_link:
                <>
                    {account.status === 'pending' && user?.quality_review ? <button style={{ textTransform: 'none' }} className='btn cst_btn btn_danger mr_20' onClick={() => {
                        storage.set('logScanHistory', props.history.location.pathname)
                        props.history.push({ pathname: `/accounts/logbook-feedback/${account.logbook.log_number}/update/${account.id}`, state: { path: '/logbook', logId: account.id }, search: account.reviewed_at ? `?quality_rejected=true` : '' })
                    }}>Click here to Review</button>
                        : account.status === 'reviewed' && user?.quality_review && account.logbook.critical ? <button style={{ textTransform: 'none' }} className='btn cst_btn btn_danger mr_20' onClick={() => {
                            storage.set('logScanHistory', props.history.location.pathname)
                            props.history.push({ pathname: `/accounts/logbook-feedback/${account.logbook.log_number}/update/${account.id}`, search: "?quality_check=true", state: { path: '/logbook', logId: account.id } })
                        }}>Click here to Quality Review</button> : null
                    }
                </>,
            iconsContainer:
                <>
                    <ReactTooltip place="left" type="dark" effect="solid" />
                    <div className='d-flex align-items-center'>
                        {account.status === 'pending' ? <button className='btn cst_btn btn_danger mr_20' onClick={() => {
                            props.logbookQRCamera(account)
                        }}>Scan QR code</button> :
                            <span data-tip="Preview Logbook Log Info" className="mr_20 account_edit_icn" onClick={() => props.history.push(`/logbook/${account.logbook.log_number}/view_log/${account.id}`)}><i class="fas fa-eye" aria-hidden="true"></i></span>
                        }

                        {account.status === 'pending' && account.reviewed_at ? (
                            <span data-tip="Preview Logbook Log Info" className="mr_20 account_edit_icn" onClick={() => {
                                props.history.push(`/logbook/${account.logbook.log_number}/view_log/${account.id}`);
                            }}><i class="fas fa-eye" aria-hidden="true"></i></span>
                        ) : ""}

                        {/* PLAYBOOK PDF LINK */}
                        {(account?.logbook?.critical == true) && (account?.logbook?.playbook?.length > 0) ? (
                            <span data-tip="View Playbook PDF" className="mr_20 account_edit_icn" onClick={() => {
                                props.history.push({ pathname: `/logbook-pdf/${account?.logbook?.playbook[0]?.id}`, state: { pdf_info: account?.logbook?.playbook[0], back: true } });
                            }}> <i class="fas fa-file-pdf pdf-icon" aria-hidden="true"></i></span>
                        ) : ''}
                    </div>

                </>,
        }
    })

    const columns = [
        {
            Header: <><span>Job name</span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /></>,
            accessor: 'job_name',
            width: 200,
            show: props.logbookList.length !== 0,
        },
        {
            Header: <><span>Log ID</span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /></>,
            accessor: 'id',
            show: props.logbookList.length !== 0,
            width: 100,
        },
        {
            Header: <><span>Logbook ID</span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /></>,
            accessor: 'logbook_id',
            show: props.logbookList.length !== 0,
            width: 100,
        },
        {
            Header: <><span>Logbook name</span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
            accessor: 'logbook_name',
            width: 135,
            show: props.logbookList.length !== 0
            // Cell: props => <span className='number'>{props.value}</span> // Custom cell components!
        },
        {
            Header: <><span>Scheduled Date / Time</span>  <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
            accessor: 'created_at',
            width: 200,
            show: props.logbookList.length !== 0,
        },
        {
            Header: <><span>Reviewed Date / Time</span>  <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
            accessor: 'reviewed_at',
            width: 200,
            show: props.logbookList.length !== 0,
        },
        {
            Header: <><span>Review Status</span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
            accessor: 'status',
            width: 180,
            show: props.logbookList.length !== 0
            // Cell: props => <span className='number'>{props.value}</span> // Custom cell components!
        },
        {
            Header: <><span>Critical</span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
            accessor: 'critical',
            show: props.logbookList.length !== 0,
            filterable: false,
            width: 70,
            // Cell: props => <span className='number'>{props.value}</span> // Custom cell components!
        },
        {
            Header: '',
            accessor: 'qr_link',
            width: 250,
            filterable: false,
            // show: false
        },
        {
            Header: '',
            accessor: 'iconsContainer',
            width: 200,
            filterable: false,
        },

    ]

    const [tablePage, setTablePage] = useState(0)
    const tablePageNew = React.useRef()

    if (logbookAreaCurPage !== tablePageNew.current) {
        tablePageNew.current = logbookAreaCurPage;
    }

    const setTablePageFn = (page) => {
        setTablePage(page)
        props.logbookAreaPageClicked(page)
        tablePageNew.current = page;
    }

    const fetchData = (state, instance) => {

        if (SearchOrResetAccountClicked) {
            dispatch(actions.resetSearchOrResetValue())
        } else {
            if (!DateGenerate && !isLogbookLogs) {
                state.page = 2;
                let page = tablePageNew.current >= 0 ? tablePageNew.current + 1 : logbookAreaCurPage + 1;
                const size = state.pageSize;
                if (No_of_rows != size) {
                    page = 1;
                    setTablePageFn(0)
                }
                if (!SearchValue) {
                    SearchValue = {}
                    SearchValue['status'] = 'pending'
                }
                if (SearchValue && SearchValue) {
                    if (SearchValue.fromDate) {
                        SearchValue.fromDate = convertDateToDifferentTZ(SearchValue.fromDate, 'from');
                    }
                    if (SearchValue.toDate) {
                        SearchValue.toDate = convertDateToDifferentTZ(SearchValue.toDate, 'to');
                    }
                }
                const sorted = JSON.stringify(state.sorted);

                /* For filtering logbook through id from email notification link */
                if (window.location.search.includes('logId')) {
                    SearchValue.id = new URLSearchParams(window.location.search).get('logId')
                }

                if (props.user.role === 'frontline') {
                    props.getAllLogbookForFrontline(SearchValue, size, page, sorted);
                } else {
                    props.getAllLogbook(SearchValue, size, page, sorted)
                }
            }
        }
    }

    return (
        SearchOrResetAccountClicked && isLogbookLogs ?
            <div className="loader_btn_block">
                <ClapSpinner backColor="#89073c" size={40} frontColor="#89073c8a" />
            </div>
            :
            <>
                <div className="ptc_container">
                    <div className='ptc_cover'>
                        <div className='ptc_cards' style={{ justifyContent: 'initial' }}>
                            <button className="csv_btn ptc_buttons" >Pending<b>(</b><div style={{ color: '#ac0047' }}><b>{props.total_pending}</b></div><b>)</b></button>
                            <button className="csv_btn ptc_buttons" style={{ marginLeft: '30px' }}>Reviewed<b>(</b><div style={{ color: '#ac0047' }}><b>{props.total_reviewed}</b></div><b>)</b></button>
                        </div>
                    </div>
                </div>
                <ReactTable
                    data={data}
                    loading={isLogbookLogs}
                    defaultPageSize={No_of_rows}
                    minRows={0}
                    manual
                    onFetchData={fetchData}
                    pages={No_of_pages}
                    gotoPage={logbookAreaCurPage}
                    page={logbookAreaCurPage}
                    onPageChange={(page) => { setTablePageFn(page) }}
                    showPaginationTop={true}
                    showPaginationBottom={false}
                    //onPageSizeChange={props.onAccountsPageSizeChange}
                    NoDataComponent={() => CustomNoDataComponent(isLogbookLogs, 'No Logbook Log Found')}
                    //showPagination={props.logbookList.length !== 0}
                    // filterable={true}
                    LoadingComponent={() =>
                        isLogbookLogs ?
                            <div style={{
                                display: "block",
                                position: "absolute",
                                left: 0,
                                height: '100%',
                                right: 0,
                                background: "rgba(255,255,255,0.8)",
                                transition: "all .3s ease",
                                top: 0,
                                bottom: 0,
                                textAlign: "center"
                            }}
                                className="loader_btn_block">
                                <ClapSpinner backColor="#89073c" size={40} frontColor="#89073c8a" />
                            </div> : null}
                    columns={columns}
                    // onPageChange={(pageIndex) => PageJump(pageIndex)}
                    // onPageSizeChange={(pageSize, pageIndex) => PageJump(pageSize, pageIndex)}
                    // renderPageJump={(onChange, value, onBlur, onKeyPress, inputType, pageJumpText) => PageJump(onChange, value, onBlur, onKeyPress, inputType, pageJumpText)}
                    // renderCurrentPage = {(page) => PageJump(page)}
                    // style={{marginTop: '10px'}}
                    getTheadThProps={(state, rowInfo, column, instance) => {

                        return {
                            style: {
                                borderTop: 'none',
                                borderBottom: 'none',
                                borderRight: 'none',
                                textAlign: 'center',
                                color: '#566975',
                                fontSize: '14px',
                                fontWeight: '600',
                                whiteSpace: 'nowrap',
                                float: 'left',
                            }
                        }
                    }
                    }
                    getTrProps={(state, rowInfo, column, instance) => {
                        // onclick = () => userDetail(rowInfo ? rowInfo.row.user : rowInfo);

                        return {
                            className: 'react-table-tr-element',
                            style: {
                                border: '1px solid #c7cdd1',
                                transition: '.3s ease-in-out',
                                width: '100%',
                                // borderRadius: '4px',
                                // height: '80px',
                                marginTop: '5px',
                                float: 'left'
                            }
                        }

                    }}
                    getTdProps={(state, rowInfo, column, instance) => {
                        // if (column.Header == 'Active' || column.Header == 'Actions') {
                        if (column.id == 'image') {
                            return {
                                style: {
                                    marginTop: '0px'
                                }
                            }
                        } else {
                            return {
                                className: 'react-td-element'
                            }
                        }
                        //  {
                        //   style: {
                        //     borderTop: 'none',
                        //     verticalAlign: 'middle',
                        //     fontSize: '14px',
                        //     color: '#2b3034',
                        //     marginTop: '10px !important',
                        //     textAlign: 'center',
                        //   }
                        // }
                    }
                    }
                    // getPaginationProps={() => {
                    //     return {
                    //         style: {
                    //             marginTop: '50px',
                    //             border: 'none',
                    //             boxShadow: 'none'
                    //         }
                    //     }
                    // }
                    // }
                    getProps={() => {
                        return {
                            style: {
                                border: 'none'
                            }
                        }
                    }}
                />
            </>
    )
}

export default LogBookAreaTable;
