import React, { Component } from 'react';
import errorHandler from '../../../../../utility/errorHandler/errorHandler.js';
import moment from "moment";
import { connect } from 'react-redux';
import * as actions from '../../../../../redux/actions/index';
import isEmpty from '../../../../../utility/IsEmptyValidator';
import { TimeZones } from '../../../../../utility/constants/constants';
import AddLogBookAreaForm from '../../../../component/Accounts/LogBook Area/LogBookAreaForm/AddLogBookAreaForm.js';

const cloneDeep = require('clone-deep');

class AddLogBookAreaContainer extends Component {

  constructor(props) {
    super(props);

    this.state = {
      areaErrorMessage: '',
      areaDayErrorMessage: "",
    }
    this.resetDayError = this.resetDayError.bind(this);
  }

  componentDidMount() {
    this.props.getAccount(this.props.match.params.accountId);
    if (isEmpty(this.props.areaTypes)) {
      this.props.getAreaTypes();
    }
    if (isEmpty(this.props.floorList)) {
      this.props.getFloors();
    }
  }

  handleSubmit = (values, a, b) => {
    if (values) {

      if (values.logbook) {

        if (values.logbook.name == '') {
          errorHandler('Logbook area name is required');
          return false;
        }
        if (values.logbook.floor_id == '') {
          errorHandler('Floor no is required');
          return false;
        }
        if (values.logbook.area_type_id == '') {
          errorHandler('Area type is required');
          return false;
        }
        if (values.logbook.schedule_at == '') {
          errorHandler('Start date is required');
          return false;
        }

      }

    }

    console.log('moment', moment.tz("12:00 AM", 'America/Chicago').format('hh:mm A'));
    let isValid = true;
    let tempForm = cloneDeep(values);
    let times = [];
    let duplicateTime = false;
    let days = [];

    if (tempForm.logbook.days && tempForm.logbook.days.length) {
      tempForm.logbook.days.map((day, index) => {
        if (day) days.push(index)
      });
      if (days.length) tempForm.logbook.days = days;
      else {
        isValid = false;
        this.setState({ areaDayErrorMessage: "Please select At least one Day" });
      }
    } else {
      isValid = false;
      this.setState({ areaDayErrorMessage: "Please select At least one Day" });
    }

    if (tempForm.logbook.timeslots_attributes) {
      tempForm.logbook.timeslots_attributes.map(e => {
        if (times.includes(e.start_time)) duplicateTime = true;
        else times.push(e.start_time)
      });
      if (tempForm.logbook.timeslots_attributes.length < 2 && !tempForm.logbook.timeslots_attributes[0].start_time) delete tempForm.logbook.timeslots_attributes
    }
    if (duplicateTime) {
      isValid = false;
      this.setState({ areaErrorMessage: "Duplicate time not allowed" });
      setTimeout(() => this.setState({ areaErrorMessage: "" }), 2000)
    }
    else {
      if (tempForm.logbook.timeslots_attributes) {
        tempForm.logbook.timeslots_attributes.map(e => {
          const date = moment().format("MM/DD/YYYY") + " " + e.start_time;
          // const hours = this.props.account.user.timezone === TimeZones.PST ? 7 : 5;
          // e.start_time_utc = moment(moment().format("MM-DD-YYYY") + " " + e.start_time, "MM-DD-YYYY HH:mm A").add(hours, "hours").format("hh:mm A")
          
          const timezone= this.props?.account?.user?.timezone
          e.start_time_utc=moment.tz(e.start_time,"HH:mm A",timezone).utc().format("hh:mm A")
          // e.start_time_utc = moment.utc(moment(date)).format("hh:mm A");
        });
      }
      if (isValid) {
        // tempForm.logbook.schedule_at_utc = moment.utc(tempForm.logbook.schedule_at).format("MM-DD-YYYY");
        tempForm.logbook.schedule_at = moment(tempForm.logbook.schedule_at).format("MM-DD-YYYY");

        if (tempForm?.logbook?.labelsId?.value?.length > 0) {
          tempForm.logbook.labelsId = Array.of(tempForm.logbook.labelsId.value);
        }

        this.props.createLogbook(this.props.match.params.accountId, tempForm)
      }
    }
  };
  resetDayError() {
    this.setState({ areaDayErrorMessage: "" })
  }

  componentWillUnmount() {
    //this.props.resetObjects();
  }

  render() {

    return (
      <AddLogBookAreaForm
        {...this.props}
        {...this.state}
        onSubmit={this.handleSubmit}
        resetDayError={this.resetDayError}
        areaDayErrorMessage={this.state.areaDayErrorMessage}
        areaErrorMessage={this.state.areaErrorMessage}
      />
    )
  }
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.adminOrSuperAdminReducer.isLoading,
    account: state.adminOrSuperAdminReducer.account,
    areaTypes: state.adminOrSuperAdminReducer.areaTypes,
    floorList: state.adminOrSuperAdminReducer.floorList,
    playbookPdfLabelsList: state.PegassurePlaybookReducer.playbookPdfLabelsList
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    createLogbook: (id, form) => dispatch(actions.createLogbook({ id, form, })),
    getAccount: (id) => dispatch(actions.getEditAccount(id)),
    getAreaTypes: () => dispatch(actions.getAreaTypes()),
    getFloors: () => dispatch(actions.getFloors()),
    getAllPlaybookPdfLabels: () => dispatch(actions.getAllPlaybookPdfLabels()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddLogBookAreaContainer);