import React from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../redux/actions/index';
import { routes } from '../../../utility/constants/constants';
import { reset } from 'redux-form';
import Companies from '../../component/companies/companies';
import { animateScroll as scroll} from 'react-scroll'
import { getUser } from '../../../utility/utility';

class CompaniesContainer extends React.Component {

    newCompanieClicked = () => {
        this.props.history.push(routes.NEW_COMPANY)
    }

    componentDidMount() {
        // if (!this.props.companiesList) {
        // this.props.getCompanies();
        // }
        scroll.scrollToTop();
    const userDetails = getUser();
    this.props.getModeAccounts(userDetails.mode);
    }

    resetClicked = () => {
        this.props.companyPageClicked(0)
        this.props.reset('searchCompanieForm');
        this.props.getCompanies({active: this.props.activeCompanies}, this.props.company_rows);
    }

    onCompaniesPageSizeChange(){
        
    }


    handleClickDeleteModel = (company) => {

        this.props.handleClickDeleteModel(company);
    }

    handleDeleteClose = () => {
        this.props.handleDeleteClose();
    }

    deleteCompany = (id) => {
        this.props.deleteCompany(id);
    }

    editCompanyClicked = (company) => {
        this.props.editCompanyClicked(company);
    }

    previewCompanyClicked = (company) => {

        this.props.previewCompanyUsers(company);
        this.props.history.push(`/customers/${company.id}/customer_users`);
    }

    searchCompanies = (values) => {
        this.props.companyPageClicked(0)
        values.active = this.props.activeCompanies;
        this.props.searchCompanies(values);
    }

    activeCompanyClicked = (e) => {
        this.props.companyActiveChanged()
    }
    render() {

        return (
            <Companies
                {...this.props}
                resetClicked={this.resetClicked}
                onSubmit={this.searchCompanies}
                deleteCompany={this.deleteCompany}
                handleDeleteClose={this.handleDeleteClose}
                newCompanieClicked={this.newCompanieClicked}
                deleteCompanie={this.deleteCompanie}
                companies_rows={this.props.company_rows}
                isLoading={this.props.isLoading}
                previewCompanyClicked={this.previewCompanyClicked}
                onCompaniesPageSizeChange={this.onCompaniesPageSizeChange}
                companiesList={this.props.companiesList}
                editCompanyClicked={this.editCompanyClicked}
                deleteObject={this.props.deleteObject}
                openDeleteModel={this.props.openDeleteModel}
                handleClickDeleteModel={this.handleClickDeleteModel}
                activeCompanies={this.props.activeCompanies}
                activeCompanyClicked={this.activeCompanyClicked}
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.authReducer.user,
        company: state.adminOrSuperAdminReducer.company,
        companiesList: state.adminOrSuperAdminReducer.companiesList,
        isLoading: state.adminOrSuperAdminReducer.isLoading,
        isCompanyPending: state.adminOrSuperAdminReducer.isCompanyPending,
        deleteObject: state.adminOrSuperAdminReducer.deleteObject,
        openDeleteModel: state.adminOrSuperAdminReducer.openDeleteModel,
        company_rows: state.adminOrSuperAdminReducer.company_rows,
        accountsList: state.adminOrSuperAdminReducer.modeAccountsList,
        activeCompanies: state.adminOrSuperAdminReducer.activeCompanies,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        handleDeleteClose: () => dispatch(actions.handleDeleteClose()),
        handleClickDeleteModel: (user) => dispatch(actions.handleClickDeleteModel(user)),
        // searchUserClicked: (quote) => dispatch(actions.searchUserClicked(quote)),
        // onCompanyPageSizeChange: (rows) => dispatch(actions.onCompanyPageSizeChange(rows)),
        searchCompanies: (values) => dispatch(actions.searchCompanies(values)),
        editCompanyClicked: (user) => dispatch(actions.editCompanyClicked(user)),
        getCompanies: (values, company_no_of_rows, page, sorted, filtered) => dispatch(actions.getCompanies(values, company_no_of_rows, page, sorted, filtered)),
        getUserPaginationCompanies: (values, company_no_of_rows, page, sorted, filtered, userId) => dispatch(actions.getUserPaginationCompanies(values, company_no_of_rows, page, sorted, filtered, userId)),
        deleteCompany: (id) => dispatch(actions.deleteCompany(id)),
        reset: (searchCompanieForm) => dispatch(reset(searchCompanieForm)),  // requires form name
        previewCompanyUsers: (company) => dispatch(actions.previewCompanyUsers(company)),
        getModeAccounts: (mode) => dispatch(actions.getModeAccounts(mode)),
        companyActiveChanged: () => dispatch(actions.companyActiveChanged()),
        companyPageClicked: (page) => dispatch(actions.companyPageClicked(page)),
        // newUserClicked: () => dispatch(actions.newUserClicked())

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CompaniesContainer);