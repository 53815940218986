import React, { Component } from 'react';
import { routes } from '../../../utility/constants/constants';
import PegassureLogbook from '../../component/PegassureLogbook/PegassureLogbook';
import * as actions from '../../../redux/actions/index';
import { connect } from 'react-redux';
import LogBookQrReader from '../../../adminOrSuperAdminOrRequestor/container/ AccountsContainer/LogBookAreaContainer/LogBookQRContainer/LogBookQRContainer';
class PegassureLogbookContainer extends Component {

    // openCamera = () => {
    //     this.props.history.push(routes.SCAN_LOGBOOK_QR);
    // }

    render() {

        return (
            // <PegassureLogbook
            // {...this.props}
            // openCamera={this.openCamera}
            // // priorEscalation={this.openPriorEscalation}
            // />
            <LogBookQrReader />
        );

    }
}

export default PegassureLogbookContainer;