import React, { Component } from 'react';
import ForgetAndResetPassword from '../../component/ForgetOrResetPassword/ForgetAndResetPassword';
import { routes } from '../../../utility/constants/constants';
import queryString from "query-string";
import * as actions from '../../../redux/actions/index';
import { connect } from 'react-redux';
// const queryString = require('query-string');


class ResetPassword extends Component {

    signInClicked = () => {
        this.props.history.push(routes.SIGNIN);
    }

    handleSubmit = (user) => {
        const token = queryString.parse(this.props.location.search);
        user.user['reset_password_token'] = token.reset_password_token;
         
        this.props.resetPassword(user);
    }

    componentDidMount() {
        console.log(this.props);
         
    }

    render() {

        return (
            <ForgetAndResetPassword
                signInClicked={this.signInClicked}
                onSubmit={this.handleSubmit}
                token={queryString.parse(this.props.location.search)}
                isLoading={this.props.isLoading}
            />

        )
    }
}

export const mapStateToProps = (state) => {
    return {
        isLoading: state.authReducer.isLoading
    }
}

export const mapDispatchToProps = (dispatch) => {
    return {
        resetPassword: (user) => dispatch(actions.resetPassword(user))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);