import React, { useEffect, useState } from 'react';
import { getFormValues, reduxForm } from 'redux-form';
import { Input } from '../../../UI/InputElement/InputElement';
import { scheduledTaskValidator as validate } from '../../../../../utility/validator/validator';
import { connect } from 'react-redux';
import { priorityOptions } from '../../../../../utility/constants/constants';
import { DateTimeField } from '../../../UI/DateTimeField/DateTimeField';

const getAccountsDropDown = (props) => {

    return props.accountsList && props.accountsList.map(account => {
        return {
            value: account.id,
            label: account.name
        }
    })
}

const getSkillSetDropDown = (props) => {

    return props.skillSet_list && props.skillSet_list.map(account => {
        return {
            value: account.id,
            label: account.name
        }
    })
}

const getCompanyListDropDown = (props) => {

    return props.company_list && props.company_list.map(account => {
        return {
            value: account.id,
            label: account.name
        }
    })
}



let NewScheduledTask = (props) => {
    const { handleSubmit, pristine, reset, submitting, error, change,onSubmit } = props;
    

    


    

    const [accounts, setAccounts] = useState(getAccountsDropDown(props));
    const [skillSet, setSkillSet] = useState(getSkillSetDropDown(props));
    const [companyList, setCompanyList] = useState(getCompanyListDropDown(props));
    const [addExistItemSlots, setAddExistItemSlots] = useState([{
        itemId: '',
        itemQuantity: ''
    }]);

    const handleFormSubmit = (values) => {
        // Call the onSubmit function with the additional argument
        onSubmit(values,Boolean(companyList.length) );
    };

    const handleCreateExistItemSlots = () => {
        let slots = [...addExistItemSlots];
        slots.push({
            itemId: '',
            itemQuantity: ''
        });
        setAddExistItemSlots(slots);
        setTimeout(() => {
            let ele = document.getElementById(`triggerPoint-${slots.length - 1}`)
            if (ele) {
                ele.click();
            }
        }, 100);
    }

    useEffect(() => {
        let slots = [...addExistItemSlots];
        let ele = document.getElementById(`triggerPoint-${slots.length - 1}`)
        if (ele) {
            ele.click();
        }
    }, [])

    useEffect(() => {
        setAccounts(getAccountsDropDown(props))
    }, [props.accountsList])

    useEffect(() => {
        setSkillSet(getSkillSetDropDown(props))
    }, [props.skillSet_list])

    useEffect(() => {
        setCompanyList(getCompanyListDropDown(props))
    }, [props.company_list])


    const noAccountOptionsMessage = (a, b) => {
        return 'Job not found';
    }

    const noSkillSetOptionsMessage = (a, b) => {
        return 'Skillset not found';
    }

    const noCompanyOptionsMessage = (a, b) => {
        return 'Company not found';
    }

    const handleDeleteExistItemSlots = (index) => {
        let itemSlots = [...addExistItemSlots];
        itemSlots.splice(index, 1);
        setAddExistItemSlots(itemSlots);

        if ((itemSlots && itemSlots.length) && (props.formStates.scheduledTask && props.formStates.scheduledTask.subtasks)) {
            let items = props.formStates.scheduledTask.subtasks;
            items.splice(index, 1);
            change("scheduledTask[subtasks]", items);
        } else {
            change("scheduledTask[subtasks]", [{ itemId: '', itemQuantity: '' }]);
        }
    };

    const findAdderess = (id) => {
        if (id) {
            const job = [...props.accountsList]
            const address = job.find((job) => job.id === id)
            return address.address.formatted_address
        } else {
            return null
        }

    }

    return (
        <main className="app-content wraper_content user_newQuote add_account_modal add_New_Accont add_newAccount">
            <div className="inspection_content_sec appcontent_Inner">
                <div className="container-fluids">
                    <div className="quote_form ticket_form" style={{ maxWidth: '700px' }}>
                        <div className="row">
                            <div className="col-sm-12 text-center"> <h2 className="md_title" >Schedule Task</h2></div>
                        </div>
                        <div className="addQuote_form schedule_taskform_in">
                            <form className="form_content" onSubmit={handleSubmit(handleFormSubmit)} >

                                <div className="cst_tab_content border-btm">

                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label name="scheduledTask[task_name]" className="form_title">Task Name <sup className="cst_sup_txt text_danger">*</sup></label>
                                                <Input dataTest="" type="text" className="form-control input-modifier" placeholder="Task Name" name="scheduledTask[task_name]" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label name="scheduledTask[job]" className="form_title">Job <sup className="cst_sup_txt text_danger">*</sup></label>
                                                <Input instanceId="job_select" name="scheduledTask[job]" noOptionsMessage={noAccountOptionsMessage} onChange={(account) => { props.getFilteredCompanies(account.value); }} type="inputSelect" options={accounts} placeholder="Please select" />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label name="scheduledTask[company]" className="form_title">Company <sup className="cst_sup_txt text_danger">*</sup></label>
                                                <Input name="scheduledTask[company]" noOptionsMessage={noCompanyOptionsMessage} type="inputSelect" options={companyList} placeholder={companyList && companyList.length > 0 ? "Please select" : "Select job, see companies"} />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label name="scheduledTask[location]" className="form_title">Location</label>
                                                <input type='text' className="form-control input-modifier" value={findAdderess(props.formStates && props.formStates.scheduledTask ? props.formStates.scheduledTask.job?.value : null)} disabled={true} />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="form_title"> Start Date <sup className="cst_sup_txt text_danger">*</sup></label>
                                                <div className="input-group cst_input_group col-lg-14 pl-0">
                                                    <div style={{ width: '100%' }}>
                                                        <Input
                                                            type="date"
                                                            datePickerType={"react-datepicker"}
                                                            minDate={new Date()}
                                                            dateFormat="MM-dd-yyyy"
                                                            className="form-control input-modifier"
                                                            placeholder="Select Date"
                                                            name="scheduledTask[start_date]"
                                                        />
                                                    </div>
                                                    <div className="input-group-append cursor-pointer" style={{ float: "right", position: "absolute", right: 0, height: "45px", top: 0 }}>
                                                        <span class="input-group-text" id="basic-addon2"> <i class="fa fa-calendar"></i> </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="form_title"> End Date <sup className="cst_sup_txt text_danger">*</sup></label>
                                                <div className="input-group cst_input_group col-lg-14 pl-0">
                                                    <div style={{ width: '100%' }}>
                                                        <Input
                                                            type="date"
                                                            datePickerType={"react-datepicker"}
                                                            minDate={props.formStates && props.formStates.scheduledTask ? props.formStates.scheduledTask.start_date : new Date()}
                                                            dateFormat="MM-dd-yyyy"
                                                            className="form-control input-modifier"
                                                            placeholder="Select Date"
                                                            name="scheduledTask[end_date]"
                                                        />
                                                    </div>
                                                    <div className="input-group-append cursor-pointer" style={{ float: "right", position: "absolute", right: 0, height: "45px", top: 0 }}>
                                                        <span class="input-group-text" id="basic-addon2"> <i class="fa fa-calendar"></i> </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="createsub_ticket">
                                                <div className='createsub_ticket_text' onClick={() => { handleCreateExistItemSlots(); }}>+ Create Tickets</div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* <div className="row">
                                                <div className="col-md-6">
                                                <div className="form-group">
                                                <label className="label_modifier">Asset Status</label>
                                                <Input instanceId="job_select"  type="select" options={assetsOptions}    placeholder="Select Status" className="custom-select input-modifier add_user_select" name="item[status]" />
                                             </div>
                                                </div>
                                            </div>  */}

                                </div>
                                {addExistItemSlots.map((item, index) => (
                                    <div className="cst_tab_content border-btm">

                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label name={`scheduledTask[subtasks][${index}][ticket]`} className="form_title">Ticket <sup className="cst_sup_txt text_danger">*</sup></label>
                                                    <Input dataTest="" type="text" className="form-control input-modifier" placeholder="" name={`scheduledTask[subtasks][${index}][ticket]`} />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label name={`scheduledTask[subtasks][${index}][skill_set]`} className="form_title">Skill Set <sup className="cst_sup_txt text_danger">*</sup></label>
                                                    <Input name={`scheduledTask[subtasks][${index}][skill_set]`} noOptionsMessage={noSkillSetOptionsMessage} isMulti={true} type="inputSelect" options={skillSet} />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="form_title">Priority <sup className="cst_sup_txt text_danger">*</sup></label>
                                                    <Input dataTest="ticket_priority" name={`scheduledTask[subtasks][${index}][priority]`} className="custom-select input-modifier" type="select" options={priorityOptions} />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            {/* <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="form_title"> Start Time <sup className="cst_sup_txt text_danger">*</sup></label>
                                                    <div className="input-group cst_input_group col-lg-14 pl-0">
                                                        <div style={{ width: '100%' }}>
                                                            <DateTimeField   timeConstraints={{ minutes: { step: 5 } }} datePickerType='react-datetime' pickerType="timePicker" dataFormat="hh:mm A" className="form-control Input-modifier" id="scheduledTaskName"
                                                                name={`scheduledTask[subtasks][${index}][start_date]`}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="form_title"> End Time <sup className="cst_sup_txt text_danger">*</sup></label>
                                                    <div className="input-group cst_input_group col-lg-14 pl-0">
                                                        <div style={{ width: '100%' }}>
                                                        <DateTimeField   timeConstraints={{ minutes: { step: 5 } }} datePickerType='react-datetime' pickerType="timePicker" min={'04:33AM'} dataFormat="hh:mm A" className="form-control Input-modifier" id="scheduledTaskName"
                                                                name={`scheduledTask[subtasks][${index}][end_date]`}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> */}
                                            <div class="col-md-12">
                                                <div className="form-group">
                                                    <label name={`scheduledTask[subtasks][${index}][message]`} className="form_title">Notes </label>
                                                    <Input type="textarea" rows='3' placeholder="Type here" className="form-control textarea_modifier" name={`scheduledTask[subtasks][${index}][message]`} />
                                                </div>
                                            </div>
                                            {index != 0 && (
                                                <div class="col-md-12">
                                                    <div class="createsub_ticket">
                                                        <div className='createsub_ticket_text' onClick={() => handleDeleteExistItemSlots(index)}> - Remove Subtickets</div>
                                                    </div>
                                                </div>
                                            )}
                                            <div style={{ display: 'none' }}>
                                                <Input
                                                    type="radio"
                                                    forLable={`triggerPoint-${index}`}
                                                    value={"false"}
                                                    className="custom-control-input"
                                                    id={`triggerPoint-${index}`}
                                                    name={`scheduledTask[subtasks][${index}][triggerPoint]`}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                ))}

                                <div className="modal-footer">
                                    <button data-test="submit_button" type="submit" disabled={props.isLoading} className={props.isLoading ? "btn cst_btn btn_danger btn-wait w-25" : "btn cst_btn btn_danger w-25"} >{props.isLoading ? "" : "Submit"}</button>
                                    <button data-test="cancel_button" type="button" className="btn cst_btn btn-outline-secondary cancel_btnnew w-25" onClick={() => { props.onCancelClicked() }}>Cancel</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </main >
    )

}


const mapStateToProps = (state) => {
    return { formStates: getFormValues('newScheduledTaskForm')(state) }

}

NewScheduledTask = reduxForm({
    form: 'newScheduledTaskForm',
    validate,
    enableReinitialize: true,
})(NewScheduledTask);

NewScheduledTask = connect(mapStateToProps)(NewScheduledTask)

export default NewScheduledTask;