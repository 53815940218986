import React from 'react';
import InspectionReports from '../../component/InspectionReports/InspectionReports'
import { connect } from 'react-redux';
import * as actions from '../../../redux/actions/index';
import { TimeZones } from '../../../utility/constants/constants';
import { convertDateToDifferentTZ, getUser } from '../../../utility/utility';
import errorHandeler from '../../../utility/errorHandler/errorHandler';
const cloneDeep = require('clone-deep');

class InspectionReportsContainer extends React.Component {

    getReports = (values) => {
        const data = cloneDeep(values);
        const user = getUser();
        // data.company_ids = data.company_ids ? data.company_ids.map(c => c.value) : [];
        data.account_ids = data.account_ids ? data.account_ids.map(a => a.value) : [];
        data.fromDate = convertDateToDifferentTZ(data.fromDate, 'from');
        data.toDate = convertDateToDifferentTZ(data.toDate, 'to');
        data.mode = user.mode;
        this.props.getInspectionReports(data);
    }
    onReportExportClicked = () => {
        if(this.props.formValues.account_ids && this.props.formValues.fromDate && this.props.formValues.toDate) {
            let data = {};
            const user = getUser();
            data['account_ids'] = this.props.formValues.account_ids ? this.props.formValues.account_ids.map(a => a.value) : [];
            data['fromDate'] = convertDateToDifferentTZ(this.props.formValues.fromDate, 'from');
            data['toDate'] = convertDateToDifferentTZ(this.props.formValues.toDate, 'to');
            data['mode'] = user.mode;
            this.props.getInspectionReportsCsv(data);
        } else {
            errorHandeler('Please apply filters');
        }
    }

    render() {
        return (
            <InspectionReports
                {...this.props}
                onSubmit={this.getReports}
                onReportExportClicked={this.onReportExportClicked}
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        isInspectionReportsLoading: state.adminOrSuperAdminReducer.isInspectionReportsLoading,
        inspectionReports: state.adminOrSuperAdminReducer.inspectionReports,
        overall_inspection_score: state.adminOrSuperAdminReducer.overall_inspection_score,
        lowest_performing_account_name: state.adminOrSuperAdminReducer.lowest_performing_account_name
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getInspectionReports: (data) => dispatch(actions.getInspectionReports(data)),
        getInspectionReportsCsv: (data) => dispatch(actions.getInspectionReportsCsv(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(InspectionReportsContainer);