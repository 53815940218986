import React, { useEffect, useState } from 'react';

import { Calendar, momentLocalizer } from 'react-big-calendar';
import ViewAttendanceDashboardModal from './ViewAttendanceDashboardModal';
import "react-big-calendar/lib/css/react-big-calendar.css";
import ReactTooltip from 'react-tooltip';

import storage from '../../../../utility/storage';
import { TimeZones, userRoles } from '../../../../utility/constants/constants';
import {
    getInbetweenWeekDates,
    getMonthNo,
    getDayBinaryNo,
    getUser,
    convertDateFormatForSchedule,
    startOfWeek,
    endOfWeek,
    makeFirstLetterCapital,
    convertDateFormatV4,
    identifyDayNameOfWeek,
    convertTo12HourFormat,
    getInbetweenWeekDates_V2,
    startOfMonth,
    endOfMonth,
    getFormattedDateOfGoat
} from '../../../../utility/utility';

import CalendarDateSearch from '../../../../utility/CalendarUtilities/CalendarDateSearch/CalendarDateSearch';
import moment from 'moment';
import { customTime, toFindPDTAvailability, tzCalendarDateTimeConverterTool, tzDateConverterTool_V5, tzEndOfWeek, tzStartOfWeek, tzTimezoneMarking, tzUTCDateConverterTool_V2, tzUTCDateTimeConverterTool_V1 } from '../../../../utility/TimezoneOperations/timezoneUtility';
const localizer = momentLocalizer(moment)
var momentTZ = require('moment-timezone');

let AttendanceDashboardComponent = (props) => {

    const { isLoading, presentsArray, absentsArray, complaintsArray, handleShiftPresencePanel_openModal, getSingleShifSchedule } = props;
    let { rbcBtnGroup, rbcToolbarLabel, rbcHeader } = {};
    const [fetchedRBCToolbarLabel, setFetchedRBCToolbarLabel] = useState([]);
    const [presentsArrayData, setPresentsArrayData] = useState([]);
    const [absentsArrayData, setAbsentsArrayData] = useState([]);
    const [complaintsArrayData, setComplaintsArrayData] = useState([]);

    const viewAttendance = (item, type) => {
        setTimeout(() => {
            document.getElementsByClassName('attendanceDashboardModal')[0].click();
            handleShiftPresencePanel_openModal({ attendanceItem: item, type });
            getSingleShifSchedule(item.id);
        }, 10);
    }

    useEffect(() => {
        rbcBtnGroup = document.getElementsByClassName('rbc-btn-group');
        rbcToolbarLabel = document.getElementsByClassName('rbc-toolbar-label');
        rbcHeader = document.getElementsByClassName('rbc-header');

        if (rbcBtnGroup) {
            rbcBtnGroup[0].childNodes[1].textContent = "Previous"
        }

        if (rbcToolbarLabel) {
            setFetchedRBCToolbarLabel(rbcToolbarLabel[0]?.childNodes[0]?.textContent?.split(' '));
        }

        if (rbcHeader) {
            rbcHeader[0].childNodes[0].textContent = "SUN"
            rbcHeader[1].childNodes[0].textContent = "MON"
            rbcHeader[2].childNodes[0].textContent = "TUE"
            rbcHeader[3].childNodes[0].textContent = "WED"
            rbcHeader[4].childNodes[0].textContent = "THU"
            rbcHeader[5].childNodes[0].textContent = "FRI"
            rbcHeader[6].childNodes[0].textContent = "SAT"
        }

        if (fetchedRBCToolbarLabel?.length > 0) {
            setPresentsArrayData(presentsArray?.length > 0 ? presentsArray.map((preItem, index) => {
                let formattedDate = getFormattedDateOfGoat(fetchedRBCToolbarLabel[1], fetchedRBCToolbarLabel[0], index + 1);
                if (preItem[formattedDate]?.length > 0) {
                    let attendanceType = 'present';
                    let presentBox = preItem[formattedDate][0];
                    return {
                        id: 'presents',
                        title: <div onClick={() => { viewAttendance(presentBox, attendanceType); }}> P </div>,
                        start: new Date(momentTZ(formattedDate)),
                        end: new Date(momentTZ(formattedDate)),
                    }
                }
            }) : []);

            setAbsentsArrayData(absentsArray?.length > 0 ? absentsArray.map((preItem, index) => {
                let formattedDate = getFormattedDateOfGoat(fetchedRBCToolbarLabel[1], fetchedRBCToolbarLabel[0], index + 1);
                if (preItem[formattedDate]?.length > 0) {
                    let attendanceType = 'absent';
                    let absentBox = preItem[formattedDate][0];
                    return {
                        id: 'absents',
                        title: <div onClick={() => { viewAttendance(absentBox, attendanceType); }}> A </div>,
                        start: new Date(momentTZ(formattedDate)),
                        end: new Date(momentTZ(formattedDate)),
                    }
                }
            }) : []);

            setComplaintsArrayData(complaintsArray?.length > 0 ? complaintsArray.map((preItem, index) => {
                let formattedDate = getFormattedDateOfGoat(fetchedRBCToolbarLabel[1], fetchedRBCToolbarLabel[0], index + 1);
                if (preItem[formattedDate]?.length > 0) {
                    let attendanceType = 'complaint';
                    let complaintBox = preItem[formattedDate][0];
                    return {
                        id: 'complaints',
                        title: <div onClick={() => { viewAttendance(complaintBox, attendanceType); }}> C </div>,
                        start: new Date(momentTZ(formattedDate)),
                        end: new Date(momentTZ(formattedDate)),
                    }
                }
            }) : []);
        }
    }, [props]);

    console.log('presentsArrayData', presentsArrayData);

    let eventStyleBunker = (event) => {
        var style = {
            backgroundColor: '',
            color: '',
            borderColor: '',
            fontSize: '',
            fontWeight: '',
            borderRadius: '',
            // opacity: 0.8,
            // border: '0px',
            // display: 'block'
        };

        if (event?.id?.length > 0) {
            if (event.id.includes('presents')) {
                style = {
                    backgroundColor: '#77dd77',
                    color: '#4d4d51',
                    borderColor: '#77dd77',
                    fontSize: 'larger',
                    fontWeight: '600',
                    borderRadius: '10px',
                };
            } else if (event.id.includes('absents')) {
                style = {
                    backgroundColor: '#FF6961',
                    color: '#ffffff',
                    borderColor: '#FF6961',
                    fontSize: 'larger',
                    fontWeight: '600',
                    borderRadius: '10px',
                };
            } else if (event.id.includes('complaints')) {
                style = {
                    backgroundColor: '#fce50b',
                    color: '#505050',
                    borderColor: '#fce50b',
                    fontSize: 'larger',
                    fontWeight: '600',
                    borderRadius: '10px',
                };
            }
        }

        return {
            style: style
        };

    };

    return (
        <>
            <main className="app-content wraper_content inspection_wraper attendance_dashboard_calendar">
                <div className="tab_header search_filter_cont inspection_tab_content job_filter">
                    <form>
                        <div className="row mt-2 justify-content-center">
                            <div className="col-xl-12 col-lg-12 text-left quotes_btn_wrap schedulesButtonWrap mw-50per mt-2">
                                <div className="media-body" style={{ display: 'flex', justifyContent: 'space-evenly' }}>

                                    <div className='d-flex flex-column align-items-center'>
                                        <div className='sticher-box presentColor' />
                                        <p className='mt-2 font-weight-bold mb-2 text-muted' style={{ fontSize: 'large' }}> Present </p>
                                    </div>


                                    <div className='d-flex flex-column align-items-center'>
                                        <div className='sticher-box absentColor' />
                                        <p className='mt-2 font-weight-bold mb-2 text-muted' style={{ fontSize: 'large' }}> Absent </p>
                                    </div>

                                    <div className='d-flex flex-column align-items-center'>
                                        <div className='sticher-box complaintColor' />
                                        <p className='mt-2 font-weight-bold mb-2 text-muted' style={{ fontSize: 'large' }}> Complaint </p>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <section className="account_sec inspection_tab_content appcontent_Inner" id="">
                    <div className="quotes_table_content accounts_table_contnet table-responsive overflow-auto">
                        <div className="table quotes_table user_react_table">
                            <div className="mt-4">
                                <Calendar
                                    localizer={localizer}
                                    startAccessor="start"
                                    endAccessor="end"
                                    events={presentsArrayData.concat(absentsArrayData).concat(complaintsArrayData)}
                                    eventPropGetter={eventStyleBunker}
                                    defaultView="month"
                                    views={['month']}
                                    style={{ height: 800, paddingRight: '4%', paddingLeft: '4%' }}
                                    tooltipAccessor={() => null}
                                    onNavigate={(date, dataType) => {
                                        debugger
                                        if (dataType == 'month') {
                                            setTimeout(() => {
                                                let isMonth = true;
                                                let fetchedCurrDateTime = momentTZ(date).format('MM-DD-YYYYTHH:mm:ss').split('T');
                                                let currentDate = tzUTCDateTimeConverterTool_V1(fetchedCurrDateTime[0], fetchedCurrDateTime[1], 'none');
                                                let userId = props.match.params.flUserId;

                                                props.getShiftAttendanceCalendarList({ isMonth, currentDate, userId });
                                            }, 500);
                                        }
                                    }}
                                    onShowMore={(e, f) => {
                                        window.isMoreClicked = true;
                                    }}
                                // view='month'
                                // defaultView="month"

                                />
                            </div>
                        </div>
                    </div>
                </section>
                <button type="button" className="d-none attendanceDashboardModal" data-toggle="modal" data-target="#attendanceDashboardModal" data-backdrop="static" data-keyboard="false" onClick={() => { handleShiftPresencePanel_openModal(); }} />
            </main>

            {props.isTimeTrackerModalOpen ? (
                <ViewAttendanceDashboardModal
                    {...props}
                />
            ) : null}
        </>
    );
};

export default AttendanceDashboardComponent;