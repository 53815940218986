import React, { useState, useEffect } from 'react';
import moment from "moment";
import { Input } from '../../../UI/InputElement/InputElement';
import { SelectField } from '../../../UI/SelectField/SelectField';
import { DateTimeField } from '../../../UI/DateTimeField/DateTimeField';
// import { newAreaValidator as validate } from '../../../../../utility/validator/newValidator';
import { connect, useDispatch, useSelector } from 'react-redux';
import { reduxForm } from 'redux-form';
import { DAYS } from "../../../../../utility/constants/constants";
import { getAllPlaybookPdfLabels } from '../../../../../redux/actions';
import { convertUTCtoTimezone } from '../../../../../utility/utility';
const momentTZ = require('moment-timezone');

// import {playbookPdfLabelsList} from ''
let EditLogBookAreaForm = (props) => {
  const { setTimeSlot, timeSlots, accountArea, handleSubmit, change, pristine, reset, submitting, error, resetDayError } = props;
  const initialTime = { hours: "00", minutes: "00", timeDivision: "am" }
  const [loaded, setLoaded] = useState(false);
  const [isCriticalEnv, setIsCriticalEnv] = useState(false);
  const {playbookPdfLabelsList}= useSelector((state)=> state.PegassurePlaybookReducer)
  const dispatch=useDispatch()
  useEffect(() => {
    setIsCriticalEnv(props?.logbookForm?.EditLogBookAreaForm?.values?.logbook?.critical);
  }, [props?.logbookForm?.EditLogBookAreaForm?.values?.logbook?.critical]);

  useEffect(()=>{
    dispatch(getAllPlaybookPdfLabels())
  },[])

  function convertToOptionsForPDF(list){
    if( !list ||list.length==0) return []
     return list.map((item)=>({label:item.title,value:item.id}))
  }

  const removeTimeSlot = (index) => {
    let times = [...timeSlots];
    times.splice(index, 1);
    let timeslots_attributes = props.logbookForm.EditLogBookAreaForm.values.logbook.timeslots_attributes;
    let id = timeslots_attributes[index] ? timeslots_attributes[index].id : "";
    timeslots_attributes.splice(index, 1);
    change("logbook[timeslots_attributes]", timeslots_attributes)
    setTimeSlot(times, id);
  };

  const addTimeSlot = () => {
    let times = [...timeSlots];
    times.push({
      "start_time": "12:00 AM"
    });
    setTimeSlot(times);
  };

  useEffect(() => {
    if (accountArea && !loaded) {
      setLoaded(true);
      let exitingSlots = accountArea && accountArea.logbook_time_slot ? accountArea.logbook_time_slot.map(time => moment(moment().format("MM-DD-YYYY") + " " + time.start_time).format("hh:mm A")) : '';
      setTimeSlot(exitingSlots);
    }
  });


  const noOptionsHolidayMessage = () => {
    return 'Holiday not found';
  }
  const getFormattedZone = (timezone) => {
    let zone = ''
    const now = momentTZ.tz(timezone);
    zone = now.format('z');
    return zone;
  }

  const pdfList = convertToOptionsForPDF(playbookPdfLabelsList)

  

  return (
    <main className="app-content add_area_modal">
      <div className="appcontent_Inner">
        <div className="add_info_block">
          <h2 className="h2_title">Edit an Logbook Area</h2>
          <div className="add_content_block">
            <form className="form_content" onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="accountID" className="label_modifier">Job ID</label>
                <Input type="text" readOnly={true} className="form-control Input-modifier" id="accountID" placeholder="Type here" name="logbook[account_id]" />
              </div>
              <div className="form-group">
                <label htmlFor="areaID" className="label_modifier">Logbook Area ID</label>
                <Input type="text" readOnly={true} className="text-uppercase form-control Input-modifier" id="areaID" placeholder="Type here" name="logbook[log_number]" />
              </div>
              <div className="form-group">
                <label htmlFor="areaName" className="label_modifier">Logbook Area Name</label>
                <Input type="text" className="form-control Input-modifier" id="areaName" placeholder="Type here" name="logbook[name]" required />
              </div>
              <div className="form-group" include="form-Input-select()">
                <label className="label_modifier">Floor No.</label>
                <SelectField
                  className="custom-select Input-modifier"
                  options={props.floorList}
                  name="logbook[floor_id]" required>
                </SelectField>
              </div>
              <div className="form-group" include="form-Input-select()">
                <label className="label_modifier">Area Type</label>
                <SelectField
                  className="custom-select Input-modifier"
                  options={props.areaTypes}
                  name="logbook[area_type_id]" required>
                </SelectField>
              </div>
              <div className="form-group">
                <label className="label_modifier">Select Time  ({props.account.user ? getFormattedZone(props.account.user.timezone) : ''}) </label>
                <button type="button" className="btn btn_danger cst_btn" onClick={() => [addTimeSlot(), setTimeSlot([...timeSlots, { ...initialTime }])]}>Add</button>
                <div className="w-100" style={{ color: "rgb(221, 39, 38)", fontSize: "13px" }}>{props.areaErrorMessage}</div>
                {
                  timeSlots && timeSlots.map((time, index) => (
                    <div className="grid_view_block row" key={index}>
                      <div className="col-10">
                        <DateTimeField timeConstraints={{ minutes: { step: 5 } }} datePickerType='react-datetime' pickerType="timePicker" dataFormat="hh:mm A" className="form-control Input-modifier" id="areaName" name={`logbook[timeslots_attributes][${index}][start_time]`} />
                      </div>
                      <div className="col-2">
                        <i className="far fa-trash-alt mt-2" onClick={e => removeTimeSlot(index)}></i>
                      </div>
                    </div>
                  ))
                }
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <div className="checkbox_block radius_checkbox">
                      {
                        DAYS && DAYS.map((day, index) => <div className="w-25 mr-0 custom-control custom-checkbox custom-control-inline" onClick={() => props.resetDayError()}>
                          <Input type="checkbox" name={`logbook[days][${index}]`} className="custom-control-input" id={`customCheck${index}`} />
                          <label className="custom-control-label" htmlFor={`customCheck${index}`}>{day}</label>
                        </div>)
                      }
                    </div>
                  </div>
                  <div className="w-100" style={{ color: "rgb(221, 39, 38)", fontSize: "13px" }}>{props.areaDayErrorMessage}</div>
                  <div className="input-group cst_input_group">
                    <label className="form_title ">Start Date</label>
                    <div className="input-group cst_input_group">
                      <Input name="logbook[schedule_at]"
                        minDate={new Date()}
                        preventReadOnly={true}
                        dateFormat="MM-dd-yyyy" id="from_date" placeholder="Start Date" datePickerType={"react-datepicker"} type="date" className="form-control from-date-analytics" />
                      <div className="input-group-append cursor-pointer" style={{ float: "right", position: "absolute", right: 0, height: "36px", top: 0 }} onClick={e => document.querySelector(".from-date-analytics").click()}>
                        <span className="input-group-text" id="basic-addon2"><i className="fa fa-calendar" alt="Calendar Icon" ></i></span>
                      </div>
                    </div>
                  </div>
                  <div className="mt-3 checkbox_block radius_checkbox">
                    <div className="custom-control custom-checkbox custom-control-inline">
                      <Input type="checkbox" name="logbook[critical]" className="custom-control-input" id="logbook[critical]" />
                      <label className="custom-control-label" for="logbook[critical]">Critical Environments Logbook</label>
                    </div>
                  </div>
                  {isCriticalEnv ? (
                    <div className="form-group" include="form-Input-select()">
                      <label className="label_modifier"> Select PegAssure Playbook PDF </label>
                      <Input name="logbook[labelsId]" dataTest="select_leave_type" type="inputSelect" options={pdfList} placeholder="Please select" />
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="modal-footer">
                <button data-test="submit_button" type="submit" disabled={props.isLoading} className={props.isLoading ? "btn cst_btn btn_danger mr-2 btn-wait" : "btn btn_danger cst_btn"}>Update QR Code</button>
                <button type="button" className="btn btn_outline_secondary cst_btn" onClick={() => { props.history.push(`/accounts/${props.match.params.accountId}/account_users?job_logbook=true`); props.cancelClicked() }}>Cancel</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </main>

  )
};

const mapStateToProps = (state, props) => {
  let logbook;
  if (props.accountArea) {
    logbook = { ...props.accountArea };
    let exitingSlots = logbook && logbook.logbook_time_slot ? logbook.logbook_time_slot.map(time => {
      return { id: time.id.toString(), start_time: convertUTCtoTimezone(time.start_time_utc, logbook?.account?.timezone) }
    }) : '';
    let currentSlots = state.form.EditLogBookAreaForm && state.form.EditLogBookAreaForm.values.logbook ? state.form.EditLogBookAreaForm.values.logbook.timeslots_attributes : [];
    if (state.form.EditLogBookAreaForm && state.form.EditLogBookAreaForm.values.logbook) {
      logbook = state.form.EditLogBookAreaForm.values.logbook
      logbook['timeslots_attributes'] = currentSlots && currentSlots.length ? currentSlots : exitingSlots;
    }
    else {
      
      logbook['days'] = [0, 1, 2, 3, 4, 5, 6].map(day => logbook['days'].includes(day));
      logbook['floor_id'] = logbook.floor_id;
      logbook['area_type_id'] = logbook.area_type_id;
      logbook['account_id'] = logbook.account_id;
      logbook['schedule_at'] = moment(logbook.schedule_at, "YYYY-MM-DDTHH:mm:ss")._d;
      
      logbook["labelsId"]= logbook.labelsId ? logbook.labelsId : null
      
    }
    delete logbook.logbook_time_slot;
    delete logbook.id;
    //delete logbook.qr_code_urls;
    //delete logbook.account;
    //delete logbook.floor;
    //delete logbook.area_type;
  };

  return {
    initialValues: { logbook },
    logbookForm: state.form
  }
};

EditLogBookAreaForm = reduxForm({
  form: 'EditLogBookAreaForm',
  //validate,
  enableReinitialize: true,
})(EditLogBookAreaForm);

EditLogBookAreaForm = connect(mapStateToProps)(EditLogBookAreaForm);

export default EditLogBookAreaForm;