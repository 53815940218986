import React, { useCallback, useEffect, useState } from 'react'
import { DateTimeField } from '../../UI/DateTimeField/DateTimeField';
//index typeName stateRef
function RenderingDateField(props) {
   const [key,setKey]=useState(0)
   const [open,setOpen]=useState(false)
  //  const [value,setValue]=useState(props.value)
   //here we need to set the time constraints
   
  const {clickOutside,isSelect,close,...remainProps}=props
  
    useEffect(()=>{
        setKey((prev)=> prev +1)
    },[props])

    useEffect(()=>{
      if(clickOutside){
        setOpen(false)
      }
    },[clickOutside])


    const handleClick=useCallback((e)=>{
      e.stopPropagation();
      
      if( isSelect.current===null){
        
        isSelect.current=true
        setOpen((prev)=> !prev)
      }else if( isSelect){
        
        isSelect.current=null
        setOpen((prev)=> !prev)
        close()
        
      }
      
    },[setOpen])

    

  return (
    <DateTimeField key={key}  {...remainProps}  open={clickOutside? false : isSelect ==props.index ? false : open} onClick={handleClick}  />
  )
}

export default RenderingDateField
