import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import Aux from '../../../../hoc/Oux/Oux';

const renderField = ({
    input,
    label,
    type,
    disabled,
    placeholder,
    id,
    rows,
    meta: { touched, error, warning },
    className,
    onChange,
    dataTest
}) => {
    return (
        <Aux>
            <textarea data-test={dataTest} {...input} onChange={value => input.onChange(value)} disabled={disabled} type={type} id={id} className={className} placeholder={placeholder} rows={rows} style={{ textAlign: "left" }} />
            {touched &&
                ((error && <span style={{color: "rgb(221, 39, 38)", fontSize: "13px"}}>{error}</span>) ||
                    (warning && <span>{warning}</span>))}
        </Aux>
    );
}

export const TextAreaField = ({ name, disabled, onChange, label, id, rows, resizable, placeholder, className, dataTest }) => (
    <Field
        placeholder={placeholder}
        disabled={disabled}
        name={name}
        id={id}
        label={label}
        rows={rows}
        resizable={resizable}
        className={className}
        onChange={onChange}
        dataTest={dataTest}
        component={renderField}
    />
);

TextAreaField.propTypes = {
    type: PropTypes.string,
    name: PropTypes.string,
    onChange: PropTypes.func,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    rows: PropTypes.number,
    className: PropTypes.string,
    resizable: PropTypes.bool
};

TextAreaField.defaultProps = {
    type: 'text',
    label: null,
    placeholder: '',
    className: '',
    rows: 3,
    resizable: false
};