import React, { useEffect, useState } from 'react'
import { Input } from '../../../../UI/InputElement/reactFormHookInputField'
import withFormHoc from '../../../../../../hoc/withReactHookForm/withReactHookForm'
import { useFieldArray } from 'react-hook-form'
import NewDailyKittingSection from './NewDailyKittingSection'
import { useSelector } from 'react-redux'
import AssetsFormSkeleton from '../../../../Inventory/Assets/NewAssetsForm/AssetsFormSkeleton'

function NewDailyKIttingPage(props) {
  const { fields, append, remove } = useFieldArray({ control: props.control, name: "sections" })
  const { isLoading, isUpdateDailykitting } = useSelector((state) => state.InventoryModuleReducer)
  const { managersList, jobList } = useSelector((state) => state.FormAuditModReducer)
  const defaultTaskData = {

  }

  const ValidateHours = (value) => {
    if (+value >= 1 && +value <= 24) {
      return true
    }
    return "Hours must be between one to twenty four"
  }

  const addTaskToList = (e) => {
    e.preventDefault();
    append(defaultTaskData)
  }

  const removeFromTaskList = (index) => (e) => {
    e.preventDefault();
    remove(index)
  }
  const getJobList = (manager) => {
    if (jobList && jobList.length > 0) {
      return manager && manager.map((data) => {
        return {
          value: data.id,
          label: data.name,
        }
      })
    } else {
      return []
    }
  };

  const [listOfJob, setJobList] = useState(getJobList(jobList))

  useEffect(() => {
    setJobList(getJobList(jobList))
  }, [jobList])

  if (isUpdateDailykitting) {
    return <AssetsFormSkeleton />
  }

  return (
    <main class="app-content wraper_content chart_section">
      <section class="teammanage_formmain">
        <div class="container">
          <div class="row" >
            <div class="col-lg-12">
              <div class="team_manage_head">
                <h1 class="tm_top_head">Daily Kitting: Safety Log</h1>
              </div>
            </div>
            <form class="col-lg-12" noValidate onSubmit={props.handleSubmit((data) => {
              props.onSubmit(data)
            })}>
              <div class="tm_form_wrapper">
                <div class="tm_form_wrap">
                  <div class="tm_form_innner">
                    <div class="form">
                      <div class="row">
                        <div class="col-md-6 col-sm-12">
                          <div class="form-group mb-0">



                            <Input
                              type="inputSelect"
                              labelClasses="form-label"
                              control={props.control}
                              controller={props.controller}
                              selectLabel="Job"
                              placeholder={"Please select"}

                              selectName={`account_id`}
                              id={`account_id`}
                              selectOptions={listOfJob}
                              required={props.register(`account_id`, {
                                required: "This field is required"
                              })}
                              validationErrorMessage={props.errors?.account_id?.message}
                              selectClasses="form-select"
                              disabled={props?.isView || props.isEdit}
                            />

                          </div>
                        </div>


                        <div class="col-md-6 col-sm-12">
                          <div class="form-group mb-0">



                            <Input
                              type="text"
                              id={`location`}
                              name={`location`}
                              formControlClasses="form-control"
                              placeholder="Location"
                              required
                              label="Location"
                              formControlProps={props.register(`location`, {
                                required: "This field is required",
                                // pattern: {
                                //   value:/^[0-9]*$/ ,
                                //   message:"Must Be a number"
                                // },
                                maxLength: 80,
                              })}
                              validationErrorMessage={
                                props.errors?.location?.message ?? ""
                              }
                              disabled={props?.isView}
                            />

                          </div>
                        </div>

                        <div class="col-md-6 col-sm-12">
                          <div class="form-group">

                            <Input
                              type="number"
                              id={`shift_time`}
                              name={`shift_time`}
                              formControlClasses="form-control"
                              placeholder="Shift"
                              required
                              label="Shift"
                              formControlProps={props.register(`shift_time`, {
                                required: "This field is required",
                                validate: ValidateHours,
                                // pattern: {
                                //   value:/^[0-9]*$/ ,
                                //   message:"Must Be a number"
                                // },
                                maxLength: 80,
                              })}
                              validationErrorMessage={
                                props.errors?.shift_time?.message ?? ""
                              }
                              disabled={props?.isView}
                              isIcon={true}
                            />

                          </div>
                        </div>

                        <div class="col-md-6 col-sm-12">
                          <div class="form-group">


                            <Input
                              type="date"
                              //datePickerType="month"
                              formControlClasses="form-control"
                              id={`date`}
                              placeholder="MM/DD/YYYY"
                              control={props.control}
                              controller={props.controller}
                              selectName={`date`}
                              dateFormat="MM/dd/yyyy"
                              // minDate={new Date().setDate(current.getDate() - 400)}
                              preventReadOnly={false}
                              label="Date"
                              formControlProps={props.register(`date`, {
                                required: "This field is required",
                                //   validate:handleValidateDateCompleted
                              })}
                              validationErrorMessage={props.errors?.date?.message ?? ""}
                              disabled={props?.isView}
                            />


                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>

                {
                  fields?.map((field, index) => (
                    <NewDailyKittingSection setValue={props?.setValue} setFocus={props.setFocus} isView={props?.isView} key={index} controller={props.controller} field={field} control={props.control} index={index} register={props.register} errors={props.errors} removeFromTaskList={removeFromTaskList} getValues={props.getValues} />
                  ))
                }
                {
                  !props?.isEdit && !props?.isView ? <div class="action_button" >
                    <span onClick={addTaskToList}>+ Add More</span>
                  </div> : null
                }

                <div class="col-md-12 col-sm-12">
                  <div class="form-group">
                    <Input
                      type="textarea"
                      id={`comments`}
                      name={`comments`}
                      formControlClasses="form-control"
                      placeholder="Type here"
                      required
                      label="Comments"
                      formControlProps={props.register(`comments`, {
                        required: "This field is required",
                        maxLength: 80,
                      })}
                      validationErrorMessage={
                        props.errors?.comments?.message ?? ""
                      }
                      disabled={props?.isView}
                    />
                  </div>
                </div>

                <div class="row">
                  <div class="col-sm-12">
                    <div class="submitcancel_btn">
                      <button type="button" class="btn cst_btn btn-outline-secondary  cancel_btnnew" onClick={props?.resetForm}>
                        Cancel
                      </button>

                      {
                        !props?.isView ? <button type="submit" className={isLoading ? "btn cst_btn btn_danger btn-wait" : "btn cst_btn btn_danger"}>
                          {isLoading ? "" : props.isEdit ? "Update" : "Submit"}
                        </button> : null
                      }

                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div >
        </div>
      </section>
    </main>
  )
}

export default withFormHoc(NewDailyKIttingPage)


