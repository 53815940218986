import React from 'react';
import swal from 'sweetalert2'
import '../../utility/ScanningAlert/scanningSuccessAlert.css';

const ScanningSuccessAlert = (message) => {
    return(
        <div className='scanSuccessAlert'>
            {
                swal.fire({
                    type: 'success',
                    width: '740px',
                    height: '210px',
                    animation: true,
                    background: '#ececec',
                    // showCloseButton: true,
                    position: 'center',
                    icon: 'success',
                    title: message,
                    showConfirmButton: false,
                    timer: 3000,
                })
            }
        </div>
    )
}

export default ScanningSuccessAlert;