import React, { Component } from 'react';
import NewAccount from '../../../component/Accounts/NewAccount/newAccount';
import getDetailAddress from '../../../../utility/getDetailAddress';
import { routes, address_attributes, DEFAULT_QUOTA } from '../../../../utility/constants/constants';
import * as actions from '../../../../redux/actions/index';
import { connect } from 'react-redux';
import isEmpty from '../../../../utility/IsEmptyValidator';
import { getUser, resetOrientation } from '../../../../utility/utility';
import { decode, encode } from 'base64-arraybuffer';
import imageCompression from 'browser-image-compression';
import { Input } from '../../../component/UI/InputElement/InputElement';
import {
    Dialog,
    DialogContent,
    DialogActions,
    DialogTitle
} from '@material-ui/core';
import AccountDuplicateModal from '../../../component/Accounts/NewAccount/accountDuplicateModal';
import errorHandler from '../../../../utility/errorHandler/errorHandler';
const cloneDeep = require('clone-deep');

class NewAccountContainer extends Component {
    constructor(props) {
        super(props);
        let quotas = null;
        if (isEmpty(this.props.account)) {
            quotas = [DEFAULT_QUOTA];
        } else if (this.props.account && this.props.account.user.quotas.length < 1) {
            quotas = [DEFAULT_QUOTA];
        } else {
            quotas = this.props.account.user.quotas;
        }
        this.state = {
            profileImageName: '',
            base64: null,
            extension: null,
            address_attributes: isEmpty(this.props.account) ? address_attributes : this.props.account.user.address,
            quotas_attributes: quotas,
            isAddressEmpty: false,
            isZipEmpty: false,
            isStateEmpty: false,
            isCityEmpty: false,
            isCountryEmpty: false,
            mode: '',
            openModel: false,
            name: '',
            number: '',
            remove_company_account_id: [],
            // modeEvent: '',
            selectedHolidaysIds: this.props.account && this.props.account.user ? this.props.account.user.holidays.map(h => h.id) : [],
            // quotaCount: this.props.account && this.props.account.user ? this.props.account.user.quotas.length === 0 ? 1 : this.props.account.user.quotas.length : 1
        }
        this.inputOpenFileRef = React.createRef();
        this.choosePhotoClicked = this.choosePhotoClicked.bind(this);
        this.onFileChange = this.onFileChange.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleAddressSelect = this.handleAddressSelect.bind(this);
        // this.props.account['quotas_attributes'] = isEmpty(this.props.account) ? [DEFAULT_QUOTA] : this.props.account.user.quotas;
    }

    onCancelClicked = () => {
        this.props.history.push(routes.ACCOUNTS);
    }

    choosePhotoClicked = () => {
        this.inputOpenFileRef.current.click();
    }

    async onFileChange(e) {

        let reader = new FileReader();
        let file = e.target.files[0];

        const orientation = await imageCompression.getExifOrientation(file);
        reader.onloadend = () => {

            resetOrientation(reader.result, orientation, this);

            this.setState({
                extension: file.name.split('.').pop().toLowerCase(),
                profileImageName: file.name,
                // base64: reader.result,
            })
        }
        reader.readAsDataURL(file)
        // reader.readAsArrayBuffer(file)
    }

    handleAddressChange = address => {
        let addressAttributes = { ...this.state.address_attributes };
        addressAttributes.formatted_address = address;

        this.setState({ address_attributes: addressAttributes, isAddressEmpty: false });
    };

    async handleAddressSelect(address) {
        const addressFields = await getDetailAddress(address);
        this.setState({
            address_attributes: addressFields,
            isAddressEmpty: false
        })
    };

    handleAddressTouch = () => {
        if (this.state.address_attributes && this.state.address_attributes.formatted_address) {
            this.setState({ isAddressEmpty: false })
        } else {
            this.setState({ isAddressEmpty: true })
        }
    }

    handleInputChange = (e) => {
        console.log(this.state);
        if (e.target.name === 'zip') {
            let addressFields = cloneDeep(this.state.address_attributes);
            addressFields.zip = e.target.value;
            this.setState({
                address_attributes: addressFields,
                isZipEmpty: false
            })
        } else if (e.target.name === 'country') {
            let addressFields = cloneDeep(this.state.address_attributes);
            addressFields.country = e.target.value;
            this.setState({
                address_attributes: addressFields,
                isCountryEmpty: false
            })
        } else if (e.target.name === 'state') {
            let addressFields = cloneDeep(this.state.address_attributes);
            addressFields.state = e.target.value;
            this.setState({
                address_attributes: addressFields,
                isStateEmpty: false
            })
        } else if (e.target.name === 'city') {
            let addressFields = cloneDeep(this.state.address_attributes);
            addressFields.city = e.target.value;
            this.setState({
                address_attributes: addressFields,
                isCityEmpty: false
            })
        } else {
            this.setState({
                about_me: e.target.value,
            })
        }
    }

    modeChange = (event) => {
        this.setState({ mode: event });
    }

    addNewAccount = (values) => {
        let account = cloneDeep(values);

        account.account.quotas_attributes = account.account.quotas;
        // account.splice(account.quotas_attributes);
        let base_photo = null;
        let arrayBuffer = null;
        if (this.state.base64 !== null && this.state.base64 !== '') {
            const image = this.state.base64.split(',');
            base_photo = image[1];
            arrayBuffer = decode(base_photo);
            account.account['ext'] = `.${this.state.extension}`
        }
        // if (account['holiday_ids']) {
        //     account['holiday_ids'] = account['holiday_ids'].map((a, i) => {
        //         return a.value
        //     });
        // }
        account['holiday_ids'] = this.state.selectedHolidaysIds;
        if (this.state.address_attributes === '' || this.state.address_attributes === null || this.state.address_attributes === undefined) {
            this.setState({
                isAddressEmpty: true,
                // isZipEmpty: true,
                // isCountryEmpty: true
            })
            // return
        } else {
            this.setState({
                isAddressEmpty: false,
            })
        }
        if (this.state.address_attributes.city === '' || this.state.address_attributes.city === null || this.state.address_attributes.city === undefined) {
            this.setState({
                isCityEmpty: true,
            })
            // return
        } else {
            this.setState({
                isCityEmpty: false,
            })
        }
        if (this.state.address_attributes.state === '' || this.state.address_attributes.state === null || this.state.address_attributes.state === undefined) {
            this.setState({
                isStateEmpty: true,
            })
            // return
        } else {
            this.setState({
                isStateEmpty: false,
            })
        }
        if (this.state.address_attributes.country === '' || this.state.address_attributes.country === null || this.state.address_attributes.country === undefined) {
            this.setState({
                isCountryEmpty: true,
            })
            // return
        } else {
            this.setState({
                isCountryEmpty: false,
            })
        }
        if (this.state.address_attributes.zip === '' || this.state.address_attributes.zip === null || this.state.address_attributes.zip === undefined) {
            this.setState({
                isZipEmpty: true,
            })
            // return
        } else {
            this.setState({
                isZipEmpty: false,
            })
        }
        if (this.state.address_attributes.city !== '' && this.state.address_attributes.city !== null && this.state.address_attributes.city !== undefined && this.state.address_attributes.state !== '' && this.state.address_attributes.state !== null && this.state.address_attributes.state !== undefined && this.state.address_attributes.country !== '' && this.state.address_attributes.country !== null && this.state.address_attributes.country !== undefined) {
            if (this.state.address_attributes) {
                if (this.state.address_attributes.formatted_address !== '' || (this.state.address_attributes.zip !== '' && this.state.address_attributes.zip !== null && this.state.address_attributes.zip !== undefined)) {
                    if (this.state.address_attributes.formatted_address !== '') {
                        if (this.state.address_attributes.zip !== '' && this.state.address_attributes.zip !== null && this.state.address_attributes.zip !== undefined) {
                            if (this.state.address_attributes.latitude === '') {
                                delete this.state.address_attributes.latitude
                            }
                            if (this.state.address_attributes.longitude === '') {
                                delete this.state.address_attributes.longitude
                            }
                            if (account.account['company_account_id']) {
                                account.account['company_account_id'] = account.account['company_account_id'].map((a, i) => {
                                    return a.value
                                });
                            }

                            account.account['address_attributes'] = this.state.address_attributes;
                            account.account['mode'] = this.state.mode;
                            if (this.props.match.params.accountId) {
                                var i = 0;
                                var entry1;
                                if (this.props.account.user.companys) {
                                    if (account.account.company_account_id) {
                                        while (i < this.props.account.user.companys.length) {
                                            entry1 = this.props.account.user.companys[i];
                                            if (account.account.company_account_id.some(function (entry2) { return entry1.id === entry2; })) {
                                                ++i;
                                            } else {
                                                this.state.remove_company_account_id.push(this.props.account.user.companys[i].id)
                                                this.props.account.user.companys.splice(i, 1);
                                            }
                                        }
                                    }
                                }
                                if (account.account.company_account_id) {
                                    account.account['remove_company_account_id'] = this.state.remove_company_account_id;
                                } else {
                                    account.account['remove_company_account_id'] = this.props.account.user.companys.map((a, i) => {
                                        return a.id
                                    });
                                }
                                if (account.account.company_account_id === false) {
                                    delete account.account.company_account_id;
                                }
                                account.account['id'] = this.props.account.user.id;
                                // delete account.account['mode'];
                                this.props.updateAccount(account, arrayBuffer, this.state.extension);
                            } else {
                                //here we need to validate 

                                if (account?.account?.company_account_id === null) {
                                    delete account.account.company_account_id;
                                }

                                if (account?.account?.company_account_id !== undefined && !Array.isArray(account.account.company_account_id) && account.account.company_account_id.length == 0) {
                                    //throw error message
                                    errorHandler("Please fill the Customer Field")
                                    return 0
                                }

                                this.props.addNewAccount(account, arrayBuffer, this.state.extension);
                            }
                        } else {
                            this.setState({
                                isZipEmpty: true,
                                // isCountryEmpty: true,
                                isAddressEmpty: false
                            })
                        }
                    } else {
                        this.setState({
                            isAddressEmpty: true,
                            isZipEmpty: false,
                            // isCountryEmpty: false
                        })
                    }
                } else {
                    this.setState({
                        isAddressEmpty: true,
                        isZipEmpty: true,
                        // isCountryEmpty: true
                    })
                }

                // this.setState({
                //     profileImageName: '',
                //     extension: null,
                //     base64: null
                // })
            } else {
                this.setState({
                    isAddressEmpty: true,
                    isZipEmpty: true,
                    // isCountryEmpty: true
                })
            }
        }

    }
    createDublicateJobForm = (acc) => {
        let existingAccount = cloneDeep(this.props.account.user);
        delete existingAccount.address.id
        let account = {};
        account['account'] = {};
        account['account']['clone_account_id'] = existingAccount.id;
        account['account']['name'] = acc.name;
        account['account']['account_number'] = acc.number;
        account['account']['active_status'] = existingAccount.active_status;
        account['account']['address_attributes'] = existingAccount.address;
        account['account']['mode'] = existingAccount.mode;
        account['account']['region_id'] = existingAccount.region_id;
        account['account']['timezone'] = existingAccount.timezone;
        // account['account']['company_account_id'] = existingAccount.companys[0].id ? existingAccount.companys[0].id : null;
        debugger;
        if (existingAccount.companys && existingAccount.companys.length > 0) {
            account['account']['company_account_id'] = existingAccount.companys.map(c => c.id);
        }
        let arrayBuffer = null;

        if (account?.account?.company_account_id !== undefined && !Array.isArray(account.account.company_account_id) && account.account.company_account_id.length == 0) {
            //throw error message
            errorHandler("Please fill the Customer Field")
            return 0
        }

        this.props.addNewAccount(account, arrayBuffer, this.state.extension);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.account !== prevProps.account) {

            this.setState({
                address_attributes: this.props.account.user.address,
                quotas_attributes: this.props.account.user.quotas,
                profileImageName: '',
                base64: null,
                extension: null,
                selectedHolidaysIds: this.props.account && this.props.account.user ? this.props.account.user.holidays.map(h => h.id) : []
            })
        }
    }

    componentDidMount() {
        // if (!this.props.regionList) {
        this.props.getAllRegions({ hide: false });
        // }
        if (!this.props.holidaysList) {
            this.props.getHolidaysList();
        }
        if (isEmpty(this.props.account) && this.props.match.params.accountId) {
            this.props.getEditAccount(this.props.match.params.accountId);
        }
        if (!this.props.companyList) {
            this.props.getAllCompanyList();
        }
        const userMode = getUser()
        this.setState({ mode: userMode.mode })

    }

    componentWillUnmount() {
        this.props.resetObjects();
    }

    holidaySelected = (id) => {
        let selectedIds = cloneDeep(this.state.selectedHolidaysIds);

        let searchIndex = this.state.selectedHolidaysIds.findIndex(function (s) {
            return s === id
        });

        if (searchIndex === -1) {
            this.setState({
                selectedHolidaysIds: [...selectedIds, id]
            })
        } else {
            selectedIds.splice(searchIndex, 1);
            this.setState({
                selectedHolidaysIds: selectedIds
            })

        }
    }

    setCheckValue = (id) => {
        let searchIndex = this.state.selectedHolidaysIds.findIndex(function (s) {
            return s === id
        });
        if (searchIndex > -1) {
            return true
        } else {
            return false
        }
    }

    selectAllClicked = (e) => {
        if (e.target.checked) {
            let selectedIds = []
            this.props.holidaysList.map(h => {
                return selectedIds.push(h.id)
            })
            this.setState({
                selectedHolidaysIds: selectedIds,
                selectAll: true,
                selectNone: false,
            })
        } else {
            this.setState({
                selectedHolidaysIds: [],
                selectAll: false
            })
        }
    }

    selectNoneClicked = (e) => {
        if (e.target.checked) {
            this.setState({
                selectedHolidaysIds: [],
                selectNone: true,
                selectAll: false
            })
        } else {
            this.setState({
                selectNone: false
            })
        }
    }

    addMoreQuotaClicked = () => {
        let quotas_attributes = [...this.state.quotas_attributes, DEFAULT_QUOTA];
        this.setState({ quotas_attributes });
    }

    render() {
        return (
            <>
                <NewAccount
                    {...this.props}
                    {...this.state}
                    onSubmit={this.addNewAccount}
                    s3Url={isEmpty(this.props.account) ? '' : this.props.account.user.photo_urls.original}
                    isAddressEmpty={this.state.isAddressEmpty}
                    isZipEmpty={this.state.isZipEmpty}
                    isCityEmpty={this.state.isCityEmpty}
                    isStateEmpty={this.state.isStateEmpty}
                    isCounutryEmpty={this.state.isCounutryEmpty}
                    isCountryEmpty={this.state.isCountryEmpty}
                    isEdit={this.props.match.params.accountId ? true : false}
                    handleAddressChange={this.handleAddressChange}
                    handleInputChange={this.handleInputChange}
                    handleAddressSelect={this.handleAddressSelect}
                    handleAddressTouch={this.handleAddressTouch}
                    handleZipcodeTouch={this.handleZipcodeTouch}
                    address_attributes={this.state.address_attributes}
                    onCancelClicked={this.onCancelClicked}
                    reference={this.inputOpenFileRef}
                    choosePhotoClicked={this.choosePhotoClicked}
                    base64={this.state.base64}
                    regionList={this.props.regionList ? this.props.regionList : []}
                    onFileChange={this.onFileChange}
                    isLoading={this.props.isLoading}
                    setCheckValue={this.setCheckValue}
                    holidaySelected={this.holidaySelected}
                    selectAllClicked={this.selectAllClicked}
                    selectNoneClicked={this.selectNoneClicked}
                    addMoreQuotaClicked={this.addMoreQuotaClicked}
                    createDublicateJobForm={this.createDublicateJobForm}
                    openDuplicateModal={this.openDuplicateModal}
                    onAccountDuplicateModalClicked={this.props.onAccountDuplicateModalClicked}
                    modeChange={this.modeChange}
                    mode={this.state.mode}
                    companyList={this.props.companyList ? this.props.companyList : []}
                />
                {this.props.accountDuplicateModal ?
                    <AccountDuplicateModal
                        {...this.props}
                        onSubmit={this.createDublicateJobForm}
                    /> : null}
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        isLoading: state.adminOrSuperAdminReducer.isLoading,
        account: state.adminOrSuperAdminReducer.account,
        regionList: state.adminOrSuperAdminReducer.regionList,
        holidaysList: state.adminOrSuperAdminReducer.holidaysList,
        isHolidaysLoading: state.adminOrSuperAdminReducer.isHolidaysLoading,
        accountDuplicateModal: state.adminOrSuperAdminReducer.accountDuplicateModal,
        companyList: state.adminOrSuperAdminReducer.companyList
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getHolidaysList: () => dispatch(actions.getHolidaysList()),
        addNewAccount: (account, arrayBuffer, extension) => dispatch(actions.addNewAccount(account, arrayBuffer, extension)),
        resetObjects: () => dispatch(actions.resetObjects()),
        updateAccount: (account, arrayBuffer, extension) => dispatch(actions.updateAccount(account, arrayBuffer, extension)),
        getEditAccount: (id) => dispatch(actions.getEditAccount(id)),
        getAllRegions: (data) => dispatch(actions.getAllRegions(data)),
        onAccountDuplicateModalClicked: () => dispatch(actions.onAccountDuplicateModalClicked()),
        getAllCompanyList: () => dispatch(actions.getAllCompanyList())

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewAccountContainer);