import React, { Component } from 'react';
import { routes } from '../../../../utility/constants/constants';
import InventoryList from '../../../component/Inventory/InventoryListComponent/InventoryList';
import * as actions from '../../../../redux/actions/index';
import { connect } from 'react-redux';
import { reset } from 'redux-form';
import { animateScroll as scroll} from 'react-scroll'
import storage from '../../../../utility/storage';
import { convertDateToDifferentTZ } from '../../../../utility/utility';
import SubScheduledTask from '../../../component/TimeTrackerModuleComponent/SubScheduledTaskComponent/SubScheduledTask';
import { tzUTCDateConverterTool_V2 } from '../../../../utility/TimezoneOperations/timezoneUtility';

var moment = require('moment');
const cloneDeep = require('clone-deep');

class SubScheduledTaskContainer extends Component {

    constructor(props) {
        super(props);

        this.state = {
            
        }
    }

    componentDidMount() {
        scroll.scrollToTop();
    }

    componentDidUpdate(prevProps, prevState) {
        const user = storage.get('user');
        if(user) {
            if(prevProps.user.selected_mode !== user.selected_mode) {
                this.props.subScheduledTaskListPageClicked(0)
            }
        }
    }

    resetClicked = () => {
        const page = 1
        const limit = this.props.subScheduledTaskList_no_of_rows
        const scheduleTask = true
        this.props.reset('subSearchScheduledTask');
        this.props.subScheduledTaskListPageClicked(0)
        this.props.getSubScheduledTask({page,limit,scheduleTask},{}, this.props.match.params.id)

    }

    searchInventoryItemsListClicked = (value) => {
        const values = cloneDeep(value)
        let accountId =  null;
        let taskName = null;
        let companyName = null;
        let accountName = null;
        let from = null;
        let to = null;

        const scheduleTask = true
        const page = 1
        const limit = this.props.scheduledTaskList_no_of_rows
        if(values.id) {
            values.id = values.id.trim()
            accountId = values.id
        }
      if(values.name) {
          values.name = values.name.trim()
          taskName = values.name
      }
      if(values.company) {
        values.company = values.company.trim()
        companyName = values.company
      }
      if(values.job) {
        values.job = values.job.trim()
        accountName =  values.job 
        }  
      if(values.fromDate) {
        from = tzUTCDateConverterTool_V2(values.fromDate, 'from');
      }  
      if(values.toDate) {
        to = tzUTCDateConverterTool_V2(values.toDate, 'to');
      }  
     
        this.props.getSubScheduledTask({page,limit, accountId,taskName,companyName,accountName,from,to,scheduleTask}, values, this.props.match.params.id);
    }

    addInventoryItemClicked = () => {
        this.props.history.push(routes.NEW_INVENTORY_ITEM);
    }

    deleteInventoryItem = (id) => {
        this.props.deleteInventoryItem(id);
    }

    handleInventoryOpenDeleteModal = (value) => {
        this.props.handleInventoryOpenDeleteModal(value);
    }

    handleInventoryCloseDeleteModal = () => {
        this.props.handleInventoryCloseDeleteModal();
    }
         
    render() {
        return (
            <SubScheduledTask
                {...this.props}
                isLoading={this.props.isLoading}
                onSubmit={this.searchInventoryItemsListClicked}
                resetClicked={this.resetClicked}
                subScheduledTaskList={this.props.subScheduledTaskList ? this.props.subScheduledTaskList : []}
                addInventoryItemClicked={this.addInventoryItemClicked}
                deleteInventoryItem={this.deleteInventoryItem}
                handleInventoryOpenDeleteModal={this.handleInventoryOpenDeleteModal}
                handleInventoryCloseDeleteModal={this.handleInventoryCloseDeleteModal}
                
            />
        )
    }
}


const mapStateToProps = (state) => {
    return {
        user: state.authReducer.user,
        isLoading: state.TimeTrackerModReducer.isLoading,
        subScheduledTaskList: state.TimeTrackerModReducer.subScheduledTaskList,
        isSubScheduledTaskList: state.TimeTrackerModReducer.isSubScheduledTaskList,
        subScheduledTaskList_no_of_rows: state.TimeTrackerModReducer.subScheduledTaskList_no_of_rows,
        subScheduledTaskListCurPage: state.TimeTrackerModReducer.subScheduledTaskListCurPage,
        openInventoryDeleteModal: state.TimeTrackerModReducer.openInventoryDeleteModal,
        deleteInventoryObject: state.TimeTrackerModReducer.deleteInventoryObject,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        reset: (searchInventoryItemsList) => dispatch(reset(searchInventoryItemsList)),  // requires form name
        subScheduledTaskListPageClicked: (page) => dispatch(actions.subScheduledTaskListPageClicked(page)),
        getSubScheduledTask: (params, values, id) => dispatch(actions.getSubScheduledTask(params, values, id)),
        deleteInventoryItem: (id) => dispatch(actions.deleteInventoryItem(id)),
        handleInventoryOpenDeleteModal: (value) => dispatch(actions.handleInventoryOpenDeleteModal(value)),
        handleInventoryCloseDeleteModal: () => dispatch(actions.handleInventoryCloseDeleteModal()),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(SubScheduledTaskContainer);