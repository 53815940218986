import React from 'react';
import { Input } from '../UI/InputElement/InputElement';
import { reduxForm } from 'redux-form';
import { region as validate } from '../../../utility/validator/validator';
import { connect } from 'react-redux';
import isEmpty from '../../../utility/IsEmptyValidator';

let NewRegion = (props) => {
    var radios = document.querySelectorAll('input[type="radio"]');
for (var i=0; i<radios.length; i++) {
    radios[i].onfocus = function() {
        this.blur();
    }
}
    const { handleSubmit, pristine, reset, submitting, error } = props;
    return (
        <div className="modal fade request_quote_modal reject_quot associateUser" id="addRegion" tabindex="-1" role="dialog" aria-labelledby="rejectQuoteModalTitle" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <label for="exampleInputEmail1" className="cst-label md_title">{props.isEdit ? 'Update Region' : 'New Region'}</label>
                        <button type="button" className="close close_icn_pic" data-dismiss="modal" onClick={props.closePopup}><img src="/images/icons/icn_close_gray.png" alt="Close Icon" /></button>
                    </div>

                    <div className="modal-body">
                        <div className="tab-content">
                            <div id="profile" className="profile_tab cst_tab_content tab-pane fade show active edit_profile_modal">
                                <form className="form_content" onSubmit={handleSubmit}>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label className="label_modifier">Region</label>
                                                <Input type="text" className="form-control input-modifier" placeholder="" name="region[name]" />
                                            </div>
                                            <div className="form-group">
                                                <Input type="radio" labelClass="custom-control-label" forLable={"customRadio1"} value={"false"} label={"Active"} className="custom-control-input" id="customRadio1" name="region[hide]" />
                                                <Input type="radio" labelClass="custom-control-label" forLable={"customRadio2"} value={"true"} label={"In-active"} className="custom-control-input" id="customRadio2" name="region[hide]" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="clearfix"></div>
                                    <div className="modal-footer">
                                        <button type="submit" disabled={props.isLoading} className={props.isLoading ? "btn cst_btn btn_danger mr-2 btn-wait" : "btn cst_btn btn_danger mr-2"} >{props.isLoading ? "" : "Save"}</button>
                                        <button onClick={props.closePopup} type="button" className="btn btn-outline-secondary cst_btn" data-dismiss="modal">Close</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

const mapStateToProps = (state) => {
    let region = {}
    if (isEmpty(state.adminOrSuperAdminReducer.region)) {
        region = {
            hide: "false",
        }
    } else {
        region = {
             name: state.adminOrSuperAdminReducer.region ? state.adminOrSuperAdminReducer.region.region.name : '',
             hide: state.adminOrSuperAdminReducer.region && state.adminOrSuperAdminReducer.region.region.hide ? 'true' : 'false',
        }
    }
        
    return { initialValues: {region} }
}

NewRegion = reduxForm({
    form: 'regionForm',
    validate
})(NewRegion);

NewRegion = connect(mapStateToProps)(NewRegion)

export default NewRegion;