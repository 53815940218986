import React, {useState} from 'react';

import {
    Dialog,
    DialogActions,
    DialogTitle,
    DialogContent,
    makeStyles,
    Button,
    IconButton,
    Icon
} from '@material-ui/core';
import { CSVLink, CSVDownload } from "react-csv";

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      padding: theme.spacing(5),
    },
    textAlign: "center"
  },
  input: {
    display: 'none',
  },
}));

const TicketImportCSV = (props) => {
  const classes = useStyles();
  const [open ,setOpen] = useState(false);
  const [importCsv, setCsv] = useState(null);

  const handleImport = () => {
    var formData = new FormData();
    formData.append('file', importCsv);
    props.importTicketCSV(formData);
  };
 const  handleClose= () => {
   setCsv(null);
   setOpen(false);
  };
  const handleChange = (e) => {
    setCsv(e.target.files[0])
  };
  return (
      <>
        <button type="button" className="csv_btn btn_danger" onClick={() => setOpen(true)} data-backdrop="static" data-keyboard="false" data-toggle="modal" data-target="#associateUser" data-test="import_csv">Import CSV</button>
  
      <Dialog
          open={open}
          onClose={handleClose}
          maxWidth={false}
          aria-labelledby="form-dialog-title"
          >
        <h4 id="form-dialog-title"  className="p-2" style={{textAlign: "left", fontSize: '13', color: '#ab385e' }}>
          Import Tickets
        </h4>
        <DialogContent className="pl-5 pr-5">
          <div className={classes.root}>
            <input
                accept=".csv"
                className={classes.input}
                id="contained-button-file"
                multiple
                type="file"
                onChange={handleChange}
                />

            <input accept="image/*" className={classes.input} id="icon-button-file" type="file" />
            <label htmlFor="contained-button-file">
              <Button variant="contained" component="span">
                UPLOAD CSV
              </Button>
            </label>
          </div>
          <h6 className="text-center">{importCsv && importCsv.name}</h6>
          <div style={{display: 'none'}} >
            <CSVLink id="ticketLogsErrorImport" filename="Area-ErrorLogs.csv" className="csv_btn btn_danger" data={props.ticketImportErrorLogs && props.ticketImportErrorLogs.length > 0 ? props.ticketImportErrorLogs : ''} >Export CSV</CSVLink>
          </div>
        </DialogContent>
        <DialogActions>
          <button style={{ minHeight: '36px', minWidth: '80px' }} onClick={() => {handleImport()}} disabled={props.isLoading} className={`${importCsv ? "" : "disabled pointer-none"} ${props.isLoading ? "btn cst_btn btn_danger mr-2 btn-wait" : "btn cst_btn btn_danger mr-2"}`} id="delete" type="delete">{props.isLoading ? "" : "Import"}</button>
          <button id='closeTicketImportPopup' style={{ minHeight: '36px', minWidth: '80px' }} onClick={() => handleClose()} className={"btn cst_btn btn-outline-secondary"} type="cancel">Cancel</button>
        </DialogActions>
      </Dialog>
      </>
  )
}


export default TicketImportCSV;