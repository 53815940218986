import React, { Component } from 'react';
import MealPenaltyGenerateForm from '../../../component/TimeTrackerModuleComponent/MealPenaltyGenerateComponent/MealPenaltyGenerateForm';
import { connect } from 'react-redux';
import * as actions from '../../../../redux/actions/index';
import { routes } from '../../../../utility/constants/constants';
import { convertTo24HourFormat, dateFormatConversion_V1, extractExtention, mealPenaltyConvertTo24HourFormat } from '../../../../utility/utility';
import { decode, encode } from 'base64-arraybuffer';
import errorHandler from '../../../../utility/errorHandler/errorHandler';
import { tzUTCDateTimeConverterTool_V1 } from '../../../../utility/TimezoneOperations/timezoneUtility';
const cloneDeep = require('clone-deep');
var momentTZ = require('moment-timezone');

class MealPenaltyGenerateContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isTriggerLoader: false,
            empSignBase64: [],
        }

    }

    componentDidMount() {

        this.setState({ isTriggerLoader: true });
        setTimeout(() => {
            this.setState({ isTriggerLoader: false });
        }, 2500);

        if (this?.props?.match?.params?.shiftDetailId) {
            this.props.getSingleShifSchedule(this.props.match.params.shiftDetailId);
            let fetchedCurrDateTime = momentTZ(new Date()).format('MM-DD-YYYYTHH:mm:ss').split('T');
            let checkOutTime = tzUTCDateTimeConverterTool_V1(fetchedCurrDateTime[0], fetchedCurrDateTime[1], 'none');
            let isMealBreakModeGenerated = true;
            this.props.getMissedMealPenaltyBreaks(this.props.match.params.shiftDetailId, checkOutTime, this.props.singleShiftScheduleData, isMealBreakModeGenerated);
        }

    }

    componentWillUnmount() {
        this.props.resetTimeTrackerObjects();
    }

    handleSubmit = (values) => {debugger

        if (!(values?.mealPenalty?.missedReason?.length > 0)) {
            errorHandler('Missed reason is required')
            return false;
        }

        let setData = { data: {} };
        let fetchedValue = cloneDeep(values.mealPenalty);

        setData.data['date_error'] = tzUTCDateTimeConverterTool_V1(dateFormatConversion_V1(fetchedValue.errorDate).split('T')[0], dateFormatConversion_V1(fetchedValue.errorDate).split('T')[1], 'none');
        setData.data['clockout_agreement'] = fetchedValue.clkAgreement;

        let fetchedCurrDateTime = momentTZ(new Date()).format('MM-DD-YYYYTHH:mm:ss').split('T');
        let empSignDate = tzUTCDateTimeConverterTool_V1(fetchedCurrDateTime[0], fetchedCurrDateTime[1], 'none');
        setData.data['employee_signed_date'] = empSignDate;

        /* Functionalities for corrections Array */
        setData.data['corrections'] = Object.entries(fetchedValue).reduce((acc, [key, value]) => {
            if (key == 'breakIn') {
                value.forEach((breakObj, index) => {debugger
                    acc.push({
                        entity_id: breakObj?.id,
                        entity_type: "Break",
                        start_time: breakObj.mealIn ? tzUTCDateTimeConverterTool_V1(dateFormatConversion_V1(fetchedValue.errorDate).split('T')[0], mealPenaltyConvertTo24HourFormat(breakObj.mealIn), 'none') : null,
                        end_time: fetchedValue.breakOut[index].mealOut ? tzUTCDateTimeConverterTool_V1(dateFormatConversion_V1(fetchedValue.errorDate).split('T')[0], mealPenaltyConvertTo24HourFormat(fetchedValue.breakOut[index].mealOut), 'none') : null,
                        reason: fetchedValue.missedReason
                    });
                });
            }
            return acc;
        }, []);

        localStorage.setItem('missedMealPenaltyForm', JSON.stringify(setData?.data));
        this.props.history.replace(`/clkIn-clkOut/${this.props.match.params.shiftDetailId}/feedback/${this?.props?.singleShiftScheduleData?.shift_id}`);

    }

    render() {
        return (
            <>

                <MealPenaltyGenerateForm
                    {...this.props}
                    isTriggerLoader={this.state.isTriggerLoader}
                    onSubmit={this.handleSubmit}
                />

            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.authReducer.user,
        isLoading: state.TimeTrackerModReducer.isLoading,
        singleShiftScheduleData: state.TimeTrackerModReducer.singleShiftScheduleData,
        mealPenaltyBreaksInfo: state.TimeTrackerModReducer.mealPenaltyBreaksInfo,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        resetTimeTrackerObjects: () => dispatch(actions.resetTimeTrackerObjects()),

        getSingleShifSchedule: (id) => dispatch(actions.getSingleShifSchedule(id)),
        getMissedMealPenaltyBreaks: (shiftDetailId, currentTime, clockInData, isMealBreakModeGenerated) => dispatch(actions.getMissedMealPenaltyBreaks(shiftDetailId, currentTime, clockInData, isMealBreakModeGenerated)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MealPenaltyGenerateContainer);