import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../../../redux/actions/index';
import LogBookLogsArea from '../../../../component/Accounts/LogBook Area/PreviewLogbookArea/LogBookLogsArea';
import PreviewLogbookLogComponent from '../../../../component/Accounts/LogBook Area/PreviewLogbookArea/previewLogbookLogs';


class PreviewLogBookLogsArea extends Component  {

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.getOneLogBookLogsArea(this.props.match.params.logbookId,this.props.match.params.logId);
  }


  componentWillUnmount() {
    //this.props.resetObjects();
  }

  render() {

    return (
      <PreviewLogbookLogComponent
        {...this.props}
        {...this.state}
      />
    )
  }
}

const mapStateToProps = (state) => {
  return {
    logbookLog: state.adminOrSuperAdminReducer.logbookLog,
    isLogbookLogsAreas: state.adminOrSuperAdminReducer.isLogbookLogsAreas,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    getOneLogBookLogsArea: (logbookId,logId) => dispatch(actions.getOneLogBookLogsArea({logbookId,logId})),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PreviewLogBookLogsArea);