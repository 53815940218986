import React, { useState } from 'react';
import AccountsTable from './accountsTable';
import { Input } from '../UI/InputElement/InputElement';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { AccountsSearchValidator as validate } from '../../../utility/validator/validator';
import { reset } from 'redux-form';
import Dialog from '@material-ui/core/Dialog';
import { convertAccountsDataToCsvType, convertAccountsGenericUrlDataToCsvType, convertDateTimezonePopupEvents, makeFirstLetterCapital } from '../../../utility/utility';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import { CSVLink, CSVDownload } from "react-csv";
import { userRoles } from '../../../utility/constants/constants';
import { ClapSpinner } from 'react-spinners-kit';
import moment from 'moment';

let Accounts = (props) => {
    // const [activeJobs, setActiveJobs] = useState(true);
    const { handleSubmit, pristine, reset, submitting, error } = props;
    // const activeJobsClicked = (e) => {
    //     setActiveJobs(e.target.checked);
    // }
    let todayDate = moment(new Date()).subtract(0, "days").format("MM-DD-YYYY");
    let tomorrowDate = moment(new Date()).subtract(-1, "days").format("MM-DD-YYYY");
    let totalOccupiedEvents = []
    let todaysEventData = []
    let tomorrowsEventData = []
    let eventsHeader = ''

    if (props.scheduleWithAccountId.length > 0) {
        props.scheduleWithAccountId.map((preview) => {
            const eventDate = moment(preview.start_time).format('MM-DD-YYYY')
          
            if (eventDate === todayDate){
                return (
                    todaysEventData =[ ...todaysEventData, 
                    <div>
                        <div className="custom_title">
                            <ul style={{color: '#000000c7'}}><li>
                                <span style={{color: "#a80046eb"}}>{makeFirstLetterCapital(preview.schedule.title)},</span>&nbsp;
                                <span style={{color: '#4d4d4dd6'}}>{preview.schedule.day},</span>&nbsp;
                                <span style={{color: '#4d4d4dd6'}}>{convertDateTimezonePopupEvents(preview.start_time).replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '')}</span>
                            </li></ul>
                        </div>
                    </div> ]
                )
            } else if(eventDate ===  tomorrowDate){
                return (
                    tomorrowsEventData = [ ...tomorrowsEventData, 
                    <div>
                        <div className="custom_title">
                            <ul style={{color: '#000000c7'}}><li>
                                <span style={{color: "#a80046eb"}}>{makeFirstLetterCapital(preview.schedule.title)},</span>&nbsp;
                                <span style={{color: '#4d4d4dd6'}}>{preview.schedule.day},</span>&nbsp;
                                <span style={{color: '#4d4d4dd6'}}>{convertDateTimezonePopupEvents(preview.start_time).replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '')}</span>
                            </li></ul>
                        </div>
                    </div> ]                                  
                )
            }
        })
    }

    if(todaysEventData.length && tomorrowsEventData.length) {
        eventsHeader = 'Today and Tomorrow Events'
    } else if(!(todaysEventData.length) && tomorrowsEventData.length) {
        eventsHeader = "Tomorrow's Event"
    } else if(todaysEventData.length && !(tomorrowsEventData.length)) {
        eventsHeader = "Today's Event"
    } else {
        eventsHeader = 'noEvents'
    }
    eventsHeader = eventsHeader == 'noEvents' ? <div className="py-5 px-5 font-weight-bold" style={{fontSize: "20px", color: "#000000"}}>Today and Tomorrow No Events Found</div> : <div className="custom_title mb-2" style={{color: '#000000c7', fontSize: 'large'}}>{eventsHeader}</div>
    totalOccupiedEvents = [eventsHeader, ...todaysEventData, ...tomorrowsEventData]
    console.log('naInspectioon', totalOccupiedEvents)

    return (
        <>
            <main className="app-content wraper_content inspection_wraper">
                <div className="tab_header search_filter_cont inspection_tab_content job_filter">
                    <form onSubmit={handleSubmit}>
                        <input type="hidden" value="something" />
                        <div className="row">
                            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                                <div className="app-search">
                                    <Input name="name" dataTest="creaters_filter" className="fil_search_input" type="search" placeholder="Search By Job" />
                                    <button className="app-search__button">
                                        <i class="fa fa-search" aria-hidden="true"></i>
                                    </button>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                                <div className="app-search">
                                    <Input dataTest="job_id_filter" name="accountNumber" className="fil_search_input" type="search" placeholder="Search By Job#" />
                                    <button className="app-search__button">
                                        <i class="fa fa-search" aria-hidden="true"></i>
                                    </button>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
                                <button data-test="users_go_btn" style={{ minWidth: '90px' }} className={"btn cst_btn btn_danger mr-2  account_btn"} id="submit" type="submit">{"Go"} </button>
                                <button data-test="users_reset_btn" style={{ minWidth: '90px' }} onClick={props.resetClicked} className={"btn cst_btn btn-outline-secondary account_btn"} id="reset" type="reset">{"Reset"} </button>
                            </div>
                            {props.user.role === userRoles.ADMIN || props.user.role === userRoles.INSPECTOR ? null :
                                <div className="col-xl-2 col-lg-2 col-md-6 col-sm-6 col-12 text-right quotes_btn_wrap">
                                    <a onClick={props.newAccountClicked} href="javascript:void (0)" className="btn btn-secondary cst_btn" data-test="new_job_account"><i className="fa fa-plus icn_plus"></i>New Job</a>
                                </div>
                            }
                        </div>
                    </form>
                </div>


                <section className="account_sec inspection_tab_content appcontent_Inner accounts_user_btn theme_col_px">
                {
                    props.user.role === userRoles.INSPECTOR ? null : 
                    <div className="theme_switch_btn" style={{display: "inline"}}>
                        <span className="switch_title">In-active</span>
                        <label>                                
                            <input type="checkbox" checked={props.activeJobs} onClick={($event) => props.activeJobsClicked($event)}/>
                            <span></span>
                            {/* <span className="ext_title d-none">Ext<span className="mob_txt_hide">ernal</span></span> */}
                        </label>
                        <span className="ext_title">Active</span>
                    </div>
                }
                
                    {/* <div>
                        <button style={{ float: 'right' }}>Enable Filter</button>
                    </div> */}
                    {props.accountsList && props.accountsList.length === 0 ? null :
                        <>
                          <div class="dropdown text-right" style={{float: 'right'}}>
                          {
                            props.user.role === userRoles.INSPECTOR ? null : 
                            <button type="button" class="btn_danger dropbtn btn cst_btn mr-1 mb-1 min-width-btn">Export</button>
                          }
                                    <div class="dropdown-content">
                                        <a href="#" style={{display: 'grid'}}>
                                        <button className="csv_btn btn_danger" onClick={() => props.onAccountExportClicked()} >Export CSV</button>
                                        </a>
                                        <a href="#" style={{display: 'none'}} >
                                        <CSVLink id="accountExport" filename="Accounts.csv" className="csv_btn btn_danger" data={props.accountExportDataList && props.accountExportDataList.length > 0 ? convertAccountsDataToCsvType(props.accountExportDataList) : ''} >Export CSV</CSVLink>
                                        </a>
                                        <a href="#" style={{display: 'grid'}}>
                                        <CSVLink filename="Accounts.csv" className="csv_btn btn_danger mr-2" data={convertAccountsGenericUrlDataToCsvType(props.accountsList)} >Export URL</CSVLink> 
                                         </a>
                                    </div>
                                </div>
                        <br /> <br /> <br /></>}
                    <div className="quotes_table_content accounts_table_contnet table-responsive">
                        <div className="table quotes_table user_react_table">
                            <AccountsTable
                                {...props}
                                deleteAccount={props.deleteAccount}
                                onAccountsPageSizeChange={props.onAccountsPageSizeChange}
                                accountsList={props.accountsList ? props.accountsList : []}
                                isLoading={props.isLoading}
                                previewAccountClicked={props.previewAccountClicked}
                                editAccountClicked={props.editAccountClicked}
                                openDeleteModel={props.openDeleteModel}
                                handleClickDeleteModel={props.handleClickDeleteModel}
                                accounts_rows={props.accounts_rows}
                                activeJobs={props.activeJobs}
                                handleScheduleClickDeleteModel={props.handleScheduleClickDeleteModel}
                                openScheduleConfirmDeleteModel={props.openScheduleConfirmDeleteModel}
                                scheduleWithAccountId={props.scheduleWithAccountId}
                                schedulePreviewAccountClicked={props.schedulePreviewAccountClicked}
                            />
                        </div>

                    </div>
                    {/* <div className="clearfix"></div>
                <div className="modal-footer">
                    <button type="button" className="btn btn_danger cst_btn">Save</button>
                    <button type="button" className="btn btn-outline-secondary cst_btn" data-dismiss="modal">Cancel</button>
                </div> */}
                </section>
            </main>
            <Dialog
                open={props.openDeleteModel}
                onClose={props.handleClose}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title" style={{ fontSize: '13', color: '#06080f' }}>
                    <h3 style={{ color: '#ab385e', textAlign: 'center' }} data-test="job_delete_title">This action cannot be undone.</h3>
                    <h3 style={{ color: '#ab385e', textAlign: 'center' }}>Are you sure you want to delete this?</h3>
                </DialogTitle>

                <DialogActions>
                    <button data-test="delete_account" style={{ minHeight: '36px', minWidth: '80px' }} onClick={() => props.deleteAccount(props.deleteObject.user.id)} disabled={props.isLoading} className={props.isLoading ? "btn cst_btn btn_danger mr-2 btn-wait" : "btn cst_btn btn_danger mr-2"} id="delete" type="delete">{props.isLoading ? "" : "Delete"}</button>
                    <button style={{ minHeight: '36px', minWidth: '80px' }} onClick={props.handleDeleteClose} className={"btn cst_btn btn-outline-secondary"} id="cancel" type="cancel">Cancel</button>
                    {/* <Button onClick={props.handleClose} variant="contained" color="primary">
                        Cancel
                        </Button>


                    <Button onClick={() => props.deleteAccount(props.deleteObject.id)} disabled={props.isLoading} variant="contained" color="secondary">
                        {props.isLoading ? <CircularProgress size={24} /> : 'Delete'}
                    </Button> */}
                </DialogActions>
            </Dialog>

            <Dialog
                open={props.openScheduleConfirmDeleteModel}
                onClose={props.handleClose}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title" style={{ fontSize: '13', color: '#06080f' }}>
                    {props.isLoading ?
                        <div className="loader_btn_block" style={{ width: "300px", height: "300px" }}>
                            <ClapSpinner backColor="#89073c" size={40} frontColor="#89073c8a" />
                        </div> :
                        <>
                            <div className="preview_schedule">
                                <div className="custom_article">
                                    <button type="button" className="close" data-dismiss="modal" onClick={props.handleDeleteClose}><img src="/images/icons/icn_close_gray.png" alt="Close Icon" /></button>
                                </div>
                                <div className="form-group">{totalOccupiedEvents}</div>
                                <button style={{ minWidth: '100%' }} onClick={props.handleDeleteClose} className={"btn cst_btn btn_danger mt-4 mb-4 mx-auto"} id="cancel" type="cancel">Close</button>
                            </div>
                        </>
                 
                    }
                </DialogTitle>
            </Dialog>
        </>


    )
}

const mapStateToProps = (state) => {
    let accountNumber = state.adminOrSuperAdminReducer.searchaccountValues ? state.adminOrSuperAdminReducer.searchaccountValues.accountNumber : "";
    let name = state.adminOrSuperAdminReducer.searchaccountValues ? state.adminOrSuperAdminReducer.searchaccountValues.name : "";

    return { initialValues: { accountNumber, name } }
}

Accounts = reduxForm({
    form: 'searchAccountForm',
    validate,
    enableReinitialize: true
})(Accounts);

Accounts = connect(mapStateToProps)(Accounts)

export default Accounts;