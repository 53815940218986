import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as actions from '../../../../../redux/actions/index';
import SignatureCanvas from 'react-signature-canvas'

const SignaturePad = (props) => {
    const [trimmedDataURL, setTrimmedDataURL] = useState(null);
    const [isSignatureEmpty, setIsSignatureEmpty] = useState(true);
    const [submitSignatureLoading, setSubmitSignatureLoading] = useState(false);
    const signatureData = useSelector(state => state.adminOrSuperAdminReducer.signatureData);
    const dispatch = useDispatch();

    let sigPad = {};
    let clear = () => {
        setIsSignatureEmpty(true);
        sigPad.clear();
    };
    let trim = () => {
        setSubmitSignatureLoading(true);
        console.log(sigPad.getTrimmedCanvas().toDataURL("image/png"));
        setTrimmedDataURL({
            trimmedDataURL: sigPad.getTrimmedCanvas().toDataURL("image/png")
        });

        props.onSignatureAdded(signatureData.secIndex, signatureData.lineIndex, sigPad.getTrimmedCanvas().toDataURL("image/png"));
    };
    let beginDrag = () => {
        setIsSignatureEmpty(false);
    }

    return (
        <div id="addnew_signature" className="modal fade add_account_modal edit_profile_modal addnew_account_popup user_newQuote signature_modal">
            <div className="modal-dialog  modal-dialog-centered">

                <div className="modal-content">
                    <div className="modal-header">
                        <button type="button" className="close" data-dismiss="modal"><i className="fa fa-times icn_plus"></i></button>
                        <h4 className="modal-title">Add a Signature</h4>
                    </div>
                    <div className="modal-body">
                        <div className="inspection_content_sec appcontent_Inner">
                            <SignatureCanvas penColor='black' backgroundColor='white'
                                onBegin={beginDrag}
                                ref={ref => {
                                    sigPad = ref;
                                }}
                                canvasProps={{ width: 400, height: 200, className: 'quote_form' }} />
                            <div className="form-group btn_block mb-0">
                                <button disabled={isSignatureEmpty || submitSignatureLoading} onClick={trim} className={submitSignatureLoading ? "btn cst_btn btn_danger mr-2 btn-wait" : "btn cst_btn btn_danger mr-2"}>{submitSignatureLoading ? "" : "Submit"}</button>
                                <button type="button" onClick={clear} className="btn cst_btn mr-2 btn-outline-secondary">Clear</button>
                                <button data-dismiss="modal" type="button" className="btn cst_btn btn-outline-secondary" onClick={() => dispatch(actions.closePopup())}>Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default SignaturePad;