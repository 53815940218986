import React, { useState, useEffect } from 'react';
import { reduxForm } from 'redux-form';
import "react-datepicker/dist/react-datepicker.css";
import { priorityOptions} from '../../../../utility/constants/constants';
import { Input } from '../../UI/InputElement/InputElement';
import { newPCTValidator as validate } from '../../../../utility/validator/validator';
import { connect } from 'react-redux';
import ImageLoader from 'react-imageloader';
import ModalImageGallery from '../../ModalImageGallery/modalImageGallery';
import * as Scroll from 'react-scroll';
import PCTFormSkeleton from './PCTFormSkeleton';

var scroller = Scroll.scroller;

const getAccountsDropDown = (props) => {

    return props.PCTjobsList && props.PCTjobsList.map(account => {
        return {
            value: account.id,
            label: account.name
        }
    })
}

let NewPCTComponent = (props) => {

    const base64Clicked = (i, urlLength) => {
        setIsImageGallery(true);
        setStartIndex((urlLength + i));
    }

    const photoUrlClicked = (i) => {

        setIsImageGallery(true);
        setStartIndex(i);
    }

    const [accounts, setAccounts] = useState(getAccountsDropDown(props));

    useEffect(() => {
        setAccounts(getAccountsDropDown(props))
    }, [props.PCTjobsList])


    const noAccountOptionsMessage = (a, b) => {
        return 'Job not found';
    }

    function preloader() {
        return <img style={{ width: '100%', height: '100%' }} src="/images/gif/giphy.gif" />;
    }

    const [isImageGallery, setIsImageGallery] = useState(false);
    const [startIndex, setStartIndex] = useState(0);
    const { handleSubmit, pristine, reset, submitting, error } = props;
    if (isImageGallery) {
        return <ModalImageGallery
            isImageGallery={isImageGallery}
            setIsImageGallery={setIsImageGallery}
            startIndex={startIndex}
            base64={props.portfolioImages}
            imageUrls={props.savedPortfolioURls}
        />
    }
    //  if (props.isPCTLoading) {
    //     return <PCTFormSkeleton />
    // } else {
    return (
        <main className="wraper_content user_newQuote">
            <div className="inspection_content_sec appcontent_Inner">
                <div className="container-fluid">
                    <div className="pct_form ticket_form">
                        <h2 className="md_title" data-test="create_ticket_header">{'Submit a Ticket'}</h2>
                        <div className="addQuote_form" >
                            <form onSubmit={handleSubmit}>
                                <div className='row'>
                                    <div className="form-group col-md-6">
                                        <label name="ticket[account_id]" className="form_title">Job <sup className="cst_sup_txt text_danger">*</sup></label>
                                        <Input instanceId="job_select" name="ticket[account_id]"  noOptionsMessage={noAccountOptionsMessage} type="inputSelect" options={accounts} />
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label className="form_title">Subject <sup className="cst_sup_txt text_danger">*</sup></label>
                                        <Input dataTest="ticket_subject" name="ticket[subject]" type="text" className="form-control input-modifier" placeholder="subject" />
                                    </div>
                                </div>
                                <div className='row'>
                                <div className="form-group col-md-6">
                                    <label className="form_title">Priority <sup className="cst_sup_txt text_danger">*</sup></label>
                                    <Input dataTest="ticket_priority" name="ticket[priority]" className="custom-select input-modifier" type="select" options={priorityOptions} />
                                </div>
                                    <div className="form-group col-md-6">
                                        <label className="form_title">Due on <sup className="cst_sup_txt text_danger">*</sup></label>

                                        <div className="input-group cst_input_group">
                                            <Input name="ticket[due_on]"
                                                dataTest="ticket_time_picker"
                                                minDate={new Date()}
                                                preventReadOnly={true}
                                                dateFormat="MM-dd-yyyy" id="from_date" placeholder="Due Date" datePickerType={"react-datepicker"} type="date" className="form-control from-date-analytics" />
                                            <div className="input-group-append cursor-pointer" style={{ float: "right", position: "absolute", right: 0, height: "36px", top: 0 }} onClick={e => document.querySelector(".from-date-analytics").click()}>
                                                <span className="input-group-text" id="basic-addon2"><i className="fa fa-calendar" alt="Calendar Icon" ></i></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label for="email" className="form_title">
                                                First Name <sup className="cst_sup_txt text_danger">*</sup>
                                            </label>
                                            <Input
                                                dataTest="first_name_data"
                                                type="text"
                                                className="form-control input-modifier"
                                                id="email"
                                                placeholder=""
                                                name="ticket[first_name]"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="form_title">Last Name <sup className="cst_sup_txt text_danger">*</sup></label>
                                            <Input
                                                dataTest="last_name_data"
                                                type="text"
                                                className="form-control input-modifier"
                                                placeholder=""
                                                name="ticket[last_name]"
                                            />
                                        </div>
                                    </div>
                                    </div>
                                    <div className='row'>
                                        <div className="form-group col-md-6">
                                            <label for="email" className="form_title">
                                                Email <sup className="cst_sup_txt text_danger">*</sup>
                                            </label>
                                            <Input
                                                dataTest="email_data"
                                                type="text"
                                                name="ticket[email]"
                                                className="form-control input-modifier"
                                                placeholder=""
                                            />
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label className="form_title">Message <sup className="cst_sup_txt text_danger">*</sup></label>
                                        <Input type="textarea" name="ticket[message]" className="form-control textarea_modifier" rows="6" placeholder="Detailed Message" id="comment" />
                                    </div>
                                
                                </div>
                                <div className="file_attachment">
                                    <Input type="file" name="attachment_paths" hidden={true} multiple={true} onSelect={props.onPortfolioFileChange} fileRef={props.reference} accept="image/*" />
                                    <a href="javascript:void(0)" onClick={props.choosePhotoClicked} className="file_title"><img src="/images/icons/attachment_icon.png" alt="Icon" /> Attachments</a>
                                    <p className="size_dec">Up to 10 atachments (10MB each).</p>

                                    <div class="upload_file">
                                        <div class="row">
                                            {props.savedPortfolioURls.map((url, i) => {
                                                return (
                                                    <div class="col-2 col-sm-2 cst_gutter" key={i}>
                                                        <div class="file_view" onClick={() => photoUrlClicked(i)}>
                                                            <ImageLoader
                                                                style={{ width: '100%', height: '100%', cursor: 'pointer' }}
                                                                src={url.photo_urls.small}
                                                                wrapper={React.createFactory('div')}
                                                                preloader={preloader}>
                                                                Image load failed!
                                                            </ImageLoader>
                                                        </div>
                                                        <div class="cancel_icon">
                                                            <img src="/images/icons/icn_delete_white.png" onClick={() => props.removeUrls(url, i)} alt="Image" />
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                            {props.portfolioImages.map((url, i) => {
                                                return (
                                                    <div class="col-2 col-sm-2 cst_gutter" key={i} >
                                                        <div class="file_view">
                                                            <img src={url} alt="Image" onClick={() => base64Clicked(i, props.savedPortfolioURls.length)} />
                                                            <div class="cancel_icon">
                                                                <img src="/images/icons/icn_delete_white.png" onClick={() => props.removeImage(url, i)} alt="Image" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }

                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group btn_block mb-0">
                                    <button data-test="submit_button" type="submit" className={props.isLoading ? "btn cst_btn btn_danger btn-wait" : "btn cst_btn btn_danger"}>{props.isLoading ? "" : "Submit"}</button>
                                    <button data-test="cancel_button" type="button" onClick={() => props.onCancelClicked()} className="btn cst_btn btn-outline-secondary  cancel_btnnew">Cancel</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            {/* </div> */}
        </main>
    )

    // }
}

const mapStateToProps = (state) => {
    let ticket = {};
    ticket ={
        account_id : '',
        subject : '',
        priority : '',
        due_on : '',
        first_name : '',
        last_name : '',
        email : '',
        message : '',
    }
    return { initialValues: { ticket } }

}

NewPCTComponent = reduxForm({
    form: 'newPCTForm',
    validate,
    enableReinitialize: true,
    onSubmitFail: (errors) => scrollToFirstError(errors),
})(NewPCTComponent);

NewPCTComponent = connect(mapStateToProps)(NewPCTComponent)

export const scrollToFirstError = (errors) => {

    let scroolToError = null;
    if (errors) {
        if (errors.ticket.account_id) {
            scroolToError = `ticket[account_id]`;
        } else if (errors.ticket.first_name) {
            scroolToError = `ticket[first_name]`;
        } else if (errors.ticket.subject) {
            scroolToError = `ticket[subject]`;
        } else if (errors.ticket.message) {
            scroolToError = `ticket[message]`;
        } else if (errors.ticket.last_name) {
            scroolToError = `ticket[last_name]`;
        } else if (errors.ticket.due_on) {
            scroolToError = `ticket[due_on]`;
        } else if (errors.ticket.priority) {
            scroolToError = `ticket[priority]`;
        }else if (errors.ticket.email) {
            scroolToError = `ticket[email]`;
        }
    }
    //  
    scroller.scrollTo(`${scroolToError}`, {
        duration: 500,
        delay: 1,
        isDynamic: true,
        smooth: true,
        // containerId: 'ContainerElementID',
        offset: -100,
    })

}

export default NewPCTComponent;