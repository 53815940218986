import React, { useEffect, useState } from 'react';
import ReactTable from 'react-table-v6';
import '../../../styles/custom.css';
import { CustomNoDataComponent, getUser, makeFirstLetterCapital } from '../../../utility/utility';
import { FaArrowDown, FaArrowUp } from "react-icons/fa";
import ReactTooltip from 'react-tooltip';
import { useSelector, useDispatch } from 'react-redux';
import * as actions from '../../../redux/actions/index';
import { MagicSpinner, PushSpinner, SwishSpinner, WaveSpinner, ClapSpinner } from 'react-spinners-kit';
import 'react-table-v6/react-table.css';
import ImageLoader from 'react-imageloader';
import { userRoles } from '../../../utility/constants/constants';

const PageJump = (onChange, value, onBlur, onKeyPress, inputType, pageJumpText) => {
    //  
}

const AccountsTable = (props) => {
    //   
    const dispatch = useDispatch();
    const SearchValue = useSelector(state => state.form.searchAccountForm.values);
    const SearchOrResetAccountClicked = useSelector(state => state.adminOrSuperAdminReducer.SearchOrResetAccountClicked);
    const DateGenerate = useSelector(state => state.adminOrSuperAdminReducer.dataGenerated);
    const isAccountLoading = useSelector(state => state.adminOrSuperAdminReducer.isAccountLoading);
    const No_of_pages = useSelector(state => state.adminOrSuperAdminReducer.account_page);
    const No_of_rows = useSelector(state => state.adminOrSuperAdminReducer.accounts_rows);
    const accountPage = useSelector(state => state.adminOrSuperAdminReducer.accountCurPage);
    const job_rows = useSelector(state => state.adminOrSuperAdminReducer.job_rows);

    function preloader() {
        return <img style={{ height: '35px', width: '35px', borderRadius: '50px', float: 'left' }} src="/images/gif/giphy.gif" />;
    }

    const data = props.accountsList.map(account => {

        return {
            image:
                account.user && account.user.photo_urls.small ?
                    <ImageLoader
                        imgProps={{ style: { height: '35px', width: '35px', borderRadius: '50px', float: 'left' } }}
                        style={{ height: '35px', width: '35px', borderRadius: '50px', float: 'left' }}
                        src={account.user.photo_urls.small}
                        wrapper={React.createFactory('div')}
                        preloader={preloader}>
                        Image load failed!
                                    </ImageLoader>
                    :
                    account.photo_urls && account.photo_urls.small ?
                        <ImageLoader
                            imgProps={{ style: { height: '35px', width: '35px', borderRadius: '50px', float: 'left' } }}
                            style={{ height: '35px', width: '35px', borderRadius: '50px', float: 'left' }}
                            src={account.photo_urls.small}
                            wrapper={React.createFactory('div')}
                            preloader={preloader}>
                            Image load failed!
                                    </ImageLoader>
                        :
                        <img src="/customImages/user.png" style={{ height: '35px', width: '35px', borderRadius: '50px', float: 'left' }} />
            ,
            account: account.user ? makeFirstLetterCapital(account.user.name) : makeFirstLetterCapital(account.name),
            accountNumber: 
            <>
            {
            props.user.role === userRoles.INSPECTOR ? null :
            <>
            {
                account.user ? account.user.account_number : account.account_number
            }
            </>
           }
            </>,
            // contactName: account.user.first_name + ' ' + account.user.last_name,
            // // lastName: account.user.last_name,
            // phoneNumber: account.user.phone_number,
            region: 
            <>
            {
            props.user.role === userRoles.INSPECTOR ? null :
            <>
            {
                account.user && account.user.region ? account.user.region.name : account.region ? account.region.name : ''
            }
            </>
           }
            </>,
            // dateAndTime: '22 Jun 2019 , 1:00 PM',
            // rol: 'DM',
            // 
            // email: account.user.email,
            address:
            <>
            {
            props.user.role === userRoles.INSPECTOR ? null :
            <>
            {
                account.user && account.user.address ? account.user.address.formatted_address : account.address ? account.address.formatted_address : ''
            }
            </>
             }
            </>,
            actions: <>
            {
                props.user.role === userRoles.INSPECTOR ? null :
                <span data-tip="View\Associate Users" className="mr_20 account_edit_icn" onClick={() => props.previewAccountClicked(account)} data-test="account_view"><i className="fas fa-eye cst_icon mr-2" aria-hidden="true"></i></span>
            }
                <ReactTooltip place="left" type="dark" effect="solid" />
                {props.user.role === userRoles.ADMIN || props.user.role === userRoles.INSPECTOR ? null :
                    <>
                        <span data-tip="Edit" className="mr_20 account_edit_icn" onClick={() => props.editAccountClicked(account)} data-test="account_edits"><i class="fa fa-pencil" aria-hidden="true"></i></span>
                        <span data-tip="Delete" className="mr_20 account_edit_icn" onClick={() => props.handleClickDeleteModel(account)} data-test="account_trash"><i class="fa fa-trash" aria-hidden="true"></i></span>
                    </>
                }
                {
                    props.user.role === userRoles.INSPECTOR ? 
                    <span data-tip="View-Schedules" className="mr_20 account_edit_icn" onClick={() => props.schedulePreviewAccountClicked(account)} data-test="account_view"><i className="fas fa-eye cst_icon mr-2" aria-hidden="true"></i></span>
                    : null
                }
                <span data-tip="Show Calendar as a tool tip"  className="mr_20 account_edit_icn" onClick={() => props.handleScheduleClickDeleteModel(account)} data-test="account_trash"><i class="fa fa-calendar" aria-hidden="true"></i></span>
            </>
        }
    })

    const columns = [
        {
            Header: '',
            accessor: 'image', // String-based value accessors!,
            width: 40,
            filterable: false,
        }, {
            Header: <><span>Job Name</span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /></>,
            accessor: 'account',
            width: 200,
            show: props.accountsList.length !== 0,
            Filter: ({ filter, onChange }) => (
                <input
                    onChange={event => onChange(event.target.value)}
                    value={filter ? filter.value : ''}
                    placeholder={'Search Account...'}
                    className={'form-control input-modifier'}
                />
            ), // String-based value accessors!
        }, {
            Header: 
            <>
            {
                props.user.role === userRoles.INSPECTOR ? null :
                <>
                <><span>Job #</span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /></>
                </>
            }
            </>,
            accessor: 'accountNumber',
            show: props.accountsList.length !== 0,
            width: 100,
            Filter: ({ filter, onChange }) => (
                <input
                    onChange={event => onChange(event.target.value)}
                    value={filter ? filter.value : ''}
                    placeholder={'Search Account Number...'}
                    className={'form-control input-modifier'}
                />
            ), // String-based value accessors!
        },
        // {
        //     Header: <><span>Contact Name</span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
        //     accessor: 'contactName',
        //     show: props.accountsList.length !== 0,
        //     Filter: ({ filter, onChange }) => (
        //         <input
        //             onChange={event => onChange(event.target.value)}
        //             value={filter ? filter.value : ''}
        //             placeholder={'Search Name...'}
        //             className={'form-control input-modifier'}
        //         />
        //     ), // String-based value accessors!
        // },
        // //  {
        // //     Header: <><span>Last Name</span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
        // //     accessor: 'lastName',
        // //     // Cell: props => <span className='number'>{props.value}</span> // Custom cell components!
        // // },
        // {
        //     Header: <><span>Phone Number</span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
        //     accessor: 'phoneNumber',
        //     show: props.accountsList.length !== 0,
        //     Filter: ({ filter, onChange }) => (
        //         <input
        //             onChange={event => onChange(event.target.value)}
        //             value={filter ? filter.value : ''}
        //             placeholder={'Search Number...'}
        //             className={'form-control input-modifier'}
        //         />
        //     ),
        //     // Cell: props => <span className='number'>{props.value}</span> // Custom cell components!
        // },
        //  {
        //     // id: 'friendName', // Required because our accessor is not a string
        //     Header: <span>Date & Time</span>,
        //     accessor: 'dateAndTime' // Custom value accessors!
        // },
        {
            Header: 
            <>
            {
                props.user.role === userRoles.INSPECTOR ? null :
                <>
                <><span>Region</span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>
                 </>
            }
            </>,
            accessor: 'region',
            width: 135,
            show: props.accountsList.length !== 0
            // Cell: props => <span className='number'>{props.value}</span> // Custom cell components!
        },
        // {
        //     Header: <><span>Email</span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
        //     accessor: 'email',
        //     width: 220,
        //     show: props.accountsList.length !== 0,
        //     Filter: ({ filter, onChange }) => (
        //         <input
        //             onChange={event => onChange(event.target.value)}
        //             value={filter ? filter.value : ''}
        //             placeholder={'Search Email...'}
        //             className={'form-control input-modifier'}
        //         />
        //     ),
        //     // show: false
        // },
        {
            Header: 
            <>
            {
                props.user.role === userRoles.INSPECTOR ? null :
                <>
                <><span>Address</span>  <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>
                </>
            }
            </>,
            accessor: 'address',
            // width: 200,
            show: props.accountsList.length !== 0,
            Filter: ({ filter, onChange }) => (
                <input
                    onChange={event => onChange(event.target.value)}
                    value={filter ? filter.value : ''}
                    placeholder={'Search Address...'}
                    className={'form-control input-modifier'}
                />
            ),
            // show: false
        },
        {
            Header: '',
            accessor: 'actions',
            width: 135,
            filterable: false,
            // show: false
        }
    ]

    useEffect(() => {
        if (SearchOrResetAccountClicked) {
            dispatch(actions.resetSearchOrResetValue())
        } else {
            const account_no_of_rows = 10;
            setTablePageFn(0)
            const page = 1;
            const sorted = [];
            const filtered = [];
            SearchValue.active = props.activeJobs
            if (props.user.role === userRoles.SUPER_ADMIN) {
                props.getAccounts(
                    SearchValue,
                    account_no_of_rows, page,
                    sorted,
                    filtered);
            } else {
                props.getUserAccounts(
                    SearchValue,
                    account_no_of_rows, page,
                    sorted,
                    filtered,
                    props.user.id);
            }
        }
      }, [props.activeJobs]);

      const [tablePage, setTablePage] = useState(0)
      const tablePageNew =  React.useRef()

      if(accountPage !== tablePageNew.current) {
        tablePageNew.current = accountPage;
    }
      const setTablePageFn = (page) => {
          setTablePage(page)
          props.accountPageClicked(page)
          tablePageNew.current = page;
      }

    const fetchData = (state, instance) => {

        if (SearchOrResetAccountClicked) {
            dispatch(actions.resetSearchOrResetValue())
        } else {
                if (!DateGenerate && !isAccountLoading) {
                state.page = 2;
                const account_no_of_rows = state.pageSize;
                let page = tablePageNew.current >= 0 ? tablePageNew.current + 1 : accountPage + 1;
                if(job_rows != account_no_of_rows) {
                    page = 1;
                    setTablePageFn(0)
                }
                const sorted = state.sorted;
                const filtered = state.filtered;
                SearchValue.active = props.activeJobs
                if (props.user.role === userRoles.SUPER_ADMIN) {
                    props.getAccounts(
                        SearchValue,
                        account_no_of_rows, page,
                        sorted,
                        filtered);
                } else {
                    props.getUserAccounts(
                        SearchValue,
                        account_no_of_rows, page,
                        sorted,
                        filtered,
                        props.user.id);
                }
            }
        }
        // }
        // }
    }

    return (
        SearchOrResetAccountClicked && isAccountLoading ?
            <div className="loader_btn_block">
                <ClapSpinner backColor="#89073c" size={40} frontColor="#89073c8a" />
            </div>
            :
            <ReactTable
                data={data}
                loading={isAccountLoading}
                defaultPageSize={No_of_rows}
                minRows={0}
                manual
                onFetchData={fetchData}
                pages={No_of_pages}
                gotoPage={accountPage}
                page = { accountPage }
                onPageChange={(page) => {setTablePageFn(page)}}
                showPaginationTop={true}
                // onPageSizeChange={props.onAccountsPageSizeChange}
                NoDataComponent={() => CustomNoDataComponent(isAccountLoading, 'No Jobs Found')}
                showPagination={props.accountsList.length !== 0}
                // filterable={true}
                LoadingComponent={() =>
                    isAccountLoading ?
                        <div style={{
                            display: "block",
                            position: "absolute",
                            left: 0,
                            height: '100%',
                            right: 0,
                            background: "rgba(255,255,255,0.8)",
                            transition: "all .3s ease",
                            top: 0,
                            bottom: 0,
                            textAlign: "center"
                        }}
                            className="loader_btn_block">
                            <ClapSpinner backColor="#89073c" size={40} frontColor="#89073c8a" />
                        </div> : null}
                columns={columns}
                // onPageChange={(pageIndex) => PageJump(pageIndex)}
                // onPageSizeChange={(pageSize, pageIndex) => PageJump(pageSize, pageIndex)}
                // renderPageJump={(onChange, value, onBlur, onKeyPress, inputType, pageJumpText) => PageJump(onChange, value, onBlur, onKeyPress, inputType, pageJumpText)}
                // renderCurrentPage = {(page) => PageJump(page)}
                // style={{marginTop: '10px'}}
                getTheadThProps={(state, rowInfo, column, instance) => {

                    return {
                        style: {
                            borderTop: 'none',
                            borderBottom: 'none',
                            borderRight: 'none',
                            textAlign: 'center',
                            color: '#566975',
                            fontSize: '14px',
                            fontWeight: '600',
                            whiteSpace: 'nowrap',
                            float: 'left',
                        }
                    }
                }
                }
                getTrProps={(state, rowInfo, column, instance) => {
                    // onclick = () => userDetail(rowInfo ? rowInfo.row.user : rowInfo);

                    return {
                        className: 'react-table-tr-element',
                        style: {
                            border: '1px solid #c7cdd1',
                            transition: '.3s ease-in-out',
                            width: '100%',
                            // borderRadius: '4px',
                            // height: '80px',
                            marginTop: '5px',
                            float: 'left'
                        }
                    }

                }}
                getTdProps={(state, rowInfo, column, instance) => {
                    // if (column.Header == 'Active' || column.Header == 'Actions') {
                    if (column.id == 'image') {
                        return {
                            style: {
                                marginTop: '0px'
                            }
                        }
                    } else {
                        return {
                            className: 'react-td-element'
                        }
                    }
                    //  {
                    //   style: {
                    //     borderTop: 'none',
                    //     verticalAlign: 'middle',
                    //     fontSize: '14px',
                    //     color: '#2b3034',
                    //     marginTop: '10px !important',
                    //     textAlign: 'center',
                    //   }
                    // }
                }
                }
                // getPaginationProps={() => {
                //     return {
                //         style: {
                //             marginTop: '50px',
                //             border: 'none',
                //             boxShadow: 'none'
                //         }
                //     }
                // }
                // }
                getProps={() => {
                    return {
                        style: {
                            border: 'none'
                        }
                    }
                }}
            />
    )
}

export default AccountsTable;
