import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { ClapSpinner } from "react-spinners-kit";
import { Input } from "../../UI/InputElement/InputElement";
import { reduxForm } from "redux-form";
import { inspectionDuplicateFormValidator as validate } from "../../../../utility/validator/validator";
import { connect } from "react-redux";

let InspectionDuplicateModal = (props) => {
  const { handleSubmit, pristine, reset, submitting, error } = props;
  const closeBtnClicked = () => {
      reset()
  }
  return (
    <div
      className="modal fade performance_modal associateUser InspectionDuplicateModal"
      id="InspectionDuplicateModal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-dialog-centered modal-sm"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-header">
            <h2 className="md_title" id="exampleModalLongTitle">
            Copy Inspection Form
            </h2>
            <button
              type="button"
              onClick={closeBtnClicked}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">
                <img
                  src="/images/icons/icn_cancel_white.png"
                  alt="Icon"
                />
              </span>
            </button>
          </div>
          <div className="modal-body">
          <form onSubmit={handleSubmit}>
            <div className="row">
                <div className="col-md-12">
                    <div className="form-group">
                        <label className="label_modifier">Inspection Form Name</label>
                        <Input className="form-control input-modifier" id='duplicate_inspection_name' name='inspection_form_name' />
                    </div>
                </div>
            </div>
            <div style={{textAlign: 'center'}}>
            {/* <button style={{ minHeight: '36px', minWidth: '80px' }} className="btn cst_btn btn_danger mr-2" type='submit' >Submit</button> */}
            <button type="submit" disabled={props.isLoading} className={props.isLoading ? "btn cst_btn btn_danger mr-2 btn-wait" : "btn cst_btn btn_danger mr-2"} >{props.isLoading ? "" : "Submit"}</button>
            <button style={{ minHeight: '36px', minWidth: '80px' }} data-dismiss="modal" onClick={closeBtnClicked} aria-label="Close" className={"btn cst_btn btn-outline-secondary"} id='close_duplicate_inspection_button' type="cancel">Cancel</button>
            </div>
          </form>
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  let name = "";

  return { initialValues: { name } }
}
InspectionDuplicateModal =  reduxForm({
  form: "inspectionDuplicateModal",
  validate,
  enableReinitialize: true
})(InspectionDuplicateModal);


InspectionDuplicateModal = connect(mapStateToProps)(InspectionDuplicateModal)

export default InspectionDuplicateModal;