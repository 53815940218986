import React, { useEffect } from "react";
import NewRestRoomPageContainer from "../../../../../component/FormAuditModule/LogBook/NewRestroomPortering/NewRestroomPortering/RestRoomPage";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";
//import { getManagersList } from "../../../../../../redux/actions";
import { getSingleRestRoomDetails, addRestroomDetails,editRestroomDetails } from "../../../../../../redux/actions/FormAuditModAction/LogBookAction/LogbookAction";
import { getManagersList,getUsersList } from "../../../../../../redux/actions/FormAuditModAction/TeamManagementAction/teamManagementAction";
import moment from "moment";
import { convertDateTimezoneFormAudit, convertTimeStringFormAudit, convertTimeStringToUTCFormAudit, ConvertToFormat, tzUTCDateConverterTool_FormAudit } from "../../../../../../utility/TimezoneOperations/timezoneUtility";

function NewRestroomPorteringContainer() {
  const {
    location: { pathname },
  } = useHistory();
  const { id } = useParams();
  const dispatch = useDispatch();
  const isEdit=Boolean(pathname.split("/")[2] !== "view" && id)
  const isView=Boolean(pathname.split("/")[2].includes("view"))
  const {restroomDetails}=useSelector((state)=>state.LogBookReducer)
  

  const formatSectionData = (item) => {
    return [
      {
        supervisor_id: item.supervisor_id,
        account_id: item.account_id,
        cleaning_workers: item.cleaning_workers,
        team_checklist_completed: item.team_checklist_completed,
        completed: item.completed,
      },
    ];
  };


  const MomentFormatToHour = (date, format = "HH:mm:ss") => {
    date=date.format(format)
    return convertTimeStringToUTCFormAudit(date,format)
  }

  const MomentStringToHour=(str,format="HH:mm:ss")=>{
    let time=convertTimeStringFormAudit(str,format)
    return time
  }


  function hoursToTimeString(hour) {

    // Use moment.js to format the time
    const formattedTime = moment({
      hour: hour,
      minute: 0,
      second: 0,
    }).format("HH:mm:ss");

    return formattedTime;
  }

  const structureDefaultForm=(section)=>{
     const lineItems=section.lineItems.map((items)=>{
      const {restroom_portering_section_id,active_status,updated_at,created_at,...remainItem}=items
      return{
        ...remainItem
      }
     })
      
     return{
      ...section,
      in_time:MomentStringToHour(section.in_time),
      out_time:MomentStringToHour(section.out_time),
      lineItems:lineItems,
     }
  }

  const convertDate = (date) => {
    if (date) {
      var parts = date.split('T');
      var datePart = parts[0];
      let dt = moment(datePart)._d
      return dt
    }
  }








  const structureShifts=(shift)=>{
    
    return shift.map((item)=>{
      let {break_1_time,break_2_time,lunch_time,off_time,on_time,employee,...remainItem}=item
      return{
        break_1_time:MomentStringToHour(break_1_time),
        break_2_time:MomentStringToHour(break_2_time),
        lunch_time:MomentStringToHour(lunch_time),
        off_time:MomentStringToHour(off_time),
        on_time:MomentStringToHour(on_time),
        ...remainItem
      }
    })
  }




  const defaultForm = {
    section:isEdit || isView ? restroomDetails?.section ? [{...structureDefaultForm(restroomDetails?.section),...restroomDetails.comments}] : [{
      in_time: "",
      out_time: "",
      restroom_condition: "",
      next_restroom: "",
      initial: "",
      lineItems: [
        {
          "name": "Removed debris",
          "sort": 1,
          "is_checked": false
        },
        {
          "name": "Mopped residual liquids",
          "sort": 2,
          "is_checked": false
        },
        {
          "name": "Mopped floor (show)",
          "sort": 3,
          "is_checked": false
        },
        {
          "name": "Cleaned sinks / counters",
          "sort": 4,
          "is_checked": false
        },
        {
          "name": "Wiped urinals",
          "sort": 5,
          "is_checked": false
        },
        {
          "name": "Wiped toilets",
          "sort": 6,
          "is_checked": false
        },
        {
          "name": "Stocked-seat covers",
          "sort": 7,
          "is_checked": false
        },
        {
          "name": "Stocked-soap",
          "sort": 8,
          "is_checked": false
        }
        ,
        {
          "name": "Stocked-toilets tissue",
          "sort": 9,
          "is_checked": false
        },
        {
          "name": "Stocked-towels",
          "sort": 10,
          "is_checked": false
        },

        {
          "name": "Wiped Stainless Steel",
          "sort": 11,
          "is_checked": false
        },
        {
          "name": "Cleaned baby chan. table",
          "sort": 12,
          "is_checked": false
        },
        {
          "name": "Cleaned mirror(s)",
          "sort": 13,
          "is_checked": false
        },
        {
          "name": "Deodorized",
          "sort": 14,
          "is_checked": false
        },
        {
          "name": "Pushed down trash",
          "sort": 15,
          "is_checked": false
        },
        {
          "name": "Dumped trash",
          "sort": 16,
          "is_checked": false
        }
      ]
    }] : [{
      in_time: "",
      out_time: "",
      restroom_condition: "",
      next_restroom: "",
      initial: "",
      lineItems: [
        {
          "name": "Removed debris",
          "sort": 1,
          "is_checked": false
        },
        {
          "name": "Mopped residual liquids",
          "sort": 2,
          "is_checked": false
        },
        {
          "name": "Mopped floor (show)",
          "sort": 3,
          "is_checked": false
        },
        {
          "name": "Cleaned sinks / counters",
          "sort": 4,
          "is_checked": false
        },
        {
          "name": "Wiped urinals",
          "sort": 5,
          "is_checked": false
        },
        {
          "name": "Wiped toilets",
          "sort": 6,
          "is_checked": false
        },
        {
          "name": "Stocked-seat covers",
          "sort": 7,
          "is_checked": false
        },
        {
          "name": "Stocked-soap",
          "sort": 8,
          "is_checked": false
        }
        ,
        {
          "name": "Stocked-toilets tissue",
          "sort": 9,
          "is_checked": false
        },
        {
          "name": "Stocked-towels",
          "sort": 10,
          "is_checked": false
        },

        {
          "name": "Wiped Stainless Steel",
          "sort": 11,
          "is_checked": false
        },
        {
          "name": "Cleaned baby chan. table",
          "sort": 12,
          "is_checked": false
        },
        {
          "name": "Cleaned mirror(s)",
          "sort": 13,
          "is_checked": false
        },
        {
          "name": "Deodorized",
          "sort": 14,
          "is_checked": false
        },
        {
          "name": "Pushed down trash",
          "sort": 15,
          "is_checked": false
        },
        {
          "name": "Dumped trash",
          "sort": 16,
          "is_checked": false
        }
      ]
    }],
    restroom:isEdit || isView ? restroomDetails?.restroom ? restroomDetails.restroom : "" :"",
    day_date: isEdit || isView ? restroomDetails?.day_date ? convertDateTimezoneFormAudit(restroomDetails.day_date) : "" :'',
    shift_hours: isEdit || isView ? restroomDetails?.shift_hours ? MomentStringToHour(restroomDetails.shift_hours).hour() : "" :"",
    shifts: isEdit || isView ? restroomDetails?.shifts ? structureShifts(restroomDetails.shifts) :[
      {
        employee_signature: "",
        on_time: "",
        break_1_time: "",
        lunch_time: "",
        // active_status: "Active",
        sort: 1,
        break_2_time: "",
        off_time: "",
        supervisor_id: "",
      },
      {
        employee_signature: "",
        on_time: "",
        break_1_time: "",
        lunch_time: "",
        // active_status: "",
        sort: 1,
        break_2_time: "",
        off_time: "",
        supervisor_id: "",
      },
      {
        employee_signature: "",
        on_time: "",
        break_1_time: "",
        lunch_time: "",
        // _status: "Active",
        sort: 1,
        break_2_time: "",
        off_time: "",
        supervisor_id: "",
      },
    ] : [
      {
        employee_signature: "",
        on_time: "",
        break_1_time: "",
        lunch_time: "",
        // active_status: "Active",
        sort: 1,
        break_2_time: "",
        off_time: "",
        supervisor_id: "",
      },
      {
        employee_signature: "",
        on_time: "",
        break_1_time: "",
        lunch_time: "",
        // active_status: "",
        sort: 1,
        break_2_time: "",
        off_time: "",
        supervisor_id: "",
      },
      {
        employee_signature: "",
        on_time: "",
        break_1_time: "",
        lunch_time: "",
        // _status: "Active",
        sort: 1,
        break_2_time: "",
        off_time: "",
        supervisor_id: "",
      },
    ],

  }
  
  useEffect(() => {
    dispatch(getManagersList({ supervisor: true }))
    dispatch(getUsersList({roles:'frontline'}))
  }, []);
   
  useEffect(() => {
    if (isEdit || (isView && id)) {
      
      dispatch(getSingleRestRoomDetails(id));
    }
  }, [id]);


  const structureBody = (data) => {
    let body = null
    const { section, shifts, ...remainData } = data
    
    const formatedShifts =shifts.map((shift) => ({
      ...shift,
      break_1_time: MomentFormatToHour(shift.break_1_time),
      break_2_time: MomentFormatToHour(shift.break_2_time),
      off_time: MomentFormatToHour(shift.off_time),
      on_time: MomentFormatToHour(shift.on_time),
      lunch_time: MomentFormatToHour(shift.lunch_time)
    }))
    if (isEdit) {
      
      const {comments,discrepancies,measure_taken,restroom,...remainingSection}=section[0]
      
      const newComments={
        comments,
        discrepancies,
        measure_taken,
        restroom,
        id
      }
      
      body=[{
        id,
        user_id:restroomDetails?.user_id,
        ...remainData,
        shifts: formatedShifts,
        section:{...remainingSection,in_time: MomentFormatToHour(remainingSection.in_time), out_time: MomentFormatToHour(remainingSection.out_time)},
        day_date: tzUTCDateConverterTool_FormAudit(ConvertToFormat(remainData.day_date),"to"),
        shift_hours: hoursToTimeString(+remainData.shift_hours),
        comments:newComments,
        active_status: "active",
        
      }]
      
    } else {

      body = section.map((item) => {
        const { comments, restroom, discrepancies, measure_taken, ...remainItem } = item
        return {
          ...remainData,
          day_date: tzUTCDateConverterTool_FormAudit(ConvertToFormat(remainData.day_date),"to"),  //tzUTCDateConverterTool_FormAudit(ConvertToFormat(item.date), "to")    
          shift_hours: hoursToTimeString(+remainData.shift_hours),
          shifts: formatedShifts,
          section: { ...remainItem, in_time: MomentFormatToHour(item.in_time), out_time: MomentFormatToHour(item.out_time) },

          // out_time:MomentFormatToHour(item.out_time),
          comments: {
            comments: comments,
            measure_taken:measure_taken,
            restroom:restroom,
            discrepancies: discrepancies
          },
        }
      })
    }
    return {
      data: body
    }
  }


  const onSubmit = (data) => {
    const body = structureBody(data)
    if (isEdit) {
      dispatch(editRestroomDetails(body))

    } else {
      dispatch(addRestroomDetails(body))
    }
  }


  return (
    <NewRestRoomPageContainer
      elements={defaultForm}
      formSubmittedCallback={onSubmit}
      isEdit={isEdit}
      isView={isView}
      modalUpdate={true}
    />
  );
}

export default NewRestroomPorteringContainer;