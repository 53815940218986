import React from 'react';
import { calculateHoursFromMinutes_V1, converShifDetailstDate, converShiftScheduleTime, getOrdinalNumber, makeFirstLetterCapital } from '../../../../utility/utility';
import TimecardCorrectionFormSkeleton from './TimecardCorrectionFormSkeleton';
import { userRoles } from '../../../../utility/constants/constants';
import { tzDateConverterTool_V1, tzDateConverterTool_V6, tzDateConverterTool_V7, tzTimeConversionTool_V1, tzTimeConversionTool_V2 } from '../../../../utility/TimezoneOperations/timezoneUtility';
var feedGap = null;

let PreviewTimecardCorrectedComponent = (props) => {

    const { isLoading, isTriggerLoader, singleTimecardCorrectData, singleShiftScheduleData } = props;

    if (isTriggerLoader) {
        return (
            <TimecardCorrectionFormSkeleton />
        )
    } else {

        return (
            <>
                <main className="app-content add_account_modal add_frontline_pop">
                    <div className="appcontent_Inner user_newQuote pt-0">

                        <div className="container py-5">

                            <div className="row text-center mb-4">
                                <div className="col-lg-7 mx-auto">
                                    <h1 className="mb-0" style={{ color: '#4a4a4a', fontWeight: '500', fontSize: '35px' }}> Timecard Corrected Info </h1>
                                </div>
                            </div>
                            <div className="addQuote_form">

                                <div className="row">
                                    <div className="col-lg-8 mx-auto">
                                        <ul className="list-group shadow rounded" style={{ marginLeft: '10%', marginRight: '10%' }}>

                                            <li className="list-group-item">
                                                <div className="media align-items-lg-center flex-column flex-lg-row p-3">
                                                    <div className="media-body order-2 order-lg-1">
                                                        <h5 className="mt-0 font-weight-bold mb-2 text-dark"> Employee Name </h5>
                                                    </div>
                                                    <h5 className="ml-lg-5 order-1 order-lg-2 text-muted"> {singleTimecardCorrectData?.employee?.id ? makeFirstLetterCapital(singleTimecardCorrectData.employee.first_name) + makeFirstLetterCapital(singleTimecardCorrectData.employee.last_name) : '−'} </h5>
                                                </div>
                                            </li>

                                            <li className="list-group-item">
                                                <div className="media align-items-lg-center flex-column flex-lg-row p-3">
                                                    <div className="media-body order-2 order-lg-1">
                                                        <h5 className="mt-0 font-weight-bold mb-2 text-dark"> Employee Mail </h5>
                                                    </div>
                                                    <h5 className="ml-lg-5 order-1 order-lg-2 text-muted"> {singleTimecardCorrectData?.employee?.id ? singleTimecardCorrectData.employee.email : '−'} </h5>
                                                </div>
                                            </li>

                                            <li className="list-group-item">
                                                <div className="media align-items-lg-center flex-column flex-lg-row p-3">
                                                    <div className="media-body order-2 order-lg-1">
                                                        <h5 className="mt-0 font-weight-bold mb-2 text-dark"> Employee Signed Date </h5>
                                                    </div>
                                                    <h5 className="ml-lg-5 order-1 order-lg-2 text-muted"> {singleTimecardCorrectData?.employee_signed_date ? tzDateConverterTool_V1(singleTimecardCorrectData?.employee_signed_date) : '−'} </h5>
                                                </div>
                                            </li>

                                            <li className="list-group-item">
                                                <div className="media align-items-lg-center flex-column flex-lg-row p-3">
                                                    <div className="media-body order-2 order-lg-1">
                                                        <h5 className="mt-0 font-weight-bold mb-2 text-dark"> Allocated Job </h5>
                                                    </div>
                                                    <h5 className="ml-lg-5 order-1 order-lg-2 text-muted"> {singleTimecardCorrectData?.shiftDetail?.accounts[0]?.id ? singleTimecardCorrectData.shiftDetail.accounts[0].name : '−'} </h5>
                                                </div>
                                            </li>

                                            <li className="list-group-item">
                                                <div className="media align-items-lg-center flex-column flex-lg-row p-3">
                                                    <div className="media-body order-2 order-lg-1">
                                                        <h5 className="mt-0 font-weight-bold mb-2 text-dark"> Shift Timing </h5>
                                                    </div>
                                                    <h5 className="ml-lg-5 order-1 order-lg-2 text-muted"> {singleShiftScheduleData?.shift && singleShiftScheduleData?.accounts ? `${tzTimeConversionTool_V1(singleShiftScheduleData.shift?.start_time, singleShiftScheduleData.shift?.start_date)} - ${tzTimeConversionTool_V1(singleShiftScheduleData.shift?.end_time, singleShiftScheduleData.shift?.end_date)}` : '−'} </h5>
                                                </div>
                                            </li>

                                            <li className="list-group-item">
                                                <div className="media align-items-lg-center flex-column flex-lg-row p-3">
                                                    <div className="media-body order-2 order-lg-1">
                                                        <h5 className="mt-0 font-weight-bold mb-2 text-dark"> Clocked In Time </h5>
                                                    </div>
                                                    <h5 className="ml-lg-5 order-1 order-lg-2 text-muted"> {singleTimecardCorrectData?.shiftDetail?.check_int_at ? tzDateConverterTool_V7(singleTimecardCorrectData?.shiftDetail?.check_int_at) : '−'} </h5>
                                                </div>
                                            </li>

                                            <li className="list-group-item">
                                                <div className="media align-items-lg-center flex-column flex-lg-row p-3">
                                                    <div className="media-body order-2 order-lg-1">
                                                        <h5 className="mt-0 font-weight-bold mb-2 text-dark"> Clocked Out Time </h5>
                                                    </div>
                                                    <h5 className="ml-lg-5 order-1 order-lg-2 text-muted"> {singleTimecardCorrectData?.shiftDetail?.check_out_at ? tzDateConverterTool_V7(singleTimecardCorrectData?.shiftDetail?.check_out_at) : '−'} </h5>
                                                </div>
                                            </li>

                                            <li className="list-group-item">
                                                <div className="media align-items-lg-center flex-column flex-lg-row p-3">
                                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%' }}>
                                                        <div className="media-body" style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                                            <h5 className="mt-0 font-weight-bold mb-2 text-dark"> Meal Break Time </h5>
                                                        </div>
                                                        <div className="media-body" style={{ display: 'flex', flexDirection: 'column' }}>
                                                            {singleTimecardCorrectData?.corrections?.length > 0 ? singleTimecardCorrectData?.corrections?.map((feed, index, array) => {
                                                                return (
                                                                    <>
                                                                        <div style={{ borderLeft: '5px solid #cfcfcf', backgroundColor: '#f0f0f0' }}>
                                                                            <h5 className="ml-lg-3 order-1 order-lg-2 text-muted mt-2 mb-1"> {getOrdinalNumber(index + 1)} meal </h5>
                                                                            <h5 className="ml-lg-3 font-italic text-muted mb-2" style={{ fontSize: '15px' }}>
                                                                                {`${feed?.start_time ? tzDateConverterTool_V7(feed.start_time) : null} − ${feed?.end_time ? tzDateConverterTool_V7(feed.end_time) : null}`}
                                                                            </h5>
                                                                        </div>
                                                                        {index === array.length - 1 ? null : <br />}
                                                                    </>
                                                                );

                                                            }) : (<h5 className="ml-lg-0 font-italic text-color-info mb-0" style={{ fontSize: '15px' }}> − </h5>)}
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>

                                            <li className="list-group-item">
                                                <div className="media align-items-lg-center flex-column flex-lg-row p-3">
                                                    <div className="media-body order-2 order-lg-1">
                                                        <h5 className="mt-0 font-weight-bold mb-2 text-dark"> Date of Error </h5>
                                                    </div>
                                                    <h5 className="ml-lg-5 order-1 order-lg-2 text-muted"> {singleTimecardCorrectData?.date_error ? tzDateConverterTool_V1(singleTimecardCorrectData?.date_error) : '−'} </h5>
                                                </div>
                                            </li>

                                            <li className="list-group-item">
                                                <div className="media align-items-lg-center flex-column flex-lg-row p-3">
                                                    <div className="media-body order-2 order-lg-1">
                                                        <h5 className="mt-0 font-weight-bold mb-2 text-dark"> Reason for Error </h5>
                                                    </div>
                                                    <h5 className="ml-lg-5 order-1 order-lg-2 text-muted"> {singleTimecardCorrectData?.corrections?.length > 1 ? singleTimecardCorrectData?.corrections[0]?.reason : '−'} </h5>
                                                </div>
                                            </li>

                                            <li className="list-group-item">
                                                <div className="media align-items-lg-center flex-column flex-lg-row p-3">
                                                    <div className="media-body order-2 order-lg-1">
                                                        <h5 className="mt-0 font-weight-bold mb-2 text-dark"> Supervisor/Manager Name </h5>
                                                    </div>
                                                    <h5 className="ml-lg-5 order-1 order-lg-2 text-muted"> {singleTimecardCorrectData?.manager?.id ? makeFirstLetterCapital(singleTimecardCorrectData.manager.first_name) + makeFirstLetterCapital(singleTimecardCorrectData.manager.last_name) : '−'} </h5>
                                                </div>
                                            </li>

                                            <li className="list-group-item">
                                                <div className="media align-items-lg-center flex-column flex-lg-row p-3">
                                                    <div className="media-body order-2 order-lg-1">
                                                        <h5 className="mt-0 font-weight-bold mb-2 text-dark"> Supervisor/Manager Signed Date </h5>
                                                    </div>
                                                    <h5 className="ml-lg-5 order-1 order-lg-2 text-muted"> {singleTimecardCorrectData?.manager_singed_date ? tzDateConverterTool_V1(singleTimecardCorrectData?.manager_singed_date) : '−'} </h5>
                                                </div>
                                            </li>

                                            <li className="list-group-item">
                                                <div className="media align-items-lg-center flex-column flex-lg-row p-3">
                                                    <div className="media-body order-2 order-lg-1">
                                                        <h5 className="mt-0 font-weight-bold mb-2 text-dark"> Payroll Name </h5>
                                                    </div>
                                                    <h5 className="ml-lg-5 order-1 order-lg-2 text-muted"> {singleTimecardCorrectData?.payroll?.id ? makeFirstLetterCapital(singleTimecardCorrectData.payroll.first_name) + makeFirstLetterCapital(singleTimecardCorrectData.payroll.last_name) : '−'} </h5>
                                                </div>
                                            </li>

                                            <li className="list-group-item">
                                                <div className="media align-items-lg-center flex-column flex-lg-row p-3">
                                                    <div className="media-body order-2 order-lg-1">
                                                        <h5 className="mt-0 font-weight-bold mb-2 text-dark"> Payroll Signed Date </h5>
                                                    </div>
                                                    <h5 className="ml-lg-5 order-1 order-lg-2 text-muted"> {singleTimecardCorrectData?.payroll_signed_date ? tzDateConverterTool_V1(singleTimecardCorrectData?.payroll_signed_date) : '−'} </h5>
                                                </div>
                                            </li>

                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </>
        );
    }

};

export default PreviewTimecardCorrectedComponent;