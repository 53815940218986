import { Chart } from "react-google-charts";
import React, { useEffect, useState } from 'react';
// import CHIServysTable from '../../container/CHIServysContainer/CHIServysTable';
import WeekMonthYearLists from "../Dashboards/weekMonthYearLists";
import '../../../styles/custom.css';
import 'animate.css';
import ReactTable from 'react-table-v6';
import { FaArrowDown, FaArrowUp } from "react-icons/fa";
import 'react-table-v6/react-table.css';
import { ClapSpinner } from "react-spinners-kit";
import ImageLoader from 'react-imageloader';
import { CustomNoDataComponent, getFirstAndLastOfMonth } from "../../../utility/utility";
import { Input } from '../UI/InputElement/InputElement';
import { reduxForm, formValueSelector, getFormValues } from 'redux-form';
import { connect } from "react-redux";
import { KpiDashboardSearchValidator as validate } from '../../../utility/validator/validator';
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { routes } from "../../../utility/constants/constants";
import { ModalKpiGetFormatedDate, GetFormatedDate, weekStartDayAndWeekEndDay, getMonthAndYear, getYearView, get_next_week_start, getNextYearDate, getNextMonthDate, getPast90Days, get_next_90days, convertDateToDifferentTZ, convertDateAndTimeTimezonelll, getUser, getPast90DaysCall, KpiGetFormatedDate, getNextMonth, weekStartDayAndWeekEndDay1, getPast90DaysStartDayAndEndDay, getCustomConvertedDate } from '../../../utility/utility';

import {Link} from "react-router-dom";
var moment = require('moment');

const getAccountsDropDown = (accounts) => {

    return accounts && accounts.map(a => {
        return {
            value: a.id,
            label: a.name
        }
    })
}

const getUsersDropDown = (users) => { 

    return users && users.map(u => {
        return {
            value: u.id,
            label: u.first_name + ' ' + u.last_name
        }
    })
}





let KpiInspectionDashboard = (props) => {
    let avergeScore = [];
    let customerInspectionData = [];
    let topInspection = [];
    let qqvInspection = [];
    let naInspection = [];

 

    //average score chart
    if (props.kpiInspectionAverageScore && props.kpiInspectionAverageScore.inspaction_avg_Score && props.kpiInspectionAverageScore.inspaction_avg_Score.length > 0) {
        props.kpiInspectionAverageScore.inspaction_avg_Score.map((e, i) => {
            avergeScore.push([e[0], e[1] ? e[1] : 0])
            if(e[1] == '' && e[2] && e[2].length > 0) {
                naInspection.push([e[0], e[1] == '' && e[2].length > 0 ? e[2] : 0])
            }
        })
    }
    console.log('naInspectioon', naInspection)
    if (avergeScore.length > 0) {
        avergeScore.unshift(['Index', 'Average Score'])
    }
    //end of average score chart
    //top inspection chart
    if (props.kpiTopInspector && props.kpiTopInspector.top_inspectors && props.kpiTopInspector.top_inspectors.length > 0) {
        props.kpiTopInspector.top_inspectors.map((e, i) => { 
            topInspection.push([e.inspected_by.first_name + e.inspected_by.last_name, parseInt(e.inspection_count)])
        })
    } else {
        topInspection.push(['', 0])
    }
    if (topInspection.length > 0) {
        topInspection.unshift(['Inspector', 'Count'])
    }
    //end of top inspection chart
    
    //Customer Inspection
    if (props.kpiInspectionCustomerData && props.kpiInspectionCustomerData.qqv_inspaction_line_chart && props.kpiInspectionCustomerData.qqv_inspaction_line_chart.length > 0) {
        props.kpiInspectionCustomerData.qqv_inspaction_line_chart.map((e, i) => {
            customerInspectionData.push([e[0], e[1].deficiencyCount ? e[1].deficiencyCount : 0, e[1].qualityCount ? e[1].qualityCount : 0])
        })
    }
    if (customerInspectionData.length > 0) {
        customerInspectionData.unshift(['Count', 'Deficiency Count', 'Quality Count'])
    }
    //end of customer inspection
    
    //qqv inspection chart
    if (props.kpiInspectionQQvData && props.kpiInspectionQQvData.inspection_chi_score && props.kpiInspectionQQvData.inspection_chi_score.length > 0) {
        props.kpiInspectionQQvData.inspection_chi_score.map((e, i) => {
            qqvInspection.push([e[0].inspection_form_name ? e[0].inspection_form_name.name : '', e[0].Deligted ? parseInt(e[0].Deligted) : 0, e[0].Dissatisfied ? parseInt(e[0].Dissatisfied) : 0, e[0].Neutral ? parseInt(e[0].Neutral) : 0])
        })
    } else {
        qqvInspection.push(['-', 0, 0, 0])
    }
    if (qqvInspection.length > 0) {
        qqvInspection.unshift(['Form', 'Delighted', 'Dissatisfied', 'Neutral'])
    }
    //qqv inspection chart

    const getData = (key) => {
        let dataArray = []
        props.kpiQualityInspectionData.groups[key].map((task, index) => {
            dataArray.push([task.name, parseInt(task.line_items)])
        })
        dataArray.unshift(['Inspector', 'Count'])
        return dataArray
    }

    const getScoreData = () => {
        let dataArray = []
        props.kpiAverageSectionQualityData && props.kpiAverageSectionQualityData.sectionAvgRows &&  props.kpiAverageSectionQualityData.sectionAvgRows.map((task) => {
            dataArray.push([task.name, parseInt(task.avg)])
        })
        dataArray.unshift(['Area', 'Score'])
        return dataArray
    }
    const getSafetyData = () => {
        let dataArray = []
        props.kpiAverageSectionSafetyData && props.kpiAverageSectionSafetyData.sectionAvgRows &&  props.kpiAverageSectionSafetyData.sectionAvgRows.map((task) => {
            dataArray.push([task.name, parseInt(task.avg)])
        })
        dataArray.unshift(['Area', 'Score'])
        return dataArray
    }

   
    

    const { handleSubmit, pristine, reset, submitting, error } = props;
    function preloader() {
        return <img style={{ height: '35px', width: '35px', borderRadius: '50px', float: 'left' }} src="/images/gif/giphy.gif" />;
    }
const data = props.kpiTopInspector && props.kpiTopInspector.top_inspectors && props.kpiTopInspector.top_inspectors.map(ir => {
   
    return {
            // requestor: ir.user,
            // company: ir.company_name,
            image: ir.inspected_by.photo_urls && ir.inspected_by.photo_urls.small ?
                <ImageLoader
                    imgProps={{ style: { height: '35px', width: '35px', borderRadius: '50px', float: 'left' } }}
                    style={{ height: '35px', width: '35px', borderRadius: '50px', float: 'left' }}
                    src={ir.inspected_by.photo_urls.small}
                    wrapper={React.createFactory('div')}
                    preloader={preloader}>
                    Image load failed!
                    </ImageLoader>
                :
                <img src="/customImages/user.png" style={{ height: '35px', width: '35px', borderRadius: '50px', float: 'left' }} />
            ,
            name: ir.inspected_by.first_name + ' ' + ir.inspected_by.last_name,
            count: ir.inspection_count,
            qqvCount: ir.qqv_count,
            nonQQvCount: ir.non_qqv_count,
            shiftInsCount: ir.end_of_shift,
            safetyInspectionsCount: ir.safety,
            managementInspections: ir.sr_mgmt
        }
    })
    const column = [
        {
            Header: '',
            accessor: 'image', // String-based value accessors!,
            width: 55
        }, {
            Header: <><span>Inspector</span><FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
            accessor: 'name', // String-based value accessors!,
            width: '15%',
            show: props.kpiTopInspector.top_inspectors && props.kpiTopInspector.top_inspectors.length !== 0,
        },{
            Header: <><span>Number of QQV Inspections</span><FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
            accessor: 'qqvCount', // String-based value accessors!,
            width: '15%',
            show: props.kpiTopInspector.top_inspectors && props.kpiTopInspector.top_inspectors.length !== 0,
        },{
            Header: <><span>Number of Quality Inspections</span><FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
            accessor: 'nonQQvCount', // String-based value accessors!,
            width: '15%',
            show: props.kpiTopInspector.top_inspectors && props.kpiTopInspector.top_inspectors.length !== 0,
        },{
            Header: <><span>End of Shift Inspections</span><FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
            accessor: 'shiftInsCount', // String-based value accessors!,
            width: '15%',
            show: props.kpiTopInspector.top_inspectors && props.kpiTopInspector.top_inspectors.length !== 0,
        },{
            Header: <><span>Safety Inspections</span><FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
            accessor: 'safetyInspectionsCount', // String-based value accessors!,
            width: '15%',
            show: props.kpiTopInspector.top_inspectors && props.kpiTopInspector.top_inspectors.length !== 0,
        },{
            Header: <><span>Management Inspections</span><FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
            accessor: 'managementInspections', // String-based value accessors!,
            width: '15%',
            show: props.kpiTopInspector.top_inspectors && props.kpiTopInspector.top_inspectors.length !== 0,
        }, {
            Header: <><span>Total Inspections Count</span><FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
            accessor: 'count', // String-based value accessors!,
            width: '15%',
            show: props.kpiTopInspector.top_inspectors && props.kpiTopInspector.top_inspectors.length !== 0,
        },

    ]
    


    const [accountList, setAccountList] = useState(getAccountsDropDown(props.company.accounts));
    const [usersList, setUsersList] = useState(getUsersDropDown(props.dropDownUsersList));
    const [selectedInspection, setSelectedInspection] = useState(0);



    const trimText = (num) => {
        return Number.parseFloat(num).toFixed(2);
    }
    useEffect(() => {
        setAccountList(getAccountsDropDown(props.company.accounts))
    }, [props.company.accounts])

    useEffect(() => {
        setUsersList(getUsersDropDown(props.dropDownUsersList))
    }, [props.dropDownUsersList])

   

    const noUserOptionsMessage = (a, b) => {
        return 'User not found';
    }

    const noAccountOptionsMessage = (a, b) => {
        return 'Job not found';
    }
    const qualitySortTable = (n) => {
        var table, rows, switching, i, x, y, shouldSwitch, dir, switchcount = 0;
        table = document.getElementById("table_head_area_sort");
        switching = true;
        dir = "asc";
        while (switching) {
          switching = false;
          rows = table.rows;
          for (i = 0; i < (rows.length - 1); i++) {
            shouldSwitch = false;
            x = rows[i].getElementsByTagName("TD")[n];
            y = rows[i + 1].getElementsByTagName("TD")[n];
             if (dir == "asc") {
              if (x.innerHTML.toLowerCase() > y.innerHTML.toLowerCase()) {
                shouldSwitch= true;
                break;            
              }
            } else if (dir == "desc") {
              if (x.innerHTML.toLowerCase() < y.innerHTML.toLowerCase()) {
                shouldSwitch = true;
                break;
              }
            }
          }
          if (shouldSwitch) {
            rows[i].parentNode.insertBefore(rows[i + 1], rows[i]);
            switching = true;
            switchcount ++;
          } else {
            if (switchcount == 0 && dir == "asc") {
              dir = "desc";
              switching = true;
            }
          }
        }
      }
    
      const safetySortTable = (n) => { 
        var table, rows, switching, i, x, y, shouldSwitch, dir, switchcount = 0;
        table = document.getElementById("table_head_safety");
        switching = true;
        dir = "asc";
        while (switching) {
          switching = false;
          rows = table.rows;
          for (i = 0; i < (rows.length - 1); i++) { 
            shouldSwitch = false;
            x = rows[i].getElementsByTagName("TD")[n];
            y = rows[i + 1].getElementsByTagName("TD")[n];
             if (dir == "asc") {
              if (parseInt(x.innerHTML) > parseInt(y.innerHTML)) {
                shouldSwitch= true;
                break;
              }
            } else if (dir == "desc") {
              if (parseInt(x.innerHTML) < parseInt(y.innerHTML)) {
                shouldSwitch = true;
                break;
              }
            }
          }
          if (shouldSwitch) {
            rows[i].parentNode.insertBefore(rows[i + 1], rows[i]);
            switching = true;
            switchcount ++;
          } else {
            if (switchcount == 0 && dir == "asc") {
              dir = "desc";
              switching = true;
            }
          }
        }
      }
      const sortTable = (n) => {
        var table, rows, switching, i, x, y, shouldSwitch, dir, switchcount = 0;
        table = document.getElementById("table_head_qsi");
        switching = true;
        dir = "asc";
        while (switching) {
          switching = false;
          rows = table.rows;
          for (i = 0; i < (rows.length - 1); i++) {
            shouldSwitch = false;
            x = rows[i].getElementsByTagName("TD")[n];
            y = rows[i + 1].getElementsByTagName("TD")[n];
             if (dir == "asc") {
              if (parseInt(x.innerHTML) > parseInt(y.innerHTML)) {
                shouldSwitch= true;
                break;
              }
            } else if (dir == "desc") {
              if (parseInt(x.innerHTML) < parseInt(y.innerHTML)) {
                shouldSwitch = true;
                break;
              }
            }
          }
          if (shouldSwitch) {
            rows[i].parentNode.insertBefore(rows[i + 1], rows[i]);
            switching = true;
            switchcount ++;
          } else {
            if (switchcount == 0 && dir == "asc") {
              dir = "desc";
              switching = true;
            }
          }
        }
      }
      
    //end of deficiency chart
    return (  
        <>
       

            <section class="report_content_block appcontent_Inner">
                <div class="admin_dashboard report_content chi_content chi_surveys" id="kpi_dashboard">
                    <div class="sub_head">
                        <h2 class="h2_title d-inline-block">Overview</h2>
                        <div class="head_btn_block">
                            <WeekMonthYearLists
                             {...props} 
                             isLoading={props.isLoading}
                             onSubmit={props.goClicked}
                             monthDivideIntoThree={props.monthDivideIntoThree}
                             viewModeClicked={props.viewModeClicked}
                             showYear='show'
                              show90='show' 
                              showWeek='show'
                              showCustomDate='show'
                             showCustomDatePicker='show'
                                />
                        </div>

                    </div>
                   <div class="chi_grid_view" id="kpi_theme">
                        <div class="row">
                            <div class="col-md-12 col-xl-12">
                                <div class="grid_view ">
                                <div class="grid_card tile  ">
                                <div className="m-auto" style={{ height: 70 }}>
                                <h4 class="h4_title">Quality Inspections</h4>
                                {/* <h3 class="sub_title">Sub Total</h3> */}
                                    <strong class="count">{props.kpiInspectionCount.inspection_without_qqv}</strong>
                                </div>
                                <p class="cst_para mt-3 w-100"><span class="mr-1" style={{color: "#89073c"}}>{props.kpiInspectionCount.percentage_of_non_qqv == 'NaN' ? '0' : props.kpiInspectionCount.percentage_of_non_qqv}%</span></p>
                                </div>
                                <div class="grid_card tile ">
                                <div className="m-auto" style={{ height: 70 }}>
                                <h4 class="h4_title">QQV Inspections</h4>
                                {/* <h3 class="sub_title">Sub Total</h3> */}
                                    <strong class="count">{props.kpiInspectionCount.inspection_qqv_count}</strong>
                                </div>
                                <p class="cst_para mt-3 w-100"><span class="mr-1" style={{color: "#89073c"}}>{props.kpiInspectionCount.percentage_of_qqv == 'NaN' ? '0' : props.kpiInspectionCount.percentage_of_qqv}%</span></p>
                                </div>
                              
                                <div class="grid_card tile ">
                                <div className="m-auto" style={{ height: 70 }}>
                                <h4 class="h4_title">Management Inspections</h4>
                                {/* <h3 class="sub_title">Sub Total</h3> */}
                                    <strong class="count">{props.kpiInspectionCount.Sr_mgmt_inspector_count}</strong>
                                </div>
                                <p class="cst_para mt-3 w-100"><span class="mr-1" style={{color: "#89073c"}}>{props.kpiInspectionCount.percentage_sr_manager_inspection == 'NaN' ? '0' : props.kpiInspectionCount.percentage_sr_manager_inspection}%</span></p>
                                </div>
                                <div class="grid_card tile ">
                                <div className="m-auto" style={{ height: 70 }}>
                                <h4 class="h4_title">End of Shift Inspections</h4>
                                {/* <h3 class="sub_title">Sub Total</h3> */}
                                    <strong class="count">{props.kpiInspectionCount.inspection_EndofShift_count}</strong>
                                </div>
                                <p class="cst_para mt-3 w-100"><span class="mr-1" style={{color: "#89073c"}}>{props.kpiInspectionCount.percentage_end_of_shift_inspection == 'NaN' ? '0' : props.kpiInspectionCount.percentage_end_of_shift_inspection}%</span></p>
                                </div>
                                <div class="grid_card tile ">
                                <div className="m-auto" style={{ height: 70 }}>
                                <h4 class="h4_title">Safety Inspections</h4>
                                {/* <h3 class="sub_title">Sub Total</h3> */}
                                    <strong class="count">{props.kpiInspectionCount.safety_count}</strong>
                                </div>
                                <p class="cst_para mt-3 w-100"><span class="mr-1" style={{color: "#89073c"}}>{props.kpiInspectionCount.percentage_of_safety_inspection == 'NaN' ? '0' : props.kpiInspectionCount.percentage_of_safety_inspection}%</span></p>
                                </div>
                                <div class="grid_card tile ">
                                <div className="m-auto" style={{ height: 70 }}>
                                <h4 class="h4_title">Total Inspections</h4>
                                {/* <h3 class="sub_title">Sub Total</h3> */}
                                    <strong class="count">{props.kpiInspectionCount.completed_inspections}</strong>
                                </div>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                

                    <div class="graph_tabs ">
                        <div class="row">
                            <div class="col-xl-12 chart_cont_box">
                                <div class="tile chart-box-botL" id="chart_sec" style={{textAlign: "center"}}>
                                    <h6 class="chart_title" style={{textAlign: "left"}}>Average Score of Quality Inspections</h6>
                                    {
                                        props.kpiInspectionAverageScore.inspaction_avg_Score && props.kpiInspectionAverageScore.inspaction_avg_Score.length > 0 ?
    
                                    <>
                                    {naInspection.length > 0 ? 
                                        <div class="average_score_table">
                                    {/* <table class="table table-bordered text-center" style={{width: '100%', margin: 'auto'}} id="table_head">
                                    <thead>
                                    <th style={{verticalAlign: 'inherit'}}>Inspection ID</th>
                                    <th style={{verticalAlign: 'inherit'}}>Inspection Date</th>
                                    <th style={{verticalAlign: 'inherit'}}>Score</th>
                                
                                    </thead>

                                    <tbody class="averge_score_graph">
                                        { naInspection && naInspection.length > 0 && naInspection.map((task, index) => { 
                                            return (
                                                <>
                                                <tr>
                                                <td><Link to={'/inspections/previewInspection/' + task[1]} target="_blank" style={{pointer: "cursor", color: "#89073c"}}>{task[1]}</Link></td>
                                                <td>{task[0]}</td>
                                                <td>N/A</td>
                                                </tr>
                                                    </>
                                            )
                                    })}
                                    </tbody>
                                </table>  */}
                                </div>
                                : ''}
                                <Chart
                                        width={'100%'}
                                        height={'500px'}
                                        // style={{margin: "0 auto"}}
                                        chartType="LineChart"
                                        loader={<div>Loading Chart</div>}
                                        data={avergeScore}
                                        options={{
                                            chartArea: { width: '70%' },
                                            hAxis: {
                                                title: 'Date',
                                                minValue: 0,
                                            },
                                            vAxis: {
                                                title: 'Average Score',
                                            },
                                            colors: ['#FF8A9B'],
                                            
                                            // legend: { position: 'none', alignment: 'end' },
                                            // curveType: 'function',
                                        }}
                                        rootProps={{ 'data-testid': '1' }}
                                          chartEvents = {[
                                            {
                                              eventName: "select",
                                              callback({ chartWrapper }) {
                                                const selectedId = chartWrapper.getChart().getSelection();
                                                if (selectedId.length) {
                                                    if(props.kpiInspectionAverageScore.inspaction_avg_Score[selectedId[0].row ][2].length > 0) {
                                                        const ids = props.kpiInspectionAverageScore.inspaction_avg_Score[selectedId[0].row ][2]
                                                        props.getAverageGraphInspections(ids);
                                                        setSelectedInspection(props.kpiInspectionAverageScore.inspaction_avg_Score[selectedId[0].row ][0])
                                                        let ele = document.getElementById('getInspectionElement')
                                                        ele.click()
                                                    }
                                                }
                                              }
                                            }
                                        ]}
                                        
                                    />
                                    
                                
                                 </> : <span style={{textAlign: "center", padding: "20px"}}>No Data Found</span>}
                                </div>
                            </div>
                            <td style={{display: "none"}} id="getInspectionElement" data-toggle="modal" data-target="#averageScoreInspection" data-backdrop="static" data-keyboard="false"></td>
                            </div>


                            <div class="top_inspectores ">
                            <div class="row">
                <div class="col-xl-12 chart_cont_box">
                <div class="tile chart-box-botL" id="chart_sec">
                    <h6 class="chart_title mb-0">Top 10 Inspectors</h6>
                <div class="modal-body custom_modal_body leader_board_table top_inspec_table h-100" >
                    {props.kpiTopInspector.top_inspectors ? 
                    <div className="quotes_table_content table-responsive">
                    <div className="table quotes_table user_table_contnet user_react_table">
                        <ReactTable
                            data={data}
                            // loading={props.isLoading}
                            defaultPageSize={10}
                            minRows={0}
                            // onPageSizeChange={props.onUserPageSizeChange}
                            // showPaginationTop={true}
                             showPagination={false}
                            columns={column}
                            NoDataComponent={() => CustomNoDataComponent(props.kpiTopInspector, 'No Data Found')}
                            getTheadThProps={(state, rowInfo, column, instance) => {
                                return {
                                    style: {
                                        borderTop: 'none',
                                        borderBottom: 'none',
                                        borderRight: 'none',
                                        textAlign: 'center',
                                        color: '#566975',
                                        fontSize: '14px',
                                        fontWeight: '600',
                                        whiteSpace: 'normal',
                                        float: 'left',
                                        
                                    }
                                }
                            }
                            }
                            getTrProps={(state, rowInfo, column, instance) => {
                                return {
                                    className: 'react-table-tr-element',
                                    style: {
                                        border: '1px solid #c7cdd1',
                                        transition: '.3s ease-in-out',
                                        width: '100%',
                                        // marginTop: '5px',
                                        float: 'left',
                                    }
                                }

                            }}
                            getTdProps={(state, rowInfo, column, instance) => {

                                if (column.id == 'image') {
                                    return {
                                        style: {
                                            marginTop: '0px'
                                        }
                                    }
                                } else {
                                    return {
                                        className: 'react-td-element',
                                    }
                                }
                            }
                            }
                            getProps={() => {
                                return {
                                    style: {
                                        border: 'none'
                                    }
                                }
                            }}
                        />
                        
                    </div>
                </div>
                : <div className="loader_btn_block " style={{height: "25vh"}}> {"No Data Found"}
            </div>}
                </div>
                </div>
            </div>
                            </div>
                            </div>


                        <div className="row ">
                            <div class="col-xl-12 chart_cont_box">
                            
                            <div class="tile chart-box-botL" id="chart_sec">
                            <h6 class="chart_title">Quality Inspection Results </h6>
                            {parseInt(props.getKpiQualityInspection.name) &&                             
                            parseInt(props.getKpiQualityInspection.line_items) === 0 ? 
                               <div className="noData_found" style={{height: "300px", position: "relative"}}>
                                 <h3 className="h3_title">No Data Found</h3>
                               </div> :
                               <>
                               {
                                   props.kpiQualityInspectionData && props.kpiQualityInspectionData.groups ? Object.keys(props.kpiQualityInspectionData.groups).map((key, index) => { 
                                           return (
                                               <>
                                               <div class="" style={{fontWeight: "600", textDecoration: "underline"}}>{key}</div>
                                               <div class="pie d-flex align-items-center" >
                                               <Chart
                                                   width={'90%'}
                                                   height={'300px'}
                                                   chartType="PieChart"
                                                   loader={<div>Loading Chart</div>}

                                                   data={getData(key, index)}
                                                   options={{
                                                       colors: ['#B7D9F3', '#CDDF9D', '#D999A1', '#EEB6A8', '#CFC6E2', '#84C5C5', '#C6EDEE'],
                                                       
                                                    //    pieSliceText: 'value'
                                                   }}
                                                   rootProps={{ 'data-testid': '1' }}
                                               />
                                                <table class="table table-bordered text-center" id="table_head_area_sort">
                                                <thead>
                                                <th onClick={() => qualitySortTable(0)}>Area <i class="fas fa-sort"></i></th>
                                                <th onClick={() => qualitySortTable(0)}>Line Items</th>
                                            
                                                </thead>

                                                <tbody class="averge_score_graph">
                                                {props.kpiQualityInspectionData.groups[key].map((task, index) => {
                                                    return (
                                                        <>
                                                        <tr>
                                                        <td style={{cursor: "pointer"}} data-toggle="modal" data-target="#qualitylineItemModal" data-backdrop="static" data-keyboard="false" onClick={() => props.QualityIdlineItemClicked(task)}>{task.name}</td>
                                                            <td>{parseInt(task.line_items)}</td>
                                                     </tr>
                                                            </>
                                                    )
                                                })}
                                                </tbody>
                                                </table>
                                                </div>
                                               </>
                                           )
                                   }) : "No records found"

                               }

                           </>
                            }
                        </div>
                         
                            </div>
                        </div>

                        <div className="row ">
                            <div class="col-xl-12 chart_cont_box">
                            
                            <div class="tile chart-box-botL" id="chart_sec">
                            <h6 class="chart_title">Quality Inspection Summary Information</h6>
                            {props.kpiAverageSectionQualityData.length > 1 ? 
                               <div className="noData_found" style={{height: "300px", position: "relative"}}>
                                 <h3 className="h3_title">No Data Found</h3>
                               </div> :
                               <>
                                <div class="" style={{fontWeight: "600", textDecoration: "underline"}}></div>
                                <div class="pie d-flex " >
                                <Chart
                                    width={'90%'}
                                    height={'300px'}
                                    chartType="PieChart"
                                    loader={<div>Loading Chart</div>}

                                    data={getScoreData()}
                                    options={{
                                        colors: ['#B7D9F3', '#CDDF9D', '#D999A1', '#EEB6A8', '#CFC6E2', '#84C5C5', '#C6EDEE'],
                                        
                                    //    pieSliceText: 'value'
                                    }}
                                    rootProps={{ 'data-testid': '1' }}
                                />
                                <table class="table table-bordered text-center" id="table_head_qsi">
                                <thead>
                                <th>Area</th>
                                <th onClick={() => sortTable(1)}>Score <i class="fas fa-sort"></i></th>
                            
                                </thead>

                                <tbody class="averge_score_graph">
                                {props.kpiAverageSectionQualityData && props.kpiAverageSectionQualityData.sectionAvgRows && props.kpiAverageSectionQualityData.sectionAvgRows.map((task, index) => { 
                                    return (
                                        <>
                                        <tr>
                                        <td style={{cursor: 'pointer'}} data-toggle="modal" data-target="#lineItemModal" data-backdrop="static" data-keyboard="false" onClick={() => props.lineItemClicked(task.name)} >{task.name}</td>
                                        <td>{parseInt(task.avg)}</td>
                                        </tr>
                                            </>
                                    )
                                })}
                                </tbody>
                                </table>
                                </div>
                                </>
                            }
                        </div>
                         
                            </div>
                        </div>

                        <div className="row ">
                            <div class="col-xl-12 chart_cont_box">
                            
                            <div class="tile chart-box-botL" id="chart_sec">
                            <h6 class="chart_title">Safety Inspection Summary Information</h6>
                            {props.kpiAverageSectionSafetyData.length > 1 ? 
                               <div className="noData_found" style={{height: "300px", position: "relative"}}>
                                 <h3 className="h3_title">No Data Found</h3>
                               </div> :
                               <>
                                <div class="" style={{fontWeight: "600", textDecoration: "underline"}}></div>
                                <div class="pie d-flex " >
                                <Chart
                                    width={'90%'}
                                    height={'300px'}
                                    chartType="PieChart"
                                    loader={<div>Loading Chart</div>}

                                    data={getSafetyData()}
                                    options={{
                                        colors: ['#B7D9F3', '#CDDF9D', '#D999A1', '#EEB6A8', '#CFC6E2', '#84C5C5', '#C6EDEE'],
                                        
                                    //    pieSliceText: 'value'
                                    }}
                                    rootProps={{ 'data-testid': '1' }}
                                />
                                <table class="table table-bordered text-center" id="table_head_safety">
                                <thead>
                                <th>Area</th>
                                <th onClick={() => safetySortTable(1)}>Score <i class="fas fa-sort"></i></th>
                            
                                </thead>

                                <tbody class="averge_score_graph">
                                {props.kpiAverageSectionSafetyData && props.kpiAverageSectionSafetyData.sectionAvgRows && props.kpiAverageSectionSafetyData.sectionAvgRows.map((task, index) => { 
                                    return (
                                        <>
                                        <tr>
                                        <td style={{cursor: 'pointer'}} data-toggle="modal" data-target="#lineItemModal" data-backdrop="static" data-keyboard="false" onClick={() => props.lineItemClicked(task.name)} >{task.name}</td>
                                        <td>{parseInt(task.avg)}</td>
                                        </tr>
                                            </>
                                    )
                                })}
                                </tbody>
                                </table>
                                </div>
                                </>
                            }
                        </div>
                         
                            </div>
                        </div>
                    
                    </div>
                </div>
            </section>

            <div id="lineItemModal" className="modal fade pg_common_modal lineItemModal">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content add_area_modal">
                        <div className="modal-header">
                            <h2 className="modal-title" style={{fontSize: "17px",
                                fontWeight: "600",
                                lineHeight: "24px",
                            }}>
                            <span style={{ marginRight: "10px"}}>
                            {props.kpiSectionValues.section_name}
                            </span> 
                            <span>
                            {
                                props.kpiSearchValues.option == "month" ? getFirstAndLastOfMonth(props.currentDate) :
                                props.kpiSearchValues.option == "90_days" ? getPast90DaysStartDayAndEndDay(props.currentDate) : 
                                props.isCustomDate && props.kpiSearchValues.option == "datefilter" ? '(' + getCustomConvertedDate(props.kpiSearchValues.from) + ') to (' + getCustomConvertedDate(props.kpiSearchValues.to) + ")"  : ''
                            }
                            
                            </span>
                            </h2>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body add_content_block">
                        <table class="table table-bordered text-center" id="table_head">
                            <thead>
                            <th style={{verticalAlign: 'inherit'}}>Inspection ID</th>
                            <th style={{verticalAlign: 'inherit'}}>Inspection Form name</th>
                            <th style={{verticalAlign: 'inherit'}}>Inspection Date</th>
                            <th style={{verticalAlign: 'inherit'}}>Score</th>
                        
                            </thead>

                            <tbody class="averge_score_graph">
                                {props.avgModalData.length == 0 ? 
                                <>
                                <div style={{marginLeft: '150%', width: '100%'}}>
                                <ClapSpinner backColor="#89073c" size={40} frontColor="#89073c8a" />
                                </div>
                                </> : props.avgModalData && props.avgModalData.quality_result && props.avgModalData.quality_result.map((task, index) => {
                                return (
                                    <>
                                    <tr>
                                    <td><Link to={'/inspections/previewInspection/' + task.id} target="_blank" style={{pointer: "cursor", color: "#89073c"}}>{task.id}</Link></td>
                                    <td>{task.inspectionFormName[0].name}</td>
                                    <td>{moment(task.updated_at).format('MM-DD-YYYY')}</td>
                                    <td>{parseInt(task.avg)}</td>
                                    </tr>
                                        </>
                                )
                            })}
                            </tbody>
                        </table>
                        </div>
                        <div className="modal-footer">
                                <button type="button" className="btn btn_outline_secondary cst_btn btn_danger"  data-dismiss="modal" aria-label="Close">Close</button>
                            </div>
                    </div>
                </div>
            </div>

            <div id="qualitylineItemModal" className="modal fade pg_common_modal qualitylineItemModal">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content add_area_modal">
                    <div className="modal-header">
                        <h2 className="modal-title" style={{fontSize: "17px",
                            fontWeight: "600",
                            lineHeight: "24px",
                        }}>
                        <span style={{ marginRight: "10px"}}>
                        {props.kpiSectionValues.section_name}
                        </span> 
                        <span>
                        {
                            props.kpiSearchValues.option == "month" ? getFirstAndLastOfMonth(props.currentDate) :
                            props.kpiSearchValues.option == "90_days" ? getPast90DaysStartDayAndEndDay(props.currentDate) : 
                            props.isCustomDate && props.kpiSearchValues.option == "datefilter" ? '(' + getCustomConvertedDate(props.kpiSearchValues.from) + ') to (' + getCustomConvertedDate(props.kpiSearchValues.to) + ")"  : ''
                        }
                        
                        </span>
                        </h2>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div className="modal-body add_content_block">
                    <table class="table table-bordered text-center" id="table_head">
                        <thead>
                        <th style={{verticalAlign: 'inherit'}}>Inspection ID</th>
                        <th style={{verticalAlign: 'inherit'}}>Inspection Form name</th>
                        <th style={{verticalAlign: 'inherit'}}>Inspection Date</th>
                        <th style={{verticalAlign: 'inherit'}}>Line Items</th>
                    
                        </thead>

                        <tbody class="averge_score_graph">
                            {props.qualityModalData.length == 0 ? 
                            <>
                            <div style={{marginLeft: '150%', width: '100%'}}>
                            <ClapSpinner backColor="#89073c" size={40} frontColor="#89073c8a" />
                            </div>
                            </> : props.qualityModalData && props.qualityModalData.result && props.qualityModalData.result.map((task, index) => { 
                           
                                return (
                                <>
                                <tr>
                                <td><Link to={'/inspections/previewInspection/' + task.inspection.id} target="_blank" style={{cursor: "pointer", color: "#89073c"}}>{task.inspection.id}</Link></td>
                                <td>{task.inspection.inspection_form_name}</td>
                                <td>{moment(task.inspection.updated_at).format('MM-DD-YYYY')}</td>
                                <td>{parseInt(task.inspection.line_item_count)}</td>
                                </tr>
                                    </>
                            )
                        })}
                        </tbody>
                    </table>
                    </div>
                    <div className="modal-footer">
                            <button type="button" className="btn btn_outline_secondary cst_btn btn_danger"  data-dismiss="modal" aria-label="Close">Close</button>
                        </div>
                </div>
            </div>
        </div>

        <div id="averageScoreInspection" className="modal fade pg_common_modal lineItemModal">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content add_area_modal">
                        <div className="modal-header">
                            <h2 className="modal-title" style={{fontSize: "17px",
                                fontWeight: "600",
                                lineHeight: "24px",
                            }}>
                                <span style={{ marginRight: "10px"}}>
                            {selectedInspection}
                            </span> 
                            </h2>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body add_content_block">
                        <table class="table table-bordered text-center" id="table_head">
                            <thead>
                            <th style={{verticalAlign: 'inherit'}}>Inspection ID</th>
                            <th style={{verticalAlign: 'inherit'}}>Inspection Form name</th>
                            <th style={{verticalAlign: 'inherit'}}>Inspection Date</th>
                            <th style={{verticalAlign: 'inherit'}}>Score</th>
                        
                            </thead>

                            <tbody class="averge_score_graph">
                                {props.averageGraphInspections && props.averageGraphInspections.length == 0 ? 
                                <>
                                <div style={{marginLeft: '150%', width: '100%'}}>
                                <ClapSpinner backColor="#89073c" size={40} frontColor="#89073c8a" />
                                </div>
                                </> : props.averageGraphInspections && props.averageGraphInspections.length > 0 && props.averageGraphInspections.map((task, index) => { 
                                return (
                                    <>
                                    <tr>
                                    <td><Link to={'/inspections/previewInspection/' + task.id} target="_blank" style={{pointer: "cursor", color: "#89073c"}}>{task.id}</Link></td>
                                    <td>{task.inspection_form.name}</td>
                                    <td>{moment(task.updated_at).format('MM-DD-YYYY')}</td>
                                    <td>{parseInt(task.score)}</td>
                                    </tr>
                                        </>
                                )
                            })}
                            </tbody>
                        </table>
                        </div>
                        <div className="modal-footer">
                                <button type="button" className="btn btn_outline_secondary cst_btn btn_danger"  data-dismiss="modal" aria-label="Close">Close</button>
                            </div>
                    </div>
                </div>
            </div>
            </>
    )
}

const mapStateToProps = (state) => { 
    let name = state.adminOrSuperAdminReducer.searchInspectionValues && state.adminOrSuperAdminReducer.searchInspectionValues.value && state.adminOrSuperAdminReducer.searchInspectionValues.value.name ? state.adminOrSuperAdminReducer.searchInspectionValues.value.name : "";
    let region = state.adminOrSuperAdminReducer.searchInspectionValues && state.adminOrSuperAdminReducer.searchInspectionValues.value && state.adminOrSuperAdminReducer.searchInspectionValues.value.region ? state.adminOrSuperAdminReducer.searchInspectionValues.value.region : "";
    let account = state.adminOrSuperAdminReducer.searchInspectionValues && state.adminOrSuperAdminReducer.searchInspectionValues.value && state.adminOrSuperAdminReducer.searchInspectionValues.value.account ? state.adminOrSuperAdminReducer.searchInspectionValues.value.account : "";
    let user = state.adminOrSuperAdminReducer.searchInspectionValues && state.adminOrSuperAdminReducer.searchInspectionValues.value && state.adminOrSuperAdminReducer.searchInspectionValues.value.user ? state.adminOrSuperAdminReducer.searchInspectionValues.value.user : "";
    return { initialValues: { name, region, account, user} ,
    formStates: getFormValues('searchKpiDashboard')(state)
}
    
}
KpiInspectionDashboard = reduxForm({
    form: 'searchKpiDashboard',
    validate,
    enableReinitialize: true
})(KpiInspectionDashboard);

KpiInspectionDashboard = connect(mapStateToProps)(KpiInspectionDashboard)


export default KpiInspectionDashboard;