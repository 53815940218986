import React, { useEffect, useState } from 'react';
import { ClapSpinner } from 'react-spinners-kit';
import { convertAreaScheduleTime, convertDate, convertDateAndTimeTimezone, makeFirstLetterCapital } from '../../../utility/utility';
import "../CHIPreview/CHIPreview.css"
import { Input } from '../UI/InputElement/InputElement';
import { previewTicketValidator as validate } from '../../../utility/validator/validator';
import { connect, useDispatch } from 'react-redux';
import { reduxForm, change, getFormValues } from 'redux-form';
import ImageLoader from 'react-imageloader';
import errorHandler from '../../../utility/errorHandler/errorHandler';
import ModalImageGallery from '../ModalImageGallery/modalImageGallery';
import history from '../../../router/history';
import isEmpty from '../../../utility/IsEmptyValidator';
import { CHIPreviewAssignRoles, TicketStatus, userRoles } from '../../../utility/constants/constants';

var moment = require('moment');

function preloader() {
    return <img style={{ width: '100%', height: '100%' }} src="/images/gif/giphy.gif" />;
}

let getLastUpdatedDaysCount = (updatedAt) => {
    let CurrentDate = moment(new Date()).startOf('day');
    let UpdatedDate = moment(updatedAt).startOf('day');
    let DifferenceRange = CurrentDate.diff(UpdatedDate, 'days');
    if (DifferenceRange === 0) {
        return moment(updatedAt).format('hh:m A');
    } else if (DifferenceRange === 1) {
        return DifferenceRange + ' day ago'
    } else {
        return DifferenceRange + ' days ago'
    }
}



const loadCommentImages = (props, attachments_attributes, cindex, commentPhotoUrlClicked, isCommentImageGallery, setIsCommentImageGallery, startIndex, commentId, photoCommentId, editCommentObject) => {
    if (props.chi.comments && props.chi.comments.length - 1 === cindex && props.isLoading && isEmpty(attachments_attributes)) {
        return props.portfolioImages.map((element, i) => (
            <div className="attachments__file_box" key={i}>
                <img style={{ width: '100%', height: '100%' }} src="/images/gif/giphy.gif" />
            </div>
        ));

    } else {
        if (!isEmpty(attachments_attributes)) {
            if (isCommentImageGallery && photoCommentId === commentId) {
                return <ModalImageGallery
                    isImageGallery={isCommentImageGallery}
                    setIsImageGallery={setIsCommentImageGallery}
                    startIndex={startIndex}
                    // base64={props.portfolioImages}
                    imageUrls={attachments_attributes}
                />
            } else {
                return (
                    attachments_attributes.map((url, i) => (
                        <>
                            <div className="attachments__file_box">
                                <div key={i} onClick={() => commentPhotoUrlClicked(i, commentId)}>
                                    <ImageLoader
                                        style={{ width: '100%', height: '82px', cursor: 'pointer' }}
                                        src={url.photo_urls.small}
                                        wrapper={React.createFactory('div')}
                                        preloader={preloader}>
                                        Image load failed!
                                    </ImageLoader>
                                </div>
                                {editCommentObject ?
                                    <div class="cancel_icon">
                                        <img src="/images/icons/icn_delete_white.png" onClick={() => props.removeUrls(url, cindex)} alt="Image" />
                                    </div> : null
                                }
                            </div >
                        </>
                    )
                    )
                )
            }
        }
    }

}




let CHIPreview = (props) => {

    const getAdminListDropDown = () => {

        return props.accountAdminList && props.accountAdminList.length > 0 && props.accountAdminList.map(u => {
            return {
                id: u.id,
                name: u.first_name + ' ' + u.last_name,
            }
        })
    }

    const goChisurvey = () => {
        props.history.goBack();
    }

    const [editCommentObject, setEditCommentObject] = useState(null);
    const [isImageGallery, setIsImageGallery] = useState(false);
    const [isCommentImageGallery, setIsCommentImageGallery] = useState(false);
    const [commentId, setCommentId] = useState(null);
    const [startIndex, setStartIndex] = useState(0);
    const [showAttachment, setShowAttachment] = useState(false);
    const [adminList, setAdminList] = useState(getAdminListDropDown());
    // const [AccountDropDown, setAccountDropDown] = useState(true)


    const dispatch = useDispatch();

    useEffect(() => {
        setAdminList(getAdminListDropDown())
    }, [props.accountAdminList])

    useEffect(() => {
        if (props.formStates && props.formStates.chi) {
            if ((props.formStates.chi.comments_attributes.length !== props.chi.comments_attributes.length) && props.formStates.chi.comments_attributes[props.formStates.chi.comments_attributes.length - 1].description !== undefined) {
                setShowAttachment(true);
            } else {
                setShowAttachment(false);
            }
        }
    }, [props.formStates])

    const editCommentClicked = (comment, index) => {
        setEditCommentObject(comment);
        dispatch(change('previewChiForm', `chi.[comments_attributes][${index}][description]`, comment.description))
    }

    if (isEmpty(props.chi)) {
        return (<>
            <div className="loader_btn_block">
                <ClapSpinner backColor="#89073c" size={40} frontColor="#89073c8a" />
            </div>
        </>)
    } else {


        const photoUrlClicked = (i) => {
            setIsImageGallery(true);
            setStartIndex(i);
        }

        const commentPhotoUrlClicked = (i, commentId) => {
            setIsCommentImageGallery(true);
            setCommentId(commentId);
            setStartIndex(i);
        }

        if (isImageGallery) {
            return <ModalImageGallery
                isImageGallery={isImageGallery}
                setIsImageGallery={setIsImageGallery}
                startIndex={startIndex}
                // base64={props.portfolioImages}
                imageUrls={props.ticket.attachments_attributes}
            />
        } else {
            const cancelEditClicked = () => {
                props.cancelEditClicked();
                setEditCommentObject(null);
            }

            // if (props.user.role === 'client' && props.ticket.private) {
            //     errorHandler('Invalid Request')
            //     history.push(`/tickets`);
            // }


            const { handleSubmit, pristine, reset, submitting, error } = props;

            const noUserOptionsMessage = (a, b) => {
                return 'User not found';
            }
            return (
                <main class="app-content wraper_content ticket_user" id="chi_preview">
                    <div class="appcontent_Inner">
                        <div class="tickets_user_profile_body">
                            <div className="goto_back_block d-flex align-items-center pb-3">
                                <a onClick={goChisurvey} class="back_arrow m-0" href="javascript:void(0)"  >
                                    <img src="/images/icons/icn_back_arrow.png" className="" alt="Icon" />
                                </a>
                                <h3 className="app_title h3_title">Response</h3>
                            </div>
                            <div class="container-fluid">
                                <div className="row">
                                    <div class="col-md-12 col-xl-9 order-2 order-xl-1">
                                        <div class="tickets_user_activity">
                                            <div className="tickets_top_header">
                                                <header className="cp_head_title">
                                                    <h4>{props.chi.requestor_user.first_name + ' ' + props.chi.requestor_user.last_name} | {props.chi.chi_survey_response && props.chi.chi_survey_response.rating} </h4>
                                                </header>
                                                <article className="chi_preview_content">
                                                    <p className="cp_info">{props.chi.feedback}</p>
                                                    <h4>Customer Comment On CHI Survey: </h4>
                                                    <p className="cp_info" style={{ lineBreak: 'anywhere' }}>{props.chi.message}</p>
                                                </article>
                                            </div>
                                            <div class="user_activity_conent">
                                                <div class="misc_section">
                                                    {/* <div class="attachment_sec">
                                            <div class="attachment_title">
                                                <img class="attachment_icn" src="/images/icons/icn_attch.png" alt="" />
                                                <h5 class="tickets_user_title1x">Attachments<span class="attachment_size">(80MB)</span></h5>
                                            </div>
                                            <div class="attachments_files">
                                            {props.chi.attachments_attributes.map((t, i) => {

                                                return (
                                                    <div className="attachments__file_box" onClick={() => photoUrlClicked(i)}>
                                                        <ImageLoader
                                                            style={{ width: '100%', height: '100%', cursor: 'pointer' }}
                                                            src={t.photo_urls.small}
                                                            wrapper={React.createFactory('div')}
                                                            preloader={preloader}>
                                                            Image load failed!
                                                        </ImageLoader>
                                                        <img src={t.photo_urls.small} alt="Image" />
                                                    </div>
                                                )
                                            })}
                                            </div>
                                        </div>
                                        <div className="clearfix"></div> */}
                                                    <div className="tickets_users_comments">
                                                        <h4 className="tickets_user_title2x">
                                                            {props.chi.comments_attributes.length === 1 ? `${props.chi.comments_attributes.length} Comment`
                                                                : props.chi.comments_attributes.length > 1 ? `${props.chi.comments_attributes.length} Comments` :
                                                                    `Comments`}</h4>

                                                        <div class="sent_comments">
                                                            <div className="sent_comments border_bottom">

                                                                {props.commentAttributes ? props.commentAttributes.map((c, cindex) => {
                                                                    if (editCommentObject && editCommentObject.id === c.id) {
                                                                        console.log(props.enableUpdateComment, "ENABLE COMEMNT TEST")
                                                                        return (
                                                                            <form onSubmit={handleSubmit} className="tickets_view_form">
                                                                                <div className="user_comment_area">

                                                                                    <div className="form-group">
                                                                                        <Input dataTest="preview_comment_box" type="textarea" name={`chi.[comments_attributes][${cindex}][description]`} className="form-control" rows="2" id="comment" />
                                                                                    </div>

                                                                                    <div className="comment_file_attachment">

                                                                                        <div className="upload_file_block text-righ comment_file_attachmentt" >
                                                                                            <div id="file-upload-wrapper" onClick={props.choosePhotoClicked} className="file-upload-wrapper" data-text="Up to 10 attachments (10MB each).">
                                                                                                <Input type="file" dataTest="attached_file" name="attachment_paths" hidden={true} multiple={true} onSelect={props.onPortfolioFileChange} fileRef={props.reference} accept="image/*" />
                                                                                                {/* <input name="file-upload-field" type="file" className="file-upload-field" id="file-upload-field" value="" /> */}
                                                                                            </div>

                                                                                            <div className="attachment_sec">
                                                                                                <div className="attachments_files">
                                                                                                    {props.portfolioImages && props.portfolioImages.map((url, i) => (
                                                                                                        <div className="attachments__file_box" key={i}>
                                                                                                            <img className="attachment_icn" src={url} alt="" />
                                                                                                            <div className="cancel_icon">
                                                                                                                <img src="/images/icons/icn_delete_white.png" onClick={() => props.removeImage(url, i)} alt="Image" />
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    ))
                                                                                                    }
                                                                                                    {loadCommentImages(props, c.attachments_attributes, cindex, commentPhotoUrlClicked, isCommentImageGallery, setIsCommentImageGallery, startIndex, c.id, commentId, true)}
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>

                                                                                    </div>
                                                                                </div>
                                                                                <div className="comment_buttons" style={{ marginBottom: '10px' }}>
                                                                                    <button data-test="update_comment" onClick={handleSubmit(values =>
                                                                                        props.addTicketComment({ ...values }, setEditCommentObject, editCommentObject.id))}
                                                                                        disabled={props.isLoading || props.isCommentLoading || props.isCommentAndResolveLoading || !props.enableUpdateComment ? pristine : false} className={props.isLoading || props.isCommentLoading || props.isCommentAndResolveLoading ? "btn cst_btn btn_danger mr-2 btn-wait" : "btn cst_btn btn_danger mr-2"} >{props.isLoading || props.isCommentLoading || props.isCommentAndResolveLoading ? "" : "UPDATE COMMENT"}</button>
                                                                                    <button data-test="submit_button" onClick={handleSubmit(values =>
                                                                                        props.addTicketCommentAndResolve({ ...values }, setEditCommentObject, editCommentObject.id))}
                                                                                        type="submit" disabled={props.isLoading || props.isCommentAndResolveLoading || props.isCommentLoading || !props.enableUpdateComment ? pristine : false} className={props.isLoading || props.isCommentLoading || props.isCommentAndResolveLoading ? "btn cst_btn btn_success btn-wait mr-2" : "btn cst_btn btn_success mr-2"} >{props.isLoading || props.isCommentLoading || props.isCommentAndResolveLoading ? "" : "COMMENT & RESOLVE"}</button>
                                                                                    <button data-test="cancel_btn" onClick={() => cancelEditClicked()}
                                                                                        type="button" className={"btn cst_btn btn-outline-secondary"} >{"CANCEL"}</button>
                                                                                </div>
                                                                            </form>
                                                                        )
                                                                    } else {
                                                                        return (
                                                                            <div className="media">
                                                                                <div className="user_profile_icn mr-3">
                                                                                    <img src={props.user && props.user.photo_urls ? props.user.photo_urls.small ? props.user.photo_urls.small : '/customImages/user.png' : '/customImages/user.png'} alt="Generic placeholder image" />
                                                                                </div>
                                                                                <div className="media-body user_comment_details">
                                                                                    <h5 className="mt-0 sm-title">{c.user ? c.user.first_name : ''} <span className="comment_date">{getLastUpdatedDaysCount(c.updated_at)}</span></h5>
                                                                                    {props.chi.status !== 'resolved' ?
                                                                                        <a data-test="edit_description" href="javascript:void(0)" className="edit_comment" onClick={() => editCommentClicked(c, cindex)}>Edit</a>
                                                                                        : null}
                                                                                    <p data-test="description" style={{ wordWrap: "break-word" }}>{c.description}</p>
                                                                                    <div className="attachment_sec">
                                                                                        <div className="attachments_files">
                                                                                            {loadCommentImages(props, c.attachments_attributes, cindex, commentPhotoUrlClicked, isCommentImageGallery, setIsCommentImageGallery, startIndex, c.id, commentId)}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    }
                                                                }) : null}
                                                            </div>
                                                            {props.chi.status === TicketStatus.resolved || (editCommentObject && !isEmpty(editCommentObject)) ? null :
                                                                <div className="sent_comments">
                                                                    <div className="media">

                                                                        <div className="user_profile_icn mr-3">
                                                                            <img src={props.user && props.user.photo_urls ? props.user.photo_urls.small ? props.user.photo_urls.small : '/customImages/user.png' : '/customImages/user.png'} alt="Generic placeholder image" />
                                                                        </div>

                                                                        <form onSubmit={handleSubmit} className="tickets_view_form">
                                                                            <div className="tickets_user_bottomSec">
                                                                                <div className="user_comment_area">

                                                                                    <div className="form-group">
                                                                                        <Input dataTest="preview_comment_box" type="textarea"
                                                                                            name={`chi[comments_attributes][${props.chi.comments_attributes.length}][description]`}
                                                                                            className="form-control" rows="2" id="comment" />
                                                                                    </div>

                                                                                    <div className="comment_file_attachment">

                                                                                        {showAttachment ?
                                                                                            <div className="upload_file_block text-righ comment_file_attachmentt" >

                                                                                                <div id="file-upload-wrapper" onClick={props.choosePhotoClicked} className="file-upload-wrapper" data-text="Up to 10 attachments (10MB each).">
                                                                                                    <Input type="file" dataTest="attached_file" name="attachment_paths" hidden={true} multiple={true} onSelect={props.onPortfolioFileChange} fileRef={props.reference} accept="image/*" />
                                                                                                </div>
                                                                                                <div className="attachment_sec">
                                                                                                    <div className="attachments_files">

                                                                                                        {props.portfolioImages && props.portfolioImages.map((url, i) => (

                                                                                                            <div className="attachments__file_box" key={i}>
                                                                                                                <img className="attachment_icn" src={url} alt="" />
                                                                                                                <div className="cancel_icon">
                                                                                                                    <img src="/images/icons/icn_delete_white.png" onClick={() => props.removeImage(url, i)} alt="Image" />
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        ))
                                                                                                        }
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            : ''}

                                                                                    </div>
                                                                                </div>
                                                                                <div className="people_notify_others">
                                                                                    <div className="row">
                                                                                        <div className="col-sm-12">
                                                                                            <div className="row">
                                                                                                <div className="col-sm-12 col-lg-8">
                                                                                                    {/* <div className="notify_others_div">
                                                                        <p>Your comment will be emailed to:
                                                                        <span><a href="javascript:void(0)" data-toggle="tooltip" data-placement="top" title="Natalia
                                                                            Natalia
                                                                            Natalia
                                                                            Natalia
                                                                            Natalia">5 people
                                                                        </a>
                                                                        <sub>|</sub><a href="javascript:void(0)" className="notify_others">Notify others</a></span>
                                                                   </p>

                                                                        </div> */}
                                                                                                </div>

                                                                                                {/* <div className="col-sm-12 col-lg-4">
                                                                            <div className="use_hashtag">
                                                                                <p><i className="fas fa-lightbulb"></i> ProTip! Use <a href="#">hashtags</a> to change status.</p>
                                                                            </div>
                                                                        </div> */}
                                                                                            </div>

                                                                                            <div className="comment_buttons">
                                                                                                <button data-test="add_comment_btn" onClick={handleSubmit(values =>
                                                                                                    props.addTicketComment({ ...values }))}
                                                                                                    disabled={props.isCommentLoading || props.isCommentAndResolveLoading || pristine} className={props.isCommentLoading ? "btn cst_btn btn_danger mr-2 btn-wait" : "btn cst_btn btn_danger mr-2"} >{props.isCommentLoading ? "" : "ADD COMMENT"}</button>
                                                                                                <button data-test="submit_button" onClick={handleSubmit(values =>
                                                                                                    props.addTicketCommentAndResolve({ ...values }))}
                                                                                                    type="submit" disabled={props.isCommentAndResolveLoading || props.isCommentLoading || pristine} className={props.isCommentAndResolveLoading ? "btn cst_btn btn_success btn-wait" : "btn cst_btn btn_success"} >{props.isCommentAndResolveLoading ? "" : "COMMENT & RESOLVE"}</button>
                                                                                            </div>

                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                        </form>

                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="clearfix"></div>
                                    </div>

                                    <div class="col-md-12 col-xl-3 order-1 order-xl-2 border_user_profile">
                                        <div class="user_profile_details" id="aside_chi_preview">
                                            <div class="user_profile_details_sec">
                                                <div class="user_profile_picture">
                                                    <img src={props.chi.requestor_user.photo_urls.small ? props.chi.requestor_user.photo_urls.small : "/customImages/user.png"} alt="" />
                                                </div>
                                                <div class="user_profile_title">
                                                    <h4>{props.chi.requestor_user.first_name + ' ' + props.chi.requestor_user.last_name}</h4>
                                                    {/* <h6>Little and Sons</h6> */}
                                                </div>
                                                <div class="priority_id">
                                                    <div class="row">
                                                        <div class="col-6 col-sm-4 col-md-4 col-lg-4 col-xl-6">
                                                            <div class="priority_box">
                                                                <h6>Priority</h6><span>{props.chi.priority}</span>
                                                            </div>
                                                        </div>
                                                        <div class="col-6 col-sm-8  col-md-4 col-lg-4 col-xl-6">
                                                            <div class="user_id_box">
                                                                <h6>ID</h6><span>{props.chi.id}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="created_lastActivity">
                                                    <div class="row">
                                                        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-4 col-xl-6">
                                                            <div class="created">
                                                                <h6 className="h6_title">Region</h6>
                                                            </div>
                                                        </div>
                                                        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-4 col-xl-6 gutter">
                                                            <div class="last_activity">
                                                                <h6 className="h6_title"><label class="created_date">{props.chi.requestor_user.account_timezone}</label></h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="created_lastActivity">
                                                    <div class="row">
                                                        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-4 col-xl-6">
                                                            <div class="created">
                                                                <h6 className="h6_title">Assigned To</h6>
                                                            </div>
                                                        </div>
                                                        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-4 col-xl-6 gutter">
                                                            <div class="last_activity">
                                                                <h6 className="h6_title"><label class="created_date">{props.chi.user.first_name} {' '} {props.chi.user.last_name}
                                                                </label>
                                                                </h6>
                                                                <button data-toggle="modal" data-target="#reAssignModal" className="btn btn_danger account_btn ml-2 pl-3 pr-3" style={{ cursor: "pointer", marginBottom: "10px" }}>Reassign</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="modal fade performance_modal reAssignModal"
                                                        id="reAssignModal"
                                                        tabindex="-1"
                                                        role="dialog"
                                                        aria-labelledby="exampleModalCenterTitle"
                                                        aria-hidden="true"
                                                    >
                                                        <div className="modal-dialog modal-dialog-centered custom_scrollbar" role="document">
                                                            <form onSubmit={handleSubmit}>
                                                                <div className="modal-content">
                                                                    <div className="modal-header">
                                                                        <h2 className="modal-title md_title text_danger">Reassign</h2>
                                                                    </div>
                                                                    <div className="modal-body py-5" id="reassign_select">
                                                                        <div className="quote_form ticket_form">
                                                                            <div
                                                                                className="d-flex align-items-center custom_transition pt-1 pb-2"
                                                                                style={{ padding: '30px' }}>
                                                                                <Input
                                                                                    noOptionsMessage={noUserOptionsMessage}
                                                                                    dataTest="assignRoles"
                                                                                    name={`chi[user_id]`}
                                                                                    type="select"
                                                                                    options={adminList}
                                                                                    className="custom-select input-modifier"
                                                                                />
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                    <div class="modal-footer">
                                                                        <button data-test="submit_button" type="submit" id="submit" className={props.isLoading ? "btn cst_btn btn_danger mr-2 btn-wait account_btn ml-2 pl-3 pr-3 w-25" : "btn cst_btn btn_danger mr-2"}>{props.isLoading ? "" : "Save"}</button>
                                                                        <button type="button" class="btn btn-secondary w-25" data-dismiss="modal" id="reassign_close_modal">Cancel</button>
                                                                    </div>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>

                                                </div>


                                                <div class="created_lastActivity py-4 border-bottom border-top">
                                                    <div class="row align-items-center">
                                                        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-4 col-xl-6">
                                                            <div class="created">
                                                                <h6 class="h6_title status_title m-0">Status</h6>
                                                            </div>
                                                        </div>
                                                        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-4 col-xl-6 gutter">
                                                            <div class="last_activity">
                                                                <h6 class="h6_title status_result m-0"><label class="created_date m-0">{makeFirstLetterCapital(props.chi.status)}</label></h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="created_lastActivity">
                                                    <div class="row">
                                                        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-4 col-xl-6">
                                                            <div class="created">
                                                                <h6 className="h6_title">Created</h6>
                                                            </div>
                                                        </div>
                                                        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-4 col-xl-6 gutter">
                                                            <div class="last_activity">
                                                                <h6 className="h6_title">
                                                                    <label className="created_date">{convertDateAndTimeTimezone(props.chi.created_at, props.chi.requestor_user.account_timezone)}</label>
                                                                </h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="created_lastActivity">
                                                    <div class="row">
                                                        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-4 col-xl-6">
                                                            <div class="created">
                                                                <h6 className="h6_title">Last Activity</h6>
                                                            </div>
                                                        </div>
                                                        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-4 col-xl-6 gutter">
                                                            <div class="last_activity">
                                                                <h6 className="h6_title"><label class="created_date">{convertDateAndTimeTimezone(props.chi.updated_at, props.chi.requestor_user.account_timezone)}</label></h6>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="created_lastActivity">
                                                    <div class="row">
                                                        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-4 col-xl-6">
                                                            <div class="created">
                                                                <h6 className="h6_title">Due On</h6>
                                                            </div>
                                                        </div>
                                                        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-4 col-xl-6 gutter">
                                                            <div class="last_activity">
                                                                <h6 className="h6_title"><label class="created_date">{convertDateAndTimeTimezone(props.chi.due_on, props.chi.requestor_user.account_timezone)}</label></h6>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <div className="view_inspection_block">
                                        <div className="vi_inner text-center pt-3">
                                            <button className="btn vi_btn btn-secondary cst_btn">View Inspection</button>
                                        </div>
                                    </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            )
        }
    }
}

const mapStateToProps = (state) => {
    let chi = {};
    if (!isEmpty(state.adminOrSuperAdminReducer.chi)) {
        chi = state.adminOrSuperAdminReducer.chi;
        return {
            initialValues: { chi },
            formStates: getFormValues('previewChiForm')(state)
        }
    }

}

CHIPreview = reduxForm({
    form: 'previewChiForm',
    validate,
    enableReinitialize: true
})(CHIPreview);

CHIPreview = connect(mapStateToProps)(CHIPreview)


export default CHIPreview;