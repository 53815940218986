import React, { useEffect, useState } from 'react';

import { Calendar, momentLocalizer } from 'react-big-calendar';
import "react-big-calendar/lib/css/react-big-calendar.css";
import ReactTooltip from 'react-tooltip';

import storage from '../../../../../utility/storage';
import { userRoles } from '../../../../../utility/constants/constants';
import { getUser, convertDateFormatForSchedule, startOfWeek, endOfWeek, makeFirstLetterCapital, convertTo12HourFormat, convertDateFormatV4 } from '../../../../../utility/utility';

import CalendarDateSearch from '../../../../../utility/CalendarUtilities/CalendarDateSearch/CalendarDateSearch';

import moment from 'moment';
import { customTime, tzCalendarDateTimeConverterTool, tzEndOfWeek, tzStartOfWeek, tzTimezoneMarking, tzUTCDateConverterTool_V2 } from '../../../../../utility/TimezoneOperations/timezoneUtility';
const localizer = momentLocalizer(moment)

let ShiftCalendarComponent = (props) => {

    let currentUserInfo = getUser();
    let [viewOverDate, setViewOverDate] = useState();

    useEffect(() => {
        let ele = document.getElementsByClassName('rbc-btn-group');
        if (ele) {
            ele[0].childNodes[1].textContent = "Previous"
        }
    }, [props]);

    /* For Converting the Date and Time from fetched response to showcase Shift in calendar */
    const handleShiftFixtures = (shifts) => {
        const combinedDateStorage = (shifts).filter((item)=> item.status !== "cancelled").map((shift, index) => {

            let shiftCycleInfo, startShiftTime, endShiftTime;

            shiftCycleInfo = shift && shift.shift;

            if ((shift.date.split('T')[0] < shiftCycleInfo.start_date.split('T')[0]) || (shift.date.split('T')[0] > shiftCycleInfo.end_date.split('T')[0])) {

                return [];

            } else {
                
                //let convertedStartDate = shift.shift.start_date.split('T')[0];
                let convertedStartDate = shift.date.split('T')[0];
                let convertedEndDate = null;

                let startTimeConvert = tzCalendarDateTimeConverterTool(convertedStartDate, shiftCycleInfo.start_time);

                if (!(shiftCycleInfo.start_time < shiftCycleInfo.end_time)) {
                    convertedEndDate = moment(convertDateFormatV4(shift.date.split('T')[0])).add(1, 'day').format('YYYY-MM-DD');
                } else {
                    convertedEndDate = convertedStartDate //shift.date.split('T')[0];
                }

                let endTimeConvert = tzCalendarDateTimeConverterTool(convertedEndDate, shiftCycleInfo.end_time);

                if (endTimeConvert.includes('00:00:00')) {
                    endTimeConvert = moment(endTimeConvert).subtract(1, 'second').format('YYYY-MM-DDTHH:mm:ss');
                }

                if(startTimeConvert.split('T')[0] !== convertedStartDate && endTimeConvert.split('T')[0] !== convertedEndDate){
                    startTimeConvert = moment(startTimeConvert).add(1, 'day').format('YYYY-MM-DDTHH:mm:ss');
                    endTimeConvert = moment(endTimeConvert).add(1, 'day').format('YYYY-MM-DDTHH:mm:ss');
                }

                startShiftTime = new Date(startTimeConvert);
                endShiftTime = new Date(endTimeConvert);

                return {
                    id: shift.id,
                    title: 'Shift',
                    start: startShiftTime,
                    end: endShiftTime
                }

            }

        });

        return combinedDateStorage;

    }

    const shiftListData = handleShiftFixtures(props.getSingleUserShiftPlannerList && props.getSingleUserShiftPlannerList); // Calendar Data for Shift Lists

    let eventStyleBunker = (event) => {

        var style = {
            backgroundColor: '#77DD77',
            color: '#164829',
            borderColor: '#77DD77',
            fontSize: 'larger',
            fontWeight: '600',
            borderRadius: '0px',
            // opacity: 0.8,
            // border: '0px',
            // display: 'block'
        };
        return {
            style: style
        };

    };

    const calendarCardsEventCustomizer = ({ event }) => {
        return (
            <div data-tip={event.description}>
                {/* {event.title} */}
                <ReactTooltip place="top" type="dark" effect="float" />
            </div>
        );
    };

    // For getting the Date label format for Day view
    const customDateLabelFormatter = (date, culture, localizer) => {
        let formattedDate = localizer.format(date, 'MMMM DD dddd YYYY', culture);
        return `${formattedDate}`;
    };

    /* For getting the Date label format for Week view */
    const customWeekLabelFormatter = (date, culture, localizer) => {
        const start = new Date(date.start);
        const end = new Date(date.end);

        const dateFormat = 'MMMM DD YYYY';
        return `${localizer.format(start, dateFormat, culture)} – ${localizer.format(end, dateFormat, culture)}`;
    };

    /* For chaning the customized events for calendar */
    let customizationCalendarEventFormats = {
        // timeGutterFormat: 'HH:mm',
        // dayHeaderFormat: customDateLabelFormatter, /* For getting the Date label format for Day view */
        dayRangeHeaderFormat: customWeekLabelFormatter, /* For getting the Date label format for Week view */
        // Add any other custom formats here
    };

    const handleShiftWeeklySearch = (date) => {

        let searchedFirstWeekDate = tzUTCDateConverterTool_V2(tzStartOfWeek(customTime(date.weekly_startDate)), 'from');
        let searchedLastWeekDate = tzUTCDateConverterTool_V2(tzEndOfWeek(customTime(date.weekly_endDate)), 'to');
        setViewOverDate(date.weekly_startDate);

        if (props && props.user && props.user.role && props.user.role == userRoles.FRONTLINE) {
            props.getShiftCalendarScheduledList(searchedFirstWeekDate, searchedLastWeekDate, currentUserInfo.id);
        } else if (props && props.match && props.match.params && props.match.params.userId) {
            props.getShiftCalendarScheduledList(searchedFirstWeekDate, searchedLastWeekDate, props.match.params.userId);
        }

    };

    return (
        <>

            <main className="app-content wraper_content inspection_wraper">

                <div className="tab_header search_filter_cont inspection_tab_content job_filter">
                    <form>
                        <div className="row mt-2">
                            <div className="col-xl-12 col-lg-12 text-left quotes_btn_wrap schedulesButtonWrap mw-50per mt-2">
                                <div className="media-body" style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                    <div style={{ width: '50%', backgroundColor: '#555555', border: '1px solid #555555' }}>
                                        <h5
                                            className="mt-0 font-weight-bold mb-3 text-white"
                                            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}
                                        >
                                            User Timezone
                                        </h5>
                                    </div>
                                    <div style={{ width: '50%', border: '1px solid #555555' }}>
                                        <h5
                                            className="mt-0 font-weight-bold mb-3 text-dark"
                                            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}
                                        >
                                            {/* {props.user.role == userRoles.FRONTLINE ? currentUserInfo.zoneMark : tzTimezoneMarking(props.frontlineUser && props.frontlineUser.timezone)} */}
                                            {currentUserInfo.zoneMark}
                                        </h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-12 col-lg-12 text-right quotes_btn_wrap schedulesButtonWrap mw-50per">
                                <div class="dropdown text-right mr-4">
                                    <CalendarDateSearch handleWeeklySearch={handleShiftWeeklySearch} />
                                </div>
                                {props.user.role === userRoles.FRONTLINE ? null : <div className="dropdown text-right mr-4">
                                    <button type='button' onClick={() => { props.handleNewShiftClicked({ frontlineUser: props.frontlineUser }); }} className="btn btn-secondary cst_btn"><i className="fa fa-plus icn_plus"></i> Add Shift </button>
                                </div>}
                            </div>
                        </div>
                    </form>
                </div>

                <section className="account_sec inspection_tab_content appcontent_Inner availability_calendar" id="schedule_calender">
                    <div className="quotes_table_content accounts_table_contnet table-responsive overflow-auto">
                        <div className="table quotes_table user_react_table">
                            <div className="mt-4">

                                <Calendar
                                    localizer={localizer}
                                    startAccessor="start"
                                    endAccessor="end"
                                    showMultiDayTimes
                                    events={shiftListData}
                                    eventPropGetter={eventStyleBunker}
                                    defaultDate={new Date()}
                                    date={viewOverDate} //Display the SEarch field captured dates
                                    defaultView="week"
                                    views={['week']}
                                    components={{
                                        event: calendarCardsEventCustomizer
                                    }}
                                    formats={customizationCalendarEventFormats} //Using for chaning the events of calendar customwise
                                    onNavigate={(date, dataType) => {
                                        setViewOverDate(date);
                                        if (dataType == 'week') {
                                            setTimeout(() => {

                                                storage.set('liveDay', date);
                                                let firstWeekDate = tzUTCDateConverterTool_V2(tzStartOfWeek(customTime(date)), 'from');
                                                let lastWeekDate = tzUTCDateConverterTool_V2(tzEndOfWeek(customTime(date)), 'to');

                                                if (props && props.user && props.user.role && props.user.role == userRoles.FRONTLINE) {
                                                    props.getShiftCalendarScheduledList(firstWeekDate, lastWeekDate, currentUserInfo.id);
                                                } else if (props && props.match && props.match.params && props.match.params.userId) {
                                                    props.getShiftCalendarScheduledList(firstWeekDate, lastWeekDate, props.match.params.userId);
                                                }

                                            }, 500);
                                        }
                                    }}
                                    style={{ height: 600 }}
                                // timeslots={1}
                                // step={60}
                                />

                            </div>
                        </div>
                    </div>
                </section>

            </main>

        </>
    );
};

export default ShiftCalendarComponent;