import React, { useState } from 'react';
import { reduxForm, FieldArray } from 'redux-form';
import { RolesOptions, routes } from '../../../../utility/constants/constants';
import { Input } from '../../UI/InputElement/InputElement';
import { newLineRatingValidator as validate } from '../../../../utility/validator/validator';
import { RatingTypes, inspectorChoice } from '../../../../utility/constants/constants';
import isEmpty from '../../../../utility/IsEmptyValidator';
import { connect } from 'react-redux';
import { showConfirmAlert } from '../../../../utility/successAlert/ConfirmAlert';
var moment = require('moment');
const cloneDeep = require('clone-deep');

const LineItems = (props) => {
    var radios = document.querySelectorAll('input[type="radio"]');
    for (var i=0; i<radios.length; i++) {
        radios[i].onfocus = function() {
            this.blur();
        }
    }
    const { fields } = props;
    const { isEdit } = props[0];
    //  
    const deleteLineItem = (fields, index, lineItem, ratingId) => {
        if (isEdit && props[0].updateRating.rating_options_attributes.length > 0) {
            props[0].updateRating.rating_options_attributes.splice(index, 1);
            // props[0].updateInspectionForm.sections_attributes[props[0].index].line_items_attributes.splice([index], 1);
            props[0].setUpdateRating(props[0].updateRating);
            props[0].deleteRatingLineItem(lineItem.id, ratingId);
        }
        fields.remove(index);
    }

    return (
        <>
            <div className="card-body rating_card_body">
                {fields.map((member, index) =>
                    <div className="contact_info rating_info">
                        <div className="form-group rating_form_group">
                            <div className="input-group">
                                <Input name={`${member}.name`} errorType="emptyText" type="text" className="form-control input-modifier" placeholder="Choice Name" aria-label="Username" aria-describedby="basic-addon1" />
                            </div>
                            <div className="input-group-prepend">
                                <div className="input-group-text" id="basic-addon1">
                                    <div className="dropdown cst_dropdown delete_icn_block">
                                        <a onClick={() => deleteLineItem(fields, index, props[0].updateRating.rating_options_attributes[index], props[0].updateRating.id)} href="javascript:void(0)">
                                            <i className="fas fa-trash-alt cst_icon"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                <div className="card_footer">
                    <div className="btn_block text-left">
                        <button type="button" className="btn cst_btn btn_danger_outline mr-2" onClick={() => fields.push({})}>ADD ANOTHER CHOICE</button>
                    </div>
                </div>
            </div>

        </>
    )
}

let List = (props) => {

    const { handleSubmit, pristine, reset, submitting, error } = props;
    const [updateRating, setUpdateRating] = useState(cloneDeep(props.initialValues.rating_type));

    return (
        <form onSubmit={handleSubmit}>
            <div className={RatingTypes.List === props.ratingType ? "tab-pane fade list_tab_content active show" : "tab-pane fade list_tab_content"} id="list" role="tabpanel" aria-labelledby="list-tab">
                <div className="card setting_form_con edit_rating">
                    <div className="card_header d_flex" style={{padding: "0.5rem 1.75rem"}}>
                        <h5 className="sm_title">
                            <a href="javascript:void(0)" style={{ cursor: 'pointer' }}
                                onClick={() => props.history.goBack()} className="mr-2">
                                <img src="/images/icons/icn_back_arrow.png" alt="Icon" /></a>
                            <a href="javascript:void(0)" className="mr-2"></a>
                            {props.isEdit ? "Update Rating" : "New Rating"}</h5>
                            {props.isEdit ? <> <div style={{display: "grid"}}> <span> Created At : {moment(props.rating.created_at).format('MM-DD-YYYY')}</span> <span>Updated At : {moment(props.rating.updated_at).format('MM-DD-YYYY') }</span> <span>Last Edited By : TBD</span> </div>
                                </> : null}
                    </div>
                    <div className="card-body inner_card">
                        <div className="form_info">
                            <div className="row">
                                <div className="col-md-12">
                                    <p className="cst_para">Lists provide your inspectors with customized choices to pick from that won't effect the inspection score. For example, you can create a list of employee names to later run reports for inspections of those user's areas.</p>
                                    <div className="form-group cst_form_group">
                                        <label className="form_title lable_modifier">Title</label>
                                        <Input name="rating_type[name]" type="text" className="form-control input-modifier" placeholder="Name Of List" />
                                        <small id="" className="form-text">e.g. Employee Names, corrective actions, room numbers.</small>
                                    </div>
                                    <div className="form-group cst_form_group">
                                        <label className="form_title lable_modifier">Inspector can pick</label>
                                        <Input name="rating_type[choice]" type="select" options={inspectorChoice} className="custom-select input-modifier select_modifier" />
                                    </div>
                                    <div className="form-group">
                                    <Input type="radio" labelClass="custom-control-label" forLable={"customRadio1"} value={"false"} label={"Active"} className="custom-control-input" id="customRadio1" name="rating_type[hide]" />
                                    <Input type="radio" labelClass="custom-control-label" forLable={"customRadio2"} value={"true"} label={"In-active"} className="custom-control-input" id="customRadio2" name="rating_type[hide]" />
                                </div>
                                </div>
                            </div>
                        </div>
                        <label className="lable_modifier">List Choices</label>
                        <hr className="cst_hr" />
                        <div className="card rating_form_card edit_info_form mb-0">
                            <FieldArray name="rating_type[rating_options_attributes]" component={LineItems}
                                props={[{
                                    isEdit: props.isEdit,
                                    updateRating: updateRating,
                                    setUpdateRating: setUpdateRating,
                                    deleteRatingLineItem: props.deleteRatingLineItem
                                }]}
                            />
                        </div>
                    </div>
                    <div className="card_footer">
                        <div className="btn_block text-center">
                            <button button="submit" className={props.isLoading ? "btn cst_btn btn_danger mr-2 btn-wait" : "btn cst_btn btn_danger mr-2"}>{props.isLoading ? "" : "Save"}</button>
                            <button button="button" onClick={() => props.cancelClicked() } className="btn cst_btn btn-outline-secondary  cancel_btnnew">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    )
}

const mapStateToProps = (state) => {
    let rating_type = {};
    if (isEmpty(state.adminOrSuperAdminReducer.rating)) {
        rating_type['rating_options_attributes'] = [{}, {}]
        rating_type['hide'] = 'false'
    } else {
        state.adminOrSuperAdminReducer.rating.hide = state.adminOrSuperAdminReducer.rating.hide.toString()
        const ratings = cloneDeep(state.adminOrSuperAdminReducer.rating);
        rating_type = ratings

    }


    return { initialValues: { rating_type } }
}

List = reduxForm({
    form: 'newListRatingForm',
    validate,
    enableReinitialize: true
})(List);

List = connect(mapStateToProps)(List)

export default List;