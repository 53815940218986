import storage from "../../../../utility/storage";
import SuccessAlert from "../../../../utility/successAlert/successAlert";
import { formAuditLogBookActionTypes } from "../../../actions/FormAuditModAction/LogBookAction/LogbookTypes";

export const initialState = {
    dataGenerated: false,
    teamManagementToggle: false,
    os1Toggle: false,
    isLoading: false,
    isEditLog: false,
    isUpdateTrainingLog: false,
    trainingLog: null,
    isUpdateSupervisor: false,
    supervisorDetails: null,
    isUpdateManager: false,
    managerDetails: null,
    restroomDetails: null,
    isUpdateRestroom: false,

    isRestroomPortering: false,
    restroomPortering_list: [],
    restroomPortering_total_pages: 1,
    restroomPortering_no_of_rows: 10,
    restroomPorteringCurPage: 0,
    total_restroomPortering_count: '',
};

const updateObject = (oldState, updatedProps) => {
    return {
        ...oldState,
        ...updatedProps,
    };
};

export const LogBookReducer = (state = initialState, action) => {
    switch (action.type) {
        case formAuditLogBookActionTypes.GET_SINGLE_RESTROOM_DETAILS_PENDING:
            return updateObject(state, {
                isLoading: false,
                isEditLog: false,
                isUpdateRestroom: true,
                restroomDetails: null,
            });

        case formAuditLogBookActionTypes.GET_SINGLE_RESTROOM_DETAILS_FULFILLED:
            return updateObject(state, {
                isLoading: false,
                isEditLog: false,
                isUpdateRestroom: false,
                restroomDetails: action.payload,
            });

        case formAuditLogBookActionTypes.GET_ALL_RESTROOM_PORTERING_PENDING:
            return updateObject(state, {
                isLoading: true,
                isRestroomPortering: true,
                restroomPortering_list: [],
            });
        case formAuditLogBookActionTypes.GET_ALL_RESTROOM_PORTERING_FULFILLED:
            return updateObject(state, {
                isLoading: false,
                dataGenerated: false,
                isRestroomPortering: false,
                restroomPortering_list: action.payload.restroomPortering_list,
                restroomPortering_total_pages: action.payload.restroomPortering_total_pages,
                restroomPortering_no_of_rows: action.payload.restroomPortering_no_of_rows,
                restroomPorteringCurPage: action.payload.restroomPorteringCurPage,
                total_restroomPortering_count: action.payload.total_restroomPortering_count,
            });


            case formAuditLogBookActionTypes.ADD_SINGLE_RESTROOM_DETAILS_PENDING:
            return updateObject(state, {
                isLoading: true,
                isEditLog: false,
                isUpdateRestroom: false,
                restroomDetails: null,
            });

        case formAuditLogBookActionTypes.ADD_SINGLE_RESTROOM_DETAILS_FULFILLED:
            
            return updateObject(state, {
                isLoading: false,
                isEditLog: false,
                isUpdateRestroom: false,
                restroomDetails: action.payload,
            });


            case formAuditLogBookActionTypes.ADD_SINGLE_RESTROOM_DETAILS_REJECTED:
            
            return updateObject(state, {
                isLoading: false,
                isEditLog: false,
                isUpdateRestroom: false,
                restroomDetails: action.payload,
            });


            case formAuditLogBookActionTypes.EDIT_SINGLE_RESTROOM_DETAILS_PENDING:
                
            return updateObject(state, {
                isLoading: true,
                isEditLog: false,
                isUpdateRestroom: false,
                restroomDetails: null,
            });

        case formAuditLogBookActionTypes.EDIT_SINGLE_RESTROOM_DETAILS_FULFILLED:
            
            return updateObject(state, {
                isLoading: false,
                isEditLog: false,
                isUpdateRestroom: false,
                restroomDetails: action.payload,
            });


            case formAuditLogBookActionTypes.ADD_SINGLE_RESTROOM_DETAILS_REJECTED:
            
            return updateObject(state, {
                isLoading: false,
                isEditLog: false,
                isUpdateRestroom: false,
                restroomDetails: action.payload,
            });

            
        



        case formAuditLogBookActionTypes.RESTROOM_PORTERING_PAGE_CLICKED:
            return updateObject(state, { restroomPorteringCurPage: action.payload });

        default:
            return state;
    }
};
