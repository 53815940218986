import React, { useEffect, useState } from 'react';
import ReactTable from 'react-table-v6';
import '../../../styles/custom.css';
import { makeFirstLetterCapital, CustomNoDataComponent, getUser } from '../../../utility/utility';
import { MagicSpinner, PushSpinner, SwishSpinner, WaveSpinner, ClapSpinner } from 'react-spinners-kit';
import 'react-table-v6/react-table.css';
import * as actions from '../../../redux/actions/index';
import { useSelector, useDispatch } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { FaArrowDown, FaArrowUp } from "react-icons/fa";
import { userRoles } from '../../../utility/constants/constants';
import ImageLoader from 'react-imageloader';
import storage from '../../../utility/storage';

const UserTable = (props) => {

    const dispatch = useDispatch();
    const SearchValue = useSelector(state => state.adminOrSuperAdminReducer.searchUserValues);
    const DateGenerate = useSelector(state => state.adminOrSuperAdminReducer.dataGenerated);
    const SearchOrResetUserClicked = useSelector(state => state.adminOrSuperAdminReducer.SearchOrResetUserClicked);
    const No_of_pages = useSelector(state => state.adminOrSuperAdminReducer.users_page);
    const No_of_rows = useSelector(state => state.adminOrSuperAdminReducer.users_no_of_rows);
    const userPage = useSelector(state => state.adminOrSuperAdminReducer.userCurPage);
    const user_rows = useSelector(state => state.adminOrSuperAdminReducer.user_rows);

    function preloader() {
        return <img style={{ height: '35px', width: '35px', borderRadius: '50px', float: 'left' }} src="/images/gif/giphy.gif" />;
    }

    const currentUser = storage.get('user');

    useEffect(() => {
        if (SearchOrResetUserClicked) {
            dispatch(actions.resetSearchOrResetValue())
        } else {
        const users_no_of_rows = 10;
        setTablePageFn(0)
        const page = 1;
        const sorted = [];
        const filtered = [];
        SearchValue.active = props.activeUsers
        props.getUsers(
            SearchValue,
            users_no_of_rows, page,
            sorted,
            filtered);
        }
      }, [props.activeUsers]);

    const data = props.usersList.map(user => {

        return {
            image:
                user.user.photo_urls.small ?
                    <ImageLoader
                        imgProps={{ style: { height: '35px', width: '35px', borderRadius: '50px', float: 'left' } }}
                        style={{ height: '35px', width: '35px', borderRadius: '50px', float: 'left' }}
                        src={user.user.photo_urls.small}
                        wrapper={React.createFactory('div')}
                        preloader={preloader}>
                        Image load failed!
                            </ImageLoader>
                    :
                    <img src="/customImages/user.png" style={{ height: '35px', width: '35px', borderRadius: '50px', float: 'left' }} />
            // <img style={{ height: '35px', width: '35px', borderRadius: '50px' }} src={user.user.photo_urls.small ? user.user.photo_urls.small : "/customImages/user.png"} />
            ,
            contactName: makeFirstLetterCapital(user.user.first_name) + ' ' + makeFirstLetterCapital(user.user.last_name),
            // lastName: user.user.last_name,
            position: user.user.position ? user.user.position.name : '',
            role: makeFirstLetterCapital(user.user.role === userRoles.CLIENT ? 'Customer' : user.user.role),
            region: user.user.region ? user.user.region.name : '',
            // phonenumber: '810-191-8851',
            email: user.user.email,
            mode: makeFirstLetterCapital(user.user.mode),
            resendEmail: user.user.sign_in_count === 0 && user.user.role !== 'superadmin' ? 
                <>
                    <span className="mr_20 account_edit_icn">
                            <i data-tip="Resend Email" className="fas fa-envelope" aria-hidden="true" onClick={() => props.welcomeMailForUser(user.user.id)}></i>
                    </span>
                    <ReactTooltip place="top" type="dark" effect="solid" />
                </>  : '-',
            // address: '4909 Murphy Canyon Rd # 200, San Diego, CA 92123',
            actions: user.user.role === userRoles.SUPER_ADMIN ? null
            :
            <>
                {/* <span data-tip="View User" className="mr_20 account_edit_icn" onClick={() => props.previewUserClicked(user)}><i className="fas fa-eye cst_icon mr-2" aria-hidden="true"></i></span>
                <ReactTooltip place="top" type="dark" effect="solid" /> */}
               {user.user.role === userRoles.SUPER_ADMIN ? null : <span data-test="users_eye" data-tip="Notification" className="mr_20 account_edit_icn" onClick={() => props.emailPreferencesClicked(user)}>
                    <i class="fas fa-bell"></i>
                </span>}
                <ReactTooltip place="top" type="dark" effect="solid" />

                <span data-test="users_edit" data-tip="Edit" className="mr_20 account_edit_icn" onClick={() => props.editUserClicked(user)}><i class="fa fa-pencil" aria-hidden="true"></i>
                </span>
                {user.user.role === userRoles.SUPER_ADMIN ? null :
                <span data-test="users_trash"  data-tip="delete" className="mr_20 account_edit_icn" onClick={() => props.handleClickDeleteModel(user)}><i class="fa fa-trash" aria-hidden="true"></i>
                </span>}
            </>
        }
    })


    const columns = [
        {
            Header: '',
            accessor: 'image', // String-based value accessors!,
            width: 55
        }, {
            Header: <><span>Contact Name</span><FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
            accessor: 'contactName', // String-based value accessors!,
            width: 150,
            show: props.usersList.length !== 0,
        },
        // {
        //     Header: <><span>Last Name</span><FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
        //     accessor: 'lastName',
        //     show: props.usersList.length !== 0,
        //     // Cell: props => <span className='number'>{props.value}</span> // Custom cell components!
        // },
        {
            Header: <><span>Position</span><FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
            accessor: 'position',
            show: props.usersList.length !== 0,
        }, {
            // id: 'friendName', // Required because our accessor is not a string
            Header: <><span>Role</span><FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
            accessor: 'role',
            show: props.usersList.length !== 0, // Custom value accessors!
        }, {
            // id: 'friendName', // Required because our accessor is not a string
            Header: <><span>Region</span><FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
            accessor: 'region',
            show: props.usersList.length !== 0, // Custom value accessors!
        }, {
            // id: 'friendName', // Required because our accessor is not a string
            Header: <><span>Mode</span><FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
            accessor: 'mode',
            show: props.usersList.length !== 0, // Custom value accessors!
        }, {
            Header: <><span>Email</span><FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
            accessor: 'email',
            show: props.usersList.length !== 0,
            width: 230
        }, {
            Header: <><span>Welcome Email</span> </>,
            accessor: 'resendEmail',
            width: 150,
            show: props.usersList.length !== 0,
        }, {
            Header: ' ',
            accessor: 'actions',
            width: 135
            // show: false,
        }
    ]

    const [tablePage, setTablePage] = useState(0)
    const tablePageNew =  React.useRef()

    if(userPage !== tablePageNew.current) {
        tablePageNew.current = userPage;
    }
    const setTablePageFn = (page) => {
        setTablePage(page)
        props.userPageClicked(page)
        tablePageNew.current = page;
    }

    const fetchData = (state, instance) => {

        if (SearchOrResetUserClicked) {
            dispatch(actions.resetSearchOrResetValue())
        } else {

            if (!DateGenerate && !props.isUserLoading) {
                state.page = 2;
                const users_no_of_rows = state.pageSize;
                let page = tablePageNew.current >= 0 ? tablePageNew.current + 1 : userPage + 1;
                if(user_rows != users_no_of_rows) {
                    page = 1;
                    setTablePageFn(0)
                }
                const sorted = state.sorted;
                const filtered = state.filtered;
                SearchValue.active = props.activeUsers
                props.getUsers(
                    SearchValue,
                    users_no_of_rows, page,
                    sorted,
                    filtered);
            }
        }
        // }
        // }
    }

    return (
        SearchOrResetUserClicked && props.isUserLoading ?
            <div className="loader_btn_block">
                <ClapSpinner backColor="#89073c" size={40} frontColor="#89073c8a" />
            </div>
            :
            <ReactTable
                data={data}
                loading={props.isUserLoading}
                defaultPageSize={No_of_rows}
                minRows={0}
                manual
                onFetchData={fetchData}
                pages={No_of_pages}
                gotoPage={userPage}
                page = { userPage }
                onPageChange={(page) => {setTablePageFn(page)}}
                // onPageSizeChange={props.onUserPageSizeChange}
                showPaginationTop={true}
                showPagination={props.usersList.length !== 0}
                columns={columns}
                // style={{marginTop: '10px'}}
                LoadingComponent={() =>
                    props.isUserLoading ?
                        <div style={{
                            display: "block",
                            position: "absolute",
                            left: 0,
                            height: '100%',
                            right: 0,
                            background: "rgba(255,255,255,0.8)",
                            transition: "all .3s ease",
                            top: 0,
                            bottom: 0,
                            textAlign: "center"
                        }}
                            className="loader_btn_block">
                            <ClapSpinner backColor="#89073c" size={40} frontColor="#89073c8a" />
                        </div> : null}
                NoDataComponent={() => CustomNoDataComponent(props.isUserLoading, 'No Users Found')}
                getTheadThProps={(state, rowInfo, column, instance) => {
                    // console.log(column);
                    return {
                        style: {
                            borderTop: 'none',
                            borderBottom: 'none',
                            borderRight: 'none',
                            textAlign: 'center',
                            color: '#566975',
                            fontSize: '14px',
                            fontWeight: '600',
                            whiteSpace: 'nowrap',
                            float: 'left',
                        }
                    }
                }
                }
                getTrProps={(state, rowInfo, column, instance) => {
                    // onclick = () => userDetail(rowInfo ? rowInfo.row.user : rowInfo);
                    console.log(column);
                    return {
                        className: 'react-table-tr-element',
                        style: {
                            border: '1px solid #c7cdd1',
                            transition: '.3s ease-in-out',
                            width: '100%',
                            // borderRadius: '4px',
                            // height: '80px',
                            marginTop: '5px',
                            float: 'left'
                        }
                    }

                }}
                getTdProps={(state, rowInfo, column, instance) => {

                    if (column.id == 'image') {
                        return {
                            style: {
                                marginTop: '0px'
                            }
                        }
                    } else {
                        return {
                            className: 'react-td-element',
                        }
                        //  {
                        //   style: {
                        //     borderTop: 'none',
                        //     verticalAlign: 'middle',
                        //     fontSize: '14px',
                        //     color: '#2b3034',
                        //     marginTop: '10px !important',
                        //     textAlign: 'center',
                        //   }
                        // }
                    }
                }
                }
                // getPaginationProps={() => {
                //     return {
                //         style: {
                //             marginTop: '50px',
                //             border: 'none',
                //             boxShadow: 'none'
                //         }
                //     }
                // }
                // }
                getProps={() => {
                    return {
                        style: {
                            border: 'none'
                        }
                    }
                }}
            />
    )
}

export default UserTable;
