import React, { useEffect, useState } from 'react'
import CheckListTaskForm from './CheckListTaskForm'
import { Input } from '../../../../UI/InputElement/reactFormHookInputField';
import {useFieldArray,useFormContext} from 'react-hook-form'
import withFormHoc from '../../../../../../hoc/withReactHookForm/withReactHookForm';
import AssetsFormSkeleton from '../../../../Inventory/Assets/NewAssetsForm/AssetsFormSkeleton';
import { useSelector } from 'react-redux';
import { EDIT, VIEW } from '../../../../../../utility/constants/constants';
import {DevTool} from '@hookform/devtools'
import { async } from '@firebase/util';
function CheckLIstManagerPage(props) {
  const {isUpdateManager,customersList,managersList,isLoading}=useSelector((state)=>state.FormAuditModReducer)

    const {fields,append,remove,move}=useFieldArray({control:props.control,name:"items"})
     const current= new Date()

     const getManagersList = (manager) => {
      if (manager && manager.length > 0) {
        return manager && manager.map((data) => {
          return {
            value: data.id,
            label: data.first_name + ' ' + data.last_name,
          }
        })
      } else {
        return []
      }
    };


  const getCustomersList = (customer) => {
    if (customer && customer.length > 0) {
      return customer && customer.map((data) => {
        return {
          value: data.id,
          label: data.name ,
        }
      })
    } else {
      return []
    }
  };

  

  // const getEmployeeList = (employee) => {
  //   if (employee && employee.length > 0) {
  //     return employee && employee.map((data) => {
  //       return {
  //         value: data.id,
  //         label: data.first_name + ' ' + data.last_name,
  //       }
  //     })
  //   } else {
  //     return []
  //   }
  // };
  
  const [listOfCustomer,setCustomerList]=useState(getCustomersList(customersList))
  
  const [listOfManager,setManagerList]=useState(getManagersList(managersList))
  
  useEffect(()=>{
     setCustomerList(getCustomersList(customersList))
  },[customersList])

  // useEffect(()=>{
  //   getJobList(jobList)
  // },[jobList])

  useEffect(()=>{
    setManagerList(getManagersList(managersList))
  },[managersList])

//   useEffect(()=>{
//     setEmployeeList(getEmployeeList(employeeList))
//  },[employeeList])
     
        

        const defaultTaskData={        
          supervisor_id:"",
          account_id:"",
          cleaning_workers:"",
        team_checklist_completed:"",
        completed:""
        }

      const addTaskToList=(e)=>{
      e.preventDefault();
      append(defaultTaskData)
      }

    const removeFromTaskList=(index)=>(e)=>{
    e.preventDefault();
    
    remove(index)
    }

    if(isUpdateManager){
      
      return (
        <AssetsFormSkeleton />
    )
    }
   
    

  return (
    <main class="app-content wraper_content chart_section">
        <section class="teammanage_formmain">
        <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="team_manage_head">
              <h1 class="tm_top_head">{props?.isEdit ? `${EDIT} Team Checklist Manager Summary` : props?.isView ? `${VIEW} Team Checklist Manager Summary` : "Team Checklist Manager Summary"} </h1>
            </div>
          </div>
          <form class="col-lg-12" noValidate onSubmit={props.handleSubmit(async (data)=>{
             props.onSubmit(data)
          })}>
            <div class="tm_form_wrapper">
              <div class="tm_form_wrap">
                <div class="tm_form_innner">
                  <div class="form">
                    <div class="row">
                      <div class="col-md-6 col-sm-12">
                      <div class="form-group">
                          
                        <Input
                                type="inputSelect"
                                labelClasses="form-label"
                                control={props.control}
                                controller={props.controller}
                                selectLabel="Organization"
                                placeholder="Please Select"
                                selectName="company_id"
                                id="company_id"
                                selectOptions={listOfCustomer}
                                required={props.register('company_id', {
                                required: "This field is required"})}
                                validationErrorMessage={props.errors['company_id']?.message} 
                                selectClasses="form-select"  
                                disabled={props?.isView}
                            />
                            
                          {/* <div class="dropdown_caret"><img src="/images/icons/dropdown_caret.svg" alt="dropdown_caret"/></div> */}
                        </div>
                      </div>
                      <div class="col-md-6 col-sm-12">
                        <div class="form-group">
                          <Input
                          type="inputSelect"
                          labelClasses="form-label"
                          control={props.control}
                          controller={props.controller}
                          selectLabel="Manager"
                          placeholder="Please Select"
                          selectName="manager_id"
                          id="manager_id"
                          selectOptions={listOfManager}
                          required={props.register('manager_id', {
                            required: "This field is required"})}
                          validationErrorMessage={props.errors['manager_id']?.message}
                          disabled={props?.isView}
                          selectClasses="form-select"  
                          />
                        {/* <FloatingNormalSelectField
                          required
                          label="Manager"
                          selectName="manager"
                          selectOptions={['United States', 'Italy', 'Egypt', 'Iran']}
                          formControlProps={props.register('manager', {
                          required: "Manager is required"})}
                          validationErrorMessage={props.errors['manager']?.message}
                           /> */}

                        {/* <Input  
                                type="inputSelect"
                                labelClasses="form-label"
                                control={props.control}
                                controller={props.controller}
                                selectLabel="Manager"
                                selectName="manager"
                                id="Manager"
                                selectOptions={[{value: 'RED', label: 'Red'}, {value: 'BLUE', label: 'Blue'}]}
                                required={props.register('manager', {
                                required: "Manager is required"})}
                                validationErrorMessage={props.errors['manager']?.message} 
                                selectClasses="form-select"  
                            /> */}

                          {/* <div class="dropdown_caret"><img src="/images/icons/dropdown_caret.svg" alt="dropdown_caret"/></div> */}
                        </div>
                      </div>
                      <div class="col-md-6 col-sm-12">
                        <div class="form-group">
                        <Input 
                            type="date"
                            datePickerType="month-year"
                            formControlClasses="form-control"
                            id="month" 
                            placeholder="Month and Year"
                            control={props.control}
                            controller={props.controller}
                            selectName={"month"}
                            dateFormat="MMMM - YYYY"
                            minDate={new Date().setDate(current.getDate() - 400)}
                            preventReadOnly={false}
                            label="Month and Year"
                            formControlProps={props.register('month', {
                                required: "This field is required", maxLength: 80
                              })}
                            validationErrorMessage={props.errors?.month?.message ?? ""}
                            disabled={props?.isView}
                            />
                          {/* <div class="calendar_icon">
                            <img
                              src="/images/icons/calendar.svg"
                              alt="calendar"
                            />
                          </div> */}
                        </div>
                      </div>
                      {/* <div class="col-md-6 col-sm-12">
                        <div class="form-group mb-0">
                        
                        <Input 
                            type="date"
                            datePickerType="year"
                            formControlClasses="form-control"
                            id="year" 
                            placeholder="Year"
                            control={props.control}
                            controller={props.controller}
                            selectName={"year"}
                            dateFormat="YYYY"
                            // minDate={new Date().setDate(current.getDate() - 400)}
                            // preventReadOnly={false}
                            label="Year"
                            formControlProps={props.register('year', {
                                required: "This field is required", maxLength: 80
                              })}
                            
                            validationErrorMessage={props.errors?.year?.message ?? ""}
                            disabled={props?.isView}
                            />
                            
                          
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
              
              
              {
                fields.map((field,index)=>(
                  <CheckListTaskForm isView={props?.isView}  controller={props.controller} field={field} control={props.control}  index={index} register={props.register} errors={props.errors} removeFromTaskList={removeFromTaskList}  getValues={props.getValues} setValue={props.setValue} key={field.id}/>
                ))
              }

              {
                 !props?.isEdit && !props?.isView ? <div  class="action_button">
                 <span onClick={addTaskToList} >+ Add More</span>
               </div> : null
              }
             
              {/* Double Form */}
              {/* <div class="tm_form_wrap">
                <div class="tm_form_innner">
                  <div class="form">
                    <div class="row">
                      <div class="col-md-6 col-sm-12">
                        <div class="form-group">
                          <label for="disabledSelect" class="form-label">Supervisor</label
                          >
                          <select id="disabledSelect" class="form-select">
                            <option>Please Select</option>
                            <option>1</option>
                            <option>2</option>
                            <option>3</option>
                            <option>4</option>
                          </select>
                          <div class="dropdown_caret"><img src="images/icons/dropdown_caret.svg" alt="dropdown_caret"/></div>
                        </div>
                      </div>
                      <div class="col-md-6 col-sm-12">
                        <div class="form-group">
                          <label for="disabledSelect" class="form-label">Location</label
                          >
                          <select id="disabledSelect" class="form-select">
                            <option>Please Select</option>
                            <option>1990 Rey test</option>
                            <option>2</option>
                            <option>3</option>
                            <option>4</option>
                          </select>
                          <div class="dropdown_caret"><img src="images/icons/dropdown_caret.svg" alt="dropdown_caret"/></div>
                        </div>
                      </div>
                      <div class="col-md-6 col-sm-12">
                        <div class="form-group">
                          <label for="jobname" class="form-label"># of Cleaning workers</label
                          >
                          <input
                            type="text"
                            class="form-control"
                            id="jobname"
                            aria-describedby=""
                            placeholder="Type here"
                          />
                        </div>
                      </div>
                      <div class="col-md-6 col-sm-12">
                        <div class="form-group mb-0">
                          <label for="jobname" class="form-label"># of Team checklist completed</label
                          >
                          <input
                            type="text"
                            class="form-control"
                            id="jobname"
                            aria-describedby=""
                            placeholder="Type here"
                          />
                        </div>
                      </div>
                      <div class="col-md-6 col-sm-12">
                        <div class="form-group mb-0">
                          <label for="jobname" class="form-label">% Completed
                          </label>
                          <input
                            type="text"
                            class="form-control"
                            id="jobname"
                            aria-describedby=""
                            placeholder="Type here"
                          />
                        </div>
                      </div>
                      <div class="col-md-6 col-sm-12">
                        <div class="form-group mb-0">
                          <label for="jobname" class="form-label"> </label>
                          <div class="action_button pt-3">
                            <a href="javascript:void(0)">Delete Selection</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}

              <div class="row">
                <div class="col-sm-12">
                  <div class="submitcancel_btn">
                    <button type="button" class="btn cst_btn btn-outline-secondary  cancel_btnnew" onClick={props?.resetForm}>
                      Cancel
                    </button>
                    {
                        !props?.isView ? <button type="submit" className={isLoading ? "btn cst_btn btn_danger btn-wait" : "btn cst_btn btn_danger"}> 
                        {isLoading ? ""  : props.isEdit ?  "Update"  : "Submit"}
                      </button> : null
                      }
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
    </main>
  )
}

const CheckLIstManagerPageHoc = withFormHoc(CheckLIstManagerPage)
export {CheckLIstManagerPage}
export default CheckLIstManagerPageHoc
