import React from 'react';
import * as actions from '../../../redux/actions/index';
import { connect, useSelector } from 'react-redux';
import { GetFormatedDate, getNextMonthDate, getNextYearDate, getPreviousMonthDate, getPreviousYearDate, getUser, get_next_week_start, get_previous_week_start } from '../../../utility/utility';
import { currentData, userRoles, routes } from '../../../utility/constants/constants';
import $, { get } from 'jquery';
import storage from '../../../utility/storage';
import cloneDeep from 'clone-deep';
import { reset } from 'redux-form';
import KpiTabs from '../../component/KpiDashboard/KpiTabs';
import KpiTicket from '../../component/KpiDashboard/KpiTicket';


class KpiTicketContainer extends React.Component {
    // componentDidMount = () => {
    //     this.props.getAllCHIServys();
    // }
    constructor(props) {
        super(props);
        this.state = {
            // isWeek: true,
            // isMonth: false,
            // isYear: false,
        }
    }

componentDidMount = () => { 
    storage.set('companyId', this.props.match.params.companyId)
    const userDetails = getUser();
    this.props.getKpiTicketCountDashboardData(currentData.MONTH, GetFormatedDate(this.props.currentDate), '')
    this.props.getKpiTicketGraphDashboardData(currentData.MONTH, GetFormatedDate(this.props.currentDate), '')
    this.props.getKpiTicketDaysOpenDashboardData(currentData.MONTH, GetFormatedDate(this.props.currentDate), '')
    this.props.getKpiTicketHighUsersDashboardData(currentData.MONTH, GetFormatedDate(this.props.currentDate), '')
    this.props.getKpiTicketJobsDashboardData(currentData.MONTH, GetFormatedDate(this.props.currentDate), '')
    this.props.updateCurrentDate(this.props.currentDate);
    this.props.updateWeekMonthYear(false, true, false);
    this.props.getModeAccountUsers(userDetails.mode);
    // this.props.getModeAccounts(userDetails.mode);
}

nextWeekMonthYearCliked = () => {
    const userDetails = getUser();
    if (this.props.isWeek) {
        const date = get_next_week_start(this.props.currentDate);
        this.props.getKpiTicketCountDashboardData(currentData.WEEK, GetFormatedDate(date), '')
        this.props.getKpiTicketGraphDashboardData(currentData.WEEK, GetFormatedDate(date), '')
        this.props.getKpiTicketDaysOpenDashboardData(currentData.WEEK, GetFormatedDate(date), '')
        this.props.getKpiTicketHighUsersDashboardData(currentData.WEEK, GetFormatedDate(date), '')
        this.props.getKpiTicketJobsDashboardData(currentData.WEEK, GetFormatedDate(date), '')
        this.props.updateCurrentDate(date);
    } else if (this.props.isMonth) {
        if (this.props.currentDate.getMonth() === 11) {
            const date = getNextYearDate(this.props.currentDate);
            this.props.getKpiTicketCountDashboardData(currentData.MONTH, GetFormatedDate(date), '')
            this.props.getKpiTicketGraphDashboardData(currentData.MONTH, GetFormatedDate(date), '')
            this.props.getKpiTicketDaysOpenDashboardData(currentData.MONTH, GetFormatedDate(date), '')
            this.props.getKpiTicketHighUsersDashboardData(currentData.MONTH, GetFormatedDate(date), '')
            this.props.getKpiTicketJobsDashboardData(currentData.MONTH, GetFormatedDate(date), '')
            this.props.updateCurrentDate(getNextYearDate(this.props.currentDate));
        } else {
            const date = getNextMonthDate(this.props.currentDate);
            this.props.getKpiTicketCountDashboardData(currentData.MONTH, GetFormatedDate(date), '')
            this.props.getKpiTicketGraphDashboardData(currentData.MONTH, GetFormatedDate(date), '')
            this.props.getKpiTicketDaysOpenDashboardData(currentData.MONTH, GetFormatedDate(date), '')
            this.props.getKpiTicketHighUsersDashboardData(currentData.MONTH, GetFormatedDate(date), '')
            this.props.getKpiTicketJobsDashboardData(currentData.MONTH, GetFormatedDate(date), '')
            this.props.updateCurrentDate(getNextMonthDate(this.props.currentDate));
        }
    } else if (this.props.isYear) {
        const date = getNextYearDate(this.props.currentDate);
        this.props.getKpiTicketCountDashboardData(currentData.YEAR, GetFormatedDate(date), '')
        this.props.getKpiTicketGraphDashboardData(currentData.YEAR, GetFormatedDate(date), '')
        this.props.getKpiTicketDaysOpenDashboardData(currentData.YEAR, GetFormatedDate(date), '')
        this.props.getKpiTicketHighUsersDashboardData(currentData.YEAR, GetFormatedDate(date), '')
        this.props.getKpiTicketJobsDashboardData(currentData.YEAR, GetFormatedDate(date), '')
        this.props.updateCurrentDate(getNextYearDate(this.props.currentDate));
    }
}

previousWeekMonthYearCliked = () => {
    const userDetails = getUser();
    if (this.props.isWeek) {
        const date = get_previous_week_start(this.props.currentDate);
        this.props.getKpiTicketCountDashboardData(currentData.WEEK, GetFormatedDate(date), '')
        this.props.getKpiTicketGraphDashboardData(currentData.WEEK, GetFormatedDate(date), '')
        this.props.getKpiTicketDaysOpenDashboardData(currentData.WEEK, GetFormatedDate(date), '')
        this.props.getKpiTicketHighUsersDashboardData(currentData.WEEK, GetFormatedDate(date), '')
        this.props.getKpiTicketJobsDashboardData(currentData.WEEK, GetFormatedDate(date), '')
        this.props.updateCurrentDate(date);
    } else if (this.props.isMonth) {
        if (this.props.currentDate.getMonth() === 0) {
            const date = getPreviousYearDate(this.props.currentDate);
            this.props.getKpiTicketCountDashboardData(currentData.MONTH, GetFormatedDate(date), '')
            this.props.getKpiTicketGraphDashboardData(currentData.MONTH, GetFormatedDate(date), '')
            this.props.getKpiTicketDaysOpenDashboardData(currentData.MONTH, GetFormatedDate(date), '')
            this.props.getKpiTicketHighUsersDashboardData(currentData.MONTH, GetFormatedDate(date), '')
            this.props.getKpiTicketJobsDashboardData(currentData.MONTH, GetFormatedDate(date), '')
            this.props.updateCurrentDate(getPreviousYearDate(this.props.currentDate));
        } else {
            const date = getPreviousMonthDate(this.props.currentDate);
            this.props.getKpiTicketCountDashboardData(currentData.MONTH, GetFormatedDate(date), '')
            this.props.getKpiTicketGraphDashboardData(currentData.MONTH, GetFormatedDate(date), '')
            this.props.getKpiTicketDaysOpenDashboardData(currentData.MONTH, GetFormatedDate(date), '')
            this.props.getKpiTicketHighUsersDashboardData(currentData.MONTH, GetFormatedDate(date), '')
            this.props.getKpiTicketJobsDashboardData(currentData.MONTH, GetFormatedDate(date), '')
            this.props.updateCurrentDate(getPreviousMonthDate(this.props.currentDate));
        }
    } else if (this.props.isYear) {
        const date = getPreviousYearDate(this.props.currentDate);
        this.props.getKpiTicketCountDashboardData(currentData.YEAR, GetFormatedDate(date), '')
        this.props.getKpiTicketGraphDashboardData(currentData.YEAR, GetFormatedDate(date), '')
        this.props.getKpiTicketDaysOpenDashboardData(currentData.YEAR, GetFormatedDate(date), '')
        this.props.getKpiTicketHighUsersDashboardData(currentData.YEAR, GetFormatedDate(date), '')
        this.props.getKpiTicketJobsDashboardData(currentData.YEAR, GetFormatedDate(date), '')
        this.props.updateCurrentDate(getPreviousYearDate(this.props.currentDate));
        }
}
viewModeClicked = (viewMode) => {
    const userDetails = getUser();
    if (viewMode === currentData.WEEK) {
        this.props.updateWeekMonthYear(true, false, false);
        this.props.getKpiTicketCountDashboardData(currentData.WEEK, GetFormatedDate(this.props.currentDate), '')
        this.props.getKpiTicketGraphDashboardData(currentData.WEEK, GetFormatedDate(this.props.currentDate), '')
        this.props.getKpiTicketDaysOpenDashboardData(currentData.WEEK, GetFormatedDate(this.props.currentDate), '')
        this.props.getKpiTicketHighUsersDashboardData(currentData.WEEK, GetFormatedDate(this.props.currentDate), '')
        this.props.getKpiTicketJobsDashboardData(currentData.WEEK, GetFormatedDate(this.props.currentDate), '')
    } else if (viewMode === currentData.MONTH) {
        this.props.updateWeekMonthYear(false, true, false)
        this.props.getKpiTicketCountDashboardData(currentData.MONTH, GetFormatedDate(this.props.currentDate), '')
        this.props.getKpiTicketGraphDashboardData(currentData.MONTH, GetFormatedDate(this.props.currentDate), '')
        this.props.getKpiTicketDaysOpenDashboardData(currentData.MONTH, GetFormatedDate(this.props.currentDate), '')
        this.props.getKpiTicketHighUsersDashboardData(currentData.MONTH, GetFormatedDate(this.props.currentDate), '')
        this.props.getKpiTicketJobsDashboardData(currentData.MONTH, GetFormatedDate(this.props.currentDate), '')
    } else if (viewMode === currentData.YEAR) {
        this.props.updateWeekMonthYear(false, false, true)
        this.props.getKpiTicketCountDashboardData(currentData.YEAR, GetFormatedDate(this.props.currentDate), '')
        this.props.getKpiTicketGraphDashboardData(currentData.YEAR, GetFormatedDate(this.props.currentDate), '')
        this.props.getKpiTicketDaysOpenDashboardData(currentData.YEAR, GetFormatedDate(this.props.currentDate), '')
        this.props.getKpiTicketHighUsersDashboardData(currentData.YEAR, GetFormatedDate(this.props.currentDate), '')
        this.props.getKpiTicketJobsDashboardData(currentData.YEAR, GetFormatedDate(this.props.currentDate), '')
    }
}


    goClicked = (values) => { 
        if(values.created_by) {
            values.created_by = values.created_by.trim()
        }
        if(values.assigned_to) {
            values.assigned_to = values.assigned_to.trim()
        }
        if (this.props.isWeek) {
            const date = get_previous_week_start(this.props.currentDate);
            this.props.getKpiTicketCountDashboardData(currentData.WEEK, GetFormatedDate(this.props.currentDate), values)
            this.props.getKpiTicketGraphDashboardData(currentData.WEEK, GetFormatedDate(this.props.currentDate), values)
            this.props.getKpiTicketDaysOpenDashboardData(currentData.WEEK, GetFormatedDate(this.props.currentDate), values)
            this.props.getKpiTicketHighUsersDashboardData(currentData.WEEK, GetFormatedDate(this.props.currentDate), values)
            this.props.getKpiTicketJobsDashboardData(currentData.WEEK, GetFormatedDate(this.props.currentDate), values)
        } else if (this.props.isMonth) {
            if (this.props.currentDate.getMonth() === 0) {
                this.props.getKpiTicketCountDashboardData(currentData.MONTH, GetFormatedDate(this.props.currentDate), values)
                this.props.getKpiTicketGraphDashboardData(currentData.MONTH, GetFormatedDate(this.props.currentDate), values)
                this.props.getKpiTicketDaysOpenDashboardData(currentData.MONTH, GetFormatedDate(this.props.currentDate), values)
                this.props.getKpiTicketHighUsersDashboardData(currentData.MONTH, GetFormatedDate(this.props.currentDate), values)
                this.props.getKpiTicketJobsDashboardData(currentData.MONTH, GetFormatedDate(this.props.currentDate), values)
            } else {
                this.props.getKpiTicketCountDashboardData(currentData.MONTH, GetFormatedDate(this.props.currentDate), values)
                this.props.getKpiTicketGraphDashboardData(currentData.MONTH, GetFormatedDate(this.props.currentDate), values)
                this.props.getKpiTicketDaysOpenDashboardData(currentData.MONTH, GetFormatedDate(this.props.currentDate), values)
                this.props.getKpiTicketHighUsersDashboardData(currentData.MONTH, GetFormatedDate(this.props.currentDate), values)
                this.props.getKpiTicketJobsDashboardData(currentData.MONTH, GetFormatedDate(this.props.currentDate), values)
            }
        } else if (this.props.isYear) {
            this.props.getKpiTicketCountDashboardData(currentData.YEAR, GetFormatedDate(this.props.currentDate), values)
            this.props.getKpiTicketGraphDashboardData(currentData.YEAR, GetFormatedDate(this.props.currentDate), values)
            this.props.getKpiTicketDaysOpenDashboardData(currentData.YEAR, GetFormatedDate(this.props.currentDate), values)
            this.props.getKpiTicketHighUsersDashboardData(currentData.YEAR, GetFormatedDate(this.props.currentDate), values)
            this.props.getKpiTicketJobsDashboardData(currentData.YEAR, GetFormatedDate(this.props.currentDate), values)
            }
    }
    resetClicked = () => {
        this.props.reset('searchKpiDashboard')
        this.props.kpiResetClicked();
        if (this.props.isWeek) {
            this.props.getKpiTicketCountDashboardData(currentData.WEEK, GetFormatedDate(this.props.currentDate), '')
            this.props.getKpiTicketGraphDashboardData(currentData.WEEK, GetFormatedDate(this.props.currentDate), '')
            this.props.getKpiTicketDaysOpenDashboardData(currentData.WEEK, GetFormatedDate(this.props.currentDate), '')
            this.props.getKpiTicketHighUsersDashboardData(currentData.WEEK, GetFormatedDate(this.props.currentDate), '')
            this.props.getKpiTicketJobsDashboardData(currentData.WEEK, GetFormatedDate(this.props.currentDate), '')
        } else if (this.props.isMonth) {
            if (this.props.currentDate.getMonth() === 0) {
                this.props.getKpiTicketCountDashboardData(currentData.MONTH, GetFormatedDate(this.props.currentDate), '')
                this.props.getKpiTicketGraphDashboardData(currentData.MONTH, GetFormatedDate(this.props.currentDate), '')
                this.props.getKpiTicketDaysOpenDashboardData(currentData.MONTH, GetFormatedDate(this.props.currentDate), '')
                this.props.getKpiTicketHighUsersDashboardData(currentData.MONTH, GetFormatedDate(this.props.currentDate), '')
                this.props.getKpiTicketJobsDashboardData(currentData.MONTH, GetFormatedDate(this.props.currentDate), '')
            } else {
                this.props.getKpiTicketCountDashboardData(currentData.MONTH, GetFormatedDate(this.props.currentDate), '')
                this.props.getKpiTicketGraphDashboardData(currentData.MONTH, GetFormatedDate(this.props.currentDate), '')
                this.props.getKpiTicketDaysOpenDashboardData(currentData.MONTH, GetFormatedDate(this.props.currentDate), '')
                this.props.getKpiTicketHighUsersDashboardData(currentData.MONTH, GetFormatedDate(this.props.currentDate), '')
                this.props.getKpiTicketJobsDashboardData(currentData.MONTH, GetFormatedDate(this.props.currentDate), '')
            }
        } else if (this.props.isYear) {
            this.props.getKpiTicketCountDashboardData(currentData.YEAR, GetFormatedDate(this.props.currentDate), '')
            this.props.getKpiTicketGraphDashboardData(currentData.YEAR, GetFormatedDate(this.props.currentDate), '')
            this.props.getKpiTicketDaysOpenDashboardData(currentData.YEAR, GetFormatedDate(this.props.currentDate), '')
            this.props.getKpiTicketHighUsersDashboardData(currentData.YEAR, GetFormatedDate(this.props.currentDate), '')
            this.props.getKpiTicketJobsDashboardData(currentData.YEAR, GetFormatedDate(this.props.currentDate), '')
            }
    }

    render() {
        return (
            <KpiTicket
            {...this.props}
                nextWeekMonthYearCliked={this.nextWeekMonthYearCliked}
                previousWeekMonthYearCliked={this.previousWeekMonthYearCliked}
                viewModeClicked={this.viewModeClicked}
                dropDownUsersList={this.props.dropDownUsersList}
                dropDownAccountsList={this.props.dropDownAccountsList}
                accountsList={this.props.accountsList ? this.props.accountsList : []}
                onSubmit={this.goClicked}
                resetClicked={this.resetClicked}
            />
        )
    }
}
const mapStateToProps = (state) => {
    return {
        // chiServyList: state.adminOrSuperAdminReducer.chiServyList ? state.adminOrSuperAdminReducer.chiServyList : []
        company: state.adminOrSuperAdminReducer.company,
        companyUsersList: state.adminOrSuperAdminReducer.companyUsersList,
        currentDate: state.adminOrSuperAdminReducer.currentDate,
        isWeek: state.adminOrSuperAdminReducer.isWeek,
        isMonth: state.adminOrSuperAdminReducer.isMonth,
        isYear: state.adminOrSuperAdminReducer.isYear,
        dropDownUsersList: state.adminOrSuperAdminReducer.filterAccountUsers,
        accountsList: state.adminOrSuperAdminReducer.modeAccountsList,
        dropDownAccountsList: state.adminOrSuperAdminReducer.dropDownAccountsList,

        kpiTicketCountData: state.adminOrSuperAdminReducer.kpiTicketCountData,
        kpiTicketGraphData: state.adminOrSuperAdminReducer.kpiTicketGraphData,
        kpiTicketDaysOpenData: state.adminOrSuperAdminReducer.kpiTicketDaysOpenData,
        kpiTicketHightUsersData: state.adminOrSuperAdminReducer.kpiTicketHightUsersData,
        kpiTicketJobData: state.adminOrSuperAdminReducer.kpiTicketJobData,


    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        // getAllCHIServys: (chi_no_of_rows, page, sorted, filtered) => dispatch(actions.getAllCHIServys(chi_no_of_rows, page, sorted, filtered))
        updateCurrentDate: (date) => dispatch(actions.updateCurrentDate(date)),
        updateWeekMonthYear: (isWeek, isMonth, isYear) => dispatch(actions.updateWeekMonthYear(isWeek, isMonth, isYear)),
        getKpiDashboardData: (DataType, date, values) => dispatch(actions.getKpiDashboardData(DataType, date, values)),
        getKpiInspectionCount: (DataType, date, values, quarter) => dispatch(actions.getKpiInspectionCount(DataType, date, values, quarter)),
        getAccounts: () => dispatch(actions.getDropDownAccounts()),
        getModeAccounts: (mode) => dispatch(actions.getModeAccounts(mode)),
        getModeAccountUsers: (mode) => dispatch(actions.getModeAccountUsers(mode)),
        searchInspections: (values) => dispatch(actions.searchInspections(values)),
        kpiResetClicked: () => dispatch(actions.kpiResetClicked()),
        reset: (kpiSearch) => dispatch(reset(kpiSearch)), 

        getKpiTicketCountDashboardData: (DataType, date, values) => dispatch(actions.getKpiTicketCountDashboardData(DataType, date, values)),
        getKpiTicketGraphDashboardData: (DataType, date, values) => dispatch(actions.getKpiTicketGraphDashboardData(DataType, date, values)),
        getKpiTicketDaysOpenDashboardData: (DataType, date, values) => dispatch(actions.getKpiTicketDaysOpenDashboardData(DataType, date, values)),
        getKpiTicketHighUsersDashboardData: (DataType, date, values) => dispatch(actions.getKpiTicketHighUsersDashboardData(DataType, date, values)),
        getKpiTicketJobsDashboardData: (DataType, date, values) => dispatch(actions.getKpiTicketJobsDashboardData(DataType, date, values)),

        getCompanyUsers: (id, values, user_no_of_rows, page, sorted, filtered) => dispatch(actions.getCompanyUsers(id, values, user_no_of_rows, page, sorted, filtered)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(KpiTicketContainer);