import React, { useRef, useState } from "react";
import { Input } from "../../../../UI/InputElement/reactFormHookInputField";
import { useFieldArray,useWatch } from "react-hook-form";
import moment from "moment";

function RestRoomSection(props) {
  
  const {fields:CheckBoxField}=useFieldArray({
    name:`section[${props.index}].lineItems`,
    control:props.control,
    
  })

  const err=useRef(props?.errors?.section?.[props.index]?.lineItems)
  
  
  const handleValidateClick=(data)=>{
    const valid= data.some((item)=>item.is_checked)
   if(valid) return true
   else {
    err.current=true
    return "Please check at least one item"
   }
   }

   const handleValidateChange=(data)=>{
    
    const valid= data.some((item)=>item.is_checked)
   if(valid) return true
   else {
    // if(!props?.errors?.section?.[props.index]?.lineItems?.message){
    //    props.setError(`section[${props.index}].lineItems`, {
    //     message: 'Please check at least one item',
    //    })
    // }
    
    if(props.errors == undefined){
      return 
    }
    err.current=true
    return "Please check at least one item"
   }
   }

   const handleValidateTime=(out_time_data)=>{
    
    const Intime= props.getValues(`section[${props.index}].in_time`)
    
     if(!Intime) return true
     
      
     if(!out_time_data) return true 
     
     var timeComparisonResult = moment(out_time_data.format("HH:mm"), "HH:mm").isAfter(moment(Intime.format("HH:mm"), "HH:mm"));

      if( timeComparisonResult){
          return true
      }
      else  {
       //props.setFocus(`items.${props?.index}.scheduled_date`)
       return  "Out time should be greater than Intime"
     }
    
}

  props.register(`section[${props.index}].lineItems`,{
    type:"custom",
    validate:handleValidateClick,
    message:"please check one item",

  })

  const watchCheckBoxArray=useWatch({
   control:props.control,
   name:"section"
  })
  
  let checkBoxChecked=handleValidateChange(props.getValues(`section[${props.index}].lineItems`) ?? [])
  
    
   
  
   
  const firstlength=Math.floor(CheckBoxField.length /2)
  const secondlength=CheckBoxField.length - firstlength
  const firstSection=CheckBoxField.slice(0,firstlength)
  const secondSection=CheckBoxField.slice(firstlength,CheckBoxField.length)

  const handleChange=(sectionIndex,itemIndex,e)=>{
    props.setValue(`section[${sectionIndex}].items[${itemIndex}].is_checked`,e.target.checked)
  }



  
  return (
    <>
      <div class="tm_form_wrap">
        <div class="tm_form_innner">
          <div class="form">
            <div class="row">
              <div class="col-md-6 col-sm-12">
                <div class="form-group">
                  {/* <Input 
                            type="date"
                            datePickerType="time"
                            formControlClasses="form-control"   
                            id="time" 
                            placeholder="MM /DD/ YYYY"
                            control={props.control}
                            controller={props.controller}
                            selectName={"time"}
                            //dateFormat="MM/dd/yyyy"
                            // minDate={new Date().setDate(current.getDate() - 400)}
                            // preventReadOnly={false}
                            label="In Time"
                            formControlProps={props.register('time', {
                                required: "This field is required", maxLength: 80
                              })}
                            
                            validationErrorMessage={props?.errors["time"]?.date?.message ?? ""}
                            //disabled={props?.isView}
                            /> */}

                  <Input
                    type="date"
                    datePickerType="time"
                    formControlClasses="form-control"
                    id={`section[${props.index}].in_time`}
                    placeholder="In Time"
                    control={props.control}
                    controller={props.controller}
                    selectName={`section[${props.index}].in_time`}
                    // timeFormat="hh:mm"
                    timeConstraints={{
                      hours: {
                        min: 0,
                        max: 23,
                        step: 1, // Set the step to 0.5 to allow values like 0, 0.5, 1, 1.5, ..., 23
                      },
                      minutes:{
                        step:15
                      }
                    }}
                    label="In Time"
                    formControlProps={props.register(`section[${props.index}].in_time`, {
                      required: "This field is required",
                      maxLength: 80,
                    })}
                    validationErrorMessage={
                      props.errors?.section?.[props.index]?.in_time?.message ?? ""
                    }
                    disabled={props?.isView}
                  />

                  {/* <label for="jobname" class="form-label"
                            >In Time</label
                          >
                          <input
                            type="date"
                            class="form-control"
                            id="jobname"
                            aria-describedby=""
                            placeholder="Location"
                          /> */}
                  {/* <div class="calendar_icon">
                            <img
                              src="/images/icons/reminder.svg"
                              alt="calendar"
                            />
                          </div> */}
                </div>
              </div>
              <div class="col-md-6 col-sm-12">
                <div class="form-group">
                  <Input
                    type="date"
                    datePickerType="time"
                    formControlClasses="form-control"
                    id={`section[${props?.index}].out_time`}
                    placeholder="Out Time"
                    control={props.control}
                    controller={props.controller}
                    selectName={`section[${props?.index}].out_time`}
                    dateFormat="MM/dd/yyyy"
                    // minDate={new Date().setDate(current.getDate() - 400)}
                    // preventReadOnly={false}
                    timeConstraints={{
                      hours: {
                        min: 0,
                        max: 23,
                        step: 1, // Set the step to 0.5 to allow values like 0, 0.5, 1, 1.5, ..., 23
                      },
                      minutes:{
                        step:15
                      }
                    }}
                    label="Out Time"
                    formControlProps={props.register(`section[${props.index}].out_time`, {
                      required: "This field is required",
                      validate:handleValidateTime
                    })}
                    validationErrorMessage={
                      props.errors?.section?.[props?.index]?.out_time?.message ?? ""
                    }
                    disabled={props?.isView}
                  />

                  {/* <label for="jobname" class="form-label"
                            >Out Time</label>
                          <input
                            type="date"
                            class="form-control"
                            id="jobname"
                            aria-describedby=""
                            placeholder="Location"
                          /> */}
                  {/* <div class="calendar_icon">
                            <img
                              src="/images/icons/reminder.svg"
                              alt="calendar"
                            />
                          </div> */}
                </div>
              </div>
              <div class="col-md-12 col-sm-12">
                <div class="form-group">
                  <Input
                    type="text"
                    id={`section[${props.index}].restroom_condition`}
                    name={`section[${props.index}].restroom_condition`}
                    formControlClasses="form-control"
                    placeholder="Restroom condition crowd /Safety"
                    required
                    label="Restroom condition crowd /Safety"
                    formControlProps={props.register(`section[${props.index}].restroom_condition`, {
                      required: "This field is required",
                      // pattern: {
                      //   value:/^[0-9]*$/ ,
                      //   message:"Must Be a number"
                      // },
                      maxLength: 80,
                    })}
                    validationErrorMessage={props.errors?.section?.[props?.index]?.restroom_condition?.message ?? ""}
                    disabled={props?.isView}
                  />

                  {/* <label for="jobname" class="form-label"
                            >Restroom condition crowd / safety</label
                          >
                          <input
                            type="text"
                            class="form-control"
                            id="jobname"
                            aria-describedby=""
                            placeholder="Type here"
                          /> */}
                </div>
              </div>
              <div class="col-md-6 col-sm-12" name={`section[${props.index}].lineItems`}>
                <div class="custom_checkbox">
                {
                  firstSection.map((chkboxs,i)=>(
                    <div class="form-group">
                    {/* <input type="checkbox" id="check_1" checked /> */}
                    <props.controller
                      name={`section[${props.index}].lineItems[${i}].is_checked`}
                      control={props.control}
                      render={({field:{name,ref,onChange,value}}) => {
                      
                        return (
                          <>
                        <div className="cstm_checkbox_wrap_new">
                        <input
                          type="checkbox"
                          id={name}
                          // {...field}
                          ref={ref}
                          checked={value}
                          onChange={onChange}
                          
                          disabled={props.isView}

                        
                        />
                         <label  htmlFor={name}>{chkboxs.name}</label>
                         </div>
                        </>
                        )
                      }
                    }
                />
                   
                  </div>
                  ))
                }
                  
                </div>

                <div style={{display:"flex"}}>
                 <p style={{color:"red"}}>{err.current && props.trackError.current && checkBoxChecked }</p>
                 </div> 
                
              </div>
              <div class="col-md-6 col-sm-12">
                <div class="custom_checkbox">
                  {
                    secondSection.map((chckbx,i)=>(
                      <div class="form-group">
                      {/* <input type="checkbox" id="check_9" />
                      <label for="check_9">{chckbx?.name}</label> */}
                      <props.controller
                      name={`section[${props.index}].lineItems[${firstlength + i}].is_checked`}
                      control={props.control}
                      render={({field:{name,ref,onChange,value}}) => {
                         
                        return (
                          <>
                        <input
                          type="checkbox"
                          id={name}
                          // {...field}
                          ref={ref}
                          checked={value}
                          onChange={onChange}
                          disabled={props.isView}
                          
                        
                        />
                         <label htmlFor={name}>{chckbx?.name}</label>
                        </>
                        )
                      }
                    }
                />
                    </div>
                    ))
                  }
                  
                </div>
              </div>
              
              <div class="col-md-6 col-sm-12">
                <div class="form-group mb-0">
                  {/* <label for="jobname" class="form-label">
                    Next restroom
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    id="jobname"
                    aria-describedby=""
                    placeholder="Type here"
                  /> */}


                 <Input
                    type="text"
                    id={`section[${props.index}].next_restroom`}
                    name={`section[${props.index}].next_restroom`}
                    formControlClasses="form-control"
                    placeholder="Next restroom"
                    required
                    label="Next restroom"
                    formControlProps={props.register(`section[${props.index}].next_restroom`, {
                      required: "This field is required",
                      // pattern: {
                      //   value:/^[0-9]*$/ ,
                      //   message:"Must Be a number"
                      // },
                      maxLength: 80,
                    })}
                    validationErrorMessage={props.errors?.section?.[props?.index]?.next_restroom?.message ?? ""}
                    disabled={props?.isView}
                  />

                </div>
              </div>
              <div class="col-md-6 col-sm-12">
                <div class="form-group mb-0">
                  {/* <label for="jobname" class="form-label">
                    Initial
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    id="jobname"
                    aria-describedby=""
                    placeholder="Type here"
                  /> */}

<                Input
                    type="text"
                    id={`section[${props.index}].initial`}
                    name={`section[${props.index}].initial`}
                    formControlClasses="form-control"
                    placeholder="Initial"
                    required
                    label="Initial"
                    formControlProps={props.register(`section[${props.index}].initial`, {
                      required: "This field is required",
                      // pattern: {
                      //   value:/^[0-9]*$/ ,
                      //   message:"Must Be a number"
                      // },
                      maxLength: 80,
                    })}
                    validationErrorMessage={props.errors?.section?.[props?.index]?.initial?.message ?? ""}
                    disabled={props?.isView}
                  />

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="tm_form_wrap">
      <div class="tm_form_innner">
                  <div class="form">
                    <div class="row">
                      <div class="col-md-12 col-sm-12">
                        <div class="form-group">
                          <Input
                            type="textarea"
                            id={`section[${props.index}].comments`}
                            name={`section[${props.index}].comments`}
                            formControlClasses="form-control"
                            placeholder="Type here"
                            label="Comments/safety"
                            formControlProps={props.register(`section[${props.index}].comments`, {
                              required: "This field is required",
                              maxLength: 80,
                            })}
                            validationErrorMessage={
                              props.errors?.section?.[props?.index]?.comments?.message
                            }
                            disabled={props?.isView}
                          />
                        </div>
                      </div>
                      <div class="col-md-6 col-sm-12">
                        <div class="form-group">
                          <Input
                            type="text"
                            id={`section[${props.index}].measure_taken`}
                            name={`section[${props.index}].measure_taken`}
                            formControlClasses="form-control"
                            placeholder="Measures taken"
                            required
                            label="Measures taken"
                            formControlProps={props.register(`section[${props.index}].measure_taken`, {
                              required: "This field is required",
                              // pattern: {
                              //   value:/^[0-9]*$/ ,
                              //   message:"Must Be a number"
                              // },
                              maxLength: 80,
                            })}
                            validationErrorMessage={
                              props.errors?.section?.[props?.index]?.measure_taken?.message ?? ""
                            }
                            disabled={props?.isView}
                          />
                        </div>
                      </div>
                      <div class="col-md-6 col-sm-12">
                        <div class="form-group">
                          <Input
                            type="text"
                            id={`section[${props.index}].restroom`}
                            name={`section[${props.index}].restroom`}
                            formControlClasses="form-control"
                            placeholder="Restroom"
                            required
                            label="Restroom"
                            formControlProps={props.register(`section[${props.index}].restroom`, {
                              required: "This field is required",
                              // pattern: {
                              //   value:/^[0-9]*$/ ,
                              //   message:"Must Be a number"
                              // },
                              maxLength: 80,
                            })}
                            validationErrorMessage={
                              props.errors?.section?.[props?.index]?.restroom?.message ?? ""
                            }
                            disabled={props?.isView}
                          />
                        </div>
                      </div>
                      <div class="col-md-6 col-sm-12">
                        <div class="form-group">
                          <Input
                            type="text"
                            id={`section[${props.index}].discrepancies`}
                            name={`section[${props.index}].discrepancies`}
                            formControlClasses="form-control"
                            placeholder="Discrepancies"
                            required
                            label="Discrepancies"
                            formControlProps={props.register(`section[${props.index}].discrepancies`, {
                              required: "This field is required",
                              // pattern: {
                              //   value:/^[0-9]*$/ ,
                              //   message:"Must Be a number"
                              // },
                              maxLength: 80,
                            })}
                            validationErrorMessage={
                              props.errors?.section?.[props?.index]?.discrepancies?.message ?? ""
                            }
                            disabled={props?.isView}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
      {
        props?.index > 0 ?

          <div class="action_button pt-3">
            <span onClick={props.removeFromTaskList(props.index)}>
            <img src='/images/icons/trash.svg'/>
            </span>
          </div>
         : null
      }
    </>
  );
}

export default RestRoomSection;