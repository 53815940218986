import React from "react";
import { ClapSpinner } from 'react-spinners-kit';
import LogbookSearch from "./LogbookSearch";
import LogbookSearchResult from "./LogbookSearchResult";

const SearchLogbookHistory = (props) => {
  return(
      <main className="app wraper_content qr_app_content qr-app-scroll-v2">
      <LogbookSearch {...props}/>
        {
          props.isLoading ? <div className="loader_btn_block">
            <ClapSpinner backColor="#89073c" size={40} frontColor="#89073c8a" />
          </div> :
          <LogbookSearchResult {...props}/>
        }
      </main>
  )
};

export default SearchLogbookHistory;