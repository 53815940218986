import React, { useEffect, useState, useCallback } from 'react';
import { Input } from '../../UI/InputElement/InputElement';
import GooglePlacesAutoComplete from '../../GooglePlacesAutoComplete/googlePlacesAutoComplete';
import { createNewScheduleTicketValidator as validate } from '../../../../utility/validator/validator';
import { connect, useSelector } from 'react-redux';
import { getFormValues, reduxForm } from 'redux-form';
import isEmpty from '../../../../utility/IsEmptyValidator';
import { showConfirmAlert } from '../../../../utility/successAlert/ConfirmAlert';
import * as Scroll from 'react-scroll';
import moment from "moment";
import { Timezones, UserModesWithoutAll, priorityOptions, recurOnWeekSelect, selectDay, recurWeekSelect, QuarterlymonthSelect, beforeAfterOption, fortyEightHoursOption } from '../../../../utility/constants/constants';
import { getUntilDate, getUser, makeFirstLetterCapital, OutlookGetFromFormatedDate, OutlookGetToFormatedDate, getQuarter, getSelectedWeek, getRecurringDay } from '../../../../utility/utility';
import { formValueSelector } from 'redux-form';
import ReactTooltip from 'react-tooltip';
import AddToCalendar from 'react-add-to-calendar-recurring';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import { DateTimeField } from '../../UI/DateTimeField/DateTimeField';
import * as actions from '../../../../redux/actions/index';
import ModalImageGallery from '../../ModalImageGallery/modalImageGallery';

const cloneDeep = require('clone-deep');
var scroller = Scroll.scroller;

let AccountNewTicketSchedule = (props) => {
    const[promptLoader, setPromptLoader] = useState({yes: false, no: false});
    const account_info = useSelector(state => state.adminOrSuperAdminReducer.currentAccount);
    const getAccountNameForTicketSchedule = (props) => {
        if (props.accountsList && props.accountsList.length > 0) {
            return props.accountsList && props.accountsList.map((account) => {
                return {
                    value: account.id,
                    label: account.name,
                    timezone : account.timezone
                }
            })
        } else {
            return []
        }
    };
    const getAccountAreasDropDown = (accountAreas) => {
        return accountAreas && accountAreas.map((data) => {
            return {
                value: data.id,
                label: data.name
            }
        })
    };
    const getAssignedUsersDropDown = (assignedUsers) => {
        return assignedUsers && assignedUsers.map((user) => {
            return {
                value: user.id,
                label: user.first_name + ' ' + user.last_name
            }
        })
    }
    const getCategoriesDropDown = (categories) => {
        return categories && categories.map((data) => {
            return {
                value: data.category.id,
                label: data.category.name
            }
        })
    };
    const getSelectedAccountName = (accounts) => {
        return accounts && accounts.map((a) => {
            if(a.id === props.match.params.accountId) {
              return a.name
            }
        });
    };

    const [accounts, setAccounts] = useState(getAccountNameForTicketSchedule(props));
    const [accountAreasList, setAccountAreasList] = useState(getAccountAreasDropDown(props.accountAreas));
    const [assignedUsersList, setAssignedUsersList] = useState(getAssignedUsersDropDown(props.accountUsersList))
    const [categoriesList, setCategoriesList] = useState(getCategoriesDropDown(props.categoriesList));
    const [reminderTimeSlot, setReminderTimeSlot] = useState(['']);

    useEffect(() => {
        setAccounts(getAccountNameForTicketSchedule(props))
    }, [props.accountsList])

    useEffect(() => {
        setAccountAreasList(getAccountAreasDropDown(props.accountAreas))
    }, [props.accountAreas])

    const getData = useCallback(()=> {
        setAssignedUsersList(getAssignedUsersDropDown(props.accountUsersList))
    }, [props.accountUsersList]);

    useEffect(() => {
        if(props.accountUsersList != null && props.accountUsersList.length && JSON.stringify(getAssignedUsersDropDown(props.accountUsersList)) != JSON.stringify(assignedUsersList)) {
            getData();
        }
    }, [props.accountUsersList])

    useEffect(() => {
        setCategoriesList(getCategoriesDropDown(props.categoriesList))
    }, [props.categoriesList])

    const deleteReminderTimeSlot = (index) => {
        let slots = [...reminderTimeSlot];
        slots.splice(index, 1);
        setReminderTimeSlot(slots);
    };

    const [isRecurring, setIsRecurring] = useState(false);
    const [isNonRecurring, setIsNonRecurring] = useState(false);
    const [weeklyTab, setWeeklyTab] = useState(false);
    const [monthlyTab, setMonthlyTab] = useState(false);
    const [quarterlyTab, setquarterlyTab] = useState(false);
    const [occurrence, setoccurrence] = useState('');
    const [occurrenceList, setOccurrenceList] = useState('');

    const isRecurringClicked = () => {
        if(props.formStates && props.formStates.scheduleTicket.schedule) {
            delete props.formStates.scheduleTicket.schedule
            delete props.formStates.scheduleTicket.start_date
        }
        setIsRecurring(true);
        setIsNonRecurring(false);
        setWeeklyTab(true);
        setMonthlyTab(false);
        setquarterlyTab(false);
    };
    const isNonRecurringClicked = () => {
        if(props.formStates && props.formStates.scheduleTicket) {
            delete props.formStates.scheduleTicket.start_date
            delete props.formStates.scheduleTicket.schedule
        }
        setIsRecurring(false);
        setIsNonRecurring(true);
        setWeeklyTab(false);
        setMonthlyTab(false);
        setquarterlyTab(false);
        setoccurrence('');
    };

    const weeklyTabClicked = () => {
        if(props.formStates && props.formStates.scheduleTicket.schedule) {
            delete props.formStates.scheduleTicket.schedule.start_date
            delete props.formStates.scheduleTicket.schedule.end_date
        }
        setWeeklyTab(true);
        setMonthlyTab(false);
        setquarterlyTab(false);
        setoccurrence('');
    }
    const monthlyTabClicked = () => {
        if(props.formStates && props.formStates.scheduleTicket.schedule) {
            delete props.formStates.scheduleTicket.schedule.start_date
            delete props.formStates.scheduleTicket.schedule.end_date
        }
        setWeeklyTab(false);
        setMonthlyTab(true);
        setquarterlyTab(false);
        setoccurrence('');
    }
    const quarterlyTabClicked = () => {
        if(props.formStates && props.formStates.scheduleTicket.schedule) {
            delete props.formStates.scheduleTicket.schedule.start_date
            delete props.formStates.scheduleTicket.schedule.end_date
        }
        setMonthlyTab(false);
        setWeeklyTab(false);
        setquarterlyTab(true);
        setoccurrence('');
    }
    const current = new Date();

    useEffect(() => {
        if(props.formStates && props.formStates.scheduleTicket.schedule && props.formStates.scheduleTicket.schedule.frequency && props.formStates.scheduleTicket.schedule.select_week && props.formStates.scheduleTicket.schedule.day && props.formStates.scheduleTicket.schedule.start_date && props.formStates.scheduleTicket.schedule.end_date) {
            props.occurrenceCheckScheduledDates(props.formStates.scheduleTicket)
        } else if (props.formStates && props.formStates.scheduleTicket.schedule && props.formStates.scheduleTicket.schedule.frequency && props.formStates.scheduleTicket.schedule.selected_week && props.formStates.scheduleTicket.schedule.day && props.formStates.scheduleTicket.schedule.start_date && props.formStates.scheduleTicket.schedule.end_date) {
            props.occurrenceCheckScheduledDates(props.formStates.scheduleTicket)
        } else if (props.formStates && props.formStates.scheduleTicket.schedule && props.formStates.scheduleTicket.schedule.frequency && props.formStates.scheduleTicket.schedule.selected_week && props.formStates.scheduleTicket.schedule.day && props.formStates.scheduleTicket.schedule.selected_month && props.formStates.scheduleTicket.schedule.start_date && props.formStates.scheduleTicket.schedule.end_date) {
            props.occurrenceCheckScheduledDates(props.formStates.scheduleTicket)
        }
    }, [props.formStates])

    useEffect(() => {
        if(props.scheduleCheckValues) {
            if(props.formStates && props.formStates.scheduleTicket.schedule && props.formStates.scheduleTicket.schedule && props.formStates.scheduleTicket.schedule.frequency === 'weekly') {
                const occLength = props.scheduleCheckValues.occurrence.length;
                const day = props.formStates.scheduleTicket.schedule.day;
                const select_week = props.formStates.scheduleTicket.schedule.select_week;
                if(occLength && day && select_week) {
                    setoccurrence(`Every ${getSelectedWeek(select_week) === '1st' ? ' week' : getSelectedWeek(select_week) + ' week' } on ${makeFirstLetterCapital(day)}, until ${moment(props.formStates.scheduleTicket.schedule.end_date).format('MM-DD-YYYY')}, ${occLength} occurrence(s)`)
                    setOccurrenceList(props.scheduleCheckValues.occurrence)
                } else {
                    setoccurrence(`0 occurrence(s)`)
                }
            } else if (props.formStates && props.formStates.scheduleTicket.schedule.frequency === 'monthly') {
                const occLength = props.scheduleCheckValues.occurrence.length;
                const day = props.formStates.scheduleTicket.schedule.day;
                const selected_week = props.formStates.scheduleTicket.schedule.selected_week;
                if(occLength && day && selected_week) {
                    setoccurrence(`Every Month ${getSelectedWeek(selected_week)} week on ${makeFirstLetterCapital(day)}, until ${moment(props.formStates.scheduleTicket.schedule.end_date).format('MM-DD-YYYY')}, ${occLength} occurrence(s)`)
                    setOccurrenceList(props.scheduleCheckValues.occurrence)
                } else {
                    setoccurrence(`0 occurrence(s)`)
                }
            } else {
                if(props.formStates) {
                    const occLength = props.scheduleCheckValues.occurrence.length;
                    const day = props.formStates.scheduleTicket.schedule.day;
                    const selected_month = props.formStates.scheduleTicket.schedule.selected_month;
                    const selected_week = props.formStates.scheduleTicket.schedule.selected_week;

                    if(occLength && day && selected_month && selected_week) {
                        setoccurrence(`Every ${getQuarter(selected_month)} Quarter ${getSelectedWeek(selected_week)} week on ${makeFirstLetterCapital(day)}, until ${moment(props.formStates.scheduleTicket.schedule.end_date).format('MM-YYYY')}, ${occLength} occurrence(s)`)
                        setOccurrenceList(props.scheduleCheckValues.occurrence)
                    } else {
                        setoccurrence(`0 occurrence(s)`)
                    }
                } else {
                    setoccurrence(`0 occurrence(s)`)
                }
            }
        }
    }, [props.scheduleCheckValues])

    let event = {}
    if(props.formStates && props.formStates.scheduleTicket && props.formStates.scheduleTicket.schedule && props.formStates.scheduleTicket.schedule.frequency === 'weekly') {
        event = {
            title: props.formStates && props.formStates.scheduleTicket.title,
            description: `New schedule created for ${props.formStates && props.formStates.scheduleTicket && props.formStates.scheduleTicket.account_id && props.formStates.scheduleTicket.account_id.label}`,
            location: 'United States',
            startTime: OutlookGetFromFormatedDate(props.scheduleCheckValues && props.scheduleCheckValues.occurrence.length > 0 && props.scheduleCheckValues.occurrence[0]),
            endTime: OutlookGetToFormatedDate(props.scheduleCheckValues && props.scheduleCheckValues.occurrence.length > 0 && props.scheduleCheckValues.occurrence[0]),
            recurring: {
                repeat: props.formStates && props.formStates.scheduleTicket.schedule.frequency, /* or DaiLy, Monthly, Yearly */
                byDay: getRecurringDay(props.formStates && props.formStates.scheduleTicket.schedule.day), /* SU,MO,TU,WE,TH,FR,SA */
                interval: props.formStates && props.formStates.scheduleTicket.schedule.select_week, /* Default is 1 */
                weekStart: 'SU', /* Week start default is Sunday */
                until: getUntilDate(props.formStates && props.formStates.scheduleTicket.schedule.end_date),
                // count: props.scheduleCheckValues && props.scheduleCheckValues.occurrence.length, /* Weekly for 10 occurrences */
            }
        }
    } else if(props.formStates && props.formStates.scheduleTicket && props.formStates.scheduleTicket.schedule && props.formStates.scheduleTicket.schedule.frequency === 'monthly') {
        event = {
            title: props.formStates && props.formStates.scheduleTicket.title,
            description: `New schedule created for ${props.formStates && props.formStates.scheduleTicket && props.formStates.scheduleTicket.account_id && props.formStates.scheduleTicket.account_id.label}`,
            location: 'United States',
            startTime: OutlookGetFromFormatedDate(props.scheduleCheckValues && props.scheduleCheckValues.occurrence.length > 0 && props.scheduleCheckValues.occurrence[0]),
            endTime: OutlookGetToFormatedDate(props.scheduleCheckValues && props.scheduleCheckValues.occurrence.length > 0 && props.scheduleCheckValues.occurrence[0]),
            recurring: {
                repeat: props.formStates && props.formStates.scheduleTicket.schedule.frequency, /* or DaiLy, Monthly, Yearly */
                byDay: `${props.formStates && props.formStates.scheduleTicket.schedule.selected_week}${getRecurringDay(props.formStates && props.formStates.scheduleTicket.schedule.day)}`, /* SU,MO,TU,WE,TH,FR,SA , 2MO, 2TU*/
                // interval: props.formStates && props.formStates.schedules.select_week, /* Default is 1 */
                weekStart: 'SU', /* Week start default is Sunday */
                until: getUntilDate(props.formStates && props.formStates.scheduleTicket.schedule.end_date),
                // count: props.scheduleCheckValues && props.scheduleCheckValues.occurrence.length, /* Weekly for 10 occurrences */
            }
        }
    } else {
        event = {
            title: props.formStates && props.formStates.scheduleTicket.title,
            description: `New schedule created for ${props.formStates && props.formStates.scheduleTicket && props.formStates.scheduleTicket.account_id && props.formStates.scheduleTicket.account_id.label}`,
            location: 'United States',
            startTime: OutlookGetFromFormatedDate(props.scheduleCheckValues && props.scheduleCheckValues.occurrence.length > 0 && props.scheduleCheckValues.occurrence[0]),
            endTime: OutlookGetToFormatedDate(props.scheduleCheckValues && props.scheduleCheckValues.occurrence.length > 0 && props.scheduleCheckValues.occurrence[0]),
            ecurring: {
                repeat: props.formStates && props.formStates.scheduleTicket && props.formStates.scheduleTicket.schedule && props.formStates.scheduleTicket.schedule.frequency, /* or DaiLy, Monthly, Yearly */
                byDay: `${props.formStates && props.formStates.scheduleTicket && props.formStates.scheduleTicket.schedule && props.formStates.scheduleTicket.schedule.selected_week}${getRecurringDay(props.formStates && props.formStates.scheduleTicket && props.formStates.scheduleTicket.schedule && props.formStates.scheduleTicket.schedule.day)}`, /* SU,MO,TU,WE,TH,FR,SA , 2MO, 2TU*/
                // interval: props.formStates && props.formStates.schedules.select_week, /* Default is 1 */
                weekStart: 'SU', /* Week start default is Sunday */
                // count: props.scheduleCheckValues && props.scheduleCheckValues.occurrence.length, /* Weekly for 10 occurrences */
                until: getUntilDate(props.formStates && props.formStates.scheduleTicket && props.formStates.scheduleTicket.schedule && props.formStates.scheduleTicket.schedule.end_date),
            }
        }
    }
    let items = [
        { outlook: 'Outlook ics file' }
    ];

    const noOptionsMessage = (a, b) => {
        return 'No options';
    }
    const noAreaOptionsMessage = (a, b) => {
        return 'Please choose another Job';
    }

    const { handleSubmit, pristine, reset, submitting, error, account, submitFailed, change } = props;

    // const [isImageGallery, setIsImageGallery] = useState(false);
    // const [startIndex, setStartIndex] = useState(0);

    // const base64Clicked = (i, urlLength) => {
    //     setIsImageGallery(true);
    //     setStartIndex((urlLength + i));
    // }

    // if (isImageGallery) {
    //     return <ModalImageGallery
    //         isImageGallery={isImageGallery}
    //         setIsImageGallery={setIsImageGallery}
    //         startIndex={startIndex}
    //         base64={props.portfolioImages}
    //         imageUrls={props.portfolioImages}
    //     />
    // }

    return (
        <div>

            <main className="app-content  wraper_content inspection_wraper user_newQuote" id="schedule_form">
                <div className="inspection_content_sec appcontent_Inner">
                    <div className="container-fluid">
                        <div className="quote_form ticket_form" style={{maxWidth: '700px'}}>
                            <h2 className="md_title" data-test="create_ticket_header">Create New Ticket Schedule {isRecurring == true ? '(Recurring)' : isNonRecurring == true ? '(Non Recurring)' : ''}</h2>
                            <div className="addQuote_form">
                                <form onSubmit={handleSubmit}>
                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col-lg-6 pl-1">
                                                <label className="form_title"> Title <sup className="cst_sup_txt text_danger">*</sup></label>
                                                <Input name="scheduleTicket[title]" dataTest="ticket_subject" type="text" className="form-control input-modifier" placeholder="Title" />
                                            </div>
                                            <div className="col-lg-6 pr-1">
                                                {props.match.params.accountId ? <>
                                                    <label className="form_title">Job Name</label>
                                                    <input name="scheduleTicket[account_id]" dataTest="ticket_subject" type="text" className="form-control input-modifier" placeholder={account_info ? account_info.name : getSelectedAccountName(props.accountsList)} disabled />
                                                </> : 
                                                <>
                                                    <label className="form_title">Job Name <sup className="cst_sup_txt text_danger">*</sup></label>
                                                    <Input name="scheduleTicket[account_id]" noOptionsMessage={noOptionsMessage} onChange={(e) => {props.getAccountAreasListTrigger(e.value); props.getAssignedUsersListTrigger(e.value)}} type="inputSelect" placeholder="Select Job Name" options={accounts} />
                                                </>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col-lg-6 pl-1">
                                                <label className="form_title">Assigned to <sup className="cst_sup_txt text_danger">*</sup></label>
                                                <Input name="scheduleTicket[assigned_to]" dataTest="ticket_assigned" noOptionsMessage={noOptionsMessage} type="inputSelect" placeholder="Select Assigned to" options={assignedUsersList} />
                                            </div>
                                            <div className="col-lg-6 pr-1">
                                                <label className="form_title"> Category <sup className="cst_sup_txt text_danger">*</sup></label>
                                                <Input name="scheduleTicket[category]" noOptionsMessage={noOptionsMessage} type="inputSelect" placeholder="Select Category" options={categoriesList} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col-lg-6 pl-1">
                                                <div className="col-lg-16 mb-3">
                                                    <label className="form_title"> Subject <sup className="cst_sup_txt text_danger">*</sup></label>
                                                    <Input name="scheduleTicket[subject]" dataTest="ticket_subject" type="text" placeholder="Subject" className="form-control input-modifier" />
                                                </div>
                                                <div className="col-lg-16 mb-3">
                                                    {/* <label className="form_title"> Due Date <sup className="cst_sup_txt text_danger">*</sup></label>
                                                    <div className="input-group cst_input_group">
                                                        <Input name="scheduleTicket[due_on]" dataTest="ticket_time_picker" minDate={new Date()} dateFormat="MM-dd-yyyy" placeholder="Due Date" datePickerType={"react-datepicker"} type="date" className="form-control from-date-analytics" />
                                                        <div className="input-group-append cursor-pointer" style={{ float: "right", position: "absolute", right: 0, height: "36px", top: 0 }} onClick={e => document.querySelector(".from-date-analytics").click()}>
                                                            <span className="input-group-text" id="basic-addon2"><i className="fa fa-calendar" alt="Calendar Icon" ></i></span>
                                                        </div>
                                                    </div> */}
                                                    {/* <div className="col-lg-6 pr-1"> */}
                                                <label className="form_title"> Priority <sup className="cst_sup_txt text_danger">*</sup></label>
                                                <Input name="scheduleTicket[priority]" dataTest="scheduleTicket[priority]" className="custom-select input-modifier" noOptionsMessage={noOptionsMessage} type="select" placeholder="Select Priority" options={priorityOptions} />
                                            {/* </div> */}
                                                </div>
                                            </div>
                                            <div className="col-lg-6 pr-1">
                                                <label className="form_title"> Message <sup className="cst_sup_txt text_danger">*</sup></label>
                                                <Input type="textarea" name="scheduleTicket[message]" className="form-control textarea_modifier" rows="5" placeholder="Detailed Message" />
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="form-group">
                                        <div className="row">
                                            <div className="col-lg-6 pl-1">
                                                <label className="form_title">Area Name <sup className="cst_sup_txt text_danger">*</sup></label>
                                                <Input dataTest="ticket_subject" name="scheduleTicket[area_id]" noOptionsMessage={noAreaOptionsMessage} type="inputSelect" placeholder="Select Area Name" options={accountAreasList} />
                                            </div>
                                        </div>
                                    </div> */}
                                    <div className="checkbox_block radius_checkbox">
                                        <div className="custom-control custom-checkbox">
                                            <Input name="scheduleTicket[is_private]" type="checkbox" className="custom-control-input" id="customCheck1" checked />
                                            <label className="custom-control-label" for="customCheck1"> Private </label>
                                        </div>
                                    </div>

                                    <div className="file_attachment">
                                        <Input type="file" name="scheduleTicket[attachments]" hidden={true} multiple={true} onSelect={props.onPortfolioFileChange} fileRef={props.reference} accept="image/*" />
                                        <a href="javascript:void(0)" onClick={props.choosePhotoClicked} className="file_title"><img src="/images/icons/attachment_icon.png" alt="Icon" /> Attachments</a>
                                        <p className="size_dec">Upload attachments (10MB each).</p>

                                        <div class="upload_file">
                                            <div class="row">
                                                {props.portfolioImages.map((url, i) => {
                                                    return (
                                                        <div class="col-4 col-sm-4 cst_gutter" key={i} >
                                                            <div class="file_view">
                                                                <img src={url} alt="Image"
                                                                //  onClick={() => base64Clicked(i, props.portfolioImages.length)} 
                                                                 />
                                                                <div class="cancel_icon">
                                                                    <img src="/images/icons/icn_delete_white.png" onClick={() => props.removeImage(url, i)} alt="Image" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                }

                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label className="form_title">Event Recurring <sup className="cst_sup_txt text_danger">*</sup></label>
                                        <Input type="radio" onClick={isRecurringClicked} labelClass="custom-control-label" forLable={"recurring"} value={"true"} label={"Recurring"} className="custom-control-input" id="recurring" name="scheduleTicket[is_ticket_recur]" />
                                        <Input type="radio" onClick={isNonRecurringClicked} labelClass="custom-control-label" forLable={"nonRecurring"} value={"false"} label={"Non-Recurring"} className="custom-control-input" id="nonRecurring" name="scheduleTicket[is_ticket_recur]" />
                                    </div>
                                    {
                                        isRecurring &&
                                        <div className="form-group">
                                            <label className="form_title">Schedule <sup className="cst_sup_txt text_danger">*</sup></label>
                                            <Input type="radio" onClick={weeklyTabClicked} labelClass="custom-control-label" forLable={"customRadio1"} value={"weekly"} label={"Weekly"} className="custom-control-input" id="customRadio1" name="scheduleTicket[schedule][frequency]" />
                                            <Input type="radio" onClick={monthlyTabClicked} labelClass="custom-control-label" forLable={"customRadio2"} value={"monthly"} label={"Monthly"} className="custom-control-input" id="customRadio2" name="scheduleTicket[schedule][frequency]" />
                                            <Input type="radio" onClick={quarterlyTabClicked} labelClass="custom-control-label" forLable={"customRadio3"} value={"quarterly"} label={"Quarterly"} className="custom-control-input" id="customRadio3" name="scheduleTicket[schedule][frequency]" />
                                        </div>
                                    }
                                    {
                                        isNonRecurring &&
                                        <div className="form-group">
                                            {/* <div className="row"> */}
                                            <label className="form_title"> Select Date <sup className="cst_sup_txt text_danger">*</sup></label>
                                            <div className="input-group cst_input_group col-lg-6 pl-1">
                                                <Input name="scheduleTicket[start_date]" dataTest="ticket_time_picker" minDate={new Date()} dateFormat="MM-dd-yyyy" placeholder="Select Date" datePickerType={"react-datepicker"} type="date" className="form-control from-date-analytics" />
                                                <div className="input-group-append cursor-pointer" style={{ float: "right", position: "absolute", right: 0, height: "36px", top: 0 }} onClick={e => document.querySelector(".from-date-analytics").click()}>
                                                    <span className="input-group-text" id="basic-addon2"><i className="fa fa-calendar" alt="Calendar Icon" ></i></span>
                                                </div>
                                            </div>
                                            {/* </div> */}
                                        </div>
                                    }
                                    {
                                        weeklyTab &&
                                        <div className="weekly_tab">
                                            <div className="form-group">
                                                <div className="row">
                                                    <div className="col-lg-6 pr-1">
                                                        <label className="form_title">Recur week(s) on <sup className="cst_sup_txt text_danger">*</sup></label>
                                                        <Input name="scheduleTicket[schedule][select_week]" type="select" options={recurOnWeekSelect} placeholder="Week" className="custom-select input-modifier add_user_select" />
                                                    </div>
                                                    <div className="col-lg-6 pl-1">
                                                        <label className="form_title">Select Day <sup className="cst_sup_txt text_danger">*</sup></label>
                                                        <Input name="scheduleTicket[schedule][day]" type="select" options={selectDay} placeholder="Day" className="custom-select input-modifier add_user_select" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group" id="calender">
                                                <label className="form_title">Range of recurrence <sup className="cst_sup_txt text_danger">*</sup></label>
                                                <div className="row">
                                                    <div className="col-lg-6 pr-1">
                                                        <label> Start
                                                            <Input name="scheduleTicket[schedule][start_date]" minDate={new Date().setDate(current.getDate())} dateFormat="MM-dd-yyyy" placeholder="From Date" datePickerType={"react-datepicker"} type="date" className="form-control input-modifier" />
                                                            <div class="input-group-append cst_group_append"><span class="input-group-text" id="basic-addon2"><i class="fa fa-calendar"></i></span></div>
                                                        </label>
                                                    </div>
                                                    <div className="col-lg-6 pl-1">
                                                        <label> End by
                                                            <Input name="scheduleTicket[schedule][end_date]" minDate={new Date().setDate(current.getDate())} dateFormat="MM-dd-yyyy" placeholder="To Date" datePickerType={"react-datepicker"} type="date" className="form-control input-modifier" />
                                                            <div class="input-group-append cst_group_append"><span class="input-group-text" id="basic-addon2"><i class="fa fa-calendar"></i></span></div>
                                                        </label>
                                                    </div>
                                                </div>
                                                <div style={{display: 'none'}}> <AddToCalendar event={event} listItems={items}/> </div>
                                            </div>
                                        </div>
                                    }
                                    {
                                        monthlyTab &&
                                        <div className="monthly_tab">
                                            <div className="form-group box_input">
                                                <label className="form_title">Yearly Recurring</label>
                                                <Input type="radio" labelClass="custom-control-label" forLable={"customRadio5"} value={"true"} label={"Yes"} className="custom-control-input" id="customRadio5" name="scheduleTicket[schedule][is_recur]" />
                                                <Input type="radio" labelClass="custom-control-label" forLable={"customRadio6"} value={"false"} label={"No"} className="custom-control-input" id="customRadio6" name="scheduleTicket[schedule][is_recur]" />
                                            </div>
                                            <div className="form-group">
                                                <div className="row">
                                                    <div className="col pr-1">
                                                        <label className="form_title">Select Week <sup className="cst_sup_txt text_danger">*</sup></label>
                                                        <Input name="scheduleTicket[schedule][selected_week]" type="select" options={recurWeekSelect} placeholder="Week" className="custom-select input-modifier add_user_select" />
                                                    </div>
                                                    <div className="col pl-1 pr-1">
                                                        <label className="form_title">Select Day <sup className="cst_sup_txt text_danger">*</sup></label>
                                                        <Input name="scheduleTicket[schedule][day]" type="select" options={selectDay} placeholder="Day" className="custom-select input-modifier add_user_select" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group month_picker" id="calender">
                                                <label className="form_title">Range of recurrence <sup className="cst_sup_txt text_danger">*</sup></label>
                                                <div className="row">
                                                    <div className="col-lg-6 pr-1">
                                                        <label> Start
                                                            <Input name="scheduleTicket[schedule][start_date]" minDate={new Date().setDate(current.getDate() - 28)} dateFormat="MMMM-yyyy" placeholder="From Date" datePickerType={"react-month-datepicker"} type="date" className="form-control input-modifier" />
                                                            <div class="input-group-append cst_group_append"><span class="input-group-text" id="basic-addon2"><i class="fa fa-calendar"></i></span></div>
                                                        </label>
                                                    </div>
                                                    <div className="col-lg-6 pl-1">
                                                        <label> End by
                                                            <Input name="scheduleTicket[schedule][end_date]" minDate={new Date().setDate(current.getDate() - 28)} dateFormat="MMMM-yyyy" placeholder="To Date" datePickerType={"react-month-datepicker"} type="date" className="form-control input-modifier" />
                                                            <div class="input-group-append cst_group_append"><span class="input-group-text" id="basic-addon2"><i class="fa fa-calendar"></i></span></div>
                                                        </label>
                                                    </div>
                                                </div>
                                                <div style={{display: 'none'}}> <AddToCalendar event={event} listItems={items}/> </div>
                                            </div>
                                        </div>
                                    }
                                    {
                                        quarterlyTab &&
                                        <div className="monthly_tab">
                                            <div className="form-group box_input">
                                                <label className="form_title">Yearly Recurring</label>
                                                <Input type="radio" labelClass="custom-control-label" forLable={"customRadio7"} value={"true"} label={"Yes"} className="custom-control-input" id="customRadio7" name="scheduleTicket[schedule][is_recur]" />
                                                <Input type="radio" labelClass="custom-control-label" forLable={"customRadio8"} value={"false"} label={"No"} className="custom-control-input" id="customRadio8" name="scheduleTicket[schedule][is_recur]" />
                                            </div>
                                            <div className="form-group">
                                                <div className="row">
                                                    <div className="col pr-1">
                                                        <label className="form_title">Select Week <sup className="cst_sup_txt text_danger">*</sup></label>
                                                        <Input name="scheduleTicket[schedule][selected_week]" type="select" options={recurWeekSelect} placeholder="Week" className="custom-select input-modifier add_user_select" />
                                                    </div>
                                                    <div className="col pl-1 pr-1">
                                                        <label className="form_title">Select Day <sup className="cst_sup_txt text_danger">*</sup></label>
                                                        <Input name="scheduleTicket[schedule][day]" type="select" options={selectDay} placeholder="Day" className="custom-select input-modifier add_user_select" />
                                                    </div>
                                                    <div className="col pl-1 pr-1">
                                                        <label className="form_title">Month(s) <sup className="cst_sup_txt text_danger">*</sup></label>
                                                        <Input name="scheduleTicket[schedule][selected_month]" type="select" options={QuarterlymonthSelect} placeholder="Quarter" className="custom-select input-modifier add_user_select" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group month_picker" id="calender">
                                                <label className="form_title">Range of recurrence <sup className="cst_sup_txt text_danger">*</sup></label>
                                                <div className="row">
                                                    <div className="col-lg-6 pr-1">
                                                        <label> Start
                                                            <Input name="scheduleTicket[schedule][start_date]" minDate={new Date().setDate(current.getDate() - 28)} dateFormat="MM-yyyy" placeholder="From Date" datePickerType={"react-month-datepicker"} type="date" className="form-control input-modifier" />
                                                            <div class="input-group-append cst_group_append"><span class="input-group-text" id="basic-addon2"><i class="fa fa-calendar"></i></span></div>
                                                        </label>
                                                    </div>
                                                    <div className="col-lg-6 pl-1">
                                                        <label> End by
                                                            <Input name="scheduleTicket[schedule][end_date]" minDate={new Date().setDate(current.getDate() - 28)} dateFormat="MM-yyyy" placeholder="To Date" datePickerType={"react-month-datepicker"} type="date" className="form-control input-modifier" />
                                                            <div class="input-group-append cst_group_append"><span class="input-group-text" id="basic-addon2"><i class="fa fa-calendar"></i></span></div>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    <div className="form-group">
                                        <label className="form_title">Set Reminder Time & Notes</label>
                                        {reminderTimeSlot.map((time, index) => {
                                            return (<>
                                                <div class="input-group" style={{justifyContent: 'space-between', alignItems: 'center'}}>
                                                    <div className='badaiho' style={{width: '80%', display: 'flex'}}>
                                                        <div className="mr-1 mb-1 mt-1" style={{width: '29%'}}>
                                                            <Input className="custom-select input-modifier" dataTest={`scheduleTicket[reminders][${index}][ba_value]`} noOptionsMessage={noOptionsMessage} type="select" placeholder="Reminder Type" options={beforeAfterOption} name={`scheduleTicket[reminders][${index}][ba_value]`} />
                                                        </div>
                                                        <div className="mr-1 mb-1 mt-1" style={{width: '26%'}}>
                                                            <Input className="custom-select input-modifier" dataTest={`scheduleTicket[reminders][${index}][hours_value]`} noOptionsMessage={noOptionsMessage} type="select" placeholder="Select Hours" options={fortyEightHoursOption} name={`scheduleTicket[reminders][${index}][hours_value]`} />
                                                        </div>
                                                        <div className="mb-1 mt-1" style={{width: '42%'}}>
                                                            <Input className="form-control input-modifier" dataTest={`scheduleTicket[reminders][${index}][note]`} type="text" placeholder="Reminder Notes" name={`scheduleTicket[reminders][${index}][note]`} />
                                                        </div>
                                                    </div>
                                                    <div className='hadfi'>
                                                        <div class="btn-group" role="group" aria-label="Basic example">
                                                            <button type="button" className="btn btn-outline-danger" onClick={() => [setReminderTimeSlot([...reminderTimeSlot, ''])]}><i className="fa fa-plus"></i></button>
                                                            {index != 0 && <button type="button" class="btn btn-dark" onClick={e => deleteReminderTimeSlot(index)}><i className="far fa-trash-alt"></i></button>}
                                                        </div>
                                                    </div>
                                                    <div className='mt-1'>
                                                    {props.formStates && props.formStates.scheduleTicket && props.formStates.scheduleTicket.reminders && props.formStates.scheduleTicket.reminders[index] && !(props.formStates.scheduleTicket.reminders[index].ba_value) ? <span style={{color: '#dd2726', fontSize: '13px'}}>Sequence is required</span> : props.formStates && props.formStates.scheduleTicket && props.formStates.scheduleTicket.reminders && props.formStates.scheduleTicket.reminders[index] && !(props.formStates.scheduleTicket.reminders[index].hours_value) ? <span style={{color: '#dd2726', fontSize: '13px'}}>Hours is required</span> : props.formStates && props.formStates.scheduleTicket && props.formStates.scheduleTicket.reminders && props.formStates.scheduleTicket.reminders[index] && !(props.formStates.scheduleTicket.reminders[index].note) ? <span style={{color: '#dd2726', fontSize: '13px'}}>Reminder notes is required</span> : ''}
                                                    </div>
                                                </div>
                                            </>)})}
                                    </div>

                                    {occurrence && props.formStates && props.formStates.scheduleTicket.schedule && (props.scheduleCheckValues && props.scheduleCheckValues.occurrence && props.scheduleCheckValues.occurrence.length > 0 ? 
                                    <button type="button" className={"btn btn-info btn-sm mb-0"} onClick={() => {props.handleClickScheduleOccurrenceModal()}}> Preview Occurrence </button> : 
                                    props.scheduleCheckValues && props.scheduleCheckValues.occurrence && props.scheduleCheckValues.occurrence.length == 0 ? 
                                    <label className="form_title" style={{backgroundColor: '#fff', color: '#850035'}}>{occurrence}</label> : null)}

                                    <div className="form-group btn_block mb-0">
                                        <button id='create-new-ticketSchedule' type='submit' data-test="submit_button" className="btn cst_btn btn_danger" style={{ cursor: "pointer" }} disabled={occurrence && (props.scheduleCheckValues && props.scheduleCheckValues.occurrence && props.scheduleCheckValues.occurrence.length == 0 ? true : false)}> Schedule Now </button>
                                        <button data-test="cancel_button" type="button" className="btn cst_btn btn-outline-secondary  cancel_btnnew" style={{ cursor: "pointer" }} onClick={() => {
                                            props.match && props.match.params && props.match.params.accountId ? 
                                            props.history.push(`/accounts/${props.match.params.accountId}/account_users/schedule-time`) :
                                            props.history.push(`/schedules`)
                                        }}>Cancel</button>
                                    </div>
                                    <Dialog
                                        open={ props.openScheduleOutlookConfirmModal}
                                        onClose={props.handleClose}
                                        aria-labelledby="form-dialog-title"
                                    >
                                        <DialogTitle id="form-dialog-title" style={{ fontSize: '13', color: '#06080f' }}>
                                            <div className="preview_schedule">
                                                <article className="custom_article">
                                                    <h3 className="text-center" style={{color: '#272727', fontSize: '20px', fontWeight: 'bold', fontFamily: 'Open Sans'}}> Do you want to save this event for Outlook calendar? </h3>
                                                </article>
                                                <DialogActions>
                                                    <button data-test="submit_button" type='button' onClick={() => {
                                                        setPromptLoader({...promptLoader, yes: true})
                                                        if(document.getElementsByClassName('react-add-to-calendar-recurring__button').length) {
                                                            document.getElementsByClassName('react-add-to-calendar-recurring__button')[0].click()
                                                        }
                                                        props.newScheduleTicketOutlookTrigger('yes')
                                                    }} className={props.isLoading && promptLoader && promptLoader.yes ? "btn cst_btn btn-danger btn-wait" : "btn cst_btn btn-outline-danger"}> {props.isLoading && promptLoader && promptLoader.yes ? "" : "Yes"} </button>
                                                    <button data-test="submit_button" type='button' onClick={() => {
                                                        setPromptLoader({...promptLoader, no: true})
                                                        props.newScheduleTicketOutlookTrigger('no')
                                                    }} className={props.isLoading && promptLoader && promptLoader.no ? "btn cst_btn btn-secondary btn-wait" : "btn cst_btn btn-outline-dark"}> {props.isLoading && promptLoader && promptLoader.no ? "" : "No"} </button>
                                                </DialogActions>
                                            </div>
                                        </DialogTitle>
                                    </Dialog>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <Dialog
                open={props.openScheduleOccurrenceModal}
                onClose={props.handleClose}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title" style={{ fontSize: '13', color: '#06080f' }}>
                    <div className="preview_schedule">
                        <button type="button" className="close" data-dismiss="modal" onClick={props.handleDeleteClose}><img src="/images/icons/icn_close_gray.png" alt="Close Icon" /></button>
                        <article className="custom_article"><div className="custom_title"> <label className="form_title" style={{backgroundColor: '#fff', color: '#850035'}}>{occurrence}</label> </div></article>
                        <div className="form-group">
                            <label className="custom_title"> OCCURENCE LIST </label>
                            {occurrenceList && occurrenceList.map((params) => {
                                if(props.formStates && props.formStates.scheduleTicket && props.formStates.scheduleTicket.schedule && props.formStates.scheduleTicket.schedule.frequency === 'weekly') {
                                    return ( <div className="db_data"> <ul><li>{moment(params).format('MM-DD-YYYY')}</li></ul> </div> )
                                } else {
                                    const dateList = params.split(' ')[0]
                                    return ( <div className="db_data"> <ul><li>{moment(dateList).format('MM-DD-YYYY')}</li></ul> </div> )
                                }
                            })}
                        </div>
                        <DialogActions> <button style={{ minWidth: '100%' }} onClick={props.handleDeleteClose} className={"btn cst_btn btn_danger mb-4"} id="cancel" type="cancel"> Close </button> </DialogActions>
                    </div>
                </DialogTitle>
            </Dialog>
            
        </div>
    );
}

const mapStateToProps = (state, props) => {
    let scheduleTicket = {};
    scheduleTicket['account_id'] = '';
    scheduleTicket['created_by'] = '';
    return{
        initialValues: {scheduleTicket},
        formStates: getFormValues('newAccountTicketScheduleForm')(state)
    };
}

AccountNewTicketSchedule = reduxForm({
    form: 'newAccountTicketScheduleForm',
    validate,
    enableReinitialize: true,
})(AccountNewTicketSchedule);
const selector = formValueSelector('newAccountTicketScheduleForm') // <-- same as form name
AccountNewTicketSchedule = connect(state => {
    const { fromDate, toDate } = selector(state, 'fromDate', 'toDate')
    const selectedValues = { fromDate, toDate }
    return {
        selectedValues
    }
})(AccountNewTicketSchedule)
AccountNewTicketSchedule = connect(mapStateToProps)(AccountNewTicketSchedule);

export default AccountNewTicketSchedule;