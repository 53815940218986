import axios, { API_VERSION, accessToken, formAuditBaseUrl } from '../../config';
import storage from '../../utility/storage';
import { getUser } from '../../utility/utility';
const pureAxios = require('axios');

const user = storage.get('user');

const headers = {
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
    },
};


export const getAllDeepCleanChart = (params) => {
    return formAuditBaseUrl.get(API_VERSION+`schedules/ticket/chart`, {params},  headers);
}