import React,{useState, useEffect} from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { Input } from '../../../../UI/InputElement/InputElement';
import { assetCheckOutFormValidator as validate } from '../../../../../../utility/validator/validator';
import moment from 'moment';
import { convertDateTimezone, makeFirstLetterCapital } from '../../../../../../utility/utility';
import isEmpty from '../../../../../../utility/IsEmptyValidator';
import cloneDeep from 'clone-deep';

let AssetCheckOutFormComponent = (props) => {

    const getLocationsList = (props) => {
        if (props.locations && props.locations.length > 0) {
            return props.locations && props.locations.map((location) => {
                return {
                    id: location.id,
                    name: makeFirstLetterCapital(location.name)
                }
            })
        } else {
            return []
        }
    };

    const [assetData, setAssetData] = useState(props.isolatedOneAssetData ? props.isolatedOneAssetData.ticket : {});
    const [locationsList, setLocationsListList] = useState(getLocationsList(props));

    useEffect(() => {
        setAssetData(props.isolatedOneAssetData.ticket)
    }, [props.isolatedOneAssetData])

    useEffect(() => {
        setLocationsListList(getLocationsList(props))
    }, [props.locations])

    const noOptionsMessage = (a, b) => {
        return 'No options';
    }

    const { handleSubmit, pristine, reset, submitting, error, submitFailed } = props;
  
    return (
        <main className="app-content add_account_modal add_frontline_pop">
            <div className="appcontent_Inner user_newQuote pt-0">
                <div className="inspection_content_sec pt-0">
                    <div className="quote_form ticket_form" style={{maxWidth: '700px', marginTop : '25px'}}>
                        <h1 className="section_title text-center mt-5" style={{color:'#2a2a2a', fontSize:'31px', fontWeight:'600'}}> Asset Check In Form </h1>
                        <div className="addQuote_form">
                            <form onSubmit={handleSubmit}>
                                <div className="cst_tab_content">
                                <div className="form-group">
                                        <div className="row">
                                            <div className="col-lg-6 pl-1">
                                        <label className="form_title font-weight-bold"> Job </label>
                                        <input name="scan[account_id]" type="text" className="form-control input-modifier" placeholder={assetData && assetData.account.name ? assetData.account.name : ''} disabled />
                                            </div>
                                            <div className="col-lg-6 pr-1">
                                        <label className="form_title font-weight-bold"> Select Location  <sup className="cst_sup_txt text_danger">*</sup></label>
                                        <Input name="scan[location_id]" type="select" placeholder="Select Location" className="custom-select input-modifier add_user_select" noOptionsMessage={noOptionsMessage}  options={locationsList ? locationsList : []} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col-lg-6 pl-1">
                                        <label className="form_title font-weight-bold"> Returned Equipment Condition </label>
                                        <Input name="scan[equipment_condition]" dataTest="ticket_subject" type="text" placeholder="Returned Equipment Condition" className="form-control input-modifier" />
                                            </div>
                                            <div className="col-lg-6 pr-1">
                                        <label className="form_title font-weight-bold"> Inspection Supervisor Initials </label>
                                        <Input name="scan[supervisor_initial_url]" dataTest="ticket_subject" type="text" placeholder="Inspection Supervisor Initials" className="form-control input-modifier" />
                                            </div>
                                        </div>
                                    </div>

                                    {/* <div className="form-group">
                                        <label className="form_title font-weight-bold"> Job </label>
                                        <input name="scan[account_id]" type="text" className="form-control input-modifier" placeholder={assetData && assetData.account.name ? assetData.account.name : ''} disabled />
                                    </div>

                                    <div className="form-group">
                                        <label className="form_title font-weight-bold"> Select Location  <sup className="cst_sup_txt text_danger">*</sup></label>
                                        <Input name="scan[location_id]" type="select" placeholder="Select Location" className="custom-select input-modifier add_user_select" noOptionsMessage={noOptionsMessage}  options={locationsList ? locationsList : []} />
                                    </div> */}

                                    {/* <div className="form-group">
                                        <label className="form_title font-weight-bold"> Date & Time Purchased </label>
                                        <input name="scan[purchasedDate]" type="text" className="form-control input-modifier" placeholder={convertDateTimezone(assetData && assetData.created_at && assetData.created_at, 'America/Los_Angeles')} disabled />
                                    </div>

                                    <div className="form-group">
                                        <label className="form_title"> Detailed Description <sup className="cst_sup_txt text_danger">*</sup></label>
                                        <Input name="scan[desc]" type="textarea" className="form-control textarea_modifier" rows="5" placeholder="Type here..." />
                                    </div> */}

                                </div>
                                <div className="form-group btn_block mb-0">
                                    <button data-test="submit_button" type="submit" className={props.isLoading ? "btn cst_btn btn_danger btn-wait " : "btn_box cst_btn btn_danger "} >{props.isLoading ? "" : "Check In"}</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
};

const mapStateToProps = (state) => {

  let scan = {};
    if (isEmpty(state.InventoryReducer.assets)) {
        scan = {
            // active_status: 'active',
        }
    } else {
        const values = cloneDeep(state.InventoryReducer && state.InventoryReducer.assets);
        let getLastIndex = state.InventoryReducer.assets.length - 1
        scan = {
            location_id: values && values[getLastIndex] && values[getLastIndex].location && values[getLastIndex].location.id ? values[getLastIndex].location.id : null,
            // name: values &&  values.name ? makeFirstLetterCapital(values.name)  : null,
            // sku_detail: values  && values.sku_detail ? values.sku_detail : null,
            // service_date: values  && values.service_date ? moment(values.service_date, "YYYY-MM-DDTHH:mm:ss")._d : null,
            // status: values  && values.status ? values.status : null,
        }
    }

  return { initialValues: { scan } };

};


AssetCheckOutFormComponent = reduxForm({
  form: 'assetCheckOutForm',
  validate,
  enableReinitialize: true,
})(AssetCheckOutFormComponent);

AssetCheckOutFormComponent = connect(mapStateToProps)(AssetCheckOutFormComponent);

export default AssetCheckOutFormComponent;