import axios from 'axios';
// import swal from 'sweetalert';
import '../sweetAlert2.css';
import swal from 'sweetalert2'

export default function errorHandler(error) {
    
    if (axios.isCancel(error)) {
        return Promise.reject(error);
    }

    if (error.data) {
        swal.fire({
            toast: true,
            type: 'error',
            timer: 3000,
            // width: '500px',
            position: 'top-end',
            animation: true,
            background: '#e85642',
            showConfirmButton: false,
            showCloseButton: true,
            title: error.message,
            // className: 'error-handler-modal'
        });
    }

    if (error.response && error.response.data && error.response.data.error) {
        swal.fire({
            toast: true,
            type: 'error',
            timer: 3000,
            // width: '500px',
            position: 'top-end',
            animation: true,
            background: '#e85642',
            showConfirmButton: false,
            showCloseButton: true,
            title: error.response.data.error.message,
            // className: 'error-handler-modal'
        });
    } else if (error.message) {

        if (error.message === 'Request failed with status code 401') {
            swal.fire({
                toast: true,
                type: 'error',
                timer: 3000,
                // width: '500px',
                position: 'top-end',
                animation: true,
                background: '#e85642',
                showConfirmButton: false,
                showCloseButton: true,
                title: 'Please Login To Continue',
                // #f1e1c0
                // text: error.message,
                // className: 'error-handler-modal'
            });
        } else {
            swal.fire({
                toast: true,
                type: 'error',
                timer: 3000,
                // width: '500px',
                position: 'top-end',
                animation: true,
                background: '#e85642',
                showConfirmButton: false,
                showCloseButton: true,
                title: error.message,
                // #f1e1c0
                // text: error.message,
                // className: 'error-handler-modal'
            });
        }

    } else if (error && error[0].AreaType) {
        return error;
    } else {
        if (error && error.length >= 58) {
            swal.fire({
                toast: true,
                type: 'error',
                timer: 3000,
                width: '700px',
                position: 'top-end',
                animation: true,
                background: '#e85642',
                showConfirmButton: false,
                showCloseButton: true,
                title: error,
                // #f1e1c0
                // text: error.message,
                // className: 'error-handler-modal'
            });
        } else {
            swal.fire({
                toast: true,
                type: 'error',
                timer: 3000,
                position: 'top-end',
                animation: true,
                background: '#e85642',
                showConfirmButton: false,
                showCloseButton: true,
                title: error,
                // #f1e1c0
                // text: error.message,
                // className: 'error-handler-modal'
            });
        }
    }

    return Promise.reject(error.message);
}
