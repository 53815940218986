import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

const Field = (h, w) => {
    return (<Skeleton height={h} width={w} />)
}

const Image = () => {
    return (<Skeleton circle={true} height={100} width={100} />)
}

const accountSkeleton = (props) => {
    return (
        // <main className="app-content wraper_content user_newQuote add_account_modal add_New_Accont">
        //     <div className="inspection_content_sec appcontent_Inner">
        <div className="container-fluids">
            <div className="row">
                <div className="col-sm-12 text-center">

                    <h4 className="section_title">{Field(30, 200)}</h4>
                </div>
            </div>
            {/* <form onSubmit={handleSubmit}> */}
            <div className="quote_form">
                <div className="addQuote_form">
                    <div className="cst_tab_content">
                        <div className="profile_img_block">

                            {Image()}

                            <div className="clearfix"></div>
                            <div className="profile-details">

                            </div>
                        </div>


                        <div className="row">
                            <div className="col-md-6" style={{ marginTop: '15px' }}>
                                <div className="form-group">

                                    {/* <label for="email" className="label_modifier"></label> */}
                                    {Field(20)}
                                    {/* <Input type="text" className="form-control input-modifier" id="email" placeholder="" name="user[first_name]" /> */}
                                </div>
                            </div>
                            <div className="col-md-6" style={{ marginTop: '15px' }}>
                                <div className="form-group">
                                    {Field(20)}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6" style={{ marginTop: '15px' }}>
                                <div className="form-group">
                                    {Field(20)}

                                </div>
                            </div>
                            <div className="col-md-6" style={{ marginTop: '15px' }}>
                                <div className="form-group">
                                    {Field(20)}

                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6" style={{ marginTop: '15px' }}>
                                <div className="form-group">
                                    {Field(20)}

                                </div>
                            </div>
                            <div className="col-md-6" style={{ marginTop: '15px' }}>
                                <div className="form-group">
                                    {Field(20)}

                                </div>
                            </div>
                        </div>
                        <div className="row">

                            <div className="col-md-12">
                                <div className="form-group" style={{ marginTop: '25px' }}>
                                    {Field(20)}
                                </div>
                            </div>

                        </div>
                        <div className="row">
                            <div className="col-md-6" style={{ marginTop: '15px' }}>
                                <div className="form-group">
                                    {Field(20)}

                                </div>
                            </div>
                            <div className="col-md-6" style={{ marginTop: '15px' }}>
                                <div className="form-group">
                                    {Field(20)}

                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6" style={{ marginTop: '15px' }}>
                                <div className="form-group">
                                    {Field(20)}

                                </div>
                            </div>
                            <div className="col-md-6" style={{ marginTop: '15px' }}>
                                <div className="form-group">
                                    {Field(20)}

                                </div>
                            </div>
                        </div>
                    </div>

                    {/* </form> */}
                </div>
                <div className="modal-footer">
                    {Field(20)}
                    {Field(20)}
                    {/* <button type="submit" disabled={props.isLoading ? true : false} className={props.isLoading ? "btn cst_btn btn_danger mr-2 btn-wait" : "btn cst_btn btn_danger mr-2"} >{props.isLoading ? "" : "Submit"} </button>
                                <button type="button" className="btn btn-outline-secondary cst_btn" onClick={props.onCancelClicked}>Cancel</button> */}
                </div>
            </div>
        </div>
        // {/* </form> */}
        //     </div>
        // </main>
    )
}

export default accountSkeleton;