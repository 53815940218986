import React, { Component } from 'react';
import * as actions from '../../../../../redux/actions/index';
// import { RolesOptions } from '../../../../utility/constants/constants'
// import { routes, address_attributes, MinImageResolution } from '../../../../utility/constants/constants';
import { connect } from 'react-redux';
import { extractExtention } from '../../../../../utility/utility';
import { decode, encode } from 'base64-arraybuffer';
// import isEmpty from '../../../../utility/IsEmptyValidator';
import { animateScroll as scroll } from 'react-scroll'
import PreviewSubScheduledTask from '../../../../component/TimeTrackerModuleComponent/SubScheduledTaskComponent/PreviewSubScheduledTask/PreviewSubScheduledTask';
import storage from '../../../../../utility/storage';
import { tzUTCDateConverterTool_V2 } from '../../../../../utility/TimezoneOperations/timezoneUtility';
const cloneDeep = require('clone-deep');

class PreviewSubScheduledTaskContainer extends Component {

    constructor(props) {
        super(props);
        
        this.state = {
            // portfolio properties
            faultImages: 0,
            isImagesEmpty: false,
            imageName: '',
            delete_image_object: {
                commentIndex: null,
                attachmentIndex: [],
                urlId: []
            },
            imageUrl: null,
            isCommentLoading: false,
            isUpdateComment: false,
            isResolveLoading: false,
            ticket: cloneDeep(this.props.ticket),
            // uploadingImagesLength = 0,
            isCommentAndResolveLoading: false,
            isCommentAndCancelLoading: false,
            base64: [],
            // savedPortfolioURls: this.props.ticket.attachments_attributes ? this.props.ticket.attachments_attributes : [],
            AllFileTypes: [],
            fileType: '',
        }
        this.inputOpenFileRef = React.createRef();
        this.onPortfolioFileChange = this.onPortfolioFileChange.bind(this);
        this.removeImage = this.removeImage.bind(this);
        this.choosePhotoClicked = this.choosePhotoClicked.bind(this);
        this.updateBase64 = this.updateBase64.bind(this);
    }

    componentDidUpdate = (PrevProps, PrevState) => {
        if (this.props.ticket !== PrevProps.ticket) {
            if (this.props.ticket.comments && this.props.ticket.comments.length !== 0 && !this.state.isUpdateComment &&
                (this.props.ticket.comments[this.props.ticket.comments.length - 1]
                    .attachments.length !== 0) && this.state.base64.length !== 0) {

                this.setState({
                    isCommentLoading: false,
                    base64: [],
                    fileType: '',
                    imageName: ''
                })
            } else if (this.state.base64.length === 0) {
                this.setState({
                    isCommentLoading: false
                })
            } else {
                this.setState({
                    isUpdateComment: false,
                    isCommentLoading: false,
                    base64: [],
                    fileType: '',
                    imageName: ''
                })
            }

            this.setState({
                ticket: this.props.ticket
            })
        }
    }
    
    componentDidMount = () => {
        if (this.props.match.params.id && this.props.match.params.ticketId ) {
            this.props.getSubScheduledTaskPreview(this.props.match.params.id, this.props.match.params.ticketId);
        }else if(this.props.match.params.id==undefined &&  this.props.match.params.ticketId ){
            this.props.getSubScheduledTaskPreview(undefined, this.props.match.params.ticketId);
        }
        scroll.scrollToTop();
    }

    updateBase64 = () => {
        this.setState({
            isCommentLoading: false,
            base64: [],
            fileType: '',
            imageName: ''
        })
    }

    nextClicked = () => {
        debugger
        let storedRoute = ''
        if (this.props.history.location && this.props.history.location.state) {
            if (this.props.history.location.state.storedRoute) {
                storedRoute = this.props.history.location.state.storedRoute;
            }
        }
        this.props.ticketIndex && this.props.ticketIndex.map((ins, i) => {
            if (this.props.match.params.id === ins) {
                this.props.history.push({ pathname: `/schedules/tickets/preview-ticket/${this.props.ticketIndex[i + 1]}`, state: { storedRoute: storedRoute && storedRoute.length > 0 ? storedRoute : null } });
                this.props.getSubScheduledTaskPreview(this.props.match.params.id, this.props.ticketIndex[i + 1]);
            }
        })
    }

    previousClicked = () => {
        let storedRoute = ''
        if (this.props.history.location && this.props.history.location.state) {
            if (this.props.history.location.state.storedRoute) {
                storedRoute = this.props.history.location.state.storedRoute;
            }
        }
        this.props.ticketIndex && this.props.ticketIndex.map((ins, i) => {
            if (this.props.match.params.id === ins) {
                this.props.history.push({ pathname: `/schedules/tickets/preview-ticket/${this.props.ticketIndex[i - 1]}`, state: { storedRoute: storedRoute && storedRoute.length > 0 ? storedRoute : null } });
                this.props.getSubScheduledTaskPreview(this.props.match.params.id, this.props.ticketIndex[i - 1]);
            }
        })
    }

    componentWillUnmount() {
        this.props.resetObjects();
    }

    choosePhotoClicked = () => {
        this.inputOpenFileRef.current.click();
    }

    onPortfolioFileChange = (e) => {
        this.setState({
            faultImages: 0
        });
        console.log(this.state.base64);
        let files = e.target.files;
        let imageName = [], fileType = [], AllFileTypes = [], base64 = [];
        const that = this;
        // for (let i = 0; i < files.length; i++) {
        function readFile(index) {
            if (index < files.length) {
                let reader = new FileReader();
                let file = files[index];
                imageName.push(file.name)
                fileType.push(file.type)
                AllFileTypes.push(file.type)
                console.log(file, "file<<<<<<")
                if (file.type.match('image')) {
                    console.log(reader, "reader<<<<<")
                    reader.onload = (e) => {
                        var image = new Image();
                        image.src = reader.result;
                        image.onload = function () {
                            // alert('width' + image.width + 'height' + image.height);
                            // if (image.width >= MinImageResolution.width && image.height >= MinImageResolution.height) {
                            base64.push(e.target.result);
                            if (index === files.length - 1) {
                                //   
                                that.setState(prevState => ({
                                    imageName: [...prevState.imageName, ...imageName],
                                    fileType: [...prevState.fileType, ...fileType],
                                    AllFileTypes: [...prevState.AllFileTypes, ...AllFileTypes],
                                    // arrayBuffer: [...prevState.arrayBuffer, decode(reader.result)],
                                    base64: [...prevState.base64, ...base64]
                                }))
                            }
                            // }
                            // else {
                            //     that.setState(prevState => ({
                            //         faultImages: prevState.faultImages + 1
                            //     }))
                            // }
                            readFile(index + 1);
                        };

                    }
                }

                // reader.readAsArrayBuffer(file)
                reader.readAsDataURL(file)
            }
        }

        readFile(0);
    }

    removeImage = (url, fileTypeIndex) => {

        let updatedUrls = [...this.state.base64];
        let updatedFileTypes = [...this.state.fileType]
        let updatedImageNames = [...this.state.imageName]

        let urlIndex = this.state.base64.findIndex(function (u) {
            return u === url
        })
        updatedUrls.splice(urlIndex, 1);
        updatedFileTypes.splice(fileTypeIndex, 1)
        updatedImageNames.splice(fileTypeIndex, 1)

        this.setState({
            base64: updatedUrls,
            fileType: updatedFileTypes,
            imageName: updatedImageNames
        })

    }

    addTicketComment = (values, updateCommentId, setEditCommentObject) => {
        

        if (setEditCommentObject) {
            this.setState({
                isUpdateComment: true
            })
        }

        const user = storage.get('user')
        let ticket = cloneDeep(values);
        const arrayBuffer = [];

        let data = {
            data: {
                ticket_id: ticket.ticket.id,
                description: ticket.ticket.comments_attributes[ticket.ticket.comments_attributes?.length - 1].description,
                user_id: user.id,
                attachment: [],
                removeAttachmentIds: this.state.delete_image_object.urlId
            }
        }

        if (this.state.base64.length > 0) {

            this.state.base64.map((url, i) => {
                if (!url.photo_urls) {
                    let base_photo = null;
                    const image = url.split(',');
                    base_photo = image[1];

                    arrayBuffer.push(decode(base_photo))
                }

            })
        }
        const extentions = extractExtention(this.state.imageName);
        this.setState({
            isCommentLoading: true
        })
        if (arrayBuffer.length > 0) {
            this.props.getcommentsAttachmentPresignUrl(data, extentions, arrayBuffer, updateCommentId, setEditCommentObject);
        } else if (updateCommentId) {
            if (data.data.ticket_id) {
                delete data.data.ticket_id
            }
            if (data.data.user_id) {
                delete data.data.user_id
            }
            if (data.data.attachment) {
                delete data.data.attachment
            }
            this.props.updateComment(data, updateCommentId, setEditCommentObject)
        } else {
            if (data.data.removeAttachmentIds) {
                delete data.data.removeAttachmentIds
            }
            this.props.addComment(data)
        }


    }

    addTicketCommentAndResolve = (values) => {
        


        const user = storage.get('user')
        let ticket = cloneDeep(values);
        const arrayBuffer = [];
        let data = {}
        if (ticket.ticket.comments_attributes) {
            data = {
                data: {
                    comments_attributes: [{
                        ticket_id: ticket.ticket.id,
                        description: ticket.ticket.comments_attributes[ticket.ticket.comments_attributes?.length - 1].description,
                        user_id: user.id,
                        attachment: [],
                    }],
                    status: "resolved"
                }
            }
        } else {
            data = {
                data: {
                    status: "resolved"
                }
            }
        }
        if (this.state.base64.length > 0) {

            this.state.base64.map((url, i) => {
                if (!url.photo_urls) {
                    let base_photo = null;
                    const image = url.split(',');
                    base_photo = image[1];

                    arrayBuffer.push(decode(base_photo))
                }

            })
        }
        const extentions = extractExtention(this.state.imageName);
        this.setState({
            isResolveLoading: true
        })
        if (arrayBuffer.length > 0) {
            this.props.getResolveScheduledAttachmentPresignUrl(data, extentions, arrayBuffer, this.props.match.params.id, this.props.match.params.ticketId);
        } else {
            this.props.resolveScheduledTicket(data, this.props.match.params.id, this.props.match.params.ticketId)
        }
    }

    removeUrls = (url, index) => {
        let Ticket = cloneDeep(this.state.ticket);
        let updatedImageObject = []
        // if (url.photo_urls) {
        //     this.setState(prevState => ({
        //         delete_image_ids: [...prevState.delete_image_ids, url.id]
        //     }))
        // }

        let urlIndex = Ticket.comments[index].attachments.findIndex(function (attachments) {
            return attachments.id === url.id
        })

        updatedImageObject = {
            commentIndex: index,
            attachmentIndex: [...this.state.delete_image_object.attachmentIndex, urlIndex],
            urlId: [...this.state.delete_image_object.urlId, url.id]
        }

        if (url.photo_urls) {
            this.setState({
                delete_image_object: updatedImageObject
            })
        }

        Ticket.comments[index].attachments.splice(urlIndex, 1);
        this.setState({
            ticket: Ticket
        })

    }

    cancelEditClicked = () => {
        this.setState({
            delete_image_object: {
                commentIndex: null,
                attachmentIndex: [],
                urlId: []
            },
            base64: [],
            imageName: [],
            fileType: [],
            AllFileTypes: [],
            ticket: this.props.ticket
        })
    }

    render() {

        return (
            <PreviewSubScheduledTask
                {...this.props}
                addTicketComment={this.addTicketComment}
                addTicketCommentAndResolve={this.addTicketCommentAndResolve}
                ticket={this.state.ticket}
                cancelEditClicked={this.cancelEditClicked}
                removeUrls={this.removeUrls}
                isCommentLoading={this.state.isCommentLoading}
                isResolveLoading={this.state.isResolveLoading}
                isCommentAndResolveLoading={this.state.isCommentAndResolveLoading}
                isCommentAndCancelLoading={this.state.isCommentAndCancelLoading}
                isLoading={this.props.isLoading}
                user={this.props.user}
                portfolioImages={this.state.base64}
                reference={this.inputOpenFileRef}
                choosePhotoClicked={this.choosePhotoClicked}
                onPortfolioFileChange={this.onPortfolioFileChange}
                removeImage={this.removeImage}
                previousClicked={this.previousClicked}
                nextClicked={this.nextClicked}
                ticketIndex={this.props.ticketIndex}
                updateBase64={this.updateBase64}
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.authReducer.user,
        isLoading: state.adminOrSuperAdminReducer.isLoading,
        usersList: state.adminOrSuperAdminReducer.usersList,
        ticket: state.TimeTrackerModReducer.ticket,
        ratingList: state.adminOrSuperAdminReducer.ratingList,
        accountsList: state.adminOrSuperAdminReducer.accountsList,
        inspectionForm: state.adminOrSuperAdminReducer.inspectionForm,
        ticketIndex: state.adminOrSuperAdminReducer.previewScheduleTicketIndex,
        totalTickets: state.adminOrSuperAdminReducer.totalTickets
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateInspectionForm: (inspectionForm) => dispatch(actions.updateInspectionForm(inspectionForm)),
        resetObjects: () => dispatch(actions.resetObjects()),
        previewTicketClicked: (ticket) => dispatch(actions.previewTicketClicked(ticket)),
        getSubScheduledTaskPreview: (id, ticketId) => dispatch(actions.getSubScheduledTaskPreview(id, ticketId)),
        getcommentsAttachmentPresignUrl: (ticket, extentions, arrayBuffer, updateCommentId, setEditCommentObject) => dispatch(actions.getcommentsAttachmentPresignUrl(ticket, extentions, arrayBuffer, updateCommentId, setEditCommentObject)),
        addComment: (ticket) => dispatch(actions.addComment(ticket)),
        updateComment: (ticket, id, setEditCommentObject) => dispatch(actions.updateComment(ticket, id, setEditCommentObject)),
        resolveScheduledTicket: (ticket, scheduleId, ticketId) => dispatch(actions.resolveScheduledTicket(ticket, scheduleId, ticketId)),
        getResolveScheduledAttachmentPresignUrl: (ticket, extentions, arrayBuffer, scheduleId, ticketId) => dispatch(actions.getResolveScheduledAttachmentPresignUrl(ticket, extentions, arrayBuffer, scheduleId, ticketId)),

    }
}


export default connect(mapStateToProps, mapDispatchToProps)(PreviewSubScheduledTaskContainer);