import React, { useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import { emptyData, BarGraphCountOptions, BarGraphSaleOptions } from '../../../../utility/constants/constants';

import { trimString, getSecMintHoursDays, makeFirstLetterCapital } from '../../../../utility/utility';
import EmptyGraph from '../../Dashboards/emptyGraph';
import { Status, FilteredQuotes } from '../../../../utility/constants/constants';
//import DashboardSkeleton from './dashboardSkeleton';
import posed from 'react-pose';

const graphStype = {
  marginTop: '0px',
  paddingTop: '0px',
  height: '350px'
}

const Box = posed.div({
  hoverable: true,
  pressable: true,
  init: {
    scale: 1,
    filter: ' blur(0.1px)'
    // boxShadow: '0px 0px 0px rgba(0,0,0,0)'
  },
  hover: {
    scale: 1.1,
    boxShadow: '0px 5px 10px rgba(0,0,0,0.2)',
    filter: ' blur(0px)'
  },
  press: {
    scale: 1,
    // boxShadow: '0px 2px 5px rgba(0,0,0,0.1)'
  }
});

const BarGraphStyle = {
  // label: '',
  backgroundColor: '#a1395e',
  hoverBackgroundColor: '#a1395eb3',
  borderWidth: 2,
  barThickness: 5,
  // barPercentage: 0.5
}

const dashboardData = (props) => {
  const chartData = {
    scan_based_on_area: {},
    no_scan_based_on_area: {},
    not_available_based_on_area: {},
    scan_based_on_user: {},
    // scan_based_on_area_types: {},
    // scan_based_on_date: {},
  }

  Object.keys(chartData).map((key) => {
    let name = key.split("_").map(e => e.charAt(0).toUpperCase() + e.slice(1)).join(" ");
    let updated_name = ''
    if (name.toLowerCase() === 'scan based on area') {
      updated_name = 'Cleanings By Area'
    } else if (name.toLowerCase() === 'no scan based on area') {
      updated_name = 'Missed Cleanings By Area'
    } else if (name.toLowerCase() === 'not available based on area') {
      updated_name = 'Cleanings Not Available By Area'
    } else if (name.toLowerCase() === 'scan based on user') {
      updated_name = 'Cleanings By User'
    } 
    chartData[key][`${key}_options`] = {
      title: {
        display: true,
        fontSize: 20,
        padding: 30,
        fontColor: '#962d39',
        text: updated_name
      },
      ...BarGraphCountOptions
    }
    let labels = []
    let chart_data = []
    chartData[key][key] = null;
    let currentKeyId = key.split("_").reverse()[0];
    let isNoScan = key.split("_")[0] === "no";
    let noScanKey = [...key.split("_")];
    noScanKey.splice(0, 1);
    noScanKey = noScanKey.join("_");
    let arrayData = isNoScan ? noScanKey : key;
    // if (key === "scan_based_on_area_types") currentKeyId = "area_type";
    if (props.scanAnalyticsData[arrayData]) {
      for (let data of props.scanAnalyticsData[arrayData]) {
        if (currentKeyId === "date") {
          labels.push(data[0]);
          chart_data.push(String(data[1]));
        }
        else if (data.tasks_count && data.tasks_count > 0) {
          labels.push(data[`${currentKeyId}_name`]);
          chart_data.push(String(data.tasks_count));
        } else if (data.task_count && !isNoScan && data.task_count > 0) {
          labels.push(data[`name`]);
          chart_data.push(String(data.task_count));
        } else if (isNoScan && data.no_scan_count > 0) {
          labels.push(data[`name`]);
          chart_data.push(String(data.no_scan_count));
        }
        //if (currentKeyId === "date" && data[1])  
      }
    }
    chartData[key][key] = {
      labels: labels,
      datasets: [{ ...BarGraphStyle, label: '', data: chart_data }],
    }
  })


  chartData["not_available_based_on_area"][`not_available_based_on_area_options`] = {
    title: {
      display: true,
      fontSize: 20,
      padding: 30,
      fontColor: '#962d39',
      text: "Cleanings Not Available By Area"
    },
    ...BarGraphCountOptions
  }
  let labels = []
  let chart_data = []
  chartData["not_available_based_on_area"]["not_available_based_on_area"] = null;
  if (props.scanAnalyticsData["scan_based_on_area"]) {
    for (let data of props.scanAnalyticsData["scan_based_on_area"]) {
      if (data.not_available_count) {
        labels.push(data[`name`]);
        chart_data.push(String(data.not_available_count));
      }
    }
    //if (currentKeyId === "date" && data[1])  
  }
  chartData["not_available_based_on_area"]["not_available_based_on_area"] = {
    labels: labels,
    datasets: [{ ...BarGraphStyle, label: '', data: chart_data }],
  }
  console.log(chartData)

  const checkChartData = (key) => {
    let invalid = true;
    if (chartData[key][key].datasets[0].data.length !== 0) {
      chartData[key][key].datasets[0].data.map(d => {
        if (d && d !== "0") invalid = false
      })
    }
    return invalid;
  };

  const getFormattedName = (key) => {
    let name = key.split("_").map(e => e.charAt(0).toUpperCase() + e.slice(1)).join(" ").toLowerCase();
    let updated_name = ''
    if (name.toLowerCase() === 'scan based on area') {
      updated_name = 'Cleanings By Area'
    } else if (name.toLowerCase() === 'no scan based on area') {
      updated_name = 'Missed Cleanings By Area'
    } else if (name.toLowerCase() === 'not available based on area') {
      updated_name = 'Cleanings Not Available By Area'
    } else if (name.toLowerCase() === 'scan based on user') {
      updated_name = 'Cleanings By User'
    } 
    return updated_name;
  }

  if (props.formStates && props.formStates.area_ids &&
    props.formStates.area_ids.label !== 'All Areas') {
    delete chartData.scan_based_on_area_types;
  }
  return (
    <>
      <div className="row mt-3">
        <div className="col-xl-12">
          <div className="chart-content">
            <div className="row">
              {
                Object.keys(chartData).map((key, index) =>
                  <div key={index} className={`col-xl-6 chart_cont_box`}>
                    {checkChartData(key) ?
                      <EmptyGraph title={`${getFormattedName(key)}`} />
                      :
                      <div className="tile chart-box-botL" style={graphStype}>
                        {/* <h6 className="chart_title2">{key}</h6> */}
                        <div className="chart-img" style={graphStype}><Bar height={300} width={460} data={chartData[key][key]} options={chartData[key][`${key}_options`]} /></div>
                        {/*  */}
                      </div>
                    }
                  </div>
                )
              }
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const ScanAnalyticsDashbord = (props) => {

  window.onresize = function () {
    const emptyChartObj = document.getElementsByClassName("empty-graph_heading");
    const cahrtjsHeight = document.getElementById('chartId') ? document.getElementById('chartId').clientHeight : '';
    if (emptyChartObj.length !== 0) {
      for (var i = 0; i < emptyChartObj.length; i++) {
        emptyChartObj[i].style.height = `${cahrtjsHeight}px`
      }
    }
  };

  return (
    <>{dashboardData(props)}
    </>
  )
};

export default ScanAnalyticsDashbord;

