import React,{useState, useEffect} from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import PickupDetailsFormSkeleton from './PickupDetailsFormSkeleton';
import { Table } from 'antd';
import isEmpty from '../../../../../../utility/IsEmptyValidator';
import cloneDeep from 'clone-deep';
import { makeFirstLetterCapital } from '../../../../../../utility/utility';
import { Descriptions } from 'antd';

let PickupDetailsForm = (props) => {

  const data = props.singlePickupTicket && props.singlePickupTicket.ticket && props.singlePickupTicket.ticket.items.map((ticket) => {
    return {
        key: ticket.id,
        item: makeFirstLetterCapital(ticket.name),
        quantity: ticket.quantity,
    }
  });

  const columns = [
    {
      title: 'Items',
      dataIndex: 'item',
      key: 'item',
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
    },
  ];

  const { handleSubmit, pristine, reset, submitting, error } = props;
  
  if (props.isPickupDetailsFormOpen) {
    return (
        <PickupDetailsFormSkeleton />
    )
  } else {
      return (
        <main className="app-content add_account_modal add_frontline_pop">
          <div className="appcontent_Inner user_newQuote pt-0">
            <div className="inspection_content_sec pt-0">
              <div className="add_info_block quote_form">
                <h1 className="section_title text-center" style={{color:'#2a2a2a', fontSize:'33px', fontWeight:'600'}}> Pickup Details </h1>
                <div className="addQuote_form">
                  <form onSubmit={handleSubmit}>
                    <div className="cst_tab_content">

                      <div className="form-group">
                        <label className="form_title font-weight-bold"> Job Name </label>
                        <input dataTest="ticket_subject" type="text" className="form-control input-modifier" placeholder={props.singlePickupTicket.ticket && props.singlePickupTicket.ticket.account.name} name="pickedUPItems[job_name]" disabled />
                      </div>

                      <div className='pickingTicketId'>
                        <Descriptions size='small' bordered>
                          <Descriptions.Item label="Picking Ticket Id"> {props.pickingTicketId} </Descriptions.Item>
                        </Descriptions>&nbsp;
                      </div>

                      <div className="form-group">
                        <Table columns={columns} dataSource={data} pagination={false} />
                      </div>

                    </div>
                    <div className="modal-footer d-block">
                      <button data-test="submit_button" type="submit" className={props.isLoading ? "btn cst_btn btn_danger btn-wait w-75" : "btn_box cst_btn btn_danger w-75"} >{props.isLoading ? "" : "Start Pickup"}</button>
                      <button data-test="cancel_button" type="button" className="btn btn-outline-secondary cst_btn w-75 mt-2" onClick={() => props.history.push("/pickups-tickets")}> Back </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </main>
      );
  };
};

const mapStateToProps = (state) => {

  let pickedUPItems = {};
  if(isEmpty(state.InventoryReducer.singlePickupTicket)) {
    pickedUPItems = {
      // active_status: 'active',
    }
  }else {
    const values = cloneDeep(state.InventoryReducer && state.InventoryReducer.singlePickupTicket);
    pickedUPItems = {
      ticket: {
        box_id: values && values.ticket && values.ticket.delivery && values.ticket.delivery.inventory_box_id ? Number(values.ticket.delivery.inventory_box_id) : null,
        status: "picked"
      },
      ticket_id: values && values.ticket && values.ticket.id ? values.ticket.id : null,
    }
  }

  return { initialValues: { pickedUPItems } };

};


PickupDetailsForm = reduxForm({
  form: 'pickupDetailsForm',
//   validate,
  enableReinitialize: true,
})(PickupDetailsForm);

PickupDetailsForm = connect(mapStateToProps)(PickupDetailsForm);

export default PickupDetailsForm;