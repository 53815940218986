import React, { Component } from 'react';
import * as actions from '../../../../redux/actions/index';
import { connect } from 'react-redux';
import { reset } from 'redux-form';
import SkillSetLayout from '../../../component/TimeTrackerModuleComponent/SkillSetComponent/SkillSetLayout';
import { animateScroll as scroll} from 'react-scroll';
import HoursTypeLayout from '../../../component/TimeTrackerModuleComponent/HoursTypeComponent/HoursTypeLayout';

const cloneDeep = require('clone-deep');
class HoursTypeContainer extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.getHoursTypeList({ activeStatus: 'active' });
        scroll.scrollToTop();
    }

    handleFrequentTimeTrackerHoursTypes_openDeleteModal = (id) => {
        this.props.handleFrequentTimeTrackerHoursTypes_openDeleteModal(id);
    }

    handleFrequentTimeTrackerHoursTypes_closeDeleteModal = () => {
        this.props.handleFrequentTimeTrackerHoursTypes_closeDeleteModal();
    }

    handleFrequentTimeHoursTypes_closeModal = () => {
        this.props.handleFrequentTimeHoursTypes_closeModal();
        this.props.reset('hoursTypeForm');
    }

    addNewHoursType = (values) => {
        let setData = { };
        let fetchedValue = cloneDeep(values);

        // if (fetchedValue.skillset.name[fetchedValue.skillset.name.length - 1] === " ") {
        //     fetchedValue.skillset.name = fetchedValue.skillset.name.slice(0,-1);
        // }

        if (this.props.isHoursType) {

            setData.type_id = fetchedValue.hoursType.id;
            setData.description = fetchedValue.hoursType.description;
            setData.active_status = fetchedValue.hoursType.active_status == 'false' ? 'active' : 'inactive';

            this.props.editHourType(setData, this.props.isHoursType_Edit.id);

        } else {

            setData.type_id = fetchedValue.hoursType.id;
            setData.description = fetchedValue.hoursType.description;
            setData.active_status = fetchedValue.hoursType.active_status == 'false' ? 'active' : 'inactive';

            this.props.createHoursType(setData);

        }
    }

    render() {
        return (
            <div>

                <HoursTypeLayout
                    {...this.props}
                    handleFrequentTimeTrackerHoursTypes_openDeleteModal={this.handleFrequentTimeTrackerHoursTypes_openDeleteModal}
                    handleFrequentTimeTrackerHoursTypes_closeDeleteModal={this.handleFrequentTimeTrackerHoursTypes_closeDeleteModal}
                    handleFrequentTimeHoursTypes_closeModal={this.handleFrequentTimeHoursTypes_closeModal}
                    addNewHoursType={this.addNewHoursType}
                />


            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isLoadingHours: state.TimeTrackerModReducer.isLoadingHours,
        isSkillSet: state.TimeTrackerModReducer.isSkillSet,
        isLoadingHoursLits: state.TimeTrackerModReducer.isLoadingHoursLits,
        isHoursType: state.TimeTrackerModReducer.isHoursType,
        isTimeTrackerHoursTypeModalOpen: state.TimeTrackerModReducer.isTimeTrackerHoursTypeModalOpen,
        isHoursType_Edit: state.TimeTrackerModReducer.isHoursType_Edit,
        skillSet: state.TimeTrackerModReducer.skillSet,
        isHoursType_List: state.TimeTrackerModReducer.isHoursType_List,
        openHoursTimeTracker_deleteModal: state.TimeTrackerModReducer.openHoursTimeTracker_deleteModal,
        deleteFrequentHoursType: state.TimeTrackerModReducer.deleteFrequentHoursType,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        handleFrequentTimeTrackerHoursTypes_openDeleteModal: (data) => dispatch(actions.handleFrequentTimeTrackerHoursTypes_openDeleteModal(data)),
        handleFrequentTimeTrackerHoursTypes_closeDeleteModal: (data) => dispatch(actions.handleFrequentTimeTrackerHoursTypes_closeDeleteModal(data)),

        handleFrequentTimeHoursTypes_openModal: (data) => dispatch(actions.handleFrequentTimeHoursTypes_openModal(data)),
        handleFrequentTimeHoursTypes_closeModal: (data) => dispatch(actions.handleFrequentTimeHoursTypes_closeModal(data)),

        getHoursTypeList: (data) => dispatch(actions.getHoursTypeList(data)),
        createHoursType: (data) => dispatch(actions.createHoursType(data)),
        editHoursTypeClicked: (data) => dispatch(actions.editHoursTypeClicked(data)),
        editHourType: (data, id) => dispatch(actions.editHourType(data, id)),
        deleteHoursType: (data) => dispatch(actions.deleteHoursType(data)),

        reset: (skillSetForm) => dispatch(reset(skillSetForm)),  // requires form name
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(HoursTypeContainer);