import axios, { API_VERSION, accessToken, formAuditBaseUrl } from '../../config';
import storage from '../../utility/storage';
import { getUser } from '../../utility/utility';
const pureAxios = require('axios');

const user = storage.get('user');

const headers = {
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
    },
};


export const getSingleJobCardAPI = (id) => {
    return formAuditBaseUrl.get(API_VERSION + `buildingprofile/jobcardmaps/${id}` , headers);
};

export const addJobCardAPI = (body) => {
    return formAuditBaseUrl.post(API_VERSION + `buildingprofile/jobcardmaps` ,body, headers);
};

export const editJobCardAPI = (body) => {
    return formAuditBaseUrl.put(API_VERSION + `buildingprofile/jobcardmaps` ,body, headers);
};

export const getAllJobCardAPI = (params) => {
    return formAuditBaseUrl.get(API_VERSION + `buildingprofile/jobcardmaps`,{params} , headers);
};

export const addBuildingProfile = (body) => {
    return formAuditBaseUrl.post(API_VERSION + `buildingprofile/building_profile` ,body, headers);
};

export const getSingleBuildingProfile = (id) => {
    return formAuditBaseUrl.get(API_VERSION + `buildingprofile/building_profile/${id}`, headers);
};

export const getAllBuildingProfile = (params) => {
    return formAuditBaseUrl.get(API_VERSION + `buildingprofile/building_profile`,{params} , headers);
};

export const editBuildingProfile = (body) => {
    return formAuditBaseUrl.put(API_VERSION + `buildingprofile/building_profile` ,body, headers);
};
