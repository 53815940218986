import { InventoryModuleActionTypes } from '../InventoryAction/InventoryType';
import * as API from '../../../../API/FormAuditAPI/InvenroryModuleApi';
import { routes } from '../../../../utility/constants/constants';
import SuccessAlert from '../../../../utility/successAlert/successAlert';
import history from '../../../../router/history';
import * as APIT from '../../../../API/FormAuditAPI/teamManagementAPI';
import { formAuditActionTypes } from '../TeamManagementAction/teamManagementTypes';





export const getSingleDailyKittingDetails = (id) => {
    return (dispatch, getState) => {
        
        dispatch({
            type: InventoryModuleActionTypes.GET_SINGLE_DAILY_KITTING,
            payload: API.getSingleDailyKittingAPI(id) 
                .then((response) => {
                    
                    if (response.data.success) {
                        // we need to return data here 
                        
                        return response.data.data
                        
                    };
                })
                .catch((error) => {
                    
                    console.log(error);
                }),
        });
    };
};


export const addDailyKittingDetails = (data) => {
    
    return (dispatch, getState) => {
        
        dispatch({
            type: InventoryModuleActionTypes.ADD_SINGLE_DAILY_KITTING,
            payload: API.addDailyKittingAPI(data) 
                .then((response) => {
                    if (response.data.success) {
                        // we need to return data here 
                    SuccessAlert('Daily kitting Created Succesfully')
                      history.push(routes.DAILY_KITTING);   
                      return response.data; 
                        
                    };
                })
                .catch((error) => {
                    
                    console.log(error);
                }),
        });
    };
};



export const getInventoryList = (data) => {
    
    return (dispatch, getState) => {
        
        dispatch({
            type: InventoryModuleActionTypes.GET_ALL_INVENTORY_LIST,
            payload: API.getAllInventoryListAPI(data) 
                .then((response) => {
                    
                    if (response.data.success) {
                        // we need to return data here 

                        return response.data.products; 
                        
                    };
                })
                .catch((error) => {
                    
                    console.log(error);
                }),
        });
    };
};

export const getAllMonthlyReport = (params) => {
    return (dispatch, getState) => {
        dispatch({
            type: InventoryModuleActionTypes.GET_ALL_MONTHLY_REPORT_PRODUCTS,
            payload:API.getMonthlyReportsProductsAPI(params) 
                .then((response) => {
                    return response.data.data
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
  };


  export const getAllDailyKitting = (params) => {
    return (dispatch, getState) => {
        dispatch({
            type: InventoryModuleActionTypes.GET_ALL_DAILY_KITTING,
            payload: API.getAllDailyKItting(params)
                .then((response) => {
                    if (response.data.success) {
                        return {
                            dailyKitting_list: response.data.data,
                            dailyKitting_total_pages: response.data.totalPages,
                            dailyKitting_no_of_rows: response.data.limit,
                            total_dailyKitting_count: response.data.total,
                            dailyKittingCurPage: params.page - 1,
                        };
                    };
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
  };

  export const dailyKittingPageClicked = (data) => {
    return {
        type: InventoryModuleActionTypes.DAILY_KITTING_PAGE_CLICKED,
        payload: data,
    };
  };


  export const editDailyKittingDetails = (data) => {
    
    return (dispatch, getState) => {
        
        dispatch({
            type: InventoryModuleActionTypes.EDIT_SINGLE_DAILY_KITTING,
            payload: API.editDailyKittingAPI(data) 
                .then((response) => {
                    
                    if (response.data.success) {
                        // we need to return data here 
                    SuccessAlert('Daily kitting updated  Succesfully')
                      history.push(routes.DAILY_KITTING);   
                        return response.data; 
                        
                    };
                })
                .catch((error) => {
                    
                    console.log(error);
                }),
        });
    };
};



export const getCostSummaryAnalysis = (data) => {
    
    return (dispatch, getState) => {
        
        dispatch({
            type: InventoryModuleActionTypes.GET_COST_SUMMARY_ANALYSIS,
            payload: API.getCostSummary() 
                .then((response) => {
                    
                    if (response.data.success) {
                        // we need to return data here 

                        return response.data.products; 
                        
                    };
                })
                .catch((error) => {
                    
                    console.log(error);
                }),
        });
    };
};


export const addMonthlyUsageReports=(body,setElements)=>{
    
    return (dispatch, getState) => {
        dispatch({
            type: InventoryModuleActionTypes.ADD_MONTHLY_USAGE_REPORTS,
            payload: API.addMonthlyUsageReportsBulkAPI(body) 
                .then((response) => {
                    
                    if (response.data.success) {
                        // we need to return data here 
                        SuccessAlert("Monthly Usage Reports Added Succesfully")
                        // setElements({
                        //     "fte": "",
                        //     "working_days": "",
                        //     "square_footage": "",
                        //     "average_cost_per_day": "",
                        //     "monthly_cost_per_fte": "",
                        //     "cost_per_sq_ft": "",
                        // })
                        return response.data; 
                        
                    };
                })
                .catch((error) => {
                    
                    console.log(error);
                }),
        });
    };
}
  

export const getAllMonthlyCostReport = (params) => {
    return (dispatch, getState) => {
        dispatch({
            type: InventoryModuleActionTypes.GET_ALL_MONTHLY_REPORT_COST,
            payload:API.getMonthlyReportsCostAPI(params) 
                .then((response) => {
                    if(response.data.success){
                        return response.data.data;
                    }
                    
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
  };


  export const addBulkMonthlyCostReport =(body,callback,fullData)=>{
    return (dispatch, getState) => {
        dispatch({
            type: InventoryModuleActionTypes.ADD_BULK_MONTHLY_REPORT_COST,
            payload:API.addMonthlyUsageReportsBulkAPI(body) 
                .then((response) => {
                    if(response.data.success){
                        
                        callback(fullData)
                        SuccessAlert("Monthly Cost Report Updated SuccesFully")
                        return response.data.data;
                    }
                    
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
  }


  export const getMonthlySummaryFormData =(params,month)=>{
    return (dispatch, getState) => {
        dispatch({
            type: InventoryModuleActionTypes.GET_SINGLE_MONTHLY_SUMMARY_FORM_DATA,
            payload:API.getMonthlyReportsCostAPI(params)  
                .then((response) => {
                    if(response.data.success){
                        
                        const available=response.data.data.find((item)=> item.month==month)
                        
                        //SuccessAlert("Monthly Cost Report Updated SuccesFully")
                        return available ? available : null;
                    }
                    
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
  }

  export const handleResetCostSummary=()=>{
    return (dispatch, getState) => {
        dispatch({
            type: InventoryModuleActionTypes.RESET_SINGLE_MONTHLY_SUMMARY_FORM_DATA,
            
        });

        dispatch({
            type: formAuditActionTypes.GET_ALL_JOB,
            payload: APIT.getAllJobApiNew() //146
                .then((response) => {
                     
                    if (response.data.success) {
                        // we need to return data here 
                      return {
                            data:response.data.accounts
                          }
                      
                       
                    }
                }
                )
        })

        dispatch({
            type: formAuditActionTypes.GET_CUSTOMER_INFO,
            payload: APIT.getCustomerInfoAPI()
                .then((response) => {
                    
                    if (response.data.success) {
                        // we need to return data here 
                       return response.data
                    }
                }
                )
        })

    };
  }

  export const handleResetSummaryForm=()=>{
    
    return (dispatch, getState) => {
        dispatch({
            type: InventoryModuleActionTypes.RESET_MONTHLY_SUMMARY_FORM_DATA,
            
        })


        dispatch({
            type: formAuditActionTypes.GET_ALL_JOB,
            payload: APIT.getAllJobApiNew() //146
                .then((response) => {
                     
                    if (response.data.success) {
                        // we need to return data here 
                      return {
                            data:response.data.accounts
                          }
                      
                       
                    }
                }
                )
        })

        dispatch({
            type: formAuditActionTypes.GET_CUSTOMER_INFO,
            payload: APIT.getCustomerInfoAPI()
                .then((response) => {
                    
                    if (response.data.success) {
                        // we need to return data here 
                       return response.data
                    }
                }
                )
        })

        
    };
  }
  
  export const handleSelfAuditSummaryForm=()=>{
    
    return (dispatch, getState) => {
        dispatch({
            type: InventoryModuleActionTypes.RESET_MONTHLY_SUMMARY_FORM_DATA,
            
        })


        dispatch({
            type: formAuditActionTypes.GET_ALL_JOB,
            payload: APIT.getAllJobApiNew() //146
                .then((response) => {
                     
                    if (response.data.success) {
                        // we need to return data here 
                      return {
                            data:response.data.accounts
                          }
                      
                       
                    }
                }
                )
        })

        dispatch({
            type: formAuditActionTypes.GET_CUSTOMER_INFO,
            payload: APIT.getCustomerInfoAPI()
                .then((response) => {
                    
                    if (response.data.success) {
                        // we need to return data here 
                       return response.data
                    }
                }
                )
        })

        
    };
  }
  


  