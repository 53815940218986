import React, { useState, useEffect } from 'react';
import { Badge, Card, Space } from 'antd';
import { SwapOutlined } from '@ant-design/icons';
import { convertDateFormatV1, convertDateFormatV5, convertStartEndTimeFormat, getConvertTo12Hours, makeFirstLetterCapital } from '../../../../../../utility/utility';
import './PreviewShiftSwappedInfo.css';
import { tzDateConverterTool_V1, tzDateConverterTool_V2, tzTimeConversionTool_V1 } from '../../../../../../utility/TimezoneOperations/timezoneUtility';

let PreviewShiftSwappedInfo = (props) => {
    debugger

    const [fromShiftInfo_shiftObject, setFromShiftInfo_shiftObject] = useState(null);
    const [fromShiftInfo_assigneeObject, setFromShiftInfo_assigneeObject] = useState(null);
    const [toShiftInfo_shiftObject, setToShiftInfo_shiftObject] = useState(null);
    const [toShiftInfo_assigneeObject, setToShiftInfo_assigneeObject] = useState(null);

    useEffect(() => {

        setFromShiftInfo_shiftObject(props.shiftSwapFromShiftInfo && props.shiftSwapFromShiftInfo.shift);
        setFromShiftInfo_assigneeObject(props.shiftSwapFromShiftInfo && props.shiftSwapFromShiftInfo.shift && props.shiftSwapFromShiftInfo.shift.assignedTo);
        setToShiftInfo_shiftObject(props.shiftSwapToShiftInfo && props.shiftSwapToShiftInfo.shift);
        setToShiftInfo_assigneeObject(props.shiftSwapToShiftInfo && props.shiftSwapToShiftInfo.shift && props.shiftSwapToShiftInfo.shift.assignedTo);

        return () => {
            // Add functionalities
        }

    }, [props.shiftSwap_allInfo && props.shiftSwap_fromShiftInfo && props.shiftSwap_toShiftInfo]);

    return (
        <div>
            <main className="app-content add_account_modal add_frontline_pop">
                <div className="appcontent_Inner user_newQuote pt-0">
                    <div className="container py-5">
                        <div className="row text-center mb-4">
                            <div className="col-lg-7 mx-auto">
                                <h1 className="mb-0" style={{ color: '#1f1f1f', fontWeight: '600', fontSize: '40px' }}> Swapped Shift Info </h1>
                                {/* <p className="lead mb-0" style={{ color: 'black' }}>Create an elegant product list using Bootstrap 4 list group</p> */}
                            </div>
                        </div>
                        <div className="addQuote_form">
                            <form>
                                <div className="row">
                                    <div className="col-lg-12 mx-auto rounded border border-light swappedShiftInfo">

                                        <Badge.Ribbon text="APPROVED" color="green" size="default">
                                            <Card title={`${fromShiftInfo_assigneeObject && fromShiftInfo_assigneeObject.first_name && fromShiftInfo_assigneeObject.last_name && makeFirstLetterCapital(fromShiftInfo_assigneeObject.first_name) + ' ' + makeFirstLetterCapital(fromShiftInfo_assigneeObject.last_name)} Swaps a Shift with ${toShiftInfo_assigneeObject && toShiftInfo_assigneeObject.first_name && toShiftInfo_assigneeObject.last_name && makeFirstLetterCapital(toShiftInfo_assigneeObject.first_name) + ' ' + makeFirstLetterCapital(toShiftInfo_assigneeObject.last_name)}`} size="large" hoverable={true} bordered={true}>

                                                <div class="row row-cols-1 row-cols-md-3 g-4 justify-content-center">

                                                    <div class="col-lg-4">
                                                        <div class="card h-90">

                                                            <div className="testimonial-card mb-0">
                                                                <div className="card-up fromCardGradient"></div>
                                                                <div className="avatar mx-auto white">
                                                                    <img src={fromShiftInfo_assigneeObject && fromShiftInfo_assigneeObject.photo_urls && fromShiftInfo_assigneeObject.photo_urls.original ? fromShiftInfo_assigneeObject.photo_urls.original : "/images/noImage.jpg"} className="rounded-circle img-fluid" alt="" />
                                                                </div>
                                                                <div className="card-body text-center">
                                                                    <h3 className="card-title text-color-iridium swapUserName mt-0 mb-0"> {fromShiftInfo_assigneeObject && fromShiftInfo_assigneeObject.first_name && fromShiftInfo_assigneeObject.last_name ? makeFirstLetterCapital(fromShiftInfo_assigneeObject.first_name) + ' ' + makeFirstLetterCapital(fromShiftInfo_assigneeObject.last_name) : '−'} </h3>
                                                                    <p> {fromShiftInfo_assigneeObject && fromShiftInfo_assigneeObject.email ? fromShiftInfo_assigneeObject.email : '−'} </p>
                                                                    <h4><span className="badge rounded-pill swapUserRoleBadge"> Requestor </span></h4>
                                                                </div>
                                                            </div>

                                                            <hr className="hr_divider mt-0 mb-0" />

                                                            <div class="card-body">
                                                                {/* <h5 class="card-title">Card title</h5>
                                                                <p class="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p> */}
                                                                <div className="media-body d-flex justify-content-start">
                                                                    <div className="swapDateHead">
                                                                        <h5 className="d-flex justify-content-center align-items-center font-weight-bold h-100 mt-0 mb-3">
                                                                            Swap From -
                                                                        </h5>
                                                                    </div>
                                                                    <div className="swapDateBody">
                                                                        <h5 className="d-flex justify-content-center align-items-center font-weight-bold h-100 mt-0 mb-3">
                                                                            {props.shiftSwapFromShiftInfo && props.shiftSwapFromShiftInfo && props.shiftSwapFromShiftInfo.date ? tzDateConverterTool_V1(props.shiftSwapFromShiftInfo.date) : '−'}
                                                                        </h5>
                                                                    </div>
                                                                </div>
                                                                <div className="media-body d-flex justify-content-start mt-4">
                                                                    <div class="collapse" id="collapseRequestorCard" style={{ width: '100%' }}>
                                                                        <div class="card-body p-0">
                                                                            <li className="list-group-item">
                                                                                <div className="media align-items-lg-center flex-column flex-lg-row p-3">
                                                                                    <div className='d-flex flex-row align-items-center w-100'>
                                                                                        <div className="media-body">
                                                                                            <h5 className="mt-0 font-weight-bold mb-2 text-dark"> Shift Day </h5>
                                                                                        </div>
                                                                                        <div className="media-body d-flex justify-content-center">
                                                                                            <h5 className="ml-lg-0 order-1 order-lg-2 text-muted">
                                                                                                {props.shiftSwapFromShiftInfo && props.shiftSwapFromShiftInfo.date ? tzDateConverterTool_V2(props.shiftSwapFromShiftInfo.date) : '–'}
                                                                                            </h5>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </li>
                                                                            <li className="list-group-item">
                                                                                <div className="media align-items-lg-center flex-column flex-lg-row p-3">
                                                                                    <div className='d-flex flex-row align-items-center w-100'>
                                                                                        <div className="media-body">
                                                                                            <h5 className="mt-0 font-weight-bold mb-2 text-dark"> Shift Note </h5>
                                                                                        </div>
                                                                                        <div className="media-body d-flex justify-content-center flex-column-reverse">
                                                                                            <h5 className="ml-lg-0 order-1 order-lg-2 text-muted">
                                                                                                {fromShiftInfo_shiftObject && fromShiftInfo_shiftObject.notes ? fromShiftInfo_shiftObject.notes : '–'}
                                                                                            </h5>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </li>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div class="card-footer p-0">
                                                                <div className="d-flex justify-content-center">
                                                                    <a className="btn font-weight-bold expandButton" data-toggle="collapse" href="#collapseRequestorCard" role="button" aria-expanded="false" aria-controls="collapseRequestorCard">
                                                                        Expand Additional Info
                                                                    </a>
                                                                </div>
                                                            </div>

                                                            <div class="card-footer" style={{ backgroundColor: '#bababa' }}>
                                                                <div className='d-flex flex-column align-items-center'>
                                                                    <h4 className="ml-lg-0 order-1 order-lg-2 font-weight-bold" style={{ color: '#5c5c5c' }}> Shift Timing </h4>
                                                                    <h5 className="ml-lg-0 order-1 order-lg-2 font-weight-bold" style={{ color: 'white' }}>
                                                                        {fromShiftInfo_shiftObject && fromShiftInfo_shiftObject.start_time && fromShiftInfo_shiftObject.end_time ? tzTimeConversionTool_V1(fromShiftInfo_shiftObject.start_time, props.shiftSwapFromShiftInfo.date.split('T')[0]) + ' − ' + tzTimeConversionTool_V1(fromShiftInfo_shiftObject.end_time, props.shiftSwapFromShiftInfo.date.split('T')[0]) : '–'}
                                                                    </h5>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <div class="d-flex h-100 align-items-center">
                                                            <div className="container">
                                                                <div className="d-flex justify-content-center align-items-center">
                                                                    <div className="round-card d-flex align-items-center justify-content-center flex-column-reverse">
                                                                        <SwapOutlined style={{ fontSize: '80px', color: '#580025' }} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-lg-4">
                                                        <div class="card h-90">

                                                            <div className="testimonial-card mb-0">
                                                                <div className="card-up toCardGradient"></div>
                                                                <div className="avatar mx-auto white">
                                                                    <img src={toShiftInfo_assigneeObject && toShiftInfo_assigneeObject.photo_urls && toShiftInfo_assigneeObject.photo_urls.original ? toShiftInfo_assigneeObject.photo_urls.original : "/images/noImage.jpg"} className="rounded-circle img-fluid" alt="" />
                                                                </div>
                                                                <div className="card-body text-center">
                                                                    <h3 className="card-title text-color-iridium swapUserName mt-0 mb-0"> {toShiftInfo_assigneeObject && toShiftInfo_assigneeObject.first_name && toShiftInfo_assigneeObject.last_name ? makeFirstLetterCapital(toShiftInfo_assigneeObject.first_name) + ' ' + makeFirstLetterCapital(toShiftInfo_assigneeObject.last_name) : '−'} </h3>
                                                                    <p> {toShiftInfo_assigneeObject && toShiftInfo_assigneeObject.email ? toShiftInfo_assigneeObject.email : '−'} </p>
                                                                    <h4><span className="badge rounded-pill swapUserRoleBadge"> Acceptor </span></h4>
                                                                </div>
                                                            </div>

                                                            <hr className="hr_divider mt-0 mb-0" />

                                                            <div class="card-body">
                                                                {/* <h5 class="card-title">Card title</h5>
                                                                <p class="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p> */}
                                                                <div className="media-body d-flex justify-content-start">
                                                                    <div className="swapDateHead">
                                                                        <h5 className="d-flex justify-content-center align-items-center font-weight-bold h-100 mt-0 mb-3">
                                                                            Swap To -
                                                                        </h5>
                                                                    </div>
                                                                    <div className="swapDateBody">
                                                                        <h5 className="d-flex justify-content-center align-items-center font-weight-bold h-100 mt-0 mb-3">
                                                                            {props.shiftSwapToShiftInfo && props.shiftSwapToShiftInfo && props.shiftSwapToShiftInfo.date ? tzDateConverterTool_V1(props.shiftSwapToShiftInfo.date) : '−'}
                                                                        </h5>
                                                                    </div>
                                                                </div>
                                                                <div className="media-body d-flex justify-content-start mt-4">
                                                                    <div class="collapse" id="collapseAcceptorCard" style={{ width: '100%' }}>
                                                                        <div class="card-body p-0">
                                                                            <li className="list-group-item">
                                                                                <div className="media align-items-lg-center flex-column flex-lg-row p-3">
                                                                                    <div className='d-flex flex-row align-items-center w-100'>
                                                                                        <div className="media-body">
                                                                                            <h5 className="mt-0 font-weight-bold mb-2 text-dark"> Shift Day </h5>
                                                                                        </div>
                                                                                        <div className="media-body d-flex justify-content-center">
                                                                                            <h5 className="ml-lg-0 order-1 order-lg-2 text-muted">
                                                                                                {props.shiftSwapToShiftInfo && props.shiftSwapToShiftInfo.date ? tzDateConverterTool_V2(props.shiftSwapToShiftInfo.date) : '–'}
                                                                                            </h5>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </li>
                                                                            <li className="list-group-item">
                                                                                <div className="media align-items-lg-center flex-column flex-lg-row p-3">
                                                                                    <div className='d-flex flex-row align-items-center w-100'>
                                                                                        <div className="media-body">
                                                                                            <h5 className="mt-0 font-weight-bold mb-2 text-dark"> Shift Note </h5>
                                                                                        </div>
                                                                                        <div className="media-body d-flex justify-content-center">
                                                                                            <h5 className="ml-lg-0 order-1 order-lg-2 text-muted">
                                                                                                {toShiftInfo_shiftObject && toShiftInfo_shiftObject.notes ? toShiftInfo_shiftObject.notes : '–'}
                                                                                            </h5>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </li>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div class="card-footer p-0">
                                                                <div className="d-flex justify-content-center">
                                                                    <a className="btn font-weight-bold expandButton" data-toggle="collapse" href="#collapseAcceptorCard" role="button" aria-expanded="false" aria-controls="collapseAcceptorCard">
                                                                        Expand Additional Info
                                                                    </a>
                                                                </div>
                                                            </div>

                                                            <div class="card-footer" style={{ backgroundColor: '#bababa' }}>
                                                                <div className='d-flex flex-column align-items-center'>
                                                                    <h4 className="ml-lg-0 order-1 order-lg-2 font-weight-bold" style={{ color: '#5c5c5c' }}> Shift Timing </h4>
                                                                    <h5 className="ml-lg-0 order-1 order-lg-2 font-weight-bold" style={{ color: 'white' }}>
                                                                        {toShiftInfo_shiftObject && toShiftInfo_shiftObject.start_time && toShiftInfo_shiftObject.end_time ? tzTimeConversionTool_V1(toShiftInfo_shiftObject.start_time, props.shiftSwapToShiftInfo.date.split('T')[0]) + ' − ' + tzTimeConversionTool_V1(toShiftInfo_shiftObject.end_time, props.shiftSwapToShiftInfo.date.split('T')[0]) : '–'}
                                                                    </h5>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>

                                                </div>

                                            </Card>
                                        </Badge.Ribbon>

                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div >
            </main >
        </div>
    );
};

export default PreviewShiftSwappedInfo;