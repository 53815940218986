import React, { Component } from 'react';
import * as actions from '../../../../../redux/actions/index';
import {  userRoles } from '../../../../../utility/constants/constants'
import { extractExtention, getUser } from '../../../../../utility/utility';
import { connect } from 'react-redux';
import isEmpty from '../../../../../utility/IsEmptyValidator';
import { decode, encode } from 'base64-arraybuffer';
import storage from '../../../../../utility/storage';
import moment from "moment";
import * as commonService from "../../../../../utility/utility";
import { reset } from 'redux-form';
import NewBox from '../../../../component/Inventory/Box/NewBoxForm/newBoxForm';

const cloneDeep = require('clone-deep');

class NewBoxContainer extends Component {

    componentDidMount() {
        if(this.props.match.params && this.props.match.params.boxId){
            this.props.getSingleBox(this.props.match.params.boxId)
        }
    }


    onCancelClicked = () => {
        this.props.history.goBack();
    }

    addNewTicket = (values) =>{

        if(values.box && values.box.name){
            values.box.name = values.box.name.trim()
        }

        if(this.props.match.params && this.props.match.params.boxId){
            delete values.box.number
            this.props.updateBox(values, this.props.match.params.boxId)
        }else{
            this.props.addBox(values)
        }
     
    }

   

    render() {

        return (
            
                <NewBox
                    user={this.props.user}
                    onSubmit={this.addNewTicket}
                    onCancelClicked={this.onCancelClicked}
                    isLoading ={this.props.isLoading}
                    isEdit ={this.props.isEdit}
                    isUpdateBox ={this.props.isUpdateBox}
                    box ={this.props.box}
                />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.authReducer.user,
        isLoading: state.InventoryReducer.isLoading,
        isEdit: state.InventoryReducer.isEdit,
        isUpdateBox: state.InventoryReducer.isUpdateBox,
        box: state.InventoryReducer.box,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        resetObjects: () => dispatch(actions.resetObjects()),
        reset: (newTicketForm) => dispatch(reset(newTicketForm)),
        addBox: (body) => dispatch(actions.addBox(body)),
        getSingleBox: (id) => dispatch(actions.getSingleBox(id)),
        updateBox: (body, id) => dispatch(actions.updateBox(body, id)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewBoxContainer);