// import SuccessAlert from '../../../utility/successAlert/successAlert';
// import ScanningSuccessAlert from '../../../utility/ScanningAlert/scanningSuccessAlert';
// import { routes } from '../../../utility/constants/constants';
import { formAuditLogBookActionTypes } from './LogbookTypes';
import * as API from '../../../../API/FormAuditAPI/logbookAPI';
import { routes } from '../../../../utility/constants/constants';
import SuccessAlert from '../../../../utility/successAlert/successAlert';
import history from '../../../../router/history';
// import * as APIV from '../../../API/formAuditAPI';



export const getSingleRestRoomDetails = (id) => {
    return (dispatch, getState) => {
        
        dispatch({
            type: formAuditLogBookActionTypes.GET_SINGLE_RESTROOM_DETAILS,
            payload: API.getSingleRestroomDetails(id) //146
                .then((response) => {
                    
                    if (response.data.success) {
                        // we need to return data here 
                        
                        return response.data.data
                        
                    };
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};



export const addRestroomDetails = (body) => {
  return (dispatch, getState) => {
      
      dispatch({
          type: formAuditLogBookActionTypes.ADD_SINGLE_RESTROOM_DETAILS,
          payload: API.addRestroomDetailsAPI(body) 
              .then((response) => {
                
                  if (response.data.success) {
                      // we need to return data here 
                      SuccessAlert('Restroom Portering Created Succesfully')
                      history.push(routes.RESTROOM_PORTERING);
                        return response.data;
                      
                  };
              })
              .catch((error) => {
                  console.log(error);
              }),
      });
  };
};

export const editRestroomDetails = (body) => {
  return (dispatch, getState) => {
      
      dispatch({
          type: formAuditLogBookActionTypes.EDIT_SINGLE_RESTROOM_DETAILS,
          payload: API.editRestroomDetailsAPI(body) 
              .then((response) => {
                
                  if (response.data.success) {
                      // we need to return data here 
                      SuccessAlert('Restroom Portering Updated Succesfully  ')
                      
                      history.push(routes.RESTROOM_PORTERING);
                        return response.data;
                      
                  };
              })
              .catch((error) => {
                  console.log(error);
              }),
      });
  };
};

export const getAllRestroomPortering = (params) => {
  return (dispatch, getState) => {
      dispatch({
          type: formAuditLogBookActionTypes.GET_ALL_RESTROOM_PORTERING,
          payload: API.getAllRestroomPortering(params)
              .then((response) => {
                  if (response.data.success) {
                      return {
                          restroomPortering_list: response.data.data,
                          restroomPortering_total_pages: response.data.totalPages,
                          restroomPortering_no_of_rows: response.data.limit,
                          total_restroomPortering_count: response.data.total,
                          restroomPorteringCurPage: params.page - 1,
                      };
                  };
              })
              .catch((error) => {
                  console.log(error);
              }),
      });
  };
};

export const restroomPorteringPageClicked = (data) => {
  return {
      type: formAuditLogBookActionTypes.RESTROOM_PORTERING_PAGE_CLICKED,
      payload: data,
  };
};
