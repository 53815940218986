import React, { useState } from 'react';
// import SkillSetTableList from './SkillSetTableList';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import NewHoursType from './NewHoursType/NewHourType';
import HoursTypeTableList from './HoursTypeTableList';
import HighLevelEncoder from '@zxing/library/esm/core/aztec/encoder/HighLevelEncoder';
// import NewSkillSet from './NewSkillSet/NewSkillSet';

const HoursTypeLayout = (props) => {
    
    const ActiveSkillSetListCategory = () => {
        props.getHoursTypeList({ activeStatus: '' });
    }
    const InActiveSkillSetListCategory = () => {
        props.getHoursTypeList({ activeStatus: 'inactive' });
    }

 
    const { handleSubmit, pristine, reset, submitting, error } = props;
    return (
        <>

            <main className="app-content wraper_content inspection_wraper categories_blc">
                <div className="tab_header search_filter_cont inspection_tab_content appcontent_inner">
                    <form onSubmit={handleSubmit}>
                        <input type="hidden" value="something" />
                        <div className="row mt-2">
                            <div class="col">
                                <button type='button' className="btn cst_btn btn_danger mr-2" onClick={ActiveSkillSetListCategory}>Active Hours type</button>
                                <button type='button' className="btn cst_btn btn_danger mr-2" onClick={InActiveSkillSetListCategory}>In-Active Hours type</button>
                            </div>
                            <div className="col">
                                <div className="col-xl-12 col-lg-4 col-md-12 col-sm-12 text-right accounts_btn_wrap">
                                    <a href="JavaScript:void(0);" className="btn btn-secondary cst_btn" data-toggle="modal" data-target="#addHoursType" data-backdrop="static" data-keyboard="false" onClick={() => { props.handleFrequentTimeHoursTypes_openModal(); }}><i className="fa fa-plus icn_plus" aria-hidden="true"></i> New Hours type </a>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <section className="account_sec user_screen inspection_tab_content">
                    <HoursTypeTableList
                        {...props}
                    />
                </section>
            </main>

            <Dialog
                open={props.openHoursTimeTracker_deleteModal}
                // onClose={props.handleClose}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title" style={{ fontSize: '13', color: '#06080f' }}>
                    <h3 style={{ color: '#ab385e' }}>Are you sure?</h3>
                </DialogTitle>

                <DialogActions>
                    <button style={{ minHeight: '36px', minWidth: '80px' }} onClick={() => props.deleteHoursType(props.deleteFrequentHoursType)} disabled={props.isLoadingHours} className={props.isLoadingHours ? "btn cst_btn btn_danger mr-2 btn-wait" : "btn cst_btn btn_danger mr-2"} id="deleteCriteria" type="delete">{props.isLoadingHours ? "" : "Delete"}</button>
                    <button style={{ minHeight: '36px', minWidth: '80px' }} onClick={props.handleFrequentTimeTrackerHoursTypes_closeDeleteModal} className={"btn cst_btn btn-outline-secondary"} id="cancel" type="cancel">Cancel</button>
                </DialogActions>
            </Dialog>

            {props.isTimeTrackerHoursTypeModalOpen ? (
                <NewHoursType
                    {...props}
                    onSubmit={props.addNewHoursType}
                    handleFrequentTimeHoursTypes_closeModal={props.handleFrequentTimeHoursTypes_closeModal}
                />
            ) : null}

        </>
    );
};

export default HoursTypeLayout;