import React, { Component } from "react";
import { MagicSpinner, PushSpinner, SwishSpinner, WaveSpinner, ClapSpinner } from 'react-spinners-kit';
import { reset, getFormValues } from 'redux-form';

import * as actions from '../../../redux/actions/index';
import { connect } from 'react-redux';
import { GetFormatedDate, getWeekStartAndEndDay, getNextMonthDate, getPreviousMonthDate, getNextYearDate, getPreviousYearDate, get_next_week_start, get_previous_week_start } from '../../../utility/utility';
import { currentData, userRoles, routes } from '../../../utility/constants/constants';
import FilterQuotas from "./FilterQuotas";
import QuotasChart from "./QuotasChart";
import QuotasYearFilter from "./QuotasYearFilter";
import moment from "moment";
class QuotasGraph extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isWeek: true,
      isMonth: false,
      isYear: false,
      currentDate: this.props.currentDate,
      isPDFView: false
    }
    this.handleFilterSearch = this.handleFilterSearch.bind(this);
  }

  previousYearCliked = () => {
      const date = getPreviousYearDate(this.props.currentDate);
      const date2 = moment(date).format('MM-DD-YYYY');
      this.props.getQuotaForQuotes({ id:this.props.analyticsFormValues.account_ids.value, option: 'year', date: date2 });
      this.props.updateCurrentDate(getPreviousYearDate(this.props.currentDate));
  }
  nextYearCliked = () => {
      const date = getNextYearDate(this.props.currentDate);
      const date2 = moment(date).format('MM-DD-YYYY');
      this.props.getQuotaForQuotes({ id:this.props.analyticsFormValues.account_ids.value, option: 'year', date: date2 });
      this.props.updateCurrentDate(getNextYearDate(this.props.currentDate));
  }
  viewModeClicked = (viewMode) => {
    if (viewMode === currentData.WEEK) {
      this.props.updateWeekMonthYear(true, false, false);
    //   this.props.getScanAnalyticsData({ option: viewMode, date: GetFormatedDate(this.props.currentDate) });


    } else if (viewMode === currentData.MONTH) {
      this.props.updateWeekMonthYear(false, true, false)
    //   this.props.getScanAnalyticsData({ option: viewMode, date: GetFormatedDate(this.props.currentDate) });

    } else if (viewMode === currentData.YEAR) {
      this.props.updateWeekMonthYear(false, false, true)
    //   this.props.getScanAnalyticsData({ option: viewMode, date: GetFormatedDate(this.props.currentDate) });
    }
    this.props.resetFilterForm();
  }

  componentDidMount() {
    // if (!this.props.dashboardData) {
    let DataType = null;
    if (this.props.isWeek) {
      DataType = currentData.WEEK
    } else if (this.props.isMonth) {
      DataType = currentData.MONTH
    } else if (this.props.isYear) {
      DataType = currentData.YEAR
    }
    // this.props.getScanAnalyticsData({ option: DataType, date: GetFormatedDate(this.props.currentDate) });
    // this.props.getAllAreas();
    // this.props.nonLoggedInFrontlines();
    // this.props.getQuotaForQuotes({ id:this.props.analyticsFormValues.account_ids.value, option: currentData.YEAR, date: date });
  }

  handleFilterSearch(e) {
    e.preventDefault();
    let date, end_date, option, account_id, area_id;
    if (this.props.analyticsFormValues.account_ids && typeof this.props.analyticsFormValues.account_ids.value === "number") account_id = this.props.analyticsFormValues.account_ids.value;
    if (this.props.analyticsFormValues.area_ids && typeof this.props.analyticsFormValues.area_ids.value === "number") area_id = this.props.analyticsFormValues.area_ids.value;
    if (this.props.analyticsFormValues.fromDate) {
      date = GetFormatedDate(this.props.analyticsFormValues.fromDate);
      end_date = GetFormatedDate(this.props.analyticsFormValues.toDate);
    }
    if (!date) {
      if (this.props.isWeek) {
        option = currentData.WEEK
      } else if (this.props.isMonth) {
        option = currentData.MONTH
      } else if (this.props.isYear) {
        option = currentData.YEAR
      }
      date = GetFormatedDate(this.props.currentDate);
    }
    this.props.getQuotaForQuotes({ id:this.props.analyticsFormValues.account_ids.value, option: option, date: date });
  }
  render() {
    return (<div className="admin_dashboard appcontent_Inner">
      <QuotasYearFilter {...this.props}
        previousYearCliked={this.previousYearCliked}
        nextYearCliked={this.nextYearCliked}
        viewModeClicked={this.viewModeClicked}
      />
      {
        // this.props.scanAnalyticsData ? <CountBlock {...this.props} /> : ""
      }
      <div className="gray_bg_wrap">
        <FilterQuotas handleFilterSearch={this.handleFilterSearch} {...this.props} />
      </div>
      {this.props.isLoading ?
        <div  >
          <ClapSpinner backColor="#89073c" size={40} frontColor="#89073c8a" />
        </div>
        : (this.props.quotasForQuotes ?
          <>
            <QuotasChart {...this.props} />
          </> : "")}
    </div>
    )
  }
}


const mapStateToProps = (state) => {
  return {
    // nonLoggedInFrontlineData: state.adminOrSuperAdminReducer.noneLoggedInFrontlinData,
    // scanAnalyticsData: state.adminOrSuperAdminReducer.scanAnalyticsData,
    // allAreaData: state.adminOrSuperAdminReducer.allAreaData,
    isLoading: state.adminOrSuperAdminReducer.isLoading,
    currentDate: state.adminOrSuperAdminReducer.currentDate,
    isWeek: state.adminOrSuperAdminReducer.isWeek,
    isMonth: state.adminOrSuperAdminReducer.isMonth,
    isYear: state.adminOrSuperAdminReducer.isYear,
    user: state.authReducer.user,
    formStates: getFormValues('analyticsForm')(state),
    quotasForQuotes: state.adminOrSuperAdminReducer.quotasForQuotes
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    // nonLoggedInFrontlines: () => dispatch(actions.nonLoggedInFrontlines()),
    // getAllAreas: () => dispatch(actions.getAllAreaData()),
    // getScanAnalyticsData: (data) => dispatch(actions.getScanAnalyticsData(data)),
    getQuotaForQuotes: (id, date) => dispatch(actions.getQuotaForQuotes(id, date)),
    updateCurrentDate: (date) => dispatch(actions.updateCurrentDate(date)),
    updateWeekMonthYear: (isWeek, isMonth, isYear) => dispatch(actions.updateWeekMonthYear(isWeek, isMonth, isYear)),
    previewTicketClicked: (ticket) => dispatch(actions.previewTicketClicked(ticket)),
    resetFilterForm: () => dispatch(reset("analyticsForm"))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(QuotasGraph);