import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../../../redux/actions/index';
import { reset } from 'redux-form';
import storage from '../../../../../utility/storage';
import { animateScroll as scroll } from 'react-scroll'
import AssetsList from '../../../../component/Inventory/Assets/AssetsList/assetsList';
import { routes } from '../../../../../utility/constants/constants';
import AssetStock from '../../../../component/Inventory/Assets/AssetStock/assetStock';


class AssetStockContainer extends Component {

  constructor(props) {
    super(props);

    this.state = {
      currentAssosciatedUserEmail: '',
      accountErrorMessage: ''
    }
  }

  componentDidMount() {
    this.props.getLocation();
    scroll.scrollToTop();
  }

  goClicked = (values) => {
    let sku_detail = ''
    let name = ''
    let status = ''
    let location = ''
    let id = ''
    const limit = this.props.assests_no_of_rows
    const page = 1;
    const type = 'asset'

    if (values.sku_detail) {
      values.sku_detail = values.sku_detail.trim()
      sku_detail = values.sku_detail
    }

    if (values.name) {
      values.name = values.name.trim()
      name = values.name
    }

    if (values.status) {
      values.status = values.status.trim()
      status = values.status
    }

    if (values.location) {
      values.location = values.location.trim()
      location = values.location
    }

    this.props.getAllAssestStock({ limit, page, sku_detail, name, type, status, location }, values, this.props.match.params.assetId);
  };


  componentWillUnmount() {
    //this.props.resetObjects();
  }

  componentDidUpdate(prevProps, prevState) {
    const user = storage.get('user');
    if (user) {
      if (prevProps.user.selected_mode !== user.selected_mode) {
        this.props.assetStockPageClicked(0)
      }
    }
  }

  handleInventoryCloseDeleteModal = () => {
    this.props.handleInventoryCloseDeleteModal();
  }

  handleInventoryOpenDeleteModal = (id) => {
    this.props.handleInventoryOpenDeleteModal(id);
  }

  resetClicked = () => {
    const limit = this.props.assests_no_of_rows
    const page = 1;
    this.props.reset('searchAssetsListForm');
    this.props.assetStockPageClicked(0)
    const type = 'asset'
    this.props.getAllAssestStock({ limit, page, type }, {}, this.props.match.params.assetId);
  }

  addAssetStockClicked = () => {
    this.props.history.push(routes.ADD_ASSET_STOCK);
  }

  assetActiveStatusToggle = (e) => {
    this.props.getAssetActiveStatusToggle();
  }

  render() {

    return (
      <AssetStock
        {...this.props}
        resetClicked={this.resetClicked}
        onSubmit={this.goClicked}
        addAssetStockClicked={this.addAssetStockClicked}
        handleInventoryOpenDeleteModal={this.handleInventoryOpenDeleteModal}
        handleInventoryCloseDeleteModal={this.handleInventoryCloseDeleteModal}
        assetActiveStatusToggle={this.assetActiveStatusToggle}
      />
    )
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.authReducer.user,
    isLoading: state.InventoryReducer.isLoading,
    assestsList: state.InventoryReducer.assestStockList,
    assests_no_of_rows: state.InventoryReducer.assestStock_no_of_rows,
    openInventoryDeleteModal: state.InventoryReducer.openInventoryDeleteModal,
    deleteInventoryObject: state.InventoryReducer.deleteInventoryObject,
    // activeAssets: state.InventoryReducer.activeAssets,
    locations: state.InventoryReducer.locations,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    reset: (searchAssetsListForm) => dispatch(reset(searchAssetsListForm)),
    getAllAssestStock: (params, values, id) => dispatch(actions.getAllAssestStock(params, values, id)),
    assetStockPageClicked: (page) => dispatch(actions.assetStockPageClicked(page)),
    deleteAssetStock: (id) => dispatch(actions.deleteAssetStock(id)),
    handleInventoryOpenDeleteModal: (box) => dispatch(actions.handleInventoryOpenDeleteModal(box)),
    handleInventoryCloseDeleteModal: () => dispatch(actions.handleInventoryCloseDeleteModal()),
    // getAssetActiveStatusToggle: () => dispatch(actions.getAssetActiveStatusToggle()),
    getLocation: () => dispatch(actions.getLocation()),
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(AssetStockContainer);