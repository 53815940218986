import React, { useState } from 'react';
import RatingTable from './ratingTable';
import { Input } from '../UI/InputElement/InputElement';
import { reduxForm } from 'redux-form';
import { connect, useSelector } from 'react-redux';
import { ratingSearchValidator as validate } from '../../../utility/validator/validator';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import { convertUsersDataToCsvType } from '../../../utility/utility';
import { CSVLink, CSVDownload } from "react-csv";
import InActiveRatingTable from './InActiveRatingTable';

let Ratings = (props) => {
    const hideRating = useSelector(state => state.adminOrSuperAdminReducer.hideRating);
      const activeListCategory = () => {
        props.getRatings({hide: false})
        props.changeHideStatus(true)
    }
    const inactiveListCategory = () => {
        props.getRatings({hide: true})
        props.changeHideStatus(false)

    }
    const { handleSubmit, pristine, reset, submitting, error } = props;
    return (
        <>
            <main className="app-content wraper_content inspection_wraper">
                <div className="tab_header search_filter_cont inspection_tab_content appcontent_inner">
                    <form onSubmit={handleSubmit}>
                        <input type="hidden" value="something" />
                        <div className="row">
                            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                                <div className="app-search">
                                    <Input className="fil_search_input input-modifier" name="name" type="search" placeholder="Search By Rating Name" />
                                    <button className="app-search__button">
                                        <i class="fa fa-search" aria-hidden="true"></i>
                                    </button>
                                </div>
                            </div>
                            {/* <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                                <div className="app-search">
                                    <Input className="fil_search_input input-modifier" name="email" type="search" placeholder="Search By Email" />
                                    <button className="app-search__button">
                                        <i class="fa fa-search" aria-hidden="true"></i>
                                    </button>
                                </div>
                            </div> */}
                            <div className="col-xl-4 col-lg-4 col-md-3 col-sm-3 col-6">
                                <button style={{ minWidth: '90px' }} className={"btn cst_btn btn_danger mr-2"} id="submit" type="submit">{"Go"} </button>
                                <button style={{ minWidth: '90px' }} onClick={props.resetClicked} className={"btn cst_btn btn-outline-secondary"} id="reset" type="reset">{"Reset"} </button>
                            </div>


                            <div className="col-xl-5 col-lg-4 col-md-3 col-sm-3  col-6 text-right quotes_btn_wrap">
                                <a onClick={props.newRatingClicked} href="javascript:void (0)" className="btn btn-secondary cst_btn" data-test="new_rating_btn"><i className="fa fa-plus icn_plus"></i>New Rating</a>
                            </div>
                        </div>
                        <div className="row">
                        <div class="col">
                            <button className="btn cst_btn btn_danger mr-2 mt-3" onClick={activeListCategory}>Active Ratings</button>
                            <button className="btn cst_btn btn_danger mr-2 mt-3" onClick={inactiveListCategory}>In-active Ratings</button>
                        </div>
                        </div>
                    </form>
                </div>

                {
                    hideRating &&                
                <section className="account_sec user_screen inspection_tab_content">
                    {/* <CSVLink filename="Users.csv" className="csv_btn btn_danger " data={convertUsersDataToCsvType(props.usersList)} >Export Csv</CSVLink><br /><br /><br /> */}
                    <div className="quotes_table_content table-responsive">
                        <div className="table quotes_table user_table_contnet user_react_table">
                            <RatingTable
                                {...props}
                                deleteUser={props.deleteUser}
                                users_rows={props.users_rows}
                                isLoading={props.isLoading}
                                onUserPageSizeChange={props.onUserPageSizeChange}
                                ratingList={props.ratingList}
                                editRatingClicked={props.editRatingClicked}
                                openDeleteModel={props.openDeleteModel}
                                handleClickDeleteModel={props.handleClickDeleteModel} />
                        </div>

                    </div>
                </section>
                }
                {
                    !hideRating &&                
                <section className="account_sec user_screen inspection_tab_content">
                    {/* <CSVLink filename="Users.csv" className="csv_btn btn_danger " data={convertUsersDataToCsvType(props.usersList)} >Export Csv</CSVLink><br /><br /><br /> */}
                    <div className="quotes_table_content table-responsive">
                        <div className="table quotes_table user_table_contnet user_react_table">
                            <InActiveRatingTable
                                {...props}
                                deleteUser={props.deleteUser}
                                users_rows={props.users_rows}
                                isLoading={props.isLoading}
                                onUserPageSizeChange={props.onUserPageSizeChange}
                                ratingList={props.ratingList}
                                editRatingClicked={props.editRatingClicked}
                                openDeleteModel={props.openDeleteModel}
                                handleClickDeleteModel={props.handleClickDeleteModel} />
                        </div>

                    </div>
                </section>
                }
            </main>
            <Dialog
                open={props.openDeleteModel}
                onClose={props.handleClose}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title" style={{ fontSize: '13', color: '#06080f' }}>
                    <h3 style={{ color: '#ab385e' }}>Are you sure?</h3>
                </DialogTitle>

                <DialogActions>
                    <button style={{ minHeight: '36px', minWidth: '80px' }} onClick={() => props.deleteRating(props.deleteObject.id)} disabled={props.isLoading} className={props.isLoading ? "btn cst_btn btn_danger mr-2 btn-wait" : "btn cst_btn btn_danger mr-2"} id="delete" type="delete">{props.isLoading ? "" : "Delete"}</button>
                    <button style={{ minHeight: '36px', minWidth: '80px' }} onClick={props.handleDeleteClose} className={"btn cst_btn btn-outline-secondary"} id="cancel" type="cancel">Cancel</button>
                    {/* <Button onClick={props.handleClose} variant="contained" color="primary">
                        Cancel
                        </Button>


                    <Button onClick={() => props.deleteAccount(props.deleteObject.id)} disabled={props.isLoading} variant="contained" color="secondary">
                        {props.isLoading ? <CircularProgress size={24} /> : 'Delete'}
                    </Button> */}
                </DialogActions>
            </Dialog>
        </>

    )
}

const mapStateToProps = (state) => {
    let email = "";
    let name = state.adminOrSuperAdminReducer.searchRatingValues ? state.adminOrSuperAdminReducer.searchRatingValues : "";

    return { initialValues: { email, name } }
}

Ratings = reduxForm({
    form: 'searchRatingForm',
    validate,
    enableReinitialize: true
})(Ratings);

Ratings = connect(mapStateToProps)(Ratings)

export default Ratings;