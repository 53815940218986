import React, { forwardRef } from "react";
import { getFormValues, reduxForm } from "redux-form";
import { Input } from "../../../UI/InputElement/InputElement";
import { connect } from "react-redux";
import { DateTimeField } from "../../../UI/DateTimeField/DateTimeField";
import { overRideAvailabilityFormValidator as validate } from "../../../../../utility/validator/validator";

let OverrideAvailability = (props) => {

    const { handleSubmit, pristine, reset, submitting, error } = props;

    const formatDate = (date) => {
        const formattedDate = new Date(date)
        const month = formattedDate.toLocaleString('en-US', { month: 'long' });
        const year = formattedDate.getFullYear();
        return `${month} ${year}`;
    };

    const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (

        <div style={{ marginLeft: "-20px", cursor: 'pointer' }} className="font-weight-bold h6" onClick={onClick} ref={ref}>
            {formatDate(value)} <i style={{ fontSize: "17px" }} className="fa">&#xf107;</i>
        </div>
    ));

    return (
        <main className="app-content wraper_content user_newQuote add_account_modal add_New_Accont newuser">
            <div className="inspection_content_sec appcontent_Inner">
                <div className="container-fluids">
                    <div className="ticket_form">
                        <h2 className="md_title">
                            Request for change in Availability
                        </h2>
                    </div>
                    <form onSubmit={handleSubmit} className="form_content">
                        <div className="quote_form overrideAvailability_form">
                            <div className="addQuote_form scroll_acc_com">
                                <div className="cst_tab_content">
                                    <div className="row">
                                        <div class="col-md-12">
                                            <div className="mb-2"> 
                                            Select Date
                                            </div>
                                            <label>
                                                <Input name="data[start_date]" dateFormat="MM-dd-yyyy" placeholder="Select Date" minDate={new Date()} datePickerType={"react-datepicker"} type="date" className="form-control input-modifier start_date_custom_width" />
                                                <div class="input-group-append cst_group_append" style={{marginTop : '31px'}}>
                                                    <span  class="input-group-text" id="basic-addon2"><i class="fa fa-calendar"></i></span>
                                                </div>
                                            </label>
                                        </div>

                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label for="data[start_time]" className="form_title">
                                                    Start Time
                                                </label>
                                                <DateTimeField timeConstraints={{ minutes: { step: 5 } }} datePickerType='react-datetime' pickerType="timePicker" dataFormat="hh:mm A" className="form-control Input-modifier" id="areaName" name={`data[start_time]`} />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label for="data[end_time]" className="form_title">End Time</label>
                                                <DateTimeField timeConstraints={{ minutes: { step: 5 } }} datePickerType='react-datetime' pickerType="timePicker" dataFormat="hh:mm A" className="form-control Input-modifier" id="areaName" name={`data[end_time]`} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button
                                        data-test="submit_button"
                                        type="submit"
                                        disabled={props.isLoading}
                                        className={
                                            props.isLoading
                                                ? "btn cst_btn btn_danger mr-2 btn-wait"
                                                : "btn cst_btn btn_danger mr-2"
                                        }
                                    >
                                        {props.isLoading ? "" : "Submit"}{" "}
                                    </button>
                                    <button
                                        data-test="cancel_button"
                                        type="button"
                                        className="btn btn-outline-secondary cst_btn"
                                        onClick={() => props.onCancelClicked()}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </main>
    );
}

const mapStateToProps = (state) => {


    return {
        formStates: getFormValues('overrideAvailabilityForm')(state)
    };
};

OverrideAvailability = reduxForm({
    form: "overrideAvailabilityForm",
    validate,
    enableReinitialize: true,
})(OverrideAvailability);

OverrideAvailability = connect(mapStateToProps)(OverrideAvailability);

export default OverrideAvailability;