import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../../redux/actions/index';
import { reset } from 'redux-form';
import storage from '../../../../utility/storage';
import { animateScroll as scroll} from 'react-scroll'
import Box from '../../../component/Inventory/Box/box';
import { routes } from '../../../../utility/constants/constants';


class BoxContainer extends Component {

  constructor(props) {
    super(props);

    this.state = {
      currentAssosciatedUserEmail: '',
      accountErrorMessage: ''
    }
  }

  componentDidMount() {
    scroll.scrollToTop();
  }

  newBoxClicked = () => {
    this.props.history.push(routes.ADD_BOX);
    this.props.addNewBoxClicked()
}

  searchFrontlineUserClicked = (values) => {
    let name = ''
    let number = ''
    const page = 1
    const limit = this.props.box_no_of_rows
    if(values.id) {
      values.id = values.id.trim()
      number = values.id
  }
  if(values.name) {
    values.name = values.name.trim()
    name = values.name
}
    this.props.getBoxList({page,limit,name,number});
  };

  handleBoxDeleteClose = () => {
    this.props.handleInventoryCloseDeleteModal();
}

handleBoxClickDeleteModel = (id) => {
  this.props.handleInventoryOpenDeleteModal(id);
}

  resetClicked = () => {
    const limit = this.props.box_no_of_rows
    const page = 1;
    this.props.reset('searchBoxListForm');
    this.props.getBoxList({ page, limit });
    this.props.boxPageClicked(0)
  }

  render() {

    return (
      <Box
        {...this.props}
        resetClicked={this.resetClicked}
        onSubmit={this.searchFrontlineUserClicked}
        handleBoxDeleteClose={this.handleBoxDeleteClose}
        handleBoxClickDeleteModel={this.handleBoxClickDeleteModel}
        openBoxDeleteModel={this.props.openBoxDeleteModel}
        newBoxClicked={this.newBoxClicked}
        isLoading={this.props.isLoading}
      />
    )
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.authReducer.user,
    boxList: state.InventoryReducer.boxList,
    openBoxDeleteModel: state.InventoryReducer.openInventoryDeleteModal,
    isLoading: state.InventoryReducer.isLoading,
    box_no_of_rows: state.InventoryReducer.box_no_of_rows,
    deleteBoxObject: state.InventoryReducer.deleteInventoryObject,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    getBoxList: (params) => dispatch(actions.getBoxList(params)),
    handleInventoryCloseDeleteModal: () => dispatch(actions.handleInventoryCloseDeleteModal()),
    handleInventoryOpenDeleteModal: (box) => dispatch(actions.handleInventoryOpenDeleteModal(box)),
    deleteBox: (id) => dispatch(actions.deleteBox(id)),
    reset: (searchBoxListForm) => dispatch(reset(searchBoxListForm)),
    addNewBoxClicked: () => dispatch(actions.addNewBoxClicked()),
    boxPageClicked: (page) => dispatch(actions.boxPageClicked(page)),
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(BoxContainer);