import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../../../../redux/actions/index';
import { animateScroll as scroll} from 'react-scroll'
import { routes } from '../../../../../../utility/constants/constants';
import FulfillmentManagerForm from '../../../../../component/Inventory/InventoryTicketsComponents/PickupsTicketComponents/FulfillmentManagerFormComponent/FulfillmentManagerForm';
const cloneDeep = require('clone-deep');

class FulfillmentManagerFormContainer extends Component {

  constructor(props) {
    super(props);
    this.state = {
      pickingTicketId: null,
    }
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this.props.getAllInventoryBoxNamesList();
    if(this.props && this.props.match && this.props.match.params && this.props.match.params.ticketId) {
      this.setState({pickingTicketId: this.props.match.params.ticketId})
      this.props.getSinglePickupTicket(this.props.match.params.ticketId);
    };
    scroll.scrollToTop();
  }

  handleSubmit = (values) => {
    let pickupItems = cloneDeep(values.fulfillmentManager);
    pickupItems.ticket.box_id = pickupItems.ticket.box_name.value;
    delete pickupItems.ticket.box_name;
    this.props.history.push({
      pathname: routes.INVENTORY_SCAN_READY_FOR_PICKUP_QR,
      state: { pickupItems: pickupItems }
    })
  };

  componentWillUnmount() {
    this.props.resetInventoryObjects();
  }

  render() {

    return (
        <FulfillmentManagerForm
          {...this.props}
          onSubmit={this.handleSubmit}
          pickingTicketId={this.state.pickingTicketId}
        />
    )
  }
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.InventoryReducer.isLoading,
    isFulfillmentManagerFormOpen: state.InventoryReducer.isFulfillmentManagerFormOpen,
    singlePickupTicket: state.InventoryReducer.singlePickupTicket,
    inventoryBoxNamesList: state.InventoryReducer.inventoryBoxNamesList,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    resetInventoryObjects: () => dispatch(actions.resetInventoryObjects()),
    getSinglePickupTicket: (id) => dispatch(actions.getSinglePickupTicket(id)),
    getAllInventoryBoxNamesList: (data) => dispatch(actions.getAllInventoryBoxNamesList(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FulfillmentManagerFormContainer);