import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

export const PerformCompanySkeletonModal = () => {

    const Field = (h, w) => {
        return (<Skeleton height={h} width={w} />)
    }

    return (
        <>
            <div className="modal-header">
                {Field(50, 200)}
                {/* <h2 className="md_title" id="exampleModalLongTitle"></h2> */}
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <div className="modal-body">
                <div className="quote_form ticket_form">
                    <div className="addQuote_form inspectionLoader">
                        {/* <div className="form-group">
                                        <label className="form_title">Location</label>
                                        <Input name="inspection[location]" className="form-control input-modifier" placeholder="Pegasus" />
                                    </div> */}
                        <div className="form-group">
                            {/* <label className="form_title">Account</label> */}
                            {Field(80, 550)}
                            {/* <Input name="inspection[account_id]" onChange={onAccountChange} noOptionsMessage={accountOptionsMessage} type="inputSelect" options={accounts} /> */}
                            {/* <select className="custom-select input-modifier">
                                            <option selected="">Charles Schwab 4701</option>
                                            <option value="All">All</option>
                                            <option value="Pending for Approva">Pending for Approval</option>
                                            <option value="Sent to Clients">Sent to Clients</option>
                                            <option value="Approved by Clients">Approved by Clients</option>
                                        </select> */}
                        </div>
                        <div className="form-group">
                            {Field(80, 550)}
                            {/* <label className="form_title">Form</label> */}
                            {/* <Input name="inspection[inspection_form_id]" noOptionsMessage={ratingOptionsMessage} type="inputSelect" options={inspectionForms} /> */}
                            {/* <select className="custom-select input-modifier">
                                            <option selected="">Janitorial Inspection Form</option>
                                            <option value="All">All</option>
                                            <option value="Pending for Approva">Pending for Approval</option>
                                            <option value="Sent to Clients">Sent to Clients</option>
                                            <option value="Approved by Clients">Approved by Clients</option>
                                        </select> */}
                        </div>
                        <div className="form-group">
                            {/* <div className="checkbox_block radius_checkbox">
                                <div className="custom-control custom-checkbox"> */}
                            {Field(80, 550)}
                            {/* <Input type="checkbox" name="inspection[private]"  id="customCheck1" checked="" /> */}
                            {/* <label className="custom-control-label" for="customCheck1"><span><img src="../images/icons/icn_lock_lg.png" /></span></label> */}
                            {/* </div> */}
                            {/* <p className="cst_para">{Field(25, 450)}</p> */}
                            {/* <p className="cst_para">Private - If checked, this inspection and results will ONLY be visible to users with private inspection permissions.</p> */}
                            {/* </div> */}
                        </div>
                        <div className="form-group">
                            {/* <div className="checkbox_block radius_checkbox checkbox1">
                                <div className="custom-control custom-checkbox"> */}
                            {/* <Input type="checkbox" name="inspection[ticket]" className="custom-control-input" id="customCheck2" /> */}
                            {Field(80, 550)}
                            {/* <label className="custom-control-label" for="customCheck2"><span className="cst_label_txt">Check, to create a ticket</span></label> */}
                            {/* </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PerformCompanySkeletonModal;