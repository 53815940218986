import React, { useEffect, useState } from 'react'
import withFormHoc from '../../../../../../hoc/withReactHookForm/withReactHookForm'
import { Input } from '../../../../UI/InputElement/reactFormHookInputField'
import BpLineItems from './bpLineItems'
import BpSurveyItems from './bpSurveyItems'
import { BP_AREAS, calTotalAreas, routes } from '../../../../../../utility/constants/constants'
import BpModal from './bpModal'
import { useWatch } from 'react-hook-form'
import { useSelector } from 'react-redux'
import ColumnModal from './columnModal'
import { useHistory } from 'react-router-dom/cjs/react-router-dom'
import AssetsFormSkeleton from '../../../../Inventory/Assets/NewAssetsForm/AssetsFormSkeleton'

function BuildingProfileForm(props) {
    const { jobList } = useSelector((state) => state.FormAuditModReducer)
    const { isLoading, isUpdateBuildingProfile } = useSelector((state) => state.BuildingProfileReducer)
    const [areaList, setAreaList] = useState(BP_AREAS)
    const [sectionList, setSectionList] = useState(props.getValues('sections') || [])
    const [areaIndex, setAreaIndex] = useState()
    const [areaState, setAreaState] = useState(undefined)
    const [colValue, setColValue] = useState()
    const [addDetailsClicked, setAddDetailsClicked] = useState(0)
    const [addDetailsdisable, setAddDetailsdisable] = useState()
    const [updateDetailsClicked, setUpdateDetailsClicked] = useState(0)
    const [grandTotal, setGrandTotal] = useState(0)
    console.log('grandTotal', grandTotal);
    const history = useHistory()

    const sectionListHandler = (values) => {

        const field = values.sections.area_field

        const update = sectionList.find((section) => section.sections.area_field === field)

        if (update) {
            const updatedata = sectionListUpdateHandler(values, field)
            const total = [...updatedata]
            const updatedTotal = total.reduce((cur, acc) => {
                if((acc.sections.area_field === "Carpet sq. ft.") || (acc.sections.area_field ==="Tile sq. ft.") || (acc.sections.area_field === "Other-Terazzo")){
                    return cur + parseInt(acc.sections.total)
                }else{
                    return cur + 0
                }
            }, 0)
            setGrandTotal(updatedTotal)
            setSectionList(updatedata)
            props.setValue('sections', updatedata,{ shouldDirty: true })
        }
        else {
            const total = [...sectionList, values]
            const updatedTotal = total.reduce((cur, acc) => {
              if((acc.sections.area_field === "Carpet sq. ft.") || (acc.sections.area_field ==="Tile sq. ft.") || (acc.sections.area_field === "Other-Terazzo")){
                    return cur + parseInt(acc.sections.total)
                }else{
                    return cur + 0
                }
            }, 0)
            setGrandTotal(updatedTotal)
            setSectionList([...sectionList, values])
            props.setValue('sections', [...sectionList, values],{ shouldDirty: true })
        }
        setAreaIndex(undefined)

    }

    const sectionListUpdateHandler = (values, field) => {

        const updatedValue = sectionList.map((item) => {
            if (item.sections.area_field === field) {
                return values
            } else {
                return item
            }
        })


        return updatedValue

    }


    useEffect(() => {
        if (sectionList.length) {
            const values = [...sectionList]
            const updatedCol = values.map((item) => {
                return {
                    sections: {
                        ...item.sections,
                        data: addDetailsdisable.data.map((col, i) => {
                            return {
                                column_name: col.column_name,
                                column_value: item && item.sections.data[i] ? item.sections.data[i].column_value : 0,
                            }
                        }),
                    }
                }
            })

            const finalUpdatedAreaList = updatedCol.map((item) => {
                return {
                    sections: {
                        ...item.sections,
                        total: item.sections.data.reduce((acc, cur) => { return acc + parseInt(cur.column_value) }, 0),
                    }

                }
            })
            props.setValue('sections', finalUpdatedAreaList,{ shouldDirty: true })
            setSectionList(finalUpdatedAreaList)

            const setTotal = [...finalUpdatedAreaList]
            const total = [...finalUpdatedAreaList]
            const updatedTotal = total.reduce((cur, acc) => {
                if((acc.sections.area_field === "Carpet sq. ft.") || (acc.sections.area_field ==="Tile sq. ft.") || (acc.sections.area_field === "Other-Terazzo")){
                    return cur + parseInt(acc.sections.total)
                }else{
                    return cur + 0
                }
            }, 0)
            setGrandTotal(updatedTotal)

            setTotal.map((i) => {
                const value = calTotalAreas.lineItems.find((item) => item.key === i.sections.area_field)
                const surveyItems = calTotalAreas.surveyItems.find((item) => item.key === i.sections.area_field)
                if (surveyItems) {
                    props.setValue(surveyItems?.value, i.sections.total)
                }
                if (value) {
                    props.setValue(value?.value, i.sections.total)
                }
            })

        }
    }, [addDetailsdisable])

    useEffect(() => {

        setSectionList(props.getValues('sections') ?? [])
    }, [props.defaultValues])

    const updateAreaHandler = (i) => {
        setAreaIndex(i)
        setUpdateDetailsClicked((pre) => pre + 1)
    }

    const getJobList = (job) => {
        if (job && job.length > 0) {
            return job && job.map((data) => {
                return {
                    value: data.id,
                    label: data.name,
                }
            })
        } else {
            return []
        }
    };
    const [listOfJob, setJobList] = useState(getJobList(jobList))

    useEffect(() => {
        setJobList(getJobList(jobList))
    }, [jobList])

    if (isUpdateBuildingProfile) {
        return (
            <AssetsFormSkeleton />
        )
    }

    return (
        <>
            <main class="app-content wraper_content chart_section">
                <section className="teammanage_formmain">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="team_manage_head">
                                    <h1 className="tm_top_head">{props.isEdit ? "Edit Building Profile" : props.isView ? "View Building Profile" : "Building Profile"}</h1>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <form onSubmit={props.handleSubmit((data) => {

                                    props.onSubmit(data)
                                })}>
                                    <div className="tm_form_wrapper">
                                        <div className="tm_form_wrap">
                                            <div className="tm_form_innner">
                                                <div className="form">
                                                    <div className="row">
                                                        <div class="col-md-6 col-sm-12">
                                                            <div class="form-group">


                                                                <Input
                                                                    type="inputSelect"
                                                                    required
                                                                    selectLabel="Job"
                                                                    control={props.control}
                                                                    controller={props.controller}
                                                                    selectName={`account_id`}
                                                                    id={`account_id`}
                                                                    isStar={false}
                                                                    placeholder="Job"
                                                                    selectOptions={listOfJob}
                                                                    formControlProps={props.register(
                                                                        `account_id`,
                                                                        {
                                                                            required: "This field is required",
                                                                            maxLength: 80,
                                                                        }
                                                                    )}
                                                                    selectClasses="form-select"
                                                                    validationErrorMessage={
                                                                        props.errors?.account_id
                                                                            ?.message ?? ""
                                                                    }
                                                                    disabled={props?.isView || props?.isEdit}

                                                                />


                                                            </div>
                                                        </div>
                                                        <div className="col-md-6 col-sm-12">
                                                            <div className="form-group">
                                                                <Input
                                                                    type="text"
                                                                    labelClasses="form-label"
                                                                    label={`Name of Building`}
                                                                    placeholder={'Name of Building'}
                                                                    id={`building_name`}
                                                                    formControlProps={props.register(`building_name`, {
                                                                        required: "This field is required"
                                                                    })}
                                                                    validationErrorMessage={props.errors?.building_name?.message}
                                                                    formControlClasses="form-control"
                                                                    disabled={props.isView}
                                                                />
                                                            </div>

                                                        </div>

                                                        <div className="col-md-12 col-sm-12">
                                                            <div className="form-group">
                                                                <Input
                                                                    type="text"
                                                                    labelClasses="form-label"
                                                                    label={`Address`}
                                                                    placeholder={'Address'}
                                                                    id={`address`}
                                                                    formControlProps={props.register(`address`, {
                                                                        required: "This field is required"
                                                                    })}
                                                                    validationErrorMessage={props.errors?.address?.message}
                                                                    formControlClasses="form-control"
                                                                    disabled={props.isView}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-md-12 col-sm-12">
                                                            <div className="form-group">
                                                                <Input
                                                                    type="text"
                                                                    labelClasses="form-label"
                                                                    label={`City`}
                                                                    placeholder={'City'}
                                                                    id={`city`}
                                                                    formControlProps={props.register(`city`, {
                                                                        required: "This field is required"
                                                                    })}
                                                                    validationErrorMessage={props.errors?.city?.message}
                                                                    formControlClasses="form-control"
                                                                    disabled={props.isView}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 col-sm-12">
                                                            <div className="form-group">
                                                                <Input
                                                                    type="text"
                                                                    labelClasses="form-label"
                                                                    label={`State`}
                                                                    placeholder={'State'}
                                                                    id={`state`}
                                                                    formControlProps={props.register(`state`, {
                                                                        required: "This field is required"
                                                                    })}
                                                                    validationErrorMessage={props.errors?.state?.message}
                                                                    formControlClasses="form-control"
                                                                    disabled={props.isView}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 col-sm-12">
                                                            <div className="form-group">
                                                                <Input
                                                                    type="number"
                                                                    labelClasses="form-label"
                                                                    label={`Zip`}
                                                                    placeholder={'Zip'}
                                                                    id={`zip`}
                                                                    formControlProps={props.register(`zip`, {
                                                                        required: "This field is required"
                                                                    })}
                                                                    validationErrorMessage={props.errors?.zip?.message}
                                                                    formControlClasses="form-control"
                                                                    disabled={props.isView}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {props.isView ? null : (<div className="tm_form_wrap">
                                            <div className="tm_form_innner">
                                                <div className="form">
                                                    <div className="row">
                                                        {
                                                            !props.isEdit && !props.isView ? <div className="col-md-8 col-sm-8">
                                                                <div className="areas_field_wrap">
                                                                    <div className="form-group">

                                                                        <label for="jobname" className="form-label"
                                                                        >Areas</label>
                                                                        <Input
                                                                            type="inputSelect"
                                                                            control={props.control}
                                                                            controller={props.controller}
                                                                            selectName={`area`}
                                                                            placeholder={'Please Select'}
                                                                            selectOptions={areaList}
                                                                            required={props.register(`area`)}
                                                                            selectClasses="form-control"
                                                                            disabled={props.isView}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div> : null
                                                        }

                                                        {
                                                            !props.isEdit && !props.isView ? <div className="col-md-2 col-sm-4 " style={{ marginTop: "-0.6rem" }}    >
                                                                <div className="submitcancel_btn">
                                                                    <button
                                                                        type="button"
                                                                        className="btn theme_def_btn1"
                                                                        data-bs-toggle="modal"
                                                                        data-bs-target="#exampleModal"
                                                                        onClick={() => {
                                                                            const areaField = props.getValues("area")
                                                                            setAreaState(areaField)
                                                                            setAddDetailsClicked((pre) => pre + 1)
                                                                            setAreaIndex(undefined)
                                                                        }}
                                                                        style={{ width: '100%' }}
                                                                        disabled={!addDetailsdisable?.data[0]?.column_name}
                                                                    >
                                                                        Add Details
                                                                    </button>
                                                                </div>
                                                            </div> : ""
                                                        }


                                                        <div className={props.isEdit ? "col-md-12 col-sm-12" : "col-md-2 col-sm-4"} style={{ marginTop: "-0.6rem" }}    >
                                                            <div className="submitcancel_btn">
                                                                <button
                                                                    type="button"
                                                                    style={props.isEdit ? { width: '30%' } : { width: '100%' }}
                                                                    className="btn theme_def_btn1"
                                                                    data-bs-toggle="modal"
                                                                    data-bs-target="#columnModal"
                                                                    onClick={() => {
                                                                        const value = props.getValues('columnName')
                                                                        setColValue(value)
                                                                    }}
                                                                >
                                                                    Set Up Floors
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>)}


                                        {sectionList && sectionList.map((item, i) => (
                                            <div className="tm_form_wrap">
                                                <div className="tm_form_innner">
                                                    <div className="form">
                                                        <div className="row">
                                                            <div className="col-md-6 col-sm-12">
                                                                <div className="form-group">
                                                                    <label htmlFor='areas' class="form-label">Areas</label>
                                                                    <input
                                                                        type="text"
                                                                        name='areas'
                                                                        className="form-control"
                                                                        disabled={true}
                                                                        value={item.sections.area_field}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6 col-sm-12">
                                                                <div className="form-group">
                                                                    <label htmlFor='total' class="form-label">Totals</label>
                                                                    <input
                                                                        type="text"
                                                                        name='total'
                                                                        className="form-control"
                                                                        value={item.sections.total}
                                                                        disabled={true}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-12 col-md-12 col-sm-12">
                                                                <div className="form-group">
                                                                    <label htmlFor='comments' class="form-label">Comments</label>
                                                                    <input
                                                                        type="text"
                                                                        name='comments'
                                                                        className="form-control"
                                                                        value={item.sections.comments}
                                                                        disabled={true}
                                                                    />
                                                                </div>
                                                                <div className="submitcancel_btn">
                                                                    <button
                                                                        type="button"
                                                                        className="btn theme_def_btn1"
                                                                        onClick={() => updateAreaHandler(i)}
                                                                        data-bs-toggle="modal"
                                                                        data-bs-target="#exampleModal"
                                                                    >
                                                                        {props.isView ? "View Details" : "Update Details"}
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                        <div className="tm_form_wrap">
                                            <div className="tm_form_innner">
                                                <div className="form">
                                                    <div className="row">
                                                        <BpLineItems {...props} />
                                                        <BpSurveyItems {...props} grandTotal={grandTotal} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-sm-12">
                                                <div className="submitcancel_btn">
                                                    <button onClick={() => history.goBack()} type="button" className="btn cst_btn btn-outline-secondary  cancel_btnnew">
                                                        Cancel
                                                    </button>
                                                    {
                                                        !props?.isView ? <button type="submit" className={isLoading ? "btn cst_btn btn_danger btn-wait" : "btn cst_btn btn_danger"}>
                                                            {isLoading ? "" : props.isEdit ? "Update" : "Submit"}
                                                        </button> : null
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <BpModal {...props} setAreaList={setAreaList} sectionListHandler={sectionListHandler} sectionListUpdateHandler={sectionListUpdateHandler} areaIndex={areaIndex} setAreaIndex={setAreaIndex} sectionList={sectionList} getValues={props.getValues} area={props.getValues('area')} areaState={areaState} addDetailsClicked={addDetailsClicked} setAddDetailsdisable={setAddDetailsdisable} updateDetailsClicked={updateDetailsClicked} />
            <ColumnModal {...props} colValue={colValue} />
        </>
    )
}

export default withFormHoc(BuildingProfileForm) 