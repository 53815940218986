import React, { useState, useEffect } from 'react';
import { makeFirstLetterCapital } from '../../../../../utility/utility';
import PreviewJobAssociationSkeleton from './PreviewJobAssociationSkeleton';
import queryString from 'query-string';

let PreviewJobAssociationComponent = (props) => {

    const [jobAssociation, setJobAssociation] = useState(null);
    console.log('jobAssociation', jobAssociation);


    useEffect(() => {

        setJobAssociation(props.singleJobAssociation);

    }, [props.singleJobAssociation]);

    const params =  queryString.stringify({sharaeble : props?.match?.params?.id });

    if (props.isLoading) {

        return (
            <>
                <PreviewJobAssociationSkeleton />
            </>
        );

    } else {

        return (
            <>

                <main className="app-content add_account_modal add_frontline_pop">
                    <div className="appcontent_Inner user_newQuote pt-0">

                        <div className="container py-5">

                            <div className="row text-center mb-4">
                                <div className="col-lg-7 mx-auto">
                                    <h1 className="mb-0" style={{ color: '#4a4a4a', fontWeight: '500', fontSize: '37px' }}> Job Association Details </h1>
                                </div>
                            </div>
                            <div className="addQuote_form">
                                <form>

                                    <div className="row">
                                        <div className="col-lg-9 mx-auto">
                                            <ul className="list-group shadow rounded" style={{ marginLeft: '10%', marginRight: '10%' }}>

                                                <li className="list-group-item">
                                                    <div className="media align-items-lg-center flex-column flex-lg-row p-3">
                                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%' }}>
                                                            <div className="media-body">
                                                                <h5 className="mt-0 font-weight-bold mb-2 text-dark"> Name </h5>
                                                            </div>
                                                            <div className="media-body">
                                                                {jobAssociation && jobAssociation.name ? (
                                                                    <>
                                                                        <h5 className="ml-lg-0 order-1 order-lg-2 ">
                                                                            {jobAssociation.name}
                                                                        </h5>
                                                                    </>
                                                                ) : '–'}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>

                                                <li className="list-group-item">
                                                    <div className="media align-items-lg-center flex-column flex-lg-row p-3">
                                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%' }}>
                                                            <div className="media-body" style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                                                <div >
                                                                    <h5
                                                                        className="mt-0 font-weight-bold mb-3 text-dark"
                                                                        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}
                                                                    >
                                                                        {jobAssociation && jobAssociation?.accounts?.length > 1 ? 'Associated Jobs' : 'Associated Job'}
                                                                    </h5>
                                                                </div>
                                                            </div>
                                                            <div className="media-body" style={{ display: 'flex', flexDirection: 'column' }}>
                                                                {jobAssociation && jobAssociation?.accounts?.length > 0 ? jobAssociation.accounts.map((job, index, array) => {

                                                                    return (
                                                                        <>
                                                                            <div style={{ borderLeft: '5px solid #cfcfcf', backgroundColor: '#f0f0f0' }}>
                                                                                <h5 className="ml-lg-3 order-1 order-lg-2 text-muted mt-2 mb-1"> {job && job.name} </h5>
                                                                            </div>
                                                                            {index === array.length - 1 ? null : <br />}
                                                                        </>
                                                                    );

                                                                }) : (<h5 className="ml-lg-0 font-italic text-color-info mb-0" style={{ fontSize: '15px' }}> None of Jobs were Associated </h5>)}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>

                                                <li className="list-group-item">
                                                    <div className="media align-items-lg-center flex-column flex-lg-row p-3">
                                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%' }}>
                                                            <div className="media-body">
                                                                <h5 className="mt-0 font-weight-bold mb-2 text-dark"> Created Tickets </h5>
                                                            </div>
                                                            <div className="media-body">
                                                                <button data-test="" type="button" className="btn cst_btn pegdesk_button" onClick={() => props.history.push({pathname : '/tickets', search : `?${params}`})}>
                                                                    Go to Created Tickets
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>

                                            </ul>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>

                    </div >
                </main >

            </>
        );

    }

};

export default PreviewJobAssociationComponent;