import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../../redux/actions/index';
import { closeModel } from '../../../../utility/utility';
import { routes } from '../../../../utility/constants/constants';
import { reset } from 'redux-form';
import isEmpty from '../../../../utility/IsEmptyValidator';
import $ from "jquery";
import { validateCompanyUsers } from '../../../../utility/validator/submit';
import CompanyUsers from '../../../component/companies/companyUsers/companyUsers';
import storage from '../../../../utility/storage';


class CompanyUsersContainer extends Component {

    constructor(props) {
        super(props);

        this.state = {
            currentAssosciatedUserEmail: '',
            companyErrorMessage: ''
        }
    }

    newCompanieClicked = () => {
        this.props.history.push(routes.NEW_COMPANY)
    }

    kpiDashboardClicked = () => {
        this.props.history.push(`/customers/${this.props.match.params.companyId}/kpidashboard`);
    }
    BRMSuheduleClicked = () => {
        this.props.history.push(`/brm-schedule`);
    }


    componentDidMount() {
        // if (!this.props.companyUsersList) {
        //     this.props.getCompanyUsers(this.props.match.params.companyId);
        // }
        this.props.getCompanyUsers( this.props.match.params.companyId, {}, this.props.company_users_no_of_rows)
    }

   

    resetClicked = () => {
        this.props.reset('searchCompanieUsersForm');
        this.props.getCompanyUsers(this.props.match.params.companyId, {}, this.props.company_users_no_of_rows);
    }


    handleClickDeleteModel = (user) => {

        this.props.handleClickDeleteModel(user);
    }

    handleDeleteClose = () => {
        this.props.handleDeleteClose();
    }

    deleteCompany = (id) => {
        this.props.deleteCompany(id);
    }

    editCompanyClicked = (company) => {
        this.props.editCompanyClicked(company);
    }

    previewCompanyClicked = (company) => {
        this.props.history.push(routes.COMPANY_USERS)
    }

    openAssociateUserClicked = () => {
        this.props.openAssociateUserClicked();
        this.props.getFilteredCompanyUsers(this.props.match.params.companyId);
    }

    // associateCompanyClicked = () => {
    //     this.props.associateCompanyClicked();
    // }

    userSelected = (value) => {

        this.setState({
            currentAssosciatedUserEmail: value.email.label
        })
    }

    addCompanyUsers = (values, a, b) => {

        const current_company = Object.entries(values['current_company']);
        const selected_compnies = Object.entries(values['selected_compnies']);

        let company_ids = new Array();
        if (current_company[0][1]) {
            if (current_company[0][1]) {
                company_ids.push(current_company[0][0])
            }

            selected_compnies.map(sc => {
                if (sc[1]) {
                    return company_ids.push(sc[0])
                } else {

                    let index = company_ids.findIndex(function (c) {
                        return c === sc[0]
                    });

                    if (index > -1) {
                        company_ids.splice(index, 1);
                    }
                }
                //   
            })

            let error = validateCompanyUsers(company_ids);

            if (!error) {
                this.setState({
                    companyErrorMessage: ''
                })
                const body = {
                    email: values.email.label,
                    company_ids: company_ids
                }

                this.props.addCompanyUsers(this.props.match.params.companyId, body);
            } else {
                this.setState({
                    companyErrorMessage: error
                })
            }
        } else {
            closeModel();
        }
    }

    componentWillUnmount() {
        this.props.resetObjects();
    }

    newCompanyUserClicked = () => {
        this.props.history.push(`/companies/${this.props.match.params.companyId}/new_company_user`)
    }

    handelSubmit = (values) => {

        this.props.searchCompanyUsers(this.props.match.params.companyId, values)
    }

    getCurrentCompany = () => {
        if (isEmpty(this.props.company)) {
            this.props.getCompany(this.props.match.params.companyId);
        }
    }

    deleteCompanyUser = (userId) => {
        this.props.deleteAccount(userId);
    }

    render() {

        return (
            <CompanyUsers
                {...this.props}
                onSubmit={this.handelSubmit}
                BRMSuheduleClicked={this.BRMSuheduleClicked}
                kpiDashboardClicked={this.kpiDashboardClicked}
                userSelected={this.userSelected}
                getCurrentCompany={this.getCurrentCompany}
                companyErrorMessage={this.state.companyErrorMessage}
                companyUsersList={this.props.companyUsersList}
                addCompanyUsers={this.addCompanyUsers}
                newCompanyUserClicked={this.newCompanyUserClicked}
                resetClicked={this.resetClicked}
                deleteCompany={this.deleteCompany}
                handleDeleteClose={this.handleDeleteClose}
                newCompanieClicked={this.newCompanieClicked}
                deleteCompanyUser={this.deleteCompanyUser}
                companyUsers_rows={this.props.companyUsers_rows}
                isLoading={this.props.isLoading}
                previewCompanyClicked={this.previewCompanyClicked}
                onCompanyUsersPageSizeChange={this.onCompanyUsersPageSizeChange}
                company={this.props.company}
                editCompanyUsersClicked={this.editCompanyUsersClicked}
                deleteObject={this.props.deleteObject}
                openDeleteModel={this.props.openDeleteModel}
                handleClickDeleteModel={this.handleClickDeleteModel}
                openAssociateUserCompanyModal={this.props.openAssociateUserCompanyModal}
                // openAssociateCompanyModal={this.props.openAssociateCompanyModal}
                openAssociateUserClicked={this.openAssociateUserClicked}
                closeCompanyModalClicked={this.props.closeCompanyModalClicked}
                filterCompanyUsers={this.props.filterCompanyUsers}
                popupLoading={this.props.popupLoading}
            // AssociateCompanyClicked={this.associateCompanyClicked}
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.authReducer.user,
        company: state.adminOrSuperAdminReducer.company,
        companyUsersList: state.adminOrSuperAdminReducer.companyUsersList,
        // companyUsersList: state.adminOrSuperAdminReducer.searchedCompanyUsersList ? state.adminOrSuperAdminReducer.searchedCompanyUsersList : state.adminOrSuperAdminReducer.companyUsersList,
        isLoading: state.adminOrSuperAdminReducer.isLoading,
        isCompanyUsersPending: state.adminOrSuperAdminReducer.isCompanyUsersPending,
        deleteObject: state.adminOrSuperAdminReducer.deleteObject,
        openDeleteModel: state.adminOrSuperAdminReducer.openDeleteModel,
        company_users_no_of_rows: state.adminOrSuperAdminReducer.company_users_no_of_rows,
        openAssociateUserCompanyModal: state.adminOrSuperAdminReducer.openAssociateUserCompanyModal,
        // openAssociateCompanyModal: state.adminOrSuperAdminReducer.openAssociateCompanyModal,
        filterCompanyUsers: state.adminOrSuperAdminReducer.filterCompanyUsers ? state.adminOrSuperAdminReducer.filterCompanyUsers : [],
        popupLoading: state.adminOrSuperAdminReducer.popupLoading
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getCompanyUsers: (id, values, user_no_of_rows, page, sorted, filtered) => dispatch(actions.getCompanyUsers(id, values, user_no_of_rows, page, sorted, filtered)),
        addCompanyUsers: (id, body) => dispatch(actions.addCompanyUsers(id, body)),
        openAssociateUserClicked: () => dispatch(actions.openAssociateUserClicked()),
        resetObjects: () => dispatch(actions.resetObjects()),
        // associateCompanyModalClicked: () => dispatch(actions.associateCompanyModalClicked()),
        closeCompanyModalClicked: () => dispatch(actions.closeCompanyModalClicked()),
        getFilteredCompanyUsers: (companyId) => dispatch(actions.getFilteredCompanyUsers(companyId)),
        getCompany: (id) => dispatch(actions.getEditCompany(id)),
        // associateCompanyClicked: () => dispatch(actions.associateCompanyClicked()),
        handleDeleteClose: () => dispatch(actions.handleDeleteClose()),
        handleClickDeleteModel: (user) => dispatch(actions.handleClickDeleteModel(user)),
        // searchUserClicked: (quote) => dispatch(actions.searchUserClicked(quote)),
        // onCompanyPageSizeChange: (rows) => dispatch(actions.onCompanyPageSizeChange(rows)),
        // editCompanyUsersClicked: (user) => dispatch(actions.editCompanyUsersClicked(user)),
        searchCompanyUsers: (accountId, values) => dispatch(actions.searchCompanyUsers(accountId, values)),
        deleteCompanyUser: (userId, companyId) => dispatch(actions.deleteCompanyUser(userId, companyId)),
        reset: (searchCompanieUsersForm) => dispatch(reset(searchCompanieUsersForm)),  // requires form name
        deleteAccount: (id) => dispatch(actions.deleteAccount(id)),
        getKpiDashboardData: (DataType, date, inspection_form_name) => dispatch(actions.getKpiDashboardData(DataType, date, inspection_form_name)),
        // getKpiInspectionCount: (DataType, date, inspection_form_name) => dispatch(actions.getKpiInspectionCount(DataType, date, inspection_form_name)),
        getKpiInspectionQQvScoreDashboardData: (DataType, date) => dispatch(actions.getKpiInspectionQQvScoreDashboardData(DataType, date)),
        getKpiInspectionQQvRootcaseDashboardData: (DataType, date) => dispatch(actions.getKpiInspectionQQvRootcaseDashboardData(DataType, date)),
        getKpiInspectionDeficencyDashboardData: (DataType, date) => dispatch(actions.getKpiInspectionDeficencyDashboardData(DataType, date)),
        // getKpiAvarageScore: (DataType, date) => dispatch(actions.getKpiAvarageScore(DataType, date)),
        // getKpiTopInspection: (DataType, date, values, quarter) => dispatch(actions.getKpiTopInspection(DataType, date, values, quarter)),
        // getKpiQualityInspection: (DataType, date, values, quarter) => dispatch(actions.getKpiQualityInspection(DataType, date, values, quarter)),
        
        // newUserClicked: () => dispatch(actions.newUserClicked())
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(CompanyUsersContainer);