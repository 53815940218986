import React, { useState } from 'react';
import { ClapSpinner } from 'react-spinners-kit';
import ReactTable from 'react-table-v6';
import '../../../../styles/custom.css';
import {
    Dialog,
    DialogContent,
    DialogActions,
    DialogTitle
} from '@material-ui/core';
import { CustomNoDataComponent } from '../../../../utility/utility';
import { useSelector, useDispatch } from 'react-redux';
import { FaArrowDown, FaArrowUp } from "react-icons/fa";
import * as actions from '../../../../redux/actions/index';
import 'react-table-v6/react-table.css';

const HoursTypeTable = (props) => {

    const [openModel, setOpen] = useState(false);
    const [currentArea, setCurrentArea] = useState(null);
    const dispatch = useDispatch();

    const DateGenerate = useSelector(state => state.adminOrSuperAdminReducer.dataGenerated);
    const SearchOrResetAccountUsersClicked = useSelector(state => state.adminOrSuperAdminReducer.SearchOrResetAccountUsersClicked);
    const No_of_pages = useSelector(state => state.TimeTrackerModReducer.total_no_of_pages);
    const No_of_rows = useSelector(state => state.adminOrSuperAdminReducer.account_users_row);
    const data = props.accountHoursType && props.accountHoursType.map((area, i) => {
        return {
            account_number: area ? area.account_number : '',
            type_id: area ? area.type_id : '',
            description: area && area.description ? area.description : '',
            actions:
                <>
                    {
                        props.user && props.user.role === "superadmin" ? <span className="mr account_edit_icn" onClick={() => [setOpen(true), setCurrentArea(area)]}><i class="fa fa-trash" aria-hidden="true" data-test="area_trash_icon"></i>
                        </span>
                            : ""
                    }
                </>
        }
    })


    const columns = [
        {
            Header: <><span>JobSite ID</span><FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
            accessor: 'account_number', // String-based value accessors!,
            show: props.accountHoursType.length !== 0,
        }, {
            Header: <><span>Task Code</span><FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
            accessor: 'type_id', // String-based value accessors!,
            show: props.accountHoursType.length !== 0,
        }, {
            Header: <><span>Description</span><FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
            accessor: 'description', // String-based value accessors!,
            show: props.accountHoursType.length !== 0,
        },
        {
            Header: ' ',
            accessor: 'actions',
            width: 350
            // show: false,
        }
    ]

    const fetchData = (state) => {

        if (SearchOrResetAccountUsersClicked) {
            dispatch(actions.resetSearchOrResetValue())
        } else {

            if (!DateGenerate) {
                const size = state.pageSize;
                const page = state.page + 1;
                const sorted = JSON.stringify(state.sorted);
                if (!sorted.includes('checkbox')) {
                    props.getAccountHoursType({ limit:size, page  },
                        props.match.params.accountId);
                }
            }
        }
    }
    const handleConfirm = () => {
        setOpen(false);
        props.deleteAccountHoursType(currentArea.account_rate_id, props.match.params.accountId)
    };


    return (
        SearchOrResetAccountUsersClicked  ?
            <div className="loader_btn_block">
                <ClapSpinner backColor="#89073c" size={40} frontColor="#89073c8a" />
            </div>
            :
            <> <ReactTable
                data={data}
                loading={props?.isLoadingList}
                defaultPageSize={No_of_rows ? No_of_rows : 10}
                minRows={0}
                manual
                onFetchData={fetchData}
                pages={No_of_pages}
                onPageSizeChange={props.onUserPageSizeChange}
                showPaginationTop={true}
                showPagination={props.accountHoursType.length !== 0}
                showPaginationBottom={false}
                columns={columns}
                NoDataComponent={() => CustomNoDataComponent(props.isLoadingList, 'No Hours Type Found')}
                getTheadThProps={() => {
                    return {
                        style: {
                            borderTop: 'none',
                            borderBottom: 'none',
                            borderRight: 'none',
                            textAlign: 'center',
                            color: '#566975',
                            fontSize: '14px',
                            fontWeight: '600',
                            whiteSpace: 'nowrap',
                            float: 'left',
                        }
                    }
                }
                }
                LoadingComponent={() =>
                    props.isLoadingList ?
                        <div style={{
                            display: "block",
                            position: "absolute",
                            left: 0,
                            height: '100%',
                            right: 0,
                            background: "rgba(255,255,255,0.8)",
                            transition: "all .3s ease",
                            top: 0,
                            bottom: 0,
                            textAlign: "center"
                        }}
                            className="loader_btn_block">
                            <ClapSpinner backColor="#89073c" size={40} frontColor="#89073c8a" />
                        </div> : null}
                getTrProps={() => {
                    return {
                        className: 'react-table-tr-element',
                        style: {
                            border: '1px solid #c7cdd1',
                            transition: '.3s ease-in-out',
                            width: '100%',
                            marginTop: '5px',
                            float: 'left'
                        }
                    }

                }}
                getTdProps={(column) => {

                    if (column.id == 'image') {
                        return {
                            style: {
                                marginTop: '0px'
                            }
                        }
                    } else {
                        return {
                            className: 'react-td-element',
                        }
                    }
                }
                }
                getProps={() => {
                    return {
                        style: {
                            border: 'none'
                        }
                    }
                }}
            />
                <Dialog
                    open={openModel}

                    aria-labelledby="form-dialog-title"
                >
                    <DialogTitle id="form-dialog-title" style={{ fontSize: '13', color: '#06080f' }}>
                        <h3 style={{ color: '#ab385e' }}>Are you sure?</h3>
                    </DialogTitle>

                    <DialogActions>
                        <button data-test="delete_account" style={{ minHeight: '36px', minWidth: '80px' }} onClick={() => handleConfirm()} disabled={props.isLoadingHollyday} className={props.isLoadingHollyday ? "btn cst_btn btn_danger mr-2 btn-wait" : "btn cst_btn btn_danger mr-2"} id="delete" type="delete">{props.isLoadingHollyday ? "" : "Delete"}</button>
                        <button style={{ minHeight: '36px', minWidth: '80px' }} onClick={() => setOpen(false)} className={"btn cst_btn btn-outline-secondary"} id="cancel" type="cancel">Cancel</button>
                    </DialogActions>
                </Dialog>
            </>
    )
}

export default HoursTypeTable;