import React from 'react';
import { CHIReviews, quarterCheckDates, userRoles } from '../../../utility/constants/constants';
import CHIReview1 from '../../component/CHIReview/CHIReviewStep1';
import CHIReview2 from '../../component/CHIReview/CHIReviewStep2';
import CHIReview3 from '../../component/CHIReview/CHIReviewStep3';
import { connect } from 'react-redux';
import * as actions from '../../../redux/actions/index';
import queryString from "query-string";
import storage from '../../../utility/storage';
import { convertedQuarterlyDate } from '../../../utility/utility';

class CHIReviewContianer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            CHIReviews: {
                chiReview1: true,
                // chiReview2: false,
                chiReview3: false
            },
            activeReview: 0,
            feed_back: ''
        }
    }

    componentDidMount = (prevProps, prevState) => {
        let user = storage.get('user');
        if (user.role === userRoles.CLIENT) {
            if ((convertedQuarterlyDate == quarterCheckDates.QUARTER1) || (convertedQuarterlyDate == quarterCheckDates.QUARTER2) ||
                (convertedQuarterlyDate == quarterCheckDates.QUARTER3) || (convertedQuarterlyDate == quarterCheckDates.QUARTER4)) {
                let chisurveyStart = storage.get('chisurveyStarted');
                this.setState({ chisurveyStarted: chisurveyStart })
            }
        }
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps.chi_review_loading === true && this.props.chi_review_loading === false) {
            this.setState({
                CHIReviews: {
                    chiReview1: false,
                    // chiReview2: false,
                    chiReview3: true
                }
            })
        }
    }

    nextClicked = (chiReview) => {

        if (chiReview === CHIReviews.chiReview1) {
            this.setState({
                CHIReviews: {
                    chiReview1: false,
                    // chiReview2: true,
                    chiReview3: true
                }
            })
        } else if (chiReview === CHIReviews.chiReview3) {

        }
    }

    setActiveReview = (review) => {
        this.setState({
            activeReview: review
        })
    }

    changeReviewText = (e) => {
        this.setState({
            feed_back: e.target.value
        })
    }

    submitCHIReview = (nextStep) => {
        const data = queryString.parse(this.props.location.search);

        // this.nextClicked(nextStep);

        const value = {
            chi_response: {
                user_id: data.user_id,
                chi_survey_id: data.chi_survey_id,
                rating: this.state.activeReview,
                feedback: this.state.feed_back
            }
        }

        this.props.submitCHIReview(value);
    }

    render() {

        return (
            this.state.CHIReviews.chiReview1 ?
                <CHIReview1
                    {...this.props}
                    {...this.state}
                    nextClicked={this.nextClicked}
                    submitCHIReview={this.submitCHIReview}
                    changeReviewText={this.changeReviewText}
                    setActiveReview={this.setActiveReview} /> :
                this.state.CHIReviews.chiReview2 ?
                    <CHIReview2
                        {...this.props}
                        {...this.state}
                        submitCHIReview={this.submitCHIReview}
                        changeReviewText={this.changeReviewText}
                        nextClicked={this.nextClicked} /> :
                    this.state.CHIReviews.chiReview3 ?
                        <CHIReview3
                            {...this.props}
                            {...this.state}
                            nextClicked={this.nextClicked} /> : null
        )
    }
}

const mapStateToProps = (state) => {
    return {
        chi_review_loading: state.adminOrSuperAdminReducer.chi_review_loading
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        submitCHIReview: (values) => dispatch(actions.submitCHIReview(values))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CHIReviewContianer);