import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reset } from 'redux-form';
import history from '../../../../../router/history';
import * as actions from '../../../../../redux/actions/index';
import { routes } from '../../../../../utility/constants/constants';
import EditPerDayShiftDetailForm from '../../../../component/TimeTrackerModuleComponent/ShiftComponent/ShiftCrudComponent/EditPerDayShiftDetailComponent/EditPerDayShiftDetailForm';
import errorHandler from '../../../../../utility/errorHandler/errorHandler';
import moment from 'moment';
import { convertDateFormatV4, convertTo24HourFormat } from '../../../../../utility/utility';
import { tzUTCDateTimeConverterTool_V1, tzUTCTimeConverterTool_V1 } from '../../../../../utility/TimezoneOperations/timezoneUtility';
const cloneDeep = require('clone-deep');

class EditPerDayShiftDetailContainer extends Component {
    constructor(props) {
        super(props);

    }

    componentDidMount() {

        if (this.props && this.props.match && this.props.match.params && this.props.match.params.perDayShiftId) {
            this.props.getSingleShifSchedule(this.props.match.params.perDayShiftId); // For getting Shift Per day details
        }

        setTimeout(() => {
            //accountId: this.props && this.props.singleShiftScheduleData && this.props.singleShiftScheduleData.accounts && this.props.singleShiftScheduleData.accounts[0] && this.props.singleShiftScheduleData.accounts[0].id,
            this.props.getUsersListByTheirRoles({ roles: 'frontline', shiftDetailId: this.props && this.props.match && this.props.match.params && this.props.match.params.perDayShiftId });
        }, 1000);

    }

    componentWillMount() {

    }

    handleSubmit = (values) => {

        if (values) {

            if (values.shiftOrigin) {

                if (values.shiftOrigin.user_id && !(values.shiftOrigin.user_id.value)) {
                    errorHandler('Frontline user is Required');
                    return false;
                }

            }

        }

        let setData = { data: {} };
        let fetchedValues = cloneDeep(values.shiftOrigin);

        if (this.props && this.props.match && this.props.match.params && this.props.match.params.perDayShiftId) {

            /* For Edit Flow */
            setData['data']['accountIds'] = fetchedValues['accountIds'].map(e => Number(e.id));
            setData['data']['user_id'] = Number(fetchedValues['user_id']['value']);
            setData['data']['start_date'] = fetchedValues['start_date'];
            setData['data']['end_date'] = fetchedValues['end_date'];
            setData['data']['start_time'] = convertTo24HourFormat(fetchedValues['start_time']);
            setData['data']['end_time'] = convertTo24HourFormat(fetchedValues['end_time']);
            setData['data']['notes'] = fetchedValues['notes'];

            this.props.checkFrontlinerShift(setData, 'update', this.props.match.params.perDayShiftId);

        }

    }

    onCancelClicked = () => {
        this.props.handleCancelClickAction();
        history.goBack();
    }

    hanldeReVampingShift = (values) => {
        /* For ReInitializing Shift Flow */

        let setData = { data: {} };
        let fetchedValues = cloneDeep(values.shiftOrigin);

        if (this.props && this.props.match && this.props.match.params && this.props.match.params.perDayShiftId) {
            /* For Edit Flow */

            setData['data']['user_id'] = Number(fetchedValues['user_id']['value']);

            this.props.editPerDayFLUserShiftDetail(setData, this.props.match.params.perDayShiftId);

        }
    }

    render() {
        return (
            <div>

                <EditPerDayShiftDetailForm
                    {...this.props}
                    onSubmit={this.handleSubmit}
                    onCancelClicked={this.onCancelClicked}
                    hanldeReVampingShift={this.hanldeReVampingShift}
                />

            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.authReducer.user,
        isLoading: state.TimeTrackerModReducer.isLoading,
        isEditFLShiftForm: state.TimeTrackerModReducer.isEditFLShiftForm,
        isUpdateFLShiftForm: state.TimeTrackerModReducer.isUpdateFLShiftForm,
        isTimeTrackerModalOpen: state.TimeTrackerModReducer.isTimeTrackerModalOpen,

        frontlineUsersList: state.TimeTrackerModReducer.usersListBySelectedRole,

        singleShiftScheduleData: state.TimeTrackerModReducer.singleShiftScheduleData, // For getting Shift Per day details
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        resetTimeTrackerObjects: () => dispatch(actions.resetTimeTrackerObjects()),
        handleCancelClickAction: () => dispatch(actions.handleCancelClickAction()),
        handleFrequentTimeTracker_openModal: (data) => dispatch(actions.handleFrequentTimeTracker_openModal(data)),
        handleFrequentTimeTracker_closeModal: (data) => dispatch(actions.handleFrequentTimeTracker_closeModal(data)),

        getUsersListByTheirRoles: (params) => dispatch(actions.getUsersListByTheirRoles(params)),

        getSingleShifSchedule: (id) => dispatch(actions.getSingleShifSchedule(id)), // For getting Shift Per day details

        checkFrontlinerShift: (data, formType, id) => dispatch(actions.checkFrontlinerShift(data, formType, id)),
        editPerDayFLUserShiftDetail: (data, id) => dispatch(actions.editPerDayFLUserShiftDetail(data, id)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditPerDayShiftDetailContainer);