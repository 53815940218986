import React, { useState } from 'react';
import { currentData } from '../../../utility/constants/constants';
import { GetFormatedDate, weekStartDayAndWeekEndDay, getMonthAndYear, getYearView, get_next_week_start, getNextYearDate, getNextMonthDate, getPast90Days, get_next_90days } from '../../../utility/utility';
import { convertDashboardDataToCsvType } from '../../../utility/utility';
import { CSVLink, CSVDownload } from "react-csv";
import { Input } from '../UI/InputElement/InputElement';
import { CustomDateFormSearchValidator as validate } from '../../../utility/validator/validator';
import { reduxForm, change, } from 'redux-form';
import { connect, useDispatch } from 'react-redux';
import { formValueSelector, getFormValues } from 'redux-form';


let CustomeDateFormate = (props) => {  
    let customDateFormValues = props.formStates
    const { handleSubmit, pristine, reset, submitting, error } = props;

   const getDisableStatus = () => {
    if(props.formStates) {
        if(props.formStates.fromDate !== '' && props.formStates.toDate !== '') {
            return false
        } else {
            return true
        }
    } else {
        return true
    }
    }
    return (
        <>
            <form className="form-inline" onSubmit={handleSubmit}>
                <div className="input-group cst_input_group">
                    <label>
                    <Input name="fromDate" maxDate={props.selectedValues.toDate ? props.selectedValues.toDate : new Date()} dateFormat="MM-dd-yyyy" placeholder="From Date" datePickerType={"react-datepicker"} type="date" className="form-control input-modifier" />
                    <div class="input-group-append cst_group_append">
                        <span class="input-group-text" id="basic-addon2"><i class="fa fa-calendar"></i></span>
                    </div>
                    </label>
                </div>
                <div className="input-group cst_input_group ml-2">
                    <label>
                    <Input name="toDate" minDate={props.selectedValues.fromDate ? props.selectedValues.fromDate : null} maxDate={new Date()} dateFormat="MM-dd-yyyy" placeholder="To Date" datePickerType={"react-datepicker"} type="date" className="form-control input-modifier" />
                    <div class="input-group-append cst_group_append">
                        <span class="input-group-text" id="basic-addon2"><i class="fa fa-calendar"></i></span>
                    </div>
                    </label>
                </div>
                <div className="input-group cst_input_group ml-2">
                <button
                style={{height: "40px", minWidth: "100px"}}
                data-test="submit_button"
                type="submit"
                disabled={props.isLoading || getDisableStatus()}
                className={
                  props.isLoading
                    ? "btn cst_btn btn_danger mr-2 btn-wait"
                    : "btn cst_btn btn_danger mr-2 "
                }
              >
                {props.isLoading ? "" : "Submit"}{" "}
              </button>
                </div>

            </form>
        </>
    )
}

const mapStateToProps = (state) => {
    let fromDate =  "";
    let toDate = "";

    return { initialValues: { fromDate, toDate }, formStates: getFormValues('customeDateForm') (state) }
}

CustomeDateFormate = reduxForm({
    form: 'customeDateForm',
    validate,
    enableReinitialize: true
})(CustomeDateFormate);

const selector = formValueSelector('customeDateForm') 
CustomeDateFormate = connect(state => {
    const { fromDate, toDate } = selector(state, 'fromDate', 'toDate')
    const selectedValues = { fromDate, toDate }
    return {
        selectedValues
    }
}) (CustomeDateFormate)

CustomeDateFormate = connect(mapStateToProps)(CustomeDateFormate)
export default CustomeDateFormate;
