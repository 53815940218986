import React, { useEffect } from "react";
import {  useLocation, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import PreviewEventForm from "../../../component/companies/BRMEventContainers/PreviewEvents/previewEventcomponent";
import { completePreviewEvent, getOnePreviewEvent, removePreviewDetails } from "../../../../redux/actions/BrmActions/BrmAction";
import { convertDateTimezoneFormAuditReduxForm, tzDateConverterTool_V2PreviewEvent, tzUTCDateTimeConverterTool_TIME_DATE } from "../../../../utility/TimezoneOperations/timezoneUtility";
import errorHandler from "../../../../utility/errorHandler/errorHandler";
import { convertDateFormatV4, convertTo24HourFormat } from "../../../../utility/utility";


const PreviewEventContainer = () => {
    const { id } = useParams();
    const dispatch = useDispatch()
    const { feedbackData } = useSelector((state) => state.BRM)
    const { state } = useLocation()

    const convertDate = (date) => {
        if (date) {
            var parts = date.split('T');
            var datePart = parts[0];
            let dt = moment(datePart)._d
            return dt
        }
    }

    const defaultForm = {
        attedees: feedbackData && feedbackData.feedbackUser?.length ? feedbackData.feedbackUser : [
            {
                first_name: "",
                last_name: "",
                email: "",
            },
        ],
        scheduled_at: feedbackData && feedbackData.scheduled_at ? convertDateTimezoneFormAuditReduxForm(feedbackData.scheduled_at) : "",
        time: feedbackData && feedbackData.time ? tzDateConverterTool_V2PreviewEvent(feedbackData.time):"",
        notes: feedbackData && feedbackData.notes ? feedbackData.notes : "",
    }

    

    const structureBody = (data) => {
        let time=tzUTCDateTimeConverterTool_TIME_DATE(convertDateFormatV4(data.scheduled_at), convertTo24HourFormat(data?.time?.format ? data.time.format("h:mm a") : data?.time));
        let scheduled_at = time
        return {
            data: {
                ...data,
                scheduled_at,
                time
            }
        }
    }


    useEffect(() => {
        if ((id && (state?.isFetch || state == undefined))) {
            dispatch(getOnePreviewEvent(id))
        }

        return () => dispatch(removePreviewDetails(null))
    }, [id, state])

    const onSubmit = (data) => {
    // const uniqueValues = new Set(data.attedees.map(s => s.email.trim()));
    // if (uniqueValues.size < data.attedees.length) {
    //   return errorHandler("Please use unique email addresses")
    // }
        dispatch(completePreviewEvent(id, structureBody(data)))
    }


    

    return (
        <PreviewEventForm
            formSubmittedCallback={onSubmit}
            elements={defaultForm}
            isEdit={true}
            isView={(state?.isFetch || feedbackData?.status == "completed") || false}
            modalUpdate={true}
        />
    );
}

export default PreviewEventContainer;