import React from 'react';
import { reduxForm, change, } from 'redux-form';
import { connect, useDispatch } from 'react-redux';
import { Input } from '../UI/InputElement/InputElement';
import { convertQuotesReportsToCsvType, CustomNoDataComponent, trimString } from '../../../utility/utility';
import { inspectionReportsValidator as validate } from '../../../utility/validator/validator';
import { FaArrowDown, FaArrowUp } from "react-icons/fa";
import ReactTable from 'react-table-v6';
import 'react-table-v6/react-table.css';
import '../../../styles/custom.css';


let InspectionReports = (props) => { 
    const dispatch = useDispatch();

    let allAccountIds = [];
    let accounts = [];

    if (props.formValues.company_ids && props.formValues.company_ids.length > 0) {
        props.formValues.company_ids.map(c => {
            if (c.value.length > 1) {
                c.value.forEach(cid => {
                    let index = props.companyDropdownList.findIndex(com => com.id === cid);
                    if (index >= 0) {
                        props.companyDropdownList[index].accounts_associated_with_company.map(account => {
                            allAccountIds.push(account.id);
                            accounts.push({
                                value: account.id,
                                label: account.name
                            })
                        })
                    }
                })
            } else {
                let index = props.companyDropdownList.findIndex(com => com.id === c.value);
                if (index >= 0) {
                    props.companyDropdownList[index].accounts_associated_with_company.map(account => {
                        allAccountIds.push(account.id);
                        accounts.push({
                            value: account.id,
                            label: account.name
                        })
                    })
                }
            }
        })
    } else {
        accounts = props.accountsList && props.accountsList.map(account => {
            allAccountIds.push(account.id)
            return {
                value: account.id,
                label: account.name
            }
        });
    }

    const groupedAccountOptions = [
        {
            label: 'All Jobs',
            options: [{
                value: allAccountIds,
                label: 'All Jobs'
            }]
        },
        {
            label: 'Job-wise',
            options: accounts
        }
    ]

    const noAccountOptionsMessage = (a, b) => {
        return 'Job not found';
    }

    let allCompanyIds = [];
    const companyDropdownList = props.companyDropdownList && props.companyDropdownList.map(c => {
        allCompanyIds.push(c.id);
        return {
            value: c.id,
            label: c.name
        }
    })

    const groupedCompanyOptions = [
        {
            label: 'All Customers',
            options: [{
                value: allCompanyIds,
                label: 'All Customers'
            }]
        },
        {
            label: 'Customer-wise',
            options: companyDropdownList
        }
    ]

    const noCompanyOptionsMessage = () => {
        return 'Customer not found';
    }

    const onAccountChange = (e) => {

        const index = e && e.findIndex(c => c.label === 'All Jobs');
        if (index === 0 && e.length > 1) {
            e = e.splice(0, 1);
        } else if (index > 0 && e.length > 1) {
            e = e.splice(0, e.length - 1);
        }
    }

    const onCompanyChange = (e) => {

        const index = e && e.findIndex(c => c.label === 'All Customers');
        if (index === 0 && e.length > 1) {
            e = e.splice(0, 1);
        } else if (index > 0 && e.length > 1) {
            e = e.splice(0, e.length - 1);
        }
        dispatch(change('reportsForm', `account_ids`, []))
    }

    const { handleSubmit, pristine, reset, submitting, error } = props;

    const data1 = props.inspectionReports && props.inspectionReports.map(ir => {
        return {
            // requestor: ir.user,
            // company: ir.company_name,
            account: ir.account_name,
            recentInspections: ir.last_thirty_days_inspections,
            totalInspectionCount: ir.total_inspections_count,
            avgInspectionScore: ir.avg_inspection_score_of_each_inspector,

            // avg_inspection_score: ir.avg_inspection_score_of_each_inspector
        }
    })

    const columns1 = [
        // {
        //     Header: <><span>Requestor</span><FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
        //     accessor: 'requestor', // String-based value accessors!,
        //     width: 105,
        //     show: props.inspectionReports && props.inspectionReports.length !== 0,
        // },
        // {
        //     Header: <><span>Customer</span><FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
        //     accessor: 'company', // String-based value accessors!,
        //     width: 94,
        //     show: props.inspectionReports && props.inspectionReports.length !== 0,
        // },
        {
            Header: <><span>Job</span><FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
            accessor: 'account', // String-based value accessors!,
            width: 266,
            show: props.inspectionReports && props.inspectionReports.length !== 0,
        }, {
            Header: <><span>Recent Performed Inspections (completed)</span><FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
            accessor: 'recentInspections', // String-based value accessors!,
            width: 348,
            show: props.inspectionReports && props.inspectionReports.length !== 0,
        },
        {
            Header: <><span>Total Inspection Count</span><FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
            accessor: 'totalInspectionCount', // String-based value accessors!,
            width: 195,
            show: props.inspectionReports && props.inspectionReports.length !== 0,
        },
        {
            Header: <><span>Avg Inspection Score</span><FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
            accessor: 'avgInspectionScore', // String-based value accessors!,
            // width: 169,
            show: props.inspectionReports && props.inspectionReports.length !== 0,
        },
        //  {
        //     Header: <><span>Avg Inspection score</span><FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
        //     accessor: 'avg_inspection_score', // String-based value accessors!,
        //     // width: 100,
        //     show: props.inspectionReports && props.inspectionReports.length !== 0,
        // }
    ]

    return (

        <div className="container-fluid">
                <button className="csv_btn btn_danger" onClick={() => props.onReportExportClicked()} >Export CSV</button>
            <div className="select_filter_block">
                <h3 className="h3_title">Select Filters</h3>
                <form onSubmit={handleSubmit}>
                    <div className="tile">
                        <ol className="activity-feed">
                            {/* <li className="feed-item">
                                <div className="filter_select">
                                    <Input name="company_ids" onChange={onCompanyChange} isMulti={true} type="inputGroupSelect" className="multi_Select" noOptionsMessage={noCompanyOptionsMessage} options={groupedCompanyOptions} placeholder="CUSTOMERS" />
                                </div>
                            </li> */}

                            <li className="feed-item">
                                <div className="filter_select">
                                    <Input name="account_ids" onChange={onAccountChange} isMulti={true} type="inputGroupSelect" className="multi_Select" noOptionsMessage={noAccountOptionsMessage} options={groupedAccountOptions} placeholder="JOBS" />
                                </div>
                            </li>
                            {/* <li className="feed-item">
                            <div className="form-group">
                                <label className="form_title cst_mt">Include Tags</label>
                                <Input type="radio" labelClass="custom-control-label" forLable={"all"} value="all" label={"All"} className="custom-control-input input-modifier" id="all" name="tag_opportunity" />
                                <Input type="radio" labelClass="custom-control-label" forLable={"yes"} value="true" label={"Yes"} className="custom-control-input input-modifier" id="yes" name="tag_opportunity" />
                                <Input type="radio" labelClass="custom-control-label" forLable={"no"} value="false" label={"No"} className="custom-control-input input-modifier" id="no" name="tag_opportunity" />
                            </div>
                        </li>
                        <li className="feed-item">
                            <div className="form-group">
                                <label className="form_title cst_mt">Is Active</label>
                                <Input type="radio" labelClass="custom-control-label" forLable={"activeAll"} value="all" label={"All"} className="custom-control-input input-modifier" id="activeAll" name="active_status" />
                                <Input type="radio" labelClass="custom-control-label" forLable={"activeYes"} value="true" label={"yes"} className="custom-control-input input-modifier" id="activeYes" name="active_status" />
                                <Input type="radio" labelClass="custom-control-label" forLable={"activeNo"} value="false" label={"No"} className="custom-control-input input-modifier" id="activeNo" name="active_status" />
                            </div>
                        </li> */}
                            <li className="feed-item">
                                <form className="form-inline">
                                    <label className="form_title ">Date Range</label>
                                    <div className="input-group cst_input_group">
                                        <label>
                                            <Input name="fromDate" maxDate={props.formValues.toDate ? props.formValues.toDate : new Date()} dateFormat="MM-dd-yyyy" placeholder="From Date" datePickerType={"react-datepicker"} type="date" className="form-control input-modifier" />
                                            <div class="input-group-append cst_group_append">
                                                <span class="input-group-text" id="basic-addon2"><i class="fa fa-calendar"></i></span>
                                            </div>
                                        </label>
                                        {/* <Input name="fromDate"
                                        maxDate={props.formValues.toDate ? props.formValues.toDate : null}
                                        dateFormat="MM-dd-yyyy" placeholder="From Date" datePickerType={"react-datepicker"} type="date" className="form-control" /> */}
                                        {/* <div className="input-group-append">
                                        <span className="input-group-text" id="basic-addon2"><img src="/images/icons/calendar.png" alt="Calendar Icon" /></span>
                                    </div> */}
                                    </div>
                                    <span className="des">-</span>
                                    <div className="input-group cst_input_group">
                                        <label>
                                            <Input name="toDate" minDate={props.formValues.fromDate ? props.formValues.fromDate : null} maxDate={new Date()} dateFormat="MM-dd-yyyy" placeholder="To Date" datePickerType={"react-datepicker"} type="date" className="form-control input-modifier" />
                                            <div class="input-group-append cst_group_append">
                                                <span class="input-group-text" id="basic-addon2"><i className="fa fa-calendar"></i></span>
                                            </div>
                                            {/* <Input name="toDate"
                                            minDate={props.formValues.fromDate ? props.formValues.fromDate : null}
                                            dateFormat="MM-dd-yyyy" placeholder="To Date" datePickerType={"react-datepicker"} type="date" className="form-control" />
                                        <div className="input-group-append">
                                            <span className="input-group-text" id="basic-addon2"><img src="/images/icons/calendar.png" alt="Calendar Icon" /></span>
                                        </div> */}
                                        </label>
                                    </div>
                                </form>
                            </li>
                            <li className="feed-item">
                                <div className="btn_block">
                                    <button type="submit" className={props.isInspectionReportsLoading ? "btn btn_danger cst_btn btn-wait" : 'btn btn_danger cst_btn'}>
                                        {props.isInspectionReportsLoading ? '' : 'Submit'}</button>
                                </div>
                            </li>
                        </ol>
                        <span style={{ color: '#862937' }}>{props.inspectionReportsMessage}</span>
                        {/* {props.inspectionReports && props.inspectionReports.length > 0 ?
                        <div className="tile_ft">
                            <CSVLink filename="QuotesReport.csv" className="btn btn_danger cst_btn" data={convertQuotesReportsToCsvType(props.inspectionReports)}>Download CSV</CSVLink>
                        </div>
                        : null} */}
                    </div>
                </form>

                {props.inspectionReports ?
                    <>
                        <div className="row">
                        {props.formValues.account_ids && props.formValues.account_ids.length > 1 ? <> 
                            <div className="col-6 col-sm-6 col-lg-6 col-xl-6" >
                                <div className="grid_card tile">
                                    <h4 className="chart_title">{props.lowest_performing_account_name ? props.lowest_performing_account_name : ''}</h4>
                                    <p className="cst_para">Lowest Performing Job</p>
                                </div>
                            </div>
                             </> : ''}
                            <div className="col-6 col-sm-6 col-lg-6 col-xl-6" >
                                <div className="grid_card tile">
                                    <h4 className="chart_title">{props.overall_inspection_score ? props.overall_inspection_score : 0}</h4>
                                    <p className="cst_para">Overall Avg Inspection Score</p>
                                </div>
                            </div>
                        </div>
                        <div className="quotes_table_content table-responsive">
                            <div className="table quotes_table user_table_contnet user_react_table">
                                <ReactTable
                                    data={data1}
                                    // loading={props.isLoading}
                                    defaultPageSize={10}
                                    minRows={0}
                                    // onPageSizeChange={props.onUserPageSizeChange}
                                    showPaginationTop={true}
                                    showPagination={props.inspectionReports.length !== 0}
                                    columns={columns1}
                                    NoDataComponent={() => CustomNoDataComponent(props.isInspectionReportsLoading, 'No Data Found')}
                                    getTheadThProps={(state, rowInfo, column, instance) => {
                                        return {
                                            style: {
                                                borderTop: 'none',
                                                borderBottom: 'none',
                                                borderRight: 'none',
                                                textAlign: 'center',
                                                color: '#566975',
                                                fontSize: '14px',
                                                fontWeight: '600',
                                                whiteSpace: 'nowrap',
                                                float: 'left',
                                            }
                                        }
                                    }
                                    }
                                    getTrProps={(state, rowInfo, column, instance) => {
                                        return {
                                            className: 'react-table-tr-element',
                                            style: {
                                                border: '1px solid #c7cdd1',
                                                transition: '.3s ease-in-out',
                                                width: '100%',
                                                marginTop: '5px',
                                                float: 'left'
                                            }
                                        }

                                    }}
                                    getTdProps={(state, rowInfo, column, instance) => {

                                        if (column.id == 'image') {
                                            return {
                                                style: {
                                                    marginTop: '0px'
                                                }
                                            }
                                        } else {
                                            return {
                                                className: 'react-td-element',
                                            }
                                        }
                                    }
                                    }
                                    getProps={() => {
                                        return {
                                            style: {
                                                border: 'none'
                                            }
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    </>
                    : null}
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return { initialValues: { tag_opportunity: 'all', active_status: 'all' } }
}

InspectionReports = reduxForm({
    form: 'reportsForm',
    validate,
    enableReinitialize: true
})(InspectionReports);

InspectionReports = connect(mapStateToProps)(InspectionReports)
export default InspectionReports;