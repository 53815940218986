import React, { Component } from 'react';
import ChangePassword from '../../component/updateProfile/changePassword';
import * as actions from '../../../redux/actions/index';
import { connect } from 'react-redux';
import { reset } from 'redux-form';

class ChangePasswordContainer extends Component {

    changePassword = (user) => {
        this.props.changePassword(user);
    }

    componentWillUnmount() {
        // this.props.reset('changePasswordForm');
    }

    render() {

        return (
            <ChangePassword
                onSubmit={this.changePassword}
                closeProfileModel={this.props.closeProfileModel}
                isLoading={this.props.isLoading}
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        isLoading: state.authReducer.isLoading
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        // closeProfileModel: () => dispatch(actions.closeProfileModel),
        reset: (changePasswordForm) => dispatch(reset(changePasswordForm)),
        changePassword: (user) => dispatch(actions.changePassword(user)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangePasswordContainer);