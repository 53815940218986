import React, { Component } from 'react';
import AdminDashbord from '../../component/Dashboards/adminDashbord';
import { currentData, userRoles, routes } from '../../../utility/constants/constants';
import RequestorDashbord from '../../component/Dashboards/requestorDashbord';
import SuperAdminDashbord from '../../component/Dashboards/superAdminDashbord';
import ClientDashbord from '../../component/Dashboards/clientDashboard';
import { GetFormatedDate, getWeekStartAndEndDay, getNextMonthDate, getPreviousMonthDate, getNextYearDate, getPreviousYearDate, get_next_week_start, get_previous_week_start, getMonthStartAndEndDay, GetFormatedDateToStartUTC } from '../../../utility/utility';
import * as actions from '../../../redux/actions/index';
import { connect } from 'react-redux';
import ModeModal from '../../component/updateProfile/modeModal';
import { getUser } from "../../../utility/utility";
import $ from 'jquery';
import moment from 'moment';
const cloneDeep = require('clone-deep');

class DashbordContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isWeek: true,
            isMonth: false,
            isYear: false,
            currentDate: this.props.currentDate,
            isPDFView: false
            // currentDate: new Date(),
            // currentDate: new Date(),
            // currentDate: new Date()
        }
    }

    componentDidMount() {

        // if (!this.props.dashboardData) {
        // if (this.props.user && this.props.user.role === "client" && this.props.user.accounts && this.props.user.accounts.length) this.props.getAllUpcomingSchedule({ page: 1, size: 10, account_id: this.props.user.accounts[0].id })
        let user = getUser();
        
        let DataType = null;
        if (this.props.isWeek) {
            DataType = currentData.WEEK
        } else if (this.props.isMonth) {
            DataType = currentData.MONTH
        } else if (this.props.isYear) {
            DataType = currentData.YEAR
        }
        if (this.props.user.role === userRoles.CLIENT) {
            this.props.getClientDashboardData(DataType, GetFormatedDateToStartUTC(GetFormatedDate(this.props.currentDate)))
            .then(r => {
                //to check if edit_profile in the route is present and is true which is being sent from mail. Eg: https://staging-node.pegdesk.com/dashboard?edit_profile=true
                let openProfile = new URLSearchParams(this.props.history.location.search, { ignoreQueryPrefix: true }).get('edit_profile');
                if (openProfile === "true") {
                    let ele = document.getElementById('edit_button_click')
                    ele.click()
                }
            })
            this.props.getLogbookHistoryDashboard();
        } else {
            const user = getUser();
            
            if (user.mode) {
            this.props.getBrmDashboard()
            this.props.getInspectionDashboardData(DataType, GetFormatedDateToStartUTC(GetFormatedDate(this.props.currentDate))).then(r=> {
                 //to check if edit_profile in the route is present and is true which is being sent from mail. Eg: https://staging-node.pegdesk.com/dashboard?edit_profile=true
                let openProfile = new URLSearchParams(this.props.history.location.search, { ignoreQueryPrefix: true }).get('edit_profile');
                if (openProfile === "true") {
                    $('#edit_button_click').click();
                    let ele = document.getElementById('edit_button_click')
                    ele.click()
                }
            })
            this.props.getTicketDashboardData(DataType, GetFormatedDateToStartUTC(GetFormatedDate(this.props.currentDate))).then(r=> {
                //to check if edit_profile in the route is present and is true which is being sent from mail. Eg: https://staging-node.pegdesk.com/dashboard?edit_profile=true
               let openProfile = new URLSearchParams(this.props.history.location.search, { ignoreQueryPrefix: true }).get('edit_profile');
               if (openProfile === "true") {
                   $('#edit_button_click').click();
                   let ele = document.getElementById('edit_button_click')
                   ele.click()
               }
           })
           this.props.getQuoteDashboardData(DataType, GetFormatedDateToStartUTC(GetFormatedDate(this.props.currentDate))).then(r=> {
                //to check if edit_profile in the route is present and is true which is being sent from mail. Eg: https://staging-node.pegdesk.com/dashboard?edit_profile=true
                let openProfile = new URLSearchParams(this.props.history.location.search, { ignoreQueryPrefix: true }).get('edit_profile');
                if (openProfile === "true") {
                    $('#edit_button_click').click();
                    let ele = document.getElementById('edit_button_click')
                    ele.click()
                }
            })
            this.props.getLogbookDashboardData(DataType, GetFormatedDateToStartUTC(GetFormatedDate(this.props.currentDate)))
            // .then(r=> {
            //     //to check if edit_profile in the route is present and is true which is being sent from mail. Eg: https://staging-node.pegdesk.com/dashboard?edit_profile=true
            //     let openProfile = new URLSearchParams(this.props.history.location.search, { ignoreQueryPrefix: true }).get('edit_profile');
            //     if (openProfile === "true") {
            //         $('#edit_button_click').click();
            //         let ele = document.getElementById('edit_button_click')
            //         ele.click()
            //     }
            // })

        }
    }
    // var todayDate = new Date();
    //             // var currentDate = moment(todayDate);
    //             let startAndEndDate = getWeekStartAndEndDay(todayDate);
    //             startAndEndDate.firstday = moment(startAndEndDate.firstday).utc().toISOString();
    //            startAndEndDate.lastday = moment(startAndEndDate.lastday).utc().toISOString();
    //            this.props.getScheduleEventDashboard(startAndEndDate.firstday, startAndEndDate.lastday);
            
             //to check if edit_profile in the route is present and is true which is being sent from mail. Eg: https://staging-node.pegdesk.com?edit_profile=true
        // let openProfile = new URLSearchParams(this.props.history.location.search, { ignoreQueryPrefix: true }).get('edit_profile');
        // if (openProfile === "true") {
        //     this.props.onProfileClick();
        //     $('#edit_button_click').click();
        //     // $('#editProfile').modal({
        //     //     show: true,
        //     //     backdrop: false
        //     // })
        // }
        // }

        // const obj = document.getElementById('chartId');
        //  
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.user.selected_mode !== this.props.user.selected_mode) {
            // some condition to render new data
        }

        // if(prevProps.currentDate !== this.props.currentDate) {
        //     this.setState({
        //         currentDate: this.props.currentDate
        //     })
        // }
        const emptyChartObj = document.getElementsByClassName("empty-graph_heading");
        let cahrtjsHeight = null;
        if (this.props.user.role === userRoles.CLIENT) {

            if (this.props.dashboardData) {
                if (this.props.dashboardData.inspection_count > 0) {
                    cahrtjsHeight = document.getElementById('inspectionsPerformedId') ? document.getElementById('inspectionsPerformedId').clientHeight : '';
                } else if (this.props.dashboardData.ticket_count > 0) {
                    cahrtjsHeight = document.getElementById('ticketsCreatedId') ? document.getElementById('ticketsCreatedId').clientHeight : '';
                }
                if (this.props.dashboardData.inspection_count > 0) {
                    cahrtjsHeight = document.getElementById('inspectionsPerformedId') ? document.getElementById('inspectionsPerformedId').clientHeight : '';
                } else if (this.props.dashboardData.ticket_count > 0) {
                    cahrtjsHeight = document.getElementById('ticketsCreatedId') ? document.getElementById('ticketsCreatedId').clientHeight : '';
                }
            } else {
                cahrtjsHeight = document.getElementById('inspectionsPerformedId') ? document.getElementById('inspectionsPerformedId').clientHeight : '';
            }
        } else if (this.props.user.role === userRoles.REQUESTOR) {
            if (this.props.inspectionDashboardData && this.props.ticketDashboardData && this.props.quoteDashboardData) {
                if (this.props.inspectionDashboardData.inspection_count > 0) {
                    cahrtjsHeight = document.getElementById('inspectionsPerformedId') ? document.getElementById('inspectionsPerformedId').clientHeight : '';
                } else if (this.props.ticketDashboardData.ticket_count > 0) {
                    cahrtjsHeight = document.getElementById('ticketsCreatedId') ? document.getElementById('ticketsCreatedId').clientHeight : '';
                }
                if (this.props.quoteDashboardData.quotes_created > 0) {
                    cahrtjsHeight = document.getElementById('quotesCreatedId') ? document.getElementById('quotesCreatedId').clientHeight : '';
                } else if (this.props.quoteDashboardData.quotes_sent_to_clients > 0) {
                    cahrtjsHeight = document.getElementById('quotesSentToClientsId') ? document.getElementById('quotesSentToClientsId').clientHeight : '';
                } else if (this.props.quoteDashboardData.quotes_approved_by_clients > 0) {
                    cahrtjsHeight = document.getElementById('quotesApprovedByClientsId') ? document.getElementById('quotesApprovedByClientsId').clientHeight : '';
                } else if (this.props.quoteDashboardData.quotes_not_tagged > 0) {
                    cahrtjsHeight = document.getElementById('quotesNotTagged') ? document.getElementById('quotesNotTagged').clientHeight : '';
                }
            } else {
                cahrtjsHeight = document.getElementById('quotesCreatedId') ? document.getElementById('quotesCreatedId').clientHeight : '';
            }
        } else if (this.props.user.role === userRoles.SUPER_ADMIN) {
            if (this.props.inspectionDashboardData && this.props.ticketDashboardData && this.props.quoteDashboardData) {
                if (this.props.inspectionDashboardData.inspection_count > 0) {
                    cahrtjsHeight = document.getElementById('inspectionsPerformedId') ? document.getElementById('inspectionsPerformedId').clientHeight : '';
                } else if (this.props.ticketDashboardData.ticket_count > 0) {
                    cahrtjsHeight = document.getElementById('ticketsCreatedId') ? document.getElementById('ticketsCreatedId').clientHeight : '';
                } else if (this.props.quoteDashboardData.top_ten_quotes_approved_by_admin.length > 0 && this.props.quoteDashboardData.top_ten_quotes_approved_by_admin[0].quotes_count > 0) {
                    cahrtjsHeight = document.getElementById('quotesApprovedByAdmin') ? document.getElementById('quotesApprovedByAdmin').clientHeight : '';
                } else if (this.props.quoteDashboardData.top_ten_sales_approved_by_admin.length > 0 && this.props.quoteDashboardData.top_ten_sales_approved_by_admin[0].total_sale !== '') {
                    cahrtjsHeight = document.getElementById('salesApprovedByAdmin') ? document.getElementById('salesApprovedByAdmin').clientHeight : '';
                } else if (this.props.quoteDashboardData.top_ten_quotes_approved_by_client.length > 0 && this.props.quoteDashboardData.top_ten_quotes_approved_by_client[0].quotes_count > 0) {
                    cahrtjsHeight = document.getElementById('quotesApprovedByClient') ? document.getElementById('quotesApprovedByClient').clientHeight : '';
                } else if (this.props.quoteDashboardData.top_ten_sales_approved_by_client.length > 0 && this.props.quoteDashboardData.top_ten_sales_approved_by_client[0].total_sale !== '') {
                    cahrtjsHeight = document.getElementById('salesApprovedByClient') ? document.getElementById('salesApprovedByClient').clientHeight : '';
                }
            } else {
                cahrtjsHeight = document.getElementById('quotesApprovedByAdmin') ? document.getElementById('quotesApprovedByAdmin').clientHeight : '';
            }
        } else if (this.props.user.role === userRoles.ADMIN) {
            if (this.props.inspectionDashboardData && this.props.ticketDashboardData && this.props.quoteDashboardData) {
                if (this.props.inspectionDashboardData.inspection_count > 0) {
                    cahrtjsHeight = document.getElementById('inspectionsPerformedId') ? document.getElementById('inspectionsPerformedId').clientHeight : '';
                } else if (this.props.ticketDashboardData.ticket_count > 0) {
                    cahrtjsHeight = document.getElementById('ticketsCreatedId') ? document.getElementById('ticketsCreatedId').clientHeight : '';
                }
                if (this.props.quoteDashboardData.top_ten_quotes_approved_by_admin.length > 0 && this.props.quoteDashboardData.top_ten_quotes_approved_by_admin[0].quotes_count > 0) {
                    cahrtjsHeight = document.getElementById('quotesApprovedByAdminId') ? document.getElementById('quotesApprovedByAdminId').clientHeight : '';
                } else if (this.props.quoteDashboardData.top_ten_sales_approved_by_admin.length > 0 && this.props.quoteDashboardData.top_ten_sales_approved_by_admin[0].total_sale !== '') {

                    cahrtjsHeight = document.getElementById('salesApprovedByAdminId') ? document.getElementById('salesApprovedByAdminId').clientHeight : '';
                } else if (this.props.quoteDashboardData.top_ten_quotes_approved_by_client.length > 0 && this.props.quoteDashboardData.top_ten_quotes_approved_by_client[0].quotes_count > 0) {

                    cahrtjsHeight = document.getElementById('quotesApprovedByClientId') ? document.getElementById('quotesApprovedByClientId').clientHeight : '';
                } else if (this.props.quoteDashboardData.top_ten_sales_approved_by_client.length > 0 && this.props.quoteDashboardData.top_ten_sales_approved_by_client[0].total_sale !== '') {

                    cahrtjsHeight = document.getElementById('salesApprovedByClientId') ? document.getElementById('salesApprovedByClientId').clientHeight : '';
                }
            } else {
                cahrtjsHeight = document.getElementById('quotesApprovedByAdminId') ? document.getElementById('quotesApprovedByAdminId').clientHeight : '';
            }
        }
        else {
            cahrtjsHeight = document.getElementById('chartId') ? document.getElementById('chartId').clientHeight : '';
        }
        if (emptyChartObj.length !== 0) {
            for (var i = 0; i < emptyChartObj.length; i++) {

                if (cahrtjsHeight === "") {
                    emptyChartObj[i].style.height = `400px`
                } else {
                    emptyChartObj[i].style.height = `${cahrtjsHeight}px`
                }

            }
        }

    }

    viewModeClicked = (viewMode) => {
        if (viewMode === currentData.WEEK) {
            // this.setState({
            //     isWeek: true,
            //     isMonth: false,
            //     isYear: false
            // });
            this.props.updateWeekMonthYear(true, false, false);
            if (this.props.user.role === userRoles.CLIENT) {
                this.props.getClientDashboardData(viewMode, GetFormatedDateToStartUTC(GetFormatedDate(this.props.currentDate)));
            } else { 
                this.props.getInspectionDashboardData(viewMode, GetFormatedDateToStartUTC(GetFormatedDate(this.props.currentDate)));
                this.props.getTicketDashboardData(viewMode, GetFormatedDateToStartUTC(GetFormatedDate(this.props.currentDate)));
                this.props.getQuoteDashboardData(viewMode, GetFormatedDateToStartUTC(GetFormatedDate(this.props.currentDate)));
                // var todayDate = new Date();
                // // var currentDate = moment(todayDate);
                // let startAndEndDate = getWeekStartAndEndDay(todayDate);
                // startAndEndDate.firstday = moment(startAndEndDate.firstday)
                // startAndEndDate.lastday = moment(startAndEndDate.lastday)
                this.props.getScheduleEventDashboard(viewMode, GetFormatedDate(this.props.currentDate), 10, 1);
                this.props.getLogbookDashboardData(viewMode, GetFormatedDateToStartUTC(GetFormatedDate(this.props.currentDate)));
            }

        } else if (viewMode === currentData.MONTH) {

            // this.setState({
            //     isWeek: false,
            //     isMonth: true,
            //     isYear: false
            // });
            this.props.updateWeekMonthYear(false, true, false)
            if (this.props.user.role === userRoles.CLIENT) {
                this.props.getClientDashboardData(viewMode, GetFormatedDateToStartUTC(GetFormatedDate(this.props.currentDate)));
            } else { 
                this.props.getInspectionDashboardData(viewMode, GetFormatedDateToStartUTC(GetFormatedDate(this.props.currentDate)));
                this.props.getTicketDashboardData(viewMode, GetFormatedDateToStartUTC(GetFormatedDate(this.props.currentDate)));
                this.props.getQuoteDashboardData(viewMode, GetFormatedDateToStartUTC(GetFormatedDate(this.props.currentDate)));
            //     var todayDate = new Date();
            //     let startAndEndDate = new Date();
            //     startAndEndDate.firstday = moment(startAndEndDate.firstday).startOf('month');
            //    startAndEndDate.lastday = moment(startAndEndDate.lastday).endOf("month");
               this.props.getScheduleEventDashboard(viewMode, GetFormatedDate(this.props.currentDate), 10, 1);
          
                this.props.getLogbookDashboardData(viewMode, GetFormatedDateToStartUTC(GetFormatedDate(this.props.currentDate)));
            }
        } else if (viewMode === currentData.YEAR) {

            // this.setState({
            //     isWeek: false,
            //     isMonth: false,
            //     isYear: true
            // });
            this.props.updateWeekMonthYear(false, false, true)

            if (this.props.user.role === userRoles.CLIENT) {
                this.props.getClientDashboardData(viewMode, GetFormatedDateToStartUTC(GetFormatedDate(this.props.currentDate)));
            } else {
                this.props.getInspectionDashboardData(viewMode, GetFormatedDateToStartUTC(GetFormatedDate(this.props.currentDate)));
                this.props.getTicketDashboardData(viewMode, GetFormatedDateToStartUTC(GetFormatedDate(this.props.currentDate)));
                this.props.getQuoteDashboardData(viewMode, GetFormatedDateToStartUTC(GetFormatedDate(this.props.currentDate)));
            //     var todayDate = new Date();
            //     let startAndEndDate = new Date();
            //     startAndEndDate.firstday = moment(startAndEndDate.firstday).startOf('year');
            //    startAndEndDate.lastday = moment(startAndEndDate.lastday).endOf("year");
               this.props.getScheduleEventDashboard(viewMode, GetFormatedDate(this.props.currentDate), 10, 1);
          
                this.props.getLogbookDashboardData(viewMode, GetFormatedDateToStartUTC(GetFormatedDate(this.props.currentDate)));
            }
        }
    }

    nextWeekMonthYearCliked = () => {

        if (this.props.isWeek) {
            const date = get_next_week_start(this.props.currentDate);
            if (this.props.user.role === userRoles.CLIENT) {
                this.props.getClientDashboardData(currentData.WEEK, GetFormatedDateToStartUTC(GetFormatedDate(date)));
            } else {
                this.props.getInspectionDashboardData(currentData.WEEK, GetFormatedDateToStartUTC(GetFormatedDate(date)));
                this.props.getTicketDashboardData(currentData.WEEK, GetFormatedDateToStartUTC(GetFormatedDate(date)));
                this.props.getQuoteDashboardData(currentData.WEEK, GetFormatedDateToStartUTC(GetFormatedDate(date)));
                this.props.getScheduleEventDashboard(currentData.WEEK, GetFormatedDate(date), 10, 1);
                this.props.getLogbookDashboardData(currentData.WEEK, GetFormatedDateToStartUTC(GetFormatedDate(date)));
            }
            // this.setState({
            //     currentDate: date
            // });
            this.props.updateCurrentDate(date);

        } else if (this.props.isMonth) {
            if (this.props.currentDate.getMonth() === 11) {
                const date = getNextYearDate(this.props.currentDate);

                if (this.props.user.role === userRoles.CLIENT) {
                    this.props.getClientDashboardData(currentData.MONTH, GetFormatedDateToStartUTC(GetFormatedDate(date)));
                } else {
                    this.props.getInspectionDashboardData(currentData.MONTH, GetFormatedDateToStartUTC(GetFormatedDate(date)));
                    this.props.getTicketDashboardData(currentData.MONTH, GetFormatedDateToStartUTC(GetFormatedDate(this.props.currentDate)));
                    this.props.getScheduleEventDashboard(currentData.MONTH, GetFormatedDate(date), 10, 1);                   
                    this.props.getQuoteDashboardData(currentData.MONTH, GetFormatedDateToStartUTC(GetFormatedDate(date)));
                    this.props.getLogbookDashboardData(currentData.MONTH, GetFormatedDateToStartUTC(GetFormatedDate(date)));                    
                }
                // this.setState({
                //     currentDate: getNextYearDate(this.props.currentDate)
                // });
                this.props.updateCurrentDate(getNextYearDate(this.props.currentDate));
            } else {
                const date = getNextMonthDate(this.props.currentDate);

                if (this.props.user.role === userRoles.CLIENT) {
                    this.props.getClientDashboardData(currentData.MONTH, GetFormatedDateToStartUTC(GetFormatedDate(date)));
                } else {
                    this.props.getInspectionDashboardData(currentData.MONTH, GetFormatedDateToStartUTC(GetFormatedDate(date)));
                    this.props.getTicketDashboardData(currentData.MONTH, GetFormatedDateToStartUTC(GetFormatedDate(date)));
                    this.props.getQuoteDashboardData(currentData.MONTH, GetFormatedDateToStartUTC(GetFormatedDate(date)));
                    this.props.getScheduleEventDashboard(currentData.MONTH, GetFormatedDate(date), 10, 1); 
                    this.props.getLogbookDashboardData(currentData.MONTH, GetFormatedDateToStartUTC(GetFormatedDate(date))); 
                }
                // this.setState({
                //     currentDate: getNextMonthDate(this.props.currentDate)
                // });
                this.props.updateCurrentDate(getNextMonthDate(this.props.currentDate));
            }
        } else if (this.props.isYear) {

            const date = getNextYearDate(this.props.currentDate);

            if (this.props.user.role === userRoles.CLIENT) {
                this.props.getClientDashboardData(currentData.YEAR, GetFormatedDateToStartUTC(GetFormatedDate(date)));
            } else {
                this.props.getInspectionDashboardData(currentData.YEAR, GetFormatedDateToStartUTC(GetFormatedDate(date)));
                this.props.getTicketDashboardData(currentData.YEAR, GetFormatedDateToStartUTC(GetFormatedDate(date)));
                this.props.getScheduleEventDashboard(currentData.YEAR, GetFormatedDate(date), 10, 1);
                this.props.getQuoteDashboardData(currentData.YEAR, GetFormatedDateToStartUTC(GetFormatedDate(date)));
                this.props.getLogbookDashboardData(currentData.YEAR, GetFormatedDateToStartUTC(GetFormatedDate(date))); 
            }
            // this.setState({
            //     currentDate: getNextYearDate(this.props.currentDate)
            // });
            this.props.updateCurrentDate(getNextYearDate(this.props.currentDate));
        }
    }

    previousWeekMonthYearCliked = () => {
        if (this.props.isWeek) {
            const date = get_previous_week_start(this.props.currentDate);

            if (this.props.user.role === userRoles.CLIENT) {
                this.props.getClientDashboardData(currentData.WEEK, GetFormatedDateToStartUTC(GetFormatedDate(date)));
            } else {
                this.props.getInspectionDashboardData(currentData.WEEK, GetFormatedDateToStartUTC(GetFormatedDate(date)));
                this.props.getTicketDashboardData(currentData.WEEK, GetFormatedDateToStartUTC(GetFormatedDate(date)));
                this.props.getQuoteDashboardData(currentData.WEEK, GetFormatedDateToStartUTC(GetFormatedDate(date)));
                this.props.getScheduleEventDashboard(currentData.WEEK, GetFormatedDate(date), 10, 1); 
                this.props.getLogbookDashboardData(currentData.WEEK, GetFormatedDateToStartUTC(GetFormatedDate(date))); 
            }
            // this.setState({
            //     currentDate: date
            // });
            this.props.updateCurrentDate(date);
            //  
        } else if (this.props.isMonth) {
            if (this.props.currentDate.getMonth() === 0) {
                const date = getPreviousYearDate(this.props.currentDate);
                //  
                if (this.props.user.role === userRoles.CLIENT) {
                    this.props.getClientDashboardData(currentData.MONTH, GetFormatedDateToStartUTC(GetFormatedDate(date)));
                } else {
                    this.props.getInspectionDashboardData(currentData.MONTH, GetFormatedDateToStartUTC(GetFormatedDate(date)));
                    this.props.getTicketDashboardData(currentData.MONTH, GetFormatedDateToStartUTC(GetFormatedDate(date)));
                    this.props.getQuoteDashboardData(currentData.MONTH, GetFormatedDateToStartUTC(GetFormatedDate(date)));
                    this.props.getScheduleEventDashboard(currentData.MONTH, GetFormatedDate(date), 10, 1); 
                    this.props.getLogbookDashboardData(currentData.MONTH, GetFormatedDateToStartUTC(GetFormatedDate(date))); 
                }
                // this.setState({
                //     currentDate: getPreviousYearDate(this.props.currentDate)
                // });
                this.props.updateCurrentDate(getPreviousYearDate(this.props.currentDate));
            } else {
                const date = getPreviousMonthDate(this.props.currentDate);
                //  
                if (this.props.user.role === userRoles.CLIENT) {
                    this.props.getClientDashboardData(currentData.MONTH, GetFormatedDateToStartUTC(GetFormatedDate(date)));
                } else {
                    this.props.getInspectionDashboardData(currentData.MONTH, GetFormatedDateToStartUTC(GetFormatedDate(date)));
                    this.props.getTicketDashboardData(currentData.MONTH, GetFormatedDateToStartUTC(GetFormatedDate(date)));
                    this.props.getQuoteDashboardData(currentData.MONTH, GetFormatedDateToStartUTC(GetFormatedDate(date)));
                    this.props.getScheduleEventDashboard(currentData.MONTH, GetFormatedDate(date), 10, 1); 
                    this.props.getLogbookDashboardData(currentData.MONTH, GetFormatedDateToStartUTC(GetFormatedDate(date))); 
                }
                // this.setState({
                //     currentDate: getPreviousMonthDate(this.props.currentDate)
                // });
                this.props.updateCurrentDate(getPreviousMonthDate(this.props.currentDate));
            }

        } else if (this.props.isYear) {

            const date = getPreviousYearDate(this.props.currentDate);

            if (this.props.user.role === userRoles.CLIENT) {
                this.props.getClientDashboardData(currentData.YEAR, GetFormatedDateToStartUTC(GetFormatedDate(date)));
            } else {
                this.props.getInspectionDashboardData(currentData.YEAR, GetFormatedDateToStartUTC(GetFormatedDate(date)));
                this.props.getTicketDashboardData(currentData.YEAR, GetFormatedDateToStartUTC(GetFormatedDate(date)));
                this.props.getQuoteDashboardData(currentData.YEAR, GetFormatedDateToStartUTC(GetFormatedDate(date)));
                this.props.getScheduleEventDashboard(currentData.YEAR, GetFormatedDate(date), 10, 1); 
                this.props.getLogbookDashboardData(currentData.YEAR, GetFormatedDateToStartUTC(GetFormatedDate(date)));
            }
            // this.setState({
            //     currentDate: getPreviousYearDate(this.props.currentDate)
            // });
            this.props.updateCurrentDate(getPreviousYearDate(this.props.currentDate));
        }
    }

    viewFilterData = (Status, from) => {
        if(from === 'tickets') {
            this.props.history.push({pathname: routes.VIEW_FILTERED_TICKETS})
        } else if (from === 'quotes') {
            this.props.history.push({pathname: routes.VIEW_FILTERED_QUOTES})
        } else if (from === 'inspections') {
            this.props.history.push({pathname: routes.VIEW_FILTERED_INSPECTIONS})
        }  else if (from === 'logbook') {
            this.props.history.push({pathname: routes.LOGBOOK})
        }
        let searchLogbookValues = {};
        if(this.props.isWeek) {
            searchLogbookValues['fromDate']  = new Date(moment(this.props.currentDate).startOf('week').format('MM/DD/YYYY'));
            searchLogbookValues['toDate']  = new Date(moment(this.props.currentDate).endOf('week').format('MM/DD/YYYY'));
            searchLogbookValues['currentDate']  = moment(this.props.currentDate).format('MM/DD/YY');
            searchLogbookValues['status']  = Status
            searchLogbookValues['isWeek']  = this.props.isWeek
        } else if(this.props.isMonth) {
            searchLogbookValues['currentDate']  = moment(this.props.currentDate).format('MM/DD/YY');
            searchLogbookValues['status']  = Status
            searchLogbookValues['isMonth']  = this.props.isMonth
        }
        if(this.props.isYear) {
            searchLogbookValues['currentDate']  = moment(this.props.currentDate).format('MM/DD/YY');
            searchLogbookValues['status']  = Status
            searchLogbookValues['isYear']  = this.props.isYear
        }

        this.props.updateLogbookSearchValues(searchLogbookValues);

        this.props.history.push({
            search: '?newPerformInspection=false',
            state: {
                Status: Status,
                isWeek: this.props.isWeek,
                isMonth: this.props.isMonth,
                isYear: this.props.isYear,
                currentDate: this.props.currentDate,
            }
        });
        // if (this.props.isWeek) {
        //     const startAndEndDate = getWeekStartAndEndDay(this.props.currentDate);
        //     this.props.viewWeeklyFilterQuotes(Status, startAndEndDate.firstday, startAndEndDate.lastday, this.props.currentDate);
        // } else if (this.props.isMonth || this.props.isYear) {
        //     const month = this.props.currentDate.getMonth();
        //     const year = this.props.currentDate.getFullYear();
        //     this.props.viewMonthlyAndYearlyFilterQuotes(Status, month, year, this.props.isMonth, this.props.isYear, this.props.currentDate);
        // }
    }

    viewPdfClicked = () => {
        this.setState({
            isPDFView: true
        })
    }

    previewTicketClicked = (ticket) => {
        this.props.previewTicketClicked(ticket);
    }


    onAreaChange = (accountid) => {
        this.props.searchHistory({page: 1, size: 15, "area_name": accountid.label}, accountid.value);
    }

   

    render() {

        if (this.props.user.role === userRoles.ADMIN) {
            return (<AdminDashbord
                user={this.props.user}
                inspectionDashboardData={this.props.inspectionDashboardData}
                ticketDashboardData={this.props.ticketDashboardData}
                quoteDashboardData={this.props.quoteDashboardData}
                logbookDashboardData={this.props.logbookDashboardData}
                previewTicketClicked={this.previewTicketClicked}
                viewFilterData={this.viewFilterData}
                isWeek={this.props.isWeek}
                isPDFView={this.state.isPDFView}
                viewPdfClicked={this.viewPdfClicked}
                currentDate={cloneDeep(this.props.currentDate)}
                isLoading={this.props.isLoading}
                isMonth={this.props.isMonth}
                viewModeClicked={this.viewModeClicked}
                nextWeekMonthYearCliked={this.nextWeekMonthYearCliked}
                previousWeekMonthYearCliked={this.previousWeekMonthYearCliked}
                getScheduleDashboardData={this.props.getScheduleDashboardData}
                getScheduleEventDashboard={this.props.getScheduleEventDashboard}
                isYear={this.props.isYear}
                brmdashboard = {this.props.brmdashboard}
                />)
        } else if (this.props.user.role === userRoles.SUPER_ADMIN) {

            return (
                <>
            <SuperAdminDashbord
                isWeek={this.props.isWeek}
                modeModal={this.props.modeModal}
                previewTicketClicked={this.previewTicketClicked}
                viewFilterData={this.viewFilterData}
                isMonth={this.props.isMonth}
                viewPdfClicked={this.viewPdfClicked}
                isLoading={this.props.isLoading}
                isPDFView={this.state.isPDFView}
                currentDate={cloneDeep(this.props.currentDate)}
                viewModeClicked={this.viewModeClicked}
                isYear={this.props.isYear}
                nextWeekMonthYearCliked={this.nextWeekMonthYearCliked}
                previousWeekMonthYearCliked={this.previousWeekMonthYearCliked}
                inspectionDashboardData={this.props.inspectionDashboardData}
                ticketDashboardData={this.props.ticketDashboardData}
                quoteDashboardData={this.props.quoteDashboardData} 
                getScheduleDashboardData={this.props.getScheduleDashboardData}
                getScheduleEventDashboard={this.props.getScheduleEventDashboard}
                logbookDashboardData={this.props.logbookDashboardData} 
                />
                </>)

        } else if (this.props.user.role === userRoles.REQUESTOR) {

            return (<RequestorDashbord
                user={this.props.user}
                isWeek={this.props.isWeek}
                previewTicketClicked={this.previewTicketClicked}
                viewFilterData={this.viewFilterData}
                viewPdfClicked={this.viewPdfClicked}
                isMonth={this.props.isMonth}
                isYear={this.props.isYear}
                isPDFView={this.state.isPDFView}
                currentDate={cloneDeep(this.props.currentDate)}
                isLoading={this.props.isLoading}
                viewModeClicked={this.viewModeClicked}
                nextWeekMonthYearCliked={this.nextWeekMonthYearCliked}
                previousWeekMonthYearCliked={this.previousWeekMonthYearCliked}
                inspectionDashboardData={this.props.inspectionDashboardData}
                ticketDashboardData={this.props.ticketDashboardData}             
                getScheduleDashboardData={this.props.getScheduleDashboardData}
                getScheduleEventDashboard={this.props.getScheduleEventDashboard}
                quoteDashboardData={this.props.quoteDashboardData}
                logbookDashboardData={this.props.logbookDashboardData} />)

        } else if (this.props.user.role === userRoles.CLIENT) {
            return (<ClientDashbord
                user={this.props.user}
                isAreaLoading={this.props.isAreaLoading}
                onAreaChange={this.onAreaChange}
                upcomingSchedules={this.props.upcomingSchedules}
                isWeek={this.props.isWeek}
                viewFilterData={this.viewFilterData}
                viewPdfClicked={this.viewPdfClicked}
                previewTicketClicked={this.previewTicketClicked}
                isMonth={this.props.isMonth}
                isYear={this.props.isYear}
                isPDFView={this.state.isPDFView}
                currentDate={cloneDeep(this.props.currentDate)}
                isLoading={this.props.isLoading}
                viewModeClicked={this.viewModeClicked}
                nextWeekMonthYearCliked={this.nextWeekMonthYearCliked}
                previousWeekMonthYearCliked={this.previousWeekMonthYearCliked}
                dashboardData={this.props.dashboardData}
                logbookHistoryDashboard={this.props.logbookHistoryDashboard}
                 />)
        }

    }
}

const mapStateToProps = (state) => {
    return {
        dashboardData: state.adminOrSuperAdminReducer.dashboardData,
        inspectionDashboardData: state.adminOrSuperAdminReducer.inspectionDashboardData,
        ticketDashboardData: state.adminOrSuperAdminReducer.ticketDashboardData,
        quoteDashboardData: state.adminOrSuperAdminReducer.quoteDashboardData,
        logbookDashboardData: state.adminOrSuperAdminReducer.logbookDashboardData,
        logbookHistoryDashboard: state.adminOrSuperAdminReducer.logbookHistoryDashboard,
        upcomingSchedules: state.adminOrSuperAdminReducer.searchHistory,
        isLoading: state.adminOrSuperAdminReducer.isLoading,
        currentDate: state.adminOrSuperAdminReducer.currentDate,
        isWeek: state.adminOrSuperAdminReducer.isWeek,
        isMonth: state.adminOrSuperAdminReducer.isMonth,
        isYear: state.adminOrSuperAdminReducer.isYear,
        isAreaLoading: state.adminOrSuperAdminReducer.isAreaLoading,
        user: state.authReducer.user,
        modeModal: state.authReducer.modeModal,
        getScheduleDashboardData: state.adminOrSuperAdminReducer.getScheduleDashboardData,
        brmdashboard: state.BRM.brmdashboard,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        searchHistory: (params, id) => dispatch(actions.searchHistory(params, id)),
        viewWeeklyFilterQuotes: (status, startDate, endDate, currentDate) => dispatch(actions.viewWeeklyFilterQuotes(status, startDate, endDate, currentDate)),
        viewMonthlyAndYearlyFilterQuotes: (status, month, year, isMonth, isYear, currentDate) => dispatch(actions.viewMonthlyAndYearlyFilterQuotes(status, month, year, isMonth, isYear, currentDate)),
        getClientDashboardData: (DataType, date) => dispatch(actions.getClientDashboardData(DataType, date)),
        getInspectionDashboardData: (DataType, date) => dispatch(actions.getInspectionDashboardData(DataType, date)),
        getTicketDashboardData: (DataType, date) => dispatch(actions.getTicketDashboardData(DataType, date)),
        getQuoteDashboardData: (DataType, date) => dispatch(actions.getQuoteDashboardData(DataType, date)),
        getLogbookDashboardData: (DataType, date) => dispatch(actions.getLogbookDashboardData(DataType, date)),
        getLogbookHistoryDashboard: (DataType, date) => dispatch(actions.getLogbookHistoryDashboard()),
        updateCurrentDate: (date) => dispatch(actions.updateCurrentDate(date)),
        updateWeekMonthYear: (isWeek, isMonth, isYear) => dispatch(actions.updateWeekMonthYear(isWeek, isMonth, isYear)),
        previewTicketClicked: (ticket) => dispatch(actions.previewTicketClicked(ticket)),
        getScheduleEventDashboard: (DataType, date, rows, page, sorted, filtered) => dispatch(actions.getScheduleEventDashboard(DataType, date, rows, page, sorted, filtered)),
        updateLogbookSearchValues: (values) => dispatch(actions.updateLogbookSearchValues(values)),
        getBrmDashboard: (values) => dispatch(actions.getBRMDashBoard(values))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DashbordContainer);