import React from "react";
import { Modal } from 'react-bootstrap';
import ImageSlider from '../ImageSlider/ImageSlider';

const ModalImageGallery = (props) => {

    return (
        <Modal
            // style={{ minWidth: '1200px' }}
            show={props.isImageGallery}
            onHide={() => props.setIsImageGallery(false)}
            onEscapeKeyDown={() => props.setIsImageGallery(false)}
            size="lg"
            className="img_gallery_modal"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <a href="javascript:void(0);" onClick={() => props.setIsImageGallery(false)} class="close_btn" data-dismiss="modal"><i class="fa fa-times-circle"></i></a>
            <Modal.Body>
                <ImageSlider startIndex={props.startIndex} showFullscreenButton={false} showThumbnails={true} autoPlay={false} base64={props.base64} imageUrls={props.imageUrls} />
            </Modal.Body>
        </Modal>
    )
}

export default ModalImageGallery;