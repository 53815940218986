import { auditsActionTypes } from "../../../actions/FormAuditModAction/AuditsAction/AuditsTypes";


export const initialState = {
    dataGenerated: false,
    isLoading: false,

    isPerformanceAuditList: false,
    performanceAuditList: [],
    performanceAuditList_curPage: 0,
    performanceAuditList_total_pages: 1,
    performanceAuditList_no_of_rows: 10,
    total_performanceAuditList_count: '',
    performanceAuditList_searchValues: {},

    performance_audit_questions: [],
    isPerformanceAuditForm: false,
    singlePerformanceAuditData: {},
    isSelfAuditAuditForm: false,
    self_audit_questions: [],
    self_audit_details: null,

    selfAuditList: [],
    selfAuditList_curPage: 0,
    selfAuditList_total_pages: 1,
    selfAuditList_no_of_rows: 10,
    total_selfAuditList_count: '',
    isSelfAuditList: false,
    selfAuditList_searchValues: {},

    performanceAuditEditId : '',
};

const updateObject = (oldState, updatedProps) => {
    return {
        ...oldState,
        ...updatedProps,
    };
};

export const auditsReducer = (state = initialState, action) => {
    switch (action.type) {
        case auditsActionTypes.GET_ALL_PERFORMANCE_AUDIT_PENDING:
            return updateObject(state, {
                isLoading: true,
                isPerformanceAuditList: true,
                performanceAuditList: [],
            });
        case auditsActionTypes.GET_ALL_PERFORMANCE_AUDIT_FULFILLED:
            return updateObject(state, {
                isLoading: false,
                dataGenerated: false,
                isPerformanceAuditList: false,
                performanceAuditList: action.payload.performanceAuditList,
                performanceAuditList_total_pages: action.payload.performanceAuditList_total_pages,
                performanceAuditList_no_of_rows: action.payload.performanceAuditList_no_of_rows,
                performanceAuditList_curPage: action.payload.performanceAuditList_curPage,
                total_performanceAuditList_count: action.payload.total_performanceAuditList_count,
                performanceAuditList_searchValues: action.payload.searchValues,
            });

        case auditsActionTypes.PERFORMANCE_AUDIT_LIST_PAGE_CLICKED:
            return updateObject(state, { performanceAuditList_curPage: action.payload });

        case auditsActionTypes.PERFORMANCE_AUDIT_PAGE_CLICKED:
            return updateObject(state, { performanceAuditList_searchValues: {} });

        case auditsActionTypes.GET_ALL_PERFORMANCE_AUDIT_QUESTIONS_PENDING:
            return updateObject(state, {
                isPerformanceAuditForm: true
            });
        case auditsActionTypes.GET_ALL_PERFORMANCE_AUDIT_QUESTIONS_FULFILLED:
            return updateObject(state, {
                isPerformanceAuditForm: false,
                performance_audit_questions: action.payload.performance_audit_questions
            });

        case auditsActionTypes.GET_ALL_SELF_AUDIT_QUESTIONS_PENDING:
            return updateObject(state, {
                isSelfAuditAuditForm: true
            });
        case auditsActionTypes.GET_ALL_SELF_AUDIT_QUESTIONS_FULFILLED:

            return updateObject(state, {
                isSelfAuditAuditForm: false,
                self_audit_questions: action.payload.self_audit_questions
            });

        case auditsActionTypes.ADD_SELF_AUDIT_PAGE_PENDING:
            return updateObject(state, {
                isLoading: true
            });
        case auditsActionTypes.ADD_SELF_AUDIT_PAGE_FULFILLED:
            return updateObject(state, {
                isLoading: false
            });

        case auditsActionTypes.GET_SINGLE_SELF_AUDIT_PAGE_PENDING:
            return updateObject(state, {
                isSelfAuditAuditForm: true,
            });
        case auditsActionTypes.GET_SINGLE_SELF_AUDIT_PAGE_FULFILLED:

            return updateObject(state, {
                isSelfAuditAuditForm: false,
                self_audit_details: action.payload.self_audit_details
                // self_audit_questions:action.payload.self_audit_details.auditorSections
            });

        case auditsActionTypes.EDIT_SELF_AUDIT_PAGE_PENDING:
            return updateObject(state, {
                isLoading: true,
            });
        case auditsActionTypes.EDIT_SELF_AUDIT_PAGE_FULFILLED:

            return updateObject(state, {
                isLoading: false
                // self_audit_questions:action.payload.self_audit_details.auditorSections
            });




        case auditsActionTypes.GET_ALL_SELF_AUDIT_PAGE_PENDING:
            return updateObject(state, {
                isLoading: true,
                isSelfAuditList: true,
                selfAuditList: [],
            });

        case auditsActionTypes.GET_ALL_SELF_AUDIT_PAGE_FULFILLED:
            return updateObject(state, {
                isLoading: false,
                dataGenerated: false,
                isSelfAuditList: false,
                selfAuditList: action.payload.selfAuditList,
                selfAuditList_total_pages: action.payload.selfAuditList_total_pages,
                selfAuditList_no_of_rows: action.payload.selfAuditList_no_of_rows,
                selfAuditList_curPage: action.payload.selfAuditList_curPage,
                total_selfAuditList_count: action.payload.total_selfAuditList_count,
                selfAuditList_searchValues: action.payload.searchValues,
            });

        case auditsActionTypes.ADD_PERFORMANCE_AUDIT_PENDING:
            return updateObject(state, {
                isLoading: true,
            })

        case auditsActionTypes.ADD_PERFORMANCE_AUDIT_FULFILLED:
            return updateObject(state, {
                isLoading: false,
            })
        case auditsActionTypes.ADD_PERFORMANCE_AUDIT_REJECTED:
            return updateObject(state, {
                isLoading: false,
            })

        case auditsActionTypes.GET_SINGLE_PERFORMANCE_AUDIT_PENDING:
            return updateObject(state, {
                isPerformanceAuditForm: true,
                singlePerformanceAuditData : {},
            });

        case auditsActionTypes.GET_SINGLE_PERFORMANCE_AUDIT_FULFILLED:
            return updateObject(state, {
                isPerformanceAuditForm: false,
                singlePerformanceAuditData: action.payload
            });

        case auditsActionTypes.EDIT_PERFORMANCE_AUDIT_PENDING:
            return updateObject(state, {
                isLoading: true,
            })

        case auditsActionTypes.EDIT_PERFORMANCE_AUDIT_FULFILLED:
            return updateObject(state, {
                isLoading: false,
            })
        case auditsActionTypes.EDIT_PERFORMANCE_AUDIT_REJECTED:
            return updateObject(state, {
                isLoading: false,
            })

        case auditsActionTypes.PERFORMANCE_AUDIT_EDIT_ID:
            return updateObject(state, {
                performanceAuditEditId: action.payload,
            })

        case auditsActionTypes.SELF_AUDIT_LIST_PAGE_CLICKED:
        return updateObject(state, { selfAuditList_curPage: action.payload });

        default:
            return state;
    }
};
