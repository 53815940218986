import React from 'react';
import ReactTable from 'react-table-v6';
import '../../../../styles/custom.css';
import 'react-table-v6/react-table.css';
import { makeFirstLetterCapital, CustomNoDataComponent } from '../../../../utility/utility';
import { FaArrowDown, FaArrowUp } from "react-icons/fa";
import { MagicSpinner, PushSpinner, SwishSpinner, WaveSpinner, ClapSpinner } from 'react-spinners-kit';
import isEmpty from '../../../../utility/IsEmptyValidator';

const renderUserData = (user) => {

    if (isEmpty(user)) {
        return []
    } else {
        return {
            // image: <img style={{ height: '35px', width: '35px', borderRadius: '50px' }} src={user.photo_urls.original ? user.photo_urls.original : "/customImages/user.png"} />,
            contactName: user.user.first_name + ' ' + user.user.last_name,
            // lastName: user.user.last_name,
            // position: user.user.position ? user.user.position.name : '',
            role: makeFirstLetterCapital(user.user.role),
            // region: user.user.region ? user.region.name : '',
            // phonenumber: '810-191-8851',
            email: user.user.email,
            // address: '4909 Murphy Canyon Rd # 200, San Diego, CA 92123',
            // actions: user.user.role === userRoles.SUPER_ADMIN ? null
            //     :
            //     <>
            //         <span data-tip="View User" className="mr_20 account_edit_icn" onClick={() => props.previewUserClicked(user)}><i className="fas fa-eye cst_icon mr-2" aria-hidden="true"></i></span>
            //         <ReactTooltip place="top" type="dark" effect="solid" />
            //         <span className="mr_20 account_edit_icn" onClick={() => props.editUserClicked(user)}><i class="fa fa-pencil" aria-hidden="true"></i>
            //         </span>
            //         <span className="mr_20 account_edit_icn" onClick={() => props.handleClickDeleteModel(user)}><i class="fa fa-trash" aria-hidden="true"></i>
            //         </span>
            //     </>
        }
    }
}


const PreviewUser = (props) => {
    const user = props.user;

    if (isEmpty(user)) {
        return []
    } else {
         
        return (
            <main class="app-content wraper_content edit_inspection">
                <section class="appcontent_Inner">
                    <div class="container">
                        <div class="row">
                            <div class="col-xl-10 offset-xl-1">
                                <div class="userDetails">
                                    <div class="card">
                                        <div class="card-body">
                                            <div class="media">
                                                <div class="profile-img">
                                                    <img src={user.user.photo_urls.medium ? user.user.photo_urls.medium : "/customImages/user.png"} alt="" />
                                                </div>
                                                <div class="media-body media_details">
                                                    <h5 class="media-title">{user.user.first_name + ' ' + user.user.last_name}</h5>
                                                    <h6 class="h6_title">{user.user.role}</h6>
                                                </div>
                                            </div>
                                            <div class="profile-info">
                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <div class="UD_tabs">
                                                            <ul class="nav nav-tabs" id="myTab" role="tablist">
                                                                <li class="nav-item">
                                                                    <a class="nav-link active" id="about-tab" data-toggle="tab" href="#about" role="tab" aria-controls="about" aria-selected="true">About</a>
                                                                </li>
                                                                <li class="nav-item">
                                                                    <a class="nav-link" id="accounts-tab" data-toggle="tab" href="#accounts" role="tab" aria-controls="accounts" aria-selected="false">Job </a>
                                                                </li>
                                                                <li class="nav-item">
                                                                    <a class="nav-link" id="companies-tab" data-toggle="tab" href="#companies" role="tab" aria-controls="companies" aria-selected="false">Companies </a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <div class="tab-content profile-tab" id="myTabContent">
                                                            <div class="tab-pane fade show active" id="about" role="tabpanel" aria-labelledby="about-tab">
                                                                <div class="row">
                                                                    <div class="col-sm-6">
                                                                        <label>Contact Name</label>
                                                                    </div>
                                                                    <div class="col-sm-6">
                                                                        <p>{user.user.first_name + ' ' + user.user.last_name}</p>
                                                                    </div>
                                                                </div>
                                                                <div class="row">
                                                                    <div class="col-sm-6">
                                                                        <label>Position</label>
                                                                    </div>
                                                                    <div class="col-sm-6">
                                                                        <p>{user.user.position ? user.user.position.name : ''}</p>
                                                                    </div>
                                                                </div>
                                                                <div class="row">
                                                                    <div class="col-sm-6">
                                                                        <label>Role</label>
                                                                    </div>
                                                                    <div class="col-sm-6">
                                                                        <p>{makeFirstLetterCapital(user.user.role)}</p>
                                                                    </div>
                                                                </div>
                                                                <div class="row">
                                                                    <div class="col-sm-6">
                                                                        <label>Region</label>
                                                                    </div>
                                                                    <div class="col-sm-6">
                                                                        <p>{user.user.region ? user.user.region.name : ''}</p>
                                                                    </div>
                                                                </div>
                                                                <div class="row">
                                                                    <div class="col-sm-6">
                                                                        <label>Email</label>
                                                                    </div>
                                                                    <div class="col-sm-6">
                                                                        <p>{user.user.email}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="tab-pane fade" id="accounts" role="tabpanel" aria-labelledby="accounts-tab">
                                                                <div class="UD_table table-responsive">
                                                                    <table class="table">
                                                                        <thead>
                                                                            <tr>
                                                                                <th scope="col">Job#</th>
                                                                                <th scope="col">Job Name</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {user.user.accounts ? user.user.accounts.map((a, i) => (
                                                                                <tr>
                                                                                    <td>{a.account_number}</td>
                                                                                    <td>{a.name}</td>
                                                                                </tr>
                                                                            )) : null}
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                            <div class="tab-pane fade" id="companies" role="tabpanel" aria-labelledby="companies-tab">
                                                                <div class="UD_table table-responsive">
                                                                    <table class="table">
                                                                        <thead>
                                                                            <tr>
                                                                                <th scope="col">Company#</th>
                                                                                <th scope="col">Company Name</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {user.user.companies ? user.user.companies.map((c, i) => (
                                                                                <tr>
                                                                                    <td>{c.id}</td>
                                                                                    <td>{c.name}</td>
                                                                                </tr>
                                                                            )) : null}
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                </section>
            </main>
        )
    }
}

export default PreviewUser;