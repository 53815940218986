import React, { useEffect, useState } from 'react';
import TimeCardReviewListTable from './TimeCardReviewListTable';
import { formValueSelector, getFormValues, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Input } from '../../UI/InputElement/InputElement';
import { convertDateToPickerFormat } from '../../../../utility/utility';
import { userRoles } from '../../../../utility/constants/constants';

let TimeCardReviewListLayout = (props) => {

    const { handleSubmit, pristine, reset, submitting, error } = props;

    const statusOptions = [
        {
            value: 'requested',
            label: `Requested`
        },
        {
            value: 'reviewed_by_manager',
            label: 'Manager Approval'
        },
        {
            value: 'approved_by_payroll',
            label: 'Payroll Approval'
        }
    ];

    return (
        <>

            <main className="app-content wraper_content inspection_wraper">
                <div className="tab_header search_filter_cont inspection_tab_content ticket_filter">
                    <form onSubmit={handleSubmit}>
                        <input type="hidden" value="something" />
                        <div className="row">
                            <div className="col-xl-2 col-lg-4 col-md-6 col-sm-6">
                                <Input name="status" dataTest="select_leave_type" type="inputSelect" options={statusOptions} placeholder="Search by Status" />
                            </div>

                            <div className="col-xl-8 col-lg-6 col-sm-3 col-12">
                                <button data-test="filter_go_btn" style={{ minWidth: '90px' }} className={"btn cst_btn btn_danger mr-2  account_btn"} id="submit" type="submit">{"Go"} </button>
                                <button data-test="filter_reset_btn" style={{ minWidth: '90px' }} onClick={props.resetClicked} className={"btn cst_btn btn-outline-secondary account_btn"} id="reset" type="reset">{"Reset"}</button>
                            </div>
                        </div>
                    </form>
                </div>


                <section className="account_sec inspection_tab_content appcontent_Inner">
                    <div className="quotes_table_content accounts_table_contnet table-responsive">
                        <div className="table quotes_table user_react_table">
                            <TimeCardReviewListTable
                                {...props}
                            />
                        </div>
                    </div>
                </section>
            </main>

            <Dialog
                open={props.openFrequentTimeTracker_deleteModal}
                onClose={props.handleFrequentTimeTracker_closeDeleteModal}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title" style={{ fontSize: '13', color: '#06080f' }}>
                    <h3 style={{ color: '#ab385e' }} data-test="modal_delete_title">Are you sure?</h3>
                </DialogTitle>

                <DialogActions>
                    <button data-test="modal_delete" style={{ minHeight: '36px', minWidth: '80px' }} onClick={() => props.deleteTimecardCorrectionCard(props.deleteFrequentTimeTracker_object)} disabled={props.isLoading} className={props.isLoading ? "btn cst_btn btn_danger mr-2 btn-wait" : "btn cst_btn btn_danger mr-2"} id="delete" type="delete">{props.isLoading ? "" : "Delete"}</button>
                    <button data-test="modal_cancel" style={{ minHeight: '36px', minWidth: '80px' }} onClick={props.handleFrequentTimeTracker_closeDeleteModal} className={"btn cst_btn btn-outline-secondary"} id="cancel" type="cancel">Cancel</button>
                </DialogActions>
            </Dialog>

        </>
    );
};

const mapStateToProps = (state) => {
    let status = state.TimeTrackerModReducer.timecardReviewedList_searchValues && state.TimeTrackerModReducer.timecardReviewedList_searchValues.status ? state.TimeTrackerModReducer.timecardReviewedList_searchValues.status : "";

    return { initialValues: { status }, formStates: getFormValues('searchTimecardReviewedListForm')(state) }
}

TimeCardReviewListLayout = reduxForm({
    form: 'searchTimecardReviewedListForm',
    enableReinitialize: true
})(TimeCardReviewListLayout);
TimeCardReviewListLayout = connect(mapStateToProps)(TimeCardReviewListLayout)

export default TimeCardReviewListLayout;