import React, { useState, useEffect } from 'react';
import PegassurePlaybookListTable from './PegAssurePlaybookListTable';
import { reduxForm, getFormValues, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import { Input } from '../../UI/InputElement/InputElement';
import { Dialog, DialogTitle, DialogActions } from '@material-ui/core';
import '../../../../styles/custom.css';
import moment from 'moment';
import PegassurePlaybookPdfForm from '../PegassurePlaybookManageComponent/PegassurePlaybookPdfForm';

let PegassurePlaybookListLayout = (props) => {

    const [openPlaybookUploadModal, setOpenPlaybookUploadModal] = useState(false);
    const { handleSubmit, pristine, reset, submitting, change, error } = props;

    const getPlaybookIdList = (playbookPdfLabelsList) => {
        if (props.playbookPdfLabelsList && props.playbookPdfLabelsList.length > 0) {
            return playbookPdfLabelsList && playbookPdfLabelsList.map((data) => {
                return {
                    value: data.id,
                    label: data.id
                }
            })
        } else {
            return []
        }
    };

    const getPlaybookTitleList = (playbookPdfLabelsList) => {
        if (props.playbookPdfLabelsList && props.playbookPdfLabelsList.length > 0) {
            return playbookPdfLabelsList && playbookPdfLabelsList.map((data) => {
                return {
                    value: data.title,
                    label: data.title
                }
            })
        } else {
            return []
        }
    };

    const [playbookIdList, setPlaybookIdList] = useState(getPlaybookIdList(props.playbookPdfLabelsList));
    const [playbookTitleList, setPlaybookTitleList] = useState(getPlaybookTitleList(props.playbookPdfLabelsList));

    useEffect(() => {
        setPlaybookIdList(getPlaybookIdList(props.playbookPdfLabelsList));
        setPlaybookTitleList(getPlaybookTitleList(props.playbookPdfLabelsList));
    }, [props.playbookPdfLabelsList]);

    return (
        <>
            <main className="app-content wraper_content inspection_wraper">
                <div className="tab_header search_filter_cont inspection_tab_content appcontent_inner user_filter" id="user_filter">

                    <form onSubmit={handleSubmit} >
                        <div className="row mt-2">

                            <div className="col-xl-2 col-lg-4 col-md-6 col-sm-6">
                                <Input name="playbookId" dataTest="select_playbook_id" type="inputSelect" options={playbookIdList} placeholder="Search by Id" />
                            </div>

                            <div className="col-xl-2 col-lg-4 col-md-6 col-sm-6">
                                <Input name="playbookTitle" dataTest="select_playbook_title" type="inputSelect" options={playbookTitleList} placeholder="Search by Name" />
                            </div>

                            <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
                                <div className="frontline_btn">
                                    <button data-test="filter_go_btn" style={{ minWidth: '90px' }} className={"btn cst_btn btn_danger mr-2"} id="submit" type="submit">{"Go"} </button>
                                    <button data-test="filter_reset_btn" style={{ minWidth: '90px' }} onClick={props.resetClicked} className={"btn cst_btn btn-outline-secondary"} id="reset" type="reset">{"Reset"} </button>
                                </div>
                            </div>
                        </div>
                    </form>

                </div>

                <section className="account_sec inspection_tab_content appcontent_Inner">

                    <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px', marginBottom: '20px' }}>
                        <div className="row">
                            <div className="col-xl-6 col-lg-4 col-md-4 col-sm-12">
                                <button type="button" className="btn cst_btn btn_danger " onClick={() => { setOpenPlaybookUploadModal(true); }} data-toggle="modal" data-target="#pegassurePlaybookPdfForm" data-backdrop="static" data-keyboard="false" data-test="import_csv">Upload Playbook PDF</button>
                            </div>
                        </div>
                    </div>

                    <div className="quotes_table_content accounts_table_contnet table-responsive">
                        <div className="table quotes_table user_react_table">
                            <PegassurePlaybookListTable
                                {...props}
                                previewFeedbackClicked={props.previewFeedbackClicked}
                                onSubmit={props.onSearch}
                                resetClicked={props.resetClicked}
                            />
                        </div>
                    </div>

                </section>
            </main>

            {/* Upload Playbook PDF Modal */}
            {openPlaybookUploadModal || props.isPlaybookPdfEdit ? (<PegassurePlaybookPdfForm {...props} />) : null}

            <Dialog
                open={props.openPegPlaybook_deleteModal}
                onClose={props.handlePegassurePlaybook_closeDeleteModal}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title" style={{ fontSize: '13', color: '#06080f' }}>
                    <h3 style={{ color: '#ab385e' }} data-test="modal_delete_title">Are you sure?</h3>
                </DialogTitle>

                <DialogActions>
                    <button data-test="modal_delete" style={{ minHeight: '36px', minWidth: '80px' }} onClick={() => props.deletePegassurePlaybookPdf(props.deletePegPlaybook_object)} disabled={props.isLoading} className={props.isLoading ? "btn cst_btn btn_danger mr-2 btn-wait" : "btn cst_btn btn_danger mr-2"} id="delete" type="delete">{props.isLoading ? "" : "Delete"}</button>
                    <button data-test="modal_cancel" style={{ minHeight: '36px', minWidth: '80px' }} onClick={props.handlePegassurePlaybook_closeDeleteModal} className={"btn cst_btn btn-outline-secondary"} id="cancel" type="cancel">Cancel</button>
                </DialogActions>
            </Dialog>
        </>
    );
}

const mapStateToProps = (state) => {

    let playbookId = state?.PegassurePlaybookReducer?.pegassurePlaybookList_searchValues?.playbookId ? state.PegassurePlaybookReducer.pegassurePlaybookList_searchValues.playbookId : '';
    let playbookTitle = state?.PegassurePlaybookReducer?.pegassurePlaybookList_searchValues?.playbookTitle ? state.PegassurePlaybookReducer.pegassurePlaybookList_searchValues.playbookTitle : '';

    return { initialValues: { playbookId, playbookTitle }, formStates: getFormValues('searchPlaybookPdfList')(state) }

}

PegassurePlaybookListLayout = reduxForm({
    form: 'searchPlaybookPdfList',
    enableReinitialize: true
})(PegassurePlaybookListLayout);

PegassurePlaybookListLayout = connect(mapStateToProps)(PegassurePlaybookListLayout)

export default PegassurePlaybookListLayout;