import axios, { API_VERSION, BASE_URL } from '../config';
import realAxios from 'axios';
import { getUser } from '../utility/utility';
import storage from '../utility/storage';
import jwt_decode from "jwt-decode";
// import  from 'axios';
const queryString = require('query-string');
const pureAxios = require('axios');

const headers = {
    headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
    },
};

export const uploadImageToS3 = (url, arrayBuffer) => {
    return pureAxios.put(url, arrayBuffer);
};

export const updatePreferences = (id, user) => {
    return axios.put(API_VERSION + `users/${id}/client_setting`, user, headers);
};

export const getCategories = (data) => {
    return axios.get(API_VERSION + `categories?hide=${data.hide}`, headers);
};

export const addNewCategory = (body) => {
    return axios.post(API_VERSION + `categories`, body, headers);
};

export const updateCategory = (body, id) => {
    return axios.put(API_VERSION + `categories/${id}`, body, headers);
};

export const deleteCategory = (id) => {
    return axios.delete(API_VERSION + `categories/${id}`, headers);
};

export const getTimezones = () => {
    return axios.get(API_VERSION + `timezone_configs`, headers);
};

export const addNewTimezone = (body) => {
    return axios.post(API_VERSION + `timezone_configs`, body, headers);
};

export const updateTimezone = (body, id) => {
    return axios.put(API_VERSION + `timezone_configs/${id}`, body, headers);
};

export const deleteTimezone = (id) => {
    return axios.delete(API_VERSION + `timezone_configs/${id}`, headers);
};

export const getTickets = (values, ticekt_no_of_rows, page, sorted, filtered, queryParams) => {
    debugger
    const Sorted = JSON.stringify(sorted);
    const user = getUser();
    let teslaTriggerKey = '';
    if (values && values.value && values.value.teslaExternalStatus) {
        if (values.value.teslaExternalStatus == 'external') {
            teslaTriggerKey = 'true';
        } else if (values.value.teslaExternalStatus == 'non-external') {
            teslaTriggerKey = 'false';
        }
    }

    return axios.get(API_VERSION + `tickets${page ? '?page=' + page : ''}${queryParams ? '&shareable=' + queryParams.sharaeble : ''}${values.value && values.value.sharaeble ? '&shareable=' + values.value.sharaeble : ''}${values.value && values.value.subject ? '&subject=' + encodeURIComponent(values.value.subject) : ''}${values.value && values.value.description ? '&description=' + encodeURIComponent(values.value.description) : ''}${values.value && values.value.id ? '&id=' + values.value.id : ''}${values.value && values.value.assigned_to ? '&assigned_to=' + values.value.assigned_to : ''}${values.value && values.value.created_by ? '&created_by=' + values.value.created_by : ''}${values.value && values.value.category ? '&category=' + values.value.category.value : ''}${values.value && values.value.job ? '&job=' + values.value.job : ''}${values.value && values.value.fromDate ? '&from=' + values.value.fromDate : ''}${values.value && values.value.toDate ? '&to=' + values.value.toDate : ''}${values.isWeek === undefined ? '' : '&isWeek=' + values.isWeek}${values.isMonth === undefined ? '' : '&isMonth=' + values.isMonth}${values.isYear === undefined ? '' : '&isYear=' + values.isYear}${values.firstday ? '&from=' + values.firstday : ''}${values.lastday ? '&to=' + values.lastday : ''}${values.currentDate ? '&currentDate=' + values.currentDate : ''}${values.name ? '&account_name=' + values.name : ''}${values.region ? '&region=' + values.region : ''}${values.fromDate ? '&from=' + values.fromDate : ''}${values.toDate ? '&to=' + values.toDate : ''}${values.value && values.value.status ? '&status=' + values.value.status.toLowerCase() : '&status=open'}${ticekt_no_of_rows ? '&ticket_no_of_rows=' + ticekt_no_of_rows : ''}${Sorted ? '&column_name=' + Sorted : ''}${'&mode=' + user.mode}${(teslaTriggerKey == 'true' || teslaTriggerKey == 'false') ? '&external=' + teslaTriggerKey : ''}`, headers);
}

export const getPriorTickets = (values, ticekt_no_of_rows, page, sorted, filtered) => {
    const Sorted = JSON.stringify(sorted);
    const user = getUser();
    return axios.get(API_VERSION + `tickets/priority${page ? '?page=' + page : ''}${values.value && values.value.subject ? '&subject=' + encodeURIComponent(values.value.subject) : ''}${values.value && values.value.description ? '&description=' + encodeURIComponent(values.value.description) : ''}${values.value && values.value.id ? '&id=' + values.value.id : ''}${values.value && values.value.assigned_to ? '&assigned_to=' + values.value.assigned_to : ''}${values.value && values.value.created_by ? '&created_by=' + values.value.created_by : ''}${values.value && values.value.category ? '&category=' + values.value.category.value : ''}${values.value && values.value.job ? '&job=' + values.value.job : ''}${values.value && values.value.fromDate ? '&from=' + values.value.fromDate : ''}${values.value && values.value.toDate ? '&to=' + values.value.toDate : ''}${values.isWeek === undefined ? '' : '&isWeek=' + values.isWeek}${values.isMonth === undefined ? '' : '&isMonth=' + values.isMonth}${values.isYear === undefined ? '' : '&isYear=' + values.isYear}${values.firstday ? '&from=' + values.firstday : ''}${values.lastday ? '&to=' + values.lastday : ''}${values.currentDate ? '&currentDate=' + values.currentDate : ''}${values.name ? '&account_name=' + values.name : ''}${values.region ? '&region=' + values.region : ''}${values.fromDate ? '&from=' + values.fromDate : ''}${values.toDate ? '&to=' + values.toDate : ''}${values && values.status ? '&status=' + values.status.toLowerCase() : values.value && values.value.status ? '&status=' + values.value.status.toLowerCase() : '&status=open'}${ticekt_no_of_rows ? '&ticket_no_of_rows=' + ticekt_no_of_rows : ''}${Sorted ? '&column_name=' + Sorted : ''}${'&mode=' + user.mode}`, headers);
}

export const priorTicketStatusClicked = (ticket) => {
    return axios.get(API_VERSION + `tickets/count/${ticket.id ? ticket.id : ''}`, headers);
}

export const getMyTickets = (values, ticekt_no_of_rows, page, sorted, filtered) => {
    const Sorted = JSON.stringify(sorted);
    const user = getUser();
    return axios.get(API_VERSION + `tickets${page ? '?page=' + page : ''}${values.value && values.value.subject ? '&subject=' + encodeURIComponent(values.value.subject) : ''}${values.value && values.value.description ? '&description=' + encodeURIComponent(values.value.description) : ''}${values.value && values.value.id ? '&id=' + values.value.id : ''}${values.value && values.value.assigned_to ? '&assigned_to=' + values.value.assigned_to : ''}${values.value && values.value.created_by ? '&created_by=' + values.value.created_by : ''}${values.value && values.value.category ? '&category=' + values.value.category.value : ''}${values.value && values.value.job ? '&job=' + values.value.job : ''}${values.value && values.value.fromDate ? '&from=' + values.value.fromDate : ''}${values.value && values.value.toDate ? '&to=' + values.value.toDate : ''}${values.isWeek === undefined ? '' : '&isWeek=' + values.isWeek}${values.isMonth === undefined ? '' : '&isMonth=' + values.isMonth}${values.isYear === undefined ? '' : '&isYear=' + values.isYear}${values.firstday ? '&from=' + values.firstday : ''}${values.lastday ? '&to=' + values.lastday : ''}${values.currentDate ? '&currentDate=' + values.currentDate : ''}${values.name ? '&account_name=' + values.name : ''}${values.region ? '&region=' + values.region : ''}${values.fromDate ? '&from=' + values.fromDate : ''}${values.toDate ? '&to=' + values.toDate : ''}${values.value && values.value.status ? '&status=' + values.value.status.toLowerCase() : '&status=open'}${ticekt_no_of_rows ? '&ticket_no_of_rows=' + ticekt_no_of_rows : ''}${Sorted ? '&column_name=' + Sorted : ''}${'&mode=' + user.mode}${'&ticket_info=mylist'}`, headers);
}

export const getPreviewScheduledTickets = (values, ticekt_no_of_rows, page, sorted, filtered, ticketId) => {
    const Sorted = JSON.stringify(sorted);
    const user = getUser();
    
    return axios.get(API_VERSION + `schedules/ticket/${ticketId}${page ? '?page=' + page : ''}${values.value && values.value.subject ? '&subject=' + encodeURIComponent(values.value.subject) : ''}${values.value && values.value.description ? '&description=' + encodeURIComponent(values.value.description) : ''}${values.value && values.value.id ? '&id=' + values.value.id : ''}${values.value && values.value.assigned_to ? '&assigned_to=' + values.value.assigned_to : ''}${values.value && values.value.created_by ? '&created_by=' + values.value.created_by : ''}${values.value && values.value.category ? '&category=' + values.value.category.value : ''}${values.value && values.value.job ? '&job=' + values.value.job : ''}${values.value && values.value.fromDate ? '&from=' + values.value.fromDate : ''}${values.value && values.value.toDate ? '&to=' + values.value.toDate : ''}${values.isWeek === undefined ? '' : '&isWeek=' + values.isWeek}${values.isMonth === undefined ? '' : '&isMonth=' + values.isMonth}${values.isYear === undefined ? '' : '&isYear=' + values.isYear}${values.firstday ? '&from=' + values.firstday : ''}${values.lastday ? '&to=' + values.lastday : ''}${values.currentDate ? '&currentDate=' + values.currentDate : ''}${values.name ? '&account_name=' + values.name : ''}${values.region ? '&region=' + values.region : ''}${values.fromDate ? '&from=' + values.fromDate : ''}${values.toDate ? '&to=' + values.toDate : ''}${values.value && values.value.status ? '&status=' + values.value.status.toLowerCase() : '&status=open'}${ticekt_no_of_rows ? '&ticket_no_of_rows=' + ticekt_no_of_rows : ''}${Sorted ? '&column_name=' + Sorted : ''}${'&mode=' + user.mode}`, headers);
}

export const getScheduledTickets = (values, ticekt_no_of_rows, page, sorted, filtered, isForm) => {
    const Sorted = JSON.stringify(sorted);
    const user = getUser();
    return axios.get(API_VERSION + `schedules/ticket${page ? '?page=' + page : ''}${values.value && values.value.subject ? '&subject=' + encodeURIComponent(values.value.subject) : ''}${values.value && values.value.description ? '&description=' + encodeURIComponent(values.value.description) : ''}${values.value && values.value.title ? '&title=' + encodeURIComponent(values.value.title) : ''}${values.value && values.value.id ? '&id=' + values.value.id : ''}${values.value && values.value.assigned_to ? '&assigned_to=' + values.value.assigned_to : ''}${values.value && values.value.created_by ? '&created_by=' + values.value.created_by : ''}${values.value && values.value.category ? '&category_id=' + values.value.category.value : ''}${values.value && values.value.job ? '&job_name=' + values.value.job : ''}${values.value && values.value.fromDate ? '&from=' + values.value.fromDate : ''}${values.value && values.value.toDate ? '&to=' + values.value.toDate : ''}${values.isWeek === undefined ? '' : '&isWeek=' + values.isWeek}${values.isMonth === undefined ? '' : '&isMonth=' + values.isMonth}${values.isYear === undefined ? '' : '&isYear=' + values.isYear}${values.firstday ? '&from=' + values.firstday : ''}${values.lastday ? '&to=' + values.lastday : ''}${values.currentDate ? '&currentDate=' + values.currentDate : ''}${values.name ? '&account_name=' + values.name : ''}${values.region ? '&region=' + values.region : ''}${values.fromDate ? '&from=' + values.fromDate : ''}${values.toDate ? '&to=' + values.toDate : ''}${values.value && values.value.status ? '&status=' + values.value.status.toLowerCase() : '&status=open'}${ticekt_no_of_rows ? '&limit=' + ticekt_no_of_rows : ''}${Sorted ? '&sorted=' + Sorted : ''}${isForm ? '&isForm=' + isForm : ''}${'&mode=' + user.mode}`, headers);
}

export const deleteScheduledTicket = (ticketId) => {
    return axios.delete(API_VERSION + `schedules/ticket/${ticketId}`, headers);
};

export const getUserTicketsList = (values, ticekt_no_of_rows, page, Sorted, filtered) => {
    // const Sorted = JSON.stringify(sorted);
    const user = getUser();
    let teslaTriggerKey = '';

    if (values && values.value && values.value.teslaExternalStatus) {
        if (values.value.teslaExternalStatus == 'external') {
            teslaTriggerKey = 'true';
        } else if (values.value.teslaExternalStatus == 'non-external') {
            teslaTriggerKey = 'false';
        }
    }

    return axios.get(API_VERSION + `tickets/user_tickets${page ? '?page=' + page : ''}${values.value && values.value.id ? '&id=' + values.value.id : ''}${values.value && values.value.subject ? '&subject=' + encodeURIComponent(values.value.subject) : ''}${values.value && values.value.description ? '&description=' + encodeURIComponent(values.value.description) : ''}${values.value && values.value.assigned_to ? '&assigned_to=' + values.value.assigned_to : ''}${values.value && values.value.created_by ? '&created_by=' + values.value.created_by : ''}${values.value && values.value.category ? '&category=' + values.value.category.value : ''}${values.value && values.value.job ? '&job=' + values.value.job : ''}${values.value && values.value.fromDate ? '&from=' + values.value.fromDate : ''}${values.value && values.value.toDate ? '&to=' + values.value.toDate : ''}${values.isWeek === undefined ? '' : '&isWeek=' + values.isWeek}${values.isMonth === undefined ? '' : '&isMonth=' + values.isMonth}${values.isYear === undefined ? '' : '&isYear=' + values.isYear}${values.firstday ? '&from=' + values.firstday : ''}${values.lastday ? '&to=' + values.lastday : ''}${values.currentDate ? '&currentDate=' + values.currentDate : ''}${values.name ? '&account_name=' + values.name : ''}${values.region ? '&region=' + values.region : ''}${values.fromDate ? '&from=' + values.fromDate : ''}${values.toDate ? '&to=' + values.toDate : ''}${values.value && values.value.status ? '&status=' + values.value.status.toLowerCase() : '&status=open'}${ticekt_no_of_rows ? '&user_ticket_no_of_rows=' + ticekt_no_of_rows : ''
        }${Sorted && Sorted.length > 0 && Sorted[0].id ? '&column_name=' + Sorted[0].id : ''
        }${Sorted && Sorted.length > 0 && Sorted[0].desc ? '&sort=desc' : '&sort=asc'}${'&mode=' + user.mode}${(teslaTriggerKey == 'true' || teslaTriggerKey == 'false') ? '&external=' + teslaTriggerKey : ''}`, headers);
}

export const getUserMyTicketsList = (values, ticekt_no_of_rows, page, Sorted, filtered) => {
    // const Sorted = JSON.stringify(sorted);
    const user = getUser();
    return axios.get(API_VERSION + `tickets/user_tickets${page ? '?page=' + page : ''}${values.value && values.value.id ? '&id=' + values.value.id : ''}${values.value && values.value.subject ? '&subject=' + encodeURIComponent(values.value.subject) : ''}${values.value && values.value.description ? '&description=' + encodeURIComponent(values.value.description) : ''}${values.value && values.value.assigned_to ? '&assigned_to=' + values.value.assigned_to : ''}${values.value && values.value.created_by ? '&created_by=' + values.value.created_by : ''}${values.value && values.value.category ? '&category=' + values.value.category.value : ''}${values.value && values.value.job ? '&job=' + values.value.job : ''}${values.value && values.value.fromDate ? '&from=' + values.value.fromDate : ''}${values.value && values.value.toDate ? '&to=' + values.value.toDate : ''}${values.isWeek === undefined ? '' : '&isWeek=' + values.isWeek}${values.isMonth === undefined ? '' : '&isMonth=' + values.isMonth}${values.isYear === undefined ? '' : '&isYear=' + values.isYear}${values.firstday ? '&from=' + values.firstday : ''}${values.lastday ? '&to=' + values.lastday : ''}${values.currentDate ? '&currentDate=' + values.currentDate : ''}${values.name ? '&account_name=' + values.name : ''}${values.region ? '&region=' + values.region : ''}${values.fromDate ? '&from=' + values.fromDate : ''}${values.toDate ? '&to=' + values.toDate : ''}${values.value && values.value.status ? '&status=' + values.value.status.toLowerCase() : '&status=open'}${ticekt_no_of_rows ? '&user_ticket_no_of_rows=' + ticekt_no_of_rows : ''
        }${Sorted && Sorted.length > 0 && Sorted[0].id ? '&column_name=' + Sorted[0].id : ''
        }${Sorted && Sorted.length > 0 && Sorted[0].desc ? '&sort=desc' : '&sort=asc'}${'&mode=' + user.mode}${'&ticket_info=mylist'}`, headers);
}
export const getTicketPresignedUrl = (ticket, extentions) => {
    const body = { ext: extentions };
    return axios.patch(
        API_VERSION + `tickets/${ticket.id ? ticket.id : ticket.ticket.id}/presigned_photo_url`,
        body,
        headers
    );
};

export const getQuotePresignedUrl = (quote, extentions) => {
    const body = { ext: extentions };
    return axios.patch(API_VERSION + `quotes/${quote.id}/presigned_photo_url`, body, headers);
};

export const addNewTicket = (ticket) => {
    return axios.post(API_VERSION + `tickets`, ticket, headers);
};

export const getEditTicket = (id) => {
    return axios.get(API_VERSION + `tickets/${id}`, headers);
};

export const updateTicket = (ticket) => {
    if (ticket && ticket.ticket && ticket.ticket.ticketEvent) {
        storage.set('scheduleTicketId', ticket.ticket.ticketEvent.schedule_ticket_id);
        delete ticket.ticket.ticketEvent;
        delete ticket.ticket.user;
    }
    if (ticket.chi) {
        return axios.put(API_VERSION + `tickets/${ticket.id ? ticket.id : ticket.chi.id}`, ticket, headers);
    }
    else {
        return axios.put(API_VERSION + `tickets/${ticket.id ? ticket.id : ticket.ticket.id}`, ticket, headers);
    }
}

export const deletePortfolioImages = (idsToDelete) => {
    // const body = { attachment_ids: [idsToDelete] };
    return axios.delete(API_VERSION + `attachments?attachment_ids=[${idsToDelete}]`, headers);
};

export const deleteTicket = (id) => {
    return axios.delete(API_VERSION + `tickets/${id}`, headers);
};

export const resolveTicket = (tick) => {
    if (tick.chi) {
        const ticket = {}
        ticket.ticket = tick.chi;
        return axios.put(API_VERSION + `tickets/${ticket.ticket.id}/resolve_ticket`, ticket, headers);
    }
    if (tick.ticket) {
        return axios.put(API_VERSION + `tickets/${tick.ticket.id}/resolve_ticket`, tick, headers);
    }
}

export const cancelTicket = (ticket) => {
    debugger
    return axios.put(API_VERSION + `tickets/${ticket.ticket.id}/resolve_ticket`, ticket, headers);
}

export const getCommentsPresignedUrl = (extentions, comment) => {
    const body = { ext: extentions };

    return axios.patch(API_VERSION + `comments/${comment.id}/presigned_photo_url`, body);
};

export const getInspectionsList = (values, inspection_no_of_rows, page, Sorted) => {
    let kpiIdsArray = ''
    if (values.kpiIds && values.kpiIds.kpiIds) {
        kpiIdsArray = '[' + values.kpiIds.kpiIds + ']'
    }
    // return axios.get(API_VERSION + `inspections`, headers);
    // const Sorted = JSON.stringify(sorted);
    const user = getUser();

    // const Sorted = queryString.stringify(sorted)

    return axios.get(API_VERSION + `inspections${page ? '?page=' + page : ''}${values.isWeek === undefined ? '' : '&isWeek=' + values.isWeek}${values.isMonth === undefined ? '' : '&isMonth=' + values.isMonth}${values.isYear === undefined ? '' : '&isYear=' + values.isYear}${values.firstday ? '&from=' + values.firstday : ''}${values.lastday ? '&to=' + values.lastday : ''}${values.currentDate ? '&currentDate=' + values.currentDate : ''}${values.value && values.value.id ? '&inspection_id=' + values.value.id : ''}${values.value && values.value.name ? '&inspection_form_name=' + values.value.name : ''}${values.value && values.value.region ? '&region=' + values.value.region : ''}${values.value && values.value.score ? '&score=' + values.value.score : ''}${values.value && values.value.fromDate ? '&from=' + values.value.fromDate : ''}${values.value && values.value.toDate ? '&to=' + values.value.toDate : ''}${inspection_no_of_rows ? '&inspection_no_of_rows=' + inspection_no_of_rows : ''}${Sorted && Sorted.length > 0 && Sorted[0].id ? '&column_name=' + Sorted[0].id : ''}${Sorted && Sorted.length > 0 && Sorted[0].desc ? '&sort=desc' : '&sort=asc'}${values.value && values.value.status ? '&status=' + values.value.status : ''}${values.value && values.value.account ? '&account_id=' + values.value.account.value : ''}${values.value && values.value.user ? '&inspected_by_id=' + values.value.user.value : ''}${values.value && values.value.qqv_status ? '&qqv_status=' + values.value.qqv_status : ''}${values.value && values.value.qqv_customer_contact ? '&qqv_customer_contact=' + values.value.qqv_customer_contact : ''}${values.kpiIds ? '&kpiIds=' + kpiIdsArray : ''}${'&mode=' + user.mode}`, headers);
}

export const getMyInspectionsList = (values, inspection_no_of_rows, page, Sorted, mylist) => {
    const user = getUser();
    return axios.get(API_VERSION + `inspections${page ? '?page=' + page : ''}${values.isWeek === undefined ? '' : '&isWeek=' + values.isWeek}${values.isMonth === undefined ? '' : '&isMonth=' + values.isMonth}${values.isYear === undefined ? '' : '&isYear=' + values.isYear}${values.firstday ? '&from=' + values.firstday : ''}${values.lastday ? '&to=' + values.lastday : ''}${values.currentDate ? '&currentDate=' + values.currentDate : ''}${values.value && values.value.id ? '&inspection_id=' + values.value.id : ''}${values.value && values.value.name ? '&inspection_form_name=' + values.value.name : ''}${values.value && values.value.region ? '&region=' + values.value.region : ''}${values.value && values.value.score ? '&score=' + values.value.score : ''}${values.value && values.value.fromDate ? '&from=' + values.value.fromDate : ''}${values.value && values.value.toDate ? '&to=' + values.value.toDate : ''}${inspection_no_of_rows ? '&inspection_no_of_rows=' + inspection_no_of_rows : ''}${Sorted && Sorted.length > 0 && Sorted[0].id ? '&column_name=' + Sorted[0].id : ''}${Sorted && Sorted.length > 0 && Sorted[0].desc ? '&sort=desc' : '&sort=asc'}${values.value && values.value.status ? '&status=' + values.value.status : ''}${values.value && values.value.account ? '&account_id=' + values.value.account.value : ''}${values.value && values.value.user ? '&inspected_by_id=' + values.value.user.value : ''}${values.value && values.value.qqv_status ? '&qqv_status=' + values.value.qqv_status : ''}${values.value && values.value.qqv_customer_contact ? '&qqv_customer_contact=' + values.value.qqv_customer_contact : ''}${'&mode=' + user.mode}${'&inspection_info=mylist'}`, headers);
}

export const getPendingInspectionsList = (values, inspection_no_of_rows, page, Sorted) => {
    const user = getUser();
    return axios.get(API_VERSION + `inspections/pending_inspection${page ? '?page=' + page : ''}${values.isWeek === undefined ? '' : '&isWeek=' + values.isWeek}${values.isMonth === undefined ? '' : '&isMonth=' + values.isMonth}${values.isYear === undefined ? '' : '&isYear=' + values.isYear}${values.firstday ? '&from=' + values.firstday : ''}${values.lastday ? '&to=' + values.lastday : ''}${values.currentDate ? '&currentDate=' + values.currentDate : ''}${values.value && values.value.id ? '&inspection_id=' + values.value.id : ''}${values.value && values.value.name ? '&inspection_form_name=' + values.value.name : ''}${values.value && values.value.region ? '&region=' + values.value.region : ''}${values.value && values.value.score ? '&score=' + values.value.score : ''}${values.value && values.value.fromDate ? '&from=' + values.value.fromDate : ''}${values.value && values.value.toDate ? '&to=' + values.value.toDate : ''}${inspection_no_of_rows ? '&inspection_no_of_rows=' + inspection_no_of_rows : ''}${Sorted && Sorted.length > 0 && Sorted[0].id ? '&column_name=' + Sorted[0].id : ''}${Sorted && Sorted.length > 0 && Sorted[0].desc ? '&sort=desc' : '&sort=asc'}${values.value && values.value.status ? '&status=' + values.value.status : ''}${values.value && values.value.account ? '&account_id=' + values.value.account.value : ''}${values.value && values.value.user ? '&inspected_by_id=' + values.value.user.value : ''}${values.value && values.value.qqv_status ? '&qqv_status=' + values.value.qqv_status : ''}${values.value && values.value.qqv_customer_contact ? '&qqv_customer_contact=' + values.value.qqv_customer_contact : ''}${'&mode=' + user.mode}`, headers);
}

export const getDashboardInspectionsList = (values, inspection_no_of_rows, page, Sorted) => {
    // const Sorted = JSON.stringify(sorted);
    const user = getUser();
    return axios.get(
        API_VERSION +
        `dashboard/inspections${page ? '?page=' + page : ''}${values.name ? '&inspection_form_name=' + values.name : ''
        }${inspection_no_of_rows ? '&inspection_no_of_rows=' + inspection_no_of_rows : ''}${Sorted && Sorted.length > 0 && Sorted[0].id ? '&column_name=' + Sorted[0].id : ''
        }${Sorted && Sorted.length > 0 && Sorted[0].desc ? '&sort=desc' : '&sort=asc'}${values.status ? '&status=' + values.status : ''
        }${values.account ? '&account_id=' + values.account.value : ''}${values.user ? '&inspected_by_id=' + values.user.value : ''
        }${values.fromDate ? '&from=' + values.fromDate : ''}${values.toDate ? '&to=' + values.toDate : ''}${values.region ? '&region=' + values.region : ''
        }${'&mode=' + user.mode}`,
        headers
    );
};

export const getUserInspcetionsList = (values, inspection_no_of_rows, page, Sorted) => {
    let kpiIdsArray = ''
    if (values.kpiIds && values.kpiIds.kpiIds) {
        kpiIdsArray = '[' + values.kpiIds.kpiIds + ']'
    }
    // const Sorted = JSON.stringify(sorted);
    const user = getUser();
    return axios.get(API_VERSION +
        `inspections/user_inspection${inspection_no_of_rows ? '?inspection_no_of_rows=' + inspection_no_of_rows : ''}${Sorted && Sorted.length > 0 && Sorted[0].id ? '&column_name=' + Sorted[0].id : ''}${Sorted && Sorted.length > 0 && Sorted[0].desc ? '&sort=desc' : '&sort=asc'}${values.isWeek === undefined ? '' : '&isWeek=' + values.isWeek}${values.isMonth === undefined ? '' : '&isMonth=' + values.isMonth}${values.isYear === undefined ? '' : '&isYear=' + values.isYear}${values.firstday ? '&from=' + values.firstday : ''}${values.lastday ? '&to=' + values.lastday : ''}${values.currentDate ? '&currentDate=' + values.currentDate : ''}${values.value && values.value.id ? '&inspection_id=' + values.value.id : ''}${values.value && values.value.name ? '&inspection_form_name=' + values.value.name : ''}${values.value && values.value.region ? '&region=' + values.value.region : ''}${values.value && values.value.score ? '&score=' + values.value.score : ''}${values.value && values.value.fromDate ? '&from=' + values.value.fromDate : ''}${values.value && values.value.toDate ? '&to=' + values.value.toDate : ''}${values.value && values.value.status ? '&status=' + values.value.status : ''}${values.value && values.value.account ? '&account_id=' + values.value.account.value : ''}${values.value && values.value.user ? '&inspected_by_id=' + values.value.user.value : ''}${page ? '&page=' + page : ''}${values.status ? '&status=' + values.status : ''}${values.value && values.value.qqv_status ? '&qqv_status=' + values.value.qqv_status : ''}${values.value && values.value.qqv_customer_contact ? '&qqv_customer_contact=' + values.value.qqv_customer_contact : ''}${values.kpiIds ? '&kpiIds=' + kpiIdsArray : ''}${'&mode=' + user.mode}`, headers);
}
export const getUserMyInspcetionsList = (values, inspection_no_of_rows, page, Sorted) => {
    // const Sorted = JSON.stringify(sorted);
    const user = getUser();
    return axios.get(API_VERSION +
        `inspections/user_inspection${inspection_no_of_rows ? '?inspection_no_of_rows=' + inspection_no_of_rows : ''}${Sorted && Sorted.length > 0 && Sorted[0].id ? '&column_name=' + Sorted[0].id : ''}${Sorted && Sorted.length > 0 && Sorted[0].desc ? '&sort=desc' : '&sort=asc'}${values.isWeek === undefined ? '' : '&isWeek=' + values.isWeek}${values.isMonth === undefined ? '' : '&isMonth=' + values.isMonth}${values.isYear === undefined ? '' : '&isYear=' + values.isYear}${values.firstday ? '&from=' + values.firstday : ''}${values.lastday ? '&to=' + values.lastday : ''}${values.currentDate ? '&currentDate=' + values.currentDate : ''}${values.value && values.value.id ? '&inspection_id=' + values.value.id : ''}${values.value && values.value.name ? '&inspection_form_name=' + values.value.name : ''}${values.value && values.value.region ? '&region=' + values.value.region : ''}${values.value && values.value.score ? '&score=' + values.value.score : ''}${values.value && values.value.fromDate ? '&from=' + values.value.fromDate : ''}${values.value && values.value.toDate ? '&to=' + values.value.toDate : ''}${values.value && values.value.status ? '&status=' + values.value.status : ''}${values.value && values.value.account ? '&account_id=' + values.value.account.value : ''}${values.value && values.value.user ? '&inspected_by_id=' + values.value.user.value : ''}${page ? '&page=' + page : ''}${values.status ? '&status=' + values.status : ''}${values.value && values.value.qqv_status ? '&qqv_status=' + values.value.qqv_status : ''}${values.value && values.value.qqv_customer_contact ? '&qqv_customer_contact=' + values.value.qqv_customer_contact : ''}${'&mode=' + user.mode}${'&inspection_info=mylist'}`, headers);
}

export const getInspectionReports = (data) => {
    // &overall_inspections=${true}&number_of_recently_performed_inspections=${true}
    // &avg_inspection_score_each_inspector=${true}&lowest_performing_account=${true}
    return axios.patch(API_VERSION + `inspections/reports?from=${data.fromDate}&to=${data.toDate}&mode=${data.mode}`, { account_ids: data.account_ids })
}

export const getInspectionReportsCsv = (data) => {
    return axios.patch(API_VERSION + `inspections/export_inspection?from=${data.fromDate}&to=${data.toDate}&mode=${data.mode}`, { account_ids: data.account_ids[0] })
}

export const performInspection = (Inspection) => {
    const user = getUser();
    Inspection['mode'] = user.mode;
    return axios.post(API_VERSION + `inspections`, Inspection, headers);
};

export const updateInspection = (inspection) => {
    delete inspection.mode;
    return axios.put(API_VERSION + `inspections/${inspection.inspection.inspection_id}`, inspection, headers);
};

export const updatePendingInspection = (inspection) => {
    return axios.put(API_VERSION + `inspections/pending_inspection/${inspection.id}`, inspection, headers);
};

export const getLineItemsPresignedUrl = (extentions, lineItemId) => {
    const body = { ext: extentions };
    return axios.patch(API_VERSION + `line_items/${lineItemId}/presigned_photo_url`, body, headers);
};

export const getNotesPresignedUrl = (extentions, inspectionId) => {
    const body = { ext: extentions };
    return axios.patch(API_VERSION + `inspections/${inspectionId}/presigned_photo_url`, body, headers);
};

export const getInspection = (id) => {
    return axios.get(API_VERSION + `inspections/${id}`, headers);
};

export const onInspectionExportClicked = (body) => {
    return axios.get(API_VERSION + `inspections/export${body.mode ? '?mode=' + body.mode : ''
        }${body.account ? '&account_id=' + body.account.value : ''
        }${body.fromDate ? '&from=' + body.fromDate : ''
        }${body.id ? '&inspection_id=' + body.id : ''
        }${body.name ? '&inspection_form_name=' + body.name : ''
        }${body.qqv_customer_contact ? '&qqv_customer_contact=' + body.qqv_customer_contact : ''
        }${body.qqv_status ? '&qqv_status=' + body.qqv_status : ''
        }${body.region ? '&region=' + body.region : ''
        }${body.score ? '&score=' + body.score : ''
        }${body.status ? '&status=' + body.status : ''
        }${body.toDate ? '&to=' + body.toDate : ''
        }${body.user ? '&inspected_by_id=' + body.user.value : ''
        }${body.sort && body.sort.length > 0 ? body.sort[0].desc == true ? '&sort=desc' : '&sort=asc' : ''
        }
    `, headers);
}
export const searchUserInspection = (values, inspection_no_of_rows, page, sorted) => {
    const Sorted = JSON.stringify(sorted);
    const user = getUser();
    return axios.get(
        API_VERSION +
        `inspections/user_inspection${inspection_no_of_rows ? '?inspection_no_of_rows=' + inspection_no_of_rows : ''
        }${values.isWeek === undefined ? '' : '&isWeek=' + values.isWeek}${values.isMonth === undefined ? '' : '&isMonth=' + values.isMonth
        }${values.isYear === undefined ? '' : '&isYear=' + values.isYear}${values.firstday ? '&from=' + values.firstday : ''
        }${values.lastday ? '&to=' + values.lastday : ''}${values.currentDate ? '&currentDate=' + values.currentDate : ''
        }${page ? '&page=' + page : ''}${values.value && values.value.name ? '&inspection_form_name=' + values.value.name : ''
        }${values.value && values.value.region ? '&region=' + values.value.region : ''}${values.value && values.value.score ? '&score=' + values.value.score : ''
        }${values.value && values.value.account ? '&account_id=' + values.value.account.value : ''}${values.value && values.value.user ? '&inspected_by_id=' + values.value.user.value : ''
        }${Sorted ? '&column_name=' + Sorted : ''}${values.value && values.value.status ? '&status=' + values.value.status : ''
        }${values.value && values.value.fromDate ? '&from=' + values.value.fromDate : ''}${values.value && values.value.toDate ? '&to=' + values.value.toDate : ''
        }${values.region ? '&region=' + values.region : ''}${values.value && values.value.id ? '&inspection_id=' + values.value.id : ''
        }${values.value && values.value.qqv_status ? '&qqv_status=' + values.value.qqv_status : ''
        }${values.value && values.value.qqv_customer_contact ? '&qqv_customer_contact=' + values.value.qqv_customer_contact : ''
        }${'&mode=' + user.mode}`,
        headers
    );
};

export const searchUserPendingInspection = (values, inspection_no_of_rows, page, sorted) => {
    const Sorted = JSON.stringify(sorted);
    const user = getUser();
    return axios.get(
        API_VERSION +
        `inspections/pending_inspection${inspection_no_of_rows ? '?inspection_no_of_rows=' + inspection_no_of_rows : ''
        }${values.isWeek === undefined ? '' : '&isWeek=' + values.isWeek}${values.isMonth === undefined ? '' : '&isMonth=' + values.isMonth
        }${values.isYear === undefined ? '' : '&isYear=' + values.isYear}${values.firstday ? '&from=' + values.firstday : ''
        }${values.lastday ? '&to=' + values.lastday : ''}${values.currentDate ? '&currentDate=' + values.currentDate : ''
        }${page ? '&page=' + page : ''}${values.value && values.value.name ? '&inspection_form_name=' + values.value.name : ''
        }${values.value && values.value.region ? '&region=' + values.value.region : ''}${values.value && values.value.score ? '&score=' + values.value.score : ''
        }${values.value && values.value.account ? '&account_id=' + values.value.account.value : ''}${values.value && values.value.user ? '&inspected_by_id=' + values.value.user.value : ''
        }${Sorted ? '&column_name=' + Sorted : ''}${values.value && values.value.status ? '&status=' + values.value.status : ''
        }${values.value && values.value.fromDate ? '&from=' + values.value.fromDate : ''}${values.value && values.value.toDate ? '&to=' + values.value.toDate : ''
        }${values.region ? '&region=' + values.region : ''}${values.value && values.value.id ? '&inspection_id=' + values.value.id : ''
        }${values.value && values.value.qqv_status ? '&qqv_status=' + values.value.qqv_status : ''
        }${values.value && values.value.qqv_customer_contact ? '&qqv_customer_contact=' + values.value.qqv_customer_contact : ''
        }${'&mode=' + user.mode}`,
        headers
    );
};

export const searchUserMyInspection = (values, inspection_no_of_rows, page, sorted) => {
    const Sorted = JSON.stringify(sorted);
    const user = getUser();
    return axios.get(
        API_VERSION +
        `inspections/user_inspection${inspection_no_of_rows ? '?inspection_no_of_rows=' + inspection_no_of_rows : ''
        }${values.isWeek === undefined ? '' : '&isWeek=' + values.isWeek}${values.isMonth === undefined ? '' : '&isMonth=' + values.isMonth
        }${values.isYear === undefined ? '' : '&isYear=' + values.isYear}${values.firstday ? '&from=' + values.firstday : ''
        }${values.lastday ? '&to=' + values.lastday : ''}${values.currentDate ? '&currentDate=' + values.currentDate : ''
        }${page ? '&page=' + page : ''}${values.value && values.value.name ? '&inspection_form_name=' + values.value.name : ''
        }${values.value && values.value.region ? '&region=' + values.value.region : ''}${values.value && values.value.score ? '&score=' + values.value.score : ''
        }${values.value && values.value.account ? '&account_id=' + values.value.account.value : ''}${values.value && values.value.user ? '&inspected_by_id=' + values.value.user.value : ''
        }${Sorted ? '&column_name=' + Sorted : ''}${values.value && values.value.status ? '&status=' + values.value.status : ''
        }${values.value && values.value.fromDate ? '&from=' + values.value.fromDate : ''}${values.value && values.value.toDate ? '&to=' + values.value.toDate : ''
        }${values.region ? '&region=' + values.region : ''}${values.value && values.value.id ? '&inspection_id=' + values.value.id : ''
        }${values.value && values.value.qqv_status ? '&qqv_status=' + values.value.qqv_status : ''
        }${values.value && values.value.qqv_customer_contact ? '&qqv_customer_contact=' + values.value.qqv_customer_contact : ''
        }${'&mode=' + user.mode}&inspection_info=mylist`,
        headers
    );
};

export const searchInspections = (values, inspection_no_of_rows, page, sorted) => {
    const user = getUser();
    const Sorted = JSON.stringify(sorted);
    return axios.get(API_VERSION + `inspections${inspection_no_of_rows ? '?inspection_no_of_rows=' + inspection_no_of_rows : ''}${values.isWeek === undefined ? '' : '&isWeek=' + values.isWeek}${values.isMonth === undefined ? '' : '&isMonth=' + values.isMonth}${values.isYear === undefined ? '' : '&isYear=' + values.isYear}${values.firstday ? '&from=' + values.firstday : ''}${values.lastday ? '&to=' + values.lastday : ''}${values.currentDate ? '&currentDate=' + values.currentDate : ''}${page ? '&page=' + page : ''}${values.value && values.value.qqv_status ? '&qqv_status=' + values.value.qqv_status : ''}${values.value && values.value.name ? '&inspection_form_name=' + values.value.name : ''}${values.value && values.value.region ? '&region=' + values.value.region : ''}${values.value && values.value.score ? '&score=' + values.value.score : ''}${values.value && values.value.account ? '&account_id=' + values.value.account.value : ''}${values.value && values.value.user ? '&inspected_by_id=' + values.value.user.value : ''}${Sorted ? '&column_name=' + Sorted : ''}${values.value && values.value.status ? '&status=' + values.value.status : ''}${values.value && values.value.fromDate ? '&from=' + values.value.fromDate : ''}${values.value && values.value.toDate ? '&to=' + values.value.toDate : ''}${values.region ? '&region=' + values.region : ''}${values.value && values.value.id ? '&inspection_id=' + values.value.id : ''}${values.value && values.value.qqv_customer_contact ? '&qqv_customer_contact=' + values.value.qqv_customer_contact : ''}${'&mode=' + user.mode}`, headers);
}
export const searchPendingInspections = (values, inspection_no_of_rows, page, sorted) => {
    const user = getUser();
    const Sorted = JSON.stringify(sorted);
    return axios.get(API_VERSION + `inspections/pending_inspection${inspection_no_of_rows ? '?inspection_no_of_rows=' + inspection_no_of_rows : ''}${values.isWeek === undefined ? '' : '&isWeek=' + values.isWeek}${values.isMonth === undefined ? '' : '&isMonth=' + values.isMonth}${values.isYear === undefined ? '' : '&isYear=' + values.isYear}${values.firstday ? '&from=' + values.firstday : ''}${values.lastday ? '&to=' + values.lastday : ''}${values.currentDate ? '&currentDate=' + values.currentDate : ''}${page ? '&page=' + page : ''}${values.value && values.value.qqv_status ? '&qqv_status=' + values.value.qqv_status : ''}${values.value && values.value.name ? '&inspection_form_name=' + values.value.name : ''}${values.value && values.value.region ? '&region=' + values.value.region : ''}${values.value && values.value.score ? '&score=' + values.value.score : ''}${values.value && values.value.account ? '&account_id=' + values.value.account.value : ''}${values.value && values.value.user ? '&inspected_by_id=' + values.value.user.value : ''}${Sorted ? '&column_name=' + Sorted : ''}${values.value && values.value.status ? '&status=' + values.value.status : ''}${values.value && values.value.fromDate ? '&from=' + values.value.fromDate : ''}${values.value && values.value.toDate ? '&to=' + values.value.toDate : ''}${values.region ? '&region=' + values.region : ''}${values.value && values.value.id ? '&inspection_id=' + values.value.id : ''}${values.value && values.value.qqv_customer_contact ? '&qqv_customer_contact=' + values.value.qqv_customer_contact : ''}${'&mode=' + user.mode}`, headers);
}
export const searchMyInspections = (values, inspection_no_of_rows, page, sorted) => {
    const user = getUser();
    const Sorted = JSON.stringify(sorted);
    return axios.get(API_VERSION + `inspections${inspection_no_of_rows ? '?inspection_no_of_rows=' + inspection_no_of_rows : ''}${values.isWeek === undefined ? '' : '&isWeek=' + values.isWeek}${values.isMonth === undefined ? '' : '&isMonth=' + values.isMonth}${values.isYear === undefined ? '' : '&isYear=' + values.isYear}${values.firstday ? '&from=' + values.firstday : ''}${values.lastday ? '&to=' + values.lastday : ''}${values.currentDate ? '&currentDate=' + values.currentDate : ''}${page ? '&page=' + page : ''}${values.value && values.value.qqv_status ? '&qqv_status=' + values.value.qqv_status : ''}${values.value && values.value.name ? '&inspection_form_name=' + values.value.name : ''}${values.value && values.value.region ? '&region=' + values.value.region : ''}${values.value && values.value.score ? '&score=' + values.value.score : ''}${values.value && values.value.account ? '&account_id=' + values.value.account.value : ''}${values.value && values.value.user ? '&inspected_by_id=' + values.value.user.value : ''}${Sorted ? '&column_name=' + Sorted : ''}${values.value && values.value.status ? '&status=' + values.value.status : ''}${values.value && values.value.fromDate ? '&from=' + values.value.fromDate : ''}${values.value && values.value.toDate ? '&to=' + values.value.toDate : ''}${values.region ? '&region=' + values.region : ''}${values.value && values.value.id ? '&inspection_id=' + values.value.id : ''}${values.value && values.value.qqv_customer_contact ? '&qqv_customer_contact=' + values.value.qqv_customer_contact : ''}${'&mode=' + user.mode}&inspection_info=mylist`, headers);
}
// export const updateLineItemsPresignedUrl = (arrayBuffer, presigned_url) => {
//     return
// }

export const deleteInspection = (id) => {
    return axios.delete(API_VERSION + `inspections/${id}`);
};

export const getQuotesCsvReports = (data) => {
    return axios.get(

        API_VERSION +
        `quotes/reports?from=${data.fromDate}&to=${data.toDate}&account_ids=[${data.account_ids}]&tag_opportunity=${data.tag_opportunity}&active_status=${data.active_status}&mode=${data.mode}`
    );
};

export const getInspectionForms = () => {
    const user = getUser();
    return axios.get(API_VERSION + `inspection_forms/list?mode=${user.mode}`, headers);
};

export const getInspectionFormsForJob = () => {
    const user = getUser();
    return axios.get(API_VERSION + `inspection_forms/account_list?mode=${user.mode}`, headers);
};

export const getInspectionFormList = (searchValues, insform_no_of_rows, page, Sorted, filtered) => {
    // const Sorted = JSON.stringify(sorted);
    const user = getUser();
    return axios.get(API_VERSION + `inspection_forms/list?${searchValues && searchValues.name !== '' ? '&name=' + searchValues.name : ''}&mode=${user.mode}&page=${page}&limit=${insform_no_of_rows}${Sorted && Sorted.length > 0 && Sorted[0].id ? '&column_name=' + Sorted[0].id : ''}${Sorted && Sorted.length > 0 && Sorted[0].desc ? '&sort=desc' : '&sort=asc'}`, headers);
}

export const addNewInspectionForm = (newInspectionForm) => {
    return axios.post(API_VERSION + `inspection_forms`, newInspectionForm, headers);
};

export const getEditInspectionForm = (id) => {
    return axios.get(API_VERSION + `inspection_forms/${id}`, headers);
};

export const updateInspectionForm = (inspectionForm) => {
    return axios.put(API_VERSION + `inspection_forms/${inspectionForm.inspection_form.id}`, inspectionForm, headers);
};

export const deleteInspectionForm = (id) => {
    return axios.delete(API_VERSION + `inspection_forms/${id}`, headers);
};

export const deleteInspectionFormSection = (id) => {
    return axios.delete(API_VERSION + `inspection_forms/destroy_section/${id}`, headers);
};

export const deleteInspectionFormLineItem = (id) => {
    return axios.delete(API_VERSION + `inspection_forms/destroy_line_item/${id}`, headers);
};

export const getRatings = (data) => {
    return axios.get(API_VERSION + `rating_types?hide=${data.hide}`, headers);
};

export const updateRating = (rating) => {
    return axios.put(API_VERSION + `rating_types/${rating.rating_type.id}`, rating.rating_type, headers);
};

export const getEditRating = (id) => {
    return axios.get(API_VERSION + `rating_types/${id}`, headers);
};

export const deleteRating = (id) => {
    return axios.delete(API_VERSION + `rating_types/${id}`, headers);
};

export const addNewRating = (newRating) => {
    return axios.post(API_VERSION + `rating_types`, newRating, headers);
};

export const deleteRatingLineItem = (IdsToDelete) => {
    const ids = IdsToDelete.map((r) => r.lineItemId);
    return axios.delete(API_VERSION + `rating_types/delete_rating_option?rating_option_ids=${ids}`, headers);
};

export const addNewCompany = (company) => {
    return axios.post(API_VERSION + 'companies', company, headers);
};

export const updateCompany = (company) => {
    const id = company.company.id;
    delete company.company.id;
    return axios.put(API_VERSION + `companies/${id}`, company, headers);
}

export const getCompanyPresignedUrl = (extention) => {
    const body = { ext: extention };

    return axios.get(API_VERSION + `companies/presigned_photo_url?ext=${extention}`, body, headers);
}
export const getCompanies = (values, company_no_of_rows, page, sorted, filtered) => {
    const Sorted = JSON.stringify(sorted);
    let active
    if (values.active === 'true') {
        active = true
    } else if (values.active === 'false') {
        active = false
    } else {
        active = values.active
    }
    const user = getUser();
    return axios.get(API_VERSION + `companies?&company_no_of_rows=${company_no_of_rows ? company_no_of_rows : ''}${values.name ? '&company_name=' + values.name : ''}&active_status=${active ? 'active' : 'inactive'}${values.account_id ? '&account_id=' + values.account_id.value : ''}&mode=${user.mode}${page ? '&page=' + page : ''}${Sorted ? '&column_name=' + Sorted : ''}${values?.matrix ? '&tier=' + values.matrix : ''}`, headers);
}

export const getUserPaginationCompanies = (values, company_no_of_rows, page, sorted, filtered, userId) => {
    const Sorted = JSON.stringify(sorted);

    return axios.get(
        API_VERSION +
        `users/${userId}/admin_companies?${values.name ? 'company_name=' + values.name : ''}${company_no_of_rows ? '&company_no_of_rows=' + company_no_of_rows : ''
        }${page ? '&page=' + page : ''}${Sorted ? '&column_name=' + Sorted : ''}`,
        headers
    );
};

export const getCompaniesDropDownList = () => {
    return axios.get(API_VERSION + `companies/list`, headers);
};

export const getEditCompany = (id) => {
    return axios.get(API_VERSION + `companies/${id}`, headers);
};

export const deleteCompany = (id) => {
    return axios.delete(API_VERSION + `companies/${id}`, headers);
};

export const getCompanyUsers = (companyId, values, company_user_no_of_rows, page, sorted, filtered) => {
    const Sorted = JSON.stringify(sorted);
    // const email = values && values.email ? values.email.replace('+', "%2B") : '';

    return axios.get(API_VERSION + `companies/${companyId}/account_list${company_user_no_of_rows ? '?company_user_no_of_rows=' + company_user_no_of_rows : ''}${page ? '&page=' + page : ''}${values.account_id ? '&account_id=' + values.account_id.value : ''}${Sorted ? '&column_name=' + Sorted : ''}`)
}

export const addCompanyUsers = (id, body) => {
    return axios.post(API_VERSION + `companies/${id}/add_company_users`, body, headers);
};

export const deleteCompanyUser = (userId, companyId) => {
    return axios.delete(API_VERSION + `users/${userId}/destroy_company_user?company_id=${companyId}`, headers);
};

export const updateQuoteFormClient = (accessToken, quoteId, body) => {
    const token = storage.get('token');
    if (token) {
        return axios.put(API_VERSION + `quotes/${quoteId}/confirmed_quote`, body, {
            headers: {
                // 'Authorization': `bearer ${accessToken}`,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
    } else {
        return axios.put(API_VERSION + `quotes/${quoteId}/confirmed_quote`, body, {
            headers: {
                'Authorization': `bearer ${accessToken}`,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
    }
}

export const loginUserModeSelection = (user_id, mode) => {
    return axios.put(API_VERSION + `users/${user_id}/usermode?mode=` + mode, headers);
};

export const getModeAccounts = (mode) => {
    return axios.get(API_VERSION + `accounts/get_all_account/account_mode?mode=` + mode, headers);
}
export const getCompanyAccounts = (mode) => {
    return axios.get(API_VERSION + `companies/account_list?mode=` + mode, headers);
}
export const getInspectionDashboardData = (DataType, date) => {
    const user = getUser();
    return axios.get(
        API_VERSION + `dashboard/inspection?option=${DataType}&date=${date}&mode=${user.mode}`,
        headers
    );
};
export const getTicketDashboardData = (DataType, date) => {
    const user = getUser();
    return axios.get(
        API_VERSION + `dashboard/ticket?option=${DataType}&date=${date}&mode=${user.mode}`,
        headers
    );
};
export const getQuoteDashboardData = (DataType, date) => {
    const user = getUser();
    return axios.get(
        API_VERSION + `dashboard/quote?option=${DataType}&date=${date}&mode=${user.mode}`,
        headers
    );
};

export const getLogbookDashboardData = (DataType, date) => {
    const user = getUser();
    return axios.get(
        API_VERSION + `dashboard/logbook?option=${DataType}&date=${date}&mode=${user.mode}`,
        headers
    );
};

export const getLogbookHistoryDashboard = () => {
    return axios.get(API_VERSION + `logbook/dashboard`, headers);
};

export const getClientDashboardData = (DataType, date) => {
    const user = getUser();
    return axios.get(
        API_VERSION + `inspections/inspection_dashboard?option=${DataType}&date=${date}&mode=${user.mode}`,
        headers
    );
};

export const SignIn = (credentials) => {
    return axios.post(API_VERSION + 'users/sign_in', credentials, headers);
};

export const ForgetPassowrd = (credentials) => {
    return axios.post(API_VERSION + 'users/password', credentials, headers);
};

export const resetPassword = (credentials) => {
    return axios.put(API_VERSION + 'users/password', credentials, headers);
};

export const updateUserPassword = (id, credentials) => {
    return axios.put(API_VERSION + 'users/changepassword/' + id, credentials, headers);
};

export const logout = () => {
    return axios.put(API_VERSION + 'users/logout', '', headers);
};

export const getPositions = (data) => {
    return axios.get(API_VERSION + `positions?hide=${data.hide}`, headers);
};

export const updateProfile = (user) => {
    return axios.put(API_VERSION + `users/${user.user.id}`, user, headers);
};

export const getUserPresignedUrl = (extention) => {
    return axios.get(API_VERSION + `users/presigned_photo_url?ext=.${extention}`, headers);
};

export const changePassword = (user) => {
    return axios.put(API_VERSION + `users/update_password`, user, headers);
};

// export const updateUserPassword = (id, credentials) => {
//     return axios.put(API_VERSION + 'users/changepassword/' + id, credentials, headers)
// };

export const AddNewUser = (user) => {
    return axios.post(API_VERSION + 'users', user, headers);
};

export const updateUserPageSizeChange = (rows) => {
    return axios.put(API_VERSION + 'users/update_rows', rows, headers);
};

export const addNewAccount = (account) => {
    return axios.post(API_VERSION + 'accounts', account, headers);
};

export const updateUser = (user) => {
    return axios.put(API_VERSION + `users/${user.user.id}`, user, headers);
};

export const getAllUsers = () => {
    return axios.get(API_VERSION + `users/get_all_users`, headers);
};

export const getAllModeAccountUsers = (mode) => {
    return axios.get(API_VERSION + `users/get_all_usermode?mode=${mode}`, headers);
};

export const getInspectionFormForAccount = (id) => {
    const user = getUser();
    return axios.get(API_VERSION + `inspections/account_inspection?account_id=${id}&mode=${user.mode}`, headers);
};

export const checkSchedule = (body) => {
    return axios.get(API_VERSION + `schedules/check?schedule=${JSON.stringify(body.schedules)}`, headers);
};

export const getUsers = (values, user_no_of_rows, page, sorted) => {
    const Sorted = JSON.stringify(sorted);
    let active;
    if (values.active === 'true') {
        active = true;
    } else if (values.active === 'false') {
        active = false;
    } else {
        active = values.active;
    }
    const email = values && values.email ? values.email.replace('+', "%2B") : ''; return axios.get(API_VERSION + `users?creator=${values.name ? values.name : ''}&active_status=${active ? 'active' : 'inactive'}&email=${email}&user_no_of_rows=${user_no_of_rows}${page ? '&page=' + page : ''}${Sorted ? '&column_name=' + Sorted : ''}${values.mode ? '&mode=' + values.mode : ''}${values.role ? '&role=' + values.role : ''}`, headers);
}

export const getEditUser = (id) => {
    return axios.get(API_VERSION + `users/${id}`, headers);
};

export const getAccounts = (values, account_no_of_rows, page, sorted, filtered) => {
    const Sorted = JSON.stringify(sorted);
    const user = getUser();
    let active;
    if (values.active === 'true') {
        active = true;
    } else if (values.active === 'false') {
        active = false;
    } else {
        active = values.active;
    }
    return axios.get(
        API_VERSION +
        `accounts?mode=${user.mode}&active_status=${active ? 'active' : 'inactive'}${values.name ? '&account_name=' + values.name : ''
        }${values.accountNumber ? '&account_number=' + values.accountNumber : ''}${account_no_of_rows ? '&account_no_of_rows=' + account_no_of_rows : ''
        }${page ? '&page=' + page : ''}${Sorted ? '&column_name=' + Sorted : ''}`,
        headers
    );
};

export const getUserPaginationAccounts = (values, account_no_of_rows, page, sorted, filtered, userId) => {
    const Sorted = JSON.stringify(sorted);
    let active;
    const user = getUser();
    if (values.active === 'true') {
        active = true;
    } else if (values.active === 'false') {
        active = false;
    } else {
        active = values.active;
    }
    return axios.get(
        API_VERSION +
        `users/${userId}/admin_accounts?mode=${user.mode}${page ? '&page=' + page : ''}&active_status=${active ? 'active' : 'inactive'
        }${values.name ? '&account_name=' + values.name : ''}${values.accountNumber ? '&account_number=' + values.accountNumber : ''
        }${account_no_of_rows ? '&account_no_of_rows=' + account_no_of_rows : ''}${Sorted ? '&column_name=' + Sorted : ''
        }`,
        headers
    );
};

export const getUserAccounts = (id) => {
    const user = getUser();
    return axios.get(API_VERSION + `users/${id}/user_accounts?mode=${user.mode}`, headers);
};

export const onAccountsPageSizeChange = (rows) => {
    return axios.put(API_VERSION + 'users/update_rows', rows, headers);
};

export const getAccountsPreSignedUrl = (account, extention) => {
    return axios.get(API_VERSION + `accounts/${account.account.id}/presigned_photo_url?ext=.${extention}`);
};

export const updateAccount = (account) => {
    return axios.put(API_VERSION + `accounts/${account.account.id}`, account, headers);
};

export const getEditAccount = (id) => {
    return axios.get(API_VERSION + `accounts/${id}`, headers);
};

export const getAccountUsers = (accountId, values, account_user_no_of_rows, page, sorted, filtered) => {
    const Sorted = JSON.stringify(sorted);
    const email = values && values.email ? values.email.replace('+', "%2B") : '';
    return axios.get(API_VERSION + `accounts/${accountId}/account_users?${account_user_no_of_rows ? 'account_user_no_of_rows=' + account_user_no_of_rows : ''}${email ? '&email=' + email : ''}${page ? '&page=' + page : ''}${Sorted ? '&column_name=' + Sorted : ''}`, headers)
}
export const getAccountUsersInfoPage = (accountId, values, account_user_no_of_rows, page, sorted, filtered) => {
    const Sorted = JSON.stringify(sorted);
    const email = values && values.email ? values.email.replace('+', "%2B") : '';
    const activeStatus = values && values.active_status ? values.active_status : '';
    return axios.get(API_VERSION + `accounts/${accountId}/account_users/user_info${page ? '?page=' + page : ''}${account_user_no_of_rows ? '&account_user_no_of_rows=' + account_user_no_of_rows : ''}${email ? '&email=' + email : ''}${Sorted ? '&column_name=' + Sorted : ''}${!(activeStatus == '') ? '&active_status=' + activeStatus : ''}`, headers)
}

export const getAccountUsersInfo = (accountId) => {
    return axios.get(API_VERSION + `accounts/${accountId}/account_users_info`, headers);
};

export const addAccountUsers = (accountId, body) => {
    return axios.post(API_VERSION + `accounts/${accountId}/add_account_users`, body);
};

export const submitAssociateAccountHolidays = (accountId, holidaysId) => {
    return axios.put(API_VERSION + `accounts/${accountId}/add_holidays`, holidaysId, headers);
};

export const submitAssociateAccountJobs = (accountId, inspectionIds) => {
    return axios.put(API_VERSION + `accounts/${accountId}/add_inspection_forms`, inspectionIds, headers);
};

export const getAccountHolidays = (params, accountId) => {
    debugger
    return axios.get(API_VERSION + `accounts/${accountId}/account_holidays`, { params }, headers);
};

export const deleteAccountUser = (userId, accountId) => {
    return axios.delete(API_VERSION + `users/${userId}/destroy_account_user?account_id=${accountId}`, headers);
};

export const getAllRegions = (data) => {
    return axios.get(API_VERSION + `regions?hide=${data.hide}`, headers);
};

export const deleteUser = (id) => {
    return axios.delete(API_VERSION + `users/${id}`, headers);
};

export const deleteAccount = (id) => {
    return axios.delete(API_VERSION + `accounts/${id}`, headers);
};

export const getQuote = (id) => {
    return axios.get(API_VERSION + `quotes/${id}`, headers);
};

export const onQuotesPageSizeChange = (rows) => {
    return axios.put(API_VERSION + 'users/update_rows', rows, headers);
};

export const getQuotes = (values, quotes_no_of_rows, page, sorted) => {
    var Sorted;
    if (sorted && sorted.length > 0) {
        Sorted = JSON.stringify(sorted);
    } else {
        Sorted = null;
    }
    const user = getUser();
    return axios.get(
        API_VERSION +
        `quotes${page ? '?page=' + page : ''}${values.isWeek === undefined ? '' : '&isWeek=' + values.isWeek}${values.isMonth === undefined ? '' : '&isMonth=' + values.isMonth
        }${values.isYear === undefined ? '' : '&isYear=' + values.isYear}${values.firstday ? '&from=' + values.firstday : ''
        }${values.lastday ? '&to=' + values.lastday : ''}${values.currentDate ? '&currentDate=' + values.currentDate : ''
        }${values.value && values.value.id ? '&quote_id=' + values.value.id : ''}${values.value && values.value.name ? '&account_name=' + values.value.name : ''
        }${values.value && values.value.region ? '&region=' + values.value.region : ''}${values.value && values.value.Status
            ? '&status=' + values.value.Status
            : values.Status
                ? '&status=' + values.Status
                : ''
        }${values.value && values.value.fromDate ? '&from=' + values.value.fromDate : ''}${values.value && values.value.toDate ? '&to=' + values.value.toDate : ''
        }${Sorted ? '&column_name=' + Sorted : ''}${quotes_no_of_rows ? '&quotes_no_of_rows=' + quotes_no_of_rows : ''
        }${'&mode=' + user.mode}`,
        headers
    );
};

export const resendEmailCliked = (id) => {
    return axios.get(API_VERSION + `quotes/${id}/resend_quote_email`, headers);
};

export const getQuoteLog = (id) => {
    return axios.get(API_VERSION + `quotes/${id}`, headers);
};

export const getDropDownAccounts = () => {
    return axios.get(API_VERSION + 'accounts/get_all_account', headers);
};

export const getDropDownAccountsForCompanies = () => {
    return axios.get(API_VERSION + `accounts/get_all_account_for_company`, headers);
};

export const addNewQuote = (quote) => {
    return axios.post(API_VERSION + 'quotes', quote, headers);
};

export const updateQuote = (quote) => {
    return axios.put(API_VERSION + `quotes/${quote.id ? quote.id : quote.quote.id}`, quote, headers);
};

export const approveOrRejectQuote = (id, body) => {
    return axios.put(API_VERSION + `quotes/${id}/approve_or_reject_quote`, body, headers);
};

export const deleteQuote = (id) => {
    return axios.delete(API_VERSION + `quotes/${id}`, headers);
};

export const searchQuote = (status, name) => {
    return axios.get(API_VERSION + `quotes?status=${status}&name=${name}`, headers);
};

export const searchUser = (name, email) => {
    return axios.get(API_VERSION + `users?name=${name}&email=${email}`, headers);
};

export const searchAccounts = (name, email) => {
    return axios.get(API_VERSION + `accounts?name=${name}&email=${email}`, headers);
};

export const addPosition = (position) => {
    return axios.post(API_VERSION + 'positions', position, headers);
};

export const updatePosition = (position, id) => {
    return axios.put(API_VERSION + `positions/${id}`, position, headers);
};

export const deletePositon = (id) => {
    return axios.delete(API_VERSION + `positions/${id}`, headers);
};

export const addRegion = (region) => {
    return axios.post(API_VERSION + 'regions', region, headers);
};

export const updateRegion = (region, id) => {
    return axios.put(API_VERSION + `regions/${id}`, region, headers);
};

export const deleteRegion = (id) => {
    return axios.delete(API_VERSION + `regions/${id}`, headers);
};

export const getAccountAreas = (params, id) => {
    return axios.get(API_VERSION + `accounts/${id}/areas`, { params }, headers);
};
export const getAreaTypes = () => {
    return axios.get(API_VERSION + `area_types`, headers);
};
export const createArea = (body, id) => {
    return axios.post(API_VERSION + `accounts/${id}/areas`, body, headers);
};
export const getArea = (id, area_id) => {
    return axios.get(API_VERSION + `accounts/${id}/areas/${area_id}`, headers);
};
export const getAllLogbookArea = (values, size, page, sorted, id) => {
    return axios.get(API_VERSION + `accounts/${id}/logbook?${size ? 'size=' + size : ''}${page ? '&page=' + page : ''}${sorted ? '&sorted=' + sorted : ''}${values && values.logbook_name ? '&logbook_name=' + values.logbook_name : ''}`, headers);
};

export const getAllLogbook = (values, size, page, sorted) => {
    return axios.get(API_VERSION + `logbook/logs?${size ? 'limit=' + size : ''}${page ? '&page=' + page : ''}${sorted ? '&sorted=' + sorted : ''}${values && values.search ? '&search=' + values.search : ''}${page ? '&page=' + page : ''}${values && values.account_id ? '&job_id=' + values.account_id : ''}${values && values.name ? '&logbook_name=' + values.name : ''}${values && values.fromDate ? '&from=' + values.fromDate : ''}${values && values.toDate ? '&to=' + values.toDate : ''}${values && values.status ? '&status=' + values.status : ''}${values && values.currentDate ? '&currentDate=' + values.currentDate : ''}${values && values.isWeek ? '&isWeek=' + values.isWeek : ''}${values && values.isMonth ? '&isMonth=' + values.isMonth : ''}${values && values.isYear ? '&isYear=' + values.isYear : ''}${values && values.id ? '&id=' + values.id : ''}`, headers);
};


export const getAllLogBookLogsArea = (values, page, limit, sorted, logbookId) => {
    return axios.get(API_VERSION + `logbook/${logbookId}/log?${limit ? 'limit=' + limit : ''}${page ? '&page=' + page : ''}${sorted ? '&sorted=' + sorted : ''}${values && values.name ? '&reviewed_by=' + values.name : ''}${values && values.status ? '&status=' + values.status : ''}${values && values.fromDate ? '&from=' + values.fromDate : ''}${values && values.toDate ? '&to=' + values.toDate : ''}`, headers);
};

export const getOneLogBookArea = (accountId, logbookId) => {
    return axios.get(API_VERSION + `accounts/${accountId}/logbook/${logbookId}`, headers);
};

export const getOneLogBookLogsArea = (logbookId, logId) => {
    return axios.get(API_VERSION + `logbook/${logbookId}/log/${logId}`, headers);
};
export const updateArea = (body, id, area_id) => {
    return axios.put(API_VERSION + `accounts/${id}/areas/${area_id}`, body, headers);
};

export const deleteArea = (areaId, id) => {
    return axios.delete(API_VERSION + `accounts/${id}/areas/${areaId}`, headers);
};
export const deleteLogbookArea = (accountId, logbookId) => {
    return axios.delete(API_VERSION + `accounts/${accountId}/logbook/${logbookId}`, headers);
};

export const importArea = (body, id) => {
    return axios.post(API_VERSION + `accounts/${id}/areas/import`, body, headers);
};

export const importCheckArea = (body, id) => {
    return axios.post(API_VERSION + `accounts/${id}/areas/check`, body, headers);
};

export const getAreaByQR = (id) => {
    return axios.get(`areas/${id}`, headers);
};

export const searchHistory = (body, id) => axios.post(`accounts/${id}/history`, body, headers);

export const getAllHistory = (params, id) => axios.get(`account/${id}`, { params }, headers);

export const createAreaTask = (id) => axios.put(`areas/${id}/create_task`, headers);

export const getFrontlineList = (params) => {
    debugger
    params.sorted = JSON.stringify(params.sorted);
    params.fl_users_no_of_rows = params.size;
    return axios.get(API_VERSION + `frontlines`, { params }, headers);
};

export const getFrontline = (id) => axios.get(API_VERSION + `frontlines/${id}`, headers);

export const createFrontline = (body) => axios.post(API_VERSION + `frontlines`, body, headers);

export const updateFrontline = (body, id) => axios.put(API_VERSION + `frontlines/${id}`, body, headers);

export const deleteFrontline = (body, id) => axios.delete(API_VERSION + `frontlines/${id}`, body, headers);

export const importFrontline = (body) => axios.post(API_VERSION + `frontlines/import`, body, headers);

export const addAreaType = (body) => axios.post(API_VERSION + `area_types`, body, headers);

export const updateAreaType = (body, id) => axios.put(API_VERSION + `area_types/${id}`, body, headers);

export const updateDeficiency = (body, id) => axios.put(API_VERSION + `deficiency/${id}`, body, headers);

export const getDeficiency = (id) => axios.get(`areas/deficiencies/${id}`, headers);

export const deleteAreaType = (id) => axios.delete(API_VERSION + `area_types/${id}`, headers);

export const addFloor = (body) => axios.post(API_VERSION + `floors`, body, headers);

export const addDeficiency = (body) => axios.post(API_VERSION + `deficiency`, body, headers);

export const updateFloor = (body, id) => axios.put(API_VERSION + `floors/${id}`, body, headers);

export const deleteFloor = (id) => axios.delete(API_VERSION + `floors/${id}`, headers);

export const getFloors = () => axios.get(API_VERSION + `floors`, headers);

export const getAllDeficiencies = (body) => axios.get(API_VERSION + `deficiency`, headers);

export const getAllAreaDetails = (token) => axios.get(`accounts/${token}/area_details`, headers);

export const scanAnalytics = (params) => axios.get(API_VERSION + `accounts/scan_analytics`, { params }, headers);

export const getAllAreaData = (data) => {
    return axios.get(API_VERSION + `all_areas${data !== '' ? '?account_id=' + data : ''}`, headers);
};

export const nonLoggedInFrontlineData = () => axios.get(API_VERSION + `/accounts/non_logged_in_frontline_csv`, headers);

export const getAllAreaOfAccount = (params, id) => axios.get(`areas/my_schedule`, { params }, headers);

export const getUpcomingScheduleOnArea = (id) => {
    return axios.get(API_VERSION + `inspections/schedules?area_id=${id}`, headers);
};

export const scheduleStatus = (body, area_id) => axios.put(`areas/timeslots/${area_id}/schedule_status`, body, headers);

export const getHolidaysList = () => {
    return axios.get(API_VERSION + `holidays`, headers);
};

export const getQuotaForQuotes = (data) => {
    return axios.get(API_VERSION + `quotas?account_ids=[ ${data.id}] &date=${data.date}&option=year`, headers);
};

export const addNewHoliday = (data) => {
    return axios.post(API_VERSION + `holidays`, data, headers);
};

export const updateHoliday = (data) => {
    return axios.put(API_VERSION + `holidays/${data.holiday.id}`, data, headers);
};

export const deleteHoliday = (id) => {
    return axios.delete(API_VERSION + `holidays/${id}`, headers);
};

export const setInActiveAreas = (accountid, ids, value) => {
    return axios.put(API_VERSION + `accounts/${accountid}/areas/turn_off_areas?area_ids=[${ids}]&turn_off=${value}`);
};

export const submitCHIReview = (value) => {
    return axios.post(API_VERSION + `accounts/chi_survey`, value, {
        headers: {
            // "Authorization": `bearer ${token}`,
            'Content-Type': 'application/json',
            Accept: 'application/json',
        },
    });
};

export const getAllCHIServyResponse = (chi_no_of_rows, page, date, option, sorted, filtered) => {
    let Sorted = "[]"
    if (sorted !== "") {
        Sorted = JSON.stringify(sorted);
    }
    return axios.get(API_VERSION + `dashboard/chi_survey_responses${page ? '?page=' + page : ''}${chi_no_of_rows ? '&no_of_rows=' + chi_no_of_rows : ''}${date ? '&date=' + date : ''}${option ? '&option=' + option : ''}${Sorted ? '&column_name=' + Sorted : ''}`)
}
export const getCHIDashboard = (date, mode, option) => {
    return axios.get(API_VERSION + `dashboard/chi_dashboard${date ? '?date=' + date : ''}${mode ? '&mode=' + mode : ''}${option ? '&option=' + option : ''}`)
}
export const getAllQRCodes = (account_id) => axios.get(API_VERSION + `accounts/${account_id}/get_all_qr_code_urls`, {}, headers);

export const qqvFeedback = (feedback) => {
    return axios.post(API_VERSION + `inspections/feedback`, { feedback }, headers);
}
export const getAccountExportData = (mode) => axios.get(API_VERSION + `accounts/account_export?mode=${mode}`, {}, headers);

export const getUserExportData = () => axios.get(API_VERSION + `users/export_user`, {}, headers);

export const makeAccountUserAsOwner = (accountId, userId) => {
    return axios.put(API_VERSION + `accounts/${accountId}/owner/${userId}`, headers);
};
export const welcomeMailForUser = (userId) => {
    return axios.put(API_VERSION + `users/${userId}/resend_email`, headers);
}
export const getAssociatedInspections = (accountId, mode) => {
    return axios.get(API_VERSION + `inspection_forms/account_inspection_list?mode=${mode}&account_id=${accountId}`)
}

export const getCustomers = (id) => axios.get(API_VERSION + `quotes/user_info?account_id=${id}`, {}, headers);

export const getChiPreview = (id) => {
    return axios.get(API_VERSION + `chi_survey/${id}`, headers);
}

export const getAccountAdminList = () => {
    return axios.get(API_VERSION + `accounts/admin_list`, headers)
}
export const getInspectionIndex = () => {
    const user = getUser();
    return axios.get(API_VERSION + `inspections/inspection_index${'?mode=' + user.mode}`, headers);
};

export const getUserInspectionIndex = () => {
    const user = getUser();
    return axios.get(API_VERSION + `inspections/user_inspection_index${'?mode=' + user.mode}`, headers);
};

export const inspectionCSvCliked = (id) => {
    return axios.get(API_VERSION + `inspections/${id}/inventory_export`, headers);
};

export const getInspectionPdf = (id) => {
    return axios.get(API_VERSION + `inspections/${id}/download`, headers);
};
export const getKpiDashboardData = (DataType, date, values, quarter) => {
    const companyId = storage.get('companyId')
    const user = getUser();
    return axios.get(API_VERSION + `kpi/inspection_count?company_id=${companyId}${DataType === 'threeMonths' ? '' : '&option=' + DataType}${quarter ? '&quarter=' + quarter : ''}&date=${date}T00:00:00Z${values && values.inspection_account && values.inspection_account.value ? '&account_id=' + values.inspection_account.value : ''}${values && values.inspection_name ? '&inspection_form_name=' + values.inspection_name : ''}${values && values.inspection_region ? '&region=' + values.inspection_region : ''}${values && values.inspection_user && values.inspection_user.value ? '&inspected_by_id=' + values.inspection_user.value : ''}&mode=${user.mode}`, headers);
}
export const getKpiInspectionQQvScoreDashboardData = (DataType, date, values, quarter) => {
    const companyId = storage.get('companyId')
    const user = getUser();
    return axios.get(API_VERSION + `kpi/inspection_qqv_score?company_id=${companyId}${DataType === 'threeMonths' ? '' : '&option=' + DataType}${quarter ? '&quarter=' + quarter : ''}&date=${date}T00:00:00Z${values && values.inspection_account && values.inspection_account.value ? '&account_id=' + values.inspection_account.value : ''}${values && values.inspection_name ? '&inspection_form_name=' + values.inspection_name : ''}${values && values.inspection_region ? '&region=' + values.inspection_region : ''}${values && values.inspection_user && values.inspection_user.value ? '&inspected_by_id=' + values.inspection_user.value : ''}&mode=${user.mode}`, headers);
}
export const getKpiInspectionQQvRootcaseDashboardData = (DataType, date, values, quarter) => {
    const companyId = storage.get('companyId')
    const user = getUser();
    return axios.get(API_VERSION + `kpi/inspection_qqv_rootcase?company_id=${companyId}${DataType === 'threeMonths' ? '' : '&option=' + DataType}${quarter ? '&quarter=' + quarter : ''}&date=${date}T00:00:00Z${values && values.inspection_account && values.inspection_account.value ? '&account_id=' + values.inspection_account.value : ''}${values && values.inspection_name ? '&inspection_form_name=' + values.inspection_name : ''}${values && values.inspection_region ? '&region=' + values.inspection_region : ''}${values && values.inspection_user && values.inspection_user.value ? '&inspected_by_id=' + values.inspection_user.value : ''}&mode=${user.mode}`, headers);
}
export const getKpiInspectionDeficencyDashboardData = (DataType, date, values, quarter) => {
    const companyId = storage.get('companyId')
    const user = getUser();
    return axios.get(API_VERSION + `kpi/inspection_deficency_graph?company_id=${companyId}${DataType === 'threeMonths' ? '' : '&option=' + DataType}${quarter ? '&quarter=' + quarter : ''}&date=${date}T00:00:00Z${values && values.inspection_account && values.inspection_account.value ? '&account_id=' + values.inspection_account.value : ''}${values && values.inspection_name ? '&inspection_form_name=' + values.inspection_name : ''}${values && values.inspection_region ? '&region=' + values.inspection_region : ''}${values && values.inspection_user && values.inspection_user.value ? '&inspected_by_id=' + values.inspection_user.value : ''}&mode=${user.mode}`, headers);
}
export const getKpiInspectionDateDashboardData = (DataType, date, values, quarter) => {
    const companyId = storage.get('companyId')
    const user = getUser();
    return axios.get(API_VERSION + `kpi/inspection_date?company_id=${companyId}${DataType === 'threeMonths' ? '' : '&option=' + DataType}${quarter ? '&quarter=' + quarter : ''}&date=${date}T00:00:00Z${values && values.inspection_account && values.inspection_account.value ? '&account_id=' + values.inspection_account.value : ''}${values && values.inspection_name ? '&inspection_form_name=' + values.inspection_name : ''}${values && values.inspection_region ? '&region=' + values.inspection_region : ''}${values && values.inspection_user && values.inspection_user.value ? '&inspected_by_id=' + values.inspection_user.value : ''}&mode=${user.mode}`, headers);
}
export const getKpiTopInspectionDashboardData = (DataType, date, values, quarter) => {
    const companyId = storage.get('companyId')
    const user = getUser();
    return axios.get(API_VERSION + `kpi/top_inspection?company_id=${companyId}${DataType === 'threeMonths' ? '' : '&option=' + DataType}${quarter ? '&quarter=' + quarter : ''}&date=${date}T00:00:00Z${values && values.inspection_account && values.inspection_account.value ? '&account_id=' + values.inspection_account.value : ''}${values && values.inspection_name ? '&inspection_form_name=' + values.inspection_name : ''}${values && values.inspection_region ? '&region=' + values.inspection_region : ''}${values && values.inspection_user && values.inspection_user.value ? '&inspected_by_id=' + values.inspection_user.value : ''}&mode=${user.mode}`, headers);
}
export const getKpiInspectioninAccountDashboardData = (DataType, date, values, quarter) => {
    const companyId = storage.get('companyId')
    const user = getUser();
    return axios.get(API_VERSION + `kpi/inspection_account?company_id=${companyId}${DataType === 'threeMonths' ? '' : '&option=' + DataType}${quarter ? '&quarter=' + quarter : ''}&date=${date}T00:00:00Z${values && values.inspection_account && values.inspection_account.value ? '&account_id=' + values.inspection_account.value : ''}${values && values.inspection_name ? '&inspection_form_name=' + values.inspection_name : ''}${values && values.inspection_region ? '&region=' + values.inspection_region : ''}${values && values.inspection_user && values.inspection_user.value ? '&inspected_by_id=' + values.inspection_user.value : ''}&mode=${user.mode}`, headers);
}
export const getKpiInspectionPieChartDashboardData = (DataType, date, values, quarter) => {
    const companyId = storage.get('companyId')
    const user = getUser();
    return axios.get(API_VERSION + `kpi/pie_chart?company_id=${companyId}${DataType === 'threeMonths' ? '' : '&option=' + DataType}${quarter ? '&quarter=' + quarter : ''}&date=${date}T00:00:00Z${values && values.inspection_account && values.inspection_account.value ? '&account_id=' + values.inspection_account.value : ''}${values && values.inspection_name ? '&inspection_form_name=' + values.inspection_name : ''}${values && values.inspection_region ? '&region=' + values.inspection_region : ''}${values && values.inspection_user && values.inspection_user.value ? '&inspected_by_id=' + values.inspection_user.value : ''}&mode=${user.mode}`, headers);
}
export const getKpiInspectioCustomerDashboardData = (DataType, date, values, quarter) => {
    const companyId = storage.get('companyId')
    const user = getUser();
    return axios.get(API_VERSION + `kpi/inspection_customer?company_id=${companyId}${DataType === 'threeMonths' ? '' : '&option=' + DataType}${quarter ? '&quarter=' + quarter : ''}&date=${date}T00:00:00Z${values && values.inspection_account && values.inspection_account.value ? '&account_id=' + values.inspection_account.value : ''}${values && values.inspection_name ? '&inspection_form_name=' + values.inspection_name : ''}${values && values.inspection_region ? '&region=' + values.inspection_region : ''}${values && values.inspection_user && values.inspection_user.value ? '&inspected_by_id=' + values.inspection_user.value : ''}&mode=${user.mode}`, headers);
}
export const getKpiInspectionQQvDashboardData = (DataType, date, values, quarter) => {
    const companyId = storage.get('companyId')
    const user = getUser();
    return axios.get(API_VERSION + `kpi/inspection_qqv?company_id=${companyId}${DataType === 'threeMonths' ? '' : '&option=' + DataType}${quarter ? '&quarter=' + quarter : ''}&date=${date}T00:00:00Z${values && values.inspection_account && values.inspection_account.value ? '&account_id=' + values.inspection_account.value : ''}${values && values.inspection_name ? '&inspection_form_name=' + values.inspection_name : ''}${values && values.inspection_region ? '&region=' + values.inspection_region : ''}${values && values.inspection_user && values.inspection_user.value ? '&inspected_by_id=' + values.inspection_user.value : ''}&mode=${user.mode}`, headers);
}

export const getAllCompanyList = () => {
    const user = getUser();
    return axios.get(API_VERSION + `accounts/company_list?mode=${user.mode}`, headers);
}


export const getKpiTicketCountDashboardData = (DataType, date, values) => {
    const companyId = storage.get('companyId')
    const user = getUser();
    return axios.get(API_VERSION + `kpi/ticket_count?company_id=${companyId}&option=${DataType}&date=${date}T00:00:00Z${values && values.account_id && values.account_id.value ? '&account_id=' + values.account_id.value : ''}${values && values.category_id ? '&category_id=' + values.category_id : ''}${values && values.created_by ? '&created_by=' + values.created_by : ''}${values && values.assigned_to ? '&assigned_to=' + values.assigned_to : ''}&mode=${user.mode}`, headers);
}

export const getKpiTicketGraphDashboardData = (DataType, date, values) => {
    const companyId = storage.get('companyId')
    const user = getUser();
    return axios.get(API_VERSION + `kpi/tickets_graph?company_id=${companyId}&option=${DataType}&date=${date}T00:00:00Z${values && values.account_id && values.account_id.value ? '&account_id=' + values.account_id.value : ''}${values && values.category_id ? '&category_id=' + values.category_id : ''}${values && values.created_by ? '&created_by=' + values.created_by : ''}${values && values.assigned_to ? '&assigned_to=' + values.assigned_to : ''}&mode=${user.mode}`, headers);
}

export const getKpiTicketDaysOpenDashboardData = (DataType, date, values) => {
    const companyId = storage.get('companyId')
    const user = getUser();
    return axios.get(API_VERSION + `kpi/ticket_days_open?company_id=${companyId}&option=${DataType}&date=${date}T00:00:00Z${values && values.account_id && values.account_id.value ? '&account_id=' + values.account_id.value : ''}${values && values.category_id ? '&category_id=' + values.category_id : ''}${values && values.created_by ? '&created_by=' + values.created_by : ''}${values && values.assigned_to ? '&assigned_to=' + values.assigned_to : ''}&mode=${user.mode}`, headers);
}

export const getKpiTicketHighUsersDashboardData = (DataType, date, values) => {
    const companyId = storage.get('companyId')
    const user = getUser();
    return axios.get(API_VERSION + `kpi/ticket_high_users?company_id=${companyId}&option=${DataType}&date=${date}T00:00:00Z${values && values.account_id && values.account_id.value ? '&account_id=' + values.account_id.value : ''}${values && values.category_id ? '&category_id=' + values.category_id : ''}${values && values.created_by ? '&created_by=' + values.created_by : ''}${values && values.assigned_to ? '&assigned_to=' + values.assigned_to : ''}&mode=${user.mode}`, headers);
}

export const getKpiTicketJobsDashboardData = (DataType, date, values) => {
    const companyId = storage.get('companyId')
    const user = getUser();
    return axios.get(API_VERSION + `kpi/ticket_jobs?company_id=${companyId}&option=${DataType}&date=${date}T00:00:00Z${values && values.account_id && values.account_id.value ? '&account_id=' + values.account_id.value : ''}${values && values.category_id ? '&category_id=' + values.category_id : ''}${values && values.created_by ? '&created_by=' + values.created_by : ''}${values && values.assigned_to ? '&assigned_to=' + values.assigned_to : ''}&mode=${user.mode}`, headers);
}


// new kpi inspection data 

export const getKpiInspectionCount = (body) => {
    // const companyId = storage.get('companyId')
    // const user = getUser();
    return axios.post(API_VERSION + `kpi/inspections/count`, body, headers);
}
export const getKpiAvarageScore = (body) => {

    return axios.post(API_VERSION + `kpi/inspections/average_score`, body, headers);
}
export const getKpiTopInspection = (body) => {
    return axios.post(API_VERSION + `kpi/inspections/top_inspectors`, body, headers);
}
export const getKpiQualityInspection = (body) => {
    return axios.post(API_VERSION + `kpi/inspections/quality`, body, headers);
}

export const getKpiAverageSectionQuality = (body) => {
    return axios.post(API_VERSION + `kpi/inspections/avg_section_quality`, body, headers);
}

export const getAvgModalData = (body) => {
    return axios.post(API_VERSION + `kpi/inspections/avg_lineitem_quality`, body, headers);
}

export const getQualityInsModalData = (body) => {
    return axios.post(API_VERSION + `kpi/inspections/quality/detail`, body, headers);
}

export const getAverageGraphInspections = (body) => {
    return axios.post(API_VERSION + `kpi/inspections/average_score_detail`, body, headers);
}

export const getKpiAverageSectionSafety = (body) => {
    return axios.post(API_VERSION + `kpi/inspections/avg_section_safety`, body, headers);
}
// criteria api start here

export const getCriteria = (id) => {
    const user = getUser();
    return axios.get(API_VERSION + `criteria/${id}`, headers);
};

export const getCriteriaList = (data) => {
    const user = getUser();
    return axios.get(API_VERSION + `criteria?hide=${data.hide}`, headers);
};

export const createCriteries = (body) => {
    const user = getUser();
    return axios.post(API_VERSION + `criteria`, body, headers);
};

export const editCriteria = (body, id) => {
    const user = getUser();
    return axios.put(API_VERSION + `criteria/${id}`, body, headers);
};

export const deleteCriteria = (id) => {
    const user = getUser();
    return axios.delete(API_VERSION + `criteria/${id}`, headers);
};
export const addNewSchedule = (schedule) => {
    return axios.post(API_VERSION + `schedules`, schedule, headers);
};
export const getAllSchedule = (account_id, start_time, end_time) => {
    return axios.get(API_VERSION + `schedules?account_id=${account_id}&start_time=${start_time}&end_time=${end_time}`, headers);
};
export const getScheduleEvents = (startDate, endDate) => {
    return axios.get(API_VERSION + `schedules/events?from=${startDate}&to=${endDate}`, headers);
};
export const getSchedule = (id) => {
    return axios.get(API_VERSION + `schedules/${id}`, headers);
}
export const getAccountSchedule = (id, startDate, endDate) => {
    return axios.get(API_VERSION + `schedules?account_id=${id}&from=${startDate}&to=${endDate}`, headers);
}
export const deleteSchedule = (id) => {
    return axios.delete(API_VERSION + `schedules/${id}?schedule=true`, headers);
};
export const deleteEvent = (id) => {
    return axios.delete(API_VERSION + `schedules/${id}?event=true`, headers);
};
export const getScheduleMailEvents = (id) => {
    return axios.get(API_VERSION + `schedules/events?id=${id}`, headers);
};
export const getScheduleEventDashboard = (dataType, date, schedule_no_of_rows, page, sorted, filtered) => {
    var Sorted;
    if (sorted && sorted.length > 0) {
        Sorted = JSON.stringify(sorted);
    } else {
        Sorted = null;
    }
    return axios.get(API_VERSION + `schedules/dashboard?option=${dataType}&date=${date}&page=${page}&limit=${schedule_no_of_rows}${Sorted ? '&sorted=' + Sorted : ''}`, headers);
};

export const scheduleHistory = (params, accountId) => {
    var Sorted;
    if (params.sorted && params.sorted.length > 0) {
        Sorted = JSON.stringify(params.sorted);
    } else {
        Sorted = null;
    }
    delete params.sorted;
    params.fl_users_no_of_rows = params.size;
    if (accountId) {
        return axios.get(API_VERSION + `schedules/logs?account_id=${accountId}`, { params }, headers);
    } else {
        return axios.get(API_VERSION + `schedules/logs`, { params }, headers);
    }
}
export const onTicketExportClicked = (body) => {
    debugger;
    return axios.get(API_VERSION + `tickets/export${body.mode ? '?mode=' + body.mode : ''
        }${body.assigned_to ? '&assigned_to=' + body.assigned_to : ''
        }${body.category ? '&category=' + body.category.value : ''
        }${body.created_by ? '&created_by=' + body.created_by : ''
        }${body.description ? '&description=' + body.description : ''
        }${body.fromDate ? '&from=' + body.fromDate : ''
        }${body.id ? '&id=' + body.id : ''
        }${body.job ? '&job=' + body.job : ''
        }${body.status ? '&status=' + body.status : ''
        }${body.subject ? '&subject=' + body.subject : ''
        }${body.toDate ? '&to=' + body.toDate : ''
        }`,
        headers);
}
export const checkOwnerInfo = () => {
    return axios.get(API_VERSION + `inspections/account_owner`, headers);
};

export const getLatestInspections = (inspection_form_id, id) => {
    const user = getUser();
    return axios.get(API_VERSION + `inspections/latest_inspections?inspection_form_id=${inspection_form_id}&mode=${user.mode}&account_id=${id}`, headers);
};

export const createLogbook = (body) => {
    return axios.post(API_VERSION + `accounts/${body.id}/logbook`, body.form, headers);
};

export const updateLogbook = (body) => {
    return axios.put(API_VERSION + `accounts/${body.id}/logbook/${body.area_id}`, body.form, headers);
};

export const getLogbookPresignedUrl = (logId, extentions) => {
    const body = { ext: extentions };
    return axios.put(API_VERSION + `logbook/${logId}/presigned_url`, body, headers);
};

export const createLogbookLog = (logId,logbookId, logBody) => {
    if (logbookId) {
        return axios.put(API_VERSION + `logbook/${logId}/log/${logbookId}`, logBody, headers);
    } else {
        return axios.post(API_VERSION + `logbook/${logId}/log`, logBody, headers);
    }
};

export const getAllLoghistoryAreaDetails = (token, id) => {
    return axios.get(`logs/${token}/logbook_details`, headers);
};

export const searchLogHistory = (limit, page, id, values) => {
    return axios.get(`logs/${id}/history?${limit ? 'limit=' + limit : ''}${page ? '&page=' + page : ''}${values && values.value ? '&logbook_name=' + values.value : ''}`, headers);
};
export const submitFeedback = (feedback, id) => {
    return axios.post(API_VERSION + `pegassure_feedback/${id}`, feedback, headers);
};

export const submitFeedbackPresignUrl = (feedback, id, extentions) => {
    const body = { ext: extentions };
    return axios.put(
        API_VERSION + `pegassure_feedback/${id}/presigned_url`,
        body,
        headers
    );
};

export const getPegassureFeedbacks = (values, feedbacks_no_of_rows, page, Sorted, filtered) => {

    return axios.get(API_VERSION + `pegassure_feedback?page=${page
        }${feedbacks_no_of_rows ? '&limit=' + feedbacks_no_of_rows : ''
        }${values.start_time ? '&start_time=' + values.start_time : ''
        }${values.end_time ? '&end_time=' + values.end_time : ''
        }${values.review ? '&rating=' + values.review : ''
        }${values.area_name ? '&area_name=' + values.area_name : ''
        }${values.job_name ? '&job_name=' + values.job_name : ''
        }${values.from ? '&from=' + values.from : ''
        }${values.to ? '&to=' + values.to : ''
        }${values.day ? '&day=' + values.day : ''
        }${values.to_day ? '&to_day=' + values.to_day : ''
        }${values.attachment ? '&attachments=' + values.attachment : ''
        }${Sorted && Sorted.length > 0 && Sorted[0].id ? '&column_name=' + Sorted[0].id : ''
        }${Sorted && Sorted.length > 0 && Sorted[0].desc ? '&sort=desc' : '&sort=asc'}`, headers);
};

export const getFeedback = (id) => {
    return axios.get(API_VERSION + `pegassure_feedback/${id}`, headers);
};

export const getAllLogbookForFrontline = (values, size, page, sorted) => {debugger
    return axios.get(API_VERSION + `logbook/users/logs?${size ? 'limit=' + size : ''}${values && values.search ? '&search=' + values.search : ''}${page ? '&page=' + page : ''}${sorted ? '&sorted=' + sorted : ''}${values && values.account_id ? '&job_id=' + values.account_id : ''}${values && values.name ? '&logbook_name=' + values.name : ''}${values && values.fromDate ? '&from=' + values.fromDate : ''}${values && values.toDate ? '&to=' + values.toDate : ''}${values && values.status ? '&status=' + values.status : ''}`, headers);
};

export const importLogbookArea = (body, id) => {
    return axios.post(API_VERSION + `accounts/${id}/logbook/import`, body, headers);
};

export const importCheckLogbookArea = (body, id) => {
    return axios.post(API_VERSION + `accounts/${id}/logbook/import/check`, body, headers);
};

export const createNewScheduleTicket = (body, isDeepClean) => {
    debugger
    if (isDeepClean) {
        return axios.post(API_VERSION + `schedules/ticket`, body, headers);
    } else {
        return axios.post(API_VERSION + `schedules/ticket`, body, headers);
    }
};

export const getPreviewSingleTicket = (id) => {
    return axios.get(API_VERSION + `schedules/tickets/ticket/${id}`, headers);
};

export const getScheduleTicketEvents = (startDate, endDate, id) => {
    return axios.get(API_VERSION + `schedules/ticket/events?${id ? 'account_id=' + id : ''}&from=${startDate}&to=${endDate}`, headers);
};

export const getOneScheduleTicketEventInfo = (id) => {
    return axios.get(API_VERSION + `schedules/ticket/schedule/${id}`, headers);
}

export const deleteCurrentScheduleTicketEvent = (id) => {
    return axios.delete(API_VERSION + `schedules/ticket/${id}`, headers);
};

export const deleteEntireScheduleTicketEvents = (id) => {
    return axios.delete(API_VERSION + `schedules/ticket/${id}?schedule=true`, headers);
};

export const getScheduleTicketPresignedUrl = (scheduleTicket, extentions) => {
    const body = { ext: extentions };
    return axios.patch(API_VERSION + `schedules/ticket/${scheduleTicket.created_by}/presigned_url`, body, headers);
};

export const scheduleTicketHistory = (params, accountId) => {
    var Sorted;
    if (params.sorted && params.sorted.length > 0) {
        Sorted = JSON.stringify(params.sorted);
    } else {
        Sorted = null;
    }
    delete params.sorted;
    params.fl_users_no_of_rows = params.size;
    if (accountId) {
        return axios.get(API_VERSION + `schedules/ticket/logs?account_id=${accountId}`, { params }, headers);
    } else {
        return axios.get(API_VERSION + `schedules/ticket/logs`, { params }, headers);
    }
}

export const deletePreviewScheduledTicket = (id) => {
    return axios.delete(API_VERSION + `tickets/${id}`, headers);
};

export const getAPIAccess = (values, company_no_of_rows, page, sorted, filtered) => {
    const Sorted = JSON.stringify(sorted);
    let active
    if (values.active === 'true') {
        active = true
    } else if (values.active === 'false') {
        active = false
    } else {
        active = values.active
    }
    const user = getUser();
    return axios.get(API_VERSION + `companies/access?${company_no_of_rows ? '&limit=' + company_no_of_rows : ''}${values.name ? '&company_name=' + values.name : ''}&active_status=${active ? 'active' : 'inactive'}${values.account_id ? '&access_type=' + values.account_id.value : ''}&mode=${user.mode}${page ? '&page=' + page : ''}${Sorted ? '&column_name=' + Sorted : ''}`, headers);
}

export const addNewAPIAccess = (company) => {
    debugger
    return axios.post(API_VERSION + 'companies/access', company, headers);
};

export const updateAPIAccess = (company, id) => {
    return axios.put(API_VERSION + `companies/access/${id}`, company, headers);
};

export const getOneAPIAccess = (id) => {
    return axios.get(API_VERSION + `companies/access/${id}`, headers);
};

export const deleteAPIAccess = (id) => {
    return axios.delete(API_VERSION + `companies/access/${id}`, headers);
};

export const getChiSurveyQuarterlyCheckChattels = () => {
    return axios.get(API_VERSION + `chi_survey/check`, headers);
}

export const importTicket = (body) => {
    return axios.post(API_VERSION + `tickets/import`, body, headers);
};

export const getFLUserProfileImagePresignUrl = (extention) => {
    return axios.get(API_VERSION + `users/presigned_photo_url?ext=${extention}`, headers);
};

export const getCustomerOrInspectorQqvQuestions = (params) => {
    return axios.get(API_VERSION + `inspections/feedback_quiz`, { params }, headers);
}

export const inspectorFeedback = (body) => {
    return axios.post(API_VERSION + `inspections/inspector/feedback`, body, headers);
}

/* FL User Shift Attestation Question retrieve GET Call */
export const getPerFLUserShiftAttestation = (params) => {
    return axios.get(API_VERSION + `inspections/feedback_quiz`, { params }, headers);
};

export const getLogbookLogQuestions = (params) => {
    return axios.get(API_VERSION + `inspections/feedback_quiz`, {params},  headers);
};

export const addPCT = (ticket) => {
    return axios.post(API_VERSION + `tickets/pct`, ticket, headers);
};

export const getPCTPresignedUrl = (ticket, extentions) => {
    const body = { ext: extentions };
    return axios.patch(
        API_VERSION + `tickets/pct/${ticket.data.shareable_id}/presigned_photo_url`,
        body,
        headers
    );
};