import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reset } from 'redux-form';
import * as actions from '../../../../../redux/actions/index';
import { routes, address_attributes } from '../../../../../utility/constants/constants';
import { extractExtention, getUser, resetOrientation, getConvertTo24Hours, convertTo24HourFormat, convertDateFormatV4 } from '../../../../../utility/utility';
import NewShiftForm from '../../../../component/TimeTrackerModuleComponent/ShiftComponent/ShiftCrudComponent/NewShiftComponent/NewShiftForm';
import { animateScroll as scroll } from 'react-scroll'
import { decode, encode } from 'base64-arraybuffer';
import errorHandler from '../../../../../utility/errorHandler/errorHandler';
import moment from 'moment';
import { tzUTCDateTimeConverterTool_V1, tzUTCDateTimeConverterTool_V1_Prod, tzUTCTimeConverterTool_V1, tzUTCTimeConverterTool_V1_Prod } from '../../../../../utility/TimezoneOperations/timezoneUtility';
const cloneDeep = require('clone-deep');

class NewShiftContainer extends Component {
    constructor(props) {
        super(props);

    }

    componentWillMount() {

    }

    componentDidMount() {

        if (this.props && this.props.match && this.props.match.params && this.props.match.params.shiftId) {
            this.props.getShiftDetailForSingleFLUser(this.props.match.params.shiftId);
        }

        this.props.getUsersListByTheirRoles({ roles: 'frontline' });

    }

    getSelectedFLUserJobs = (flUserId) => {

        this.props.getJobAccountSitesList({ userId: flUserId, address: true });

    }

    handleSubmit = (values) => {

        if (values) {

            if (values.shiftOrigin) {

                if (values.shiftOrigin.accountIds && values.shiftOrigin.accountIds.length == 0) {
                    errorHandler('Job is Required');
                    return false;
                }

                /* START TIME && END TIME FUNCTIONALITY VALIDATION */
                if (values.shiftOrigin.start_time && values.shiftOrigin.end_time) {
                    let shiftStart = new Date(`${moment(values.shiftOrigin.start_date).format('YYYY-MM-DD')} ${getConvertTo24Hours(values.shiftOrigin.start_time)}`);
                    let shiftEnd = new Date(`${moment(values.shiftOrigin.end_date).format('YYYY-MM-DD')} ${getConvertTo24Hours(values.shiftOrigin.end_time)}`);

                    if (values.shiftOrigin.start_time == values.shiftOrigin.end_time) {
                        errorHandler("Start Time and End Time can't be identical");
                        return false;
                    }
                    if (values.shiftOrigin.start_time.includes('PM') && values.shiftOrigin.end_time.includes('AM')) {
                        errorHandler('Select a time range that falls within a single day');
                        return false;
                    }
                }

            }

        }

        let setData = { data: {} };
        let fetchedValues = cloneDeep(values.shiftOrigin);

        let findRemovedAccountIds = (array1, array2) => {
            return array1.reduce((acc, item1) => {
                const isMatched = array2.some(item2 => item1.id == item2.value);
                if (!isMatched) {
                    acc.push(Number(item1.id));
                }
                return acc;
            }, []);
        };

        /* For capturing arrived duplicate jobs */
        let identifyArrivedDuplicateJobs = (jobsArray) => {
            let accrossedJobId = new Set();
            let repeatedJobIdArray = new Set();
            const originJobIdArray = [];

            jobsArray.forEach(num => {
                if (accrossedJobId.has(num)) {
                    repeatedJobIdArray.add(num);
                } else {
                    accrossedJobId.add(num);
                    originJobIdArray.push(num);
                }
            });

            return {
                duplicateJobId: [...repeatedJobIdArray],
                originJobIdArray
            };
        }

        if (this.props && this.props.match && this.props.match.params && this.props.match.params.shiftId) {
            /* For Edit Flow */

            if (fetchedValues["accountIds"]) {
                setData['data']['accountIds'] = fetchedValues['accountIds'].map(e => Number(e.value));

                // For capturing arrived duplicate jobs
                let capturedJobsErrorHandler = identifyArrivedDuplicateJobs(setData['data']['accountIds']);
                if (capturedJobsErrorHandler && capturedJobsErrorHandler.duplicateJobId && capturedJobsErrorHandler.duplicateJobId.length > 0) {
                    errorHandler('Please remove the same selected Jobs');
                    return false;
                }

                let removedAccountIds = findRemovedAccountIds(this.props.getShiftDetailOfSingleFLUser.accounts, fetchedValues.accountIds);
                if (!(removedAccountIds.length == 0)) {
                    setData["data"]["removeAccountIds"] = removedAccountIds
                }
            }
            setData['data']['user_id'] = Number(fetchedValues['user_id']['value']);
            setData['data']['start_date'] = tzUTCDateTimeConverterTool_V1(fetchedValues['start_date'], convertTo24HourFormat(fetchedValues['start_time']), 'from');
            setData['data']['end_date'] = tzUTCDateTimeConverterTool_V1(fetchedValues['end_date'], convertTo24HourFormat(fetchedValues['end_time']), 'from');
            setData['data']['start_time'] = tzUTCTimeConverterTool_V1(convertDateFormatV4(fetchedValues['start_date']), convertTo24HourFormat(fetchedValues['start_time']));
            setData['data']['end_time'] = tzUTCTimeConverterTool_V1(convertDateFormatV4(fetchedValues['start_date']), convertTo24HourFormat(fetchedValues['end_time']));
            setData['data']['notes'] = fetchedValues['notes'];

            this.props.editFrontlinerShift(setData, this.props.match.params.shiftId);

        } else {
            /* For Create Flow */

            setData['data']['accountIds'] = fetchedValues['accountIds'].map(e => Number(e.value));
            setData['data']['user_id'] = Number(fetchedValues['user_id']['value']);
            setData['data']['start_date'] = tzUTCDateTimeConverterTool_V1(fetchedValues['start_date'], convertTo24HourFormat(fetchedValues['start_time']), 'from');
            setData['data']['end_date'] = tzUTCDateTimeConverterTool_V1(fetchedValues['end_date'], convertTo24HourFormat(fetchedValues['end_time']), 'from');
            setData['data']['start_time'] = tzUTCTimeConverterTool_V1(convertDateFormatV4(fetchedValues['start_date']), convertTo24HourFormat(fetchedValues['start_time']));
            setData['data']['end_time'] = tzUTCTimeConverterTool_V1(convertDateFormatV4(fetchedValues['start_date']), convertTo24HourFormat(fetchedValues['end_time']));
            setData['data']['notes'] = fetchedValues['notes'];

            this.props.checkFrontlinerShift(setData, 'create');

        }

    }

    onCancelClicked = () => {
        this.props.handleCancelClickAction();
        this.props.history.push(routes.SHIFT_SCHEDULE);
    }

    hanldeReVampingShift = (values) => {
        /* For ReInitializing Shift Flow */

        let setData = { data: {} };
        let fetchedValues = cloneDeep(values.shiftOrigin);

        setData['data']['accountIds'] = fetchedValues['accountIds'].map(e => Number(e.value));
        setData['data']['user_id'] = Number(fetchedValues['user_id']['value']);
        setData['data']['start_date'] = tzUTCDateTimeConverterTool_V1(fetchedValues['start_date'], convertTo24HourFormat(fetchedValues['start_time']), 'none');
        setData['data']['end_date'] = tzUTCDateTimeConverterTool_V1(fetchedValues['end_date'], convertTo24HourFormat(fetchedValues['end_time']), 'none');
        setData['data']['start_time'] = tzUTCTimeConverterTool_V1(convertDateFormatV4(fetchedValues['start_date']), convertTo24HourFormat(fetchedValues['start_time']));
        setData['data']['end_time'] = tzUTCTimeConverterTool_V1(convertDateFormatV4(fetchedValues['start_date']), convertTo24HourFormat(fetchedValues['end_time']));
        setData['data']['notes'] = fetchedValues['notes'];

        this.props.createFrontlinerShift(setData);
    }

    render() {
        return (
            <div>

                <NewShiftForm
                    {...this.props}
                    getSelectedFLUserJobs={this.getSelectedFLUserJobs}
                    onSubmit={this.handleSubmit}
                    onCancelClicked={this.onCancelClicked}
                    hanldeReVampingShift={this.hanldeReVampingShift}
                />

            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.authReducer.user,
        isLoading: state.TimeTrackerModReducer.isLoading,
        isEditFLShiftForm: state.TimeTrackerModReducer.isEditFLShiftForm,
        isUpdateFLShiftForm: state.TimeTrackerModReducer.isUpdateFLShiftForm,
        isTimeTrackerModalOpen: state.TimeTrackerModReducer.isTimeTrackerModalOpen,

        jobAccountSitesList: state.TimeTrackerModReducer.jobAccountSitesList,
        frontlineUsersList: state.TimeTrackerModReducer.usersListBySelectedRole,
        getShiftDetailOfSingleFLUser: state.TimeTrackerModReducer.getShiftDetailOfSingleFLUser,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        resetTimeTrackerObjects: () => dispatch(actions.resetTimeTrackerObjects()),
        handleCancelClickAction: () => dispatch(actions.handleCancelClickAction()),
        handleFrequentTimeTracker_openModal: (data) => dispatch(actions.handleFrequentTimeTracker_openModal(data)),
        handleFrequentTimeTracker_closeModal: (data) => dispatch(actions.handleFrequentTimeTracker_closeModal(data)),

        getJobAccountSitesList: (params) => dispatch(actions.getJobAccountSitesList(params)),
        getUsersListByTheirRoles: (params) => dispatch(actions.getUsersListByTheirRoles(params)),
        getShiftDetailForSingleFLUser: (id) => dispatch(actions.getShiftDetailForSingleFLUser(id)),

        checkFrontlinerShift: (data, formType) => dispatch(actions.checkFrontlinerShift(data, formType)),
        createFrontlinerShift: (data) => dispatch(actions.createFrontlinerShift(data)),
        editFrontlinerShift: (data, id) => dispatch(actions.editFrontlinerShift(data, id)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewShiftContainer);
