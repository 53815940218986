import React from "react";
import { connect } from "react-redux";
import { reset } from "redux-form";
import * as actions from "../../../../../redux/actions/index";
import NewJobAssociationForm from "../../../../component/PCTComponent/JobAssociationComponent/NewJobAssosiationFoem/newJobAssociationForm";
import moment from "moment";
const cloneDeep = require("clone-deep");

class NewInventoryItemContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedJobIds: [],
      removeAccountIDs : []
    };
  }


  // updateState(newValue) {
  //   this.setState({ selectedJobIds: newValue });
  // }

  componentDidMount() {
    this.props.getModeAccounts(this.props.user.mode);
    
    if (this.props && this.props.match.params && this.props.match.params.id) {
      //we need to call an API
      this.props.getSingleJobAssociation(this.props.match.params.id);
    }
  }
 
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.accountsList !== prevProps.accountsList) {
      this.setState({
        selectedJobIds:
          
          this.props?.singleJobAssociation?.accounts?.length
            ? this.props.singleJobAssociation?.accounts.map((a)=>a.id)
            : [],
      });
    }
  }

  componentWillUnmount() {
    this.props.resetInventoryObjects();
  }

  jobSelected = (id) => {
    let selectedIds = cloneDeep(this.state.selectedJobIds);

    let searchIndex = this.state.selectedJobIds.findIndex(function(s) {
      return s === id;
    });
    if (searchIndex === -1) {
      this.setState({
        selectedJobIds: [...selectedIds, id],
      });
    } else {
      selectedIds.splice(searchIndex, 1);
      this.setState({
        selectedJobIds: selectedIds,
      });
    }
  };

  setJobCheckValue = (id) => {
    let searchIndex = this.state.selectedJobIds.findIndex(function(s) {
      return s === id;
    });
    if (searchIndex > -1) {
      return true;
    } else {
      return false;
    }
  };

  addNewInventoryItem = (values) => {
    let inventoryItems = cloneDeep(values);
    let item = {};
    if (inventoryItems.item && inventoryItems.item.name) {
      item.name = inventoryItems.item.name.trim();
    }
    if (
      inventoryItems.item &&
      inventoryItems.item.selectedJobs &&
      inventoryItems.item.selectedJobs.length
    ) {
      item.accountIDs = inventoryItems.item.selectedJobs;
      item.type = "ticket";
      item.link = "/public-customer-ticket/new-ticket";
    }
    if(this.props.match.params && this.props.match.params.id){
      var i = 0;
      var entry1;
      if (this.props.singleJobAssociation.accounts) {
          if (this.state.selectedJobIds) {
              while (i < this.props.singleJobAssociation.accounts.length) {
                  entry1 = this.props.singleJobAssociation.accounts[i];
                  if (this.state.selectedJobIds.some(function (entry2) { return entry1.id === entry2; })) {
                      // Found, progress to next
                      ++i;
                  } else {
                      // Not found, store id and splice
                      this.state.removeAccountIDs.push(this.props.singleJobAssociation.accounts[i].id)
                      this.props.singleJobAssociation.accounts.splice(i, 1);
                  }
              }
          }
      }
      if (this.state.removeAccountIDs.length) {
          item.removeAccountIDs = this.state.removeAccountIDs;
      }
      this.props.updateAssociatedJobs({ data: item }, this.props.match.params.id );
    }else{
      this.props.createInventoryItem({ data: item });
    }
    
  };



  onCancelClicked = () => {
    this.props.history.push("/job-association-list");
  };

  render() {
    return (
      <NewJobAssociationForm
        {...this.state}
        {...this.props}
        onSubmit={this.addNewInventoryItem}
        onCancelClicked={this.onCancelClicked}
        setJobCheckValue={this.setJobCheckValue}
        jobSelected={this.jobSelected}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.authReducer.user,
    isLoading: state.JobAssociateReducer.isLoadingForm,
    isEditInventoryItem: state.InventoryReducer.isEditInventoryItem,
    isUpdateInventoryItem: state.InventoryReducer.isUpdateInventoryItem,
    singleInventoryItem: state.InventoryReducer.singleInventoryItem,
    inventoryLocationList: state.InventoryReducer.inventoryLocationList,
    accountsList: state.adminOrSuperAdminReducer.modeAccountsList,
    singleJobAssociation: state.JobAssociateReducer.singleJobAssociation,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    resetInventoryObjects: () => dispatch(actions.resetInventoryObjects()),
    createInventoryItem: (data) => dispatch(actions.createAssociatedJobs(data)),
    updateInventoryItem: (data) => dispatch(actions.updateInventoryItem(data)),
    getSingleInventoryItem: (id) =>
      dispatch(actions.getSingleInventoryItem(id)),
    getAllInventoryLocationList: (data) =>
      dispatch(actions.getAllInventoryLocationList(data)),
    getModeAccounts: (mode) => dispatch(actions.getModeAccounts(mode)),
    getSingleJobAssociation: (id) => dispatch(actions.getSingleJobAssociation(id)),
    updateAssociatedJobs: (data,id) => dispatch(actions.updateAssociatedJobs(data,id)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewInventoryItemContainer);
