import React, { Component } from 'react';
import * as actions from '../../../redux/actions/index';
import { connect } from 'react-redux';
import { reset } from 'redux-form';
import { getUser, makeFirstLetterCapital } from '../../../utility/utility';
import { animateScroll as scroll} from 'react-scroll'
import Criteria from '../../component/Criteria/Criteria';

const cloneDeep = require('clone-deep');
class CriteriaContainer extends Component {
    componentDidMount() { 
        // if (!this.props.criteries) {
            this.props.getCriteriaList({
                hide: false,
            });
        // }
    scroll.scrollToTop();
    }

    addNewCriteria = (values) => {
        if (this.props.isEdit) {
            const val = cloneDeep(values)
            val.criteria['name'] = makeFirstLetterCapital(val?.criteria?.name);
            delete val.criteria.id
            delete val.criteria.active_status
            this.props.editCriteria(val, this.props.criteria.criteria.id);
        } else {
            // values.criteria['active_status'] = 'active';
            values.criteria['name'] = makeFirstLetterCapital(values?.criteria?.name);
            this.props.createCriteries(values);
        }
    }
    closePopup = () => {
        this.props.closePopup();
        this.props.reset('myCriteriaForm');
    }
    render() {

        return (
            <Criteria
                criteries={this.props.criteries ? this.props.criteries : []}
                openDeleteModel={this.props.openDeleteModel}
                addNewCriteria={this.addNewCriteria}
                isEdit={this.props.isEdit}
                isOpenModel={this.props.isOpenModel}
                handleDeleteClose={this.props.handleDeleteClose}
                editCriteriaClicked={this.props.editCriteriaClicked}
                deleteCriteria={this.props.deleteCriteria}
                deleteObject={this.props.deleteObject}
                handleClickDeleteModel={this.props.handleClickDeleteModel}
                isLoading={this.props.isLoading}
                isCriteria={this.props.isCriteria}
                openPopup={this.props.openPopup}
                closePopup={this.closePopup}
                getCriteriaList={this.props.getCriteriaList}
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        criteries: state.adminOrSuperAdminReducer.criteries,
        criteria: state.adminOrSuperAdminReducer.criteria,
        isEdit: state.adminOrSuperAdminReducer.isEdit,
        isOpenModel: state.adminOrSuperAdminReducer.isOpenModel,
        isLoading: state.adminOrSuperAdminReducer.isLoading,
        isCriteria: state.adminOrSuperAdminReducer.isCriteria,
        deleteObject: state.adminOrSuperAdminReducer.deleteObject,
        openDeleteModel: state.adminOrSuperAdminReducer.openDeleteModel
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        handleDeleteClose: () => dispatch(actions.handleDeleteClose()),
        createCriteries: (values) => dispatch(actions.createCriteries(values)),
        editCriteria: (values, id) => dispatch(actions.editCriteria(values, id)),
        deleteCriteria: (id) => dispatch(actions.deleteCriteria(id)),
        closePopup: () => dispatch(actions.closePopup()),
        getCriteriaList: (data) => dispatch(actions.getCriteriaList(data)),
        openPopup: () => dispatch(actions.openPopup()),
        editCriteriaClicked: (criteria) => dispatch(actions.editCriteriaClicked(criteria)),
        handleClickDeleteModel: (criteria) => dispatch(actions.handleClickDeleteModel(criteria)),
        reset: (criteriaForm) => dispatch(reset(criteriaForm)),  // requires form name
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CriteriaContainer);