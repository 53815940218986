import React, { Component } from 'react';
import ClockOutFeedbackComponent from '../../../component/TimeTrackerModuleComponent/ClockInClockOutScreenComponent/ClockOutFeedbackComponent';
import { connect } from 'react-redux';
import * as actions from '../../../../redux/actions/index';
import { extractExtention, } from '../../../../utility/utility';
import { decode, encode } from 'base64-arraybuffer';
import { tzUTCDateTimeConverterTool_V1 } from '../../../../utility/TimezoneOperations/timezoneUtility';
import storage from '../../../../utility/storage';
const cloneDeep = require('clone-deep');
var momentTZ = require('moment-timezone');

class ClockOutFeedbackContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            base64: [],
        }
    }

    componentDidMount() {

        if (this.props && this.props.match && this.props.match.params && this.props.match.params.clkId && this.props.match.params.shiftId) {

            let page, limit, shiftId, date = null;

            page = 1
            limit = 100
            shiftId = this.props.match.params.shiftId;
            let fetchedCurrDateTime = momentTZ(new Date()).format('MM-DD-YYYYTHH:mm:ss').split('T');
            date = tzUTCDateTimeConverterTool_V1(fetchedCurrDateTime[0], fetchedCurrDateTime[1], 'none');

            this.props.getPerFLUserScheduledTaskTickets({ page, limit, shiftId, date });
            this.props.getPerFLUserShiftAttestation({ type: 'attestation', language: "en" });

        }

    }

    componentWillUnmount() {
        this.props.resetTimeTrackerObjects();

    }

    onSignatureAdded = (sigImageFile) => {

        let image = sigImageFile;
        let shiftId = this?.props?.match?.params?.clkId;

        let setData = { data: { shift: {}, signature: null } };

        if (storage.get('missedMealPenaltyForm')) {
            let mealPenalty = JSON.parse(localStorage.getItem('missedMealPenaltyForm'));
            setData.data.mealPenalty = mealPenalty;
        }
        let clkFeedbackForm = JSON.parse(localStorage.getItem('clkFeedbackForm'));
        let clkAttestationForm = JSON.parse(localStorage.getItem('clkAttestationForm'));
        let clkOutTime = JSON.parse(localStorage.getItem('clkOutTime'));
        let fetchedCurrDateTime = momentTZ(new Date()).format('MM-DD-YYYYTHH:mm:ss').split('T');
        let checkOutTime = tzUTCDateTimeConverterTool_V1(fetchedCurrDateTime[0], fetchedCurrDateTime[1], 'none');
        setData.data.shift_id = shiftId;
        setData.data.shift = { check_out_at: checkOutTime, feedback: clkFeedbackForm?.feedback ? clkFeedbackForm?.feedback :" feedback"  };
        setData.data.tickets = clkFeedbackForm?.tickets ? clkFeedbackForm?.tickets : [];
        setData.data.attestation = clkAttestationForm.attestation;
        setData.data.addresses_attributes = clkOutTime?.addresses_attributes ? clkOutTime.addresses_attributes : {}

        this.setState({
            base64: [image]
        }, () => {
            if (this.state.base64.length > 0) {
                const arrayBuffer = [];
                this.state.base64.map((url, i) => {
                    if (!url.photo_urls) {
                        let base_photo = null;
                        const image = url.split(',');
                        base_photo = image[1];
                        arrayBuffer.push(decode(base_photo))
                    }
                })
                const extentions = extractExtention(['img.jpg']);
                this.props.getFLUserSignAttachPresignUrl(shiftId, extentions, arrayBuffer, setData);
            }
        })
    }

    handleAttestation_openReasonModal = (id, index) => {
        this.props.handleFrequentTimeTracker_openDeleteModal({ id, index });
    }

    handleAttestation_closeReasonModal = () => {
        this.props.handleFrequentTimeTracker_closeDeleteModal();
    }

    handlePreviewSubTicket = (data) => {
        this.props.history.push(`/scheduled-tasks/ticket/preview-scheduled-task/${data.subTicketId}`);
    }

    languageHandler = (lang) => {
        this.props.getPerFLUserShiftAttestation({ type: 'attestation', language: lang });
    }

    render() {
        return (
            <>

                <ClockOutFeedbackComponent
                    {...this.props}
                    onSignatureAdded={this.onSignatureAdded}
                    handleAttestation_openReasonModal={this.handleAttestation_openReasonModal}
                    handleAttestation_closeReasonModal={this.handleAttestation_closeReasonModal}
                    handlePreviewSubTicket={this.handlePreviewSubTicket}
                    languageHandler={this.languageHandler}
                />

            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.authReducer.user,
        isLoading: state.TimeTrackerModReducer.isLoading,
        assignedTaskTicketInfo: state.TimeTrackerModReducer.scheduledTask_assignedTicketInfo,
        attestationQQV: state.adminOrSuperAdminReducer.flShift_attestationQQV,
        language: state.adminOrSuperAdminReducer.language,
        openAttestation_ReasonModal: state.TimeTrackerModReducer.openFrequentTimeTracker_deleteModal,
        capturesAttestation_object: state.TimeTrackerModReducer.deleteFrequentTimeTracker_object,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        resetTimeTrackerObjects: () => dispatch(actions.resetTimeTrackerObjects()),

        getPerFLUserScheduledTaskTickets: (data) => dispatch(actions.getPerFLUserScheduledTaskTickets(data)),
        getPerFLUserShiftAttestation: (data) => dispatch(actions.getPerFLUserShiftAttestation(data)),
        getFLUserSignAttachPresignUrl: (shiftId, extentions, arrayBuffer, formData) => dispatch(actions.getFLUserSignAttachPresignUrl(shiftId, extentions, arrayBuffer, formData)),

        handleFrequentTimeTracker_openDeleteModal: (value) => dispatch(actions.handleFrequentTimeTracker_openDeleteModal(value)),
        handleFrequentTimeTracker_closeDeleteModal: () => dispatch(actions.handleFrequentTimeTracker_closeDeleteModal()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ClockOutFeedbackContainer);