import React, { useEffect, useRef, useState } from 'react';
import { useFieldArray, useForm, useWatch } from 'react-hook-form';
import { Input } from '../../../../UI/InputElement/reactFormHookInputField'
import { BP_AREAS, calTotalAreas } from '../../../../../../utility/constants/constants'
import { Controller } from 'react-hook-form'
import { closeFormModel, closeModel, openFormModel } from '../../../../../../utility/utility';
import { DevTool } from '@hookform/devtools';

function BpModal(props) {


    const [sections, setSections] = useState([])
    const [areaList, setAreaList] = useState(BP_AREAS)
    const [lastSection, setLastSection] = useState()
    const [UpdateArea, setUpdateArea] = useState()

    // const selectRef = useRef()

    const columnName = props.getValues('columnName')

    const { formState: { errors, defaultValues }, handleSubmit: ModalSubmit, getValues, reset, trigger, register, isDirty, control, setValue: setValueForm } = useForm({

        defaultValues: useEffect(() => {
            reset({
                sections: {
                    area_field: props.areaState ? props.areaState : '',
                    total: '',
                    comments: '',
                    data: columnName ? columnName?.data?.map((item) => {
                        return {
                            column_name: item.column_name,
                            column_value: '',
                        }
                    }) : []
                }
            })
            setUpdateArea('')
                props.setAddDetailsdisable(columnName)

        }, [columnName, props.addDetailsClicked]),
        shouldFocusError: false
    })


    useEffect(() => {
        
        let value = props.sectionList[props.areaIndex]
         setUpdateArea(value?.sections?.area_field)
        reset(value)
        //openFormModel()             
    }, [props.areaIndex, props.updateDetailsClicked])


    useEffect(() => {
        if (sections.length) {
            const updatedAreas = areaList.filter((item1) => !sections.some(item2 => item1.value === item2.sections.area_field))
            setAreaList(updatedAreas)
            props.setAreaList(updatedAreas)

            const values = [...sections]
          
            const updatedSections = values.map((item) => {

                const column = lastSection.sections.data.map((data, i) => {

                    return {
                        column_name: data.column_name,
                        column_value: data.column_value
                    }
                })
                return {
                    sections: {
                        area_field: item.sections.area_field,
                        total: item.sections.total,
                        comments: item.sections.comments,
                        data: column
                    }
                }
            })

            props.sectionListHandler(lastSection);
        }

    }, [sections])

    

    useEffect(()=>{
      
      if(props.areaState && props.areaIndex==undefined){
        reset({
            sections: {
                area_field: props.areaState,
                total: '',
                comments: '',
                data: columnName ? columnName?.data?.map((item) => {
                    return {
                        column_name: item.column_name,
                        column_value: '',
                    }
                }) : []
            }
        },)
      }
      
    },[props.areaState])



    const { fields } = useFieldArray({ control: control, name: "sections.data" })
    const controller = Controller

    const modalSubmitHandler = (values) => {

        calTotalAreas.lineItems.map((item) => {
            if(item.key === values.sections.area_field){
                props.setValue(item.value, values.sections.total)
            }
        })

        calTotalAreas.surveyItems.map((item) => {
            if(item.key === values.sections.area_field){
                props.setValue(item.value, values.sections.total)
            }
        })

        setLastSection(values)
        setSections((pre) => [...pre, values])
        const data = values.sections.data.map((item) => {
            return {
                column_name: item.column_name,
                column_value: ""
            }
        })
        props.setValue('area', values)
        reset({
            sections: {
                area_field: '',
                total: '',
                comments: '',
                data: data,
            }
        })

        props.setValue("area","")
        closeFormModel()
    }
    // to calculate total
    const watch = useWatch({
        control: control,
        name: "sections.data"
    })

    useEffect(() => {
        const totalCalc = () => {
            const count = watch?.reduce((accumulator, currentItem) => {
                if (currentItem.column_value === "") {
                    return accumulator
                } else {
                    return accumulator + parseFloat(currentItem.column_value);
                }
            }
                , 0)

            setValueForm("sections.total", count)
        }
        totalCalc()
    }, [watch])


    return (
        <>
            {/* <!-- Modal --> */}
            <div className="custom_modal">
                <div
                    className="modal fade"
                    id="exampleModal"
                    tabindex="-1"
                    data-bs-backdrop="static"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                >
                    <div className="modal-dialog">
                        <form onSubmit={ModalSubmit((data) => modalSubmitHandler(data))}>
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel">Area Details</h5>
                                    <button
                                        type="button"
                                        className="btn-close btn p-0"
                                        style={{
                                            backgroundColor: "#7c2b2b"
                                        }}
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                        onClick={(e) => {
                                            e.preventDefault()
                                            reset()
                                            closeModel()

                                        }}
                                    >
                                        <img src="/images/icons/cross_icon.svg" alt="cross_icon" />
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <Input
                                                    type="inputSelect"
                                                    labelClasses="form-label"
                                                    control={control}
                                                    controller={controller}
                                                    selectLabel="Area"
                                                    selectName={`sections.area_field`}
                                                    placeholder={UpdateArea  ? UpdateArea : 'Please Select'}
                                                    id={`sections.area_field`}
                                                    //selectRef={selectRef}
                                                    selectOptions={areaList}
                                                    required={register(`sections.area_field`, {
                                                        required: "This field is required"
                                                    })}
                                                    validationErrorMessage={errors?.sections?.area_field?.message}
                                                    selectClasses="form-control"
                                                    disabled={props.areaIndex >= 0}
                                                />
                                            </div>
                                        </div>
                                        {fields.map((item, index) => (
                                            <div key={index}>
                                                <span style={{ display: 'flex' }}>
                                                    <div className="col-md-6 col-sm-12">
                                                        <div className="form-group">
                                                            <Input
                                                                type="text"
                                                                formControlProps={register(`sections.data.${index}.column_name`, {
                                                                    required: "This field is required"
                                                                })}
                                                                style={{ height: '30px' }}
                                                                validationErrorMessage={errors?.sections?.data?.[index]?.column_name?.message}
                                                                formControlClasses="form-control"
                                                                disabled={true}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 col-sm-12">
                                                        <div className="form-group">
                                                            <Input
                                                                type="number"
                                                                formControlProps={register(`sections.data.${index}.column_value`, {
                                                                    required: "This field is required"
                                                                })}
                                                                style={{ height: '30px' }}
                                                                validationErrorMessage={errors?.sections?.data?.[index]?.column_value?.message}
                                                                formControlClasses="form-control"
                                                                disabled={props.isView}
                                                            />
                                                        </div>
                                                    </div>
                                                </span>
                                            </div>
                                        ))}

                                        <div className="col-md-12 col-sm-12">
                                            <div className="form-group">
                                                <Input
                                                    type="number"
                                                    labelClasses="form-label"
                                                    label={`Total`}
                                                    //value={totalCalc()}
                                                    placeholder={'Total'}
                                                    id={`sections.total`}
                                                    name={`sections.total`}
                                                    formControlProps={register(`sections.total`)}
                                                    formControlClasses="form-control"
                                                    disabled={true}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-12 col-sm-12">
                                            <div className="form-group">
                                                <Input
                                                    type="textarea"
                                                    labelClasses="form-label"
                                                    label={`Comments`}
                                                    placeholder={'Type here'}
                                                    id={`sections.comments`}
                                                    formControlProps={register(`sections.comments`, {
                                                        required: "This field is required"
                                                    })} elevator_sq_footage
                                                    validationErrorMessage={errors?.sections?.comments?.message}
                                                    formControlClasses="form-control"
                                                    disabled={props.isView}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    {props.isView ? (<button
                                        type="button"
                                        class="btn theme_def_btn1"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                    >
                                        Go Back
                                    </button>) : (<button
                                        type="submit"
                                        class="btn theme_def_btn1"
                                    >
                                        Submit
                                    </button>)}
                                </div>
                            </div>
                        </form>
                    </div>
                    <DevTool control={control} />
                </div>
            </div>
        </>
    )
}

export default BpModal