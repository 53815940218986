import React, { useState, useRef } from 'react'
import { reduxForm } from 'redux-form';
import { connect, useSelector } from 'react-redux';
import { Input } from '../../../UI/InputElement/InputElement'
import SignatureCanvas from 'react-signature-canvas'
import ModalImageGallery from '../../../ModalImageGallery/modalImageGallery';
import storage from '../../../../../utility/storage';
import Skeleton from 'react-loading-skeleton';
import isEmpty from '../../../../../utility/IsEmptyValidator';
import cloneDeep from 'clone-deep';
import ImageLoader from 'react-imageloader';
import LogbookFeedbackSkeleton from './LogBookFeedbackSkeleton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import reactRouter from 'react-router';
import RejectQuote from '../../../Quotes/rejectQuote';
import { DialogContent } from '@material-ui/core';


let LogBookFeedback = (props) => {
    const [isLoading, setIsloading] = useState(false);
    const [isRejectLoading, setIsRejectLoading] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const [isSignatureEmpty, setIsSignatureEmpty] = useState(false);
    let signatureShade = useRef({});
    const [isImageGallery, setIsImageGallery] = useState(false);
    const [startIndex, setStartIndex] = useState(0);
    const questions = useSelector(state => state.adminOrSuperAdminReducer.logbookLogQuestions);
    const logbookLog = useSelector(state => state.adminOrSuperAdminReducer.logbookLog);
    const [openRejectMOdal, setOpenRejectMOdal] = useState(false)
    const Field = (h, w) => {
        return (<Skeleton height={h} width={w} />)
    }

    const [validError, setValidError] = useState("")

    const ReasonRef = useRef()

    const base64Clicked = (i, urlLength) => {
        setIsImageGallery(true);
        setStartIndex((urlLength + i));
    }

    function preloader() {
        return <img style={{ width: '100%', height: '100%' }} src="/images/gif/giphy.gif" />;
    }

    let clearSign = () => {
        signatureShade.clear();
    };

    const CheckQuestionValidation = (answers) => {
        const result = answers.filter((item) => item.is_yes === true)
        return result.length
    }

    const photoUrlClicked = (i) => {

        setIsImageGallery(true);
        setStartIndex(i);
    }
    const onSubmitImage = () => {
        if(window.location?.search === "?quality_rejected=true"){
            if (CheckQuestionValidation(props.logbookForm.LogBookFeedbackForm.values.logbook_log.answers) === questions.length) {
                setIsloading(true);
                let logFeedbackValues = props.logbookForm.LogBookFeedbackForm.values;
                let onSubmitLogValues = {logFeedbackValues}
                setTimeout(() => {
                    props.onSignatureAdded(onSubmitLogValues);
                }, 1000);
            }else { 
                CheckQuestionValidation(props.logbookForm.LogBookFeedbackForm.values.logbook_log.answers) === questions.length ? setIsChecked(false) : setIsChecked(true);
            }
        }else {
            if (signatureShade.isEmpty() === false && CheckQuestionValidation(props.logbookForm.LogBookFeedbackForm.values.logbook_log.answers) === questions.length) {
                setIsloading(true);
                let logFeedbackValues = props.logbookForm.LogBookFeedbackForm.values;
                let sigImageFile = signatureShade.getTrimmedCanvas().toDataURL("image/png")
                let onSubmitLogValues = {logFeedbackValues, sigImageFile}
                setTimeout(() => {
                    console.log(onSubmitLogValues);
                    props.onSignatureAdded(onSubmitLogValues);
                }, 1000);
            }else { 
                CheckQuestionValidation(props.logbookForm.LogBookFeedbackForm.values.logbook_log.answers) === questions.length ? setIsChecked(false) : setIsChecked(true);
                signatureShade.isEmpty() === true ? setIsSignatureEmpty(true) : setIsSignatureEmpty(false);
            }
        }
    };

    if (isImageGallery) {
        return <ModalImageGallery
            isImageGallery={isImageGallery}
            setIsImageGallery={setIsImageGallery}
            startIndex={startIndex}
            base64={props.portfolioImages}
            imageUrls={props.savedPortfolioURls}
        />
    }

    const qualityCheck = (quality,reason) => {
        let comment=null
        if (quality === 'quality_rejected') {
            setIsRejectLoading(true)
            comment=reason
        } else {
            setIsloading(true);
        }
        props.qualityCheckHandler(quality,comment)
    }

    if((window.location?.search === "?quality_check=true" || window.location?.search === "?quality_rejected=true") && isEmpty(logbookLog)){
     return  <LogbookFeedbackSkeleton />
    }

    return (
        <main class="app-content qr_app_content app_screen_outer schedule-screen ups_schedule">
            <div class="client_login list_view">
                <div class="login_form_content Schedule_con">
                    <div className="comments_wrap">
                        <form className="form_content">
                            <div className="form-group">
                                <label htmlFor="name" className="form_title font-weight-bold">Please enter your name </label>
                                <Input name="logbook_log[name]" className="form-control textarea_modifier" placeholder="Your name" readOnly={true} id="logbook_log[name]" />
                            </div>
                            {
                                questions.length ? questions.map((item, i) => (
                                    <div key={i} className="checkbox_block radius_checkbox">
                                        <div className="custom-control custom-checkbox custom-control-inline">
                                            <Input type="checkbox" disabled={window.location?.search === "?quality_check=true" ? true : false} name={`logbook_log[answers][${i}.is_yes]`} className="custom-control-input" id={`logbook_log[answers][${i}.is_yes]`} />
                                            <label className="custom-control-label" htmlFor={`logbook_log[answers][${i}.is_yes]`}><span style={{ fontSize: '15px', fontWeight: '700' }}> {item.about}</span></label>
                                        </div>
                                    </div>
                                )) : (
                                    <>
                                        <div className="row">
                                            <div className="col-md-6" style={{ marginTop: '10px' }}>
                                                <div className="form-group">
                                                    {Field(20, 350)}

                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6" style={{ marginTop: '10px' }}>
                                                <div className="form-group">
                                                    {Field(20, 350)}

                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6" style={{ marginTop: '10px' }}>
                                                <div className="form-group">
                                                    {Field(20, 350)}

                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )
                            }
                            {isChecked === true ? <span style={{ color: 'red', fontFamily: 'serif', fontWeight: 'bold', fontSize: '15px' }}>&nbsp;&nbsp;Please Select all the Checkboxes </span> : null}
                            <div className="form-group mt-3">
                                <label htmlFor="comment" className="form_title font-weight-bold">Please enter any comment about this logbook</label>
                                <Input disabled={window.location?.search === "?quality_check=true" ? true : false} name="logbook_log[comment]" type="textarea" className="form-control textarea_modifier" rows="2" placeholder="Your comment" id="logbook_log[comment]" />
                            </div>
                            <div className="file_attachment">
                                <Input disabled={window.location?.search === "?quality_check=true" ? true : false} type="file" name="attachment_paths" hidden={true} multiple={true} onSelect={props.onPortfolioFileChange} fileRef={props.reference} accept="image/*" />
                                <a href="javascript:void(0)" style={{ textDecoration: 'none', color: 'black' }} onClick={props.choosePhotoClicked} className="file_title"><img src="/images/icons/attachment_icon.png" alt="Icon" /> Attachments</a>
                                <p className="size_dec">Up to 10 atachments (10MB each).</p>

                                        <div class="upload_file">
                                            <div class="row">
                                            {props.savedPortfolioURls && props.savedPortfolioURls.map((url, i) => {
                                                    return (
                                                        <div class="col-4 col-sm-4 cst_gutter" key={i}>
                                                            <div class="file_view" onClick={() => photoUrlClicked(i)}>
                                                                <ImageLoader
                                                                    style={{ width: '100%', height: '100%', cursor: 'pointer' }}
                                                                    src={url.photo_urls.small}
                                                                    wrapper={React.createFactory('div')}
                                                                    preloader={preloader}>
                                                                    Image load failed!
                                                            </ImageLoader>
                                                            </div>
                                                           { 
                                                          window.location?.search === "?quality_rejected=true" ?  <div class="cancel_icon">
                                                                <img src="/images/icons/icn_delete_white.png" onClick={() => props.removeUrls(url, i)} alt="Image" />
                                                            </div>
                                                            : null
                                                           } 
                                                        </div>
                                                    )
                                                })}
                                                {props.portfolioImages.map((url, i) => {
                                                    return (
                                                        <div class="col-4 col-sm-4 cst_gutter" key={i} >
                                                            <div class="file_view">
                                                                <img src={url} alt="Image" onClick={() => base64Clicked(i, props.savedPortfolioURls.length)} />
                                                                <div class="cancel_icon">
                                                                    <img src="/images/icons/icn_delete_white.png" onClick={() => props.removeImage(url, i)} alt="Image" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                }

                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="form_title font-weight-bold">Please provide your Signature</label>
                                {window.location?.search === "?quality_check=true" || window.location?.search === "?quality_rejected=true" ? (
                                    <div className="file_attachment">
                                        <img src={props.signature?.small} alt="Image" />
                                    </div>
                                ) : (<>
                                    <SignatureCanvas
                                        ref={(ref) => { signatureShade = ref; }}
                                        penColor='black' backgroundColor='white'
                                        canvasProps={{ width: 500, height: 150, className: 'form-control textarea_modifier' }}
                                    />
                                    {isSignatureEmpty === true ? <span style={{ color: 'red', fontFamily: 'serif', fontWeight: 'bold', fontSize: '15px' }}>&nbsp;&nbsp;This field is required </span> : null}
                                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}><button type="button" onClick={clearSign} className="btn mr-2 btn-outline-secondary">Clear</button></div>
                                </>)
                                }

                            </div>
                            <div className="form-group btn_block mb-0">
                                {
                                    window.location?.search === "?quality_check=true" ? (
                                        <div style={{ display: "flex" }} >
                                            <button data-test="submit_button" onClick={() => qualityCheck('quality_reviewed')} type="button"  className={isLoading ? "btn cst_btn btn_success btn-wait btn_core mr-2 w-50 py-3" : "btn cst_btn btn_success mr-2 w-50 py-3"} >{isLoading ? "" : 'Approve'}</button>
                                            {/* <a href="JavaScript:void(0);" data-toggle="modal" data-target="#addReason" data-backdrop="static" data-keyboard="false" onClick={() => setOpenRejectMOdal(true)}>{isRejectLoading ? "" : 'Reject'} </a> */}
                                            <button data-test="submit_button" onClick={() => setOpenRejectMOdal(true)} type="button" className={isRejectLoading ? "btn cst_btn btn-danger btn-wait btn_core mr-2 w-50 py-3" : "btn btn-danger cst_btn mr-2 w-50 py-3"} >{isRejectLoading ? "" : 'Reject'} </button>
                                        </div>
                                    ) : (
                                        <button data-test="submit_button" onClick={onSubmitImage} type="button" disabled={isLoading ? true : false} className={isLoading ? "btn cst_btn btn_danger btn-wait btn_core mr-2 w-100" : "btn cst_btn btn_danger mr-2 w-100 py-3"} >{isLoading ? '' : 'Submit'}</button>
                                    )
                                }
                                <button data-test="cancel_button" type="button" className="btn btn-outline-secondary w-100 py-3 mb-3 mt-3" onClick={() => { props.scanLogbookPage() }} >Cancel</button>
                            </div>
                            {/* {sigImageFile ? 
                        ( <img src={sigImageFile} alt="sign-image" style={{ width: "300px", height: "100px", border: "3px groove pink", display: "block", margin: "0 auto" }} /> ) 
                        : null} */}
                        </form>
                    </div>
                </div>
            </div>
            <Dialog
                open={openRejectMOdal}
                aria-labelledby="form-dialog-title"

            >
                <DialogTitle id="form-dialog-title" style={{ fontSize: '13', color: 'white', backgroundColor: "#151b26" }}>
                    <h3 style={{ color: "white" }}> Reason for Rejection  </h3>
                </DialogTitle>
                <DialogContent style={{ width: "30rem",}}>

                    <DialogActions>
                        

                        <div className="modal-body">
                            <div className="tab-content">
                                <div id="profile" className="profile_tab cst_tab_content tab-pane fade show active edit_profile_modal">
                                    <form className="form_content" >
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label className="label_modifier">Reason</label>
                                                    <input ref={ReasonRef} type="text" className="form-control input-modifier" placeholder="Enter reason" id='reason' />
                                                </div>
                                                {
                                                    validError ? <p style={{ color: "red", fontSize: ".9rem" }}>{validError}</p> : null
                                                }


                                            </div>
                                        </div>
                                        <div className="clearfix"></div>
                                        <div className="modal-footer" style={{ marginRight: "2.5rem" }}>
                                            <button data-test="submit_button" type="button" disabled={props.isLoading} className={props.isLoading ? "btn cst_btn btn_danger mr-2 btn-wait" : "btn cst_btn btn_danger mr-2"} onClick={() => {
                                                
                                                if (ReasonRef.current) {
                                                    let reason = ReasonRef.current.value
                                                     
                                                    if (!reason) {
                                                        
                                                        setValidError("Please Add Any Reason")
                                                    }
                                                    else {
                                                        qualityCheck('quality_rejected',reason)
                                                        setOpenRejectMOdal(false)
                                                    }
                                                }
                                            }} >Submit</button>
                                            <button onClick={()=>{
                                                setOpenRejectMOdal(false)
                                            }} id='closeaddReason' type="button" className="btn btn-outline-secondary cst_btn" data-dismiss="modal">Cancel</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>

                    </DialogActions>
                </DialogContent>

            </Dialog>





        </main>


    );
}

const mapStateToProps = (state, props) => {
    const storageUser = storage.get('user');
    const questions = state.adminOrSuperAdminReducer.logbookLogQuestions

    const convertLogsQuestion = (logQuestion) => {
        return logQuestion?.map((item) => {
            return {
                question_id: item.id,
                is_yes: item.is_yes ? item.is_yes : false
            }
        })
    }
    if(!isEmpty(state.adminOrSuperAdminReducer.logbookLog) && window.location.search === "?quality_check=true" || window.location.search === "?quality_rejected=true"){
        const logbookLog = cloneDeep(state.adminOrSuperAdminReducer.logbookLog)
        const logbook_log = {
            name: logbookLog.reviewed_at ? `${logbookLog.user.first_name} ${logbookLog.user.last_name}`  : `${storageUser.first_name} ${storageUser.laslast_namet_name}`,
            comment: logbookLog.comment,
            answers: convertLogsQuestion(logbookLog.answers),
            initial: '',
            attachments : [],
            status : 'reviewed'
        }
        return {
            initialValues: { logbook_log },
            logbookForm: state.form
        }
    } else {
        const logbookLog = cloneDeep(state.adminOrSuperAdminReducer.logbookLog)
        const logbook_log = {
            name: logbookLog && logbookLog.reviewed_at ? `${logbookLog.user.first_name} ${logbookLog.user.last_name}`  : `${storageUser.first_name} ${storageUser.last_name}`,
            comment: '',
            answers: convertLogsQuestion(questions),
            initial: '',
            attachments: [],
            status: 'reviewed'
        }
        return {
            initialValues: { logbook_log },
            logbookForm: state.form
        }
    }
};

LogBookFeedback = reduxForm({
    form: 'LogBookFeedbackForm',
      enableReinitialize: true,
})(LogBookFeedback);

LogBookFeedback = connect(mapStateToProps)(LogBookFeedback);

export default LogBookFeedback



