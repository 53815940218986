import React from "react";
import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
} from "@material-ui/core";
const ModeModal = (props) => {
  return (
    <Dialog open={props.openDialog} aria-labelledby="form-dialog-title">
      <DialogTitle
        id="form-dialog-title"
        style={{ fontSize: "13", color: "#06080f" }}
      >
        <h3 style={{ color: "#ab385e" }} data-test="login_expect">Please Set Your Mode</h3>
      </DialogTitle>
      <DialogActions>
        <div className="theme_switch_btn MuiDialogContent-root MuiDialogActions-spacing">
          <span className="switch_title">
            Int<span className="mob_txt_hide">ernal</span>
          </span>
          <label>
            <input type="checkbox" onChange={($event) => props.modeChange($event)}/>
            <span data-test="checkbox_external"></span>
            <span data-test="checkbox_external" className="ext_title">
              Ext<span className="mob_txt_hide">ernal</span>
            </span>
          </label>
        </div>
      </DialogActions>
      <DialogActions>
        <button data-test="continue_btn" style={{ minHeight: '36px', minWidth: '80px' }} onClick={(event) => props.userModeClicked(event)} className={"btn cst_btn btn-outline-secondary"} >Continue</button>
      </DialogActions>
    </Dialog>
  );
};

export default ModeModal;
