import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { Input } from '../../../UI/InputElement/InputElement';
import isEmpty from '../../../../../utility/IsEmptyValidator';
import { getUser, makeFirstLetterCapital } from '../../../../../utility/utility';
import { newInventoryItemValidator as validate } from '../../../../../utility/validator/validator';
import InventoryItemFormSkeleton from './InventoryItemFormSkeleton';
import moment from "moment";
import cloneDeep from 'clone-deep';
import { Button, InputNumber, Space } from 'antd';
import { convertDateTimezoneFormAudit, convertDateTimezoneFormAuditReduxForm } from '../../../../../utility/TimezoneOperations/timezoneUtility';

let NewInventoryItemForm = (props) => {

    const getInventoryLocationList = (props) => {
        if (props.inventoryLocationList && props.inventoryLocationList.length > 0) {
            return props.inventoryLocationList && props.inventoryLocationList.map((location) => {
                return {
                    value: location.id,
                    label: makeFirstLetterCapital(location.name)
                }
            })
        } else {
            return []
        }
    };

    const [locationList, setLocationList] = useState(getInventoryLocationList(props));

    useEffect(() => {
        setLocationList(getInventoryLocationList(props))
    }, [props.inventoryLocationList])

    const noOptionsMessage = (a, b) => {
        return 'No options';
    }

    const { handleSubmit, pristine, reset, submitting, error, submitFailed } = props;

    if (props.isUpdateInventoryItem) {
        return (
            <InventoryItemFormSkeleton />
        )
    } else {
        return (
            <main className="app-content wraper_content user_newQuote add_account_modal add_New_Accont add_newAccount">
                <div className="inspection_content_sec appcontent_Inner">
                    <div className="container-fluids">
                        <div className="quote_form ticket_form" style={{ maxWidth: '900px' }}>
                            <div className="row">
                                <div className="col-sm-12 text-center"> <h2 className="md_title" >{props.isEditInventoryItem ? "Update Inventory Item" : "Add New Inventory Item"}</h2></div>
                            </div>
                            <div className="addQuote_form">
                                <form className="form_content" onSubmit={handleSubmit} >
                                    <div className="cst_tab_content">

                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label className="form_title"> Item <sup className="cst_sup_txt text_danger">*</sup></label>
                                                    <Input dataTest="" type="text" className="form-control input-modifier" placeholder="Item Name" name="item[name]" />
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label className="form_title"> Quantity <sup className="cst_sup_txt text_danger">*</sup></label>
                                                    <Input dataTest="" type="numberWithIncDec" min={1} className="form-control input-modifier" placeholder="No of Quantity" name="item[initial_quantity]" />
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label className="form_title"> Location </label>
                                                    <Input type="inputSelect" placeholder="Select Location" noOptionsMessage={noOptionsMessage} options={locationList} name="item[location_id]" />
                                                </div>
                                            </div>
                                        </div>


                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="form_title"> Manufacturer <sup className="cst_sup_txt text_danger">*</sup></label>
                                                    <Input dataTest="" type="text" className="form-control input-modifier" placeholder="Manufacturer Name" name="item[manufacture]" />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="form_title"> SKU Details <sup className="cst_sup_txt text_danger">*</sup></label>
                                                    <Input dataTest="" type="text" className="form-control input-modifier" placeholder="Enter SKU No" name="item[sku_detail]" />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="form_title"> Expiration Date <sup className="cst_sup_txt text_danger">*</sup></label>
                                                    <div className="input-group cst_input_group col-lg-14 pl-0">
                                                        <div style={{ width: '100%' }}>
                                                            <Input
                                                                type="date"
                                                                datePickerType={"react-datepicker"}
                                                                minDate={new Date()}
                                                                dateFormat="MM-dd-yyyy"
                                                                className="form-control input-modifier"
                                                                placeholder="Select Date"
                                                                name="item[expiration_date]"
                                                            />
                                                        </div>
                                                        <div className="input-group-append cursor-pointer" style={{ float: "right", position: "absolute", right: 0, height: "45px", top: 0 }}>
                                                            <span class="input-group-text" id="basic-addon2"> <i class="fa fa-calendar"></i> </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="form_title"> Suppliers <sup className="cst_sup_txt text_danger">*</sup></label>
                                                    <Input dataTest="" type="text" className="form-control input-modifier" placeholder="Enter Supplier Name" name="item[supplier]" />
                                                </div>
                                            </div>
                                        </div>

                                        {/* New Items */}

                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="form_title"> Cost of replacement <sup className="cst_sup_txt text_danger">*</sup></label>
                                                    <Input dataTest="" type="number" className="form-control input-modifier" placeholder="Cost of replacement" name="item[cost_at_replacement]" />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="form_title"> Unit Cost <sup className="cst_sup_txt text_danger">*</sup></label>
                                                    <Input dataTest="" type="number" className="form-control input-modifier" placeholder="Enter Cost" name="item[initial_cost]" />
                                                </div>
                                            </div>
                                        </div>




                                        {props.isEditInventoryItem ?
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label className="form_title"> Reason <sup className="cst_sup_txt text_danger">*</sup></label>
                                                        <Input dataTest="" type="text" className="form-control input-modifier" placeholder="Type here" name="item[reason]" />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    {/* <div className="form-group">
                                                            <label className="form_title"> Inventory Status</label>
                                                            <Input type="radio" labelClass="custom-control-label" forLable={"customRadio1"} value={"active"} label={"Active"} className="custom-control-input" id="customRadio1" name="item[active_status]" />
                                                            <Input type="radio" labelClass="custom-control-label" forLable={"customRadio2"} value={"inactive"} label={"Inactive"} className="custom-control-input" id="customRadio2" name="item[active_status]" />
                                                        </div> */}
                                                </div>
                                            </div> : null}

                                            <div className="row">
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                <div className="checkbox_block radius_checkbox">
                                                <div className="custom-control custom-checkbox custom-control-inline">
                                                <Input
                                                    type="checkbox"
                                                    name="item[os1]"
                                                    className="custom-control-input"
                                                    id="customCheck1"
                                                />
                                                <label
                                                    style={{fontSize : '15px'}}
                                                    className="custom-control-label"
                                                    for="customCheck1"
                                                >
                                                    OS1 Item <sup className="cst_sup_txt text_danger">*</sup>
                                                </label>
                                                </div>
                                                </div>
                                                </div>
                                            </div>
                                            {/* <div className="col-md-6"> */}
                                                {/* <div className="form-group">
                                                    <label className="form_title"> Unit Cost <sup className="cst_sup_txt text_danger">*</sup></label>
                                                    <Input dataTest="" type="number" className="form-control input-modifier" placeholder="Enter Cost" name="item[initial_cost]" />
                                                </div> */}
                                            {/* </div> */}
                                        </div>

                                    </div>
                                    <div className="modal-footer">
                                        <button data-test="submit_button" type="submit" disabled={props.isLoading} className={props.isLoading ? "btn cst_btn btn_danger btn-wait w-25" : "btn cst_btn btn_danger w-25"} >{props.isLoading ? "" : "Submit"}</button>
                                        <button data-test="cancel_button" type="button" className="btn cst_btn btn-outline-secondary cancel_btnnew w-25" onClick={() => { props.onCancelClicked() }}>Cancel</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </main >
        )
    }
}

const mapStateToProps = (state) => {

    let item = {};

    const formatLocationValue = (id, name) => ({
        value: id,
        label: makeFirstLetterCapital(name)
    });

    if (isEmpty(state.InventoryReducer.singleInventoryItem)) {
        item = {
            // active_status: 'active',
        }
    } else {
        const values = cloneDeep(state.InventoryReducer && state.InventoryReducer.singleInventoryItem);
        //convertDateTimezoneFormAudit(values.product.salvage_date)
        item = {
            name: values && values.product && values.product.name ? makeFirstLetterCapital(values.product.name) : null,
            initial_quantity: values && values.product && values.product.item && values.product.item.initial_quantity ? values.product.item.initial_quantity : null,
            manufacture: values && values.product && values.product.manufacture ? makeFirstLetterCapital(values.product.manufacture) : null,
            sku_detail: values && values.product && values.product.sku_detail ? values.product.sku_detail : null,
            expiration_date: values && values.product && values.product.expiration_date ? convertDateTimezoneFormAuditReduxForm(values.product.expiration_date) : null,
            supplier: values && values.product && values.product.supplier ? makeFirstLetterCapital(values.product.supplier) : null,
            cost_at_replacement: values && values.product && values.product.cost_at_replacement ? values.product.cost_at_replacement : null,
            initial_cost: values && values.product && values.product.initial_cost ? values.product.initial_cost : null,
            location_id: values && values.product && values.product.location_id ? formatLocationValue(values.product.location_id, values.product.itemLocation.name) : null,
            os1: values && values.product && values.product.os1 ? values.product.os1 : null,
        }
    }
    return { initialValues: { item } }

}

NewInventoryItemForm = reduxForm({
    form: 'newInventoryItemForm',
    validate,
    enableReinitialize: true
})(NewInventoryItemForm);

NewInventoryItemForm = connect(mapStateToProps)(NewInventoryItemForm)

export default NewInventoryItemForm;





