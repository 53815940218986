import React, { useState } from 'react';
import DepartmentTableList from './DepartmentTableList';
import NewDepartment from './NewDepartment/NewDepartment';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';

const DepartmentLayout = (props) => {

    const ActiveDepartmentListCategory = () => {
        props.getDepartmentList({ activeStatus: 'active' });
    }
    const InActiveDepartmentListCategory = () => {
        props.getDepartmentList({ activeStatus: 'inactive' });
    }

    const { handleSubmit, pristine, reset, submitting, error } = props;

    return (
        <>

            <main className="app-content wraper_content inspection_wraper categories_blc">
                <div className="tab_header search_filter_cont inspection_tab_content appcontent_inner">
                    <form onSubmit={handleSubmit}>
                        <input type="hidden" value="something" />
                        <div className="row mt-2">
                            <div class="col">
                                <button type='button' className="btn cst_btn btn_danger mr-2" onClick={ActiveDepartmentListCategory}>Active Department</button>
                                <button type='button' className="btn cst_btn btn_danger mr-2" onClick={InActiveDepartmentListCategory}>In-Active Department</button>
                            </div>
                            <div className="col">
                                <div className="col-xl-12 col-lg-4 col-md-12 col-sm-12 text-right accounts_btn_wrap">
                                    <a href="JavaScript:void(0);" className="btn btn-secondary cst_btn" data-toggle="modal" data-target="#addDepartment" data-backdrop="static" data-keyboard="false" onClick={() => { props.handleFrequentTimeTracker_openModal(); }}><i className="fa fa-plus icn_plus" aria-hidden="true"></i> New Department </a>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <section className="account_sec user_screen inspection_tab_content">
                    <DepartmentTableList
                        {...props}
                    />
                </section>
            </main>

            <Dialog
                open={props.openFrequentTimeTracker_deleteModal}
                // onClose={props.handleClose}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title" style={{ fontSize: '13', color: '#06080f' }}>
                    <h3 style={{ color: '#ab385e' }}>Are you sure?</h3>
                </DialogTitle>

                <DialogActions>
                    <button style={{ minHeight: '36px', minWidth: '80px' }} onClick={() => props.deleteDepartment(props.deleteFrequentTimeTracker_object)} disabled={props.isLoading} className={props.isLoading ? "btn cst_btn btn_danger mr-2 btn-wait" : "btn cst_btn btn_danger mr-2"} id="deleteCriteria" type="delete">{props.isLoading ? "" : "Delete"}</button>
                    <button style={{ minHeight: '36px', minWidth: '80px' }} onClick={props.handleFrequentTimeTracker_closeDeleteModal} className={"btn cst_btn btn-outline-secondary"} id="cancel" type="cancel">Cancel</button>
                </DialogActions>
            </Dialog>

            {props.isTimeTrackerModalOpen ? (
                <NewDepartment
                    {...props}
                    onSubmit={props.addNewDepartment}
                    handleFrequentTimeTracker_closeModal={props.handleFrequentTimeTracker_closeModal}
                />
            ) : null}

        </>
    );
};

export default DepartmentLayout;