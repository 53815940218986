import React from 'react'
import { Input } from '../../../../UI/InputElement/reactFormHookInputField'

function TrainingForm(props) {
  
  return (
  <>
    <div class="tm_form_wrap">
                  <div class="tm_form_innner">
                    <div class="form">
                      <div class="row">
                        <div class="col-md-6 col-sm-12">
                          <div class="form-group">
                            {/* <label for="jobname" class="form-label">
                              Instructor
                            </label>
                            <input
                              type="text"
                              class="form-control"
                              id="jobname"
                              aria-describedby=""
                              placeholder="Instructor"
                            /> */}

                   <Input
                    type="text"
                    id={ `data.${props.index}.instructor`}
                    name={ `data.${props.index}.instructor`}
                    formControlClasses="form-control"
                    placeholder="Instructor"
                    required
                    label="Instructor"
                    formControlProps={props.register(
                      `data.${props.index}.instructor`,
                      {
                        required: "This field is required",
                        maxLength: 80,
                      }
                    )}
                    validationErrorMessage={
                      props.errors?.data?.[props.index]?.instructor
                        ?.message ?? ""
                    }
                    disabled={props?.isView}
                    // readOnly={props?.isView}
                  />
                          </div>
                        </div>
                        <div class="col-md-6 col-sm-12">
                          <div class="form-group">

                        <Input
                          type="text"
                          id={`data.${props.index}.location`}
                          name={`data.${props.index}.location`}
                          formControlClasses="form-control"
                          placeholder="Location"
                          required
                          label="Location"
                          formControlProps={props.register(
                            `data.${props.index}.location`,
                            {
                              required: "This field is required",
                              maxLength: 80,
                            }
                          )}
                          validationErrorMessage={
                            props.errors?.data?.[props.index]?.location
                              ?.message ?? ""
                          }
                          disabled={props?.isView}
                        />


                            {/* <label for="jobname" class="form-label">
                              Location
                            </label>
                            <input
                              type="text"
                              class="form-control"
                              id="jobname"
                              aria-describedby=""
                              placeholder="Location"
                            /> */}
                          </div>
                        </div>
                        <div class="col-md-6 col-sm-12">
                           <div class="form-group">
                            {/* <label for="jobname" class="form-label">
                              Title
                            </label>
                            <input
                              type="text"
                              class="form-control"
                              id="jobname"
                              aria-describedby=""
                              placeholder="Title"
                            />  */}

                  <Input
                    type="text"
                    id={`data.${props.index}.title`}
                    name={`data.${props.index}.title`}
                    formControlClasses="form-control"
                    placeholder="Title"
                    required
                    label="Title"
                    formControlProps={props.register(
                      `data.${props.index}.title`,
                      {
                        required: "This field is required",
                        maxLength: 80,
                      }
                    )}
                    validationErrorMessage={
                      props.errors?.data?.[props.index]?.title
                        ?.message ?? ""
                    }
                    disabled={props?.isView}
                  />
                          </div>
                        </div>
                        <div class="col-md-6 col-sm-12">
                          <div class="form-group">
                          <Input 
                            type="date"
                            //datePickerType="month"
                            formControlClasses="form-control"
                            id={`data.${props?.index}.date`} 
                            placeholder="MM/DD/YYYY"
                            control={props.control}
                            controller={props.controller}
                            selectName={`data.${props?.index}.date`} 
                            dateFormat="MM/dd/yyyy"
                            // minDate={new Date().setDate(current.getDate() - 400)}
                            preventReadOnly={false}
                            label="Date"
                            formControlProps={props.register(`data.${props?.index}.date`, {
                                required: "This field is required", maxLength: 80
                              })}
                            validationErrorMessage={props.errors?.data?.[props?.index]?.date?.message ?? ""}
                            disabled={props?.isView}
                            />

                            {/* <label for="jobname" class="form-label">
                              Date
                            </label>
                            <input
                              type="date"
                              class="form-control"
                              id="jobname"
                              aria-describedby=""
                              placeholder="Location"
                            /> */}
                            {/* <div class="calendar_icon">
                              <img
                                src="/images/icons/calendar.svg"
                                alt="calendar"
                              />
                            </div> */}
                          </div>
                        </div>
                        <div class="col-md-6 col-sm-12">
                          <div class="form-group">
                            {/* <label for="jobname" class="form-label">
                              Verification number
                            </label>
                            <input
                              type="text"
                              class="form-control"
                              id="jobname"
                              aria-describedby=""
                              placeholder="Verification number"
                            /> */}

                 <Input
                    type="number"
                    id={`data.${props.index}.verification_code`}
                    name={`data.${props.index}.verification_code`}
                    formControlClasses="form-control"
                    placeholder="Verification number"
                    required
                    label="Verification number"
                    formControlProps={props.register(
                      `data.${props.index}.verification_code`,
                      {
                        required: "This field is required",
                        maxLength: 80,
                      }
                    )}
                    validationErrorMessage={
                      props.errors?.data?.[props.index]?.verification_code
                        ?.message ?? ""
                    }
                    disabled={props?.isView}
                  />


                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {
                    props?.index > 0 ? 
                    <div class="col-md-12 col-sm-12" >
                    <div class="action_button pt-3">
                      <span onClick={props.removeFromTaskList(props.index)}>
                        <img src='/images/icons/trash.svg' />
                      </span>
                    </div>
                    </div> : null
                }
                </div>
                </>
  )
}

export default TrainingForm
