import React, { useCallback, useEffect, useState } from "react";
import { Input } from "../../../UI/InputElement/reactFormHookInputField";
import withFormHoc from "../../../../../hoc/withReactHookForm/withReactHookForm";
import { useDispatch, useSelector } from "react-redux";
import { useWatch } from "react-hook-form";
import { getCompaniesMatrox, getJobBasedMatrix, resetMatrixJobs } from "../../../../../redux/actions/BrmActions/BrmAction";
let CustomMatrix = (props) => {

  const { isLoading, matrixCompanies, matrix_jobs } = useSelector((state) => state.BRM);
  const dispatch = useDispatch();

  const handleCloseFn = useCallback(
    (...args) => {
      if (typeof props.handleClose == "function") {
        if (props.isEdit) {
          props.handleClose(...args);
          return;
        } else return;
      }
      return;
    },
    [props?.handleClose]
  );

  const MeetingName = [
    {
      value: "quality_control_visits",
      label: "Quality Control Visits",
    },
    {
      value: "vice_president_visit",
      label: "Vice President Visit",
    },
    {
      value: "president_visit_call",
      label: "President Visit/Call",
    },
    {
      value: "business_review_meeting",
      label: "Business Review Meeting",
    },
    {
      value: "business_review_report",
      label: "Business Review Report",
    },
    // {
    //   value: "none",
    //   label: "N/A",
    // },
  ];

  const IntervalValue = [
    {
      value: "weekly",
      label: "Weekly",
    },
    {
      value: "monthly",
      label: "1 X Monthly",
    },
    {
      value: "semi_annual",
      label: "Semi Annual",
    },
    {
      value: "quarterly",
      label: "Quarterly",
    },
    {
      value: "annual",
      label: "Annual",
    },
    {
      value: "bi_monthly",
      label: "2 X Month",
    },
    {
      value: "none",
      label: "N/A",
    },
  ];

  const TiersValue = [
    {
      value: 1,
      label: "Tier A Customer",
    },
    {
      value: 2,
      label: "Tier B Customer",
    },
    {
      value: 3,
      label: "Tier C Customer",
    },
  ];

  var radios = document.querySelectorAll('input[type="radio"]');
  for (var i = 0; i < radios.length; i++) {
    radios[i].onfocus = function () {
      this.blur();
    };
  }

  const getCompnayName = (manager) => {
    if (matrixCompanies && matrixCompanies.length > 0) {
      return (
        manager &&
        manager.map((data) => {
          return {
            value: data.id,
            label: data.name,
            tier: data.company_tier
          };
        })
      );
    } else {
      return [];
    }
  };

  function getTierValue(tier) {
    if (!tier) return "N/A"
    return TiersValue.find((tr) => tr.value == tier).label.split("Customer")[0]
  }
  const [companyList, setCompanyList] = useState(getCompnayName(matrixCompanies));
  const [tierValue, setTierValue] = useState("")
  const companyWatch = useWatch({
    name: `data[company_id]`,
    control: props.control
  })

  let findCompanyTier = ""


  const getJobList = (manager) => {
    if (matrix_jobs && matrix_jobs.length > 0) {
      return manager && manager.map((data) => {
        return {
          value: data.id,
          label: data.name,
        }
      })
    } else {
      return []
    }
  };

  const [listOfJob, setJobList] = useState(getJobList(matrix_jobs))

  useEffect(() => {
    setJobList(getJobList(matrix_jobs))
  }, [matrix_jobs])

  useEffect(() => {
    if (companyWatch) {
      findCompanyTier = companyList.find((cmp) => cmp.value == companyWatch).tier
      // here if the accountId have value
      const acc_id = props.getValues("data[account_id]")
      if (acc_id) {
        props.setValue("data[account_id]", "")
      }
      dispatch(getJobBasedMatrix({ companyId: companyWatch }))
      props.setValue("data[tier]", getTierValue(findCompanyTier))
      
    }
  }, [companyWatch])
  // OnChnage of the Matrix name
  useEffect(() => {
    if (matrixCompanies) {
      setCompanyList(getCompnayName(matrixCompanies));
    }
  }, [matrixCompanies]);


  const tierWatch = useWatch({
    control: props.control,
    name: `data[tier]`
  })



  return (
    <div
      className="modal fade request_quote_modal reject_quot associateUser"
      id="addCustomMatrix"
      tabindex="-1"
      role="dialog"
      aria-labelledby="rejectQuoteModalTitle"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <label for="exampleInputEmail1" className="cst-label md_title">
              {props.isEdit == true
                ? "Update Custom Matrix"
                : "Add Custom Matrix"}
            </label>
            <button
              type="button"
              className="close close_icn_pic"
              data-dismiss="modal"
              onClick={() => {
                handleCloseFn();
                props.reset();
                dispatch(resetMatrixJobs())
              }}
            >
              <img src="/images/icons/icn_close_gray.png" alt="Close Icon" />
            </button>
          </div>

          <div className="modal-body">
            <div className="tab-content">
              <div
                id="profile"
                className="profile_tab cst_tab_content tab-pane fade show active edit_profile_modal"
              >
                <form
                  className="form_content"
                  onSubmit={props.handleSubmit((data) => {
                    props.onSubmit(data);
                  })}
                >
                  <div className="row">
                    <div className="col-md-12">
                      {/* <div className="form-group"> */}
                      {/* <label className="label_modifier"> Tier A </label> */}
                      {/* <Input
                          type="inputSelect"
                          labelClasses="form-label"
                          control={props.control}
                          controller={props.controller}
                          selectLabel="Tiers"
                          placeholder={"Please Select"}
                          selectName={"data[matrix]"}
                          id={"data[matrix]"}
                          selectOptions={TiersValue}
                          required={props.register(`data[matrix]`, {
                            required: "This field is required",
                          })}
                          validationErrorMessage={
                            props.errors?.data?.matrix?.message
                          }
                          selectClasses="form-select"
                          disabled={props?.isEdit && true}
                        /> */}
                      {/* </div> */}
                      <div className="form-group">
                        <Input
                          type="inputSelect"
                          labelClasses="form-label"
                          control={props.control}
                          controller={props.controller}
                          selectLabel="Select Company"
                          placeholder={"Please Select"}
                          selectName={"data[company_id]"}
                          id={"data[company_id]"}
                          selectOptions={companyList}
                          required={props.register(`data[company_id]`, {
                            required: "This field is required",
                          })}
                          validationErrorMessage={
                            props.errors?.data?.company_id?.message
                          }
                          selectClasses="form-select"
                          disabled={props?.isEdit && true}
                        />
                      </div>
                      {/* here tier field */}
                      <div className="form-group">


                        {/* <Input
                          type="text"
                          id={`data.${props.index}.instructor`}
                          name={`data.${props.index}.instructor`}
                          formControlClasses="form-control"
                          placeholder="Instructor"
                          required
                          label="Instructor"
                          formControlProps={props.register(
                            `data.${props.index}.instructor`,
                            {
                              required: "This field is required",
                              maxLength: 80,
                            }
                          )}
                          validationErrorMessage={
                            props.errors?.data?.[props.index]?.instructor
                              ?.message ?? ""
                          }
                          disabled={props?.isView}
                        // readOnly={props?.isView}
                        /> */}

                        <Input
                          type="text"
                          labelClasses="form-label"
                          selectLabel="Tiers"
                          label={`Tiers`}
                          placeholder={'Tiers'}
                          id={`data[tier]`}
                          name={`data[tier]`}
                          formControlProps={props.register(`data[tier]`, {
                            //required: "This field is required",
                          })}
                          //value={tierValue}
                          //validationErrorMessage={props.errors?.Tier?.message}
                          formControlClasses="form-control"
                          disabled={true}
                          readonly={true}
                        />
                      </div>

                      {/* end tier field */}

                      {/* start job dropdown */}
                      <div className="form-group">
                        <Input
                          type="inputSelect"
                          labelClasses="form-label"
                          control={props.control}
                          controller={props.controller}
                          selectLabel="Job"
                          placeholder={"Please Select"}
                          selectName={"data[account_id]"}
                          id={"data[account_id]"}
                          selectOptions={listOfJob}
                          required={props.register(`data[account_id]`, {
                            required: "This field is required",
                          })}
                          validationErrorMessage={
                            props.errors?.data?.account_id?.message
                          }
                          selectClasses="form-select"
                          disabled={!listOfJob.length}
                          readonly={!listOfJob.length}
                        />
                      </div>
                      {/* end job dropdown */}
                      <div className="form-group">
                        <Input
                          type="inputSelect"
                          labelClasses="form-label"
                          control={props.control}
                          controller={props.controller}
                          selectLabel="Meeting Name"
                          placeholder={"Please Select"}
                          selectName={"data[name]"}
                          id={"data[name]"}
                          selectOptions={MeetingName}
                          required={props.register(`data[name]`, {
                            required: "This field is required",
                          })}
                          validationErrorMessage={
                            props.errors?.data?.name?.message
                          }
                          selectClasses="form-select"
                          disabled={false}
                        />
                      </div>
                      <div className="form-group">
                        <Input
                          type="inputSelect"
                          labelClasses="form-label"
                          control={props.control}
                          controller={props.controller}
                          selectLabel="Select Interval"
                          placeholder={"Please Select"}
                          selectName={"data[frequency]"}
                          id={"data[frequency]"}
                          selectOptions={IntervalValue}
                          required={props.register(`data[frequency]`, {
                            required: "This field is required",
                          })}
                          validationErrorMessage={
                            props.errors?.data?.frequency?.message
                          }
                          selectClasses="form-select"
                          disabled={false}
                          customMenuList={{
                            height: "200px",
                            '::-webkit-scrollbar': {
                              width: '4px'
                            },
                            '::-webkit-scrollbar-track': {
                              background: '#f1f1f1'
                            },
                            '::-webkit-scrollbar-thumb': {
                              background: "a2a2a2"
                            },
                            '::-webkit-disabled-scrollbar-thumb:hover': {
                              background: '#555'
                            }
                          }}
                          customMenu={props.isEdit ? { position: "relative" } : {}}

                        />
                      </div>

                      {
                        props.isEdit ?
                          <>
                            <div style={{ display: 'flex',alignItems: 'center', flexDirection: "row",justifyContent:"flex-start" }}>
                            <div style={{ display: 'flex', alignItems: 'center', flexDirection: "row",width:"auto", }} className="container-brm">
                                <Input
                                  type="radio"
                                  id="active_status_active"
                                  name={`data[active_status]`}
                                  formControlClasses="custom-control-input"
                                  placeholder="Type here"
                                  label=" Add meeting Notes"
                                  formControlProps={props.register(
                                    `data[active_status]`,
                                    {
                                      required: "This field is required",
                                      maxLength: 80,
                                    }
                                  )}
                                  validationErrorMessage={
                                    props.errors?.notes
                                      ?.message ?? ""
                                  }
                                  disabled={props?.isView}
                                  value="1"
                                />
                                <label className="checkmark" htmlFor="active_status_active"></label>
                                <label htmlFor="active_status_active" style={{ marginTop: '-2px', fontSize: '15px' }}>Active</label>

                              </div>
                              <div style={{ display: 'flex', alignItems: 'center', flexDirection: "row" }} className="container-brm">
                                <Input
                                  type="radio"
                                  id="active_status_inactive"
                                  name={`data[active_status]`}
                                  formControlClasses="custom-control-input"
                                  placeholder="Type here"

                                  label=" Add meeting Notes"
                                  formControlProps={props.register(
                                    `data[active_status]`,
                                    {
                                      required: "Please select one of these option.",
                                      maxLength: 80,
                                    }
                                  )}
                                  validationErrorMessage={
                                    props.errors?.notes
                                      ?.message ?? ""
                                  }
                                  disabled={props?.isView}
                                  value="0"
                                />
                                <label className="checkmark" htmlFor="active_status_inactive"></label>
                                <label htmlFor="active_status_inactive" style={{  marginTop: '-2px', fontSize: '15px' }}>InActive</label>
                              </div>
                            </div>
                            {
                              props.errors?.data?.active_status?.message ? <span id="form-error" style={{ color: '#DD2726', fontSize: '13px' }} >{props.errors?.active?.message} </span> : null
                            }
                          </>


                          : null
                      }

                    </div>
                  </div>
                  <div className="clearfix"></div>
                  <div className="modal-footer">
                    <button
                      data-test="submit_button"
                      type="submit"
                      disabled={isLoading || tierWatch === "N/A"}
                      className={
                        isLoading
                          ? "btn cst_btn btn_danger mr-2 btn-wait"
                          : "btn cst_btn btn_danger mr-2"
                      }
                    >
                      {isLoading
                        ? ""
                        : props.isEdit == true
                          ? "Update"
                          : "Submit"}
                    </button>
                    <button
                      onClick={() => {
                        handleCloseFn();
                        props.reset();
                        dispatch(resetMatrixJobs())
                      }}
                      id="closeCriteriaModal"
                      type="button"
                      className="btn btn-outline-secondary cst_btn"
                      data-dismiss="modal"
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const CustomMatrixHoc = withFormHoc(CustomMatrix);

export default CustomMatrixHoc;
