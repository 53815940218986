import React, { useEffect } from 'react';
import { Input } from '../../UI/InputElement/InputElement';
import {  reduxForm } from 'redux-form';
import { NewHolidayValidator as validate } from '../../../../utility/validator/validator';
import { connect } from 'react-redux';
import * as moment from 'moment-timezone';
const cloneDeep = require('clone-deep');

let PayrollHoliday = (props) => {

    const { handleSubmit, pristine, reset, submitting, error, change , initialize} = props;

    useEffect(() => {
        props.dispatch(initialize({holiday : {is_recurring : 'true',customer_pay:"true"}}))
    },[props.clearField])

    

    return (
        <div className="modal fade request_quote_modal reject_quot associateUser" id="payrollHolidayModal" tabindex="-1" role="dialog" aria-labelledby="rejectQuoteModalTitle" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered add_frontline_pop" role="document">
          <div className="modal-content user_newQuote">
            <div className="modal-header">
              <label for="exampleInputEmail1" className="cst-label md_title">Create a Payroll Holiday</label>
              <button type="button" className="close close_icn_pic" data-dismiss="modal" onClick={props.closePopup}><img src="/images/icons/icn_close_gray.png" alt="Close Icon" /></button>
            </div>

            <div className="modal-body">
              <div className="tab-content">
                <div id="profile" className="profile_tab cst_tab_content tab-pane fade show active edit_profile_modal">
                <form onSubmit={handleSubmit} className="form_content">
                        <div className="form_content">
                            <div className="form-group">
                                <label for="accountID" className="label_modifier">Holiday</label>
                                <Input type="text" className="form-control input-modifier" placeholder="" name="holiday[holiday_reason]" />
                                {/* <input type="text" className="form-control input-modifier" id="" placeholder="Type here" name="" value="Good Friday" /> */}
                            </div>
                            <div className="form-group" >
                                <label className="label_modifier">Date</label>
                                <Input name="holiday[holiday_date]" minDate={new Date()} dateFormat="MM-dd-yyyy" placeholder="" datePickerType={"react-datepicker"} type="date" className="form-control input-modifier" />
                            </div>
                            <div style={{display:"flex",flexDirection:"row",justifyContent:"space-evenly"}}>
                                <div className="form-group " style={{display:"flex",flexDirection:"column"}}>
                                    <p className="label_modifier display-block">Is Recurring</p>
                                    <div>
                                        <Input type="radio" labelClass="custom-control-label" forLable={"customRadio1"} value={"true"} label={"Yes"} className="custom-control-input" id="customRadio1" name="holiday[is_recurring]" />
                                        <Input type="radio" labelClass="custom-control-label" forLable={"customRadio2"} value={"false"} label={"No"} className="custom-control-input" id="customRadio2" name="holiday[is_recurring]" />
                                    </div>
                                
                                </div>
                                    <div className="form-group " style={{display:"flex",flexDirection:"column",textTransform:"none"}}>
                                        <p  className="label_modifier display-block">Pay</p>
                                            <div >
                                            <Input type="radio" labelClass="custom-control-label" forLable={"pay1"} value={"true"} label={"Customer Pay"} className="custom-control-input" id="pay1" name="holiday[customer_pay]" />
                                            <Input type="radio" labelClass="custom-control-label" forLable={"pay2"} value={"false"} label={"Pegasus Pay"} className="custom-control-input" id="pay2" name="holiday[customer_pay]" />
                                            </div>
                                    </div>
                            </div>
                            

                           
                        </div>

                        <div className="modal-footer">
                            <button type="submit" disabled={props.isLoadingHollyday} className={props.isLoadingHollyday ? "btn cst_btn btn_danger btn-wait mr-2" : "btn btn_danger cst_btn mr-2"}>{props.isLoadingHollyday ? "" : "Add"}</button>
                            <button data-dismiss="modal" aria-label="Close"  type="button" className="btn btn_outline_secondary cst_btn">Cancel</button>
                        </div>
                    </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div >
    )
}


const mapStateToProps = (state) => {
    let data = cloneDeep(state.adminOrSuperAdminReducer.holiday);
    if (state.adminOrSuperAdminReducer.isEdit) {
        const date = moment(data.holiday_date);
        data.holiday_date = date._d;
        data.is_recurring = data.is_recurring ? "true" : "false";
        data.customer_pay= data.customer_pay ? "true" :"false"
    } else {
        data.is_recurring = "true"
        data.customer_pay="true"
    }
    let holiday = {
        holiday: data
    }
    return { initialValues: holiday}
}

PayrollHoliday = reduxForm({
    form: 'newPayrollHoliday',
    validate
})(PayrollHoliday);

PayrollHoliday = connect(mapStateToProps)(PayrollHoliday)

export default PayrollHoliday;