import React, { useState, useRef, useEffect } from 'react';
import { reduxForm, FieldArray, formValueSelector, change, initialize } from 'redux-form';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { RolesOptions, RatingTypes, routes } from '../../../../utility/constants/constants';
import { Input } from '../../UI/InputElement/InputElement';
import { newInspectionValidator as validate } from '../../../../utility/validator/validator';
import { FaAlignJustify } from "react-icons/fa";
import isEmpty from '../../../../utility/IsEmptyValidator';
import { stopSubmit } from 'redux-form';
import { connect, useDispatch } from 'react-redux';
import * as Scroll from 'react-scroll';
import { showConfirmAlert } from '../../../../utility/successAlert/ConfirmAlert';
import { Prompt } from 'react-router-dom';
import storage from '../../../../utility/storage';
import ReactTooltip from 'react-tooltip';
import { makeFirstLetterCapital } from '../../../../utility/utility';
var moment = require('moment');

const cloneDeep = require('clone-deep');
var scroller = Scroll.scroller;

const LineItems = (props) => {
    const { fields } = props;
    const { isEdit } = props[0];
    const noOptionsMessage = (a, b) => {
        return 'Rating not found';
    }

    const deleteSection = (section) => {
        //  
        if (isEdit) {
            props[0].updateInspectionForm.sections_attributes.splice([props[0].index], 1);
            props[0].setUpdateInspectionForm(props[0].updateInspectionForm);
            //  
            if (section) {
                props[0].deleteInspectionFormSection(section.id);
            }
        }
        props[0].section.remove(props[0].index);
    }

    const deleteLineItem = (fields, index, lineItem) => {
        if (isEdit) {
            props[0].updateInspectionForm.sections_attributes[props[0].index].line_items_attributes.splice([index], 1);
            props[0].setUpdateInspectionForm(props[0].updateInspectionForm);
            if (lineItem) {
                props[0].deleteInspectionFormLineItem(lineItem.id);
            }
        }
        fields.remove(index);
    }

    return (
        <Droppable droppableId={isEdit ? `droppable${props[0].updateInspectionForm.sections_attributes[props[0].index] && props[0].updateInspectionForm.sections_attributes[props[0].index].id}` :
            `${props[0].index}`} type={`${props[0].index}`}>
            {(provided, snapshot) => (
                <div
                    ref={provided.innerRef}
                >
                    {/* <> */}
                    <div className="contact_info rating_info">
                        {fields.map((lineItem, index) =>
                        // (
                        {
                            return (
                                <Draggable
                                    key={
                                        isEdit ?
                                            props[0].updateInspectionForm.sections_attributes[props[0].index] ?
                                                props[0].updateInspectionForm.sections_attributes[props[0].index].line_items_attributes[index] ?
                                                    props[0].updateInspectionForm.sections_attributes[props[0].index].line_items_attributes[index].id ?
                                                        `${props[0].updateInspectionForm.sections_attributes[props[0].index].line_items_attributes[index].id}` :
                                                        `${props[0].index} ${index}` : `${props[0].index} ${index}` : `${props[0].index} ${index}`
                                            : `${props[0].index} ${index}`
                                    }
                                    draggableId={
                                        isEdit ?
                                            props[0].updateInspectionForm.sections_attributes[props[0].index] ?
                                                props[0].updateInspectionForm.sections_attributes[props[0].index].line_items_attributes[index] ?
                                                    props[0].updateInspectionForm.sections_attributes[props[0].index].line_items_attributes[index].id ?
                                                        `${props[0].updateInspectionForm.sections_attributes[props[0].index].line_items_attributes[index].id}` :
                                                        `${props[0].index} ${index}` : `${props[0].index} ${index}` : `${props[0].index} ${index}`
                                            : `${props[0].index} ${index}`
                                    }
                                    index={index}
                                >
                                    {(provided, snapshot) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                        >

                                            <div className="row">
                                                <div className="col-sm-12 col-md-1 col-lg-1 col-xl-1 gutter">
                                                    <span {...provided.dragHandleProps} className="menu_icn">
                                                        {provided.placeholder}
                                                        <FaAlignJustify />
                                                    </span>
                                                </div>
                                                <div className="col-sm-12 col-md-4 col-lg-4 col-xl-3 gutter">
                                                    <div className="form-group">
                                                        <Input name={`${lineItem}.name`} type="text" className="form-control input-modifier bg_gray" id="" aria-describedby="emailHelp" placeholder="Line item name" />
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 col-md-4 col-lg-3 col-xl-3 gutter">
                                                    <div className="form-group" name={`${lineItem}.rating_type_id`}>
                                                        <Input name={`${lineItem}.rating_type_id`} type="inputGroupSelect" className="form-control input-modifier bg_gray" noOptionsMessage={noOptionsMessage} options={props[0].groupedRatingOptions} placeholder="Line item rating type" />
                                                        {/* <Input type="text" className="form-control input-modifier bg_gray" id="" aria-describedby="emailHelp" placeholder="1-3 Ratings" /> */}
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 col-md-2 col-lg-3 col-xl-3 gutter">
                                                    <div className="form-group">
                                                        <Input name={`${lineItem}.weight`} min={0} type="number" className="form-control input-modifier bg_gray" id="" aria-describedby="emailHelp" placeholder="Line item weight" />
                                                    </div>
                                                </div>
                                                {fields.length > 1 ?
                                                    <>
                                                        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-2 gutter">
                                                            <div className="card border-0 description_card">
                                                                <div className="card-body text-center">
                                                                    {/* <!-- add description btn block --> */}
                                                                    <a className="add_des_btn" data-toggle="collapse" href={`#descriptionCollapse${props[0].index}${index}`} role="button" aria-expanded="false" aria-controls={`descriptionCollapse${props[0].index}${index}`}>
                                                                        <i class="fa fa-plus-square"></i>
                                                                    </a>
                                                                    <a href="javascript:void(0)" onClick={() => deleteLineItem(fields, index, props[0].updateInspectionForm.sections_attributes[props[0].index].line_items_attributes[index])} className=" delete_icn_block icn_block">
                                                                        <i className="fas fa-trash-alt cst_icon mr-2"></i>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* <!-- add description content --> */}
                                                        <div className="collapse description-collapse" id={`descriptionCollapse${props[0].index}${index}`}>
                                                            <div className="form-group">
                                                                <Input type="textarea" name={`${lineItem}.description`} className="form-control textarea_modifier" id="exampleFormControlTextarea1" rows="3" placeholder="Description" />
                                                            </div>
                                                        </div>
                                                    </>
                                                    : null}
                                            </div>
                                        </div>
                                    )}
                                </Draggable>
                            )
                        }
                            // )
                        )}

                    </div>
                    <div className="card_footer">
                        <div className="btn_block text-center">
                            <button type="button" className="btn cst_btn btn_danger_outline mr-2" onClick={() => fields.push({})}>ADD SUB SECTION</button>
                            <button type="button" className="btn cst_btn btn_secondary_outline" onClick={() => deleteSection(props[0].updateInspectionForm.sections_attributes[props[0].index])}>DELETE SECTION</button>
                        </div>
                    </div>
                    {/* </> */}
                </div>
            )}
        </Droppable>
    )

}

const Section = (props) => {
    const { fields, change } = props;
    const { isEdit, getModeAccounts } = props[0];
    const noOptionsMessage = (a, b) => {
        return 'Job not found';
    }
    const getMode = (data) => {
        const mode = data.target.value;
        props[0].parantProps.change("inspection_form[account_ids]", "");
        getModeAccounts(mode);
    }
    let sections_attributes = {}

    sections_attributes['line_items_attributes'] = [{}, {}, {}]
    let isPrivate = false;
    let isInventory = false;
    if (props[0].inspectionForm && props[0].inspectionForm.inspection_form) {
        console.log('props[0].inspectionForm', props[0].inspectionForm);
        isPrivate = props[0].inspectionForm.inspection_form.private;
    }
    if (props[0].inspectionForm && props[0].inspectionForm.inspection_form) {
        console.log('props[0].inspectionForm', props[0].inspectionForm);
        isInventory = props[0].inspectionForm.inspection_form.inventory;
    }
    
    // sections_attributes.line_items_attributes.map(lineAttribute => {
    //     lineAttribute['weight'] = 1;
    // })

    return (


        <div className="card-body inner_card">
            <div className="form_info">

                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group">
                            <label className="form_title">Name</label>
                            <Input type="text" name={'inspection_form[name]'} className="form-control input-modifier" placeholder="Name, tittle or heading of the form" />
                        </div>
                        <div className="checkbox_block radius_checkbox">
                            <div className={props[0].isEdit && isPrivate  ? 'disabled_checkbox custom-control custom-checkbox' : 'custom-control custom-checkbox'}>
                            { props[0].isEdit && isPrivate ? <Input name="inspection_form[private]" type="checkbox" className="custom-control-input" id="customCheck1" checked="" disabled/>
                            :
                            <Input name="inspection_form[private]" type="checkbox" className="custom-control-input" id="customCheck1" checked=""/>
                            }
                                <label className="custom-control-label" for="customCheck1">
                                    {props[0].updateInspectionForm.private ? <span className="icn_lock"><i className="fa fa-lock"></i></span>
                                        : <span className="icn_lock"><i className="fa fa-unlock-alt"></i></span>}
                                Private - If checked, this inspection and results will ONLY be visible to users with private inspection permissions.</label>
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="form_title">Category</label>
                            <Input name="inspection_form[category_id]" type="select" className="custom-select input-modifier add_user_select" noOptionsMessage={noOptionsMessage} options={props[0].CategoriesOptions} placeholder="Select" />
                        </div>
                        
                       {/* <div className="form-group">
                            <label className="form_title">Departments</label>
                            <Input name="inspection_form[department_id]" type="select" className="custom-select input-modifier add_user_select" noOptionsMessage={noOptionsMessage} options={props[0].DepartmentOptions} placeholder="Select" />
                           
                        </div> */}
                        <div className="form-group">
                        <label className="form_title">Criteria</label>
                        { props[0].isEdit ? <Input className='mb-3 width_80' type="inputSelect" name="inspection_form[criteria_id]" placeholder="Please select" readOnly={true} /> : <Input name="inspection_form[criteria_id]" type="select" options={props[0].CriteriaOptions}  className="custom-select input-modifier add_user_select" />}
                    </div>
                    </div>
                    <div className="col-md-6">
                    {/* {props[0].isEdit ? '' : */}
                        <div className="form-group">
                            <div>
                            <label className="form_title">Mode</label>
                            { props[0].isEdit ? <Input name="inspection_form[mode]" type="select" options={props[0].UserModes} onChange={getMode} disabled className="custom-select input-modifier add_user_select" /> : <Input name="inspection_form[mode]" type="select" options={props[0].UserModes} onChange={getMode} className="custom-select input-modifier add_user_select" />}
                            </div>
                        </div>
                    {/* } */}
                        <div className="form-group">
                            <label name="inspection_form[account_ids]" className="form_title">Available to</label>
                            {/* <Input name="quote[account_id]" noOptionsMessage={noOptionsMessage} options={accounts} /> */}
                            <Input name="inspection_form[account_ids]" isMulti={true} type="inputGroupSelect" className="input-modifier" noOptionsMessage={noOptionsMessage} options={props[0].groupedAccountOptions} placeholder="JOB-WIDE" />
                        </div>
                        <label className="cst_label">Site-specific forms can only be used within that site.</label>
                        <div className="checkbox_block radius_checkbox">
                        <div className={'custom-control custom-checkbox'} >                    
                        <Input style={{cursor: "pointer"}} name="inspection_form[inventory]" type="checkbox" className="custom-control-input" id="customCheck2" checked=""/>
                        <label style={{cursor: "pointer", color: "#566975"}} className="custom-control-label pl-2" for="customCheck2">Inventory
                        </label>
                        </div>
                    </div>
                    <div className="checkbox_block radius_checkbox">
                        <div className={'custom-control custom-checkbox'} >                    
                        <Input style={{cursor: "pointer"}} name="inspection_form[tag_follow_up]" type="checkbox" className="custom-control-input" id="customCheck3" checked=""/>
                        <label style={{cursor: "pointer", color: "#566975"}} className="custom-control-label pl-2" for="customCheck3">Tag Follow Up
                        </label>
                        </div>
                    </div>
                    </div>
                </div>

                <div className="btn_block">
                    <button type="button" className="btn cst_btn btn_danger_outline" onClick={() => fields.push(sections_attributes)}>ADD SECTION</button>
                    <a href="javascript:void(0)" class="weight_icn" data-toggle="modal" data-target="#weightModalLabel">Weight<img src="/images/icons/icn_info.png" class="ml-1" /></a>
                </div>
            </div>

            <Droppable droppableId="droppable" type="section">
                {(provided) => (
                    <div ref={provided.innerRef}>
                        {
                            fields.map((section, index) => {
                                console.log(`key=${index}`);
                                console.log(`draggableId=${isEdit ? section.id ? section.id : index : index}`);
                                console.log(`index=${index}`);
                                return (
                                    <Draggable
                                        key={`${index}`}
                                        draggableId={isEdit ? section.id ? `${section.id}` : `${index}` : `${index}`}
                                        index={index}
                                    >
                                        {(provided, snapshot) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                            >

                                                <div className="card rating_form_card edit_info_form mb-0">
                                                    <span {...provided.dragHandleProps} className="menu_icn">
                                                        {provided.placeholder}
                                                        <FaAlignJustify />
                                                    </span>
                                                    <div className="card-body rating_card_body">
                                                        <div className="ins_form_btn">
                                                            <Input name={`${section}.name`} type="text" className="form-control input-modifier input-modifier1" id="" aria-describedby="emailHelp" placeholder="Enter a section name here" />
                                                        </div>
                                                        <FieldArray name={`${section}.line_items_attributes`} component={LineItems}
                                                            props={[{
                                                                section: fields, index: index, groupedRatingOptions: props[0].groupedRatingOptions,
                                                                isEdit: isEdit, updateInspectionForm: props[0].updateInspectionForm,
                                                                setUpdateInspectionForm: props[0].setUpdateInspectionForm,
                                                                deleteInspectionFormSection: props[0].deleteInspectionFormSection,
                                                                deleteInspectionFormLineItem: props[0].deleteInspectionFormLineItem,
                                                                ...provided.droppableProps
                                                            }]} />
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </Draggable>
                                )
                            }
                            )
                        }
                    </div>
                )}
            </Droppable>


            {/* <!-- Weight Modal html here --> */}
            <div class="modal fade weight_modal" id="weightModalLabel" tabindex="-1" role="dialog" aria-labelledby="weightModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-lg" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLongTitle">Line Item Weight</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <p class="cst_para">The score for an inspection is based on a weighted average of the line items.
                             By changing the "weight," you can make some items count more towards the overall score.</p>
                            <p class="cst_para">For example, say you have an inspection with three items: "Item 1," "Item 2," and "Item 3." All of them are out of 100 percentage points. "Item 3" is the most important item, and you want to make sure it is done well. </p>
                            <p class="cst_para">Therefore, you give it a weight of 2 (it is twice as important as other items). If an inspection is performed and "Item 1" gets a 65, "Item 2" gets a 75, and "Item 3" gets a 100, then the overall score would be:</p>
                            <div class="card cst_modal_card">
                                <div class="card-body">
                                    <p class="cst_para">(65*1) + (75*1) + (100*2) </p>
                                    <p class="cst_para">-----------------------------------</p>
                                    <p class="cst_para">100*1 + 100*1 + 100*2</p>
                                </div>
                            </div>
                            <p class="cst_para"> This would give you an overall score of 85%.</p>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary btn-outline-secondary" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- // Weight Modal html here --> */}

        </div >

    )
}


let NewInspectionForm = (props) => {
    //  
    // function usePrevious(value) {
    //     const ref = useRef();
    //     useEffect(() => {
    //         ref.current = value;
    //     });
    //     return ref.current;
    // }

    const { handleSubmit, pristine, reset, submitting, error } = props;
    let allAccountIds = [];

    const [updateInspectionForm, setUpdateInspectionForm] = cloneDeep(useState(props.initialValues.inspection_form));

    // const prevBookingList = cloneDeep(usePrevious(props.initialValues.inspection_form));
    const dispatch = useDispatch();

    useEffect((prevProps, prevState) => {
        if (props.formValues.inspection_form) {
            setUpdateInspectionForm(props.formValues.inspection_form);
        }
    }, [props.formValues.inspection_form])

    const userTimezone=props.userTimezone
    
    // const didMountRef = useRef(false);
    // useEffect((prevProps, prevState) => {
    //     if (didMountRef.current) {
    //         if ((props.initialValues.inspection_form !== prevBookingList)) {
    //             // setUpdateInspectionForm(props.initialValues.inspection_form);
    //         }
    //     } else {

    //         didMountRef.current = true
    //     }

    //     // Update the document title using the browser API
    //     // document.title = `You clicked ${count} times`;
    // });

    const accounts = props.accountsList.map(account => {
        allAccountIds.push(account.id)
        return {
            value: account.id,
            label: account.name
        }
    });

    const CategoriesOptions = props.categoriesList && props.categoriesList.map(c => {
        return {
            id: c.category.id,
            name: c.category.name
        }
    })

    const CriteriaOptions = props.criteries && props.criteries.map(c => { 
        return {
            id: c.id,
            name: makeFirstLetterCapital(c.name && c.name.length > 32 ? c.name.substr(0, 32 - 1) + "..." : c.name)
        }
    })


    const UserModes = [
        // {
        //     id: 'all',
        //     name: 'All'
        // },
        {
            id: 'internal',
            name: 'Internal'
        },
        {
            id: 'external',
            name: 'External'
        }
    ]

    const PercentageRatings = props.ratingList.filter(p => p.category === RatingTypes.Percentage);
    const Percentages = PercentageRatings.map(rating => {
        if (rating.category === RatingTypes.Percentage) {
            return {
                value: rating.id,
                label: rating.name
            }
        }
    })

    const ListRatings = props.ratingList.filter(l => l.category === RatingTypes.List);
    const ListPickers = ListRatings.map(rating => {
        if (rating.category === RatingTypes.List) {
            return {
                value: rating.id,
                label: rating.name
            }
        }
    })

    const TextRatings = props.ratingList.filter(l => l.category === RatingTypes.Text);
    const Text = TextRatings.map(rating => {
        if (rating.category === RatingTypes.Text) {
            return {
                value: rating.id,
                label: rating.name
            }
        }
    })

    const groupedAccountOptions = [
        {
            label: 'Available to all Jobs',
            options: [{
                value: allAccountIds,
                label: 'Job-wide'
            }]
        },
        {
            label: 'Site-Specific',
            options: accounts
        }
    ]

    const groupedRatingOptions = [
        {
            label: 'Percentages',
            options: Percentages
        },
        {
            label: 'List Picker',
            options: ListPickers
        },
        {
            label: 'Text',
            options: Text
        }
    ]

    const onDragEnd = (result) => {

        const startIndex = result.source.index;
        const endIndex = result.destination ? result.destination.index : result.source.index;
        const inspectionForms = cloneDeep(props.formValues.inspection_form);

        if (result.type === 'section') {
            const [removed] = inspectionForms.sections_attributes.splice(startIndex, 1);
            inspectionForms.sections_attributes.splice(endIndex, 0, removed);

            inspectionForms.sections_attributes.map((sec, i) => {
                sec['sort'] = i;
            })

        } else {
            const secIndex = parseInt(result.type);
            const [removed] = inspectionForms.sections_attributes[secIndex].line_items_attributes.splice(startIndex, 1);
            inspectionForms.sections_attributes[secIndex].line_items_attributes.splice(endIndex, 0, removed);

            inspectionForms.sections_attributes.map((sec, i) => {
                sec['sort'] = i;
                sec.line_items_attributes.map((line, i) => {
                    line['sort'] = i;
                })
            })
        }

        // setUpdateInspectionForm(inspectionForms);

        let inspection_form = {};
        inspection_form['inspection_form'] = inspectionForms;
        dispatch(initialize("newInspectionForm", inspection_form));
        // props.reorderDragAndDrop(inspectionForms);
    }

    const backIconClicked = () => {
        storage.remove('inspectionFormData');
        props.history.goBack()
    }

    const cancelInspectionClicked = () => {
        storage.remove('inspectionFormData');
        props.makeCancelInspectionStateFalse();
        // props.history.push(routes.INSPECTIONS);
        props.history.push(routes.INSPECTION_FORMS)
    }

    

    const blockMessage = (e) => {
        ;
        // if (props.cancelClicked && !props.isLoading) {
        //     showConfirmAlert("Are you sure?", 'Your changes are not saved. Do you still want to leave?', () => {
        //         cancelInspectionClicked()
        //         props.history.push(e.pathname);
        //     })
        //     return false
        // }

        return true
    }
    return (
        <main className="app-content wraper_content edit_inspection settings">
            {props.isDataChanged && <Prompt
                when={true}
                message={blockMessage}
            />}
            <section className="appcontent_Inner">
                <div className="row">
                    <div className="col-md-12">
                        <div className="card edit_ins_content setting_form_con">
                            <div className="card_header d_flex" style={{padding: "0.5rem 1.75rem"}}>
                                <h5 className="sm_title">
                                    <a href="javascript:void(0)" style={{ cursor: 'pointer' }}
                                        onClick={() => backIconClicked()} className="mr-2">
                                        <img src="/images/icons/icn_back_arrow.png" alt="Icon" /></a>
                                    <a href="javascript:void(0)" className="mr-2"></a>
                                    {props.isEdit ? "Update Inspection Form" : "New Inspection Form"}</h5>
                                    {props.isEdit ? <> <div className="d-flex"> <div style={{display: "grid"}}> <span> Created At : {moment.tz(props.inspectionForm?.inspection_form?.created_at,userTimezone).format('MM-DD-YYYY')}</span> <span>Updated At : {moment.tz(props.inspectionForm?.inspection_form?.updated_at,userTimezone).format('MM-DD-YYYY') }</span> <span>Last Edited By : TBD</span> </div> <span data-tip="Create a new copy" style={{padding: "25px"}} data-backdrop="static" data-keyboard="false" data-toggle="modal" data-target="#InspectionDuplicateModal" onClick={() => props.onInspectionDuplicateModalClicked()} className="icn_lock"><i className="fas fa-copy" style={{ fontSize: 'large', cursor: 'pointer' }}></i></span></div>
                                    <ReactTooltip place="top" type="dark" effect="solid" />
                                </> : null}
                                {/* {props.isEdit ? <><span data-tip="Create a new copy" onClick={props.createDublicateInspectionForm} className="icn_lock"><i className="fas fa-copy" style={{ fontSize: 'large', cursor: 'pointer' }}></i></span>
                                    <ReactTooltip place="top" type="dark" effect="solid" />
                                </> : null} */}
                            </div>
                            <form onSubmit={handleSubmit}>
                                <DragDropContext
                                    onDragEnd={onDragEnd}
                                >
                                    <FieldArray name="inspection_form[sections_attributes]"
                                        props={[{
                                            CategoriesOptions: CategoriesOptions,
                                            CriteriaOptions: CriteriaOptions,
                                            groupedAccountOptions: groupedAccountOptions, groupedRatingOptions: groupedRatingOptions,
                                            isEdit: props.isEdit,
                                            inspectionForm: props.inspectionForm,
                                            updateInspectionForm: updateInspectionForm, setUpdateInspectionForm: setUpdateInspectionForm,
                                            deleteInspectionFormSection: props.deleteInspectionFormSection,
                                            deleteInspectionFormLineItem: props.deleteInspectionFormLineItem,
                                            getModeAccounts: props.getModeAccounts,
                                            formValues: props.formValues,
                                            UserModes: UserModes,
                                            parantProps: {...props}
                                        }]} component={Section} />
                                    <div className="card_footer">
                                        <div className="btn_block text-center">
                                            <button type="submit" className={props.isLoading ? "btn cst_btn btn_danger mr-2 btn-wait" : 'btn cst_btn btn_danger mr-2'}>{props.isLoading ? '' : 'Save'}</button>
                                            <button type="button"
                                                onClick={() => cancelInspectionClicked ()} className="btn cst_btn btn-outline-secondary  cancel_btnnew">Cancel</button>
                                        </div>
                                    </div>
                                </DragDropContext>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    )
}


const mapStateToProps = (state, props) => {

    const CurrentInspectonFormData = storage.get("inspectionFormData", null);
    
    let inspection_form =
        state.adminOrSuperAdminReducer.inspectionForm.inspection_form && state.adminOrSuperAdminReducer.inspectionForm.inspection_form.id ?
            state.adminOrSuperAdminReducer.inspectionForm.inspection_form :
            CurrentInspectonFormData && CurrentInspectonFormData.inspection_form !== 'undefined' && CurrentInspectonFormData.inspection_form && !isEmpty(CurrentInspectonFormData.inspection_form) ? CurrentInspectonFormData.inspection_form
                :
                cloneDeep(state.adminOrSuperAdminReducer.inspectionForm.inspection_form ?
                    state.adminOrSuperAdminReducer.inspectionForm.inspection_form : state.adminOrSuperAdminReducer.inspectionForm);
    

    if (isEmpty(inspection_form)) {
        inspection_form['sections_attributes'] = [{}]
        inspection_form.sections_attributes[0]['line_items_attributes'] = [{}, {}, {}]
        // inspection_form.sections_attributes.map(section => {
        //     section.line_items_attributes.map(lineAttribute => {
        //         lineAttribute['weight'] = 1;
        //     })
        // })
    } else if (inspection_form.id) {
        // }
        // else {
        //  
        
        
        const getAccountId = (account) => {
            return account.map(a =>
                ({
                    value: a.id,
                    label: a.name
                })
            )
        }
        if (inspection_form.form_category === 'account_wide') {
            const accountIds = {
                value: [],
                label: 'Job-wide'
            }
            inspection_form['account_ids'] = [accountIds];
            if (state.adminOrSuperAdminReducer.accountsList) {
                state.adminOrSuperAdminReducer.accountsList.map(a => (
                    inspection_form.account_ids[0]['value'].push(a.user ? a.user.id : a.id)
                ));
            }
        } else {
            inspection_form['account_ids'] = getAccountId(inspection_form.accounts);
        }
        inspection_form.sections_attributes.map(section => {
            section.line_items_attributes.map(lineAttribute => {
                if (lineAttribute.rating_type) {
                    lineAttribute['rating_type_id'] = { value: lineAttribute.rating_type.id, label: lineAttribute.rating_type.name }
                }
            })
        })

        // inspection_form = inspection_form;
        if (inspection_form.category) {
            inspection_form['category_id'] = inspection_form.category.id;
        }
        if (inspection_form.tag_follow_up) {
            inspection_form['tag_follow_up'] = inspection_form.tag_follow_up;
        }
        // inspection_form['account_id']
        inspection_form['criteriaId'] = inspection_form?.criteriaId ? inspection_form?.criteriaId : inspection_form?.criteria_id;
        inspection_form['criteria_id'] = {value: props?.inspectionForm?.inspection_form?.criteria?.id, label: props?.inspectionForm?.inspection_form?.criteria?.name}
        //inspection_form['criteriaId'] = inspection_form?.criteria_id
        
    }

    return { initialValues: { inspection_form } }

}

NewInspectionForm = reduxForm({
    form: 'newInspectionForm',
    validate,
    // enableReinitialize: true,
    onSubmitFail: (errors) => scrollToFirstError(errors),
})(NewInspectionForm);

NewInspectionForm = connect(mapStateToProps)(NewInspectionForm);

export const scrollToFirstError = (errors) => {

    let scroolToError = null;
    let InspectionErrors = {}
    // InspectionErrors['inspection'] = {};
    InspectionErrors['inspection_form'] = {};
    if (errors.inspection_form.name) {
        scroolToError = `inspection_form[name]`;
    } else if (errors.inspection_form.account_ids) {
        scroolToError = `inspection_form[account_ids]`;
    } else if (errors.inspection_form.mode) {
        scroolToError =  `inspection_form[mode]`;
    } else {
        InspectionErrors.inspection_form['sections_attributes'] = cloneDeep(Object.values(errors.inspection_form.sections_attributes));
        // cloneDeep(errors);
        let secIndex = null;
        let lineIndex = null;

        InspectionErrors.inspection_form.sections_attributes.map((section, sIndex) => {

            if (!scroolToError || scroolToError === '') {
                if (secIndex !== null && lineIndex !== null) {
                    return false
                }
                if (section.name) {
                    scroolToError = `inspection_form[sections_attributes][${sIndex}].name`;
                    return false;
                }

                section['line_items_attributes'] = cloneDeep(Object.values(errors.inspection_form.sections_attributes[sIndex].line_items_attributes));
                section.line_items_attributes.map((line, lIndex) => {
                    if (!scroolToError || scroolToError === '') {
                        if (line.name) {

                            scroolToError = `inspection_form[sections_attributes][${sIndex}].line_items_attributes[${lIndex}].name`;
                            return false
                        }
                        if (line.rating_type_id) {

                            scroolToError = `inspection_form[sections_attributes][${sIndex}].line_items_attributes[${lIndex}].rating_type_id`;
                            return false
                        }
                    }
                })
            }
        })
    }

    // Object.keys(errors.inspection.inspection_form.sections_attributes[secIndex[0]].line_items_attributes);

    scroller.scrollTo(`${scroolToError}`, {
        duration: 500,
        delay: 1,
        isDynamic: true,
        smooth: true,
        // containerId: 'ContainerElementID',
        offset: -100,
    })
}

export default NewInspectionForm;