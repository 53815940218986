import storage from "../../../../utility/storage";
import SuccessAlert from "../../../../utility/successAlert/successAlert";
import { InventoryModuleActionTypes } from "../../../actions/FormAuditModAction/InventoryAction/InventoryType";



export const initialState = {
    dataGenerated: false,
    teamManagementToggle: false,
    os1Toggle: false,

    isEditLog: false,
    isUpdateTrainingLog: false,
    monthly_report_list: undefined,
    is_Report: true,
    inventoryList: [],
    isLoading: false,
    isEditLog: false,
    isUpdateDailykitting: false,
    dailyKittingDetails: null,
    isMonthlycostReport: false,
    monthly_cost_report_list: [],
    isLoadMonthlyCost: false,
    monthly_summary_form:null,
    isCostfetched:false,

    isdailyKitting: false,
    dailyKitting_list: [],
    dailyKitting_total_pages: 1,
    dailyKitting_no_of_rows: 10,
    dailyKittingCurPage: 0,
    total_dailyKitting_count: '',
};

const updateObject = (oldState, updatedProps) => {
    return {
        ...oldState,
        ...updatedProps,
    };
};

export const InventoryModuleReducer = (state = initialState, action) => {
    switch (action.type) {

        case InventoryModuleActionTypes.GET_ALL_MONTHLY_REPORT_PRODUCTS_PENDING:

            return updateObject(state, {
                isMonthlyReport: true
            });

        case InventoryModuleActionTypes.GET_ALL_MONTHLY_REPORT_PRODUCTS_FULFILLED:

            return updateObject(state, {
                isMonthlyReport: false,
                monthly_report_list: action.payload,
            });

        case InventoryModuleActionTypes.ADD_SINGLE_DAILY_KITTING_PENDING:

            return updateObject(state, {
                isLoading: true
            });

        case InventoryModuleActionTypes.ADD_SINGLE_DAILY_KITTING_FULFILLED:

            return updateObject(state, {
                isLoading: false
            });

        case InventoryModuleActionTypes.GET_ALL_DAILY_KITTING_PENDING:
            return updateObject(state, {
                isLoading: true,
                isdailyKitting: true,
                dailyKitting_list: [],
            });
        case InventoryModuleActionTypes.GET_ALL_DAILY_KITTING_FULFILLED:
            return updateObject(state, {
                isLoading: false,
                dataGenerated: false,
                isdailyKitting: false,
                dailyKitting_list: action.payload.dailyKitting_list,
                dailyKitting_total_pages: action.payload.dailyKitting_total_pages,
                dailyKitting_no_of_rows: action.payload.dailyKitting_no_of_rows,
                dailyKittingCurPage: action.payload.dailyKittingCurPage,
                total_dailyKitting_count: action.payload.total_dailyKitting_count,
            });

        case InventoryModuleActionTypes.GET_ALL_INVENTORY_LIST_PENDING:

            return updateObject(state, {
                inventoryList: []
            });


        case InventoryModuleActionTypes.GET_ALL_INVENTORY_LIST_FULFILLED:

            return updateObject(state, {
                inventoryList: action.payload
            });


        case InventoryModuleActionTypes.GET_SINGLE_DAILY_KITTING_PENDING:
            return updateObject(state, {
                isLoading: false,
                isEditLog: false,
                isUpdateDailykitting: true,
                dailyKittingDetails: null,
            });

        case InventoryModuleActionTypes.GET_SINGLE_DAILY_KITTING_FULFILLED:
            return updateObject(state, {
                isLoading: false,
                isEditLog: false,
                isUpdateDailykitting: false,
                dailyKittingDetails: action.payload,
            });




        case InventoryModuleActionTypes.EDIT_SINGLE_DAILY_KITTING_PENDING:
            return updateObject(state, {
                isLoading: true,
                isEditLog: false,

            });

        case InventoryModuleActionTypes.EDIT_SINGLE_DAILY_KITTING_FULFILLED:
            return updateObject(state, {
                isLoading: false,
                isEditLog: false,

            });



        case InventoryModuleActionTypes.ADD_MONTHLY_USAGE_REPORTS_PENDING:
            return updateObject(state, {
                isLoading: true,
                isEditLog: false,

            });

        case InventoryModuleActionTypes.ADD_MONTHLY_USAGE_REPORTS_FULFILLED:
            return updateObject(state, {
                isLoading: false,
                isEditLog: false,

            });
        case InventoryModuleActionTypes.ADD_MONTHLY_USAGE_REPORTS_REJECTED:
            return updateObject(state, {
                isLoading: false,
                isEditLog: false,

            });

        case InventoryModuleActionTypes.GET_ALL_MONTHLY_REPORT_COST_PENDING:

            return updateObject(state, {
                isMonthlycostReport: true,
                isLoadMonthlyCost: true,
                isCostfetched:false,
            });

        case InventoryModuleActionTypes.GET_ALL_MONTHLY_REPORT_COST_FULFILLED:

            return updateObject(state, {
                isMonthlycostReport: false,
                monthly_cost_report_list: action.payload,
                isLoadMonthlyCost: false,
                isCostfetched:true,
            });

        case InventoryModuleActionTypes.GET_ALL_MONTHLY_REPORT_COST_REJECTED:
            return updateObject(state, {
                isMonthlycostReport: false,
                monthly_cost_report_list: [],
                isLoadMonthlyCost: false,
                    isCostfetched:false,
            });



        case InventoryModuleActionTypes.ADD_BULK_MONTHLY_REPORT_COST_PENDING:
            return updateObject(state, {
                isLoading: true,
                isEditLog: false,

            });

        case InventoryModuleActionTypes.ADD_BULK_MONTHLY_REPORT_COST_FULFILLED:
            return updateObject(state, {
                isLoading: false,
                isEditLog: false,

            });
        case InventoryModuleActionTypes.ADD_BULK_MONTHLY_REPORT_COST_REJECTED:
            return updateObject(state, {
                isLoading: false,
                isEditLog: false,

            });

                    

         case InventoryModuleActionTypes.GET_SINGLE_MONTHLY_SUMMARY_FORM_DATA_PENDING:
            
            return updateObject(state, {
                isMonthlycostReport: true,
                isLoadMonthlyCost:true
            });

            case InventoryModuleActionTypes.GET_SINGLE_MONTHLY_SUMMARY_FORM_DATA_FULFILLED:
            
            return updateObject(state, {
                isMonthlycostReport: false,
                monthly_summary_form: action.payload,
                isLoadMonthlyCost:false
            });

            case InventoryModuleActionTypes.GET_SINGLE_MONTHLY_SUMMARY_FORM_DATA_REJECTED:
                return updateObject(state, {
                    isMonthlycostReport: false,
                    monthly_summary_form: null,
                    isLoadMonthlyCost:false
                });



            case InventoryModuleActionTypes.RESET_SINGLE_MONTHLY_SUMMARY_FORM_DATA:
            
            return updateObject(state, {
                isMonthlycostReport: false,
                isLoadMonthlyCost:false,
                monthly_cost_report_list:[],
                isCostfetched:false,
            });
            
            case InventoryModuleActionTypes.RESET_MONTHLY_SUMMARY_FORM_DATA:
            
            return updateObject(state, {
                
                isMonthlyReport:false,
                monthly_report_list:undefined,
                monthly_summary_form:null,
            });
           
            





        default:
            return state;
    }
};
