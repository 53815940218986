import React, { useEffect, useState } from 'react';
import ReactTable from 'react-table-v6';
import { makeFirstLetterCapital, CustomNoDataComponent, getUser } from '../../../../../utility/utility';
import { MagicSpinner, PushSpinner, SwishSpinner, WaveSpinner, ClapSpinner } from 'react-spinners-kit';
import '../../../../../../node_modules/react-table-v6/react-table.css';
import { useSelector, useDispatch } from 'react-redux';
import { FaArrowDown, FaArrowUp } from "react-icons/fa";
import * as actions from '../../../../../redux/actions/index';
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { Select } from 'antd';
import { qrCodeSize_option } from '../../../../../utility/constants/constants';
import Dialog from '@material-ui/core/Dialog';
import moment from 'moment';

const AssetStockTable = (props) => {
  const [pageSize, setPageSize] = useState(10);
  const [openModel, setOpen] = useState(false);
  const [openQrModel, setQROpen] = useState(false);
  const [currentQrArea, setCurrentQrArea] = useState({});
  const [qrCodeSize, setQrCodeSize] = useState('medium');
  const [qrCodeSize_MedSmall, setQrCodeSize_MedSmall] = useState(null);
  const [isQrCodeRender, setIsQrCodeRender] = useState(false);

  const dispatch = useDispatch();
  const SearchValue = useSelector(state => state.form.searchAssetsListForm && state.form.searchAssetsListForm.values ? state.form.searchAssetsListForm.values : {});
  const DateGenerate = useSelector(state => state.InventoryReducer.dataGenerated);
  const values = useSelector(state => state.InventoryReducer.assetStock_searchValues);
  const SearchOrResetAssetsClicked = useSelector(state => state.InventoryReducer.SearchOrResetAssetsClicked);
  const No_of_pages = useSelector(state => state.InventoryReducer.assestStock_pages);
  const No_of_rows = useSelector(state => state.InventoryReducer.assestStock_no_of_rows);
  const assestsCurPage = useSelector(state => state.InventoryReducer.assestStockCurPage);

  const printQr = () => {
    const input = document.getElementsByClassName("qr-body-single")[0];
    html2canvas(input, { useCORS: true, allowTaint: true })
      .then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF();
        pdf.addImage(imgData, 'PNG', 10, 10);
        //pdf.output('dataurlnewwindow');
        pdf.save("download.pdf");
      })
  };

  // useEffect(() => {
  //   if (SearchOrResetAssetsClicked) {
  //       dispatch(actions.resetSearchOrResetInventory())
  //   } else {
  //     const page = 1;
  //     const limit = props.assests_no_of_rows
  //     const type = 'asset'
  //     const status = props.activeAssets ? 'active' : 'inactive'
  //     props.getAllAssestStock({page, limit, type, status}, values, props.match.params.assetId);
  //   }
  // }, [props.activeAssets]);

  const data = props.assestsList && props.assestsList.map(asset => {
    return {
      sku_detail: asset && asset.sku_detail ? asset.sku_detail : '–',
      // id : asset.id,
      name: makeFirstLetterCapital(asset.name),
      status: asset.status === 'checkedOut' ? 'Checked Out' : asset.status === 'needsService' ? 'Need Service' : asset.status === 'readyForUse' ? 'Ready For Use' : asset.status === 'underRepair' ? 'Under Repair' : '-',
      //quantity : asset.item ? asset.item.initial_quantity : '-',
      //in_stock_quantity : asset.item ? asset.item.current_quantity : '-',
      //approver:makeFirstLetterCapital(asset.supplier) ,
      qr_code: <button type="button" className="btn cst_btn btn_danger " onClick={() => [
        setQROpen(true), setCurrentQrArea(asset && asset.attachments && asset.attachments[0] && asset.attachments[0] ? asset.attachments[0] : null)
      ]}>Show QR Code</button>,
      actions: <>
        <span className="mr_20 account_edit_icn" onClick={() => props.history.push(`/assets/view-asset-stock/${asset.id}`)}><i class="fas fa-eye" aria-hidden="true" ></i></span>
        <span className="mr_20 account_edit_icn" onClick={() => props.history.push(`/edit-asset-stock/${asset.id}`)}><i class="fa fa-pencil" aria-hidden="true" ></i></span>
        <span className="mr_20 account_edit_icn" onClick={() => props.handleInventoryOpenDeleteModal(asset)}><i class="fa fa-trash" aria-hidden="true" ></i></span>
      </>

    }
  })


  const columns = [
    {
      Header: <><span>Serial Number</span><FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
      accessor: 'sku_detail', // String-based value accessors!,
      show: props.assestsList.length !== 0,
    },
    {
      Header: <><span>Asset Name</span><FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
      accessor: 'name', // String-based value accessors!,
      show: props.assestsList.length !== 0,
    },
    {
      Header: <><span>Asset Status</span><FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
      accessor: 'status', // String-based value accessors!,
      show: props.assestsList.length !== 0,
    },
    //   Header: <><span>Quantity</span><FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
    //   accessor: 'quantity', // String-based value accessors!,
    //   show: props.assestsList.length !== 0,
    // },
    // {
    //   Header: <><span>Instock Quantity</span><FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
    //   accessor: 'in_stock_quantity', // String-based value accessors!,
    //   show: props.assestsList.length !== 0,
    // },
    // {
    //   Header: <><span>Supplier</span><FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
    //   accessor: 'approver', // String-based value accessors!,
    //   show: props.assestsList.length !== 0,
    // },
    {
      Header: <><span>QR Scanner</span><FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
      accessor: 'qr_code', // String-based value accessors!,
      show: props.assestsList.length !== 0,
    },
    {
      Header: " ",
      accessor: "actions",
      show: props.assestsList.length !== 0,
      width: 100
    }
  ]

  const [tablePage, setTablePage] = useState(0)
  const tablePageNew = React.useRef()
  if (assestsCurPage !== tablePageNew.current) {
    tablePageNew.current = assestsCurPage;
  }
  const setTablePageFn = (page) => {
    setTablePage(page)
    props.assetStockPageClicked(page)
    tablePageNew.current = page;
  }

  const fetchData = (state, instance) => {
    if (SearchOrResetAssetsClicked) {
      dispatch(actions.resetSearchOrResetInventory())
    } else {
      if (!DateGenerate) {
        state.page = 2;
        const limit = state.pageSize;
        const fl_users_no_of_rows = state.pageSize;
        let page = tablePageNew.current >= 0 ? tablePageNew.current + 1 : assestsCurPage + 1;
        if (No_of_rows != fl_users_no_of_rows) {
          page = 1;
          setTablePageFn(0)
        }
        const sorted = state.sorted;
        const type = 'asset'

        const sku_detail = SearchValue.sku_detail ? SearchValue.sku_detail : '';
        const name = SearchValue.name ? SearchValue.name : '';
        const status = SearchValue.status ? SearchValue.status : '';
        const location = SearchValue.location ? SearchValue.location : '';
        // const status = props.activeAssets ? 'active' : 'inactive'
        props.getAllAssestStock({ page, limit, sorted, sku_detail, name, type, status, location }, values, parseInt(props.match.params.assetId))
      }
    }
    // }
  }
  const handleConfirm = (confirm) => {
    setOpen(false);
    //props.deleteArea(currentArea.id, props.match.params.accountId)
  };

  const handleQrCodeSizeRender = (size) => {
    setIsQrCodeRender(true);
    if (size == 'medSmall') {
      setQrCodeSize(null);
      setQrCodeSize_MedSmall('small');
    } else {
      setQrCodeSize_MedSmall(null);
      setQrCodeSize(size);
    }
    setTimeout(() => {
      setIsQrCodeRender(false);
    }, 1000);
  };

  return (
    SearchOrResetAssetsClicked && props.isLoading ?
      <div className="loader_btn_block">
        <ClapSpinner backColor="#89073c" size={40} frontColor="#89073c8a" />
      </div>
      :
      <> <ReactTable
        data={data}
        loading={props.isLoading}
        defaultPageSize={No_of_rows ? No_of_rows : pageSize}
        minRows={0}
        manual
        onFetchData={fetchData}
        pages={No_of_pages}
        gotoPage={assestsCurPage}
        page={assestsCurPage}
        onPageChange={(page) => { setTablePageFn(page) }}
        onPageSizeChange={(e) => setPageSize(e)}
        showPaginationTop={true}
        showPaginationBottom={false}
        showPagination={props.assestsList.length !== 0}
        columns={columns}
        NoDataComponent={() => CustomNoDataComponent(props.isLoading, 'No Assests Found')}
        getTheadThProps={(state, rowInfo, column, instance) => {
          return {
            style: {
              borderTop: 'none',
              borderBottom: 'none',
              borderRight: 'none',
              textAlign: 'center',
              color: '#566975',
              fontSize: '14px',
              fontWeight: '600',
              whiteSpace: 'nowrap',
              float: 'left',
            }
          }
        }
        }
        LoadingComponent={() =>
          props.isLoading ?
            <div style={{
              display: "block",
              position: "absolute",
              left: 0,
              height: '100%',
              right: 0,
              background: "rgba(255,255,255,0.8)",
              transition: "all .3s ease",
              top: 0,
              bottom: 0,
              textAlign: "center"
            }}
              className="loader_btn_block">
              <ClapSpinner backColor="#89073c" size={40} frontColor="#89073c8a" />
            </div> : null}
        getTrProps={(state, rowInfo, column, instance) => {
          return {
            className: 'react-table-tr-element',
            style: {
              border: '1px solid #c7cdd1',
              transition: '.3s ease-in-out',
              width: '100%',
              marginTop: '5px',
              float: 'left'
            }
          }

        }}
        getTdProps={(state, rowInfo, column, instance) => {

          if (column.id == 'image') {
            return {
              style: {
                marginTop: '0px'
              }
            }
          } else {
            return {
              className: 'react-td-element',
            }
          }
        }
        }
        getProps={() => {
          return {
            style: {
              border: 'none'
            }
          }
        }}
      />
        <Dialog
          open={openQrModel}

          aria-labelledby="form-dialog-title"
        >
          <div className="row p-0 m-0">
            <div className="col-10">
              <h4 id="form-dialog-title" className="p-2" style={{ textAlign: "left", fontSize: '13', color: '#ab385e' }}>
                QR Code
              </h4></div>
            <div className="col-2">
              <span onClick={() => { handleQrCodeSizeRender('medium'); setQROpen(false); }} className="float-right text-right mt-2 cursor-pointer"><i className="fa fa-times"></i></span>
            </div>
          </div>
          <div className="qr-body-single" style={{
            display: "flex",
            justifyContent: "center"
          }}>
            {isQrCodeRender ? (
              <ClapSpinner backColor="#89073c" size={40} frontColor="#89073c8a" />) : (
              <img src={currentQrArea && currentQrArea.photo_urls && currentQrArea.photo_urls[qrCodeSize ? qrCodeSize : qrCodeSize_MedSmall]} style={{
                maxHeight: qrCodeSize == "original" ? "75vh" : "unset",
                width: qrCodeSize == "original" ? "41vw" : qrCodeSize_MedSmall ? "1.25in" : "unset"
              }} alt="dfdf" />
            )}
            {/* <img src={currentQrArea && currentQrArea.attachments ? currentQrArea.attachments[0].photo_urls.medium : null} alt="dfdf" /> */}
          </div>

          <div className="qrCode_sizeSetup_antd_select p-2">
            <Select
              suffixIcon={null}
              className={"btn cst_btn btn_danger mr-2"}
              style={{ minHeight: '51px', minWidth: '80px' }}
              placement={"bottomRight"}
              defaultValue='medium'
              options={qrCodeSize_option}
              onChange={(size) => { handleQrCodeSizeRender(size); }}
            />
            <button style={{ minHeight: '51px', minWidth: '80px' }} onClick={() => printQr()} className={"float-right btn mr-2 cst_btn btn_danger"} id="cancel" type="cancel">Get PDF</button>
            <button style={{ minHeight: '51px', minWidth: '80px' }} onClick={() => window.print()} disabled={props.isLoading} className={props.isLoading ? "float-right btn cst_btn btn_danger mr-2 btn-wait" : "float-right btn cst_btn btn_danger mr-2"} id="delete" type="delete">{props.isLoading ? "" : "Print QR"}</button>
          </div>
        </Dialog>

      </>
  )
}

export default AssetStockTable;