import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../../redux/actions/index';
import { closeModel, endOfWeek, getUser, startOfWeek } from '../../../../utility/utility';
import { routes, userRoles } from '../../../../utility/constants/constants';
import { reset } from 'redux-form';
import isEmpty from '../../../../utility/IsEmptyValidator';
import $ from "jquery";
import { validateAccountUsers } from '../../../../utility/validator/submit';
import { animateScroll as scroll } from 'react-scroll'
import AttendanceDashboardComponent from '../../../component/TimeTrackerModuleComponent/AttendanceDashboardComponent/AttendanceDashboardComponent';
import { criteria } from '../../../../utility/validator/validator';
import { convertDateFormatForSchedule } from '../../../../utility/utility';
import storage from '../../../../utility/storage';
import { customTime, tzEndOfWeek, tzStartOfWeek, tzUTCDateConverterTool_V2, tzUTCDateTimeConverterTool_V1 } from '../../../../utility/TimezoneOperations/timezoneUtility';
const cloneDeep = require('clone-deep');
var moment = require('moment');
var momentTZ = require('moment-timezone');

class AttendanceDashboardContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isTriggerLoader: false,
        }

    }

    componentDidMount() {

        this.setState({ isTriggerLoader: true });
        setTimeout(() => {
            this.setState({ isTriggerLoader: false });
        }, 2500);

        if (this?.props?.match?.params?.flUserId) {
            let isMonth = true;
            let fetchedCurrDateTime = momentTZ(new Date()).format('MM-DD-YYYYTHH:mm:ss').split('T');
            let currentDate = tzUTCDateTimeConverterTool_V1(fetchedCurrDateTime[0], fetchedCurrDateTime[1], 'none');
            let userId = this.props.match.params.flUserId;

            this.props.getShiftAttendanceCalendarList({ isMonth, currentDate, userId });
            if (this.props.match.params && this.props.match.params.flUserId) {
                this.props.getFrontline(this.props.match.params.flUserId);
            }
        }

    }

    componentDidUpdate() {
        if(this.props.frontlineUser){
            this.props.frontlineUserActiveNameChange(`${this.props.frontlineUser?.first_name} ${this.props.frontlineUser?.last_name} -`)
        }
    }

    render() {
        return (
            <>

                <AttendanceDashboardComponent
                    {...this.props}
                    isTriggerLoader={this.state.isTriggerLoader}
                />

            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.authReducer.user,
        isLoading: state.TimeTrackerModReducer.isLoading,
        singleShiftScheduleData: state.TimeTrackerModReducer.singleShiftScheduleData,
        presentsArray: state.TimeTrackerModReducer.shiftAttendance_presents,
        absentsArray: state.TimeTrackerModReducer.shiftAttendance_absents,
        complaintsArray: state.TimeTrackerModReducer.shiftAttendance_complaints,
        isTimeTrackerModalOpen: state.TimeTrackerModReducer.isTimeTrackerModalOpen,
        attendancePanelType: state.TimeTrackerModReducer.attendancePanelType,
        attendancePanelObj: state.TimeTrackerModReducer.attendancePanelObj,
        frontlineUser: state.adminOrSuperAdminReducer.frontlineUser,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        handleShiftPresencePanel_openModal: (data) => dispatch(actions.handleShiftPresencePanel_openModal(data)),
        handleShiftPresencePanel_closeModal: (data) => dispatch(actions.handleShiftPresencePanel_closeModal(data)),

        getShiftAttendanceCalendarList: (params) => dispatch(actions.getShiftAttendanceCalendarList(params)),
        getSingleShifSchedule: (id) => dispatch(actions.getSingleShifSchedule(id)),
        getFrontline: (id) => dispatch(actions.getFrontline(id)),
        frontlineUserActiveNameChange:(name)=> dispatch(actions.updateActiveUserName(name))
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(AttendanceDashboardContainer);