import React, { useEffect } from 'react';
import { Input } from '../../../UI/InputElement/InputElement';
import { reduxForm } from 'redux-form';
import { leaveRequestRejectionValidator as validate } from '../../../../../utility/validator/validator';
import { connect } from 'react-redux';
import isEmpty from '../../../../../utility/IsEmptyValidator';

let ShiftCancelRequestForm = (props) => {

    var radios = document.querySelectorAll('input[type="radio"]');
    for (var i = 0; i < radios.length; i++) {
        radios[i].onfocus = function () {
            this.blur();
        }
    }

    const { handleSubmit, pristine, reset, submitting, error } = props;

    return (
        <div className="modal fade request_quote_modal reject_quot associateUser" id="shiftCancelRequest" tabindex="-1" role="dialog" aria-labelledby="rejectQuoteModalTitle" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-header"></div>
                    <div className="modal-body">
                        <div className="tab-content">
                            <div id="profile" className="profile_tab cst_tab_content tab-pane fade show active edit_profile_modal">
                                <form className="form_content">
                                    <div className="row" style={{ marginBottom: '-15px' }}>
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label className="col-md-12 form_title text-center" style={{ fontSize: '25px' }}>Do you want to Cancel the shift ?</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button onClick={props.handleFrequentTimeTracker_closeModal} id='closeLeaveRequestRejectionModal' type="button" className="btn btn-outline-secondary cst_btn" data-dismiss="modal">No</button>
                                        <button onClick={() => { props.handleCancelRequestSanction({ shiftId: props.shiftId }) }} data-test="submit_button" type="button" disabled={props.isLoading} className={props.isLoading ? "btn cst_btn btn_danger mr-2 btn-wait" : "btn cst_btn btn_danger mr-2"} > {props.isLoading ? "" : "Yes"} </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
};

export default ShiftCancelRequestForm;