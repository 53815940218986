import React, { useState } from 'react';
import { MagicSpinner, PushSpinner, SwishSpinner, WaveSpinner, ClapSpinner } from 'react-spinners-kit';
import moment from "moment";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import ReactTable from 'react-table-v6';

import {
    Dialog,
    DialogContent,
    DialogActions,
    DialogTitle
} from '@material-ui/core';
import { makeFirstLetterCapital, CustomNoDataComponent } from '../../../../../utility/utility';


import { useSelector, useDispatch } from 'react-redux';
import { FaArrowDown, FaArrowUp } from "react-icons/fa";
import * as actions from '../../../../../redux/actions/index';
import { userRoles } from '../../../../../utility/constants/constants';
import ImageLoader from 'react-imageloader';
import { DAYS } from "../../../../../utility/constants/constants";


import 'react-table-v6/react-table.css';
import '../../../../../styles/custom.css';

const AccountHolidaysTable = (props) => {
    const [openModel, setOpen] = useState(false);
    const [openQrModel, setQROpen] = useState(false);
    const [openScheduleModel, setScheduleOpen] = useState(false);
    const [currentQrArea, setCurrentQrArea] = useState(false);
    const [currentArea, setCurrentArea] = useState(null);
    const dispatch = useDispatch();
    //const SearchValue = useSelector(state => state.form.searchAccountAreasForm.values);
    const DateGenerate = useSelector(state => state.adminOrSuperAdminReducer.dataGenerated);
    const SearchOrResetAccountUsersClicked = useSelector(state => state.adminOrSuperAdminReducer.SearchOrResetAccountUsersClicked);
    const No_of_pages = useSelector(state => state.adminOrSuperAdminReducer.total_no_of_pages);
    const No_of_rows = useSelector(state => state.adminOrSuperAdminReducer.account_users_row);

    const printQr = () => {
        const input = document.getElementsByClassName("qr-body-single")[0];
        html2canvas(input, { useCORS: true, allowTaint: true })
            .then((canvas) => {
                const imgData = canvas.toDataURL('image/png');
                const pdf = new jsPDF();
                pdf.addImage(imgData, 'PNG', 10, 10);
                //pdf.output('dataurlnewwindow');
                pdf.save("download.pdf");
            })
    };

    function preloader() {
        return <img style={{ height: '35px', width: '35px', borderRadius: '50px', float: 'left' }} src="/images/gif/giphy.gif" />;
    }

    const data = props.accountHolidaysList.map((h, i) => {
        const date = moment(h.holiday_date).format('MM-DD-YYYY');
        return {

            serialNo: i + 1,
            reason: h.holiday_reason,
            date: date,
            //     actions:
            //         <>
            //             {/* <span className="mr_20 account_edit_icn" onClick={() => props.editUserClicked(user)}><i class="fa fa-pencil" aria-hidden="true"></i>
            //    </span> */}
            //             <button className="btn mr_20 account_edit_icn" onClick={() => [setQROpen(true), setCurrentQrArea(area)]}>Show QR
            //             </button>
            //             <button className="btn mr_20 account_edit_icn" onClick={() => [setScheduleOpen(true), setCurrentArea(area)]}>View Schedule
            //   </button>
            //             <span className="mr_20 account_edit_icn" onClick={() => props.history.push(`/accounts/${props.account.user.id}/account_users/areas/${area.id}/edit`)}><i class="fa fa-pencil" aria-hidden="true"></i></span>
            //             {props.user && props.user.role === "superadmin" ? <span className="mr account_edit_icn" onClick={() => [setOpen(true), setCurrentArea(area)]}><i class="fa fa-trash" aria-hidden="true"></i>
            //             </span> : ""}
            //         </>
        }
    })

    const columns = [

        {
            Header: <><span>S.No</span><FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
            accessor: 'serialNo', // String-based value accessors!,
            show: props.accountHolidaysList.length !== 0,
        },
        {
            Header: <><span>Date</span><FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
            accessor: 'date', // String-based value accessors!,
            show: props.accountHolidaysList.length !== 0,
        },
        {
            Header: <><span>Holiday Name</span><FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
            accessor: 'reason', // String-based value accessors!,
            show: props.accountHolidaysList.length !== 0,
        },

        // {
        //     Header: ' ',
        //     accessor: 'actions',
        //     width: 350
        //     // show: false,
        // }
    ]

    const fetchData = (state, instance) => {

        if (SearchOrResetAccountUsersClicked) {
            dispatch(actions.resetSearchOrResetValue())
        } else {

            if (!DateGenerate) {
                const size = state.pageSize;
                const page = state.page + 1;
                const sorted = JSON.stringify(state.sorted);
                const filtered = state.filtered;
                //const email = SearchValue && SearchValue.email ? SearchValue.email.replace('+', "%2B") : ""
                props.getAccountHolidays({}, props.match.params.accountId);
            }
        }
        // }
    }
    const handleConfirm = (confirm) => {
        setOpen(false);
        props.deleteArea(currentArea.id, props.match.params.accountId)
    };
    
    return (
        SearchOrResetAccountUsersClicked && props.accountHolidaysLoading ?
            <div className="loader_btn_block">
                <ClapSpinner backColor="#89073c" size={40} frontColor="#89073c8a" />
            </div>
            :
            <> <ReactTable
                data={data}
                loading={props.accountHolidaysLoading}
                defaultPageSize={10}
                minRows={0}
                // manual
                onFetchData={fetchData}
                // pages={No_of_pages}
                onPageSizeChange={props.onUserPageSizeChange}
                showPaginationTop={true}
                showPagination={props.accountHolidaysList.length !== 0}
                columns={columns}
                NoDataComponent={() => CustomNoDataComponent(props.accountHolidaysLoading, 'No Holidays Found')}
                getTheadThProps={(state, rowInfo, column, instance) => {
                    return {
                        style: {
                            borderTop: 'none',
                            borderBottom: 'none',
                            borderRight: 'none',
                            textAlign: 'center',
                            color: '#566975',
                            fontSize: '14px',
                            fontWeight: '600',
                            whiteSpace: 'nowrap',
                            float: 'left',
                        }
                    }
                }
                }
                LoadingComponent={() =>
                    props.accountHolidaysLoading ?
                        <div style={{
                            display: "block",
                            position: "absolute",
                            left: 0,
                            height: '100%',
                            right: 0,
                            background: "rgba(255,255,255,0.8)",
                            transition: "all .3s ease",
                            top: 0,
                            bottom: 0,
                            textAlign: "center"
                        }}
                            className="loader_btn_block">
                            <ClapSpinner backColor="#89073c" size={40} frontColor="#89073c8a" />
                        </div> : null}
                getTrProps={(state, rowInfo, column, instance) => {
                    // onclick = () => userDetail(rowInfo ? rowInfo.row.user : rowInfo);
                    console.log(column);
                    return {
                        className: 'react-table-tr-element',
                        style: {
                            border: '1px solid #c7cdd1',
                            transition: '.3s ease-in-out',
                            width: '100%',
                            // borderRadius: '4px',
                            // height: '80px',
                            marginTop: '5px',
                            float: 'left'
                        }
                    }

                }}
                getTdProps={(state, rowInfo, column, instance) => {

                    if (column.id == 'image') {
                        return {
                            style: {
                                marginTop: '0px'
                            }
                        }
                    } else {
                        return {
                            className: 'react-td-element',
                        }
                    }
                }
                }
                getProps={() => {
                    return {
                        style: {
                            border: 'none'
                        }
                    }
                }}
            />
                <Dialog
                    open={openModel}

                    aria-labelledby="form-dialog-title"
                >
                    <DialogTitle id="form-dialog-title" style={{ fontSize: '13', color: '#06080f' }}>
                        <h3 style={{ color: '#ab385e' }}>Are you sure?</h3>
                    </DialogTitle>

                    <DialogActions>
                        <button style={{ minHeight: '36px', minWidth: '80px' }} onClick={() => handleConfirm(true)} disabled={props.isLoading} className={props.isLoading ? "btn cst_btn btn_danger mr-2 btn-wait" : "btn cst_btn btn_danger mr-2"} id="delete" type="delete">{props.isLoading ? "" : "Delete"}</button>
                        <button style={{ minHeight: '36px', minWidth: '80px' }} onClick={() => setOpen(false)} className={"btn cst_btn btn-outline-secondary"} id="cancel" type="cancel">Cancel</button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={openQrModel}

                    aria-labelledby="form-dialog-title"
                >
                    <div className="row p-0 m-0">
                        <div className="col-10">
                            <h4 id="form-dialog-title" className="p-2" style={{ textAlign: "left", fontSize: '13', color: '#ab385e' }}>
                                QR Code
               </h4></div>
                        <div className="col-2">
                            <span onClick={() => setQROpen(false)} className="float-right text-right mt-2 cursor-pointer"><i className="fa fa-times"></i></span>
                        </div>
                    </div>
                    <div className="qr-body-single"><img src={currentQrArea && currentQrArea.qr_code_urls.medium} alt="dfdf" /></div>

                    <div className=" p-2">
                        <button style={{ minHeight: '36px', minWidth: '80px' }} onClick={() => printQr()} className={"float-right btn mr-2 cst_btn btn_danger"} id="cancel" type="cancel">Get PDF</button>
                        <button style={{ minHeight: '36px', minWidth: '80px' }} onClick={() => window.print()} disabled={props.isLoading} className={props.isLoading ? "float-right btn cst_btn btn_danger mr-2 btn-wait" : "float-right btn cst_btn btn_danger mr-2"} id="delete" type="delete">{props.isLoading ? "" : "Print QR"}</button>
                    </div>
                </Dialog>
                <Dialog
                    open={openScheduleModel}
                    aria-labelledby="form-dialog-title"
                >
                    <div className="row p-2 m-0">
                        <div className="col-10">
                            <h4 style={{ color: '#ab385e' }}>Schedule Time</h4>
                        </div>
                        <div className="col-2">
                            <span onClick={() => setScheduleOpen(false)} className="float-right text-right mt-0 cursor-pointer"><i className="fa fa-times"></i></span>
                        </div>
                    </div>
                    <DialogContent>
                        <div className="p-3">
                            <div>
                                {
                                    currentArea && currentArea.days.map((day, index) => <label className="text_danger m-1">{DAYS[day]}</label>)
                                }
                            </div>
                            <div className="mt-1">
                                {
                                    currentArea && currentArea.timeslots.map((time, index) =>
                                        <label key={index} className="m-1 timeslot-btn">{moment(moment().format("MM-DD-YYYY") + " " + time.start_time, "MM-DD-YYYY HH:mm:ss").format("hh:mm A")}</label>
                                    )
                                }
                            </div>
                        </div>
                    </DialogContent>
                    <div className=" p-2">
                        <button style={{ minHeight: '36px', minWidth: '80px', visibility: "hidden" }} onClick={() => setScheduleOpen(false)} className={"float-right btn mr-2 cst_btn btn_danger"} id="cancel" type="cancel">Cancel</button>
                        <button style={{ minHeight: '36px', minWidth: '80px', visibility: "hidden" }} onClick={() => setScheduleOpen(false)} disabled={props.isLoading} className={props.isLoading ? "float-right btn cst_btn btn_danger mr-2 btn-wait" : "float-right btn cst_btn btn_danger mr-2"} id="delete" type="delete">Okay</button>
                    </div>
                </Dialog>
            </>
    )
}

export default AccountHolidaysTable;