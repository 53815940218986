import React, { useEffect, useState } from 'react';
import ReactTable from 'react-table-v6';
import '../../../../styles/custom.css';
import { makeFirstLetterCapital, CustomNoDataComponent, getUser, convertFeedbackTime, convertToPst } from '../../../../utility/utility';
import { MagicSpinner, PushSpinner, SwishSpinner, WaveSpinner, ClapSpinner } from 'react-spinners-kit';
import 'react-table-v6/react-table.css';
import * as actions from '../../../../redux/actions/index';
import { useSelector, useDispatch } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { FaArrowDown, FaArrowUp } from "react-icons/fa";
import { userRoles } from '../../../../utility/constants/constants';
import ImageLoader from 'react-imageloader';
import { orderBy } from 'firebase/firestore';
import { tzDateConverterTool_V4 } from '../../../../utility/TimezoneOperations/timezoneUtility';

const PegassurePlaybookListTable = (props) => {

    const dispatch = useDispatch();
    const SearchValue = useSelector(state => state?.form?.searchPlaybookPdfList?.values ? state.form.searchPlaybookPdfList.values : state?.PegassurePlaybookReducer?.pegassurePlaybookList_searchValues ? state.PegassurePlaybookReducer.pegassurePlaybookList_searchValues : {});
    const DateGenerate = useSelector(state => state.PegassurePlaybookReducer.dataGenerated);
    const values = useSelector(state => state.TimeTrackerModReducer.pegassurePlaybookList_searchValues);
    const No_of_pages = useSelector(state => state.PegassurePlaybookReducer.pegassurePlaybookList_total_pages);
    const No_of_rows = useSelector(state => state.PegassurePlaybookReducer.pegassurePlaybookList_no_of_rows);
    const isPegassurePlaybookList = useSelector(state => state.PegassurePlaybookReducer.isPegassurePlaybookList);
    const myPlaybookListCurPage = useSelector(state => state.PegassurePlaybookReducer.pegassurePlaybookList_curPage);
    const SearchOrResetPegassurePlaybookListClicked = useSelector(state => state.PegassurePlaybookReducer.SearchOrResetPegassurePlaybookListClicked);


    const data = props?.pegassurePlaybookList?.map((playbook) => {debugger

        return {
            id: playbook.id ? playbook.id : '−',
            title: playbook.title ? playbook.title : '−',
            date: playbook?.created_at ? tzDateConverterTool_V4(playbook?.created_at) : '−',
            actions:
                <>
                    <ReactTooltip place="left" type="dark" effect="solid" />
                    <span data-tip="Preview Playbook Pdf" className="mr_20 account_edit_icn" onClick={() => props.history.push({ pathname: `/logbook-pdf/${playbook?.id}`, state: { pdf_info: playbook, back: true } })} data-test="edit_eye_icon"><i className="fas fa-eye cst_icon mr-2" aria-hidden="true"></i></span>
                    <span data-tip="Preview Attached Logbook Area's" className="mr_20 account_edit_icn" onClick={() => props.history.push({ pathname: `/playbook-logarea-grid/${playbook.id}` })} data-test="edit_eye_icon"><i className="fas fa-folder-open cst_icon mr-2" aria-hidden="true"></i></span>
                    <span className="mr_20 account_edit_icn" onClick={() => props.handleUpdatePegassurePlaybookPdfClicked(playbook.id)} data-toggle="modal" data-target="#pegassurePlaybookPdfForm" data-backdrop="static" data-keyboard="false"><i class="fa fa-pencil" aria-hidden="true"></i></span>
                    <span className="mr_20 account_edit_icn" onClick={() => props.handlePegassurePlaybook_openDeleteModal(playbook.id)}><i class="fa fa-trash" aria-hidden="true"></i></span>
                </>
        }
    })


    const columns = [
        {
            Header: <><span> ID </span><FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
            accessor: 'id', // String-based value accessors!,
            width: 400,
            show: props?.pegassurePlaybookList?.length !== 0,
        },
        {
            Header: <><span> Name </span><FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
            accessor: 'title',
            width: 400,

            show: props?.pegassurePlaybookList?.length !== 0,
        },
        {
            Header: <><span> Created Date </span><FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
            accessor: 'date',
            width: 400,

            show: props?.pegassurePlaybookList?.length !== 0,
        },
        {
            Header: '',
            accessor: 'actions',
            width: 450
        }
    ]

    const [tablePage, setTablePage] = useState(0)
    const tablePageNew = React.useRef()

    if (myPlaybookListCurPage !== tablePageNew.current) {
        tablePageNew.current = myPlaybookListCurPage;
    }
    const setTablePageFn = (page) => {
        setTablePage(page)
        props.pegassurePlaybookListPageClicked(page)
        tablePageNew.current = page;
    }

    const fetchData = (state, instance) => {
        if (SearchOrResetPegassurePlaybookListClicked) {
            dispatch(actions.resetSearchOrResetPegassurePlaybook());
        } else {
            if (!DateGenerate && !props.isPegassurePlaybookList) {
                state.page = 2;
                let page = tablePageNew.current >= 0 ? tablePageNew.current + 1 : myPlaybookListCurPage + 1;
                let limit = state.pageSize;
                if (No_of_rows != limit) {
                    // page = 1;
                    setTablePageFn(0)
                }

                /* Sorting */
                const order = state?.sorted?.length && state?.sorted[0]?.id ? state.sorted[0]?.id : null;
                const orderBy = state?.sorted?.length ? state.sorted[0]?.desc === true ? 'desc' : 'asc' : null;

                let sendGridData = {
                    page: page,
                    limit: limit,
                    order: order,
                    orderBy: orderBy
                }

                props.getPegassurePlaybookPdfList(sendGridData, values);
            }
        }
    }

    return (
        <>
            {SearchOrResetPegassurePlaybookListClicked && props.isPegassurePlaybookList ? (
                <div className="loader_btn_block">
                    <ClapSpinner backColor="#89073c" size={40} frontColor="#89073c8a" />
                </div>
            ) : (
                <div>
                    <ReactTable
                        data={data}
                        loading={props.isPegassurePlaybookList}
                        minRows={0}
                        defaultPageSize={No_of_rows}
                        pages={No_of_pages}
                        gotoPage={myPlaybookListCurPage}
                        page={myPlaybookListCurPage}

                        onPageChange={(page) => { setTablePageFn(page) }}
                        // onPageSizeChange={props.onAccountsPageSizeChange}
                        manual
                        onFetchData={fetchData}
                        showPaginationTop={true}
                        // showPaginationBottom={false}

                        showPagination={props?.pegassurePlaybookList?.length !== 0}
                        columns={columns}
                        NoDataComponent={() => CustomNoDataComponent(props.isPegassurePlaybookList, 'No Pegassure Playbook List Found')}
                        LoadingComponent={() =>
                            isPegassurePlaybookList ?
                                <div style={{
                                    display: "block",
                                    position: "absolute",
                                    left: 0,
                                    height: '100%',
                                    right: 0,
                                    background: "rgba(255,255,255,0.8)",
                                    transition: "all .3s ease",
                                    top: 0,
                                    bottom: 0,
                                    textAlign: "center"
                                }}
                                    className="loader_btn_block">
                                    <ClapSpinner backColor="#89073c" size={40} frontColor="#89073c8a" />
                                </div> : null}
                        getTheadThProps={(state, rowInfo, column, instance) => {
                            return {
                                style: {
                                    borderTop: 'none',
                                    borderBottom: 'none',
                                    borderRight: 'none',
                                    textAlign: 'center',
                                    color: '#566975',
                                    fontSize: '14px',
                                    fontWeight: '600',
                                    whiteSpace: 'nowrap',
                                    float: 'left',
                                }
                            }
                        }
                        }
                        getTrProps={(state, rowInfo, column, instance) => {
                            console.log(column);
                            return {
                                className: 'react-table-tr-element',
                                style: {
                                    border: '1px solid #c7cdd1',
                                    transition: '.3s ease-in-out',
                                    width: '100%',
                                    marginTop: '5px',
                                    float: 'left'
                                }
                            }

                        }}
                        getTdProps={(state, rowInfo, column, instance) => {

                            if (column.id == 'image') {
                                return {
                                    style: {
                                        marginTop: '0px'
                                    }
                                }
                            } else {
                                return {
                                    className: 'react-td-element',
                                }
                            }
                        }
                        }
                        getProps={() => {
                            return {
                                style: {
                                    border: 'none'
                                }
                            }
                        }}
                    />
                </div>
            )}
        </>
    )
}

export default PegassurePlaybookListTable;
