import React, { useEffect, useState } from 'react';
import { formValueSelector, getFormValues, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Input } from '../../../UI/InputElement/InputElement';
import { convertDateToPickerFormat, transformStringToSelectOptionObject } from '../../../../../utility/utility';
import { shiftStatusTypeOptions, userRoles } from '../../../../../utility/constants/constants';
import SingleAvailabilityListTable from './singleAvailabilityListTable';

let SingleAvailabilityList = (props) => {

    const { handleSubmit, pristine, reset, submitting, error } = props;

    return (
        <>
            <main className="app-content wraper_content inspection_wraper">
                <div className="tab_header search_filter_cont inspection_tab_content ticket_filter">
                    <form onSubmit={handleSubmit}>
                        <div className="row mt-2">

                            <div className="col-xl-2 col-lg-4 col-md-6 col-sm-6">
                                <Input name="shiftStatus" dataTest="select_leave_type" type="inputSelect" options={shiftStatusTypeOptions} placeholder="Search by Status" />
                            </div>

                            <div className="col-xl-2 col-lg-4 col-md-6 col-sm-6">
                                <div className="input-group cst_input_group col-lg-14 pl-0">
                                    <label>
                                        <div style={{ width: '100%' }}>
                                            <Input
                                                type="date"
                                                datePickerType={"react-datepicker"}
                                                dateFormat="MM-dd-yyyy"
                                                className="form-control input-modifier"
                                                placeholder="From Date"
                                                name="fromDate"
                                            />
                                        </div>
                                        <div className="input-group-append cursor-pointer" style={{ float: "right", position: "absolute", right: 0, height: "37px", top: 0 }}>
                                            <span class="input-group-text" id="basic-addon2"> <i class="fa fa-calendar"></i> </span>
                                        </div>
                                    </label>
                                </div>
                            </div>

                            <div className="col-xl-2 col-lg-4 col-md-6 col-sm-6">
                                <div className="input-group cst_input_group col-lg-14 pl-0">
                                    <label>
                                        <div style={{ width: '100%' }}>
                                            <Input
                                                type="date"
                                                datePickerType={"react-datepicker"}
                                                dateFormat="MM-dd-yyyy"
                                                className="form-control input-modifier"
                                                placeholder="To Date"
                                                name="toDate"
                                            />
                                        </div>
                                        <div className="input-group-append cursor-pointer" style={{ float: "right", position: "absolute", right: 0, height: "37px", top: 0 }}>
                                            <span class="input-group-text" id="basic-addon2"> <i class="fa fa-calendar"></i> </span>
                                        </div>
                                    </label>
                                </div>
                            </div>

                            <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
                                <div className="frontline_btn">
                                    <button data-test="filter_go_btn" style={{ minWidth: '90px' }} className={"btn cst_btn btn_danger mr-2  account_btn"} id="submit" type="submit">{"Go"} </button>
                                    <button data-test="filter_reset_btn" style={{ minWidth: '90px' }} onClick={props.resetClicked} className={"btn cst_btn btn-outline-secondary account_btn"} id="reset" type="reset">{"Reset"}</button>
                                </div>
                            </div>

                        </div>
                    </form>
                </div>

                <section className="account_sec inspection_tab_content appcontent_Inner">
                    <div className="quotes_table_content accounts_table_contnet table-responsive">
                        <div className="table quotes_table user_react_table">

                            <SingleAvailabilityListTable
                                {...props}
                            />
                        </div>
                    </div>
                </section>
            </main>

            <Dialog
                open={props.openDeleteModel}
                onClose={props.handleClose}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title" style={{ fontSize: '13', color: '#06080f' }}>
                    <h3 style={{ color: '#ab385e' }} data-test="modal_delete_title">Are you sure?</h3>
                </DialogTitle>

                <DialogActions>
                    <button data-test="modal_delete" style={{ minHeight: '36px', minWidth: '80px' }} onClick={() => props.deleteTicket(props.deleteObject.id)} disabled={props.isLoading} className={props.isLoading ? "btn cst_btn btn_danger mr-2 btn-wait" : "btn cst_btn btn_danger mr-2"} id="delete" type="delete">{props.isLoading ? "" : "Delete"}</button>
                    <button data-test="modal_cancel" style={{ minHeight: '36px', minWidth: '80px' }} onClick={props.handleDeleteClose} className={"btn cst_btn btn-outline-secondary"} id="cancel" type="cancel">Cancel</button>
                </DialogActions>
            </Dialog>
        </>
    )
}

const mapStateToProps = (state) => {

    let capturedStatus = 'created';

    if (state.TimeTrackerModReducer && state.TimeTrackerModReducer.shiftScheduleDetailList && state.TimeTrackerModReducer.shiftScheduleDetailList.length) {
        capturedStatus = state.TimeTrackerModReducer.shiftScheduleDetailList[0].status;
    }

    let shiftStatus = state.TimeTrackerModReducer.shiftScheduleDetail_searchValues && state.TimeTrackerModReducer.shiftScheduleDetail_searchValues.shiftStatus ? transformStringToSelectOptionObject(state.TimeTrackerModReducer.shiftScheduleDetail_searchValues.shiftStatus) : transformStringToSelectOptionObject(capturedStatus);
    let fromDate = state.TimeTrackerModReducer.shiftScheduleDetail_searchValues && state.TimeTrackerModReducer.shiftScheduleDetail_searchValues.fromDate ? convertDateToPickerFormat(state.TimeTrackerModReducer.shiftScheduleDetail_searchValues.fromDate) : "";
    let toDate = state.TimeTrackerModReducer.shiftScheduleDetail_searchValues && state.TimeTrackerModReducer.shiftScheduleDetail_searchValues.toDate ? convertDateToPickerFormat(state.TimeTrackerModReducer.shiftScheduleDetail_searchValues.toDate) : "";

    return { initialValues: { shiftStatus, fromDate, toDate }, formStates: getFormValues('searchShiftScheduleDetail')(state) }

}

SingleAvailabilityList = reduxForm({
    form: 'searchShiftScheduleDetail',
    // validate,
    enableReinitialize: true
})(SingleAvailabilityList);

const selector = formValueSelector('searchShiftScheduleDetail');

SingleAvailabilityList = connect(state => {
    const { fromDate, toDate } = selector(state, 'fromDate', 'toDate')
    const selectedValues = { fromDate, toDate }
    return {
        selectedValues
    }
})(SingleAvailabilityList);

SingleAvailabilityList = connect(mapStateToProps)(SingleAvailabilityList)

export default SingleAvailabilityList;