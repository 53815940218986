import React, { Component } from 'react';
import * as actions from '../../../redux/actions/index';
import { connect } from 'react-redux';
import { reset } from 'redux-form';
import { getUser } from '../../../utility/utility';
import { animateScroll as scroll } from 'react-scroll'
import FeedbackComponent from '../../component/PegAssureComponent/FeedbackComponent';
const cloneDeep = require('clone-deep');
class FeedbackContainer extends Component {
    componentDidMount() {
        // if (!this.props.criteries) {
       
        scroll.scrollToTop();
    }


    render() {

        return (
            <FeedbackComponent
                {...this.props}
                openDeficiencyClicked={this.props.openDeficiencyClicked}
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        openDeficiencyModal: state.adminOrSuperAdminReducer.openDeficiencyModal,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        openDeficiencyClicked: () => dispatch(actions.openDeficiencyClicked()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FeedbackContainer);