import React from 'react';
import { connect } from 'react-redux';
import PreviewUser from '../../../component/user/PreviewUser/previewUser';
import isEmpty from '../../../../utility/IsEmptyValidator';
import * as actions from '../../../../redux/actions/index';

class PreviewUserContainer extends React.Component {

    componentDidMount = () => {
        if (isEmpty(this.props.user)) {
            this.props.getEditUser(this.props.match.params.userId);
        }
    }

    componentWillUnmount = () => {
        this.props.resetObjects();
    }

    render() {

        return (
            <PreviewUser
                user={this.props.user}
                isLoading={this.props.isLoading}
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.adminOrSuperAdminReducer.requestor,
        isLoading: state.adminOrSuperAdminReducer.isLoading,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getEditUser: (id) => dispatch(actions.getEditUser(id)),
        resetObjects: () => dispatch(actions.resetObjects())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PreviewUserContainer);