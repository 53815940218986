import React from 'react';

let TimesheetCardsScreen = (props) => {

    const { timecard } = props;

    /* Timecard example template */
    // var timecard = [
    //     {
    //         "user_id": "1",
    //         "workedtimesum": "34",
    //         "overtimesum": "34",
    //         "penaltytimesum": "24",
    //         "breaktimesum": "34",
    //         "unapprovedtimesum": "14"
    //     }
    // ]

    function convertMinutesToHoursAndMinutes(minutes) {
        const hours = Math.floor(minutes / 60);
        const remainingMinutes = minutes % 60;
        return {
            hours: hours,
            minutes: remainingMinutes
        };
    }

    return (
        <>

            <div className='row mt-5'>
                <div className="col-6 col-sm-6 col-lg-6 col-xl-3" >
                    <div className="tile performance_chart" style={{ cursor: 'pointer' }}>
                        <h4 className="chart_title1 text-color-stormDust" style={{ fontWeight: '700' }}> Total Working Hours </h4>
                        <div className="flex_box performance_box">
                            <div className="tile_block">
                                <h2 className='text-color-davyGrey' style={{ fontWeight: '700' }}> {timecard[0]?.workedtimesum ? `${convertMinutesToHoursAndMinutes(timecard[0]?.workedtimesum).hours} Hours ${convertMinutesToHoursAndMinutes(timecard[0]?.workedtimesum).minutes} Minutes` : '−−−−−'} </h2>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-6 col-sm-6 col-lg-6 col-xl-3" >
                    <div className="tile performance_chart" style={{ cursor: 'pointer' }}>
                        <h4 className="chart_title1 text-color-stormDust" style={{ fontWeight: '700' }}> Total Meal Break Hours </h4>
                        <div className="flex_box performance_box">
                            <div className="tile_block">
                                <h2 className='text-color-davyGrey' style={{ fontWeight: '700' }}> {timecard[0]?.breaktimesum ? `${convertMinutesToHoursAndMinutes(timecard[0]?.breaktimesum).hours} Hours ${convertMinutesToHoursAndMinutes(timecard[0]?.breaktimesum).minutes} Minutes` : '−−−−−'} </h2>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-6 col-sm-6 col-lg-6 col-xl-3" >
                    <div className="tile performance_chart" style={{ cursor: 'pointer' }}>
                        <h4 className="chart_title1 text-color-stormDust" style={{ fontWeight: '700' }}> Total Overtime Hours </h4>
                        <div className="flex_box performance_box">
                            <div className="tile_block">
                                <h2 className='text-color-davyGrey' style={{ fontWeight: '700' }}> {timecard[0]?.overtimesum ? `${convertMinutesToHoursAndMinutes(timecard[0]?.overtimesum).hours} Hours ${convertMinutesToHoursAndMinutes(timecard[0]?.overtimesum).minutes} Minutes` : '−−−−−'} </h2>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-6 col-sm-6 col-lg-6 col-xl-3" >
                    <div className="tile performance_chart" style={{ cursor: 'pointer' }}>
                        <h4 className="chart_title1 text-color-stormDust" style={{ fontWeight: '700' }}> Total Penalty Hours </h4>
                        <div className="flex_box performance_box">
                            <div className="tile_block">
                                <h2 className='text-color-davyGrey' style={{ fontWeight: '700' }}> {timecard[0]?.penaltytimesum ? `${convertMinutesToHoursAndMinutes(timecard[0]?.penaltytimesum).hours} Hours ${convertMinutesToHoursAndMinutes(timecard[0]?.penaltytimesum).minutes} Minutes` : '−−−−−'} </h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
};

export default TimesheetCardsScreen;