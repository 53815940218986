import storage from "../../../../utility/storage";
import SuccessAlert from "../../../../utility/successAlert/successAlert";
import { formAuditTagModuleActionTypes } from "../../../actions/FormAuditModAction/TagModuleAction/TagModuleActionType";

export const initialState = {
    isUpdateRequestForm: false,
    requsetFormDetails: [],
    isLoading: false,
    requestFormList: [],
    requestForm_total_pages: 1,
    requestForm_no_of_rows: 10,
    requestFormCurPage: 0,
    total_requestForm_count: "",
};

const updateObject = (oldState, updatedProps) => {
    return {
        ...oldState,
        ...updatedProps,
    };
};

export const TagModuleReducer = (state = initialState, action) => {
    switch (action.type) {
        case formAuditTagModuleActionTypes.GET_SINGLE_REQUESTFORM_DETAILS_PENDING:
            return updateObject(state, {
                isLoading: false,
                isEditLog: false,
                isUpdateRequestForm: true,
                requsetFormDetails: null,
            });

        case formAuditTagModuleActionTypes.GET_SINGLE_REQUESTFORM_DETAILS_FULFILLED:

            return updateObject(state, {
                isLoading: false,
                isEditLog: false,
                isUpdateRequestForm: false,
                requsetFormDetails: action.payload,
            });

        case formAuditTagModuleActionTypes.GET_ALL_REQUESTFORM_DETAILS_PENDING:
            return updateObject(state, {
                isLoading: true,
                isRequestForm: true,
                requestFormList: [],
            });

        case formAuditTagModuleActionTypes.GET_ALL_REQUESTFORM_DETAILS_FULFILLED:

            return updateObject(state, {
                isLoading: false,
                dataGenerated: false,
                isRequestForm: false,
                requestFormList: action.payload.requestForm_list,
                requestForm_total_pages: action.payload.requestForm_total_pages,
                requestForm_no_of_rows: action.payload.requestForm_no_of_rows,
                requestFormCurPage: action.payload.requestFormCurPage,
                total_requestForm_count: action.payload.total_requestForm_count,
            });


        case formAuditTagModuleActionTypes.ADD_SINGLE_REQUESTFORM_DETAILS_PENDING:
            return updateObject(state, {
                isLoading: true,
                isEditLog: false,
                isUpdateRestroom: false,
                restroomDetails: null,
            });

        case formAuditTagModuleActionTypes.ADD_SINGLE_REQUESTFORM_DETAILS_FULFILLED:

            return updateObject(state, {
                isLoading: false,
                isEditLog: false,
                isUpdateRestroom: false,
                restroomDetails: action.payload,
            });


        //     case formAuditLogBookActionTypes.ADD_SINGLE_RESTROOM_DETAILS_REJECTED:

        //     return updateObject(state, {
        //         isLoading: false,
        //         isEditLog: false,
        //         isUpdateRestroom: false,
        //         restroomDetails: action.payload,
        //     });


        case formAuditTagModuleActionTypes.EDIT_SINGLE_REQUESTFORM_DETAILS_PENDING:

            return updateObject(state, {
                isLoading: true,
                isEditLog: false,
                isUpdateRestroom: false,
                restroomDetails: null,
            });

        case formAuditTagModuleActionTypes.EDIT_SINGLE_REQUESTFORM_DETAILS_FULFILLED:

            return updateObject(state, {
                isLoading: false,
                isEditLog: false,
                isUpdateRestroom: false,
                restroomDetails: action.payload,
            });


        //     case formAuditLogBookActionTypes.ADD_SINGLE_RESTROOM_DETAILS_REJECTED:

        //     return updateObject(state, {
        //         isLoading: false,
        //         isEditLog: false,
        //         isUpdateRestroom: false,
        //         restroomDetails: action.payload,
        //     });



        case formAuditTagModuleActionTypes.REQUSET_FORM_PAGE_CLICKED:
            return updateObject(state, { requestFormCurPage: action.payload });

        default:
            return state;
    }
};
