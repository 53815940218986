import { API_VERSION, formAuditBaseUrl, timeTrackerBaseUrl } from '../../config';

const headers = {
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
};

//check if the baseURL IS correct or not    

export const getMatrixListAPI = (data) => {
  return formAuditBaseUrl.get(API_VERSION + `matrix?type=${data?.type}&page=1&limit=1000&active_status=${data.active_status ? data?.active_status:"active"}`, headers);

};

export const addStandardMatrixApi = (body) => {
  return formAuditBaseUrl.post(API_VERSION + `matrix/standard`, body, headers);  
};

export const updateStandardMatrixApi = (body,id) => {
  return formAuditBaseUrl.put(API_VERSION + `matrix/${id}`, body, headers);  
};

export const addCutomMatrixApi = (body) => {
  return formAuditBaseUrl.post(API_VERSION + `matrix/`, body, headers);  
};

export const deleteStandardMatrixApi = (id) => {
  return formAuditBaseUrl.delete(API_VERSION + `matrix/${id}`, headers);
};

export const getStandardMatrixApi = () => {
  return formAuditBaseUrl.get(API_VERSION + `matrix`, headers);
};

export const completePreviewEvent = (id,body) => {
  return formAuditBaseUrl.put(API_VERSION + `matrix/feedback/${id}`, body, headers);  
}

export const getAllBrmSchedule = (params) => {
  return formAuditBaseUrl.get(API_VERSION + `matrix/feedback`,{params} , headers);
};


export const getOneFeedBack = (id) => {
  return formAuditBaseUrl.get(API_VERSION + `matrix/feedback/${id}` , headers);
};



export const getBRMDashboardAPI = () => {
  return formAuditBaseUrl.get(API_VERSION + `matrix/feedback?dashboard=true`, headers);
};


export const getMatrixCompanyListAPI = () => {
  return formAuditBaseUrl.get(API_VERSION + `/companies/list`, headers);
};


export const fetchJobBasedMatrix = (params) => {
  return formAuditBaseUrl.get(API_VERSION + `/accounts/list/`, {params}, headers);  
};