import { BRMActionTypes } from "../../actions/BrmActions/BrmActionTypes";

export const initialState = {
  isStandardMatrix: true,
  isStandardMatrixModalOpen: false,
  isCustomMatrixModalOpen: false,
  matrixList: [],
  brmdashboard:[],
  matrixCompanies:[],
  updateMatrixList: {},
  isMatrixLoading: false,
  isMatrix: false,
  dashboardLoading:false,
  matrixCompanyLoader:false,
  isLoading: false,
  openmodal: false,
  deleteId: "",

  isBrmScheduleList: false,
  brmScheduleList: [],
  brmScheduleList_curPage: 0,
  brmScheduleList_total_pages: 1,
  brmScheduleList_no_of_rows: 10,
  total_brmScheduleList_count: '',
  brmScheduleList_searchValues: {},
  feedbackData:null,
  dataGenerated: false,
  matrix_jobs:[]
};

const updateObject = (oldState, updatedProps) => {
  return {
    ...oldState,
    ...updatedProps,
  };
};

export const BRMReducer = (state = initialState, action) => {
  switch (action.type) {
    case BRMActionTypes.CLICK_ON_MATRIX_BUTTON:
      return updateObject(state, {
        isStandardMatrix: action.payload,
        isStandardMatrixModalOpen: false,
        isCustomMatrixModalOpen: false,
      });

    case BRMActionTypes.CUSTOM_MATRIX_MODAL_OPEN:
      return updateObject(state, {
        isCustomMatrixModalOpen: action.payload,
        isStandardMatrixModalOpen: false,
      });

    case BRMActionTypes.STANDARD_MATRIX_MODAL_OPEN:
      return updateObject(state, {
        isStandardMatrixModalOpen: action.payload,
        isCustomMatrixModalOpen: false,
      });

    case BRMActionTypes.GET_MATRIX_LIST_PENDING:
      return updateObject(state, {
        matrixList: [],
        isMatrixLoading: true,
      });

    case BRMActionTypes.GET_MATRIX_LIST_FULFILLED:
      return updateObject(state, {
        matrixList: action.payload,
        isMatrixLoading: false,
      });

    case BRMActionTypes.GET_MATRIX_LIST_REJECTED:
      return updateObject(state, {
        matrixList: [],
        isMatrixLoading: false,
      });

    case BRMActionTypes.DELETE_STANDARD_MATRIX_PENDING:
      return updateObject(state, {
        isLoading: true,
      });

    case BRMActionTypes.DELETE_STANDARD_MATRIX_REJECTED:
      return updateObject(state, {
        isLoading: false,
      });

    case BRMActionTypes.DELETE_STANDARD_MATRIX_FULFILLED:
      const updatedMatrixList = state.matrixList.filter(
        (item) => item.id !== action.payload
      );
      return updateObject(state, {
        matrixList: updatedMatrixList,
        isLoading: false,
      });

    case BRMActionTypes.UPDATE_STANDARD_MATRIX:
      return updateObject(state, {
        updateMatrixList: action.payload,
        isMatrixLoading: false,
      });

    case BRMActionTypes.RESET_STANDARD_MATRIX:
      return updateObject(state, {
        updateMatrixList: {},
        isMatrixLoading: false,
      });

    case BRMActionTypes.ADD_CUSTOM_MATRIX_PENDING:
      return updateObject(state, {
        isLoading: true
      });
    case BRMActionTypes.ADD_CUSTOM_MATRIX_FULFILLED:
      return updateObject(state, {
        isLoading: false
      });
    case BRMActionTypes.ADD_CUSTOM_MATRIX_REJECTED:
      return updateObject(state, {
        isLoading: false
      });

    case BRMActionTypes.OPEN_MATRIX_DELETE_MODAL:
      return updateObject(state, {
        openmodal: true,
        deleteId: action.payload
      });

    case BRMActionTypes.CLOSE_MATRIX_DELETE_MODAL:
      return updateObject(state, {
        openmodal: false,

      });

    case BRMActionTypes.UPDATE_STANDARD_MATRIX_API_PENDING:
      return updateObject(state, {
        isLoading: true,
      });

    case BRMActionTypes.UPDATE_STANDARD_MATRIX_API_FULFILLED:
      return updateObject(state, {
        isLoading: false,
      });
    case BRMActionTypes.UPDATE_STANDARD_MATRIX_API_REJECTED:
      return updateObject(state, {
        isLoading: false,
      });

    case BRMActionTypes.ADD_STANDARD_MATRIX_PENDING:
      return updateObject(state, {
        isLoading: true,
      });

    case BRMActionTypes.ADD_STANDARD_MATRIX_REJECTED:
      return updateObject(state, {
        isLoading: false,
      });


    case BRMActionTypes.ADD_STANDARD_MATRIX_FULFILLED:
      return updateObject(state, {
        isLoading: false,
      });


    case BRMActionTypes.COMPLETE_PREVIEW_EVENT_PENDING:
      return updateObject(state, {
        isLoading: true,
      });

    case BRMActionTypes.COMPLETE_PREVIEW_EVENT_REJECTED:
      return updateObject(state, {
        isLoading: false,
      });

      case BRMActionTypes.COMPLETE_PREVIEW_EVENT_FULFILLED:
      return updateObject(state, {
        isLoading: false,
      });

    case BRMActionTypes.ADD_STANDARD_MATRIX_FULFILLED:
      return updateObject(state, {
        isLoading: false,
      });

    
    case BRMActionTypes.GET_ALL_BRM_SCHEDULE_PENDING:
      return updateObject(state, {
        isLoading: true,
        isBrmScheduleList: true,
        brmScheduleList: [],
      });
    case BRMActionTypes.GET_ALL_BRM_SCHEDULE_FULFILLED:
      return updateObject(state, {
        isLoading: false,
        dataGenerated: false,
        isBrmScheduleList: false,
        brmScheduleList: action.payload.brmScheduleList,
        brmScheduleList_total_pages: action.payload.brmScheduleList_total_pages,
        brmScheduleList_no_of_rows: action.payload.brmScheduleList_no_of_rows,
        brmScheduleList_curPage: action.payload.brmScheduleList_curPage,
        total_brmScheduleList_count: action.payload.total_brmScheduleList_count,
        brmScheduleList_searchValues: action.payload.searchValues,
      });

    case BRMActionTypes.BRM_SCHEDULE_PAGE_CLICKED:
      return updateObject(state, { brmScheduleList_curPage: action.payload });

            case BRMActionTypes.GET_BRM_DASHBOARD_PENDING:
              return updateObject(state, {
                brmdashboard : [],
                dashboardLoading: true,
              });
        
            case BRMActionTypes.GET_BRM_DASHBOARD_FULFILLED:
              return updateObject(state, {
                brmdashboard: action.payload,
                dashboardLoading: false,
              });
        
            case BRMActionTypes.GET_BRM_DASHBOARD_REJECTED:
              return updateObject(state, {
                brmdashboard: [],
                dashboardLoading: false,
              });

              case BRMActionTypes.GET_MATRIX_COMPANIES_PENDING:
              return updateObject(state, {
                matrixCompanies : [],//matrixCompanies
                matrixCompanyLoader: true,
              });
        
            case BRMActionTypes.GET_MATRIX_COMPANIES_FULFILLED:
              return updateObject(state, {
                matrixCompanies: action.payload,
                matrixCompanyLoader: false,
              });
        
            case BRMActionTypes.GET_MATRIX_COMPANIES_REJECTED:
              return updateObject(state, {
                matrixCompanies: [],
                matrixCompanyLoader: false
              });


              case BRMActionTypes.GET_ONE_FEEDBACK_PENDING:
                return updateObject(state, {
                  isLoading: true,
                  feedbackData: null
                });
              case BRMActionTypes.GET_ONE_FEEDBACK_FULFILLED:
                return updateObject(state, {
                  isLoading: false,
                  feedbackData: action.payload
                });
              case BRMActionTypes.GET_ONE_FEEDBACK_REJECTED:
                return updateObject(state, {
                  isLoading: false,
                  feedbackData: null
                });
                
                case BRMActionTypes.REMOVE_FEEDBACK:
                  return updateObject(state, {
                    isLoading: false,
                    feedbackData:null
                  }); 

                  case BRMActionTypes.FETCH_JOB_BASED_MATRIX_PENDING:
                    return updateObject(state, {
                      // isLoading: true,
                      matrix_jobs: []
                    });
                  case BRMActionTypes.FETCH_JOB_BASED_MATRIX_FULFILLED:
                    return updateObject(state, {
                      isLoading: false,
                      matrix_jobs: action.payload
                    });
                  case BRMActionTypes.FETCH_JOB_BASED_MATRIX_REJECTED:
                    return updateObject(state, {
                      isLoading: false,
                      matrix_jobs: []
                    });
                    case BRMActionTypes.RESET_JOB_BASED_MATRIX:
                      return updateObject(state, {
                        matrix_jobs: []
                      });
                  
                  
    default:
      return state;
  }
};
//COMPLETE_PREVIEW_EVENT