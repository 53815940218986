import React, { Component } from 'react';
import * as actions from '../../../../redux/actions/index';
import { connect } from 'react-redux';
import { reset } from 'redux-form';
import SkillSetLayout from '../../../component/TimeTrackerModuleComponent/SkillSetComponent/SkillSetLayout';
import { animateScroll as scroll} from 'react-scroll';

const cloneDeep = require('clone-deep');
class SkillSetContainer extends Component {
    constructor(props) {
        super(props);

    }

    componentDidMount() {
        this.props.getSkillSetList({ activeStatus: 'active' });
        scroll.scrollToTop();
    }

    handleFrequentTimeTracker_openDeleteModal = (id) => {
        this.props.handleFrequentTimeTracker_openDeleteModal(id);
    }

    handleFrequentTimeTracker_closeDeleteModal = () => {
        this.props.handleFrequentTimeTracker_closeDeleteModal();
    }

    handleFrequentTimeTracker_closeModal = () => {
        this.props.handleFrequentTimeTracker_closeModal();
        this.props.reset('skillSetForm');
    }

    addNewSkillSet = (values) => {
        let setData = { data: {} };
        let fetchedValue = cloneDeep(values);

        if (fetchedValue.skillset.name[fetchedValue.skillset.name.length - 1] === " ") {
            fetchedValue.skillset.name = fetchedValue.skillset.name.slice(0,-1);
        }

        if (this.props.isSkillSetEdit) {

            setData.data.name = fetchedValue.skillset.name;
            setData.data.description = fetchedValue.skillset.description;
            setData.data.active_status = fetchedValue.skillset.active_status == 'false' ? 'active' : 'inactive';

            this.props.editSkillSet(setData, this.props.skillSet.id);

        } else {

            setData.data.name = fetchedValue.skillset.name;
            setData.data.description = fetchedValue.skillset.description;
            setData.data.active_status = fetchedValue.skillset.active_status == 'false' ? 'active' : 'inactive';

            this.props.createSkillSet(setData);

        }
    }

    render() {
        return (
            <div>

                <SkillSetLayout
                    {...this.props}
                    handleFrequentTimeTracker_openDeleteModal={this.handleFrequentTimeTracker_openDeleteModal}
                    handleFrequentTimeTracker_closeDeleteModal={this.handleFrequentTimeTracker_closeDeleteModal}
                    handleFrequentTimeTracker_closeModal={this.handleFrequentTimeTracker_closeModal}
                    addNewSkillSet={this.addNewSkillSet}
                />


            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isLoading: state.TimeTrackerModReducer.isLoading,
        isSkillSet: state.TimeTrackerModReducer.isSkillSet,
        isSkillSetEdit: state.TimeTrackerModReducer.isSkillSetEdit,
        isTimeTrackerModalOpen: state.TimeTrackerModReducer.isTimeTrackerModalOpen,
       
        skillSet: state.TimeTrackerModReducer.skillSet,
        skillSet_list: state.TimeTrackerModReducer.skillSet_list,

        openFrequentTimeTracker_deleteModal: state.TimeTrackerModReducer.openFrequentTimeTracker_deleteModal,
        deleteFrequentTimeTracker_object: state.TimeTrackerModReducer.deleteFrequentTimeTracker_object,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        handleFrequentTimeTracker_openDeleteModal: (data) => dispatch(actions.handleFrequentTimeTracker_openDeleteModal(data)),
        handleFrequentTimeTracker_closeDeleteModal: (data) => dispatch(actions.handleFrequentTimeTracker_closeDeleteModal(data)),

        handleFrequentTimeTracker_openModal: (data) => dispatch(actions.handleFrequentTimeTracker_openModal(data)),
        handleFrequentTimeTracker_closeModal: (data) => dispatch(actions.handleFrequentTimeTracker_closeModal(data)),

        getSkillSetList: (data) => dispatch(actions.getSkillSetList(data)),
        createSkillSet: (data) => dispatch(actions.createSkillSet(data)),
        editSkillSetClicked: (data) => dispatch(actions.editSkillSetClicked(data)),
        editSkillSet: (data, id) => dispatch(actions.editSkillSet(data, id)),
        deleteSkillSet: (data) => dispatch(actions.deleteSkillSet(data)),

        reset: (skillSetForm) => dispatch(reset(skillSetForm)),  // requires form name
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SkillSetContainer);