import React from 'react'
import withFormHoc from '../../../../../../hoc/withReactHookForm/withReactHookForm'
import { useFieldArray } from 'react-hook-form'
import AssetsFormSkeleton from '../../../../Inventory/Assets/NewAssetsForm/AssetsFormSkeleton'
import { useSelector } from 'react-redux'
import { EDIT, VIEW } from '../../../../../../utility/constants/constants'
import { Views } from 'react-big-calendar'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import ChecklistLogForm from './checklistLogForm'

function NewTeamChecklistLogForm(props) {
  const {isLoading, isChecklistLogLoading}=useSelector((state)=>state.FormAuditModReducer)
  const {fields,append,remove}=useFieldArray({control:props.control,name:"data"})
  const history = useHistory()
 
 const defaultTaskData={
  manager_id:"",
  tc_issued:"",
  tc_returned:"",
  returned:"",
 }
 const addTaskToList=(e)=>{
   e.preventDefault();
   append(defaultTaskData)
   }

   const removeFromTaskList=(index)=>(e)=>{
     e.preventDefault();
     remove(index)
     }
   
     if (isChecklistLogLoading) {
       return (
           <AssetsFormSkeleton />
       )
   }
  return (
    <main class="app-content wraper_content chart_section">
    <section class="teammanage_formmain">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="team_manage_head">
              <h1 class="tm_top_head"> {props?.isEdit ? `${EDIT} Team Checklist Log` : props?.isView ? `${VIEW} Team Checklist Log` : "Team Checklist Log"}</h1>
            </div>
          </div>
          <form class="col-lg-12" onSubmit={props.handleSubmit(props.onSubmit)}>
            <div class="tm_form_wrapper">
            {
                  fields?.map((field,index)=>(
                    <ChecklistLogForm 
                     isView={props?.isView} key={field.id} controller={props.controller} field={field} control={props.control}  index={index} register={props.register} errors={props.errors} removeFromTaskList={removeFromTaskList}
                     getValues={props.getValues} 
                     setValue={props.setValue}
                    />
                   ))
                } 
             {
                  !props?.isEdit && !props?.isView ? <div class="action_button" >
                  <span onClick={addTaskToList}>+ Add More</span>
                </div> : null
                }
              <div class="row">
                <div class="col-sm-12">
                  <div class="submitcancel_btn">
                    <button onClick={() => history.goBack()} type="button" class="btn cst_btn btn-outline-secondary  cancel_btnnew">
                      Cancel
                    </button>
                    {props?.isView ? null : <button type="submit" class={isLoading ? "btn cst_btn btn_danger btn-wait" : "btn cst_btn btn_danger"}>
                    {isLoading ? ""  : props.isEdit ?  "Update"  : "Submit"}
                    </button>}
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  </main>
  )
}

export default withFormHoc(NewTeamChecklistLogForm) 