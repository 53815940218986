import React, { Component } from 'react';
import * as actions from '../../../../redux/actions/index';
import { connect } from 'react-redux';
import { reset } from 'redux-form';
import DepartmentLayout from '../../../component/TimeTrackerModuleComponent/DepartmentComponent/DepartmentLayout';
import { animateScroll as scroll } from 'react-scroll';

const cloneDeep = require('clone-deep');
class DepartmentContainer extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.getDepartmentList({ activeStatus: 'active' });
        scroll.scrollToTop();
    }

    handleFrequentTimeTracker_openDeleteModal = (id) => {
        this.props.handleFrequentTimeTracker_openDeleteModal(id);
    }

    handleFrequentTimeTracker_closeDeleteModal = () => {
        this.props.handleFrequentTimeTracker_closeDeleteModal();
    }

    handleFrequentTimeTracker_closeModal = () => {
        this.props.handleFrequentTimeTracker_closeModal();
        this.props.reset('departmentForm');
    }

    addNewDepartment = (values) => {
        let setData = { data: {} };
        let fetchedValue = cloneDeep(values);

        if (fetchedValue.department.name[fetchedValue.department.name.length - 1] === " ") {
            fetchedValue.department.name = fetchedValue.department.name.slice(0,-1);
        }

        if (this.props.isDepartmentEdit) {

            setData.data.name = fetchedValue.department.name;
            setData.data.description = fetchedValue.department.description;
            setData.data.active_status = fetchedValue.department.active_status == 'false' ? 'active' : 'inactive';

            this.props.editDepartment(setData, this.props.department.id);

        } else {

            setData.data.name = fetchedValue.department.name;
            setData.data.description = fetchedValue.department.description;
            setData.data.active_status = fetchedValue.department.active_status == 'false' ? 'active' : 'inactive';

            this.props.createDepartment(setData);
        }
    }

    render() {
        return (
            <div>

                <DepartmentLayout
                    {...this.props}
                    handleFrequentTimeTracker_openDeleteModal={this.handleFrequentTimeTracker_openDeleteModal}
                    handleFrequentTimeTracker_closeDeleteModal={this.handleFrequentTimeTracker_closeDeleteModal}
                    handleFrequentTimeTracker_closeModal={this.handleFrequentTimeTracker_closeModal}
                    addNewDepartment={this.addNewDepartment}
                />


            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isLoading: state.TimeTrackerModReducer.isLoading,
        isDepartment: state.TimeTrackerModReducer.isDepartment,
        isDepartmentEdit: state.TimeTrackerModReducer.isDepartmentEdit,
        isTimeTrackerModalOpen: state.TimeTrackerModReducer.isTimeTrackerModalOpen,

        department: state.TimeTrackerModReducer.department,
        department_list: state.TimeTrackerModReducer.department_list,

        openFrequentTimeTracker_deleteModal: state.TimeTrackerModReducer.openFrequentTimeTracker_deleteModal,
        deleteFrequentTimeTracker_object: state.TimeTrackerModReducer.deleteFrequentTimeTracker_object,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        handleFrequentTimeTracker_openDeleteModal: (data) => dispatch(actions.handleFrequentTimeTracker_openDeleteModal(data)),
        handleFrequentTimeTracker_closeDeleteModal: (data) => dispatch(actions.handleFrequentTimeTracker_closeDeleteModal(data)),

        handleFrequentTimeTracker_openModal: (data) => dispatch(actions.handleFrequentTimeTracker_openModal(data)),
        handleFrequentTimeTracker_closeModal: (data) => dispatch(actions.handleFrequentTimeTracker_closeModal(data)),

        getDepartmentList: (data) => dispatch(actions.getDepartmentList(data)),
        createDepartment: (data) => dispatch(actions.createDepartment(data)),
        editDepartmentClicked: (data) => dispatch(actions.editDepartmentClicked(data)),
        editDepartment: (data, id) => dispatch(actions.editDepartment(data, id)),
        deleteDepartment: (data) => dispatch(actions.deleteDepartment(data)),

        reset: (departmentForm) => dispatch(reset(departmentForm)),  // requires form name
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DepartmentContainer);