import React, { useState, useEffect, useRef } from 'react';
import 'react-quill/dist/quill.snow.css'; // ES6
import { Input } from '../../UI/InputElement/InputElement';
import { connect, useSelector } from 'react-redux';
import { newManualQuoteValidator as validate } from '../../../../utility/validator/validator';
import { reduxForm } from 'redux-form';
import ReactQuill,  { Quill } from 'react-quill'; // ES6
import { showConfirmAlert } from '../../../../utility/successAlert/ConfirmAlert';
import { userRoles } from '../../../../utility/constants/constants';
import QuotesSkeleton from '../../../component/Quotes/NewQuotes/QuotesSkeleton';
import { DateTimeField } from '../../UI/DateTimeField/DateTimeField';
import ImageLoader from 'react-imageloader';
import ModalImageGallery from '../../ModalImageGallery/modalImageGallery';
var Link = Quill.import('formats/link');
const cloneDeep = require('clone-deep');

const getAccountsDropDown = (props) => {
    if (
        props.user.role === userRoles.INSPECTOR ||
        props.user.role === userRoles.CLIENT ||
        props.user.role === userRoles.REQUESTOR
    ) {
        return (
            props.userAccountLists &&
            props.userAccountLists.map((account) => {
                return {
                    value: account.id,
                    label: account.name,
                };
            })
        );
    } else {
        return (
            props.accountsList &&
            props.accountsList.map((account) => {
                return {
                    value: account.id,
                    label: account.name,
                };
            })
        );
    }
};

const getCustomerDropdown = (props) => {
    return (
        props.dropDownCustomerList &&
        props.dropDownCustomerList.map((customer) => {
            return {
                value: customer.id,
                label: customer.first_name + ' ' + customer.last_name,
            };
        })
    );
};

let CreateQuoteManually = (props) => {
    const isAccountLoading = useSelector((state) => state.adminOrSuperAdminReducer.isAccountLoading);
    const [descriptionError, setDescriptionError] = useState('');
    const [editorHtmlToText, setEditorHtmlToText] = useState('');
    const [editorHtml, setEditorHtml] = useState('');
    const [accounts, setAccounts] = useState(getAccountsDropDown(props));
    const [customer, setCustomer] = useState(getCustomerDropdown(props));
    const [sigantureImage, setSigantureImage] = useState('');
    const [theme, setTheme] = useState('snow');

    useEffect(() => {
        setAccounts(getAccountsDropDown(props));
    }, [props.accountsList, props.userAccountLists]);

    useEffect(() => {
        setCustomer(getCustomerDropdown(props));
    }, [props.dropDownCustomerList]);

    const handleChange = (html, recentAdded, b, editorFunctions) => {
        let text = editorFunctions.getText();
        setEditorHtmlToText(text);
        recentAdded.ops.forEach((a, i) => {
            if (a.insert && a.insert.image) {
                setSigantureImage(a.insert.image);
            }
        });
        Link.sanitize = function customSanitizeLinkInput(linkValueInput) {
            var urlValue= linkValueInput;
            if (urlValue.indexOf("http://") == 0 || urlValue.indexOf("https://") == 0) {
               console.log(urlValue)
               return urlValue
           }
           else{
               
               urlValue = "https://" + urlValue;
               return urlValue
           }
   
       }
        if (text.length > 20) {
            setDescriptionError('');
        }
        setEditorHtml(html);
    };

    const handleThemeChange = (newTheme) => {
        if (newTheme === 'core') newTheme = null;
        setTheme(newTheme);
    };

    const EditorModules = {
        toolbar: [
            [{ header: '1' }, { header: '2' }, { font: [] }],
            [{ size: [] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
            ['link'],
            // ['link', 'image', 'video'],
            // ['clean']
        ],
        clipboard: {
            // toggle to add extra line breaks when pasting HTML:
            matchVisual: false,
        },
    };

    const EditorFormats = [
        'header',
        'font',
        'size',
        'bold',
        'italic',
        'underline',
        'strike',
        'blockquote',
        'list',
        'bullet',
        'indent',
        'link',
        'image',
        'video',
    ];

    const noOptionsMessage = (a, b) => {
        return 'Job not found';
    };

    const noCustomerMessage = (a, b) => {
        return 'Customer not found';
    };

    const submitQuote = (values, htmlContent, base64) => {
        // props.validate(values);
        // if(values.quote.account_id === '' || values.quote.account_id === null) {

        // showConfirmAlert("Are you sure that you would like to send this quote to your customer?", 'Please Confirm', () => {
        // submitQuote(values, editorHtml, sigantureImage)
        setDescriptionError('');
        let quote = cloneDeep(values);
        quote.quote['account_id'] = quote.quote['account_id']['value'];
        quote.quote['description'] = htmlContent;
        quote.quote['manual'] = true;
        props.submitManuallQuote(quote);
    };
    const base64Clicked = (i, urlLength) => {
        setIsImageGallery(true);
        setStartIndex(urlLength + i);
    };
    const photoUrlClicked = (i) => {
        setIsImageGallery(true);
        setStartIndex(i);
    };

    function preloader() {
        return (
            <img
                style={{ margin: '0 auto', display: 'block', width: '50%', height: '50%', cursor: 'pointer' }}
                src="/images/gif/giphy.gif"
            />
        );
    }

    const [isImageGallery, setIsImageGallery] = useState(false);
    const [startIndex, setStartIndex] = useState(0);
    const { handleSubmit, pristine, reset, submitting, error } = props;
    if (isImageGallery) {
        return (
            <ModalImageGallery
                isImageGallery={isImageGallery}
                setIsImageGallery={setIsImageGallery}
                startIndex={startIndex}
                base64={props.portfolioImages}
                imageUrls={props.savedPortfolioURls}
            />
        );
    }
    return (
        <main className="app-content  wraper_content inspection_wraper user_newQuote">
            <div className="inspection_content_sec appcontent_Inner">
                <div className="container-fluid">
                    {isAccountLoading ? (
                        <QuotesSkeleton />
                    ) : (
                        <form onSubmit={handleSubmit((values) => submitQuote(values, editorHtml, sigantureImage))}>
                            <div className="quote_form" style={{ maxWidth: '600px' }}>
                                <h1 data-test="new_quote_title">Add a Internal Quote</h1>
                                <div className="addQuote_form">
                                    <div className="form-group" data-test="quote_job_select">
                                        <label className="form_title">Job</label>
                                        <Input
                                            name="quote[account_id]"
                                            onChange={(e) => props.accountChanged(e)}
                                            noOptionsMessage={noOptionsMessage}
                                            type="inputSelect"
                                            options={accounts}
                                        />
                                    </div>

                                    <div class="form-row mb-2">
                                        <div className="form-group form-group col-3 mb-0">
                                            <label className="form_title">Amount</label>
                                            <Input
                                                type="number"
                                                dataTest="quote_amount_input"
                                                min={1}
                                                name="quote[total_sale]"
                                                className="form-control input-modifier"
                                                id="total_sale"
                                            />
                                        </div>
                                        <div class="form-group col-9 mb-0">
                                            <label className="form_title">Date</label>
                                            <Input
                                                name="quote[quote_created_at]"
                                                minDate={new Date()}
                                                preventReadOnly={true}
                                                dataTest="calendar_date_picker"
                                                style={{ maxWidth: '165px' }}
                                                dateFormat="MMMM d, yyyy h:mm aa"
                                                showTimeSelect
                                                placeholder="Start Date"
                                                datePickerType={'react-datepicker'}
                                                type="date"
                                                className="form-control input-modifier from-date-analytics"
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label className="form_title">By Method</label>
                                        <Input
                                            type="textarea"
                                            name="quote[by_method]"
                                            className="form-control input-modifier"
                                            rows="2"
                                            placeholder="Type here"
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label name="quote[confirmed_by]" className="form_title">
                                            Customer
                                        </label>
                                        <Input
                                            name="quote[confirmed_by]"
                                            readOnly={!props.dropDownCustomerList}
                                            noOptionsMessage={noCustomerMessage}
                                            isMulti={false}
                                            type="inputSelect"
                                            options={customer}
                                        />
                                    </div>
                                    <ReactQuill
                                        style={{ height: '50%' }}
                                        theme={theme}
                                        onChange={handleChange}
                                        defaultValue={editorHtml}
                                        modules={EditorModules}
                                        formats={EditorFormats}
                                        bounds={'.app'}
                                        placeholder="Detail work to be completed."
                                    />
                                    <span style={{ color: '#DD2726', fontSize: '13px' }}>{descriptionError}</span>
                                    <div className="file_attachment ticket_form" style={{ paddingTop: '10px' }}>
                                        <Input
                                            type="file"
                                            name="attachment_paths"
                                            hidden={true}
                                            multiple={true}
                                            onSelect={props.onPortfolioFileChange}
                                            fileRef={props.reference}
                                            accept="image/*"
                                        />
                                        <a
                                            href="javascript:void(0)"
                                            onClick={props.choosePhotoClicked}
                                            className="file_title"
                                        >
                                            <img src="/images/icons/attachment_icon.png" alt="Icon" /> Attachments
                                        </a>
                                        <p className="size_dec">Up to 10 atachments (10MB each).</p>

                                        <div class="upload_file">
                                            <div class="row">
                                                {props.savedPortfolioURls &&
                                                    props.savedPortfolioURls.map((url, i) => {
                                                        return (
                                                            <div class="col-4 col-sm-4 cst_gutter" key={i}>
                                                                <div
                                                                    class="file_view"
                                                                    onClick={() => photoUrlClicked(i)}
                                                                >
                                                                    {props.setLoader ? (
                                                                        <ImageLoader
                                                                            style={{
                                                                                margin: '0 auto',
                                                                                display: 'block',
                                                                                width: '50%',
                                                                                height: '50%',
                                                                                cursor: 'pointer',
                                                                            }}
                                                                            src={url.photo_urls.small}
                                                                            wrapper={React.createFactory('div')}
                                                                            preloader={preloader}
                                                                        >
                                                                            Image load failed!
                                                                        </ImageLoader>
                                                                    ) : null}
                                                                </div>
                                                                <div class="cancel_icon">
                                                                    <img
                                                                        src="/images/icons/icn_delete_white.png"
                                                                        onClick={() => props.removeUrls(url, i)}
                                                                        alt="Image"
                                                                    />
                                                                </div>
                                                            </div>
                                                        );
                                                    })}
                                                {props.portfolioImages &&
                                                    props.portfolioImages.map((url, i) => {
                                                        return (
                                                            <div class="col-4 col-sm-4 cst_gutter" key={i}>
                                                                <div class="file_view">
                                                                    <img
                                                                        src={url}
                                                                        alt="Image"
                                                                        onClick={() =>
                                                                            base64Clicked(
                                                                                i,
                                                                                props.savedPortfolioURls.length
                                                                            )
                                                                        }
                                                                    />
                                                                    <div class="cancel_icon">
                                                                        <img
                                                                            src="/images/icons/icn_delete_white.png"
                                                                            onClick={() => props.removeImage(url, i)}
                                                                            alt="Image"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        );
                                                    })}
                                                {props.setLoader ? (
                                                    <ImageLoader
                                                        style={{
                                                            margin: '0 auto',
                                                            display: 'block',
                                                            width: '50%',
                                                            height: '50%',
                                                            cursor: 'pointer',
                                                        }}
                                                        src=""
                                                        wrapper={React.createFactory('div')}
                                                        preloader={preloader}
                                                    >
                                                        Image load failed!
                                                    </ImageLoader>
                                                ) : null}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group btn_block mb-0">
                                    <button
                                        data-test="submit_button"
                                        disabled={props.isLoading}
                                        type="submit"
                                        className={
                                            props.isLoading
                                                ? 'btn cst_btn btn_danger mr-2 btn-wait'
                                                : 'btn cst_btn btn_danger mr-2'
                                        }
                                    >
                                        {props.isLoading ? '' : 'Submit'}
                                    </button>
                                    <button
                                        data-test="cancel_button"
                                        type="button"
                                        onClick={props.onCancleClicked}
                                        className="btn cst_btn btn-outline-secondary"
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </form>
                    )}
                </div>
            </div>
        </main>
    );
};

const mapStateToProps = (state) => {
    let quote = {
        account_id: '',
        // tag_opportunity: "true"
        total_sale: '',
        quote_created_at: '',
        confirmed_by: '',
        by_method: '',
    };
    return {
        initialValues: { quote },
    };
};

CreateQuoteManually = reduxForm({
    form: 'newQuoteForm',
    validate,
})(CreateQuoteManually);

CreateQuoteManually = connect(mapStateToProps)(CreateQuoteManually);

export default CreateQuoteManually;
