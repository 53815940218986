import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

const renderField = ({ input, disabled, id, checked, onClick, label, h5Class, divClass, h5Text, divText, checkbox, className, value, labelClass, spanClass, iconClass, meta: { touched, asyncValidating, error, warning } }) => {

    return (
        <>
            {/* <div className="clear-fix" style={{marginTop: '20px'}}> <p>{touched && error ? error : null}</p></div> */}
            {checkbox === 'selectStyle' ?

                <label className={input.value ? "select_style_links active" : "select_style_links"}>
                    <span className={"itemcontent"}>
                        <input disabled={disabled} type="checkbox" {...input} checked={input.value} className={className} />
                        {/* input.value ? input.value : checked */}
                        <h5 className={h5Class}>{h5Text}</h5>
                        {/* <div className={divClass}>{divText}</div> */}
                    </span>
                </label>

                :
                labelClass !== "" ? <label className={labelClass}>{label}
                    <i className={iconClass}></i>
                    <input disabled={disabled} type="checkbox" {...input} checked={input.value} />
                    <span className={spanClass}></span>
                </label> :

                    <>
                        <input disabled={disabled} className={className} type="checkbox" id={id} {...input} checked={input.value === true} />
                        <span> {touched && error ? error : null}</span>
                    </>
            }
            {/* <span>{touched && error ? error : null}</span> */}
        </>
    );
}
export const CheckBoxBtn = ({ disabled, type, id, onClick, h5Class, checked, divClass, divText, h5Text, checkbox, name, className, label, value, labelClass, spanClass, iconClass, handleChange }) => (
    <Field
        type={type}
        disabled={disabled}
        id={id}
        name={name}
        label={label}
        checked={checked}
        labelClass={labelClass}
        checkbox={checkbox}
        divClass={divClass}
        // onClick={onClick}
        divText={divText}
        spanClass={spanClass}
        className={className}
        iconClass={iconClass}
        h5Class={h5Class}
        h5Text={h5Text}
        onChange={handleChange}
        component={renderField}
    />
);

CheckBoxBtn.propTypes = {
    type: PropTypes.string,
    id: PropTypes.string,
    name: PropTypes.string,
    label: PropTypes.string,
    className: PropTypes.string,
    divClass: PropTypes.string,
    divText: PropTypes.string,
    checkbox: PropTypes.string,
    checked: PropTypes.bool,
    labelClass: PropTypes.string,
    h5Class: PropTypes.string,
    h5Text: PropTypes.string,
    spanClass: PropTypes.string,
    onClick: PropTypes.func,
    handleChange: PropTypes.func,
    iconClass: PropTypes.string
};

CheckBoxBtn.defaultProps = {
    type: 'checkbox',
    label: null,
    checkbox: '',
    labelClass: '',
    spanClass: '',
    iconClass: ''
};