import React from 'react';
import ReactTable from 'react-table-v6';
import '../../../styles/custom.css';
import { makeFirstLetterCapital, CustomNoDataComponent, capitalAndConvertHyphen } from '../../../utility/utility';
import { MagicSpinner, PushSpinner, SwishSpinner, WaveSpinner, ClapSpinner } from 'react-spinners-kit';
import 'react-table-v6/react-table.css';
import ReactTooltip from 'react-tooltip';
import { FaArrowDown, FaArrowUp } from "react-icons/fa";
import { userRoles } from '../../../utility/constants/constants';


const InactiveCriteriaTable = (props) => {

    return (
        <>
                {!props.isCriteria ? <div className="quotes_table_content table-responsive performance_modal add_account associateUser">
                    <div className="table quotes_table user_table_contnet user_react_table">
                    <div class="col-lg-12">
                        <div class="quote_form user_quota_form ticket_form">
                            <div class="addQuote_form">
                            <div class="form-group">
                                <div class="form-group checkbox_cst_grid">
                                {props.criteries ? props.criteries && props.criteries.map((a, i) => (
                                    <>
                                    {
                                        a.hide === true ? 
                                        <div class="checkbox_block radius_checkbox category_text_overflow">
                                <div class="custom-checkbox gridStyle">
                                <div class="gridInlineBlc">
                                    <label class="f12 grdTitle">{makeFirstLetterCapital(a.name)} </label>
                                    <div class="icnBlc">
                                    <span className="mr_20 account_edit_icn" data-toggle="modal" data-target="#addCriteria" data-backdrop="static" data-keyboard="false" onClick={() => props.editCriteriaClicked(a)}><i class="fa fa-pencil" aria-hidden="true"></i></span>
                                    <span className="mr_20 account_edit_icn" onClick={() => props.handleClickDeleteModel(a.id)}><i class="fa fa-trash" aria-hidden="true"></i></span>
                                    </div>
                                </div>
                                </div>
                                </div> : ''
                                    }
                            
                                </>
                            )) : 'No Data Found' }
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div> : <div className="loader_btn_block spinner">
                            <ClapSpinner backColor="#89073c" size={40} frontColor="#89073c8a" />
                        </div>}
                        </>
    )
}

export default InactiveCriteriaTable;
