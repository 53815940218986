import React, {useState} from "react";
// import Pagination from "react-js-pagination";
// import LogHistoryUnavailableModel from "./LogHistoryUnavailableModel";
import {convertDateAndTimeTimezonelll} from "../../../../../utility/utility";
import "./paginationLogHistory.css";

const LogbookSearchResult = ({searchLogHistory, user,...props}) => {
  const [currentSchedule, setCurrentSchedule] = useState({});
  return(
      <div className="appcontent_Inner">
        {/* {searchLogHistory ? <LogHistoryUnavailableModel {...props} currentSchedule={currentSchedule} setCurrentSchedule={setCurrentSchedule}></LogHistoryUnavailableModel> : ""} */}
        <div className="history_block min-height-75">
          <div className="add_content_block">
            <h2 className="h3_title"><span className="title_block"> Logbook History
            </span> <span className="mob_border_view"><span className="cst_border"></span></span></h2>
              <ol className="activity-feed">
                {
                  searchLogHistory && searchLogHistory.logbook_logs && Object.keys(searchLogHistory.logbook_logs).length > 0 ? Object.keys(searchLogHistory.logbook_logs).map((key, index) => {
                    return searchLogHistory.logbook_logs[key]["logbookLog"].map((task, index) => {
                      return (<li key={index} className="feed-item timeline_item">
                        {
                          (task.status === "not_available" && user && ["superadmin", "admin"].includes(user.role)) ?
                              <span className="list_content">
                                <span className="font-weight-semibold">{task.logbook.name}</span>{` was `}
                                <strong className="sm_title text_danger">{`In-use `}</strong>{`at `}
                                <span className="font-weight-semibold">
                                  {
                                    task.reviewed_at ?   convertDateAndTimeTimezonelll(task.reviewed_at, searchLogHistory.timezone) :  convertDateAndTimeTimezonelll(task.start_time_utc, searchLogHistory.timezone)
                                  }
                                </span>
                                <span data-toggle="modal" onClick={() => setCurrentSchedule(task)} data-target="#setUnavailable" className="info_icon">
                                  {" "}<img src="/images/icons/info.png" alt="Image" />
                                </span>
                              </span>
                              :
                              <span className="list_content">
                                <span className="font-weight-semibold">{task.logbook.name}</span>
                                {task.status === "reviewed" ? ` reviewed on ` : ` not reviewed on `}
                                <span className="font-weight-semibold">
                                  {
                                    task.reviewed_at ? convertDateAndTimeTimezonelll(task.reviewed_at, searchLogHistory.timezone) :  convertDateAndTimeTimezonelll(task.start_time_utc, searchLogHistory.timezone)
                                  }
                                </span>
                              </span>
                        }
                      </li>)
                    })
                  }) : <h6 style={{textAlign: 'center'}}> No logs found </h6>
                }
              </ol>
              {/* <div className="row">
                <nav aria-label="Page navigation example" className="history-pagination col-12">

                {
                  searchLogHistory &&  searchLogHistory.logbook_logs && props.total_no_of_pages ? <Pagination
                      activePage={props.search.page}
                      className="float-right"
                      itemsCountPerPage={props.search.size}
                      totalItemsCount={props.search.size * props.total_no_of_pages}
                      pageRangeDisplayed={5}
                      onChange={props.handlePageChange}
                      /> : ""
                }
                  </nav>
              </div> */}
            </div>
          </div>
        </div>
  )
};

export default LogbookSearchResult;