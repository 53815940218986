import React, { useEffect, useState } from 'react';
import { Input } from '../../UI/InputElement/InputElement';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import { companyUserSearchValidator as validate } from '../../../../utility/validator/validator';
import AssociateUserModal from './associateUserModal';
import AssociateCompanyModal from './associateCompanyModal';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import CompanyUsersTable from './companyUsersTable';
import { userRoles } from '../../../../utility/constants/constants';

const getAccountsDropDown = (accounts) => {

    return accounts && accounts.map(a => {
        return {
            value: a.id,
            label: a.name
        }
    })
}

let CompanyUsers = (props) => {
    const [accountList, setAccountList] = useState(getAccountsDropDown(props.companyUsersList));
    const getAccountsDropDownAllJobs = props.companyUsersList && props.companyUsersList.map(a => {
        return {
                value: a.id,
                label: a.name
            }
        })
    useEffect(() => {
        setAccountList(getAccountsDropDown(props.companyUsersList))
    }, [props.companyUsersList])

    const noAccountOptionsMessage = (a, b) => {
        return 'Job not found';
    }
    const { handleSubmit, pristine, reset, submitting, error } = props;
    return (
        <>
            <main className="app-content wraper_content inspection_wraper">
                <div className="tab_header search_filter_cont inspection_tab_content appcontent_inner">
                    <form onSubmit={handleSubmit}>
                        <div className="row">
                            {/* <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                                <div className="app-search">
                                    <Input className="fil_search_input input-modifier" name="name" type="search" placeholder="Search By Companie" />
                                    <button className="app-search__button">
                                        <i class="fa fa-search" aria-hidden="true"></i>
                                    </button>
                                </div>
                            </div> */}
                            <div className="col-xl-3 col-lg-4 col-md-12 col-sm-12">
                                <Input name="account_id" noOptionsMessage={noAccountOptionsMessage} placeholder="Job" type="inputSelect" options={getAccountsDropDownAllJobs} />
                            </div>
                            <div className="col-xl-4 col-lg-2 col-md-6 col-sm-6 col-12">
                                <button style={{ minWidth: '90px' }} className={"btn cst_btn btn_danger mr-2  account_btn"} id="submit" type="submit">{"Go"} </button>
                                <button style={{ minWidth: '90px' }} onClick={props.resetClicked} className={"btn cst_btn btn-outline-secondary account_btn"} id="reset" type="reset">{"Reset"} </button>
                            </div>
                            <div className="col-xl-4 col-lg-2 col-md-6 col-sm-6 col-12 text-right" style={{ display: "flex", justifyContent: "flex-end" }}>
                                <div>
                                    <button className="btn cst_btn btn_danger mr-2 account_btn" id="submit" type="" onClick={props.BRMSuheduleClicked}>BRM Schedule</button>
                                </div>
                                <div>
                                    <button className="btn cst_btn btn_danger mr-2 account_btn" id="submit" type="" onClick={props.kpiDashboardClicked}>Dashboard</button>
                                </div>
                            </div>

                            {/* <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                                <div className="app-search">
                                    <Input className="fil_search_input input-modifier" name="email" type="search" placeholder="Search By Email" />
                                    <button className="app-search__button">
                                        <i class="fa fa-search" aria-hidden="true"></i>
                                    </button>
                                </div>
                            </div> */}
                            {/* <div className="col-xl-4 col-lg-5 col-md-12 col-sm-12 text-right accounts_btn_wra theme_col_px">
                                <button type="button" className="btn btn-secondary cst_btn mr-3" onClick={props.openAssociateUserClicked} data-backdrop="static" data-keyboard="false" data-toggle="modal" data-target="#associateUser">Associate Job</button>
                                {props.user.role === userRoles.ADMIN ? null :
                                    <button type="button" className="btn btn-secondary cst_btn" onClick={props.newCompanyUserClicked} data-toggle="modal" data-target="#addAccountModal"><i class="fa fa-plus icn_plus"></i>New User</button>
                                }
                            </div> */}
                            {/* <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-6">
                                <button style={{ minWidth: '90px' }} onClick={props.resetClicked} className={"btn cst_btn btn-outline-secondary"} id="reset" type="reset">{"Reset"} </button>
                            </div>


                            <div className="col-xl-2 col-lg-2 col-md-6 col-sm-6 col-6 text-right quotes_btn_wrap">
                                <a onClick={props.newCompanieClicked} href="javascript:void (0)" className="btn btn-secondary cst_btn"><i className="fa fa-plus icn_plus"></i>New Company</a>
                            </div> */}
                        </div>
                    </form>
                </div>


                <section className="account_sec user_screen inspection_tab_content">
                    {/* <CSVLink filename="Users.csv" className="csv_btn btn_danger " data={convertUsersDataToCsvType(props.usersList)} >Export Csv</CSVLink><br /><br /><br /> */}
                    <div className="quotes_table_content table-responsive">
                        <div className="table quotes_table user_table_contnet user_react_table account_table">
                            <CompanyUsersTable
                                {...props}
                                deleteCompanyUser={props.deleteCompanyUser}
                                companyUsers_rows={props.companyUsers_rows}
                                isLoading={props.isLoading}
                                onCompanyUsersPageSizeChange={props.onCompanyUsersPageSizeChange}
                                companyUsersList={props.companyUsersList ? props.companyUsersList : []}
                                editCompanyUsersClicked={props.editCompanyUsersClicked}
                                openDeleteModel={props.openDeleteModel}
                                handleClickDeleteModel={props.handleClickDeleteModel} />
                        </div>

                    </div>
                    {props.openAssociateUserCompanyModal ?
                        <>
                            {/* <AssociateUserModal
                                onSubmit={props.userSelected}
                                usersList={props.usersList}
                                closeCompanyModalClicked={props.closeCompanyModalClicked} 
                                /> */}
                            <AssociateCompanyModal
                                onSubmit={props.addCompanyUsers}
                                getCurrentCompany={props.getCurrentCompany}
                                companyErrorMessage={props.companyErrorMessage}
                                popupLoading={props.popupLoading}
                                filterCompanyUsers={props.filterCompanyUsers}
                                company={props.company}
                                closeCompanyModalClicked={props.closeCompanyModalClicked}
                            />
                        </>
                        : null}
                    {/* {props.openAssociateCompanyModal ?
                         : null} */}

                </section>
            </main>

            <Dialog
                open={props.openDeleteModel}
                onClose={props.handleClose}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title" style={{ fontSize: '13', color: '#06080f' }}>
                    <h3 style={{ color: '#ab385e' }}>Are you sure?</h3>
                </DialogTitle>

                <DialogActions>
                    <button style={{ minHeight: '36px', minWidth: '80px' }} onClick={() => props.deleteCompanyUser(props.deleteObject.id)} disabled={props.isLoading} className={props.isLoading ? "btn cst_btn btn_danger mr-2 btn-wait" : "btn cst_btn btn_danger mr-2"} id="delete" type="delete">{props.isLoading ? "" : "Delete"}</button>
                    <button style={{ minHeight: '36px', minWidth: '80px' }} onClick={props.handleDeleteClose} className={"btn cst_btn btn-outline-secondary"} id="cancel" type="cancel">Cancel</button>
                </DialogActions>
            </Dialog>
        </>
    )

}

const mapStateToProps = (state) => {
    let account_id = "";

    return { initialValues: { account_id } }
}

CompanyUsers = reduxForm({
    form: 'searchCompanieUsersForm',
    validate,
    enableReinitialize: true
})(CompanyUsers);

CompanyUsers = connect(mapStateToProps)(CompanyUsers)

export default CompanyUsers;