import React from 'react';
import { connect } from 'react-redux';
import { reset } from 'redux-form';
import * as actions from '../../../../../redux/actions/index';
import { routes, address_attributes } from '../../../../../utility/constants/constants';
import isEmpty from '../../../../../utility/IsEmptyValidator';
import { extractExtention, getUser, resetOrientation } from '../../../../../utility/utility';
import NewAssetsForm from '../../../../component/Inventory/Assets/NewAssetsForm/NewAssetsForm';
import moment from 'moment';
import SuccessAlert from '../../../../../utility/successAlert/successAlert';
import { ConvertToFormat, tzUTCDateConverterTool_FormAudit } from '../../../../../utility/TimezoneOperations/timezoneUtility';
const cloneDeep = require('clone-deep');

class NewAssetsContainer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentDidMount() {
        
        if (this.props && this.props.match && this.props.match.params && this.props.match.params.assetId) {
            this.props.getSingleAsset(this.props.match.params.assetId);
        }
        //this.props.getAllInventoryLocationList();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {}

    componentWillUnmount() {
        this.props.resetInventoryObjects();
    }

    addNewAsset = (values) => {
        let assetData = cloneDeep(values.asset.item);
        
        if(assetData.item && assetData.item.name){
            assetData.item.name = assetData.item.name.trim()
        }
        
        if (this.props && this.props.match && this.props.match.params && this.props.match.params.assetId) {
             let newAssetData = assetData[0]
             
            const expiration_date = tzUTCDateConverterTool_FormAudit(ConvertToFormat(newAssetData.expiration_date),"to"); 
            const purchase_date = tzUTCDateConverterTool_FormAudit(ConvertToFormat(newAssetData.purchase_date),"to")
            const salvage_date= tzUTCDateConverterTool_FormAudit(ConvertToFormat(newAssetData.salvage_date),"to")
            newAssetData.expiration_date = expiration_date;
            newAssetData.purchase_date = purchase_date;
            newAssetData.salvage_date = salvage_date;
            // if(assetData.item && assetData.item) {
            //     assetData.item.quantity = assetData.item.initial_quantity;
            //     delete assetData.item.initial_quantity
            // }
            
            let id = this.props.match.params.assetId;
            let body={
                item:newAssetData
            }
            this.props.updateAsset(body,id);

        } else {
            //const expiration_date = moment(assetData.item.expiration_date).format('YYYY-MM-DD');
            assetData= assetData.map((item)=> ({...item,type:"asset",salvage_date:tzUTCDateConverterTool_FormAudit(ConvertToFormat(item.salvage_date),"to"),purchase_date:tzUTCDateConverterTool_FormAudit(ConvertToFormat(item.purchase_date),"to"),expiration_date:tzUTCDateConverterTool_FormAudit(ConvertToFormat(item.expiration_date),"to")}))
            //assetData.item.expiration_date = expiration_date;
            //assetData.item.type = 'asset';
            const body ={
                items:assetData
            }
            this.props.createAsset(body);
        }       
    }

    onCancelClicked = () => {
        this.props.history.goBack();
    }

    render() {

        return (
            <NewAssetsForm
                {...this.props}
                onSubmit={this.addNewAsset}
                onCancelClicked={this.onCancelClicked}
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.authReducer.user,
        isLoading: state.InventoryReducer.isLoading,
        isEditAsset: state.InventoryReducer.isEditAsset,
        isUpdateAsset: state.InventoryReducer.isUpdateAsset,
        singleAssetData: state.InventoryReducer.singleAssetData,
        inventoryLocationList: state.InventoryReducer.inventoryLocationList,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        resetInventoryObjects: () => dispatch(actions.resetInventoryObjects()),
        createAsset: (data) => dispatch(actions.createAsset(data)),
        updateAsset: (data,id) => dispatch(actions.updateAsset(data,id)),
        getSingleAsset: (id) => dispatch(actions.getSingleAsset(id)),
        getAllInventoryLocationList: (data) => dispatch(actions.getAllInventoryLocationList(data)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewAssetsContainer);