import React, { useEffect, useState } from 'react';
import '../../../../../../styles/custom.css';
import NewDailyKittingPage from '../../../../../component/FormAuditModule/Inventory/DailyKittingPage/NewDailyKittingPage/NewDailyKIttingPage';
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";
import {  getAllJob } from "../../../../../../redux/actions/FormAuditModAction/TeamManagementAction/teamManagementAction";
import { addDailyKittingDetails, getInventoryList, getSingleDailyKittingDetails,editDailyKittingDetails } from '../../../../../../redux/actions/FormAuditModAction/InventoryAction/InventoryAction';
import moment from 'moment';
import { convertDateTimezoneFormAudit, ConvertToFormat, tzUTCDateConverterTool_FormAudit } from '../../../../../../utility/TimezoneOperations/timezoneUtility';


const NewDailyKittingContainer = () => {

  const { location: { pathname } } = useHistory()
  const { id } = useParams();
  const dispatch = useDispatch()
  const isEdit = Boolean(pathname.split("/")[2] !== "view" && id)
  const isView = Boolean(pathname.split("/")[2].includes("view"))
  const { dailyKittingDetails } = useSelector((state) => state.InventoryModuleReducer)
  
  const formatSectionData = (item) => {
    const {item_id,...remainData}=item
    return [{ ...remainData,item:+item_id }]
  }

  
  const defaultForm = {
    date: isEdit || isView ? dailyKittingDetails?.date ? convertDateTimezoneFormAudit(dailyKittingDetails.date): "" : "",
    location: isEdit || isView ? dailyKittingDetails?.location ? dailyKittingDetails.location : "" : "",
    shift_time: isEdit || isView ? dailyKittingDetails?.shift_time ? dailyKittingDetails.shift_time.split(":")[0]  : "" : "",
    comments: isEdit || isView ? dailyKittingDetails?.comments ? dailyKittingDetails.comments: "" : "",
    account_id:isEdit || isView ? dailyKittingDetails?.account_id ? dailyKittingDetails.account_id: "" : "",
    sections: isEdit || isView ? dailyKittingDetails?.daily_kit_log_sections ? formatSectionData(dailyKittingDetails.daily_kit_log_sections) : [
      {
        item: "",
        count: "",
        specialist: ""
      },

    ]
      :
      [
        {
          item: "",
          count: "",
          specialist: ""
        }, ,]

  }



  const MomentStringToHour = (str, format = "HH:mm:ss", sec) => {
    let time = `${str}:00:00`;

    return time
  }



  const structureBody = (data) => {
    let body = null

    const { sections , ...remainData } = data
    let newSection=sections[0]
    const commonBody = {
      ...remainData,
      date:tzUTCDateConverterTool_FormAudit(ConvertToFormat(remainData.date), "to")
    }
    if (isEdit) {
      delete newSection.active_status;
      delete newSection.created_at;
      delete newSection.updated_at;
      delete newSection.daily_kit_log_id;
      delete newSection.id;
      delete newSection.inventory_product;
      body = [{
        date:remainData.date,
        location:remainData.location,
        comments:remainData.comments,
        id: dailyKittingDetails.id,
        shift_time: MomentStringToHour(commonBody.shift_time),
        ...newSection,
        item: newSection.item,
        count:+newSection.count,
        account_id:remainData.account_id
      }]
      
    }
    else {
      body = data.sections.map((item) => ({ ...commonBody, ...item, item: item.item.toString(), shift_time: MomentStringToHour(commonBody.shift_time) }))
    }
    
    return {
      data: body
    }
  }

  const onSubmit = (data) => {
    if (isEdit) {
      dispatch(editDailyKittingDetails(structureBody(data)))
    } else {
      dispatch(addDailyKittingDetails(structureBody(data)))
    }
    // if(data){
    //   // SuccessAlert('Daily Kitting Added Successfully')
    //   // history.push(routes.DAILY_KITTING)
    // }
    // if(isEdit){
    //    dispatch(editTeamManagementSummary(structureBody(data)))

    // }else{

    //   dispatch(addTeamManagementSummary(structureBody(data)))
    // }
  }

  useEffect(() => {
    if (isEdit || isView && id) {
      dispatch(getSingleDailyKittingDetails(id))
    }
    dispatch(getAllJob())
  }, [id])




  useEffect(() => {

    dispatch(getInventoryList({os1 : true}))
  }, [])

   
  return (
    <>
      <NewDailyKittingPage
        elements={defaultForm}
        formSubmittedCallback={onSubmit}
        isEdit={isEdit}
        isView={isView}
        modalUpdate={true}
      />

    </>
  )
}



export default NewDailyKittingContainer;