import React, { Component } from 'react';
import ForgetAndResetPassword from '../../component/ForgetOrResetPassword/ForgetAndResetPassword';
import { routes } from '../../../utility/constants/constants';
import * as actions from '../../../redux/actions/index';
import { connect } from 'react-redux';

class ForgetPassword extends Component {

    signInClicked = () => {
        this.props.history.push(routes.SIGNIN);
    }

    handleSubmit = (user) => {
        this.props.forgetPassword(user);
    }

    render() {

        return (
            <ForgetAndResetPassword
                signInClicked={this.signInClicked}
                onSubmit={this.handleSubmit}
                isLoading = {this.props.isLoading}
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        isLoading: state.authReducer.isLoading
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        forgetPassword: (user) => dispatch(actions.forgetPassword(user))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgetPassword);