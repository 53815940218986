import axios, { API_VERSION, timeTrackerBaseUrl } from '../../config';
import storage from '../../utility/storage';
import { getUser } from '../../utility/utility';
const pureAxios = require('axios');

const user = storage.get('user');

const headers = {
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
    },
};

/* Amazon S3 Bucket Upload API */
export const uploadImageToS3 = (url, arrayBuffer) => {
    return pureAxios.put(url, arrayBuffer);
};

/* PATCH Presign URL API for Pegassure Playbook PDF */
export const getPegassurePlaybookPdfPresignUrl = (extention) => {
    return timeTrackerBaseUrl.patch(API_VERSION + `config/public/presignUrl?id=100&name=face`, { data: { ext: [extention] } }, headers);
};

/* POST Create Playbook Pdf API for Pegassure Playbook PDF */
export const createPegassurePlaybookPdf = (body) => {
    return axios.post(API_VERSION + `playbook`, body, headers);
}

/* PUT Update Playbook Pdf API for Pegassure Playbook PDF */
export const updatePegassurePlaybookPdf = (body, id) => {
    return axios.put(API_VERSION + `playbook/${id}`, body, headers);
}

/* DELETE Remove Playbook Pdf API for Pegassure Playbook PDF */
export const deletePegassurePlaybookPdf = (id) => {
    return axios.delete(API_VERSION + `playbook/${id}`, headers);
};

/* GET List of Playbook Pdf API for Pegassure Playbook PDF */
export const getPegassurePlaybookPdfList = (params) => {
    return axios.get(API_VERSION + `playbook`, { params }, headers);
};

/* GET List of Playbook Pdf Labels API for Pegassure Playbook PDF */
export const getAllPlaybookPdfLabels = (params) => {
    return axios.get(API_VERSION + `playbook/labels`, { params }, headers);
};

/* GET Single Playbook Pdf Info API for Pegassure Playbook PDF */
export const getSinglePlaybookPdfInfo = (id) => {
    return axios.get(API_VERSION + `playbook/${id}`, headers);
};

/* GET All Logbook areas list occupied with Playbook PDF API for Pegassure Playbook PDF */
export const getAllLogbookAreasOccupiedWithPlaybook = (id) => {
    return axios.get(API_VERSION + `playbook/logbooks/${id}`, headers);
};

/* GET Playbook PDF API info by logbook area */
export const getPlaybookInfoByLogNumber = (logNumber) => {
    return axios.get(API_VERSION + `accounts/logbook/${logNumber}`, headers);
};