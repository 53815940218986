import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { getFormValues, reduxForm } from 'redux-form';
import { Input } from '../../../../UI/InputElement/InputElement';
import isEmpty from '../../../../../../utility/IsEmptyValidator';
import { getConvertTo12Hours } from '../../../../../../utility/utility';
import { DateTimeField } from '../../../../UI/DateTimeField/DateTimeField';
import { newShiftFormValidator as validate } from '../../../../../../utility/validator/validator';
import ShiftFormSkeleton from './ShiftFormSkeleton';
import ShiftOverriddenWarningModal from './ShiftOverriddenWarningComponent/ShiftOverriddenWarningModal';
import moment from "moment";
import cloneDeep from 'clone-deep';

// import $ from 'jquery';
// $(document).ready(function() {
//     $('#').trigger('click');
// });

let NewShiftForm = (props) => {
    let fetchedJobAddressStorage = [];
    const [selectedJobsAddressList, setSelectedJobsAddressList] = useState([]);
    let flUser = null;
    if (props && props.history && props.history.location && props.history.location.state && props.history.location.state.frontlineUser) {
        flUser = props.history.location.state.frontlineUser
    }

    useEffect(() => {
        if (props.isEditFLShiftForm && props.isEditFLShiftForm == true) {

            if (props.jobAccountSitesList && props.jobAccountSitesList.length > 0) {
                props.jobAccountSitesList && props.jobAccountSitesList.reduce((acc, curr, index) => {

                    props.formStates && props.formStates.shiftOrigin && props.formStates.shiftOrigin.accountIds && props.formStates.shiftOrigin.accountIds.map((e) => {
                        if (e.value == curr.id) {
                            fetchedJobAddressStorage.push(curr);
                        }
                    })

                    return acc;

                }, [])
                setSelectedJobsAddressList(fetchedJobAddressStorage);
            }

        }
    }, [props.jobAccountSitesList])

    const getFrontlineUsersList = (frontlineUsersList) => {
        if (props.frontlineUsersList && props.frontlineUsersList.length > 0) {
            return frontlineUsersList && frontlineUsersList.map((data) => {
                return {
                    value: data.id,
                    label: data.first_name + ' ' + data.last_name,

                }
            })
        } else {
            return []
        }
    };

    const getJobAccountSitesList = (jobAccountSitesList) => {
        if (props.jobAccountSitesList && props.jobAccountSitesList.length > 0) {
            return jobAccountSitesList && jobAccountSitesList.map((data) => {
                return {
                    value: data.id,
                    label: data.name,
                    address: data.address

                }
            })
        } else {
            return []
        }
    };

    const [frontlineUsersList, setFrontlineUsersList] = useState(getFrontlineUsersList(props.frontlineUsersList));
    const [jobSitesList, setJobSitesList] = useState(getJobAccountSitesList(props.jobAccountSitesList));

    useEffect(() => {
        setFrontlineUsersList(getFrontlineUsersList(props.frontlineUsersList))
    }, [props.frontlineUsersList]);

    useEffect(() => {
        setJobSitesList(getJobAccountSitesList(props.jobAccountSitesList))
    }, [props.jobAccountSitesList]);

    const noOptionsMessage = (a, b) => {
        return 'No options';
    }

    const { handleSubmit, pristine, reset, submitting, error, submitFailed } = props;

    if (props.isUpdateFLShiftForm) {
        return (
            <ShiftFormSkeleton />
        )
    } else {
        return (
            <>
                <main className="app-content wraper_content user_newQuote add_account_modal add_New_Accont add_newAccount">
                    <div className="inspection_content_sec appcontent_Inner">
                        <div className="container-fluids">
                            <div className="quote_form ticket_form" style={{ maxWidth: '700px' }}>
                                <div className="row">
                                    <div className="col-sm-12 text-center"> <h2 className="md_title" >{props.isEditFLShiftForm ? "Update Shift" : "New Shift"}</h2></div>
                                </div>
                                <div className="addQuote_form">
                                    <form className="form_content" onSubmit={handleSubmit} >
                                        <div className="cst_tab_content">

                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label className="form_title fw-bold"> Frontline User <sup className="cst_sup_txt text_danger">*</sup></label>
                                                        {flUser && flUser.id ? (
                                                            <input type="text" className="form-control input-modifier" placeholder={flUser.first_name + ' ' + flUser.last_name} disabled />
                                                        ) : (
                                                            <Input name="shiftOrigin[user_id]" dataTest="select_leave_type" type="inputSelect" options={frontlineUsersList} onChange={(flUser) => { props.getSelectedFLUserJobs(flUser.value); }} placeholder="Please select" />
                                                        )}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row mb-3">
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label className="form_title fw-bold"> Job <sup className="cst_sup_txt text_danger">*</sup></label>
                                                        <Input
                                                            name="shiftOrigin[accountIds]"
                                                            isMulti={true} dataTest="select_leave_type"
                                                            noOptionsMessage={noOptionsMessage}
                                                            type="inputSelect"
                                                            onChange={(selectedJobsInfo) => {
                                                                setSelectedJobsAddressList(selectedJobsInfo);
                                                            }}
                                                            options={jobSitesList}
                                                            placeholder={jobSitesList && jobSitesList.length > 0 ? "Please select" : "To obtain job details, choose a Frontline user"}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            {selectedJobsAddressList && selectedJobsAddressList.map((job, index) => {
                                                if (job && job.address && job.address.zip.length > 0) {
                                                    return (
                                                        <>
                                                            <div className="row" key={index}>
                                                                <div className="col-md-12">
                                                                    <div className="form-group">
                                                                        <label className="form_title fw-bold"> {job.label ? job.label : job.name} </label>
                                                                        <input
                                                                            name="shiftOrigin[jobAddress]"
                                                                            dataTest=""
                                                                            type="text"
                                                                            className="form-control input_modifier"
                                                                            placeholder={`${job.address.state + ', ' + job.address.country + ', ' + job.address.zip}`}
                                                                            disabled={true}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                    );
                                                } else {
                                                    return (
                                                        <>
                                                            <div className="row" key={index}>
                                                                <div className="col-md-12">
                                                                    <div className="form-group">
                                                                        <label className="form_title fw-bold"> {job.label} </label>
                                                                        <input
                                                                            name="shiftOrigin[jobAddress]"
                                                                            dataTest=""
                                                                            type="text"
                                                                            className="form-control input_modifier"
                                                                            placeholder='No Address'
                                                                            disabled={true}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                    );
                                                }
                                            })}

                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label className="form_title fw-bold"> From Date <sup className="cst_sup_txt text_danger">*</sup></label>
                                                        <div className="input-group cst_input_group col-lg-14 pl-0">
                                                            <div style={{ width: '100%' }}>
                                                                <Input
                                                                    name="shiftOrigin[start_date]"
                                                                    type="date"
                                                                    datePickerType={"react-datepicker"}
                                                                    minDate={new Date()}
                                                                    dateFormat="MM-dd-yyyy"
                                                                    className="form-control input-modifier"
                                                                    placeholder="Select Date"
                                                                />
                                                            </div>
                                                            <div className="input-group-append cursor-pointer" style={{ float: "right", position: "absolute", right: 0, height: "45px", top: 0 }}>
                                                                <span class="input-group-text" id="basic-addon2"> <i class="fa fa-calendar"></i> </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label className="form_title fw-bold"> To Date <sup className="cst_sup_txt text_danger">*</sup></label>
                                                        <div className="input-group cst_input_group col-lg-14 pl-0">
                                                            <div style={{ width: '100%' }}>
                                                                <Input
                                                                    name="shiftOrigin[end_date]"
                                                                    type="date"
                                                                    datePickerType={"react-datepicker"}
                                                                    minDate={props && props.formStates && props.formStates.shiftOrigin && props.formStates.shiftOrigin.start_date ? new Date(props && props.formStates && props.formStates.shiftOrigin && props.formStates.shiftOrigin.start_date) : new Date()}
                                                                    dateFormat="MM-dd-yyyy"
                                                                    className="form-control input-modifier"
                                                                    placeholder="Select Date"
                                                                />
                                                            </div>
                                                            <div className="input-group-append cursor-pointer" style={{ float: "right", position: "absolute", right: 0, height: "45px", top: 0 }}>
                                                                <span class="input-group-text" id="basic-addon2"> <i class="fa fa-calendar"></i> </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label className="form_title fw-bold"> Start Time <sup className="cst_sup_txt text_danger">*</sup></label>
                                                        <DateTimeField name="shiftOrigin[start_time]" timeConstraints={{ minutes: { step: 5 } }} datePickerType='react-datetime' pickerType="timePicker" dataFormat="hh:mm A" className="form-control Input-modifier" id="startTime" />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label className="form_title fw-bold"> End Time <sup className="cst_sup_txt text_danger">*</sup></label>
                                                        <DateTimeField name="shiftOrigin[end_time]" timeConstraints={{ minutes: { step: 5 } }} datePickerType='react-datetime' pickerType="timePicker" dataFormat="hh:mm A" className="form-control Input-modifier" id="endTime" />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row mb-3">
                                                <div className="col-md-12">
                                                    <div className="group">
                                                        <label className="form_title fw-bold"> Notes <sup className="cst_sup_txt text_danger">*</sup></label>
                                                        <Input name="shiftOrigin[notes]" type="textarea" className="form-control textarea_modifier" rows="5" placeholder="Add your notes" />
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="modal-footer">
                                            <button data-test="submit_button" type="submit" disabled={props.isLoading} className={props.isLoading ? "btn cst_btn btn_danger btn-wait w-25" : "btn cst_btn btn_danger w-25"} >{props.isLoading ? "" : "Submit"}</button>
                                            <button data-test="cancel_button" type="button" className="btn cst_btn btn-outline-secondary cancel_btnnew w-25" onClick={() => { props.onCancelClicked() }}>Cancel</button>
                                            <button
                                                type="button"
                                                className="d-none shiftOverriddenWarningModal"
                                                data-toggle="modal"
                                                data-target="#shiftOverriddenWarningModal"
                                                data-backdrop="static"
                                                data-keyboard="false"
                                                onClick={() => { props.handleFrequentTimeTracker_openModal(); }}
                                            />
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </main >

                {props.isTimeTrackerModalOpen ? (
                    <ShiftOverriddenWarningModal {...props} />
                ) : null}
            </>
        );
    }

};

const mapStateToProps = (state, props) => {

    let shiftOrigin = {}

    const getAccountIdsInfo = (accounts) => {
        return accounts && accounts.map((res) => ({
            value: res.id,
            label: res.name,
            address: res.address,
        }));
    };

    const getAssignedFLUserInfo = (fluser) => {
        return {
            value: fluser.id,
            label: fluser.first_name + ' ' + fluser.last_name,
        }
    };

    if (isEmpty(state.TimeTrackerModReducer.getShiftDetailOfSingleFLUser)) {
        let flUser = null;
        if (props && props.history && props.history.location && props.history.location.state && props.history.location.state.frontlineUser) {
            flUser = props.history.location.state.frontlineUser
        }
        shiftOrigin = {
            accountIds: [],
            user_id: flUser && flUser.id ? getAssignedFLUserInfo(flUser) : '',
            start_date: '',
            end_date: '',
            start_time: '',
            end_time: '',
            notes: '',
        }
    } else {
        const value = cloneDeep(state.TimeTrackerModReducer.getShiftDetailOfSingleFLUser);

        shiftOrigin = {
            accountIds: value && value.accounts && value.accounts.length > 0 ? getAccountIdsInfo(value.accounts) : [],
            user_id: value && value.assignedTo && value.assignedTo.id ? getAssignedFLUserInfo(value.assignedTo) : '',
            start_date: value && value.start_date && value.start_date.length > 0 ? new Date(value.start_date) : '',
            end_date: value && value.end_date && value.end_date.length > 0 ? new Date(value.end_date) : '',
            start_time: value && value.start_time && value.start_time.length > 0 ? getConvertTo12Hours(value.start_time) : '',
            end_time: value && value.end_time && value.end_time.length > 0 ? getConvertTo12Hours(value.end_time) : '',
            notes: value && value.notes && value.notes.length > 0 ? value.notes : '',
        }
    }

    return {
        initialValues: { shiftOrigin },
        formStates: getFormValues('newShiftForm')(state)
    }

}

NewShiftForm = reduxForm({
    form: 'newShiftForm',
    validate,
    enableReinitialize: true
})(NewShiftForm);

NewShiftForm = connect(mapStateToProps)(NewShiftForm)

export default NewShiftForm;