import React from 'react';
import { Input } from '../../UI/InputElement/InputElement';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import BoxTable from './boxTable';

let Box = (props) => {

  const { handleSubmit, pristine, reset, submitting, error } = props;
  return (
    <>
      <main className="app-content wraper_content inspection_wraper">
        <div className="tab_header search_filter_cont inspection_tab_content appcontent_inner frontlines_filter mb-2">
          <form onSubmit={handleSubmit} >
            <div className="row">
              <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
                <div className="app-search">
                  <Input className="fil_search_input input-modifier" name="name" type="search" placeholder="Search by Box Name" dataTest="frontline_name_filter"/>
                  <button className="app-search__button">
                    <i class="fa fa-search" aria-hidden="true"></i>
                  </button>
                </div>
              </div>
              <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
                <div className="app-search">
                  <Input className="fil_search_input input-modifier" name="id" type="search" placeholder="Search by Box Number" dataTest="frontline_id_filter" />
                  <button className="app-search__button">
                    <i class="fa fa-search" aria-hidden="true"></i>
                  </button>
                </div>
              </div>
              <div className="col-xl-5 col-lg-3 col-md-6 col-sm-6 col-12 text-right">
                <div className="frontline_btn">
                <button data-test="filter_go_btn" style={{ minWidth: '90px' }} className={"btn cst_btn btn_danger mr-2"} id="submit" type="submit">{"Go"} </button>
                <button data-test="filter_reset_btn" style={{ minWidth: '90px' }} onClick={props.resetClicked} className={"btn cst_btn btn-outline-secondary"} id="reset" type="reset">{"Reset"} </button>
              </div>
              </div>
            </div>
          </form>
        </div>
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 text-left ">
        <button data-test="import_csv" type="button" onClick={props.newBoxClicked} className="btn cst_btn btn_danger float-right">Add Box</button>
      </div>
        <section className="account_sec user_screen inspection_tab_content">

          <div className="quotes_table_content table-responsive">

            <div className="table quotes_table user_table_contnet user_react_table account_table">
              <BoxTable
                {...props}
                isLoading={props.isLoading}
                handleBoxClickDeleteModel={props.handleBoxClickDeleteModel}
                 />
            </div>

          </div>
        </section>
      </main>

      <Dialog
        open={props.openBoxDeleteModel}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title" style={{ fontSize: '13', color: '#06080f' }}>
          <h3 style={{ color: '#ab385e' }}>Are you sure?</h3>
        </DialogTitle>

        <DialogActions>
          <button style={{ minHeight: '36px', minWidth: '80px' }} onClick={() => props.deleteBox(props.deleteBoxObject.id)} disabled={props.isLoading} className={props.isLoading ? "btn cst_btn btn_danger mr-2 btn-wait" : "btn cst_btn btn_danger mr-2"} id="delete" type="delete">{props.isLoading ? "" : "Delete"}</button>
          <button style={{ minHeight: '36px', minWidth: '80px' }} onClick={props.handleBoxDeleteClose} className={"btn cst_btn btn-outline-secondary"} id="cancel" type="cancel">Cancel</button>
        </DialogActions>
      </Dialog>

    </>
  )
}

const mapStateToProps = (state) => {
  let name = "";
  let id = "";
  return { initialValues: { name,id } }
}

Box = reduxForm({
  form: 'searchBoxListForm',
  enableReinitialize: true
})(Box);

Box = connect(mapStateToProps)(Box)

export default Box;