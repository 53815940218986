import { formAuditTagModuleActionTypes } from './TagModuleActionType';
import * as API from '../../../../API/FormAuditAPI/tagModuleAPI';
import { routes } from '../../../../utility/constants/constants';
import SuccessAlert from '../../../../utility/successAlert/successAlert';
import history from '../../../../router/history';
// import * as APIV from '../../../API/formAuditAPI';



export const getSingleRequestFormDetails = (id) => {
    return (dispatch, getState) => {
        
        dispatch({
            type: formAuditTagModuleActionTypes.GET_SINGLE_REQUESTFORM_DETAILS,
            payload: API.getSingleRequestFormDetails(id) //146
                .then((response) => {
                    
                    if (response.data.success) {
                        // we need to return data here 
                         
                        return response.data.data
                        
                    };
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};



export const addRequestFormDetails = (body) => {
  return (dispatch, getState) => {
      
      dispatch({
          type: formAuditTagModuleActionTypes.ADD_SINGLE_REQUESTFORM_DETAILS,
          payload: API.addRequestFormDetailsAPI(body) 
              .then((response) => {
                
                  if (response.data.success) {
                      // we need to return data here 
                      SuccessAlert('Request Form Created Succesfully')
                      history.push(routes.REQUSET_FORM);
                      return response.data;
                      
                  };
              })
              .catch((error) => {
                  console.log(error);
              }),
      });
  };
};

export const editRequestFormDetails = (body) => {
  return (dispatch, getState) => {
      
      dispatch({
          type: formAuditTagModuleActionTypes.EDIT_SINGLE_REQUESTFORM_DETAILS,
          payload: API.editRequserFormDetailsAPI(body) 
              .then((response) => {
                   
                  if (response.data.success) {
                      // we need to return data here 
                      SuccessAlert('Request Form Updated Succesfully  ')
                      history.push(routes.REQUSET_FORM);
                      return response.data;
                      
                  };
              })
              .catch((error) => {
                  console.log(error);
              }),
      });
  };
};

export const getAllRequestFormDetails = (params) => {
  return (dispatch, getState) => {
      
      dispatch({
          type: formAuditTagModuleActionTypes.GET_ALL_REQUESTFORM_DETAILS,
          payload: API.getAllRequestFormDetails(params)
              .then((response) => {
                
                  if (response.data.success) {
                      return {
                          requestForm_list: response.data.data,
                          requestForm_total_pages: response.data.totalPages,
                          requestForm_no_of_rows: response.data.limit,
                          total_requestForm_count: response.data.total,
                          requestFormCurPage: params.page - 1,
                      };
                  };
              })
              .catch((error) => {
                  console.log(error);
              }),
      });
  };
};

export const requestFormPageClicked = (data) => {
  return {
      type: formAuditTagModuleActionTypes.REQUSET_FORM_PAGE_CLICKED,
      payload: data,
  };
};


