import React, { useEffect, useState } from 'react';
import { formValueSelector, getFormValues, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import LeaveListTable from './LeaveListTable';
import { Input } from '../../UI/InputElement/InputElement';
import { convertDateToPickerFormat } from '../../../../utility/utility';
import { userRoles } from '../../../../utility/constants/constants';

let LeaveListLayout = (props) => {

    const statusOptions = [
        {
            id: 'requested',
            name: 'Requested'
        },
        {
            id: 'approved',
            name: 'Approved'
        },
        {
            id: 'reject',
            name: 'Rejected'
        },
    ];

    const leaveTypeOptions = [
        {
            id: 'Vacation Time Off',
            name: 'Vacation Time Off'
        },
        {
            id: 'Paid Sick Time Off',
            name: 'Paid Sick Time Off'
        },
        {
            id: 'Time Off Without Pay',
            name: 'Time Off Without Pay'
        },
        {
            id: 'Bereavement',
            name: 'Bereavement'
        },
        {
            id: 'Non Worked Hours',
            name: 'Non Worked Hours'
        },
        {
            id: 'Workers Compensation',
            name: 'Workers Compensation'
        },
        {
            id: 'Jury Duty',
            name: 'Jury Duty'
        },
        {
            id: '22CASPSL B1 (for EHS use)',
            name: '22CASPSL B1 (for EHS use)'
        },
        {
            id: '22CASPSL B2 (for EHS use)',
            name: '22CASPSL B2 (for EHS use)'
        },
        {
            id: 'Exclusion Pay (for EHS use)',
            name: 'Exclusion Pay (for EHS use)'
        },
    ];

    const { handleSubmit, pristine, reset, submitting, error } = props;

    return (
        <div>

            <main className="app-content wraper_content inspection_wraper">
                <div className="tab_header search_filter_cont inspection_tab_content appcontent_inner frontlines_filter">
                    <form onSubmit={handleSubmit} >
                        <div className="row mt-2">
                            <div className="col-xl-2 col-lg-4 col-md-6 col-sm-6">
                                <Input name="status" dataTest="select_ticket_status" type="select" options={statusOptions} placeholder="Search By Status" className="custom-select input-modifier add_user_select" />
                            </div>
                            <div className="col-xl-2 col-lg-4 col-md-6 col-sm-6">
                                <Input name="leaveTypes" dataTest="select_ticket_status" type="select" options={leaveTypeOptions} placeholder="Search By Leave Types" className="custom-select input-modifier add_user_select" />
                            </div>
                            <div className="col-xl-2 col-lg-4 col-md-6 col-sm-6">
                                <label>
                                    <Input name="fromDate" dateFormat="MM-dd-yyyy" placeholder="Start Date" datePickerType={"react-datepicker"} type="date" className="form-control input-modifier" />
                                    <div class="input-group-append cst_group_append" style={{ height: "37px" }}>
                                        <span class="input-group-text" id="basic-addon2"><i class="fa fa-calendar"></i></span>
                                    </div>
                                </label>
                            </div>
                            <div class="col-xl-2 col-lg-4 col-md-6 col-sm-6">
                                <label>
                                    <Input name="toDate" minDate={props.selectedValues.fromDate ? props.selectedValues.fromDate : null} dateFormat="MM-dd-yyyy" placeholder="End Date" datePickerType={"react-datepicker"} type="date" className="form-control input-modifier" />
                                    <div class="input-group-append cst_group_append" style={{ height: "37px" }}>
                                        <span class="input-group-text" id="basic-addon2"><i class="fa fa-calendar"></i></span>
                                    </div>
                                </label>
                            </div>
                            {/* <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
                                <div className="app-search">
                                    <Input className="fil_search_input input-modifier" name="name" type="search" placeholder="Search by Items" dataTest="frontline_name_filter" />
                                    <button className="app-search__button">
                                        <i class="fa fa-search" aria-hidden="true"></i>
                                    </button>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
                                <div className="app-search">
                                    <Input className="fil_search_input input-modifier" name="manufacture" type="search" placeholder="Search by Manufacture" dataTest="frontline_id_filter" />
                                    <button className="app-search__button">
                                        <i class="fa fa-search" aria-hidden="true"></i>
                                    </button>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
                                <div className="app-search">
                                    <Input className="fil_search_input input-modifier" name="supplier" type="search" placeholder="Search by Supplier" dataTest="frontline_id_filter" />
                                    <button className="app-search__button">
                                        <i class="fa fa-search" aria-hidden="true"></i>
                                    </button>
                                </div>
                            </div>
                            <div class="col-xl-2 col-lg-4 col-md-6 col-sm-6">
                                <label>
                                    <Input name="expiration_date" minDate={new Date()} dateFormat="MM-dd-yyyy" placeholder="Expiration Date" datePickerType={"react-datepicker"} type="date" className="form-control input-modifier" />
                                    <div class="input-group-append cst_group_append">
                                        <span class="input-group-text" id="basic-addon2"><i class="fa fa-calendar"></i></span>
                                    </div>
                                </label>
                            </div> */}
                            <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
                                <div className="frontline_btn">
                                    <button data-test="filter_go_btn" style={{ minWidth: '90px' }} className={"btn cst_btn btn_danger mr-2"} id="submit" type="submit">{"Go"} </button>
                                    <button data-test="filter_reset_btn" style={{ minWidth: '90px' }} onClick={props.resetClicked} className={"btn cst_btn btn-outline-secondary"} id="reset" type="reset">{"Reset"} </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <section className="account_sec inspection_tab_content appcontent_Inner">

                    {props.user.role === userRoles.FRONTLINE && (
                        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px', marginBottom: '20px' }}>
                            <div className="row"> <div className="col-xl-6 col-lg-4 col-md-4 col-sm-12">
                                <button className='btn cst_btn btn_danger' onClick={() => { props.addLeaveRequestFormClicked() }}> Request Leave </button>
                            </div> </div>
                        </div>
                    )}

                    <div className="quotes_table_content accounts_table_contnet table-responsive">
                        <div className="table quotes_table user_react_table">
                            <LeaveListTable
                                {...props}
                            />
                        </div>
                    </div>

                </section>
            </main>
            <Dialog
                open={props.openFrequentTimeTracker_deleteModal}
                onClose={props.handleFrequentTimeTracker_closeDeleteModal}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title" style={{ fontSize: '13', color: '#06080f' }}>
                    <h3 style={{ color: '#ab385e' }} data-test="modal_delete_title">Are you sure?</h3>
                </DialogTitle>

                <DialogActions>
                    <button data-test="modal_delete" style={{ minHeight: '36px', minWidth: '80px' }} onClick={() => props.deleteLeave(props.deleteFrequentTimeTracker_object)} disabled={props.isLoading} className={props.isLoading ? "btn cst_btn btn_danger mr-2 btn-wait" : "btn cst_btn btn_danger mr-2"} id="delete" type="delete">{props.isLoading ? "" : "Delete"}</button>
                    <button data-test="modal_cancel" style={{ minHeight: '36px', minWidth: '80px' }} onClick={props.handleFrequentTimeTracker_closeDeleteModal} className={"btn cst_btn btn-outline-secondary"} id="cancel" type="cancel">Cancel</button>
                </DialogActions>
            </Dialog>

        </div>
    );
};

const mapStateToProps = (state) => {
    let status = state.TimeTrackerModReducer.leavesList_searchValues && state.TimeTrackerModReducer.leavesList_searchValues.status ? state.TimeTrackerModReducer.leavesList_searchValues.status : "";
    let leaveTypes = state.TimeTrackerModReducer.leavesList_searchValues && state.TimeTrackerModReducer.leavesList_searchValues.leaveTypes ? state.TimeTrackerModReducer.leavesList_searchValues.leaveTypes : "";
    let fromDate = state.TimeTrackerModReducer.leavesList_searchValues && state.TimeTrackerModReducer.leavesList_searchValues.fromDate && state.TimeTrackerModReducer.leavesList_searchValues.fromDate ? convertDateToPickerFormat(state.TimeTrackerModReducer.leavesList_searchValues.fromDate) : "";
    let toDate = state.TimeTrackerModReducer.leavesList_searchValues && state.TimeTrackerModReducer.leavesList_searchValues.toDate && state.TimeTrackerModReducer.leavesList_searchValues.toDate ? convertDateToPickerFormat(state.TimeTrackerModReducer.leavesList_searchValues.toDate) : "";

    return { initialValues: { status, leaveTypes, fromDate, toDate } }
}

LeaveListLayout = reduxForm({
    form: 'searchLeaveList',
    // validate,
    enableReinitialize: true
})(LeaveListLayout);

const selector = formValueSelector('searchLeaveList')
LeaveListLayout = connect(state => {
    const { fromDate, toDate } = selector(state, 'fromDate', 'toDate')
    const selectedValues = { fromDate, toDate }
    return {
        selectedValues
    }
})(LeaveListLayout)

LeaveListLayout = connect(mapStateToProps)(LeaveListLayout)

export default LeaveListLayout;