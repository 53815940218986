import React, { Component } from 'react';
import { connect } from 'react-redux';
import { decode, encode } from 'base64-arraybuffer';
import imageCompression from 'browser-image-compression';
import * as actions from '../../../../../redux/actions/index';
import PreviewLeaveRequestComponent from '../../../../component/TimeTrackerModuleComponent/LeavesTrackComponent/PreviewLeaveRequestComponent/PreviewLeaveRequestComponent';
import { animateScroll as scroll } from 'react-scroll'
import { reset } from 'redux-form';

const cloneDeep = require('clone-deep');

class PreviewLeaveRequestContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            previewFileQuery: {
                previewOpen: false,
                previewTitle: '',
                previewImage: '',
            },
            isLoad: false,
        }

    }

    componentDidMount() {

        if (this.props && this.props.match && this.props.match.params && this.props.match.params.leaveId) {
            this.props.getSingleLeaveRequest(this.props.match.params.leaveId);
        }
        this.setState({isLoad: true});
        setTimeout(() => {
            this.setState({isLoad: false});
        }, 1000);
        scroll.scrollToTop();

    }

    componentWillUnmount() {
        this.props.resetTimeTrackerObjects();
    }

    handleFilePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
                reader.onerror = (error) => reject(error);
            });
        }
        this.setState({
            previewFileQuery: {
                previewOpen: true,
                previewImage: file.url || file.preview,
                previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
            }
        });
    }

    handlePreviewCancel = () => this.setState({ previewFileQuery: { previewOpen: false } });

    handleLeaveRequestSanction = (values) => {
        let setData = { data: {} };
        let fetchedValue = cloneDeep(values);
        if (fetchedValue) {

            if (fetchedValue.status == 'approved') {

                setData.data.status = values.status;

            } else if (fetchedValue.rejectMod && fetchedValue.rejectMod.status == 'reject') {

                setData.data.status = fetchedValue.rejectMod.status;
                setData.data.reject_reason = fetchedValue.rejectMod.reject_reason

            }

            this.props.getCloseLeaveRequest(setData, this.props.match.params.leaveId);

        }

    }

    handleFrequentTimeTracker_closeModal = () => {
        this.props.handleFrequentTimeTracker_closeModal();
        this.props.reset('leaveRequestRejectionForm');
    }

    render() {
        return (
            <PreviewLeaveRequestComponent
                {...this.props}
                handleFrequentTimeTracker_closeModal={this.handleFrequentTimeTracker_closeModal}
                handleLeaveRequestSanction={this.handleLeaveRequestSanction}
                isLoad={this.state.isLoad}
            />
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.authReducer.user,
        isLoading: state.TimeTrackerModReducer.isLoading,
        isTimeTrackerModalOpen: state.TimeTrackerModReducer.isTimeTrackerModalOpen,
        singleLeaveRequestData: state.TimeTrackerModReducer.singleLeaveRequestData,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        reset: (leaveRequestRejectionForm) => dispatch(reset(leaveRequestRejectionForm)),  // requires form name
        resetTimeTrackerObjects: () => dispatch(actions.resetTimeTrackerObjects()),
        handleFrequentTimeTracker_openModal: (data) => dispatch(actions.handleFrequentTimeTracker_openModal(data)),
        handleFrequentTimeTracker_closeModal: (data) => dispatch(actions.handleFrequentTimeTracker_closeModal(data)),

        getSingleLeaveRequest: (id) => dispatch(actions.getSingleLeaveRequest(id)),
        getCloseLeaveRequest: (request, id) => dispatch(actions.getCloseLeaveRequest(request, id)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PreviewLeaveRequestContainer);