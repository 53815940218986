import React, { useEffect, useState } from 'react'
import { Input } from '../../../../UI/InputElement/reactFormHookInputField'
import { useSelector } from 'react-redux'
import { useWatch } from 'react-hook-form';

function ChecklistLogForm(props) {
  const { managersList } = useSelector((state) => state.FormAuditModReducer)

  const getManagersList = (manager) => {
    if (managersList && managersList.length > 0) {
      return manager && manager.map((data) => {
        return {
          value: data.id,
          label: data.first_name + ' ' + data.last_name,
        }
      })
    } else {
      return []
    }
  };
  const [managerList, setManagerList] = useState(getManagersList(managersList));

  useEffect(() => {
    setManagerList(getManagersList(managersList))
  }, [managersList])

  const watchFieldCompleted=useWatch({
    control:props.control,
    name:`data.${props.index}.tc_issued`
  })

  const watchFieldTotalCompleted=useWatch({
    control:props.control,
    name:`data.${props.index}.tc_returned`
  })

  

  const getPercentage=()=>{
    
    const tc_issued=props.getValues(`data.${props.index}.tc_issued`)
    const tc_returned=props.getValues(`data.${props.index}.tc_returned`)
    
    if(tc_issued && tc_returned){
        const percentage = (+tc_returned / +tc_issued) * 100
        const FinalPercentage = percentage.toFixed(0) + "%"
        props.setValue(`data.${props.index}.returned`,FinalPercentage)
        return
    }
    else return ""
  }

  return (
    <div class="tm_form_wrap" key={props.key}>
      <div class="tm_form_innner">
        <div class="form">
          <div class="row">
            <div class="col-md-6 col-sm-12">
              <div class="form-group">
                <Input
                  type="inputSelect"
                  labelClasses="form-label"
                  control={props.control}
                  placeholder={'Please Select'}
                  controller={props.controller}
                  selectLabel="Manager / Assistant Manager"
                  selectName={`data.${props.index}.manager_id`}
                  id={`data.${props.index}.manager_id`}
                  selectOptions={managerList}
                  required={props.register(`data.${props.index}.manager_id`, {
                    required: "This field is required",
                    maxLength: 80,
                  })}
                  validationErrorMessage={
                    props.errors?.data?.[props.index]?.manager_id
                      ?.message ?? ""
                  }
                  selectClasses="form-select"
                  disabled={props?.isView}
                />
              </div>
            </div>
            <div class="col-md-6 col-sm-12">
              <div class="form-group">
                <label for="tc_issued" class="form-label">
                  #Tc Issued
                  <span class="label_tick">
                    <img
                      src="/images/icons/labeltick.png"
                      alt="labeltick"
                    />
                  </span>
                </label>
                <Input
                  type="number"
                  formControlClasses="form-control"
                  placeholder={'Type here'}
                  formControlProps={props.register(
                    `data.${props.index}.tc_issued`,
                    {
                      required: "This field is required",
                      maxLength: 80,
                    }
                  )}
                  validationErrorMessage={
                    props.errors?.data?.[props.index]?.tc_issued
                      ?.message ?? ""
                  }
                  disabled={props?.isView}
                />
              </div>
            </div>
            <div class="col-md-6 col-sm-12">
              <div class="form-group mb-0">
                <label for="tc_returned" class="form-label">
                  #Tc Returned
                  <span class="label_tick">
                    <img
                      src="/images/icons/labeltick.png"
                      alt="labeltick"
                    />
                  </span>
                </label>
                <Input
                  type="number"
                  formControlClasses="form-control"
                  placeholder={'Type here'}
                  formControlProps={props.register(
                    `data.${props.index}.tc_returned`,
                    {
                      required: "This field is required",
                      maxLength: 80,
                    }
                  )}
                  validationErrorMessage={
                    props.errors?.data?.[props.index]?.tc_returned
                      ?.message ?? ""
                  }
                  disabled={props?.isView}
                />
              </div>
            </div>
            <div class="col-md-6 col-sm-12">
              <div class="form-group mb-0">
                <label for="returned" class="form-label">
                  % Returned
                </label>
                <Input
                  type="text"
                  value={getPercentage()}
                  formControlClasses="form-control"
                  formControlProps={props.register(
                    `data.${props.index}.returned`,
                    {
                      maxLength: 80,
                    }
                  )}
                  disabled={true}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {
        props?.index > 0 ?
          <div class="col-md-12 col-sm-12" >
            <div class="action_button pt-3">
              <span onClick={props.removeFromTaskList(props.index)}>
              <img src='/images/icons/trash.svg' />
              </span>
            </div>
          </div> : null
      }
    </div>
  )
}

export default ChecklistLogForm