import React, { useEffect, useState } from 'react';
import TicketTable from './ticketTable';
// import PerformInspectionModal from './performInspectionModal';
import { Input } from '../UI/InputElement/InputElement';
import { formValueSelector, getFormValues, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { TicketSearchValidator as validate } from '../../../utility/validator/validator';
import Dialog from '@material-ui/core/Dialog';
import { convertAccountsDataToCsvType, convertDateToPickerFormat, convertQuotesDataToCsvType } from '../../../utility/utility';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import { CSVLink, CSVDownload } from "react-csv";
import { userRoles } from '../../../utility/constants/constants';
import { searchTicket, searchMyTicket } from '../../../redux/actions';

const getCategoriesDropDown = (categories) => {

    return categories && categories.map(a => {
        return {
            value: a.category.id,
            label: a.category.name
        }
    })
}

let Ticket = (props) => {
    let ticketFormValues = props.formStates
    const { handleSubmit, pristine, reset, submitting, error } = props;
    const [categoriesList, setCategoriesListList] = useState(getCategoriesDropDown(props.categoriesList));

    useEffect(() => {
        setCategoriesListList(getCategoriesDropDown(props.categoriesList))
    }, [props.categoriesList])

    const statusOptions = [
        {
            id: 'Open',
            name: 'Open'
        }, {
            id: 'resolved',
            name: 'Resolved'
        } , {
            id: 'canceled',
            name: 'Canceled'
        }
    ];

    const noCategoriesOptionsMessage = (a, b) => {
        return 'Category not found';
    }

    return (
        <>
            <main className="app-content wraper_content inspection_wraper">
                <div className="tab_header search_filter_cont inspection_tab_content ticket_filter">
                    <form onSubmit={handleSubmit}>
                        <input type="hidden" value="something" />
                        <div className="row">
                            <div className="col-xl-2 col-lg-4 col-md-6 col-sm-6">
                                <div className="app-search">
                                    <Input dataTest="search_by_description" name="id" className="fil_search_input" type="number" placeholder="ID" />
                                    <button className="app-search__button">
                                        <i class="fa fa-search" aria-hidden="true"></i>
                                    </button>
                                </div>
                            </div>
                            <div className="col-xl-2 col-lg-4 col-md-6 col-sm-6">
                                <div className="app-search">
                                    <Input name="subject" className="fil_search_input" type="search" placeholder="Subject" />
                                    <button className="app-search__button">
                                        <i class="fa fa-search" aria-hidden="true"></i>
                                    </button>
                                </div>
                            </div>
                            <div className="col-xl-2 col-lg-4 col-md-6 col-sm-6">
                                <div className="app-search">
                                    <Input dataTest="search_by_description" name="description" className="fil_search_input" type="search" placeholder="Description" />
                                    <button className="app-search__button">
                                        <i class="fa fa-search" aria-hidden="true"></i>
                                    </button>
                                </div>
                            </div>
                            <div className="col-xl-2 col-lg-4 col-md-6 col-sm-6">
                                <Input name="status" dataTest="select_ticket_status" type="select" options={statusOptions} placeholder="Search By Status" className="custom-select input-modifier add_user_select" />

                                {/* <select className="custom-select input-modifier">
                                <option value="All">All</option>
                                <option value="Pending for Approva">Pending for Approval</option>
                                <option value="Sent to Clients">Sent to Clients</option>
                                <option value="Approved by Clients">Approved by Clients</option>
                            </select> */}
                            </div>
                            <div class="more_filter_block">
                                    <button type="button" className="btn btn-link more_filter_btn collapsed" data-toggle="collapse" data-target="#morefilterBtn" aria-expanded="true" aria-controls="morefilterBtn">
                                        More Filters
                                    </button>
                                </div>
                            {props.user.role === userRoles.REQUESTOR || props.fromDashboard ? null :
                                <div className="col-xl-2 col-lg-6 col-sm-3 col-12 text-right quotes_btn_wrap">
                                    <a onClick={props.newTicketClicked} href="javascript:void (0)" className="btn btn-secondary cst_btn" data-toggle="modal" data-backdrop="static" data-keyboard="false" data-target="#addAccountModal" data-test="add_new_ticket"><i className="fa fa-plus icn_plus"></i>New</a>
                                </div>
                            }
                            </div>
                            <div id="morefilterBtn" className="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                            <div className="row" style={{marginTop: '10px'}}>
                            <div className="col-xl-2 col-lg-4 col-md-6 col-sm-6">
                                <div className="app-search">
                                    <Input dataTest="search_by_description" name="job" className="fil_search_input" type="search" placeholder="Job" />
                                    <button className="app-search__button">
                                        <i class="fa fa-search" aria-hidden="true"></i>
                                    </button>
                                </div> 
                            </div>
                            <div className="col-xl-2 col-lg-4 col-md-6 col-sm-6">
                                <div className="app-search">
                                    <Input dataTest="search_by_description" name="created_by" className="fil_search_input" type="search" placeholder="Created By" />
                                    <button className="app-search__button">
                                        <i class="fa fa-search" aria-hidden="true"></i>
                                    </button>
                                </div>
                            </div>
                            <div className="col-xl-2 col-lg-4 col-md-6 col-sm-6">
                                <div className="app-search">
                                    <Input dataTest="search_by_description" name="assigned_to" className="fil_search_input" type="search" placeholder="Assigned To" />
                                    <button className="app-search__button">
                                        <i class="fa fa-search" aria-hidden="true"></i>
                                    </button>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                                {/* <Input name="status" dataTest="select_ticket_status" type="inputSelect" options={categoriesList} placeholder="Search By Categories" className="custom-select input-modifier add_user_select" /> */}
                                <Input name="category" noOptionsMessage={noCategoriesOptionsMessage} placeholder="Category" type="inputSelect" options={categoriesList} />

                            </div>
                            </div>
                            </div>
                            
                            {props.fromDashboard ? null : <div className='row' style={{marginTop: '10px'}}>
                        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                            <label>
                                <Input name="fromDate" maxDate={props.selectedValues.toDate ? props.selectedValues.toDate : new Date()} dateFormat="MM-dd-yyyy" placeholder="From Date" datePickerType={"react-datepicker"} type="date" className="form-control input-modifier" />
                                <div class="input-group-append cst_group_append">
                                    <span class="input-group-text" id="basic-addon2"><i class="fa fa-calendar"></i></span>
                                </div>
                            </label>
                        </div>
                        <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                            <label>
                                <Input name="toDate" minDate={props.selectedValues.fromDate ? props.selectedValues.fromDate : null} maxDate={new Date()} dateFormat="MM-dd-yyyy" placeholder="To Date" datePickerType={"react-datepicker"} type="date" className="form-control input-modifier" />
                                <div class="input-group-append cst_group_append">
                                    <span class="input-group-text" id="basic-addon2"><i class="fa fa-calendar"></i></span>
                                </div>
                            </label>
                        </div>

                        <div className="col-xl-2 col-lg-6 col-sm-3 col-12">
                                    <button data-test="filter_go_btn" style={{ minWidth: '90px' }} className={"btn cst_btn btn_danger mr-2  account_btn"} id="submit" type="submit">{"Go"} </button>
                                    <button data-test="filter_reset_btn" style={{ minWidth: '90px' }} onClick={props.resetClicked} className={"btn cst_btn btn-outline-secondary account_btn"} id="reset" type="reset">{"Reset"}</button>
                            </div>
                        </div>}
                        <div className="more_filter_content">
                            <div id="morefilterBtn" className="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                                <div className="card-body">
                                    <div className="row">
                                        {/* <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                                            <div className="app-search">
                                                <Input name="region" className="fil_search_input" type="search" placeholder="Search By Region" />
                                                <button className="app-search__button">
                                                    <i class="fa fa-search" aria-hidden="true"></i>
                                                </button>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>


                <section className="account_sec inspection_tab_content appcontent_Inner">
                    {/* <div>
                        <button style={{ float: 'right' }}>Enable Filter</button>
                    </div> */}
                    {/* <CSVLink filename="Accounts.csv" className="csv_btn btn_danger " data={convertAccountsDataToCsvType(props.accountsList)} >Export Csv</CSVLink><br /><br /><br /> */}
                    <div className="quotes_table_content accounts_table_contnet table-responsive">

                        <div className="table quotes_table user_react_table">
                            <TicketTable
                                // deleteTicket={props.deleteTicket}
                                {...props}
                                onAccountsPageSizeChange={props.onAccountsPageSizeChange}
                                ticketsList={props.ticketsList}
                                isLoading={props.isLoading}
                                previewTicketClicked={props.previewTicketClicked}
                                editTicketClicked={props.editTicketClicked}
                                openDeleteModel={props.openDeleteModel}
                                handleClickDeleteModel={props.handleClickDeleteModel}
                                accounts_rows={props.accounts_rows}
                                onTicketExportClicked={props.onTicketExportClicked}
                            />
                        </div>

                    </div>
                    {/* <div className="clearfix"></div>
                <div className="modal-footer">
                    <button type="button" className="btn btn_danger cst_btn">Save</button>
                    <button type="button" className="btn btn-outline-secondary cst_btn" data-dismiss="modal">Cancel</button>
                </div> */}
                </section>
            </main>
            <Dialog
                open={props.openDeleteModel}
                onClose={props.handleClose}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title" style={{ fontSize: '13', color: '#06080f' }}>
                    <h3 style={{ color: '#ab385e' }} data-test="modal_delete_title">Are you sure?</h3>
                </DialogTitle>

                <DialogActions>
                    <button data-test="modal_delete" style={{ minHeight: '36px', minWidth: '80px' }} onClick={() => props.deleteTicket(props.deleteObject.id)} disabled={props.isLoading} className={props.isLoading ? "btn cst_btn btn_danger mr-2 btn-wait" : "btn cst_btn btn_danger mr-2"} id="delete" type="delete">{props.isLoading ? "" : "Delete"}</button>
                    <button data-test="modal_cancel" style={{ minHeight: '36px', minWidth: '80px' }} onClick={props.handleDeleteClose} className={"btn cst_btn btn-outline-secondary"} id="cancel" type="cancel">Cancel</button>
                    {/* <Button onClick={props.handleClose} variant="contained" color="primary">
                        Cancel
                        </Button>


                    <Button onClick={() => props.deleteAccount(props.deleteObject.id)} disabled={props.isLoading} variant="contained" color="secondary">
                        {props.isLoading ? <CircularProgress size={24} /> : 'Delete'}
                    </Button> */}
                </DialogActions>
            </Dialog>
        </>

    )
}


const mapStateToProps = (state) => {
    let subject = state.adminOrSuperAdminReducer.searchTicketValues && state.adminOrSuperAdminReducer.searchTicketValues.subject ? state.adminOrSuperAdminReducer.searchTicketValues.subject : "";;
    let description = state.adminOrSuperAdminReducer.searchTicketValues && state.adminOrSuperAdminReducer.searchTicketValues.description ? state.adminOrSuperAdminReducer.searchTicketValues.description : "";
    let id = state.adminOrSuperAdminReducer.searchTicketValues && state.adminOrSuperAdminReducer.searchTicketValues.id ? state.adminOrSuperAdminReducer.searchTicketValues.id : "";
    let status = state.adminOrSuperAdminReducer.searchTicketValues && state.adminOrSuperAdminReducer.searchTicketValues.status ? state.adminOrSuperAdminReducer.searchTicketValues.status : "Open";
    let job = state.adminOrSuperAdminReducer.searchTicketValues && state.adminOrSuperAdminReducer.searchTicketValues.job ? state.adminOrSuperAdminReducer.searchTicketValues.job : "";
    let created_by = state.adminOrSuperAdminReducer.searchTicketValues && state.adminOrSuperAdminReducer.searchTicketValues.created_by ? state.adminOrSuperAdminReducer.searchTicketValues.created_by : "";
    let assigned_to = state.adminOrSuperAdminReducer.searchTicketValues && state.adminOrSuperAdminReducer.searchTicketValues.assigned_to ? state.adminOrSuperAdminReducer.searchTicketValues.assigned_to : "";
    let category = state.adminOrSuperAdminReducer.searchTicketValues && state.adminOrSuperAdminReducer.searchTicketValues.category ? state.adminOrSuperAdminReducer.searchTicketValues.category : "";
    let fromDate = state.adminOrSuperAdminReducer.searchTicketValues && state.adminOrSuperAdminReducer.searchTicketValues.fromDate ? convertDateToPickerFormat(state.adminOrSuperAdminReducer.searchTicketValues.fromDate) : "";
    let toDate = state.adminOrSuperAdminReducer.searchTicketValues &&  state.adminOrSuperAdminReducer.searchTicketValues.toDate ? convertDateToPickerFormat(state.adminOrSuperAdminReducer.searchTicketValues.toDate) : "";

    return { initialValues: { subject, status, description, id, job,fromDate, toDate, created_by, assigned_to, category }, formStates: getFormValues('searchTicket') (state) }
}

Ticket = reduxForm({
    form: 'searchTicket',
    validate,
    enableReinitialize: true
})(Ticket);
const selector = formValueSelector('searchTicket') // <-- same as form name
Ticket = connect(state => {
    const { fromDate, toDate } = selector(state, 'fromDate', 'toDate')
    const selectedValues = { fromDate, toDate }
    return {
        selectedValues
    }
}) (Ticket)
Ticket = connect(mapStateToProps)(Ticket)
export default Ticket;