import React, { Component } from 'react';
import SignIn from '../../component/SingIn/signIn';
import { connect } from 'react-redux';
import * as actions from '../../../redux/actions/index';
import { routes } from '../../../utility/constants/constants';
import storage from '../../../utility/storage';

class SignInContainer extends Component {

    constructor(props) {
        super(props);

        this.signUpClicked = this.signUpClicked.bind(this);
    }

    forgetPasswordClicked = () => {
        this.props.history.push(routes.FORGET_PASSWORD)
    }

    signUpClicked = () => {

        this.props.history.push(routes.SIGNUP)
    }

    signInClicked = (values) => {
        values.user.email = values.user.email.trim()
        values.user.password = values.user.password.trim()
        this.props.signInClicked(values);
    }

    faceRecognitionLoginClicked = () => {
        storage.set('FrontlineUser_FaceDetected', 'inProgress');
        this.props.history.push(routes.FRONTLINE_FACE_DETECTOR);
        window.location.reload(false);
    }

    render() {
        return (
            <SignIn
                onSubmit={this.signInClicked}
                signUpClicked={this.signUpClicked}
                isLoading={this.props.isLoading}
                forgetPasswordClicked={this.forgetPasswordClicked}
                faceRecognitionLoginClicked={this.faceRecognitionLoginClicked} />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        isLoading: state.authReducer.isLoading
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        signInClicked: (user) => dispatch(actions.signInClicked(user))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SignInContainer);