import React, { Component } from 'react';
import { routes } from '../../../../../utility/constants/constants';
import * as actions from '../../../../../redux/actions/index';
import { connect } from 'react-redux';
import { reset } from 'redux-form';
import { animateScroll as scroll} from 'react-scroll'
import storage from '../../../../../utility/storage';
import DailyKitting from '../../../../component/FormAuditModule/Inventory/DailyKittingPage/dailyKitting';

var moment = require('moment');
const cloneDeep = require('clone-deep');

class DailyKittingContainer extends Component {

    constructor(props) {
        super(props);

        this.state = {
            
        }
    }

    componentDidMount() {
        scroll.scrollToTop();
    }

    componentDidUpdate(prevProps, prevState) {
        const user = storage.get('user');
        if(user) {
            if(prevProps.user.selected_mode !== user.selected_mode) {
                this.props.scheduledTaskListPageClicked(0)
            }
        }
    }

    // resetClicked = () => {
    //     const page = 1
    //     const limit = this.props.scheduledTaskList_no_of_rows
    //     this.props.reset('searchScheduledTask');
    //     this.props.scheduledTaskListPageClicked(0)
    //     this.props.getScheduledTask({page,limit})

    // }

    // searchInventoryItemsListClicked = (value) => {
    //     const values = cloneDeep(value)
    //     let accountId =  null;
    //     let taskName = null;
    //     let companyName = null;
    //     let accountName = null;
    //     let from = null;
    //     let to = null;
    
    //     const page = 1
    //     const limit = this.props.scheduledTaskList_no_of_rows
    //     if(values.id) {
    //         values.id = values.id.trim()
    //         accountId = values.id
    //     }
    //   if(values.name) {
    //       values.name = values.name.trim()
    //       taskName = values.name
    //   }
    //   if(values.company) {
    //     values.company = values.company.trim()
    //     companyName = values.company
    //   }
    //   if(values.job) {
    //     values.job = values.job.trim()
    //     accountName =  values.job 
    //     }  
    //   if(values.fromDate) {
    //     values.fromDate = convertDateToDifferentTZ(values.fromDate, 'from');
    //     from = values.fromDate.split('T')[0];
    //   }  
    //   if(values.toDate) {
    //     values.toDate = convertDateToDifferentTZ(values.toDate, 'to');
    //     to = values.toDate.split('T')[0];
    //   }  
     
    //     this.props.getScheduledTask({page,limit,accountId,taskName,companyName,accountName,from,to}, values);
    // }
    newTicketClicked = () => {
        this.props.history.push(routes.NEW_DAILY_KITTING);
    }
         
    render() {
        return (
            <DailyKitting
            {...this.props}
            isLoading={this.props.isLoading}
            dailyKitting_list={this.props.dailyKitting_list ? this.props.dailyKitting_list : []}
            newTicketClicked={this.newTicketClicked}
                
            />
        )
    }
}




const mapStateToProps = (state) => {
    return {
        user: state.authReducer.user,
        isLoading: state.InventoryModuleReducer.isLoading,
        dailyKitting_list: state.InventoryModuleReducer.dailyKitting_list,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        reset: (searchInventoryItemsList) => dispatch(reset(searchInventoryItemsList)),  // requires form name
        getAllDailyKitting: (page) => dispatch(actions.getAllDailyKitting(page)),
        dailyKittingPageClicked: (params) => dispatch(actions.dailyKittingPageClicked(params)),
        scheduledTaskListPageClicked: (params) => dispatch(actions.scheduledTaskListPageClicked(params)),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(DailyKittingContainer);