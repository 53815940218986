import React, { useEffect, useState } from 'react';
import { Input } from '../../UI/InputElement/InputElement';
import GooglePlacesAutoComplete from '../../GooglePlacesAutoComplete/googlePlacesAutoComplete';
import { newAccountValidator as validate } from '../../../../utility/validator/validator';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import AccountSkeleton from './accountSkeleton';
import isEmpty from '../../../../utility/IsEmptyValidator';
import { showConfirmAlert } from '../../../../utility/successAlert/ConfirmAlert';
import * as Scroll from 'react-scroll';
import moment from "moment";
import { Timezones, UserModesWithoutAll, usTimeZones } from '../../../../utility/constants/constants';
import { getUser, makeFirstLetterCapital } from '../../../../utility/utility';
import accountDuplicateModal from './accountDuplicateModal';
import ReactTooltip from 'react-tooltip';
var scroller = Scroll.scroller;
const QuotasField = ({ quotas_attributes }) => (
    <div>
        {quotas_attributes.map((quota, index) => {
            return (
                <div key={index}>
                    <div className="col-md-8">
                        <div className="form-group">
                            <label for="email" className="label_modifier">Year</label>
                            <Input type="text" className="form-control input-modifier" placeholder="" name={`account[quotas][${index}].quota_year`} />
                        </div>
                    </div>
                    <div className="col-md-8">
                        <div className="form-group">
                            <label className="label_modifier">Amount($)</label>
                            <Input type="number" className="form-control input-modifier" placeholder="" name={`account[quotas][${index}].quota_amount`} />
                        </div>
                    </div>
                </div>
            )
        })
        }
    </div>
);

let NewAccounts = (props) => {
    const companyList = props.companyList ? props.companyList.map(company => {
        return {
            value: company.id,
            label: company.name
        }
    }) : [];

    
    console.log('props', props);
    const RegionOptions = props.regionList.map(reg => { 
        return {
            id: reg.region.id,
            name: makeFirstLetterCapital(reg.region.name)
        }
    })

    // const CompanyOptions = props.companyList.map(company => {  
    //     return {
    //         id: company.id,
    //         name: makeFirstLetterCapital(company.name)
    //     }
    // })

    
    // CompanyOptions.sort((a, b) => a.name.normalize().localeCompare(b.name.normalize()));


    const TimezoneOptions = props.timezone ? props.timezone : usTimeZones;
    
    // const TimezoneOptions = moment.tz.zonesForCountry('US');

    const Holidays = props.holidaysList ? props.holidaysList.map(h => {
        return {
            value: h.id,
            label: h.holiday_reason
        }
    }) : [];

    const noOptionsHolidayMessage = () => {
        return 'Holiday not found';
    }

    // useEffect( () => {

    // }, [props.quotas_attributes])
    const accountModeChange = (event) => {
        props.modeChange(event.target.value)
    }

    const modeOptions = 
        [{
            id: 'internal',
            name: 'Internal'}, {
        // }] : [{
            id: 'external',
            name: 'External'
        }]

    const { handleSubmit, pristine, reset, submitting, error, account, submitFailed } = props;
    if ((submitFailed) && (!props.address_attributes || (props.address_attributes && !props.address_attributes.formatted_address))) {
        props.handleAddressTouch()
    }
    return (
        <main className="app-content wraper_content user_newQuote add_account_modal add_New_Accont add_newAccount">
            <div className="inspection_content_sec appcontent_Inner">
                {(!props.regionList || props.regionList.length === 0) ?
                    <AccountSkeleton />
                    :
                    <div className="container-fluids">
                        <div className="row">
                            <div className="col-sm-12 text-center">
                                <h4 className="section_title" data-test="job_header_title">{props.isEdit ? "Update Job" : "Add a New Job"}</h4>
                            </div>
                        </div>
                        <div className="quote_form">
                            <div className="addQuote_form">
                                <form className="form_content" onSubmit={handleSubmit} >
                                    <div className="cst_tab_content">
                                        {/* <div className="profile_img_block" style={{ marginBottom: '20px' }}>
                                        <div className="circle_img user-pic">
                                            <Input hidden={true} type="file" name="photo_path" onSelect={props.onFileChange} fileRef={props.reference} accept="image/*" />
                                            { <input type="file" id="imgupload" style={{ display: 'none' }} /> }
                                            <div className="user-profile-pic"><img style={{ height: '90px', width: '90px', borderRadius: '90px' }} src={props.base64 ? props.base64 : props.s3Url ? props.s3Url : "/customImages/user.png"} /></div>
                                        </div>
                                        <div className="clearfix"></div>
                                        <div className="profile-details">
                                            <a href="javascript:void (0)" onClick={props.choosePhotoClicked}>Change profile image</a>
                                        </div> 
                                    </div>  */}

                                        {/* html@since:24-07-2019*/}
                                        <div className="profile_img_block newaccoubnt_profile">
                                            {props.isEdit ? <> <span data-tip="Create a new copy" style={{paddingTop: "25px", float: 'right'}} data-backdrop="static" data-keyboard="false" data-toggle="modal" data-target="#AccountDuplicateModal" onClick={() => props.onAccountDuplicateModalClicked()} className="icn_lock"><i className="fas fa-copy" style={{ fontSize: 'large', cursor: 'pointer' }}></i></span> 
                                    <ReactTooltip place="top" type="dark" effect="solid" />
                                        </> : null}
                                            <div className="circle_img user-pic">
                                                <Input hidden={true} type="file" name="photo_path" onSelect={props.onFileChange} fileRef={props.reference} accept="image/*" />
                                                <div className="user-profile-pic">
                                                    <img style={{ height: '90px', width: '90px', borderRadius: '90px' }} src={props.base64 ? props.base64 : props.s3Url ? props.s3Url : "/customImages/user.png"} />
                                                    <div class="icn_userEdit" onClick={props.choosePhotoClicked}><a href="javascript:void (0)"><i class="fa fa-camera" aria-hidden="true"></i></a></div>
                                                </div>
                                            </div>
                                            <div className="clearfix"></div>
                                            <div className="profile-details">
                                                {/* <a href="javascript:void (0)" onClick={props.choosePhotoClicked}>Change profile image</a> */}
                                            </div>
                                        </div>
                                        {/*End Of html@since:24-07-2019*/}
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label for="email" className="label_modifier">Job Name</label>
                                                    <Input dataTest="job_name_data" type="text" className="form-control input-modifier" placeholder="" name="account[name]" />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="label_modifier">Job Number</label>
                                                    <Input dataTest="job_number_data" type="number" className="form-control input-modifier" placeholder="" name="account[account_number]" />
                                                </div>
                                            </div>
                                        </div>

                                        {/* <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label for="email" className="label_modifier">Contact Email</label>
                                                    <Input type="email" name="account[email]" className="form-control input-modifier" placeholder="" /> */}
                                        {/* readOnly={props.isEdit ? true : false} */}
                                        {/* </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="label_modifier">Contact Phone</label>
                                                    <Input type="numberFormate" className="form-control input-modifier" placeholder="" name="account[phone_number]" />
                                                </div>
                                            </div>
                                        </div> */}
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label for="email" className="label_modifier">Street Address</label>
                                                    <GooglePlacesAutoComplete
                                                        value={props.address_attributes ? props.address_attributes.formatted_address : ''}
                                                        {...props}
                                                    />
                                                    {/* <input type="text" className="form-control input-modifier" placeholder="" /> */}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label for="email" className="label_modifier">City</label>
                                                    <input type="text" style={props.isCityEmpty ? { borderColor: 'rgb(240, 77, 83)', borderLeftWidth: '3px' } : null} onChange={props.handleInputChange}  value={props.address_attributes ? props.address_attributes.city : ''} className="form-control input-modifier" name='city' placeholder="" />
                                                    <span style={{ color: '#DD2726', fontSize: '13px' }}>{props.isCityEmpty ? 'This field is required.' : null}</span>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label className="label_modifier">State</label>
                                                    <input type="text" style={props.isStateEmpty ? { borderColor: 'rgb(240, 77, 83)', borderLeftWidth: '3px' } : null} onChange={props.handleInputChange}  value={props.address_attributes ? props.address_attributes.state : ''} className="form-control input-modifier" name='state' placeholder="" />
                                                    <span style={{ color: '#DD2726', fontSize: '13px' }}>{props.isStateEmpty ? 'This field is required.' : null}</span>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label className="label_modifier">Country</label>
                                                    <input type="text" style={props.isCountryEmpty ? { borderColor: 'rgb(240, 77, 83)', borderLeftWidth: '3px' } : null} onChange={props.handleInputChange} value={props.address_attributes ? props.address_attributes.country : ''} className="form-control input-modifier" name="country"  placeholder="" />
                                                    <span style={{ color: '#DD2726', fontSize: '13px' }}>{props.isCountryEmpty ? 'This field is required.' : null}</span>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label className="label_modifier">Zip</label>
                                                    <input data-test="job_zip_data" style={props.isZipEmpty ? { borderColor: 'rgb(240, 77, 83)', borderLeftWidth: '3px' } : null} type="text" onChange={props.handleInputChange} value={props.address_attributes ? props.address_attributes.zip : ''} className="form-control input-modifier" name="zip" placeholder="" />
                                                    <span style={{ color: '#DD2726', fontSize: '13px' }}>{props.isZipEmpty ? 'This field is required.' : null}</span>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label className="label_modifier">Region</label>
                                                    <Input dataTest="jobs_region_data" name="account[region_id]" type="select" options={RegionOptions} className="custom-select input-modifier add_user_select" />
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label className="label_modifier">Timezone</label>
                                                    <Input dataTest="job_timezone_data" name="account[timezone]" type="select" options={TimezoneOptions} className="custom-select input-modifier add_user_select" />
                                                </div>
                                            </div>
                                            {/* <div className="col-md-4">
                                                <div className="form-group">
                                                    <label className="label_modifier">Holidays</label>
                                                    <Input name="holiday_ids" isMulti={true} noOptionsMessage={noOptionsHolidayMessage} type="inputSelect" options={Holidays} />
                                                </div>
                                            </div> */}
                                        </div>
                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label className="form_title">Status</label>

                                                    <Input type="radio" labelClass="custom-control-label" forLable={"customRadio1"} value={"active"} label={"Active"} className="custom-control-input" id="customRadio1" name="account[active_status]" />

                                                    <Input type="radio" labelClass="custom-control-label" forLable={"customRadio2"} value={"inactive"} label={"Inactive"} className="custom-control-input" id="customRadio2" name="account[active_status]" />

                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group select-disable-icon">
                                                    <label className="form_title">Mode</label>
                                                    <Input name="account[mode]" type="select" options={modeOptions} disabled onChange={($event) => accountModeChange($event)} className="custom-select input-modifier " />
                                                    {/* <Input tbype="text" name="account[mode]" style={{ backgroundColor: '#c7cdd154' }} readOnly={true} className="form-control input-modifier" placeholder="" /> */}
                                                </div>
                                            </div>
                                            
                                            {/* <div className="col-md-4">
                                            <div className="form-group">
                                                <label className="form_title">Mode</label>
                                                <Input name="account[mode]" type="select" options={UserModesWithoutAll}  className="custom-select input-modifier " disabled />
                                            </div>
                                        </div> */}

                                        </div>
                                        <div class="row">
                                        <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="label_modifier">Customer</label>
                                                    <Input dataTest="jobs_region_data" options={companyList} name="account[company_account_id]" isMulti={true} type="inputSelect"  />
                                                </div>
                                            </div>
                                            </div>
                                        {/* checked={account.user && account.user.mode === "internal" ? true : false} */}
                                        {/* <div className="row">
                                            <label className="label_modifier">Set Quota for Job</label>
                                            <a href="javascript:void(0);" onClick={props.addMoreQuotaClicked}>Add</a>
                                            <QuotasField quotas_attributes={props.quotas_attributes}/>
                                        </div> */}

                                        {/* <div className="form-group mt-4">
                                            <label className="label_modifier">Holiday</label>
                                            <div className="checkbox_block radius_checkbox">
                                                <div className="custom-control custom-checkbox custom-control-inline">
                                                    <input onChange={props.selectAllClicked} checked={props.selectNone ? false : props.selectAll} type="checkbox" className="custom-control-input" id="selectAll" />
                                                    <label className="custom-control-label" for="selectAll">Select All</label>
                                                </div>
                                                <div className="custom-control custom-checkbox custom-control-inline">
                                                    <input onChange={props.selectNoneClicked} checked={props.selectAll ? false : props.selectNone} type="checkbox" className="custom-control-input" id="selectNone" />
                                                    <label className="custom-control-label" for="selectNone">Select None</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            {props.holidaysList && props.holidaysList.map(h => {
                                                return (
                                                    <div className="checkbox_block radius_checkbox">
                                                        <div className="custom-control custom-checkbox mb-2">
                                                            <input disabled={props.selectNone || props.selectAll} onChange={() => props.holidaySelected(h.id)} checked={props.setCheckValue(h.id)} type="checkbox" className="custom-control-input" id={`holiday${h.id}`} />
                                                            <label className="custom-control-label" for={`holiday${h.id}`}>{h.holiday_reason}</label>
                                                        </div>
                                                    </div>)
                                            })}
                                        </div> */}
                                        {/* <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label for="text" className="label_modifier">Contact2 First Name</label>
                                                    <Input type="text" className="form-control input-modifier" placeholder="" name="account[contact2_FN]" />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="label_modifier">Contact2 Last Name</label>
                                                    <Input type="text" className="form-control input-modifier" placeholder="" name="account[contact2_LN]" />
                                                </div>
                                            </div>
                                        </div> */}
                                        {/* <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label for="email" className="label_modifier">Contact2 Email</label>
                                                    <Input type="email" name="account[contact2_email]" className="form-control input-modifier" placeholder="" /> */}
                                        {/* readOnly={props.isEdit ? true : false} */}
                                        {/* </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="label_modifier">Contact2 Phone</label>
                                                    <Input type="numberFormate" className="form-control input-modifier" placeholder="" name="account[contact2_phone]" />
                                                </div>
                                            </div>
                                        </div> */}

                                    </div>

                                    <div className="modal-footer">
                                        <button data-test="submit_button" type="submit" disabled={props.isLoading} className={props.isLoading ? "btn cst_btn btn_danger mr-2 btn-wait" : "btn cst_btn btn_danger mr-2"} >{props.isLoading ? "" : "Submit"}</button>
                                        <button data-test="cancel_button" type="button" className="btn btn-outline-secondary cst_btn" onClick={() => props.onCancelClicked()}>Cancel</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                }

            </div>
        </main >

    )
}

const mapStateToProps = (state) => {
    let account = {
        active_status: "active"
    };
    let holiday_ids = [];
    const getHolidaysIds = (holidays) => {
        return holidays.map(h =>
        ({
            value: h.id,
            label: h.holiday_reason
        })
        )
    }
    const getCompanyIdList = (companyList) => {
        return companyList && companyList.length > 0 && companyList.map(a =>
            ({
                value: a.id,
                label: a.name
            })
        )
    }
    const user = getUser();
    if (isEmpty(state.adminOrSuperAdminReducer.account)) {
        account = {
            active_status: "active",
            mode: user.mode
        }
    } else {
        account = {
            first_name: state.adminOrSuperAdminReducer.account.user.first_name,
            last_name: state.adminOrSuperAdminReducer.account.user.last_name,
            name: state.adminOrSuperAdminReducer.account.user.name,
            phone_number: state.adminOrSuperAdminReducer.account.user.phone_number,
            account_number: state.adminOrSuperAdminReducer.account.user.account_number,
            region_id: state.adminOrSuperAdminReducer.account.user.region ? state.adminOrSuperAdminReducer.account.user.region.id : '',
            email: state.adminOrSuperAdminReducer.account.user.email,
            contact2_FN: state.adminOrSuperAdminReducer.account.user.contact2_FN,
            contact2_LN: state.adminOrSuperAdminReducer.account.user.contact2_LN,
            contact2_email: state.adminOrSuperAdminReducer.account.user.contact2_email,
            contact2_phone: state.adminOrSuperAdminReducer.account.user.contact2_phone,
            active_status: state.adminOrSuperAdminReducer.account.user.active_status,
            quotas: state.adminOrSuperAdminReducer.account.user.quotas,
            timezone: state.adminOrSuperAdminReducer.account.user.timezone,
            mode: state.adminOrSuperAdminReducer.account.user.mode,
            company_account_id: getCompanyIdList(state.adminOrSuperAdminReducer.account.user.companys)
        }
        holiday_ids = getHolidaysIds(state.adminOrSuperAdminReducer.account.user.holidays)
    }
    
    return { initialValues: { account, holiday_ids: holiday_ids } }
}


NewAccounts = reduxForm({
    form: 'newAccountForm',
    validate,
    enableReinitialize: true,
    onSubmitFail: (errors) => scrollToFirstError(errors),
})(NewAccounts);

NewAccounts = connect(mapStateToProps)(NewAccounts);

export const scrollToFirstError = (errors) => {

    let scroolToError = null;

    if (errors.account.account_number) {
        scroolToError = `account[account_number]`;
    } else if (errors.account.email) {
        scroolToError = `account[email]`;
    } else if (errors.account.first_name) {
        scroolToError = `account[first_name]`;
    } else if (errors.account.last_name) {
        scroolToError = `account[last_name]`;
    } else if (errors.account.name) {
        scroolToError = `account[name]`;
    } else if (errors.account.phone_number) {
        scroolToError = `account[phone_number]`;
    } else if (errors.account.region_id) {
        scroolToError = `account[region_id]`;
    }

    scroller.scrollTo(`${scroolToError}`, {
        duration: 500,
        delay: 1,
        isDynamic: true,
        smooth: true,
        // containerId: 'ContainerElementID',
        offset: -100,
    })

}

export default NewAccounts;
