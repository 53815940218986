import React, { Component } from 'react';
import { routes } from '../../../../../utility/constants/constants';
import InventoryList from '../../../../component/Inventory/InventoryListComponent/InventoryList';
import * as actions from '../../../../../redux/actions/index';
import { connect } from 'react-redux';
import { reset } from 'redux-form';
import { animateScroll as scroll } from 'react-scroll'
import storage from '../../../../../utility/storage';
import { convertDateToDifferentTZ } from '../../../../../utility/utility';
import ShiftScheduleDetail from '../../../../component/TimeTrackerModuleComponent/ShiftComponent/ShiftScheduleDetailComponent/ShiftScheduleDetail';
import errorHandler from '../../../../../utility/errorHandler/errorHandler';
import { tzUTCDateConverterTool_V2 } from '../../../../../utility/TimezoneOperations/timezoneUtility';

// ShiftScheduleDetailComponent/ShiftScheduleDetail

var moment = require('moment');
const cloneDeep = require('clone-deep');

class ShiftScheduleDetailContainer extends Component {

    constructor(props) {
        super(props);

        this.state = {

        }
    }

    componentDidMount() {
        scroll.scrollToTop();
    }

    componentDidUpdate(prevProps, prevState) {
        const user = storage.get('user');
        if (user) {
            if (prevProps.user.selected_mode !== user.selected_mode) {
                this.props.shiftScheduleDetailListPageClicked(0)
            }
        }
    }

    resetClicked = () => {
        const page = 1
        const limit = this.props.shiftScheduleDetailList_no_of_rows
        let status = 'created';
        this.props.reset('searchShiftScheduleDetail');
        this.props.shiftScheduleDetailListPageClicked(0)
        this.props.getShiftScheduleDetail({ page, limit, status }, {}, this.props.match.params.id)

    }

    searchInventoryItemsListClicked = (value) => {

        if (value) {
            if (value.fromDate == '' && value.toDate == '') {
            } else if (value.fromDate == '' || value.toDate == '') {
                errorHandler(`Both 'From' and 'To' dates are required.`)
                return false
            }
        }

        const values = cloneDeep(value)

        const page = 1
        const limit = this.props.shiftScheduleDetailList_no_of_rows
        let status = null;
        let from = null;
        let to = null;

        if (values.shiftStatus) {
            values.shiftStatus = values.shiftStatus && values.shiftStatus.value && values.shiftStatus.value.trim()
            status = values.shiftStatus
        }
        if (values.fromDate) {
            from = tzUTCDateConverterTool_V2(values.fromDate, 'from');
        }
        if (values.toDate) {
            to = tzUTCDateConverterTool_V2(values.toDate, 'to');
        }

        this.props.getShiftScheduleDetail({ page, limit, status, from, to }, values, this.props.match.params.id);

    }

    addInventoryItemClicked = () => {
        this.props.history.push(routes.NEW_INVENTORY_ITEM);
    }

    deleteInventoryItem = (id) => {
        this.props.deleteInventoryItem(id);
    }

    handleInventoryOpenDeleteModal = (value) => {
        this.props.handleInventoryOpenDeleteModal(value);
    }

    handleInventoryCloseDeleteModal = () => {
        this.props.handleInventoryCloseDeleteModal();
    }

    newTicketClicked = () => {
        // this.props.resetObjects();
        // this.props.history.push(routes.NEW_SCHEDULED_TASK);
    }

    handleSwapRequestSanction = (swapId) => {

        this.props.history.push({
            pathname: `/shift-schedule/swap/${swapId}`,
            // state: {storedRoute: null}
        });

    }

    handleCancelRequestSanction = (value) => {

        let fetchedValue = {
            data: { status: 'cancelled' }
        }

        this.props.grantCancelShiftRequest(fetchedValue, value.shiftId, this.props.match.params.id);

    }

    handleEditPerDayShiftDetailClicked = (perDayShiftId) => {debugger
        this.props.history.push({ pathname: `/frontlineUser/edit-perDay-shiftDetail/${perDayShiftId}` })
    }

    render() {
        return (
            <ShiftScheduleDetail
                {...this.props}
                isLoading={this.props.isLoading}
                onSubmit={this.searchInventoryItemsListClicked}
                resetClicked={this.resetClicked}
                shiftScheduleDetailList={this.props.shiftScheduleDetailList ? this.props.shiftScheduleDetailList : []}
                addInventoryItemClicked={this.addInventoryItemClicked}
                deleteInventoryItem={this.deleteInventoryItem}
                handleInventoryOpenDeleteModal={this.handleInventoryOpenDeleteModal}
                handleInventoryCloseDeleteModal={this.handleInventoryCloseDeleteModal}
                newTicketClicked={this.newTicketClicked}
                handleCancelRequestSanction={this.handleCancelRequestSanction}
                handleSwapRequestSanction={this.handleSwapRequestSanction}
                handleEditPerDayShiftDetailClicked={this.handleEditPerDayShiftDetailClicked}

            />
        )
    }
}


const mapStateToProps = (state) => {
    return {
        user: state.authReducer.user,
        isLoading: state.TimeTrackerModReducer.isLoading,
        shiftScheduleDetailList: state.TimeTrackerModReducer.shiftScheduleDetailList,
        isShiftScheduleDetailList: state.TimeTrackerModReducer.isShiftScheduleDetailList,
        shiftScheduleDetailList_no_of_rows: state.TimeTrackerModReducer.shiftScheduleDetailList_no_of_rows,
        shiftScheduleDetailListCurPage: state.TimeTrackerModReducer.shiftScheduleDetailListCurPage,
        openInventoryDeleteModal: state.TimeTrackerModReducer.openInventoryDeleteModal,
        deleteInventoryObject: state.TimeTrackerModReducer.deleteInventoryObject,
        isTimeTrackerModalOpen: state.TimeTrackerModReducer.isTimeTrackerModalOpen,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        reset: (searchInventoryItemsList) => dispatch(reset(searchInventoryItemsList)),  // requires form name
        shiftScheduleDetailListPageClicked: (page) => dispatch(actions.shiftScheduleDetailListPageClicked(page)),
        getShiftScheduleDetail: (params, values, id) => dispatch(actions.getShiftScheduleDetail(params, values, id)),
        deleteInventoryItem: (id) => dispatch(actions.deleteInventoryItem(id)),
        handleInventoryOpenDeleteModal: (value) => dispatch(actions.handleInventoryOpenDeleteModal(value)),
        handleInventoryCloseDeleteModal: () => dispatch(actions.handleInventoryCloseDeleteModal()),
        previewSingleScheduledTaskClicked: (ticket) => dispatch(actions.previewSingleScheduledTaskClicked(ticket)),
        resetObjects: () => dispatch(actions.resetObjects()),

        handleFrequentTimeTracker_openModal: (data) => dispatch(actions.handleFrequentTimeTracker_openModal(data)),
        handleFrequentTimeTracker_closeModal: (data) => dispatch(actions.handleFrequentTimeTracker_closeModal(data)),
        grantCancelShiftRequest: (cancelRequest, shiftId, paramsId) => dispatch(actions.grantCancelShiftRequest(cancelRequest, shiftId, paramsId)),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(ShiftScheduleDetailContainer);