export const buildingProfileActionTypes = {

    GET_SINGLE_JOB_CARD:'GET_SINGLE_JOB_CARD',
    GET_SINGLE_JOB_CARD_PENDING: 'GET_SINGLE_JOB_CARD_PENDING',
    GET_SINGLE_JOB_CARD_FULFILLED: 'GET_SINGLE_JOB_CARD_FULFILLED',
    GET_SINGLE_JOB_CARD_REJECTED:'GET_SINGLE_JOB_CARD_REJECTED',

    ADD_SINGLE_JOB_CARD:'ADD_SINGLE_JOB_CARD',
    ADD_SINGLE_JOB_CARD_PENDING: 'ADD_SINGLE_JOB_CARD_PENDING',
    ADD_SINGLE_JOB_CARD_FULFILLED: 'ADD_SINGLE_JOB_CARD_FULFILLED',
    ADD_SINGLE_JOB_CARD_REJECTED:'ADD_SINGLE_JOB_CARD_REJECTED',


    EDIT_SINGLE_JOB_CARD:'EDIT_SINGLE_JOB_CARD',
    EDIT_SINGLE_JOB_CARD_PENDING: 'EDIT_SINGLE_JOB_CARD_PENDING',
    EDIT_SINGLE_JOB_CARD_FULFILLED: 'EDIT_SINGLE_JOB_CARD_FULFILLED',
    EDIT_SINGLE_JOB_CARD_REJECTED:'EDIT_SINGLE_JOB_CARD_REJECTED',


    GET_ALL_JOB_CARD:'GET_ALL_JOB_CARD',
    GET_ALL_JOB_CARD_PENDING: 'GET_ALL_JOB_CARD_PENDING',
    GET_ALL_JOB_CARD_FULFILLED: 'GET_ALL_JOB_CARD_FULFILLED',
    GET_ALL_JOB_CARD_REJECTED:'GET_ALL_JOB_CARD_REJECTED',

    JOB_CARD_PAGE_CLICKED  : 'JOB_CARD_PAGE_CLICKED',

    GET_ALL_BUILDING_PROFILE:'GET_ALL_BUILDING_PROFILE',
    GET_ALL_BUILDING_PROFILE_PENDING: 'GET_ALL_BUILDING_PROFILE_PENDING',
    GET_ALL_BUILDING_PROFILE_FULFILLED: 'GET_ALL_BUILDING_PROFILE_FULFILLED',
    GET_ALL_BUILDING_PROFILE_REJECTED:'GET_ALL_BUILDING_PROFILE_REJECTED',

    ADD_SINGLE_BUILDING_PROFILE:'ADD_SINGLE_BUILDING_PROFILE',
    ADD_SINGLE_BUILDING_PROFILE_PENDING: 'ADD_SINGLE_BUILDING_PROFILE_PENDING',
    ADD_SINGLE_BUILDING_PROFILE_FULFILLED: 'ADD_SINGLE_BUILDING_PROFILE_FULFILLED',
    ADD_SINGLE_BUILDING_PROFILE_REJECTED:'ADD_SINGLE_BUILDING_PROFILE_REJECTED',

    GET_SINGLE_BUILDING_PROFILE:'GET_SINGLE_BUILDING_PROFILE',
    GET_SINGLE_BUILDING_PROFILE_PENDING: 'GET_SINGLE_BUILDING_PROFILE_PENDING',
    GET_SINGLE_BUILDING_PROFILE_FULFILLED: 'GET_SINGLE_BUILDING_PROFILE_FULFILLED',
    GET_SINGLE_BUILDING_PROFILE_REJECTED:'GET_SINGLE_BUILDING_PROFILE_REJECTED',

    BUILDING_PROFILE_PAGE_CLICKED : "BUILDING_PROFILE_PAGE_CLICKED",

    EDIT_SINGLE_BUILDING_PROFILE:'EDIT_SINGLE_BUILDING_PROFILE',
    EDIT_SINGLE_BUILDING_PROFILE_PENDING: 'EDIT_SINGLE_BUILDING_PROFILE_PENDING',
    EDIT_SINGLE_BUILDING_PROFILE_FULFILLED: 'EDIT_SINGLE_BUILDING_PROFILE_FULFILLED',
    EDIT_SINGLE_BUILDING_PROFILE_REJECTED:'EDIT_SINGLE_BUILDING_PROFILE_REJECTED',



}