import React, { useState } from "react";
import { ClapSpinner } from "react-spinners-kit";
import { convertLogbookDateTimezone, makeFirstLetterCapital } from "../../../../../utility/utility";
import ImageLoader from "react-imageloader";
import ModalImageGallery from "../../../ModalImageGallery/modalImageGallery";

let LogBookLogsArea = (props) => {
  const [startIndex, setStartIndex] = useState(0);
  const [isImageGallery, setIsImageGallery] = useState(false);
  const [photoUrls, setPhotoUrls] = useState([]);

  const log = props.logbookLog ? props.logbookLog : {};

  function preloader() {
    return <img style={{ width: '100%', height: '100%' }} src="/images/gif/giphy.gif" />;
}
  
  const photoUrlClicked = (urls, i) => {
    setIsImageGallery(true);
    setStartIndex(i);
    setPhotoUrls(urls);
}

if (isImageGallery) {
  return <ModalImageGallery
      isImageGallery={isImageGallery}
      setIsImageGallery={setIsImageGallery}
      startIndex={startIndex}
      imageUrls={photoUrls}
  />
}
  return props.isLogbookLogsAreas ? (
    <div className="loader_btn_block">
      {" "}
      <ClapSpinner backColor="#89073c" size={40} frontColor="#89073c8a" />{" "}
    </div>
  ) : (
    <>
      <main className="app-content add_area_modal">
        <div className="appcontent_Inner">
          <div className="add_info_block">
            <div className="inspections">
              <div className="left_list ">
                <h2 className="h2_title ">Logbook Log Details</h2>
              </div>
              <div className="inspection_content_block">
                <div className="left_list">
                  <h4 style={{ color: "#850037", fontWeight: "bold" }}>
                    Logbook Name :{" "}
                  </h4>
                </div>
                <div className="right_list">
                  <h4>{log.name ? makeFirstLetterCapital(log.logbook.name) : "-"}</h4>
                </div>
              </div>

              <div className="inspection_content_block">
                <div className="left_list">
                  <h4 style={{ color: "#850037", fontWeight: "bold" }}>
                    Reviewed by :{" "}
                  </h4>
                </div>
                <div className="right_list">
                  <h4>{log.name ? makeFirstLetterCapital(log.name) : "-"}</h4>
                </div>
              </div>

              {/* <div className="inspection_content_block">
                <div className="left_list">
                  <h4 style={{ color: "#850037", fontWeight: "bold" }}>
                    Logbook ID :{" "}
                  </h4>
                </div>
                <div className="right_list">
                  <h4>{log?.id}</h4>
                </div>
              </div> */}

              <div className="inspection_content_block">
                <div className="left_list">
                  <h4 style={{ color: "#850037", fontWeight: "bold" }}>
                    Review Status :{" "}
                  </h4>
                </div>
                <div className="right_list">
                  <h4>{log.status === 'quality_reviewed' ? 'Quality Reviewed' : makeFirstLetterCapital(log.status)}</h4>
                </div>
              </div>

              <div className="inspection_content_block">
                <div className="left_list">
                  <h4 style={{ color: "#850037", fontWeight: "bold" }}>
                    Scheduled Date :{" "}
                  </h4>
                </div>
                <div className="right_list">
                  <h4>
                    {log?.created_at ? convertLogbookDateTimezone(log?.start_time_utc, log.user?.account_timezone) : '-' }
                  </h4>
                </div>
              </div>
              <div className="inspection_content_block">
                <div className="left_list">
                  <h4 style={{ color: "#850037", fontWeight: "bold" }}>
                    Reviewed Date :{" "}
                  </h4>
                </div>
                <div className="right_list">
                  <h4>
                    {log?.updated_at ? convertLogbookDateTimezone(log?.reviewed_at, log.user?.account_timezone) : '-' }
                  </h4>
                </div>
              </div>
           
              <div className="inspection_content_block">
                <div className="left_list">
                  <h4 style={{ color: "#850037", fontWeight: "bold" }}>
                    Comments :{" "}
                  </h4>
                </div>
                <div className="right_list">
                  <h4>
                    {makeFirstLetterCapital(log.comment) }
                  </h4>
                </div>
              </div>

              <div className="inspection_content_block">
                <div className="left_list">
                  <h4 style={{ color: "#850037", fontWeight: "bold" }}>
                    Initial :
                  </h4>
                </div>
                {/* <div className="right_list">
                  <img src={log.initial_url?.public_url} alt='initial' />
                </div> */}
              </div>

              <div className="inspection_content_block">
              <div className="left_list" >
                <img src={log.initial_url?.small} alt='initial' />
                </div>
              </div>
              </div>
              </div>

           {log.attachments?.length ? 
            <>
             <div className="attachment_logbook_logs_container mb-5">
                <div className="left_list">
                  <h4 style={{ color: "#850037", fontWeight: "bold" }}>
                    Attachments :
                  </h4>
                </div>
              </div>

              {/* <div className="inspection_content_block"> */}
                <div className="attachment_logbook_logs_container">
              {log.attachments && log.attachments.map((t, i) => {

                  return (
                      <div className="attachment_logbook_logs" onClick={() => photoUrlClicked(log?.attachments, i)}>
                          <ImageLoader
                              style={{ width: '100%', height: '100%', cursor: 'pointer', marginBottom : '20px' }}
                              src={t.photo_urls?.small}
                              wrapper={React.createFactory('div')}
                              preloader={preloader}>
                              Image load failed!
                          </ImageLoader>
                       </div>
                  )
              })}
              </div>
              {/* </div> */}
            </>
             : null
           }   

            
        </div>
      </main>
    </>
  );
};

export default LogBookLogsArea;
