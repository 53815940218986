import React, { useEffect, useState } from 'react';
import ReactTable from 'react-table-v6';
import '../../../../../styles/custom.css';
import { CustomNoDataComponent, makeFirstLetterCapital, convertDateToDifferentTZ } from '../../../../../utility/utility';
import { FaArrowDown, FaArrowUp } from "react-icons/fa";
import ReactTooltip from 'react-tooltip';
import * as actions from '../../../../../redux/actions/index';
import { useSelector, useDispatch } from 'react-redux';
import { ClapSpinner } from 'react-spinners-kit';
import 'react-table-v6/react-table.css';
import { convertDateUTCFormAudit, convertDateUTCFormAuditToSelectedTimezone } from '../../../../../utility/TimezoneOperations/timezoneUtility';

var moment = require('moment');

let TeamChecklistTable = (props) => {

    const dispatch = useDispatch();
    // const SearchValue = useSelector(state => state.form.searchScheduledTask && state.form.searchScheduledTask.values ? state.form.searchScheduledTask.values : {});
    const DateGenerate = useSelector(state => state.FormAuditModReducer.dataGenerated);
    // const values = useSelector(state => state.FormAuditModReducer.scheduledTask_searchValues);
    const No_of_pages = useSelector(state => state.FormAuditModReducer.checklist_total_pages);
    const No_of_rows = useSelector(state => state.FormAuditModReducer.checklist_no_of_rows);
    const ischecklist = useSelector(state => state.FormAuditModReducer.ischecklist);
    const checklistCurPage = useSelector(state => state.FormAuditModReducer.checklistCurPage);
    const SearchOrResetScheduledTaskListClicked = useSelector(state => state.FormAuditModReducer.SearchOrResetScheduledTaskListClicked);

    const accountTimezone = 'America/Los_Angeles';

    const checklistLineItem = (data) => {
        if (data) {
            return `${data.checked_count} of ${data.total_count}`
        } else {
            return "-"
        }
    }

    const data = props.checklist_list && props.checklist_list.map((value) => {
        return {
            account_id: value && value.account ? value.account.name : '-',
            location: value && value.location ? value.location : '-',
            date: value.date ? convertDateUTCFormAuditToSelectedTimezone(value.date,value?.account?.timezone) : '-',//moment.utc(value.date).format('MM-DD-YYYY')
            specialist: value && value.specialist ? value.specialist : '-',
            employee_id: value && value.employee ? `${makeFirstLetterCapital(value.employee.first_name)} ${makeFirstLetterCapital(value.employee.last_name)}` : '-',
            conducted_by: value && value.conductedBy ? `${makeFirstLetterCapital(value.conductedBy.first_name)} ${makeFirstLetterCapital(value.conductedBy.last_name)}` : '-',
            // collection: value && value.sections ? checklistLineItem(value.sections.find((item) => item.name === COLLECTIONS.collection)) : '-',
            // vaccum: value && value.sections ? checklistLineItem(value.sections.find((item) => item.name === COLLECTIONS.vaccum)) : '-',
            // restroom: value && value.sections ? checklistLineItem(value.sections.find((item) => item.name === COLLECTIONS.restroom)) : '-',
            // utility: value && value.sections ? checklistLineItem(value.sections.find((item) => item.name === COLLECTIONS.utility)) : '-',
            // dailyKitting: value && value.sections ? checklistLineItem(value.sections.find((item) => item.name === COLLECTIONS.dailyKitting)) : '-',
            // accessoris: value && value.sections ? checklistLineItem(value.sections.find((item) => item.name === COLLECTIONS.accessoris)) : '-',
            // beyondComplaince: value && value.sections ? checklistLineItem(value.sections.find((item) => item.name === COLLECTIONS.beyondComplaince)) : '-',
            // controlItems: value && value.sections ? checklistLineItem(value.sections.find((item) => item.name === COLLECTIONS.controlItems)) : '-',
            // pantry: value && value.sections ? checklistLineItem(value.sections.find((item) => item.name === COLLECTIONS.pantry)) : '-',
            actions:
                <>
                    <span className="mr_20 account_edit_icn" onClick={() => props.history.push(`/team-checklist/view/${value.id}`)} ><i class="fas fa-eye" aria-hidden="true" ></i></span>
                    {
                        props?.isCreateAccess ? <span className="mr_20 account_edit_icn" onClick={() => props.history.push(`/team-checklist/${value.id}`)} ><i class="fa fa-pencil" aria-hidden="true" ></i></span> : null
                    }

                </>
        }
    })

    const columns = [
        {
            Header: <><span> Job </span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
            accessor: 'account_id',
            show: props.checklist_list.length !== 0,
            width: 250
        },
        {
            Header: <><span> Location </span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /></>,
            accessor: 'location',
            show: props.checklist_list.length !== 0,
            // width: 120,
        },

        {
            Header: <><span> Date </span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /></>,
            accessor: 'date',
            show: props.checklist_list.length !== 0,
        },
        {
            Header: <><span> Specialist </span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /></>,
            accessor: 'specialist',
            show: props.checklist_list.length !== 0,
        },
        {
            Header: <><span> Employee </span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /></>,
            accessor: 'employee_id',
            show: props.checklist_list.length !== 0,
            width: 200,
        },
        {
            Header: <><span> Conducted By</span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /></>,
            accessor: 'conducted_by',
            show: props.checklist_list.length !== 0,
            width: 200,
        },
        // {
        //     Header: <><span> Collection</span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /></>,
        //     accessor: 'collection',
        //     show: props.checklist_list.length !== 0,
        //     // width: 100,
        // },

        // {
        //     Header: <><span>Vacuum</span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
        //     accessor: 'vaccum',
        //     show: props.checklist_list.length !== 0,
        //     // width: 235,
        // },
        // {
        //     Header: <><span>Restroom</span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
        //     accessor: 'restroom',
        //     show: props.checklist_list.length !== 0,
        //     // width: 245,
        // },
        // {
        //     Header: <><span>Utility</span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
        //     accessor: 'utility',
        //     show: props.checklist_list.length !== 0,

        // },
        // {
        //     Header: <><span>Daily Kitting</span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
        //     accessor: 'dailyKitting',
        //     show: props.checklist_list.length !== 0,
        //     width: 135,
        // },
        // {
        //     Header: <><span>Accessories</span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
        //     accessor: 'accessoris',
        //     show: props.checklist_list.length !== 0,

        // },
        // {
        //     Header: <><span>Beyond Compliance</span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
        //     accessor: 'beyondComplaince',
        //     show: props.checklist_list.length !== 0,
        //     width: 160,
        // },
        // {
        //     Header: <><span>Control Items</span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
        //     accessor: 'controlItems',
        //     show: props.checklist_list.length !== 0,
        //     width: 135,
        // },
        // {
        //     Header: <><span>Pantry</span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
        //     accessor: 'pantry',
        //     show: props.checklist_list.length !== 0,

        // },
        {
            Header: '',
            accessor: 'actions',
            sortable: false,
            // width: 100,
        }
    ]

    const [tablePage, setTablePage] = useState(0)
    const tablePageNew = React.useRef()
    if (checklistCurPage !== tablePageNew.current) {
        tablePageNew.current = checklistCurPage;
    }
    const setTablePageFn = (page) => {
        setTablePage(page)
        props.ChecklistPageClicked(page)
        tablePageNew.current = page;
    }

    const fetchData = (state, instance) => {
        if (SearchOrResetScheduledTaskListClicked) {
            dispatch(actions.resetSearchOrResetInventory())
        } else {
            if (!DateGenerate && !props.ischecklist) {
                state.page = 2;
                const limit = state.pageSize;
                let page = tablePageNew.current >= 0 ? tablePageNew.current + 1 : checklistCurPage + 1;
                const inventoryItemsList_no_of_rows = state.pageSize;
                if (No_of_rows != inventoryItemsList_no_of_rows) {
                    page = 1;
                    setTablePageFn(0)
                }

                const order = state.sorted[0]?.id;
                const orderBy = state.sorted && state.sorted.length ? state.sorted[0]?.desc === true ? 'desc' : 'asc' : null;
                // const accountId = SearchValue.id ? SearchValue.id : null;
                // const taskName = SearchValue.name ? SearchValue.name : null;
                // const companyName = SearchValue.company ? SearchValue.company : null;
                // const accountName = SearchValue.job ? SearchValue.job : null;
                // const from = SearchValue.fromDate ? convertDateToDifferentTZ(SearchValue.fromDate, 'from') : null;
                // const to = SearchValue.toDate ? convertDateToDifferentTZ(SearchValue.toDate, 'to') : null;
                props.getAllChecklist({ page, limit, order, orderBy })
            }
        }
    }

    return (
        SearchOrResetScheduledTaskListClicked && props.ischecklist ?
            <div  >
                <ClapSpinner backColor="#89073c" size={40} frontColor="#89073c8a" />
            </div>
            :
            <ReactTable
                data={data}
                loading={props.ischecklist}
                minRows={0}
                defaultPageSize={No_of_rows}
                pages={No_of_pages}
                gotoPage={checklistCurPage}
                page={checklistCurPage}
                onPageChange={(page) => { setTablePageFn(page) }}
                // onPageSizeChange={props.onUserPageSizeChange}
                manual
                onFetchData={fetchData}
                showPaginationTop={true}
                showPaginationBottom={false}
                showPagination={props.checklist_list.length !== 0}
                columns={columns}
                // style={{marginTop: '10px'}}
                NoDataComponent={() => CustomNoDataComponent(ischecklist, 'No Team Checklist Found')}
                LoadingComponent={() =>
                    ischecklist ?
                        <div style={{
                            display: "block",
                            position: "absolute",
                            left: 0,
                            height: '100%',
                            right: 0,
                            background: "rgba(255,255,255,0.8)",
                            transition: "all .3s ease",
                            top: 0,
                            bottom: 0,
                            textAlign: "center"
                        }}
                            className="loader_btn_block">
                            <ClapSpinner backColor="#89073c" size={40} frontColor="#89073c8a" />
                        </div> : null}
                getTheadThProps={(state, rowInfo, column, instance) => {
                    // console.log(column);
                    return {
                        style: {
                            borderTop: 'none',
                            borderBottom: 'none',
                            borderRight: 'none',
                            textAlign: 'center',
                            color: '#566975',
                            fontSize: '14px',
                            fontWeight: '600',
                            whiteSpace: 'nowrap',
                            float: 'left',
                        }
                    }
                }
                }
                getTrProps={(state, rowInfo, column, instance) => {
                    // onclick = () => userDetail(rowInfo ? rowInfo.row.user : rowInfo);
                    console.log(column);
                    return {
                        className: 'react-table-tr-element',
                        style: {
                            border: '1px solid #c7cdd1',
                            transition: '.3s ease-in-out',
                            width: '100%',
                            // borderRadius: '4px',
                            // height: '80px',
                            marginTop: '5px',
                            float: 'left'
                        }
                    }

                }}
                getTdProps={(state, rowInfo, column, instance) => {

                    if (column.id == 'image') {
                        return {
                            style: {
                                marginTop: '0px'
                            }
                        }
                    } else {
                        return {
                            className: 'react-td-element',
                        }
                        //  {
                        //   style: {
                        //     borderTop: 'none',
                        //     verticalAlign: 'middle',
                        //     fontSize: '14px',
                        //     color: '#2b3034',
                        //     marginTop: '10px !important',
                        //     textAlign: 'center',
                        //   }
                        // }
                    }
                }
                }
                // getPaginationProps={() => {
                //     return {
                //         style: {
                //             marginTop: '50px',
                //             border: 'none',
                //             boxShadow: 'none'
                //         }
                //     }
                // }
                // }
                getProps={() => {
                    return {
                        style: {
                            border: 'none'
                        }
                    }
                }}
            />
    )
}

export default TeamChecklistTable;