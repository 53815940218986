import React from "react";
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { Input } from '../UI/InputElement/InputElement';
let Search = (props) => {
  const { onAccountChange} = props;
  const noOptionsMessage = (a, b) => {
    return 'Area not found';
  }
  
  const accountOptions = props.user.accounts.map(account => {return {value: account.id, label: account.name}})
  return(
        <form className="form-inline mobile-view-history1">
            {/* <Input name="quote[account_id]" noOptionsMessage={noOptionsMessage} options={accounts} /> */}
            <Input name="account_id" type="inputGroupSelect" className="input-modifier w-100 ml-0 mb-2" selected={props.user.default_account === accountOptions} onChange={onAccountChange} noOptionsMessage={noOptionsMessage} options={accountOptions} placeholder="AREA-NAME" />
        </form>
  )
};

const mapStateToProps = (state, props) => {
  let account_id;
  if (props.user) {
    let account = props.user.default_account ? props.user.default_account : (props.user.accounts && props.user.accounts.length ? props.user.accounts[0] : {});
    account_id = {value: account.id, label: account.name};
  }
  return {
    initialValues:  {account_id: account_id },
  }
};
Search = reduxForm({
  form: 'historySearch',
  enableReinitialize: true,
})(Search);

Search = connect(mapStateToProps)(Search);
export default Search;