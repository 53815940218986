import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import Select from "react-select";
import Aux from '../../../../hoc/Oux/Oux';
import propTypes from 'react-table-v6/lib/propTypes';


const customStyles = {
    control: (base, state) => {
        if (state.isFocused) {
            //  
            return {
                ...base,
                borderColor: "#9b2c59",
                boxShadow: 'none',
                '&:hover': {
                    borderColor: '#9b2c59'
                }
            }
        } else {
            return {
                ...base
            }
        }
    }
};

const errorStyle = {
    control: (base, state) => {
        if (state.isFocused) {
            //  
            return {
                ...base,
                borderColor: "#9b2c59",
                borderLeft: '3px solid #f04d53',
                boxShadow: 'none',
                '&:hover': {
                    borderColor: '3px solid #9b2c59',
                    borderLeft: '3px solid #9b2c59',
                }
            }
        } else {
            return {
                ...base,
                borderColor: '#f04d53',
                borderLeft: '3px solid #f04d53',
            }
        }
    }
}

const groupStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
};
const groupBadgeStyles = {
    backgroundColor: '#EBECF0',
    borderRadius: '2em',
    color: '#172B4D',
    display: 'inline-block',
    fontSize: 12,
    fontWeight: 'normal',
    lineHeight: '1',
    minWidth: 1,
    padding: '0.16666666666667em 0.5em',
    textAlign: 'center',
};

const formatGroupLabel = data => (
    <div style={groupStyles}>
        <span>{data.label}</span>
        <span style={groupBadgeStyles}>{data.options.length}</span>
    </div>
);

const renderField = ({ input, isMulti, readOnly, changeFunction, onChange, getValue, label, disabled, type, noOptionsMessage, dataTest, className, options, instanceId, placeholder, meta: { touched, asyncValidating, error, warning },CustomOption },props) => {
    //  
    const onChangeValue = (value) => {
        
        input.onChange(value);
        if (changeFunction) {
            changeFunction(value);
        }
    }
    
    return (
        <Aux>
            {/* <div className="testcafe_inputselect" data-test="ticket_job"> */}
                {type === 'inputSelect' ?
                    <Select {...input}
                        className={className}
                        blurInputOnSelect={onChange}
                        onChange={value => onChangeValue(value)}
                        onBlur={event => event.preventDefault()}
                        isDisabled={readOnly}
                        instanceId={instanceId}
                        data-test={dataTest}
                        // value={selectedValue}
                        // getValue={getValue}
                        // onBlur={() => changeFunction ? changeFunction(input.value) : null}
                        isMulti={isMulti}
                        noOptionsMessage={noOptionsMessage}
                        placeholder={placeholder}
                        styles={(touched && error) ? errorStyle : customStyles}
                        options={options}
                        components={CustomOption ? { Option:CustomOption  } :{}}

                    // class={(touched && error) ? { borderColor: '#f04d53', borderLeft: '3px solid #f04d53', cursor: 'pointer' } : { borderColor: '#9b2c59', boxShadow: 'none', cursor: 'pointer' }}
                    />
                    :
                    type === 'inputGroupSelect' ?
                        <Select {...input}
                            className={className}
                            blurInputOnSelect={onChange}
                            onChange={value => input.onChange(value)}
                            onBlur={event => event.preventDefault()}
                            data-test={dataTest}
                            // getValue={getValue}
                            // onBlur={() => input.onBlur(input.value)}
                            isMulti={isMulti}
                            noOptionsMessage={noOptionsMessage}
                            placeholder={placeholder}
                            styles={(touched && error) ? errorStyle : customStyles}
                            options={options}
                            formatGroupLabel={formatGroupLabel}
                        // class={(touched && error) ? { borderColor: '#f04d53', borderLeft: '3px solid #f04d53', cursor: 'pointer' } : { borderColor: '#9b2c59', boxShadow: 'none', cursor: 'pointer' }}
                        />
                        :
                        <select
                            {...input}
                            type={type}
                            className={className}
                            data-test={dataTest}
                            disabled={disabled}
                            style={(touched && error) ? { borderColor: '#f04d53', borderLeft: '3px solid #f04d53', cursor: 'pointer' } : { cursor: 'pointer' }}
                        >
                                <option value="">{placeholder ? placeholder : 'Select'}</option>
                            
                            {
                                options && options.length > 0 && options.map(option => {
                                    if (option.id)
                                        return <option data-test={option.name} key={option.id} value={option.id} className="custom_option">{option.name}</option>
                                    else
                                        return <option data-test={option.name} key={option} value={option.id} className="custom_option">{option}</option>
                                })
                            }
                        </select>
                }
                {touched &&
                    ((error && <span style={{ color: '#DD2726', fontSize: '13px' }} data-test="error_msg">{error}</span>) ||
                        (warning && <span>{warning}</span>))}
            {/* </div> */}
        </Aux>

    );
}
export const SelectField = ({ name, readOnly, changeFunction, getValue, onChange, isMulti, disabled, type, noOptionsMessage, label, className, options, placeholder, borderColor, instanceId, dataTest,CustomOption }) => (
    <Field
        name={name}
        readOnly={readOnly}
        changeFunction={changeFunction}
        label={label}
        getValue={getValue}
        type={type}
        isMulti={isMulti}
        disabled={disabled}
        onChange={onChange}
        instanceId={instanceId}
        noOptionsMessage={noOptionsMessage}
        className={className}
        placeholder={placeholder}
        options={options}
        dataTest={dataTest}
        // selectedValue={selectedValue}
        component={renderField}
        borderColor={borderColor}
        CustomOption={CustomOption}
    />
);

SelectField.propTypes = {
    name: PropTypes.string,
    onChange: PropTypes.func,
    isMulti: PropTypes.bool,
    label: PropTypes.string,
    disabled: PropTypes.bool,
    type: PropTypes.string,
    getValue: PropTypes.func,
    noOptionsMessage: PropTypes.func,
    placeholder: PropTypes.string,
    className: PropTypes.string,
    options: PropTypes.array,
    dataTest: PropTypes.string,
};

SelectField.defaultProps = {
    name: '',
    label: null,
    placeholder: '',
    className: '',
    options: [],
    borderColor: '#c3c3c3',
    dataTest: ''
};