import React, { useState } from 'react';
import ReactTable from 'react-table-v6';
import ReactTooltip from 'react-tooltip';
import '../../../../styles/custom.css';
import { CustomNoDataComponent, makeFirstLetterCapital, convertDateToDifferentTZ, converShifDetailstDate, converShiftScheduleTime } from '../../../../utility/utility';
import { FaArrowDown, FaArrowUp } from "react-icons/fa";
import * as actions from '../../../../redux/actions/index';
import { useSelector, useDispatch } from 'react-redux';
import { ClapSpinner } from 'react-spinners-kit';
import 'react-table-v6/react-table.css';
import storage from '../../../../utility/storage';
import { tzDateConverterTool_V1 } from '../../../../utility/TimezoneOperations/timezoneUtility';
var moment = require('moment');

let SwapListTable = (props) => {

    const dispatch = useDispatch();
    const SearchValue = useSelector(state => state.form.searchSwapListForm && state.form.searchSwapListForm.values ? state.form.searchSwapListForm.values : {});
    const DateGenerate = useSelector(state => state.TimeTrackerModReducer.dataGenerated);
    const values = useSelector(state => state.TimeTrackerModReducer.swapList_searchValues);
    const No_of_pages = useSelector(state => state.TimeTrackerModReducer.swapList_total_pages);
    const No_of_rows = useSelector(state => state.TimeTrackerModReducer.swapList_no_of_rows);
    const isSwapList = useSelector(state => state.TimeTrackerModReducer.isSwapList);
    const swapListCurPage = useSelector(state => state.TimeTrackerModReducer.swapListCurPage);
    const SearchOrResetShiftScheduleListClicked = useSelector(state => state.TimeTrackerModReducer.SearchOrResetShiftScheduleListClicked);

    const accountTimezone = 'America/Los_Angeles';

    const data = props.swapList && props.swapList.map((value) => {
        return {
            accountName: value && value.accounts ? value.accounts.map((item, i) => makeFirstLetterCapital(`${item.name}${value.accounts.length === i + 1 ? '' : ', '}`)) : '-',
            swap_from: value && value.fromShiftDetails && value.fromShiftDetails.date ? `${tzDateConverterTool_V1(value.fromShiftDetails.date)}` : '-',
            swap_to: value && value.toShiftDetails && value.toShiftDetails.date ? `${tzDateConverterTool_V1(value.toShiftDetails.date)}` : '-',
            assigned_to: value && value.assignedUser ? `${makeFirstLetterCapital(value.assignedUser.first_name)} ${makeFirstLetterCapital(value.assignedUser.last_name)}` : '-',
            swapping_with: value && value.swappingWith ? `${makeFirstLetterCapital(value.swappingWith.first_name)} ${makeFirstLetterCapital(value.swappingWith.last_name)}` : '-',
            status: value && value.status ? makeFirstLetterCapital(value.status) : '-',
            actions:
                <>

                    <ReactTooltip place="left" type="dark" effect="solid" />
                    <span data-tip="View Shift Swap Details" className="mr_20 account_edit_icn" onClick={() => { props.handleShiftSwapPreviewClick({ swapId: value.id, swapStatus: value.status }) }}  ><i class="fas fa-eye" aria-hidden="true" ></i></span>

                </>
        }
    })

    const columns = [
        // {
        //     Header: <><span> Job </span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /></>,
        //     accessor: 'accountName',
        //     show: props.swapList.length !== 0,
        // },
        {
            Header: <><span> Swap From</span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /></>,
            accessor: 'swap_from',
            show: props.swapList.length !== 0,
        },
        {
            Header: <><span> Swap To </span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /></>,
            accessor: 'swap_to',
            show: props.swapList.length !== 0,
        },
        {
            Header: <><span> Assigned User </span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /></>,
            accessor: 'assigned_to',
            show: props.swapList.length !== 0,
        },
        {
            Header: <><span> Swapping With </span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /></>,
            accessor: 'swapping_with',
            show: props.swapList.length !== 0,
        },
        {
            Header: <><span> Swap Status </span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /></>,
            accessor: 'status',
            show: props.swapList.length !== 0,
        },
        {
            Header: '',
            accessor: 'actions',
            width: 135,
        }
    ]

    const [tablePage, setTablePage] = useState(0)
    const tablePageNew = React.useRef()
    if (swapListCurPage !== tablePageNew.current) {
        const shiftId = storage.get('swapId')
        if (shiftId) {
            tablePageNew.current = swapListCurPage
        } else {
            tablePageNew.current = 0;
        }
    }
    const setTablePageFn = (page) => {
        setTablePage(page)
        props.swapListPageClicked(page)
        tablePageNew.current = page;
    }

    const fetchData = (state, instance) => {
        if (SearchOrResetShiftScheduleListClicked) {
            dispatch(actions.resetSearchOrResetInventory())
        } else {
            if (!DateGenerate && !props.isSwapList) {
                state.page = 2;
                const limit = state.pageSize;
                let page = tablePageNew.current >= 0 ? tablePageNew.current + 1 : swapListCurPage + 1;
                const inventoryItemsList_no_of_rows = state.pageSize;
                if (No_of_rows != inventoryItemsList_no_of_rows) {
                    page = 1;
                    setTablePageFn(0)
                }

                const order = state.sorted[0]?.id;
                const orderBy = state.sorted && state.sorted.length ? state.sorted[0]?.desc === true ? 'desc' : 'asc' : null;
                const status = SearchValue.shiftSwapRequestStatus ? typeof (SearchValue.shiftSwapRequestStatus) == 'object' ? SearchValue.shiftSwapRequestStatus.value : SearchValue.shiftSwapRequestStatus : null;
                props.getSwapList({ page, limit, order, orderBy, status }, values)
                storage.remove('swapId')
            }
        }
    }

    return (
        SearchOrResetShiftScheduleListClicked && props.isSwapList ?
            <div  >
                <ClapSpinner backColor="#89073c" size={40} frontColor="#89073c8a" />
            </div>
            :
            <ReactTable
                data={data}
                loading={props.isSwapList}
                minRows={0}
                defaultPageSize={No_of_rows}
                pages={No_of_pages}
                gotoPage={swapListCurPage}
                page={swapListCurPage}
                onPageChange={(page) => { setTablePageFn(page) }}
                // onPageSizeChange={props.onUserPageSizeChange}
                manual
                onFetchData={fetchData}
                showPaginationTop={true}
                showPaginationBottom={false}
                showPagination={props.swapList.length !== 0}
                columns={columns}
                // style={{marginTop: '10px'}}
                NoDataComponent={() => CustomNoDataComponent(props.isSwapList, 'No Swap Request List Found')}
                LoadingComponent={() =>
                    isSwapList ?
                        <div style={{
                            display: "block",
                            position: "absolute",
                            left: 0,
                            height: '100%',
                            right: 0,
                            background: "rgba(255,255,255,0.8)",
                            transition: "all .3s ease",
                            top: 0,
                            bottom: 0,
                            textAlign: "center"
                        }}
                            className="loader_btn_block">
                            <ClapSpinner backColor="#89073c" size={40} frontColor="#89073c8a" />
                        </div> : null}
                getTheadThProps={(state, rowInfo, column, instance) => {
                    // console.log(column);
                    return {
                        style: {
                            borderTop: 'none',
                            borderBottom: 'none',
                            borderRight: 'none',
                            textAlign: 'center',
                            color: '#566975',
                            fontSize: '14px',
                            fontWeight: '600',
                            whiteSpace: 'nowrap',
                            float: 'left',
                        }
                    }
                }
                }
                getTrProps={(state, rowInfo, column, instance) => {
                    // onclick = () => userDetail(rowInfo ? rowInfo.row.user : rowInfo);
                    console.log(column);
                    return {
                        className: 'react-table-tr-element',
                        style: {
                            border: '1px solid #c7cdd1',
                            transition: '.3s ease-in-out',
                            width: '100%',
                            // borderRadius: '4px',
                            // height: '80px',
                            marginTop: '5px',
                            float: 'left'
                        }
                    }

                }}
                getTdProps={(state, rowInfo, column, instance) => {

                    if (column.id == 'image') {
                        return {
                            style: {
                                marginTop: '0px'
                            }
                        }
                    } else {
                        return {
                            className: 'react-td-element',
                        }
                        //  {
                        //   style: {
                        //     borderTop: 'none',
                        //     verticalAlign: 'middle',
                        //     fontSize: '14px',
                        //     color: '#2b3034',
                        //     marginTop: '10px !important',
                        //     textAlign: 'center',
                        //   }
                        // }
                    }
                }
                }
                // getPaginationProps={() => {
                //     return {
                //         style: {
                //             marginTop: '50px',
                //             border: 'none',
                //             boxShadow: 'none'
                //         }
                //     }
                // }
                // }
                getProps={() => {
                    return {
                        style: {
                            border: 'none'
                        }
                    }
                }}
            />
    )
}

export default SwapListTable;