import React from 'react'
import { Input } from '../../../../UI/InputElement/reactFormHookInputField'
import { useFieldArray } from 'react-hook-form'


function QuestionField(props) {
    const { fields: questionField } = useFieldArray({
        control: props.control,
        name: `auditorSections[${props.index}].auditorSectionsLineItems`
    })
    return (
        <>
            {
                questionField.length > 0 ? questionField.map((question, index) => {
                    return (
                        <div class="selfaudit_checkpoint">
                            <p>{question?.title}?</p>
                            <div class="selfaudit_checkboxwrap">
                                <Input type="radio-custom" {...props.register(`auditorSections[${props.index}].auditorSectionsLineItems[${index}].is_checked`)} >
                                    <props.controller
                                        name={`auditorSections[${props.index}].auditorSectionsLineItems[${index}].is_checked`}
                                        control={props.control}
                                        defaultValue={null} // set your default value accordingly
                                        render={({ field: { ref, onChange, name, value } }) => {

                                            return (
                                                <>

                                                    <div class="custom_checkbox">
                                                        <div class="form-group">
                                                            <input type="radio" value={`true`} id={`auditorSections[${props.index}].auditorSectionsLineItems-${index}-correct`} ref={ref} checked={value === "true"} onChange={(e) => {

                                                                onChange(e.target.value)
                                                            }} disabled={props.isView} />
                                                            <label for={`auditorSections[${props.index}].auditorSectionsLineItems-${index}-correct`}>Correct</label>
                                                        </div>
                                                    </div>
                                                    <div class="custom_checkbox">
                                                        <div class="form-group">
                                                            <input type="radio" id={`auditorSections[${props.index}].auditorSectionsLineItems-${index}-incorrect`} value={"false"} ref={ref} checked={value == "false"} onChange={(e) => {

                                                                onChange(e.target.value)
                                                            }} disabled={props.isView} />
                                                            <label for={`auditorSections[${props.index}].auditorSectionsLineItems-${index}-incorrect`}>Incorrect</label>
                                                        </div>
                                                    </div>


                                                </>
                                            )
                                        }}
                                    />
                                </Input>
                                <div class="custom_notesbox">
                                    <div class="form-group mb-0">
                                        <Input
                                            type="text"
                                            placeholder={'Notes'}
                                            name={`auditorSections[${props.index}].auditorSectionsLineItems[${index}].notes`}
                                            id={`auditorSections[${props.index}].auditorSectionsLineItems[${index}].notes`}
                                            disabled={props.isView}
                                            formControlProps={props.register(`auditorSections[${props.index}].auditorSectionsLineItems[${index}].notes`, {
                                                required: false,
                                                // pattern: {
                                                //   value:/^[0-9]*$/ ,
                                                //   message:"Must Be a number"
                                                // },
                                                maxLength: 80,
                                            })}
                                            //onChange={handleChange}
                                        />
                                    </div>
                                </div>
                            </div>

                        </div>
                    )
                }) : null
            }

        </>
    )
}

export default QuestionField
