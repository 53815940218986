import React, { useEffect } from "react";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";
//import { getManagersList } from "../../../../../../redux/actions";

import MonthlyTablePageHook from "../../../../../component/FormAuditModule/Inventory/MonthlyCostSummaryAnalysis/MonthlyTablePageHook";

function MonthlyTablePageContainer(props) {
  
   
  return (
    <MonthlyTablePageHook
      elements={props.costData ? props.costData :[]}
      refresh={props.refresh}
      
    />
  );
}

export default MonthlyTablePageContainer;