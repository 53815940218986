import React, { Component } from 'react';
import NewRating from '../../../component/Ratings/NewRating/newRatings';
import * as actions from '../../../../redux/actions/index';
import { RolesOptions } from '../../../../utility/constants/constants'
import { routes, address_attributes, RatingTypes } from '../../../../utility/constants/constants';
import { connect } from 'react-redux';
import isEmpty from '../../../../utility/IsEmptyValidator';
import { decode, encode } from 'base64-arraybuffer';
import getDetailAddress from '../../../../utility/getDetailAddress';

const cloneDeep = require('clone-deep');

class NewRatingContainer extends Component {

    constructor(props) {
        super(props);

        this.state = {
            profileImageName: '',
            base64: null,
            extension: null,
            address_attributes: isEmpty(this.props.user) ? address_attributes : this.props.user.user.address,
            isAddressEmpty: false,
            isZipEmpty: false,
            idsToDeleteLineItems: [],
            ratingType: this.props.match.params.ratingId ? this.props.rating.category : RatingTypes.Percentage

        }
        this.inputOpenFileRef = React.createRef();
        this.choosePhotoClicked = this.choosePhotoClicked.bind(this);
        this.onFileChange = this.onFileChange.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleAddressSelect = this.handleAddressSelect.bind(this);
    }

    onCancelClicked = () => {
        this.props.history.push(routes.USER);
    }

    onFileChange = (e) => {

        let reader = new FileReader();
        let file = e.target.files[0];
        reader.onloadend = () => {
            this.setState({
                extension: file.name.split('.').pop().toLowerCase(),
                profileImageName: file.name,
                base64: reader.result,
            })
        }
        reader.readAsDataURL(file)
        // reader.readAsArrayBuffer(file)
    }

    choosePhotoClicked = () => {
        this.inputOpenFileRef.current.click();
    }

    handleAddressChange = address => {
        let addressAttributes = { ...this.state.address_attributes };
        addressAttributes.formatted_address = address;

        this.setState({ address_attributes: addressAttributes, isAddressEmpty: false });
    };

    async handleAddressSelect(address) {
        const addressFields = await getDetailAddress(address);
        this.setState({
            address_attributes: addressFields,
            isAddressEmpty: false
        })
    };

    handleInputChange = (e) => {
        console.log(this.state);
        if (e.target.name === 'zip') {
            let addressFields = cloneDeep(this.state.address_attributes);
            addressFields.zip = e.target.value;
            this.setState({
                address_attributes: addressFields,
                isZipEmpty: false
            })
        } else {
            this.setState({
                about_me: e.target.value,
            })
        }
    }


    componentDidUpdate(prevProps, prevState, snapshot) {

        if (this.props.rating && this.props.rating !== prevProps.rating) {

            this.setState({
                ratingType: this.props.rating.category
            })
        }
    }


    componentDidMount() {
        if (isEmpty(this.props.rating) && this.props.match.params.ratingId) {
            this.props.getEditRating(this.props.match.params.ratingId);
        }
    }

    addNewRating = (values) => {
        const value = cloneDeep(values)
        if(value.rating_type.count) {
            delete value.rating_type.count;
        }
        if(value.rating_type.rating_options_attributes && value.rating_type.rating_options_attributes.length > 0){
            value.rating_type.rating_options_attributes.map((rat, i) => {
                value.rating_type.rating_options_attributes[i]['color_code'] = rat.color_code ? rat.color_code : ''
            })
        }
        if (this.props.match.params.ratingId) {
            if (this.state.idsToDeleteLineItems.length > 0) {
                this.props.deleteRatingLineItem(value, this.state.idsToDeleteLineItems);
            } else {
                this.props.updateRating(value);
            }

        } else {

            value.rating_type['category'] = this.state.ratingType;
            this.props.addNewRating(value);
        }
    }


    componentWillUnmount() {
        this.props.resetObjects();
    }

    addNewRating = (values) => { 
        const value = cloneDeep(values)
        if(value.rating_type.count) {
            delete value.rating_type.count;
        }
        if(value.rating_type.rating_options_attributes && value.rating_type.rating_options_attributes.length > 0){
            value.rating_type.rating_options_attributes.map((rat, i) => {
                value.rating_type.rating_options_attributes[i]['color_code'] = rat.color_code ? rat.color_code : ''
            })
        }
        if (this.props.match.params.ratingId) {
            if (this.state.idsToDeleteLineItems.length > 0) {
                this.props.deleteRatingLineItem(value, this.state.idsToDeleteLineItems);
            } else {
                this.props.updateRating(value);
            }

        } else {

            value.rating_type['category'] = this.state.ratingType;
            this.props.addNewRating(value);
        }
    }

    cancelClicked = () => {
        this.props.history.push(routes.RATINGS);
    }

    updateRatingClicked = (rating) => { 

        this.setState({
            ratingType: rating
        })
    }

    deleteRatingLineItem = (id, ratingId) => {
        const IdsToDelete = [{
            lineItemId: id,
            ratingId: ratingId
        }]
        this.setState((prevState) => {
            return {
                idsToDeleteLineItems: [...prevState.idsToDeleteLineItems, ...IdsToDelete]
            }
        })
        // this.props.deleteRatingLineItem(id, ratingId);
    }

    render() {

        return (
            <NewRating
                s3Url={isEmpty(this.props.user) ? '' : this.props.user.user.photo_urls.original}
                user={this.user}
                history={this.props.history}
                deleteRatingLineItem={this.deleteRatingLineItem}
                updateRatingClicked={this.updateRatingClicked}
                ratingType={this.state.ratingType}
                cancelClicked={this.cancelClicked}
                addNewRating={this.addNewRating}
                rating={this.props.rating}
                isEdit={this.props.match.params.ratingId ? true : false}
                reference={this.inputOpenFileRef}
                choosePhotoClicked={this.choosePhotoClicked}
                base64={this.state.base64}
                onFileChange={this.onFileChange}
                onCancelClicked={this.onCancelClicked}
                isLoading={this.props.isLoading}
                // isAddressEmpty={this.state.isAddressEmpty}
                // isZipEmpty={this.state.isZipEmpty}
                // handleAddressChange={this.handleAddressChange}
                handleInputChange={this.handleInputChange}
            // handleAddressSelect={this.handleAddressSelect}
            // address_attributes={this.state.address_attributes}
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.adminOrSuperAdminReducer.requestor,
        isLoading: state.adminOrSuperAdminReducer.isLoading,
        regionList: state.adminOrSuperAdminReducer.regionList,
        rating: state.adminOrSuperAdminReducer.rating,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        addNewRating: (newRating) => dispatch(actions.addNewRating(newRating)),
        updateRating: (values) => dispatch(actions.updateRating(values)),
        resetObjects: () => dispatch(actions.resetObjects()),
        getEditRating: (id) => dispatch(actions.getEditRating(id)),
        deleteRatingLineItem: (rating, IdsToDelete) => dispatch(actions.deleteRatingLineItem(rating, IdsToDelete))

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewRatingContainer);