export const auditsActionTypes = {
    GET_ALL_PERFORMANCE_AUDIT: 'GET_ALL_PERFORMANCE_AUDIT',
    GET_ALL_PERFORMANCE_AUDIT_PENDING: 'GET_ALL_PERFORMANCE_AUDIT_PENDING',
    GET_ALL_PERFORMANCE_AUDIT_FULFILLED: 'GET_ALL_PERFORMANCE_AUDIT_FULFILLED',

    PERFORMANCE_AUDIT_LIST_PAGE_CLICKED : 'PERFORMANCE_AUDIT_LIST_PAGE_CLICKED',

    PERFORMANCE_AUDIT_PAGE_CLICKED : 'PERFORMANCE_AUDIT_PAGE_CLICKED',

    GET_ALL_PERFORMANCE_AUDIT_QUESTIONS: 'GET_ALL_PERFORMANCE_AUDIT_QUESTIONS',
    GET_ALL_PERFORMANCE_AUDIT_QUESTIONS_PENDING: 'GET_ALL_PERFORMANCE_AUDIT_QUESTIONS_PENDING',
    GET_ALL_PERFORMANCE_AUDIT_QUESTIONS_FULFILLED: 'GET_ALL_PERFORMANCE_AUDIT_QUESTIONS_FULFILLED',

    GET_ALL_SELF_AUDIT_QUESTIONS: 'GET_ALL_SELF_AUDIT_QUESTIONS',
    GET_ALL_SELF_AUDIT_QUESTIONS_PENDING: 'GET_ALL_SELF_AUDIT_QUESTIONS_PENDING',
    GET_ALL_SELF_AUDIT_QUESTIONS_FULFILLED: 'GET_ALL_SELF_AUDIT_QUESTIONS_FULFILLED',

    ADD_SELF_AUDIT_PAGE: 'ADD_SELF_AUDIT_PAGE',
    ADD_SELF_AUDIT_PAGE_PENDING: 'ADD_SELF_AUDIT_PAGE_PENDING',
    ADD_SELF_AUDIT_PAGE_FULFILLED: 'ADD_SELF_AUDIT_PAGE_FULFILLED',

    GET_SINGLE_SELF_AUDIT_PAGE: 'GET_SINGLE_SELF_AUDIT_PAGE',
    GET_SINGLE_SELF_AUDIT_PAGE_PENDING: 'GET_SINGLE_SELF_AUDIT_PAGE_PENDING',
    GET_SINGLE_SELF_AUDIT_PAGE_FULFILLED: 'GET_SINGLE_SELF_AUDIT_PAGE_FULFILLED',

    EDIT_SELF_AUDIT_PAGE: 'EDIT_SELF_AUDIT_PAGE',
    EDIT_SELF_AUDIT_PAGE_PENDING: 'EDIT_SELF_AUDIT_PAGE_PENDING',
    EDIT_SELF_AUDIT_PAGE_FULFILLED: 'EDIT_SELF_AUDIT_PAGE_FULFILLED',

    GET_ALL_SELF_AUDIT_PAGE: 'GET_ALL_SELF_AUDIT_PAGE',
    GET_ALL_SELF_AUDIT_PAGE_PENDING: 'GET_ALL_SELF_AUDIT_PAGE_PENDING',
    GET_ALL_SELF_AUDIT_PAGE_FULFILLED: 'GET_ALL_SELF_AUDIT_PAGE_FULFILLED',

    ADD_PERFORMANCE_AUDIT:'ADD_PERFORMANCE_AUDIT',
    ADD_PERFORMANCE_AUDIT_PENDING: 'ADD_PERFORMANCE_AUDIT_PENDING',
    ADD_PERFORMANCE_AUDIT_FULFILLED: 'ADD_PERFORMANCE_AUDIT_FULFILLED',
    ADD_PERFORMANCE_AUDIT_REJECTED:'ADD_PERFORMANCE_AUDIT_REJECTED',

    GET_SINGLE_PERFORMANCE_AUDIT:'GET_SINGLE_PERFORMANCE_AUDIT',
    GET_SINGLE_PERFORMANCE_AUDIT_PENDING: 'GET_SINGLE_PERFORMANCE_AUDIT_PENDING',
    GET_SINGLE_PERFORMANCE_AUDIT_FULFILLED: 'GET_SINGLE_PERFORMANCE_AUDIT_FULFILLED',
    GET_SINGLE_PERFORMANCE_AUDIT_REJECTED:'GET_SINGLE_PERFORMANCE_AUDIT_REJECTED',

    EDIT_PERFORMANCE_AUDIT:'EDIT_PERFORMANCE_AUDIT',
    EDIT_PERFORMANCE_AUDIT_PENDING: 'EDIT_PERFORMANCE_AUDIT_PENDING',
    EDIT_PERFORMANCE_AUDIT_FULFILLED: 'EDIT_PERFORMANCE_AUDIT_FULFILLED',
    EDIT_PERFORMANCE_AUDIT_REJECTED:'EDIT_PERFORMANCE_AUDIT_REJECTED',

    PERFORMANCE_AUDIT_EDIT_ID : 'PERFORMANCE_AUDIT_EDIT_ID',
    SELF_AUDIT_LIST_PAGE_CLICKED : 'PERFORMANCE_AUDIT_LIST_PAGE_CLICKED',


}