import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { change, getFormValues, reduxForm } from 'redux-form';
import { Input } from '../../../UI/InputElement/InputElement';
import isEmpty from '../../../../../utility/IsEmptyValidator';
import { getUser, makeFirstLetterCapital } from '../../../../../utility/utility';
import { newAssetValidatorPage as validate } from '../../../../../utility/validator/validator';
import AssetsFormSkeleton from './AssetsFormSkeleton';
import moment from "moment";
import cloneDeep from 'clone-deep';
import { useDispatch } from 'react-redux';
import { convertDateTimezoneFormAudit, convertDateTimezoneFormAuditReduxForm } from '../../../../../utility/TimezoneOperations/timezoneUtility';
const defaultForm={
    name:"",
    serial_number:"",
    salvage_date:"",
    cost_at_replacement:"",
    life_expectancy:"",
    location:"",
    facility:"",
    purchase_date:"",
    expiration_date:"",
    supplier:"",
    manufacture:"",
    initial_quantity:""

}



let NewAssetsForm = (props) => {

  const dispatch=useDispatch()
    const assetsOptions = [
        {
            id: 'inService',
            name: 'In Service'
        },
        {
            id: 'notInService',
            name: 'Not In Service'
        },
        {
            id: 'underRepair',
            name: 'Under Repair'
        },
    ];

    const getInventoryLocationList = (props) => {
        if (props.inventoryLocationList && props.inventoryLocationList.length > 0) {
            return props.inventoryLocationList && props.inventoryLocationList.map((location) => {
                return {
                    value: location.id,
                    label: makeFirstLetterCapital(location.name)
                }
            })
        } else {
            return []
        }
    };

    const [locationList, setLocationList] = useState(getInventoryLocationList(props));

    useEffect(() => {
        setLocationList(getInventoryLocationList(props))
    }, [props.inventoryLocationList])

    
    

    const [addExistItemSlots, setAddExistItemSlots] = useState([""]);

    const noOptionsMessage = (a, b) => {
        return 'No options';
    }
   
    const handleCreateExistItemSlots = () => {
        
        let items=cloneDeep( props.formStates.asset.item)
        let clonedItems=cloneDeep(items)
        let slots = [...clonedItems];
        slots.push(defaultForm);
        
        setAddExistItemSlots(slots);
        dispatch(change('newAssetsForm', `asset[item]`, slots));
        
    }

    const handleDeleteExistItemSlots = (index) => {
        let itemSlots = [...addExistItemSlots];
        itemSlots.splice(index, 1);
        
        setAddExistItemSlots(itemSlots);
        dispatch(change('newAssetsForm', `asset[item]`, itemSlots));

        // let quantitySlots = [...maxQuantity];
        // quantitySlots.splice(index, 1);
        // setMaxQuantity(quantitySlots);

        // if ((itemSlots && itemSlots.length) && (props.formStates.ticket && props.formStates.ticket.items)) {
        //     let items = props.formStates.ticket.items;
        //     items.splice(index, 1);
        //     change("ticket[items]", items);
        // } else {
        //     change("ticket[items]", [{ itemId: '', itemQuantity: '' }]);
        // }
    };

    const { handleSubmit, pristine, reset, submitting, error, submitFailed } = props;
    console.log("FormState",props);
    

    if (props.isUpdateAsset) {
        return (
            <AssetsFormSkeleton />
        )
    } else {
        return (
            <main className="app-content wraper_content user_newQuote add_account_modal add_New_Accont add_newAccount">
                <div className="inspection_content_sec appcontent_Inner">
                    <div className="container-fluids">
                        <div className="quote_form ticket_form" style={{ maxWidth: '700px' }}>
                            <div className="row">
                                <div className="col-sm-12 text-center"> <h2 className="md_title" >{props.isEditAsset ? "Update Asset" : "Add Asset"}</h2></div>
                            </div>
                            <div className="addQuote_form">
                                <form className="form_content" onSubmit={handleSubmit} >
                                           
                                                {
                                                    addExistItemSlots.map((item,index)=>{
                                                        return(
                                                            <div className="cst_tab_content">

                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label className="form_title"> Asset Name <sup className="cst_sup_txt text_danger">*</sup></label>
                                                                <Input dataTest="" type="text" className="form-control input-modifier" placeholder="Asset Name" name={`asset[item][${index}][name]`}  /> 
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label className="form_title">  Serial Number <sup className="cst_sup_txt text_danger">*</sup></label>
                                                                <Input dataTest="" type="text" className="form-control input-modifier" placeholder=" Serial Number " name={`asset[item][${index}][serial_number]`}/>
                                                            </div>
                                                        </div>
                                                        
                                                    </div>

                                                    <div className="row">
                                                    <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label className="form_title"> Quantity <sup className="cst_sup_txt text_danger">*</sup></label>
                                                                <Input dataTest="" type="numberWithIncDec" min={props.initialValues && props?.initialValues?.asset?.item?.[0]?.initial_quantity ? props.initialValues.asset.item[0].initial_quantity : 0} className="form-control input-modifier" placeholder="Enter Quantity" name={`asset[item][${index}][initial_quantity]`} />
                                                                {parseInt(props?.initialValues?.asset?.item?.[0]?.initial_quantity) === parseInt(props?.formStates?.asset?.item?.[0]?.initial_quantity) ? <p className='text-danger'>Quantity can't be decreased here; please delete it from Sub-Asset stocks</p> : null}
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label className="form_title"> Manufacturer <sup className="cst_sup_txt text_danger">*</sup></label>
                                                                <Input dataTest="" type="text" className="form-control input-modifier" placeholder="Manufacturer Name" name={`asset[item][${index}][manufacture]`} />
                                                            </div>
                                                        </div>
                                                        
                                                    </div>

                                                    <div className="row">
                                                    <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label className="form_title"> Suppliers <sup className="cst_sup_txt text_danger">*</sup></label>
                                                                <Input dataTest="" type="text" className="form-control input-modifier" placeholder="Enter Suppliers Name" name={`asset[item][${index}][supplier]`} />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label className="form_title"> Next Service Date <sup className="cst_sup_txt text_danger">*</sup></label>
                                                                <div className="input-group cst_input_group col-lg-14 pl-0">
                                                                    <div style={{ width: '100%' }}>
                                                                        <Input
                                                                            type="date"
                                                                            datePickerType={"react-datepicker"}
                                                                            minDate={new Date()}
                                                                            dateFormat="MM-dd-yyyy"
                                                                            className="form-control input-modifier"
                                                                            placeholder="Select Date"
                                                                            name={`asset[item][${index}][expiration_date]`}
                                                                        />
                                                                    </div>
                                                                    <div className="input-group-append cursor-pointer" style={{ float: "right", position: "absolute", right: 0, height: "45px", top: 0 }}>
                                                                        <span class="input-group-text" id="basic-addon2"> <i class="fa fa-calendar"></i> </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* new Data */}

                                                        
                                                    </div>

                                                    {/* New Row */}


                                                    <div className="row">
                                                    <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label className="form_title"> Purchase Date <sup className="cst_sup_txt text_danger">*</sup></label>
                                                                <div className="input-group cst_input_group col-lg-14 pl-0">
                                                                    <div style={{ width: '100%' }}>
                                                                        <Input
                                                                            type="date"
                                                                            datePickerType={"react-datepicker"}
                                                                            minDate={new Date()}
                                                                            dateFormat="MM-dd-yyyy"
                                                                            className="form-control input-modifier"
                                                                            placeholder="Select Date"
                                                                            name={`asset[item][${index}][purchase_date]`}
                                                                        />
                                                                    </div>
                                                                    <div className="input-group-append cursor-pointer" style={{ float: "right", position: "absolute", right: 0, height: "45px", top: 0 }}>
                                                                        <span class="input-group-text" id="basic-addon2"> <i class="fa fa-calendar"></i> </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label className="form_title"> Initial Cost <sup className="cst_sup_txt text_danger">*</sup></label>
                                                                <Input dataTest="" type="number" className="form-control input-modifier" placeholder="Initial Cost" name={`asset[item][${index}][initial_cost]`} />
                                                            </div>
                                                        </div>
                                                        
                                                    </div>

                                                    {/* New Row */}

                                                    <div className='row'>
                                                    <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label className="form_title"> Facility <sup className="cst_sup_txt text_danger">*</sup></label>
                                                                <Input dataTest="" type="text" className="form-control input-modifier" placeholder="Facility" name={`asset[item][${index}][facility]`} />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label className="form_title"> Location <sup className="cst_sup_txt text_danger">*</sup></label>
                                                                <Input type="text" placeholder="Location" className="form-control input-modifier"
                                                                    name={`asset[item][${index}][location]`} />
                                                            </div>
                                                        </div>
                                                        
                                                    </div>

                                                    {/* New Row */}

                                                    <div className="row">
                                                    <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label className="form_title"> Life Expectancy <sup className="cst_sup_txt text_danger">*</sup></label>
                                                                <Input dataTest="" type="text" className="form-control input-modifier" placeholder="Life Expectancy " name={`asset[item][${index}][life_expectancy]`} />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label className="form_title"> Cost at Replacement <sup className="cst_sup_txt text_danger">*</sup></label>
                                                                <Input dataTest="" type="number" className="form-control input-modifier" placeholder="Cost at Replacement " name={`asset[item][${index}][cost_at_replacement]`} />
                                                            </div>
                                                        </div>
                                                       
                                                    </div>


                                                    {/* New Row */}

                                                    <div className="row">
                                                    <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label className="form_title"> Salvage Date <sup className="cst_sup_txt text_danger">*</sup></label>
                                                                <div className="input-group cst_input_group col-lg-14 pl-0">
                                                                    <div style={{ width: '100%' }}>
                                                                        <Input
                                                                            type="date"
                                                                            datePickerType={"react-datepicker"}
                                                                            minDate={new Date()}
                                                                            dateFormat="MM-dd-yyyy"
                                                                            className="form-control input-modifier"
                                                                            placeholder="Select Date"
                                                                            name={`asset[item][${index}][salvage_date]`}
                                                                        />
                                                                    </div>
                                                                    <div className="input-group-append cursor-pointer" style={{ float: "right", position: "absolute", right: 0, height: "45px", top: 0 }}>
                                                                        <span class="input-group-text" id="basic-addon2"> <i class="fa fa-calendar"></i> </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label className="form_title"> Manufacturer Serial Number <sup className="cst_sup_txt text_danger">*</sup></label>
                                                                <Input dataTest="" type="number" className="form-control input-modifier" placeholder="Manufacturer Serial Number " name={`asset[item][${index}][manufacturer_serial_number]`}/>
                                                            </div>
                                                        </div>
                                                    </div>


                                                    <div style={{ marginTop: '35px' }} role="group" aria-label="Basic example">
                                                                    {index != 0 && <button type="button" class="btn btn-dark" onClick={e => handleDeleteExistItemSlots(index)}><i className="far fa-trash-alt"></i></button>}
                                                    </div>

                                                    {/* <div className="row">
                                                            <div className="col-md-6">
                                                            <div className="form-group">
                                                            <label className="label_modifier">Asset Status</label>
                                                            <Input instanceId="job_select"  type="select" options={assetsOptions}    placeholder="Select Status" className="custom-select input-modifier add_user_select" name="item[status]" />
                                                        </div>
                                                                </div>
                                                            </div>  */}

                                                </div>
                                                        )
                                                    })
                                                }

                                    {
                                        !props.isEditAsset ? <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                        <button
                                            type='button'
                                            className='btn btn-outline-danger'
                                            style={{ fontSize: '14px', fontWeight: 'bolder', }}
                                            onClick={() => { handleCreateExistItemSlots(); }}
                                        >
                                            + Add More New Item 
                                        </button>
                                    </div> : null
                                    }
                                    
                                    <div className="modal-footer">
                                        <button data-test="submit_button" type="submit" disabled={props.isLoading} className={props.isLoading ? "btn cst_btn btn_danger btn-wait w-25" : "btn cst_btn btn_danger w-25"} >{props.isLoading ? "" : props.isEditAsset ? "Update Asset" : "Add Asset"}</button>
                                        <button data-test="cancel_button" type="button" className="btn cst_btn btn-outline-secondary cancel_btnnew w-25" onClick={() => { props.onCancelClicked() }}>Cancel</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </main >
        )
    }
}

const mapStateToProps = (state) => {
   
    let item = {};
    
    if (isEmpty(state.InventoryReducer.singleAssetData)) {
        item = [{
          ...defaultForm
        }]
    } else {
        const values = cloneDeep(state.InventoryReducer && state.InventoryReducer.singleAssetData);
        item = [{
            name: values && values.product && values.product.name ? makeFirstLetterCapital(values.product.name)  : null,
            initial_quantity: values && values.product && values.product.item && values.product.item.initial_quantity ? values.product.item.initial_quantity : null,
            manufacture: values && values.product && values.product.manufacture ? makeFirstLetterCapital(values.product.manufacture) : null,
            expiration_date: values && values.product && values.product.expiration_date ? convertDateTimezoneFormAuditReduxForm(values.product.expiration_date): null,
            purchase_date: values && values.product && values.product.purchase_date ? convertDateTimezoneFormAuditReduxForm(values.product.purchase_date) : null,
            initial_cost: values && values.product && values.product.initial_cost ? values.product.initial_cost : null,
            facility: values && values.product && values.product.facility ? makeFirstLetterCapital(values.product.facility) : null,
            life_expectancy: values && values.product && values.product.life_expectancy ? makeFirstLetterCapital(values.product.life_expectancy) : null,
            cost_at_replacement: values && values.product && values.product.cost_at_replacement ? values.product.cost_at_replacement : null,
            salvage_date: values && values.product && values.product.salvage_date ? convertDateTimezoneFormAuditReduxForm(values.product.salvage_date) : null,
            manufacturer_serial_number: values && values.product && values.product.manufacturer_serial_number ? values.product.manufacturer_serial_number : null,
            location: values && values.product && values.product.location ? values.product.location : null,
            serial_number: values && values.product && values.product.serial_number ? values.product.serial_number : null,
            supplier: values && values.product && values.product.supplier ? values.product.supplier : null,


        }]
    }
    
    return { initialValues: { asset:{
        item
    } }, formStates: getFormValues('newAssetsForm')(state) }

}

NewAssetsForm = reduxForm({
    form: 'newAssetsForm',
    validate,
    enableReinitialize: true
})(NewAssetsForm);

NewAssetsForm = connect(mapStateToProps)(NewAssetsForm)

export default NewAssetsForm;