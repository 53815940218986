import React, { useEffect, useState } from 'react';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import InventoryListTable from './InventoryListTable';
import { Input } from '../../UI/InputElement/InputElement';
import { convertDateToPickerFormat } from '../../../../utility/utility';

let InventoryList = (props) => {

  const { handleSubmit, pristine, reset, submitting, error } = props;

  const isOS1Options = [
    {
      id: 'yes',
      name: 'Yes'
    },
    {
      id: 'no',
      name: 'No'
    },
  ];

  return (
    <>
      <main className="app-content wraper_content inspection_wraper">
        <div className="tab_header search_filter_cont inspection_tab_content appcontent_inner frontlines_filter">
          <form onSubmit={handleSubmit} >
            <div className="row mt-2">
              <div className="col-xl-2 col-lg-3 col-md-6 col-sm-6 col-12">
                <div className="app-search">
                  <Input className="fil_search_input input-modifier" name="name" type="search" placeholder="Search by Items" dataTest="frontline_name_filter" />
                  <button className="app-search__button">
                    <i class="fa fa-search" aria-hidden="true"></i>
                  </button>
                </div>
              </div>
              <div className="col-xl-2 col-lg-3 col-md-6 col-sm-6 col-12">
                <div className="app-search">
                  <Input className="fil_search_input input-modifier" name="manufacture" type="search" placeholder="Search by Manufacture" dataTest="frontline_id_filter" />
                  <button className="app-search__button">
                    <i class="fa fa-search" aria-hidden="true"></i>
                  </button>
                </div>
              </div>
              <div className="col-xl-2 col-lg-3 col-md-6 col-sm-6 col-12">
                <div className="app-search">
                  <Input className="fil_search_input input-modifier" name="supplier" type="search" placeholder="Search by Supplier" dataTest="frontline_id_filter" />
                  <button className="app-search__button">
                    <i class="fa fa-search" aria-hidden="true"></i>
                  </button>
                </div>
              </div>
              <div class="col-xl-2 col-lg-4 col-md-6 col-sm-6">
                <label>
                  <Input name="expiration_date" minDate={new Date()} dateFormat="MM-dd-yyyy" placeholder="Expiration Date" datePickerType={"react-datepicker"} type="date" className="form-control input-modifier" />
                  <div class="input-group-append cst_group_append">
                    <span class="input-group-text" id="basic-addon2"><i class="fa fa-calendar"></i></span>
                  </div>
                </label>
              </div>
              <div className="col-xl-2 col-lg-4 col-md-6 col-sm-6">
                <Input name="os1" dataTest="select_ticket_status" type="select" options={isOS1Options} placeholder="Search by OS1 Items" className="custom-select input-modifier add_user_select" />
              </div>
              <div className="col-xl-2 col-lg-3 col-md-6 col-sm-6 col-12">
                <div className="frontline_btn">
                  <button data-test="filter_go_btn" style={{ minWidth: '90px' }} className={"btn cst_btn btn_danger mr-2"} id="submit" type="submit">{"Go"} </button>
                  <button data-test="filter_reset_btn" style={{ minWidth: '90px' }} onClick={props.resetClicked} className={"btn cst_btn btn-outline-secondary"} id="reset" type="reset">{"Reset"} </button>
                </div>
              </div>
            </div>
          </form>
        </div>
        <section className="account_sec inspection_tab_content appcontent_Inner">

          <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px', marginBottom: '20px' }}>
            <div className="row"> <div className="col-xl-6 col-lg-4 col-md-4 col-sm-12">
              <button className='btn cst_btn btn_danger' onClick={() => { props.addInventoryItemClicked() }}> Add Inventory </button>
            </div> </div>
          </div>

          <div className="quotes_table_content accounts_table_contnet table-responsive">
            <div className="table quotes_table user_react_table">
              <InventoryListTable
                {...props}
              />
            </div>
          </div>

        </section>
      </main>
      <Dialog
        open={props.openInventoryDeleteModal}
        onClose={props.handleInventoryCloseDeleteModal}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title" style={{ fontSize: '13', color: '#06080f' }}>
          <h3 style={{ color: '#ab385e' }} data-test="modal_delete_title">Are you sure?</h3>
        </DialogTitle>

        <DialogActions>
          <button data-test="modal_delete" style={{ minHeight: '36px', minWidth: '80px' }} onClick={() => props.deleteInventoryItem(props.deleteInventoryObject)} disabled={props.isLoading} className={props.isLoading ? "btn cst_btn btn_danger mr-2 btn-wait" : "btn cst_btn btn_danger mr-2"} id="delete" type="delete">{props.isLoading ? "" : "Delete"}</button>
          <button data-test="modal_cancel" style={{ minHeight: '36px', minWidth: '80px' }} onClick={props.handleInventoryCloseDeleteModal} className={"btn cst_btn btn-outline-secondary"} id="cancel" type="cancel">Cancel</button>
        </DialogActions>
      </Dialog>
    </>

  )
}

const mapStateToProps = (state) => {
  let name = state.InventoryReducer.inventoryItems_searchValues && state.InventoryReducer.inventoryItems_searchValues.name ? state.InventoryReducer.inventoryItems_searchValues.name : "";
  let manufacture = state.InventoryReducer.inventoryItems_searchValues && state.InventoryReducer.inventoryItems_searchValues.manufacture ? state.InventoryReducer.inventoryItems_searchValues.manufacture : "";
  let expiration_date = state.InventoryReducer.inventoryItems_searchValues && state.InventoryReducer.inventoryItems_searchValues.expiration_date ? convertDateToPickerFormat(state.InventoryReducer.inventoryItems_searchValues.expiration_date) : "";
  let supplier = state.InventoryReducer.inventoryItems_searchValues && state.InventoryReducer.inventoryItems_searchValues.supplier ? state.InventoryReducer.inventoryItems_searchValues.supplier : "";
  let os1 = state.InventoryReducer.inventoryItems_searchValues && state.InventoryReducer.inventoryItems_searchValues.os1 ? state.InventoryReducer.inventoryItems_searchValues.os1 : "";

  return { initialValues: { name, manufacture, expiration_date, supplier ,os1} }
}

InventoryList = reduxForm({
  form: 'searchInventoryItemsList',
  // validate,
  enableReinitialize: true
})(InventoryList);

InventoryList = connect(mapStateToProps)(InventoryList)

export default InventoryList;