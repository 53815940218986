import React, { useEffect, useState } from 'react'
import withFormHoc from '../../../../../hoc/withReactHookForm/withReactHookForm'
import { Input } from '../../../UI/InputElement/reactFormHookInputField'
import { useFieldArray, useWatch } from 'react-hook-form'
import MonthlyTable from './MonthlyTable'
import { useDispatch, useSelector } from 'react-redux'
import withFormHocCostSummary from '../../../../../hoc/withReactHookForm/withReactHookFormCostSummary'
import MonthlyTablePageContainer from '../../../../container/FormAuditModuleContainer/Inventory/MonthlyCostSummaryAnalysis/MonthlyCostSummaryForm/MonthlyTableHook'
import { ClapSpinner } from 'react-spinners-kit'
import { handleResetCostSummary } from '../../../../../redux/actions/FormAuditModAction/InventoryAction/InventoryAction'
import { getCustomerInfos, getJobRelatedToCompany } from '../../../../../redux/actions/FormAuditModAction/TeamManagementAction/teamManagementAction'

function MonthlyCostSummaryPage(props) {


  
  const { jobList, customersList } = useSelector((state) => state.FormAuditModReducer)
  
  const { isLoadMonthlyCost,isCostfetched } = useSelector((state) => state.InventoryModuleReducer)
  const dispatch=useDispatch()
  // isLoadMonthlyCost
  const getJobList = (job) => {
    if (job && job.length > 0) {
      return job && job.map((data) => {
        return {
          value: data.id,
          label: data.name,
        }
      })
    } else {
      return []
    }
  };

  const jobSelected=useWatch({
    control:props.control,
    name:"account_id"
  })

  const companySelected=useWatch({
    control:props.control,
    name:"customer"
  })

  



  const getCustomerList = (Customer) => {
    if (Customer && Customer.length > 0) {
      return Customer && Customer.map((data) => {
        return {
          value: data.id,
          label: data.name,
        }
      })
    } else {
      return []
    }
  };

  const [listOfJob, setJobList] = useState(getJobList(jobList))
  const [listOfCustomer, setCustomerList] = useState(getCustomerList(customersList))

  const GoBtnClick=()=>{
    document.getElementById('Go-btn').click()
  }
const [disabled,setDisabled]=useState(true)

  const watchJOb=useWatch({
    control:props.control,
    name:"account_id"
  })

  const watchYear=useWatch({
    control:props.control,
    name:"year"
  })


  useEffect(() => {
    setCustomerList(getCustomerList(customersList))
  }, [customersList])

  useEffect(() => {
    setJobList(getJobList(jobList))
  }, [jobList])

  const handleReset=(props)=>{
     props.setValue('account_id',"")
     props.setValue('customer',"")
     props.setValue('year',"")
     dispatch(handleResetCostSummary())
     
     

  }

  useEffect(()=>{
    
     if(jobSelected){
      let params={
        accountId:jobSelected,
        account:true
      }
      dispatch(getCustomerInfos(params))
     }
  },[jobSelected])

  useEffect(()=>{
    
    if(companySelected){
     let params={
       address:true,
       companyId:companySelected
     }
     dispatch(getJobRelatedToCompany(params))
    }
 },[companySelected])



  
  


  return (
    <main class="app-content wraper_content chart_section">

      <section class="teammanage_formmain">

        <div class="monthlyuse_datepicker_wrap">

          <div noValidate class="container" >
            <form noValidate className='row ' style={{ gap: "1rem", marginLeft: "11px" }} onSubmit={props.handleSubmit((data) => {

              props.onSubmit(data)
            })}>
              <div class="form-group col-md-3" style={{ width: "15rem" }}>

                <Input
                  type="inputSelect"
                  labelClasses="form-label"
                  control={props.control}
                  controller={props.controller}
                  placeholder="Job"

                  selectName={`account_id`}
                  id={`account_id`}
                  selectOptions={listOfJob}
                  required={props.register(`account_id`, {
                    required: "This field is required"
                  })}
                  validationErrorMessage={props.errors?.account_id?.message}
                  selectClasses="form-select"
                //disabled={props?.isView}
                />

              </div>

              <div class="form-group col-md-3" style={{ width: "15rem" }}>

                <Input
                  type="inputSelect"
                  labelClasses="form-label"
                  control={props.control}
                  controller={props.controller}
                  placeholder="Customer"

                  selectName={`customer`}
                  id={`customer`}
                  selectOptions={listOfCustomer}
                  
                  selectClasses="form-select"
                  disabled={props?.isView}
                />

              </div>

              <div class="form-group col-md-3" style={{ width: "15rem" }}>

                <Input
                  type="date"
                  datePickerType="year"
                  formControlClasses="form-control"
                  id="year"
                  placeholder="Year"
                  control={props.control}
                  controller={props.controller}
                  selectName={"year"}
                  dateFormat="YYYY"
                  //minDate={new Date().setDate(current.getDate() - 400)}
                  isMin={true}
                  preventReadOnly={false}
                  label=""
                  formControlProps={props.register('year', {
                    required: "This field is required",
                    // validate:handleValidateyear,

                  })}
                  validationErrorMessage={props.errors?.year?.message ?? ""}
                  disabled={props?.isView}
                />

              </div>
              <div className="col-md-1">
                <button data-test="filter_go_btn" id="Go-btn" style={{ minWidth: '90px' }} 
                disabled={!Boolean(watchJOb !==undefined && watchYear !==undefined) || !Boolean(watchJOb !=="" && watchYear !=="")}         
                className={"btn cst_btn btn_danger mr-2  account_btn"}  type="submit">{"Go"} </button>

              </div>
              <div className="col-md-1">
                {/* <button data-test="filter_go_btn" id="reset" style={{ minWidth: '90px' }}  onClick={(e)=>{
                  e.preventDefault()
                  handleReset(props)
                }} disabled={!props.isDirty} className={"btn cst_btn btn-outline-secondary account_btn"} type="button">{"Reset"} </button> */}

             <button data-test="filter_go_btn" style={{ minWidth: '90px' }} onClick={(e) => {
                                    e.preventDefault()
                                    handleReset(props)
                                }} className={"btn cst_btn btn-outline-secondary account_btn"} id="reset" type="button">{"Reset"} </button>

              </div>
            </form>
            <div class="row" style={{ width: "100%" }}>
              <div class="monthlyuse_datepicker organselect" style={{ display: "flex", flexDirection: "row", gap: ".5rem" }}>



              </div>

              <div class="col-lg-12">
                {/* <div class="team_manage_head">
                <h1 class="tm_top_head">Montly Cost Summary And Analysis</h1>
              </div> */}
              </div>

              <div class="col-md-12">
                <div class="monthcost_analys_tables">

                  {
                    !props.isDirty || !isCostfetched ? <p style={{marginLeft:"25rem",fontSize:"larger"}}>Please select those fields to get cost Summary </p> : null
                  }


                  {
                    isLoadMonthlyCost ?

                    <div style={{
                      display: "block",
                      position: "absolute",
                      left: 0,
                      height: '100%',
                      right: 0,
                      background: "rgba(255,255,255,0.8)",
                      transition: "all .3s ease",
                      top: 0,
                      bottom: 0,
                      textAlign: "center"
                  }}
                      className="loader_btn_block">
                      <ClapSpinner backColor="#89073c" size={40} frontColor="#89073c8a" />
                  </div>
                    
                    :  <MonthlyTablePageContainer refresh={GoBtnClick} costData={{ sections: props.costData ? props.costData : [] }} />
                  }

                  {/* {
                  isLoadMonthlyCost ? <p>Loading</p> : props.costData.map((monthData, index) => (
                    <MonthlyTable key={index} data={monthData} {...props} />
                  ))
                } */}


                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  )
}

export default withFormHoc(MonthlyCostSummaryPage)
