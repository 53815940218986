import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { ClapSpinner } from "react-spinners-kit";
import { Input } from "../../UI/InputElement/InputElement";
import { reduxForm } from "redux-form";
import { updateUserPasswordValidator as validate } from "../../../../utility/validator/validator";

const UpdateUserPasswordModal = (props) => { 
  const { handleSubmit, pristine, reset, submitting, error } = props;
  return (
    <div
      className="modal fade performance_modal associateUser updatePassword"
      id="updateUserPasswordModal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-dialog-centered modal-sm"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-header">
            <h2 className="md_title" id="exampleModalLongTitle">
            Update password
            </h2>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">
                <img
                  src="/images/icons/icn_cancel_white.png"
                  alt="Icon"
                />
              </span>
            </button>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col-lg-12">
                <form
                  className="login100-form"
                  onSubmit={handleSubmit}
                >
                  <div className="inner_form">
                    <div className="fields">
                        <div className="form-group">
                          <label for="password" className="label_modifier">
                            New Password
                          </label>
                          <Input
                            type="password"
                            autocomplete="new-password"
                            className="form-control input-modifier"
                            placeholder=""
                            name="user[password]"
                          />
                        </div>
                        <div className="form-group">
                          <label for="password" className="label_modifier">
                            Confirm Password
                          </label>
                          <Input
                            type="password"
                            autocomplete="new-password"
                            className="form-control input-modifier"
                            placeholder=""
                            name="user[password_confirmation]"
                          />
                        </div>
                    </div>
                    <div className="btn_block">
                      <button
                        className={
                          props.passwordIsLoading
                            ? "btn cst_btn btn_danger mr-2 btn-wait"
                            : "btn cst_btn btn_danger mr-2"
                        }
                        id="submit"
                        type="submit"
                      >{props.passwordIsLoading ? "" : "Submit"}</button>
                      <button
                        type="button"
                        className="btn btn-outline-secondary cst_btn"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={() => props.closePasswordModalClicked()}
                      >
                        Cancel
                      </button>
                    </div>
                   
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default reduxForm({
  form: "updateUserPasswordForm",
  validate,
})(UpdateUserPasswordModal);
