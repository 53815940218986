import React, { useState, useEffect, useRef } from 'react';
import { Badge, Card, Space } from 'antd';
import * as faceapi from 'face-api.js';
import '../frontlineForm.css';

import errorHandler from '../../../../utility/errorHandler/errorHandler';
import SuccessAlert from '../../../../utility/successAlert/successAlert';
import storage from '../../../../utility/storage';

let FaceRegAnalyzerComponent = (props) => {

    return (
        <div className="myapp faceRegAnal" style={{ margin: '2rem' }}>

            <div className='mb-4'>
                <span className="faceAnalyzerWaitScreenloaderText">Kindly wait for the Face Identification process to Conclude</span>

            </div>

            <Badge.Ribbon text="Scanned Image" color="green" size="default">
                <Card title="Collating Profile" size="medium" hoverable={true} bordered={true}>
                    <div className="gallery">
                        {props.capturedScannedImageUrl ? (
                            <img src={props.capturedScannedImageUrl} height="auto" />
                        ) : (
                            <img src="/images/noImage.jpg" height="auto" />
                        )}
                    </div>
                </Card>
            </Badge.Ribbon>

            <div className='mt-5'>
                <span className="faceAnalyzerWaitScreenloader"></span>
            </div>

        </div>
    );
};

export default FaceRegAnalyzerComponent;