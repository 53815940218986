import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import Aux from '../../../../hoc/Oux/Oux';

const renderField = ({ id, disabled, name, accept, hidden, style, onSelect, fileRef, label, placeholder, inputGroupLabelClass, inputGroupLabel, inputGroupClass, type, className, iconClass, htmlFor,dataTest, multiple, meta: { touched, asyncValidating, error }, ...props }) => {
    return (
        <Aux>
            <input type={type}
                name={name}
                disabled={disabled}
                onChange={onSelect}
                ref={fileRef}
                hidden={hidden}
                style={style}
                multiple={multiple}
                className={className}
                id={id}
                data-test={dataTest}
                accept={accept}
                multiple={multiple}
                placeholder={placeholder}
                {...props} />
            {touched && error && <span style={{ color: 'red' }}>{error}</span>}
        </Aux>
    );
};

export const FileInputField = ({ disabled, fileRef, accept, hidden, onSelect, style, type, name, id, label, placeholder, inputGroupLabelClass, inputGroupClass, className, iconClass, htmlFor, inputGroupLabel, HandleChange, multiple, dataTest }) => (
    <Field
        type={type}
        hidden={hidden}
        disabled={disabled}
        placeholder={placeholder}
        name={name}
        fileRef={fileRef}
        id={id}
        label={label}
        inputGroupLabelClass={inputGroupLabelClass}
        inputGroupClass={inputGroupClass}
        className={className}
        iconClass={iconClass}
        htmlFor={htmlFor}
        multiple={multiple}
        inputGroupLabel={inputGroupLabel}
        onSelect={onSelect}
        style={style}
        accept={accept}
        dataTest={dataTest}
        component={renderField}

    />
);

FileInputField.propTypes = {
    type: PropTypes.string,
    name: PropTypes.string,
    id: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    className: PropTypes.string,
    iconClass: PropTypes.string,
    inputGroupLabelClass: PropTypes.string,
    inputGroupLabel: PropTypes.string,
    inputGroupClass: PropTypes.string,
    HandleChange: PropTypes.func,
    htmlFor: PropTypes.string
};

FileInputField.defaultProps = {
    type: 'file',
    id: '',
    label: null,
    multiple: false,
    placeholder: '',
    className: '',
    inputGroupLabelClass: '',
    inputGroupLabel: '',
    inputGroupClass: '',
    htmlFor: ''
};