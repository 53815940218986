import React from "react";
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { Input } from '../../../UI/InputElement/InputElement';

let LogbookMobileSearch = (props) => {
  const { onAreaChange} = props;
  const noOptionsMessage = (a, b) => {
    return 'Area not found';
  }
  const logbookAreasOption = props.logbookNames && props.logbookNames.map((log) => {
      return{ value: log, label: log}
  })
  return(
        <form className="form-inline mobile-view-history">

            <label className="form_title">Logbook Area Name</label>
            {/* <Input name="quote[account_id]" noOptionsMessage={noOptionsMessage} options={accounts} /> */}
            <Input name="area_name" type="inputGroupSelect" className="input-modifier" onChange={onAreaChange} noOptionsMessage={noOptionsMessage} options={logbookAreasOption} placeholder="Logbook-Area-Name" />

        </form>
  )
};
LogbookMobileSearch = reduxForm({
  form: 'historySearch'
})(LogbookMobileSearch);

LogbookMobileSearch = connect(null)(LogbookMobileSearch);
export default LogbookMobileSearch;