import React, { Component } from 'react';
import Floors from '../../component/Insights/Floor/FloorList';
import * as actions from '../../../redux/actions/index';
import { connect } from 'react-redux';

class FloorsContainer extends Component {

  componentDidMount() {
    if (!this.props.floorList.length) {
      this.props.getAllFloors();
    }
  }

  render() {

    return (
        <Floors
            isLoading={this.props.isLoading}
            floorList={this.props.floorList ? this.props.floorList : []}
            openPopup={this.props.openPopup}
            openDeleteModel={this.props.openDeleteModel}
            handleDeleteClose={this.props.handleDeleteClose}
            deleteFloor={this.props.deleteFloor}
            editFloorClicked={this.props.editFloorClicked}
            deleteObject={this.props.deleteObject}
            handleClickDeleteModel={this.props.handleClickDeleteModel}
            totalFloors= {this.props.totalFloors}
            />
    )
  }
}

const mapStateToProps = (state) => {
  return {
    floorList: state.adminOrSuperAdminReducer.floorList,
    isLoading: state.adminOrSuperAdminReducer.isLoading,
    deleteObject: state.adminOrSuperAdminReducer.deleteObject,
    openDeleteModel: state.adminOrSuperAdminReducer.openDeleteModel,
    totalFloors: state.adminOrSuperAdminReducer.totalFloors,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getAllFloors: () => dispatch(actions.getFloors()),
    openPopup: () => dispatch(actions.openPopup()),
    editFloorClicked: (floor) => dispatch(actions.editFloorClicked(floor)),
    handleDeleteClose: () => dispatch(actions.handleDeleteClose()),
    deleteFloor: (id) => dispatch(actions.deleteFloor(id)),
    handleClickDeleteModel: (account) => dispatch(actions.handleClickDeleteModel(account)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FloorsContainer);