import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../../../redux/actions/index';
import { reset } from 'redux-form';
import storage from '../../../../../utility/storage';
import { animateScroll as scroll} from 'react-scroll'
import AssetsList from '../../../../component/Inventory/Assets/AssetsList/assetsList';
import { routes } from '../../../../../utility/constants/constants';
import AssetsPreview from '../../../../component/Inventory/Assets/AssetsPreview/assetsPreview';


class AssetsPreviewContainer extends Component {

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    scroll.scrollToTop();
  }

  render() {

    return (
      <AssetsPreview
        {...this.props}
      />
    )
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.authReducer.user,
    isLoading: state.InventoryReducer.isLoading,
    assets: state.InventoryReducer.assetSingleLogList,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSingleLogAsset: (id, params) => dispatch(actions.getSingleLogAsset(id, params)),
    assetsPageClicked: (page) => dispatch(actions.assetsPageClicked(page)),
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(AssetsPreviewContainer);