import { BRMActionTypes } from "./BrmActionTypes";
import SuccessAlert from "../../../utility/successAlert/successAlert";
import {
  BRMDeleteCloseModal,
  closeModel,
} from "../../../utility/utility";
import * as API from "../../../API/BrmAPI/BrmAPI";
import mixpanel from "mixpanel-browser";
import history from '../../../router/history';
import { routes } from "../../../utility/constants/constants";
mixpanel.init("3f0db59ea5a88d250644645440cc0b76", { debug: true });

const refreshPage = () => {
  window.location.reload(false);
};

export const onMatrixChange = (id) => (dispatch) =>
  dispatch({
    type: BRMActionTypes.CLICK_ON_MATRIX_BUTTON,
    payload: id,
  });

export const onCustomMatrixModalChange = (isOpen) => (dispatch) =>
  dispatch({
    type: BRMActionTypes.CUSTOM_MATRIX_MODAL_OPEN,
    payload: isOpen,
  });

export const onStandardMatrixModalChange = (isOpen) => (dispatch) => {
  dispatch({
    type: BRMActionTypes.STANDARD_MATRIX_MODAL_OPEN,
    payload: isOpen,
  });
};

export const updateStandardMatrixFulfilled = (payload) => (dispatch) => {
  dispatch({
    type: BRMActionTypes.UPDATE_STANDARD_MATRIX,
    payload,
  });
};

export const ResetSelectedMatrix = (payload) => (dispatch) => {
  dispatch({
    type: BRMActionTypes.RESET_STANDARD_MATRIX,
    payload,
  });
};

export const addStandardMatrix = (body) => (dispatch) => {
  dispatch({
    type: BRMActionTypes.ADD_STANDARD_MATRIX,
    payload: API.addStandardMatrixApi(body)
      .then((response) => {
        if (response.data.success) {
          SuccessAlert("Standard matrix Created successfully");
          closeModel();
          return true;
        }
        return false;
      })
      .then((isSuccess) => {
        if (isSuccess) {
          dispatch({
            type: BRMActionTypes.GET_MATRIX_LIST,
            payload: API.getMatrixListAPI({ type: "default" }).then((res) => {
              if (res.data.success) {
                return res.data.data;
              }
            }),
          });
        }
      })
      .catch((error) => {
        console.log(error);
        closeModel();
      }),
  });
};

export const updateStandardMatrix = (body, id) => (dispatch) => {
  dispatch({
    type: BRMActionTypes.UPDATE_STANDARD_MATRIX_API,
    payload: API.updateStandardMatrixApi(body, id)
      .then((response) => {
        if (response.data.success) {
          if (response.data.data.type === "custom") {
            SuccessAlert("Custom matrix Updated successfully");
          } else {
            SuccessAlert("Standard matrix Updated successfully");
          }
          closeModel();
          return response.data.data.type;
        }
        return false;
      })
      .then((isSuccess) => {
        if (isSuccess) {
          dispatch({
            type: BRMActionTypes.GET_MATRIX_LIST,
            payload: API.getMatrixListAPI({ type: isSuccess }).then((res) => {
              if (res.data.success) {
                return res.data.data;
              }
            }),
          });
        }
      })
      .catch((error) => {
        console.log(error);
        closeModel();
      }),
  });
};

export const getMatrixList = (data) => {
  return (dispatch) => {
    dispatch({
      type: BRMActionTypes.GET_MATRIX_LIST,
      payload: API.getMatrixListAPI(data)
        .then((response) => {
          if (response.data.success) {
            return response.data.data;
          }
        })
        .catch((error) => {
          console.log(error);
          return [];
        }),
    });
  };
};

export const deleteStandardMatrix = (data, actionType) => {
  return (dispatch, getState) => {
    dispatch({
      type: BRMActionTypes.DELETE_STANDARD_MATRIX,
      payload: API.deleteStandardMatrixApi(data)
        .then((response) => {
          BRMDeleteCloseModal();
          if (response.data.success) {
            if (actionType === "default") {
              dispatch({
                type: BRMActionTypes.DELETE_STANDARD_MATRIX_FULFILLED,
                payload: data,
              })
              SuccessAlert("Standard Matrix Deleted Successfully");
            } else {
              dispatch({
                type: BRMActionTypes.DELETE_STANDARD_MATRIX_FULFILLED,
                payload: data,
              });
              SuccessAlert("Custom Matrix Deleted Successfully");
            }
          }
        })
        .catch((error) => {
          console.log(error);
          BRMDeleteCloseModal();
        }),
    });
  };
};

export const addCustomMatrix = (body) => (dispatch) => {
  dispatch({
    type: BRMActionTypes.ADD_CUSTOM_MATRIX,
    payload: API.addCutomMatrixApi(body)
      .then((response) => {
        if (response.data.success) {
          SuccessAlert("Custom matrix Created successfully");
          closeModel();
          return true;
        }
        return false;
      })
      .then((isSuccess) => {
        if (isSuccess) {
          dispatch({
            type: BRMActionTypes.GET_MATRIX_LIST,
            payload: API.getMatrixListAPI({ type: "custom" }).then((res) => {
              if (res.data.success) {
                return res.data.data;
              }
            }),
          });
        }
      })
      .catch((error) => {
        console.log(error);
        closeModel();
      }),
  });
};

export const Open_delete_popup = (id) => (dispatch) => {
  dispatch({
    type: BRMActionTypes.OPEN_MATRIX_DELETE_MODAL,
    payload: id,
  });
};

export const close_delete_popup = () => (dispatch) => {
  dispatch({
    type: BRMActionTypes.CLOSE_MATRIX_DELETE_MODAL,
    payload: true,
  });
};



export const completePreviewEvent = (id, body) => (dispatch) => {
  dispatch({
    type: BRMActionTypes.COMPLETE_PREVIEW_EVENT,
    payload: API.completePreviewEvent(id, body)
      .then((response) => {
        if (response.data.success) {
          SuccessAlert("Schedule has been completed");
          history.push(routes.BRM_Schedule)
          return true;
        }
        return false;
      }).catch((err) => {
        console.log("error", err);
      })
  })
}

export const getAllBrmSchedule = (params, values) => {
  return (dispatch) => {
    dispatch({
      type: BRMActionTypes.GET_ALL_BRM_SCHEDULE,
      payload: API.getAllBrmSchedule(params)
        .then((response) => {
          if (response.data.success) {
            return {
              brmScheduleList: response.data.data,
              brmScheduleList_total_pages: response.data.totalPages,
              brmScheduleList_no_of_rows: response.data.limit,
              total_brmScheduleList_count: response.data.total,
              brmScheduleList_curPage: params.page - 1,
              searchValues: values
            };
          };
        })
        .catch((error) => {
          console.log(error);
        }),
    });
  };
};

export const brmSchedulePageClicked = (data) => {
  return {
    type: BRMActionTypes.BRM_SCHEDULE_PAGE_CLICKED,
    payload: data,
  };
};


export const getBRMDashBoard = () => {
  return (dispatch) => {
    dispatch({
      type: BRMActionTypes.GET_BRM_DASHBOARD,
      payload: API.getBRMDashboardAPI()
        .then((response) => {

          if (response.data.success) {
            return response.data.data;
          }
        })
        .catch((error) => {
          console.log(error);
          return [];
        }),
    });
  };
};


export const getOnePreviewEvent = (id) => (dispatch) => {
  dispatch({
    type: BRMActionTypes.GET_ONE_FEEDBACK,
    payload: API.getOneFeedBack(id)
      .then((response) => {
        if (response.data.success) {
          return response.data.data;
        }
      }).catch((err) => {
        console.log("error", err);
      })
  })
}


export const getCompaniesMatrox = () => {
  return (dispatch) => {
    dispatch({
      type: BRMActionTypes.GET_MATRIX_COMPANIES,
      payload: API.getMatrixCompanyListAPI()
        .then((response) => {
          if (response.data.success) {
            return response.data.data;
          }
        })
        .catch((error) => {
          console.log(error);
          return [];
        }),
    });
  };
};

export const removePreviewDetails = (id) => (dispatch) => {
  dispatch({
    type: BRMActionTypes.REMOVE_FEEDBACK,
    payload: null
  })
}


export const getJobBasedMatrix = (body) => (dispatch) => {
  dispatch({
    type: BRMActionTypes.FETCH_JOB_BASED_MATRIX,
    payload: API.fetchJobBasedMatrix(body)
      .then((response) => {
        if (response.data.success) {
          return response.data.data;
        }
      })
      .catch((error) => {
        console.log(error);
        closeModel();
      }),
  });
};


export const resetMatrixJobs = (id) => (dispatch) => {
  dispatch({
    type: BRMActionTypes.RESET_JOB_BASED_MATRIX,
    payload: []
  })
}