import React, { Component } from 'react';
import MyProfile from '../../component/updateProfile/myProfile';
import * as actions from '../../../redux/actions/index';
import { decode, encode } from 'base64-arraybuffer';
import { connect } from 'react-redux';
import isEmpty from '../../../utility/IsEmptyValidator';
import imageCompression from 'browser-image-compression';
import { resetOrientation } from '../../../utility/utility';
import cloneDeep from 'clone-deep';

class MyProfileContainer extends Component {

    constructor(props) {
        super(props);

        this.state = {
            profileImageName: '',
            base64: null,
            extension: null,
        }
        this.inputOpenFileRef = React.createRef();
        this.choosePhotoClicked = this.choosePhotoClicked.bind(this);
        this.onFileChange = this.onFileChange.bind(this);
    }

    choosePhotoClicked = () => {
        this.inputOpenFileRef.current.click();
    }

    async onFileChange(e) {

        let reader = new FileReader();
        let file = e.target.files[0];

        const orientation = await imageCompression.getExifOrientation(file);
        reader.onloadend = () => {

            resetOrientation(reader.result, orientation, this);
            this.setState({
                extension: file.name.split('.').pop().toLowerCase(),
                profileImageName: file.name,
                // base64: reader.result,
            })
        }
        reader.readAsDataURL(file)
        // reader.readAsArrayBuffer(file)
    }

    updateProfile = (user) => {

        let setUser = { user: {} };
        let fetchPayloadUser = cloneDeep(user.user);
        let fetchDbUser = cloneDeep(user.fetchedUser);
        let base_photo = null;
        let arrayBuffer = null;

        if (this.state.base64 !== null && this.state.base64 !== '') {
            const image = this.state.base64.split(',');
            base_photo = image[1];
            arrayBuffer = decode(base_photo);
        }

        setUser.user = fetchDbUser;
        setUser.user.first_name = fetchPayloadUser.first_name
        setUser.user.last_name = fetchPayloadUser.last_name
        setUser.user.mode = fetchPayloadUser.mode
        setUser.user.position_id = fetchPayloadUser.position_id
        setUser.user.email = fetchPayloadUser.email
        setUser.user.timezone = fetchPayloadUser.timezone && fetchPayloadUser.timezone

        delete setUser.user['selected_mode'];
        delete setUser.user['criteria'];
        delete setUser.user['form_access_actions'];
        
        this.props.updateProfile(setUser, arrayBuffer, this.state.extension);

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.user !== prevProps.user) {
            this.setState({
                profileImageName: '',
                base64: null,
                extension: null,
            })
        }
    }

    componentDidMount() {
        if (!this.props.user) {
        this.props.getUpdatedCurrentUser(this.props.userId);
        }

        // if (!this.props.positionsList) {
        this.props.getPositions({ hide: false });
        // }
    }

    render() {
        return (
            <MyProfile
                onSubmit={this.updateProfile}
                s3Url={isEmpty(this.props.user.photo_urls) ? '' : this.props.user.photo_urls.original}
                reference={this.inputOpenFileRef}
                positionsList={this.props.positionsList ? this.props.positionsList : []}
                isLoading={this.props.isLoading}
                choosePhotoClicked={this.choosePhotoClicked}
                base64={this.state.base64}
                closeProfileModel={this.props.closeProfileModel}
                onFileChange={this.onFileChange}
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        userId: state.authReducer.user.id,
        isLoading: state.authReducer.isLoading,
        user: state.authReducer.user,
        positionsList: state.adminOrSuperAdminReducer.positionsList
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        // closeProfileModel: () => dispatch(actions.closeProfileModel()),
        getUpdatedCurrentUser: (id) => dispatch(actions.getUpdatedCurrentUser(id)),
        getPositions: (data) => dispatch(actions.getPositions(data)),
        resetObjects: () => dispatch(actions.resetObjects()),
        updateProfile: (user, arrayBuffer, extension) => dispatch(actions.updateProfile(user, arrayBuffer, extension)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyProfileContainer);