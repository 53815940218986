import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../redux/actions/index';
import * as $ from "jquery";
import ScanSearchResult from "../../component/History/ScanSearchResult";
class SearchHistory extends Component {
  constructor(props) {
    super(props)
    this.state = {
      result: 'No result',
      loading: false,
      search: {
        area_type_id: null,
        floor_id: null,
        area_name: null,
        page: 1,
        size: 15,
        totalCount: 0
      }
    };
    this.handlePageChange = this.handlePageChange.bind(this)

  }

  getHistory(params, id) {
    return  this.props.getSearchHistory(params, id)
  }
  componentDidMount() {
    $('.login100-more').addClass("d-none");
    if (this.props.areaSearchResult ) {
      if (this.props.user && this.props.user.role === "frontline") {
        this.props.createAreaTask(this.props.areaSearchResult.id).then(res => {
          if (res.value){
            this.getHistory({
              page: this.state.search.page,
              size: this.state.search.size,
              "area_id": this.props.areaSearchResult.id,
              "area_type_id": this.props.areaSearchResult.area_type.id,
              "floor_no": this.props.areaSearchResult.floor_no,
              "area_name": this.props.areaSearchResult.name
            }, this.props.areaSearchResult.account.token).then(res => {
              // if (this.props.user && this.props.user.role === "frontline") setTimeout(() => this.props.history.push("/scan_qr"), 15000);
            })
          }
        })
      } else {
        this.getHistory({
          page: this.state.search.page,
          size: this.state.search.size,
          "area_id": this.props.areaSearchResult.id,
          "area_type_id": this.props.areaSearchResult.area_type.id,
          "floor_no": this.props.areaSearchResult.floor_no,
          "area_name": this.props.areaSearchResult.name
        }, this.props.areaSearchResult.account.token);
      }
    }
    else if (this.props.user && this.props.user.role === "frontline") {
      this.props.history.push("/scan_qr")
    } else if (!this.props.searchHistory) {
      this.props.history.push(`/account/${this.props.match.params.accountId}`);
    }
    //if (isEmpty(this.props.areaTypes)) {
    //  this.props.getAreaTypes();
    //}
  }
  scheduleTask= (id) => {
    this.props.createAreaTask(id)
  };
  componentWillUnmount() {
    $('.login100-more').removeClass("d-none");
  }
  handlePageChange(pageNumber) {
    console.log(`active page is ${pageNumber}`);
    let search = {...this.state.search};
  search.page = pageNumber;
    this.setState({search});
    this.getHistory({...search,
    "area_id": this.props.searchHistory.last_sync.area.id,
    "area_type_id": this.props.searchHistory.last_sync.area.area_type.id,
    "floor_no": this.props.searchHistory.last_sync.area.floor.id,
    "area_name": this.props.searchHistory.last_sync.area.name
    }, this.props.searchHistory.last_sync.area.account.token)
  }
  render() {
    return (
        <>
        <ScanSearchResult
            {...this.props}
            search = {this.state.search}
            total_no_of_pages={this.props.total_no_of_pages}
            token={this.props.match.params.accountId}
            handlePageChange={this.handlePageChange}
            scheduleTask={this.scheduleTask}
            />
        </>

    )
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.authReducer.user,
    isLoading: state.adminOrSuperAdminReducer.isLoading,
    total_no_of_pages: state.adminOrSuperAdminReducer.total_no_of_pages,
    areaSearchResult: state.adminOrSuperAdminReducer.areaSearchResult,
    searchHistory: state.adminOrSuperAdminReducer.searchHistory
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSearchHistory: (params,id) => dispatch(actions.searchHistory(params,id)),
    createAreaTask: (id) => dispatch(actions.createAreaTask(id))
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(SearchHistory);
//export default SearchHistory;