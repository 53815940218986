import React, { useEffect } from 'react';

let OverrideAvailabilityReviewForm = (props) => {

    var radios = document.querySelectorAll('input[type="radio"]');
    for (var i = 0; i < radios.length; i++) {
        radios[i].onfocus = function () {
            this.blur();
        }
    }

    const { overrideAvailability } = props;

    return (
        <div className="modal fade request_quote_modal reject_quot associateUser" id="overrideAvailabilityReviewRequest" tabindex="-1" role="dialog" aria-labelledby="rejectQuoteModalTitle" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-header"></div>
                    <div className="modal-body">
                        <div className="tab-content">
                            <div id="profile" className="profile_tab cst_tab_content tab-pane fade show active edit_profile_modal">
                                {overrideAvailability.reviewStatus == 'approved' ? (
                                    <form className="form_content">
                                        <div className="row" style={{ marginBottom: '-15px' }}>
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label className="col-md-12 form_title text-center" style={{ fontSize: '25px' }}>Do you want to Approve the Override Request ?</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="modal-footer">
                                            <button onClick={props.handleFrequentTimeTracker_closeModal} id='closeLeaveRequestRejectionModal' type="button" className="btn btn-outline-secondary cst_btn" data-dismiss="modal">No</button>
                                            <button onClick={() => { props.handleOverrideAvailabilityRequestSanction(overrideAvailability) }} data-test="submit_button" type="button" disabled={props.isLoading} className={props.isLoading ? "btn cst_btn btn_danger mr-2 btn-wait" : "btn cst_btn btn_danger mr-2"} > {props.isLoading ? "" : "Yes"} </button>
                                        </div>
                                    </form>
                                ) : overrideAvailability.reviewStatus == 'rejected' ? (
                                    <form className="form_content">
                                        <div className="row" style={{ marginBottom: '-15px' }}>
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label className="col-md-12 form_title text-center" style={{ fontSize: '25px' }}>Do you want to Reject the Override shift ?</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="modal-footer">
                                            <button onClick={props.handleFrequentTimeTracker_closeModal} id='closeLeaveRequestRejectionModal' type="button" className="btn btn-outline-secondary cst_btn" data-dismiss="modal">No</button>
                                            <button onClick={() => { props.handleOverrideAvailabilityRequestSanction(overrideAvailability) }} data-test="submit_button" type="button" disabled={props.isLoading} className={props.isLoading ? "btn cst_btn btn_danger mr-2 btn-wait" : "btn cst_btn btn_danger mr-2"} > {props.isLoading ? "" : "Yes"} </button>
                                        </div>
                                    </form>
                                ) : null}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
};

export default OverrideAvailabilityReviewForm;