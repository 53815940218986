import React, { Component } from 'react';
import { connect } from 'react-redux';
import { decode, encode } from 'base64-arraybuffer';
import * as actions from '../../../../redux/actions/index';
import FaceRecognitionComponent from '../../../component/Frontline/FrontlineUserFaceRecognition/FaceRecognitionComponent';
import { getUser, resetOrientation, extractExtention } from '../../../../utility/utility';
import { animateScroll as scroll } from 'react-scroll'
import storage from '../../../../utility/storage';
import ClockInClockOutFaceRecognition from '../../../component/Frontline/ClockInClockOutFaceRecognition/clockInClockOutFaceRecognition';
import { tzUTCDateTimeConverterTool_V1 } from '../../../../utility/TimezoneOperations/timezoneUtility';
import queryString from 'query-string';
const cloneDeep = require('clone-deep');
var momentTZ = require('moment-timezone');

class ClockInClockOutFaceRecognitionContainer extends Component {

    constructor(props) {
        super(props);

        this.state = {
        }
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        const getUserObject = storage.get('user');
        scroll.scrollToTop();
    }

    componentWillUnmount() {
        storage.remove('attempt')
    }

    handleSubmit = (imageUrl, attempts) => {

        storage.set('attempt', attempts)
        const queryParams = queryString.parse(this.props.location.search);
        let setData = { data: 
            { 
                photo_path: '',
                audit: {
                    type: "ShiftDetail",
                    shift_action: queryParams.type,
                    shift_detail_id: queryParams.shift_detail_id,
                    attempt: attempts
                }

            }
         };
        let base_photo, extension = [], arrayBuffer;

        // FOR CREATE EXTENSION
        let getImageType = imageUrl.match(/^data:(image\/\w+);/);
        if (getImageType && getImageType.length > 1) {
            const mimeType = getImageType[1];
            extension.push('.' + mimeType.split('/')[1]);
        }

        // FOR CREATE ARRAYBUFFER
        let getImageBinaryUrl = imageUrl.split(',');
        base_photo = getImageBinaryUrl[1];
        arrayBuffer = decode(base_photo);
        this.props.getScannedFrontlineFaceImagePresignUrl(setData, arrayBuffer, extension, queryParams.type, this.props.location.state);

    }

    render() {

        return (
            <>
                <div style={{ margin: '10px' }}>
                    <ClockInClockOutFaceRecognition
                        {...this.props}
                        handleSubmit={this.handleSubmit}
                    />
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        isLoading: state.TimeTrackerModReducer.isLoading,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getScannedFrontlineFaceImagePresignUrl: (data, arrayBuffer, extension, queryParams, values) => dispatch(actions.getScannedFrontlineFaceImagePresignUrl(data, arrayBuffer, extension, queryParams, values)),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(ClockInClockOutFaceRecognitionContainer);