import React, { useState } from 'react';
import { formValueSelector, getFormValues, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import { accountUsersSearchValidator as validate } from '../../../../../utility/validator/validator';
import { Input } from '../../../UI/InputElement/InputElement';
import '../../../../../styles/custom.css';
import PreviewLogBookAreaTable from './PreviewLogBookAreaTable';
import LogBookQrReader from '../../../../../adminOrSuperAdminOrRequestor/container/ AccountsContainer/LogBookAreaContainer/LogBookQRContainer/LogBookQRContainer';

let PreviewLogbookArea = (props) => {
    const isReviewedOptions = [ { id: 'reviewed', name: 'Yes' }, { id: 'pending' , name: 'No' } ];
    const noAccountOptionsMessage = () => {
        return 'Options not found';
    }
    console.log('props', props);
    const { handleSubmit, pristine, reset, submitting, error } = props;

    return (
        <>
            <main className="app-content wraper_content inspection_wraper">
                <div className="tab_header search_filter_cont inspection_tab_content appcontent_inner">
                    <form onSubmit={handleSubmit}>
                        <div className="row" style={{justifyContent:'space-between'}}>
                            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6" style={{ maxWidth : '200px' }}>
                                <label>
                                    <Input name="fromDate" maxDate={props.selectedValues.toDate ? props.selectedValues.toDate : new Date()} dateFormat="MM-dd-yyyy" placeholder="From Date" datePickerType={"react-datepicker"} type="date" className="form-control input-modifier" />
                                    <div class="input-group-append cst_group_append"> <span class="input-group-text" id="basic-addon2"><i class="fa fa-calendar"></i></span> </div>
                                </label>
                            </div>
                            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6" style={{ maxWidth : '200px' }}>
                                <label>
                                    <Input name="toDate" minDate={props.selectedValues.fromDate ? props.selectedValues.fromDate : null} maxDate={new Date()} dateFormat="MM-dd-yyyy" placeholder="To Date" datePickerType={"react-datepicker"} type="date" className="form-control input-modifier" />
                                    <div class="input-group-append cst_group_append"> <span class="input-group-text" id="basic-addon2"><i class="fa fa-calendar"></i></span> </div>
                                </label>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                                <div className="app-search">
                                    <Input name="name" dataTest="creaters_filter" className="fil_search_input" type="search" placeholder="Search Reviewed by" />
                                    <button className="app-search__button">
                                        <i class="fa fa-search" aria-hidden="true"></i>
                                    </button>
                                </div>
                            </div>
                            <div className="col-xl-2 col-lg-4 col-md-6 col-sm-6 media_padding" >
                                <div className="app-search">
                                    <Input dataTest="mode_filter" name="status" noOptionsMessage={noAccountOptionsMessage} type="select" placeholder="Is Reviewed" options={isReviewedOptions} className="custom-select input-modifier add_user_select" />
                                </div>
                            </div>
                            <div className="col-xl-2 col-lg-2 col-md-6 col-sm-6 col-12">
                                <div class="rset_btn">
                                    <button data-test="submit_button" style={{ minWidth: '90px' }} className={"btn cst_btn btn_danger mr-2  account_btn"} id="submit" type="submit">{"Go"} </button>
                                    <button style={{ minWidth: '90px' }} onClick={props.resetClicked} className={"btn cst_btn btn-outline-secondary account_btn"} id="reset" type="reset">{"Reset"} </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div id="exTab3" className="">
                    <div className="tab-content clearfix">
                        <div className={'tab-pane active'} id="account-area">
                            <section className="account_sec user_screen inspection_tab_content">
                                <div className="quotes_table_content table-responsive">
                                    <div className="table quotes_table user_table_contnet user_react_table account_table accAreaTable" id="area_table_head">
                                        <PreviewLogBookAreaTable
                                            {...props}
                                            accountId={props.accountId}
                                            isLoading={props.isLoading}
                                            viewLogbookQrScan={props.viewLogbookQrScan}
                                            previewLogBookAreaQrScanClicked={props.previewLogBookAreaQrScanClicked}
                                            logbookQRCamera={props.logbookQRCamera}
                                        />
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </main>
            <Dialog open={props.viewLogbookQrScan} aria-labelledby="form-dialog-title">
                <DialogActions>
                    <LogBookQrReader />
                </DialogActions>
            </Dialog>
        </>
    )
}

const mapStateToProps = (state) => {
    //let email = "";
    //let name = "";

    //return { initialValues: { email, name } }
}

PreviewLogbookArea = reduxForm({
    form: 'searchPreviewLogbookForm',
    validate,
    enableReinitialize: true
})(PreviewLogbookArea);

const selector = formValueSelector('searchPreviewLogbookForm') // <-- same as form name
PreviewLogbookArea = connect(state => {
    const { fromDate, toDate } = selector(state, 'fromDate', 'toDate')
    const selectedValues = { fromDate, toDate }
    return {
        selectedValues
    }
}) (PreviewLogbookArea)

PreviewLogbookArea = connect(mapStateToProps)(PreviewLogbookArea)

export default PreviewLogbookArea;