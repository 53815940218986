import React, { useState } from "react";
import { routes } from "../../../utility/constants/constants";

let ThankyouComponent = (props) => {

    return (
        <main class="app-content qr_app_content app_screen_outer schedule-screen ups_schedule m-0 text-center" style={{maxWidth: "100%"}}>
            <div class="client_login list_view m-0 w-100" style={{maxWidth: "100%"}}>
                <div class="login_form_content Schedule_con">
                    
                    <div className="comments_wrap text-center">
                        <div className="positive_vote_img maintain_div text-center">
                            <img src="/images/icons/positive-vote.png" style={{ height: '150px', width: '150px' }} />
                        </div>
                        <div className="thank_msg text-center w-100">Thank you for your feedback</div>
                        {/* <div className="form-group btn_block mb-0 float-left mt-3 w-100">
                            <button data-test="submit_button" type="submit" className={"btn cst_btn btn_danger w-100 py-3"} onClick={() => props.history.push(`/pegassure-feedback/${props.match.params.areaId}`)}>Back To Home</button>
                        </div> */}
                    </div>
                </div>
            </div>
        </main>
    )
}

export default ThankyouComponent;
