import React, { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import StandardMatrixTableList from './StandardMatrixTableList';
import AddStandardMatrix from './AddStandardMatrix/AddStandardMatrixContainer';
import AddCustomMatrixContainer from './CustomMatrix/CustomMatrixContainer';

const StandardMatrixLayout = (props) => {
    const ActiveCustomListCategory = (active) => {
        props.getMatrixList({ type: 'custom',active_status:active?"active":"inactive" });
    }

    const ActiveStandardListCategory = (active) => {
        props.getMatrixList({ type: 'default',active_status:active?"active":"inactive" });
    }

    const closeModal = () => {
        const closeButton = document.getElementById("cancel");
        closeButton.click()
    }

    const [isChecked, setIsChecked] = useState(true);
    
    useEffect(()=>{
       if( props?.isStandardMatrix ){
        ActiveStandardListCategory(isChecked)
       }else{
        ActiveCustomListCategory(isChecked)
       }
    },[isChecked,props?.isStandardMatrix])


    

    const handleChange = () => {
      setIsChecked(!isChecked);
    };
  

    const { handleSubmit} = props;

    return (
        <>
            <main className="app-content wraper_content inspection_wraper categories_blc">
                <div className="tab_header search_filter_cont inspection_tab_content appcontent_inner">
                    <form onSubmit={handleSubmit}>
                        <input type="hidden" value="something" />
                        <div className="row mt-2">
                            <div class="col">
                                <button type='button' className="btn cst_btn btn_danger mr-2" style={props?.isStandardMatrix ?{ fontWeight:"bolder",padding:"10px 8px"}:{}} onClick={() => { props.onMatrixChange(true);setIsChecked(true)}}>Standard Matrix</button>
                                <button type='button' className="btn cst_btn btn_danger mr-2" style={!props?.isStandardMatrix ?{ fontWeight:"bolder",padding:"10px 8px"}:{}} onClick={() => { props.onMatrixChange(false);setIsChecked(true)}}>Custom Matrix</button>
                            </div>
                            <div className="col">
                                <div className="col-xl-12 col-lg-4 col-md-12 col-sm-12 text-right accounts_btn_wrap"> 
                                {props?.isStandardMatrix ? 
                                <a href="JavaScript:void(0);" className="btn btn-secondary cst_btn" data-toggle="modal" data-target="#addstandardMatrix" data-backdrop="static" data-keyboard="false" onClick={() => {  props.onStandardMatrixModalChange(true) }}><i className="fa fa-plus icn_plus" aria-hidden="true"></i> Add Standard Matrix </a>  
                                : 
                                <a href="JavaScript:void(0);" className="btn btn-secondary cst_btn" data-toggle="modal" data-target="#addCustomMatrix" data-backdrop="static" data-keyboard="false" onClick={() =>  props.onCustomMatrixModalChange(true) }><i className="fa fa-plus icn_plus" aria-hidden="true"></i> Add Custom Matrix </a>}
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <section className="account_sec user_screen inspection_tab_content">

                {/* <div style={{display:"flex",flexDirection:"row",gap:".5rem",alignItems:"center"}}>
                <label class="switch">
                    <input type="checkbox" checked={isChecked} onChange={handleChange} />
                    <span class="slider round"></span>
                </label>
                <label style={{fontSize:"medium",fontWeight:"bold"}}>{isChecked ? "Active" :"In Active" }</label>
                </div> */}
                <div className="theme_switch_btn" style={{display: "inline"}}>
                        <span className="switch_title">In Active</span>
                        <label>                                
                            <input type="checkbox" checked={isChecked} onChange={handleChange}/>
                            <span></span>
                        </label>
                        <span className="ext_title">Active</span>
                    </div>
                

                    <StandardMatrixTableList
                        {...props}
                    />
                </section>
            </main>
            {props.isStandardMatrixModalOpen ? (
                <AddStandardMatrix isEdit={false} setIsChecked={setIsChecked} />
            ) : null}

            {props?.isCustomMatrixModalOpen ? (
                 <AddCustomMatrixContainer
                 matrixCompanies={props.matrixCompanies}
                 isEdit={false}
                 setIsChecked={setIsChecked}
             />
            ) : null}

            <Dialog
                open={props.openFrequentTimeTracker_deleteModal}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title" style={{ fontSize: '13', color: '#06080f' }}>
                    <h3 style={{ color: '#ab385e' }}>Are you sure?</h3>
                </DialogTitle>

                <DialogActions>
                    <button style={{ minHeight: '36px', minWidth: '80px' }} onClick={() => props.deleteStandardMatrix(props.deleteId)} disabled={props.isLoading} className={props.isLoading ? "btn cst_btn btn_danger mr-2 btn-wait" : "btn cst_btn btn_danger mr-2"} id="deleteCriteria" type="delete">{props.isLoading ? "" : "Delete"}</button>
                    <button style={{ minHeight: '36px', minWidth: '80px' }} onClick={props.close_delete_popup} className={"btn cst_btn btn-outline-secondary"} id="cancel" type="cancel">Cancel</button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={props.deletemodal}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title" style={{ fontSize: '13', color: '#06080f' }}>
                    <h3 style={{ color: '#ab385e' }}>Are you sure?</h3>
                </DialogTitle>

                <DialogActions>
                <button 
                        style={{ minHeight: '36px', minWidth: '80px' }} 
                        onClick={() => { 
                            props.deleteStandardMatrix(props.deleteId,props?.matrixList[0]?.type); 
                            closeModal();
                        }}  disabled={props.isLoading} className={props.isLoading ? "btn cst_btn btn_danger mr-2 btn-wait" : "btn cst_btn btn_danger mr-2"} id="deleteCriteria" type="button">
                    {props.isLoading ? "" : "Delete"}
                 </button>
                    <button style={{ minHeight: '36px', minWidth: '80px' }} onClick={props.close_delete_popup} className={"btn cst_btn btn-outline-secondary"} id="cancel" type="cancel">Cancel</button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default StandardMatrixLayout;