export const PegassurePlaybookActionTypes = {

    RESET_PEGASSURE_PLAYBOOK_EVENTS: 'RESET_PEGASSURE_PLAYBOOK_EVENTS',

    RESET_SEARCH_OR_RESET: 'RESET_SEARCH_OR_RESET',

    HANDLE_PEGASSURE_PLAYBOOK_OPEN_DELETE_MODAL: 'HANDLE_PEGASSURE_PLAYBOOK_OPEN_DELETE_MODAL',
    HANDLE_PEGASSURE_PLAYBOOK_CLOSE_DELETE_MODAL: 'HANDLE_PEGASSURE_PLAYBOOK_CLOSE_DELETE_MODAL',

    /* Amazon S3 Bucket Upload Type */
    UPLOAD_IMAGE_TO_S3: 'UPLOAD_IMAGE_TO_S3',
    UPLOAD_IMAGE_TO_S3_PENDING: 'UPLOAD_IMAGE_TO_S3_PENDING',
    UPLOAD_IMAGE_TO_S3_FULFILLED: 'UPLOAD_IMAGE_TO_S3_FULFILLED',

    /* PATCH Presign URL Type for Pegassure Playbook PDF */
    GET_PEGASSURE_PLAYBOOK_PDF_PRESIGNED_URL: 'GET_PEGASSURE_PLAYBOOK_PDF_PRESIGNED_URL',
    GET_PEGASSURE_PLAYBOOK_PDF_PRESIGNED_URL_PENDING: 'GET_PEGASSURE_PLAYBOOK_PDF_PRESIGNED_URL_PENDING',
    GET_PEGASSURE_PLAYBOOK_PDF_PRESIGNED_URL_FULFILLED: 'GET_PEGASSURE_PLAYBOOK_PDF_PRESIGNED_URL_FULFILLED',

    /* POST Create Playbook Type API for Pegassure Playbook PDF */
    CREATE_PEGASSURE_PLAYBOOK_PDF: 'CREATE_PEGASSURE_PLAYBOOK_PDF',
    CREATE_PEGASSURE_PLAYBOOK_PDF_PENDING: 'CREATE_PEGASSURE_PLAYBOOK_PDF_PENDING',
    CREATE_PEGASSURE_PLAYBOOK_PDF_FULFILLED: 'CREATE_PEGASSURE_PLAYBOOK_PDF_FULFILLED',

    /* PUT Update Playbook Type API for Pegassure Playbook PDF */
    UPDATE_PEGASSURE_PLAYBOOK_PDF: 'UPDATE_PEGASSURE_PLAYBOOK_PDF',
    UPDATE_PEGASSURE_PLAYBOOK_PDF_PENDING: 'UPDATE_PEGASSURE_PLAYBOOK_PDF_PENDING',
    UPDATE_PEGASSURE_PLAYBOOK_PDF_FULFILLED: 'UPDATE_PEGASSURE_PLAYBOOK_PDF_FULFILLED',

    /* Trigger call for Update PUT method */
    UPDATE_PEGASSURE_PLAYBOOK_PDF_CLICKED: 'UPDATE_PEGASSURE_PLAYBOOK_PDF_CLICKED',

    /* DELETE Remove Playbook Pdf Type for Pegassure Playbook PDF */
    DELETE_PEGASSURE_PLAYBOOK_PDF: 'DELETE_PEGASSURE_PLAYBOOK_PDF',
    DELETE_PEGASSURE_PLAYBOOK_PDF_PENDING: 'DELETE_PEGASSURE_PLAYBOOK_PDF_PENDING',
    DELETE_PEGASSURE_PLAYBOOK_PDF_FULFILLED: 'DELETE_PEGASSURE_PLAYBOOK_PDF_FULFILLED',

    /* GET List of Playbook Pdf Type for Pegassure Playbook PDF */
    GET_PEGASSURE_PLAYBOOK_PDF_LIST: 'GET_PEGASSURE_PLAYBOOK_PDF_LIST',
    GET_PEGASSURE_PLAYBOOK_PDF_LIST_PENDING: 'GET_PEGASSURE_PLAYBOOK_PDF_LIST_PENDING',
    GET_PEGASSURE_PLAYBOOK_PDF_LIST_FULFILLED: 'GET_PEGASSURE_PLAYBOOK_PDF_LIST_FULFILLED',

    PEGASSURE_PLAYBOOK_LIST_PAGE_CLICKED: 'PEGASSURE_PLAYBOOK_LIST_PAGE_CLICKED',

    /* GET List of Playbook Pdf Labels Type for Pegassure Playbook PDF */
    GET_ALL_PLAYBOOK_PDF_LABELS: 'GET_ALL_PLAYBOOK_PDF_LABELS',
    GET_ALL_PLAYBOOK_PDF_LABELS_PENDING: 'GET_ALL_PLAYBOOK_PDF_LABELS_PENDING',
    GET_ALL_PLAYBOOK_PDF_LABELS_FULFILLED: 'GET_ALL_PLAYBOOK_PDF_LABELS_FULFILLED',

    /* GET Single Playbook Pdf Info Type for Pegassure Playbook PDF */
    GET_SINGLE_PLAYBOOK_PDF_INFO: 'GET_SINGLE_PLAYBOOK_PDF_INFO',
    GET_SINGLE_PLAYBOOK_PDF_INFO_PENDING: 'GET_SINGLE_PLAYBOOK_PDF_INFO_PENDING',
    GET_SINGLE_PLAYBOOK_PDF_INFO_FULFILLED: 'GET_SINGLE_PLAYBOOK_PDF_INFO_FULFILLED',

    /* GET All Logbook areas list occupied with Playbook PDF Type for Pegassure Playbook PDF */
    GET_ALL_LOGBOOK_AREA_OCCUPIED_WITH_PLAYBOOK: 'GET_ALL_LOGBOOK_AREA_OCCUPIED_WITH_PLAYBOOK',
    GET_ALL_LOGBOOK_AREA_OCCUPIED_WITH_PLAYBOOK_PENDING: 'GET_ALL_LOGBOOK_AREA_OCCUPIED_WITH_PLAYBOOK_PENDING',
    GET_ALL_LOGBOOK_AREA_OCCUPIED_WITH_PLAYBOOK_FULFILLED: 'GET_ALL_LOGBOOK_AREA_OCCUPIED_WITH_PLAYBOOK_FULFILLED',

    /* GET Playbook PDF Type info by logbook area */
    GET_PLAYBOOK_INFO_BY_LOG_NUMBER: 'GET_PLAYBOOK_INFO_BY_LOG_NUMBER',
    GET_PLAYBOOK_INFO_BY_LOG_NUMBER_PENDING: 'GET_PLAYBOOK_INFO_BY_LOG_NUMBER_PENDING',
    GET_PLAYBOOK_INFO_BY_LOG_NUMBER_FULFILLED: 'GET_PLAYBOOK_INFO_BY_LOG_NUMBER_FULFILLED',

};