import cloneDeep from 'clone-deep';
import history from '../../../router/history';
import { routes, userRoles } from '../../../utility/constants/constants';
import { PegassurePlaybookActionTypes } from './PegassurePlaybookActionTypes';
import * as API from '../../../API/PegassurePlaybookAPI/PegassurePlaybookApi';
import storage from '../../../utility/storage';
import SuccessAlert from '../../../utility/successAlert/successAlert';
import errorHandler from '../../../utility/errorHandler/errorHandler';
import { closeModel, convertDateFormatV5, makeFirstLetterCapital } from '../../../utility/utility';
import supportErrorHandler from '../../../utility/errorHandler/supportErrorHandler';
import { signInClicked } from '../AuthActions/authAction';
import InfoAlert from '../../../utility/infoAlert/infoAlert';
import { tzUTCDateTimeConverterTool_V1 } from '../../../utility/TimezoneOperations/timezoneUtility';
var momentTZ = require('moment-timezone');
const refreshPage = () => { window.location.reload(false); }

export const resetPegassurePlaybookEvents = () => {
    return {
        type: PegassurePlaybookActionTypes.RESET_PEGASSURE_PLAYBOOK_EVENTS,
    };
};

export const resetSearchOrResetPegassurePlaybook = () => {
    return {
        type: PegassurePlaybookActionTypes.RESET_SEARCH_OR_RESET,
    };
};

export const handlePegassurePlaybook_openDeleteModal = (deletePegPlaybookObject) => {
    return {
        type: PegassurePlaybookActionTypes.HANDLE_PEGASSURE_PLAYBOOK_OPEN_DELETE_MODAL,
        payload: deletePegPlaybookObject,
    };
};

export const handlePegassurePlaybook_closeDeleteModal = () => {
    return {
        type: PegassurePlaybookActionTypes.HANDLE_PEGASSURE_PLAYBOOK_CLOSE_DELETE_MODAL,
    };
};

/* PATCH Presign URL Action for Pegassure Playbook PDF */
export const getPegassurePlaybookPdfPresignUrl = (uploadPdfObject, arrayBuffer, extention) => {
    return (dispatch, getState) => {
        dispatch({
            type: PegassurePlaybookActionTypes.GET_PEGASSURE_PLAYBOOK_PDF_PRESIGNED_URL,
            payload: API.getPegassurePlaybookPdfPresignUrl(extention)
                .then((response) => {
                    let presigned_url = null
                    if (response.data.success) {
                        debugger
                        if (response.data.presigned_urls && response.data.presigned_urls[0]) {
                            presigned_url = response.data.presigned_urls[0].presigned_url;
                            uploadPdfObject.data.photoPath = response.data.presigned_urls[0].attachment_path;
                        }
                    }
                    
                    dispatch({
                        type: PegassurePlaybookActionTypes.UPLOAD_IMAGE_TO_S3,
                        payload: API.uploadImageToS3(presigned_url, arrayBuffer)
                            .then((response) => {
                                dispatch(createPegassurePlaybookPdf(uploadPdfObject));
                            })
                            .catch((error) => {
                                console.log(error);
                            }),
                    });
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};

/* POST Create Playbook Pdf Action for Pegassure Playbook PDF */
export const createPegassurePlaybookPdf = (params) => {
    return (dispatch, getState) => {
        dispatch({
            type: PegassurePlaybookActionTypes.CREATE_PEGASSURE_PLAYBOOK_PDF,
            payload: API.createPegassurePlaybookPdf(params)
                .then((response) => {
                    let page = 1;
                    let limit = 10;
                    if (response.data.success == true) {
                        document.getElementById('closePlaybookPdfUploadModal').click();
                        closeModel();
                        SuccessAlert('PDF Uploaded Successfully');
                        dispatch(getPegassurePlaybookPdfList({ page, limit }, {}));
                        return response.data;
                    } else if (response.data.success == false) {
                        document.getElementById('closePlaybookPdfUploadModal').click();
                        closeModel();
                        
                        let errorMessage= response.data?.message ?? "PDF Upload Failed"
                        errorHandler(errorMessage);
                        dispatch(getPegassurePlaybookPdfList({ page, limit }, {}));
                        return response.data;
                    }
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
}

/* PUT Update Playbook Pdf Action for Pegassure Playbook PDF */
export const updatePegassurePlaybookPdf = (body, id) => {
    return (dispatch, getState) => {
        dispatch({
            type: PegassurePlaybookActionTypes.UPDATE_PEGASSURE_PLAYBOOK_PDF,
            payload: API.updatePegassurePlaybookPdf(body, id)
                .then((response) => {
                    let page = 1;
                    let limit = 10;
                    if (response.data.success == true) {
                        document.getElementById('closePlaybookPdfUploadModal').click();
                        closeModel();
                        SuccessAlert('Successfully Renamed PDF');
                        dispatch(getPegassurePlaybookPdfList({ page, limit }, {}));
                        return response.data;
                    } else if (response.data.success == false) {
                        document.getElementById('closePlaybookPdfUploadModal').click();
                        closeModel();
                        errorHandler('PDF Renamed Failed');
                        dispatch(getPegassurePlaybookPdfList({ page, limit }, {}));
                        return response.data;
                    }
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
}

/* Trigger call for Update PUT method */
export const updatePegassurePlaybookPdfClicked = (playbookId) => {
    return {
        type: PegassurePlaybookActionTypes.UPDATE_PEGASSURE_PLAYBOOK_PDF_CLICKED,
        payload: playbookId,
    };
};

/* DELETE Remove Playbook Pdf Action for Pegassure Playbook PDF */
export const deletePegassurePlaybookPdf = (data) => {
    return (dispatch, getState) => {
        dispatch({
            type: PegassurePlaybookActionTypes.DELETE_PEGASSURE_PLAYBOOK_PDF,
            payload: API.deletePegassurePlaybookPdf(data)
                .then((response) => {
                    if (response.data.success == true) {
                        let page = 1;
                        let limit = cloneDeep(getState().PegassurePlaybookReducer.pegassurePlaybookList_no_of_rows);
                        SuccessAlert('PDF deleted successfully');
                        dispatch(getPegassurePlaybookPdfList({ page, limit }, {}));
                        return response.data;
                    }
                })
                .catch(error => {
                    console.log(error);
                })
        })
    }
}

/* GET List of Playbook Pdf Action for Pegassure Playbook PDF */
export const getPegassurePlaybookPdfList = (params, values) => {
    return (dispatch, getState) => {
        dispatch({
            type: PegassurePlaybookActionTypes.GET_PEGASSURE_PLAYBOOK_PDF_LIST,
            payload: API.getPegassurePlaybookPdfList(params)
                .then((response) => {
                    if (response.data.success) {
                        return {
                            pegassure_playbookList: response?.data?.data,
                            pegassure_playbookList_total_pages: response?.data?.totalPages,
                            pegassure_playbookList_no_of_rows: response?.data?.currentCount,
                            pegassure_playbookList_curPage: params?.page - 1,
                            pegassure_playbookList_total_tickets: response?.data?.total,
                            searchValues: values,
                            pegassure_playbookList_default_currentPage: response?.data?.currentPage,
                        };
                    };
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};

export const pegassurePlaybookListPageClicked = (data) => {
    return {
        type: PegassurePlaybookActionTypes.PEGASSURE_PLAYBOOK_LIST_PAGE_CLICKED,
        payload: data,
    };
};

/* GET List of Playbook Pdf Labels Action for Pegassure Playbook PDF */
export const getAllPlaybookPdfLabels = (params) => {
    return (dispatch, getState) => {
        dispatch({
            type: PegassurePlaybookActionTypes.GET_ALL_PLAYBOOK_PDF_LABELS,
            payload: API.getAllPlaybookPdfLabels(params)
                .then((response) => {

                    if (response.data.success) {
                        return response.data;
                    };
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};

/* GET Single Playbook Pdf Info Action for Pegassure Playbook PDF */
export const getSinglePlaybookPdfInfo = (id) => {
    return (dispatch, getState) => {
        dispatch({
            type: PegassurePlaybookActionTypes.GET_SINGLE_PLAYBOOK_PDF_INFO,
            payload: API.getSinglePlaybookPdfInfo(id)
                .then((response) => {
                    if (response.data.success) {
                        return response.data;
                    };
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};

/* GET All Logbook areas list occupied with Playbook PDF Action for Pegassure Playbook PDF */
export const getAllLogbookAreasOccupiedWithPlaybook = (id) => {
    return (dispatch, getState) => {
        dispatch({
            type: PegassurePlaybookActionTypes.GET_ALL_LOGBOOK_AREA_OCCUPIED_WITH_PLAYBOOK,
            payload: API.getAllLogbookAreasOccupiedWithPlaybook(id)
                .then((response) => {
                    if (response.data.success) {
                        return {
                            selected_playbook_info: response.data.playbook,
                            playbook_area_list: response?.data?.data ? response.data.data : [],
                            playbook_area_header_utils: { totalArea: response.data.total, playbookId: response.data.playbook.id, playbookTitle: response.data.playbook.title }
                        }
                    };
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};

/* GET Playbook PDF Action info by logbook area */
export const getPlaybookInfoByLogNumber = (logNumber) => {
    return (dispatch, getState) => {
        dispatch({
            type: PegassurePlaybookActionTypes.GET_PLAYBOOK_INFO_BY_LOG_NUMBER,
            payload: API.getPlaybookInfoByLogNumber(logNumber)
                .then((response) => {
                    if (response.data.success) {
                        return {
                            accountArea: {
                                name: response.data.logbook.name,
                                log_number: response.data.logbook.log_number,
                                days: response.data.logbook.days,
                                schedule_at: response.data.logbook.schedule_at,
                                account_id: response.data.logbook.account_id,
                                floor_id: response.data.logbook.floor_id,
                                area_type_id: response.data.logbook.area_type_id,
                                timeslots_attributes: response.data.logbook.timeslots_attributes,
                                remove_timeslot_ids: response.data.logbook.remove_timeslot_ids,
                                active_status: response.data.logbook.active_status,
                                logbook_time_slot: response.data.logbook.logbook_time_slot,
                                id: response.data.logbook.id,
                                critical: response.data.logbook.critical,
                                playbook: response.data.logbook.playbook.length ? response.data.logbook.playbook : []
                            }
                        };
                    };
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};