import React, { useState } from 'react';
import { Input } from '../UI/InputElement/InputElement';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { categorySearchValidator as validate } from '../../../utility/validator/validator';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import { convertUsersDataToCsvType } from '../../../utility/utility';
import { CSVLink, CSVDownload } from "react-csv";
import CategoryTable from '../CategoryComponent/CategoriesTable';
import NewCategory from '../CategoryComponent/NewCategory/newCategory';
import { MagicSpinner, PushSpinner, SwishSpinner, WaveSpinner, ClapSpinner } from 'react-spinners-kit';
import $ from 'jquery'
import InActiveCategoryList from './InActiveCategoryList';

// $(document).ready(function(){
// $("#active_status").click(function(){
//     $("#active_status_show").show();
//     $('#active_status_hide').hide();
//   });
// $("#inActive_status").click(function(){
//     $("#active_status_show").hide();
//     $('#active_status_hide').show();
// }) 
// })
 

export let Categories = (props) => {
    const [activeList, setActiveList] = useState(true);
    const [inactiveList, setinactiveList] = useState(false);

    const activeListCategory = () => {
        props.getCategories({hide: false})
        setActiveList(true)
        setinactiveList(false)
    }
    const inactiveListCategory = () => {
        props.getCategories({hide: true})
        setinactiveList(true)
        setActiveList(false)
    }

    const { handleSubmit, pristine, reset, submitting, error } = props;
    return (
        <>
            <main className="app-content wraper_content inspection_wraper categories_blc">
                <div className="tab_header search_filter_cont inspection_tab_content appcontent_inner">
                    <form onSubmit={handleSubmit}>
                        <input type="hidden" value="something" />
                        <div className="row">
                            {/* <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                                <div className="app-search">
                                    <Input className="fil_search_input input-modifier" name="name" type="search" placeholder="Search By Category" />
                                    <button className="app-search__button">
                                        <i class="fa fa-search" aria-hidden="true"></i>
                                    </button>
                                </div>
                            </div> */}
                            {/* <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                                <div className="app-search">
                                    <Input className="fil_search_input input-modifier" name="email" type="search" placeholder="Search By Email" />
                                    <button className="app-search__button">
                                        <i class="fa fa-search" aria-hidden="true"></i>
                                    </button>
                                </div>
                            </div> */}
                            {/* <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-6"> */}
                            {/* <button style={{ minWidth: '90px' }} className={"btn cst_btn btn_danger mr-2"} id="submit" type="submit">{"Go"} </button> */}
                            {/* <button style={{ minWidth: '90px' }} onClick={props.resetClicked} className={"btn cst_btn btn-outline-secondary"} id="reset" type="reset">{"Reset"} </button>
                            </div> */}
                            <div className="col-lg-6">
                            <div className="row">
                            <div className="col">
                                    <a href="JavaScript:void(0);" className="btn cst_btn btn_danger mr-2" id="active_status" onClick={activeListCategory}>Active Categories</a>
                                    <a href="JavaScript:void(0);" className="btn cst_btn btn_danger mr-2" id="inActive_status" onClick={inactiveListCategory}>In-active Categories</a>
                               </div>
                               </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="col-xl-12 col-lg-4 col-md-12 col-sm-12 text-right accounts_btn_wrap">
                                    <a href="JavaScript:void(0);" className="btn btn-secondary cst_btn" data-toggle="modal" data-target="#addPosition" data-backdrop="static" data-keyboard="false" onClick={props.openPopup}><i className="fa fa-plus icn_plus" aria-hidden="true"></i>New Category</a>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>

{
    activeList &&
        <section className="account_sec user_screen inspection_tab_content" id="active_status_show">
                                <CategoryTable
                                    deleteUser={props.deleteUser}
                                    users_rows={props.users_rows}
                                    // isLoading={props.isLoading}
                                    isCategories={props.isCategories}
                                    onUserPageSizeChange={props.onUserPageSizeChange}
                                    categoriesList={props.categoriesList ? props.categoriesList : []}
                                    editCategoryClicked={props.editCategoryClicked}
                                    openDeleteModel={props.openDeleteModel}
                                    handleClickDeleteModel={props.handleClickDeleteModel} />
                    </section>
} 
{
    inactiveList &&
              <section className="account_sec user_screen inspection_tab_content" id="active_status_hide">
                   <InActiveCategoryList 
                           deleteUser={props.deleteUser}
                                    users_rows={props.users_rows}
                                    // isLoading={props.isLoading}
                                    isCategories={props.isCategories}
                                    onUserPageSizeChange={props.onUserPageSizeChange}
                                    categoriesList={props.categoriesList ? props.categoriesList : []}
                                    editCategoryClicked={props.editCategoryClicked}
                                    openDeleteModel={props.openDeleteModel}
                                    handleClickDeleteModel={props.handleClickDeleteModel}
    />
</section>
}
            </main>
            <Dialog
                open={props.openDeleteModel}
                onClose={props.handleClose}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title" style={{ fontSize: '13', color: '#06080f' }}>
                    <h3 style={{ color: '#ab385e' }}>Are you sure?</h3>
                </DialogTitle>

                <DialogActions>
                    <button style={{ minHeight: '36px', minWidth: '80px' }} onClick={() => props.deleteCategory(props.deleteObject.id)} disabled={props.isLoading} className={props.isLoading ? "btn cst_btn btn_danger mr-2 btn-wait" : "btn cst_btn btn_danger mr-2"} id="delete" type="delete">{props.isLoading ? "" : "Delete"}</button>
                    <button style={{ minHeight: '36px', minWidth: '80px' }} onClick={props.handleDeleteClose} className={"btn cst_btn btn-outline-secondary"} id="cancel" type="cancel">Cancel</button>
                    {/* <Button onClick={props.handleClose} variant="contained" color="primary">
                        Cancel
                        </Button>


                    <Button onClick={() => props.deleteAccount(props.deleteObject.id)} disabled={props.isLoading} variant="contained" color="secondary">
                        {props.isLoading ? <CircularProgress size={24} /> : 'Delete'}
                    </Button> */}
                </DialogActions>
            </Dialog>
            {props.isOpenModel ?
                <NewCategory
                    onSubmit={props.addNewCategory}
                    closePopup={props.closePopup}
                    isLoading={props.isLoading}
                /> : null}
        </>
    )
}


const mapStateToProps = (state) => {
    let email = "";
    let name = "";

    return { initialValues: { email, name } }
}

Categories = reduxForm({
    form: 'searchUserForm',
    validate,
    enableReinitialize: true
})(Categories);

Categories = connect(mapStateToProps)(Categories)

export default Categories;