import React, { useEffect, useState } from 'react'
import { Input } from '../../../../UI/InputElement/reactFormHookInputField';
import { useSelector } from 'react-redux';
import { useWatch } from 'react-hook-form';
import moment from 'moment';
function CleaningWorkersForm(props) {
  const current = new Date()
  const { cleaning_worker_list } = useSelector((state) => state.FormAuditModReducer)
  const getEmployeeList = (employee) => {
    if (employee && employee.length > 0) {
      return employee && employee.map((data) => {
        return {
          value: data.id,
          label: data.first_name + ' ' + data.last_name,
        }
      })
    } else {
      return []
    }
  };
  const [listOfemployee, setEmployeeList] = useState(getEmployeeList(cleaning_worker_list))

  useEffect(() => {
    setEmployeeList(getEmployeeList(cleaning_worker_list))
  }, [cleaning_worker_list])

  const handleValidateDateCompleted = (data,) => {

    const scheduledDate = props.getValues(`items.${props?.index}.scheduled_date`)
    const completed = props.getValues(`items.${props?.index}.completed`)
    if (!scheduledDate) return true
    if (completed === "No" && !data) return true
    if (completed === "Yes" && !data) return "This field is required"

    if (!data) return true
    
    const scheduleDatem = moment(scheduledDate).startOf('day')
    const completeDateM = moment(data).startOf('day')
      
    if (completeDateM.isAfter(scheduleDatem) || scheduleDatem.isSame(completeDateM)) {
      return true
    }
    else {
      //props.setFocus(`items.${props?.index}.scheduled_date`)
      return "Completion date should be greater than the scheduled date"
    }

  }

  const completedStateWatch = useWatch({
    name: `items.${props?.index}.completed`,
    control: props.control
  })

  useEffect(() => {
    if (completedStateWatch == "No") {
      const value = props.getValues(`items.${props?.index}.date_of_completed`)

      if (value) {
        props.setValue(`items.${props?.index}.date_of_completed`, "")
      }

    }
  }, [completedStateWatch])

  return (
    <>
      <div class="tm_form_wrap">
        <div class="tm_form_innner">
          <div class="form">
            <div class="row">
              <div class="col-md-6 col-sm-12">
                <div class="form-group">
                  {/* <label for="disabledSelect" class="form-label">
                            Cleaning worker
                          </label>
                          <select id="disabledSelect" class="form-select">
                            <option>Please Select</option>
                            <option>1</option>
                            <option>2</option>
                            <option>3</option>
                            <option>4</option>
                          </select> */}

                  <Input
                    type="inputSelect"
                    labelClasses="form-label"
                    control={props.control}
                    controller={props.controller}
                    selectLabel="Cleaning worker"
                    selectName={`items.${props?.index}.worker_id`}
                    id={`items.${props?.index}.worker_id`}
                    selectOptions={listOfemployee}
                    placeholder={"Please Select"}
                    required={props.register(`items.${props?.index}.worker_id`, {
                      required: "This field is required"
                    })}
                    validationErrorMessage={props.errors?.items?.[props?.index]?.worker_id?.message}
                    selectClasses="form-select"
                    disabled={props?.isView}
                  />

                  {/* <div class="dropdown_caret">
                            <img
                              src="/images/icons/dropdown_caret.svg"
                              alt="dropdown_caret"
                            />
                          </div> */}
                </div>
              </div>
              <div class="col-md-6 col-sm-12">
                <div class="form-group">


                  <Input
                    type="date"
                    //datePickerType="month"
                    formControlClasses="form-control"
                    id={`items.${props?.index}.scheduled_date`}

                    placeholder="MM/DD/YYYY"
                    control={props.control}
                    controller={props.controller}
                    selectName={`items.${props?.index}.scheduled_date`}
                    dateFormat="MM/dd/yyyy"
                    // minDate={new Date().setDate(current.getDate() - 400)}
                    preventReadOnly={false}
                    label="Scheduled date"
                    formControlProps={props.register(`items.${props?.index}.scheduled_date`, {
                      required: "This field is required", maxLength: 80
                    })}
                    validationErrorMessage={props.errors?.items?.[props?.index]?.scheduled_date?.message ?? ""}
                    disabled={props?.isView}
                  />

                  {/* <label for="jobname" class="form-label">
                            Scheduled date
                          </label>
                          <input
                            type="date"
                            class="form-control"
                            id="jobname"
                            aria-describedby=""
                            placeholder=""
                          /> */}
                  {/* <div class="calendar_icon">
                            <img
                              src="/images/icons/calendar.svg"
                              alt="calendar"
                            />
                          </div> */}
                </div>
              </div>
              <div class="col-md-6 col-sm-12">
                <div class="form-group">


                  {/* <Input
                          type="inputSelect"
                          labelClasses="form-label"
                          control={props.control}
                          controller={props.controller}
                          selectLabel="completed"
                          selectName={`items.${props?.index}.worker_id`}
                          id={`items.${props?.index}.worker_id`}
                          selectOptions={listOfemployee}
                          required={props.register(`items.${props?.index}.worker_id`, {
                          required: "This Fiels is required"})}
                          validationErrorMessage={props.errors?.items?.[props?.index]?.worker_id?.message}
                          selectClasses="form-select"  
                          disabled={props?.isView}
                        /> */}

                  <Input
                    type="inputSelect"
                    required
                    selectLabel="Completed"
                    control={props.control}
                    controller={props.controller}
                    selectName={`items.${props?.index}.completed`}
                    id={`items.${props?.index}.completed`}
                    placeholder={"Please Select"}
                    selectOptions={[{ value: "Yes", label: "Yes" }, { value: "No", label: "No" }]}
                    formControlProps={props.register(`items.${props?.index}.completed`, {
                      required: "This field is required"
                    })}
                    selectClasses="form-select"
                    validationErrorMessage={props.errors?.items?.[`${props?.index}`]?.completed?.message}
                    disabled={props?.isView}

                  />
                  {/* <Input
                            type="text"
                            id={`items.${props.index}.completed`}
                            name={`items.${props.index}.completed`}
                            formControlClasses="form-control"
                            placeholder="Type here"
                            required
                            label="Completed"
                            formControlProps={props.register(
                            `items.${props.index}.completed`,
                            {
                                required: "This Field is Required",
                                maxLength: 80,
                            }
                            )}
                            validationErrorMessage={
                            props.errors?.items?.[props.index]?.completed
                                ?.message ?? ""
                            }
                            disabled={props?.isView}
                        /> */}
                  {/* <label for="jobname" class="form-label">
                            Completed
                          </label>
                          <input
                            type="text"
                            class="form-control"
                            id="jobname"
                            aria-describedby=""
                            placeholder="Type here"
                          /> */}
                </div>
              </div>
              <div class="col-md-6 col-sm-12">
                <div class="form-group">
                  <Input
                    type="date"
                    //datePickerType="month"
                    formControlClasses="form-control"
                    id={`items.${props?.index}.date_of_completed`}
                    placeholder="MM/DD/YYYY"
                    control={props.control}
                    controller={props.controller}
                    selectName={`items.${props?.index}.date_of_completed`}
                    dateFormat="MM/dd/yyyy"
                    // minDate={new Date().setDate(current.getDate() - 400)}
                    preventReadOnly={false}
                    label="Date completed"
                    formControlProps={props.register(`items.${props?.index}.date_of_completed`, {
                      // required: "This field is required",
                      validate: handleValidateDateCompleted
                    })}
                    validationErrorMessage={props.errors?.items?.[props?.index]?.date_of_completed?.message ?? ""}
                    disabled={props?.isView || completedStateWatch === "No"}
                  />
                  {/* <label for="jobname" class="form-label">
                            Date completed
                          </label>
                          <input
                            type="date"
                            class="form-control"
                            id="jobname"
                            aria-describedby=""
                            placeholder=""
                          /> */}
                  {/* <div class="calendar_icon">
                            <img
                              src="/images/icons/calendar.svg"
                              alt="calendar"
                            />
                          </div> */}
                </div>
              </div>
              <div class="col-md-12 col-sm-12">
                <div class="form-group">

                  <Input
                    type="textarea"
                    id={`items.${props.index}.comments`}
                    name={`items.${props.index}.comments`}
                    formControlClasses="form-control"
                    placeholder="Type here"
                    required
                    label="Comments"
                    formControlProps={props.register(
                      `items.${props.index}.comments`,
                      {
                        required: "This field is required",
                        maxLength: 80,
                      }
                    )}
                    validationErrorMessage={
                      props.errors?.items?.[props.index]?.comments
                        ?.message ?? ""
                    }
                    disabled={props?.isView}
                  />

                  {/* <label for="floatingTextarea">Comments</label>
                          <textarea
                            class="form-control"
                            placeholder="Type here"
                            id="floatingTextarea"
                          ></textarea> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {
        props?.index > 0 ?
          <div class="col-md-12 col-sm-12" >
            <div class="action_button pt-3">
              <span onClick={props.removeFromTaskList(props.index)}>
                <img src='/images/icons/trash.svg' />
              </span>
            </div>
          </div> : null
      }

    </>
  )
}

export default CleaningWorkersForm