import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

const Field = (h, w) => {
    return (<Skeleton height={h} width={w} />)
}

const Circle = (h, w) => {
    return (<Skeleton circle={true} height={h} width={w} />)
}

const QuotesSkeleton = () => {

    return (
        <div className="quote_form">
            <h1>{Field(30, 200)}</h1>
            <div className="addQuote_form">
                {/* <form onSubmit={handleSubmit}> */}
                <div className="form-group">
                    <label className="form_title"></label>
                    {Field(20)}
                    {/* <Input name="quote[account_id]" noOptionsMessage={noOptionsMessage} type="inputSelect" options={accounts} /> */}
                </div>
                <div className="form-group" style={{ marginTop: '50px' }}>
                    {/* <label className="form_title"></label> */}
                    {/* <div className="custom-control custom-radio custom-control-inline"> */}
                    <span style={{ marginRight: '5px' }}>{Circle(20, 20)}</span>
                    {/* <Input type="radio" onClick={props.toggleTagOpportunities} labelClass="custom-control-label" forLable={"customRadio1"} value="true" label={"Yes"} className="custom-control-input" id="customRadio1" name="quote[tag_opportunity]" /> */}
                    {/* <label className="custom-control-label" for="customRadio1"></label> */}
                    {/* </div> */}
                    {/* <div className="custom-control custom-radio custom-control-inline"> */}
                    <span>{Circle(20, 20)}</span>
                    {/* <Input type="radio" onClick={props.toggleTagOpportunities} labelClass="custom-control-label" forLable={"customRadio2"} value="false" label={"No"} className="custom-control-input" id="customRadio2" name="quote[tag_opportunity]" /> */}
                    {/* <label className="custom-control-label" for="customRadio2"></label> */}
                    {/* </div> */}
                </div>
                <div className="desc_block" >
                    <div className="form-group" style={{ marginTop: '50px' }}>
                        <label className="form_title" ></label>
                        {Field(20, 200)}
                        {/* <Input type="number" min={1} name="quote[total_sale]" className="form-control input-modifier" id="total_sale" /> */}
                    </div>
                    <div className="form-group" style={{ marginTop: '50px' }}>
                        <label className="form_title"></label>
                        {Field(100)}
                        {/* <Input type="textarea" name="quote[description]" className="form-control input-modifier" rows="5" placeholder="Type here" id="comment" /> */}
                    </div>
                </div>
                <div className="form-group btn_block mb-0" style={{ marginTop: '50px' }}>
                    <span style={{ marginRight: '5px' }}>{Field(20, 100)}</span>
                    <span>{Field(20, 100)}</span>
                    {/* <button disabled={props.isLoading} type="submit" className={props.isLoading ? "btn cst_btn btn_danger mr-2 btn-wait" : "btn cst_btn btn_danger mr-2"}>{props.isLoading ? "" : "Submit"}</button>
                        <button type="button" onClick={props.onCancleClicked} className="btn cst_btn btn-outline-secondary">Cancel</button> */}
                </div>
                {/* </form> */}
            </div>
        </div>
    )
}

export default QuotesSkeleton;