import React, { useEffect, useState } from 'react';
import { Bar, Line } from 'react-chartjs-2';
import { emptyData, BarGraphCountOptions, BarGraphSaleOptions, LineGraphQuoteCountOptions, LineGraphSaleOptions, routes } from '../../../utility/constants/constants';
import WeekMonthYearLists from './weekMonthYearLists';
import { trimString, getSecMintHoursDays, IsArrayofObjectEmpty } from '../../../utility/utility';
import EmptyGraph from './emptyGraph';
import { Status, FilteredQuotes } from '../../../utility/constants/constants';
import { MagicSpinner, PushSpinner, SwishSpinner, WaveSpinner, ClapSpinner } from 'react-spinners-kit';
import DashboardSkeleton from './dashboardSkeleton';
import posed from 'react-pose';
import PastInspections from './pastInspections';
import modeModel from '../../component/updateProfile/modeModal';
import { Link } from 'react-router-dom';
import '../../../styles/custom.css';
import InspectionSchedules from './InspectionSchedules.js';
import moment from 'moment';


const graphStype = {
    marginTop: '0px',
    paddingTop: '0px'
}

const AverageInspectionScoreStyle = {
   width:'956px',
   height:'300px'
}

const Box = posed.div({
    hoverable: true,
    pressable: true,
    init: {
        scale: 1,
        filter: ' blur(0.1px)'
        // boxShadow: '0px 0px 0px rgba(0,0,0,0)'
    },
    hover: {
        scale: 1.1,
        boxShadow: '0px 5px 10px rgba(0,0,0,0.2)',
        filter: ' blur(0px)'
    },
    press: {
        scale: 1,
        // boxShadow: '0px 2px 5px rgba(0,0,0,0.1)'
    }
});

const BarGraphStyle = {
    // label: '',
    backgroundColor: '#a1395e',
    hoverBackgroundColor: '#a1395eb3',
    borderWidth: 2,
    barThickness: 5,
    // barPercentage: 0.5
}

const DashboardData = (props) => {
    if (!props.inspectionDashboardData || !props.ticketDashboardData || !props.quoteDashboardData || !props.logbookDashboardData) {
        return (
            <DashboardSkeleton />
        )
    } else {

        const Average_Inspection_Score = {
            title: {
                display: true,
                fontSize: 20,
                padding: 30,
                fontColor: '#962d39',
                text: 'Average Inspection Score'
            },
            // ...LineGraphQuoteCountOptions
            ...BarGraphCountOptions
        }
        const Average_Inspection_Lable = [];
        const Average_Inspection_Data = [];
        let Average_Inspection = null;

        if (props.inspectionDashboardData.average_inspection_score_chart_data) {
            for (let data of props.inspectionDashboardData.average_inspection_score_chart_data) {
                Average_Inspection_Lable.push(data[0]);
                Average_Inspection_Data.push(String(data[1]));
            }
        }

        Average_Inspection = {
            labels: Average_Inspection_Lable,
            datasets: [{
                ...BarGraphStyle, label: '', borderColor: '#a1395e',
                borderWidth: 2, backgroundColor: '#a1395e', data: Average_Inspection_Data, fill: false
            }]
        }

        const Inspections_Performed_options = {
            title: {
                display: true,
                fontSize: 20,
                padding: 30,
                fontColor: '#962d39',
                text: 'Inspections Performed'
            },
            ...BarGraphCountOptions
        }
        const Inspections_Performed_Lable = [];
        const Inspections_Performed_Data = [];
        let Inspections_Performed = null;
        if (props.inspectionDashboardData.inspection_count_chart_data) {
            for (let data of props.inspectionDashboardData.inspection_count_chart_data) {
                Inspections_Performed_Lable.push(data[0]);
                Inspections_Performed_Data.push(String(data[1]));
            }
        }

        Inspections_Performed = {
            labels: Inspections_Performed_Lable,
            datasets: [{ ...BarGraphStyle, label: '', backgroundColor: '#a1395e', data: Inspections_Performed_Data }]

        }

        const Tickets_Created_options = {
            title: {
                display: true,
                fontSize: 20,
                padding: 30,
                fontColor: '#962d39',
                text: 'Tickets Created'
            },
            ...BarGraphCountOptions
        }
        const Tickets_Created_Lable = [];
        const Tickets_Created_Data = [];
        let Tickets_Created = null;
        if (props.ticketDashboardData.ticket_count_chart_data) {
            for (let data of props.ticketDashboardData.ticket_count_chart_data) {
                Tickets_Created_Lable.push(data[0]);
                Tickets_Created_Data.push(String(data[1]));
            }
        }

        Tickets_Created = {
            labels: Tickets_Created_Lable,
            datasets: [{ ...BarGraphStyle, label: '', backgroundColor: '#a1395e', data: Tickets_Created_Data }]
        }

        // * PEGASSURE_LOGBOOKS_PENDING
        const PegAssure_Logbooks_Pending_options = {
            title: {
                display: true,
                fontSize: 20,
                padding: 30,
                fontColor: '#962d39',
                text: 'PegAssure Logbooks Pending'
            },
            ...BarGraphCountOptions
        }
        const PegAssure_Logbooks_Pending_Lable = [];
        const PegAssure_Logbooks_Pending_Data = [];
        let PegAssure_Logbooks_Pending = null;
        if (props.logbookDashboardData.pending_chart) {
            for (let data of props.logbookDashboardData.pending_chart) {
                PegAssure_Logbooks_Pending_Lable.push(data[0]);
                PegAssure_Logbooks_Pending_Data.push(String(data[1]));
            }
        }
        PegAssure_Logbooks_Pending = {
            labels: PegAssure_Logbooks_Pending_Lable,
            datasets: [{ ...BarGraphStyle, label: '', backgroundColor: '#a1395e', data: PegAssure_Logbooks_Pending_Data }]
        }
        // * PEGASSURE_LOGBOOKS_REVIEWED
        const PegAssure_Logbooks_Reviewed_options = {
            title: {
                display: true,
                fontSize: 20,
                padding: 30,
                fontColor: '#962d39',
                text: 'PegAssure Logbooks Reviewed'
            },
            ...BarGraphCountOptions
        }
        const PegAssure_Logbooks_Reviewed_Lable = [];
        const PegAssure_Logbooks_Reviewed_Data = [];
        let PegAssure_Logbooks_Reviewed = null;
        if (props.logbookDashboardData.reviewed_chart) {
            for (let data of props.logbookDashboardData.reviewed_chart) {
                PegAssure_Logbooks_Reviewed_Lable.push(data[0]);
                PegAssure_Logbooks_Reviewed_Data.push(String(data[1]));
            }
        }
        PegAssure_Logbooks_Reviewed = {
            labels: PegAssure_Logbooks_Reviewed_Lable,
            datasets: [{ ...BarGraphStyle, label: '', backgroundColor: '#a1395e', data: PegAssure_Logbooks_Reviewed_Data }]
        }

        const Top_ten_requestors_by_users_options = {
            title: {
                display: true,
                fontSize: 20,
                padding: 30,
                fontColor: '#962d39',
                text: 'Top 10 Quotes, Created by Users'
            },
            ...BarGraphCountOptions
        }
        const Top_ten_requestors_by_users_Lable = [];
        const Top_ten_requestors_by_users_Data = [];
        let Top_ten_requestors_by_users = null;
        if (props.quoteDashboardData.top_ten_requestors_by_users) {

            for (let data of props.quoteDashboardData.top_ten_requestors_by_users) {
                Top_ten_requestors_by_users_Lable.push(data.user_name);
                Top_ten_requestors_by_users_Data.push(String(data.quotes_count));

            }
        }

        Top_ten_requestors_by_users = {
            labels: Top_ten_requestors_by_users_Lable,
            datasets: [{ ...BarGraphStyle, label: '', data: Top_ten_requestors_by_users_Data }],
        }

        const Top_ten_sales_by_users_options = {
            title: {
                display: true,
                fontSize: 20,
                padding: 30,
                fontColor: '#962d39',
                text: 'Top 10 Sales, Created by Users'
            },
            ...BarGraphSaleOptions
        }
        const Top_ten_sales_by_users_Lable = [];
        const Top_ten_sales_by_users_Data = [];
        let Top_ten_sales_by_users = null;
        if (props.quoteDashboardData.top_ten_sales_by_users) {

            for (let data of props.quoteDashboardData.top_ten_sales_by_users) {
                Top_ten_sales_by_users_Lable.push(data.user_name);
                Top_ten_sales_by_users_Data.push(data.total_sale);

            }
        }

        Top_ten_sales_by_users = {
            labels: Top_ten_sales_by_users_Lable,
            datasets: [{ ...BarGraphStyle, label: '', data: Top_ten_sales_by_users_Data }],

        }

        const Top_ten_quotes_approved_by_admin_options = {
            title: {
                display: true,
                fontSize: 20,
                padding: 30,
                fontColor: '#962d39',
                text: 'Top 10 Quotes, Sent by User(#)'
            },
            ...BarGraphCountOptions
        }
        const Top_ten_quotes_approved_by_admin_Lable = [];
        const Top_ten_quotes_approved_by_admin_Data = [];
        let Top_ten_quotes_approved_by_admin = null;
        if (props.quoteDashboardData.top_ten_quotes_approved_by_admin) {

            for (let data of props.quoteDashboardData.top_ten_quotes_approved_by_admin) {
                Top_ten_quotes_approved_by_admin_Lable.push(data.user_name);
                Top_ten_quotes_approved_by_admin_Data.push(String(data.quotes_count));

            }
        }

        Top_ten_quotes_approved_by_admin = {
            labels: Top_ten_quotes_approved_by_admin_Lable,
            datasets: [{ ...BarGraphStyle, label: '', data: Top_ten_quotes_approved_by_admin_Data }]

        }

        const Top_ten_sales_approved_by_admin_options = {
            title: {
                display: true,
                fontSize: 20,
                padding: 30,
                fontColor: '#962d39',
                text: 'Top 10 Sales, Sent by User($)'
            },
            ...BarGraphSaleOptions
        }
        const Top_ten_sales_approved_by_admin_Lable = [];
        const Top_ten_sales_approved_by_admin_Data = [];
        let Top_ten_sales_approved_by_admin = null;
        if (props.quoteDashboardData.top_ten_sales_approved_by_admin) {

            for (let data of props.quoteDashboardData.top_ten_sales_approved_by_admin) {
                Top_ten_sales_approved_by_admin_Lable.push(data.user_name);
                Top_ten_sales_approved_by_admin_Data.push(data.total_sale);

            }
        }

        Top_ten_sales_approved_by_admin = {
            labels: Top_ten_sales_approved_by_admin_Lable,
            datasets: [{ ...BarGraphStyle, label: '', data: Top_ten_sales_approved_by_admin_Data }]
        }

        const Top_ten_quotes_approved_by_client_options = {
            title: {
                display: true,
                fontSize: 20,
                padding: 30,
                fontColor: '#962d39',
                text: 'Top 10 Quotes, Approved by Customer(#)'
            },
            ...BarGraphCountOptions
        }
        const Top_ten_quotes_approved_by_client_Lable = [];
        const Top_ten_quotes_approved_by_client_Data = [];
        let Top_ten_quotes_approved_by_client = null;
        if (props.quoteDashboardData.top_ten_quotes_approved_by_client) {

            for (let data of props.quoteDashboardData.top_ten_quotes_approved_by_client) {
                Top_ten_quotes_approved_by_client_Lable.push(data.user_name);
                Top_ten_quotes_approved_by_client_Data.push(String(data.quotes_count));

            }
        }

        Top_ten_quotes_approved_by_client = {
            labels: Top_ten_quotes_approved_by_client_Lable,
            datasets: [{ ...BarGraphStyle, label: '', data: Top_ten_quotes_approved_by_client_Data }]

        }

        const Top_ten_sales_approved_by_client_options = {
            title: {
                display: true,
                fontSize: 20,
                padding: 30,
                fontColor: '#962d39',
                text: 'Top 10 Quotes, Approved by Customer($)'
            },
            ...BarGraphSaleOptions
        }
        const Top_ten_sales_approved_by_client_Lable = [];
        const Top_ten_sales_approved_by_client_Data = [];
        let Top_ten_sales_approved_by_client = null;
        if (props.quoteDashboardData.top_ten_sales_approved_by_client) {

            for (let data of props.quoteDashboardData.top_ten_sales_approved_by_client) {
                Top_ten_sales_approved_by_client_Lable.push(data.user_name);
                Top_ten_sales_approved_by_client_Data.push(data.total_sale);
            }
        }

        Top_ten_sales_approved_by_client = {
            labels: Top_ten_sales_approved_by_client_Lable,
            datasets: [{ ...BarGraphStyle, label: '', data: Top_ten_sales_approved_by_client_Data }]

        }


        const Top_ten_quotes_rejected_by_admin_options = {
            title: {
                display: true,
                fontSize: 20,
                padding: 30,
                fontColor: '#962d39',
                text: 'Top 10 Quotes, Rejected by Admin'
            },
            ...BarGraphCountOptions
        }
        const Top_ten_quotes_rejected_by_admin_Lable = [];
        const Top_ten_quotes_rejected_by_admin_Data = [];
        let Top_ten_quotes_rejected_by_admin = null;
        if (props.quoteDashboardData.top_ten_quotes_rejected_by_admin) {

            for (let data of props.quoteDashboardData.top_ten_quotes_rejected_by_admin) {
                Top_ten_quotes_rejected_by_admin_Lable.push(data.user_name);
                Top_ten_quotes_rejected_by_admin_Data.push(String(data.quotes_count));
            }
        }

        Top_ten_quotes_rejected_by_admin = {
            labels: Top_ten_quotes_rejected_by_admin_Lable,
            datasets: [{ ...BarGraphStyle, label: '', data: Top_ten_quotes_rejected_by_admin_Data }]

        }


        const Top_ten_sales_rejected_by_admin_options = {
            title: {
                display: true,
                fontSize: 20,
                padding: 30,
                fontColor: '#962d39',
                text: 'Top 10 Sales, Rejected by Admin'
            },
            ...BarGraphSaleOptions
        }
        const Top_ten_sales_rejected_by_admin_Lable = [];
        const Top_ten_sales_rejected_by_admin_Data = [];
        let Top_ten_sales_rejected_by_admin = null;
        if (props.quoteDashboardData.top_ten_sales_rejected_by_admin) {

            for (let data of props.quoteDashboardData.top_ten_sales_rejected_by_admin) {
                Top_ten_sales_rejected_by_admin_Lable.push(data.user_name);
                Top_ten_sales_rejected_by_admin_Data.push(data.total_sale);

            }
        }

        Top_ten_sales_rejected_by_admin = {
            labels: Top_ten_sales_rejected_by_admin_Lable,
            datasets: [{ ...BarGraphStyle, label: '', data: Top_ten_sales_rejected_by_admin_Data }]

        }


        const Top_ten_quotes_denied_by_client_options = {
            title: {
                display: true,
                fontSize: 20,
                padding: 30,
                fontColor: '#962d39',
                text: 'Top 10 Quotes, Denied by Customers'
            },
            ...BarGraphCountOptions
        }
        const Top_ten_quotes_denied_by_client_Lable = [];
        const Top_ten_quotes_denied_by_client_Data = [];
        let Top_ten_quotes_denied_by_client = null;
        if (props.quoteDashboardData.top_ten_quotes_denied_by_client) {

            for (let data of props.quoteDashboardData.top_ten_quotes_denied_by_client) {
                Top_ten_quotes_denied_by_client_Lable.push(data.user_name);
                Top_ten_quotes_denied_by_client_Data.push(String(data.quotes_count));

            }
        }

        Top_ten_quotes_denied_by_client = {

            labels: Top_ten_quotes_denied_by_client_Lable,
            datasets: [{ ...BarGraphStyle, label: '', data: Top_ten_quotes_denied_by_client_Data }]

        }


        const Top_ten_sales_denied_by_client_options = {
            title: {
                display: true,
                fontSize: 20,
                padding: 30,
                fontColor: '#962d39',
                text: 'Top 10 Sales, Denied by Customers'
            },
            ...BarGraphSaleOptions
        }
        const Top_ten_sales_denied_by_client_Lable = [];
        const Top_ten_sales_denied_by_client_Data = [];
        let Top_ten_sales_denied_by_client = null;
        if (props.quoteDashboardData.top_ten_sales_denied_by_client) {

            for (let data of props.quoteDashboardData.top_ten_sales_denied_by_client) {
                Top_ten_sales_denied_by_client_Lable.push(data.user_name);
                Top_ten_sales_denied_by_client_Data.push(data.total_sale);

            }
        }

        Top_ten_sales_denied_by_client = {
            labels: Top_ten_sales_denied_by_client_Lable,
            datasets: [{ ...BarGraphStyle, label: '', data: Top_ten_sales_denied_by_client_Data }]
        }

        return (
            <>
                    {/* {props.modeModel ? <modeModel/> : null} */}
                <WeekMonthYearLists {...props} />
                {/* <!-- chart --> */}
                {props.isLoading ?
                    <div  >
                        <ClapSpinner backColor="#89073c" size={40} frontColor="#89073c8a" />
                    </div>
                    :

                    <div className="chart-content">
                        <div className="row">
                            <div class="col-lg-9 col-xl-10">
                                <div className="row">
                                    <div className="col-xl-12">
                                        <div className="row">
                                            <div className="col-xl-12 chart_cont_box graph_responsive" onClick={() => props.viewFilterData(FilteredQuotes.PENDING_FOR_APPROVAL, 'inspections')}>
                                                {Average_Inspection.datasets[0].data[0] === '0' || Average_Inspection.datasets[0].data.length === 0 ?
                                                    <EmptyGraph title={'Top 10 Quotes, Sent by User(#)'} />
                                                    :
                                                    <div className="tile chart-box-botL" id=''>
                                                        <div className="chart-img" style={graphStype}><Bar height={15} width={50} data={Average_Inspection} options={Average_Inspection_Score} id="desktop_screen_graph"/></div>
                                                        <div className="chart-img" style={graphStype}><Bar height={45} width={50} data={Average_Inspection} options={Average_Inspection_Score} id="mobile_screen_graph"/></div>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-xl-12">
                                        <div className="row">
                                            <div className="col-xl-6 chart_cont_box" onClick={() => props.viewFilterData(FilteredQuotes.PENDING_FOR_APPROVAL, 'inspections')}>
                                                {IsArrayofObjectEmpty(Inspections_Performed.datasets[0].data) || Inspections_Performed.datasets[0].data.length === 0 ?
                                                    <EmptyGraph title={'Inspections Performed'} />
                                                    :
                                                    <div className="tile chart-box-botL" id='inspectionsPerformedId' style={graphStype}>
                                                        <div className="chart-img" style={graphStype}>
                                                            <Bar height={45} width={50} data={Inspections_Performed} options={Inspections_Performed_options} />
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                            <div className="col-xl-6 chart_cont_box" onClick={() => props.viewFilterData(FilteredQuotes.PENDING_FOR_APPROVAL, 'tickets')}>
                                                {IsArrayofObjectEmpty(Tickets_Created.datasets[0].data) || Tickets_Created.datasets[0].data.length === 0 ?
                                                    <EmptyGraph title={'Tickets Created'} />
                                                    :
                                                    <div className="tile chart-box-botL" id="ticketsCreatedId" style={graphStype}>
                                                        <div className="chart-img" style={graphStype}>
                                                            <Bar height={45} width={50} data={Tickets_Created} options={Tickets_Created_options} />
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-xl-12">
                                        <div className="row">
                                            <div className="col-xl-6 chart_cont_box" onClick={() => props.viewFilterData('pending', 'logbook')}>
                                                {IsArrayofObjectEmpty(PegAssure_Logbooks_Pending.datasets[0].data) || PegAssure_Logbooks_Pending.datasets[0].data.length === 0 ?
                                                    <EmptyGraph title={'PegAssure Logbooks Pending'} />
                                                    :
                                                    <div className="tile chart-box-botL" id='pegAssureLogbooksPendingId' style={graphStype}>
                                                        <div className="chart-img" style={graphStype}>
                                                            <Bar height={45} width={50} data={PegAssure_Logbooks_Pending} options={PegAssure_Logbooks_Pending_options} />
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                            <div className="col-xl-6 chart_cont_box" onClick={() => props.viewFilterData('reviewed', 'logbook')}>
                                                {IsArrayofObjectEmpty(PegAssure_Logbooks_Reviewed.datasets[0].data) || PegAssure_Logbooks_Reviewed.datasets[0].data.length === 0 ?
                                                    <EmptyGraph title={'PegAssure Logbooks Reviewed'} />
                                                    :
                                                    <div className="tile chart-box-botL" id="pegAssureLogbooksReviewedId" style={graphStype}>
                                                        <div className="chart-img" style={graphStype}>
                                                            <Bar height={45} width={50} data={PegAssure_Logbooks_Reviewed} options={PegAssure_Logbooks_Reviewed_options} />
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6 col-sm-6 col-lg-6 col-xl-4" >
                                        <div className="tile performance_chart" onClick={() => props.viewFilterData('approved', 'quotes')} style={{ cursor: 'pointer' }}>
                                            <h4 className="chart_title1">Total # Quotes Sent</h4>
                                            <div className="flex_box performance_box">
                                                <div className="tile_block">
                                                    <h2>{props.quoteDashboardData.requests_approved_by_admin}</h2>
                                                </div>
                                                <div className="circle">
                                                    <img src="../images/icons/icn_quotes_sent.png" alt="Total Quotes Sent" />
                                                </div>
                                            </div>
                                            <div className="card_f_txt">
                                                {/* <h6>Sent to Clients</h6> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-sm-6 col-lg-6 col-xl-4" >
                                        <div className="tile performance_chart" onClick={() => props.viewFilterData(FilteredQuotes.APPROVED_BY_CLIENTS, 'quotes')} style={{ cursor: 'pointer' }}>
                                            <h4 className="chart_title1">Total # Quotes Approved</h4>
                                            <div className="flex_box performance_box">
                                                <div className="tile_block">
                                                    <h2>{props.quoteDashboardData.requests_approved_by_clients}</h2>
                                                </div>
                                                <div className="circle">
                                                    <img src="../images/icons/icn_quotes_approved.png" alt="Total Quotes Approved" />
                                                </div>
                                            </div>
                                            <div className="card_f_txt">
                                                {/* <h6>Approved by Clients</h6> */}
                                            </div>
                                        </div>
                                    </div>
                                    
                                    {/* <div className="col-6 col-sm-6 col-lg-6 col-xl-3" onClick={() => props.viewFilterQuotes(FilteredQuotes.DENIED_BY_ADMIN)}>
                                <div className="tile performance_chart" style={{ cursor: 'pointer' }}>
                                    <h4 className="chart_title1">Total Quotes Denied</h4>
                                    <div className="flex_box performance_box">
                                        <div className="tile_block">
                                            <h2>{props.dashboardData.requests_rejected_by_admin}</h2>
                                        </div>
                                        <div className="circle">
                                            <img src="../images/icons/icn_quotes_denied.png" alt="Total Quotes Denied" />
                                        </div>
                                    </div>
                                    <div className="card_f_txt">
                                        <h6>Denied by Admin</h6>
                                    </div>
                                </div>
                            </div> */}
                                    {/* <div className="col-6 col-sm-6 col-lg-6 col-xl-3" onClick={() => props.viewFilterQuotes(FilteredQuotes.REJECTED_BY_CLIENTS)}>
                                <div className="tile performance_chart" style={{ cursor: 'pointer' }}>
                                    <h4 className="chart_title1">Total Quotes Rejected</h4>
                                    <div className="flex_box performance_box">
                                        <div className="tile_block">
                                            <h2>{props.dashboardData.requests_denied_by_clients}</h2>
                                        </div>
                                        <div className="circle">
                                            <img src="../images/icons/icn_quotes_rejected.png" alt="Total Quotes Approved" />
                                        </div>
                                    </div>
                                    <div className="card_f_txt">
                                        <h6>Rejected by Clients</h6>
                                    </div>
                                </div>
                            </div> */}
                                    <div className="col-6 col-sm-6 col-lg-6 col-xl-4" >
                                        <div className="tile performance_chart" onClick={() => props.viewFilterData('pending', 'quotes')} style={{ cursor: 'pointer' }}>
                                            <h4 className="chart_title1">Total # Quotes Pending</h4>
                                            <div className="flex_box performance_box">
                                                <div className="tile_block">
                                                    <h2>{props.quoteDashboardData.requests_pending_by_clients}</h2>
                                                </div>
                                                <div className="circle">
                                                    <img src="../images/icons/icn_quotes_rejected.png" alt="Total Quotes Approved" />
                                                </div>
                                            </div>
                                            <div className="card_f_txt">
                                                {/* <h6>Pending by clients</h6> */}
                                            </div>
                                        </div>
                                    </div>
                                    {/* </div>
                        <div className="row"> */}
                                    {/* <div className="col-6 col-sm-6 col-lg-6 col-xl-3" onClick={() => props.viewFilterQuotes(FilteredQuotes.DENIED_BY_ADMIN)}>
                                <div className="tile performance_chart" style={{ cursor: 'pointer' }}>
                                    <h4 className="chart_title1">Total Opportunities Denied</h4>
                                    <div className="flex_box performance_box">
                                        <div className="tile_block">
                                            <h2>${trimString(String(props.dashboardData.sales_rejected_by_admin, 7))}</h2>
                                        </div>
                                        <div className="circle">
                                            <img src="../images/icons/icn_opportunities_denied.png" alt="Total Opportunities Denied" />
                                        </div>
                                    </div>
                                    <div className="card_f_txt">
                                        <h6>Denied by Admin</h6>
                                    </div>
                                </div>
                            </div> */}
                                   <div className="col-6 col-sm-6 col-lg-6 col-xl-4" >
                                        <div className="tile performance_chart" onClick={() => props.viewFilterData('approved', 'quotes')} style={{ cursor: 'pointer' }}>
                                            <h4 className="chart_title1">Total $ Quotes Sent</h4>
                                            <div className="flex_box performance_box">
                                                <div className="tile_block">
                                                    <h2>${trimString(String(props.quoteDashboardData.sales_approved_by_admin, 7))}</h2>
                                                </div>
                                                <div className="circle">
                                                    <img src="../images/icons/icn_opportunities_created.png" alt="Total Opportunities Created" />
                                                </div>
                                            </div>
                                            <div className="card_f_txt">
                                                {/* <h6>Quotes sent to Clients</h6> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-sm-6 col-lg-6 col-xl-4" >
                                        <div className="tile performance_chart" onClick={() => props.viewFilterData(FilteredQuotes.APPROVED_BY_CLIENTS, 'quotes')} style={{ cursor: 'pointer' }}>
                                            <h4 className="chart_title1">Total $ Quotes Approved</h4>
                                            <div className="flex_box performance_box">
                                                <div className="tile_block">
                                                    <h2>${trimString(String(props.quoteDashboardData.sales_approved_by_clients, 7))}</h2>
                                                </div>
                                                <div className="circle">
                                                    <img src="../images/icons/icn_total_sales_approved.png" alt="Total Sales Approved" />
                                                </div>
                                            </div>
                                            <div className="card_f_txt">
                                                {/* <h6>Approved by Clients</h6> */}
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="col-6 col-sm-6 col-lg-6 col-xl-3" onClick={() => props.viewFilterQuotes(FilteredQuotes.REJECTED_BY_CLIENTS)}>
                                <div className="tile performance_chart" style={{ cursor: 'pointer' }}>
                                    <h4 className="chart_title1">Total Sales Rejected </h4>
                                    <div className="flex_box performance_box">
                                        <div className="tile_block">
                                            <h2>${trimString(String(props.dashboardData.sales_denied_by_clients, 7))}</h2>
                                        </div>
                                        <div className="circle">
                                            <img src="../images/icons/icn_total_sales_reject.png" alt="Total Sales Rejected" />
                                        </div>
                                    </div>
                                    <div className="card_f_txt">
                                        <h6>Rejected by Clients</h6>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                                    <div className="col-6 col-sm-6 col-lg-6 col-xl-4" >
                                        <div className="tile performance_chart" onClick={() => props.viewFilterData('pending', 'quotes')} style={{ cursor: 'pointer' }}>
                                            <h4 className="chart_title1">Total $ Quotes Pending </h4>
                                            <div className="flex_box performance_box">
                                                <div className="tile_block">
                                                    <h2>${trimString(String(props.quoteDashboardData.sales_pending_by_clients, 7))}</h2>
                                                </div>
                                                <div className="circle">
                                                    <img src="../images/icons/icn_total_sales_reject.png" alt="Total Sales Rejected" />
                                                </div>
                                            </div>
                                            <div className="card_f_txt">
                                                {/* <h6>Pending by Clients</h6> */}
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="col-6 col-sm-6 col-lg-6 col-xl-4" >
                                        <div className="tile performance_chart" onClick={() => props.viewFilterData('pending', 'logbook')}>
                                            <h4 className="chart_title1">Total PegAssure Logbooks</h4>
                                            <div className="flex_box performance_box">
                                                <div className="tile_block">
                                                    <h2>{props.logbookDashboardData.total_logs}</h2>
                                                </div>
                                                <div className="circle">
                                                    <img src="../images/icons/icn_quotes_sent.png" alt="Total Quotes Sent" />
                                                </div>
                                            </div>
                                            <div className="card_f_txt">
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                                {/* <div className="row">
                            <div className="col-xl-12">
                                <div className="row">
                                    <div className="col-xl-6 chart_cont_box" id="chartId">
                                        {Top_ten_requestors_by_users.datasets[0].data[0] === '0' || Top_ten_requestors_by_users.datasets[0].data.length === 0 ?
                                            <EmptyGraph title={'Top 10 Quotes, Created by Users'} />
                                            :
                                            <div className="tile chart-box-botL" style={graphStype}>
                                                <div className="chart-img" style={graphStype}><Bar height={45} width={50} data={Top_ten_requestors_by_users} options={Top_ten_requestors_by_users_options} /></div>
                                            </div>
                                        }

                                    </div>
                                    <div className="col-xl-6 chart_cont_box">
                                        {Top_ten_sales_by_users.datasets[0].data[0] === '0' || Top_ten_sales_by_users.datasets[0].data.length === 0 ?
                                            <EmptyGraph title={'Top 10 Sales, Created by Users'} />
                                            :
                                            <div className="tile chart-box-botL" style={graphStype}>
                                                <div className="chart-img" style={graphStype}><Bar height={45} width={50} data={Top_ten_sales_by_users} options={Top_ten_sales_by_users_options} /></div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div> */}

                                <div className="row">
                                    <div className="col-xl-12">
                                        <div className="row">
                                            <div className="col-xl-6 chart_cont_box">
                                                {Top_ten_quotes_approved_by_admin.datasets[0].data[0] === '0' || Top_ten_quotes_approved_by_admin.datasets[0].data.length === 0 ?
                                                    <EmptyGraph title={'Top 10 Quotes, Sent by User(#)'} />
                                                    :
                                                    <div className="tile chart-box-botL" id='quotesApprovedByAdmin' onClick={() => props.viewFilterData(FilteredQuotes.SEND_TO_CLIENTS, 'quotes')} style={graphStype}>
                                                        {/* <h6 className="chart_title2">Top 10 Quotes Approved by Admin</h6> */}
                                                        <div className="chart-img" style={graphStype}><Bar height={45} width={50} data={Top_ten_quotes_approved_by_admin} options={Top_ten_quotes_approved_by_admin_options} /></div>
                                                        {/*  */}
                                                    </div>
                                                }
                                            </div>
                                            <div className="col-xl-6 chart_cont_box">
                                                {Top_ten_sales_approved_by_admin.datasets[0].data[0] === '0' || Top_ten_sales_approved_by_admin.datasets[0].data.length === 0 ?
                                                    <EmptyGraph title={'Top 10 Quotes, Sent by User($)'} />
                                                    :
                                                    <div className="tile chart-box-botL" id='salesApprovedByAdmin' onClick={() => props.viewFilterData(FilteredQuotes.SEND_TO_CLIENTS, 'quotes')}  style={graphStype}>
                                                        {/* <h6 className="chart_title2">Top 10 Sales by Quotes Approved by Admin</h6> */}
                                                        <div className="chart-img" style={graphStype}><Bar height={45} width={50} data={Top_ten_sales_approved_by_admin} options={Top_ten_sales_approved_by_admin_options} /></div>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-xl-12">
                                        <div className="row">
                                            <div className="col-xl-6 chart_cont_box">
                                                {Top_ten_quotes_approved_by_client.datasets[0].data[0] === '0' || Top_ten_quotes_approved_by_client.datasets[0].data.length === 0 ?
                                                    <EmptyGraph title={'Top 10 Quotes, Approved by Customer(#)'} />
                                                    :
                                                    <div className="tile chart-box-botL" id='quotesApprovedByClient' onClick={() => props.viewFilterData(FilteredQuotes.APPROVED_BY_CLIENTS, 'quotes')} style={graphStype}>
                                                        {/* <h6 className="chart_title2">Top 10 Quotes Approved by Client</h6> */}
                                                        <div className="chart-img" style={graphStype}><Bar height={45} width={50} data={Top_ten_quotes_approved_by_client} options={Top_ten_quotes_approved_by_client_options} /></div>
                                                    </div>
                                                }
                                            </div>
                                            <div className="col-xl-6 chart_cont_box">
                                                {Top_ten_sales_approved_by_client.datasets[0].data[0] === '0' || Top_ten_sales_approved_by_client.datasets[0].data.length === 0 ?
                                                    <EmptyGraph title={'Top 10 Quotes, Approved by Customer($)'} />
                                                    :
                                                    <div className="tile chart-box-botL" id='salesApprovedByClient' onClick={() => props.viewFilterData(FilteredQuotes.APPROVED_BY_CLIENTS, 'quotes')} style={graphStype}>
                                                        {/* <h6 className="chart_title2">Top 10 Sales by Approved, Approved by Client</h6> */}
                                                        <div className="chart-img" style={graphStype}><Bar height={45} width={50} data={Top_ten_sales_approved_by_client} options={Top_ten_sales_approved_by_client_options} /></div>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="row">
                            <div className="col-xl-12">
                                <div className="row">
                                    <div className="col-xl-6 chart_cont_box">
                                        {Top_ten_quotes_rejected_by_admin.datasets[0].data[0] === '0' || Top_ten_quotes_rejected_by_admin.datasets[0].data.length === 0 ?
                                            <EmptyGraph title={'Top 10 Quotes, Rejected by Admin'} />
                                            :
                                            <div className="tile chart-box-botL" style={graphStype}>
                                                <div className="chart-img" style={graphStype}>
                                                    <Bar height={45} width={50} data={Top_ten_quotes_rejected_by_admin} options={Top_ten_quotes_rejected_by_admin_options} />
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    <div className="col-xl-6 chart_cont_box" >
                                        {Top_ten_sales_rejected_by_admin.datasets[0].data[0] === '0' || Top_ten_sales_rejected_by_admin.datasets[0].data.length === 0 ?
                                            <EmptyGraph title={'Top 10 Sales, Rejected by Admin'} />
                                            :
                                            <div className="tile chart-box-botL" style={graphStype}>
                                                <div className="chart-img" style={graphStype}>
                                                    <Bar height={45} width={50} data={Top_ten_sales_rejected_by_admin} options={Top_ten_sales_rejected_by_admin_options} />
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="row">
                                    <div className="col-xl-6 chart_cont_box">
                                        {Top_ten_quotes_denied_by_client.datasets[0].data[0] === '0' || Top_ten_quotes_denied_by_client.datasets[0].data.length === 0 ?
                                            <EmptyGraph title={'Top 10 Quotes, Denied by Client'} />
                                            :
                                            <div className="tile chart-box-botL" style={graphStype}>
                                                <div className="chart-img" style={graphStype}><Bar height={45} width={50} data={Top_ten_quotes_denied_by_client} options={Top_ten_quotes_denied_by_client_options} /></div>
                                            </div>
                                        }
                                    </div>
                                    <div className="col-xl-6 chart_cont_box">
                                        {Top_ten_sales_denied_by_client.datasets[0].data[0] === '0' || Top_ten_sales_denied_by_client.datasets[0].data.length === 0 ?
                                            <EmptyGraph title={'Top 10 Sales, Denied by Client'} />
                                            :
                                            <div className="tile chart-box-botL" style={graphStype}>
                                                <div className="chart-img" style={graphStype}><Bar height={45} width={50} data={Top_ten_sales_denied_by_client} options={Top_ten_sales_denied_by_client_options} /></div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div> */}
                                <InspectionSchedules {...props}/>
                                <PastInspections {...props} />
                            </div>
                            <div class="col-lg-3 col-xl-2 chart_cont_box">
                                <div class="ticket_inbox">
                                    <div class="card">
                                        <div class="card-header">
                                            Ticket Inbox
                                        </div>
                                        <div class="card-body">
                                            <div class="ticket_status">
                                                <div class="col">
                                                    <div class="col p-0">
                                                        <small>Open</small>
                                                        <h2 class="status_pending">{props.ticketDashboardData.pending_ticket_count}</h2>
                                                    </div>
                                                    <div class="col p-0">
                                                        <small>Resolved</small>
                                                        <h2 class="status_open">{props.ticketDashboardData.resolved_ticket_count}</h2>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="ticket_link_list">
                                                <ul class="link_list">
                                                    {props.ticketDashboardData.ticket_data && props.ticketDashboardData.ticket_data.map((ticket) => {
                                                        return (
                                                            <li>
                                                                <a href="javascript:void(0);" onClick={() => props.previewTicketClicked(ticket)}>{ticket.subject}</a>
                                                                <small>{ticket.user && ticket.user.first_name + ' ' + ticket.user && ticket.user.last_name} </small>
                                                                <br />
                                                                <small>{getSecMintHoursDays(ticket.updated_at, ticket.account.timezone)}</small>
                                                            </li>
                                                        )
                                                    })}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </>
        )
    }
}

const SuperAdminDashbord = (props) => {

    window.onresize = function () {

        const emptyChartObj = document.getElementsByClassName("empty-graph_heading");
        const cahrtjsHeight = document.getElementById('chartId') ? document.getElementById('chartId').clientHeight : '';
        if (emptyChartObj.length !== 0) {
            for (var i = 0; i < emptyChartObj.length; i++) {
                emptyChartObj[i].style.height = `${cahrtjsHeight}px`
            }
        }
    };

    return (
        <>
            <main className="app-content wraper_content chart_section">

                <section className="admin_dashboard appcontent_Inner">
                    {DashboardData(props)}
                </section>
            </main>
        </>
    )
}

export default SuperAdminDashbord;

