import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../../../redux/actions/index';
import { reset } from 'redux-form';
import storage from '../../../../../utility/storage';
import { animateScroll as scroll } from 'react-scroll'
import EmergencyPickup from '../../../../component/Inventory/InventoryTicketsComponents/EmergencyPickup/emergencyPickup';


class EmergencyPickupContainer extends Component {

  constructor(props) {
    super(props);

    this.state = {
      currentAssosciatedUserEmail: '',
      accountErrorMessage: '',
      paramsTicketId: '',
    }
  }

  componentDidMount() {
    if (this.props && this.props.match && this.props.match.params && this.props.match.params.ticketId) {
      this.setState({ paramsTicketId: this.props.match.params.ticketId });
      setTimeout(() => {
        this.props.getEmergencyPickupTickets({ page: 1, limit: 10, id: this.state.paramsTicketId });
      }, 500);
    };
    scroll.scrollToTop();
  }

  goClicked = (values) => {
    let manager = ''
    let fulfillment_manager_location = ''
    let approver = ''
    let status = ''
    let id = ''

    const page = 1
    const limit = this.props.pickingTicket_no_of_rows
    const is_emergency = true
    if (values.manager) {
      values.manager = values.manager.trim()
      manager = values.manager
    }
    if (values.fulfillment_manager_location) {
      values.fulfillment_manager_location = values.fulfillment_manager_location.trim()
      fulfillment_manager_location = values.fulfillment_manager_location
    }
    if (values.approver) {
      values.approver = values.approver.trim()
      approver = values.approver
    }
    if (values.status) {
      status = values.status
    }
    if (values.id) {
      id = values.id
    }
    this.props.getEmergencyPickupTickets({ page, limit, manager, fulfillment_manager_location, approver, status, is_emergency, id }, values);
  };

  componentWillUnmount() {
    //this.props.resetObjects();
  }

  componentDidUpdate(prevProps, prevState) {
    const user = storage.get('user');
    if (user) {
      if (prevProps.user.selected_mode !== user.selected_mode) {
        this.props.emergencyTicketPageClicked(0)
      }
    }
  }

  resetClicked = () => {
    this.setState({paramsTicketId: ''});
    const limit = this.props.pickingTicket_no_of_rows
    const page = 1;
    const is_emergency = true
    this.props.reset('searchEmergencyPickupListForm');
    this.props.emergencyTicketPageClicked(0)
    this.props.getEmergencyPickupTickets({ limit, page, is_emergency });
  }

  approveClicked = (id) => {

    const ticket = {
      status: 'approved'
    }
    this.props.approvePickupTicket({ ticket }, id);
  }

  rejectClicked = (id) => {

    const ticket = {
      status: 'rejected'
    }
    this.props.approvePickupTicket({ ticket }, id);
  }

  render() {

    return (
      <EmergencyPickup
        {...this.props}
        resetClicked={this.resetClicked}
        approveClicked={this.approveClicked}
        rejectClicked={this.rejectClicked}
        onSubmit={this.goClicked}
        paramsTicketId={this.state.paramsTicketId}
      />
    )
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.authReducer.user,
    pickupTicketList: state.InventoryReducer.pickingTicketList,
    openDeleteModel: state.adminOrSuperAdminReducer.openDeleteModel,
    isLoading: state.InventoryReducer.isLoading,
    pickingTicket_no_of_rows: state.InventoryReducer.pickingTicket_no_of_rows,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    getEmergencyPickupTickets: (params, values) => dispatch(actions.getEmergencyPickupTickets(params, values)),
    approvePickupTicket: (params, id) => dispatch(actions.approvePickupTicket(params, id)),
    handleDeleteClose: () => dispatch(actions.handleDeleteClose()),
    handleClickDeleteModel: (frontline) => dispatch(actions.handleClickDeleteModel(frontline)),
    deleteFrontline: (id) => dispatch(actions.deleteFrontline(id)),
    importFrontline: (body) => dispatch(actions.importFrontline(body)),
    reset: (searchEmergencyPickupListForm) => dispatch(reset(searchEmergencyPickupListForm)),
    searchFrontlineUser: (values) => dispatch(actions.searchFrontlineUser(values)),
    emergencyTicketPageClicked: (page) => dispatch(actions.emergencyTicketPageClicked(page)),
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(EmergencyPickupContainer);