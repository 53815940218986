import React, { Component } from 'react';
import * as actions from '../../../../../redux/actions/index';
import { connect } from 'react-redux';
import { reset } from 'redux-form';
import { animateScroll as scroll } from 'react-scroll'
import storage from '../../../../../utility/storage';
import { convertDateToDifferentTZ, getUser } from '../../../../../utility/utility';
import errorHandler from '../../../../../utility/errorHandler/errorHandler';
import OverrideAvailabilityRequestLisLayout from '../../../../component/TimeTrackerModuleComponent/AvailabilityComponent/OverrideAvailabilityRequestListComponent/OverrideAvailabilityRequestLisLayout';
import { RolesOptions, userRoles } from '../../../../../utility/constants/constants';

const cloneDeep = require('clone-deep');

class OverrideAvailabilityRequestListContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }

    }

    componentDidMount() {
        scroll.scrollToTop();
    }

    componentDidUpdate(prevProps, prevState) {
        const user = storage.get('user');
        if (user) {
            if (prevProps.user.selected_mode !== user.selected_mode) {
                this.props.overrideAvailabilityRequestListPageClicked(0);
            }
        }
    };

    SearchOverrideAvailabilityRequestListClicked = (value) => {

        let currentUser = getUser();
        let page = 1
        let limit = this.props.overrideAvailabilityRequestList_no_of_rows
        let status = null;

        const values = cloneDeep(value)

        if (values.overrideAvailabilityRequestStatus) {
            values.overrideAvailabilityRequestStatus = values.overrideAvailabilityRequestStatus && values.overrideAvailabilityRequestStatus.value && values.overrideAvailabilityRequestStatus.value.trim();
            status = values.overrideAvailabilityRequestStatus
        }

        if (this.props.user.role == userRoles.FRONTLINE) {
            this.props.getOverrideAvailabilityRequestList({ page, limit, status, userId: currentUser.id }, values);
        } else {
            this.props.getOverrideAvailabilityRequestList({ page, limit, status });
        }

    };

    resetClicked = () => {
        let currentUser = getUser();
        const page = 1
        const limit = 10
        let status = 'requested';
        this.props.reset('searchOverrideAvailabilityRequestListForm');
        this.props.overrideAvailabilityRequestListPageClicked(0)
        if (this.props.user.role == userRoles.FRONTLINE) {
            this.props.getOverrideAvailabilityRequestList({ page, limit, status, userId: currentUser.id });
        } else {
            this.props.getOverrideAvailabilityRequestList({ page, limit, status });
        }
    };

    handleOverrideAvailabilityRequestSanction = (override) => {

        let fetchedValue = {};

        if (override.reviewStatus == 'approved') {

            fetchedValue = {
                data: { status: override.reviewStatus }
            }

        } else if (override.reviewStatus == 'rejected') {

            fetchedValue = {
                data: { status: override.reviewStatus }
            }

        }

        this.props.grantOverrideAvailabilityRequest(fetchedValue, override.id);

    }

    render() {
        return (
            <div>

                <OverrideAvailabilityRequestLisLayout
                    {...this.props}
                    onSubmit={this.SearchOverrideAvailabilityRequestListClicked}
                    resetClicked={this.resetClicked}
                    overrideAvailabilityRequestList={this.props.overrideAvailabilityRequestList ? this.props.overrideAvailabilityRequestList : []}
                    handleOverrideAvailabilityRequestSanction={this.handleOverrideAvailabilityRequestSanction}
                />

            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.authReducer.user,
        isLoading: state.TimeTrackerModReducer.isLoading,
        isOverrideAvailabilityRequestList: state.TimeTrackerModReducer.isOverrideAvailabilityRequestList,
        overrideAvailabilityRequestList: state.TimeTrackerModReducer.overrideAvailabilityRequestList,
        overrideAvailabilityRequestList_no_of_rows: state.TimeTrackerModReducer.overrideAvailabilityRequestList_no_of_rows,
        overrideAvailabilityRequestList_curPage: state.TimeTrackerModReducer.overrideAvailabilityRequestList_curPage,
        isTimeTrackerModalOpen: state.TimeTrackerModReducer.isTimeTrackerModalOpen,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        reset: (searchOverrideAvailabilityRequestList) => dispatch(reset(searchOverrideAvailabilityRequestList)),  // requires form name
        getOverrideAvailabilityRequestList: (params, values) => dispatch(actions.getOverrideAvailabilityRequestList(params, values)),
        overrideAvailabilityRequestListPageClicked: (page) => dispatch(actions.overrideAvailabilityRequestListPageClicked(page)),
        grantOverrideAvailabilityRequest: (reviewRequest, id) => dispatch(actions.grantOverrideAvailabilityRequest(reviewRequest, id)),
        handleFrequentTimeTracker_openModal: (data) => dispatch(actions.handleFrequentTimeTracker_openModal(data)),
        handleFrequentTimeTracker_closeModal: (data) => dispatch(actions.handleFrequentTimeTracker_closeModal(data)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OverrideAvailabilityRequestListContainer);