import React from "react";
import { MagicSpinner, PushSpinner, SwishSpinner, WaveSpinner, ClapSpinner } from 'react-spinners-kit';
import Search from "./Search";
import SearchResult from "./SearchResult";

const SearchHistory = (props) => {
  return(
      <main className="app wraper_content qr_app_content qr-app-scroll">
      <Search {...props}/>
        {
          props.isLoading ? <div className="loader_btn_block">
            <ClapSpinner backColor="#89073c" size={40} frontColor="#89073c8a" />
          </div> :
          <SearchResult {...props}/>
        }
        <div className="new_search mt-2 qr-page btn_block">
          <button type="button" className={`btn btn_danger cst_btn ${props.search.area_name && props.search.area_type_id && props.search.floor_id ? "" : "disabled-btn"}`} onClick={props.handleSubmitNewSearch}>New Search</button>
        </div>
      </main>
  )
};

export default SearchHistory;