import React, { useEffect, useState } from 'react';
import { formValueSelector, getFormValues, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Input } from '../../../UI/InputElement/InputElement';
import { userRoles, overrideAvailabilityRequestStatusOptions } from '../../../../../utility/constants/constants';
import { transformStringToSelectOptionObject } from '../../../../../utility/utility';
import OverrideAvailabilityRequestLisTable from './OverrideAvailabilityRequestLisTable';

let OverrideAvailabilityRequestLisLayout = (props) => {

    const { handleSubmit } = props;

    return (
        <div>

            <main className="app-content wraper_content inspection_wraper">
                <div className="tab_header search_filter_cont inspection_tab_content ticket_filter">
                    <form onSubmit={handleSubmit}>
                        <div className="row justify-content-start mt-2">

                            <div className="col-xl-2 col-lg-4 col-md-6 col-sm-6">
                                <Input name="overrideAvailabilityRequestStatus" dataTest="select_leave_type" type="inputSelect" options={overrideAvailabilityRequestStatusOptions} placeholder="Search by Swap Status" />
                            </div>

                            <div className="col-xl-2 col-lg-6 col-sm-3 col-12 d-flex justify-content-center">
                                <button data-test="filter_go_btn" style={{ minWidth: '90px' }} className={"btn cst_btn btn_danger mr-2  account_btn"} id="submit" type="submit">{"Go"} </button>
                                <button data-test="filter_reset_btn" style={{ minWidth: '90px' }} onClick={props.resetClicked} className={"btn cst_btn btn-outline-secondary account_btn"} id="reset" type="reset">{"Reset"}</button>
                            </div>

                        </div>
                    </form>
                </div>

                <section className="account_sec inspection_tab_content appcontent_Inner">
                    <div className="quotes_table_content accounts_table_contnet table-responsive">
                        <div className="table quotes_table user_react_table mt-5">
                            <OverrideAvailabilityRequestLisTable
                                {...props}
                            />
                        </div>
                    </div>
                </section>
            </main>

        </div>
    );
};

const mapStateToProps = (state) => {

    let capturedStatus = 'requested';

    if (state.TimeTrackerModReducer && state.TimeTrackerModReducer.overrideAvailabilityRequestList && state.TimeTrackerModReducer.overrideAvailabilityRequestList.length) {
        capturedStatus = state.TimeTrackerModReducer.overrideAvailabilityRequestList[0].status;
    }

    let overrideAvailabilityRequestStatus = state.TimeTrackerModReducer.overrideAvailabilityRequestList_searchValues && state.TimeTrackerModReducer.overrideAvailabilityRequestList_searchValues.status ? state.TimeTrackerModReducer.overrideAvailabilityRequestList_searchValues.status : transformStringToSelectOptionObject(capturedStatus);
    return { initialValues: { overrideAvailabilityRequestStatus }, formStates: getFormValues('searchOverrideAvailabilityRequestListForm')(state) }
}

OverrideAvailabilityRequestLisLayout = reduxForm({
    form: 'searchOverrideAvailabilityRequestListForm',
    // validate,
    enableReinitialize: true
})(OverrideAvailabilityRequestLisLayout);
OverrideAvailabilityRequestLisLayout = connect(mapStateToProps)(OverrideAvailabilityRequestLisLayout)

export default OverrideAvailabilityRequestLisLayout;