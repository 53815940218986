import React from 'react';
import ReactDOM from 'react-dom';
import $ from 'jquery';
import './index.css';
import App from './App';
import { API_ADMIN as adminAxios, API_VERSION } from './config';
import { timeTrackerBaseUrl as timeTrackerAxios } from './config';
import { formAuditBaseUrl as formAuditAxios } from './config';
import { Provider } from 'react-redux';
import store from './redux/store/store';
import authInterceptor from './utility/interceptors/authInterceptor';
import tokenInterceptor from './utility/interceptors/tokenInterceptor';
import axios from './config';
import errorHandler from './utility/errorHandler/errorHandler';
import * as serviceWorker from './serviceWorker';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import storage from './utility/storage';
import * as actions from './redux/actions/index'

// const refreshAuthLogic = failedRequest =>
//     axios.post(API_VERSION + 'users/refresh_token', {}, {
//         headers: {
//             'Content-Type': 'application/json',
//             'Accept': 'application/json',
//             'x-refreshtoken': storage.get('refresh_token')
//         }
//     }).then(tokenRefreshResponse => {
//         storage.set('token', tokenRefreshResponse.data.user.access_token);
//         storage.set('refresh_token', tokenRefreshResponse.data.user.refresh_token);
//         failedRequest.response.config.headers['Authorization'] = 'Bearer ' + tokenRefreshResponse.data.user.access_token;
//         return Promise.resolve();
//     }).catch(error => {
//         // store.dispatch(actions.logout())
//     });
// createAuthRefreshInterceptor(axios, refreshAuthLogic);
axios.interceptors.request.use(authInterceptor, error => Promise.reject(error));
axios.interceptors.response.use(tokenInterceptor, errorHandler);

timeTrackerAxios.interceptors.request.use(authInterceptor, error => Promise.reject(error));
timeTrackerAxios.interceptors.response.use(tokenInterceptor, errorHandler);

formAuditAxios.interceptors.request.use(authInterceptor, error => Promise.reject(error));
formAuditAxios.interceptors.response.use(tokenInterceptor, errorHandler);

adminAxios.interceptors.request.use(authInterceptor, error => Promise.reject(error));
adminAxios.interceptors.response.use(tokenInterceptor, errorHandler);


const app = (
    <Provider store={store}>
        <App />
    </Provider>
)
ReactDOM.render(app, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();




