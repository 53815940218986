import React, { Component } from 'react';
import * as actions from '../../../../redux/actions/index';
import { connect } from 'react-redux';
import LocationIndex from '../../../component/Inventory/LocationComponent';
const cloneDeep = require('clone-deep');
var moment = require('moment');

class LocationIndexContainer extends Component {

  constructor(props) {
    super(props);

    this.state = {
    }
}

componentDidMount() {

 if(!this.props.fulfillmentManager.length){
  this.props.getFulfillmentManager()
 }
  
}

  handelLocationSubmit = (value) => {debugger
    if(value && value.location){
      value.location.name = value.location.name.trim()
    }
    if (this.props.isEdit) {
      this.props.updateLocation(value, this.props.location.id)
    } else {
      this.props.addLocation(value);
    }
  }

  handelLocationAssociationSubmit = (data) => {
    let value = cloneDeep(data)
    let body = {}
    if (this.props.isEdit) {

      body['user_id'] = value.location_association.fulfilment_manager;
        this.props.updateLocationAssociation({location: body}, value.location_association.location); 

    } else {
        body['user_id'] = value.location_association.fulfilment_manager;
        this.props.addLocationAssociation({location: body}, value.location_association.location);      
    }
  }



  render() {

    return (
      <LocationIndex
        {...this.props}
        openPopup={this.props.openPopup}
        closePopup={this.props.closePopup}
        isOpenModel={this.props.isOpenModel}
        isEdit={this.props.isEdit}
        handelLocationSubmit={this.handelLocationSubmit}
        handelLocationAssociationSubmit={this.handelLocationAssociationSubmit}
        emptyObjects={this.props.emptyObjects}
        isLoading={this.props.isLoading}
        location_count= {this.props.location_count}
        location_association_count= {this.props.location_association_count}
        fulfillmentManager= {this.props.fulfillmentManager}
        locationName= {this.props.locationName}
      />
    )
  }
}

const mapStateToProps = (state) => {
  return {
    isOpenModel: state.InventoryReducer.isOpenModel,
    isLoading: state.InventoryReducer.isLoading,
    isEdit: state.InventoryReducer.isEdit,
    location_count: state.InventoryReducer.location_count,
    location_association_count: state.InventoryReducer.location_association_count,
    fulfillmentManager: state.InventoryReducer.fulfillmentManager,
    locationName: state.InventoryReducer.locationName,
    location: state.InventoryReducer.location
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    openPopup: () => dispatch(actions.openPopupInventory()),
    closePopup: () => dispatch(actions.closePopupInventory()),
    addLocation: (params) => dispatch(actions.addLocation(params)),
    addLocationAssociation: (params, id) => dispatch(actions.addLocationAssociation(params, id)),
    emptyObjects: () => dispatch(actions.emptyObjects()),
    getFulfillmentManager: () => dispatch(actions.getFulfillmentManager()),
    updateLocation: (params, id) => dispatch(actions.updateLocation(params, id)),
    updateLocationAssociation: (params, id) => dispatch(actions.updateLocationAssociation(params, id)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LocationIndexContainer);