import React from "react";
import { ClapSpinner } from "react-spinners-kit";
import { convertDate, convertDateAndTimeTimezone, makeFirstLetterCapital } from "../../../utility/utility";
import '../../../styles/custom.css';
import { QuoteStatusLog } from "../../../utility/constants/constants";

const TicketStatusModal = (props) => {
  const { handleSubmit, pristine, reset, submitting, error, tickets } = props;
  
  let created_at;
  let updated_at;
  if(tickets) {
     created_at = tickets && tickets.created_at ? (tickets.created_at) : null;
     updated_at = tickets && tickets.updated_at ? (tickets.updated_at) : null;
  }
  

  const StatusTicketsTitle = (log) => {
    const status = log.status;
    if (status === QuoteStatusLog.ALL) {
        return (
            <span className="text_success">{'Requested'}</span>
        )
    } else if (status === QuoteStatusLog.PENDING_FOR_APPROVAL) {
        return (
            <span className="text_success">{'Pending For Approval'}</span>
        )
    } else if (status === QuoteStatusLog.APPROVED_BY_ADMIN) {
        return (
            <span className="text_success">{'Approved By Admin'}</span>
        )
    } else if (status === QuoteStatusLog.APPROVED_BY_SUPERADMIN) {
        return (
            <span className="text_success">{'Approved By Superadmin'}</span>
        )
    } else if (status === QuoteStatusLog.AUTO_APPROVED_QUOTE) {
        return (
            <span className="text_success">{'Auto Approved Quote'}</span>
        )
    }else if (status === QuoteStatusLog.REJECTED_BY_ADMIN) {
        return (
            <span style={{color: "#d28500"}}>{'Rejected By Admin'}</span>
        )
    }else if (status === QuoteStatusLog.REJECTED_BY_SUPERADMIN) {
        return (
            <span style={{color: "#d28500"}}>{'Rejected By Superadmin'}</span>
        )
    }else if (status === QuoteStatusLog.CONFIRM_BY_CLIENT) {
        return (
            <span className="text_success">{'Confirmed By Customer'}</span>
        )
    }else if (status === QuoteStatusLog.DECLINED_BY_CLIENT) {
        return (
            <span className="text-danger">{'Expired'}</span>
        )
    }
    else if (status === QuoteStatusLog.DRAFT) {
        return (
            <span className="text_success">{'Draft'}</span>
        )
    }
}

  return (
    <>
    {tickets ? 
    <div class="modal fade view_quotes_cc quote_status_loader qsl_main_content" id="viewQuotesCC" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-lg " role="document">
        <div className="modal-content">
            <div className="modal-header qqv_status_bg">
            <div style={{width: "100%", display: "block", float: "left"}}>
            <h5 className="modal-title text-white" id="exampleModalLabel" style={{float: "left"}}>Ticket Status Log</h5>
            {/* && props.tickets.inspection_id  */}
            {tickets ? 
            <>
            <h5 className="modal-title text-white" id="exampleModalLabel" style={{float: "right", paddingRight: "20px"}}>EMAIL Sent : {tickets.history && tickets.history.length ? tickets.history[0].email_count : "0"}</h5>
            <h5 className="modal-title text-white" id="exampleModalLabel" style={{float: "right", paddingRight: "20px"}}>SMS Sent : {tickets.history && tickets.history.length ? tickets.history[0].count : "0"}</h5>
            </>
            : null
            }
            </div>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true" className="text-white">&times;</span>
            </button>
            </div>
            <table className="table timline_table_th mb-0 qqv_status_bg">
                <thead>
                    <tr>
                        <th className="text-center Qsl_table_head list_empty_th">&nbsp;</th>
                        <th className="text-center Qsl_table_head status text-white" >Status</th>
                        <th className="text-center Qsl_table_head requested_by text-white" >Performed By</th>
                        <th className="text-center Qsl_table_head requested_on text-white" >Performed On</th>
                    </tr>
                </thead>
            </table>
            <div className="modal-body pt-4" id="progress_status_bar"  style={{background: "#f8e4e5"}}>
                            <div className="quotes_table_content">
                                <div className="view_quotes_timeline_wrap">
                                    <div className="step completed">
                                        <div className="view_quotes_timeline_content ">
                                            <table className="table view_quotes_list"> <tbody>
                                            {tickets && (
                            <>
                                                    <tr className="cst_tr" >
                                                    {/* {index === props.tickets.tickets.length - 1  ? */}
                                                    <td className="list_empty_th">
                                                        <div className="v-stepper">
                                                            <div className="circle"><i className="fa fa-check" aria-hidden="true"></i></div>
                                                            <div className="line"></div>
                                                        </div>
                                                        </td>
                                                        
                                                       {/* <td className="text-center status"><span className="text_success">{makeFirstLetterCapital(log.status)}</span></td> */}
                                                       
                                                       <td className="text-center status Qsl_table_font">{'Open'}</td>
                                                        <td className="text-center requested_by Qsl_table_font"><span>{'-'}</span></td>
                                                        <td className="text-center requested_on Qsl_table_font" >{convertDateAndTimeTimezone(created_at, tickets.user?.account_timezone)}</td>
                                                       {/* {
                                                        ((log.status === QuoteStatusLog.REJECTED_BY_SUPERADMIN ) || (log.status === QuoteStatusLog.REJECTED_BY_ADMIN )) ? 
                                                        <td className="text-center amount Qsl_table_font"><span className="total_sale_qsl px-3">-</span></td> : */}
                                                       
                                                        {/* <td className="text-center amount Qsl_table_font"><span className="total_sale_qsl">{tickets.history && tickets.history.length ? tickets.history[0].count : "0"}</span></td> */}
                                                       {/* } */}
                                                    </tr>
                                                    <tr style={{height:'10px'}}></tr>
                                                    </>
                                                               )                  
                                                            }


                                                    {tickets.accepted_by && (
                            <>
                                                    <tr className="cst_tr" >
                                                    {/* {index === props.tickets.tickets.length - 1  ? */}
                                                    <td className="list_empty_th">
                                                        <div className="v-stepper">
                                                            <div className="circle"><i className="fa fa-check" aria-hidden="true"></i></div>
                                                            <div className="line"></div>
                                                        </div>
                                                        </td>
                                                        
                                                       {/* <td className="text-center status"><span className="text_success">{makeFirstLetterCapital(log.status)}</span></td> */}
                                                       
                                                       <td className="text-center status Qsl_table_font">{'Accepted'}</td>
                                                        <td className="text-center requested_by Qsl_table_font"><span>{makeFirstLetterCapital(tickets.accepted_by.first_name)} {makeFirstLetterCapital(tickets.accepted_by.last_name)}</span></td>
                                                        <td className="text-center requested_on Qsl_table_font" >{convertDateAndTimeTimezone(tickets.accepted_at, tickets.user?.account_timezone)}</td>
                                                       {/* {
                                                        ((log.status === QuoteStatusLog.REJECTED_BY_SUPERADMIN ) || (log.status === QuoteStatusLog.REJECTED_BY_ADMIN )) ? 
                                                        <td className="text-center amount Qsl_table_font"><span className="total_sale_qsl px-3">-</span></td> : */}
                                                       {/* } */}
                                                    </tr>
                                                    <tr style={{height:'10px'}}></tr>
                                                    </>
                                                               )                  
                                                            }


                                                     {tickets.resolved_user && (
                            <>
                                                    <tr className="cst_tr" >
                                                    {/* {index === props.tickets.tickets.length - 1  ? */}
                                                    <td className="list_empty_th">
                                                        <div className="v-stepper">
                                                            <div className="circle"><i className="fa fa-check" aria-hidden="true"></i></div>
                                                            <div className="line"></div>
                                                        </div>
                                                        </td>
                                                        
                                                       {/* <td className="text-center status"><span className="text_success">{makeFirstLetterCapital(log.status)}</span></td> */}
                                                       
                                                       <td className="text-center status Qsl_table_font">{makeFirstLetterCapital(tickets.status)}</td>
                                                        <td className="text-center requested_by Qsl_table_font"><span>{makeFirstLetterCapital(tickets.resolved_user.first_name)} {makeFirstLetterCapital(tickets.resolved_user.last_name)}</span></td>
                                                        <td className="text-center requested_on Qsl_table_font" >{convertDateAndTimeTimezone(updated_at,tickets.user?.account_timezone)}</td>
                                                       {/* {
                                                        ((log.status === QuoteStatusLog.REJECTED_BY_SUPERADMIN ) || (log.status === QuoteStatusLog.REJECTED_BY_ADMIN )) ? 
                                                        <td className="text-center amount Qsl_table_font"><span className="total_sale_qsl px-3">-</span></td> : */}
                                                       {/* } */}
                                                    </tr>
                                                    <tr style={{height:'10px'}}></tr>
                                                    </>
                                                               )                  
                                                            }       
                                                    {/* // : (
                                                    //     <div className="notFound text-center p-2 font-weight-bold">
                                                    //         <p class="info_title">No Record Found</p>
                                                    //     </div>
                                                    // )
                                                     */}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    {/* <div className="step active">
                                    <div className="view_quotes_timeline_content">
                                        <table className="table view_quotes_list">
                                            <tbody>
                                                <tr className="cst_tr">
                                                    <td className="list_empty_th">
                                                        <div className="v-stepper">
                                                            <div className="circle"></div>
                                                            <div className="line"></div>
                                                        </div>
                                                    </td>
                                                    <td className="text-center status"><span className="text_warning">Rejected by superadmin</span></td>
                                                    <td className="text-center requested_by"><span className="">TC Testing</span></td>
                                                    <td className="text-center requested_on">05-19-2021 (CST)</td>
                                                    <td className="text-center amount"><span className="">$211.00</span></td>
                                                </tr>
                                                <tr style={{height:'10px'}}></tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    </div>
                                    <div className="step">
                                        <div className="view_quotes_timeline_content">
                                            <table className="table view_quotes_list">
                                                <tbody>
                                                    <tr className="cst_tr">
                                                        <td className="list_empty_th">
                                                            <div className="v-stepper">
                                                                <div className="circle"></div>
                                                                <div className="line"></div>
                                                            </div>
                                                        </td>
                                                        <td className="text-center status"><span className="text_danger">N/A</span></td>
                                                        <td className="text-center requested_by"><span className="">TC Testing</span></td>
                                                        <td className="text-center requested_on">05-19-2021 (CST)</td>
                                                        <td className="text-center amount"><span className="">$211.00</span></td>
                                                    </tr>
                                                    <tr style={{height:'10px'}}></tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
            
                                 
            </div>
        </div>
        </div>
    </div>
      : null                          }
      </>
  );
};

export default TicketStatusModal;
