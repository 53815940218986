import React, { useEffect, useRef, useState } from 'react';
import { useFieldArray, useForm, useWatch } from 'react-hook-form';
import { Input } from '../../../../UI/InputElement/reactFormHookInputField'
import { BP_AREAS } from '../../../../../../utility/constants/constants'
import { Controller } from 'react-hook-form'
import { closeFormModel, closeModel, openFormModel } from '../../../../../../utility/utility';
import { DevTool } from '@hookform/devtools';

function ColumnModal(props) {

    const columnName = props.getValues('columnName')

    const { formState: { errors, defaultValues }, handleSubmit: ModalSubmit, getValues, reset, trigger, register, isDirty, control, setValue: setValueForm } = useForm({
        defaultValues:useEffect(() => {
                reset({
                    data: columnName ? columnName?.data?.map((item) => {
                        return {
                            column_name: item.column_name,
                        }
                    }) : [{column_name : ''}]
            })
            
        }, [columnName]),
        shouldFocusError: false
    })

    const { fields, append, remove } = useFieldArray({ control: control, name: "data", })
    const area_field = getValues(`data`)

    const modalSubmitHandler = (values) => {
        props.setValue('columnName', values)
        closeFormModel()
    }

    const watch = useWatch({
        control: control,
        name: "data"
    })

   const resetColModal = () =>{
        reset(props.colValue)
   }

    useEffect(() => {
     if(props.colValue) {
        reset(props.colValue)
     }
    },[props.colValue])


    const defaultTaskData = {
        column_name: '',
    }

    const addTaskToList = (e) => {
        e.preventDefault();
        append(defaultTaskData)
    }

    const removeFromTaskList = () => (e) => {
        e.preventDefault();
        remove(area_field.length - 1)
    }

    return (
        <>
            {/* <!-- Modal --> */}
            <div className="custom_modal">
                <div
                    className="modal fade"
                    id="columnModal"
                    tabindex="-1"
                    aria-labelledby="columnModalLabel"
                    data-bs-backdrop="static"
                    aria-hidden="true"
                >
                    <div className="modal-dialog">
                        <form onSubmit={ModalSubmit((data) => modalSubmitHandler(data))}>
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="columnModalLabel">Set Up Floors</h5>
                                    <button
                                        type="button"
                                        className="btn-close btn p-0"
                                        style={{
                                            backgroundColor: "#7c2b2b"
                                        }}
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                        onClick={(e) => {
                                            e.preventDefault()
                                            closeModel()
                                            resetColModal()

                                        }}
                                    >
                                        <img src="/images/icons/cross_icon.svg" alt="cross_icon" />
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div class="action_button" >
                                                <span onClick={addTaskToList} className='mb-3'>+ Add Floor</span>
                                            </div>
                                        </div>
                                        {fields.map((item, index) => (
                                            <div key={index} className="col-md-12 col-sm-12">
                                                <div className="form-group">
                                                    <Input
                                                        type="text"
                                                        formControlProps={register(`data.${index}.column_name`, {
                                                            required: "This field is required"
                                                        })}
                                                        style={{ height: '30px' }}
                                                        validationErrorMessage={errors?.data?.[index]?.column_name?.message}
                                                        formControlClasses="form-control"
                                                        placeholder = {'Floor Title'}
                                                    />
                                                </div>
                                            </div>
                                        ))}
                                        {
                                            area_field?.length > 1 ? (
                                                <div className="col-md-12">
                                                    <div class="action_button" >
                                                        <span onClick={removeFromTaskList()} className='mb-0'>
                                                            <img src='/images/icons/trash.svg' alt='trash' />
                                                        </span>
                                                    </div>
                                                </div>
                                            ) : null
                                        }

                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button
                                        type="submit"
                                        class="btn theme_def_btn1"
                                    >
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <DevTool control={control} />
                </div>
            </div>
        </>
    )
}

export default ColumnModal