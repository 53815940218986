import React from 'react'
import { Input } from '../../../../UI/InputElement/reactFormHookInputField'
import { useWatch } from 'react-hook-form'

function BpSurveyItems(props) {

    const aprox_sqft_carpet_floor = useWatch({
        control:props.control,
        name:`surveyItems.aprox_sqft_carpet_floor`
      })

      const aprox_sqft_tile_floor = useWatch({
        control:props.control,
        name:`surveyItems.aprox_sqft_tile_floor`
      })

      const aprox_sqft_other_floor = useWatch({
        control:props.control,
        name:`surveyItems.aprox_sqft_other_floor`
      })

      const getCarpetPercentage = () => {
        
        const aprox_sqft_carpet_floor = props.getValues(`surveyItems.aprox_sqft_carpet_floor`)
        
        if(aprox_sqft_carpet_floor){
            const percentage = (+aprox_sqft_carpet_floor / +props.grandTotal) * 100
            props.setValue(`surveyItems.aprox_percent_carpet_floor`, percentage.toFixed(0))
            return
        }
        else if(props.isEdit || props.isView) {
            return 0
        } 
        else {
            return ""
        }
      }

      const getTilePercentage = () => {
        
        const aprox_sqft_tile_floor = props.getValues(`surveyItems.aprox_sqft_tile_floor`)
        
        if(aprox_sqft_tile_floor){
            const percentage = (+aprox_sqft_tile_floor / +props.grandTotal) * 100
            props.setValue(`surveyItems.aprox_percent_tile_floor`, percentage.toFixed(0))
            return
        }
        else if(props.isEdit || props.isView) {
            return 0
        } 
        else {
            return ""
        }
      }

      const getOtherPercentage = () => {
        
        const aprox_sqft_other_floor = props.getValues(`surveyItems.aprox_sqft_other_floor`)
        
        if(aprox_sqft_other_floor){
            const percentage = (+aprox_sqft_other_floor / +props.grandTotal) * 100
            props.setValue(`surveyItems.aprox_percent_other_floor`, percentage.toFixed(0))
            return
        }
        else if(props.isEdit || props.isView) {
            return 0
        } 
        else {
            return ""
        }
      }

    return (
        <>
            <div className="col-md-6 col-sm-12">
                <div className="form-group">
                    <Input
                        type="number"
                        labelClasses="form-label"
                        label={`Approximate % of carpeted floor`}
                        placeholder={'Approximate % of carpeted floor'}
                        id={`surveyItems.aprox_percent_carpet_floor`}
                        formControlProps={props.register(`surveyItems.aprox_percent_carpet_floor`)}
                        formControlClasses="form-control"
                        value={getCarpetPercentage()}
                        disabled={true}
                    />
                    <span className="percentile_img"
                    ><img
                            src="/images/icons/percentile.svg"
                            alt="percentile"
                        /></span>
                </div>
            </div>
            <div className="col-md-6 col-sm-12">
                <div className="form-group">
                    <Input
                        type="number"
                        labelClasses="form-label"
                        label={`Approximate sq. ft. of carpeted floor`}
                        placeholder={'Approximate sq. ft. of carpeted floor'}
                        id={`surveyItems.aprox_sqft_carpet_floor`}
                        formControlProps={props.register(`surveyItems.aprox_sqft_carpet_floor`)}
                        formControlClasses="form-control"
                        disabled={true}
                    />
                </div>
            </div>
            <div className="col-md-6 col-sm-12">
                <div className="form-group">
                    <Input
                        type="number"
                        labelClasses="form-label"
                        label={`Approximate % of tile floor`}
                        placeholder={'Approximate % of tile floor'}
                        id={`surveyItems.aprox_percent_tile_floor`}
                        formControlProps={props.register(`surveyItems.aprox_percent_tile_floor`)}
                        formControlClasses="form-control"
                        disabled={true}
                        value={getTilePercentage()}
                    />
                    <span className="percentile_img"
                    ><img
                            src="/images/icons/percentile.svg"
                            alt="percentile"
                        /></span>
                </div>
            </div>
            <div className="col-md-6 col-sm-12">
                <div className="form-group">
                    <Input
                        type="number"
                        labelClasses="form-label"
                        label={`Approximate sq. ft. of tile floor`}
                        placeholder={'Approximate sq. ft. of tile floor'}
                        id={`surveyItems.aprox_sqft_tile_floor`}
                        formControlProps={props.register(`surveyItems.aprox_sqft_tile_floor`)}
                        formControlClasses="form-control"
                        disabled={true}
                    />
                </div>
            </div>
            <div className="col-md-6 col-sm-12">
                <div className="form-group">
                    <Input
                        type="number"
                        labelClasses="form-label"
                        label={`Approximate % of other floor`}
                        placeholder={'Approximate % of other floor'}
                        id={`surveyItems.aprox_percent_other_floor`}
                        formControlProps={props.register(`surveyItems.aprox_percent_other_floor`)}
                        formControlClasses="form-control"
                        disabled={true}
                        value={getOtherPercentage()}
                    />
                    <span className="percentile_img"
                    ><img
                            src="/images/icons/percentile.svg"
                            alt="percentile"
                        /></span>
                </div>
            </div>
            <div className="col-md-6 col-sm-12">
                <div className="form-group">
                    <Input
                        type="number"
                        labelClasses="form-label"
                        label={`Approximate sq. ft. of other floor`}
                        placeholder={'Approximate sq. ft. of other floor'}
                        id={`surveyItems.aprox_sqft_other_floor`}
                        formControlProps={props.register(`surveyItems.aprox_sqft_other_floor`)}
                        formControlClasses="form-control"
                        disabled={true}
                    />
                </div>
            </div>
            <div className="col-md-6 col-sm-12">
                <div className="form-group">
                    <Input
                        type="number"
                        labelClasses="form-label"
                        label={`Cleaning hours per night w/o supervision`}
                        placeholder={'Cleaning hours per night w/o supervision'}
                        id={`surveyItems.cleaning_hrs_per_night`}
                        formControlProps={props.register(`surveyItems.cleaning_hrs_per_night`, {
                            required: "This field is required"
                        })}
                        validationErrorMessage={props.errors?.surveyItems?.cleaning_hrs_per_night?.message}
                        formControlClasses="form-control"
                        disabled={props.isView}
                    />
                </div>
            </div>
            <div className="col-md-6 col-sm-12">
                <div className="form-group">
                    <Input
                        type="text"
                        labelClasses="form-label"
                        label={`Supervision per night`}
                        placeholder={'Supervision per night'}
                        id={`surveyItems.supervision_per_night`}
                        formControlProps={props.register(`surveyItems.supervision_per_night`, {
                            required: "This field is required"
                        })}
                        validationErrorMessage={props.errors?.surveyItems?.supervision_per_night?.message}
                        formControlClasses="form-control"
                        disabled={props.isView}
                    />
                </div>
            </div>
            <div className="col-md-12 col-sm-12">
                <div className="form-group">
                    <Input
                        type="text"
                        labelClasses="form-label"
                        label={`Management hours per night charged directly to this project`}
                        placeholder={'Management hours per night charged directly to this project'}
                        id={`surveyItems.management_per_hours`}
                        formControlProps={props.register(`surveyItems.management_per_hours`, {
                            required: "This field is required"
                        })}
                        validationErrorMessage={props.errors?.surveyItems?.management_per_hours?.message}
                        formControlClasses="form-control"
                        disabled={props.isView}
                    />
                </div>
            </div>
            <div className="col-md-12 col-sm-12">
                <div className="form-group">
                    <Input
                        type="number"
                        labelClasses="form-label"
                        label={`Average hourly rate for cleaners $`}
                        placeholder={'Average hourly rate for cleaners $'}
                        id={`surveyItems.avg_hourly_rate`}
                        formControlProps={props.register(`surveyItems.avg_hourly_rate`, {
                            required: "This field is required"
                        })}
                        validationErrorMessage={props.errors?.surveyItems?.avg_hourly_rate?.message}
                        formControlClasses="form-control"
                        disabled={props.isView}
                    />
                </div>
            </div>
            <div className="col-md-12 col-sm-12">
                <div className="form-group">
                    <Input
                        type="number"
                        labelClasses="form-label"
                        label={`Current daily labor budget $`}
                        placeholder={'Current daily labor budget $'}
                        id={`surveyItems.daily_labor_budget`}
                        formControlProps={props.register(`surveyItems.daily_labor_budget`, {
                            required: "This field is required"
                        })}
                        validationErrorMessage={props.errors?.surveyItems?.daily_labor_budget?.message}
                        formControlClasses="form-control"
                        disabled={props.isView}
                    />
                </div>
            </div>
            <div className="col-md-6 col-sm-12">
                <div className="form-group">
                    <Input
                        type="date"
                        datePickerType="time"
                        formControlClasses="form-control"
                        id={`surveyItems.cleaning_time_from`}
                        placeholder="Scheduled cleaning times from"
                        control={props.control}
                        controller={props.controller}
                        selectName={`surveyItems.cleaning_time_from`}
                        dateFormat="MM/dd/yyyy"
                        label="Scheduled cleaning times from"
                        formControlProps={props.register(
                            `surveyItems.cleaning_time_from`,
                            {
                                required: "This field is required",
                                maxLength: 80,
                            }
                        )}
                        timeConstraints={{ minutes: { step: 5 } }}
                        validationErrorMessage={
                            props.errors?.surveyItems?.cleaning_time_from?.message ?? ""
                        }
                        disabled={props?.isView}
                    />
                </div>
            </div>
            <div className="col-md-6 col-sm-12">
                <div className="form-group">
                    <Input
                        type="date"
                        datePickerType="time"
                        formControlClasses="form-control"
                        id={`surveyItems.cleaning_time_to`}
                        placeholder="Scheduled cleaning times to"
                        control={props.control}
                        controller={props.controller}
                        selectName={`surveyItems.cleaning_time_to`}
                        dateFormat="MM/dd/yyyy"
                        label="Scheduled cleaning times to"
                        formControlProps={props.register(
                            `surveyItems.cleaning_time_to`,
                            {
                                required: "This field is required",
                                maxLength: 80,
                            }
                        )}
                        timeConstraints={{ minutes: { step: 5 } }}
                        validationErrorMessage={
                            props.errors?.surveyItems?.cleaning_time_to?.message ?? ""
                        }
                        disabled={props?.isView}
                    />
                </div>
            </div>
            <div className="col-md-12 col-sm-12">
                <div className="form-group">
                    <Input
                        type="textarea"
                        labelClasses="form-label"
                        label={`Are there any special cleaning requirements before or after the normal cleaning time?`}
                        placeholder={'Type here'}
                        id={`surveyItems.special_cleaning_req`}
                        formControlProps={props.register(`surveyItems.special_cleaning_req`, {
                            required: "This field is required"
                        })}
                        validationErrorMessage={props.errors?.surveyItems?.special_cleaning_req?.message}
                        formControlClasses="form-control"
                        disabled={props.isView}
                    />
                </div>
            </div>
            <div className="col-md-6 col-sm-12">
                <div className="form-group">
                    <Input
                        type="textarea"
                        labelClasses="form-label"
                        label={`Are there any special security requirements?`}
                        placeholder={'Type here'}
                        id={`surveyItems.special_security_req`}
                        formControlProps={props.register(`surveyItems.special_security_req`, {
                            required: "This field is required"
                        })}
                        validationErrorMessage={props.errors?.surveyItems?.special_security_req?.message}
                        formControlClasses="form-control"
                        disabled={props.isView}
                    />
                </div>
            </div>
            <div className="col-md-6 col-sm-12">
                <div className="form-group">
                    <Input
                        type="textarea"
                        labelClasses="form-label"
                        label={`Special project work required`}
                        placeholder={'Type here'}
                        id={`surveyItems.special_project_work_req`}
                        formControlProps={props.register(`surveyItems.special_project_work_req`, {
                            required: "This field is required"
                        })}
                        validationErrorMessage={props.errors?.surveyItems?.special_project_work_req?.message}
                        formControlClasses="form-control"
                        disabled={props.isView}
                    />
                </div>
            </div>
            <div className="col-md-6 col-sm-12">
                <div className="form-group">
                    <Input
                        type="inputSelect"
                        labelClasses="form-label"
                        control={props.control}
                        controller={props.controller}
                        selectLabel="Is there a good labor source available for this project?"
                        selectName={`surveyItems.good_labor_source_avail`}
                        placeholder={'Please Select'}
                        id={`surveyItems.good_labor_source_avail`}
                        selectOptions={[{ value: "true", label: "Yes" }, { value: "false", label: "No" }]}
                        required={props.register(`surveyItems.good_labor_source_avail`, {
                            required: "This field is required"
                        })}
                        validationErrorMessage={props.errors?.surveyItems?.good_labor_source_avail?.message}
                        selectClasses="form-select"
                        disabled={props.isView}
                    />
                </div>
            </div>
            <div className="col-md-6 col-sm-12">
                <div className="form-group">
                <Input
                        type="inputSelect"
                        labelClasses="form-label"
                        control={props.control}
                        controller={props.controller}
                        selectLabel="Is transportation a problem for employees?"
                        selectName={`surveyItems.transportation_problem`}
                        placeholder={'Please Select'}
                        id={`surveyItems.transportation_problem`}
                        selectOptions={[{ value: "true", label: "Yes" }, { value: "false", label: "No" }]}
                        required={props.register(`surveyItems.transportation_problem`, {
                            required: "This field is required"
                        })}
                        validationErrorMessage={props.errors?.surveyItems?.transportation_problem?.message}
                        selectClasses="form-select"
                        disabled={props.isView}
                    />
                </div>
            </div>
            <div className="col-md-6 col-sm-12">
                <div className="form-group">
                <Input
                        type="inputSelect"
                        labelClasses="form-label"
                        control={props.control}
                        controller={props.controller}
                        selectLabel="Are employees available to work extended hours (5 or 6 hour)"
                        selectName={`surveyItems.employee_avail_extended_hrs`}
                        placeholder={'Please Select'}
                        id={`surveyItems.employee_avail_extended_hrs`}
                        selectOptions={[{ value: "true", label: "Yes" }, { value: "false", label: "No" }]}
                        required={props.register(`surveyItems.employee_avail_extended_hrs`, {
                            required: "This field is required"
                        })}
                        validationErrorMessage={props.errors?.surveyItems?.employee_avail_extended_hrs?.message}
                        selectClasses="form-select"
                        disabled={props.isView}
                    />
                </div>
            </div>
            <div className="col-md-6 col-sm-12">
                <div className="form-group">
                <Input
                        type="inputSelect"
                        labelClasses="form-label"
                        control={props.control}
                        controller={props.controller}
                        selectLabel="Would building owner or manager object to extended hours?"
                        selectName={`surveyItems.building_owner_manager_extended_hrs`}
                        placeholder={'Please Select'}
                        id={`surveyItems.building_owner_manager_extended_hrs`}
                        selectOptions={[{ value: "true", label: "Yes" }, { value: "false", label: "No" }]}
                        required={props.register(`surveyItems.building_owner_manager_extended_hrs`, {
                            required: "This field is required"
                        })}
                        validationErrorMessage={props.errors?.surveyItems?.building_owner_manager_extended_hrs?.message}
                        selectClasses="form-select"
                        disabled={props.isView}
                    />
                </div>
            </div>
            <div className="col-md-6 col-sm-12">
                <div className="form-group">
                <Input
                        type="inputSelect"
                        labelClasses="form-label"
                        control={props.control}
                        controller={props.controller}
                        selectLabel="Are there non-English speaking employees?"
                        selectName={`surveyItems.non_eng_speak_employees`}
                        placeholder={'Please Select'}
                        id={`surveyItems.non_eng_speak_employees`}
                        selectOptions={[{ value: "true", label: "Yes" }, { value: "false", label: "No" }]}
                        required={props.register(`surveyItems.non_eng_speak_employees`, {
                            required: "This field is required"
                        })}
                        validationErrorMessage={props.errors?.surveyItems?.non_eng_speak_employees?.message}
                        selectClasses="form-select"
                        disabled={props.isView}
                    />
                </div>
            </div>
            <div className="col-md-6 col-sm-12">
                <div className="form-group">
                    <Input
                        type="text"
                        labelClasses="form-label"
                        label={`Language(s):`}
                        placeholder={'Type here'}
                        id={`surveyItems.languages`}
                        name={`surveyItems.languages`}
                        formControlProps={props.register(`surveyItems.languages`, {
                            required: "This field is required"
                        })}
                        validationErrorMessage={props.errors?.surveyItems?.languages?.message}
                        formControlClasses="form-control"
                        disabled={props.isView}
                    />
                </div>
            </div>
            <div className="col-md-6 col-sm-12">
                <div className="form-group">
                <Input
                        type="inputSelect"
                        labelClasses="form-label"
                        control={props.control}
                        controller={props.controller}
                        selectLabel="What are the priorities for this building?"
                        selectName={`surveyItems.priorities`}
                        placeholder={'Please Select'}
                        id={`surveyItems.priorities`}
                        selectOptions={[{ value: "Decrease labor", label: "Decrease labor" }, { value: "Improve quality", label: "Improve quality" },{ value: "Other", label: "Other" }]}
                        required={props.register(`surveyItems.priorities`, {
                            required: "This field is required"
                        })}
                        validationErrorMessage={props.errors?.surveyItems?.priorities?.message}
                        selectClasses="form-select"
                        disabled={props.isView}
                    />
                </div>
            </div>
            <div className="col-md-6 col-sm-12">
                <div className="form-group">
                <Input
                        type="inputSelect"
                        labelClasses="form-label"
                        control={props.control}
                        controller={props.controller}
                        selectLabel="When is the building substantially empty?"
                        selectName={`surveyItems.building_substantially_empty`}
                        placeholder={'Please Select'}
                        id={`surveyItems.building_substantially_empty`}
                        selectOptions={[{ value: "5:00 p.m.", label: "5:00 p.m." }, { value: "5:30 p.m.", label: "5:30 p.m." },{ value: "6:00 p.m.", label: "6:00 p.m." },{ value: "Other", label: "Other" }]}
                        required={props.register(`surveyItems.building_substantially_empty`, {
                            required: "This field is required"
                        })}
                        validationErrorMessage={props.errors?.surveyItems?.building_substantially_empty?.message}
                        selectClasses="form-select"
                        disabled={props.isView}
                    />
                </div>
            </div>
            <div className="col-md-6 col-sm-12">
                <div className="form-group">
                <Input
                        type="inputSelect"
                        labelClasses="form-label"
                        control={props.control}
                        controller={props.controller}
                        selectLabel="Current start time?"
                        selectName={`surveyItems.current_start_time`}
                        placeholder={'Please Select'}
                        id={`surveyItems.current_start_time`}
                        selectOptions={[{ value: "5:00 p.m.", label: "5:00 p.m." }, { value: "5:30 p.m.", label: "5:30 p.m." },{ value: "6:00 p.m.", label: "6:00 p.m." },{ value: "Other", label: "Other" }]}
                        required={props.register(`surveyItems.current_start_time`, {
                            required: "This field is required"
                        })}
                        validationErrorMessage={props.errors?.surveyItems?.current_start_time?.message}
                        selectClasses="form-select"
                        disabled={props.isView}
                    />
                </div>
            </div>
            <div className="col-md-6 col-sm-12">
                <div className="form-group">
                <Input
                        type="inputSelect"
                        labelClasses="form-label"
                        control={props.control}
                        controller={props.controller}
                        selectLabel="Service schedule"
                        selectName={`surveyItems.service_schedule`}
                        placeholder={'Please Select'}
                        id={`surveyItems.service_schedule`}
                        selectOptions={[{ value: "Monday - Friday", label: "Monday - Friday" }, { value: "Sunday - Thursday", label: "Sunday - Thursday" },{ value: "24/7", label: "24/7" },{ value: "Other", label: "Other" }]}
                        required={props.register(`surveyItems.service_schedule`, {
                            required: "This field is required"
                        })}
                        validationErrorMessage={props.errors?.surveyItems?.service_schedule?.message}
                        selectClasses="form-select"
                        disabled={props.isView}
                    />
                </div>
            </div>
            <div className="col-md-12 col-sm-12">
                <div className="form-group">
                <Input
                        type="textarea"
                        labelClasses="form-label"
                        label={`Notes`}
                        placeholder={'Type here'}
                        id={`surveyItems.notes`}
                        formControlProps={props.register(`surveyItems.notes`, {
                            required: "This field is required"
                        })}elevator_sq_footage
                        validationErrorMessage={props.errors?.surveyItems?.notes?.message}
                        formControlClasses="form-control"
                        disabled={props.isView}
                    />
                </div>
            </div>
        </>
    )
}

export default BpSurveyItems