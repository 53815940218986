import React, { Component } from 'react';
import { routes } from '../../../utility/constants/constants';
import User from '../../component/user/user';
import * as actions from '../../../redux/actions/index';
import NewUser from '../../component/user/NewUser/newUser';
import { connect } from 'react-redux';
import { reset } from 'redux-form';
import storage from '../../../utility/storage';
import SuccessAlert from '../../../utility/successAlert/successAlert';
import { animateScroll as scroll} from 'react-scroll'


class UserContainer extends Component {

    constructor(props) {
        super(props);
        this.newUserClicked = this.newUserClicked.bind(this);
        this.state = {
            // activeUsers: localStorage.getItem('user_active') ? localStorage.getItem('user_active') === 'true' ? true : false : true,
            
        }
    }

    newUserClicked = () => {
        console.log(this.props);
        this.props.history.push(routes.NEW_USER);
        this.props.newUserClicked();
    }

    componentDidUpdate(prevProps, prevState) {
        const user = storage.get('user');
        if(user) {
        if(prevProps.user.selected_mode !== user.selected_mode) {
            this.props.userPageClicked(0)
        }
    }
    }

    componentDidMount() {
        // if (!this.props.usersList) {
        //     this.props.getUsers();
        // }
        // this.setState({activeUsers: localStorage.getItem('user_active') === 'true' ? true : false});
        scroll.scrollToTop();
    }

    deleteUser = (id) => {
        this.props.deleteUser(id)
    }

    editUserClicked = (user) => {

        this.props.editUserClicked(user);
    }

    emailPreferencesClicked = (user) => {
        this.props.emailPreferencesClicked(user);
    }

    searchUserClicked = (values) => {
        if(values.email) {
            values.email = values.email.trim()
        }
        if(values.name) {
            values.name = values.name.trim()
        }
        this.props.userPageClicked(0)
        values.active = this.props.activeUsers;
        this.props.SearchUsers(values);
    }

    activeUserClicked = (e) => {
        // this.setState({activeUsers: e.target.checked});
        this.props.usersActiveChanged()
    }

    resetClicked = () => { 
        this.props.reset('searchUserForm');
        this.props.userPageClicked(0)
        this.props.getUsers({active: this.props.activeUsers}, this.props.users_no_of_rows);
    }

    handleClickDeleteModel = (user) => {

        this.props.handleClickDeleteModel(user);
    }

    handleDeleteClose = () => {
        this.props.handleDeleteClose();
    }

    onUserPageSizeChange = (pageNo) => {
        let rows = {
            no_of_rows: pageNo
        }
        this.props.onUserPageSizeChange(rows);
    }

    previewUserClicked = (user) => {
        this.props.goToPreviewUser(user);
    }
    onUserExportClicked = () => { 
        this.props.getUserExportData().then(response => {
            // let ele = document.getElementById('userExport')
            //     ele.click()
            if(response.action.payload.data.success){ 
                SuccessAlert('Please check your email for export results');
            }
        })
    }
    render() {

        return (
            <User
                {...this.props}
                resetClicked={this.resetClicked}
                onSubmit={this.searchUserClicked}
                editUserClicked={this.editUserClicked}
                emailPreferencesClicked={this.emailPreferencesClicked}
                deleteUser={this.deleteUser}
                getUsers={this.props.getUsers}
                previewUserClicked={this.previewUserClicked}
                users_rows={this.props.users_rows}
                usersList={this.props.usersList}
                onUserPageSizeChange={this.onUserPageSizeChange}
                isLoading={this.props.isLoading}
                newUserClicked={this.newUserClicked}
                handleDeleteClose={this.handleDeleteClose}
                openDeleteModel={this.props.openDeleteModel}
                handleClickDeleteModel={this.props.handleClickDeleteModel}
                deleteObject={this.props.deleteObject}
                onUserExportClicked={this.onUserExportClicked}
                activeUsers={this.props.activeUsers}
                activeUserClicked={this.activeUserClicked}
                welcomeMailForUser={this.props.welcomeMailForUser}
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.authReducer.user,
        // user: state.adminOrSuperAdminReducer.requestor,
        usersList: state.adminOrSuperAdminReducer.usersList,
        isLoading: state.adminOrSuperAdminReducer.isLoading,
        isUserLoading: state.adminOrSuperAdminReducer.isUserLoading,
        deleteObject: state.adminOrSuperAdminReducer.deleteObject,
        openDeleteModel: state.adminOrSuperAdminReducer.openDeleteModel,
        users_no_of_rows: state.adminOrSuperAdminReducer.users_no_of_rows,
        userExportDataList: state.adminOrSuperAdminReducer.userExportData,
        activeUsers: state.adminOrSuperAdminReducer.activeUsers,
        userCurPage: state.adminOrSuperAdminReducer.userCurPage,
        searchUserValues: state.adminOrSuperAdminReducer.searchUserValues
        // state.adminOrSuperAdminReducer.users_no_of_rows
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        handleDeleteClose: () => dispatch(actions.handleDeleteClose()),
        handleClickDeleteModel: (user) => dispatch(actions.handleClickDeleteModel(user)),
        goToPreviewUser: (user) => dispatch(actions.goToPreviewUser(user)),
        // searchUserClicked: (quote) => dispatch(actions.searchUserClicked(quote)),
        onUserPageSizeChange: (rows) => dispatch(actions.onUserPageSizeChange(rows)),
        editUserClicked: (user) => dispatch(actions.editUserClicked(user)),
        emailPreferencesClicked: (user) => dispatch(actions.emailPreferencesClicked(user)),
        getUsers: (values, user_no_of_rows, page, sorted, filtered) => dispatch(actions.getUsers(values, user_no_of_rows, page, sorted, filtered)),
        SearchUsers: (values) => dispatch(actions.SearchUsers(values)),
        deleteUser: (id) => dispatch(actions.deleteUser(id)),
        reset: (searchUserForm) => dispatch(reset(searchUserForm)),  // requires form name
        getUserExportData: () => dispatch(actions.getUserExportData()),
        newUserClicked: () => dispatch(actions.newUserClicked()),
        usersActiveChanged: () => dispatch(actions.usersActiveChanged()),
        welcomeMailForUser: (id) => dispatch(actions.welcomeMailForUser(id)),
        userPageClicked: (page) => dispatch(actions.userPageClicked(page)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserContainer);
