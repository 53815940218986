import React, { useState } from 'react';
import ReactTable from 'react-table-v6';
import '../../../styles/custom.css';
import { makeFirstLetterCapital, CustomNoDataComponent, capitalAndConvertHyphen, isString } from '../../../utility/utility';
import { MagicSpinner, PushSpinner, SwishSpinner, WaveSpinner, ClapSpinner } from 'react-spinners-kit';
import 'react-table-v6/react-table.css';
import { FaArrowDown, FaArrowUp } from "react-icons/fa";
import { userRoles, RatingTypes } from '../../../utility/constants/constants';
import { useSelector } from 'react-redux';


const RatingsTable = (props) => {
    const ratingPage = useSelector(state => state.adminOrSuperAdminReducer.ratingCurPage);
    const No_of_rows = useSelector(state => state.adminOrSuperAdminReducer.rating_no_of_rows);
    const data = props.ratingList.map(rating => {
    if(rating.hide !== true){
        return {
            name: rating.name,
            type: capitalAndConvertHyphen(rating.category),
            lineItems: rating.rating_options_attributes.length,
            actions:
                rating.category === RatingTypes.Text ? null :
                    <>
                        <span className="mr_20 account_edit_icn" onClick={() => props.editRatingClicked(rating)}><i class="fa fa-pencil" aria-hidden="true"></i>
                        </span>
                        <span className="mr_20 account_edit_icn" onClick={() => props.handleClickDeleteModel(rating)}><i class="fa fa-trash" aria-hidden="true"></i>
                        </span>
                    </>
        }
    } else{
        return(
            <div>No Data Found</div>
        )
    }
        
    })

    const [tablePage, setTablePage] = useState(0)
    // const tablePageNew =  React.useRef()

    const setTablePageFn = (page, rows) => { 
        setTablePage(page)
        props.ratingPageClicked(page, rows)
        // tablePageNew.current = page;
    }

    const columns = [
        {
            Header: <><span>Rating Name</span><FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
            accessor: 'name', // String-based value accessors!,
            show: props.ratingList.length !== 0,

        }, {
            Header: <><span>Rating Type</span><FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
            accessor: 'type',
            show: props.ratingList.length !== 0,

        }, {
            Header: <><span>Line Items</span><FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
            accessor: 'lineItems',
            show: props.ratingList.length !== 0,

        }, {
            Header: ' ',
            accessor: 'actions',

            // show: false,
        }
    ]


    return (
        props.isLoading ?
            <div  >
                <ClapSpinner backColor="#89073c" size={40} frontColor="#89073c8a" />
            </div>
            :
            <ReactTable
                data={data}
                loading={props.isLoading}
                defaultPageSize={isString(No_of_rows) ? No_of_rows.trim() : No_of_rows}
                minRows={0}
                onPageSizeChange={props.onUserPageSizeChange}
                page = { ratingPage
                }
                onPageChange={(page) => setTablePageFn(page, No_of_rows)}
                showPaginationTop={true}
                showPagination={props.ratingList.length !== 0}
                columns={columns}
                // style={{marginTop: '10px'}}
                NoDataComponent={() => CustomNoDataComponent(props.isLoading, 'No Ratings Found')}
                getTheadThProps={(state, rowInfo, column, instance) => {
                    // console.log(column);
                    return {
                        style: {
                            borderTop: 'none',
                            borderBottom: 'none',
                            borderRight: 'none',
                            textAlign: 'center',
                            color: '#566975',
                            fontSize: '14px',
                            fontWeight: '600',
                            whiteSpace: 'nowrap',
                            float: 'left',
                        }
                    }
                }
                }
                getTrProps={(state, rowInfo, column, instance) => {
                    // onclick = () => userDetail(rowInfo ? rowInfo.row.user : rowInfo);
                    console.log(column);
                    return {
                        className: 'react-table-tr-element',
                        style: {
                            border: '1px solid #c7cdd1',
                            transition: '.3s ease-in-out',
                            width: '100%',
                            // borderRadius: '4px',
                            // height: '80px',
                            marginTop: '5px',
                            float: 'left'
                        }
                    }

                }}
                getTdProps={(state, rowInfo, column, instance) => {

                    if (column.id == 'image') {
                        return {
                            style: {
                                marginTop: '0px'
                            }
                        }
                    } else {
                        return {
                            className: 'react-td-element',
                        }
                        //  {
                        //   style: {
                        //     borderTop: 'none',
                        //     verticalAlign: 'middle',
                        //     fontSize: '14px',
                        //     color: '#2b3034',
                        //     marginTop: '10px !important',
                        //     textAlign: 'center',
                        //   }
                        // }
                    }
                }
                }
                // getPaginationProps={() => {
                //     return {
                //         style: {
                //             marginTop: '50px',
                //             border: 'none',
                //             boxShadow: 'none'
                //         }
                //     }
                // }
                // }
                getProps={() => {
                    return {
                        style: {
                            border: 'none'
                        }
                    }
                }}
            />
    )
}

export default RatingsTable;
