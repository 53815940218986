import swal from 'sweetalert2'
import '../sweetAlert2.css';

const ErrorAlert = (message) => {
    swal.fire({
        toast: true,
        type: 'error',
        timer: 3000,
        // width: '500px',
        position: 'top-end',
        animation: true,
        background: '#e85642',
        showConfirmButton: false,
        showCloseButton: true,
        title: message,
    })
}

export default ErrorAlert;