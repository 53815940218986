import React from 'react';
import ReactTable from 'react-table-v6';
import '../../../../styles/custom.css';
import { makeFirstLetterCapital, CustomNoDataComponent } from '../../../../utility/utility';
import { MagicSpinner, PushSpinner, SwishSpinner, WaveSpinner, ClapSpinner } from 'react-spinners-kit';
import 'react-table-v6/react-table.css';
import { useSelector, useDispatch } from 'react-redux';
import { FaArrowDown, FaArrowUp } from "react-icons/fa";
import * as actions from '../../../../redux/actions/index';
import { userRoles } from '../../../../utility/constants/constants';
import ImageLoader from 'react-imageloader';

let CompanyUsersTable = (props) => {
    const dispatch = useDispatch();
    const SearchValue = useSelector(state => state.form.searchCompanieUsersForm.values);
    const DateGenerate = useSelector(state => state.adminOrSuperAdminReducer.dataGenerated);
    const SearchOrResetCompanyUsersClicked = useSelector(state => state.adminOrSuperAdminReducer.SearchOrResetCompanyUsersClicked);
    const No_of_pages = useSelector(state => state.adminOrSuperAdminReducer.total_no_of_pages);
    const No_of_rows = useSelector(state => state.adminOrSuperAdminReducer.company_users_row);

    function preloader() {
        return <img style={{ height: '35px', width: '35px', borderRadius: '50px', float: 'left' }} src="/images/gif/giphy.gif" />;
    }

    const data = props.companyUsersList.map(user => {

        return {
            image:
                user.photo_urls.small ?
                    <ImageLoader
                        imgProps={{ style: { height: '35px', width: '35px', borderRadius: '50px', float: 'left' } }}
                        style={{ height: '35px', width: '35px', borderRadius: '50px', float: 'left' }}
                        src={user.photo_urls.small}
                        wrapper={React.createFactory('div')}
                        preloader={preloader}>
                        Image load failed!
            </ImageLoader>
                    :
                    <img src="/customImages/user.png" style={{ height: '35px', width: '35px', borderRadius: '50px', float: 'left' }} />
            // <img style={{ height: '35px', width: '35px', borderRadius: '50px' }} src={user.photo_urls.original ? user.photo_urls.original : "/customImages/user.png"} />
            ,
            account: user.name,
            // lastName: user.user.last_name,
            // position: user.user.position ? user.user.position.name : '',
            // role: makeFirstLetterCapital(user.user.role),
            // region: user.user.region ? user.user.region.name : '',
            // phonenumber: '810-191-8851',
            address: user.address ? user.address.formatted_address : '',
            accountNumber: user.account_number,
            region: user.region ? user.region.name : '',
            // emailNotificationAccess: <>
            //     <span className={user.quote_notifications ? "text_danger" : "text_gray"}>{user.quote_notifications ? <strong>Quotes</strong> : "Quotes"}</span> |
            // <span className={user.ticket_notifications ? "text_danger" : "text_gray"}>{user.ticket_notifications ? <strong>Tickets</strong> : "Tickets"}</span> |
            // <span className={user.inspection_notifications ? "text_danger" : "text_gray"}>{user.inspection_notifications ? <strong>Inspection</strong> : "Inspection"}</span></>,
            actions: user.role === userRoles.SUPER_ADMIN ? null
                :
                <>
                    {/* <span className="mr_20 account_edit_icn" onClick={() => props.editUserClicked(user)}><i class="fa fa-pencil" aria-hidden="true"></i>
                    </span> */}
                    <span className="mr_20 account_edit_icn" onClick={() => props.handleClickDeleteModel(user)}><i class="fa fa-trash" aria-hidden="true"></i>
                    </span>
                </>
        }
    })


    const columns = [
        {
            Header: '',
            accessor: 'image', // String-based value accessors!,
            width: 55
        }, {
            Header: <><span>Job Name</span><FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
            accessor: 'account', // String-based value accessors!,
            show: props.companyUsersList.length !== 0,
        },{
            // id: 'friendName', // Required because our accessor is not a string
            Header: <><span>Job #</span><FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
            accessor: 'accountNumber',
            show: props.companyUsersList.length !== 0, // Custom value accessors!
        },
        // {
        //     Header: <><span>Last Name</span><FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
        //     accessor: 'lastName',
        //     show: props.companyUsersList.length !== 0,
        //     // Cell: props => <span className='number'>{props.value}</span> // Custom cell components!
        // },

        // {
        //     Header: <><span>Position</span><FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
        //     accessor: 'position',
        //     show: props.companyUsersList.length !== 0,
        // }, {
        //     // id: 'friendName', // Required because our accessor is not a string
        //     Header: <><span>Role</span><FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
        //     accessor: 'role',
        //     show: props.companyUsersList.length !== 0, // Custom value accessors!
        // }, {
        //     // id: 'friendName', // Required because our accessor is not a string
        //     Header: <><span>Region</span><FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
        //     accessor: 'region',
        //     show: props.companyUsersList.length !== 0, // Custom value accessors!
        // },
        {
            Header: <><span>Address</span><FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
            accessor: 'address',
            show: props.companyUsersList.length !== 0,
            width: 350
        },
        // {
        //     Header: <><span>Region</span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
        //     accessor: 'region',
        //     width: 135,
        //     show: props.companyUsersList.length !== 0
        //     // Cell: props => <span className='number'>{props.value}</span> // Custom cell components!
        // },
        // {
        //     Header: <><span>Email Notification Access</span><FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
        //     accessor: 'emailNotificationAccess',
        //     sortable: false,
        //     show: props.companyUsersList.length !== 0,
        //     width: 300
        // },
        // {
        //     Header: ' ',
        //     accessor: 'actions',
        //     width: 100
        //     // show: false,
        // }
    ]

    const fetchData = (state, instance) => {

        if (SearchOrResetCompanyUsersClicked) {
            dispatch(actions.resetSearchOrResetValue())
        } else {
            if (!DateGenerate) {
                const account_users_no_of_rows = state.pageSize;
                const page = state.page + 1;
                const sorted = state.sorted;
                const filtered = state.filtered;

                props.getCompanyUsers(
                    props.match.params.companyId,
                    SearchValue,
                    account_users_no_of_rows, page,
                    sorted,
                    filtered);
            }
        }
        // }
        // }
    }

    return (
        SearchOrResetCompanyUsersClicked && props.isCompanyUsersPending ?
            <div className="loader_btn_block">
                <ClapSpinner backColor="#89073c" size={40} frontColor="#89073c8a" />
            </div>
            :
            <ReactTable
                data={data}
                loading={props.isCompanyUsersPending}
                defaultPageSize={No_of_rows}
                minRows={0}
                manual
                onFetchData={fetchData}
                pages={No_of_pages}
                // onPageSizeChange={props.onUserPageSizeChange}
                showPaginationTop={true}
                showPagination={props.companyUsersList.length !== 0}
                columns={columns}
                // style={{marginTop: '10px'}}
                NoDataComponent={() => CustomNoDataComponent(props.isCompanyUsersPending, 'No Users Found')}
                getTheadThProps={(state, rowInfo, column, instance) => {
                    return {
                        style: {
                            borderTop: 'none',
                            borderBottom: 'none',
                            borderRight: 'none',
                            textAlign: 'center',
                            color: '#566975',
                            fontSize: '14px',
                            fontWeight: '600',
                            whiteSpace: 'nowrap',
                            float: 'left',
                        }
                    }
                }
                }
                LoadingComponent={() =>
                    props.isCompanyUsersPending ?
                        <div style={{
                            display: "block",
                            position: "absolute",
                            left: 0,
                            height: '100%',
                            right: 0,
                            background: "rgba(255,255,255,0.8)",
                            transition: "all .3s ease",
                            top: 0,
                            bottom: 0,
                            textAlign: "center"
                        }}
                            className="loader_btn_block">
                            <ClapSpinner backColor="#89073c" size={40} frontColor="#89073c8a" />
                        </div> : null}
                getTrProps={(state, rowInfo, column, instance) => {
                    // onclick = () => userDetail(rowInfo ? rowInfo.row.user : rowInfo);
                    console.log(column);
                    return {
                        className: 'react-table-tr-element',
                        style: {
                            border: '1px solid #c7cdd1',
                            transition: '.3s ease-in-out',
                            width: '100%',
                            // borderRadius: '4px',
                            // height: '80px',
                            marginTop: '5px',
                            float: 'left'
                        }
                    }

                }}
                getTdProps={(state, rowInfo, column, instance) => {

                    if (column.id == 'image') {
                        return {
                            style: {
                                marginTop: '0px'
                            }
                        }
                    } else {
                        return {
                            className: 'react-td-element',
                        }
                        //  {
                        //   style: {
                        //     borderTop: 'none',
                        //     verticalAlign: 'middle',
                        //     fontSize: '14px',
                        //     color: '#2b3034',
                        //     marginTop: '10px !important',
                        //     textAlign: 'center',
                        //   }
                        // }
                    }
                }
                }
                // getPaginationProps={() => {
                //     return {
                //         style: {
                //             marginTop: '50px',
                //             border: 'none',
                //             boxShadow: 'none'
                //         }
                //     }
                // }
                // }
                getProps={() => {
                    return {
                        style: {
                            border: 'none'
                        }
                    }
                }}
            />
    )
}

export default CompanyUsersTable;