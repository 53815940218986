import React from 'react'

const LogBookThankYou = () => {
  return (
    <main className="app-content wraper_content inspection_wraper">
        <div className="container" style={{ marginTop: "100px" }}>
            <div className="row">
                <div className="col-md-12 FLH_col">
                    <div className="FLH_button_container">
                        <div className="card-body">
                            <img src="/images/icons/positive-vote.png" style={{ height: '150px', width: '150px' }} />
                            <div className="thank_msg text-center w-100 h1" style={{marginTop: '30px'}}>Thank you for your feedback</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
  )
}

export default LogBookThankYou