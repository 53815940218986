import React from 'react';
import ReactTable from 'react-table-v6';
import '../../../styles/custom.css';
import { CustomNoDataComponent, makeFirstLetterCapital, convertDateTimezone } from '../../../utility/utility';
import { FaArrowDown, FaArrowUp } from "react-icons/fa";
import 'react-table-v6/react-table.css';
import { TimeZones } from '../../../utility/constants/constants';

var moment = require('moment');

const ClientPastInspections = (props) => {

    const data = props.dashboardData.past_inspection && props.dashboardData.past_inspection.map(inspection => {
        return {
            date: convertDateTimezone(inspection.created_at, inspection.timezone),
            score: inspection.score !== null ? inspection.score : 'N/A',
            form: makeFirstLetterCapital(inspection.name)
        }
    })

    const columns = [
        {
            Header: <><span>Date</span><FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
            accessor: 'date', // String-based value accessors!,
            show: props.dashboardData.past_inspection && props.dashboardData.past_inspection.length !== 0,
        }, {
            Header: <><span>Score</span><FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
            accessor: 'score', // String-based value accessors!,
            show: props.dashboardData.past_inspection && props.dashboardData.past_inspection.length !== 0,
        }, {
            Header: <><span>Form</span><FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
            accessor: 'form', // String-based value accessors!,
            show: props.dashboardData.past_inspection && props.dashboardData.past_inspection.length !== 0,
        },
    ]


    return (
        <div className="chi_response" style={{width: '100%'}}>
            <div className="tile pb-0">
                <div className="response_head">
                    <h5 className="h5_title">Past Inspections</h5>
                    {/* <div className="cst_select_block p-0">
                        <select className="custom-select">
                            <option selected>All</option>
                            <option value="1">One</option>
                            <option value="2">Two</option>
                            <option value="3">Three</option>
                        </select>
                    </div> */}
                </div>

                <div className="quotes_table_content table-responsive response_table">
                    <div className="table mb-0">
                        <ReactTable
                            data={data}
                            // loading={props.isLoading}
                            defaultPageSize={10}
                            minRows={0}
                            // showPaginationTop={true}
                            showPagination={props.dashboardData.past_inspection && props.dashboardData.past_inspection.length !== 0}
                            columns={columns}
                            // style={{marginTop: '10px'}}
                            NoDataComponent={() => CustomNoDataComponent(props.isLoading, 'No Inspections Found')}
                            getTheadThProps={(state, rowInfo, column, instance) => {
                                // console.log(column);
                                return {
                                    style: {
                                        borderTop: 'none',
                                        borderBottom: 'none',
                                        borderRight: 'none',
                                        textAlign: 'center',
                                        color: '#566975',
                                        fontSize: '14px',
                                        fontWeight: '600',
                                        whiteSpace: 'nowrap',
                                        float: 'left',
                                    }
                                }
                            }
                            }
                            getTrProps={(state, rowInfo, column, instance) => {
                                // onclick = () => userDetail(rowInfo ? rowInfo.row.user : rowInfo);
                                console.log(column);
                                return {
                                    className: 'react-table-tr-element',
                                    style: {
                                        border: '1px solid #c7cdd1',
                                        transition: '.3s ease-in-out',
                                        width: '100%',
                                        marginTop: '5px',
                                        float: 'left'
                                    }
                                }

                            }}
                            getTdProps={(state, rowInfo, column, instance) => {
                                return {
                                    className: 'react-td-element',
                                }
                            }
                            }

                            getProps={() => {
                                return {
                                    style: {
                                        border: 'none'
                                    }
                                }
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ClientPastInspections;