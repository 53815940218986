import React, { Component } from 'react';
import * as actions from '../../../redux/actions/index';
import { connect } from 'react-redux';
import { getUser } from '../../../utility/utility';
import { animateScroll as scroll} from 'react-scroll'
import Deficiency from '../../component/Insights/Deficiencies/Deficiency';

class DeficiencyContainer extends Component {
  componentDidMount() {
    if (!this.props.deficiencies.length) {
      this.props.getAllDeficiencies();
    }
    scroll.scrollToTop();
  }

  render() {

    return (
        <Deficiency
            deficiencies={this.props.deficiencies ? this.props.deficiencies : []}
            openDeleteModel={this.props.openDeleteModel}
            handleDeleteClose={this.props.handleDeleteClose}
            editDeficiencyClicked={this.props.editDeficiencyClicked}
            deleteAreaType={this.props.deleteAreaType}
            deleteObject={this.props.deleteObject}
            handleClickDeleteModel={this.props.handleClickDeleteModel}
            isLoading={this.props.isLoading}
            openPopup={this.props.openPopup}
            totalDeficiencies= {this.props.totalDeficiencies}
            />
    )
  }
}

const mapStateToProps = (state) => {
  return {
    deficiencies: state.adminOrSuperAdminReducer.deficiencies,
    isLoading: state.adminOrSuperAdminReducer.isLoading,
    deleteObject: state.adminOrSuperAdminReducer.deleteObject,
    openDeleteModel: state.adminOrSuperAdminReducer.openDeleteModel,
    totalDeficiencies: state.adminOrSuperAdminReducer.totalDeficiencies,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    handleDeleteClose: () => dispatch(actions.handleDeleteClose()),
    deleteAreaType: (id) => dispatch(actions.deleteAreaType(id)),
    getAreaTypes: () => dispatch(actions.getAreaTypes()),
    getAllDeficiencies: () => dispatch(actions.getAllDeficiencies()),
    openPopup: () => dispatch(actions.openPopup()),
    editDeficiencyClicked: (deficiency) => dispatch(actions.editDeficiencyClicked(deficiency)),
    handleClickDeleteModel: (account) => dispatch(actions.handleClickDeleteModel(account)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DeficiencyContainer);