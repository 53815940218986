import React, { Component } from 'react';
import { routes, userRoles } from '../../../../../utility/constants/constants';
import * as actions from '../../../../../redux/actions/index';
import { connect } from 'react-redux';
import { reset } from 'redux-form';
import { convertDateToDifferentTZ, getUser } from '../../../../../utility/utility';
import { animateScroll as scroll} from 'react-scroll'
import storage from '../../../../../utility/storage';
import ProjectModuleList from '../../../../component/FormAuditModule/ProjectModule/ProjectModuleList/projectModuleList';
var moment = require('moment');
const cloneDeep = require('clone-deep');

class ProjectModuleListContainer extends Component {

    constructor(props) {
        super(props);

        this.state = {
            fromDashboard: false,
            pathname: ''
        }
    }

    newTicketClicked = () => {
        this.props.history.push(routes.NEW_PROJECT_MODULE);
    }

    // componentDidMount() {
    //     if(this.props.history) {
    //         this.setState({fromDashboard: this.props.history.location.pathname.includes('dashboard'), pathname: this.props.history.location.pathname});
    //     }
    //     // if (!this.props.categoriesList) {
    //             this.props.getCategories({hide: false});
    //     // }
    //     scroll.scrollToTop();
    // }

    // componentDidUpdate(prevProps, prevState) {
    //     const user = storage.get('user');
    //     if(user) {
    //     if(prevProps.user.selected_mode !== user.selected_mode) {
    //         this.props.projectModuleTicketPageClicked(0)
    //     }
    // }
    //     if(prevProps.history.location.pathname !== prevState.pathname && prevProps.history.location.pathname !== this.state.pathname) {
    //         this.setState({fromDashboard: this.props.history.location.pathname.includes('dashboard'), pathname: this.props.history.location.pathname});
    //     }
    // }

    // handleClickDeleteModel = (ticket) => {

    //     this.props.handleClickDeleteModel(ticket);
    // }

    // deleteTicket = (id) => {
    //     this.props.deleteTicket(id);
    // }

    // handleDeleteClose = () => {
    //     this.props.handleDeleteClose();
    // }

    // editTicketClicked = (ticket) => {
    //     this.props.editTicketClicked(ticket);
    // }

    previewTicketClicked = (ticket) => {
        this.props.previewTicketClicked(ticket);
    }

    // searchTicket = (value) => {
    //     const page  = 1;
    //     const no_of_rows = this.props.ticket_no_of_rows.trim()
    //     if(value.assigned_to) {
    //         value.assigned_to = value.assigned_to.trim()
    //     }
    //     if(value.subject) {
    //         value.subject = value.subject.trim()
    //     }
    //     if(value.description) {
    //         value.description = value.description.trim()
    //     }
    //     if(value.title) {
    //         value.title = value.title.trim()
    //     }
    //     if(value.job) {
    //         value.job = value.job.trim()
    //     }
    //     if(value.created_by) {
    //         value.created_by = value.created_by.trim()
    //     }
        
    //     // if (this.props.user.role === userRoles.ADMIN || this.props.user.role === userRoles.REQUESTOR || this.props.user.role === userRoles.CLIENT || this.props.user.role === userRoles.INSPECTOR) {
    //     // this.props.getUserMyTicketsList(values);
    //     // } else {
    //         this.props.projectModuleTicketPageClicked(0)
    //         if(this.props.history.location.state) {
    //             const values = cloneDeep(value);
    //             if (values.fromDate && values.toDate) {
    //                 values.fromDate = convertDateToDifferentTZ(values.fromDate, 'from');
    //                 values.toDate = convertDateToDifferentTZ(values.toDate, 'to');
    //             }
    //             this.props.updateWeekMonthYear(this.props.history.location.state.isWeek, this.props.history.location.state.isMonth, this.props.history.location.state.isYear);
    //             // this.props.updateCurrentDate(this.props.history.location.state.currentDate);
    //             let SearchValue = {};
    //             if (this.props.history.location.state.isWeek) {
    //                 SearchValue = this.props.history.location.state;
    //                 SearchValue.firstday = moment(SearchValue.currentDate).startOf('week').format('MM/DD/YYYY');
    //                 SearchValue.lastday = moment(SearchValue.currentDate).endOf('week').format('MM/DD/YYYY');
    //                 SearchValue.currentDate = moment(SearchValue.currentDate).utc().toISOString();
    //                 SearchValue.value = values;
    //             } else if (this.props.history.location.state.isMonth || this.props.history.location.state.isYear) {
    //                 SearchValue = this.props.history.location.state;
    //                 SearchValue.value = values;
    //                 SearchValue.currentDate = moment(SearchValue.currentDate).utc().toISOString();
    //             }
    //     this.props.getScheduledTickets(SearchValue,no_of_rows,page)
    //     } else {
    //         delete value.value;
    //         const values = {value: cloneDeep(value)};
    //         if (values.value.fromDate && values.value.toDate) {
    //             values.value.fromDate = convertDateToDifferentTZ(values.value.fromDate, 'from');
    //             values.value.toDate = convertDateToDifferentTZ(values.value.toDate, 'to');
    //         }
    //     this.props.getScheduledTickets(values,no_of_rows,page)
    //     }
    // }

    // resetClicked = () => { 
    //     this.props.reset('scheduleSearchTicket');
    //     let SearchValue = {};
    //     if (this.props.history.location.pathname === routes.VIEW_FILTERED_TICKETS) {
    
    //         this.props.updateWeekMonthYear(this.props.history.location.state.isWeek, this.props.history.location.state.isMonth, this.props.history.location.state.isYear);
    //         if (this.props.history.location.state.isWeek) {
    
    //             SearchValue = this.props.history.location.state;
    //             delete SearchValue.value
    //             SearchValue.firstday = moment(SearchValue.currentDate).startOf('week').format('MM/DD/YYYY');
    //             SearchValue.lastday = moment(SearchValue.currentDate).endOf('week').format('MM/DD/YYYY');
    //             SearchValue.currentDate = moment(SearchValue.currentDate).utc().toISOString();
    //         } else if (this.props.history.location.state.isMonth || this.props.history.location.state.isYear) {
    //             SearchValue = this.props.history.location.state;
    //             SearchValue.currentDate = moment(SearchValue.currentDate).utc().toISOString();
    //         }
    //     } 
    //     this.props.projectModuleTicketPageClicked(0)
    //     delete SearchValue.value
    //         this.props.getScheduledTickets(SearchValue, this.props.ticket_no_of_rows);
    // }
    // onTicketExportClicked = (body) => { 
    //     const user = getUser();
    //     let data = {}
    //     data['mode']= user.mode
    //     Object.keys(body).map((key, index) => {
    //         if(body[key] !== '') {
    //             data[key] = body[key]
    //         }
    //     })
    //     this.props.onTicketExportClicked(data)
    // }

    render() {
        return (
            <ProjectModuleList
                {...this.props}
                onSubmit={this.searchTicket}
                newTicketClicked={this.newTicketClicked}
                resetClicked={this.resetClicked}
                previewTicketClicked={this.previewTicketClicked}
                ticketsList={this.props.ticketsList ? this.props.ticketsList : []}
                isLoading={this.props.isLoading}
                // editTicketClicked={this.editTicketClicked}
                // handleClickDeleteModel={this.props.handleClickDeleteModel}
                // handleDeleteClose={this.handleDeleteClose}
                // deleteTicket={this.deleteTicket}
                // openDeleteModel={this.props.openDeleteModel}
                // deleteObject={this.props.deleteObject}
                // fromDashboard={this.state.fromDashboard}
                // onTicketExportClicked={this.onTicketExportClicked}
            />
        )
    }
}


const mapStateToProps = (state) => {
    return {
        user: state.authReducer.user,
        ticketsList: state.adminOrSuperAdminReducer.scheduleTicketList,
        isLoading: state.adminOrSuperAdminReducer.isLoading,
        isTicketPending: state.adminOrSuperAdminReducer.isTicketPending,
        deleteObject: state.adminOrSuperAdminReducer.deleteObject,
        openDeleteModel: state.adminOrSuperAdminReducer.openDeleteModel,
        ticket_no_of_rows: state.adminOrSuperAdminReducer.project_module_ticket_no_of_rows,
        categoriesList: state.adminOrSuperAdminReducer.categoriesList,
        ticketIndex: state.adminOrSuperAdminReducer.scheduleTicketIndex,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        resetObjects: () => dispatch(actions.resetObjects()),
        handleDeleteClose: () => dispatch(actions.handleDeleteClose()),
        deleteTicket: (id) => dispatch(actions.deleteTicket(id)),
        getScheduledTickets: (values, ticket_no_of_rows, page, sorted, filtered, isForm) => dispatch(actions.getScheduledTickets(values, ticket_no_of_rows, page, sorted, filtered, isForm)),
        previewTicketClicked: (ticket) => dispatch(actions.previewTicketClicked(ticket)),
        // openPopup: () => dispatch(actions.openPopup()),
        searchMyTicket: (values) => dispatch(actions.searchMyTicket(values)),
        reset: (scheduleSearchTicket) => dispatch(reset(scheduleSearchTicket)),  // requires form name
        editTicketClicked: (ticket) => dispatch(actions.editTicketClicked(ticket)),
        handleClickDeleteModel: (ticket) => dispatch(actions.handleClickDeleteModel(ticket)),
        updateCurrentDate: (date) => dispatch(actions.updateCurrentDate(date)),
        updateWeekMonthYear: (isWeek, isMonth, isYear) => dispatch(actions.updateWeekMonthYear(isWeek, isMonth, isYear)),
        projectModuleTicketPageClicked: (page) => dispatch(actions.projectModuleTicketPageClicked(page)),
        getCategories: (data) => dispatch(actions.getCategories(data)),
        onTicketExportClicked: (body) => dispatch(actions.onTicketExportClicked(body)),
        deleteScheduledTicket: (ticketId) => dispatch(actions.deleteScheduledTicket(ticketId)),
        previewSingleTicketClicked: (ticket, isForm) => dispatch(actions.previewSingleTicketClicked(ticket, isForm)),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(ProjectModuleListContainer);