import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../../redux/actions/index';
import { animateScroll as scroll } from 'react-scroll'
import InspectionScheduleHistory from '../../../component/Accounts/AccountUsers/InspectionScheduleHistory';

class InspectionScheduleHistoryContainer extends Component {

    constructor(props) {
        super(props);
    
        this.state = {
        }
    }

    componentDidMount() {
        scroll.scrollToTop();
    }

    componentDidUpdate() {
        this.props.scheduleHistoryPageClicked(0)
    }

    render() {
        return (
            <div>
                <InspectionScheduleHistory
                    {...this.props} 
                />                
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.authReducer.user,
        isLoading: state.adminOrSuperAdminReducer.isLoading,
        scheduleHistory_logList: state.adminOrSuperAdminReducer.scheduleHistory_logList,
        total_no_of_pages: state.adminOrSuperAdminReducer.scheduleHistory_totalPages,
        scheduleHistory_totalListCount: state.adminOrSuperAdminReducer.scheduleHistory_totalListCount,
        scheduleHistoryCurPage: state.adminOrSuperAdminReducer.scheduleHistoryCurPage,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        scheduleHistory: (params, accountId) => dispatch(actions.scheduleHistory(params, accountId)),
        scheduleHistoryPageClicked: (page) => dispatch(actions.scheduleHistoryPageClicked(page)),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(InspectionScheduleHistoryContainer);