import React from 'react';
import { Input } from '../../UI/InputElement/InputElement';
import { connect, useSelector } from 'react-redux';
import { associateUserCompanyValidation as validate } from '../../../../utility/validator/validator';
import { reduxForm, formValueSelector } from 'redux-form';
import isEmpty from '../../../../utility/IsEmptyValidator';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
const cloneDeep = require('clone-deep');


let AssociateCompanyModal = (props) => {
    const { handleSubmit, userId, pristine, reset, submitting, error } = props;
    const isFilterCompanyUsersPending = useSelector(state => state.adminOrSuperAdminReducer.isFilterCompanyUsersPending);

    let selected_compnies = [];
    const users = props.filterCompanyUsers.map(user => {
        return {
            value: user.id,
            label: user.email
        }
    });

    if (userId) {
        props.filterCompanyUsers.map(user => {
            if (user.id === parseInt(userId)) {
                selected_compnies = cloneDeep(user.companies);
            }
        });
    }

    const noOptionsMessage = (a, b) => {
        return 'User not found';
    }

    const closeCompanyModalClicked = () => {

        props.closeCompanyModalClicked();
    }


    return (
        <>
            <div className="modal fade performance_modal add_account associateUser lookup_user" id="associateUser" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h2 className="md_title" id="exampleModalLongTitle">Lookup a user</h2>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" >
                                <span aria-hidden="true"><img src="/images/icons/icn_cancel_white.png" alt="Icon" onClick={closeCompanyModalClicked} /></span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div class="row">
                                <div class="col-lg-12">
                                    <div className="quote_form ticket_form">
                                        <div className="addQuote_form">

                                            <div className="form-group">
                                                {props.filterCompanyUsers.length === 0 || isFilterCompanyUsersPending ?
                                                    <Skeleton height={30} width={450} />
                                                    :
                                                    <>
                                                        <label className="form_title">Email Address</label>
                                                        <Input name="email" noOptionsMessage={noOptionsMessage} type="inputSelect" options={users} />
                                                    </>
                                                }
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer border-0 text-center">
                            <div className="btn_block">
                                {props.filterCompanyUsers.length === 0 ?
                                    <Skeleton height={30} width={100} />
                                    :
                                    <button onClick={props.getCurrentCompany} disabled={props.initialValues.email ? props.initialValues.email.value ? false : true : true} type="button" className="btn cst_btn btn_danger" data-dismiss="modal" data-backdrop="static" data-keyboard="false" data-toggle="modal" data-target="#associateAccount">Next</button>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade performance_modal add_account associateUser associateAccount" id="associateAccount" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h2 className="md_title" id="exampleModalLongTitle">Associate Company</h2>
                            <button type="button" data-dismiss="modal" className="close" aria-label="Close" onClick={closeCompanyModalClicked}>
                                <span aria-hidden="true"><img src="/images/icons/icn_cancel_white.png" alt="Icon" /></span>
                            </button>
                        </div>
                        <form onSubmit={handleSubmit}>
                            <div className="modal-body">
                                <span style={{ color: '#DD2726', fontSize: '13px' }}>{props.companyErrorMessage}</span>
                                <div className="quote_form ticket_form">
                                    <div className="selec_style">
                                        <div className="items-collection">
                                            <div className="select_style_list select_style_list1">
                                                {props.company.name ?
                                                    <span className="items mr-3">
                                                        <span className="btn-group itemcontent">

                                                            <Input type="checkbox" h5Text={props.company.name} checkbox="selectStyle" name={`current_company[${props.company.id}]`} autoComplete="off" className="checkbox_modifier" />
                                                        </span>
                                                    </span>
                                                    :
                                                    <Skeleton height={30} width={100} />
                                                }
                                                {selected_compnies ? selected_compnies.map(sc => {
                                                    return (
                                                        <span className="items mr-3">
                                                            <span className="btn-group itemcontent">
                                                                <Input type="checkbox" h5Text={sc.name} checkbox="selectStyle" name={`selected_compnies[${sc.id}]`} autoComplete="off" className="checkbox_modifier" />
                                                            </span>
                                                        </span>
                                                    )
                                                }) : null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="modal-footer text-center">
                                <div className="btn_block">
                                    {props.company.name ?
                                        <>
                                            <button type="submit" disabled={props.popupLoading ? true : false} className={props.popupLoading ? "btn cst_btn btn_danger mr-2 btn-wait" : "btn cst_btn btn_danger mr-2"}>{props.popupLoading ? "" : "Submit"}</button>
                                            <button type="button" data-dismiss="modal" className={"btn cst_btn btn_secondary_outline"} onClick={closeCompanyModalClicked}>Cancel</button>
                                        </>
                                        :
                                        <>
                                            <Skeleton height={30} width={100} />
                                            <Skeleton height={30} width={100} /> </>
                                    }

                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    let current_company = [];
    let selected_compnies = [];
    let email = state.form.associateCompanyModal ? state.form.associateCompanyModal.values.email ? state.form.associateCompanyModal.values.email : null : null;
    let userId = state.form.associateCompanyModal ? state.form.associateCompanyModal.values.email ? state.form.associateCompanyModal.values.email.value : null : null;

    if (!isEmpty(state.adminOrSuperAdminReducer.company)) {
        current_company[`${state.adminOrSuperAdminReducer.company.id}`] = true
    }

    if (userId && state.adminOrSuperAdminReducer.filterCompanyUsers) {
        state.adminOrSuperAdminReducer.filterCompanyUsers.map(user => {
            if (user.id === parseInt(userId) && user.companies) {
                user.companies.map(sc => {
                    selected_compnies[`${sc.id}`] = true
                })
            }
        });
    }
    return {
        initialValues: { email, current_company, selected_compnies }
    }
}

AssociateCompanyModal = reduxForm({
    form: 'associateCompanyModal',
    enableReinitialize: true,
    validate,
})(AssociateCompanyModal);

const selector = formValueSelector('associateCompanyModal') // <-- same as form name
AssociateCompanyModal = connect(state => {

    const userId = selector(state, 'email.value')
    return {
        userId: `${userId || ''}`
    }
})(AssociateCompanyModal)

AssociateCompanyModal = connect(mapStateToProps)(AssociateCompanyModal);

export default AssociateCompanyModal;