import React, { Component } from 'react';
import { routes } from '../../../../../utility/constants/constants';
import * as actions from '../../../../../redux/actions/index';
import * as actionsInventory from '../../../../../redux/actions/FormAuditModAction/InventoryAction/InventoryAction';
import { connect } from 'react-redux';
import { reset } from 'redux-form';
import { animateScroll as scroll} from 'react-scroll'
import storage from '../../../../../utility/storage';
import { convertDateToDifferentTZ } from '../../../../../utility/utility';
import MonthlyReportLog from '../../../../component/FormAuditModule/Inventory/MonthlyReport/monthlyReport';


var moment = require('moment');
const cloneDeep = require('clone-deep');

class MonthlyUsageReportContainer extends Component {

    constructor(props) {
        super(props);

        this.state = {
            
        }
    }

    componentDidMount() {
        scroll.scrollToTop();
        this.props.getAllJob()
        this.props.getAllCustomer()
    }

    
    
    componentDidUpdate(prevProps, prevState) {
        const user = storage.get('user');
        if(user) {
            if(prevProps.user.selected_mode !== user.selected_mode) {
                this.props.scheduledTaskListPageClicked(0)
            }
        }
    }

    searchMonthlyReport=(values)=>{
        
        let month=(values.year.getMonth() +1 )/  10  < 1 ? `0${values.year.getMonth() +1}` : values.year.getMonth() +1
        let yearFormated=`${values.year.getFullYear()
        }-${month}`
        const params={
            //customer:values.customer.value,
            accountId:values.job.value,
            targetMonth:yearFormated
        }
        
        this.setState({
            ...values
        })
        
        this.props.getAllMonthlyReport(params)
        
       const newParams={account_id:params.accountId,year:params.targetMonth.split("-")[0]}
        
        this.props.getMonthlySummaryFormData(newParams,+params.targetMonth.split("-")[1])
    }

    getSelectedJobsCompany=(accountId)=>{
        
        let params={
            accountId,
            account:true
        }
        this.props.getAllCustomer(params)
    }

    getSelectedCompanyJobs=(companyId)=>{
        
        let params={
            address:true,
            companyId
        }
        this.props.getJobRelatedToCompany(params)
    }

    // resetClicked = () => {
    //     const page = 1
    //     const limit = this.props.scheduledTaskList_no_of_rows
    //     this.props.reset('searchScheduledTask');
    //     this.props.scheduledTaskListPageClicked(0)
    //     this.props.getScheduledTask({page,limit})

    // }

    // searchInventoryItemsListClicked = (value) => {
    //     const values = cloneDeep(value)
    //     let accountId =  null;
    //     let taskName = null;
    //     let companyName = null;
    //     let accountName = null;
    //     let from = null;
    //     let to = null;
    
    //     const page = 1
    //     const limit = this.props.scheduledTaskList_no_of_rows
    //     if(values.id) {
    //         values.id = values.id.trim()
    //         accountId = values.id
    //     }
    //   if(values.name) {
    //       values.name = values.name.trim()
    //       taskName = values.name
    //   }
    //   if(values.company) {
    //     values.company = values.company.trim()
    //     companyName = values.company
    //   }
    //   if(values.job) {
    //     values.job = values.job.trim()
    //     accountName =  values.job 
    //     }  
    //   if(values.fromDate) {
    //     values.fromDate = convertDateToDifferentTZ(values.fromDate, 'from');
    //     from = values.fromDate.split('T')[0];
    //   }  
    //   if(values.toDate) {
    //     values.toDate = convertDateToDifferentTZ(values.toDate, 'to');
    //     to = values.toDate.split('T')[0];
    //   }  
     
    //     this.props.getScheduledTask({page,limit,accountId,taskName,companyName,accountName,from,to}, values);
    // }
    newTicketClicked = () => {
        this.props.history.push(routes.TRAINING_LOG_NEW);
    }
    
    render() {
        return (
            <MonthlyReportLog
            {...this.props}
            isLoading={this.props.isLoading}
            monthly_report_list={this.props.monthly_report_list ? this.props.monthly_report_list : null}
            newTicketClicked={this.newTicketClicked}
            onSubmit={this.searchMonthlyReport}
            state={this.state}
            getSelectedJobsCompany={this.getSelectedJobsCompany}
            getSelectedCompanyJobs={this.getSelectedCompanyJobs}
            />
        )
    }
}




const mapStateToProps = (state) => {
    
    return {
        user: state.authReducer.user,
        isLoading: state.FormAuditModReducer.isLoading,
        monthly_report_list: state.InventoryModuleReducer.monthly_report_list,
        jobList:state.FormAuditModReducer.jobList,
        customersList:state.FormAuditModReducer.customersList
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        reset: (searchInventoryItemsList) => dispatch(reset(searchInventoryItemsList)),  // requires form name
        getAllMonthlyReport: (params) => dispatch(actionsInventory.getAllMonthlyReport(params)),
        TrainingLogPageClicked: (params) => dispatch(actions.TrainingLogPageClicked(params)),
        getAllJob: (page) => dispatch(actions.getAllJobNew()),
        getAllCustomer:(params)=> dispatch(actions.getCustomerInfos(params)),
        getMonthlySummaryFormData:(params,month)=>dispatch(actions.getMonthlySummaryFormData(params,month)),
        handleReset:()=>dispatch(actions.handleResetSummaryForm()),
        getJobRelatedToCompany:(params)=>dispatch(actions.getJobRelatedToCompany(params))
        
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(MonthlyUsageReportContainer);