import React from 'react';
import { Input } from '../UI/InputElement/InputElement';
import { changePassword as validate } from '../../../utility/validator/validator';
import { reduxForm } from 'redux-form';

const ChangePassword = (props) => {
    const { handleSubmit, pristine, reset, submitting, error } = props;
    return (
        <div id="password" className="tab-pane password_tab fade cst_tab_content tab-pane fade show active">
            <form className="form_content" onSubmit={handleSubmit} data-test="change_pwd_form">
                <div className="row">
                    <div className="col-lg-6 offset-lg-3">
                        <div className="form-group">
                            <label for="password" className="label_modifier">Current Password</label>
                            <Input dataTest="current_pwd" type="password" autocomplete="new-password" className="form-control input-modifier" placeholder="" name="user[current_password]" />
                        </div>
                        <div className="form-group">
                            <label for="password" className="label_modifier">New Password</label>
                            <Input dataTest="new_pwd" type="password" autocomplete="new-password" className="form-control input-modifier" placeholder="" name="user[password]" />
                        </div>
                        <div className="form-group">
                            <label for="password" className="label_modifier">Confirm Password</label>
                            <Input dataTest="confirm_pwd" type="password" autocomplete="new-password" className="form-control input-modifier" placeholder="" name="user[password_confirmation]" />
                        </div>
                    </div>
                </div>

                <div className="clearfix"></div>
                <div className="modal-footer">
                    <button data-test="submit_button" type="submit" disabled={props.isLoading} className={props.isLoading ? "btn cst_btn btn_danger mr-2 btn-wait" : "btn cst_btn btn_danger mr-2"}>{props.isLoading ? "" : "Save"}</button>
                    <button data-test="cancel_button" onClick={props.closeProfileModel} type="button" className="btn btn-outline-secondary cst_btn" data-dismiss="modal">Close</button>
                </div>
            </form>
        </div>
    )
}


export default reduxForm({
    form: 'changePasswordForm',
    validate
})(ChangePassword);