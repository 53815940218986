import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { getFormValues, reduxForm } from 'redux-form';
import { Input } from '../../UI/InputElement/InputElement';
import isEmpty from '../../../../utility/IsEmptyValidator';
import SignatureCanvas from 'react-signature-canvas'
import { makeFirstLetterCapital } from '../../../../utility/utility';
import cloneDeep from 'clone-deep';
import { DateTimeField } from '../../UI/DateTimeField/DateTimeField';
import { tzDateConverterTool_V3, tzTimeConversionTool_V1 } from '../../../../utility/TimezoneOperations/timezoneUtility';
import TimecardCorrectionFormSkeleton from '../TimeCardCorrectionModuleComponent/TimecardCorrectionFormSkeleton';
import storage from '../../../../utility/storage';

let MealPenaltyGenerateForm = (props) => {

    const { handleSubmit, isLoading, isTriggerLoader, singleShiftScheduleData, mealPenaltyBreaksInfo } = props;
    let currentUser = storage.get('user');

    if (isTriggerLoader) {
        return (
            <TimecardCorrectionFormSkeleton />
        )
    } else {
        return (
            <>
                <main className="app-content wraper_content user_newQuote add_account_modal add_New_Accont add_newAccount">
                    <div className="inspection_content_sec appcontent_Inner">
                        <div className="container-fluids">
                            <div className="quote_form ticket_form" style={{ maxWidth: '900px' }}>

                                <div className="row">
                                    <h3 className="col-sm-12 text-center card-title text-color-iridium swapUserName mt-0 mb-0 md_title" style={{ fontSize: '35px' }}> Hi {currentUser && currentUser.first_name + ' ' + currentUser.last_name} ! </h3>
                                    <h5 className="col-sm-12 text-center ml-lg-0 order-1 order-lg-2 text-muted mt-3"> Could you please explain why you haven't taken your meal break? </h5>
                                </div>

                                <form className="form_content" onSubmit={handleSubmit} >
                                    <div className="addQuote_form mb-3">
                                        <div className="cst_tab_content">

                                            {mealPenaltyBreaksInfo?.mealPenaltyBreaks?.map((time, index) => (
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label className="form_title fw-bold"> Expected Meal Start </label>
                                                            <Input name={`mealPenalty[breakIn][${index}][mealIn]`} type="text" readOnly={true} className="form-control input-modifier" id="" placeholder="Type here" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label className="form_title fw-bold"> Expected Meal Out </label>
                                                            <Input name={`mealPenalty[breakOut][${index}][mealOut]`} type="text" readOnly={true} className="form-control input-modifier" id="" placeholder="Type here" />
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}

                                            <div className="row mt-3 mb-3">
                                                <div className="col-md-12 monthly_tab">
                                                    <div className="form-group box_input">
                                                        <label className="form_title fw-bold">
                                                            *** For a clock Out error, the employee must complete the attestation question***
                                                        </label>
                                                        <label className="form_title fw-bold">
                                                            1. I certify I was able to take my meal and rest break(s) today in accordance with state law, I performed my work today without sustaining any work injury and that my work hours are complete and accurate. (click the applicable answer)
                                                        </label>
                                                        <Input type="radio" labelClass="custom-control-label" forLable={"customTrue"} value={"true"} label={"Yes"} className="custom-control-input" id="customTrue" name="mealPenalty[clkAgreement]" />
                                                        <Input type="radio" labelClass="custom-control-label" forLable={"customFalse"} value={"false"} label={"No"} className="custom-control-input" id="customFalse" name="mealPenalty[clkAgreement]" />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label className="form_title fw-bold"> Missed Reason <sup className="cst_sup_txt text_danger">*</sup> </label>
                                                        <Input name="mealPenalty[missedReason]" type="text" className="form-control input-modifier" id="" placeholder="Please enter your reason" />
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                    <div className="modal-footer">
                                        {singleShiftScheduleData.id && <button data-test="submit_button" type="submit" disabled={isLoading} className={isLoading ? "btn cst_btn btn_danger btn-wait w-25" : "btn cst_btn btn_danger w-25"} >{isLoading ? "" : "Submit"}</button>}
                                    </div>
                                </form>

                            </div>
                        </div>
                    </div>
                </main>
            </>
        );
    }
};

const mapStateToProps = (state, props) => {

    let mealPenalty = {};

    if (isEmpty(state.TimeTrackerModReducer.singleShiftScheduleData)) {

        mealPenalty = {
        }

    } else {

        let catchedFormValues = state?.form?.mealPenaltyGeneratorForm?.values?.mealPenalty;
        const mealPenaltyBreaks = cloneDeep(state.TimeTrackerModReducer.mealPenaltyBreaksInfo?.mealPenaltyBreaks);
        const shiftSlot = cloneDeep(state.TimeTrackerModReducer.mealPenaltyBreaksInfo?.shiftSlot);

        let breakInSlots = mealPenaltyBreaks?.map((mealBreak) => {
            return { id: mealBreak?.id, mealIn: mealBreak?.expected_start ? tzTimeConversionTool_V1(mealBreak?.expected_start.split('T')[1].split('.')[0], mealBreak?.expected_start.split('T')[0]) : '' }
        });

        let breakOutSlots = mealPenaltyBreaks?.map((mealBreak) => {
            return { id: mealBreak?.id, mealOut: mealBreak?.expected_end ? tzTimeConversionTool_V1(mealBreak?.expected_end.split('T')[1].split('.')[0], mealBreak?.expected_end.split('T')[0]) : '' }
        });

        mealPenalty = {
            breakIn: breakInSlots?.length > 0 ? breakInSlots : [],
            breakOut: breakOutSlots?.length > 0 ? breakOutSlots : [],
            errorDate: shiftSlot?.date ? tzDateConverterTool_V3(shiftSlot.date) : '',
            clkAgreement: 'true',
        }

    }

    return {
        initialValues: { mealPenalty },
        formStates: getFormValues('mealPenaltyGeneratorForm')(state)
    }

}

MealPenaltyGenerateForm = reduxForm({
    form: 'mealPenaltyGeneratorForm',
    // validate,
    enableReinitialize: true
})(MealPenaltyGenerateForm);

MealPenaltyGenerateForm = connect(mapStateToProps)(MealPenaltyGenerateForm)

export default MealPenaltyGenerateForm;