import { projectModuleActionTypes } from "../../../actions/FormAuditModAction/ProjectModuleAction/ProjectModuleTypes";


export const initialState = {
    dataGenerated: false,
    isLoading: false,

    isRestroomPortering: false,
    restroomPortering_list: [],
    restroomPortering_total_pages: 1,
    restroomPortering_no_of_rows: 10,
    restroomPorteringCurPage: 0,
    total_restroomPortering_count: '',
};

const updateObject = (oldState, updatedProps) => {
    return {
        ...oldState,
        ...updatedProps,
    };
};

export const projectModuleReducer = (state = initialState, action) => {
    switch (action.type) {
        case projectModuleActionTypes.GET_ALL_DEEP_CLEAN_CHART_PENDING:
            return updateObject(state, {
                isLoading: true,
                isRestroomPortering: true,
                restroomPortering_list: [],
            });
        case projectModuleActionTypes.GET_ALL_DEEP_CLEAN_CHART_FULFILLED:
            return updateObject(state, {
                isLoading: false,
                dataGenerated: false,
                isRestroomPortering: false,
                restroomPortering_list: action.payload.restroomPortering_list,
                restroomPortering_total_pages: action.payload.restroomPortering_total_pages,
                restroomPortering_no_of_rows: action.payload.restroomPortering_no_of_rows,
                restroomPorteringCurPage: action.payload.restroomPorteringCurPage,
                total_restroomPortering_count: action.payload.total_restroomPortering_count,
            });

            case projectModuleActionTypes.DEEP_CLEAN_CHART_PAGE_CLICKED:
            return updateObject(state, { restroomPorteringCurPage: action.payload });



        default:
            return state;
    }
};
