import React, { Component } from 'react';
import * as actions from '../../../../../redux/actions/index';
import { connect } from 'react-redux';
import { animateScroll as scroll } from 'react-scroll'
import PreviewJobAssociationComponent from '../../../../component/PCTComponent/JobAssociationComponent/PreviewJobAssociationComponent/PreviewJobAssociationComponent';

class PreviewJobAssociationContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoad: false,
        }

    }

    componentDidMount() {

        if (this.props && this.props.match && this.props.match.params && this.props.match.params.id) {
            this.props.getSingleJobAssociation(this.props.match.params.id);
        }

        scroll.scrollToTop();

    }

    render() {
        return (
            <div>

                <PreviewJobAssociationComponent
                    {...this.props}
                />

            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isLoading: state.JobAssociateReducer.isLoading,
        singleJobAssociation: state.JobAssociateReducer.singleJobAssociation,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getSingleJobAssociation: (id) => dispatch(actions.getSingleJobAssociation(id)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PreviewJobAssociationContainer);