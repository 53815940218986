import React, { Component } from 'react';
import { routes } from '../../../../../utility/constants/constants';
import * as actions from '../../../../../redux/actions/index';
import { connect } from 'react-redux';
import { reset } from 'redux-form';
import { animateScroll as scroll} from 'react-scroll'
import storage from '../../../../../utility/storage';
import ProjectModule from '../../../../component/FormAuditModule/ProjectModule/DeepCleanChartCompnent/projectModule';

var moment = require('moment');
const cloneDeep = require('clone-deep');

class ProjectModuleContainer extends Component {

    constructor(props) {
        super(props);

        this.state = {
            
        }
    }

    componentDidMount() {
        scroll.scrollToTop();
    }

    componentDidUpdate(prevProps, prevState) {
        const user = storage.get('user');
        if(user) {
            if(prevProps.user.selected_mode !== user.selected_mode) {
                this.props.scheduledTaskListPageClicked(0)
            }
        }
    }

    resetClicked = () => {
        const page = 1
        const limit = this.props.restroomPortering_no_of_rows
        this.props.reset('searchDeepClean');
        this.props.deepCleanChartPageClicked(0)
        this.props.getAllDeepCleanChart({page,limit})

    }

    searchDeepChart = (value) => {
        const values = cloneDeep(value)
        let year =  null;
    
        const page = 1
        const limit = this.props.restroomPortering_no_of_rows
        if(values.year) {
            year = values.year?.year
        }
     
        this.props.getAllDeepCleanChart({page,limit,year}, values);
    }

    newTicketClicked = () => {
        this.props.history.push(routes.NEW_PROJECT_MODULE);
    }
         
    render() {
        return (
            <ProjectModule
            {...this.props}
            onSubmit={this.searchDeepChart}
            resetClicked={this.resetClicked}
            isLoading={this.props.isLoading}
            restroomPortering_list={this.props.restroomPortering_list ? this.props.restroomPortering_list : []}
            newTicketClicked={this.newTicketClicked}
                
            />
        )
    }
}




const mapStateToProps = (state) => {
    return {
        user: state.authReducer.user,
        isLoading: state.projectModuleReducer.isLoading,
        restroomPortering_list: state.projectModuleReducer.restroomPortering_list,
        restroomPortering_no_of_rows: state.projectModuleReducer.restroomPortering_no_of_rows,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        reset: (searchInventoryItemsList) => dispatch(reset(searchInventoryItemsList)),  // requires form name
        getAllDeepCleanChart: (page) => dispatch(actions.getAllDeepCleanChart(page)),
        deepCleanChartPageClicked: (params) => dispatch(actions.deepCleanChartPageClicked(params)),
        reset: (scheduleSearchTicket) => dispatch(reset(scheduleSearchTicket)), 
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(ProjectModuleContainer);