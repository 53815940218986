import React from 'react';
import ReactTable from 'react-table-v6';
import '../../../../styles/custom.css';
import { capitalAndConvertHyphen, CustomNoDataComponent, makeFirstLetterCapital } from '../../../../utility/utility';
import { FaArrowDown, FaArrowUp } from "react-icons/fa";
import 'react-table-v6/react-table.css';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import { MagicSpinner, PushSpinner, SwishSpinner, WaveSpinner, ClapSpinner } from 'react-spinners-kit';

export const AreaTypes = (props) => {
  const sortAreaTypes = (array) => {
    return array.sort((a, b) => a.name.localeCompare(b.name))
  }
  // const data = sortAreaTypes(props.areaTypes).map(areaType => {
  //   return {
  //     name: areaType.name,
  //     actions: <>
  //     <span className="mr_20 account_edit_icn" data-toggle="modal" data-target="#addAreaType" data-backdrop="static" data-keyboard="false" onClick={() => props.editAreaTypeClicked(areaType)}><i class="fa fa-pencil" aria-hidden="true"></i></span>
  //     </>
  //   }
  // })

  // const columns = [
  //   {
  //     Header: <><span>Area Type </span><FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
  //     accessor: 'name', // String-based value accessors!,
  //     show: props.areaTypes.length !== 0,
  //   }, {
  //     Header: '',
  //     accessor: 'actions',
  //     width: 150,
  //     filterable: false,
  //     sort: false
  //     // show: false
  //   }
  // ]

  return (
      <>
      <div className="row">
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 text-right accounts_btn_wrap">
          <a href="JavaScript:void(0);" className="btn btn-secondary cst_btn" data-toggle="modal" data-target="#addAreaType" data-backdrop="static" data-keyboard="false" onClick={props.openPopup}><i className="fa fa-plus icn_plus" aria-hidden="true"></i>New Area Type</a>
        </div>
      </div>
      {props.areaTypes ? <div className="quotes_table_content table-responsive performance_modal add_account associateUser pegAssureTab">
                    <div className="table quotes_table user_table_contnet user_react_table">
                    <div class="col-lg-12">
                        <div class="quote_form user_quota_form ticket_form">
                            <div class="addQuote_form">
                            <div class="form-group">
                                <div class="form-group checkbox_cst_grid">
                                {props.areaTypes && sortAreaTypes(props.areaTypes).map((a, i) => (
                            <div class="checkbox_block radius_checkbox">
                                <div class="custom-checkbox gridStyle">
                                <div class="gridInlineBlc">
                                    <label class="f12 grdTitle">{makeFirstLetterCapital(a.name)} </label>
                                    <div class="icnBlc">
                                    <span className="mr_20 account_edit_icn" data-toggle="modal" data-target="#addAreaType" data-backdrop="static" data-keyboard="false" onClick={() => props.editAreaTypeClicked(a)}><i class="fa fa-pencil" aria-hidden="true"></i></span>
                                    </div>
                                </div>
                                </div>
                                </div>
                            )) }
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div> : <div className="loader_btn_block spinner">
                            <ClapSpinner backColor="#89073c" size={40} frontColor="#89073c8a" />
                        </div>}

      <Dialog
          open={props.openDeleteModel}
          onClose={props.handleClose}
          aria-labelledby="form-dialog-title"
          >
        <DialogTitle id="form-dialog-title" style={{ fontSize: '13', color: '#06080f' }}>
          <h3 style={{ color: '#ab385e' }}>Are you sure?</h3>
        </DialogTitle>

        <DialogActions>
          <button style={{ minHeight: '36px', minWidth: '80px' }} onClick={() => props.deleteAreaType(props.deleteObject.id)} disabled={props.isLoading} className={props.isLoading ? "btn cst_btn btn_danger mr-2 btn-wait" : "btn cst_btn btn_danger mr-2"} id="delete" type="delete">{props.isLoading ? "" : "Delete"}</button>
          <button style={{ minHeight: '36px', minWidth: '80px' }} onClick={props.handleDeleteClose} className={"btn cst_btn btn-outline-secondary"} id="cancel" type="cancel">Cancel</button>
        </DialogActions>
      </Dialog>
      </>
  )
}

export default AreaTypes;