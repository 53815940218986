import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

const Field = (h, w) => {
    return (<Skeleton height={h} width={w} />)
}

const ShiftFormSkeleton = (props) => {
    return (
        <main className="app-content wraper_content user_newQuote add_account_modal add_New_Accont add_newAccount">
            <div className="inspection_content_sec appcontent_Inner">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12 text-center"> <h4 className="section_title">{Field(30, 200)}</h4> </div>
                    </div>
                    <form>
                        <div className="quote_form ticket_form">
                            <div className="addQuote_form">
                                <div className="cst_tab_content">

                                    <div className="row">
                                        <div className="col-md-6" style={{ marginTop: '15px' }}> <div className="form-group"> {Field(30)} </div> </div>
                                        <div className="col-md-6" style={{ marginTop: '15px' }}> <div className="form-group"> {Field(30)} </div> </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-6" style={{ marginTop: '15px' }}> <div className="form-group"> {Field(30)} </div> </div>
                                        <div className="col-md-6" style={{ marginTop: '15px' }}> <div className="form-group"> {Field(30)} </div> </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-6" style={{ marginTop: '15px' }}> <div className="form-group"> {Field(30)} </div> </div>
                                        <div className="col-md-6" style={{ marginTop: '15px' }}> <div className="form-group"> {Field(30)} </div> </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-6" style={{ marginTop: '15px' }}> <div className="form-group"> {Field(30)} </div> </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-6" style={{ marginTop: '15px' }}> <div className="form-group"> {Field(30)} </div> </div>
                                        <div className="col-md-6" style={{ marginTop: '15px' }}> <div className="form-group"> {Field(30)} </div> </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-6" style={{ marginTop: '15px' }}> <div className="form-group"> {Field(30)} </div> </div>
                                        <div className="col-md-6" style={{ marginTop: '15px' }}> <div className="form-group"> {Field(30)} </div> </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-6" style={{ marginTop: '15px' }}> <div className="form-group"> {Field(30)} </div> </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-4 mr-2 ml-2" style={{ marginTop: '15px' }}> <div className="form-group"> {Field(30)} </div> </div>
                                        <div className="col-md-4 ml-2 mr-2" style={{ marginTop: '15px' }}> <div className="form-group"> {Field(30)} </div> </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </main>
    )
}

export default ShiftFormSkeleton;