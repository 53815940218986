import { buildingProfileActionTypes } from "../../../actions/FormAuditModAction/BuildingProfileAction/BuildingProfileTypes";

export const initialState = {
    isLoading: false,
    isEditLog: false,
    isUpdatejobCard: false,
    jobCardDetails: null,
    allJobCards: [],
    total_jobcard_count: 0,
    jobcard_list: [],
    jobcard_total_pages: 0,
    jobcard_no_of_rows: 10,
    jobcardCurPage: 0,
    isjobCards: false,

    isBuildingProfile: false,
    buildingProfile_list: [],
    buildingProfile_total_pages: 1,
    buildingProfile_no_of_rows: 10,
    buildingProfileCurPage: 0,
    total_buildingProfile_count: '',
};

const updateObject = (oldState, updatedProps) => {
    return {
        ...oldState,
        ...updatedProps,
    };
};

export const BuildingProfileReducer = (state = initialState, action) => {
    switch (action.type) {

        case buildingProfileActionTypes.ADD_SINGLE_JOB_CARD_PENDING:
            return updateObject(state, {
                isLoading: true,
                isEditLog: false,
                isUpdatejobCard: false,
                restroomDetails: null,
            });

        case buildingProfileActionTypes.ADD_SINGLE_JOB_CARD_FULFILLED:

            return updateObject(state, {
                isLoading: false,
                isEditLog: false,
                isUpdatejobCard: false,

            });


        case buildingProfileActionTypes.ADD_SINGLE_JOB_CARD_REJECTED:

            return updateObject(state, {
                isLoading: false,
                isEditLog: false,
                isUpdatejobCard: false,

            });


        case buildingProfileActionTypes.GET_SINGLE_JOB_CARD_PENDING:

            return updateObject(state, {
                isLoading: false,
                isUpdatejobCard: true,
                jobCardDetails: null
            });

        case buildingProfileActionTypes.GET_SINGLE_JOB_CARD_FULFILLED:

            return updateObject(state, {
                isLoading: false,
                isUpdatejobCard: false,
                jobCardDetails: action.payload,
                allJobCards: []
            });


        // case buildingProfileActionTypes.GET_ALL_JOB_CARD_PENDING:

        // return updateObject(state, {
        //     isLoading: false,
        //     isUpdatejobCard: false,
        //     jobCardDetails:action.payload
        // });

        // case buildingProfileActionTypes.GET_ALL_JOB_CARD_FULFILLED:

        // return updateObject(state, {
        //     allJobCards:action.payload

        // });

        case buildingProfileActionTypes.GET_ALL_JOB_CARD_PENDING:
            return updateObject(state, {
                isLoading: true,
                isjobCards: true,
                jobcard_list: [],
            });
        case buildingProfileActionTypes.GET_ALL_JOB_CARD_FULFILLED:

            return updateObject(state, {
                isLoading: false,
                dataGenerated: false,
                isjobCards: false,
                jobcard_list: action.payload.jobcard_list,
                jobcard_total_pages: action.payload.jobcard_total_pages,
                jobcard_no_of_rows: action.payload.jobcard_no_of_rows,
                jobcardCurPage: action.payload.jobcardCurPage,
                total_jobcard_count: action.payload.total_jobcard_count,
            });

        case buildingProfileActionTypes.EDIT_SINGLE_JOB_CARD_PENDING:
            return updateObject(state, {
                isLoading: true,
                isEditLog: false,
                isUpdatejobCard: false,
                restroomDetails: null,
            });

        case buildingProfileActionTypes.EDIT_SINGLE_JOB_CARD_FULFILLED:

            return updateObject(state, {
                isLoading: false,
                isEditLog: false,
                isUpdatejobCard: false,

            });


        case buildingProfileActionTypes.EDIT_SINGLE_JOB_CARD_REJECTED:

            return updateObject(state, {
                isLoading: false,
                isEditLog: false,
                isUpdatejobCard: false,

            });


        case buildingProfileActionTypes.GET_SINGLE_BUILDING_PROFILE_PENDING:

            return updateObject(state, {
                isLoading: true,
                isUpdateBuildingProfile: true,
                buildingProfileDetails: null
            });

        case buildingProfileActionTypes.GET_SINGLE_BUILDING_PROFILE_FULFILLED:

            return updateObject(state, {
                isLoading: false,
                isUpdateBuildingProfile: false,
                buildingProfileDetails: action.payload,
                allJobCards: []
            });


        case buildingProfileActionTypes.ADD_SINGLE_BUILDING_PROFILE_PENDING:
            return updateObject(state, {
                isLoading: true,
            })

        case buildingProfileActionTypes.ADD_SINGLE_BUILDING_PROFILE_FULFILLED:
            return updateObject(state, {
                isLoading: false,
            })
        case buildingProfileActionTypes.ADD_SINGLE_BUILDING_PROFILE_REJECTED:
            return updateObject(state, {
                isLoading: false,
            })


        case buildingProfileActionTypes.GET_ALL_BUILDING_PROFILE_PENDING:
            return updateObject(state, {
                isLoading: true,
                isBuildingProfile: true,
                buildingProfile_list: [],
            });
        case buildingProfileActionTypes.GET_ALL_BUILDING_PROFILE_FULFILLED:
            return updateObject(state, {
                isLoading: false,
                dataGenerated: false,
                isBuildingProfile: false,
                buildingProfile_list: action.payload.buildingProfile_list,
                buildingProfile_total_pages: action.payload.buildingProfile_total_pages,
                buildingProfile_no_of_rows: action.payload.buildingProfile_no_of_rows,
                buildingProfileCurPage: action.payload.buildingProfileCurPage,
                total_buildingProfile_count: action.payload.total_buildingProfile_count,
            });

        case buildingProfileActionTypes.BUILDING_PROFILE_PAGE_CLICKED:
            return updateObject(state, { buildingProfileCurPage: action.payload });

        case buildingProfileActionTypes.EDIT_SINGLE_BUILDING_PROFILE_PENDING:
            return updateObject(state, {
                isLoading: true,
            })

        case buildingProfileActionTypes.EDIT_SINGLE_BUILDING_PROFILE_FULFILLED:
            return updateObject(state, {
                isLoading: false,
            })
        case buildingProfileActionTypes.EDIT_SINGLE_BUILDING_PROFILE_REJECTED:
            return updateObject(state, {
                isLoading: false,
            })


        default:
            return state;
    }
};
