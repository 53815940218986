import React from 'react';
import { CHIReviews } from '../../../utility/constants/constants';
import storage from '../../../utility/storage';

const CHIReviewStep2 = (props) => {
    const token = storage.get('token');

    return (
        <body className="dashboard_page_body">
            <main className="report_wrapper web_view">
                <section className="chi_content_block appcontent_Inner">
                    {/* <!-- web modals --> */}
                    {token ? <div className="web_modal" style={{marginLeft: props.chisurveyStarted && props.chisurveyStarted == true ? '0px' : '25%', marginTop: '40px'}}>
                        <div className="web-modal-content web_two">
                        <h4 className="modal_h4">Step 2 of 3</h4>
                            {/* <h4 className="modal_h4"><a href="javascript:void(0);" className="back_arrow"><img src="/images/icons/expand_more.png" alt="Arrow" /></a>Step 2 of 3</h4> */}
                            <div className="web_inn_container">
                                <figure className="web_logo">
                                    <img src="/images/icons/web_logo.png" alt="Pegasus" />
                                </figure>
                                <div className="web_inn_con">
                                    <h3 className="web_h3 text-center">Use this space to provide additional feedback or general comments about your experience with Pegasus (optional) </h3>
                                    <div className="post_comment">
                                        <div className="form-group">
                                            {/* <label className="label_modifier">Post your comments</label> */}
                                            <textarea onChange={props.changeReviewText} value={props.feed_back} className="form-control textarea_modifier" id="exampleFormControlTextarea1" rows="8" placeholder="Type here" />
                                        </div>
                                        <div className="web_btn_block">
                                            <button onClick={() => props.submitCHIReview(CHIReviews.chiReview2)} type="button"
                                                className={props.chi_review_loading ? "btn btn_danger_dark cst_btn btn_load btn-wait" : "btn btn_danger_dark cst_btn"}>{props.chi_review_loading ? "" : "Next"}</button>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div> : <div className="web_modal">
                        <div className="web-modal-content web_two" style={{ marginTop: '40px'}}>
                        <h4 className="modal_h4">Step 2 of 3</h4>
                            {/* <h4 className="modal_h4"><a href="javascript:void(0);" className="back_arrow"><img src="/images/icons/expand_more.png" alt="Arrow" /></a>Step 2 of 3</h4> */}
                            <div className="web_inn_container">
                                <figure className="web_logo">
                                    <img src="/images/icons/web_logo.png" alt="Pegasus" />
                                </figure>
                                <div className="web_inn_con">
                                    <h3 className="web_h3 text-center">Please tell us why you gave us a score of {props.activeReview}.</h3>
                                    <div className="post_comment">
                                        <div className="form-group">
                                            <label className="label_modifier">Post your comments</label>
                                            <textarea onChange={props.changeReviewText} value={props.feed_back} className="form-control textarea_modifier" id="exampleFormControlTextarea1" rows="8" placeholder="Type here" />
                                        </div>
                                        <div className="web_btn_block">
                                            <button disabled={!props.feed_back || props.feed_back.length === 0} onClick={() => props.submitCHIReview(CHIReviews.chiReview2)} type="button"
                                                className={props.chi_review_loading ? "btn btn_danger_dark cst_btn btn_load btn-wait" : "btn btn_danger_dark cst_btn"}>{props.chi_review_loading ? "" : "Next"}</button>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>}
                </section>
            </main>
        </body >
    )
}

export default CHIReviewStep2;