import React from 'react';
import ReactDatePicker from 'react-datepicker';
import Select from 'react-select';
import { components } from 'react-select';
import DatePicker from 'react-multi-date-picker'
import Calender from 'react-multi-date-picker/plugins/date_panel'
import ReactTimepicker from 'react-datetime'  
//import CustomSelectValueContainer from './customSelectValueContainer';
// type FloatingInputFieldProps = {
//   floatingLabelClasses?: string,
//   formControlClasses?: string,
//   formControlProps?: {},
//   formGroupClasses?: string,
//   label: string,
//   placeholder?: string
//   required?: boolean,
//   step?: string,
//   type?: string,
//   validationErrorMessage?: string,
//   value?: string
// }

const { ValueContainer, Placeholder } = components;
const current = new Date()


const FloatingInputField = (props) => {

  const placeholder = props.placeholder ? props.placeholder : props.label;
  const type = props.type ? props.type : 'text';

  const { formControlProps, formControlClasses, step, label, validationErrorMessage, labelClasses,isStar, ...remainProps } = props

  return (
    <>
      
        {label ? <label htmlFor={props.id} class="form-label">{label ? label : ''}{isStar ? <sup className="cst_sup_txt text_danger">*</sup> : null}</label> : ""}
        <div style={{ position: 'relative', display: "flex" }}>
        <input
          value={props?.value}
          required={props.required}
          className={formControlClasses}
          type={type}
          placeholder={placeholder}
          disabled={Boolean(props?.disabled)}
          {...remainProps}
          {...formControlProps}
        />
        {
          props?.isIcon ? props?.icon ? props.icon : <div class="calendar_icon">
          <img
            src="/images/icons/reminder.svg"
            alt="calendar"
          />
          </div> : null
        }
        </div>
      
      {props?.validationErrorMessage ? <span id="form-error" style={{ color: '#DD2726', fontSize: '13px' }} >
        {props.validationErrorMessage}
      </span> : null



      }
    </>

  );
};


const FloatingTextareaField = (props) => {
  const placeholder = props.placeholder ? props.placeholder : props.label;
  // const type = props.type ? props.type : 'text';
  const { formControlClasses, formControlProps, validationErrorMessage, ...remainProps } = props
  return (
    <>
      {props?.label ? <label htmlFor={props.id} class="form-label">{props?.label ? props.label : placeholder}</label> : null}
      <textarea
        value={props.value}
        required={props.required}
        className={props.formControlClasses}
        step={props.step}
        placeholder={placeholder}
        {...formControlProps}
        {...remainProps}
        disabled={Boolean(props?.disabled)}
      />
      {props?.validationErrorMessage && props.label ? <p id="form-error" style={{ color: '#DD2726', fontSize: '13px' }} >
        {props.validationErrorMessage}
      </p> : null
      }
    </>
  );
};


const FloatingRadioField=(props)=>{
  const placeholder = props.placeholder ? props.placeholder : props.label;
  const { formControlClasses, formControlProps, validationErrorMessage,labelName,labelFor, ...remainProps } = props
  return (
    <>
      <input
        value={props.value}
        required={props.required}
        className={props.formControlClasses}
        placeholder={placeholder}
        type={props.type}
        {...formControlProps}
        {...remainProps}
        disabled={Boolean(props?.disabled)}
      />
      <label htmlFor={labelFor}>{labelName}</label>
      {props?.validationErrorMessage && props.label ? <p id="form-error" style={{ color: '#DD2726', fontSize: '13px' }} >
        {props.validationErrorMessage}
      </p> : null
      }
    </>
  );
}

const pastYearDisabled = (date) => {
  return date.getFullYear() < current.getFullYear()

}


const FloatingSelectField = (props) => {

  function getCustomMenuListStyles() {
    const pathname = window.location.pathname;
    let scrollbarThumbColor = '#888'; 

    if (pathname === '/standard-matrix') {
        scrollbarThumbColor = '#a2a2a2'; 
    } 

    return {
        height: "200px",
        '::-webkit-scrollbar': {
            width: '4px'
        },
        '::-webkit-scrollbar-track': {
            background: '#f1f1f1'
        },
        '::-webkit-scrollbar-thumb': {
            background: scrollbarThumbColor
        },
        '::-webkit-disabled-scrollbar-thumb:hover': {
            background: '#555'
        }
    };
}
  const customMenuListStyles = getCustomMenuListStyles();

  const Controller = props.controller
  const { labelClasses, selectLabel, placeholder,isStar } = props
  return (
    <>
      {selectLabel ? <label htmlFor={props.selectName} class={props.labelClasses ? props.labelClasses : "form-label"}>{selectLabel}{isStar ? <sup className="cst_sup_txt text_danger">*</sup> : null}</label> : null}
      <Controller
        control={props.control}
        defaultValue={props.defaultValue}
        name={props.selectName}
        render={({ field: { onChange, value, name, ref }, formState }) => {
          return (
            <>
              <Select

                className={props.selectClasses ? "" : "form-select"}
                ref={ref}
                name={name}
                placeholder={placeholder}
                isDisabled={props?.disabled}
                
                styles={{
                  control: (base, state) => {
                    if (state.isFocused) {
                      //  
                      return {
                        ...base,

                        boxShadow: 'none',
                        '&:hover': {
                          borderColor: '#c7cdd199',
                          border: "1px solid #c7cdd199",
                        },
                        height: "45px",
                        border: "1px solid #c7cdd199"
                      }
                    } else {
                      return {
                        ...base,
                        height: "45px",
                        border: "1px solid #c7cdd199",
                      }
                    }
                  },
                  dropdownIndicator: (provided) => ({
                    ...provided,
                    // Hide the default dropdown arrow
                    // display: 'none',
                    
                  }),
                  menuList: (provided) => ({
                    ...provided,
                    // ...customMenuListStyles
                    ...props.customMenuList
                  }),
                  menu: (provided) => ({
                    ...provided,
                    // ...customMenuListStyles
                    ...props.customMenu
                  }),

                }}
                // {...props.customReactSelectProps}
                options={props.selectOptions}
                
                value={props.selectOptions.find(c => c.value === value) ?? null}
                onChange={(e) => {
                  e.value ? onChange(e.value) : onChange(e.map((c) => c.value))
                }}
              
              />
              <p type="invalid" id="form-error" style={formState?.errors ? { color: '#DD2726', fontSize: '13px' } : null}>{props.validationErrorMessage}</p>

            </>
          )
        }}
      />
    </>
  );
};


const FloatingMonthField = (props) => {
  const Controller = props.controller;

  return (
    <>
      <label htmlFor={props.selectName} class={props.labelClasses ? props.labelClasses : "form-label"}>{props.label}</label>
      <Controller
        name={props.selectName}
        //defaultValue={props?.defaultValue ? props.defaultValue : null}
        control={props.control}
        render={({ field: { onChange, value, name, ref }, formState }) => (
          <>
            <div style={{ position: 'relative', display: "flex" }}>
              <ReactDatePicker
                ref={ref}
                disabled={props?.disabled}
                name={name}
                className={props?.className ? props?.className : "form-control"}
                showMonthYearPicker
                selected={value ? value : null}
                value={value ? value : null}
                onFocus={(e) => e.target.readOnly = !props.preventReadOnly}
                placeholderText={props.placeholder}
                minDate={props?.minDate}
                onChange={(value) => {
                  value ? onChange(value) : onChange(null)
                }}
                maxDate={props?.maxDate}
                dateFormat={props.dateFormat}
              />
              <div class="calendar_icon">
                <img
                  src="/images/icons/calendar.svg"
                  alt="calendar"
                />
              </div>
            </div >
            <p type="invalid" id="form-error" style={formState?.errors ? { color: '#DD2726', fontSize: '13px' } : null}>{props.validationErrorMessage}</p>
          </>
        )
        }

      />
    </>
  );
}


const FloatingDateField = (props) => {
  const Controller = props.controller;

  return (
    <>
    
      <label htmlFor={props.selectName} class={props.labelClasses ? props.labelClasses : "form-label"}>{props.label}</label>
      <Controller
        name={props.selectName}
        control={props.control}
        render={({ field: { onChange, value, name, ref }, formState }) => {

          return (
            <>
              <div style={{ position: 'relative', display: "flex" }}>
                <ReactDatePicker
                  disabled={Boolean(props?.disabled)}
                  readOnly={props.disabled}
                  ref={ref}
                  name={name}
                  className={props?.className ? props?.className : "form-control"}
                  selected={value ? value : null}
                  value={value ? value : null}
                  onFocus={(e) => e.target.readOnly = !props.preventReadOnly}
                  placeholderText={props.placeholder}
                  minDate={props?.minDate ? props.minDate : new Date()}
                  onChange={(value) => {
                    value ? onChange(value) : onChange(null)
                  }}
                  formatYear={props.dateFormat}
                  maxDate={props?.maxDate}
                  dateFormat={props.dateFormat}


                />
                <div class="calendar_icon">
                  <img
                    src="/images/icons/calendar.svg"
                    alt="calendar"
                  />
                </div>
              </div >

              <p type="invalid" id="form-error" style={formState?.errors ? { color: '#DD2726', fontSize: '13px' } : null}>{props.validationErrorMessage}</p>
            </>
          )
        }
        }
      />
    </>
  );
}



const FloatingMonthAndYearField = (props) => {
  const Controller = props.controller;

  return (
    <>
      <label htmlFor={props.selectName} class={props.labelClasses ? props.labelClasses : "form-label"}>{props.label}</label>
      <Controller
        name={props.selectName}

        control={props.control}
        render={({ field: { onChange, value, name, ref }, formState }) => (
          <>
            <div style={{ position: 'relative', display: "flex" }}>
              <DatePicker
                ref={ref}
                name={name}
                disabled={Boolean(props?.disabled)}
                placeholder={props.placeholder ? props.placeholder : props.label}
                highlightToday={false}
                inputClass={props?.formControlClasses ? props.formControlClasses : 'form-control'}
                containerStyle={{ width: "100%" }}
                format={props?.dateFormat ? props?.dateFormat : "YYYY"}
                multiple={props?.multiple ? props.multiple : false}
                onChange={(value) => {
                  value ? onChange(value) : onChange(null)
                }}
                editable={false}
                plugins={props?.pastYearDisabled ? [<Calender disabled={pastYearDisabled} />] : []}

                // inputMode='numeric'
                // filterDate={(date)=>{
                //    debugger;
                // }}
                minDate={current}
                value={value ? value : null} // set a default date in the selected year

                onlyMonthPicker
              />
              <div class="calendar_icon">
                <img
                  src="/images/icons/calendar.svg"
                  alt="calendar"
                />
              </div>
            </div >
            <p type="invalid" id="form-error" style={formState?.errors ? { color: '#DD2726', fontSize: '13px' } : null}>{props.validationErrorMessage}</p>
          </>
        )
        }
      />
    </>
  );
}




const FloatingYearField = (props) => {
  const Controller = props.controller;
  const isMin=props.isMin
  
  return (
    <>
    {props.label ? <label htmlFor={props.selectName} class={props.labelClasses ? props.labelClasses : "form-label"}>{props.label}</label> : null}
      
      <Controller
        name={props.selectName}

        control={props.control}
        render={({ field: { onChange, value, name, ref }, formState }) => (
          <>
            <div style={{ position: 'relative', display: "flex" }}>
              <DatePicker
                ref={ref}
                name={name}
                disabled={Boolean(props?.disabled)}
                placeholder={props.placeholder ? props.placeholder : props.label}
                highlightToday={false}
                inputClass={props?.formControlClasses ? props.formControlClasses : 'form-control'}
                containerStyle={{ width: "100%" }}
                format={props?.dateFormat ? props?.dateFormat : "YYYY"}
                multiple={props?.multiple ? props.multiple : false}
                onChange={(value) => {
                  value ? onChange(value) : onChange(null)
                }}
                editable={false}
                plugins={props?.pastYearDisabled ? [<Calender disabled={pastYearDisabled} />] : []}

                // inputMode='numeric'
                // filterDate={(date)=>{
                //    debugger;
                // }}
                minDate={!isMin ? current : null}
                value={value ? value : null} // set a default date in the selected year
                onlyYearPicker
                
              //onlyMonthPicker
              />
              <div class="calendar_icon">
                <img
                  src="/images/icons/calendar.svg"
                  alt="calendar"
                />
              </div>
            </div >
            <p type="invalid" id="form-error" style={formState?.errors ? { color: '#DD2726', fontSize: '13px' } : null}>{props.validationErrorMessage}</p>
          </>
        )
        }
      />
    </>
  );
}

const FloatingTimeField = (props) => {
  const Controller = props.controller;

  return (
    <>
    {
      props.label ?  <label for={props.selectName} class={props.labelClasses ? props.labelClasses : "form-label"}>{props.label}</label> : null
    }
     
      <Controller
        name={props.selectName}

        control={props.control}
        render={({ field: { onChange, value, name, ref }, formState }) => {
          console.log("Valuw",value);
          return (
            <>
              <div style={{ position: 'relative', display: "flex" }}>
                <ReactTimepicker
                  // disabled={Boolean(props?.disabled)}
                  ref={ref}
                  name={name}
                  className={props?.className ? props?.className : ""}
                  // selected={value ? value: null}
                  timeFormat={props?.timeFormat ? props.timeFormat : "hh:mm A"}
                  value={value ? value : null}
                  dateFormat={false}

                  timeConstraints={props.timeConstraints}

                  // input={false}
                  //onFocus={(e) => e.target.readOnly = !props.preventReadOnly}
                  // placeholderText={props.placeholder}
                  inputProps={{ placeholder: props.placeholder, disabled: Boolean(props.disabled),id:name,name:name, style:props?.style? props.style :{} }}
                  // minDate={props?.minDate}
                  onChange={(value) => {
                    if (props.disabled) {
                      return
                    } else {
                      value ? onChange(value) : onChange(null)
                    }

                  }}

                // formatYear={props.dateFormat}
                // maxDate={props?.maxDate}
                // dateFormat={props.dateFormat}


                />
                <div class="calendar_icon">
                  <img
                    src="/images/icons/reminder.svg"
                    alt="calendar"
                  />
                </div>
              </div >

              <p type="invalid" id="form-error" style={formState?.errors ? { color: '#DD2726', fontSize: '13px' } : null}>{props.validationErrorMessage}</p>
            </>
          )
        }
        }
      />
    </>
  );
}


const CustomSelectValueContainer = ({ children, ...props }) => {
  return (
    <ValueContainer {...props}>
      <Placeholder {...props} isFocused={props.isFocused}>
        {props.selectProps.placeholder}
      </Placeholder>
      {React.Children.map(children, (child, index) =>
        child && child.type !== Placeholder ? <p>{index}{child}</p> : null
      )}
    </ValueContainer>
  );
};



const FloatingNormalSelectField = (props) => {

  return (
    <>
      <label htmlFor={props.selectName} className={props.labelClasses ? props.labelClasses : "form-label"}>{props.label}</label>

      <select className={props.selectClasses ? props.selectClasses : "form-select"} required={props.required}
        {...props.formControlProps}
        defaultValue={"Select"}
      >
        <option value="" >Select</option>
        {props.selectOptions.map((item, index) => {
          return <option key={index} value={item}>{item}</option>
        })}
      </select>
      <p type="invalid" id="form-error" style={props.validationErrorMessage ? { color: '#DD2726', fontSize: '13px' } : null}>{props.validationErrorMessage}</p>

    </>
  );
};

const FloatingRadioFieldCustom=(props)=>{
  return(
    <>
    {props.children}
    </>
  )
}



export default CustomSelectValueContainer;

export { FloatingInputField, FloatingSelectField, FloatingNormalSelectField, FloatingMonthField }



export const Input = (props) => {
  let inputElement = null;

  switch (props.type) {

    case ('text'): {
      const { selectLabel, ...remainProps } = props
      inputElement = <FloatingInputField
        value={props.value}
        required={props.required}
        className={props.formControlClasses}
        type={props.type}
        step={props.step}
        placeholder={props.placeholder}
        {...remainProps}
        {...props.formControlProps}
      />
      break;
    }
    case ('select'): {

      const { formControlProps, type, ...remainProps } = props

      inputElement = <FloatingNormalSelectField
        {...remainProps}
        formControlProps={formControlProps}

      />
      break;
    }
    case ("inputSelect"): {
      const { type, ...remainProps } = props
      inputElement = <FloatingSelectField
        {...remainProps}
      />
      break;
    }

    case ("date"): {
      const { datePickerType, ...remainProps } = props

      if (datePickerType == "month") {

        inputElement = <FloatingMonthField {...remainProps} />
        break;

      }
      if (datePickerType == "year") {
        
        inputElement = <FloatingYearField {...remainProps} />
        break
      }
      if (datePickerType == "month-year") {
        inputElement = <FloatingMonthAndYearField {...remainProps} />
        break;
      }
      if (datePickerType == "time") {
        inputElement = <FloatingTimeField {...remainProps} />;
        break
      }
      else {
        inputElement = <FloatingDateField {...remainProps} />
        break;
      }

    }

    case ("textarea"): {
      inputElement = <FloatingTextareaField
        value={props.value}
        required={props.required}
        className={props.formControlClasses}
        type={props.type}
        step={props.step}
        placeholder={props.placeholder}
        {...props}
        {...props.formControlProps}
      />
      break;
    }

    case ("radio"): {
      inputElement= <FloatingRadioField {...props}
      
      />
      break;
    }

    case ("radio-custom"): {
      inputElement= <FloatingRadioFieldCustom {...props}
      
      />
      break;
    }

    

    

    default:
      inputElement = <FloatingInputField {...props} />;

  }

  return inputElement;
}


