import React from 'react';
import { connect } from 'react-redux';
import { reset } from 'redux-form';
import * as actions from '../../../../../redux/actions/index';
import { routes, address_attributes } from '../../../../../utility/constants/constants';
import { extractExtention, getUser, resetOrientation } from '../../../../../utility/utility';
import NewInventoryItemForm from '../../../../component/Inventory/InventoryListComponent/NewInventoryItemForm/NewInventoryItemForm';
import moment from 'moment';
import { ConvertToFormat, tzUTCDateConverterTool_FormAudit } from '../../../../../utility/TimezoneOperations/timezoneUtility';
const cloneDeep = require('clone-deep');

class NewInventoryItemContainer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentDidMount() {
        if (this.props && this.props.match && this.props.match.params && this.props.match.params.inventoryItemId) {
            this.props.getSingleInventoryItem(this.props.match.params.inventoryItemId);
        }

        this.props.getAllInventoryLocationList({ name: true });
    }

    componentDidUpdate(prevProps, prevState, snapshot) { }

    componentWillUnmount() {
        this.props.resetInventoryObjects();
    }

    addNewInventoryItem = (values) => {
        let inventoryItems = cloneDeep(values);
        if (inventoryItems.item && inventoryItems.item.name) {
            inventoryItems.item.name = inventoryItems.item.name.trim()
        }
        if (this.props && this.props.match && this.props.match.params && this.props.match.params.inventoryItemId) {

            const expiration_date =tzUTCDateConverterTool_FormAudit(ConvertToFormat(inventoryItems.item.expiration_date),"to"); //moment(inventoryItems.item.expiration_date).format('YYYY-MM-DD');
            inventoryItems.item.expiration_date = expiration_date;
            debugger;
            // if(inventoryItems.item && inventoryItems.item.initial_quantity) {
            //     inventoryItems.item.quantity = inventoryItems.item.initial_quantity;
            //     delete inventoryItems.item.initial_quantity
            // }

            inventoryItems.id = this.props.match.params.inventoryItemId;
            if (inventoryItems?.item?.location_id?.value) inventoryItems.item.location_id = Number(inventoryItems.item.location_id.value);
            this.props.updateInventoryItem(inventoryItems);

        } else {

            if (inventoryItems?.item?.location_id?.value) inventoryItems.item.location_id = Number(inventoryItems.item.location_id.value);
            const expiration_date = tzUTCDateConverterTool_FormAudit(ConvertToFormat(inventoryItems.item.expiration_date),"to")//moment(inventoryItems.item.expiration_date).format('YYYY-MM-DD');
            inventoryItems.item.expiration_date = expiration_date;
            inventoryItems.item.type = 'item';
            this.props.createInventoryItem(inventoryItems);

        }
    }

    onCancelClicked = () => {
        this.props.history.goBack();
    }

    render() {

        return (
            <NewInventoryItemForm
                {...this.props}
                onSubmit={this.addNewInventoryItem}
                onCancelClicked={this.onCancelClicked}
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.authReducer.user,
        isLoading: state.InventoryReducer.isLoading,
        isEditInventoryItem: state.InventoryReducer.isEditInventoryItem,
        isUpdateInventoryItem: state.InventoryReducer.isUpdateInventoryItem,
        singleInventoryItem: state.InventoryReducer.singleInventoryItem,
        inventoryLocationList: state.InventoryReducer.inventoryLocationList,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        resetInventoryObjects: () => dispatch(actions.resetInventoryObjects()),
        createInventoryItem: (data) => dispatch(actions.createInventoryItem(data)),
        updateInventoryItem: (data) => dispatch(actions.updateInventoryItem(data)),
        getSingleInventoryItem: (id) => dispatch(actions.getSingleInventoryItem(id)),
        getAllInventoryLocationList: (data) => dispatch(actions.getAllInventoryLocationList(data)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewInventoryItemContainer);