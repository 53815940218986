import React, { Component } from 'react';
import * as actions from '../../../../../redux/actions/index';
import { connect } from 'react-redux';
import { reset } from 'redux-form';
import { animateScroll as scroll } from 'react-scroll';
import NewScheduledTask from '../../../../component/TimeTrackerModuleComponent/ScheduledTaskComponent/NewScheduledTask/NewScheduledTask';
import cloneDeep from 'clone-deep';
import { tzUTCDateTimeConverterTool_V1 } from '../../../../../utility/TimezoneOperations/timezoneUtility';
import errorHandler from '../../../../../utility/errorHandler/errorHandler';
var moment = require('moment');

class NewScheduledTaskContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isButtonLoading: false,
        }
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        this.props.getJobsList({ address: true });
        this.props.getSkillSetList({ activeStatus: 'active' });
        scroll.scrollToTop();
    }

    getSelectedJob_companies = (id) => {

        this.props.getCompanyList({ accountId: id });

    }

    onCancelClicked = () => {
        this.props.history.goBack();
    }

    handleSubmit = (values,isCompany) => {
        //this.setState({isButtonLoading: true})
        let data = {}
        const value = cloneDeep(values)
        
        if(isCompany && value.scheduledTask?.company==undefined){
            //  and no company trigger error message 

            errorHandler("Company field is Required")
            return false;
            
        }
        else if(isCompany && value.scheduledTask?.company ){
            data['company_id'] = value.scheduledTask.company.value
        }
        data['name'] = value.scheduledTask.task_name
        data['start_date'] = tzUTCDateTimeConverterTool_V1(value.scheduledTask.start_date, '00:00:00', 'from');
        data['end_date'] = tzUTCDateTimeConverterTool_V1(value.scheduledTask.end_date, '00:00:00', 'to');
        data['account_id'] = value.scheduledTask.job.value
        // data['company_id'] = value.scheduledTask.company.value
        data['subtasks'] = value.scheduledTask.subtasks.map((item) => {
            return {
                subject: item.ticket,
                skills: item.skill_set.map(item => item.value.toString()),
                // due_on: item.start_date,
                // end_time: item.end_date,
                // start_time: item.start_date,
                priority: item.priority,
                message: item && item.message ? item.message : ''
            }
        })
        this.props.newScheduledTaskTicket({ data })
    }



    render() {

        return (

            <NewScheduledTask
                user={this.props.user}
                onSubmit={this.handleSubmit}
                getFilteredCompanies={this.getSelectedJob_companies}
                onCancelClicked={this.onCancelClicked}
                isLoading={this.props.isLoading}
                accountsList={this.props.accountsList}
                skillSet_list={this.props.skillSet_list}
                company_list={this.props.company_list}
            //isButtonLoading={this.state.isButtonLoading}
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.authReducer.user,
        isLoading: state.TimeTrackerModReducer.isLoading,
        accountsList: state.TimeTrackerModReducer.job_list,
        skillSet_list: state.TimeTrackerModReducer.skillSet_list,
        company_list: state.TimeTrackerModReducer.company_list,

    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        resetObjects: () => dispatch(actions.resetObjects()),
        reset: (newScheduledTaskForm) => dispatch(reset(newScheduledTaskForm)),
        getJobsList: (data) => dispatch(actions.getJobsList(data)),
        getSkillSetList: (data) => dispatch(actions.getSkillSetList(data)),
        getCompanyList: (data) => dispatch(actions.getCompanyList(data)),
        newScheduledTaskTicket: (data) => dispatch(actions.newScheduledTaskTicket(data)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewScheduledTaskContainer);