import React, { useState, useEffect } from 'react';
import ReactImageGallery from 'react-image-gallery';
import { userRoles } from '../../../utility/constants/constants';

const NewVersion = (props) => {
    const images = [
        {
            original: 'https://picsum.photos/id/1018/1000/600/',
            thumbnail: 'https://picsum.photos/id/1018/250/150/',
        },
        {
            original: 'https://picsum.photos/id/1015/1000/600/',
            thumbnail: 'https://picsum.photos/id/1015/250/150/',
        },
        {
            original: 'https://picsum.photos/id/1019/1000/600/',
            thumbnail: 'https://picsum.photos/id/1019/250/150/',
        },
    ];

    return (
        <div className="">
            <main className="app-content  wraper_content inspection_wraper user_newQuote">
                <div className="inspection_content_sec appcontent_Inner mt-5">
                    <div className="container-fluid">
                        <div className="quote_form ticket_form text-center" style={{ maxWidth: "100%" }}>
                            <h2 className="md_title mb-4" data-test="create_ticket_header" style={{ color: "#850037" }}>New Enhancement</h2>
                                <div className="col-lg-8 mx-auto">
                                {
                                    props.user.role === userRoles.SUPER_ADMIN || props.user.role === userRoles.ADMIN ?  
                                <>
                                <div className="col d-flex" style={{marginBottom: '10px'}}>
                                        <div className="col-lg-12 d-flex">
                                            <div className="addQuote_form text-center" style={{ display: "inline-block", margin: 'auto' }}>
                                                <h2 className="md_title" data-test="create_ticket_header">Find Below Document For Pegassure Feedback </h2>
                                                <p className="md_title" style={{ fontSize: "20px" }}>Open a PDF file 
                                                <a target="_blank" href="https://pegasus-railsapi-qa-asset.s3.us-west-2.amazonaws.com/PegAssure+Feedback+Flow+(2).pdf" style={{ color: "#850037" }} className="ml-2">
                                                Click Here
                                                </a>.
                                                </p>
                                                <p><span style={{fontWeight: 'bold'}}>Date:</span> 20-07-2022 <span style={{fontWeight: 'bold'}}>Version no:</span> v2.2.0</p>
                                            </div>
                                        </div>
                                    </div> 
                                    {/* <div className="col d-flex"  style={{marginBottom: '10px'}}>
                                        <div className="col-lg-12 d-flex">
                                            <div className="addQuote_form text-center" style={{ display: "inline-block", margin: 'auto' }}>
                                                <h2 className="md_title" data-test="create_ticket_header">Find Below Document For New Enhancement </h2>
                                                <p className="md_title" style={{ fontSize: "20px" }}>Open a PDF file 
                                                <a target="_blank" href="https://pegasus-railsapi-qa-media.s3.us-west-2.amazonaws.com/PegAssure+Feedback+Flow+(2).pdf" style={{ color: "#850037" }} className="ml-2">
                                                Click Here
                                                </a>.
                                                </p>
                                                <p><span style={{fontWeight: 'bold'}}>Date:</span> 12-05-2022 <span style={{fontWeight: 'bold'}}>Version no:</span> v2.1.8</p>
                                            </div>
                                        </div>
                                    </div>  */}
                                </> : null
                                    
                                }
                                {
                                        props.user.role === userRoles.INSPECTOR ? 
                                        <div className="col-lg-12 d-flex">
                                        <div className="addQuote_form text-center" style={{ display: "inline-block" }}>
                                            <h2 className="md_title" data-test="create_ticket_header">Find Below Document For New Enhancement </h2>
                                            <p className="md_title" style={{ fontSize: "20px" }}>Open a PDF file 
                                            <a target="_blank" href="https://pegasus-railsapi-qa-asset.s3.us-west-2.amazonaws.com/inspector_doc.pdf" style={{ color: "#850037" }} className="ml-2">
                                            Click Here
                                            </a>.
                                            </p>
                                            <p><span style={{fontWeight: 'bold'}}>Date:</span> 20-06-2022 <span style={{fontWeight: 'bold'}}>Version no:</span> v2.1.9</p>
                                        </div>
                                    </div> : null
                                }
                                {/*<div className="addQuote_form my-5" style={{ display: "inline-block" }}>
                                    <h2 className="md_title" data-test="create_ticket_header">Find Below Gallery For New Enhancement </h2>
                                    <ReactImageGallery items={images} />;
                                </div>*/}
                                {/* <div className="col d-flex"  style={{marginBottom: '10px'}}>
                                        <div className="col-lg-12 d-flex">
                                            <div className="addQuote_form text-center" style={{ display: "inline-block", margin: 'auto' }}>
                                                <h2 className="md_title" data-test="create_ticket_header">Find Below Document For New Enhancement </h2>
                                                <p className="md_title" style={{ fontSize: "20px" }}>Open a PDF file 
                                                <a target="_blank" href="https://pegasus-railsapi-qa-media.s3.us-west-2.amazonaws.com/PegAssure+Feedback+Flow+(2).pdf" style={{ color: "#850037" }} className="ml-2">
                                                Click Here
                                                </a>.
                                                </p>
                                                <p><span style={{fontWeight: 'bold'}}>Date:</span> 12-05-2022 <span style={{fontWeight: 'bold'}}>Version no:</span> v2.1.8</p>
                                            </div>
                                        </div>
                                    </div>  */}
                                <div className="col d-flex" style={{marginBottom: '10px'}}>
                                        <div className="col-lg-12 d-flex">
                                            <div className="addQuote_form text-center" style={{ display: "inline-block", margin: 'auto' }}>
                                                <h2 className="md_title" data-test="create_ticket_header"> Find Below Document For Pegassure Functional flow </h2>
                                                <p className="md_title" style={{ fontSize: "20px" }}>Open a PDF file 
                                                <a target="_blank" href="https://pegasus-railsapi-qa-asset.s3.us-west-2.amazonaws.com/PegAssure+-+Functional+Document+(1).pdf" style={{ color: "#850037" }} className="ml-2">
                                                Click Here
                                                </a>.
                                                </p>
                                                <p><span style={{fontWeight: 'bold'}}>Date:</span> 03-11-2022 <span style={{fontWeight: 'bold'}}>Version no:</span> v2.2.1</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col d-flex" style={{marginBottom: '10px'}}>
                                        <div className="col-lg-12 d-flex">
                                            <div className="addQuote_form text-center theatre" style={{ display: "inline-block", margin: 'auto' }}>
                                                <h2 className="md_title" data-test="create_ticket_header"> Find the below video for PegAssure Super Admin flow </h2>
                                                <video controls>
                                                    <source src="https://pegasus-railsapi-qa-asset.s3.us-west-2.amazonaws.com/pegassure_superadmin.mp4" type="video/mp4" />
                                                </video>
                                                <p><span style={{fontWeight: 'bold'}}>Date:</span> 03-11-2022 <span style={{fontWeight: 'bold'}}>Version no:</span> v2.2.1</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col d-flex" style={{marginBottom: '10px'}}>
                                        <div className="col-lg-12 d-flex">
                                            <div className="addQuote_form text-center theatre" style={{ display: "inline-block", margin: 'auto' }}>
                                                <h2 className="md_title" data-test="create_ticket_header"> Find the below video for PegAssure Frontline Worker flow </h2>
                                                <video controls>
                                                    <source src="https://pegasus-railsapi-qa-asset.s3.us-west-2.amazonaws.com/pegassure_Frontline.mp4" type="video/mp4" />
                                                </video>
                                                <p><span style={{fontWeight: 'bold'}}>Date:</span> 03-11-2022 <span style={{fontWeight: 'bold'}}>Version no:</span> v2.2.1</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col d-flex" style={{marginBottom: '10px'}}>
                                        <div className="col-lg-12 d-flex">
                                            <div className="addQuote_form text-center theatre" style={{ display: "inline-block", margin: 'auto' }}>
                                                <h2 className="md_title" data-test="create_ticket_header"> Find the below video for PegAssure Customer flow </h2>
                                                <video controls>
                                                    <source src="https://pegasus-railsapi-qa-asset.s3.us-west-2.amazonaws.com/pegassure_customer.mp4" type="video/mp4" />
                                                </video>
                                                <p><span style={{fontWeight: 'bold'}}>Date:</span> 03-11-2022 <span style={{fontWeight: 'bold'}}>Version no:</span> v2.2.1</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col d-flex" style={{marginBottom: '10px'}}>
                                        <div className="col-lg-12 d-flex">
                                            <div className="addQuote_form text-center theatre" style={{ display: "inline-block", margin: 'auto' }}>
                                                <h2 className="md_title" data-test="create_ticket_header"> Find the below video for Inventory Functional Flow </h2>
                                                <video controls>
                                                    <source src="https://pegasus-railsapi-qa-asset.s3.us-west-2.amazonaws.com/YouCut_20230607_175701447.mp4" type="video/mp4" />
                                                </video>
                                                <p><span style={{fontWeight: 'bold'}}>Date:</span> 08-06-2023 <span style={{fontWeight: 'bold'}}>Version no:</span> v2.2.1</p>
                                            </div>
                                        </div>
                                    </div> 
                                </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    )
}
export default NewVersion;