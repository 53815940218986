import React, { useEffect } from 'react';
import { Input } from '../../../UI/InputElement/InputElement';
import { reduxForm } from 'redux-form';
import { leaveRequestRejectionValidator as validate } from '../../../../../utility/validator/validator';
import { connect } from 'react-redux';
import isEmpty from '../../../../../utility/IsEmptyValidator';

let LeaveRequestRejectionForm = (props) => {

    var radios = document.querySelectorAll('input[type="radio"]');
    for (var i = 0; i < radios.length; i++) {
        radios[i].onfocus = function () {
            this.blur();
        }
    }

    const { handleSubmit, pristine, reset, submitting, error } = props;

    return (
        <div className="modal fade request_quote_modal reject_quot associateUser" id="reasonForRejection" tabindex="-1" role="dialog" aria-labelledby="rejectQuoteModalTitle" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <label for="exampleInputEmail1" className="cst-label md_title"> Reason for Rejection </label>
                        <button type="button" className="close close_icn_pic" data-dismiss="modal" onClick={props.handleFrequentTimeTracker_closeModal}><img src="/images/icons/icn_close_gray.png" alt="Close Icon" /></button>
                    </div>
                    <div className="modal-body">
                        <div className="tab-content">
                            <div id="profile" className="profile_tab cst_tab_content tab-pane fade show active edit_profile_modal">
                                <form className="form_content" onSubmit={handleSubmit}>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label className="form_title"> Enter Your Reason For Rejection <sup className="cst_sup_txt text_danger">*</sup> </label>
                                                <Input name="rejectMod[reject_reason]" type="textarea" className="form-control textarea_modifier" rows="5" placeholder="Add your comments" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="clearfix"></div>
                                    <div className="modal-footer">
                                        <button data-test="submit_button" type="submit" disabled={props.isLoading} className={props.isLoading ? "btn cst_btn btn_danger mr-2 btn-wait" : "btn cst_btn btn_danger mr-2"} > {props.isLoading ? "" : "Save"} </button>
                                        <button onClick={props.handleFrequentTimeTracker_closeModal} id='closeLeaveRequestRejectionModal' type="button" className="btn btn-outline-secondary cst_btn" data-dismiss="modal">Close</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
};

const mapStateToProps = (state) => {
    let rejectMod = {}

    rejectMod = {
        status: 'reject',
        reject_reason: ''
    }

    return { initialValues: { rejectMod } }
}

LeaveRequestRejectionForm = reduxForm({
    form: 'leaveRequestRejectionForm',
    validate,
    // enableReinitialize: true,
    onSubmitFail: (errors) => scrollToFirstError(errors),
})(LeaveRequestRejectionForm);
export const scrollToFirstError = (errors) => {
    let scrollToError = null;
    if (errors.rejectMod.reason) {
        scrollToError = `rejectMod[reason]`;
    }
}

LeaveRequestRejectionForm = connect(mapStateToProps)(LeaveRequestRejectionForm)

export default LeaveRequestRejectionForm;