import React, { useEffect, useState } from 'react';
import { formValueSelector, getFormValues, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import { IsFormAccessForView, convertDateToPickerFormat } from '../../../../../utility/utility';
import { Input } from '../../../UI/InputElement/InputElement';
import PerformanceAuditTable from './performanceAuditTable';

let PerformanceAudit = (props) => {
    const { handleSubmit, pristine, reset, submitting, error } = props;

    const isCreateAccess = IsFormAccessForView(props.user.form_access_actions, ["audit", "performance_audit"], true)

    return (
        <>
            <main className="app-content wraper_content inspection_wraper">
                <div className="tab_header search_filter_cont inspection_tab_content ticket_filter">
                    <form onSubmit={handleSubmit}>
                        <input type="hidden" value="something" />

                        <div className='row' style={{ marginTop: '10px' }}>
                            <div className="col-xl-2 col-lg-4 col-md-6 col-sm-6" style={{ maxWidth: '17%' }}>
                                <Input name="fromDate" dateFormat="MM-dd-yyyy" placeholder="From Date" datePickerType={"react-datepicker"} type="date" className="form-control input-modifier" />
                                <div class="input-group-append cst_group_append">
                                    <span class="input-group-text" id="basic-addon2"><i class="fa fa-calendar"></i></span>
                                </div>
                            </div>
                            <div class="col-xl-2 col-lg-4 col-md-6 col-sm-6" style={{ maxWidth: '17%' }}>

                                <Input name="toDate" minDate={props.selectedValues.fromDate ? props.selectedValues.fromDate : null} dateFormat="MM-dd-yyyy" placeholder="To Date" datePickerType={"react-datepicker"} type="date" className="form-control input-modifier" />
                                <div class="input-group-append cst_group_append">
                                    <span class="input-group-text" id="basic-addon2"><i class="fa fa-calendar"></i></span>
                                </div>

                            </div>

                            <div className="col-xl-8 col-lg-6 col-sm-3 col-12 text-left">
                                <button data-test="filter_go_btn" style={{ minWidth: '90px' }} className={"btn cst_btn btn_danger mr-2  account_btn"} id="submit" type="submit">{"Go"} </button>
                                <button data-test="filter_reset_btn" style={{ minWidth: '90px' }} onClick={props.resetClicked} className={"btn cst_btn btn-outline-secondary account_btn"} id="reset" type="reset">{"Reset"}</button>
                            </div>
                        </div>
                    </form>
                </div>


                <section className="account_sec inspection_tab_content appcontent_Inner">
                   {isCreateAccess ? <div className='row'>
                        <div className="col-xl-12 col-lg-6 col-sm-3 col-12 text-right quotes_btn_wrap">
                            <a onClick={props.newTicketClicked} href="javascript:void (0)" className="btn btn-secondary cst_btn" data-toggle="modal" data-backdrop="static" data-keyboard="false" data-target="#addAccountModal" data-test="add_new_ticket"><i className="fa fa-plus icn_plus"></i> Add More</a>
                        </div>
                    </div> : null} 
                    <div className="quotes_table_content accounts_table_contnet table-responsive">
                        <div className="table quotes_table user_react_table mt-3">
                            <PerformanceAuditTable
                                {...props}
                                isCreateAccess={isCreateAccess}
                            />
                        </div>
                    </div>
                </section>
            </main>
            <Dialog
                open={props.openDeleteModel}
                onClose={props.handleClose}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title" style={{ fontSize: '13', color: '#06080f' }}>
                    <h3 style={{ color: '#ab385e' }} data-test="modal_delete_title">Are you sure?</h3>
                </DialogTitle>

                <DialogActions>
                    <button data-test="modal_delete" style={{ minHeight: '36px', minWidth: '80px' }} onClick={() => props.deleteTicket(props.deleteObject.id)} disabled={props.isLoading} className={props.isLoading ? "btn cst_btn btn_danger mr-2 btn-wait" : "btn cst_btn btn_danger mr-2"} id="delete" type="delete">{props.isLoading ? "" : "Delete"}</button>
                    <button data-test="modal_cancel" style={{ minHeight: '36px', minWidth: '80px' }} onClick={props.handleDeleteClose} className={"btn cst_btn btn-outline-secondary"} id="cancel" type="cancel">Cancel</button>
                    {/* <Button onClick={props.handleClose} variant="contained" color=convertDateToPickerFormat"primary">
                        Cancel
                        </Button>


                    <Button onClick={() => props.deleteAccount(props.deleteObject.id)} disabled={props.isLoading} variant="contained" color="secondary">
                        {props.isLoading ? <CircularProgress size={24} /> : 'Delete'}
                    </Button> */}
                </DialogActions>
            </Dialog>
        </>

    )
}

const mapStateToProps = (state) => {
    let fromDate = state.auditsReducer.performanceAuditList_searchValues && state.auditsReducer.performanceAuditList_searchValues.fromDate ? convertDateToPickerFormat(state.auditsReducer.performanceAuditList_searchValues.fromDate) : "";
    let toDate = state.auditsReducer.performanceAuditList_searchValues && state.auditsReducer.performanceAuditList_searchValues.toDate ? convertDateToPickerFormat(state.auditsReducer.performanceAuditList_searchValues.toDate) : "";
    return { initialValues: { fromDate, toDate }, formStates: getFormValues('searchPerformanceAudit')(state) }
}

PerformanceAudit = reduxForm({
    form: 'searchPerformanceAudit',
    // validate,
    enableReinitialize: true
})(PerformanceAudit);
const selector = formValueSelector('searchPerformanceAudit')
PerformanceAudit = connect(state => {
    const { fromDate, toDate } = selector(state, 'fromDate', 'toDate')
    const selectedValues = { fromDate, toDate }
    return {
        selectedValues
    }
})(PerformanceAudit)
PerformanceAudit = connect(mapStateToProps)(PerformanceAudit)

export default PerformanceAudit;