import React from "react";

const ReportCountBlock = (props) => {
  return (
      <><div className="clearfix"></div>
      <div className="chart-content grid_block mt-3">
      <div className="container-fluid">
        <div className="grid_view">
          <div className="grid_card tile">
            <h4 className="chart_title">{props.scanAnalyticsData.areas_count}</h4>
            <p className="cst_para">Total Areas </p>
          </div>
          <div className="grid_card tile">
            <h4 className="chart_title">{props.scanAnalyticsData.scans_count}</h4>
            <p className="cst_para">Total Cleanings</p>
          </div>
          <div className="grid_card tile">
            <h4 className="chart_title">{props.scanAnalyticsData.not_cleaned_counts}</h4>
            <p className="cst_para">Total Missed Cleanings</p>
          </div>
          <div className="grid_card tile">
            <h4 className="chart_title">{props.scanAnalyticsData.not_available_counts}</h4>
            <p className="cst_para">Total Not Available</p>
          </div>
          <div className="grid_card tile">
            <h4 className="chart_title">{props.scanAnalyticsData.no_of_users_scanned}</h4>
            <p className="cst_para">No of Users scanned </p>
          </div>
        </div>
      </div>
      </div>
      </>
  )
};

export default ReportCountBlock;