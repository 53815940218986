import React, { Component } from 'react';
import InsightsPage from '../../component/Insights';
import * as actions from '../../../redux/actions/index';
import { connect } from 'react-redux';
import errorHandeler from '../../../utility/errorHandler/errorHandler';
import { TimeZones } from '../../../utility/constants/constants';
import { ConvertToFormat, tzUTCDateConverterTool_FormAudit } from '../../../utility/TimezoneOperations/timezoneUtility';
const cloneDeep = require('clone-deep');
var moment = require('moment');

class SettingsContainer extends Component {

  constructor(props) {
    super(props);

    this.state = {
    }
}

  handelAreaTypesSubmit = (value) => {
    if (this.props.isEdit) {
      delete value.area_type.active_status;
      this.props.updateAreaType(value, this.props.areaType.id)
    } else {
      this.props.addAreaType(value);
    }
  }

  handelDeficiencySubmit = (data) => {debugger
    let value = cloneDeep(data)
    if (this.props.isEdit) {
      if(value.deficiency.title === this.props.deficiency.title) {
        let ele = document.getElementById('deficiencyClose');
        if (ele) {
            ele.click();
        }
        return
      }
        let body = {}
        body['title'] = value.deficiency.title;
        this.props.updateDeficiency({deficiency: body}, value.deficiency.id)
    } else {
        let body = {}
        body['title'] = value.deficiency.title;
        this.props.addDeficiency({deficiency: body});      
    }
  }

  handelFloorSubmit = (value) => {
    if (this.props.isEdit) {
      delete value.floor.active_status;
      this.props.updateFloor(value, this.props.floor.id)
    } else {
      this.props.addFloor(value);
    }
  }

  addNewHoliday = (values) => {
    let data = cloneDeep(values);
    // data.holiday.holiday_date = moment.tz(data.holiday.holiday_date, TimeZones.PST).format('MM-DD-YYYY')
    //here we need to convert date to UTC !!!!
    data.holiday.holiday_date = tzUTCDateConverterTool_FormAudit(ConvertToFormat(data.holiday.holiday_date), "to");
    if (data.holiday.is_recurring === "true") {
      data.holiday.is_recurring = true;
    } else {
      data.holiday.is_recurring = false;
    }

    if (this.props.isEdit) {

      this.props.updateHoliday(data);
    } else {
      this.props.addNewHoliday(data);
    }
  }

  render() {

    return (
      <InsightsPage
        {...this.props}
        openPopup={this.props.openPopup}
        closePopup={this.props.closePopup}
        isOpenModel={this.props.isOpenModel}
        isEdit={this.props.isEdit}
        addNewHoliday={this.addNewHoliday}
        handelAreaTypesSubmit={this.handelAreaTypesSubmit}
        handelFloorSubmit={this.handelFloorSubmit}
        handelDeficiencySubmit={this.handelDeficiencySubmit}
        emptyObjects={this.props.emptyObjects}
        isLoading={this.props.isLoading}
        totalAreaTypes= {this.props.totalAreaTypes}
        totalDeficiencies= {this.props.totalDeficiencies}
        totalFloors= {this.props.totalFloors}
        totalHolidays= {this.props.totalHolidays}
        clearDeficiency={this.props.clearDeficiency}
      />
    )
  }
}

const mapStateToProps = (state) => {
  return {
    isOpenModel: state.adminOrSuperAdminReducer.isOpenModel,
    isLoading: state.adminOrSuperAdminReducer.isLoading,
    areaType: state.adminOrSuperAdminReducer.areaType,
    deficiency: state.adminOrSuperAdminReducer.deficiency,
    floor: state.adminOrSuperAdminReducer.floor,
    isEdit: state.adminOrSuperAdminReducer.isEdit,
    isHolidaysLoading: state.adminOrSuperAdminReducer.isHolidaysLoading,
    totalAreaTypes: state.adminOrSuperAdminReducer.totalAreaTypes,
    totalDeficiencies: state.adminOrSuperAdminReducer.totalDeficiencies,
    totalFloors: state.adminOrSuperAdminReducer.totalFloors,
    totalHolidays: state.adminOrSuperAdminReducer.totalHolidays
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    openPopup: () => dispatch(actions.openPopup()),
    closePopup: () => dispatch(actions.closePopup()),
    addAreaType: (AreaTypes) => dispatch(actions.addAreaType(AreaTypes)),
    updateAreaType: (AreaTypes, id) => dispatch(actions.updateAreaType(AreaTypes, id)),
    addFloor: (Floor) => dispatch(actions.addFloor(Floor)),
    addDeficiency: (deficiency) => dispatch(actions.addDeficiency(deficiency)),
    updateDeficiency: (deficiency, id) => dispatch(actions.updateDeficiency(deficiency, id)),
    updateFloor: (Floor, id) => dispatch(actions.updateFloor(Floor, id)),
    emptyObjects: () => dispatch(actions.emptyObjects()),
    addNewHoliday: (data) => dispatch(actions.addNewHoliday(data)),
    updateHoliday: (data) => dispatch(actions.updateHoliday(data)),
    clearDeficiency: () => dispatch(actions.clearDeficiency()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SettingsContainer);