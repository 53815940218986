import Highcharts, { color } from 'highcharts';

const CustomHighCharts = (props) => {

    return (
        Highcharts.chart(`${props.id}`, {
            chart: {
                type: `${props.type}`
            },
            // colorAxis: [{ colors: ["#7cb5ec"] }],
            title: {
                text: props.heading
            },
            subtitle: {
                // text: 'Source: <a href="http://en.wikipedia.org/wiki/List_of_cities_proper_by_population">Wikipedia</a>'
            },
            xAxis: {
                type: 'category',
                labels: {
                    rotation: -45,
                    style: {
                        fontSize: '13px',
                        fontFamily: 'Verdana, sans-serif'
                    }
                }
            },
            yAxis: {
                min: 0,
                title: {
                    text: ' '
                }
            },
            legend: {
                enabled: false
            },
            tooltip: {
                pointFormat: props.toolTip
            },
            plotOptions: {
                column: {
                    zones: [{
                        // color: '#a1395e'
                    }]
                },
                bar: {
                    zones: [{
                        // color: '#a1395e'
                    }]
                },
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    dataLabels: {
                        enabled: true,
                        format: '<b>{point.name}</b>: {point.percentage:.1f} %'
                    }
                }
            },
            series: [{
                name: 'Population',
                data: props.data,
                dataLabels: {
                    enabled: true,
                    rotation: -90,
                    color: '#FFFFFF',
                    align: 'right',
                    format: props.barLabel, // one decimal
                    y: 10, // 10 pixels down from the top
                    style: {
                        fontSize: '13px',
                        fontFamily: 'Verdana, sans-serif'
                    }
                }
            }]
        })
    )
}

export default CustomHighCharts;