import React, { Component } from 'react';
import { connect } from 'react-redux';
import { decode, encode } from 'base64-arraybuffer';
import * as actions from '../../../../redux/actions/index';
import FaceRecognitionComponent from '../../../component/Frontline/FrontlineUserFaceRecognition/FaceRecognitionComponent';
import { getUser, resetOrientation, extractExtention } from '../../../../utility/utility';
import { animateScroll as scroll } from 'react-scroll'
import storage from '../../../../utility/storage';
const cloneDeep = require('clone-deep');

class FaceRecognitionContainer extends Component {

    constructor(props) {
        super(props);

        this.state = {
        }
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        const getUserObject = storage.get('user');
        scroll.scrollToTop();
    }

    // componentWillUnmount() {
    //     navigator.mediaDevices.getUserMedia({ video: false })
    //         .then((currentStream) => {
    //         })
    //         .catch((err) => {
    //             window.location.reload();
    //         })
    // }

    handleSubmit = (imageUrl) => {

        let setData = { data: { photo_path: '' } };
        let base_photo, extension = [], arrayBuffer;

        // FOR CREATE EXTENSION
        let getImageType = imageUrl.match(/^data:(image\/\w+);/);
        if (getImageType && getImageType.length > 1) {
            const mimeType = getImageType[1];
            extension.push('.' + mimeType.split('/')[1]);
        }

        // FOR CREATE ARRAYBUFFER
        let getImageBinaryUrl = imageUrl.split(',');
        base_photo = getImageBinaryUrl[1];
        arrayBuffer = decode(base_photo);

        this.props.getScannedFaceImagePresignUrl(setData, arrayBuffer, extension);

    }

    render() {

        return (
            <>
                <div style={{ margin: '10px' }}>
                    <FaceRecognitionComponent
                        {...this.props}
                        handleSubmit={this.handleSubmit}
                    />
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        isLoading: state.TimeTrackerModReducer.isLoading,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getScannedFaceImagePresignUrl: (data, arrayBuffer, extension) => dispatch(actions.getScannedFaceImagePresignUrl(data, arrayBuffer, extension)),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(FaceRecognitionContainer);