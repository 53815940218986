import React, { Component } from 'react';
import AreaTypes from '../../component/Insights/AreaTypes/AreaTypes';
import * as actions from '../../../redux/actions/index';
import { connect } from 'react-redux';
import { getUser } from '../../../utility/utility';
import { animateScroll as scroll} from 'react-scroll'

class AreaTypeContainer extends Component {
  componentDidMount() {
    if (!this.props.areaTypes.length) {
      this.props.getAreaTypes();
    }
    scroll.scrollToTop();
  }

  render() {

    return (
        <AreaTypes
            areaTypes={this.props.areaTypes ? this.props.areaTypes : []}
            openDeleteModel={this.props.openDeleteModel}
            handleDeleteClose={this.props.handleDeleteClose}
            editAreaTypeClicked={this.props.editAreaTypeClicked}
            deleteAreaType={this.props.deleteAreaType}
            deleteObject={this.props.deleteObject}
            handleClickDeleteModel={this.props.handleClickDeleteModel}
            isLoading={this.props.isLoading}
            openPopup={this.props.openPopup}
            totalAreaTypes= {this.props.totalAreaTypes}
            />
    )
  }
}

const mapStateToProps = (state) => {
  return {
    areaTypes: state.adminOrSuperAdminReducer.areaTypes,
    isLoading: state.adminOrSuperAdminReducer.isLoading,
    deleteObject: state.adminOrSuperAdminReducer.deleteObject,
    openDeleteModel: state.adminOrSuperAdminReducer.openDeleteModel,
    totalAreaTypes: state.adminOrSuperAdminReducer.totalAreaTypes,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    handleDeleteClose: () => dispatch(actions.handleDeleteClose()),
    deleteAreaType: (id) => dispatch(actions.deleteAreaType(id)),
    getAreaTypes: () => dispatch(actions.getAreaTypes()),
    openPopup: () => dispatch(actions.openPopup()),
    editAreaTypeClicked: (position) => dispatch(actions.editAreaTypeClicked(position)),
    handleClickDeleteModel: (account) => dispatch(actions.handleClickDeleteModel(account)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AreaTypeContainer);