import React, { useState, useEffect } from 'react';
import { Input } from '../UI/InputElement/InputElement';
import { useDispatch, useSelector } from "react-redux";
import { reduxForm, getFormValues } from 'redux-form';
import { connect } from 'react-redux';
import { isEmpty, makeFirstLetterCapital } from '../../../utility/utility';
import PerformInsepctionSkeletonModal from './performInspectioSkeletonModal';
import { CreatingInspectionValidator as validate } from '../../../utility/validator/validator';
import { userRoles } from '../../../utility/constants/constants';
import { ClapSpinner } from 'react-spinners-kit';
import {Link} from "react-router-dom";

const getAccountsDropDown = (props) => {
    if (props.user.role === userRoles.INSPECTOR || props.user.role === userRoles.REQUESTOR) {
        return props.userAccountLists.map(account => {
            return {
                value: account.id,
                label: account.name
            }
        })
    } else {
        return props.accountsList.map(account => {
            return {
                value: account.id,
                label: account.name
            }
        })
    }
}
let PerformInspectionModal = (props) => {
    const isInspectionForm = useSelector(state => state.adminOrSuperAdminReducer.isInspectionForm);
    const { handleSubmit, pristine, reset, submitting, error } = props;
    const [inspectionForms, setInspectionForms] = useState([]);
    const [accounts, setAccounts] = useState(getAccountsDropDown(props))
    const [temp, setTemp] = useState(false)
    const [disable, setDisable] = useState(false)
    const [LatestInspectionList, setLatestInspectionList] = React.useState(false)
    
    const showLatestInspectionList = (props) => {
        setLatestInspectionList(true);
        
    }

    useEffect(() => {
        setAccounts(getAccountsDropDown(props))
    }, [props.accountsList, props.userAccountLists])
    const accountOptionsMessage = (a, b) => {
        return 'Job not found';
    }
    const ratingOptionsMessage = () => {
        return 'Form not found';
    }
    useEffect(() => {
        const InspectionForms = [];
        props.inspectionFormsList.forEach(inspectionForm => {
            InspectionForms.push({
                value: inspectionForm.id,
                label: inspectionForm.name
            })
        })
        setInspectionForms(InspectionForms);
    }, [props.inspectionFormsList])
    const onAccountChange = (account) => {
        setTemp(true)
        setDisable(false);
        props.onAccountChange(account.value);
    }

    const onInspectionFormChange = (ins) => {
        const index = props.inspectionFormsList.findIndex(form => form.id === ins.value);
        const inspectionForm = props.inspectionFormsList[index];
        props.change("inspection[private]", inspectionForm.private);
        if (inspectionForm.private) {
            setDisable(true);
        } else {
            setDisable(false);
        }
    }
    // if (isEmpty(props.inspectionFormsList) || isEmpty(props.accountsList)) {
    // return (<></>)
    // } else {
    return (
        <div className="modal fade performance_modal add_account latestInspectionModal" id="addAccountModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    {((props.inspectionFormsList.length === 0 || props.accountsList.length === 0) && (props.isLoading || isInspectionForm)) && props.inspectionFormsList.length > 0 ?
                        <PerformInsepctionSkeletonModal />
                        :
                        <>
                            <div className="modal-header">
                                <h2 className="md_title" id="exampleModalLongTitle" data-test="perform_inspection_title">Perform Inspection</h2>
                                <button onClick={() => {props.cancelPerformInspectionModalClicked(); setLatestInspectionList(false)}} type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <form onSubmit={handleSubmit}>
                                {LatestInspectionList ? null : <div className="modal-body">
                                    <div className="quote_form ticket_form">
                                        <div className="addQuote_form">
                                            {/* <div className="form-group">
                                        <label className="form_title">Location</label>
                                        <Input name="inspection[location]" className="form-control input-modifier" placeholder="Pegasus" />
                                    </div> */}
                                            <div className="form-group" data-test="inpection_job_form">
                                                <label className="form_title">Job</label>
                                                <Input dataTest="inspection_job_input" name="inspection[account_id]" onChange={onAccountChange} noOptionsMessage={accountOptionsMessage} type="inputSelect" options={accounts} />
                                                <span style={{ color: '#DD2726', fontSize: '13px' }}>{!temp && props.isAccountEmpty ? 'This is a required field.' : null}</span>
                                                {/* <select className="custom-select input-modifier">
                                            <option selected="">Charles Schwab 4701</option>
                                            <option value="All">All</option>
                                            <option value="Pending for Approva">Pending for Approval</option>
                                            <option value="Sent to Clients">Sent to Clients</option>
                                            <option value="Approved by Clients">Approved by Clients</option>
                                        </select> */}
                                            </div>
                                            <div className="form-group" data-test="inpection_from_form">
                                                <label className="form_title">Form</label>
                                                <Input dataTest="inpection_form_input" instanceId="inpection_from_select" name="inspection[inspection_form_id]" onChange={onInspectionFormChange} noOptionsMessage={ratingOptionsMessage} type="inputSelect" options={inspectionForms}/>
                                                <span style={{ color: '#DD2726', fontSize: '13px' }}>{props.isFormEmpty ? 'This is a required field.' : null}</span>
                                                {/* <select className="custom-select input-modifier">
                                            <option selected="">Janitorial Inspection Form</option>
                                            <option value="All">All</option>
                                            <option value="Pending for Approva">Pending for Approval</option>
                                            <option value="Sent to Clients">Sent to Clients</option>
                                            <option value="Approved by Clients">Approved by Clients</option>
                                        </select> */}
                                            </div>
                                            <div className="form-group">
                                                <div className="checkbox_block radius_checkbox">
                                                    <div className={disable ? 'disabled_checkbox custom-control custom-checkbox' : 'custom-control custom-checkbox'}>
                                                        {disable ?
                                                            <Input type="checkbox" name="inspection[private]" className="custom-control-input" style={{ cursor: 'disable' }} id="customCheck1" checked="" disabled />
                                                            :
                                                            <Input type="checkbox" name="inspection[private]" className="custom-control-input" id="customCheck1" checked="" />
                                                        }
                                                        <label className="custom-control-label" for="customCheck1" data-test="private_inspection_checkbox"><span><img src="../images/icons/icn_lock_lg.png" /></span></label>
                                                    </div>
                                                    <p className="cst_para">Private - If checked, this inspection and results will ONLY be visible to users with private inspection permissions.</p>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className="btn_block text-center">
                                                    <button type="button" onClick={() => {showLatestInspectionList(); props.latestInspectionGoClicked(props.formStates)}} className="btn cst_btn btn_danger w-25 mr-2">Submit</button>
                                                    {
                                                        LatestInspectionList ? '' :
                                                        <button type="button" className="btn cst_btn btn-outline-secondary cancel_btnnew w-25" data-dismiss="modal" style={{transition: "0.3s"}} onClick={() => {props.cancelPerformInspectionModalClicked(); setLatestInspectionList(false)}}>Cancel</button>
                                                    }
                                                </div>
                                            </div>

                                            {/* <div className="form-group">
                                                <div className="checkbox_block radius_checkbox checkbox1">
                                                    <div className="custom-control custom-checkbox">
                                                        <Input type="checkbox" name="inspection[ticket]" className="custom-control-input" id="customCheck2" />
                                                        <label className="custom-control-label" for="customCheck2"><span className="cst_label_txt">Check, to create a ticket</span></label>
                                                    </div>
                                                </div>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>}
                                {
                                    LatestInspectionList ?
                                        <div className=" border-0 text-center px-3 pt-0" style={{transition: "0.3s"}}>
                                            <div className="form-group">
                                                {props.latestInspectionList.inspection && props.latestInspectionList.inspections.length > 0 ? <label style={{ fontSize: "15px", fontWeight: "600", color: "#151b26", textAlign: "left" }}>Last Three Inspections</label> : ''}
                                                <ul class="responsive-table latest_ins_table m-0 p-0">
                                                    <li class="table-header">
                                                        <div class="col">Inspection Id</div>
                                                        <div class="col">Job Name</div>
                                                        <div class="col">Inspection Form</div>
                                                        <div class="col">Active Status</div>
                                                        <div class="col-2"></div>
                                                    </li>
                                                    {
                                                        props.latestInspectionList ? 
                                                        <>
                                                        {
                                                            props.latestInspectionList && props.latestInspectionList.inspections.map((i) => {
                                                                return (
                                                                    <>
                                                                        <li class="table-row">
                                                                            <div class="col" data-label="Inspection Id">{i.id}</div>
                                                                            <div class="col" data-label="Account Name">{i.account_info.account_name}</div>
                                                                            <div class="col" data-label="Inspection Form">{i.inspection_form.name}</div>
                                                                            <div class="col" data-label="Customer Name">{makeFirstLetterCapital(i.active_status)}</div>
                                                                            <div class="col-2" data-label="Payment Status">
                                                                            <Link to={`/inspections/previewInspection/${i.id}`} target="_blank" data-tip="View Inspection" className="mr_20 account_edit_icn" ><i className="fas fa-eye cst_icon mr-2" style={{color: "#850037"}} aria-hidden="true"></i></Link>
                                                                            </div>
                                                                        </li>
                                                                    </>
                                                                )
                                                            })
                                                        }
                                                        {props.latestInspectionList.inspections.length < 1 ? <>
                                                        <h4>No Recent Inspection Found</h4>
                                                        </>
                                                        : ''}
                                                   
                                                        </> :
                                                        <>
                                                        <div className="loader_btn_block" style={{height: "10vh"}}>
                                                        <ClapSpinner backColor="#89073c" size={20} frontColor="#89073c8a" />
                                                    </div>
                                                        </>
                                                    }
                                                    </ul>
                                                    
                                                <button type="submit" disabled={!props.openPerformInspectionModal && !props.isAccountEmpty && !props.isFormEmpty} className="btn cst_btn btn_danger mt-2 mr-2" >Perform Inspection</button>
                                                <button type="button" className="btn cst_btn btn-outline-secondary cancel_btnnew w-25 mt-2" data-dismiss="modal" onClick={() => {props.cancelPerformInspectionModalClicked(); setLatestInspectionList(false)}}>Cancel</button>
                                                <button type="button" style={{marginLeft: '10px'}} className="btn cst_btn btn-outline-secondary cancel_btnnew w-25 mt-2" onClick={() => {props.clearLatestInspection(); setLatestInspectionList(false)}}>Go Back</button>
                                                </div>
                                        </div>
                                        : ''}
                            </form>
                        </>
                    }
                </div>
            </div>
        </div>
    )
    // }
}
const mapStateToProps = (state) => {
    let inspection_form_id = "";
    let account_id = "";
    return {
        initialValues: { inspection: { inspection_form_id, account_id }}, formStates: getFormValues('performingInspectionForm')(state)  }
}
PerformInspectionModal = reduxForm({
    form: 'performingInspectionForm',
    validate,
    enableReinitialize: true
})(PerformInspectionModal);
PerformInspectionModal = connect(mapStateToProps)(PerformInspectionModal)
export default PerformInspectionModal;