import React, { useEffect, useState } from 'react';
import { Input } from '../UI/InputElement/InputElement';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import { companySearchValidator as validate } from '../../../utility/validator/validator';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import CompaniesTable from './companiesTable';
import { routes, userRoles } from '../../../utility/constants/constants';

const getAccountsDropDown = (accounts) => {

    return accounts && accounts.map(a => {
        return {
            value: a.id,
            label: a.name
        }
    })
}
let Companies = (props) => {
    const [accountList, setAccountList] = useState(getAccountsDropDown(props.accountsList));

    useEffect(() => {
        setAccountList(getAccountsDropDown(props.accountsList))
    }, [props.accountsList])

    const noAccountOptionsMessage = (a, b) => {
        return 'Job not found';
    }
    const { handleSubmit, pristine, reset, submitting, error } = props;

    const TierOptions = [
        {
            id: '1',
            name: 'Tier A'
        }, {
            id: '2',
            name: 'Tier B'
        }, {
            id: '3',
            name: 'Tier C'
        }
    ];
    return (
        <>
            <main className="app-content wraper_content inspection_wraper">
                <div className="tab_header search_filter_cont inspection_tab_content appcontent_inner">
                    <form onSubmit={handleSubmit}>
                        <input type="hidden" value="something" />
                        <div className="row">
                            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                                <div className="app-search">
                                    <Input className="fil_search_input input-modifier" name="name" type="search" placeholder="Search By Customer" />
                                    <button className="app-search__button">
                                        <i class="fa fa-search" aria-hidden="true"></i>
                                    </button>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-12 col-sm-12">
                                <Input name="account_id" noOptionsMessage={noAccountOptionsMessage} placeholder="Job" type="inputSelect" options={accountList} />
                            </div>

                            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                                <Input name="matrix" dataTest="select_ticket_status" type="select" options={TierOptions} placeholder="Tier" className="custom-select input-modifier add_user_select" />
                            </div>

                            {/* <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                                <div className="app-search">
                                    <Input className="fil_search_input input-modifier" name="email" type="search" placeholder="Search By Email" />
                                    <button className="app-search__button">
                                        <i class="fa fa-search" aria-hidden="true"></i>
                                    </button>
                                </div>
                            </div> */}
                            <div className="col-xl-2 col-lg-4 col-md-3 col-sm-3 col-12">
                                <button style={{ minWidth: '90px' }} className={"btn cst_btn btn_danger mr-2"} id="submit" type="submit">{"Go"} </button>
                                <button style={{ minWidth: '90px' }} onClick={props.resetClicked} className={"btn cst_btn btn-outline-secondary"} id="reset" type="reset">{"Reset"} </button>
                            </div>

                            {/* {props.user.role === userRoles.ADMIN ? null :
                                <div className="col-xl-4 col-lg-4 col-md-3 col-sm-3  col-6  quotes_btn_wrap">
                                    <a onClick={props.newCompanieClicked} href="javascript:void (0)" className="btn btn-secondary cst_btn"><i className="fa fa-plus icn_plus"></i>New Customer</a>
                                </div>
                            } */}
                        </div>
                    </form>
                </div>

                <div className='mt-4'>
                    {props.user.role === userRoles.ADMIN ? null :
                        <div className=" mr-4 text-right">
                            <a onClick={props.newCompanieClicked} href="javascript:void (0)" className="btn btn-secondary cst_btn"><i className="fa fa-plus icn_plus"></i>New Customer</a>
                        </div>
                    }
                    
                </div>


                <section className="account_sec user_screen inspection_tab_content">
                    <div className="theme_switch_btn" style={{ display: "inline" }}>
                        <span className="switch_title">In-active</span>
                        <label>
                            <input type="checkbox" checked={props.activeCompanies} onChange={(e) => props.activeCompanyClicked(e)} />
                            <span></span>
                        </label>
                        <span className="ext_title">Active</span>
                    </div>
                    {/* <CSVLink filename="Users.csv" className="csv_btn btn_danger " data={convertUsersDataToCsvType(props.usersList)} >Export Csv</CSVLink><br /><br /><br /> */}
                    <div className="quotes_table_content table-responsive">
                        <div className="table quotes_table user_table_contnet user_react_table">
                            <CompaniesTable
                                {...props}
                                deleteCompanie={props.deleteCompanie}
                                companies_rows={props.companies_rows}
                                isLoading={props.isLoading}
                                onCompaniesPageSizeChange={props.onCompaniesPageSizeChange}
                                companiesList={props.companiesList ? props.companiesList : []}
                                editCompanyClicked={props.editCompanyClicked}
                                previewCompanyClicked={props.previewCompanyClicked}
                                openDeleteModel={props.openDeleteModel}
                                handleClickDeleteModel={props.handleClickDeleteModel}
                                activeCompanies={props.activeCompanies}
                            />
                        </div>

                    </div>
                </section>
            </main>
            <Dialog
                open={props.openDeleteModel}
                onClose={props.handleClose}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title" style={{ fontSize: '13', color: '#06080f' }}>
                    <h3 style={{ color: '#ab385e' }}>Are you sure?</h3>
                </DialogTitle>

                <DialogActions>
                    <button style={{ minHeight: '36px', minWidth: '80px' }} onClick={() => props.deleteCompany(props.deleteObject.id)} disabled={props.isLoading} className={props.isLoading ? "btn cst_btn btn_danger mr-2 btn-wait" : "btn cst_btn btn_danger mr-2"} id="delete" type="delete">{props.isLoading ? "" : "Delete"}</button>
                    <button style={{ minHeight: '36px', minWidth: '80px' }} onClick={props.handleDeleteClose} className={"btn cst_btn btn-outline-secondary"} id="cancel" type="cancel">Cancel</button>
                    {/* <Button onClick={props.handleClose} variant="contained" color="primary">
                    Cancel
                    </Button>


                <Button onClick={() => props.deleteAccount(props.deleteObject.id)} disabled={props.isLoading} variant="contained" color="secondary">
                    {props.isLoading ? <CircularProgress size={24} /> : 'Delete'}
                </Button> */}
                </DialogActions>
            </Dialog>
        </>
    )
}


const mapStateToProps = (state) => {
    let name = "";
    let account_id = "";
    let matrix=""
    return { initialValues: { name, account_id,matrix } }
}

Companies = reduxForm({
    form: 'searchCompanieForm',
    validate,
    enableReinitialize: true
})(Companies);

Companies = connect(mapStateToProps)(Companies)

export default Companies;