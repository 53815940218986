import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

const card = (props) => {

    return (
        <div className="col-6 col-xl-3">
            <Skeleton height={140} />
        </div>
    )
}

const graph = (props) => {
    return (
        <div className="col-xl-6 chart_cont_box">
            <Skeleton height={250} width={500} />
        </div>
    )
}
// const cards = (props) => {
//     return 
// }

const DashboardSkeleton = (props) => {
    return (
        <>
            <SkeletonTheme>
                <div className="row">
                    <div className="col-md-12">
                        <div className="app_inner_title">
                            <div className="calender_block">
                                <div className="filter_cal_box">
                                    <div className="filter_block" >
                                        <Skeleton height={40} width={50} /><Skeleton height={40} width={50} /><Skeleton height={40} width={50} />
                                    </div>

                                    <Skeleton height={40} width={40} />
                                    <Skeleton height={40} width={40} />

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- chart --> */}
                <div className="chart-content">
                    <div className="row">
                        {card(props)}
                        {card(props)}
                        {card(props)}
                        {card(props)}
                    </div>
                    <div className="row">
                        {card(props)}
                        {card(props)}
                        {card(props)}
                        {card(props)}
                    </div>
                    <div className="row">
                        <div className="user_dashboardChart">
                            <div className="row">
                                {graph(props)}
                                {graph(props)}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="user_dashboardChart">
                            <div className="row">
                                {graph(props)}
                                {graph(props)}
                            </div>
                        </div>
                    </div>
                </div>
            </SkeletonTheme>
        </>


    )
}

export default DashboardSkeleton;