import React, { useCallback, useEffect } from "react";
import { Input } from "../../../UI/InputElement/reactFormHookInputField";
import withFormHoc from "../../../../../hoc/withReactHookForm/withReactHookForm";
import { DevTool } from "@hookform/devtools";
import { useSelector } from "react-redux";

let AddStandardMatrix = (props) => {

  //useSelector For update Data
  const getUpdatedMatrixList = useSelector(
    (state) => state.BRM.updateMatrixList
  );

  const { isLoading } = useSelector((state) => state.BRM)

  //handle Form Close
  const handleCloseFn = useCallback(
    (...args) => {
      if (typeof props.handleClose == "function") {
        if (props.isEdit) {
          props.handleClose(...args);
          return;
        } else return;
      }
      return;
    },
    [props?.handleClose]
  );

  // default state value For Form.
  const MeetingName = [
    {
      value: "quality_control_visits",
      label: "Quality Control Visits",
    },
    {
      value: "vice_president_visit",
      label: "Vice President Visit",
    },
    {
      value: "president_visit_call",
      label: "President Visit/Call",
    },
    {
      value: "business_review_meeting",
      label: "Business Review Meeting",
    },
    {
      value: "business_review_report",
      label: "Business Review Report",
    },
    // {
    //   value: "none",
    //   label: "N/A",
    // },
  ];

  const Tier_A = [
    {
      value: "weekly",
      label: "Weekly",
    },
    {
      value: "monthly",
      label: "1 X Monthly",
    },
    {
      value: "semi_annual",
      label: "Semi Annual",
    },
    {
      value: "quarterly",
      label: "Quarterly",
    },
    {
      value: "none",
      label: "N/A",
    },
  ];

  const Tier_B = [
    {
      value: "bi_monthly",
      label: "2 X Month",
    },
    {
      value: "quarterly",
      label: "Quarterly",
    },
    {
      value: "annual",
      label: "Annual",
    },
    {
      value: "semi_annual",
      label: "Semi Annual",
    },
    {
      value: "none",
      label: "N/A",
    },

  ];

  const Tier_C = [
    {
      value: "monthly",
      label: "1 X Monthly",
    },
    {
      value: "semi_annual",
      label: "Semi Annual",
    },
    {
      value: "annual",
      label: "Annual",
    },
    {
      value: "none",
      label: "N/A",
    },
  ];

  const updateMatrixFrequecy = [
    {
      value: "weekly",
      label: "Weekly",
    },
    {
      value: "monthly",
      label: "1 X Monthly",
    },
    {
      value: "bi_monthly",
      label: "2 X Monthly",
    },
    {
      value: "semi_annual",
      label: "Semi Annual",
    },
    {
      value: "annual",
      label: "Annual",
    },
    {
      value: "quarterly",
      label: "Quartely",
    },
    {
      value: "none",
      label: "N/A",
    },
  ];

  // check the matrix update the value.
  const CheckTierFunction = (matrix) => {
    if (matrix === 1) {
      return "Tier A Customer";
    } else if (matrix === 2) {
      return "Tier B Customer";
    } else {
      return "Tier C Customer";
    }
  };

  const handleValidate = useCallback(() => {
    const tiera = props.getValues("tiera")
    const tierb = props.getValues("tierb")
    const tierc = props.getValues("tierc")
    if ((tiera == null || tiera == "") && ((tierb == null || tierb == "")) && ((tierc == null || tierc == ""))) return "Please select atleast One Tier"
    props.clearErrors('tierc')
    return true;
  }, [])

  // const handleValidate=()=>{
  //   const tiera=props.getValues("tiera")
  //   const tierb=props.getValues("tierb")
  //   const tierc=props.getValues("tierc")
  //   if((tiera == null || tiera=="" ) && ((tierb == null || tierb=="" )) && ((tierc == null || tierc=="" )) ) return "Please select atleast One Tier"
  //   return true;
  // } 

  return (
    <div
      className="modal fade request_quote_modal reject_quot associateUser"
      id="addstandardMatrix"
      tabindex="-1"
      role="dialog"
      aria-labelledby="rejectQuoteModalTitle"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <label for="exampleInputEmail1" className="cst-label md_title" style={{ marginTop: props?.isEdit ? '7px' : '0px' }}>
              {props.isEdit == true
                ? "Update Standard Matrix"
                : "Add Standard Matrix"}
            </label>
            <button
              type="button"
              className="close close_icn_pic"
              data-dismiss="modal"
              onClick={() => {
                handleCloseFn();
                props.reset();
              }}
            >
              <img src="/images/icons/icn_close_gray.png" alt="Close Icon" />
            </button>
          </div>

          <div className="modal-body">
            <div className="tab-content">
              <div
                id="profile"
                className="profile_tab cst_tab_content tab-pane fade show active edit_profile_modal"
              >
                <form
                  className="form_content"
                  onSubmit={props.handleSubmit((data) => {
                    props.onSubmit(data);
                  })}
                >
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <Input
                          type="inputSelect"
                          labelClasses="form-label"
                          control={props.control}
                          controller={props.controller}
                          selectLabel={"Meeting Name"}
                          placeholder={"Please Select"}
                          selectName={"name"}
                          id={"name"}
                          selectOptions={MeetingName}
                          required={props.register(`name`, {
                            required: "This field is required",
                          })}
                          validationErrorMessage={props?.errors?.name?.message}
                          selectClasses="form-select"
                          disabled={false}
                        />
                      </div>
                      <div className="form-group">
                        <Input
                          type="inputSelect"
                          labelClasses="form-label"
                          control={props.control}
                          controller={props.controller}
                          selectLabel={
                            props.isEdit
                              ? CheckTierFunction(getUpdatedMatrixList?.matrix)
                              : "Tier A Customer"
                          }
                          placeholder={"Please Select"}
                          selectName={"tiera"}
                          id={"tiera"}
                          selectOptions={
                            props?.isEdit ? updateMatrixFrequecy : Tier_A
                          }
                          required={props.register(`tiera`, {
                            // required: "This field is required",
                            validate: handleValidate
                          })}
                          // validationErrorMessage={props.errors?.tiera?.message}
                          selectClasses="form-select"
                          disabled={false}
                          customMenuList={{

                            height: "200px",
                            '::-webkit-scrollbar': {
                              width: '4px'
                            },
                            '::-webkit-scrollbar-track': {
                              background: '#f1f1f1'
                            },
                            '::-webkit-scrollbar-thumb': {
                              background: "a2a2a2"
                            },
                            '::-webkit-disabled-scrollbar-thumb:hover': {
                              background: '#555'
                            }
                          }}
                          customMenu={props.isEdit ? { position: "relative" } : {}}
                        />
                      </div>
                      {!props?.isEdit ? (
                        <div
                          className="form-group"
                          style={{ display: `${props?.isEdit && "none"}` }}
                        >
                          <Input
                            type="inputSelect"
                            labelClasses="form-label"
                            control={props.control}
                            controller={props.controller}
                            selectLabel="Tier B Customer"
                            placeholder={"Please Select"}
                            selectName={"tierb"}
                            id={"tierb"}
                            selectOptions={Tier_B}
                            required={props.register(`tierb`, {
                              // required: "This field is required",
                              validate: handleValidate
                            })}
                            // validationErrorMessage={
                            //   props.errors?.tierb?.message
                            // }
                            selectClasses="form-select"
                            disabled={false}
                          />
                        </div>
                      ) : null}
                      {!props?.isEdit ? (
                        <div className="form-group">
                          <Input
                            type="inputSelect"
                            labelClasses="form-label"
                            control={props.control}
                            controller={props.controller}
                            selectLabel="Tier C Customer"
                            placeholder={"Please Select"}
                            selectName={"tierc"}
                            id={"tierc"}
                            selectOptions={Tier_C}
                            required={props.register(`tierc`, {
                              // required: "This field is required",
                              validate: handleValidate
                            })}
                            validationErrorMessage={
                              props.errors?.tierc?.message
                            }
                            selectClasses="form-select"
                            disabled={false}
                          />
                        </div>
                      ) : null}

                      {
                        props.isEdit ?
                         <>
                          <div style={{ display: 'flex',alignItems: 'center', flexDirection: "row",justifyContent:"flex-start" }}>
                            <div style={{ display: 'flex', alignItems: 'center', flexDirection: "row",width:"auto", }} className="container-brm">
                                <Input
                                  type="radio"
                                  id="active_status_active"
                                  name={`active_status`}
                                  formControlClasses="custom-control-input"
                                  placeholder="Type here"
                                  
                                  label=" Add meeting Notes"
                                  formControlProps={props.register(
                                    `active_status`,
                                    {
                                      required: "This field is required",
                                      maxLength: 80,
                                    }
                                  )}
                                  validationErrorMessage={
                                    props.errors?.notes
                                      ?.message ?? ""
                                  }
                                  disabled={props?.isView}
                                  value="1"
                                />
                               <label className="checkmark" htmlFor="active_status_active"></label>
                            <label htmlFor="active_status_active" style={{  marginTop:'-2px', fontSize:'15px'}}>Active</label>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', flexDirection: "row" }} className="container-brm">
                                <Input
                                  type="radio"
                                  id="active_status_inactive"
                                  name={`active_status`}
                                  formControlClasses="custom-control-input"
                                  placeholder="Type here"
                                  
                                  label=" Add meeting Notes"
                                  formControlProps={props.register(
                                    `active_status`,
                                    {
                                      required: "Please select one of these option.",
                                      maxLength: 80,
                                    }
                                  )}
                                  validationErrorMessage={
                                    props.errors?.notes
                                    ?.message ?? ""
                                  }
                                  disabled={props?.isView}
                                  value="0"
                                />
                            <label className="checkmark" htmlFor="active_status_inactive"></label>
                            <label htmlFor="active_status_inactive" style={{  marginTop:'-2px', fontSize:'15px'}}>InActive</label>                            
                          </div>
                          </div>
                          {
                              props.errors?.active_status?.message ? <span id="form-error" style={{ color: '#DD2726', fontSize: '13px' }} >{props.errors?.active_status?.message} </span>: null
                            }
                          </>


                          : null
                      }
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      data-test="submit_button"
                      type="submit"
                      disabled={isLoading}
                      className={
                        isLoading
                          ? "btn cst_btn btn_danger mr-2 btn-wait"
                          : "btn cst_btn btn_danger mr-2"
                      }
                    >
                      {isLoading
                        ? ""
                        : props.isEdit == true
                          ? "Update"
                          : "Submit"}
                    </button>
                    <button
                      onClick={() => {
                        handleCloseFn();
                        props.reset();
                      }}
                      type="button"
                      className="btn btn-outline-secondary cst_btn"
                      data-dismiss="modal"
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <DevTool control={props.control} />
    </div>
  );
};

const AddStandardMatrixHOC = withFormHoc(AddStandardMatrix);
export default AddStandardMatrixHOC;