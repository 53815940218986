import React, { useEffect, useState } from 'react';
import { Input } from '../../UI/InputElement/InputElement';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import { companySearchValidator as validate } from '../../../../utility/validator/validator';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import { routes, userRoles } from '../../../../utility/constants/constants';
import APIAccessTable from './APIAccessTable';
import PerformAPIAccessModal from './performAPIAccessModal'


let APIAccess = (props) => {
    const accessTypeOptions = [
        {label: 'Read', value :'read'},
        {label: 'Write', value :'write'},
        {label: 'Both', value :'both'},
    ]

    const addcompanies = (values) =>{
        
      props.companyAccessFormValue(values)
    }
    const { handleSubmit, pristine, reset, submitting, error } = props;
    return (
        <>
            <main className="app-content wraper_content inspection_wraper">
                <div className="tab_header search_filter_cont inspection_tab_content appcontent_inner">
                    <form onSubmit={handleSubmit}>
                        <input type="hidden" value="something" />
                        <div className="row">
                            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                                <div className="app-search">
                                    <Input className="fil_search_input input-modifier" name="name" type="search" placeholder="Search By Customer" />
                                    <button className="app-search__button">
                                        <i class="fa fa-search" aria-hidden="true"></i>
                                    </button>
                                </div>
                            </div> 
                            <div className="col-xl-3 col-lg-4 col-md-12 col-sm-12">
                                <Input name="account_id" placeholder="Search By Access Type" type="inputSelect" options={accessTypeOptions} />
                            </div>
                            <div className="col-xl-2 col-lg-4 col-md-3 col-sm-3 col-12">
                                <button style={{ minWidth: '90px' }} className={"btn cst_btn btn_danger mr-2"} id="submit" type="submit">{"Go"} </button>
                                <button style={{ minWidth: '90px' }} onClick={props.resetClicked} className={"btn cst_btn btn-outline-secondary"} id="reset" type="reset">{"Reset"} </button>
                            </div>

                            {props.user.role === userRoles.ADMIN ? null :
                                <div className="col-xl-4 col-lg-4 col-md-3 col-sm-3  col-6 text-right quotes_btn_wrap">
                                    <a href="javascript:void (0)" className="btn btn-secondary cst_btn" data-toggle="modal" data-backdrop="static" data-keyboard="false" data-target="#addAPIAccess" onClick={(e) => {
                                        var customClick = setInterval(() => {
                                            if(document.getElementById("addAPIAccess")) {
                                                document.getElementById("customRadio1").click();
                                                document.getElementById("customRadio2").click();
                                                clearInterval(customClick);
                                            }
                                        }, 100);
                                    }}><i className="fa fa-plus icn_plus"></i>New Token</a>
                                </div>
                            }
                        </div>
                    </form>
                </div>


                <section className="account_sec user_screen inspection_tab_content">
                <div className="theme_switch_btn" style={{display: "inline"}}>
                        <span className="switch_title">In-active</span>
                        <label>                                
                            <input type="checkbox" checked={props.activeCompanies} onChange={(e) => props.activeCompanyClicked(e)}/>
                            <span></span>
                        </label>
                        <span className="ext_title">Active</span>
                    </div>
                    {/* <CSVLink filename="Users.csv" className="csv_btn btn_danger " data={convertUsersDataToCsvType(props.usersList)} >Export Csv</CSVLink><br /><br /><br /> */}
                    <div className="quotes_table_content table-responsive">
                        <div className="table quotes_table user_table_contnet user_react_table">
                            <APIAccessTable
                                {...props}
                                deleteCompanie={props.deleteCompanie}
                                companies_rows={props.companies_rows}
                                isLoading={props.isLoading}
                                onCompaniesPageSizeChange={props.onCompaniesPageSizeChange}
                                companiesList={props.companiesList ? props.companiesList : []}
                                editCompanyClicked={props.editCompanyClicked}
                                previewCompanyClicked={props.previewCompanyClicked}
                                openDeleteModel={props.openDeleteModel}
                                handleClickDeleteModel={props.handleClickDeleteModel}
                                activeCompanies={props.activeCompanies}
                                editCompanyaccessClicked={props.editCompanyaccessClicked}
                                />
                        </div>

                    </div>
                </section>
            </main>
            <Dialog
                open={props.openDeleteModel}
                onClose={props.handleClose}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title" style={{ fontSize: '13', color: '#06080f' }}>
                    <h3 style={{ color: '#ab385e' }}>Are you sure?</h3>
                </DialogTitle>

                <DialogActions>
                    <button style={{ minHeight: '36px', minWidth: '80px' }} onClick={() => props.deleteCompany(props.deleteObject.id)} disabled={props.isLoading} className={props.isLoading ? "btn cst_btn btn_danger mr-2 btn-wait" : "btn cst_btn btn_danger mr-2"} id="delete" type="delete">{props.isLoading ? "" : "Delete"}</button>
                    <button style={{ minHeight: '36px', minWidth: '80px' }} onClick={props.handleDeleteClose} className={"btn cst_btn btn-outline-secondary"} id="cancel" type="cancel">Cancel</button>
                   
                </DialogActions>
            </Dialog>
            <PerformAPIAccessModal
                isLoading={props.isLoading}
                onSubmit={addcompanies}
                companyDropdownList={props.companyDropdownList ? props.companyDropdownList : []}
                history={props.history}
                cancelCompanyModel={props.cancelCompanyModel}
                isEdit={props.isEdit}
            />
        </>
    )
}


const mapStateToProps = (state) => {
    let name =  "";
    let account_id = "";

    return { initialValues: { name, account_id } }
}

APIAccess = reduxForm({
    form: 'searchCompanieForm',
    validate,
    enableReinitialize: true
})(APIAccess);

APIAccess = connect(mapStateToProps)(APIAccess)

export default APIAccess;