import React, { Component } from "react";
import { MagicSpinner, PushSpinner, SwishSpinner, WaveSpinner, ClapSpinner } from 'react-spinners-kit';
import { reset, getFormValues } from 'redux-form';

import * as actions from '../../../redux/actions/index';
import { connect } from 'react-redux';
import { GetFormatedDate, getWeekStartAndEndDay, getNextMonthDate, getPreviousMonthDate, getNextYearDate, getPreviousYearDate, get_next_week_start, get_previous_week_start, getUser } from '../../../utility/utility';
import { currentData, userRoles, routes } from '../../../utility/constants/constants';
import WeekMonthYearLists from "./ScanAnalyticsComponents/Filter";
import FilterScanReport from "./ScanAnalyticsComponents/FilterScanReport";
import CountBlock from "./ScanAnalyticsComponents/ReportCountBlock";
import ScanAnalyticsChart from "./ScanAnalyticsComponents/ScanAnalyticsChart";
class ScanAnalytics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isWeek: true,
      isMonth: false,
      isYear: false,
      currentDate: this.props.currentDate,
      isPDFView: false,
      mode: ''
    }
    this.handleFilterSearch = this.handleFilterSearch.bind(this);
  }

  previousWeekMonthYearCliked = () => {
    if (this.props.isWeek) {
      const date = get_previous_week_start(this.props.currentDate);
      this.props.getScanAnalyticsData({ option: currentData.WEEK, date: GetFormatedDate(date), mode: this.state.mode});
      this.props.updateCurrentDate(date);
    } else if (this.props.isMonth) {
      if (this.props.currentDate.getMonth() === 0) {
        const date = getPreviousYearDate(this.props.currentDate);
        this.props.getScanAnalyticsData({ option: currentData.MONTH, date: GetFormatedDate(date), mode: this.state.mode });
        this.props.updateCurrentDate(getPreviousYearDate(this.props.currentDate));
      } else {
        const date = getPreviousMonthDate(this.props.currentDate);
        this.props.getScanAnalyticsData({ option: currentData.MONTH, date: GetFormatedDate(date), mode: this.state.mode });
        this.props.updateCurrentDate(getPreviousMonthDate(this.props.currentDate));
      }

    } else if (this.props.isYear) {
      const date = getPreviousYearDate(this.props.currentDate);
      this.props.getScanAnalyticsData({ option: currentData.YEAR, date: GetFormatedDate(date), mode: this.state.mode });
      this.props.updateCurrentDate(getPreviousYearDate(this.props.currentDate));
    }
    this.props.resetFilterForm();
  }
  nextWeekMonthYearCliked = () => {

    if (this.props.isWeek) {
      const date = get_next_week_start(this.props.currentDate);
      this.props.getScanAnalyticsData({ option: currentData.WEEK, date: GetFormatedDate(date), mode: this.state.mode });
      // this.setState({
      //     currentDate: date
      // });
      this.props.updateCurrentDate(date);

    } else if (this.props.isMonth) {
      if (this.props.currentDate.getMonth() === 11) {
        const date = getNextYearDate(this.props.currentDate);
        this.props.getScanAnalyticsData({ option: currentData.MONTH, date: GetFormatedDate(date), mode: this.state.mode });
        this.props.updateCurrentDate(getNextYearDate(this.props.currentDate));
      } else {
        const date = getNextMonthDate(this.props.currentDate);
        this.props.getScanAnalyticsData({ option: currentData.MONTH, date: GetFormatedDate(date), mode: this.state.mode });
        this.props.updateCurrentDate(getNextMonthDate(this.props.currentDate));
      }
    } else if (this.props.isYear) {

      const date = getNextYearDate(this.props.currentDate);
      this.props.getScanAnalyticsData({ option: currentData.YEAR, date: GetFormatedDate(date), mode: this.state.mode });
      this.props.updateCurrentDate(getNextYearDate(this.props.currentDate));
    }
    this.props.resetFilterForm();
  }
  viewModeClicked = (viewMode) => {
    if (viewMode === currentData.WEEK) {
      this.props.updateWeekMonthYear(true, false, false);
      this.props.getScanAnalyticsData({ option: viewMode, date: GetFormatedDate(this.props.currentDate), mode: this.state.mode });


    } else if (viewMode === currentData.MONTH) {
      this.props.updateWeekMonthYear(false, true, false)
      this.props.getScanAnalyticsData({ option: viewMode, date: GetFormatedDate(this.props.currentDate), mode: this.state.mode });

    } else if (viewMode === currentData.YEAR) {
      this.props.updateWeekMonthYear(false, false, true)
      this.props.getScanAnalyticsData({ option: viewMode, date: GetFormatedDate(this.props.currentDate), mode: this.state.mode });
    }
    this.props.resetFilterForm();
  }
  componentDidMount() {
    // if (!this.props.dashboardData) {
    const user = getUser();
    this.setState({mode: user.mode});

    let DataType = null;
    if (this.props.isWeek) {
      DataType = currentData.WEEK
    } else if (this.props.isMonth) {
      DataType = currentData.MONTH
    } else if (this.props.isYear) {
      DataType = currentData.YEAR
    }
    let account_id = new URLSearchParams(this.props.history.location.search, { ignoreQueryPrefix: true }).get('account');
    let date = new URLSearchParams(this.props.history.location.search, { ignoreQueryPrefix: true }).get('date');
    let end_date = new URLSearchParams(this.props.history.location.search, { ignoreQueryPrefix: true }).get('end_date');
    if (account_id && date && end_date) {
      this.props.getScanAnalyticsData({ date, end_date, account_id, mode: user.mode });
    } else  {
      this.props.getScanAnalyticsData({ option: DataType, date: GetFormatedDate(this.props.currentDate), mode: user.mode });
    }
    this.props.getAllAreas('');
    // this.props.nonLoggedInFrontlines();
  }

  handleFilterSearch(e) {
    // e.preventDefault();
    let date, end_date, option, account_id, area_id;
    if (this.props.analyticsFormValues.account_ids && typeof this.props.analyticsFormValues.account_ids.value === "string") account_id = this.props.analyticsFormValues.account_ids.value;
    if (this.props.analyticsFormValues.area_ids && typeof this.props.analyticsFormValues.area_ids.value === "string") area_id = this.props.analyticsFormValues.area_ids.value;
    if (this.props.analyticsFormValues.fromDate) {
      date = GetFormatedDate(this.props.analyticsFormValues.fromDate);
      end_date = GetFormatedDate(this.props.analyticsFormValues.toDate);
    }
    if (!date) {
      if (this.props.isWeek) {
        option = currentData.WEEK
      } else if (this.props.isMonth) {
        option = currentData.MONTH
      } else if (<this className="props">`    </this>.isYear) {
        option = currentData.YEAR
      }
      date = GetFormatedDate(this.props.currentDate);
    }
    this.props.getScanAnalyticsData({ date, end_date, account_id, area_id, option, mode: this.state.mode });
  }
  render() {
    return (<div className="admin_dashboard appcontent_Inner">
      <WeekMonthYearLists {...this.props}
        previousWeekMonthYearCliked={this.previousWeekMonthYearCliked}
        nextWeekMonthYearCliked={this.nextWeekMonthYearCliked}
        viewModeClicked={this.viewModeClicked}
      />
      {
        this.props.scanAnalyticsData ? <CountBlock {...this.props} /> : ""
      }
      <div className="gray_bg_wrap">
        <FilterScanReport onSubmit={this.handleFilterSearch} {...this.props} />
      </div>

      {this.props.isLoading ?
        <div  >
          <ClapSpinner backColor="#89073c" size={40} frontColor="#89073c8a" />
        </div>
        : (this.props.scanAnalyticsData ?
          <>
            <ScanAnalyticsChart {...this.props} />
          </> : "")}
    </div>
    )
  }
}


const mapStateToProps = (state) => {
  return {
    nonLoggedInFrontlineData: state.adminOrSuperAdminReducer.noneLoggedInFrontlinData,
    scanAnalyticsData: state.adminOrSuperAdminReducer.scanAnalyticsData,
    allAreaData: state.adminOrSuperAdminReducer.allAreaData,
    isLoading: state.adminOrSuperAdminReducer.isLoading,
    currentDate: state.adminOrSuperAdminReducer.currentDate,
    isWeek: state.adminOrSuperAdminReducer.isWeek,
    isMonth: state.adminOrSuperAdminReducer.isMonth,
    isYear: state.adminOrSuperAdminReducer.isYear,
    user: state.authReducer.user,
    formStates: getFormValues('analyticsForm')(state)
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    nonLoggedInFrontlines: () => dispatch(actions.nonLoggedInFrontlines()),
    getAllAreas: (data) => dispatch(actions.getAllAreaData(data)),
    getScanAnalyticsData: (data) => dispatch(actions.getScanAnalyticsData(data)),
    updateCurrentDate: (date) => dispatch(actions.updateCurrentDate(date)),
    updateWeekMonthYear: (isWeek, isMonth, isYear) => dispatch(actions.updateWeekMonthYear(isWeek, isMonth, isYear)),
    previewTicketClicked: (ticket) => dispatch(actions.previewTicketClicked(ticket)),
    resetFilterForm: () => dispatch(reset("analyticsForm"))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ScanAnalytics);