import React, { Component } from 'react';
import * as actions from '../../../../redux/actions/index';
import { RolesOptions, userRoles } from '../../../../utility/constants/constants'
import { routes, address_attributes, MinImageResolution } from '../../../../utility/constants/constants';
import { connect } from 'react-redux';
import { extractExtention } from '../../../../utility/utility';
import { decode, encode } from 'base64-arraybuffer';
import isEmpty from '../../../../utility/IsEmptyValidator';
import PriorEscPreviewTicket from '../../../component/PriorEscalatorTicket/PriorEscPreviewTicket/priorEscPreviewTicket';
import { animateScroll as scroll} from 'react-scroll'
import errorHandler from '../../../../utility/errorHandler/errorHandler';
const cloneDeep = require('clone-deep');

class PriorEscPreviewTicketContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            // portfolio properties
            faultImages: 0,
            isImagesEmpty: false,
            imageName: '',
            delete_image_object: {
                commentIndex: null,
                attachmentIndex: [],
                urlId: []
            },
            imageUrl: null,
            isCommentLoading: false,
            isUpdateComment: false,
            ticket: cloneDeep(this.props.ticket),
            // uploadingImagesLength = 0,
            isCommentAndResolveLoading: false,
            base64: [],
            // savedPortfolioURls: this.props.ticket.attachments_attributes ? this.props.ticket.attachments_attributes : [],
            AllFileTypes: [],
            fileType: '',
        }
        this.inputOpenFileRef = React.createRef();
        this.onPortfolioFileChange = this.onPortfolioFileChange.bind(this);
        this.removeImage = this.removeImage.bind(this);
        this.choosePhotoClicked = this.choosePhotoClicked.bind(this);
    }

    componentDidUpdate = (PrevProps, PrevState) => {
        if (this.props.ticket !== PrevProps.ticket) {
            if (this.props.ticket.comments_attributes && this.props.ticket.comments_attributes.length !== 0 && !this.state.isUpdateComment &&
                (this.props.ticket.comments_attributes[this.props.ticket.comments_attributes.length - 1]
                    .attachments_attributes.length !== 0) && this.state.base64.length !== 0) {

                this.setState({
                    isCommentLoading: false,
                    base64: [],
                    fileType: '',
                    imageName: ''
                })
            } else if (this.state.base64.length === 0) {
                this.setState({
                    isCommentLoading: false
                })
            } else {
                this.setState({
                    isUpdateComment: false
                })
            }

            this.setState({
                ticket: this.props.ticket
            })
        }
    }

    componentDidMount = () => {
        if (this.props.match.params.ticketId) {
            this.props.getEditTicket(this.props.match.params.ticketId);
        }
        scroll.scrollToTop();
    }

    nextClicked = () => {
        this.props.prior_ticketIndex && this.props.prior_ticketIndex.map((ins, i)=> {
            if(this.props.match.params.ticketId === ins) {
            this.props.previewTicketClicked({id: this.props.prior_ticketIndex[i+1]});
            this.props.getEditTicket(this.props.prior_ticketIndex[i+1]);
            }
        })
    }

    previousClicked = () => {
        this.props.prior_ticketIndex && this.props.prior_ticketIndex.map((ins, i)=> {
            if(this.props.match.params.ticketId === ins) {
            this.props.previewTicketClicked({id: this.props.prior_ticketIndex[i-1]});
            this.props.getEditTicket(this.props.prior_ticketIndex[i-1]);
            }
        })
    }

    componentWillUnmount() {
        this.props.resetObjects();
    }

    choosePhotoClicked = () => {
        this.inputOpenFileRef.current.click();
    }

    onPortfolioFileChange = (e) => {
        this.setState({
            faultImages: 0
        });
        console.log(this.state.base64);
        let files = e.target.files;
        let imageName = [], fileType = [], AllFileTypes = [], base64 = [];
        const that = this;
        // for (let i = 0; i < files.length; i++) {
        function readFile(index) {
            if (index < files.length) {
                let reader = new FileReader();
                let file = files[index];
                imageName.push(file.name)
                fileType.push(file.type)
                AllFileTypes.push(file.type)
                console.log(file, "file<<<<<<")
                if (file.type.match('image')) {
                    console.log(reader, "reader<<<<<")
                    reader.onload = (e) => {
                        var image = new Image();
                        image.src = reader.result;
                        image.onload = function () {
                            // alert('width' + image.width + 'height' + image.height);
                            // if (image.width >= MinImageResolution.width && image.height >= MinImageResolution.height) {
                            base64.push(e.target.result);
                            if (index === files.length - 1) {
                                //   
                                that.setState(prevState => ({
                                    imageName: [...prevState.imageName, ...imageName],
                                    fileType: [...prevState.fileType, ...fileType],
                                    AllFileTypes: [...prevState.AllFileTypes, ...AllFileTypes],
                                    // arrayBuffer: [...prevState.arrayBuffer, decode(reader.result)],
                                    base64: [...prevState.base64, ...base64]
                                }))
                            }
                            // }
                            // else {
                            //     that.setState(prevState => ({
                            //         faultImages: prevState.faultImages + 1
                            //     }))
                            // }
                            readFile(index + 1);
                        };

                    }
                }

                // reader.readAsArrayBuffer(file)
                reader.readAsDataURL(file)
            }
        }

        readFile(0);
    }

    removeImage = (url, fileTypeIndex) => {

        let updatedUrls = [...this.state.base64];
        let updatedFileTypes = [...this.state.fileType]
        let updatedImageNames = [...this.state.imageName]

        let urlIndex = this.state.base64.findIndex(function (u) {
            return u === url
        })
        updatedUrls.splice(urlIndex, 1);
        updatedFileTypes.splice(fileTypeIndex, 1)
        updatedImageNames.splice(fileTypeIndex, 1)

        this.setState({
            base64: updatedUrls,
            fileType: updatedFileTypes,
            imageName: updatedImageNames
        })

    }

    addTicketComment = (values, setEditCommentObject, updateCommentId) => {

        if (setEditCommentObject) {
            // setEditCommentObject(null);
            this.setState({
                isUpdateComment: true
            })
        }

        let ticket = cloneDeep(values);

        // this.state.delete_image_object.attachmentIndex.forEach(attIndex => {
        if (ticket.id) {
            ticket.ticket.comments_attributes[this.state.delete_image_object.commentIndex]['delete_image_ids'] =
                this.state.delete_image_object.urlId
        }
        // .attachments_attributes.map((a, i) => {
        //     if (i === attIndex) {
        //         a['delete_image_ids'] = [];
        //         a.delete_image_ids.push(this.state.delete_image_object.urlId[attIndex]);
        //     }
        // })
        // })



        // if(ticket.ticket.comments_attributes) {
        //     ticket.ticket.comments_attributes[ticket.ticket.comments_attributes.length - 1]['user_id'] = this.props.user.id;
        // }
        const arrayBuffer = [];

        if (this.state.base64.length > 0) {

            this.state.base64.map((url, i) => {
                if (!url.photo_urls) {
                    let base_photo = null;
                    const image = url.split(',');
                    base_photo = image[1];

                    arrayBuffer.push(decode(base_photo))
                }

            })
            // this.props.getCommentsPresignedUrl(values, extentions, arrayBuffer);
        }
        const extentions = extractExtention(this.state.imageName);
        //  else {
        this.setState({
            isCommentLoading: true
        })
        // ticket.ticket['platform'] = 'web'
        ticket.ticket['status'] = 'accepted';
        this.props.resolveTicket(ticket, extentions, arrayBuffer, updateCommentId, setEditCommentObject, this.state.delete_image_object.urlId);
        // }

    }

    addTicketCommentAndResolve = (values, setEditCommentObject, updateCommentId) => {
        if (setEditCommentObject) {
            // setEditCommentObject(null);
            this.setState({
                isUpdateComment: true
            })
        }
        const ticket = cloneDeep(values);
        this.state.delete_image_object.attachmentIndex.forEach(attIndex => {
            ticket.ticket.comments_attributes[this.state.delete_image_object.commentIndex]
                .attachments_attributes.map((a, i) => {
                    if (i === attIndex) {
                        a['delete_image_ids'].push(this.state.delete_image_object.urlId[attIndex])
                    }
                })
        })
        if(this.props.user.role === userRoles.FRONTLINE) {
            if(ticket.ticket.comments_attributes) {
                ticket.ticket.comments_attributes[ticket.ticket.comments_attributes.length - 1]['user_id'] = this.props.user.id;
            }
        }
        ticket.ticket['status'] = 'resolved';
        ticket.ticket['end_time'] = new Date();
        // ticket.ticket['platform'] = 'web'
        const arrayBuffer = [];
        if (this.state.base64.length > 0 && ticket.ticket.comments_attributes.length < 1) {
            errorHandler('Please Enter Comment')
            return
        }
        if (this.state.base64.length > 0 && ticket.ticket.comments_attributes.length > 0) {
            if(ticket.ticket.comments_attributes[0] && !ticket.ticket.comments_attributes[0].description){
                errorHandler('Please Enter Comment')
                return
            }
        }
        if (this.state.base64.length > 0) {

            this.state.base64.map((url, i) => {
                if (!url.photo_urls) {
                    let base_photo = null;
                    const image = url.split(',');
                    base_photo = image[1];

                    arrayBuffer.push(decode(base_photo))
                }

            })
        }
        const extentions = extractExtention(this.state.imageName);
        // this.props.getCommentsPresignedUrl(ticket, extentions, arrayBuffer);
        // } else {
        this.setState({
            isCommentAndResolveLoading: true
        })
        this.props.resolveTicket(ticket, extentions, arrayBuffer, updateCommentId, setEditCommentObject);
        // }
    }

    removeUrls = (url, index) => {
        let Ticket = cloneDeep(this.state.ticket);
        let updatedImageObject = []
        // if (url.photo_urls) {
        //     this.setState(prevState => ({
        //         delete_image_ids: [...prevState.delete_image_ids, url.id]
        //     }))
        // }

        let urlIndex = Ticket.comments_attributes[index].attachments_attributes.findIndex(function (attachments) {
            return attachments.id === url.id
        })

        updatedImageObject = {
            commentIndex: index,
            attachmentIndex: [...this.state.delete_image_object.attachmentIndex, urlIndex],
            urlId: [...this.state.delete_image_object.urlId, url.id]
        }

        if (url.photo_urls) {
            this.setState({
                delete_image_object: updatedImageObject
            })
        }

        Ticket.comments_attributes[index].attachments_attributes.splice(urlIndex, 1);
        this.setState({
            ticket: Ticket
        })

    }

    cancelEditClicked = () => {
        this.setState({
            delete_image_object: {
                commentIndex: null,
                attachmentIndex: [],
                urlId: []
            },
            base64: [],
            imageName: [],
            fileType: [],
            AllFileTypes: [],
            ticket: this.props.ticket
        })
    }

    render() {

        return (
            <PriorEscPreviewTicket
                {...this.props}
                addTicketComment={this.addTicketComment}
                addTicketCommentAndResolve={this.addTicketCommentAndResolve}
                ticket={this.state.ticket}
                cancelEditClicked={this.cancelEditClicked}
                removeUrls={this.removeUrls}
                isCommentLoading={this.state.isCommentLoading}
                isCommentAndResolveLoading={this.state.isCommentAndResolveLoading}
                isLoading={this.props.isLoading}
                user={this.props.user}
                portfolioImages={this.state.base64}
                reference={this.inputOpenFileRef}
                choosePhotoClicked={this.choosePhotoClicked}
                onPortfolioFileChange={this.onPortfolioFileChange}
                removeImage={this.removeImage}
                previousClicked={this.previousClicked}
                nextClicked={this.nextClicked}
                prior_ticketIndex={this.props.prior_ticketIndex}
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.authReducer.user,
        isLoading: state.adminOrSuperAdminReducer.isLoading,
        usersList: state.adminOrSuperAdminReducer.usersList,
        ticket: state.adminOrSuperAdminReducer.ticket,
        ratingList: state.adminOrSuperAdminReducer.ratingList,
        accountsList: state.adminOrSuperAdminReducer.accountsList,
        inspectionForm: state.adminOrSuperAdminReducer.inspectionForm,
        prior_ticketIndex: state.adminOrSuperAdminReducer.prior_ticketIndex,
        totalTickets: state.adminOrSuperAdminReducer.totalTickets
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateInspectionForm: (inspectionForm) => dispatch(actions.updateInspectionForm(inspectionForm)),
        resetObjects: () => dispatch(actions.resetObjects()),
        previewTicketClicked: (ticket) => dispatch(actions.priorEscPreviewTicketClicked(ticket)),
        getEditTicket: (id) => dispatch(actions.getEditTicket(id)),
        // getCommentsPresignedUrl: (ticket, extentions, arrayBuffer) => dispatch(actions.getCommentsPresignedUrl(ticket, extentions, arrayBuffer)),
        resolveTicket: (ticket, extentions, arrayBuffer, updateCommentId, setEditCommentObject, idsToDelete) => dispatch(actions.resolveTicket(ticket, extentions, arrayBuffer, updateCommentId, setEditCommentObject, idsToDelete))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PriorEscPreviewTicketContainer);