import React from 'react';
import HolidaysTable from './HolidaysTable';
import NewHoliday from './NewHolidayModal';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';

const Holidays = (props) => {
     
    return (
        <>
            <div className="row">
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 text-right accounts_btn_wrap">
                    <a href="JavaScript:void(0);" onClick={props.openPopup} data-toggle="modal" data-target="#holidayModal" data-backdrop="static" className="btn btn-secondary cst_btn"><i className="fa fa-plus icn_plus"></i>Holiday</a>
                </div>
            </div>
            <section className="account_sec user_screen inspection_tab_content">
                <div className="quotes_table_content table-responsive">
                    <div className="table quotes_table user_table_contnet user_react_table">

                        <HolidaysTable
                            handleClickDeleteModel={props.handleClickDeleteModel}
                            editHolidayClicked={props.editHolidayClicked}
                            {...props} />
                    </div>
                </div>
            </section>

            <Dialog
                open={props.openDeleteModel}
                onClose={props.handleClose}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title" style={{ fontSize: '13', color: '#06080f' }}>
                    <h3 style={{ color: '#ab385e' }}>Are you sure?</h3>
                </DialogTitle>

                <DialogActions>
                    <button style={{ minHeight: '36px', minWidth: '80px' }} onClick={() => props.deleteHoliday(props.deleteObject.id)} disabled={props.isHolidaysLoading} className={props.isHolidaysLoading ? "btn cst_btn btn_danger mr-2 btn-wait" : "btn cst_btn btn_danger mr-2"} id="delete" type="delete">{props.isHolidaysLoading ? "" : "Delete"}</button>
                    <button style={{ minHeight: '36px', minWidth: '80px' }} onClick={props.handleDeleteClose} className={"btn cst_btn btn-outline-secondary"} id="cancel" type="cancel">Cancel</button>
                    {/* <Button onClick={props.handleClose} variant="contained" color="primary">
                        Cancel
                        </Button>


                    <Button onClick={() => props.deleteAccount(props.deleteObject.id)} disabled={props.isLoading} variant="contained" color="secondary">
                        {props.isLoading ? <CircularProgress size={24} /> : 'Delete'}
                    </Button> */}
                </DialogActions>
            </Dialog>
            {/* <!-- Holiday Modal --> */}
           
        </>
    )
}

export default Holidays;