import React, {useEffect, useState} from 'react';
import ReactTable from 'react-table-v6';
import '../../../../../styles/custom.css';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeFirstLetterCapital, CustomNoDataComponent } from '../../../../../utility/utility';
import { MagicSpinner, PushSpinner, SwishSpinner, WaveSpinner, ClapSpinner } from 'react-spinners-kit';
import 'react-table-v6/react-table.css';
import { useSelector, useDispatch } from 'react-redux';
import { FaArrowDown, FaArrowUp } from "react-icons/fa";
import * as actions from '../../../../../redux/actions/index';
import { userRoles } from '../../../../../utility/constants/constants';
import ImageLoader from 'react-imageloader';
import {FLOOR_NO} from "../../../../../utility/constants/constants";

const AccountsUserQrTable = (props) => {
  const table = React.createRef();
  const [currentLayout, setLayout] = useState({
    col: 2
  });
  const [totalRow, setTotalSize] = useState(8);
  const [pageSize, setPageSize] = useState(1);
  const [openModel, setOpen] = useState(false);
  const [openQrModel, setQROpen] = useState(false);
  const [currentQrArea, setCurrentQrArea] = useState(false);
  const [currentArea, setCurrentArea] = useState(null);
  const dispatch = useDispatch();
  //const SearchValue = useSelector(state => state.form.searchAccountAreasForm.values);
  const DateGenerate = useSelector(state => state.adminOrSuperAdminReducer.dataGenerated);
  const SearchOrResetAccountUsersClicked = useSelector(state => state.adminOrSuperAdminReducer.SearchOrResetAccountUsersClicked);
  const No_of_pages = useSelector(state => state.adminOrSuperAdminReducer.total_no_of_pages);
  const No_of_rows = useSelector(state => state.adminOrSuperAdminReducer.account_users_row);

  useEffect(()=>{
    setLayout({col: props.qrCodeSize[0]})
  },[props.qrCodeSize])

  function preloader() {
    return <img style={{ height: '35px', width: '35px', borderRadius: '50px', float: 'left' }} src="/images/gif/giphy.gif" />;
  }
  let  data = Array(Math.ceil(parseInt(pageSize* parseInt(No_of_rows))/currentLayout.col)).fill().map((_, i) => {
    let areas = {};
    let start = i * currentLayout.col;
    let end =  start + currentLayout.col;
    let j = 0;
    //[...props.accountAreas, ...props.accountAreas ,...props.accountAreas ,...props.accountAreas , ...props.accountAreas, ...props.accountAreas, ...props.accountAreas, ...props.accountAreas, ...props.accountAreas].map((area, index) => {
    props.accountAreas.map((area, index) => {
      if (index >= start && index <= end){
        areas = {...areas,[`qr_${j+1}`]: 
        <div style={{
          display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"
        }}>
          <img 
            src={area['qr_code_urls'][props.qrCodeSize[1] ? props.qrCodeSize[1] : props.qrCodeSize_MedSmall[1]]}
            style={{
              maxHeight: props.qrCodeSize[1] == "original" ? "78vh" :"unset",
              width: props.qrCodeSize[1] == "original" ? "41vw" : props.qrCodeSize_MedSmall[1] ? "1.25in" : "unset"
            }}
            // width={currentLayout.col === 5 ? "144px" : (currentLayout.col === 2 ? "200px" : "300px")}
            // height={currentLayout.col === 5 ? "94px" : (currentLayout.col === 2 ? "200px" : "300px")}
            crossOrigin="anonymous"
            alt=""
          />
        <div>{area.floor.name}<br/><strong>{area.name}</strong></div></div>}
        j+=1;
      }

    });
    return areas;
  });
  data = data.filter(e => e['qr_1']);
const columns = Array(currentLayout.col).fill().map((_,i) => {
      return {
        Header: ' ',
        accessor: `qr_${i+1}`, // String-based value accessors!,
        show: props.accountAreas.length !== 0,
      }
    });

  const fetchData = (state, instance) => {

    if (SearchOrResetAccountUsersClicked) {
      dispatch(actions.resetSearchOrResetValue())
    } else {
      if (!DateGenerate) {
        const size = state.pageSize*totalRow;
        const page = state.page + 1;
        const sorted = state.sorted;
        const filtered = state.filtered;
        //const email = SearchValue && SearchValue.email ? SearchValue.email.replace('+', "%2B") : ""
        props.getAccountAreas({size,page, sorted,filtered},
            props.match.params.accountId);
      }
    }
    // }
  }
  const handleConfirm = (confirm) => {
    setOpen(false);
    props.deleteArea(currentArea.id, props.match.params.accountId)
  };
 const handleColChange = (e) => {
    setTotalSize(parseInt(e.target.value) > 1 ? 8 : 1);
    setLayout({col: parseInt(e.target.value)});
  }
// React.useEffect(() => {
//   fetchData(table.current.state, table.current);
// }, [currentLayout])
  return (
      SearchOrResetAccountUsersClicked && props.isAccountAreas ?
          <div className="loader_btn_block">
            <ClapSpinner backColor="#89073c" size={40} frontColor="#89073c8a" />
          </div>
          :
          <>
              <div className=" select-wrap -pageSizeOptions" ref={props.columnRef}>
                <select value={currentLayout.col} onChange={e => handleColChange(e)} style={{
  cursor: 'pointer',
  border: "1px solid rgba(0,0,0,0.1)",
  background: "#fff",
  padding: "5px 7px",
  fontSize: "inherit",
  borderRadius: "3px",
  fontWeight: "normal",
  outline: "none"}}>
                <option value="1">1 columns</option>
                <option value="2">2 columns</option>
                <option value="5">5 columns</option>
                </select>
                <button style={{ minHeight: '36px', minWidth: '80px' }} onClick={() => props.getAllQrPdf()} disabled={props.qrLoading} className={props.qrLoading ? "float-right btn cst_btn btn_danger mr-2 btn-wait" : "float-right btn cst_btn btn_danger mr-2"} id="cancel" type="cancel">Get All QR PDF</button>
              </div>

              <ReactTable
              className="qr-table-area"
              data={data}
              loading={props.isAccountAreas}
ref={table}
              defaultPageSize={pageSize}
              minRows={0}
              manual

              //showPageSizeOptions={false}
              pageSizeOptions= {[ 1, 2, 3, 5, 10]}
              onFetchData={fetchData}
              pages={No_of_pages}
               onPageSizeChange={(e) => setPageSize(e)}

              showPagination={props.accountAreas.length !== 0}
              columns={columns}
              NoDataComponent={() => CustomNoDataComponent(props.isAccountAreas, 'No Area Found')}
              getTheadThProps={(state, rowInfo, column, instance) => {
                    return {
                        style: {
                            borderTop: 'none',
                            borderBottom: 'none',
                            borderRight: 'none',
                            textAlign: 'center',
                            color: '#566975',
                            fontSize: '14px',
                            fontWeight: '600',
                            whiteSpace: 'nowrap',
                            float: 'left',
                        }
                    }
                }
                }
              LoadingComponent={() =>
                    props.isAccountAreas ?
                        <div style={{
                            display: "block",
                            position: "absolute",
                            left: 0,
                            height: '100%',
                            right: 0,
                            background: "rgba(255,255,255,0.8)",
                            transition: "all .3s ease",
                            top: 0,
                            bottom: 0,
                            textAlign: "center"
                        }}
                            className="loader_btn_block">
                            <ClapSpinner backColor="#89073c" size={40} frontColor="#89073c8a" />
                        </div> : null}
              getTrProps={(state, rowInfo, column, instance) => {
                    // onclick = () => userDetail(rowInfo ? rowInfo.row.user : rowInfo);
                    return {
                        className: 'qr-tr-table react-table-tr-elementqr',
                        style: {

                            transition: '.3s ease-in-out',
                            width: currentLayout.col === 2 ? "700px" : `800px`,
                          background: "none",
                            // borderRadius: '4px',
                            // height: '80px',
                            marginTop: '5px',
                            float: 'left'
                        }
                    }

                }}
              getTdProps={(state, rowInfo, column, instance) => {

                    if (column.id == 'image') {
                        return {
                            style: {
                                marginTop: '0px'
                            }
                        }
                    } else {
                        return {
                            className: 'react-td-element qr-td-element',
                          style: {
                            width: "103px",
                          }
                        }

                    }
                }
                }
              // getPaginationProps={() => {
              //     return {
              //         style: {
              //             marginTop: '50px',
              //             border: 'none',
              //             boxShadow: 'none'
              //         }
              //     }
              // }
              // }
              getProps={() => {
                    return {
                        style: {
                            border: 'none'
                        }
                    }
                }}
              />
          <Dialog
              open={openModel}

              aria-labelledby="form-dialog-title"
              >
            <DialogTitle id="form-dialog-title" style={{ fontSize: '13', color: '#06080f' }}>
              <h3 style={{ color: '#ab385e' }}>Are you sure?</h3>
            </DialogTitle>

            <DialogActions>
              <button style={{ minHeight: '36px', minWidth: '80px' }} onClick={() => handleConfirm(true)} disabled={props.isLoading} className={props.isLoading ? "btn cst_btn btn_danger mr-2 btn-wait" : "btn cst_btn btn_danger mr-2"} id="delete" type="delete">{props.isLoading ? "" : "Delete"}</button>
              <button  style={{ minHeight: '36px', minWidth: '80px' }} onClick={() => setOpen(false)} className={"btn cst_btn btn-outline-secondary"} id="cancel" type="cancel">Cancel</button>
            </DialogActions>
          </Dialog>
          <Dialog
              open={openQrModel}

              aria-labelledby="form-dialog-title"
              >
            <DialogTitle id="form-dialog-title" style={{ fontSize: '13', color: '#06080f' }}>
              <h3 style={{ color: '#ab385e' }}>QR Code</h3>
            </DialogTitle>
            <img src={currentQrArea && currentQrArea.qr_code_urls.medium} alt="dfdf" />
            <DialogActions>
              <button style={{ minHeight: '36px', minWidth: '80px' }} onClick={() => setQROpen(false)} disabled={props.isLoading} className={props.isLoading ? "btn cst_btn btn_danger mr-2 btn-wait" : "btn cst_btn btn_danger mr-2"} id="delete" type="delete">{props.isLoading ? "" : "Okay"}</button>
              <button  style={{ minHeight: '36px', minWidth: '80px' }} onClick={() => setQROpen(false)} className={"btn cst_btn btn-outline-secondary"} id="cancel" type="cancel">Cancel</button>
            </DialogActions>
          </Dialog>
          </>
  )
}

export default AccountsUserQrTable;