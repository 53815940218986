import React from 'react';
import * as actions from '../../../redux/actions/index';
import { connect, useSelector } from 'react-redux';
import { GetFormatedDate, getnext90Days, getNextMonth, getNextMonthDate, getNextMonthShow, getNextYearDate, getPast90Days, getPast90DaysCall, getPast90DaysEndDay, getPast90DaysStartDay, getPreviousMonthDate, getPreviousYearDate, getUser, get_next_90days, get_next_90days_date, get_next_quarter, get_next_week_start, get_previous_90days, get_previous_quarter, get_previous_quater, get_previous_week_start, KpiGetFormatedDate, previous90DaysValue } from '../../../utility/utility';
import { currentData, userRoles, routes } from '../../../utility/constants/constants';
import $, { get } from 'jquery';
import storage from '../../../utility/storage';
import cloneDeep from 'clone-deep';
import { reset } from 'redux-form';
import KpiTabs from '../../component/KpiDashboard/KpiTabs';
import KpiInspection from '../../component/KpiDashboard/KpiInspection';
import moment from 'moment';


class KpiInspectionContainer extends React.Component {
    // componentDidMount = () => {
    //     this.props.getAllCHIServys();
    // }
    constructor(props) {
        super(props);
        this.state = {
            // isWeek: true,
            // isMonth: false,
            // isYear: false,
        }
    }

componentDidMount = () => { 
    let values = {}
    const user = getUser();
    values['company_id'] = this.props.match.params.companyId;
    values['quarter'] = getPast90DaysCall(this.props.currentDate);
    values['date']=KpiGetFormatedDate(this.props.currentDate);
    values['mode']= user.mode;
    values['option']=currentData.MONTH;
    // values['account_id']=[user.id];
    storage.set('companyId', this.props.match.params.companyId)
    const userDetails = getUser();
    this.props.getCompanyUsers( this.props.match.params.companyId, {}, this.props.company_users_no_of_rows)
    this.props.getKpiInspectionCount(values)
    this.props.getKpiAvarageScore(values)
    this.props.getKpiTopInspection(values)
    this.props.getKpiQualityInspection(values);
    this.props.getKpiAverageSectionQuality(values)
    this.props.getKpiAverageSectionSafety(values)
    // this.props.getKpiQualityInspection(currentData.MONTH, GetFormatedDate(this.props.currentDate), '', getPast90DaysCall(this.props.currentDate))
    this.props.updateCurrentDate(this.props.currentDate);
    this.props.updateWeekMonthYear(false, true, false);
    this.props.getModeAccountUsers(userDetails.mode);
}

nextWeekMonthYearCliked = () => { 
    const date = getNextMonthShow(this.props.currentDate);
    const userDetails = getUser();
    let values = {}
        const user = getUser();
        values['company_id'] = this.props.match.params.companyId;
        values['quarter'] = getPast90DaysCall(this.props.currentDate);
        values['mode']= user.mode;
        // values['account_id']=[user.id];
        storage.set('companyId', this.props.match.params.companyId)
    if (this.props.isWeek) {
        const date = get_next_week_start(this.props.currentDate);
        this.props.getKpiInspectionCount(values)
        this.props.getKpiAvarageScore(values)
        this.props.getKpiQualityInspection(values);
        this.props.getKpiAverageSectionQuality(values)
        this.props.getKpiAverageSectionSafety(values)
         this.props.updateCurrentDate(date);
        // this.getMoreData('', 'week')
    } else if (this.props.isMonth) {
        values['option']=currentData.MONTH;
        values['date']=KpiGetFormatedDate(date);
        if (this.props.currentDate.getMonth() === 11) {
            const date = getNextYearDate(this.props.currentDate);
            values['date']=KpiGetFormatedDate(date);
            this.props.getKpiInspectionCount(values)
            this.props.getKpiAvarageScore(values)
            this.props.getKpiTopInspection(values)
            this.props.getKpiQualityInspection(values);
            this.props.getKpiAverageSectionQuality(values)
            this.props.getKpiAverageSectionSafety(values)
            this.props.updateCurrentDate(date);
            // this.getMoreData('', 'month')    
        } else {
            const date = getNextMonthShow(this.props.currentDate);
            this.props.getKpiInspectionCount(values)
            this.props.getKpiAvarageScore(values)
            this.props.getKpiTopInspection(values)
            this.props.getKpiQualityInspection(values);
            this.props.getKpiAverageSectionQuality(values)
            this.props.getKpiAverageSectionSafety(values)
            this.props.updateCurrentDate(date);
            // this.getMoreData('', 'month')    
        }
    }  else if (this.props.is90Days) { 
        // const presentDateValue = new Date()
        // let today = new Date();
        // const prev90DaysValue=today.setDate(today.getDate() - 90);
        // values['from']= KpiGetFormatedDate(prev90DaysValue);
        // values['to']= KpiGetFormatedDate(presentDateValue);
        const date = get_next_90days_date((this.props.currentDate) - 90);
        // values['to'] = this.props.kpiSearchValues.from;
        values['from'] = this.props.kpiSearchValues.to; //same line for nextclick
        moment.defaultFormat = "MM-DD-YYYY";
        let fromDate = moment(this.props.kpiSearchValues.to, moment.defaultFormat)
        values['to'] = moment(fromDate).add(90, 'days').format('MM-DD-YYYY') + 'T00:00:00' + 'Z';
        values['option']=currentData.THREE_MONTHS;
        this.props.getKpiInspectionCount(values)
        this.props.getKpiAvarageScore(values)
        this.props.getKpiTopInspection(values)
        this.props.getKpiQualityInspection(values);
        this.props.getKpiAverageSectionQuality(values)
        this.props.getKpiAverageSectionSafety(values)
        this.props.updateCurrentDate(date);
        // this.getNextMoreData('', '90Days')    
    } else if (this.props.isYear) {
        values['date']=KpiGetFormatedDate(date);
        const date = getNextYearDate(this.props.currentDate);
        this.props.getKpiInspectionCount(values)
        this.props.getKpiAvarageScore(values)
        this.props.getKpiTopInspection(values)
        this.props.getKpiQualityInspection(values);
        this.props.getKpiAverageSectionQuality(values)
        this.props.getKpiAverageSectionSafety(values)
        this.props.updateCurrentDate(getNextYearDate(this.props.currentDate));
        // this.getMoreData('', 'year')    
    }
}

previousWeekMonthYearCliked = () => {
    const date = getPreviousMonthDate(this.props.currentDate);
    const userDetails = getUser();
    let values = {}
        const user = getUser();
        values['company_id'] = this.props.match.params.companyId;
        values['quarter'] = getPast90DaysCall(this.props.currentDate);
        values['mode']= user.mode;
        // values['account_id']=[user.id];
        storage.set('companyId', this.props.match.params.companyId)
    if (this.props.isWeek) {
        const date = get_previous_week_start(this.props.currentDate);
        this.props.getKpiInspectionCount(values)
        this.props.getKpiAvarageScore(values)
        this.props.getKpiTopInspection(values)
        this.props.getKpiQualityInspection(values);
        this.props.getKpiAverageSectionQuality(values)
        this.props.getKpiAverageSectionSafety(values)
        this.props.updateCurrentDate(date);
        // this.getMoreData('', 'week')    
    } else if (this.props.isMonth) {
        values['date']=KpiGetFormatedDate(date);
        values['option']=currentData.MONTH;
        if (this.props.currentDate.getMonth() === 0) {
            const date = getPreviousYearDate(this.props.currentDate);
            values['date']=KpiGetFormatedDate(date);
            this.props.getKpiInspectionCount(values)
            this.props.getKpiAvarageScore(values)
            this.props.getKpiTopInspection(values)
            this.props.getKpiQualityInspection(values);
            this.props.getKpiAverageSectionQuality(values)
            this.props.getKpiAverageSectionSafety(values)
            this.props.updateCurrentDate(date);
            // this.getMoreData('', 'month')    
        } else {
            const date = getPreviousMonthDate(this.props.currentDate);
            this.props.getKpiInspectionCount(values)
            this.props.getKpiAvarageScore(values)
            this.props.getKpiTopInspection(values)
            this.props.getKpiQualityInspection(values);
            this.props.getKpiAverageSectionQuality(values)
            this.props.getKpiAverageSectionSafety(values)
           this.props.updateCurrentDate(date);
            // this.getMoreData('', 'month')    
        }
    } if (this.props.is90Days) {
        const date = get_previous_90days((this.props.currentDate) - 90);
        values['to'] = this.props.kpiSearchValues.from;
        // values['from'] = this.props.kpiSearchValues.to; //same line for nextclick
        moment.defaultFormat = "MM-DD-YYYY";
        let fromDate = moment(this.props.kpiSearchValues.from, moment.defaultFormat)
        values['from'] = moment(fromDate).subtract(90, 'days').format('MM-DD-YYYY') + 'T00:00:00' + 'Z';
       values['option']=currentData.THREE_MONTHS;
        this.props.getKpiInspectionCount(values)
        this.props.getKpiAvarageScore(values)
        this.props.getKpiTopInspection(values)
        this.props.getKpiQualityInspection(values);
        this.props.getKpiAverageSectionQuality(values)
        this.props.getKpiAverageSectionSafety(values)
         this.props.updateCurrentDate(date);
        // this.getPreviousMoreData('', '90Days')    
    } else if (this.props.isYear) {
        const date = getPreviousYearDate(this.props.currentDate);
        this.props.getKpiInspectionCount(values)
        this.props.getKpiAvarageScore(values)
        this.props.getKpiTopInspection(values)
        this.props.getKpiQualityInspection(values);
        this.props.getKpiAverageSectionQuality(values)
        this.props.getKpiAverageSectionSafety(values)
        this.props.updateCurrentDate(getPreviousYearDate(this.props.currentDate));
        // this.getMoreData('', 'year')
        }
}
viewModeClicked = (viewMode) => {  
    const presentDateValue = new Date()
    let today = new Date();
    const prev90DaysValue=today.setDate(today.getDate() - 89);
    const userDetails = getUser();
    let values = {}
        const user = getUser();
        values['company_id'] = this.props.match.params.companyId;
        values['quarter'] = getPast90DaysCall(this.props.currentDate);
        this.props.updateCurrentDate(presentDateValue);
        values['mode']= user.mode;
        // values['account_id']=[user.id];
        storage.set('companyId', this.props.match.params.companyId)
    if (viewMode === currentData.WEEK) {
        // this.props.updateWeekMonthYear(true, false, false);
        // this.props.getKpiInspectionCount(currentData.WEEK, KpiGetFormatedDate(this.props.currentDate), '', getPast90DaysCall(this.props.currentDate))
        // this.props.getKpiTopInspection(currentData.WEEK, KpiGetFormatedDate(this.props.currentDate), '', getPast90DaysCall(this.props.currentDate))
        // //   this.getMoreData('', 'week')
    } else if (viewMode === currentData.MONTH) { 
        values['date']=KpiGetFormatedDate(presentDateValue);
        values['option']=currentData.MONTH;
        const userDetails = getUser();
        this.props.updateWeekMonthYear(false, true, false)
        this.props.getKpiInspectionCount(values)
        this.props.getKpiAvarageScore(values)
        this.props.getKpiTopInspection(values)
        this.props.getKpiQualityInspection(values);
        this.props.getKpiAverageSectionQuality(values)
        this.props.getKpiAverageSectionSafety(values)
        // this.getMoreData('', 'month')
    }  else if (viewMode === currentData.THREE_MONTHS) {
        values['option']=currentData.THREE_MONTHS;
        const presentDateValue = new Date()
        let today = new Date();
        const prev90DaysValue=new Date(moment(today).subtract(90, 'days'));
        values['from']= KpiGetFormatedDate(prev90DaysValue);
        values['to']= KpiGetFormatedDate(presentDateValue);
        this.props.updateWeekMonthYear(false, false, false, true)
        this.props.getKpiInspectionCount(values)
        this.props.getKpiAvarageScore(values)
        this.props.getKpiTopInspection(values)
        this.props.getKpiQualityInspection(values);
        this.props.getKpiAverageSectionQuality(values)
        this.props.getKpiAverageSectionSafety(values)
        this.props.updateCurrentDate(presentDateValue);
        // this.getMoreData('', '90Days')    
    } else if (viewMode === currentData.YEAR) {
        // this.props.updateWeekMonthYear(false, false, true)
        // this.props.getKpiInspectionCount(currentData.YEAR, KpiGetFormatedDate(this.props.currentDate), '', getPast90DaysCall(this.props.currentDate))
        // // this.getMoreData('', 'year')    
    } else if (viewMode === currentData.CUSTOM) {
        values['date']=KpiGetFormatedDate(presentDateValue);
        values['option']=currentData.CUSTOM;
        this.props.updateWeekMonthYear(false, false, false, false, true)
        // this.props.getKpiInspectionCount(values)   
    }
}

    goClicked = (data) => {
        let values = {}
        const user = getUser();
        values['company_id'] = this.props.match.params.companyId;
        values['quarter'] = getPast90DaysCall(this.props.currentDate);
        values['date']=GetFormatedDate(this.props.currentDate);
        values['mode']= user.mode;
        values['account_id']= data.inspection_account ? data.inspection_account.map(a => a.value) : [];
        if(data.inspection_account[0].label === "All Jobs"){
            values['account_id'] = this.props.companyUsersList ? this.props.companyUsersList.map(a => a.id) : []
        }
        values['inspected_by_id']= data.inspection_user ? data.inspection_user.value : '';
        
       if (this.props.isMonth) {
        values['option']=currentData.MONTH; 
            if (this.props.currentDate.getMonth() === 0) {
                this.props.getKpiInspectionCount(values)
                this.props.getKpiAvarageScore(values)
                this.props.getKpiTopInspection(values)
                this.props.getKpiQualityInspection(values);
                this.props.getKpiAverageSectionQuality(values)
                this.props.getKpiAverageSectionSafety(values)
                } else {
                this.props.getKpiInspectionCount(values)
                this.props.getKpiAvarageScore(values)
                this.props.getKpiTopInspection(values)
                this.props.getKpiQualityInspection(values);
                this.props.getKpiAverageSectionQuality(values)
                this.props.getKpiAverageSectionSafety(values)
                }
        } else if (this.props.is90Days) {
        const date = get_previous_90days((this.props.currentDate) - 90);
        values['to']= KpiGetFormatedDate((this.props.currentDate) - 90); 
        values['from']= KpiGetFormatedDate(date);
        values['option']=currentData.THREE_MONTHS;
        this.props.getKpiInspectionCount(values)
            this.props.getKpiAvarageScore(values)
            this.props.getKpiTopInspection(values)
            this.props.getKpiQualityInspection(values);
            this.props.getKpiAverageSectionQuality(values)
            this.props.getKpiAverageSectionSafety(values)
           } else if(this.props.isCustomDate){ 
            values['option']=currentData.DATEFILTER;
            this.props.getKpiInspectionCount(values)
                this.props.getKpiAvarageScore(values)
                this.props.getKpiTopInspection(values)
                this.props.getKpiQualityInspection(values);
                this.props.getKpiAverageSectionQuality(values)
                this.props.getKpiAverageSectionSafety(values)
           }
    }
    resetClicked = (data) => {
        this.props.reset('searchKpiDashboard')
        this.props.kpiResetClicked();
        const userDetails = getUser();
        let values = {}
        const user = getUser();
        values['company_id'] = this.props.match.params.companyId;
        values['quarter'] = getPast90DaysCall(this.props.currentDate);
        values['date']=GetFormatedDate(this.props.currentDate);
        values['mode']= user.mode;
        values['account_id']= data.account_id ? data.account_id.map(a => a.value) : [];
        values['inspected_by_id']= data.inspected_by_id ? data.inspected_by_id.value : '';
        values['reset']= true;
       if (this.props.isMonth) {
        values['option']=currentData.MONTH; 
            if (this.props.currentDate.getMonth() === 0) {
                this.props.getKpiInspectionCount(values)
                this.props.getKpiAvarageScore(values)
                this.props.getKpiTopInspection(values)
                this.props.getKpiQualityInspection(values);
                this.props.getKpiAverageSectionQuality(values)
                this.props.getKpiAverageSectionSafety(values)
                } else {
                this.props.getKpiInspectionCount(values)
                this.props.getKpiAvarageScore(values)
                this.props.getKpiTopInspection(values)
                this.props.getKpiQualityInspection(values);
                this.props.getKpiAverageSectionQuality(values)
                this.props.getKpiAverageSectionSafety(values)
                }
        } else if (this.props.is90Days) {
        const date = get_previous_90days((this.props.currentDate) - 90);
        values['to']= KpiGetFormatedDate((this.props.currentDate) - 90); 
        values['from']= KpiGetFormatedDate(date);
        values['option']=currentData.THREE_MONTHS;
        this.props.getKpiInspectionCount(values)
            this.props.getKpiAvarageScore(values)
            this.props.getKpiTopInspection(values)
            this.props.getKpiQualityInspection(values);
            this.props.getKpiAverageSectionQuality(values)
            this.props.getKpiAverageSectionSafety(values)
           } else if(this.props.isCustomDate){
            values['option']=currentData.DATEFILTER;
            this.props.getKpiInspectionCount(values)
                this.props.getKpiAvarageScore(values)
                this.props.getKpiTopInspection(values)
                this.props.getKpiQualityInspection(values);
                this.props.getKpiAverageSectionQuality(values)
                this.props.getKpiAverageSectionSafety(values)
           }
    }

    lineItemClicked = (name) => {
        let values = {}
        const user = getUser();
        values['company_id'] = this.props.match.params.companyId;
        values['quarter'] = getPast90DaysCall(this.props.currentDate);
        values['date']=KpiGetFormatedDate(this.props.currentDate);
        values['mode']= user.mode;
        values['option']=currentData.MONTH;
        values['section_name'] = name;
        this.props.getAvgModalData(values);
    }

    QualityIdlineItemClicked = (qulaityLineItem) => {
        let values = {}
        values['section_name'] = qulaityLineItem.name
        values['line_item_ids'] = qulaityLineItem.line_item_ids;
        values['rating_option_id']= qulaityLineItem.rating_option_id;
        this.props.getQualityInsModalData(values);
    }
    getAverageGraphInspections = (ids) => {
        this.props.getAverageGraphInspections({inspection_ids: ids})
    }
    render() {
        return (
            <>
            <KpiInspection
            {...this.props}
                nextWeekMonthYearCliked={this.nextWeekMonthYearCliked}
                previousWeekMonthYearCliked={this.previousWeekMonthYearCliked}
                viewModeClicked={this.viewModeClicked}
                kpiInspectionData={this.props.kpiInspectionData} 
                kpiInspectionQQvScore={this.props.kpiInspectionQQvScore}
                kpiInspectionRootcaseData={this.props.kpiInspectionRootcaseData}
                kpiInspectionDeficencyData={this.props.kpiInspectionDeficencyData}
                kpiInspectionDateData={this.props.kpiInspectionDateData}
                kpiTopInspectionData={this.props.kpiTopInspectionData}
                kpiInspectionAccountData={this.props.kpiInspectionAccountData}
                kpiInspectionPieChartData={this.props.kpiInspectionPieChartData}
                kpiInspectionCustomerData={this.props.kpiInspectionCustomerData}
                kpiInspectionQQvData={this.props.kpiInspectionQQvData}
                readMoreClicked={this.readMoreClicked}
                dropDownUsersList={this.props.dropDownUsersList}
                dropDownAccountsList={this.props.dropDownAccountsList}
                accountsList={this.props.accountsList ? this.props.accountsList : []}
                onSubmit={this.goClicked}
                resetClicked={this.resetClicked}
                monthDivideIntoThree={this.monthDivideIntoThree}
                //new kpi chnages
                kpiInspectionCount={this.props.kpiInspectionCount}
                kpiTopInspector={this.props.kpiTopInspector}
                kpiInspectionAverageScore={this.props.kpiInspectionAverageScore}
                kpiQualityInspectionData={this.props.kpiQualityInspectionData}
                lineItemClicked={this.lineItemClicked}
                isLoading={this.props.isLoading}
                kpiSectionValues={this.props.kpiSectionValues}
                scoretableIdClicked={this.scoretableIdClicked}
                QualityIdlineItemClicked={this.QualityIdlineItemClicked}
                getAverageGraphInspections={this.getAverageGraphInspections}
            />
            </>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        company: state.adminOrSuperAdminReducer.company,
        companyUsersList: state.adminOrSuperAdminReducer.companyUsersList,
        currentDate: state.adminOrSuperAdminReducer.currentDate,
        isWeek: state.adminOrSuperAdminReducer.isWeek,
        isMonth: state.adminOrSuperAdminReducer.isMonth,
        isYear: state.adminOrSuperAdminReducer.isYear,
        is90Days: state.adminOrSuperAdminReducer.is90Days,
        isCustomDays: state.adminOrSuperAdminReducer.isCustomDays,
        kpiInspectionAverageScore: state.adminOrSuperAdminReducer.kpiInspectionAverageScore,
        kpiInspectionQQvScore: state.adminOrSuperAdminReducer.kpiInspectionQQvScore,
        kpiInspectionRootcaseData: state.adminOrSuperAdminReducer.kpiInspectionRootcaseData,
        kpiInspectionDeficencyData: state.adminOrSuperAdminReducer.kpiInspectionDeficencyData,
        kpiInspectionDateData: state.adminOrSuperAdminReducer.kpiInspectionDateData,
        kpiTopInspectionData: state.adminOrSuperAdminReducer.kpiTopInspectionData,
        kpiInspectionAccountData: state.adminOrSuperAdminReducer.kpiInspectionAccountData,
        kpiInspectionPieChartData: state.adminOrSuperAdminReducer.kpiInspectionPieChartData,
        kpiInspectionCustomerData: state.adminOrSuperAdminReducer.kpiInspectionCustomerData,
        kpiInspectionQQvData: state.adminOrSuperAdminReducer.kpiInspectionQQvData,
        dropDownUsersList: state.adminOrSuperAdminReducer.filterAccountUsers,
        accountsList: state.adminOrSuperAdminReducer.modeAccountsList,
        dropDownAccountsList: state.adminOrSuperAdminReducer.dropDownAccountsList,
        isCustomDate: state.adminOrSuperAdminReducer.isCustomDate,
        //new kpi changes
        kpiInspectionData: state.adminOrSuperAdminReducer.kpiInspectionData,
        kpiInspectionCount: state.adminOrSuperAdminReducer.kpiInspectionCount,
        kpiTopInspector: state.adminOrSuperAdminReducer.kpiTopInspector,
        kpiQualityInspectionData: state.adminOrSuperAdminReducer.kpiQualityInspectionData,
        kpiAverageSectionQualityData: state.adminOrSuperAdminReducer.kpiAverageSectionQualityData,
        kpiAverageSectionSafetyData: state.adminOrSuperAdminReducer.kpiAverageSectionSafetyData,
        avgModalData: state.adminOrSuperAdminReducer.avgModalData,
        isLoading: state.adminOrSuperAdminReducer.isLoading,
        kpiSearchValues: state.adminOrSuperAdminReducer.kpiSearchValues,
        kpiSectionValues: state.adminOrSuperAdminReducer.kpiSectionValues,
        qualityModalData: state.adminOrSuperAdminReducer.qualityModalData,
        averageGraphInspections: state.adminOrSuperAdminReducer.averageGraphInspections,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        // getAllCHIServys: (chi_no_of_rows, page, sorted, filtered) => dispatch(actions.getAllCHIServys(chi_no_of_rows, page, sorted, filtered))
        updateCurrentDate: (date) => dispatch(actions.updateCurrentDate(date)),
        updateWeekMonthYear: (isWeek, isMonth, isYear, is90Days, isCustomDate) => dispatch(actions.updateWeekMonthYear(isWeek, isMonth, isYear, is90Days, isCustomDate)),
        getAccounts: () => dispatch(actions.getDropDownAccounts()),
        getModeAccounts: (mode) => dispatch(actions.getModeAccounts(mode)),
        getModeAccountUsers: (mode) => dispatch(actions.getModeAccountUsers(mode)),
        searchInspections: (values) => dispatch(actions.searchInspections(values)),
        kpiResetClicked: () => dispatch(actions.kpiResetClicked()),
        reset: (kpiSearch) => dispatch(reset(kpiSearch)), 
        getCompanyUsers: (id, values, user_no_of_rows, page, sorted, filtered) => dispatch(actions.getCompanyUsers(id, values, user_no_of_rows, page, sorted, filtered)),
        //new kpi chnages
        getKpiInspectionCount: (values) => dispatch(actions.getKpiInspectionCount(values)),
        getKpiAvarageScore: (values) => dispatch(actions.getKpiAvarageScore(values)),
        getKpiTopInspection: (values) => dispatch(actions.getKpiTopInspection(values)),
        getKpiQualityInspection: (values) => dispatch(actions.getKpiQualityInspection(values)),
        getKpiAverageSectionQuality: (values) => dispatch(actions.getKpiAverageSectionQuality(values)),
        getKpiAverageSectionSafety: (values) => dispatch(actions.getKpiAverageSectionSafety(values)),
        getAvgModalData: (values) => dispatch(actions.getAvgModalData(values)),
        getQualityInsModalData: (values) => dispatch(actions.getQualityInsModalData(values)),
        getAverageGraphInspections: (values) => dispatch(actions.getAverageGraphInspections(values))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(KpiInspectionContainer);