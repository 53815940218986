import axios, { API_VERSION, accessToken, formAuditBaseUrl } from '../../config';
import storage from '../../utility/storage';
import { getUser } from '../../utility/utility';
const pureAxios = require('axios');

const user = storage.get('user');

const headers = {
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
    },
};


export const getSingleRestroomDetails = (id) => {
    
    return formAuditBaseUrl.get(API_VERSION+`logbookform/frequency_log/${id}`, headers);
}

export const getAllRestroomPortering = (params) => {
    return formAuditBaseUrl.get(API_VERSION+`logbookform/frequency_log`, {params},  headers);
}


export const addRestroomDetailsAPI = (body) => {
    
    return formAuditBaseUrl.post(API_VERSION+`logbookform/frequency_log`,body, headers);
}

export const editRestroomDetailsAPI = (body) => {
    return formAuditBaseUrl.put(API_VERSION+`logbookform/frequency_log`,body, headers);
}



