import React from 'react';
import $ from "jquery";
import storage from '../../utility/storage';
import { Status, userRoles, TimeZones, TimezoneMonths, routes } from '../constants/constants';
import { convertDateFormatV4, convertTo12HourFormat, getUser } from '../utility';

// import { DateTime, Duration } from 'luxon';

var dateFormat = require('dateformat');
var moment = require('moment');
var momentTZ = require('moment-timezone');
const cloneDeep = require('clone-deep');
const queryString = require('query-string');
const { DateTime, Duration } = require('luxon');

export const customTime = (date) => {

    let fetchedDate = date;
    fetchedDate.setHours(0, 0, 0, 0);

    return fetchedDate;

}

export const toFindPDTAvailability = (date) => {
    // debugger
    let currentUser = getUser();

    let gettingDateofTimezone = currentUser?.timezone ? momentTZ(date).tz(currentUser?.timezone) : null;
    let result = momentTZ(gettingDateofTimezone && gettingDateofTimezone._d);

    let isPacificDaylightTime = result.isDST();

    return isPacificDaylightTime;

}

export const tzStartOfWeek = (date) => {

    // Get the current date
    const currentDate = moment(date);
    const startOfWeek = currentDate.startOf('week');

    // Format the dates as strings
    const startOfWeekString = startOfWeek.format('YYYY-MM-DD');

    return startOfWeekString;

}

export const tzEndOfWeek = (date) => {

    // Get the current date
    const currentDate = moment(date);
    const endOfWeek = currentDate.endOf('week');

    // Format the dates as strings
    const endOfWeekString = endOfWeek.format('YYYY-MM-DD');

    return endOfWeekString;

}

export const tzTimezoneMarking = (timezone) => {

    let zoneMark = null

    if (timezone) {
        if (timezone == TimeZones.PST) {
            zoneMark = 'Pacific Standard Time (PST)'
        } else if (timezone == TimeZones.CST) {
            zoneMark = 'Central Standard Time (CST)'
        }
    }

    return zoneMark

}

/* For availability and shift calendar display date && time timezone functionalities */
export const tzCalendarDateTimeConverterTool = (date, time) => {

    let formatedUTCDateString = moment.utc(`${date}T${time}`);

    let currentUser = getUser();

    let utcToCurrentTimeoneDateString = null;

    utcToCurrentTimeoneDateString = formatedUTCDateString.tz(currentUser.timezone).format('YYYY-MM-DDTHH:mm:ss');

    // utcToCurrentTimeoneDateString = momentTZ(formatedUTCDateString).tz(currentUser.timezone);
    // let result = momentTZ(utcToCurrentTimeoneDateString._d);

    /* Condition for comparing syatem and user timezone */
    // if (systemTimeZone == currentUser.timezone) {

    //     /* Condition for Current Timezone Conversion */
    //     if (currentUser.timezone == TimeZones.PST) {
    //         utcToCurrentTimeoneDateString = momentTZ(formatedUTCDateString).subtract(7, 'hours');
    //     } else if (currentUser.timezone == TimeZones.CST) {
    //         utcToCurrentTimeoneDateString = momentTZ(formatedUTCDateString).subtract(5, 'hours');
    //     }

    // } else {

    //     let currentTimezoneDateObject = momentTZ(formatedUTCDateString).tz(currentUser.timezone);
    //     /* Condition for Current Timezone Conversion */
    //     if (currentUser.timezone == TimeZones.PST) {
    //         utcToCurrentTimeoneDateString = currentTimezoneDateObject.subtract(7, 'hours');
    //     } else if (currentUser.timezone == TimeZones.CST) {
    //         utcToCurrentTimeoneDateString = currentTimezoneDateObject.subtract(5, 'hours');
    //     }

    // }

    /* Condition for Pacific Daylight Time Conversion */
    // if (isPacificDaylightTime == true) {
    //     finalFormattedDate = utcToCurrentTimeoneDateString.subtract(0, 'hour').format('YYYY-MM-DDTHH:mm:ss');
    // } else if (isPacificDaylightTime == false) {
    //     finalFormattedDate = utcToCurrentTimeoneDateString.subtract(1, 'hour').format('YYYY-MM-DDTHH:mm:ss');
    // }

    return utcToCurrentTimeoneDateString;

}

/* For Payroll - POST - PUT Calls - API Functionalities start_date/end_date converter by combining its start_time and end_time */
export const tzUTCDateTimeConverterTool_V1 = (date, time, type) => {
    
    // let systemTimeZone = momentTZ.tz.guess(); // Identify the current system time zone
    let currentUser = getUser();
    // let startEndOfDayFormatedDate = null;
    let utcDateString = null;

    if (type === 'from') {
        // startEndOfDayFormatedDate = momentTZ(`${convertDateFormatV4(date)} ${time}`).startOf('day'); //'T00:00:00'
        utcDateString = momentTZ.tz(`${convertDateFormatV4(date)} ${time}`, currentUser.timezone).startOf('day').utc().format('YYYY-MM-DDTHH:mm:ss'); //'T00:00:00'
    } else if (type == 'to') {
        // startEndOfDayFormatedDate = momentTZ(`${convertDateFormatV4(date)} ${time}`).endOf('day'); //'T23:59:59'
        utcDateString = momentTZ.tz(`${convertDateFormatV4(date)} ${time}`, currentUser.timezone).endOf('day').utc().format('YYYY-MM-DDTHH:mm:ss');
    } else if (type == 'none') {
        utcDateString = momentTZ.tz(`${convertDateFormatV4(date)} ${time}`, currentUser.timezone).utc().format('YYYY-MM-DDTHH:mm:ss');
    }

    /* Condition for comparing syatem and user timezone */
    // if (systemTimeZone == currentUser.timezone) {

    //     /* Condition for Current Timezone Conversion */
    //     if (currentUser.timezone == TimeZones.PST) {
    //         utcDateString = startEndOfDayFormatedDate.add(7, 'hours').format('YYYY-MM-DDTHH:mm:ss');
    //     } else if (currentUser.timezone == TimeZones.CST) {
    //         utcDateString = startEndOfDayFormatedDate.add(5, 'hours').format('YYYY-MM-DDTHH:mm:ss');
    //     }

    // } else {

    //     let currentTimezoneDateObject = startEndOfDayFormatedDate.tz(currentUser.timezone);
    //     /* Condition for Current Timezone Conversion */
    //     if (currentUser.timezone == TimeZones.PST) {
    //         utcDateString = currentTimezoneDateObject.add(7, 'hours').format('YYYY-MM-DDTHH:mm:ss');
    //     } else if (currentUser.timezone == TimeZones.CST) {
    //         utcDateString = currentTimezoneDateObject.add(5, 'hours').format('YYYY-MM-DDTHH:mm:ss');
    //     }

    // }

    return utcDateString;

}

/* For Payroll - GET Calls - API Functionalities start_date/end_date converter */
export const tzUTCDateConverterTool_V2 = (date, type) => {

    let utcDateString = null;
    let currentUser = getUser();
    // let startEndOfDayFormatedDate = null;
    // let utcTimezoneDateTimeObject = null;
    // let utcDateString = null;

    // if (type === 'from') {
    //     // startEndOfDayFormatedDate = momentTZ(date).startOf('day'); //'T00:00:00'
    //     momentDate = utcMoment.tz(currentUser.timezone).startOf('day').format('YYYY-MM-DDTHH:mm:ss');
    // } else if (type == 'to') {
    //     // startEndOfDayFormatedDate = momentTZ(date).endOf('day'); //'T23:59:59'
    //     momentDate = utcMoment.tz(currentUser.timezone).endOf('day').format('YYYY-MM-DDTHH:mm:ss');
    // } else if (type == 'none') {
    //     momentDate = momentTZ(date); /* NONE */
    // }

    if (type === 'from') {
        // startEndOfDayFormatedDate = momentTZ(`${convertDateFormatV4(date)} ${time}`).startOf('day'); //'T00:00:00'
        utcDateString = momentTZ.tz(date, currentUser.timezone).startOf('day').utc().format('YYYY-MM-DDTHH:mm:ss'); //'T00:00:00'
    } else if (type == 'to') {
        // startEndOfDayFormatedDate = momentTZ(`${convertDateFormatV4(date)} ${time}`).endOf('day'); //'T23:59:59'
        utcDateString = momentTZ.tz(date, currentUser.timezone).endOf('day').utc().format('YYYY-MM-DDTHH:mm:ss');
    } else if (type == 'none') {
        utcDateString = momentTZ.tz(date, currentUser.timezone).utc().format('YYYY-MM-DDTHH:mm:ss');
    }

    // if (systemTimeZone == currentUser.timezone) { 

    //     /* Condition for Current Timezone Conversion */
    //     if (currentUser.timezone == TimeZones.PST) {
    //         utcTimezoneDateTimeObject = startEndOfDayFormatedDate.add(7, 'hours');
    //     } else if (currentUser.timezone == TimeZones.CST) {
    //         utcTimezoneDateTimeObject = startEndOfDayFormatedDate.add(5, 'hours');
    //     }

    //     utcDateString = momentTZ(utcTimezoneDateTimeObject).format('YYYY-MM-DDTHH:mm:ss');

    // } else {
    

    //     let currentTimezoneDateObject = startEndOfDayFormatedDate.tz(currentUser.timezone);

    //     /* Condition for Current Timezone Conversion */
    //     if (currentUser.timezone == TimeZones.PST) {
    //         utcTimezoneDateTimeObject = currentTimezoneDateObject.add(7, 'hours');
    //     } else if (currentUser.timezone == TimeZones.CST) {
    //         utcTimezoneDateTimeObject = currentTimezoneDateObject.add(5, 'hours');
        // }

    //     utcDateString = momentTZ(utcTimezoneDateTimeObject).format('YYYY-MM-DDTHH:mm:ss');

    // }

    return utcDateString;

}

/* For Payroll - POST - PUT Calls - API Functionalities start_time/end_time converter by combining its start_date and end_date */
export const tzUTCTimeConverterTool_V1 = (date, time) => {

    // let systemTimeZone = moment.tz.guess(); // Identify the current system time zone
    let currentUser = getUser();
    let utcTimeObjectString = null;

    let combinedDateTimeObject = `${date}T${time}`;

    utcTimeObjectString = momentTZ.tz(combinedDateTimeObject, currentUser.timezone).utc().format('HH:mm:ss');

    /* Condition for comparing syatem and user timezone */
    // if (systemTimeZone == currentUser.timezone) {

    //     /* Condition for Current Timezone Conversion */
    //     if (currentUser.timezone == TimeZones.PST) {
    //         utcTimeObjectString = combinedDateTimeObject.add(7, 'hours').format('HH:mm:ss');
    //     } else if (currentUser.timezone == TimeZones.CST) {
    //         utcTimeObjectString = combinedDateTimeObject.add(5, 'hours').format('HH:mm:ss');
    //     }

    // } else {

    //     let currentTimezoneTimeObject = combinedDateTimeObject.tz(currentUser.timezone);
    //     /* Condition for Current Timezone Conversion */
    //     if (currentUser.timezone == TimeZones.PST) {
    //         utcTimeObjectString = currentTimezoneTimeObject.add(7, 'hours').format('HH:mm:ss');
    //     } else if (currentUser.timezone == TimeZones.CST) {
    //         utcTimeObjectString = currentTimezoneTimeObject.add(5, 'hours').format('HH:mm:ss');
    //     }

    // }

    return utcTimeObjectString;

}

// Using this functionality for display the start_date and end_date with start_time and end_time
export const tzDateConverterTool_V1 = (date) => {

    let currentUser = getUser();
    let utcDateString = null;

    let utcTimeObjectString = moment.utc(date)

    utcDateString = utcTimeObjectString.tz(currentUser.timezone).format('MMM DD, YYYY (z)');

    // if (currentUser) {
    //     if (currentUser.timezone && currentUser.timezone == TimeZones.PST) {
    //         zone = '(PST)'
    //     } else if (currentUser.timezone && currentUser.timezone == TimeZones.CST) {
    //         zone = '(CST)'
    //     }
    // }

    // const localDate = moment.utc(date);

    // /* Condition for Pacific Daylight Time Conversion */
    // if (isPacificDaylightTime) {
    //     currentTimezoneDateObject = localDate.add(0, 'hour');
    // } else {
    //     currentTimezoneDateObject = localDate.add(1, 'hour');
    // }

    // /* Condition for Current Timezone Conversion */
    // finalFormatedDate = momentTZ(currentTimezoneDateObject).tz(currentUser.timezone);
    // let convertedTimezoneDate = momentTZ(finalFormatedDate).format('MMM DD, YYYY') + ' ' + zone; //'YYYY-MM-DD HH:mm:ss (z)'

    return utcDateString;

}

export const tzDateConverterTool_V2 = (date) => {

    let currentUser = getUser();
    let utcDateString = null;

    let utcTimeObjectString = moment.utc(date)

    utcDateString = utcTimeObjectString.tz(currentUser.timezone).format('dddd');

    // let currentUser = getUser();
    // let isPacificDaylightTime = toFindPDTAvailability(date);
    // let currentTimezoneDateObject = null;
    // let finalFormatedDate = null;

    // const localDate = momentTZ(date);

    // /* Condition for Pacific Daylight Time Conversion */
    // if (isPacificDaylightTime) {
    //     currentTimezoneDateObject = localDate.add(0, 'hour');
    // } else {
    //     currentTimezoneDateObject = localDate.add(1, 'hour');
    // }

    // /* Condition for Current Timezone Conversion */
    // finalFormatedDate = momentTZ(currentTimezoneDateObject).tz(currentUser.timezone);
    // let convertedTimezoneDayName = momentTZ(finalFormatedDate).format('dddd'); //'YYYY-MM-DD HH:mm:ss (z)'

    return utcDateString;

}

export const tzDateConverterTool_V3 = (date) => {

    // let currentUser = getUser();
    // let isPacificDaylightTime = toFindPDTAvailability(date);
    // let currentTimezoneDateObject = null;
    // let finalFormatedDate = null;

    // const localDate = momentTZ(date);

    // /* Condition for Pacific Daylight Time Conversion */
    // if (isPacificDaylightTime) {
    //     currentTimezoneDateObject = localDate.add(0, 'hour');
    // } else {
    //     currentTimezoneDateObject = localDate.add(1, 'hour');
    // }

    // /* Condition for Current Timezone Conversion */
    // finalFormatedDate = momentTZ(currentTimezoneDateObject).tz(currentUser?.timezone);
    // let convertedTimezoneDate = new Date(finalFormatedDate);

    // return convertedTimezoneDate;

    let utcDateString = null;
    let currentUser = getUser();

    let utcTimeObjectString = moment.utc(date)

    utcDateString = utcTimeObjectString.tz(currentUser.timezone).format('YYYY-MM-DD')
    //return new Date(utcDateString);
    return moment(utcDateString)._d
}

// Using this functionality for display the start_date and end_date without start_time and end_time
export const tzDateConverterTool_V4 = (date) => {

    let currentUser = getUser();
    let utcDateString = null;

    let utcTimeObjectString = moment.utc(date)

    utcDateString = utcTimeObjectString.tz(currentUser.timezone).format('MMM DD, YYYY (z)');

    return utcDateString;

}

// Using this functionality for display the start_date and end_date without start_time and end_time
export const tzDateConverterTool_V5 = (date) => {

    let currentUser = getUser();
    // let isPacificDaylightTime = toFindPDTAvailability(date);
    let localDate = null;
    // let currentTimezoneDateObject = null;

    /* Condition for Pacific Daylight Time Conversion */
    // if (isPacificDaylightTime) {
    //     localDate = momentTZ(date).add(0, 'hour');
    // } else {
    //     localDate = momentTZ(date).add(1, 'hour');
    // }

    localDate = moment.utc(date);

    /* Condition for Current Timezone Conversion */
    // currentTimezoneDateObject = momentTZ(localDate).tz(currentUser.timezone);
    let convertedTimezoneDate = localDate.tz(currentUser.timezone).format('YYYY-MM-DDTHH:mm:ss'); //'YYYY-MM-DD HH:mm:ss (z)'

    return convertedTimezoneDate;

}

// Using this functionality for display the start_date and end_date with start_time and end_time
export const tzDateConverterTool_V6 = (date) => {

    let currentUser = getUser();
    let zone = momentTZ.tz(currentUser.timezone).format("z");
    let isPacificDaylightTime = toFindPDTAvailability(date);
    let currentTimezoneDateObject = null;
    let finalFormatedDate = null;

    // if (currentUser) {
    //     if (currentUser.timezone && currentUser.timezone == TimeZones.PST) {
    //         zone = '(PST)'
    //     } else if (currentUser.timezone && currentUser.timezone == TimeZones.CST) {
    //         zone = '(CST)'
    //     }
    // }

    const localDate = moment.utc(date);

    /* Condition for Pacific Daylight Time Conversion */
    if (isPacificDaylightTime) {
        currentTimezoneDateObject = localDate.add(0, 'hour');
    } else {
        currentTimezoneDateObject = localDate.add(1, 'hour');
    }

    /* Condition for Current Timezone Conversion */
    finalFormatedDate = momentTZ(currentTimezoneDateObject).tz(currentUser.timezone);
    let convertedTimezoneDate = momentTZ(finalFormatedDate).format('YYYY-MM-DDThh:mm A').split('T');

    let capturedDate = convertedTimezoneDate[0];
    let capturedTime = convertedTimezoneDate[1];

    let finalFormattedResult = capturedDate + ', ' + capturedTime + ' ' + zone; //'YYYY-MM-DD HH:mm:ss (z)'

    return finalFormattedResult;

}

export const tzDateConverterTool_V6_Dateonly = (date) => {

    let utcDateString = null;
    let currentUser = getUser();

    let utcTimeObjectString = moment.utc(date)

    utcDateString = utcTimeObjectString.tz(currentUser.timezone).format('YYYY-MM-DD (z)')
    
    return utcDateString;

}

/* Using this functionality for display the date with time */
export const tzDateConverterTool_V7 = (date) => {

    let currentUser = getUser();
    let utcDateString = null;

    let utcTimeObjectString = moment.utc(date)

    utcDateString = utcTimeObjectString.tz(currentUser.timezone).format('MMM DD, YYYY hh:mm A (z)');

    return utcDateString;

    // let fetchedDate = momentTZ(finalFormatedDate).format('MMM DD, YYYY'); //'YYYY-MM-DD HH:mm:ss (z)'
    // let fetchedTime = momentTZ(finalFormatedDate).format('hh:mm A'); //'YYYY-MM-DD HH:mm:ss (z)'

    // let convertedTimezoneDate = fetchedDate + ' ' + `[${fetchedTime}]` + ' ' + zone;

    // return convertedTimezoneDate;

}

/* Converting 24hours format into 12hours format */
export const tzTimeConversionTool_V1 = (time, date) => {

    let currentUser = getUser();
    let utcTimeObjectString = null;

    let combinedDateTimeObject =  moment.utc(`${date}T${time}`);

    utcTimeObjectString = combinedDateTimeObject.tz(currentUser.timezone).format('hh:mm A');

    // let currentUser = getUser();
    // let zone = null;
    // let isPacificDaylightTime = toFindPDTAvailability(date);
    // let localDate = null;
    // let finalFormatedTime = null;

    // if (currentUser) {
    //     if (currentUser.timezone && currentUser.timezone == TimeZones.PST) {
    //         zone = '(PST)'
    //     } else if (currentUser.timezone && currentUser.timezone == TimeZones.CST) {
    //         zone = '(CST)'
    //     }
    // }

    // let convertedDateString = moment(date).format('YYYY-MM-DD');
    // let combinedDateTimeObject = momentTZ(`${convertedDateString} ${time}`, 'YYYY-MM-DD HH:mm:ss'); // Combine date and time

    // /* Condition for Pacific Daylight Time Conversion */
    // if (isPacificDaylightTime) {
    //     localDate = combinedDateTimeObject.add(0, 'hour');
    // } else {
    //     localDate = combinedDateTimeObject.add(1, 'hour');
    // }

    // /* Condition for Current Timezone Conversion */
    // finalFormatedTime = momentTZ(localDate).tz(currentUser.timezone);
    // let result = momentTZ(finalFormatedTime._d).format('hh:mm A') + ' ' + zone;

    return utcTimeObjectString;

}

/* Converting 24hours format into 12hours format in Update Forms */
export const tzTimeConversionTool_V2 = (time, date) => {

    let currentUser = getUser();
    let utcTimeObjectString = null;

    let combinedDateTimeObject =  moment.utc(`${date}T${time}`);

    utcTimeObjectString = combinedDateTimeObject.tz(currentUser.timezone).format('hh:mm A');

    return utcTimeObjectString

}

/* Converting Date format into 12hours format */
export const tzTimeConverterTool_V3 = (date) => {

    let currentUser = getUser();
    let utcTimeObjectString = null;

    let combinedDateTimeObject =  moment.utc(date);

    utcTimeObjectString = combinedDateTimeObject.tz(currentUser.timezone).format('hh:mm A z');

    return utcTimeObjectString

}

/* Converting 24hours format into 24hours format from utc to current timezone */
export const tzTimeConversionTool_V4 = (time, date) => {

    let currentUser = getUser();
    let isPacificDaylightTime = toFindPDTAvailability(date);
    let localDate = null;
    let finalFormatedTime = null;

    let convertedDateString = date.split('T')[0];
    let combinedDateTimeObject = momentTZ(`${convertedDateString} ${time}`, 'YYYY-MM-DD HH:mm:ss'); // Combine date and time

    /* Condition for Pacific Daylight Time Conversion */
    if (isPacificDaylightTime) {
        localDate = combinedDateTimeObject.add(0, 'hour');
    } else {
        localDate = combinedDateTimeObject.add(1, 'hour');
    }

    /* Condition for Current Timezone Conversion */
    finalFormatedTime = momentTZ(localDate).tz(currentUser.timezone);
    let result = momentTZ(finalFormatedTime._d).format('HH:mm:ss');

    return result;

}

/* Taking the start and end value from GET api call */
export const tzInbetweenDaysFinder_V1 = (start, end) => {

    let currentUser = getUser();

    const startDate = customTime(new Date(DateTime.fromISO(start.split('T')[0]).setZone(currentUser.timezone).toFormat('y-MM-d')));
    const endDate = customTime(new Date(DateTime.fromISO(end.split('T')[0]).setZone(currentUser.timezone).toFormat('y-MM-d')));

    const startDateTime = DateTime.fromJSDate(startDate);
    const endDateTime = DateTime.fromJSDate(endDate);

    let daysBetween = [];

    let currentDate = startDateTime;
    while (currentDate <= endDateTime) {
        daysBetween.push(currentDate.toJSDate());
        currentDate = currentDate.plus({ days: 1 });
    }

    return daysBetween && daysBetween.length == 1 ? `${daysBetween.length} Day` : `${daysBetween.length} Days`

}

/* Taking the start and end value from Form calendar */
export const tzInbetweenDaysFinder_V2 = (start, end) => {

    let currentUser = getUser();

    const startDate = customTime(new Date(DateTime.fromJSDate(start).setZone(currentUser.timezone).toFormat('y-MM-d')));
    const endDate = customTime(new Date(DateTime.fromJSDate(end).setZone(currentUser.timezone).toFormat('y-MM-d')));

    const startDateTime = DateTime.fromJSDate(startDate);
    const endDateTime = DateTime.fromJSDate(endDate);

    let daysBetween = [];

    let currentDate = startDateTime;
    while (currentDate <= endDateTime) {
        daysBetween.push(currentDate.toJSDate());
        currentDate = currentDate.plus({ days: 1 });
    }

    return daysBetween && daysBetween.length == 1 ? `${daysBetween.length} Day` : `${daysBetween.length} Days`

}



export const tzTimeConversionTool_V2alpha = (timestring, date) => {

    let currentUser = getUser();
    let isPacificDaylightTime = toFindPDTAvailability(date);
    let localDate = null;
    let finalFormatedTime = null;

    let convertedDateString = date.split('T')[0];
    let combinedDateTimeObject = momentTZ(timestring, 'YYYY-MM-DD HH:mm:ss'); // Combine date and time

    /* Condition for Pacific Daylight Time Conversion */
    if (isPacificDaylightTime) {
        localDate = combinedDateTimeObject.add(0, 'hour');
    } else {
        localDate = combinedDateTimeObject.add(1, 'hour');
    }

    /* Condition for Current Timezone Conversion */
    finalFormatedTime = combinedDateTimeObject.format("hh:mm A")


    return finalFormatedTime;

}


export const tzDateConverterTool_V6R = (date) => {

    let currentUser = getUser();
    let utcTimeObjectString = null;

    let combinedDateTimeObject =  moment.utc(date);

    utcTimeObjectString = combinedDateTimeObject.tz(currentUser.timezone).format('MM-DD-YYYY, hh:mm A (z)');

    return utcTimeObjectString;

}

// export function Utc_Timezone_To_Selected_Timezones(date, Timezone, isFormat, format = "hh:mm a") {
//     //find our current systemTimezone 
//     const InputTimeZone = "UTC"
//     //here take user selected timezone otherwise system timezone ;
//     const selectedTimezone = Timezone ? Timezone : Intl.DateTimeFormat().resolvedOptions().timeZone;
//     // convert time into utc timezone first
//     const local_Time_in_utc = momentTZ(date).tz(InputTimeZone)
//     //  convert time into selected timezone from utc .
//     const convertedTime_in_selected_timezone=local_Time_in_utc.tz(selectedTimezone)
    
//     let convertedTime = null;
//     if (isFormat) {
//         convertedTime = convertedTime_in_selected_timezone.format(format)
//     } else {
//         convertedTime = convertedTime_in_selected_timezone.format()
//     }
//     return convertedTime;
// }
// export function Selected_TimeZone_To_UTC(date, selectedTimezone, isFormat, format = "hh:mm a") {
//     //here we get selected Timezone
//     const to = "UTC"
//    // here we convert the time into selected Timezone
//     const localTime_in_selected_timezone = momentTZ(date).tz(selectedTimezone)
//   // here we convert the time to utc from selected timezone 
//     const UTC_time=localTime_in_selected_timezone.tz(to)
//     let convertedTime = null
//     if (isFormat) {
//         convertedTime = UTC_time.format(format)
//     } else {
//         convertedTime = UTC_time.format()
//     }
//     return convertedTime
// }


//  const currentPstDate = "2024-03-09T03:00:00";
// const pst_utc=Selected_TimeZone_To_UTC(currentPstDate,"America/Los_Angeles",false)
// const utc_pst=Utc_Timezone_To_Selected_Timezones(pst_utc,"America/Los_Angeles",false)
// console.log("CURR DATE",currentPstDate);
// console.log("PST TO UTC",pst_utc);
// console.log("UTC TO PST",utc_pst);
// debugger;

export const tzUTCTimeToActualTime = (date) => {
    let currentUser = getUser();
    let utcTimeObjectString = moment.utc(date)
    utcTimeObjectString = utcTimeObjectString.tz(currentUser.timezone).format('HH:mm:ss');
    return utcTimeObjectString;
}

export const tzUTCDateConverterTool_V3 = (date) => {

    let utcDateString = null;
    let currentUser = getUser();

    let utcTimeObjectString = moment.utc(date)

    utcDateString = utcTimeObjectString.tz(currentUser.timezone).format('YYYY-MM-DDTHH:mm:ss');
    

    return utcDateString;

}

export const tzUTCDateConverterTool_V4 = (date, type,timezone) => {
    debugger;
    let utcDateString = null;

    if (type === 'from') {
        utcDateString = momentTZ.tz(date, timezone).startOf('day').utc().format("YYYY-MM-DDTHH:mm:ss"); //'T00:00:00'
    } else if (type == 'to') {
         
        utcDateString = momentTZ.tz(date, timezone).endOf('day').utc().format("YYYY-MM-DDTHH:mm:ss");//'T23:59:59'
    } else if (type == 'none') {
        utcDateString = momentTZ.tz(date, timezone).utc().format("YYYY-MM-DDTHH:mm:ss");
    }
    return utcDateString;

}

export const convertDateTimezoneV1 = (date, timezone) => {
    // const formattedDate = date;
    let utcDateString = null;
    let utcTimeObjectString = moment.utc(date)

    utcDateString = utcTimeObjectString.tz(timezone).format('MM-DD-YYYY (z)');
    return utcDateString
}

export const tzTimeConversionToolwithAbb_V1 = (time, date) => {

    let currentUser = getUser();
    let utcTimeObjectString = null;

    let combinedDateTimeObject =  moment.utc(`${date}T${time}`);

    utcTimeObjectString = combinedDateTimeObject.tz(currentUser.timezone).format('hh:mm A (z)');

    return utcTimeObjectString;

}



//used for all forms and audit table list 
export const convertDateUTCFormAudit= (date,format="MM/DD/YYYY (z)") => {
    // const formattedDate = date;
    let currentUser = getUser();
    let utcDateString = null;
    let utcTimeObjectString = moment.utc(date)
    utcDateString = utcTimeObjectString.tz(currentUser.timezone).format(format);
    debugger;
    return utcDateString
}

//used for view and edit in formAudit forms
export const convertDateTimezoneFormAudit= (date) => {
    if (date) {
        let currentUser = getUser();
        let utcDateString = null;
        let utcTimeObjectString = moment.utc(date)
        utcDateString = utcTimeObjectString.tz(currentUser.timezone)
        let newDate =utcDateString.format('YYYY-MM-DDTHH:mm:ss');
        return moment(newDate)._d
      
    }
}

export const convertDateToUTCFormAudit=(date,format="MM/DD/YYYY (z)") => {
    // const formattedDate = date;
    let currentUser = getUser();
    let utcDateString = null;
    let utcTimeObjectString = moment(date).tz(currentUser.timezone)
    utcDateString = utcTimeObjectString.utc().format(format);
    return utcDateString
}


export const tzUTCDateConverterTool_FormAudit = (date, type,format="YYYY-MM-DDTHH:mm:ss") => {
    let currentUser = getUser();
    let utcDateString = null;
    
    if (type === 'from') {
        utcDateString = momentTZ.tz(date, currentUser.timezone).startOf('day').utc().format(format); //'T00:00:00'
    } else if (type == 'to') {
        utcDateString = momentTZ.tz(date, currentUser.timezone).endOf('day').utc().format(format);//'T23:59:59'
    } else if (type == 'none') {
        utcDateString = momentTZ.tz(date, currentUser.timezone).utc().format(format);
    }
    return utcDateString;

}


export const tzUTCDateConverterTool_FormAuditEdit = (date, type,format="YYYY-MM-DDTHH:mm:ss") => {
    let currentUser = getUser();
    let utcDateString = null;
    if (type === 'from') {
        utcDateString = momentTZ.tz(date, currentUser.timezone).startOf('day').utc().format(format); //'T00:00:00'
    } else if (type == 'to') {
        let changedDate=momentTZ(date,currentUser.timezone).startOf('day')._d
        utcDateString = momentTZ.tz(changedDate, currentUser.timezone).endOf('day').utc().format();//'T23:59:59'
    } else if (type == 'none') {
        utcDateString = momentTZ.tz(date, currentUser.timezone).utc().format(format);
    }
    return utcDateString;

}

export function ConvertToFormat(date,format="YYYY-MM-DD"){
    return moment(date).format(format)
}

export const convertTimeToUTCFormAudit=(time,format="MM/DD/YYYY (z)",inputFormat) => {
    // const formattedDate = date;
    let currentUser = getUser();
    let utcTimeString = null;
    let utcTimeObjectString = moment.utc(time,inputFormat)
    utcTimeString = utcTimeObjectString.tz(currentUser.timezone).format(format);
    debugger;
    return utcTimeString
}




export const convertTimeStringFormAudit= (time,format="HH:mm:ss")=>{
    // const formattedDate = date;
    let currentUser = getUser();
    let utcTimeString = null;
    let utcTimeObjectString = moment.utc(time,format)
    utcTimeString = utcTimeObjectString.tz(currentUser.timezone);
    return utcTimeString
}

// export const convertTimeStringFormAuditJobCard= (start_time,end_time,decider,format="HH:mm:ss")=>{
//     // const formattedDate = date;
//     let currentUser = getUser();
//     let utcTimeStringStartTime = null;
//     const currentDate= new Date().getDate()
    
//     let utcTimeObjectStringStartTime = moment.utc(start_time,format)
//     utcTimeStringStartTime = utcTimeObjectStringStartTime.tz(currentUser.timezone)

//     let utcTimeObjectStringEnd = moment.utc(end_time,format)
//     let utcTimeStringEnd = utcTimeObjectStringEnd.tz(currentUser.timezone);

//     //here we make both date in same Time
//     utcTimeStringStartTime.set("date",currentDate)
//     utcTimeStringEnd.set("date",currentDate)

//     //check if startTime is less than end Time  

//     if(utcTimeObjectStringStartTime.isAfter(utcTimeObjectStringEnd)){
//         // here we dont need to do anything
//         utcTimeObjectStringEnd.add(1,"days")
//     }
//     if(decider=="start_time") return utcTimeObjectStringStartTime
//     return utcTimeObjectStringEnd
//     }
    
    
    




export const convertTimeStringToUTCFormAudit= (time,format="HH:mm:ss")=>{
    // const formattedDate = date;
    let currentUser = getUser();
    let utcTimeString = null;
    let utcTimeObjectString = moment.tz(time, format, currentUser.timezone);
    utcTimeString = utcTimeObjectString.utc().format(format);
    return utcTimeString
}

export const convertDateTimezoneFormAuditReduxForm = (date) => {
    if (date) {
        let currentUser = getUser();
        let utcDateString = null;
        let utcTimeObjectString = moment.utc(date)
        utcDateString = utcTimeObjectString.tz(currentUser.timezone)
        let newDate =utcDateString.format('YYYY-MM-DDTHH:mm:ss');
        return moment(newDate)._d
      
    }
}

export const addTimezoneToTime=()=>{
    let currentUser = getUser();
    let zone = momentTZ.tz(currentUser.timezone).format("z")
    return zone;
}

export function combineDateAndTime(date,time){
    let combinedDateTime = `${date} ${time}`; 
    debugger; 
    return combinedDateTime;
}


export const convertDateUTCFormAuditToSelectedTimezone= (date,timezone,format="MM/DD/YYYY (z)") => {
    // const formattedDate = date;
    
    if(!timezone){
        timezone= getUser().timezone
    }
    let utcDateString = null;
    let utcTimeObjectString = moment.utc(date)
    utcDateString = utcTimeObjectString.tz(timezone).format(format);
    return utcDateString
}

export const convertDateTimezoneFormAuditToSelectedTimezone= (date,timezone) => {
    if (date) {
        if(!timezone){
            timezone= getUser().timezone
        }
        let utcDateString = null;
        let utcTimeObjectString = moment.utc(date)
        utcDateString = utcTimeObjectString.tz(timezone)
        let newDate =utcDateString.format('YYYY-MM-DDTHH:mm:ss');
        return moment(newDate)._d
      
    }
}

export const tzDateConverterTool_V2PreviewEvent = (date) => {

    let currentUser = getUser();
    let isPacificDaylightTime = toFindPDTAvailability(date);
    let currentTimezoneDateObject = null;
    let finalFormatedDate = null;

    const localDate = momentTZ(date);

    /* Condition for Pacific Daylight Time Conversion */
    if (isPacificDaylightTime) {
        currentTimezoneDateObject = localDate.add(0, 'hour');
    } else {
        currentTimezoneDateObject = localDate.add(1, 'hour');
    }

    /* Condition for Current Timezone Conversion */
    finalFormatedDate = momentTZ(currentTimezoneDateObject).tz(currentUser.timezone);
    let convertedTimezoneDayName = momentTZ(finalFormatedDate).format('hh:mm a'); //'YYYY-MM-DD HH:mm:ss (z)'
   
    return convertedTimezoneDayName;

}

//////////////////////////////


export const tzUTCDateTimeConverterTool_V1_Prod = (date, time, type) => {

    // let systemTimeZone = momentTZ.tz.guess(); // Identify the current system time zone
    let currentUser = getUser();
    // let startEndOfDayFormatedDate = null;
    let utcDateString = null;

    if (type === 'from') {
        // startEndOfDayFormatedDate = momentTZ(`${convertDateFormatV4(date)} ${time}`).startOf('day'); //'T00:00:00'
        utcDateString = momentTZ.tz(`${convertDateFormatV4(date)} ${time}`, currentUser.timezone).startOf('day').utc().format('YYYY-MM-DDTHH:mm:ss'); //'T00:00:00'
    } else if (type == 'to') {
        // startEndOfDayFormatedDate = momentTZ(`${convertDateFormatV4(date)} ${time}`).endOf('day'); //'T23:59:59'
        utcDateString = momentTZ.tz(`${convertDateFormatV4(date)} ${time}`, currentUser.timezone).endOf('day').utc().format('YYYY-MM-DDTHH:mm:ss');
    } else if (type == 'none') {
        utcDateString = momentTZ.tz(`${convertDateFormatV4(date)} ${time}`, currentUser.timezone).utc().format('YYYY-MM-DDTHH:mm:ss');
    }

    /* Condition for comparing syatem and user timezone */
    // if (systemTimeZone == currentUser.timezone) {

    //     /* Condition for Current Timezone Conversion */
    //     if (currentUser.timezone == TimeZones.PST) {
    //         utcDateString = startEndOfDayFormatedDate.add(7, 'hours').format('YYYY-MM-DDTHH:mm:ss');
    //     } else if (currentUser.timezone == TimeZones.CST) {
    //         utcDateString = startEndOfDayFormatedDate.add(5, 'hours').format('YYYY-MM-DDTHH:mm:ss');
    //     }

    // } else {

    //     let currentTimezoneDateObject = startEndOfDayFormatedDate.tz(currentUser.timezone);
    //     /* Condition for Current Timezone Conversion */
    //     if (currentUser.timezone == TimeZones.PST) {
    //         utcDateString = currentTimezoneDateObject.add(7, 'hours').format('YYYY-MM-DDTHH:mm:ss');
    //     } else if (currentUser.timezone == TimeZones.CST) {
    //         utcDateString = currentTimezoneDateObject.add(5, 'hours').format('YYYY-MM-DDTHH:mm:ss');
    //     }

    // }

    return utcDateString;

}


export const tzUTCTimeConverterTool_V1_Prod = (date, time) => {

    // let systemTimeZone = moment.tz.guess(); // Identify the current system time zone
    let currentUser = getUser();
    let utcTimeObjectString = null;

    let combinedDateTimeObject = `${date}T${time}`;
    
    utcTimeObjectString = momentTZ.tz(combinedDateTimeObject, currentUser.timezone).utc().format('HH:mm:ss');
    
    /* Condition for comparing syatem and user timezone */
    // if (systemTimeZone == currentUser.timezone) {

    //     /* Condition for Current Timezone Conversion */
    //     if (currentUser.timezone == TimeZones.PST) {
    //         utcTimeObjectString = combinedDateTimeObject.add(7, 'hours').format('HH:mm:ss');
    //     } else if (currentUser.timezone == TimeZones.CST) {
    //         utcTimeObjectString = combinedDateTimeObject.add(5, 'hours').format('HH:mm:ss');
    //     }

    // } else {

    //     let currentTimezoneTimeObject = combinedDateTimeObject.tz(currentUser.timezone);
    //     /* Condition for Current Timezone Conversion */
    //     if (currentUser.timezone == TimeZones.PST) {
    //         utcTimeObjectString = currentTimezoneTimeObject.add(7, 'hours').format('HH:mm:ss');
    //     } else if (currentUser.timezone == TimeZones.CST) {
    //         utcTimeObjectString = currentTimezoneTimeObject.add(5, 'hours').format('HH:mm:ss');
    //     }

    // }

    return utcTimeObjectString;

}

export const tzUTCDateTimeConverterTool_TIME_DATE = (date, time) => {
    debugger;
    // let systemTimeZone = momentTZ.tz.guess(); // Identify the current system time zone
    let currentUser = getUser();
    // let startEndOfDayFormatedDate = null;
    let utcDateString = null;

    
        // startEndOfDayFormatedDate = momentTZ(`${convertDateFormatV4(date)} ${time}`).startOf('day'); //'T00:00:00'
        utcDateString = momentTZ.tz(`${convertDateFormatV4(date)} ${time}`, currentUser.timezone).utc().format('YYYY-MM-DDTHH:mm:ss'); //'T00:00:00'
   

    /* Condition for comparing syatem and user timezone */
    // if (systemTimeZone == currentUser.timezone) {

    //     /* Condition for Current Timezone Conversion */
    //     if (currentUser.timezone == TimeZones.PST) {
    //         utcDateString = startEndOfDayFormatedDate.add(7, 'hours').format('YYYY-MM-DDTHH:mm:ss');
    //     } else if (currentUser.timezone == TimeZones.CST) {
    //         utcDateString = startEndOfDayFormatedDate.add(5, 'hours').format('YYYY-MM-DDTHH:mm:ss');
    //     }

    // } else {

    //     let currentTimezoneDateObject = startEndOfDayFormatedDate.tz(currentUser.timezone);
    //     /* Condition for Current Timezone Conversion */
    //     if (currentUser.timezone == TimeZones.PST) {
    //         utcDateString = currentTimezoneDateObject.add(7, 'hours').format('YYYY-MM-DDTHH:mm:ss');
    //     } else if (currentUser.timezone == TimeZones.CST) {
    //         utcDateString = currentTimezoneDateObject.add(5, 'hours').format('YYYY-MM-DDTHH:mm:ss');
    //     }

    // }

    return utcDateString;

}
