import React, { useEffect, useState } from 'react';
import ReactTable from 'react-table-v6';
import '../../../../../styles/custom.css';
import { TicketStatus, userRoles, TimeZones, routes } from '../../../../../utility/constants/constants';
import {
    CustomNoDataComponent,
    trimString,
    makeFirstLetterCapital,
    convertDateTimezone,
    convertDateAndTimeTimezone, getUser, getWeekStartAndEndDay, convertTicketsDataToCsvType, convertDateToDifferentTZ, convertAreaScheduleTime, converShiftScheduleTime, convertDateFormatV5
} from '../../../../../utility/utility';
import { FaArrowDown, FaArrowUp } from "react-icons/fa";
import ReactTooltip from 'react-tooltip';
import { SwapOutlined } from '@ant-design/icons';
import * as actions from '../../../../../redux/actions/index';
import { useSelector, useDispatch } from 'react-redux';
import { MagicSpinner, PushSpinner, SwishSpinner, WaveSpinner, ClapSpinner } from 'react-spinners-kit';
import 'react-table-v6/react-table.css';
import storage from '../../../../../utility/storage';
import cloneDeep from 'clone-deep';
import { tzDateConverterTool_V1, tzDateConverterTool_V2, tzDateConverterTool_V6, tzTimeConversionTool_V1, tzUTCDateConverterTool_V2, tzUTCDateTimeConverterTool_V1 } from '../../../../../utility/TimezoneOperations/timezoneUtility';
var moment = require('moment');
var momentTZ = require('moment-timezone');

let SchedulesTaskMyTicketTable = (props) => {

    const dispatch = useDispatch();
    const SearchValue = useSelector(state => state.form.searchFLUserMyTicketsListForm && state.form.searchFLUserMyTicketsListForm.values ? state.form.searchFLUserMyTicketsListForm.values : {});
    const DateGenerate = useSelector(state => state.TimeTrackerModReducer.dataGenerated);
    const values = useSelector(state => state.TimeTrackerModReducer.stMyTicketsList_searchValues);
    const No_of_pages = useSelector(state => state.TimeTrackerModReducer.stMyTicketsList_total_pages);
    const No_of_rows = useSelector(state => state.TimeTrackerModReducer.stMyTicketsList_no_of_rows);
    const isSTMyTicketsList = useSelector(state => state.TimeTrackerModReducer.isSTMyTicketsList);
    const myTicketListCurPage = useSelector(state => state.TimeTrackerModReducer.stMyTicketsList_curPage);
    const SearchOrResetSTMyticketsListClicked = useSelector(state => state.TimeTrackerModReducer.SearchOrResetSTMyticketsListClicked);

    const data = props.stMyTicketsList && props.stMyTicketsList.map((value) => {
        const user = storage.get('user');
        return {
            id: value?.id ? value.id : '−',
            start_date: value?.scheduleTask?.start_date ? tzDateConverterTool_V1(value.scheduleTask.start_date) : '−',
            end_date: value?.scheduleTask?.end_date ? tzDateConverterTool_V1(value.scheduleTask.end_date) : '−',
            accountName: value?.account?.name ? value.account.name : '−',
            subTaskName: value?.subject ? value.subject : '−',
            status: value?.status ? makeFirstLetterCapital(value.status) : '−',
            assignee: makeFirstLetterCapital(user.first_name) + ' ' + makeFirstLetterCapital(user.last_name),
            actions:
                <>

                    <ReactTooltip place="left" type="dark" effect="solid" />
                    <span data-tip="View Shift Details" className="mr_20 account_edit_icn" onClick={() => { props.handlePreviewMyTicket({ ticketId: value.scheduleTask.id, subTicketId: value.id }); }}><i class="fas fa-eye" aria-hidden="true" ></i></span>

                </>
        }
    })

    const columns = [
        {
            Header: <><span> ID </span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /></>,
            accessor: 'id',
            show: props.stMyTicketsList.length !== 0,
        },
        {
            Header: <><span> Scheduled Start Date </span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /></>,
            accessor: 'start_date',
            show: props.stMyTicketsList.length !== 0,
        },
        {
            Header: <><span> Scheduled End Date </span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /></>,
            accessor: 'end_date',
            show: props.stMyTicketsList.length !== 0,
        },
        {
            Header: <><span> Job Name </span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /></>,
            accessor: 'accountName',
            show: props.stMyTicketsList.length !== 0,
        },
        {
            Header: <><span> Subtask Name </span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /></>,
            accessor: 'subTaskName',
            show: props.stMyTicketsList.length !== 0,
        },
        {
            Header: <><span> Status </span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /></>,
            accessor: 'status',
            show: props.stMyTicketsList.length !== 0,
        },
        {
            Header: <><span> Assignee </span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /></>,
            accessor: 'assignee',
            show: props.stMyTicketsList.length !== 0,
        },
        {
            Header: '',
            accessor: 'actions',
            width: 135,
        }
    ]

    const [tablePage, setTablePage] = useState(0);
    const tablePageNew = React.useRef();

    if (myTicketListCurPage !== tablePageNew.current) {
        tablePageNew.current = myTicketListCurPage;
    }

    const setTablePageFn = (page) => {
        setTablePage(page)
        props.flUserSTMyTicketsPageClicked(page)
        tablePageNew.current = page;
    }

    const fetchData = (state, instance) => {
        if (SearchOrResetSTMyticketsListClicked) {
            dispatch(actions.resetSearchOrResetTimeTracker())
        } else {
            
            if (!DateGenerate && !props.isSTMyTicketsList) {
                state.page = 2;
                const limit = state.pageSize;
                let page = tablePageNew.current >= 0 ? tablePageNew.current + 1 : myTicketListCurPage + 1;
                const myTicketsList_no_of_rows = state.pageSize;
                if (No_of_rows != myTicketsList_no_of_rows) {
                    page = 1;
                    setTablePageFn(0)
                }
                 debugger;
                const order = state.sorted[0]?.id;
                const orderBy = state.sorted && state.sorted.length ? state.sorted[0]?.desc === true ? 'desc' : 'asc' : null;
                let isFuture = SearchValue.scheduledTicketStatus && SearchValue.scheduledTicketStatus === 'upcoming' ? true : null;
                let isCurrent = SearchValue.scheduledTicketStatus && SearchValue.scheduledTicketStatus === 'today' ? true : null;
                let isPast = SearchValue.scheduledTicketStatus && SearchValue.scheduledTicketStatus === 'past' ? true : null;
                let currentDate = tzUTCDateConverterTool_V2(new Date(), 'to');
                let status=SearchValue.status && SearchValue.status? SearchValue.status : ""
                const accountId = props.location.state ?  props.location.state.jobId : null

                props.getFlUserScheduledTaskTickets({ page, limit, order, orderBy, isCurrent, isFuture, isPast, currentDate, accountId,status }, values)
            }
        }
    }

    return (
        SearchOrResetSTMyticketsListClicked && props.isSTMyTicketsList ?
            <div  >
                <ClapSpinner backColor="#89073c" size={40} frontColor="#89073c8a" />
            </div>
            :
            <ReactTable
                data={data}
                loading={props.isSTMyTicketsList}
                minRows={0}
                defaultPageSize={No_of_rows}
                pages={No_of_pages}
                gotoPage={myTicketListCurPage}
                page={myTicketListCurPage}
                onPageChange={(page) => { setTablePageFn(page) }}
                // onPageSizeChange={props.onUserPageSizeChange}
                manual
                onFetchData={fetchData}
                showPaginationTop={true}
                showPaginationBottom={false}
                showPagination={props.stMyTicketsList.length !== 0}
                columns={columns}
                // style={{marginTop: '10px'}}
                NoDataComponent={() => CustomNoDataComponent(props.isSTMyTicketsList, 'No FL User Scheduled Tickets List Found')}
                LoadingComponent={() =>
                    isSTMyTicketsList ?
                        <div style={{
                            display: "block",
                            position: "absolute",
                            left: 0,
                            height: '100%',
                            right: 0,
                            background: "rgba(255,255,255,0.8)",
                            transition: "all .3s ease",
                            top: 0,
                            bottom: 0,
                            textAlign: "center"
                        }}
                            className="loader_btn_block">
                            <ClapSpinner backColor="#89073c" size={40} frontColor="#89073c8a" />
                        </div> : null}
                getTheadThProps={(state, rowInfo, column, instance) => {
                    // console.log(column);
                    return {
                        style: {
                            borderTop: 'none',
                            borderBottom: 'none',
                            borderRight: 'none',
                            textAlign: 'center',
                            color: '#566975',
                            fontSize: '14px',
                            fontWeight: '600',
                            whiteSpace: 'nowrap',
                            float: 'left',
                        }
                    }
                }
                }
                getTrProps={(state, rowInfo, column, instance) => {
                    // onclick = () => userDetail(rowInfo ? rowInfo.row.user : rowInfo);
                    console.log(column);
                    return {
                        className: 'react-table-tr-element',
                        style: {
                            border: '1px solid #c7cdd1',
                            transition: '.3s ease-in-out',
                            width: '100%',
                            // borderRadius: '4px',
                            // height: '80px',
                            marginTop: '5px',
                            float: 'left'
                        }
                    }

                }}
                getTdProps={(state, rowInfo, column, instance) => {

                    if (column.id == 'image') {
                        return {
                            style: {
                                marginTop: '0px'
                            }
                        }
                    } else {
                        return {
                            className: 'react-td-element',
                        }
                        //  {
                        //   style: {
                        //     borderTop: 'none',
                        //     verticalAlign: 'middle',
                        //     fontSize: '14px',
                        //     color: '#2b3034',
                        //     marginTop: '10px !important',
                        //     textAlign: 'center',
                        //   }
                        // }
                    }
                }
                }
                // getPaginationProps={() => {
                //     return {
                //         style: {
                //             marginTop: '50px',
                //             border: 'none',
                //             boxShadow: 'none'
                //         }
                //     }
                // }
                // }
                getProps={() => {
                    return {
                        style: {
                            border: 'none'
                        }
                    }
                }}
            />
    )
};

export default SchedulesTaskMyTicketTable;