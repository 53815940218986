import React, { Component } from 'react';
import * as actions from '../../../redux/actions/index';
import { connect } from 'react-redux';
import { reset } from 'redux-form';
import { getUser } from '../../../utility/utility';
import { animateScroll as scroll } from 'react-scroll'
import Criteria from '../../component/Criteria/Criteria';
import NewVersion from '../../component/NewVersionComponent/NewVersionComponent';
const cloneDeep = require('clone-deep');
class NewVersionContainer extends Component {
    componentDidMount() {
        // if (!this.props.criteries) {
       
        scroll.scrollToTop();
    }


    render() {

        return (
            <NewVersion
                {...this.props}
                user={this.props.user}
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.authReducer.user,

    }
}

const mapDispatchToProps = (dispatch) => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewVersionContainer);