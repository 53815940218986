import React from 'react';
import isEmpty from '../../../utility/IsEmptyValidator';
import { Input } from '../UI/InputElement/InputElement';
import { myProfile as validate } from '../../../utility/validator/validator';
import { reduxForm } from 'redux-form';
// import { positionOptions } from '../../../utility/constants/constants';
import { connect } from 'react-redux';
import { Timezones, timezoneOptions, usTimeZones, userRoles } from '../../../utility/constants/constants';
import storage from '../../../utility/storage';
import cloneDeep from 'clone-deep';

let MyProfile = (props) => {

    const positionOptions = props.positionsList.map(pl => {
        return {
            id: pl.position.id,
            name: pl.position.name
        }
    })

    const { handleSubmit, pristine, reset, submitting, error } = props;
    return (
        <div id="profile" className="profile_tab cst_tab_content tab-pane fade show active edit_profile_modal">
            <div className="profile_img_block">
                <div className="circle_img user-pic">
                    <Input hidden={true} type="file" name="photo_path" onSelect={props.onFileChange} fileRef={props.reference} accept="image/*" />
                    {/* <input type="file" style={{ display: 'none' }} /> */}
                    <div className="user-profile-pic"><img src={props.base64 ? props.base64 : props.s3Url ? props.s3Url : "/customImages/user.png"} />
                        {/* <span class="user_title">D</span> */}
                        <div class="icn_userEdit" onClick={props.choosePhotoClicked}><a href="javascript:void (0)"><i class="fa fa-camera" aria-hidden="true"></i></a></div>
                    </div>
                </div>
                <div className="clearfix"></div>
                <div className="profile-details">
                    {/* <a href="javascript:void (0)" onClick={props.choosePhotoClicked}>Change profile image</a> */}
                </div>
            </div>
            <form className="form_content" onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col-md-4">
                        <div className="form-group" data-test="edit_profile_form">
                            <label for="email" className="label_modifier">First Name</label>
                            <Input dataTest="edit_profile_firstname" type="text" className="form-control input-modifier" id="email" placeholder="" name="user[first_name]" />
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="form-group">
                            <label className="label_modifier">Last Name</label>
                            <Input dataTest="edit_profile_lastname" type="text" className="form-control input-modifier" placeholder="" name="user[last_name]" />
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="form-group">
                            <label for="email" className="label_modifier">Mode</label>
                            <Input type="text" className="form-control input-modifier" id="email" placeholder="" readOnly={true} name="user[mode]" />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group">
                            <label for="email" className="label_modifier">Position</label>
                            <Input name="user[position_id]" type="select" options={positionOptions} className="custom-select input-modifier add_user_select" />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label for="email" className="label_modifier">Email</label>
                            <Input readOnly={true} type="text" name="user[email]" className="form-control input-modifier" placeholder="" />
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group">
                            <label for="email" className="label_modifier">Timezone</label>
                            <Input
                                name="user[timezone]"
                                className="custom-select input-modifier add_user_select"
                                type="select"
                                options={usTimeZones}
                                placeholder={"Please select"}
                                onChange={(zone) => {
                                    storage.set('appTimezone', zone.value);
                                    // window.location.reload(false);
                                }}
                            />
                        </div>
                    </div>
                </div>

                <div className="clearfix"></div>
                <div className="modal-footer">
                    <button data-test="submit_button" type="submit" disabled={props.isLoading} className={props.isLoading ? "btn cst_btn btn_danger mr-2 btn-wait" : "btn cst_btn btn_danger mr-2"} >{props.isLoading ? "" : "Save"}</button>
                    <button data-test="cancel_button" onClick={props.closeProfileModel} type="button" className="btn btn-outline-secondary cst_btn" data-dismiss="modal">Close</button>
                </div>
            </form>
        </div>
    )
}


const mapStateToProps = (state) => {

    let user = {};
    let fetchedUser = cloneDeep(state.authReducer.user);

    const getTimeZone = (timezone) => {

        let filteredTimezone = Timezones && Timezones.filter((e) => e.id == timezone);

        return filteredTimezone && filteredTimezone.map((e) => ({
            value: e.id,
            label: e.name
        }));

    }

    if (isEmpty(state.authReducer.user)) {

        user = {
            first_name: '',
            last_name: '',
            mode: '',
            position_id: '',
            email: '',
            timezone: ''
        }

    } else {

        user = {
            first_name: fetchedUser.first_name ? fetchedUser.first_name : '',
            last_name: fetchedUser.last_name ? fetchedUser.last_name : '',
            mode: fetchedUser.mode ? fetchedUser.mode : '',
            position_id: fetchedUser.position ? fetchedUser.position.id : '',
            email: fetchedUser.role == userRoles.FRONTLINE ? fetchedUser.primary_email ? fetchedUser.primary_email : '' : fetchedUser.email ? fetchedUser.email : '' ,
            timezone: fetchedUser.timezone ? fetchedUser.timezone : '',
        }

    }

    return { initialValues: { user, fetchedUser: fetchedUser } }
}

MyProfile = reduxForm({
    form: 'myProfileForm',
    validate
})(MyProfile);

MyProfile = connect(mapStateToProps)(MyProfile)

export default MyProfile;
