import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import {  ClapSpinner } from 'react-spinners-kit';


const AssociateAccountHolidaysModal = (props) => {
    return (
        <div className="modal fade performance_modal add_account associateUser" id="associateHolidays" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h2 className="md_title" id="exampleModalLongTitle" data-test="associate_holiday_title">Associate Holidays</h2>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" >
                            <span aria-hidden="true"><img src="/images/icons/icn_cancel_white.png" alt="Icon" onClick={props.closeAccountModalClicked} /></span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="quote_form ticket_form">
                                    <div className="addQuote_form">

                                        <div className="form-group">
                                        {/* <label className="label_modifier">Holiday</label> */}
                                                {/* <button type="button" className="btn btn_danger cst_btn">Submit</button> */}
                                                <div className="checkbox_block radius_checkbox">
                                                    <div className="custom-control custom-checkbox custom-control-inline">
                                                        <input onChange={props.selectAllClicked} checked={props.selectNone ? false : props.selectAll} type="checkbox" className="custom-control-input" id="selectAll" />
                                                        <label className="custom-control-label" for="selectAll">Select All</label>
                                                    </div>
                                                    <div className="custom-control custom-checkbox custom-control-inline">
                                                        <input onChange={props.selectNoneClicked} checked={props.selectAll ? false : props.selectNone} type="checkbox" className="custom-control-input" id="selectNone" />
                                                        <label className="custom-control-label" for="selectNone">Select None</label>
                                                    </div>
                                                </div>
                                            <div className="form-group checkbox_cst_grid">
                                                {props.holidaysList && props.holidaysList.map(h => {
                                                    return (
                                                        <div className="checkbox_block radius_checkbox">
                                                            <div className="custom-control custom-checkbox mb-2">
                                                                <input onChange={() => props.holidaySelected(h.id)} checked={props.setHolidaysCheckValue(h.id)} type="checkbox" className="custom-control-input" id={`holiday${h.id}`} />
                                                                <label data-test="holiday_checkbox" className="custom-control-label" for={`holiday${h.id}`}>{h.holiday_reason}</label>
                                                            </div>
                                                        </div>)
                                                })}
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer border-0 text-center">
                        <div className="btn_block">
                            {!props.holidaysList ?
                                <ClapSpinner backColor="#89073c" size={40} frontColor="#89073c8a" />
                                :
                                <button data-test="modal_submit_btn" onClick={props.submitAssociateAccountHolidays} disabled={props.holidaysList && props.holidaysList.length > 0 ? false : true} type="button" className={props.popupLoading ? "btn cst_btn btn_danger btn-wait" : "btn cst_btn btn_danger"} data-keyboard="false">{props.popupLoading ? "" : "Submit"}</button>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AssociateAccountHolidaysModal;