import React from 'react';
import { connect } from 'react-redux';
import { reset } from 'redux-form';
import * as actions from '../../../../../../redux/actions/index';
import { routes, address_attributes } from '../../../../../../utility/constants/constants';
import isEmpty from '../../../../../../utility/IsEmptyValidator';
import { extractExtention, getUser, resetOrientation } from '../../../../../../utility/utility';
import InventoryPullRequestForm from '../../../../../component/Inventory/InventoryPullRequestForm/InventoryPullRequestForm';
import moment from 'moment';
import SuccessAlert from '../../../../../../utility/successAlert/successAlert';
import BulkStorageForm from '../../../../../component/FormAuditModule/Inventory/BulkStorage/NewBulkStoragePage';
const cloneDeep = require('clone-deep');

class InventoryPullRequestContainer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isFormLoading: false,
            isButtonLoading: false,
        }
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        this.setState({isFormLoading: true})
        setTimeout(() => {
          this.setState({isFormLoading: false})
        }, 500);
        const user = getUser();
        this.props.getCommonJobsAccountList(user.mode);
        this.props.getInventoryItemNames();
        this.props.getAllInventoryLocationList({active_status: 'active', isAssociated: true});
    }

    componentDidUpdate(prevProps, prevState, snapshot) {}

    componentWillUnmount() {
        this.props.resetInventoryObjects();
    }

    handleSubmit = (values) => {
        this.setState({isButtonLoading: true})
        // deleting triggerPoint property used for triggering items and quantity field
        values.ticket.items.forEach((e) => {return delete e.triggerPoint})

        let inventoryTicketData = cloneDeep(values);
        if(inventoryTicketData.ticket) {

            if(inventoryTicketData.ticket.is_emergency && inventoryTicketData.ticket.is_emergency == "true") {
                inventoryTicketData.ticket.approver_id = Number(inventoryTicketData.ticket.approver_id.value);
            }

            inventoryTicketData.ticket.delivery_at = moment(inventoryTicketData.ticket.delivery_at).format('YYYY-MM-DD');
            inventoryTicketData.ticket.account_id = Number(inventoryTicketData.ticket.account_id.value);
            inventoryTicketData.ticket.location_id = Number(inventoryTicketData.ticket.location_id.value);
            inventoryTicketData.ticket.supervisor_id = Number(inventoryTicketData.ticket.supervisor_id.value);

            inventoryTicketData.ticket.items.map((value, index) => {
                return inventoryTicketData.ticket.items[index].id = value.id.value;
            })

        }
        SuccessAlert("Inventory Bulk Storage Created Succesfully")
        this.props.history.push('/dashboard')
        // this.props.createInventoryPullRequestTicket(inventoryTicketData);
    }

    getJobUsersInfo = (id) => {
        if(id) {
            this.props.getJobUsersInfo(id);
        };
    }

    onCancelClicked = () => {
        // this.props.history.goBack();
        this.props.history.push(routes.INVENTORY_BULK_STORAGE);
    }

    render() {

        return (
            <BulkStorageForm
                {...this.props}
                onSubmit={this.handleSubmit}
                isFormLoading={this.state.isFormLoading}
                isButtonLoading={this.state.isButtonLoading}
                getJobUsersInfo={this.getJobUsersInfo}
                onCancelClicked={this.onCancelClicked}
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.authReducer.user,
        commonJobsAccountList: state.InventoryReducer.commonJobsAccountList,
        inventoryItemNamesList: state.InventoryReducer.inventoryItemNamesList,
        jobUsersList: state.InventoryReducer.jobUsersList,
        inventoryLocationList: state.InventoryReducer.inventoryLocationList,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        resetInventoryObjects: () => dispatch(actions.resetInventoryObjects()),
        createInventoryPullRequestTicket: (data) => dispatch(actions.createInventoryPullRequestTicket(data)),
        getCommonJobsAccountList: (data) => dispatch(actions.getCommonJobsAccountList(data)),
        getInventoryItemNames: (data) => dispatch(actions.getInventoryItemNames(data)),
        getJobUsersInfo: (id) => dispatch(actions.getJobUsersInfo(id)),
        getAllInventoryLocationList: (data) => dispatch(actions.getAllInventoryLocationList(data)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(InventoryPullRequestContainer);