import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { useHistory } from 'react-router-dom';
import { ClapSpinner } from 'react-spinners-kit';
import { Empty } from 'antd';
import { getSinglePlaybookPdfInfo } from '../../../../redux/actions';

const PreviewPegassurePlaybookPdfContainer = () => {

  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch()
  const { isLoading, soloPlaybookPDFInfo } = useSelector((state) => state.PegassurePlaybookReducer);

  debugger
  const state = location.state;
  let pdf_path = state?.pdf_info && state.pdf_info.pdf_path ? state.pdf_info.pdf_path : null

  useEffect(() => {
    dispatch(getSinglePlaybookPdfInfo(location.pathname.split('/')[2]));
  }, []);

  return (
    <>
      <main className="app-content wraper_content inspection_wraper">

        {isLoading ? (
          <div className="loader_btn_block">
            <ClapSpinner backColor="#89073c" size={40} frontColor="#89073c8a" />
          </div>
        ) : (
          <>
            <div className="tab_header search_filter_cont inspection_tab_content appcontent_inner frontlines_filter mb-2 text-center d-flex align-items-center" id="user_filter" style={{ width: '100%' }}>
              <div style={{ width: '100%' }}>
                <span style={{ fontFamily: 'system-ui', fontSize: '250%', color: '#4e4e4e' }}> Pegassure Playbook Pdf </span>
              </div>
              {state.path ? (
                <div style={{ width: '12%' }}>
                  <div className="row">
                    <div className="col-xl-6 col-lg-4 col-md-4 col-sm-12">
                      <button type="button" className="btn cst_btn btn_danger " onClick={(e) => {
                        e.preventDefault();
                        history.push({ pathname: state.path, search: state.search });
                      }} data-toggle="modal" data-target="" data-backdrop="static" data-keyboard="false" data-test="import_csv"> Go to Review Logbook </button>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>

            <section className="account_sec inspection_tab_content appcontent_Inner">
              <div className="quotes_table_content accounts_table_contnet table-responsive add_account_modal add_frontline_pop appcontent_Inner user_newQuote pt-0">
                <div className="table quotes_table user_react_table container">
                  {soloPlaybookPDFInfo?.pdf_path ? (
                    <div className="row">
                      <div className="col-md-12 FLH_col">
                        <iframe
                          onLoad={(e) => isLoading}
                          src={`https://docs.google.com/gview?embedded=true&url=${encodeURIComponent(soloPlaybookPDFInfo?.pdf_path)}`}
                          title="PDF Viewer"
                          width="70%"
                          height="700px"
                          frameBorder="0"
                          allowFullScreen
                        />
                      </div>
                    </div>
                  ) : pdf_path ? (
                    <div className="row">
                      <div className="col-md-12 FLH_col">
                        <iframe
                          onLoad={(e) => isLoading}
                          src={`https://docs.google.com/gview?embedded=true&url=${encodeURIComponent(pdf_path)}`}
                          title="PDF Viewer"
                          width="70%"
                          height="700px"
                          frameBorder="0"
                          allowFullScreen
                        />
                      </div>
                    </div>
                  ) : (
                    <div className="loader_btn_block">
                      <p> <Empty description='No playbook pdf available' /> </p>
                    </div>
                  )}
                </div>
              </div>
            </section>
          </>
        )}

      </main >
    </>
  )

}

export default PreviewPegassurePlaybookPdfContainer