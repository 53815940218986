import React from 'react';
import { Input } from '../UI/InputElement/InputElement';
import { signInValidator as validate } from '../../../utility/validator/validator';
import { reduxForm } from 'redux-form';

const SignIn = (props) => {

    const { handleSubmit, pristine, reset, submitting, error } = props;
    return (
        <form className="login100-form" id="login_form" onSubmit={handleSubmit}>
            <div className="inner_form">
                <div className="fields">
                    <h1 data-test="signin_title">Sign In</h1>
                    <div className="form_group_modify">
                        <label for="email" className="label_modify">Enter Username or Email</label>
                        <Input id="email" value="test" name="user[email]" type="text" dataTest="email" className="input_modify input_modify_lg" />
                        <div className="form-error invisible"> &nbsp;</div>
                    </div>
                    <div className="form_group_modify">
                        <label for="password" className="label_modify">Password</label>
                        <Input id="password" dataTest="password" name="user[password]" type="password" className="input_modify input_modify_lg" />
                        <div className="form-error invisible" ng-className="{invisible: !loginForm.errors.password[0]}">
                            &nbsp
                        </div>
                    </div>
                    <div className="checkbox_block">
                        <div className="custom-control custom-checkbox">
                            <input type="checkbox" className="custom-control-input" id="customCheck1" />
                            <label data-test="keep_sign_checkbox" className="custom-control-label" for="customCheck1">Keep me signed in</label>
                        </div>
                    </div>

                </div>
                <button data-test="submit_button" disabled={props.isLoading} className={props.isLoading ? "theme_btn btn_lg btn_load btn-wait button_Disable" : "theme_btn btn_lg btn_load"} id="submit" type="submit">{props.isLoading ? "Please wait" : "Sign In"} </button>
                <br />
                <span data-test="forgot_password" style={{ cursor: 'pointer' }} className="underline forgot_pass" onClick={props.faceRecognitionLoginClicked}> Face Recognition Login </span>
                <br />
                <br />
                <span data-test="forgot_password" style={{ cursor: 'pointer' }} className="underline forgot_pass" onClick={props.forgetPasswordClicked}>Forgot your password?</span>
                <br />
                {/* <div className="step-info mt_30">Do not have Account? <a className="underline" href="register.html">Register</a></div> */}
            </div>
        </form>
    )
}

export default reduxForm({
    form: 'signInForm',
    validate
})(SignIn);