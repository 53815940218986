
export const BRMActionTypes = {
    SIGN_IN: 'SIGN_IN',
    SIGN_IN_PENDING: 'SIGN_IN_PENDING',
    SIGN_IN_FULFILLED: 'SIGN_IN_FULFILLED',

    CLICK_ON_MATRIX_BUTTON:'CLICK_ON_MATRIX_BUTTON',

    CUSTOM_MATRIX_MODAL_OPEN:'CUSTOM_MATRIX_MODAL_OPEN',
    STANDARD_MATRIX_MODAL_OPEN:'STANDARD_MATRIX_MODAL_OPEN',

    ADD_STANDARD_MATRIX:'ADD_STANDARD_MATRIX',
    ADD_STANDARD_MATRIX_PENDING: 'ADD_STANDARD_MATRIX_PENDING',
    ADD_STANDARD_MATRIX_FULFILLED: "ADD_STANDARD_MATRIX_FULFILLED",
    ADD_STANDARD_MATRIX_REJECTED: "ADD_STANDARD_MATRIX_REJECTED",

    GET_MATRIX_LIST:'GET_MATRIX_LIST',
    GET_MATRIX_LIST_PENDING: 'GET_MATRIX_LIST_PENDING',
    GET_MATRIX_LIST_FULFILLED: "GET_MATRIX_LIST_FULFILLED",
    GET_MATRIX_LIST_REJECTED: "GET_MATRIX_LIST_REJECTED",

    DELETE_STANDARD_MATRIX: 'DELETE_STANDARD_MATRIX',
    DELETE_STANDARD_MATRIX_PENDING: 'DELETE_STANDARD_MATRIX_PENDING',
    DELETE_STANDARD_MATRIX_FULFILLED: 'DELETE_STANDARD_MATRIX_FULFILLED',
    DELETE_STANDARD_MATRIX_REJECTED: "DELETE_STANDARD_MATRIX_REJECTED",

    UPDATE_STANDARD_MATRIX:'UPDATE_STANDARD_MATRIX',
    UPDATE_STANDARD_MATRIX_PENDING: 'UPDATE_STANDARD_MATRIX_PENDING',
    UPDATE_STANDARD_MATRIX_FULFILLED: "UPDATE_STANDARD_MATRIX_FULFILLED",
    UPDATE_STANDARD_MATRIX_REJECTED: "UPDATE_STANDARD_MATRIX_REJECTED",

    RESET_STANDARD_MATRIX:'RESET_STANDARD_MATRIX',
    RESET_STANDARD_MATRIX_PENDING: 'RESET_STANDARD_MATRIX_PENDING',
    RESET_STANDARD_MATRIX_FULFILLED: "RESET_STANDARD_MATRIX_FULFILLED",
    RESET_STANDARD_MATRIX_REJECTED: "RESET_STANDARD_MATRIX_REJECTED",
    
    ADD_CUSTOM_MATRIX:'ADD_CUSTOM_MATRIX',
    ADD_CUSTOM_MATRIX_PENDING: 'ADD_CUSTOM_MATRIX_PENDING',
    ADD_CUSTOM_MATRIX_FULFILLED: "ADD_CUSTOM_MATRIX_FULFILLED",
    ADD_CUSTOM_MATRIX_REJECTED: "ADD_CUSTOM_MATRIX_REJECTED",

    OPEN_MATRIX_DELETE_MODAL: 'OPEN_MATRIX_DELETE_MODAL',
    CLOSE_MATRIX_DELETE_MODAL: 'CLOSE_MATRIX_DELETE_MODAL',

    UPDATE_STANDARD_MATRIX_API:'UPDATE_STANDARD_MATRIX_API',
    UPDATE_STANDARD_MATRIX_API_PENDING: 'UPDATE_STANDARD_MATRIX_API_PENDING',
    UPDATE_STANDARD_MATRIX_API_FULFILLED: "UPDATE_STANDARD_MATRIX_API_FULFILLED",
    UPDATE_STANDARD_MATRIX_API_REJECTED: "UPDATE_STANDARD_MATRIX_API_REJECTED",

    COMPLETE_PREVIEW_EVENT:"COMPLETE_PREVIEW_EVENT",
    COMPLETE_PREVIEW_EVENT_PENDING:"COMPLETE_PREVIEW_EVENT_PENDING",
    COMPLETE_PREVIEW_EVENT_FULFILLED:"COMPLETE_PREVIEW_EVENT_FULFILLED",
    COMPLETE_PREVIEW_EVENT_REJECTED:"COMPLETE_PREVIEW_EVENT_REJECTED",

    GET_ALL_BRM_SCHEDULE:'GET_ALL_BRM_SCHEDULE',
    GET_ALL_BRM_SCHEDULE_PENDING: 'GET_ALL_BRM_SCHEDULE_PENDING',
    GET_ALL_BRM_SCHEDULE_FULFILLED: "GET_ALL_BRM_SCHEDULE_FULFILLED",

    BRM_SCHEDULE_PAGE_CLICKED : "BRM_SCHEDULE_PAGE_CLICKED",

    GET_ONE_FEEDBACK:'GET_ONE_FEEDBACK',
    GET_ONE_FEEDBACK_PENDING: 'GET_ONE_FEEDBACK_PENDING',
    GET_ONE_FEEDBACK_FULFILLED: "GET_ONE_FEEDBACK_FULFILLED",
    GET_ONE_FEEDBACK_REJECTED: "GET_ONE_FEEDBACK_REJECTED",
    BRM_SCHEDULE_PAGE_CLICKED : "BRM_SCHEDULE_PAGE_CLICKED",


    REMOVE_FEEDBACK:'REMOVE_FEEDBACK',
    GET_BRM_DASHBOARD:'GET_BRM_DASHBOARD',
    GET_BRM_DASHBOARD_PENDING: 'GET_BRM_DASHBOARD_PENDING',
    GET_BRM_DASHBOARD_FULFILLED: "GET_BRM_DASHBOARD_FULFILLED",
    GET_BRM_DASHBOARD_REJECTED: "GET_BRM_DASHBOARD_REJECTED",

    GET_MATRIX_COMPANIES:'GET_MATRIX_COMPANIES',
    GET_MATRIX_COMPANIES_PENDING: 'GET_MATRIX_COMPANIES_PENDING',
    GET_MATRIX_COMPANIES_FULFILLED: "GET_MATRIX_COMPANIES_FULFILLED",
    GET_MATRIX_COMPANIES_REJECTED: "GET_MATRIX_COMPANIES_REJECTED",

    FETCH_JOB_BASED_MATRIX:'FETCH_JOB_BASED_MATRIX',
    FETCH_JOB_BASED_MATRIX_PENDING: 'FETCH_JOB_BASED_MATRIX_PENDING',
    FETCH_JOB_BASED_MATRIX_FULFILLED: "FETCH_JOB_BASED_MATRIX_FULFILLED",
    FETCH_JOB_BASED_MATRIX_REJECTED: "FETCH_JOB_BASED_MATRIX_REJECTED",

    RESET_JOB_BASED_MATRIX:"RESET_JOB_BASED_MATRIX"
    
}


