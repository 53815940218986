import React, { useEffect, useState } from 'react';
import ReactTable from 'react-table-v6';
import '../../../../styles/custom.css';
import { TicketStatus, userRoles, TimeZones, routes } from '../../../../utility/constants/constants';
import {
    CustomNoDataComponent,
    trimString,
    makeFirstLetterCapital,
    convertDateTimezone,
    convertDateAndTimeTimezone, getUser, getWeekStartAndEndDay, convertTicketsDataToCsvType, convertDateToDifferentTZ, convertAreaScheduleTime, converShiftScheduleTime, convertDateFormatV5,
    ConvertToProperFormat
} from '../../../../utility/utility';
import { FaArrowDown, FaArrowUp } from "react-icons/fa";
import ReactTooltip from 'react-tooltip';
import { SwapOutlined } from '@ant-design/icons';
import * as actions from '../../../../redux/actions/index';
import { useSelector, useDispatch } from 'react-redux';
import { MagicSpinner, PushSpinner, SwishSpinner, WaveSpinner, ClapSpinner } from 'react-spinners-kit';
import 'react-table-v6/react-table.css';
import storage from '../../../../utility/storage';
import cloneDeep from 'clone-deep';
import { tzDateConverterTool_V1, tzDateConverterTool_V2, tzDateConverterTool_V6, tzDateConverterTool_V6_Dateonly, tzTimeConversionTool_V1 } from '../../../../utility/TimezoneOperations/timezoneUtility';
var moment = require('moment');

let TimeCardReviewListTable = (props) => {

    const dispatch = useDispatch();
    const SearchValue = useSelector(state => state.form.searchTimecardReviewedListForm && state.form.searchTimecardReviewedListForm.values ? state.form.searchTimecardReviewedListForm.values : {});
    const DateGenerate = useSelector(state => state.TimeTrackerModReducer.dataGenerated);
    const values = useSelector(state => state.TimeTrackerModReducer.timecardReviewedList_searchValues);
    const No_of_pages = useSelector(state => state.TimeTrackerModReducer.timecardReviewedList_total_pages);
    const No_of_rows = useSelector(state => state.TimeTrackerModReducer.timecardReviewedList_no_of_rows);
    const isTimecardReviewedList = useSelector(state => state.TimeTrackerModReducer.isTimecardReviewedList);
    const myTicketListCurPage = useSelector(state => state.TimeTrackerModReducer.timecardReviewedList_curPage);
    const SearchOrResetTimecardReviewedListClicked = useSelector(state => state.TimeTrackerModReducer.SearchOrResetTimecardReviewedListClicked);

    /* Condition for Manager access and payroll access restrictions */
    let managerAccess = props?.user?.position?.name == 'Manager' ? true : false;
    let payrollAccess = props?.user?.payroll_access == true ? true : false;

    const data = props.timecardReviewedList && props.timecardReviewedList.map((value) => {

        if (props?.user?.role == 'frontline') {
            return {
                id: value?.id ? value.id : '−',
                errorDate: value?.date_error ? tzDateConverterTool_V6_Dateonly(value.date_error) : '−',
                employee: value?.employee?.id ? makeFirstLetterCapital(value.employee.first_name) + ' ' + makeFirstLetterCapital(value.employee.last_name) : '−',
                manager: value?.manager?.id ? makeFirstLetterCapital(value.manager.first_name) + ' ' + makeFirstLetterCapital(value.manager.last_name) : '−',
                payroll: value?.payroll?.id ? makeFirstLetterCapital(value.payroll.first_name) + ' ' + makeFirstLetterCapital(value.payroll.last_name) : '−',
                status: value?.status ? ConvertToProperFormat(value.status) : '−',
                created_at:value?.created_at ? tzDateConverterTool_V6_Dateonly(value?.created_at) : '-' ,
                actions:
                    <>

                        <ReactTooltip place="left" type="dark" effect="solid" />
                        {(value.manager_id !== null) && (value.payroll_id !== null) ? (
                            <span data-tip="View Timecard corrected info" className="mr_20 account_edit_icn" style={{ color: '#89073c' }} onClick={() => { props.handleTimecardCorrectionFormClicked({ timecardId: value.id, shiftDetailId: value.shift_detail_id }); }}><i class="fas fa-clock" aria-hidden="true" ></i></span>
                        ) : (
                            <span data-tip="View Timecard corrected info" className="mr_20 account_edit_icn" onClick={() => { props.handleTimecardCorrectionFormClicked({ timecardId: value.id, shiftDetailId: value.shift_detail_id }); }}><i class="fas fa-clock" aria-hidden="true" ></i></span>
                        )}
                        {value.manager_id == null && (
                            <span data-tip="Delete Timecard" className="mr_20 account_edit_icn" onClick={() => props.handleFrequentTimeTracker_openDeleteModal(value.id)}><i class="fa fa-trash" aria-hidden="true"></i></span>
                        )}

                    </>
            }
        } else {
            return {
                id: value?.id ? value.id : '−',
                errorDate: value?.date_error ? tzDateConverterTool_V6_Dateonly(value.date_error) : '−',
                employee: value?.employee?.id ? makeFirstLetterCapital(value.employee.first_name) + ' ' + makeFirstLetterCapital(value.employee.last_name) : '−',
                manager: value?.manager?.id ? makeFirstLetterCapital(value.manager.first_name) + ' ' + makeFirstLetterCapital(value.manager.last_name) : '−',
                payroll: value?.payroll?.id ? makeFirstLetterCapital(value.payroll.first_name) + ' ' + makeFirstLetterCapital(value.payroll.last_name) : '−',
                status: value?.status ? ConvertToProperFormat(value.status) : '−',
                created_at:value?.created_at ? tzDateConverterTool_V6_Dateonly(value?.created_at) : '-' ,
                actions:
                    <>

                        <ReactTooltip place="left" type="dark" effect="solid" />
                        {(value?.manager?.id == null) && (managerAccess == true) ? (
                            <>
                                <span data-tip="Update Timecard correction" className="mr_20 account_edit_icn" onClick={() => { props.handleTimecardCorrectionFormClicked({ timecardId: value.id, shiftDetailId: value.shift_detail_id }); }}><i class="fas fa-clock" aria-hidden="true" ></i></span>
                            </>
                        ) : (value.manager_id !== null) && (value?.payroll?.id == null) && (payrollAccess == true) ? (
                            <>
                                <span data-tip="Update Timecard correction" className="mr_20 account_edit_icn" onClick={() => { props.handleTimecardCorrectionFormClicked({ timecardId: value.id, shiftDetailId: value.shift_detail_id }); }}><i class="fas fa-clock" aria-hidden="true" ></i></span>
                            </>
                        ) : (value.manager_id !== null) && (value.payroll_id !== null) ? (
                            <>
                                <span data-tip="View Timecard corrected info" className="mr_20 account_edit_icn" style={{ color: '#89073c' }} onClick={() => { props.handlePreviewTimecardCorrectedClicked({ timecardId: value.id, shiftDetailId: value.shift_detail_id }); }}><i class="fas fa-clock" aria-hidden="true" ></i></span>
                            </>
                        ) : null}

                    </>
            }
        }

    });

    const columns = [
        {
            Header: <><span> Timecard ID </span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /></>,
            accessor: 'id',
            sortable: false, // Disable sorting for this column
            show: props.timecardReviewedList.length !== 0,
        },
        {
            Header: <><span> Date of Error </span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /></>,
            accessor: 'errorDate',
            sortable: false, // Disable sorting for this column
            show: props.timecardReviewedList.length !== 0,
        },
        {
            Header: <><span> Employee Name </span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /></>,
            accessor: 'employee',
            show: props.timecardReviewedList.length !== 0,
        },
        {
            Header: <><span> Supervisor/Manager Name </span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /></>,
            accessor: 'manager',
            show: props.timecardReviewedList.length !== 0,
        },
        {
            Header: <><span> Payroll Name </span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /></>,
            accessor: 'payroll',
            show: props.timecardReviewedList.length !== 0,
        },
        {
            Header: <><span> Status </span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /></>,
            accessor: 'status',
            show: props.timecardReviewedList.length !== 0,
        },
        {
            Header: <><span> CreatedAt </span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /></>,
            accessor: 'created_at',
            show: props.timecardReviewedList.length !== 0,
        },
        {
            Header: '',
            accessor: 'actions',
            width: 135,
        }
    ];

    const [tablePage, setTablePage] = useState(0);
    const tablePageNew = React.useRef();

    if (myTicketListCurPage !== tablePageNew.current) {
        tablePageNew.current = myTicketListCurPage;
    }

    const setTablePageFn = (page) => {
        setTablePage(page)
        props.timecardReviewedListPageClicked(page)
        tablePageNew.current = page;
    }

    const fetchData = (state, instance) => {
        if (SearchOrResetTimecardReviewedListClicked) {
            dispatch(actions.resetSearchOrResetTimeTracker())
        } else {
            if (!DateGenerate && !props.isTimecardReviewedList) {
                state.page = 2;
                const limit = state.pageSize;
                let page = tablePageNew.current >= 0 ? tablePageNew.current + 1 : myTicketListCurPage + 1;
                const myTicketsList_no_of_rows = state.pageSize;
                if (No_of_rows != myTicketsList_no_of_rows) {
                    page = 1;
                    setTablePageFn(0)
                }

                const order = state.sorted[0]?.id;
                const orderBy = state.sorted && state.sorted.length ? state.sorted[0]?.desc === true ? 'desc' : 'asc' : null;

                if (props.user.role == 'frontline') {
                    let employedId = props.user.id;
                    props.getTimecardReviewedList({ page, limit, order, orderBy, employedId }, values);
                } else {
                    props.getTimecardReviewedList({ page, limit, order, orderBy }, values);
                }
            }
        }
    }

    return (
        SearchOrResetTimecardReviewedListClicked && props.isTimecardReviewedList ?
            <div  >
                <ClapSpinner backColor="#89073c" size={40} frontColor="#89073c8a" />
            </div>
            :
            <ReactTable
                data={data}
                loading={props.isTimecardReviewedList}
                minRows={0}
                defaultPageSize={No_of_rows}
                pages={No_of_pages}
                gotoPage={myTicketListCurPage}
                page={myTicketListCurPage}
                onPageChange={(page) => { setTablePageFn(page) }}
                // onPageSizeChange={props.onUserPageSizeChange}
                manual
                onFetchData={fetchData}
                showPaginationTop={true}
                showPaginationBottom={false}
                showPagination={props.timecardReviewedList.length !== 0}
                columns={columns}
                // style={{marginTop: '10px'}}
                NoDataComponent={() => CustomNoDataComponent(props.isTimecardReviewedList, 'No Timecard reviewed List Found')}
                LoadingComponent={() =>
                    isTimecardReviewedList ?
                        <div style={{
                            display: "block",
                            position: "absolute",
                            left: 0,
                            height: '100%',
                            right: 0,
                            background: "rgba(255,255,255,0.8)",
                            transition: "all .3s ease",
                            top: 0,
                            bottom: 0,
                            textAlign: "center"
                        }}
                            className="loader_btn_block">
                            <ClapSpinner backColor="#89073c" size={40} frontColor="#89073c8a" />
                        </div> : null}
                getTheadThProps={(state, rowInfo, column, instance) => {
                    // console.log(column);
                    return {
                        style: {
                            borderTop: 'none',
                            borderBottom: 'none',
                            borderRight: 'none',
                            textAlign: 'center',
                            color: '#566975',
                            fontSize: '14px',
                            fontWeight: '600',
                            whiteSpace: 'nowrap',
                            float: 'left',
                        }
                    }
                }
                }
                getTrProps={(state, rowInfo, column, instance) => {
                    // onclick = () => userDetail(rowInfo ? rowInfo.row.user : rowInfo);
                    console.log(column);
                    return {
                        className: 'react-table-tr-element',
                        style: {
                            border: '1px solid #c7cdd1',
                            transition: '.3s ease-in-out',
                            width: '100%',
                            // borderRadius: '4px',
                            // height: '80px',
                            marginTop: '5px',
                            float: 'left'
                        }
                    }

                }}
                getTdProps={(state, rowInfo, column, instance) => {

                    if (column.id == 'image') {
                        return {
                            style: {
                                marginTop: '0px'
                            }
                        }
                    } else {
                        return {
                            className: 'react-td-element',
                        }
                        //  {
                        //   style: {
                        //     borderTop: 'none',
                        //     verticalAlign: 'middle',
                        //     fontSize: '14px',
                        //     color: '#2b3034',
                        //     marginTop: '10px !important',
                        //     textAlign: 'center',
                        //   }
                        // }
                    }
                }
                }
                // getPaginationProps={() => {
                //     return {
                //         style: {
                //             marginTop: '50px',
                //             border: 'none',
                //             boxShadow: 'none'
                //         }
                //     }
                // }
                // }
                getProps={() => {
                    return {
                        style: {
                            border: 'none'
                        }
                    }
                }}
            />
    )
};

export default TimeCardReviewListTable;