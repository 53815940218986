import React, { useState, useEffect } from 'react';
import { MagicSpinner, PushSpinner, SwishSpinner, WaveSpinner, ClapSpinner } from 'react-spinners-kit';
import isEmpty from '../../../utility/IsEmptyValidator';
import { Input } from '../UI/InputElement/InputElement';
import { approveQuoteValidator as validate } from '../../../utility/validator/validator';
import { connect, useSelector } from 'react-redux';
import ReactQuill, { Quill } from 'react-quill'; // ES6
import 'react-quill/dist/quill.snow.css'; // ES6
import { reduxForm } from 'redux-form';
import ModalImageGallery from '../ModalImageGallery/modalImageGallery';
import ImageLoader from 'react-imageloader';
var Link = Quill.import('formats/link');

let ApproveQuote = (props) => {
    const quote = useSelector((state) => state.adminOrSuperAdminReducer.quote);
    const [descriptionError, setDescriptionError] = useState('');
    const [editorHtmlToText, setEditorHtmlToText] = useState('');
    const [editorHtml, setEditorHtml] = useState(quote.description);
    const [sigantureImage, setSigantureImage] = useState('');
    const [theme, setTheme] = useState('snow');

    const handleChange = (html, recentAdded, b, editorFunctions) => {
        let text = editorFunctions.getText();
        recentAdded.ops.forEach((a, i) => {
            if (a.insert && a.insert.image) {
                setSigantureImage(a.insert.image);
            }
        });
        var builtInFunc = Link.sanitize;
        Link.sanitize = function customSanitizeLinkInput(linkValueInput) {
             var urlValue= linkValueInput;
             if (urlValue.indexOf("http://") == 0 || urlValue.indexOf("https://") == 0) {
                console.log(urlValue)
                return urlValue
            }
            else{
                
                urlValue = "https://" + urlValue;
                return urlValue
            }
    
        }
        setEditorHtml(html);
    };

    const handleThemeChange = (newTheme) => {
        if (newTheme === 'core') newTheme = null;
        setTheme(newTheme);
    };

    const EditorModules = {
        toolbar: [
            [{ header: '1' }, { header: '2' }, { font: [] }],
            [{ size: [] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
            ['link'],
            // ['link', 'image', 'video'],
            // ['clean']
        ],
        clipboard: {
            // toggle to add extra line breaks when pasting HTML:
            matchVisual: false,
        },
    };

    const EditorFormats = [
        'header',
        'font',
        'size',
        'bold',
        'italic',
        'underline',
        'strike',
        'blockquote',
        'list',
        'bullet',
        'indent',
        'link',
        'image',
        'video',
    ];

    const submitQuote = (values, htmlContent, base64) => {
        setDescriptionError('');
        values.description = htmlContent;
        props.submitQuote(values);
    };
    const base64Clicked = (i, urlLength) => {
        setIsImageGallery(true);
        setStartIndex(urlLength + i);
    };
    const photoUrlClicked = (i) => {
        setIsImageGallery(true);
        setStartIndex(i);
    };

    function preloader() {
        return <img style={{ width: '100%', height: '100%' }} src="/images/gif/giphy.gif" />;
    }

    const [isImageGallery, setIsImageGallery] = useState(false);
    const [startIndex, setStartIndex] = useState(0);
    const { handleSubmit, pristine, reset, submitting, error } = props;
    if (isImageGallery) {
        return (
            <ModalImageGallery
                isImageGallery={isImageGallery}
                setIsImageGallery={setIsImageGallery}
                startIndex={startIndex}
                base64={props.portfolioImages}
                imageUrls={props.savedPortfolioURls}
            />
        );
    }

    return (
        <main className="app-content wraper_content approve_screen approve_Quotes_sec">
            <div className="container">
                {props.isLoading || isEmpty(props.quote) ? (
                    <div className="loader_btn_block">
                        <ClapSpinner backColor="#89073c" size={40} frontColor="#89073c8a" />
                    </div>
                ) : (
                    <form
                        onSubmit={handleSubmit((values) => {
                            submitQuote(values, editorHtml, sigantureImage);
                        })}
                    >
                        <div className="row">
                            <div className="col-lg-10 offset-lg-1 col-xl-8 offset-xl-2 ">
                                <div className="inner_content">
                                    <div className="header_block">
                                        <img
                                            src="/images/icons/pegasus_gry_logo.png"
                                            alt="Pegasus"
                                            className="logo_img"
                                        />
                                        {/* <span className="contact_info"><img src="/images/icons/icn_contect.png" alt="Icon" />800.734.3878</span> */}
                                    </div>
                                    <div className="work_order_block">
                                        <h2 className="md_title mb-4">
                                            Work Order
                                            <p style={{ fontSize: 'small' }}>
                                                Created by:{' '}
                                                <span style={{ fontSize: 'small', fontWeight: '400' }}>
                                                    {props.quote && props.quote.requestor &&
                                                        props.quote.requestor.first_name + ' ' + props.quote &&
                                                        props.quote.requestor.last_name}
                                                </span>
                                            </p>
                                        </h2>
                                        <div className="media w_order_media">
                                            <div className="circle_img">
                                                <img
                                                    style={{ height: '70px', width: '70px', borderRadius: '50px' }}
                                                    className="mr-3"
                                                    src={
                                                        props.quote.requestor && props.quote.requestor.photo_urls &&
                                                        props.quote.requestor.photo_urls.original
                                                            ? props.quote.requestor.photo_urls.original
                                                            : '/customImages/user.png'
                                                    }
                                                    alt="User image"
                                                />
                                                <span className="cst_dot"></span>
                                            </div>
                                            <div className="media-body w-100">
                                                <h3 className="media_title">{props.quote.account && props.quote.account.name}</h3>
                                                {/* <p>Tag Opportunities <span className="text_gray">Yes</span></p> */}
                                                <p>
                                                    Total Sale
                                                    <span className="text_gray">
                                                        <Input
                                                            type="number"
                                                            min={1}
                                                            name="totalSale"
                                                            style={{ display: 'block' }}
                                                            className="edit-input"
                                                            id="total_sale"
                                                            size="5"
                                                            maxlength="5"
                                                        />
                                                        {/* <input type="number" name="totalSale" style={{ display: 'block' }} onChange={props.fieldChange} value={props.totalSale} className="edit-input" size="5" maxlength="5" /> */}
                                                        {/* ${props.quote.total_sale} */}
                                                    </span>
                                                </p>
                                                <p className="m-0 pt-2 pb-2">This is a quote for</p>
                                                {/* {quote.manual ?
                                                        <> */}
                                                <ReactQuill
                                                    style={{ height: '50%' }}
                                                    theme={theme}
                                                    onChange={handleChange}
                                                    // value={editorHtml}
                                                    defaultValue={editorHtml}
                                                    value={
                                                        editorHtml
                                                            ? editorHtml
                                                            : props.quote.description
                                                            ? props.quote.description
                                                            : ''
                                                    }
                                                    modules={EditorModules}
                                                    formats={EditorFormats}
                                                    bounds={'.app'}
                                                    placeholder="Detail work to be completed."
                                                />
                                                <span style={{ color: '#DD2726', fontSize: '13px' }}>
                                                    {descriptionError}
                                                </span>

                                                <div
                                                    className="file_attachment ticket_form"
                                                    style={{ paddingTop: '10px' }}
                                                >
                                                    <Input
                                                        type="file"
                                                        name="attachment_paths"
                                                        hidden={true}
                                                        multiple={true}
                                                        onSelect={props.onPortfolioFileChange}
                                                        fileRef={props.reference}
                                                        accept="image/*"
                                                    />
                                                    <a
                                                        href="javascript:void(0)"
                                                        onClick={props.choosePhotoClicked}
                                                        className="file_title"
                                                    >
                                                        <img src="/images/icons/attachment_icon.png" alt="Icon" />{' '}
                                                        Attachments
                                                    </a>
                                                    <p className="size_dec">Up to 10 atachments (10MB each).</p>

                                                    <div class="upload_file">
                                                        <div class="row">
                                                            {props.savedPortfolioURls &&
                                                                props.savedPortfolioURls.map((url, i) => {
                                                                    return (
                                                                        <div class="col-4 col-sm-4 cst_gutter" key={i}>
                                                                            <div
                                                                                class="file_view"
                                                                                onClick={() => photoUrlClicked(i)}
                                                                            >
                                                                                <ImageLoader
                                                                                    style={{
                                                                                        width: '100%',
                                                                                        height: '100%',
                                                                                        cursor: 'pointer',
                                                                                    }}
                                                                                    src={url.photo_urls.small}
                                                                                    wrapper={React.createFactory('div')}
                                                                                    preloader={preloader}
                                                                                >
                                                                                    Image load failed!
                                                                                </ImageLoader>
                                                                            </div>
                                                                            <div class="cancel_icon">
                                                                                <img
                                                                                    src="/images/icons/icn_delete_white.png"
                                                                                    onClick={() =>
                                                                                        props.removeUrls(url, i)
                                                                                    }
                                                                                    alt="Image"
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    );
                                                                })}
                                                            {props.portfolioImages &&
                                                                props.portfolioImages.map((url, i) => {
                                                                    return (
                                                                        <div class="col-4 col-sm-4 cst_gutter" key={i}>
                                                                            <div class="file_view">
                                                                                <img
                                                                                    src={url}
                                                                                    alt="Image"
                                                                                    onClick={() =>
                                                                                        base64Clicked(
                                                                                            i,
                                                                                            props.savedPortfolioURls
                                                                                                .length
                                                                                        )
                                                                                    }
                                                                                />
                                                                                {/* <div class="cancel_icon">
                                                                                        <img src="/images/icons/icn_delete_white.png" onClick={() => props.removeImage(url, i)} alt="Image" />
                                                                                    </div> */}
                                                                            </div>
                                                                        </div>
                                                                    );
                                                                })}
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* </>
                                                        :
                                                        <Input type="textarea" name="description" className="form-control input-modifier" rows="3" placeholder="Type here" id="comment" />
                                                    } */}
                                                {/* <textarea type="textarea" name="description" onChange={props.fieldChange} value={props.description} className="form-control input-modifier" rows="3" placeholder="Type here" id="comment" /> */}
                                                {/* <div className="checkbox_block radius_checkbox" style={{ marginTop: '15px' }}>
                                                        <div className="custom-control custom-checkbox">
                                                            <Input name="web_preference" type="checkbox" className="custom-control-input" id="customCheck1" checked />
                                                            <label className="custom-control-label" for="customCheck1">Send Approved Mail</label>
                                                        </div>
                                                    </div> */}
                                                <div className="btn_block text-center">
                                                    <button
                                                        disabled={props.isLoading}
                                                        type="submit"
                                                        className={
                                                            props.isLoading
                                                                ? 'btn cst_btn btn_danger mr-2 btn-wait'
                                                                : 'btn cst_btn btn_danger mr-2'
                                                        }
                                                    >
                                                        {props.isLoading ? '' : 'Confirm'}
                                                    </button>
                                                    {/* onClick={props.approveQuoteClicked} */}
                                                    <button
                                                        type="button"
                                                        onClick={props.onCancleClicked}
                                                        className="btn cst_btn btn-outline-secondary cancel_btn_work"
                                                    >
                                                        Cancel
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <hr />
                                        <p className="cst_para">
                                            {/* There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form passage of Lorem Ipsum. */}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                )}
            </div>
        </main>
    );
    // }
};

const mapStateToProps = (state) => {
    let totalSale = '';
    let description = '';
    if (!isEmpty(state.adminOrSuperAdminReducer.quote)) {
        totalSale = state.adminOrSuperAdminReducer.quote.total_sale.replace(/\,/g, "");
        description = state.adminOrSuperAdminReducer.quote.description;
    }

    return { initialValues: { totalSale, description } };
};

ApproveQuote = reduxForm({
    form: 'approveQuoteForm',
    validate,
    enableReinitialize: true,
})(ApproveQuote);

ApproveQuote = connect(mapStateToProps)(ApproveQuote);

export default ApproveQuote;
