import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../../../redux/actions/index';
import { reset } from 'redux-form';
import storage from '../../../../../utility/storage';
import { animateScroll as scroll } from 'react-scroll'
import AssetsList from '../../../../component/Inventory/Assets/AssetsList/assetsList';
import { routes } from '../../../../../utility/constants/constants';


class AssetsListContainer extends Component {

  constructor(props) {
    super(props);

    this.state = {
      currentAssosciatedUserEmail: '',
      accountErrorMessage: ''
    }
  }

  componentDidMount() {
    scroll.scrollToTop();
  }

  goClicked = (values) => {
    let id = ''
    let name = ''
    let status = ''
    const limit = this.props.assests_no_of_rows
    const page = 1;
    const type = 'asset'

    if (values.id) {
      values.id = values.id.trim()
      id = values.id
    }

    if (values.name) {
      values.name = values.name.trim()
      name = values.name
    }

    this.props.getAllAssests({ limit, page, id, name, type }, values);
  };


  componentWillUnmount() {
    //this.props.resetObjects();
  }

  componentDidUpdate(prevProps, prevState) {
    const user = storage.get('user');
    if (user) {
      if (prevProps.user.selected_mode !== user.selected_mode) {
        this.props.assetsPageClicked(0)
      }
    }
  }

  handleInventoryCloseDeleteModal = () => {
    this.props.handleInventoryCloseDeleteModal();
  }

  handleInventoryOpenDeleteModal = (id) => {
    this.props.handleInventoryOpenDeleteModal(id);
  }

  resetClicked = () => {
    const limit = this.props.assests_no_of_rows
    const page = 1;
    this.props.reset('searchAssetsListForm');
    this.props.assetsPageClicked(0)
    const type = 'asset'
    this.props.getAllAssests({ limit, page, type });
  }

  addAssetClicked = () => {
    this.props.history.push(routes.NEW_ASSET);
  }

  assetActiveStatusToggle = (e) => {
    this.props.getAssetActiveStatusToggle();
  }

  render() {

    return (
      <AssetsList
        {...this.props}
        resetClicked={this.resetClicked}
        onSubmit={this.goClicked}
        addAssetClicked={this.addAssetClicked}
        handleInventoryOpenDeleteModal={this.handleInventoryOpenDeleteModal}
        handleInventoryCloseDeleteModal={this.handleInventoryCloseDeleteModal}
        assetActiveStatusToggle={this.assetActiveStatusToggle}
      />
    )
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.authReducer.user,
    isLoading: state.InventoryReducer.isLoading,
    assestsList: state.InventoryReducer.assestsList,
    assests_no_of_rows: state.InventoryReducer.assests_no_of_rows,
    openInventoryDeleteModal: state.InventoryReducer.openInventoryDeleteModal,
    deleteInventoryObject: state.InventoryReducer.deleteInventoryObject,
    activeAssets: state.InventoryReducer.activeAssets,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    reset: (searchAssetsListForm) => dispatch(reset(searchAssetsListForm)),
    getAllAssests: (params, values) => dispatch(actions.getAllAssests(params, values)),
    assetsPageClicked: (page) => dispatch(actions.assetsPageClicked(page)),
    deleteAsset: (id) => dispatch(actions.deleteAsset(id)),
    handleInventoryOpenDeleteModal: (box) => dispatch(actions.handleInventoryOpenDeleteModal(box)),
    handleInventoryCloseDeleteModal: () => dispatch(actions.handleInventoryCloseDeleteModal()),
    getAssetActiveStatusToggle: () => dispatch(actions.getAssetActiveStatusToggle()),
    assetStockClicked: (asset) => dispatch(actions.assetStockClicked(asset)),
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(AssetsListContainer);