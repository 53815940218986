import React, { useEffect } from 'react'
import NewJobCardsComponent from '../../../../../component/FormAuditModule/BuildingProfileComponent/JobCards/NewJobCards/NewJobCardsComponent'
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { useDispatch, useSelector } from 'react-redux'
import { getAllJob } from '../../../../../../redux/actions/FormAuditModAction/TeamManagementAction/teamManagementAction'
import { addJobCardDetails, editJobCardDetails, getSingleJobCardDetails } from '../../../../../../redux/actions/FormAuditModAction/BuildingProfileAction/BuildingProfileAction'
import moment from 'moment'
import { convertDateTimezoneFormAudit, convertTimeStringFormAudit, convertTimeStringFormAuditJobCard, convertTimeStringToUTCFormAudit, ConvertToFormat, tzUTCDateConverterTool_FormAudit } from '../../../../../../utility/TimezoneOperations/timezoneUtility'

function NewJobCardsContainer() {
  const { jobCardDetails } = useSelector((state) => state.BuildingProfileReducer)
  const { location: { pathname } } = useHistory()
  const { id } = useParams()
  const isEdit = Boolean(pathname.split("/")[2] !== "view" && id)
  const isView = Boolean(pathname.split("/")[2].includes("view"))
  const dispatch = useDispatch()

  const convertDate = (date) => {
    if (date) {
      
      var parts = date.split('T');
      var datePart = parts[0];
      let dt = moment(datePart)._d
      return dt
    }
  }


  const structureTask = (task,type) => {
    if(type=="Utility") return {}
    return {
      detail_time: task.detail_time.split(" ")[0],
      shift_length: task.shift_length.split(" ")[0],
      friday_project_time: task.friday_project_time.split(" ")[0],
      routine_time_3c: +task.routine_time_3c,
      routine_time_4c: +task.routine_time_4c
    }
  }

  const MomentStringToHour=(str,format="HH:mm:ss")=>{
    let time=convertTimeStringFormAudit(str,format)
    return time
  }
//convertTimeStringFormAuditJobCard

// const MomentStringToHourJobCards=(str,format="HH:mm:ss")=>{
//   let time=convertTimeStringToUTCFormAudit(str,format)
//   return time
// } 
  const structureSection = (section) => {

    let data = section.map((item) => {
     item.lineItems = item.lineItems?.sort((a,b)=> a?.sort - b?.sort)
      return {
        table_header: item.table_header,
        sort: item.sort,
        active_status: item.active_status,
        id: item.id,
        job_core_maps_cards_id: item.job_core_maps_cards_id,
        lineItems: item.lineItems.map((item) => {
          delete item.created_at
          delete item.updated_at
          delete item.active_status
          return {
            ...item, notes: item.notes, time: MomentStringToHour(item.time)
          }
        })
      }
    })

    //here we need to add Sorting 
    let sortedData= data.sort((a,b)=> a.sort - b.sort )
    return sortedData;
  }

  // const convertDateM = (date) => {

  //   if (date) {


  //     // Set the time to midnight (00:00:00)
  //     var parts = date.split('T');
  //     var datePart = parts[0];
  //     var timePart = "00:00:00.000Z"; // Set the time to midnight

  //     // Join the modified parts back together
  //     var modifiedDateString = datePart + 'T' + timePart;
  //     let myDate= new Date(modifiedDateString)
  //     let offset= myDate.getTimezoneOffset()
  //      myDate.setMinutes(offset)
  //     let dt = moment.utc(myDate)._d
  //     return dt
  //     // Output the modified date string

  //   }
  // }

  
  const defaultForm = {
    type: isView || isEdit ? jobCardDetails?.type ? jobCardDetails.type : "" : "",
    version_date: isView || isEdit ? jobCardDetails?.version_date ? convertDateTimezoneFormAudit(jobCardDetails.version_date): null : null,
    by: isView || isEdit ? jobCardDetails?.by ? jobCardDetails.by : "" : "",
    account_id: isView || isEdit ? jobCardDetails?.account_id ? jobCardDetails.account_id : "" : "",
    location: isView || isEdit ? jobCardDetails?.location ? jobCardDetails.location : "" : "",
    team: isView || isEdit ? jobCardDetails?.team ? jobCardDetails.team : "" : "",
    shift_hours: isView || isEdit ? jobCardDetails?.shift_hours : "",
    start_time: isView || isEdit ? jobCardDetails?.start_time ? MomentStringToHour(jobCardDetails.start_time) : "" : "",
    finish_time: isView || isEdit ? jobCardDetails?.finish_time ? MomentStringToHour(jobCardDetails.finish_time) : "" : "",
    tasks: isView || isEdit ? jobCardDetails?.tasks ? structureTask(jobCardDetails.tasks,jobCardDetails.type) : {
      //shift_length: "",
      detail_time: "",
      friday_project_time: "",
      routine_time_3c: "",
      routine_time_4c: ""
    } : {
      shift_length: "",
      detail_time: "",
      friday_project_time: "",
      routine_time_3c: "",
      routine_time_4c: ""
    },
    coreMaps: isView || isEdit ? jobCardDetails?.coreMaps ? jobCardDetails.coreMaps : {
      core_map_1: "",
      core_map_2: "",
      core_map_3: "",
      core_map_4: "",
      notes: {
        notes_1: "",
        notes_2: "",
        notes_3: "",
        notes_4: ""
      }
    } : {
      core_map_1: "",
      core_map_2: "",
      core_map_3: "",
      core_map_4: "",
      notes: {
        notes_1: "",
        notes_2: "",
        notes_3: "",
        notes_4: ""
      }
    },
    sections: isView || isEdit ? jobCardDetails?.sections ? structureSection(jobCardDetails.sections) : [{sort:1},{sort:2},{sort:3},{sort:4},{sort:5}] : [{sort:1},{sort:2},{sort:3},{sort:4},{sort:5}]
  }

  

  const MomentFormatToHour = (date, format = "HH:mm:ss") => {
    date=date.format(format)
    return convertTimeStringToUTCFormAudit(date,format)
  }

  const structureBody = (data) => {
    const { sections, tasks, version_date,...remainData } = data
    
    let new_VersionDate=tzUTCDateConverterTool_FormAudit(ConvertToFormat(version_date),"to")
    
    const newTask = tasks
    let body = null
    if (isEdit) {

      delete remainData.coreMaps.created_at
      delete remainData.coreMaps.updated_at
      delete remainData.coreMaps.notes.created_at
      delete remainData.coreMaps.notes.updated_at
      
      if(data.type !== "Utility"){
        newTask.id = jobCardDetails.tasks.id
        newTask.job_core_maps_cards_id = jobCardDetails.tasks.job_core_maps_cards_id
      newTask.active_status = "active"
      }
      
      let convertedSection = []
     Object.values(sections).forEach((item, index) => {
      
        convertedSection[index] = {
          sort: item.sort ? item.sort : index + 1,
          ...item,
          lineItems: item.lineItems.map((line, index) => {
            return {
              sort: line.sort ? line.sort : index + 1,
              ...line,
              time: MomentFormatToHour(line.time),
              active_status: "active"
            }
          })
        }
      })

      if(data.type !=="Utility"){
        
        body = { id: jobCardDetails.id, user_id: jobCardDetails.user_id,version_date:new_VersionDate, ...remainData, start_time: MomentFormatToHour(remainData.start_time), finish_time: MomentFormatToHour(remainData.finish_time), active_status: "active", tasks, sections: convertedSection }
      }else{
        
        body = { id: jobCardDetails.id, user_id: jobCardDetails.user_id,version_date:new_VersionDate, ...remainData, start_time: MomentFormatToHour(remainData.start_time), finish_time: MomentFormatToHour(remainData.finish_time), active_status: "active", sections: convertedSection }
      }


    } else {
       
      Object.keys(tasks).map((item) => {
        if(item =="routine_time_3c" || item =="routine_time_4c"){
          newTask[item] = +data.tasks[item] 
        }else{
          newTask[item] = data.tasks[item] + " " + "hours"
        }
      })
      let convertedSection = undefined
      if (!data.sections) {
        convertedSection = []
      } else {

        
        
        convertedSection = Object.values(data.sections).map((item, index) => {

          const { core_1, core_2, core_3, core_4, notes, lineItems, ...remainSection } = item

          return {
            ...remainSection,
            sort: item.sort,
            lineItems: lineItems.map((line, index) => {
              return {
                ...line,
                time: MomentFormatToHour(line.time),
                sort: index + 1
              }
            })
          }
        })
      }
      body = { ...remainData, tasks: newTask, start_time: MomentFormatToHour(remainData.start_time), finish_time: MomentFormatToHour(remainData.finish_time), sections: convertedSection,version_date:new_VersionDate }
    }
    return { data: [body] };
  }

  const onSubmit = (data) => {

    if (isEdit) {
      
      //dispatch(editTeamSupervisorSummary(structureBody(data)))
      dispatch(editJobCardDetails(structureBody(data)))

    } else {

      dispatch(addJobCardDetails(structureBody(data)))
    }


  }

  useEffect(() => {
    // dispatch(getCustomerInfos())
    dispatch(getAllJob())
    // dispatch(getManagersList({supervisor : true}))
    //  dispatch(getEmployeeList({employee:true}))
  }, [])

  useEffect(() => {
    if (isEdit || (isView && id)) {

      dispatch(getSingleJobCardDetails(id));
    }
  }, [id]);



  return (
    <NewJobCardsComponent
      elements={defaultForm}
      formSubmittedCallback={onSubmit}
      isEdit={isEdit}
      isView={isView}
      isUpdate={defaultForm.type ? true : false}
       />
  )
}

export default NewJobCardsContainer
