import * as API from '../../../API/TimeTrackerModApi';
import history from '../../../router/history';
import { routes } from '../../../utility/constants/constants';
import SuccessAlert from '../../../utility/successAlert/successAlert';
import { handleDeleteClose } from '../AdminOrSuperAdminActions/AdminOrSuperAdminActions';
import { JoBAssociateTypes } from './JobAssociationTypes';




export const getJobAssociateList = (params,value) => dispatch => dispatch({
    type: JoBAssociateTypes.GET_JOBASSOCIATES_LIST,
    payload: API.getJobAssociateList(params,value)
        .then(response => {
            return {
                tickets: response.data.data,
                ticket_page: response.data.totalPages, 
                ticket_no_of_rows: response.data.limit,
                totalTickets: response.data.total,
                ticketCurPage: params.page - 1,
                searchTicketValues: value
            };
        })
        .catch(error => {
            console.log(error);
        })
})


export const createAssociatedJobs = (body) => { 
    return (dispatch, getState) => {
        dispatch({
            type: JoBAssociateTypes.CREATE_JOBASSOCIATES_LIST,
            payload: API.createAssociatedJobs(body)
                .then((response) => {
                    if (response.data.success) {
                        SuccessAlert('Job Association created successfully')
                        history.push(routes.JOBASSOCIATION_TABLE);
                        return response;
                    };
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};

export const ticketPageClickedJob = (data) => {
    return {
        type: JoBAssociateTypes.JOB_ASSOCIATION_TASK_LIST_PAGE_CLICKED,
        payload: data,
    };
};


export const getSingleJobAssociation = (id) => dispatch => dispatch({
    type: JoBAssociateTypes.GET_SINGLE_JOBASSOCIATES,
    payload: API.getSingleJobAssociation(id)
        .then(response => {
            return {
                singleJobAssociation: response.data.data,
            };
        })
        .catch(error => {
            console.log(error);
        })
})

export const getSharableJobs = () => {
    return {
        type: JoBAssociateTypes.GET_JOBS_OF_ASSOCIATION,
        payload: API.getSharableJobs()
            .then((response) => {
                if (response.data.success) {
                    return {
                        accounts: response.data.accounts,
                    };
                }
                // SuccessAlert("Your mode is successfully set to " + mode);
            })
            .catch((error) => {
                console.log(error);
            })
    }
}


// export const getOneSharableJobs = (id) => {
//     return {
//         type: JoBAssociateTypes.GET_ONE_JOBS_OF_ASSOCIATION,
//         payload: API.getOneSharableJobs(id)
//             .then((response) => {
//                 if (response.data.success) {
//                     return {
//                         data: response.data.data,
//                     };
//                 }
//                 // SuccessAlert("Your mode is successfully set to " + mode);
//             })
//             .catch((error) => {
//                 console.log(error);
//             })
//     }
// }

export const updateAssociatedJobs = (body, id) => { 
    return (dispatch, getState) => {
        dispatch({
            type: JoBAssociateTypes.UPDATE_JOBASSOCIATES_LIST,
            payload: API.updateAssociatedJobs(body, id)
                .then((response) => {
                    if (response.data.success) {
                        SuccessAlert('Job Association Updated successfully')
                        history.push(routes.JOBASSOCIATION_TABLE);
                        return response;
                    };
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};

export const deleteAssociatedJobs = (id) => { 
    return (dispatch, getState) => {
        dispatch({
            type: JoBAssociateTypes.DELETE_JOBASSOCIATES_LIST,
            payload: API.deleteAssociatedJobs(id)
                .then((response) => {
                    if (response.data.success) {
                        dispatch(handleDeleteClose())
                        SuccessAlert('Job Association Deleted successfully')
                        history.push(routes.JOBASSOCIATION_TABLE);
                        return {
                            id
                        }
                    };
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};