import React from 'react';
import store from '../../../../redux/store/store';
// import { Status } from '../../../../utility/constants/constants';
import { AdminOrSuperAdminActionTypes } from '../AdminOrSuperAdminActionType';
var moment = require('moment');

export const searchAccounts = (accountName, accountNumber) => {
    const AccountList = store.getState().adminOrSuperAdminReducer.accountsList;
    let SearchedAccountList = null;
    if ((AccountList && accountName) || (AccountList && accountNumber)) {
        SearchedAccountList = AccountList.filter(account => (
            (account.user.name && account.user.name.trim().toLowerCase().indexOf(accountName.trim().toLowerCase()) > -1) &&
            // ||
            //     account.user.account_number && account.user.account_number.trim().toLowerCase().indexOf(accountName.trim().toLowerCase()) > -1) &&
            account.user.account_number && account.user.account_number.trim().toLowerCase().indexOf(accountNumber.trim().toLowerCase()) > -1
        ));

    }
    return {
        type: AdminOrSuperAdminActionTypes.SEARCH_ACCOUNTS,
        payload: SearchedAccountList
    }
}

export const searchUsers = (creatersName, email) => {
    const UsersList = store.getState().adminOrSuperAdminReducer.usersList;

    let SearchedUsersList = null;
    if ((UsersList && creatersName) || (UsersList && email)) {
        SearchedUsersList = UsersList.filter(user => (
            (user.user.first_name && user.user.last_name && user.user.first_name.trim().concat(" " + user.user.last_name).toLowerCase().indexOf(creatersName.trim().toLowerCase()) > -1 ||
                user.user.last_name && user.user.first_name && user.user.last_name.trim().concat(" " + user.user.first_name).toLowerCase().indexOf(creatersName.trim().toLowerCase()) > -1) &&
            user.user.email && user.user.email.trim().toLowerCase().indexOf(email.trim().toLowerCase()) > -1
        ));

    }
    return {
        type: AdminOrSuperAdminActionTypes.SEARCH_USERS,
        payload: SearchedUsersList
    }
}

export const searchQuotes = (searchAccountsList, searchRegion, searchStatus, fromDate, toDate) => {

    const QuotesList = store.getState().adminOrSuperAdminReducer.searchedQuoteList ? store.getState().adminOrSuperAdminReducer.searchedQuoteList : store.getState().adminOrSuperAdminReducer.filteredQuotesList ? store.getState().adminOrSuperAdminReducer.filteredQuotesList : store.getState().adminOrSuperAdminReducer.quotesList;
    let SearchedQuotesList = null;
    if ((QuotesList && searchAccountsList) || (QuotesList && searchStatus) || (QuotesList && searchRegion) || (QuotesList && fromDate) || (QuotesList && toDate)) {
        let FromDate = fromDate ? moment(fromDate).startOf('day') : null;
        let ToDate = toDate ? moment(toDate).startOf('day') : null;

        if (searchStatus === 'all' || searchStatus === '') {

            SearchedQuotesList = QuotesList.filter(quote => {
                const Created_AT = moment(quote.created_at).startOf('day');
                if (!quote.region) {
                    quote['region'] = {};
                }
                if (searchRegion !== '') {
                    return (
                        (quote.account.name && quote.account.name.trim().toLowerCase().indexOf(searchAccountsList.trim().toLowerCase()) > -1 ||
                            quote.account.account_number && quote.account.account_number.trim().toLowerCase().indexOf(searchAccountsList.trim().toLowerCase()) > -1) &&
                        quote.region.name && quote.region.name.trim().toLowerCase().indexOf(searchRegion.trim().toLowerCase()) > -1 &&
                        ((FromDate && ToDate) ? (Created_AT._d >= FromDate._d && Created_AT._d <= ToDate._d) :
                            FromDate ? (Created_AT._d >= FromDate._d) :
                                ToDate ? (Created_AT._d <= ToDate._d) : true)

                    )
                } else {
                    return (
                        (quote.account.name && quote.account.name.trim().toLowerCase().indexOf(searchAccountsList.trim().toLowerCase()) > -1 ||
                            quote.account.account_number && quote.account.account_number.trim().toLowerCase().indexOf(searchAccountsList.trim().toLowerCase()) > -1) &&
                        // quote.region.name && quote.region.name.trim().toLowerCase().indexOf(searchRegion.trim().toLowerCase()) > -1 &&
                        ((FromDate && ToDate) ? (Created_AT._d >= FromDate._d && Created_AT._d <= ToDate._d) :
                            FromDate ? (Created_AT._d >= FromDate._d) :
                                ToDate ? (Created_AT._d <= ToDate._d) : true)

                    )
                }

            })
        } else {
            SearchedQuotesList = QuotesList.filter(quote => {
                const Created_AT = moment(quote.created_at).startOf('day');
                if (!quote.region) {
                    quote['region'] = {};
                }
                if (searchRegion !== '') {
                    return (
                        (quote.account.name && quote.account.name.trim().toLowerCase().indexOf(searchAccountsList.trim().toLowerCase()) > -1 ||
                            quote.account.account_number && quote.account.account_number.trim().toLowerCase().indexOf(searchAccountsList.trim().toLowerCase()) > -1) &&
                        quote.region.name && quote.region.name.trim().toLowerCase().indexOf(searchRegion.trim().toLowerCase()) > -1 &&
                        quote.tag_opportunity && quote.status && quote.status.trim().toLowerCase().indexOf(searchStatus.trim().toLowerCase()) > -1 &&
                        ((FromDate && ToDate) ? (Created_AT._d >= FromDate._d && Created_AT._d <= ToDate._d) :
                            FromDate ? (Created_AT._d >= FromDate._d) :
                                ToDate ? (Created_AT._d <= ToDate._d) : true)

                    )

                } else {

                    return (
                        (quote.account.name && quote.account.name.trim().toLowerCase().indexOf(searchAccountsList.trim().toLowerCase()) > -1 ||
                            quote.account.account_number && quote.account.account_number.trim().toLowerCase().indexOf(searchAccountsList.trim().toLowerCase()) > -1) &&
                        // quote.region.name && quote.region.name.trim().toLowerCase().indexOf(searchRegion.trim().toLowerCase()) > -1 &&
                        quote.tag_opportunity && quote.status && quote.status.trim().toLowerCase().indexOf(searchStatus.trim().toLowerCase()) > -1 &&
                        ((FromDate && ToDate) ? (Created_AT._d >= FromDate._d && Created_AT._d <= ToDate._d) :
                            FromDate ? (Created_AT._d >= FromDate._d) :
                                ToDate ? (Created_AT._d <= ToDate._d) : true)

                    )

                }
            })
        }

    }
    return {
        type: AdminOrSuperAdminActionTypes.SEARCH_QUOTES,
        payload: SearchedQuotesList
    }
}

export const searchCompany = (companyName) => {
    const CompaniesList = store.getState().adminOrSuperAdminReducer.companiesList;

    let SearchedCompanyList = null;
    if ((CompaniesList && companyName)) {
        SearchedCompanyList = CompaniesList.filter(company => (
            (company.company.name && company.company.name.trim().toLowerCase().indexOf(companyName.trim().toLowerCase())) > -1
        ));
    }

    return {
        type: AdminOrSuperAdminActionTypes.SEARCH_COMPANY,
        payload: SearchedCompanyList
    }
}

export const searchCompanyUsers = (email) => {
    const CompanyUsersList = store.getState().adminOrSuperAdminReducer.companyUsersList;

    let SearchedCompanyUsersList = null;
    if ((CompanyUsersList && email)) {
        SearchedCompanyUsersList = CompanyUsersList.filter(user => (
            (user.email && user.email.trim().toLowerCase().indexOf(email.trim().toLowerCase())) > -1
        ));
    }

    return {
        type: AdminOrSuperAdminActionTypes.SEARCH_COMPANY_USERS,
        payload: SearchedCompanyUsersList
    }
}

export const searchAccountUsers = (email) => {
    const AccountUsersList = store.getState().adminOrSuperAdminReducer.accountUsersList;

    let SearchedAccountUsersList = null;
    if ((AccountUsersList && email)) {
        SearchedAccountUsersList = AccountUsersList.filter(user => (
            (user.email && user.email.trim().toLowerCase().indexOf(email.trim().toLowerCase())) > -1
        ));
    }

    return {
        type: AdminOrSuperAdminActionTypes.SEARCH_ACCOUNT_USERS,
        payload: SearchedAccountUsersList
    }
}

export const searchInspectionForm = (inspectionFormName) => {

    const InspectionFormsList = store.getState().adminOrSuperAdminReducer.inspectionFormTableList;

    let SearchedInspectionFormsList = null;
    if ((InspectionFormsList && inspectionFormName)) {
        SearchedInspectionFormsList = InspectionFormsList.filter(i => (
            (i.inspection_form.name && i.inspection_form.name.trim().toLowerCase().indexOf(inspectionFormName.trim().toLowerCase())) > -1
        ));
    }

    return {
        type: AdminOrSuperAdminActionTypes.SEARCH_INSPECTION_FORM,
        payload: SearchedInspectionFormsList
    }
}

export const ratingSearchValidator = (RatingName) => {
    const RatingList = store.getState().adminOrSuperAdminReducer.ratingList;
    const totalRatings = store.getState().adminOrSuperAdminReducer.totalRatings;
    const rating_no_of_rows = store.getState().adminOrSuperAdminReducer.rating_no_of_rows;
    const ratingCurPage = store.getState().adminOrSuperAdminReducer.ratingCurPage;

    let SearchedRatingList = null;
    if ((RatingList && RatingName)) {
        SearchedRatingList = RatingList.filter(r => (
            (r.name && r.name.trim().toLowerCase().indexOf(RatingName.trim().toLowerCase())) > -1
        ));
    } else {
        SearchedRatingList = RatingList;
    }

    return {
        type: AdminOrSuperAdminActionTypes.SEARCH_RATING,
        payload: {SearchedRatingList: SearchedRatingList, totalRatings: totalRatings, searchRatingValues: RatingName, curPage: ratingCurPage, rows: rating_no_of_rows}
    }
}

export const searchInspection = (Form) => {

    const InspectionsList = store.getState().adminOrSuperAdminReducer.inspectionsList;

    let SearchedInspectionsList = null;
    if ((InspectionsList && Form)) {
        SearchedInspectionsList = InspectionsList.filter(i => (
            (i.inspection_form.name && i.inspection_form.name.trim().toLowerCase().indexOf(Form.trim().toLowerCase())) > -1
        ));
    }

    return {
        type: AdminOrSuperAdminActionTypes.SEARCH_INSPECTION,
        payload: SearchedInspectionsList
    }
}

export const searchTickets = (Subject) => {
    const TicketsList = store.getState().adminOrSuperAdminReducer.ticketsList;

    let SearchedTicketsList = null;
    if ((TicketsList && Subject)) {
        SearchedTicketsList = TicketsList.filter(t => (
            (t.subject === '' ? -1 : t.subject && t.subject.trim().toLowerCase().indexOf(Subject.trim().toLowerCase())) > -1
        ));
    }

    return {
        type: AdminOrSuperAdminActionTypes.SEARCH_TICKET,
        payload: SearchedTicketsList
    }
}

// export const searchCategory = (Name) => {
//     const CategoriesList = store.getState().adminOrSuperAdminReducer.categoriesList;

//     let SearchedCategoriesList = null;
//     if ((CategoriesList && Name)) {
//         SearchedCategoriesList = CategoriesList.filter(c => (
//             (c.category.name === '' ? -1 : c.category.name && c.category.name.trim().toLowerCase().indexOf(Name.trim().toLowerCase())) > -1
//         ));
//     }

//     return {
//         type: AdminOrSuperAdminActionTypes.SEARCH_CATEGORY,
//         payload: SearchedCategoriesList
//     }
// }