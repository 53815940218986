import React, {useEffect} from 'react';
// import './style/withForm.style.scss';
import { useForm, Controller} from 'react-hook-form';
// import {Button, Form} from 'react-bootstrap';
import {useHistory} from 'react-router-dom'


const withFormHocCostSummary = (WrappedComponent) => {
  
  return (props) => {
     
    let elemntValues={}
    if(props.elements){
        elemntValues=props.elements
    }
    
    const {register, handleSubmit, formState: {errors,isDirty,defaultValues,isValid,}, control,reset,getValues,setValue,setFocus,setError} = useForm({defaultValues: useEffect(() => {
      // when the file is Valid we dont need to set the values to form 
        
        if(!isDirty  || Boolean(props.modalUpdate)){
          
          reset(props?.elements)
        }
  }, [props.elements]),

 shouldFocusError:false
});

    const history=useHistory()
     
    const onSubmit =  (data) => {
        props.formSubmittedCallback(data)
    };

    const resetForm=()=>{
      reset()
      history.goBack()
      
    }
    

    return (
      
        <WrappedComponent isValid={isValid} setError={setError} isView={props?.isView} isEdit={props?.isEdit} register={register} setFocus={setFocus} errors={errors} controller={Controller} control={control} handleSubmit={handleSubmit} onSubmit={onSubmit} resetForm={resetForm} elements={props.elements}  getValues={getValues} setValue={setValue} reset={reset} total={props?.total} isDirty={isDirty} defaultValues={defaultValues} {...props} />
       
      
    );
  };
};

export default withFormHocCostSummary;



