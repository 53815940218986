import React from 'react';
import '../../../../styles/custom.css';
import { ClapSpinner } from 'react-spinners-kit';
import AddStandardMatrix from './AddStandardMatrix/AddStandardMatrixContainer';
import 'react-table-v6/react-table.css';
import { useDispatch, useSelector } from 'react-redux';
import { updateStandardMatrixFulfilled } from '../../../../redux/actions/BrmActions/BrmAction';
import AddCustomMatrixContainer from './CustomMatrix/CustomMatrixContainer';

const StandardMatrixTableList = (props) => {
    const dispatch = useDispatch();
    const getUpdatedMatrixList = useSelector((state) => state.BRM.updateMatrixList);
    const CheckTierFunction = (matrix) => {
    
        if (matrix == 1) {
            return "Tier A Customer";
        }
        else if (matrix == 2) {
            return "Tier B Customer";
        }
        else if(matrix == 3){
            return "Tier C Customer";
        }else{
            return '-'
        }
    }

    const editStandardMatrix = (list) => {
        dispatch(updateStandardMatrixFulfilled(list));
    }

    const handleChangeName = (name) => {
        if(name === "president_visit_call")
        {
            return "President Visit/Call";
        }
        else if(name === "vice_president_visit")
        {
            return "Vice President Visit";  
        }
        else if(name === "business_review_report")
        {
            return "Business Review Report";
        }
        else if(name === "business_review_meeting")
        {
            return "Business Review Meeting";
        }
        else if( name === "quality_control_visits"){
            return "Quality Control Visits";
        }else{
            return null;
        }
    }

    const handleChangefrequency = (name) => {
        if(name === "weekly")
            {
                return "Weekly";
            }
            else if(name === "monthly")
            {
                return "Monthly";
            }
            else if(name === "semi_annual")
            {
                return "Semi Annual";
            }
            else if(name === "bi_monthly")
            {
                return "2 X Month";
            }
            else if(name === "annual")
            {
                return "Annual";
            }
            else if(name === "none"){
                return "N/A";
            }
            else if(name === "quarterly")
            {
               return "Quartely";
            }
            else{
                return null;
            }
    }

    return (
        <>
            {!(props.isMatrixLoading) ? (
                <div className="quotes_table_content table-responsive performance_modal add_account associateUser">
                    <div className="table quotes_table user_table_contnet user_react_table">
                        <div className="col-lg-12">
                            <div class="quote_form user_quota_form ticket_form">
                                <div class="addQuote_form">
                                    <div class="form-group">
                                        <div class="form-group checkbox_cst_grid">
                                            {props?.matrixList && props.matrixList?.length != 0 ? props.matrixList && props.matrixList.map((listData, index) => {
                                                return (
                                                    <React.Fragment key={index + 1}>
                                                        {
                                                            listData.type == 'default' ? (
                                                                <div class="checkbox_block radius_checkbox category_text_overflow">
                                                                    <div class="custom-checkbox gridStyle">
                                                                        <div class="gridInlineBlc">
                                                                            <label class="f12 grdTitle">{listData?.name ? handleChangeName(listData?.name) : "-"}</label>
                                                                            <label class="f12 grdTitle">{CheckTierFunction(listData?.matrix)}</label>
                                                                            <label class="f12 grdTitle">{listData?.frequency ? handleChangefrequency(listData?.frequency) : '-'}</label>
                                                                            <div class="icnBlc">
                                                                                <span className="mr_20 account_edit_icn" data-toggle="modal" data-target="#addstandardMatrix" data-backdrop="static" data-keyboard="false" onClick={() => { editStandardMatrix(listData) }}><i class="fa fa-pencil" aria-hidden="true"></i></span>
                                                                                <span className="mr_20 account_edit_icn" onClick={() => { props.Open_delete_popup(listData.id) }}><i class="fa fa-trash" aria-hidden="true"></i></span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ) : (
                                                                <div class="checkbox_block radius_checkbox category_text_overflow">
                                                                    <div class="custom-checkbox gridStyle">
                                                                        <div class="gridInlineBlc">
                                                                            <label class="f12 grdTitle">{listData?.name ? handleChangeName(listData?.name) : "-"}</label>
                                                                            <label class="f12 grdTitle">{CheckTierFunction(listData?.matrix)} - {listData?.companyMatrix?.company?.[0]?.name} - {listData?.companyMatrix?.account?.name}</label>
                                                                            <label class="f12 grdTitle">{listData?.frequency ? handleChangefrequency(listData?.frequency) : '-'}</label>
                                                                            <div class="icnBlc">
                                                                                <span className="mr_20 account_edit_icn" data-toggle="modal" data-target="#addCustomMatrix" data-backdrop="static" data-keyboard="false" onClick={() => { editStandardMatrix(listData) }}><i class="fa fa-pencil" aria-hidden="true"></i></span>
                                                                                <span className="mr_20 account_edit_icn" onClick={() => { props.Open_delete_popup(listData.id) }}><i class="fa fa-trash" aria-hidden="true"></i></span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        }
                                                    </React.Fragment>
                                                );
                                            }) : null}
                                        </div>
                                        {!props?.matrixList?.length && (
                                        <div  style={{width:'100%', display:'flex', flexDirection:'row', justifyContent:'center'}}>
                                            <p>No Data Found</p>
                                        </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        getUpdatedMatrixList?.type == "default" && <AddStandardMatrix isEdit={true} resetSelectedMatrix={props.resetSelectedMatrix} />
                    }
                    {
                        getUpdatedMatrixList?.type=="custom" && <AddCustomMatrixContainer isEdit={true} resetSelectedMatrix={props.resetSelectedMatrix}   companiesList={props.companiesList} />
                    }
                </div>
            ) : (
                <div className="loader_btn_block spinner">
                    <ClapSpinner backColor="#89073c" size={40} frontColor="#89073c8a" />
                </div>
            )}
        </>
    );
};

export default StandardMatrixTableList;