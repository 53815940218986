import React, { Component } from 'react';
import * as actions from '../../../../../../redux/actions/index';
import { connect } from 'react-redux';
import { animateScroll as scroll} from 'react-scroll'
import ListedItemsComingBackForm from '../../../../../component/Inventory/InventoryTicketsComponents/PickupsTicketComponents/ListedItemsComingBackFormComponent/ListedItemsComingBackForm';
const cloneDeep = require('clone-deep');

class ListedItemsComingBackFormContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isFormLoading: false,
            isButtonLoading: false,
            deliveryItems: null,
            arrayBuffer: null,
            extensions: null,
        }
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        this.setState({isFormLoading: true})
        setTimeout(() => {
          this.setState({isFormLoading: false})
        }, 500);
        this.props.getInventoryItemNames();
        if(this.props && this.props.history && this.props.history.location) {
            if(this.props.history.location.state && this.props.history.location.state.storedData) {
                this.setState({
                    deliveryItems: this.props.history.location.state.storedData.deliveryItems,
                    arrayBuffer: this.props.history.location.state.storedData.arrayBuffer,
                    extensions: this.props.history.location.state.storedData.extensions
                })
            }
        }
        scroll.scrollToTop();
    }

    componentDidUpdate(prevProps, prevState) {
    }

    handleSubmit = (values) => {
        this.setState({isButtonLoading: true})
        // deleting triggerPoint property used for triggering items and quantity field
        values.delivery.items.forEach((e) => {return delete e.triggerPoint})

        let listedDeliveryItems = cloneDeep(values);
        const filteredNewItems = listedDeliveryItems.delivery.items.map((e) => {return {id: e.id.value, quantity: e.quantity}})
        listedDeliveryItems.delivery.items = filteredNewItems;

        let receivedDeliveryItems = cloneDeep(this.state.deliveryItems);
        const arrayBuffer = cloneDeep(this.state.arrayBuffer);
        const extensions = cloneDeep(this.state.extensions);

        let deliveryItems = receivedDeliveryItems;
        deliveryItems.ticket.delivery = listedDeliveryItems.delivery;

        this.props.getDeliveryItemScanPresignedUrl(deliveryItems, arrayBuffer, extensions);
    }

    render() {
        return (
            <ListedItemsComingBackForm
                {...this.props}
                onSubmit={this.handleSubmit}
                isFormLoading={this.state.isFormLoading}
                isButtonLoading={this.state.isButtonLoading}
            />
        );
    }
}

const mapStateToProps = (state) => {
    return {
        inventoryItemNamesList: state.InventoryReducer.inventoryItemNamesList,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getInventoryItemNames: (data) => dispatch(actions.getInventoryItemNames(data)),
        getDeliveryItemScanPresignedUrl: (items, arrayBuffer, extension) => dispatch(actions.getDeliveryItemScanPresignedUrl(items, arrayBuffer, extension)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ListedItemsComingBackFormContainer);