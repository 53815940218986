import React from 'react';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { Input } from '../UI/InputElement/InputElement';
// import { newUserValidator as validate } from '../../../../utility/validator/validator';

let FilterQuotas = (props) => {
  let allAccountIds = [];
  let accounts = [];

  if (props.analyticsFormValues.company_ids && props.analyticsFormValues.company_ids.length > 0) {
    props.analyticsFormValues.company_ids.map(c => {
      let index = props.companyDropdownList.findIndex(com => com.id === c.value);
      if (index >= 0) {
        props.companyDropdownList[index].accounts_associated_with_company.map(account => {
          allAccountIds.push(account.id);
          accounts.push({
            value: account.id,
            label: account.name
          })
        })
      }
    })
  } else {
    accounts = props.accountsList && props.accountsList.map(account => {
          allAccountIds.push(account.id)
          return {
            value: account.id,
            label: account.name
          }
        });
  }

  const groupedAccountOptions = [
    {
      label: 'All Jobs',
      options: [{
        value: allAccountIds,
        label: 'All Jobs'
      }]
    },
    {
      label: 'Job-wise',
      options: accounts
    }
  ]

  const noAccountOptionsMessage = (a, b) => {
    return 'Job not found';
  }



  const groupedAreaOptions = () =>  {
    let allAreaIds = [];
    const allAreaDataList = [];
    props.allAreaData && props.allAreaData.map(c => {
      if (props.analyticsFormValues.account_ids && typeof props.analyticsFormValues.account_ids.value === "number") {
        if ( props.analyticsFormValues.account_ids.value === c.account.id) {
          allAreaIds.push(c.id);
          allAreaDataList.push({
            value: c.id,
            label: c.name
          })
        }
      }
      else {
        allAreaIds.push(c.id);
        allAreaDataList.push({
          value: c.id,
          label: c.name
        })
      }
      return c
    })
    return [
      {
        label: 'All Areas',
        options: [{
          value: allAreaIds,
          label: 'All Areas'
        }]
      },
      {
        label: 'Area-wise',
        options: allAreaDataList
      }
    ]
  }

  const noCompanyOptionsMessage = () => {
    return 'Company not found';
  }
  const onAccountChange = (e) => {
    props.change("area_ids","");
    props.change("account_ids",e);
  }
  return (
      <div className="select_filter_block">
        <h3 className="h3_title">Select Filters</h3>

        <form onSubmit={(e) => props.handleFilterSearch(e)}>
        <div className="tile">
          <ol className="activity-feed">


            <li className="feed-item">
              <div className="filter_select">
                <Input name="account_ids" type="inputGroupSelect" onChange={(e) => onAccountChange(e)}className="multi_Select" noOptionsMessage={noAccountOptionsMessage} options={groupedAccountOptions} placeholder="JOBS" />
                <div className="btn_block">
                <button className="btn btn_danger cst_btn margin-top-0" type="submit">Submit</button>
              </div>
              </div>
            </li>
          {/* <li className="feed-item">
             <div className="filter_select">
             <Input name="area_ids" type="inputGroupSelect" className="multi_Select" noOptionsMessage={noCompanyOptionsMessage} options={groupedAreaOptions()} placeholder="AREAS" />

             </div>
             </li> */}
            {/* <li className="feed-item">
              <div className="btn_block">
                <button className="btn btn_danger cst_btn" type="submit">Submit</button>
              </div>
            </li> */}
          </ol>
        </div>
        </form>
      </div>
  )
}

const mapStateToProps = (state) => {
  return { initialValues: '' }
}

FilterQuotas = reduxForm({
  form: 'analyticsForm',
  // validate,
  enableReinitialize: true
})(FilterQuotas);

FilterQuotas = connect(mapStateToProps)(FilterQuotas)
export default FilterQuotas;