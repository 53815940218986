import React, {useState, useEffect} from "react";
import GoogleMapReact from 'google-map-react';
import moment from "moment";
import { convertForntlineAreaScheduleTime } from "../../../utility/utility";
const AnyReactComponent = ({ text }) => (
    <div className="cst_tooltip">
            <span className="has-tooltip">
                <i className="fa fa-map-marker" style={{ fontSize: '40px', color: '#9b2c59' }}></i>
            </span>
            <span className="tooltip gray" style={{ background: '#000000a3' }}>
                <p>{text}</p>
            </span>
    </div>
  // <div style={{
  //     color: 'white',
  //     background: 'grey',
  //     padding: '15px 10px',
  //     display: 'inline-flex',
  //     textAlign: 'center',
  //     alignItems: 'center',
  //     justifyContent: 'center',
  //     borderRadius: '100%',
  //     transform: 'translate(-10%, -10%)'
  // }}>
  //     "my Lcation"
  //     {/* <i className="fa fa-map-marker" style="font-size:48px;color:red"> my location</i> */}
  // </div>
);

const Unavailable = ({ form, setForm, lng, lat, formatted_address, currentSchedule, submitSchedule, setCurrentSchedule}) => {
  const getFormattedZone = (timezone) => {
    let zone = ''
    if (timezone === 'America/Los_Angeles') {
      zone = 'PST'
    } else if ( timezone === 'America/Chicago') {
      zone = 'CST'
    }
    return '(' + zone + ')';
  }
  return (
      <div className="modal fade setUnavailable_modal" id="setUnavailable" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle">
        {currentSchedule && <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h6 className="modal-title h6_title" id="exampleModalLongTitle">{currentSchedule.area.name}</h6>
              <h6 className="h6_title"><span className="text_light">At</span> {moment(moment().format("MM-DD-YYYY") + " " + currentSchedule.start_time_zone, "MM-DD-YYYY HH:mm:ss").format("hh:mm A")} {getFormattedZone(currentSchedule.area.account.timezone)}</h6>
            </div>
            <div className="modal-body">
              <div className="mb_15">
                <label className="label_modifier">Area</label>
                  <div className="map_part">
                    <figure className="map_inner area-map-schedule" style={{position: "sticky", width: "100%"}}>

                          <GoogleMapReact
                              bootstrapURLKeys='AIzaSyAUImPEdPk-6idATsr0RVAeDsZpTR5gWvU'
                              defaultCenter={{ lat , lng }}
                              defaultZoom={15}
                               style={{}}
                              >
                           <AnyReactComponent
                               lat={lat}
                               lng={lng}
                               text={formatted_address}
                                />
                          </GoogleMapReact>
                      {/* <img src="/images/background/map.jpg" className="map_image"
                       alt="location" />*/}
                    </figure>
                  </div>
              </div>
              <div>
                <label className="label_modifier">Comments</label>
                <textarea className="form-control textarea_modifier" id="exampleFormControlTextarea1" rows="5" placeholder="Type here" onChange={e => setForm({...form, reason: e.target.value})}></textarea>
              </div>

            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn_gray_light cst_btn" data-dismiss="modal" onClick={() => setCurrentSchedule(null)}>Cancel</button>
              <button type="button" className="btn btn_danger cst_btn" data-dismiss="modal" onClick={() => [setCurrentSchedule(null), submitSchedule(form, currentSchedule)]}>Confirm</button>
            </div>
          </div>
        </div>}
      </div>
  )
};

export default Unavailable;