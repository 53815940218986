import React, { useState } from 'react';
import { ClapSpinner } from 'react-spinners-kit';
import ReactTable from 'react-table-v6';
import ReactTooltip from 'react-tooltip';
import 'react-table-v6/react-table.css';
import '../../../../../styles/custom.css';
import { convertLogbookDateTimezone, CustomNoDataComponent } from '../../../../../utility/utility';
import { useSelector, useDispatch } from 'react-redux';
import { FaArrowDown, FaArrowUp } from "react-icons/fa";
import * as actions from '../../../../../redux/actions/index';
import storage from '../../../../../utility/storage';

const PreviewLogBookAreaTable = (props) => {
  console.log('propsss', props);
  const dispatch = useDispatch();
   const SearchValue = useSelector(state => state.form.searchPreviewLogbookForm?.values);
  const DateGenerate = useSelector(state => state.adminOrSuperAdminReducer.dataGenerated);
  const SearchOrResetAccountUsersClicked = useSelector(state => state.adminOrSuperAdminReducer.SearchOrResetAccountUsersClicked);
  const No_of_pages = useSelector(state => state.adminOrSuperAdminReducer.total_logbookArea_pages);
  const No_of_rows = useSelector(state => state.adminOrSuperAdminReducer.logbook_rows);
  const logbookPreviewCurPage = useSelector(state => state.adminOrSuperAdminReducer.logbookPreviewCurPage);

  const data = props.logbookAreasList &&  props.logbookAreasList.map(area  => {
   return {
      // id: area.id,
      // date: area.status === 'reviewed' ? convertLogbookDateTimezone(area.updated_at,area.logbook.account.timezone).toUpperCase() : '-',
      scheduledDate: area.status === 'reviewed' ? convertLogbookDateTimezone(area.start_time_utc, area.logbook.account.timezone) : '-',
      id: area.id,
      reviewed_at: area.status === 'reviewed' ? convertLogbookDateTimezone(area.reviewed_at,area.logbook.account.timezone) : '-',
      status: area.status === 'reviewed' ? 'Yes' : 'No',
      reviewed_by: area.status === 'reviewed' ? area.name : '-',
      actions: <>
      {area.status === 'reviewed' ? <span className="mr_20 account_edit_icn" onClick={() => props.history.push(`/logbook/${props.match.params.logbookId}/view_log/${area.id}`)}><i class="fas fa-eye" aria-hidden="true"></i></span> 
        : 
      //   <><span data-tip="QR Scan" className="mr_20 account_edit_icn" onClick={() =>{props.logbookQRCamera(area)}} data-test="edit_eye_icon" data-toggle="modal" data-target="#viewQuotesCC"><i className="fas fa-camera cst_icon mr-2" aria-hidden="true"></i></span>
      // <ReactTooltip place="top" type="dark" effect="solid" /></>
      <button className={'btn cst_btn btn_danger'} onClick={() =>{
        storage.set('LogbookId', area.id)
        props.logbookQRCamera(area)}}>Scan QR code</button>
      }
      </>
    }
  })
    

  const columns = [
    {
      Header: <> <span> ID </span> <FaArrowUp className="icon-asc" /> <FaArrowDown className="icon-desc" /> </>,
      accessor: 'id', // String-based value accessors!,
      show: props.logbookAreasList.length !== 0,
      width: 150
    },
    {
      Header: <><span> Scheduled Date / Time </span><FaArrowUp className="icon-asc" /> <FaArrowDown className="icon-desc" /> </>,
      accessor: 'scheduledDate', // String-based value accessors!,
      show: props.logbookAreasList.length !== 0,
    },
    {
      Header: <><span> Reviewed Date / Time </span><FaArrowUp className="icon-asc" /> <FaArrowDown className="icon-desc" /> </>,
      accessor: 'reviewed_at', // String-based value accessors!,
      show: props.logbookAreasList.length !== 0,
    },
    {
      Header: <><span> Is Reviewed </span><FaArrowUp className="icon-asc" /> <FaArrowDown className="icon-desc" /> </>,
      accessor: 'status', // String-based value accessors!,
      show: props.logbookAreasList.length !== 0,
    },
    {
      Header: <><span> Reviewed By </span><FaArrowUp className="icon-asc" /> <FaArrowDown className="icon-desc" /> </>,
      accessor: 'reviewed_by',
      show: props.logbookAreasList.length !== 0,
      //width: 150
    },
    {
      Header: ' ',
      accessor: 'actions',
      // width: 150,
      // show: false,
    }
  ]

  const [tablePage, setTablePage] = useState(0)
    const tablePageNew =  React.useRef()
    
    if(logbookPreviewCurPage !== tablePageNew.current) {
        tablePageNew.current = logbookPreviewCurPage;
    }

    const setTablePageFn = (page) => {
        setTablePage(page)
        props.logbookPreviewPageClicked(page)
        tablePageNew.current = page;
    }

  const fetchData = (state, instance) => {
    if (SearchOrResetAccountUsersClicked) {
      dispatch(actions.resetSearchOrResetValue())
    } else {
        if (!DateGenerate) {
          state.page = 2;
          let page = tablePageNew.current >= 0 ? tablePageNew.current + 1 : logbookPreviewCurPage + 1;
          const limit = state.pageSize;
          if(No_of_rows != limit) {
            page = 1;
              setTablePageFn(0)
          }
          const sorted = JSON.stringify(state.sorted);
            props.getLogBookAreaPreview(SearchValue,page,limit,sorted, props.match.params.logbookId);
        }
    }
  }

  return (
    SearchOrResetAccountUsersClicked && props.isLogbookAreas || props.isAreaActiveInActivePending ?
      <div className="loader_btn_block"> <ClapSpinner backColor="#89073c" size={40} frontColor="#89073c8a" /> </div> :
    <> 
      <ReactTable
        data={data}
        loading={props.isLogbookAreas || props.isAreaActiveInActivePending}
        defaultPageSize={No_of_rows }
        minRows={0}
        manual
        onFetchData={fetchData}
        pages={No_of_pages}
        gotoPage={logbookPreviewCurPage}
        page = { logbookPreviewCurPage }
        onPageChange={(page) => {setTablePageFn(page)}}        
        onPageSizeChange={props.onUserPageSizeChange}
        showPaginationTop={true}
        showPaginationBottom={false}
        showPagination={props.logbookAreasList.length !== 0}
        columns={columns}
        NoDataComponent={() => CustomNoDataComponent(props.isLogbookAreas, 'No Logbook Found')}
        getTheadThProps={(state, rowInfo, column, instance) => {
          return {
            style: {
              borderTop: 'none',
              borderBottom: 'none',
              borderRight: 'none',
              textAlign: 'center',
              color: '#566975',
              fontSize: '14px',
              fontWeight: '600',
              whiteSpace: 'nowrap',
              float: 'left',
            }
          }
        }}
        LoadingComponent={() => props.isLogbookAreas ?
            <div style={{
              display: "block",
              position: "absolute",
              left: 0,
              height: '100%',
              right: 0,
              background: "rgba(255,255,255,0.8)",
              transition: "all .3s ease",
              top: 0,
              bottom: 0,
              textAlign: "center"
            }}
              className="loader_btn_block">
              <ClapSpinner backColor="#89073c" size={40} frontColor="#89073c8a" />
            </div> : null}
        getTrProps={(state, rowInfo, column, instance) => {
          console.log(column);
          return {
            className: 'react-table-tr-element',
            style: {
              border: '1px solid #c7cdd1',
              transition: '.3s ease-in-out',
              width: '100%',
              marginTop: '5px',
              float: 'left',
            }
          }
        }}
        getTdProps={(state, rowInfo, column, instance) => {

          if (column.id == 'image') {
            return { style: { marginTop: '0px'} }
          } else {
            return { className: 'react-td-element' }
          }
        }}
        getProps={() => {
          return { style: { border: 'none' } }
        }} />
    </>
  )
}

export default PreviewLogBookAreaTable;