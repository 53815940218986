import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import NewPosition from '../../component/Positions/newPosition';
import Positions from '../../container/PositionsContainer/positionsContainer';
import Regions from '../../container/RegionsContainer/RegionContainer';
import NewRegion from '../Regions/newRegion';

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            <Box p={3}>{children}</Box>
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired
};

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.paper
        // width: 1000,
    }
}));

const Settings = (props) => {

    const classes = useStyles();
    const theme = useTheme();
    const [value, setValue] = React.useState(0);
    const [isPosition, setIsPosition] = React.useState(true);
    const [isRegion, setIsRegion] = React.useState(false);

    const handleChange = (event, newValue) => {
        if (newValue === 0) {
            setIsPosition(true);
            setIsRegion(false);
            props.history.push(`/settings/new_positions_and_regions?position=true`)
        } else if (newValue === 1) {
            setIsPosition(false);
            setIsRegion(true);
            props.history.push(`/settings/new_positions_and_regions?region=true`)
        }
        setValue(newValue);
    }
    useEffect(() => {
        if (props.history.location.search.includes('region')) {
            setIsPosition(false);
            setIsRegion(true);
            setValue(1);
        } else {
            setIsPosition(true);
            setIsRegion(false);
            setValue(0);
        }
      });

    const handleChangeIndex = (index) => {
        setValue(index);
    }


    return (
        <>
            <main className="app-content wraper_content approve_screen approve_Quotes_sec">
                <div className="inspection_content_sec appcontent_Inner">
                    <div className={classes.root}>
                        <AppBar position="static" color="default">
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                indicatorColor="primary"
                                textColor="primary"
                                variant="fullWidth"
                                aria-label="full width tabs example"
                            >
                                <Tab label={"Positions(" + props.totalPositions + ")" } />
                                <Tab label={"Regions(" + props.totalRegions + ")" } />
                                {/* <Tab label="Item Three" /> */}
                            </Tabs>
                        </AppBar>
                        <SwipeableViews
                            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                            index={value}
                            onChangeIndex={handleChangeIndex}
                        >
                            <TabPanel value={value} index={0} dir={theme.direction}>
                                {isPosition ? <Positions /> : null}
                            </TabPanel>
                            <TabPanel value={value} index={1} dir={theme.direction}>
                                {isRegion ? <Regions /> : null}
                            </TabPanel>
                        </SwipeableViews>
                    </div>
                    {(isPosition && props.isOpenModel) ?
                        <NewPosition
                            onSubmit={props.handelPositionSubmit}
                            closePopup={props.closePopup}
                            isLoading={props.isLoading}
                            isEdit={props.isEdit}
                            emptyObjects={props.emptyObjects}
                        /> : null}
                    {/* {(isPosition && props.openDeleteModel) ?
                        
                        : null
                    } */}
                    {(isRegion && props.isOpenModel) ?
                        <NewRegion
                            onSubmit={props.handelRegionSubmit}
                            closePopup={props.closePopup}
                            isEdit={props.isEdit}
                            isLoading={props.isLoading}
                        /> : null}
                </div>
            </main>

        </>
    )
}

export default Settings;