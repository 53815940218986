import React, { useEffect } from 'react';
import { Input } from '../../../UI/InputElement/InputElement';
import { reduxForm } from 'redux-form';
import { departmentValidator as validate } from '../../../../../utility/validator/validator';
import { connect } from 'react-redux';
import isEmpty from '../../../../../utility/IsEmptyValidator';

let NewDepartment = (props) => {

    var radios = document.querySelectorAll('input[type="radio"]');
    for (var i = 0; i < radios.length; i++) {
        radios[i].onfocus = function () {
            this.blur();
        }
    }

    const { handleSubmit, pristine, reset, submitting, error } = props;

    return (
        <div className="modal fade request_quote_modal reject_quot associateUser" id="addDepartment" tabindex="-1" role="dialog" aria-labelledby="rejectQuoteModalTitle" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <label for="exampleInputEmail1" className="cst-label md_title">{props.isDepartmentEdit && props.isDepartmentEdit == true ? 'Update Department' : 'Create Department'}</label>
                        <button type="button" className="close close_icn_pic" data-dismiss="modal" onClick={props.handleFrequentTimeTracker_closeModal}><img src="/images/icons/icn_close_gray.png" alt="Close Icon" /></button>
                    </div>

                    <div className="modal-body">
                        <div className="tab-content">
                            <div id="profile" className="profile_tab cst_tab_content tab-pane fade show active edit_profile_modal">
                                <form className="form_content" onSubmit={handleSubmit}>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label className="label_modifier">Department</label>
                                                <Input type="text" className="form-control input-modifier" placeholder="" name="department[name]" />
                                            </div>
                                            <div className="form-group">
                                                <label className="label_modifier">Description</label>
                                                <Input type="text" className="form-control input-modifier" placeholder="" name="department[description]" />
                                            </div>
                                            <div className="form-group">
                                                <Input type="radio" labelClass="custom-control-label" forLable={"customRadio1"} value={'false'} label={"Active"} className="custom-control-input" id="customRadio1" name="department[active_status]" />
                                                <Input type="radio" labelClass="custom-control-label" forLable={"customRadio2"} value={'true'} label={"In-Active"} className="custom-control-input" id="customRadio2" name="department[active_status]" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="clearfix"></div>
                                    <div className="modal-footer">
                                        <button data-test="submit_button" type="submit" disabled={props.isLoading} className={props.isLoading ? "btn cst_btn btn_danger mr-2 btn-wait" : "btn cst_btn btn_danger mr-2"} >{props.isLoading ? "" : props.isDepartmentEdit == true ? 'Update' : "Create"}</button>
                                        <button onClick={props.handleFrequentTimeTracker_closeModal} id='closeCriteriaModal' type="button" className="btn btn-outline-secondary cst_btn" data-dismiss="modal">Close</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
};

const mapStateToProps = (state) => {
    let department = {}
    if (isEmpty(state.TimeTrackerModReducer.department)) {
        department = {
            active_status: 'false'
        }
    } else {
        department = {
            name: state.TimeTrackerModReducer.department ? state.TimeTrackerModReducer.department.name : '',
            description: state.TimeTrackerModReducer.department ? state.TimeTrackerModReducer.department.description : '',
            active_status: state.TimeTrackerModReducer.department && state.TimeTrackerModReducer.department.active_status ? state.TimeTrackerModReducer.department.active_status == 'active' ? 'false' : 'true' : '',
        }
    }
    return { initialValues: { department } }
}

NewDepartment = reduxForm({
    form: 'departmentForm',
    validate,
    onSubmitFail: (errors) => scrollToFirstError(errors),
})(NewDepartment);
export const scrollToFirstError = (errors) => {
    let scrollToError = null;
    if (errors.department.name) {
        scrollToError = `department[name]`;
    }
}

NewDepartment = connect(mapStateToProps)(NewDepartment)

export default NewDepartment;