import { projectModuleActionTypes } from './ProjectModuleTypes';
import * as API from '../../../../API/FormAuditAPI/projectModuleAPI';

export const getAllDeepCleanChart = (params) => {
    return (dispatch, getState) => {
        dispatch({
            type: projectModuleActionTypes.GET_ALL_DEEP_CLEAN_CHART,
            payload: API.getAllDeepCleanChart(params)
                .then((response) => {
                    if (response.data.success) {
                        return {
                            restroomPortering_list: response.data.data,
                            restroomPortering_total_pages: response.data.totalPages,
                            restroomPortering_no_of_rows: response.data.limit,
                            total_restroomPortering_count: response.data.total,
                            restroomPorteringCurPage: params.page - 1,
                        };
                    };
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
  };
  

  export const deepCleanChartPageClicked = (data) => {
    return {
        type: projectModuleActionTypes.DEEP_CLEAN_CHART_PAGE_CLICKED,
        payload: data,
    };
  };
  
  