import React, { Component } from 'react';
import TimeCardReviewListLayout from '../../../component/TimeTrackerModuleComponent/TimeCardReviewListComponent/TimeCardReviewListLayout';
import { routes } from '../../../../utility/constants/constants';
import storage from '../../../../utility/storage';
import * as actions from '../../../../redux/actions/index';
import { connect } from 'react-redux';
import { reset } from 'redux-form';
import { animateScroll as scroll } from 'react-scroll'
import { convertDateToDifferentTZ } from '../../../../utility/utility';
import { tzUTCDateConverterTool_V2 } from '../../../../utility/TimezoneOperations/timezoneUtility';
var moment = require('moment');
const cloneDeep = require('clone-deep');

class TimeCardReviewListContainer extends Component {
    constructor(props) {
        super(props);

    }

    componentDidMount() {
        scroll.scrollToTop();
    }

    componentDidUpdate(prevProps, prevState) {
        const user = storage.get('user');
        if (user) {
            if (prevProps.user.selected_mode !== user.selected_mode) {
                this.props.timecardReviewedListPageClicked(0)
            }
        }
    }

    resetClicked = () => {
        const page = 1
        const limit = this.props.timecardReviewedList_no_of_rows
        this.props.reset('searchTimecardReviewedListForm');
        this.props.timecardReviewedListPageClicked(0)
        if (this.props.user.role == 'frontline') {
            let employedId = this.props.user.id;
            this.props.getTimecardReviewedList({ page, limit, employedId });
        } else {
            this.props.getTimecardReviewedList({ page, limit });
        }
    }

    searchTimecardReviewedListClicked = (value) => {
        const values = cloneDeep(value)
        let status = null

        const page = 1
        const limit = this.props.timecardReviewedList_no_of_rows

        if (values.status) {
            status = values?.status?.value
        }

        if (this.props.user.role == 'frontline') {
            let employedId = this.props.user.id;
            this.props.getTimecardReviewedList({ page, limit, status, employedId }, values);
        } else {
            this.props.getTimecardReviewedList({ page, limit, status }, values);
        }
    }

    componentWillUnmount() {
        this.props.resetTimeTrackerObjects();
    }

    handleTimecardCorrectionFormClicked = (value) => {
        this.props.history.push({ pathname: `/scheduled-shift/timecard-correction/${value.timecardId}`, state: { shiftDetailId: value.shiftDetailId } });
    }

    handlePreviewTimecardCorrectedClicked = (value) => {
        this.props.history.push({ pathname: `/preview/timecard-correction/${value.timecardId}`, state: { shiftDetailId: value.shiftDetailId } });
    }

    deleteTimecardCorrectionCard = (id) => {
        this.props.deleteTimecardCorrectionCard(id);
    }

    handleFrequentTimeTracker_openDeleteModal = (value) => {
        this.props.handleFrequentTimeTracker_openDeleteModal(value);
    }

    handleFrequentTimeTracker_closeDeleteModal = () => {
        this.props.handleFrequentTimeTracker_closeDeleteModal();
    }

    render() {
        return (
            <>

                <TimeCardReviewListLayout
                    {...this.props}
                    onSubmit={this.searchTimecardReviewedListClicked}
                    resetClicked={this.resetClicked}
                    handleTimecardCorrectionFormClicked={this.handleTimecardCorrectionFormClicked}
                    handlePreviewTimecardCorrectedClicked={this.handlePreviewTimecardCorrectedClicked}
                    deleteTimecardCorrectionCard={this.deleteTimecardCorrectionCard}
                    handleFrequentTimeTracker_openDeleteModal={this.handleFrequentTimeTracker_openDeleteModal}
                    handleFrequentTimeTracker_closeDeleteModal={this.handleFrequentTimeTracker_closeDeleteModal}
                />

            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.authReducer.user,
        isLoading: state.TimeTrackerModReducer.isLoading,
        isTimecardReviewedList: state.TimeTrackerModReducer.isTimecardReviewedList,
        timecardReviewedList: state.TimeTrackerModReducer.timecardReviewedList,
        timecardReviewedList_no_of_rows: state.TimeTrackerModReducer.timecardReviewedList_no_of_rows,
        timecardReviewedList_curPage: state.TimeTrackerModReducer.timecardReviewedList_curPage,
        timecardReviewedList_total_pages: state.TimeTrackerModReducer.timecardReviewedList_total_pages,

        openFrequentTimeTracker_deleteModal: state.TimeTrackerModReducer.openFrequentTimeTracker_deleteModal,
        deleteFrequentTimeTracker_object: state.TimeTrackerModReducer.deleteFrequentTimeTracker_object,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        reset: (searchTimecardReviewedListForm) => dispatch(reset(searchTimecardReviewedListForm)),  // requires form name
        resetTimeTrackerObjects: () => dispatch(actions.resetTimeTrackerObjects()),

        timecardReviewedListPageClicked: (page) => dispatch(actions.timecardReviewedListPageClicked(page)),
        getTimecardReviewedList: (params, values) => dispatch(actions.getTimecardReviewedList(params, values)),

        deleteTimecardCorrectionCard: (id) => dispatch(actions.deleteTimecardCorrectionCard(id)),
        handleFrequentTimeTracker_openDeleteModal: (value) => dispatch(actions.handleFrequentTimeTracker_openDeleteModal(value)),
        handleFrequentTimeTracker_closeDeleteModal: () => dispatch(actions.handleFrequentTimeTracker_closeDeleteModal()),

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TimeCardReviewListContainer);