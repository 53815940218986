import store from '../../redux/store/store';
import * as actions from '../../redux/actions/AuthActions/authAction';
import errorHandler from '../errorHandler/errorHandler';
import { closeModel } from '../utility';
// import { debug } from 'util';
const refreshPage = () => { window.location.reload(false); }
let faceRecognizedAPI=false
const tokenInterceptor = (response) => {
    
    const state = store.getState();

    // authorize only if it's not impersonation
    
    
    if (response.data.user &&
        (response.data.user.access_token || response.data.access_token) &&
        state.authReducer.impersonate === false) {
        if(faceRecognizedAPI){
            response.data.user["is_facial"]=true
        }
        store.dispatch(actions.authorizeUser(response.data.user));
    }

    if (response.config.url.includes('face/recognize')) {
        let user=response.data?.user?.user
        faceRecognizedAPI=user?.is_facial
       
        //store.dispatch(actions.authorizeUser(response.data.user));
      }
  

    if (response.data.success == 'false') {

        if (response.data.error) {

            if (response.data.error.message) {

                if (response.data.error.message.message) {
                    errorHandler(response.data.error.message.message);
                } else {
                    errorHandler(response.data.error.message);
                }

            } else {
                errorHandler(response.data.error);
            }

        } else if (response.data.message) {

            if (response.data.message == "account_ids[0] must be a number") {
                errorHandler('Please Select Job');
            } else if (response.data.message && response.data.message.message) {
                errorHandler(response.data.message.message);
            } else {
                errorHandler(response.data.message);
            }

        }

    }

    if (response.data.success == false) {

        if (response.data.error) {
            if (response.data.error.message) {

                if (response.data.error.message.message) {
                    errorHandler(response.data.error.message.message);
                } else {
                    errorHandler(response.data.error.message);
                }

            } else {
                errorHandler(response.data.error);
            }
        }

        if (response.data.message) {
            if (response.data.message.message == "File format not supported. Please upload csv file") {
                errorHandler(response.data.message.message);
            } else if (!(Array.isArray(response.data.message))) {
                errorHandler(response.data.message);
            }
        }

    }

    return response;
}

export default tokenInterceptor;