import React, { useEffect, useState } from 'react';
import { change, formValueSelector, getFormValues, reduxForm } from 'redux-form';
import { connect, useDispatch, useSelector } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Input } from '../../../UI/InputElement/InputElement';
import { Input as HookInput } from '../../../UI/InputElement/reactFormHookInputField';
import { convertDateToPickerFormat } from '../../../../../utility/utility';
import { userRoles } from '../../../../../utility/constants/constants';
import MonthlyReportTable from './monthlyReportTable';
import SummaryForm from './SummaryForm';
import { addMonthlyUsageReports } from '../../../../../redux/actions/FormAuditModAction/InventoryAction/InventoryAction';
import { useWatch } from 'react-hook-form';

let MonthlyReportLog = (props) => {
    const dispatch = useDispatch()

    const getJobList = (job) => {

        if (job && job.length > 0) {

            return job && job.map((data) => {

                return {
                    value: data.id,
                    label: data.name,
                }
            })
        } else {
            return []
        }
    };


    const getCustomerList = (customer) => {
        if (customer && customer.length > 0) {
            return customer && customer.map((data) => {
                return {
                    value: data.id,
                    label: data.name,
                }
            })
        } else {
            return []
        }
    };

    const { handleSubmit, pristine, reset, submitting, error } = props;
    const [listOfJob, setJobList] = useState(getJobList(props.jobList))
    const [listOfCustomer, setCustomerList] = useState(props.customersList)
    const [elements, setElements] = useState({})
    //const [listOfManager, setManagerList] = useState(getManagersList(props.jobList))

    const { isMonthlyReport, monthly_summary_form } = useSelector(state => state.InventoryModuleReducer);

    const noJobOptionsMessage = (a, b) => {
        return 'Job not found';
    }

    const noCompanyOptionsMessage = (a, b) => {
        return 'Customer not found';
    }


    // useEffect(()=>{
    //   getJobList(jobList)
    // },[jobList])

    // useEffect(() => {
    //     setManagerList(getManagersList(managersList))
    // }, [managersList])


    useEffect(() => {
        setJobList(getJobList(props.jobList))
         
        if(props.formStates && props?.formStates?.job !== undefined || props?.formStates?.job !== "" ){
             const jobId=props.formStates?.job.value
             const isIncludeInJob= props?.jobList?.find((item)=> item.id == jobId)

             if(!isIncludeInJob){
                // we need to remove the current value 
                 dispatch(change('searchScheduledTask', `job`, ""));
                
                

             }

        }
    }, [props.jobList])

    useEffect(() => {
        setCustomerList(getCustomerList(props.customersList))
        if(props.formStates && props?.formStates?.Customer !== undefined || props?.formStates?.Customer !== "" ){
            const companyId=props.formStates?.job.value
            const isIncludeInCompany= props.customersList.find((item)=> item.id == companyId)
            // if(!isIncludeInCompany){
            //    // we need to remove the current value 
            //     dispatch(change('searchScheduledTask', `customer`, ""));
            // }
        
        }
    }, [props.customersList])

    

    useEffect(() => {
        setElements(monthly_summary_form)
    }, [monthly_summary_form])


    const submitSummaryForm = (data) => {
        
        let commonBody = null
        let Normalbody = {}
        if (props.state.year && props.state.job) {

            const year = props.state.year.getFullYear()
            const month = props.state.year.getMonth() + 1
            const account_id = +props.state.job.value
            commonBody = {
                year,
                month,
                account_id
            }
        }

        if (data.created_at || data.updated_at || data.id) {
            delete data.created_at
            delete data.updated_at

        }

        Object.keys(data).map((key) => Normalbody[key] = +data[key])
        
        const body = {
            data: [{
                ...commonBody,
                ...Normalbody,

            }]
        }

        dispatch(addMonthlyUsageReports(body, setElements))
    }


    

    return (
        <>
            <main className="app-content wraper_content inspection_wraper">
                <div className="tab_header search_filter_cont inspection_tab_content ticket_filter">
                    <div className='row'>
                        {/* <div className="col-xl-12 col-lg-6 col-sm-3 col-12 text-right quotes_btn_wrap">
                        <a onClick={props.newTicketClicked} href="javascript:void (0)" className="btn btn-secondary cst_btn" data-toggle="modal" data-backdrop="static" data-keyboard="false" data-target="#addAccountModal" data-test="add_new_ticket"><i className="fa fa-plus icn_plus"></i> Add More</a>
                     </div> */}

                    </div>
                    <form onSubmit={handleSubmit}>
                        <input type="hidden" value="something" />


                        <div className='row' style={{ marginTop: '10px', display: "flex" }}>
                            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6" >

                                <Input name="year" dateFormat="MMMM - yyyy" placeholder="Month and Year" datePickerType={"react-month-datepicker"} type="date" className="form-control input-modifier" />
                                <div class="input-group-append cst_group_append" style={{ marginLeft: 1 }}>
                                    <span class="input-group-text" id="basic-addon2"><i class="fa fa-calendar"></i></span>
                                </div>

                            </div>

                            <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6">

                                <Input type="inputSelect" placeholder="Job" noOptionsMessage={noJobOptionsMessage} options={listOfJob} name="job" onChange={(job) => { props.getSelectedJobsCompany(job.value); }} />
                                {/* <div class="input-group-append cst_group_append" style={{marginLeft:1}}>
                                        <span class="input-group-text" id="basic-addon2"><i class="fa fa-calendar"></i></span>
                                    </div> */}

                            </div>

                            <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6">

                                <Input type="inputSelect" placeholder="Customer" noOptionsMessage={noCompanyOptionsMessage} options={listOfCustomer} name="customer"  onChange={(company) => { props.getSelectedCompanyJobs(company.value); }} />
                                {/* <div class="input-group-append cst_group_append" style={{marginLeft:1}}>
                                        <span class="input-group-text" id="basic-addon2"><i class="fa fa-calendar"></i></span>
                                    </div> */}

                            </div>



                            <div className="col-xl-2 col-lg-6 col-sm-3 col-12">
                                <button data-test="filter_go_btn" style={{ minWidth: '90px' }} className={"btn cst_btn btn_danger mr-2  account_btn"} id="submit" disabled={!Boolean(props?.formStates?.job && props?.formStates?.year )
                                } type="submit">{"Go"} </button>
                                <button data-test="filter_go_btn" style={{ minWidth: '90px' }} onClick={() => {
                                    props.reset()
                                    props.handleReset()
                                }} className={"btn cst_btn btn-outline-secondary account_btn"} id="reset" type="button">{"Reset"} </button>
                            </div>




                        </div>


                        <div className="more_filter_content">
                            <div id="morefilterBtn" className="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                                <div className="card-body">
                                    <div className="row">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>


                <section className="account_sec inspection_tab_content appcontent_Inner">
                    {/* <div>
                        <button style={{ float: 'right' }}>Enable Filter</button>
                    </div> */}
                    {/* <CSVLink filename="Accounts.csv" className="csv_btn btn_danger " data={convertAccountsDataToCsvType(props.accountsList)} >Export Csv</CSVLink><br /><br /><br /> */}

                    {/* <div class="col-lg-12" style={{ display: "flex", }}>
                        <div class="team_manage_head">
                            <h1 class="tm_top_head"> Monthly Usage Reports   </h1>
                        </div>
                    </div> */}

                    <div className="quotes_table_content accounts_table_contnet table-responsive">

                        <div className="table quotes_table user_react_table">
                            <MonthlyReportTable
                                {...props}
                            />
                        </div>
                        <div class="monthlyuse_datepicker_wrap mt-4">
                            {/* <div class="container"> */}

                            {
                                !isMonthlyReport && props.monthly_report_list?.length > 0 ? <SummaryForm
                                    total={props.monthly_report_list?.reduce((acc, curre) => acc + Number(curre.total), 0)}
                                    formSubmittedCallback={submitSummaryForm}
                                    state={props.state}
                                    elements={elements}
                                    modalUpdate={false}
                                    isView={true}
                                /> : null
                            }

                            {/* </div> */}
                        </div>





                    </div>


                    {/* <div className="clearfix"></div>
                <div className="modal-footer">
                    <button type="button" className="btn btn_danger cst_btn">Save</button>
                    <button type="button" className="btn btn-outline-secondary cst_btn" data-dismiss="modal">Cancel</button>
                </div> */}
                </section>
            </main>
            <Dialog
                open={props.openDeleteModel}
                onClose={props.handleClose}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title" style={{ fontSize: '13', color: '#06080f' }}>
                    <h3 style={{ color: '#ab385e' }} data-test="modal_delete_title">Are you sure?</h3>
                </DialogTitle>

                <DialogActions>
                    <button data-test="modal_delete" style={{ minHeight: '36px', minWidth: '80px' }} onClick={() => props.deleteTicket(props.deleteObject.id)} disabled={props.isLoading} className={props.isLoading ? "btn cst_btn btn_danger mr-2 btn-wait" : "btn cst_btn btn_danger mr-2"} id="delete" type="delete">{props.isLoading ? "" : "Delete"}</button>
                    <button data-test="modal_cancel" style={{ minHeight: '36px', minWidth: '80px' }} onClick={props.handleDeleteClose} className={"btn cst_btn btn-outline-secondary"} id="cancel" type="cancel">Cancel</button>
                    {/* <Button onClick={props.handleClose} variant="contained" color="primary">
                        Cancel
                        </Button>


                    <Button onClick={() => props.deleteAccount(props.deleteObject.id)} disabled={props.isLoading} variant="contained" color="secondary">
                        {props.isLoading ? <CircularProgress size={24} /> : 'Delete'}
                    </Button> */}
                </DialogActions>
            </Dialog>
        </>

    )
}

const mapStateToProps = (state) => {

    let job = state.TimeTrackerModReducer.scheduledTask_searchValues && state.TimeTrackerModReducer.scheduledTask_searchValues.job ? state.TimeTrackerModReducer.scheduledTask_searchValues.job : "";
    let year = state.TimeTrackerModReducer.scheduledTask_searchValues && state.TimeTrackerModReducer.scheduledTask_searchValues.fromDate ? convertDateToPickerFormat(state.TimeTrackerModReducer.scheduledTask_searchValues.fromDate) : "";
    let customer = state.TimeTrackerModReducer.scheduledTask_searchValues && state.TimeTrackerModReducer.scheduledTask_searchValues.toDate ? convertDateToPickerFormat(state.TimeTrackerModReducer.scheduledTask_searchValues.toDate) : "";

    return { initialValues: { job, year, customer }, formStates: getFormValues('searchScheduledTask')(state) }
}

MonthlyReportLog = reduxForm({
    form: 'searchScheduledTask',
    // validate,
    enableReinitialize: true
})(MonthlyReportLog);
const selector = formValueSelector('searchScheduledTask')
MonthlyReportLog = connect(state => {
    const { fromDate, toDate } = selector(state, 'fromDate', 'toDate')
    const selectedValues = { fromDate, toDate }
    return {
        selectedValues
    }
})(MonthlyReportLog)
MonthlyReportLog = connect(mapStateToProps)(MonthlyReportLog)

export default MonthlyReportLog;

//MonthlyReportLog