import React from 'react';
import { Input } from '../../../UI/InputElement/InputElement';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import isEmpty from '../../../../../utility/IsEmptyValidator';
import cloneDeep from 'clone-deep';
import PickupsTicketTable from './PickupsTicketTable';

let PickupsTicket = (props) => {

  const statusOptions = [
    {
      id: 'readyForPickup',
      name: 'Ready For Pickup'
    },
    {
      id: 'picked',
      name: 'Picked'
    },
    {
      id: 'delivered',
      name: 'Delivered'
    },
    {
      id: 'returned',
      name: 'Returned'
    },
    {
      id: 'completed',
      name: 'Completed'
    },
  ];

  const isEmergencyOptions = [
    {
      id: 'yes',
      name: 'Yes'
    },
    {
      id: 'no',
      name: 'No'
    },
  ];

  const { handleSubmit, pristine, reset, submitting, error } = props;
  return (
    <>
      <main className="app-content wraper_content inspection_wraper">
        <div className="tab_header search_filter_cont inspection_tab_content appcontent_inner frontlines_filter mb-2">
          <form onSubmit={handleSubmit} >
            <div className="row mt-2">
              <div className="col-xl-2 col-lg-3 col-md-6 col-sm-6 col-12">
                <div className="app-search">
                  <Input className="fil_search_input input-modifier" name="id" type="search" placeholder="Search by ID" dataTest="frontline_name_filter" />
                  <button className="app-search__button">
                    <i class="fa fa-search" aria-hidden="true"></i>
                  </button>
                </div>
              </div>
              <div className="col-xl-4 col-lg-3 col-md-6 col-sm-6 col-12">
                <div className="app-search">
                  <Input className="fil_search_input input-modifier" name="fulfillment_manager_location" type="search" placeholder="Search By Fulfillment Manager Location" dataTest="frontline_name_filter" />
                  <button className="app-search__button">
                    <i class="fa fa-search" aria-hidden="true"></i>
                  </button>
                </div>
              </div>
              <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
                <div className="app-search">
                  <Input className="fil_search_input input-modifier" name="approver_name" type="search" placeholder="Search By Approver Name" dataTest="frontline_id_filter" />
                  <button className="app-search__button">
                    <i class="fa fa-search" aria-hidden="true"></i>
                  </button>
                </div>
              </div>
              <div className="col-xl-2 col-lg-3 col-md-6 col-sm-6 col-12">
                <Input className="custom-select input-modifier add_user_select" name="status" type="select" options={statusOptions} placeholder="Search By Status" />
              </div>
              <div className="col-xl-2 col-lg-4 col-md-6 col-sm-6 mt-3">
                <Input name="is_emergency" dataTest="select_ticket_status" type="select" options={isEmergencyOptions} placeholder="Search By Emergency" className="custom-select input-modifier add_user_select" />
              </div>
              {/* <div className=''> */}
              <div className="col-xl-2 col-lg-3 col-md-6 col-sm-6 col-12 mt-3">
                <div className="frontline_btn">
                  <button data-test="filter_go_btn" style={{ minWidth: '90px' }} className={"btn cst_btn btn_danger mr-2"} id="submit" type="submit">{"Go"} </button>
                  <button data-test="filter_reset_btn" style={{ minWidth: '90px' }} onClick={props.resetClicked} className={"btn cst_btn btn-outline-secondary"} id="reset" type="reset">{"Reset"} </button>
                </div>
              </div>
              {/* </div> */}

            </div>
          </form>
        </div>

        <section className="account_sec user_screen inspection_tab_content">
          {/* <div style={{display: 'flex', justifyContent: 'flex-end'}}>
            <button type="button" className="btn_danger btn cst_btn w-25"> Scan for Pickup </button>
          </div> */}
          <div className="quotes_table_content table-responsive">
            <div className="table quotes_table user_table_contnet user_react_table account_table">
              <PickupsTicketTable
                {...props}
              />
            </div>
          </div>
        </section>
      </main>
    </>
  )
}

const mapStateToProps = (state) => {
  let status = null;
  if (isEmpty(state.InventoryReducer.pickupsTicketCurStatus)) {

  } else {
    status = state.InventoryReducer && state.InventoryReducer.pickupsTicketCurStatus ? state.InventoryReducer.pickupsTicketCurStatus : 'readyForPickup'
  }

  return { initialValues: { status } };
}

PickupsTicket = reduxForm({
  form: 'searchPickupsTicketForm',
  //validate,
  enableReinitialize: true
})(PickupsTicket);

PickupsTicket = connect(mapStateToProps)(PickupsTicket)

export default PickupsTicket;