import React from 'react';
import { reduxForm, change, } from 'redux-form';
import { connect, useDispatch } from 'react-redux';
import { Input } from '../UI/InputElement/InputElement';
import { convertQuotesReportsToCsvType } from '../../../utility/utility';
import { quotesReportValidator as validate } from '../../../utility/validator/validator';
import { CSVLink, CSVDownload } from "react-csv";

let ReportsCsv = (props) => {

    const dispatch = useDispatch();

    let allAccountIds = [];
    let accounts = [];

    if (props.formValues.company_ids && props.formValues.company_ids.length > 0) {
        props.formValues.company_ids.map(c => {
            if (c.value.length > 1) {
                c.value.forEach(cid => {
                    let index = props.companyDropdownList.findIndex(com => com.id === cid);
                    if (index >= 0) {
                        props.companyDropdownList[index].accounts_associated_with_company.map(account => {
                            allAccountIds.push(account.id);
                            accounts.push({
                                value: account.id,
                                label: account.name
                            })
                        })
                    }
                })
            } else {
                let index = props.companyDropdownList.findIndex(com => com.id === c.value);
                if (index >= 0) {
                    props.companyDropdownList[index].accounts_associated_with_company.map(account => {
                        allAccountIds.push(account.id);
                        accounts.push({
                            value: account.id,
                            label: account.name
                        })
                    })
                }
            }
        })
    } else {
        accounts = props.accountsList && props.accountsList.map(account => {
            allAccountIds.push(account.id)
            return {
                value: account.id,
                label: account.name
            }
        });
    }

    const groupedAccountOptions = [
        {
            label: 'All Jobs',
            options: [{
                value: allAccountIds,
                label: 'All Jobs'
            }]
        },
        {
            label: 'Job-wise',
            options: accounts
        }
    ]

    const noAccountOptionsMessage = (a, b) => {
        return 'Job not found';
    }

    let allCompanyIds = [];
    const companyDropdownList = props.companyDropdownList && props.companyDropdownList.map(c => {
        allCompanyIds.push(c.id);
        return {
            value: c.id,
            label: c.name
        }
    })

    const groupedCompanyOptions = [
        {
            label: 'All Customers',
            options: [{
                value: allCompanyIds,
                label: 'All Customers'
            }]
        },
        {
            label: 'Customer-wise',
            options: companyDropdownList
        }
    ]

    const noCompanyOptionsMessage = () => {
        return 'Company not found';
    }

    const onAccountChange = (e) => {

        const index = e && e.findIndex(c => c.label === 'All Jobs');
        if (index === 0 && e.length > 1) {
            e = e.splice(0, 1);
        } else if (index > 0 && e.length > 1) {
            e = e.splice(0, e.length - 1);
        }
    }

    const onCompanyChange = (e) => {

        const index = e && e.findIndex(c => c.label === 'All Customers');
        if (index === 0 && e.length > 1) {
            e = e.splice(0, 1);
        } else if (index > 0 && e.length > 1) {
            e = e.splice(0, e.length - 1);
        }
        dispatch(change('reportsForm', `account_ids`, []))
    }

    const { handleSubmit, pristine, reset, submitting, error } = props;

    return (
        <div className="select_filter_block">
            <h3 className="h3_title">Select Filters</h3>
            <form onSubmit={handleSubmit}>
                <div className="tile">
                    <ol className="activity-feed">
                        {/* <li className="feed-item">
                            <div className="filter_select">
                                <Input name="company_ids" onChange={onCompanyChange} isMulti={true} type="inputGroupSelect" className="multi_Select" noOptionsMessage={noCompanyOptionsMessage} options={groupedCompanyOptions} placeholder="CUSTOMERS" />
                            </div>
                        </li> */}

                        <li className="feed-item">
                            <div className="filter_select">
                                <Input name="account_ids" onChange={onAccountChange} isMulti={true} type="inputGroupSelect" className="multi_Select" noOptionsMessage={noAccountOptionsMessage} options={groupedAccountOptions} placeholder="JOBS" />
                            </div>
                        </li>
                        <li className="feed-item">
                            <div className="form-group">
                                <label className="form_title cst_mt">Include Tags</label>
                                <Input type="radio" labelClass="custom-control-label" forLable={"all"} value="all" label={"All"} className="custom-control-input input-modifier" id="all" name="tag_opportunity" />
                                <Input type="radio" labelClass="custom-control-label" forLable={"yes"} value="true" label={"Yes"} className="custom-control-input input-modifier" id="yes" name="tag_opportunity" />
                                <Input type="radio" labelClass="custom-control-label" forLable={"no"} value="false" label={"No"} className="custom-control-input input-modifier" id="no" name="tag_opportunity" />
                            </div>
                        </li>
                        <li className="feed-item">
                            <div className="form-group">
                                <label className="form_title cst_mt">Is Active</label>
                                <Input type="radio" labelClass="custom-control-label" forLable={"activeAll"} value="all" label={"All"} className="custom-control-input input-modifier" id="activeAll" name="active_status" />
                                <Input type="radio" labelClass="custom-control-label" forLable={"activeYes"} value="true" label={"yes"} className="custom-control-input input-modifier" id="activeYes" name="active_status" />
                                <Input type="radio" labelClass="custom-control-label" forLable={"activeNo"} value="false" label={"No"} className="custom-control-input input-modifier" id="activeNo" name="active_status" />
                            </div>
                        </li>
                        <li className="feed-item">
                            <form className="form-inline">
                                <label className="form_title ">Date Range</label>
                                <div className="input-group cst_input_group">
                                    <label>
                                        <Input name="fromDate" maxDate={props.formValues.toDate ? props.formValues.toDate : new Date()} dateFormat="MM-dd-yyyy" placeholder="From Date" datePickerType={"react-datepicker"} type="date" className="form-control input-modifier" />
                                        <div class="input-group-append cst_group_append">
                                            <span class="input-group-text" id="basic-addon2"><i class="fa fa-calendar"></i></span>
                                        </div>
                                    </label>
                                    {/* <Input name="fromDate"
                                        maxDate={props.formValues.toDate ? props.formValues.toDate : null}
                                        dateFormat="MM-dd-yyyy" placeholder="From Date" datePickerType={"react-datepicker"} type="date" className="form-control" /> */}
                                    {/* <div className="input-group-append">
                                        <span className="input-group-text" id="basic-addon2"><img src="/images/icons/calendar.png" alt="Calendar Icon" /></span>
                                    </div> */}
                                </div>
                                <span className="des">-</span>
                                <div className="input-group cst_input_group">
                                    <label>
                                        <Input name="toDate" minDate={props.formValues.fromDate ? props.formValues.fromDate : null} maxDate={new Date()} dateFormat="MM-dd-yyyy" placeholder="To Date" datePickerType={"react-datepicker"} type="date" className="form-control input-modifier" />
                                        <div class="input-group-append cst_group_append">
                                            <span class="input-group-text" id="basic-addon2"><i className="fa fa-calendar"></i></span>
                                        </div>
                                        {/* <Input name="toDate"
                                            minDate={props.formValues.fromDate ? props.formValues.fromDate : null}
                                            dateFormat="MM-dd-yyyy" placeholder="To Date" datePickerType={"react-datepicker"} type="date" className="form-control" />
                                        <div className="input-group-append">
                                            <span className="input-group-text" id="basic-addon2"><img src="/images/icons/calendar.png" alt="Calendar Icon" /></span>
                                        </div> */}
                                    </label>
                                </div>
                            </form>
                        </li>
                        <li className="feed-item">
                            <div className="btn_block">
                                <button type="submit" className={props.isQuotesReportLoading ? "btn btn_danger cst_btn btn-wait" : 'btn btn_danger cst_btn'}>
                                    {props.isQuotesReportLoading ? '' : 'Submit'}</button>
                            </div>
                        </li>
                    </ol>
                    <span style={{ color: '#862937' }}>{props.quotesReportMessage}</span>
                    {/* {props.quotesReport && props.quotesReport.length > 0 ?
                        <div className="tile_ft">
                            <CSVLink filename="QuotesReport.csv" className="btn btn_danger cst_btn" data={convertQuotesReportsToCsvType(props.quotesReport)}>Download CSV</CSVLink>
                        </div>
                        : null} */}
                </div>
            </form>
        </div>
    )
}

const mapStateToProps = (state) => {
    return { initialValues: { tag_opportunity: 'all', active_status: 'all' } }
}

ReportsCsv = reduxForm({
    form: 'reportsForm',
    validate,
    enableReinitialize: true
})(ReportsCsv);

ReportsCsv = connect(mapStateToProps)(ReportsCsv)
export default ReportsCsv;