import React from 'react';
import Holidays from '../../component/Holidays/Holidays';
import AreaTypes from '../../component/AreaTypes/AreaTypes';
import { PegAssureTabs, TimeZones } from '../../../utility/constants/constants';
import * as actions from '../../../redux/actions/index';
import { connect } from 'react-redux';
import { getUser } from '../../../utility/utility';
import { animateScroll as scroll} from 'react-scroll'
const cloneDeep = require('clone-deep');
var moment = require('moment');

class Pegassure extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Tabs: {
                AreaTypes: true,
                Holidays: false,
            }
        }
    }

    tabClicked = (activeTab) => {
        if (PegAssureTabs.AreaTypes === activeTab) {
            this.setState({
                Tabs: {
                    AreaTypes: true,
                    Holidays: false,
                }
            })
        } else if (PegAssureTabs.Holidays === activeTab) {
            if (!this.props.holidaysList) {
                this.props.getHolidaysList();
            }
            this.setState({
                Tabs: {
                    AreaTypes: false,
                    Holidays: true,
                }
            })
        }
    }

    componentDidMount = () => {
        this.props.getHolidaysList();
        scroll.scrollToTop();
    }

    // addNewHoliday = (values) => {
    //     let data = cloneDeep(values);
    //     data.holiday.holiday_date = moment.tz(data.holiday.holiday_date, TimeZones.PST).format('MM-DD-YYYY')
    //     if (data.holiday.is_recurring === "true" || data.holiday.is_recurring) {
    //         data.holiday.is_recurring = true;
    //     } else {
    //         data.holiday.is_recurring = false;
    //     }

    //     if (this.props.isEdit) {
             
    //         this.props.updateHoliday(data);
    //     } else {
    //         this.props.addNewHoliday(data);
    //     }
    // }

    render() {
         
        return (
            // <main className="app-content wraper_content pegassure_wrap">
            //     <div className="pg_con_wrap">
            //         <div className="tab_header custom_table_tabs">
            //             <ul className="nav nav-tabs" id="myTab" role="tablist">
            //                 <li className="nav-item" onClick={() => this.tabClicked(PegAssureTabs.AreaTypes)}>
            //                     <a className={this.state.Tabs.AreaTypes ? "nav-link active" : "nav-link"} id="areaType-tab" data-toggle="tab" href="#areaType" role="tab" aria-controls="areaType" aria-selected="true">Area Type</a>
            //                 </li>
            //                 <li className="nav-item" onClick={() => this.tabClicked(PegAssureTabs.Holidays)}>
            //                     <a className={this.state.Tabs.Holidays ? "nav-link active" : "nav-link"} id="holidays-tab" data-toggle="tab" href="#holidays" role="tab" aria-controls="holidays" aria-selected="false">Holidays</a>
            //                 </li>
            //             </ul>
            //         </div>
            //         <div className="tab-content tab_con_block" id="myTabContent">
            //             {this.state.Tabs.AreaTypes ?
            //                 <AreaTypes
            //                     {...this.props}
            //                     closePopup={this.props.closePopup}
            //                     onClick={this.props.openPopup}
            //                 />
            //                 :
            //                 this.state.Tabs.Holidays ?
                                <Holidays
                                    {...this.props}
                                    deleteHoliday={this.props.deleteHoliday}
                                    editHolidayClicked={this.props.editHolidayClicked}
                                    addNewHoliday={this.addNewHoliday}
                                    closePopup={this.props.closePopup}
                                    onClick={this.props.openPopup}
                                    handleDeleteClose={this.props.handleDeleteClose}
                                    totalHolidays= {this.props.totalHolidays}

                                />
            //                     : null}
            //         </div>
            //     </div>
            // </main>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        holidaysList: state.adminOrSuperAdminReducer.holidaysList,
        holiday: state.adminOrSuperAdminReducer.holiday,
        isHolidaysLoading: state.adminOrSuperAdminReducer.isHolidaysLoading,
        isEdit: state.adminOrSuperAdminReducer.isEdit,
        isOpenModel: state.adminOrSuperAdminReducer.isOpenModel,
        deleteObject: state.adminOrSuperAdminReducer.deleteObject,
        openDeleteModel: state.adminOrSuperAdminReducer.openDeleteModel,
        totalHolidays: state.adminOrSuperAdminReducer.totalHolidays
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        handleDeleteClose: () => dispatch(actions.handleDeleteClose()),
        deleteRating: (id) => dispatch(actions.deleteRating(id)),
        getHolidaysList: () => dispatch(actions.getHolidaysList()),
        openPopup: () => dispatch(actions.openPopup()),
        closePopup: () => dispatch(actions.closePopup()),
        // reset: (searchRatingForm) => dispatch(reset(searchRatingForm)),  // requires form name
        editHolidayClicked: (holiday) => dispatch(actions.editHolidayClicked(holiday)),
        handleClickDeleteModel: (account) => dispatch(actions.handleClickDeleteModel(account)),
        updateHoliday: (data) => dispatch(actions.updateHoliday(data)),
        addNewHoliday: (data) => dispatch(actions.addNewHoliday(data)),
        deleteHoliday: (id) => dispatch(actions.deleteHoliday(id)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Pegassure);