import React, { Component } from 'react';
import Category from '../../component/CategoryComponent/categories';
import * as actions from '../../../redux/actions/index';
import { connect } from 'react-redux';
import { getUser } from '../../../utility/utility';
import { animateScroll as scroll} from 'react-scroll'
class CategoryContainer extends Component {
    componentDidMount() {
            this.props.getCategories(
                {hide: false}
            );
        
    scroll.scrollToTop();
    }

    addNewCategory = (values) => {

        if (this.props.isEdit) {
            this.props.updateCategory(values, this.props.category.category.id);
        } else {
            // values.category['active_status'] = 'active';
            this.props.addNewCategory(values);
        }
    }

    render() {

        return (
            <Category
                categoriesList={this.props.categoriesList ? this.props.categoriesList : []}
                openDeleteModel={this.props.openDeleteModel}
                addNewCategory={this.addNewCategory}
                isEdit={this.props.isEdit}
                isOpenModel={this.props.isOpenModel}
                handleDeleteClose={this.props.handleDeleteClose}
                editCategoryClicked={this.props.editCategoryClicked}
                deleteCategory={this.props.deleteCategory}
                deleteObject={this.props.deleteObject}
                handleClickDeleteModel={this.props.handleClickDeleteModel}
                isLoading={this.props.isLoading}
                isCategories={this.props.isCategories}
                openPopup={this.props.openPopup}
                closePopup={this.props.closePopup}
                getCategories= {this.props.getCategories}
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        categoriesList: state.adminOrSuperAdminReducer.categoriesList,
        category: state.adminOrSuperAdminReducer.category,
        isEdit: state.adminOrSuperAdminReducer.isEdit,
        isOpenModel: state.adminOrSuperAdminReducer.isOpenModel,
        isLoading: state.adminOrSuperAdminReducer.isLoading,
        isCategories: state.adminOrSuperAdminReducer.isCategories,
        deleteObject: state.adminOrSuperAdminReducer.deleteObject,
        openDeleteModel: state.adminOrSuperAdminReducer.openDeleteModel
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        handleDeleteClose: () => dispatch(actions.handleDeleteClose()),
        addNewCategory: (values) => dispatch(actions.addNewCategory(values)),
        updateCategory: (values, id) => dispatch(actions.updateCategory(values, id)),
        deleteCategory: (id) => dispatch(actions.deleteCategory(id)),
        closePopup: () => dispatch(actions.closePopup()),
        getCategories: (data) => dispatch(actions.getCategories(data)),
        openPopup: () => dispatch(actions.openPopup()),
        editCategoryClicked: (category) => dispatch(actions.editCategoryClicked(category)),
        handleClickDeleteModel: (category) => dispatch(actions.handleClickDeleteModel(category)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CategoryContainer);