import React, { useEffect } from 'react';
import { Input } from '../../../UI/InputElement/InputElement';
import { reduxForm } from 'redux-form';
import { HoursTypeValidator as validate } from '../../../../../utility/validator/validator';
import { connect } from 'react-redux';
import isEmpty from '../../../../../utility/IsEmptyValidator';

let NewHoursType = (props) => {

    var radios = document.querySelectorAll('input[type="radio"]');
    for (var i = 0; i < radios.length; i++) {
        radios[i].onfocus = function () {
            this.blur();
        }
    }

    const { handleSubmit, pristine, reset, submitting, error } = props;
    return (
        <div className="modal fade request_quote_modal reject_quot associateUser add_frontline_pop" id="addHoursType" tabindex="-1" role="dialog" aria-labelledby="rejectQuoteModalTitle" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered user_newQuote" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <label for="exampleInputEmail1" className="cst-label md_title">{props.isHoursType && props.isHoursType == true ? 'Update Hours type' : 'Create Hours type'}</label>
                        <button type="button" className="close close_icn_pic" data-dismiss="modal" onClick={props.handleFrequentTimeHoursTypes_closeModal}><img src="/images/icons/icn_close_gray.png" alt="Close Icon" /></button>
                    </div>

                    <div className="modal-body">
                        <div className="tab-content">
                            <div id="profile" className="profile_tab cst_tab_content tab-pane fade show active edit_profile_modal">
                                <form className="form_content" onSubmit={handleSubmit}>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label className="label_modifier">Hours type ID</label>
                                                <Input type="number" className="form-control input-modifier" placeholder="Hours type ID" name="hoursType[id]" />
                                            </div>
                                            <div className="form-group">
                                                <label className="label_modifier">Hours type Description</label>
                                                <Input type="text" className="form-control input-modifier" placeholder="Hours type Description" name="hoursType[description]" />
                                            </div>
                                            <div className="form-group">
                                                <Input type="radio" labelClass="custom-control-label" forLable={"customRadio1"} value={'false'} label={"Active"} className="custom-control-input" id="customRadio1" name="hoursType[active_status]" />
                                                <Input type="radio" labelClass="custom-control-label" forLable={"customRadio2"} value={'true'} label={"In-Active"} className="custom-control-input" id="customRadio2" name="hoursType[active_status]" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="clearfix"></div>
                                    <div className="modal-footer">
                                        <button data-test="submit_button" type="submit" disabled={props.isLoadingHours} className={props.isLoadingHours ? "btn cst_btn btn_danger mr-2 btn-wait" : "btn cst_btn btn_danger mr-2"} >{props.isLoadingHours ? "" : props.isHoursType == true ? 'Update' : "Create"}</button>
                                        <button onClick={props.handleFrequentTimeHoursTypes_closeModal} id='closeAddSkillSetModal' type="button" className="btn btn-outline-secondary cst_btn" data-dismiss="modal">Close</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

const mapStateToProps = (state) => {
    let hoursType = {}
    if (isEmpty(state.TimeTrackerModReducer.isHoursType_Edit)) {
        hoursType = {
            active_status: 'false'
        }
    } else {
        hoursType = {
            id: state.TimeTrackerModReducer.isHoursType_Edit ? state.TimeTrackerModReducer.isHoursType_Edit.type_id : '',
            description: state.TimeTrackerModReducer.isHoursType_Edit ? state.TimeTrackerModReducer.isHoursType_Edit.description : '',
            active_status: state.TimeTrackerModReducer.isHoursType_Edit && state.TimeTrackerModReducer.isHoursType_Edit.active_status ? state.TimeTrackerModReducer.isHoursType_Edit.active_status == 'active' ? 'false' : 'true' : '',
        }
    }
    return { initialValues: { hoursType } }
}

NewHoursType = reduxForm({
    form: 'hoursTypeForm',
    validate,
    onSubmitFail: (errors) => scrollToFirstError(errors),
})(NewHoursType);
export const scrollToFirstError = (errors) => {
    let scrollToError = null;
    if (errors.hoursType.id) {
        scrollToError = `hoursType[id]`;
    }
}

NewHoursType = connect(mapStateToProps)(NewHoursType)

export default NewHoursType;