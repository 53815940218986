import React, { Component } from 'react';
import ApproveQuote from '../../component/Quotes/approveQuote';
import * as actions from '../../../redux/actions/index';
import { connect } from 'react-redux';
import storage from '../../../utility/storage';
import isEmpty from '../../../utility/IsEmptyValidator';
import { routes } from '../../../utility/constants/constants';
import * as commonService from '../../../utility/utility';
import { decode, encode } from 'base64-arraybuffer';
import { extractExtention, getUser } from '../../../utility/utility';
import { showConfirmAlert } from '../../../utility/successAlert/ConfirmAlert';
import { animateScroll as scroll} from 'react-scroll'

class ApproveQuoteContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            faultImages: 0,
            isImagesEmpty: false,
            imageName: '',
            idsToDelete: [],
            imageUrl: null,
            base64: [],
            AllFileTypes: [],
            fileType: '',
            // totalSale: this.props.quote.total_sale,
            // description: this.props.quote.description
        };
        this.inputOpenFileRef = React.createRef();
        this.onPortfolioFileChange = this.onPortfolioFileChange.bind(this);
        this.removeImage = this.removeImage.bind(this);
        this.choosePhotoClicked = this.choosePhotoClicked.bind(this);
    }

    approveQuoteClicked = (values) => {
        const arrayBuffer = [];
        this.state.base64.map((url, i) => {
            if (!url.photo_urls) {
                let base_photo = null;
                const image = url.split(',');
                base_photo = image[1];

                arrayBuffer.push(decode(base_photo));
            }
        });
        const extentions = commonService.extractExtention(this.state.imageName);

        const body = {
            status: 'approved',
            total_sale: parseFloat(values.totalSale.replace(/\,/g, '')),
            description: values.description,
            // arrayBuffer: arrayBuffer,
            // extentions: extentions
        };
        this.props.handelApproveOrRejectClicked(this.props.match.params.quoteId, body);
    };

    choosePhotoClicked = () => {
        this.inputOpenFileRef.current.click();
    };

    onPortfolioFileChange = (e) => {
        this.setState({
            faultImages: 0,
        });
        console.log(this.state.base64);
        let files = e.target.files;
        let imageName = [],
            fileType = [],
            AllFileTypes = [],
            base64 = [];
        const that = this;
        // for (let i = 0; i < files.length; i++) {
        async function readFile(index) {
            if (index < files.length) {
                let reader = new FileReader();
                let file = files[index];
                imageName.push(file.name);
                fileType.push(file.type);
                AllFileTypes.push(file.type);

                if (file.type.match('image')) {
                    let fileDate = await commonService.compressImage(file, {});
                    let reader = new FileReader();
                    reader.readAsDataURL(fileDate);
                    reader.onload = function() {
                        // setImageData({ ...imageData, base64: reader.result, file: file });
                        base64.push(reader.result);
                        if (index === files.length - 1) {
                            //
                            that.setState((prevState) => ({
                                base64: [...prevState.base64, ...base64],
                            }));
                        }
                    };
                    reader.onerror = function(error) {
                        console.log('Error: ', error);
                    };
                    if (index === files.length - 1) {
                        //
                        that.setState((prevState) => ({
                            imageName: [...prevState.imageName, ...imageName],
                            fileType: [...prevState.fileType, ...fileType],
                            AllFileTypes: [...prevState.AllFileTypes, ...AllFileTypes],
                            // arrayBuffer: [...prevState.arrayBuffer, decode(reader.result)],
                            // base64: [...prevState.base64, ...base64]
                        }));
                    }
                    readFile(index + 1);
                }
                // reader.readAsArrayBuffer(file)
                // reader.readAsDataURL(file)
            }
        }

        readFile(0);
    };
    removeImage = (url, fileTypeIndex) => {
        let updatedUrls = [...this.state.base64];
        let updatedFileTypes = [...this.state.fileType];
        let updatedImageNames = [...this.state.imageName];

        let urlIndex = this.state.base64.findIndex(function(u) {
            return u === url;
        });
        updatedUrls.splice(urlIndex, 1);
        updatedFileTypes.splice(fileTypeIndex, 1);
        updatedImageNames.splice(fileTypeIndex, 1);

        this.setState({
            base64: updatedUrls,
            fileType: updatedFileTypes,
            imageName: updatedImageNames,
        });
    };

    removeUrls = (url) => {
        let updatedUrls = [...this.state.savedPortfolioURls];

        if (url.photo_urls) {
            this.setState((prevState) => ({
                idsToDelete: [...prevState.idsToDelete, url.id],
            }));
        }

        let urlIndex = this.state.savedPortfolioURls.findIndex(function(u) {
            return u === url;
        });

        updatedUrls.splice(urlIndex, 1);

        this.setState({
            savedPortfolioURls: updatedUrls,
        });
    };

    // componentDidUpdate(prevProps, prevState) {

    //     if (this.props.quote.description !== prevProps.quote.description) {
    //         this.setState({
    //             totalSale: this.props.quote.total_sale.replace(/\,/g, ""),
    //             description: this.props.quote.description
    //         })
    //     }
    // }

    componentDidMount() {
        if (isEmpty(this.props.quote)) {
            console.log(this.props);
            this.props.getQuote(this.props.match.params.quoteId);
        }
    scroll.scrollToTop();
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.quote !== this.props.quote) {
            if (this.state.idsToDelete.length === 0) {
                this.setState({
                    base64: [],
                    imageName: [],
                    arrayBuffer: [],
                    idsToDelete: [],
                    portfolioImages: [],
                    savedPortfolioURls: this.props.quote.attachments,
                });
            } else {
                this.setState({
                    // base64: this.state.base64,
                    imageName: [],
                    arrayBuffer: [],
                    idsToDelete: [],
                    portfolioImages: [],
                    savedPortfolioURls: this.props.quote.attachments,
                });
            }
        }
    }

    onCancleClicked = () => {
        this.props.history.push(routes.QUOTES);
    };

    // fieldChange = (e) => {
    //     this.setState({
    //         [e.target.name]: e.target.value
    //     })
    // }

    submitQuote = (quote) => {
        const arrayBuffer = [];
        this.state.base64.map((url, i) => {
            if (!url.photo_urls) {
                let base_photo = null;
                const image = url.split(',');
                base_photo = image[1];

                arrayBuffer.push(decode(base_photo));
            }
        });

        const body = {
            status: 'approved',
            total_sale: parseFloat(quote.totalSale.replace(/\,/g, '')),
            description: quote.description,
            id: this.props.match.params.quoteId,
            isApproved: true,
        };
        const extentions = extractExtention(this.state.imageName);

        if (extentions.length > 0) {
            this.props.getQuotePresignedUrl(body, extentions, arrayBuffer, this.state.idsToDelete, false);
        } else {
            this.props.updateQuote({ quote: body }, null, null, this.state.idsToDelete);
        }

        // this.props.addNewQuote(quote);

        // this.props.addQuoteManually(quote);
    };

    render() {
        return (
            <ApproveQuote
                quote={this.props.quote}
                // fieldChange={this.fieldChange}
                // description={this.state.description}
                approveQuoteClicked={this.approveQuoteClicked}
                onCancleClicked={this.onCancleClicked}
                isLoading={this.props.isLoading}
                choosePhotoClicked={this.choosePhotoClicked}
                onPortfolioFileChange={this.onPortfolioFileChange}
                savedPortfolioURls={this.state.savedPortfolioURls}
                removeImage={this.removeImage}
                portfolioImages={this.state.base64}
                idsToDelete={this.state.idsToDelete}
                removeUrls={this.removeUrls}
                reference={this.inputOpenFileRef}
                submitQuote={this.submitQuote}
                // totalSale={this.state.totalSale}
                // user={this.props.user}
            />
        );
    }
}

const mapStateToProps = (state) => {
    return {
        quote: state.adminOrSuperAdminReducer.quote,
        isLoading: state.adminOrSuperAdminReducer.isLoading,
        // user: state.authReducer.user
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getQuote: (id) => dispatch(actions.getQuote(id)),
        updateQuote: (quote_detail, setEditCommentObject, openQuoteModal, idsToDelete) =>
            dispatch(actions.updateQuote(quote_detail, setEditCommentObject, openQuoteModal, idsToDelete)),
        handelApproveOrRejectClicked: (id, body) => dispatch(actions.handelApproveOrRejectClicked(id, body)),
        getQuotePresignedUrl: (quote_detail, extentions, arrayBuffer, idsToDelete, openQuoteModal) =>
            dispatch(actions.getQuotePresignedUrl(quote_detail, extentions, arrayBuffer, idsToDelete, openQuoteModal)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ApproveQuoteContainer);
