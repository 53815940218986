import React, { useState } from 'react';
import '../../../../styles/custom.css';
import { makeFirstLetterCapital } from '../../../../utility/utility';
import { ClapSpinner } from 'react-spinners-kit';
import 'react-table-v6/react-table.css';

const HoursTypeTableList = (props) => {

    return (
        <>
            {!(props.isLoadingHoursLits) ? (
                <div className="quotes_table_content table-responsive performance_modal add_account associateUser">
                    <div className="table quotes_table user_table_contnet user_react_table">
                        <div class="col-lg-12">
                            <div class="quote_form user_quota_form ticket_form">
                                <div class="addQuote_form">
                                    <div class="form-group">
                                        <div class="form-group checkbox_cst_grid">
                                            {props.isHoursType_List && props.isHoursType_List != 0 ? props.isHoursType_List && props.isHoursType_List.map((listData, index) => {
                                                return (
                                                    <>
                                                        {
                                                            listData.active_status == 'active' ? (
                                                                <div class="checkbox_block radius_checkbox category_text_overflow">
                                                                    <div class="custom-checkbox gridStyle">
                                                                        <div class="gridInlineBlc">
                                                                            <label class="f12 grdTitle" style={{fontSize:'15px'}}>{listData?.type_id ? listData.type_id :""} - {makeFirstLetterCapital(listData?.description ? listData?.description : '-')} </label>
                                                                            <div class="icnBlc">
                                                                                <span className="mr_20 account_edit_icn" data-toggle="modal" data-target="#addHoursType" data-backdrop="static" data-keyboard="false" onClick={() => { props.editHoursTypeClicked(listData); }}><i class="fa fa-pencil" aria-hidden="true"></i></span>
                                                                                <span className="mr_20 account_edit_icn" onClick={() => { props.handleFrequentTimeTrackerHoursTypes_openDeleteModal(listData?.id) }}><i class="fa fa-trash" aria-hidden="true"></i></span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ) : listData.active_status == 'inactive' ? (
                                                                <div class="checkbox_block radius_checkbox category_text_overflow">
                                                                    <div class="custom-checkbox gridStyle">
                                                                        <div class="gridInlineBlc">
                                                                            <label class="f12 grdTitle">{listData?.type_id ? listData.type_id :""} - {makeFirstLetterCapital(listData?.description ? listData?.description : '-')}  </label>
                                                                            <div class="icnBlc">
                                                                                <span className="mr_20 account_edit_icn" data-toggle="modal" data-target="#addHoursType" data-backdrop="static" data-keyboard="false" onClick={() => { props.editHoursTypeClicked(listData); }}><i class="fa fa-pencil" aria-hidden="true"></i></span>
                                                                                <span className="mr_20 account_edit_icn" onClick={() => { props.handleFrequentTimeTrackerHoursTypes_openDeleteModal(listData?.id) }}><i class="fa fa-trash" aria-hidden="true"></i></span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ) : ''
                                                        }
                                                    </>
                                                );
                                            }) : 'No Data Found'}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="loader_btn_block spinner">
                    <ClapSpinner backColor="#89073c" size={40} frontColor="#89073c8a" />
                </div>
            )}
        </>
    );
};

export default HoursTypeTableList;