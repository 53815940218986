import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../../../../../redux/actions/index";
import cloneDeep from 'clone-deep';
import * as $ from "jquery";
import SearchLogbookHistory from "../../../../component/Accounts/LogBook Area/SearchLogbookHistory/SearchLogbookHistory";

class SearchLogbookHistoryContainer extends Component {

  timer;
  constructor(props) {

    super(props);
    this.state = {
      result: "No result",
      loading: false,
      search: { area_type_id: null, floor_id: null, area_name: null, limit: 10, page: 1, totalCount: 0, searched: false }
    };
    this.handleOnChangeSearch = this.handleOnChangeSearch.bind(this)
    this.handleSubmitSearch = this.handleSubmitSearch.bind(this)
    this.handleSubmitNewSearch = this.handleSubmitNewSearch.bind(this)
    this.handlePageChange = this.handlePageChange.bind(this)
    this.onAreaChange = this.onAreaChange.bind(this)
    this.getTheData = this.getTheData.bind(this)
    this.handleReset = this.handleReset.bind(this)

  }

  componentWillUnmount() {
    $('.login100-more').removeClass("d-none");
    $('body').removeClass("overflow-hidden");
    clearInterval(this.timer) // here...
  }

  componentDidMount = () => {
    const {limit, page} = this.state.search;
    $('.login100-more').addClass("d-none");
    $('body').addClass("overflow-hidden");
    if (window && window.innerWidth >= 768) this.props.sideBarClicked();
    //this.props.getSearchLogbookHistory({page, limit}, this.props.match.params.accountId);
    //this.props.getAreaTypes();
    this.props.getAllLogbookAreaDetails(this.props.match.params.logId);
    this.props.getSearchLogbookHistory(limit, page, this.props.match.params.logId, {});
    this.timer = setInterval(()=> this.getTheData(), 110000); // here...
  };

  getTheData () {
    this.props.getSearchLogbookHistory(this.state.search.limit, this.state.search.page, this.props.match.params.logId);
  }

  getHistory = (params) => {
    this.props.getSearchLogbookHistory(this.state.search.limit, this.state.search.page, this.props.match.params.logId).then(res=> {
      this.setState({searched: true});
    })
  };

  handleOnChangeSearch (e) {
    this.setState({searched: false});
    let search = this.state.search;
    search[e.target.name] = e.target.value ? e.target.value : null;
    this.setState({search})
  }

  handleSubmitSearch (e) {
    e.preventDefault();
    const {area_name, floor_id, area_type_id, limit, page} = this.state.search;
    this.getHistory({ page, limit, area_name, floor_id, area_type_id });
  }

  handleSubmitNewSearch (e) {
    if (this.state.search.area_name && this.state.search.area_type_id && this.state.search.floor_id) {
      const {area_name, floor_id, area_type_id, limit} = this.state.search;
      this.props.getSearchLogbookHistory({ limit, page: 1, area_name, floor_id, area_type_id }, this.props.match.params.logId);
    }
  }

  onAreaChange (e) {
    const values = cloneDeep(e)
    if(values.value) {
      values.value = values.value.trim()
    }
    this.props.getSearchLogbookHistory(this.state.search.limit, this.state.search.page, this.props.match.params.logId, values)
  }

  handlePageChange(pageNumber) {
    console.log(`active page is ${pageNumber}`);
    let search = {...this.state.search};
    search.page = pageNumber;
    this.setState({search});
    this.getHistory(search);
  }

  handleReset() {
    let search = {...this.state.search};
    search = {...search, area_type_id: null, floor_id: null, area_name: null, page: 1}
    this.setState({search});
    this.getHistory(search);
  }

  render() {
    return (
      <div>
        <SearchLogbookHistory
          {...this.props}
          handleSubmitSearch={this.handleSubmitSearch}
          handlePageChange={this.handlePageChange}
          handleSubmitNewSearch={this.handleSubmitNewSearch}
          search={this.state.search}
          searched={this.state.searched}
          total_no_of_pages={this.props.total_no_of_pages}
          handleOnChangeSearch={this.handleOnChangeSearch}
          handleReset={this.handleReset}
          onAreaChange={this.onAreaChange}
      />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.authReducer.user,
    isLoading: state.adminOrSuperAdminReducer.isLoading,

    // getAllLogbookAreaDetails
    floorList: state.adminOrSuperAdminReducer.accountFloorList,
    areaTypes: state.adminOrSuperAdminReducer.accountAreaTypes,
    logbookNames: state.adminOrSuperAdminReducer.logbookNames,

    // getSearchLogbookHistory
    searchLogHistory: state.adminOrSuperAdminReducer.searchLogHistory,
    areaSearchResult: state.adminOrSuperAdminReducer.areaSearchResult,
    total_no_of_pages: state.adminOrSuperAdminReducer.total_no_of_pages,
    timezone: state.adminOrSuperAdminReducer.timezone
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // using actions
    getAllLogbookAreaDetails: (id) => dispatch(actions.getAllLoghistoryAreaDetails(id)),
    getSearchLogbookHistory: (limit, page, id, values) => dispatch(actions.searchLogHistory(limit, page, id, values)),

    // notInUse actions
    sideBarClicked: () => dispatch(actions.sideBarClicked()),
    getAllHistory: (params, id) => dispatch(actions.getAllHistory(params, id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchLogbookHistoryContainer);