import React from 'react';
import { Input } from '../../UI/InputElement/InputElement';
import { connect, useSelector } from 'react-redux';
import { reduxForm, formValueSelector } from 'redux-form';
import isEmpty from '../../../../utility/IsEmptyValidator';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { removeDublicatesArrayObjects } from '../../../../utility/utility';
const cloneDeep = require('clone-deep');


let AssociateAccountModal = (props) => {
    const { handleSubmit, userId, pristine, reset, submitting, error } = props;
    const isFilterAccountUsersPending = useSelector(state => state.adminOrSuperAdminReducer.isFilterAccountUsersPending);

    let selected_accounts = [];
   let result = [];
   let selected_account = [];
for (var i = 0; i < props.accountUsersList.length; i++) {
  result.push(props.accountUsersList[i].email);
}
    const users = props.filterAccountUsers.filter(user => !result.includes(user.email))
.map(user =>{
        return{
            value: user.id,
            label: (user.username ? user.username : user.email)
        }
    })
    selected_account = removeDublicatesArrayObjects(cloneDeep(selected_account));
  console.log("testtesttest",users)
  if (props.initialValues.email && props.initialValues.email.length > 0) {
    props.filterAccountUsers.map(user => {
        props.initialValues.email.map(email => {
            if (user.id === parseInt(email.value)) {
                if (user.accounts) {
                    selected_accounts = cloneDeep(user.accounts.concat(selected_accounts));
                }
            }
        })
    })
}
     
    selected_accounts = removeDublicatesArrayObjects(cloneDeep(selected_accounts));
     
    const noOptionsMessage = (a, b) => {
        return 'User not found';
    }

    const closeAccountModalClicked = () => {

        props.closeAccountModalClicked();
    }

    return (
        <>
            <div className="modal fade performance_modal add_account associateUser lookup_user" id="associateUser" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h2 className="md_title" id="exampleModalLongTitle" data-test="user_loopup_title">Lookup a user</h2>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" >
                                <span aria-hidden="true"><img src="/images/icons/icn_cancel_white.png" alt="Icon" onClick={closeAccountModalClicked} /></span>
                            </button>
                        </div>
                        <form onSubmit={handleSubmit}>
                            <div className="modal-body">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div className="quote_form ticket_form">
                                            <div className="addQuote_form">

                                                <div className="form-group" data-test="associate_user_select">
                                                    {props.filterAccountUsers.length === 0 || isFilterAccountUsersPending ?
                                                        <Skeleton height={30} width={450} />
                                                        :
                                                        <>
                                                            <label className="form_title">Email Address / Username</label>
                                                            <Input instanceId="associate_user_select" isMulti={true} name="email" noOptionsMessage={noOptionsMessage} type="inputSelect" options={users} 
                                                            />
                                                            
                                                        </>
                                                    }

                                                    {/* <input className="form-control input-modifier" placeholder="Enter email address" /> */}
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer border-0 text-center">
                                <div className="btn_block">
                                    {props.filterAccountUsers.length === 0 ?
                                        <Skeleton height={30} width={100} />
                                        :
                                        <button data-test="modal_submit_btn" onClick={props.getCurrentAccount} disabled={props.initialValues.email && props.initialValues.email.length > 0 ? false : true} type="submit" className="btn cst_btn btn_danger">Submit</button>
                                    }
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className="modal fade performance_modal add_account associateUser associateAccount" id="associateAccount" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h2 className="md_title" id="exampleModalLongTitle">Associate Job</h2>
                            <button type="button" data-dismiss="modal" className="close" aria-label="Close" onClick={closeAccountModalClicked}>
                                <span aria-hidden="true"><img src="/images/icons/icn_cancel_white.png" alt="Icon" /></span>
                            </button>
                        </div>
                        <form onSubmit={handleSubmit}>
                            <div className="modal-body">
                                <span style={{ color: '#DD2726', fontSize: '13px' }}>{props.accountErrorMessage}</span>
                                <div className="quote_form ticket_form">
                                    <div className="selec_style">
                                        <div className="items-collection">
                                            <div className="select_style_list select_style_list1">
                                                {props.account ?
                                                    <span className="items mr-3">
                                                        <span className="btn-group itemcontent">
                                                            {/* <label className="select_style_links">
                                                             <span className="itemcontent"> */}
                                                            <Input type="checkbox" h5Text={props.account.name} checkbox="selectStyle" name={`current_account[${props.account.id}]`} autoComplete="off" className="checkbox_modifier" />
                                                            {/* <h5 className="small_txt">Fox</h5> */}
                                                            {/* </span>
                                                             </label> */}
                                                        </span>
                                                    </span>
                                                    : <Skeleton height={30} width={100} />}

                                                {selected_accounts ? selected_accounts.map(sc => {
                                                    return (
                                                        <span className="items mr-3">
                                                            <span className="btn-group itemcontent">
                                                                <Input type="checkbox" h5Text={sc.name} checkbox="selectStyle" name={`selected_accounts[${sc.id}]`} autoComplete="off" className="checkbox_modifier" />
                                                            </span>
                                                        </span>
                                                    )
                                                }) : null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="modal-footer text-center">
                                <div className="btn_block">
                                    {props.account ?
                                        <>
                                            <button data-test="submit_button" type="submit" className={props.popupLoading ? "btn cst_btn btn_danger btn-wait" : "btn cst_btn btn_danger"}>{props.popupLoading ? "" : "Submit"}</button>
                                            <button type="button" data-dismiss="modal" className={"btn cst_btn btn_secondary_outline"} onClick={closeAccountModalClicked}>Cancel</button>
                                        </>
                                        : <> <Skeleton height={30} width={100} /> <Skeleton height={30} width={100} /> </>}

                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    let current_account = [];
    let selected_accounts = [];
    let email = state.form.associateAccountModal ? state.form.associateAccountModal.values.email ? state.form.associateAccountModal.values.email : null : null;
    let userIds = [];
    state.form.associateAccountModal && state.form.associateAccountModal.values.email &&
        state.form.associateAccountModal.values.email.map(e => {
            userIds.push(e.value);
        });

    if (!isEmpty(state.adminOrSuperAdminReducer.account)) {
        current_account[`${state.adminOrSuperAdminReducer.account.id}`] = true
    }

    if (userIds.length > 0 && state.adminOrSuperAdminReducer.filterAccountUsers) {
        userIds.map(userId => {
            state.adminOrSuperAdminReducer.filterAccountUsers.map(user => {
                if (user.id === parseInt(userId) && user.accounts) {
                    user.accounts.map(sc => {
                        selected_accounts[`${sc.id}`] = true
                    })
                }
            })
        })
    }
    return {
        initialValues: { email, current_account, selected_accounts }
    }
}

AssociateAccountModal = reduxForm({
    form: 'associateAccountModal',
    enableReinitialize: true,
    // validate
})(AssociateAccountModal);

const selector = formValueSelector('associateAccountModal') // <-- same as form name
AssociateAccountModal = connect(state => {

    const userId = selector(state, 'email')
    return {
        userId: `${userId || ''}`
    }
})(AssociateAccountModal)

AssociateAccountModal = connect(mapStateToProps)(AssociateAccountModal);

export default AssociateAccountModal;