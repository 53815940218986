import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import NewLocation from './Location/NewLocation';
import NewLocationAssociation from './LocationAssociation/NewLocationAssociation';
import LocationContainer from '../../../container/InventoryContainer/LocationContainer/LocationContainer';
import LocationAssociationContainer from '../../../container/InventoryContainer/LocationContainer/LocationAssociationContainer';

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper
  }
}));

const LocationIndex = (props) => {

  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const [location, setLocation] = React.useState(true);
  const [locationAssociation, setLocationAssociation] = React.useState(false);

  const handleChange = (event, newValue) => {
    if (newValue === 0) {
      setLocation(true);
      setLocationAssociation(false);
    } else if (newValue === 1) {
      setLocation(false);
      setLocationAssociation(true);
    } 
    setValue(newValue);
  }

  const handleChangeIndex = (index) => {
    setValue(index);
  }


  return (
    <>
      <main className="app-content wraper_content approve_screen approve_Quotes_sec">
        <div className="inspection_content_sec appcontent_Inner">
          <div className={classes.root}>
            <AppBar position="static" color="default">
              <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"
                aria-label="full width tabs example"
              >
                <Tab label={"Location(" + props.location_count+ ")" } />
                <Tab label={"Location Association(" + props.location_association_count+ ")" } />
              </Tabs>
            </AppBar>
            <SwipeableViews
              axis={theme.direction === "rtl" ? "x-reverse" : "x"}
              index={value}
              onChangeIndex={handleChangeIndex}
            >
              <TabPanel value={value} index={0} dir={theme.direction}>
                {location ? <LocationContainer /> : null}
              </TabPanel>
              <TabPanel value={value} index={1} dir={theme.direction}>
                {locationAssociation ? <LocationAssociationContainer /> : null}
              </TabPanel>
            </SwipeableViews>
          </div>
          {(location && props.isOpenModel) ?
            <NewLocation
            {...props}
              onSubmit={props.handelLocationSubmit}
              closePopup={props.closePopup}
              isLoading={props.isLoading}
              isEdit={props.isEdit}
              emptyObjects={props.emptyObjects}
            /> : null}
           
           {(locationAssociation && props.isOpenModel) ?
            <NewLocationAssociation
              {...props}
              onSubmit={props.handelLocationAssociationSubmit}
              closePopup={props.closePopup}
              isLoading={props.isLoading}
              isEdit={props.isEdit}
              emptyObjects={props.emptyObjects}
              deletedObject={props.deletedObject}
            /> : null}

        </div>
      </main>

    </>
  )
}

export default LocationIndex;