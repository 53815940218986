import React, { useEffect, useState } from 'react';
import ReactTable from 'react-table-v6';
import '../../../styles/custom.css';
import { makeFirstLetterCapital, CustomNoDataComponent, getCountOfLineItemAttributes, convertDateAndTimeTimezone } from '../../../utility/utility';
import { MagicSpinner, PushSpinner, SwishSpinner, WaveSpinner, ClapSpinner } from 'react-spinners-kit';
import 'react-table-v6/react-table.css';
import { FaArrowDown, FaArrowUp } from "react-icons/fa";
import { userRoles } from '../../../utility/constants/constants';
import { useSelector } from 'react-redux';
import cloneDeep from 'clone-deep';
var moment = require('moment');

const InspectionFormTable = (props) => {
//   const [pageSize, setPageSize] = useState(10);
  const No_of_pages = useSelector(state => state.adminOrSuperAdminReducer.insform_page);
  const No_of_rows = useSelector(state => state.adminOrSuperAdminReducer.insform_no_of_rows);
  const isInspectionForm = useSelector(state => state.adminOrSuperAdminReducer.isInspectionForm);
  const DateGenerate = useSelector(state => state.adminOrSuperAdminReducer.dataGenerated);
  const SearchValue = cloneDeep(useSelector(state => state.form.searchInspectionForm.values));
  const SearchOrResetInsFormClicked = useSelector(state => state.adminOrSuperAdminReducer.SearchOrResetInsFormClicked);
  const inspectionFormPage = useSelector(state => state.adminOrSuperAdminReducer.inspectionFormCurPage);
  const ins_form_rows = useSelector(state => state.adminOrSuperAdminReducer.ins_form_rows);

    // const account = JSON.parse(localStorage.getItem('user'));
    // const accountTimezone = account.account_timezone;
    const accountTimezone =props?.user?.timezone
    const data = props.inspectionFormsList.map(inspectionForm => {
        debugger;
        return {
            name: inspectionForm?.inspection_form?.name  ? inspectionForm?.inspection_form?.name :"" ,
            type: inspectionForm?.inspection_form?.private ? "Private" : "Public",
            criteria: inspectionForm.inspection_form && inspectionForm?.inspection_form?.criteria && makeFirstLetterCapital(inspectionForm.inspection_form.criteria && inspectionForm.inspection_form?.criteria?.name),
            no_of_lineItems: inspectionForm?.inspection_form ? getCountOfLineItemAttributes(inspectionForm.inspection_form) :"-",
            created_at: inspectionForm?.inspection_form?.created_at ? convertDateAndTimeTimezone(inspectionForm.inspection_form.created_at, accountTimezone) :"-",
            updated_at: inspectionForm?.inspection_form?.updated_at ? convertDateAndTimeTimezone(inspectionForm.inspection_form.updated_at, accountTimezone) :"-",
            actions:
                <>
                    <span className="mr_20 account_edit_icn" onClick={() => props.editInspectionFormClicked(inspectionForm)}><i class="fa fa-pencil" aria-hidden="true"></i>
                    </span>
                    <span className="mr_20 account_edit_icn" onClick={() => props.handleClickDeleteModel(inspectionForm)}><i class="fa fa-trash" aria-hidden="true"></i>
                    </span>
                </>
        }
    })


    const columns = [
        {
            Header: <><span>Inspection Name</span><FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
            accessor: 'name', // String-based value accessors!,
            show: props.inspectionFormsList.length !== 0,

        }, {
            Header: <><span>Inspection Type</span><FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
            accessor: 'type',
            show: props.inspectionFormsList.length !== 0,

        }, {
            Header: <><span>Criteria</span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
            accessor: 'criteria',
            show: props.inspectionFormsList.length !== 0,
        }, 
         {
            Header: <><span>No Of Line Items</span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
            accessor: 'no_of_lineItems',
            show: props.inspectionFormsList.length !== 0,
        }, {
            Header: <><span>Created At</span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
            accessor: 'created_at',
            show: props.inspectionFormsList.length !== 0,
        },
        {
            Header: <><span>Updated At</span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
            accessor: 'updated_at',
            show: props.inspectionFormsList.length !== 0,
        }, {
            Header: ' ',
            accessor: 'actions',

            // show: false,
        }
    ]


    const [tablePage, setTablePage] = useState(0)
    const tablePageNew =  React.useRef()
    if(inspectionFormPage !== tablePageNew.current) {
        tablePageNew.current = inspectionFormPage;
      }
    const setTablePageFn = (page) => {
        setTablePage(page)
        props.inspectionFormPageClicked(page)
        tablePageNew.current = page;
    }

    const fetchData = (state, instance) => {

        if (SearchOrResetInsFormClicked) {
            // dispatch(actions.resetSearchOrResetValue())
        } else {
            if (!DateGenerate && !props.isInspectionForm) {
                state.page = 2;
                let page = tablePageNew.current >= 0 ? tablePageNew.current + 1 : inspectionFormPage + 1;
                const insform_no_of_rows = state.pageSize;
                if(ins_form_rows != insform_no_of_rows) {
                    page = 1;
                    setTablePageFn(0)
                }
                const sorted = state.sorted;
                const filtered = state.filtered;
                props.getInspectionFormList(SearchValue, insform_no_of_rows, page, sorted, filtered);
            }
        }
    }
        

    return (
        SearchOrResetInsFormClicked && props.isInspectionForm ?
            <div  >
                <ClapSpinner backColor="#89073c" size={40} frontColor="#89073c8a" />
            </div>
            :
            <ReactTable
                data={data}
                loading={props.isInspectionForm}
                minRows={0}
                defaultPageSize={No_of_rows}
                pages={No_of_pages}
                gotoPage={inspectionFormPage}
                page = { inspectionFormPage }
                onPageChange={(page) => {setTablePageFn(page)}}
                // onPageSizeChange={props.onUserPageSizeChange}
                manual
                onFetchData={fetchData}
                showPaginationTop={true}
                showPagination={props.inspectionFormsList.length !== 0}
                columns={columns}
                // style={{marginTop: '10px'}}
                NoDataComponent={() => CustomNoDataComponent(props.isInspectionForm, 'No InspectionForms Found')}
                LoadingComponent={() =>
                    isInspectionForm     ?
                        <div style={{
                            display: "block",
                            position: "absolute",
                            left: 0,
                            height: '100%',
                            right: 0,
                            background: "rgba(255,255,255,0.8)",
                            transition: "all .3s ease",
                            top: 0,
                            bottom: 0,
                            textAlign: "center"
                        }}
                            className="loader_btn_block">
                            <ClapSpinner backColor="#89073c" size={40} frontColor="#89073c8a" />
                        </div> : null}
                getTheadThProps={(state, rowInfo, column, instance) => {
                    // console.log(column);
                    return {
                        style: {
                            borderTop: 'none',
                            borderBottom: 'none',
                            borderRight: 'none',
                            textAlign: 'center',
                            color: '#566975',
                            fontSize: '14px',
                            fontWeight: '600',
                            whiteSpace: 'nowrap',
                            float: 'left',
                        }
                    }
                }
                }
                getTrProps={(state, rowInfo, column, instance) => {
                    // onclick = () => userDetail(rowInfo ? rowInfo.row.user : rowInfo);
                    console.log(column);
                    return {
                        className: 'react-table-tr-element',
                        style: {
                            border: '1px solid #c7cdd1',
                            transition: '.3s ease-in-out',
                            width: '100%',
                            // borderRadius: '4px',
                            // height: '80px',
                            marginTop: '5px',
                            float: 'left'
                        }
                    }

                }}
                getTdProps={(state, rowInfo, column, instance) => {

                    if (column.id == 'image') {
                        return {
                            style: {
                                marginTop: '0px'
                            }
                        }
                    } else {
                        return {
                            className: 'react-td-element',
                        }
                        //  {
                        //   style: {
                        //     borderTop: 'none',
                        //     verticalAlign: 'middle',
                        //     fontSize: '14px',
                        //     color: '#2b3034',
                        //     marginTop: '10px !important',
                        //     textAlign: 'center',
                        //   }
                        // }
                    }
                }
                }
                // getPaginationProps={() => {
                //     return {
                //         style: {
                //             marginTop: '50px',
                //             border: 'none',
                //             boxShadow: 'none'
                //         }
                //     }
                // }
                // }
                getProps={() => {
                    return {
                        style: {
                            border: 'none'
                        }
                    }
                }}
            />
    )
}

export default InspectionFormTable;