import React, { Component } from 'react';
import { routes } from '../../../utility/constants/constants';
import InspectionForm from '../../component/InspectionForm/InspectionForm';
import * as actions from '../../../redux/actions/index';
import { connect } from 'react-redux';
import { reset } from 'redux-form';
import { getUser } from '../../../utility/utility';
import storage from '../../../utility/storage';
import { animateScroll as scroll} from 'react-scroll'

class InspectionFormContainer extends Component {

    newInspectionFormClicked = () => {
        this.props.history.push(routes.NEW_INSPECTION_FORMS);
    }

    componentDidMount() {

        // if (!this.props.inspectionFormsList) {
        //     this.props.getInspectionForms();
        // }
    scroll.scrollToTop();
    }

    handleClickDeleteModel = (inspectionForm) => {

        this.props.handleClickDeleteModel(inspectionForm);
    }

    deleteInspectionForm = (id) => {
        this.props.deleteInspectionForm(id);
    }

    handleDeleteClose = () => {
        this.props.handleDeleteClose();
    }

    editInspectionFormClicked = (rating) => {
        this.props.editInspectionFormClicked(rating);
    }
    componentDidUpdate(prevProps, prevState) {
        const user = storage.get('user');
        if(user) {
        if(prevProps.user.selected_mode !== user.selected_mode) {
            this.props.inspectionFormPageClicked(0)
        }
    }
    }
    resetClicked = () => {
        this.props.reset('searchInspectionForm');
        // this.props.getAccounts();
        this.props.inspectionFormPageClicked(0)
        this.props.getInspectionFormList('', this.props.insform_no_of_rows, 1, [], '');
    }
    searchInspectionClicked = (data) => {
        if(data.name) {
            data.name = data.name.trim()
        }
        if(data.name !== '') {
        this.props.inspectionFormPageClicked(0)
            this.props.getInspectionFormList(data, this.props.insform_no_of_rows, 1, [], '');
        }
    }

    render() {
        return (
            <InspectionForm
            {...this.props}
                newInspectionFormClicked={this.newInspectionFormClicked}
                onSubmit={this.searchInspectionClicked}
                inspectionFormsList={this.props.inspectionFormsList ? this.props.inspectionFormsList : []}
                isLoading={this.props.isLoading}
                isInspectionForm={this.props.isInspectionForm}
                resetClicked={this.resetClicked}
                editInspectionFormClicked={this.editInspectionFormClicked}
                handleClickDeleteModel={this.props.handleClickDeleteModel}
                handleDeleteClose={this.handleDeleteClose}
                deleteInspectionForm={this.deleteInspectionForm}
                openDeleteModel={this.props.openDeleteModel}
                deleteObject={this.props.deleteObject}
                getInspectionFormList={this.props.getInspectionFormList}
            />
        )
    }
}


const mapStateToProps = (state) => {
    return {
        user: state.authReducer.user,
        inspectionFormsList: state.adminOrSuperAdminReducer.searchedInspectionFormsList ? state.adminOrSuperAdminReducer.searchedInspectionFormsList : state.adminOrSuperAdminReducer.inspectionFormTableList,
        isLoading: state.adminOrSuperAdminReducer.isLoading,
        isInspectionForm: state.adminOrSuperAdminReducer.isInspectionForm,
        deleteObject: state.adminOrSuperAdminReducer.deleteObject,
        openDeleteModel: state.adminOrSuperAdminReducer.openDeleteModel,
        insform_no_of_rows: state.adminOrSuperAdminReducer.insform_no_of_rows,
        insform_page: state.adminOrSuperAdminReducer.insform_page,
        inspectionFormCurPage: state.adminOrSuperAdminReducer.inspectionFormCurPage,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        handleDeleteClose: () => dispatch(actions.handleDeleteClose()),
        deleteInspectionForm: (id) => dispatch(actions.deleteInspectionForm(id)),
        getInspectionForms: () => dispatch(actions.getInspectionForms()),
        openPopup: () => dispatch(actions.openPopup()),
        reset: (searchInspectionForm) => dispatch(reset(searchInspectionForm)),  // requires form name
        editInspectionFormClicked: (inspcetionForm) => dispatch(actions.editInspectionFormClicked(inspcetionForm)),
        handleClickDeleteModel: (inspectionForm) => dispatch(actions.handleClickDeleteModel(inspectionForm)),
        getInspectionFormList: (searchValues, insform_no_of_rows, page, sorted, filtered) => dispatch(actions.getInspectionFormList(searchValues, insform_no_of_rows, page, sorted, filtered)),
        inspectionFormPageClicked: (page) => dispatch(actions.inspectionFormPageClicked(page)),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(InspectionFormContainer);