import React, {useState} from "react";

import moment from "moment";
import Pagination from "react-js-pagination";
import Unavailable from "./UnavailableModel";
import {convertDateAndTimeTimezonelll} from "../../../utility/utility";
import "./pagination.css";
const SearchResult = ({searchHistory, user,...props}) => {
  const [currentSchedule, setCurrentSchedule] = useState({});
  return(
      <div className="appcontent_Inner">
        {searchHistory ? <Unavailable {...props} currentSchedule={currentSchedule} setCurrentSchedule={setCurrentSchedule}></Unavailable> : ""}
        <div className="history_block min-height-75">
          <div className="add_content_block">
            <h2 className="h3_title"><span className="title_block">History
              {
                searchHistory && searchHistory.frontline_tasks && (props.search.area_name && props.search.area_type_id && props.search.floor_id && props.searched) && <button className="new_search-desktop btn btn_danger float-right" onClick={() => props.history.push(`/account/${props.match.params.accountId}/schedule`)}>View Schedule</button>
              }
            </span> <span className="mob_border_view"><span className="cst_border"></span></span></h2>
              <ol className="activity-feed">
                {
                  searchHistory && searchHistory.frontline_tasks ? Object.keys(searchHistory.frontline_tasks).map((key, index) => {
                    return searchHistory.frontline_tasks[key]["frontline_tasks"].map((task, index) => {
                      return (<li key={index} className="feed-item timeline_item">
                        {
                          (task.status === "not_available" && user && ["superadmin", "admin"].includes(user.role)) ?
                              <span className="list_content">
                                <span className="font-weight-semibold">{task.area.name}</span>{` was `}
                                <strong className="sm_title text_danger">{`In-use `}</strong>{`at `}
                                <span className="font-weight-semibold">
                                  {
                                    task.scanned_at ?   convertDateAndTimeTimezonelll(task.scanned_at, searchHistory.timezone) :  convertDateAndTimeTimezonelll(task.captured_time, searchHistory.timezone)
                                  }
                                </span>
                                <span data-toggle="modal" onClick={() => setCurrentSchedule(task)} data-target="#setUnavailable" className="info_icon">
                                  {" "}<img src="/images/icons/info.png" alt="Image" />
                                </span>
                              </span>
                              :
                              <span className="list_content">
                                <span className="font-weight-semibold">{task.area.name}</span>
                                {task.status === "not_cleaned" ? ` not cleaned on ` : ` cleaned on `}
                                <span className="font-weight-semibold">
                                  {
                                    task.scanned_at ? convertDateAndTimeTimezonelll(task.scanned_at, searchHistory.timezone) :  convertDateAndTimeTimezonelll(task.captured_time, searchHistory.timezone)
                                  }
                                </span>
                              </span>
                        }
                      </li>)
                    })
                  }) : "No records found"
                }
              </ol>
              <div className="row">
                <nav aria-label="Page navigation example" className="history-pagination col-12">

                {
                  searchHistory &&  searchHistory.frontline_tasks && props.total_no_of_pages ? <Pagination
                      activePage={props.search.page}
                      className="float-right"
                      itemsCountPerPage={props.search.size}
                      totalItemsCount={props.search.size * props.total_no_of_pages}
                      pageRangeDisplayed={5}
                      onChange={props.handlePageChange}
                      /> : ""
                }
                  </nav>
              </div>
            </div>
          </div>
        </div>
  )
};

export default SearchResult;