import React from "react";

const Search = (props) => {
  const {area_name, area_type_id, floor_id} = props.search;
  return(
      <div className={`theme_sub_header ${props.user ? "pt-5 mt-5" : ""}`}>
        <form className="form-inline" onSubmit={props.handleSubmitSearch}>
          <label className="label_modifier">Area Name</label>
          <select className="custom-select input-modifier" name="area_name" onChange={props.handleOnChangeSearch}>
            <option value="" selected={!area_name} >All</option>
            {props.areaNames.map((name, index) => <option key={index} value={name} selected={area_name === name}>{name}</option>)}
          </select>
          <label className="label_modifier">Floor</label>
          <select className="custom-select input-modifier" name="floor_id"  onChange={props.handleOnChangeSearch}>
            <option value="" selected={!floor_id}  >All</option>
            {props.floorList.map((floor, index) => <option key={index} value={floor.id} selected={floor_id === floor.id}>{floor.name}</option>)}
          </select>
          <label className="label_modifier">Area Type</label>
          <select className="custom-select input-modifier" name="area_type_id"  onChange={props.handleOnChangeSearch}>
            <option value="" selected={!area_type_id}  >All</option>
            {props.areaTypes.map((areaType, index) => <option key={index} value={areaType.id} selected={areaType.id === area_type_id}>{areaType.name}</option>)}
          </select>
          <div className="search_btn">
            <button type="submit" className="mr-1 btn btn_danger">Search</button>
            <button type="button" className="btn btn_danger" onClick={props.handleReset}>Reset</button>
          </div>
        </form>
      </div>
  )
};

export default Search;