import React, { Component } from 'react';
import SchedulesTaskMyTicketLayout from '../../../../component/TimeTrackerModuleComponent/ScheduledTaskComponent/ScheduledTaskMyTicketComponent/SchedulesTaskMyTicketLayout';
import { routes } from '../../../../../utility/constants/constants';
import storage from '../../../../../utility/storage';
import * as actions from '../../../../../redux/actions/index';
import { connect } from 'react-redux';
import { reset } from 'redux-form';
import { animateScroll as scroll } from 'react-scroll'
import { convertDateToDifferentTZ } from '../../../../../utility/utility';
import { tzUTCDateConverterTool_V2 } from '../../../../../utility/TimezoneOperations/timezoneUtility';
var moment = require('moment');
const cloneDeep = require('clone-deep');

class ScheduledTaskMyTicketContainer extends Component {
    constructor(props) {
        super(props);

    }

    componentDidMount() {
        scroll.scrollToTop();
    }

    componentDidUpdate(prevProps, prevState) {
        const user = storage.get('user');
        if (user) {
            if (prevProps.user.selected_mode !== user.selected_mode) {
                this.props.flUserSTMyTicketsPageClicked(0)
            }
        }
    }

    resetClicked = () => {
        const page = 1
        const limit = this.props.stMyTicketsList_no_of_rows
        const accountId = this.props.location.state ?  this.props.location.state.jobId : null
        this.props.reset('searchFLUserMyTicketsListForm');
        this.props.flUserSTMyTicketsPageClicked(0)
        this.props.getFlUserScheduledTaskTickets({ page, limit, accountId });
    }

    searchFLUserMyTicketsListClicked = (value) => {
        const values = cloneDeep(value)
        let isFuture = null
        let isCurrent = null
        let isPast = null
        const accountId = this.props.location.state ?  this.props.location.state.jobId : null
        const page = 1
        const limit = this.props.stMyTicketsList_no_of_rows
        const status=value?.status?.value ?? ""
        if (values?.scheduledTicketStatus) {
            if (values?.scheduledTicketStatus?.value === 'today') {
                isCurrent = true
            }
            if (values?.scheduledTicketStatus?.value === 'upcoming') {
                isFuture = true
            }
            if (values?.scheduledTicketStatus?.value === 'past') {
                isPast = true
            }
        }

        let newDate = new Date()
        let currentDate = tzUTCDateConverterTool_V2(newDate, 'to');

        this.props.getFlUserScheduledTaskTickets({ page, limit, isFuture, isCurrent, isPast, currentDate, accountId, status}, values);

    }

    componentWillUnmount() {

    }

    handlePreviewMyTicket = (data) => {
        this.props.history.push(`/scheduled-tasks/ticket/${data.ticketId}/preview-scheduled-task/${data.subTicketId}`);
    }

    render() {
        return (
            <>

                <SchedulesTaskMyTicketLayout
                    {...this.props}
                    onSubmit={this.searchFLUserMyTicketsListClicked}
                    resetClicked={this.resetClicked}
                    handlePreviewMyTicket={this.handlePreviewMyTicket}
                />

            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.authReducer.user,
        isLoading: state.TimeTrackerModReducer.isLoading,
        isSTMyTicketsList: state.TimeTrackerModReducer.isSTMyTicketsList,
        stMyTicketsList: state.TimeTrackerModReducer.stMyTicketsList,
        stMyTicketsList_no_of_rows: state.TimeTrackerModReducer.stMyTicketsList_no_of_rows,
        stMyTicketsList_curPage: state.TimeTrackerModReducer.stMyTicketsList_curPage,
        stMyTicketsList_total_pages: state.TimeTrackerModReducer.stMyTicketsList_total_pages,

    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        reset: (searchFLUserMyTicketsListForm) => dispatch(reset(searchFLUserMyTicketsListForm)),  // requires form name
        flUserSTMyTicketsPageClicked: (page) => dispatch(actions.flUserSTMyTicketsPageClicked(page)),
        getFlUserScheduledTaskTickets: (params, values) => dispatch(actions.getFlUserScheduledTaskTickets(params, values)),
        // resetObjects: () => dispatch(actions.resetObjects()),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(ScheduledTaskMyTicketContainer);