import React from 'react';
import { Input } from '../UI/InputElement/InputElement';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
//import { FrontlineListSearchValidator as validate } from '../../../../utility/validator/validator';
//import AssociateAccountModal from './associateAccountModal';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import FrontlineListTable from './FrontlineListComponents/FrontlineListTable';

import ImportCsv from "./FrontlineListComponents/importCsv";
import { userRoles } from '../../../utility/constants/constants';

let FrontlineList = (props) => {

  const { handleSubmit, pristine, reset, submitting, error } = props;
  return (
    <>
      <main className="app-content wraper_content inspection_wraper">
        <div className="tab_header search_filter_cont inspection_tab_content appcontent_inner frontlines_filter mb-2">
          <form onSubmit={handleSubmit} >
            <div className="row">
              <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
                <div className="app-search">
                  <Input className="fil_search_input input-modifier" name="name" type="search" placeholder="Search By Name" dataTest="frontline_name_filter" />
                  <button className="app-search__button">
                    <i class="fa fa-search" aria-hidden="true"></i>
                  </button>
                </div>
              </div>
              <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
                <div className="app-search">
                  <Input className="fil_search_input input-modifier" name="id" type="search" placeholder="Search By Id" dataTest="frontline_id_filter" />
                  <button className="app-search__button">
                    <i class="fa fa-search" aria-hidden="true"></i>
                  </button>
                </div>
              </div>
              <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
                <div className="frontline_btn">
                  <button data-test="filter_go_btn" style={{ minWidth: '90px' }} className={"btn cst_btn btn_danger mr-2"} id="submit" type="submit">{"Go"} </button>
                  <button data-test="filter_reset_btn" style={{ minWidth: '90px' }} onClick={props.resetClicked} className={"btn cst_btn btn-outline-secondary"} id="reset" type="reset">{"Reset"} </button>
                </div>
              </div>
              {props && props.user && props.user.role && (props.user.role == userRoles.SUPER_ADMIN) ? (
                <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12 text-right quotes_btn_wrap">
                  <button type="button" className="btn btn-secondary cst_btn mr-1 mb-1 min-width-btn" onClick={() => props.history.push("/frontlines/new")} data-backdrop="static" data-keyboard="false" data-toggle="modal" data-target="#associateUser" data-test="add_new_frontline"><i className="fa fa-plus icn_plus"></i> Add New Frontline Emp</button>
                </div>
              ) : null}
            </div>
          </form>
        </div>
        <ImportCsv {...props} />

        <section className="account_sec user_screen inspection_tab_content">

          <div className="quotes_table_content table-responsive">

            <div className="table quotes_table user_table_contnet user_react_table account_table">
              <FrontlineListTable
                {...props}
                accountId={props.accountId}

                isLoading={props.isLoading}
                onFrontlineListPageSizeChange={props.onFrontlineListPageSizeChange}

                editFrontlineListClicked={props.editFrontlineListClicked}
                openDeleteModel={props.openDeleteModel}
                handleClickDeleteModel={props.handleClickDeleteModel} />
            </div>

          </div>
        </section>
      </main>

      <Dialog
        open={props.openDeleteModel}
        onClose={props.handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title" style={{ fontSize: '13', color: '#06080f' }}>
          <h3 style={{ color: '#ab385e' }}>Are you sure?</h3>
        </DialogTitle>

        <DialogActions>
          <button style={{ minHeight: '36px', minWidth: '80px' }} onClick={() => props.deleteFrontline(props.deleteObject.id)} disabled={props.isLoading} className={props.isLoading ? "btn cst_btn btn_danger mr-2 btn-wait" : "btn cst_btn btn_danger mr-2"} id="delete" type="delete">{props.isLoading ? "" : "Delete"}</button>
          <button style={{ minHeight: '36px', minWidth: '80px' }} onClick={props.handleDeleteClose} className={"btn cst_btn btn-outline-secondary"} id="cancel" type="cancel">Cancel</button>
        </DialogActions>
      </Dialog>

    </>
  )
}

const mapStateToProps = (state) => {
  let email = "";

  return { initialValues: { email } }
}

FrontlineList = reduxForm({
  form: 'searchFrontlineListForm',
  //validate,
  enableReinitialize: true
})(FrontlineList);

FrontlineList = connect(mapStateToProps)(FrontlineList)

export default FrontlineList;