import React, { useEffect, useState } from 'react';
import ReactTable from 'react-table-v6';
import '../../../../styles/custom.css';
import { TicketStatus, userRoles, TimeZones, routes } from '../../../../utility/constants/constants';
import { CustomNoDataComponent,
    trimString,
    makeFirstLetterCapital,
    convertDateTimezone,
    convertDateAndTimeTimezone, getUser, getWeekStartAndEndDay, convertTicketsDataToCsvType, convertDateToDifferentTZ } from '../../../../utility/utility';
import { FaArrowDown, FaArrowUp } from "react-icons/fa";
import ReactTooltip from 'react-tooltip';
import * as actions from '../../../../redux/actions/index';
import { useSelector, useDispatch } from 'react-redux';
import { MagicSpinner, PushSpinner, SwishSpinner, WaveSpinner, ClapSpinner } from 'react-spinners-kit';
import 'react-table-v6/react-table.css';
import storage from '../../../../utility/storage';
import cloneDeep from 'clone-deep';
import { tzDateConverterTool_V1 } from '../../../../utility/TimezoneOperations/timezoneUtility';
var moment = require('moment');

let ScheduledTaskTable = (props) => {

    const dispatch = useDispatch();
    const SearchValue = useSelector(state => state.form.searchScheduledTask && state.form.searchScheduledTask.values ? state.form.searchScheduledTask.values : {});
    const DateGenerate = useSelector(state => state.TimeTrackerModReducer.dataGenerated);
    const values = useSelector(state => state.TimeTrackerModReducer.scheduledTask_searchValues);
    const No_of_pages = useSelector(state => state.TimeTrackerModReducer.scheduledTaskList_total_pages);
    const No_of_rows = useSelector(state => state.TimeTrackerModReducer.scheduledTaskList_no_of_rows);
    const isScheduledTaskList = useSelector(state => state.TimeTrackerModReducer.isScheduledTaskList);
    const scheduledTaskListCurPage = useSelector(state => state.TimeTrackerModReducer.scheduledTaskListCurPage);
    const SearchOrResetScheduledTaskListClicked = useSelector(state => state.TimeTrackerModReducer.SearchOrResetScheduledTaskListClicked);

    const accountTimezone = 'America/Los_Angeles';

    const data = props.scheduledTaskList && props.scheduledTaskList.map((value) => {
        return {
            id: makeFirstLetterCapital(value && value.id ? value.id : '-'),
            start_date: value.start_date ? tzDateConverterTool_V1(value.start_date) : '-',
            end_date: value.end_date ? tzDateConverterTool_V1(value.end_date) : '-',
            name: makeFirstLetterCapital(value && value.name ? value.name : '-'),
            companyName: value && value.company ? value.company.name : '-',
            accountName: value && value.account ? value.account.name : '',
            createdBy: value && value.createdBy ?  `${makeFirstLetterCapital(value.createdBy.first_name)} ${makeFirstLetterCapital(value.createdBy.last_name)}` : '-',
            actions:
                <>
                <span className="mr_20 account_edit_icn" onClick={() => props.previewSingleScheduledTaskClicked(value)} ><i class="fas fa-eye" aria-hidden="true" ></i></span>
                </>
        }
    })

    const columns = [
        {
            Header: <><span> ID </span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /></>,
            accessor: 'id',
            show: props.scheduledTaskList.length !== 0,
            width: 135,
        },
        {
            Header: <><span> Task Name </span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /></>,
            accessor: 'name',
            show: props.scheduledTaskList.length !== 0,
        },
        {
            Header: <><span> Start Date</span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /></>,
            accessor: 'start_date',
            show: props.scheduledTaskList.length !== 0,
        },
        {
            Header: <><span> End Date</span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /></>,
            accessor: 'end_date',
            show: props.scheduledTaskList.length !== 0,
        },
        {
            Header: <><span> Company </span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /></>,
            accessor: 'companyName',
            show: props.scheduledTaskList.length !== 0,
        },
        
        {
            Header: <><span> Job </span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
            accessor: 'accountName',
            show: props.scheduledTaskList.length !== 0
        },
        {
            Header: <><span> Created By </span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
            accessor: 'createdBy',
            show: props.scheduledTaskList.length !== 0
        },
        {
            Header: '',
            accessor: 'actions',
            width: 135,
        }
    ]

    const [tablePage, setTablePage] = useState(0)
    const tablePageNew =  React.useRef()
    if(scheduledTaskListCurPage !== tablePageNew.current) {
        tablePageNew.current = scheduledTaskListCurPage;
      }
    const setTablePageFn = (page) => {
        setTablePage(page)
        props.scheduledTaskListPageClicked(page)
        tablePageNew.current = page;
    }

    const fetchData = (state, instance) => {
        if (SearchOrResetScheduledTaskListClicked) {
            dispatch(actions.resetSearchOrResetInventory())
        } else {
            if (!DateGenerate && !props.isScheduledTaskList) {
                state.page = 2;
                const limit = state.pageSize;
                let page = tablePageNew.current >= 0 ? tablePageNew.current + 1 : scheduledTaskListCurPage + 1;
                const inventoryItemsList_no_of_rows = state.pageSize;
                if(No_of_rows != inventoryItemsList_no_of_rows) {
                    page = 1;
                    setTablePageFn(0)
                }
                
                const order = state.sorted[0]?.id;
                const orderBy = state.sorted && state.sorted.length ? state.sorted[0]?.desc === true ? 'desc' : 'asc' : null;
                const accountId = SearchValue.id ? SearchValue.id : null;
                const taskName = SearchValue.name ? SearchValue.name : null;
                const companyName = SearchValue.company ? SearchValue.company : null;
                const accountName = SearchValue.job ? SearchValue.job : null;
                const from = SearchValue.fromDate ? convertDateToDifferentTZ(SearchValue.fromDate, 'from') : null;
                const to = SearchValue.toDate ? convertDateToDifferentTZ(SearchValue.toDate, 'to') : null;
                props.getScheduledTask({page,limit, order, accountId,taskName,companyName,accountName,from,to,orderBy}, values)
            }
        }
    }

    return (
        SearchOrResetScheduledTaskListClicked && props.isScheduledTaskList ?
            <div  >
                <ClapSpinner backColor="#89073c" size={40} frontColor="#89073c8a" />
            </div>
            :
            <ReactTable
                data={data}
                loading={props.isScheduledTaskList}
                minRows={0}
                defaultPageSize={No_of_rows}
                pages={No_of_pages}
                gotoPage={scheduledTaskListCurPage}
                page = { scheduledTaskListCurPage }
                onPageChange={(page) => {setTablePageFn(page)}}
                // onPageSizeChange={props.onUserPageSizeChange}
                manual
                onFetchData={fetchData}
                showPaginationTop={true}
                showPaginationBottom={false}
                showPagination={props.scheduledTaskList.length !== 0}
                columns={columns}
                // style={{marginTop: '10px'}}
                NoDataComponent={() => CustomNoDataComponent(props.isScheduledTaskList, 'No Scheduled Task List Found')}
                LoadingComponent={() =>
                    isScheduledTaskList ?
                        <div style={{
                            display: "block",
                            position: "absolute",
                            left: 0,
                            height: '100%',
                            right: 0,
                            background: "rgba(255,255,255,0.8)",
                            transition: "all .3s ease",
                            top: 0,
                            bottom: 0,
                            textAlign: "center"
                        }}
                            className="loader_btn_block">
                            <ClapSpinner backColor="#89073c" size={40} frontColor="#89073c8a" />
                        </div> : null}
                getTheadThProps={(state, rowInfo, column, instance) => {
                    // console.log(column);
                    return {
                        style: {
                            borderTop: 'none',
                            borderBottom: 'none',
                            borderRight: 'none',
                            textAlign: 'center',
                            color: '#566975',
                            fontSize: '14px',
                            fontWeight: '600',
                            whiteSpace: 'nowrap',
                            float: 'left',
                        }
                    }
                }
                }
                getTrProps={(state, rowInfo, column, instance) => {
                    // onclick = () => userDetail(rowInfo ? rowInfo.row.user : rowInfo);
                    console.log(column);
                    return {
                        className: 'react-table-tr-element',
                        style: {
                            border: '1px solid #c7cdd1',
                            transition: '.3s ease-in-out',
                            width: '100%',
                            // borderRadius: '4px',
                            // height: '80px',
                            marginTop: '5px',
                            float: 'left'
                        }
                    }

                }}
                getTdProps={(state, rowInfo, column, instance) => {

                    if (column.id == 'image') {
                        return {
                            style: {
                                marginTop: '0px'
                            }
                        }
                    } else {
                        return {
                            className: 'react-td-element',
                        }
                        //  {
                        //   style: {
                        //     borderTop: 'none',
                        //     verticalAlign: 'middle',
                        //     fontSize: '14px',
                        //     color: '#2b3034',
                        //     marginTop: '10px !important',
                        //     textAlign: 'center',
                        //   }
                        // }
                    }
                }
                }
                // getPaginationProps={() => {
                //     return {
                //         style: {
                //             marginTop: '50px',
                //             border: 'none',
                //             boxShadow: 'none'
                //         }
                //     }
                // }
                // }
                getProps={() => {
                    return {
                        style: {
                            border: 'none'
                        }
                    }
                }}
            />
    )
}

export default ScheduledTaskTable;