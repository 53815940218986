import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../redux/actions/index';
import ReportsCsv from '../../component/Reports/ReportsCsv';
import ScanAnalytics from '../../component/Reports/ScanAnalytics';
import { getFormValues } from 'redux-form';
import { TimeZones } from '../../../utility/constants/constants';
import { convertDateToDifferentTZ, getUser, sortQuotesCount } from '../../../utility/utility';
import HighCharts from '../../component/highcharts/highCharts';
import ReportsGraphs from '../../component/Reports/ReportsGraph';
import QuotasGraphs from '../../component/Reports/QuotasGraph';
import ReportsTable from '../../component/Reports/ReportsTable';
import { CSVLink, CSVDownload } from "react-csv";
import { convertQuotesReportsToCsvType } from '../../../utility/utility';
import InspectionReportsContainer from '../InspectionReportsContainer/InspectionReportsContainer';
import storage from '../../../utility/storage';
import { animateScroll as scroll} from 'react-scroll'
// import '../../component/Reports/reports.css';

const cloneDeep = require('clone-deep');

const GrapBars = {
    COLUMN: 'column',
    PIE: 'pie'
}

const ACTIVE_TABS = {
    GRAPHS: 'graphs',
    TABLE: 'table',
}

class ReportsContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentTab: 0,
            quotesReportMessage: null,
            activeTab: ACTIVE_TABS.TABLE,
            currentUserMode: '',
            hasAccount: '',
            hasDate: '',
            hasEndDate: '',
            hasMode: '',
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.history.location.search.includes('inspection_reports')) {
            if(this.state.currentTab !== 3) {
                this.setState({ currentTab: 3 })
            }
            // this.props.history.push(`/reports?inspection_reports=true`)
        }
        if (this.props.quotesReport !== prevProps.quotesReport && this.props.quotesReport && this.props.quotesReport.length === 0) {
            this.setState({
                quotesReportMessage: 'No records found.'
            })
        }
        if (prevState.activeTab !== this.state.activeTab || this.props.quotesReport !== prevProps.quotesReport) {
            if (this.state.activeTab === ACTIVE_TABS.GRAPHS) {
                let TotalQuotesCreatedData = this.props.quotesReport.map(quote => {
                    return {
                        label: `${quote.user}(${quote.account_name})`,
                        value: parseInt(quote.total_quotes_created)
                    }
                });
                TotalQuotesCreatedData.sort(sortQuotesCount)
                TotalQuotesCreatedData = TotalQuotesCreatedData.slice(0, 9);
                HighCharts({
                    id: 'TotalQuotesCreated', type: GrapBars.COLUMN,
                    data: TotalQuotesCreatedData.map(q => { return [q.label, q.value] }),
                    heading: 'Total Quotes Created', barLabel: `{point.y}`, toolTip: '<b>{point.y}</b>'
                })

                let TotalQuotesSales = this.props.quotesReport.map(quote => {
                    return {
                        label: `${quote.user}(${quote.account_name})`,
                        value: parseInt(quote.total_sale)
                    }
                });
                TotalQuotesSales.sort(sortQuotesCount)
                TotalQuotesSales = TotalQuotesSales.slice(0, 9);
                HighCharts({
                    id: 'TotalQuotesSales', type: GrapBars.PIE,
                    data: TotalQuotesSales.map(q => { return [q.label, q.value] }),
                    heading: 'Total Quotes Sales', barLabel: `{point.y:.1f}`, toolTip: '<b>{point.y:.1f}$</b>'
                })

                let TotalQuotesPendingForAdminApproval = this.props.quotesReport.map(quote => {
                    return {
                        label: `${quote.user}(${quote.account_name})`,
                        value: parseInt(quote.quotes_in_requested_status)
                    }
                });
                TotalQuotesPendingForAdminApproval.sort(sortQuotesCount)
                TotalQuotesPendingForAdminApproval = TotalQuotesPendingForAdminApproval.slice(0, 9);
                HighCharts({
                    id: 'TotalQuotesPendingForAdminApproval', type: GrapBars.COLUMN,
                    data: TotalQuotesPendingForAdminApproval.map(q => { return [q.label, q.value] }),
                    heading: 'Total Quotes Pending For Admin Approval', barLabel: `{point.y}`, toolTip: '<b>{point.y}</b>'
                })

                let TotalQuotesApprovedByAdmin = this.props.quotesReport.map(quote => {
                    return {
                        label: `${quote.user}(${quote.account_name})`,
                        value: parseInt(quote.quotes_approved_by_admin)
                    }
                });
                TotalQuotesApprovedByAdmin.sort(sortQuotesCount)
                TotalQuotesApprovedByAdmin = TotalQuotesApprovedByAdmin.slice(0, 9);
                HighCharts({
                    id: 'TotalQuotesApprovedByAdmin', type: GrapBars.COLUMN,
                    data: TotalQuotesApprovedByAdmin.map(q => { return [q.label, q.value] }),
                    heading: 'Total Approved By Admin', barLabel: `{point.y}`, toolTip: '<b>{point.y}</b>'
                })

                let TotalQuotesRejectedByAdmin = this.props.quotesReport.map(quote => {
                    return {
                        label: `${quote.user}(${quote.account_name})`,
                        value: parseInt(quote.quotes_rejected_by_admin)
                    }
                });
                TotalQuotesRejectedByAdmin.sort(sortQuotesCount)
                TotalQuotesRejectedByAdmin = TotalQuotesRejectedByAdmin.slice(0, 9);
                HighCharts({
                    id: 'TotalQuotesRejectedByAdmin', type: GrapBars.COLUMN,
                    data: TotalQuotesRejectedByAdmin.map(q => { return [q.label, q.value] }),
                    heading: 'Total Quotes Rejected By Admin', barLabel: `{point.y}`, toolTip: '<b>{point.y}</b>'
                })

                let TotalQuotesConfirmedByClient = this.props.quotesReport.map(quote => {
                    return {
                        label: `${quote.user}(${quote.account_name})`,
                        value: parseInt(quote.quotes_confirmed_by_client)
                    }
                });
                TotalQuotesConfirmedByClient.sort(sortQuotesCount)
                TotalQuotesConfirmedByClient = TotalQuotesConfirmedByClient.slice(0, 9);
                HighCharts({
                    id: 'TotalQuotesConfirmedByClient', type: GrapBars.COLUMN,
                    data: TotalQuotesConfirmedByClient.map(q => { return [q.label, q.value] }),
                    heading: 'Total Quotes Confirmed By Customers', barLabel: `{point.y}`, toolTip: '<b>{point.y}</b>'
                })

                let TotalApprovedSales = this.props.quotesReport.map(quote => {
                    return {
                        label: `${quote.user}(${quote.account_name})`,
                        value: parseInt(quote.confirmed_sales)
                    }
                });
                TotalApprovedSales.sort(sortQuotesCount)
                TotalApprovedSales = TotalApprovedSales.slice(0, 9);
                HighCharts({
                    id: 'TotalApprovedSales', type: GrapBars.PIE,
                    data: TotalApprovedSales.map(q => { return [q.label, q.value] }),
                    heading: 'Total Approved Sales', barLabel: `{point.y:.1f}`, toolTip: '<b>{point.y:.1f}$</b>'
                })

                let TotalQuotesWaitingForClientApproval = this.props.quotesReport.map(quote => {
                    return {
                        label: `${quote.user}(${quote.account_name})`,
                        value: parseInt(quote.quotes_declined_by_client)
                    }
                });
                TotalQuotesWaitingForClientApproval.sort(sortQuotesCount)
                TotalQuotesWaitingForClientApproval = TotalQuotesWaitingForClientApproval.slice(0, 9);
                HighCharts({
                    id: 'TotalQuotesWaitingForClientApproval', type: GrapBars.COLUMN,
                    data: TotalQuotesWaitingForClientApproval.map(q => { return [q.label, q.value] }),
                    heading: 'Total Quotes Waiting For Customers Approval', barLabel: `{point.y}`, toolTip: '<b>{point.y}</b>'
                })

                let TotalSalesWaitingForApproval = this.props.quotesReport.map(quote => {
                    return {
                        label: `${quote.user}(${quote.account_name})`,
                        value: parseInt(quote.declined_sales)
                    }
                });
                TotalSalesWaitingForApproval.sort(sortQuotesCount)
                TotalSalesWaitingForApproval = TotalSalesWaitingForApproval.slice(0, 9);
                HighCharts({
                    id: 'TotalSalesWaitingForApproval', type: GrapBars.PIE,
                    data: TotalSalesWaitingForApproval.map(q => { return [q.label, q.value] }),
                    heading: 'Total Sales Waiting For Approval', barLabel: `{point.y:.1f}`, toolTip: '<b>{point.y:.1f}$</b>'
                })
            }
        }
    }

    componentDidMount = () => {
        let hasAccount = new URLSearchParams(this.props.history.location.search, { ignoreQueryPrefix: true }).get('account');
        let hasDate = new URLSearchParams(this.props.history.location.search, { ignoreQueryPrefix: true }).get('date');
        let hasEndDate = new URLSearchParams(this.props.history.location.search, { ignoreQueryPrefix: true }).get('end_date');
        let hasMode = new URLSearchParams(this.props.history.location.search, { ignoreQueryPrefix: true }).get('mode');
        this.setState({
            hasAccount: hasAccount,
            hasDate: hasDate,
            hasEndDate: hasEndDate,
            hasMode: hasMode
        })
        if(hasMode) {
            const user = storage.get('user');
            this.props.userModeClicked(user.id, hasMode);
            user.selected_mode = hasMode;
            storage.set('user', user);
        }
        if (hasAccount && hasDate && hasEndDate) {
            this.setState({ currentTab: 2 })
        }
        const userMode = getUser()
        this.setState({currentUserMode: userMode.mode})
        if (!this.props.accountsList) {
            const user = getUser();
            this.props.getModeAccounts(user.mode);
        }
        // if (!this.props.companyDropdownList) {
        //     this.props.getCompaniesDropDownList();
        // }

        // if (this.state.activeTab === GrapBars.COLUMN) {
        //      
        //     let quotesCreatedData = this.props.quotesReport;

        //     HighCharts({ id: 'TotalQuotesCreated', type: GrapBars.COLUMN, data: quotesCreatedData })
        //     HighCharts({ id: 'TotalQuotesSales', type: GrapBars.PIE, })
        // } else if (this.state.activeTab === GrapBars.BAR) {
        //     HighCharts({ id: 'quotesCreatedBar1', type: GrapBars.BAR })
        //     HighCharts({ id: 'quotesCreatedBar2', type: GrapBars.BAR })
        // } else if (this.state.activeTab === GrapBars.COLUMN) {
        //     HighCharts({ id: 'quotesCreatedBar3', type: GrapBars.PIE })
        // }
    scroll.scrollToTop();
    }

    getCsvReports = (values) => {
        this.setState({
            quotesReportMessage: null
        })
        const data = cloneDeep(values);
        data.company_ids = data.company_ids ? data.company_ids.map(c => c.value) : [];
        data.account_ids = data.account_ids ? data.account_ids.map(a => a.value) : [];
        data.fromDate = convertDateToDifferentTZ(data.fromDate, 'from');
        data.toDate = convertDateToDifferentTZ(data.toDate, 'to');
        const user = getUser();
        data.mode = user.mode;
        this.props.getQuotesCsvReports(data);
    }

    switchTabs = (value) => {
        if(value === 0) {
            this.setState({ currentTab: 0 })
            this.props.history.push(`/reports?quotes=true`)
        } else if(value === 2) {
            this.setState({ currentTab: 2 })
            this.props.history.push(`/reports?pegassure=true`)
        } else if(value === 3) {
            this.setState({ currentTab: 3 })
            this.props.history.push(`/reports?inspection_reports=true`)
        }
    }


    render() {
        return (
            <main className="app-content wraper_content report_wrapper">
                <section className="report_content_block appcontent_Inner">
                    <div className="report_content">
                        <div className="sub_head">
                            <div className="btn-group" role="group" aria-label="Basic example">
                                <button type="button" className={`btn cst_btn ${this.state.currentTab === 0 ? "btn_danger_dark" : "btn_secondary_light"} `} onClick={() => this.switchTabs(0)}>Quotes</button>
                                {/* <button type="button" className={`btn cst_btn ${this.state.currentTab === 1 ? "btn_danger_dark" : "btn_secondary_light"} `}>CHI</button> */}
                                {this.state.currentUserMode && this.state.currentUserMode === 'external' ? <button type="button" className={`btn cst_btn ${this.state.currentTab === 2 ? "btn_danger_dark" : "btn_secondary_light"} `} onClick={() => this.switchTabs(2)}>PegAssure</button> : ''}
                                <button type="button" className={`btn cst_btn ${this.state.currentTab === 3 ? "btn_danger_dark" : "btn_secondary_light"} `} onClick={() => this.switchTabs(3)}>Inspection Reports</button>
                                {/* <button type="button" className={`btn cst_btn ${this.state.currentTab === 4 ? "btn_danger_dark" : "btn_secondary_light"} `} onClick={() => this.setState({ currentTab: 4 })}>Quotas</button> */}
                            </div>
                            <div className="export_btn">
                                {this.props.quotesReport && this.props.quotesReport.length > 0 ?
                                    // <div className="tile_ft">
                                    <CSVLink filename="QuotesReport.csv" className="btn btn_danger cst_btn" data={convertQuotesReportsToCsvType(this.props.quotesReport)}>Export CSV</CSVLink>
                                    // </div>
                                    : null}
                            </div>
                        </div>
                        {/* <!-- chart --> */}
                        {this.state.currentTab === 0 ?
                            <><div className="chart-content grid_block">
                                {/* <div className="cst_select_block">
                                    <select className="custom-select">
                                        <option selected>Today</option>
                                        <option value="1">One</option>
                                        <option value="2">Two</option>
                                        <option value="3">Three</option>
                                    </select>
                                </div> */}
                                <div className="clearfix"></div>
                                <div className="container-fluid">
                                    <div className="grid_view">
                                        <div className="grid_card tile">
                                            <h4 className="chart_title">{this.props.quotes_tag_created}</h4>
                                            <p className="cst_para">Total Quoted TAGs created </p>
                                        </div>
                                        <div className="grid_card tile">
                                            <h4 className="chart_title">{this.props.tags_confirmed_by_client}</h4>
                                            <p className="cst_para">Tags Confirmed by Customers</p>
                                        </div>
                                        <div className="grid_card tile">
                                            <h4 className="chart_title">{this.props.an_account_with_zero_tags_sold}</h4>
                                            <p className="cst_para">An account with Zero Tags Sold </p>
                                        </div>
                                        <div className="grid_card tile">
                                            <h4 className="chart_title">{this.props.quotes_created_vs_quotes_approved}</h4>
                                            <p className="cst_para">Quotes Created Vs Quotes Approved</p>
                                        </div>
                                        <div className="grid_card tile">
                                            <h4 className="chart_title">{this.props.quotes_vs_quotes_rejected_by_admin}</h4>
                                            <p className="cst_para">Quotes Vs Quotes Rejected by admin</p>
                                        </div>
                                        <div className="grid_card tile">
                                            <h4 className="chart_title">{this.props.quotes_vs_approved_not_approved_by_the_client}</h4>
                                            <p className="cst_para">Quotes Vs Approved/not approved by the Customers</p>
                                        </div>
                                    </div>
                                </div>

                            </div>
                                <div className="gray_bg_wrap">
                                    <ReportsCsv
                                        onSubmit={this.getCsvReports}
                                        {...this.props}
                                        {...this.state}
                                    // formValues={this.props.formValues}
                                    // accountsList={this.props.accountsList}
                                    // companyDropdownList={this.props.companyDropdownList}
                                    // quotesReport={this.props.quotesReport}
                                    // isQuotesReportLoading={this.prop}
                                    />
                                    {this.props.quotesReport === null ? null :
                                        <div className="graph_tabs">
                                            <div className="tile tabs_nav">
                                                <ul className="nav nav-pills" id="pills-tab" role="tablist">
                                                    <li className={'nav-item'} onClick={() => this.setState({ activeTab: ACTIVE_TABS.TABLE })}>
                                                        <a className={this.state.activeTab === ACTIVE_TABS.TABLE ? "nav-link active" : "nav-link"} id="pills-graph-two-tab" data-toggle="pill" href="#pills-graph-two" role="tab" aria-controls="pills-graph-two" aria-selected="false"><img src="/images/icons/icn_table.png" alt="Graph" /></a>
                                                    </li>
                                                    <li className="nav-item" onClick={() => this.setState({ activeTab: ACTIVE_TABS.GRAPHS })}>
                                                        <a className={this.state.activeTab === ACTIVE_TABS.GRAPHS ? "nav-link active" : "nav-link"} id="pills-graph-one-tab" data-toggle="pill" href="#pills-graph-one" role="tab" aria-controls="pills-graph-one" aria-selected="true"><img src="/images/icons/graph1.png" alt="Graph" /></a>
                                                    </li>
                                                </ul>
                                            </div>
                                            {this.state.activeTab === ACTIVE_TABS.GRAPHS ?
                                                <ReportsGraphs /> :
                                                this.state.activeTab === ACTIVE_TABS.TABLE ?
                                                    <ReportsTable
                                                        quotesReport={this.props.quotesReport}
                                                    /> : null}
                                        </div>
                                    }
                                </div>
                            </> : (this.state.currentTab === 2 ? <ScanAnalytics {...this.props} 
                                    hasAccount={this.state.hasAccount}
                                    hasDate={this.state.hasDate}
                                    hasEndDate={this.state.hasEndDate}
                                    hasMode={this.state.hasMode}/> :
                                this.state.currentTab === 3 ? <InspectionReportsContainer {...this.props} /> :
                                this.state.currentTab === 4 ? <QuotasGraphs {...this.props} /> : null)}
                    </div>
                </section>
            </main>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        formValues: getFormValues("reportsForm")(state) || {},
        analyticsFormValues: getFormValues("analyticsForm")(state) || {},
        accountsList: state.adminOrSuperAdminReducer.modeAccountsList,
        isAccountLoading: state.adminOrSuperAdminReducer.isAccountLoading,
        companyDropdownList: state.adminOrSuperAdminReducer.companyDropdownList,
        isCompanyPending: state.adminOrSuperAdminReducer.isCompanyPending,
        quotesReport: state.adminOrSuperAdminReducer.quotesReport,
        isQuotesReportLoading: state.adminOrSuperAdminReducer.isQuotesReportLoading,
        an_account_with_zero_tags_sold: state.adminOrSuperAdminReducer.an_account_with_zero_tags_sold,
        quotes_created_vs_quotes_approved: state.adminOrSuperAdminReducer.quotes_created_vs_quotes_approved,
        quotes_tag_created: state.adminOrSuperAdminReducer.quotes_tag_created,
        quotes_vs_approved_not_approved_by_the_client: state.adminOrSuperAdminReducer.quotes_vs_approved_not_approved_by_the_client,
        quotes_vs_quotes_rejected_by_admin: state.adminOrSuperAdminReducer.quotes_vs_quotes_rejected_by_admin,
        tags_confirmed_by_client: state.adminOrSuperAdminReducer.tags_confirmed_by_client,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getModeAccounts: (mode) => dispatch(actions.getModeAccounts(mode)),
        getCompaniesDropDownList: () => dispatch(actions.getCompaniesDropDownList()),
        getQuotesCsvReports: (data) => dispatch(actions.getQuotesCsvReports(data)),
        userModeClicked: (user_id, mode) => dispatch(actions.loginUserModeSelection(user_id, mode)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportsContainer);