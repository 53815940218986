import React, { Component } from 'react';
import AppRouter from './router/router';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as Scroll from 'react-scroll';
import { Link, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'
import { action } from 'popmotion';
import $ from 'jquery';
import { makeFirstLetterCapital } from './utility/utility';

class UserApp extends Component {



    componentDidUpdate(prevProps, prevState) {
        if ((process.env.REACT_APP_ENV == 'production') && prevProps && prevProps.user) {
            window.smartlook('identify', Number(prevProps.user.id), {
                "User Id": prevProps.user.id,
                "User Name": prevProps.user.first_name + ' ' + prevProps.user.last_name,
                "User Email": prevProps.user.email,
                "User Role": makeFirstLetterCapital(prevProps.user.role),
            })
        } 

        let viewedPages = prevProps.history.location.pathname.split('/')[prevProps.history.location.pathname.split('/').length - 1];
        if (sessionStorage.viewedPages) {
            sessionStorage.setItem("viewedPages", [...[sessionStorage.viewedPages], ...[viewedPages]]);
        } else {
            sessionStorage.setItem("viewedPages", viewedPages);
        }
        // scroll.scrollToTop();
    }
    componentDidMount() {

        // scroll.scrollToTop();
    }

    render() {
        return <AppRouter {...this.props} />
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.authReducer.user,
        token: state.authReducer.token
    }
}

export default withRouter(connect(mapStateToProps)(UserApp));