import { AuthActionTypes } from '../../actions/AuthActions/authActionType';
import storage from '../../../utility/storage';
import { fdatasync } from 'fs';
import { updateProfile } from '../../../API/api';

const token = storage.get("token", null);
const refresh_token = storage.get("refresh_token", null);
const user = storage.get("user", null);
const is_facial=storage.get("is_facial", null);

export const initialState = {
    token: token,
    user: user,
    impersonate: false,
    refresh_token: refresh_token,
    isLoading: false,
    profileModel: false,
    modeModal: false,
    isLogbookTab: true,
    cameraSelectionModal: true,
    is_facial:is_facial
}

const updateObject = (oldState, updatedProps) => {
    return {
        ...oldState,
        ...updatedProps
    }
}

export const authReducer = (state = initialState, action) => {
    switch (action.type) {

        case AuthActionTypes.SIGN_IN_PENDING:
            return updateObject(state, { isLoading: true })
        case AuthActionTypes.SIGN_IN_FULFILLED:
            return updateObject(state, { isLoading: false })
        case AuthActionTypes.AUTHORIZE:
            return updateObject(state,
                {
                    token: action.payload.token,
                    user: action.payload.user,
                    refresh_token: action.payload.refresh_token,
                    is_facial:action.payload.user?.is_facial
                })

        case AuthActionTypes.UPDATE_USER:
            return updateObject(state,
                {
                    user: action.payload,
                })

        case AuthActionTypes.FORGET_PASSWORD_PENDING:
            return updateObject(state, { isLoading: true })
        case AuthActionTypes.FORGET_PASSWORD_FULFILLED:
            return updateObject(state, { isLoading: false })

        case AuthActionTypes.LOGOUT_PENDING:
            return updateObject(state, { isLoading: true })
        case AuthActionTypes.LOGOUT:
            return updateObject(state, {
                isLoading: false,
                token: null,
                user: null,
                refresh_token: null
            })

        case AuthActionTypes.GET_LATEST_PROFILE_PENDING:
            return updateObject(state)
        case AuthActionTypes.GET_LATEST_PROFILE_FULFILLED:
            return updateObject(state, { user: action.payload })

        case AuthActionTypes.OPEN_PROFILE_MODEL:
            return updateObject(state, {...state, profileModel: true })
        case AuthActionTypes.CLOSE_PROFILE_MODEL:
            return updateObject(state, {...state, profileModel: false })

        case AuthActionTypes.OPEN_MODE_MODAL:
            return updateObject(state, { modeModal: true })
        case AuthActionTypes.CLOSE_MODE_MODAL:
            return updateObject(state, { modeModal: false })

        case AuthActionTypes.RESET_PASSWORD_PENDING:
            return updateObject(state, { isLoading: true })
        case AuthActionTypes.RESET_PASSWORD_FULFILLED:
            return updateObject(state, { isLoading: false })

        case AuthActionTypes.UPDATE_USER_PASSWORD_PENDING:
            return updateObject(state, { isLoading: true })
        case AuthActionTypes.UPDATE_USER_PASSWORD_FULFILLED:
            return updateObject(state, { isLoading: false })

        case AuthActionTypes.CHANGE_PASSWORD_PENDING:
            return updateObject(state, { isLoading: true })
        case AuthActionTypes.CHANGE_PASSWORD_FULFILLED:
            return updateObject(state, { isLoading: false })

        case AuthActionTypes.UPDATE_USER_PASSWORD_PENDING:
            return updateObject(state, { isLoading: true })
        case AuthActionTypes.UPDATE_USER_PASSWORD_FULFILLED:
            return updateObject(state, { isLoading: false })
    
        case AuthActionTypes.GET_PROFILE_PRESIGNED_URL_PENDING:
            return updateObject(state, { isLoading: true })
        case AuthActionTypes.UPDATE_PROFILE_PENDING:
            return updateObject(state, { isLoading: true })
        case AuthActionTypes.UPDATE_PROFILE_FULFILLED:
            return updateObject(state, { isLoading: false, user: action.payload })

        case AuthActionTypes.UPDATE_PREFERENCES_PENDING:
            return updateObject(state, { isLoading: true })
        case AuthActionTypes.UPDATE_PREFERENCES_FULFILLED:
            return updateObject(state, { isLoading: false, user: action.payload })

        default: return state;
    }


}