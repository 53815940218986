import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../../../redux/actions/index';
import { reset } from 'redux-form';
import storage from '../../../../../utility/storage';
import { animateScroll as scroll} from 'react-scroll'
import PreviewInventory from '../../../../component/Inventory/InventoryListComponent/PreviewInventory/previewInventory';


class PreviewInventoryContainer extends Component {

  constructor(props) {
    super(props);

    this.state = {
      currentAssosciatedUserEmail: '',
      accountErrorMessage: ''
    }
  }

  componentDidMount() {
    scroll.scrollToTop();
  }

  searchFrontlineUserClicked = (values) => {
    if(values.id) {
      values.id = values.id.trim()
  }
  if(values.name) {
    values.name = values.name.trim()
}
    this.props.searchFrontlineUser(values);
  };


  componentWillUnmount() {
    //this.props.resetObjects();
  }

  componentDidUpdate(prevProps, prevState) {
    const user = storage.get('user');
    if(user) {
    if(prevProps.user.selected_mode !== user.selected_mode) {
      this.props.frontlineUserPageClicked(0)
    }
  }
}

  resetClicked = () => {
    const size = this.props.frontLineRows
    const page = 1;
    this.props.reset('searchPreviewInventoryForm');
    this.props.frontlineUserPageClicked(0)
    this.props.getSingleInventoryLogItem(this.props.match.params.inventoryItemId);
  }

  render() {

    return (
      <PreviewInventory
        {...this.props}
        resetClicked={this.resetClicked}
        onSubmit={this.searchFrontlineUserClicked}
      />
    )
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.authReducer.user,
    isLoading: state.InventoryReducer.isLoading,
    frontlineUserCurPage: state.adminOrSuperAdminReducer.frontlineUserCurPage,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    handleDeleteClose: () => dispatch(actions.handleDeleteClose()),
    handleClickDeleteModel: (frontline) => dispatch(actions.handleClickDeleteModel(frontline)),
    deleteFrontline: (id) => dispatch(actions.deleteFrontline(id)),
    importFrontline: (body) => dispatch(actions.importFrontline(body)),
    reset: (searchPreviewInventoryForm) => dispatch(reset(searchPreviewInventoryForm)),
    searchFrontlineUser: (values) => dispatch(actions.searchFrontlineUser(values)),
    frontlineUserPageClicked: (page) => dispatch(actions.frontlineUserPageClicked(page)),
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(PreviewInventoryContainer);