import React, { useEffect, useState, useCallback } from 'react';
import { Input } from '../../../../UI/InputElement/InputElement';
import { listedItemsComingBackFormValidator as validate } from '../../../../../../utility/validator/validator';
import { connect, useSelector } from 'react-redux';
import { getFormValues, reduxForm } from 'redux-form';
import * as Scroll from 'react-scroll';
import ListedItemsComingBackFormSkeleton from './ListedItemsComingBackFormSkeleton';
import Stack from '@mui/material/Stack';
import { Badge } from 'antd';

const cloneDeep = require('clone-deep');
var scroller = Scroll.scroller;

let ListedItemsComingBackForm = (props) => {

    // for triggerring validation for items and quantity field
    useEffect(() => {
        setTimeout(() => {
            let ele = document.getElementById('triggerPoint-0')
            if(ele) {
                ele.click();
            }
        }, 500);
    }, [])

    useEffect(() => {
        setItemNamesList(getInventoryItemNamesList(props))
    }, [props.inventoryItemNamesList])

    const getInventoryItemNamesList = (props) => {
        if (props.inventoryItemNamesList && props.inventoryItemNamesList.length > 0) {
            return props.inventoryItemNamesList && props.inventoryItemNamesList.map((item) => {
                return {
                    value: item.id,
                    label: item.name,
                    quantity: item.item.initial_quantity
                }
            })
        } else {
            return []
        }
    };

    const [itemNamesList, setItemNamesList] = useState(getInventoryItemNamesList(props));
    const [maxQuantity, setMaxQuantity] = useState([])
    const [addExistItemSlots, setAddExistItemSlots] = useState(['']);
    const [addNewItemSlots, setAddNewItemSlots] = useState(['']);

    const handleFilterSelectedExistItems = (data, index) => {
        let captureSelectedItem = JSON.parse(JSON.stringify(addExistItemSlots));
        captureSelectedItem[index] = data.value
        setAddExistItemSlots(captureSelectedItem);
    }

    const handleQuantityFinalizer = (data, index) => {
        let captureSelectedItemQuantity = JSON.parse(JSON.stringify(maxQuantity));
        captureSelectedItemQuantity[index] = data.quantity
        setMaxQuantity(captureSelectedItemQuantity);
    };

    const handleCreateExistItemSlots = () => {
        let items = [...addExistItemSlots];
        items.push({
            itemId: '',
            itemQuantity: ''
        });
        setAddExistItemSlots(items);
        setTimeout(() => {
            let ele = document.getElementById(`triggerPoint-${items.length-1}`)
            if(ele) {
                ele.click();
            }
        }, 100);
    }

    const handleDeleteExistItemSlots = (index) => {
        let itemSlots = [...addExistItemSlots];
        itemSlots.splice(index, 1);
        setAddExistItemSlots(itemSlots);

        let quantitySlots = [...maxQuantity];
        quantitySlots.splice(index, 1);
        setMaxQuantity(quantitySlots);

        if((itemSlots && itemSlots.length) && (props.formStates.delivery && props.formStates.delivery.items)) {
            let items = props.formStates.delivery.items;
            items.splice(index, 1);
            change("delivery[items]", items);
        }else {
            change("delivery[items]", [{ itemId: '', itemQuantity: '' }]);
        }
    };

    const handleCreateNewItemSlots = () => {
        let slots = [...addNewItemSlots];
        slots.push({
            itemName: '',
            itemQuantity: '',
            itemIsAuthorised: null
        });
        setAddNewItemSlots(slots);
        setTimeout(() => {
            let ele = document.getElementById(`unAuthorized-${slots.length-1}`)
            if(ele) {
                ele.click();
            }
        }, 100);
    }

    const handleDeleteNewItemSlots = (index) => {
        let slots = [...addNewItemSlots];
        slots.splice(index, 1);
        setAddNewItemSlots(slots);

        if((slots && slots.length) && (props.formStates.delivery && props.formStates.delivery.newItems)) {
            let newItems = props.formStates.delivery.newItems;
            newItems.splice(index, 1);
            change("delivery[newItems]", newItems);
        }else {
            change("delivery[newItems]", [{ itemName: '', itemQuantity: '', itemIsAuthorised: null }]);
        }
    };

    const noOptionsMessage = (a, b) => {
        return 'No options';
    }

    const { handleSubmit, pristine, reset, submitting, error, account, submitFailed, change } = props;

    if (props.isFormLoading) {
        return (
            <ListedItemsComingBackFormSkeleton />
        )
    } else {
        return (
            <main className="app-content wraper_content user_newQuote add_account_modal add_New_Accont add_newAccount">
                <div className="inspection_content_sec appcontent_Inner">
                    <div className="container-fluids">
                        <div  className="quote_form ticket_form" style={{maxWidth: '700px'}}>
                            <div className="row">
                                <div className="col-sm-12 text-center"> <h2 className="md_title" > Listed Items Coming Back </h2></div>
                            </div>
                                <div className="addQuote_form">
                                    <form className="form_content" onSubmit={handleSubmit} >
                                        <div className="cst_tab_content">

                                            <div className="form-group">
                                                {addExistItemSlots.map((time, index) => {
                                                    return (
                                                        <>
                                                            <div className="input-group mb-3" key={index}>
                                                                <div className="mb-1 mt-1" style={{width: '100%', display: 'flex'}}>
                                                                    <div className="mr-3" style={{width: '50%'}}>
                                                                        <label className="form_title"> Item <sup className="cst_sup_txt text_danger">*</sup></label>
                                                                        <Input
                                                                            type="inputSelect"
                                                                            placeholder="Select item"
                                                                            noOptionsMessage={noOptionsMessage}
                                                                            onChange={(data) => {
                                                                                handleFilterSelectedExistItems(data, index);
                                                                                handleQuantityFinalizer(data, index);
                                                                            }}
                                                                            options={itemNamesList.filter((data) => {
                                                                                return !addExistItemSlots.includes(data.value)
                                                                            })}
                                                                            name={`delivery[items][${index}][id]`}
                                                                        />
                                                                    </div>
                                                                    <div style={{marginTop: '35px'}} role="group" aria-label="Basic example">
                                                                        {index != 0 && <button type="button" class="btn btn-dark" onClick={e => handleDeleteExistItemSlots(index)}><i className="far fa-trash-alt"></i></button>}
                                                                    </div>
                                                                    <div className="ml-3" style={{width: '50%'}}>
                                                                        <Stack direction="row" spacing={1}>
                                                                            <label className="form_title"> Quantity <sup className="cst_sup_txt text_danger">*</sup>
                                                                            {/* {maxQuantity && maxQuantity.length > 0 ? 
                                                                                <span className="badge badge-pill badge-warning">
                                                                                    <Badge color="#787878" count={maxQuantity ? maxQuantity[index] : 0} showZero/>&nbsp;Quantity Available
                                                                                </span>
                                                                            : null} */}
                                                                            </label>
                                                                        </Stack>
                                                                        <Input dataTest="" type="numberWithIncDec" min={1} className="form-control input-modifier" placeholder="Enter quantity" name={`delivery[items][${index}][quantity]`} />
                                                                    </div>
                                                                </div>
                                                                <div style={{display: 'none'}}>
                                                                    <Input
                                                                        type="radio"
                                                                        forLable={`triggerPoint-${index}`}
                                                                        value={"false"}
                                                                        className="custom-control-input"
                                                                        id={`triggerPoint-${index}`}
                                                                        name={`delivery[items][${index}][triggerPoint]`}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </>
                                                    )})
                                                }
                                                {addExistItemSlots && addExistItemSlots.length == itemNamesList.length ? null : (
                                                    <div style={{display: 'flex',justifyContent: 'flex-end',}}>
                                                        <button 
                                                            type='button'
                                                            className='btn btn-outline-danger'
                                                            style={{fontSize: '14px', fontWeight: 'bolder',}}
                                                            onClick={() => {handleCreateExistItemSlots();}}
                                                        > 
                                                            + Add More Exist Item
                                                        </button>
                                                    </div>
                                                )}
                                            </div>

                                            <div className="form-group">
                                                <label className="form_title" style={{fontSize: '18px',fontWeight: 'bold', color: '#66002a'}}> Please add items if it's not shown in the list </label>
                                                {addNewItemSlots.map((time, index) => {
                                                    return (
                                                        <div className='addQuote_form p-3 mb-4' key={index}>
                                                            <div className="input-group">
                                                                <div className="mb-3 mt-1" style={{width: '100%', display: 'flex'}}>
                                                                    <div className="mr-3" style={{width: '50%'}}>
                                                                        <label className="form_title"> Item </label>
                                                                        <Input dataTest="ticket_subject" type="text" className="form-control input-modifier" placeholder="Enter item" name={`delivery[newItems][${index}][name]`} />
                                                                    </div>
                                                                    <div className="ml-3" style={{width: '50%'}}>
                                                                        <label className="form_title"> Quantity </label>
                                                                        <Input dataTest="" type="numberWithIncDec" className="form-control input-modifier" placeholder="Enter quantity" name={`delivery[newItems][${index}][quantity]`} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row" style={{alignItems: 'center'}}>
                                                                <div className="col-md-6">
                                                                    <div className="form-group">
                                                                        <label className="form_title"> Authorized? </label>
                                                                        <Input type="radio" labelClass="custom-control-label" forLable={`authorized-${index}`} value={"true"} label={"Yes"} className="custom-control-input" id={`authorized-${index}`} name={`delivery[newItems][${index}][isAuthorized]`} />
                                                                        <Input type="radio" labelClass="custom-control-label" forLable={`unAuthorized-${index}`} value={"false"} label={"No"} className="custom-control-input" id={`unAuthorized-${index}`} name={`delivery[newItems][${index}][isAuthorized]`} />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <div style={{display: 'flex',justifyContent: 'flex-end',}}>
                                                                        {index != 0 && 
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-outline-dark"
                                                                            style={{fontSize: '14px', fontWeight: 'bolder',}}
                                                                            onClick={e => handleDeleteNewItemSlots(index)}
                                                                        >
                                                                            <i className="far fa-trash-alt"/>&nbsp;&nbsp;Delete New Item
                                                                        </button>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )})
                                                }
                                                <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                                                    <button 
                                                        type='button'
                                                        className='btn btn-outline-danger'
                                                        style={{fontSize: '14px', fontWeight: 'bolder',}}
                                                        onClick={() => {handleCreateNewItemSlots();}}
                                                    > 
                                                        + Add More New Item
                                                    </button>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="modal-footer">
                                            <button data-test="submit_button" type="submit" disabled={props.isButtonLoading} className={props.isButtonLoading ? "btn cst_btn btn_danger btn-wait w-25" : "btn cst_btn btn_danger w-25"} >{props.isButtonLoading ? "" : "Submit"}</button>
                                        </div>
                                    </form>
                                </div>
                        </div>
                    </div>
                </div>
            </main >
        )
    }
}

const mapStateToProps = (state, props) => {
    let delivery = {};
    return{
        initialValues: {delivery},
        formStates: getFormValues('listedItemsComingBackForm')(state)
    };
}

ListedItemsComingBackForm = reduxForm({
    form: 'listedItemsComingBackForm',
    validate,
    enableReinitialize: true,
})(ListedItemsComingBackForm);
ListedItemsComingBackForm = connect(mapStateToProps)(ListedItemsComingBackForm);

export default ListedItemsComingBackForm;