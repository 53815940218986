import React, { useState } from "react";
import { Input } from "../UI/InputElement/InputElement";
import * as Scroll from 'react-scroll';
import { reduxForm } from 'redux-form';
import { newTicketValidator as validate } from '../../../utility/validator/validator';
import { routes } from "../../../utility/constants/constants";
import ImageLoader from 'react-imageloader';
import Webcam from "react-webcam";
import {
    Dialog,
    DialogActions,
    DialogTitle,
    DialogContent,
    makeStyles,
    Button,
    IconButton,
    Icon
} from '@material-ui/core';
import { makeFirstLetterCapital } from "../../../utility/utility";
import ModalImageGallery from "../ModalImageGallery/modalImageGallery";
const WebcamComponent = () => <Webcam />;

const videoConstraints = {
    width: 1280,
    height: 720,
    facingMode: "environment"
  };
  
var scroller = Scroll.scroller;
let CommentsComponent = (props) => {
    const { handleSubmit, pristine, reset, submitting, error } = props;

    const [isImageGallery, setIsImageGallery] = useState(false);
    const [startIndex, setStartIndex] = useState(0);
    // const [others, setOthers] = useState(false);
    const [selectedDeficiency, setSelectedDeficiency] = useState([]);
    const webcamRef = React.useRef(null);
    const [photoUrls, setPhotoUrls] = useState([]);
    const [base64, setBase64] = useState([]);

    const capture = React.useCallback(
        () => {
          const imageSrc = webcamRef.current.getScreenshot();
          // const imageArr = image
          // imageArr.push(imageSrc)
          // setImage(imageArr)
          // console.log(imageSrc, imageArr)
          props.submitCameraPics(imageSrc)
        },
    
        [webcamRef]
      );
      const photoUrlClicked = (base64, i) => {
        setIsImageGallery(true);
        setStartIndex(i);
        setBase64(base64);
    }

    if (isImageGallery) {
        return <ModalImageGallery
            isImageGallery={isImageGallery}
            setIsImageGallery={setIsImageGallery}
            startIndex={startIndex}
            base64={base64}
            imageUrls={photoUrls}
        />
    }

    const nextClicked = () => {
        props.closeDeficiencyClicked()
    }
    const deficiencySelected = (event, object) => {
            if(event.target.checked === true) {
                // if(object === 'others') {
                //     setOthers(true);
                //     return
                // }
                const selectedIds = selectedDeficiency
                selectedIds.push(object)
                setSelectedDeficiency(selectedIds)
            } else {
                // if(object === 'others') {
                //     setOthers(false);
                //     return
                // }
                const selectedIds = selectedDeficiency
                selectedIds.map((s, i) => {
                    if(s.id === object.id) {
                        selectedIds.splice(i,1)
                    }
                })
                setSelectedDeficiency(selectedIds)
            }
            props.selectedDeficiencies(selectedDeficiency)
    }
    function preloader() {
        return <img style={{ width: '100%', height: '100%' }} src="/images/gif/giphy.gif" />;
    }
    if (props.deficiency && props.deficiency.length > 0) {debugger
        let findIndex = props.deficiency.findIndex(i => i.title === "others")
        if (findIndex) {
            let firstElement = props.deficiency[findIndex]
            if (firstElement) {
                props.deficiency.splice(findIndex, 1)
                props.deficiency.push(firstElement)
            }
        }
    }
    return (
        <main class="app-content qr_app_content app_screen_outer schedule-screen ups_schedule">
            <div class="client_login list_view">
                <div class="login_form_content Schedule_con">
                    <div className="comments_wrap">
                        {props.match.params.type === 'average' ? <p style={{fontWeight: 'bolder', color: 'rgb(150, 45, 57)'}}>You have given rating of average, Please tell us why.</p> : <p style={{fontWeight: 'bolder', color: 'rgb(150, 45, 57)'}}>You have given rating of Need Service, Please tell us why?</p>}
                        <form onSubmit={handleSubmit}>
                            <div className="form-group">
                            {props.deficiency && props.deficiency.length > 0 ? <><label className="form_title">What needs service?</label>
                                <div className="d-flex flex-wrap">
                                    { selectedDeficiency.map((s, i)=> {
                                            return (
                                                <div key={i}>
                                                    <button type="button" className='btn user_selected_deficiencies_btn' >{makeFirstLetterCapital(s.title)}</button>
                                                </div>
                                            )
                                    })}
                                </div></> : ''}
                                {props.match.params.type === 'average' ? <label className="form_title">Please provide the details of service needed </label> :
                                <label className="form_title">Please provide the details of service needed <sup className="cst_sup_txt text_danger">*</sup></label>}
                                <Input type="textarea" name="comments[message]" className="form-control textarea_modifier" rows="6" placeholder="Detailed Message" id="comment" />
                            </div>
                            <div className="form-group btn_block mb-0">
                                <button data-test="cancel_button" type="button" className="w-100 py-3 mb-3" style={{ color: "#000000", background: "#ffffff", border: "1px solid #6c757d", borderRadius: "5px" }} onClick={() => props.openCameraClicked()}><i class="fa fa-camera" aria-hidden="true"></i>  Take a Photo and upload it</button>

                                <div className="file_attachment">
                                        <Input type="file" name="attachment_paths" hidden={true} multiple={true} onSelect={props.onPortfolioFileChange} fileRef={props.reference} accept="image/*" />
                                        <a href="javascript:void(0)" onClick={props.choosePhotoClicked} className="file_title"  style={{color: '#850037'}} ><img src="/images/icons/attachment_icon.png" alt="Icon"/>Upload attachments</a>
                                        <p className="size_dec"></p>

                                        <div class="upload_file">
                                            <div class="row" style={{margin: 'auto'}}>
                                                {props.portfolioImages.map((url, i) => {
                                                    return (
                                                        <div class="col-4 col-sm-4 cst_gutter" key={i}>
                                                            <div class="file_view" onClick={() => photoUrlClicked(props.portfolioImages, i)}>
                                                                <ImageLoader
                                                                    style={{ width: '100%', height: '100%', cursor: 'pointer' }}
                                                                    src={url}
                                                                    wrapper={React.createFactory('div')}
                                                                    preloader={preloader}>
                                                                    Image load failed!
                                                            </ImageLoader>
                                                            </div>
                                                            <div class="cancel_icon">
                                                                <img src="/images/icons/icn_delete_white.png" onClick={() => props.removeUrls(url, i)} alt="Image" />
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                <button style={{display: "inline-block"}} data-test="submit_button" type="submit" className={
                                    props.isLoading
                                        ? 'btn cst_btn btn_danger mr-2 btn-wait w-100 py-3'
                                        : 'btn cst_btn btn_danger mr-2 w-100 py-3'
                                } >{props.isLoading ? "" : 'Submit'}</button>
                                <button data-test="cancel_button" type="button" className="w-100 py-3 mb-3" style={{ color: "#000000", background: "#ffffff", border: "1px solid #ffffff", borderRadius: "5px", cursor: 'pointer' }} onClick={() => props.history.push(`/pegassure-feedback/${props.match.params.areaId}`)}>Cancel</button>
                            </div>
                        </form>
                    </div>
                    <Dialog
                        open={props.openCameraModal}
                        onClose={props.closeCameraClicked}
                        aria-labelledby="form-dialog-title"
                    >
                    <DialogTitle id="form-dialog-title" style={{ fontSize: '13', color: '#06080f' }}>
                        <h3 style={{ color: '#ab385e' }} className="text-center">Capture Image</h3>
                    </DialogTitle>
                    <DialogContent className="pl-5 pr-5">
                        <div>
                        <Webcam
                                audio={false}
                                height={200}
                                ref={webcamRef}
                                screenshotFormat="image/jpeg"
                                width={100 + "%"}
                                videoConstraints={videoConstraints}
                            />
                            
                        </div>
                        </DialogContent>

                    <DialogActions>
                    <button className="btn cst_btn btn_danger" onClick={(e)=>{e.preventDefault();capture();}} >
                            Capture</button>
                        {/* <button style={{ minHeight: '36px', minWidth: '80px' }} onClick={() => props.submitCameraPics(image)} className={ "btn cst_btn btn-outline-secondary"} type="button">Submit</button> */}
                        <button style={{ minHeight: '36px', minWidth: '80px' }} onClick={props.closeCameraClicked} className={"btn cst_btn btn-outline-secondary"}  type="cancel">Cancel</button>
                    </DialogActions>
                </Dialog>
                <Dialog
                        open={props.openDeficiencyModal && props.deficiency && props.deficiency.length > 0}
                        aria-labelledby="form-dialog-title"
                        onClose={(_, reason) => {
                            if (reason !== "backdropClick" && reason !== 'escapeKeyDown') {
                                props.closeDeficiencyClicked()
                            }
                        }}
                    >
                    <DialogTitle id="form-dialog-title" style={{ fontSize: '13', color: '#06080f' }}>
                        <h3 style={{ color: '#ab385e' }} className="text-center">What needs service?</h3>
                    </DialogTitle>
                    <DialogContent className="pl-5 pr-5">
                        <div>
                            {props.deficiency && props.deficiency.map((q, i) => {
                                return (
                                    <>
                                    <div key={i}>
                                        <div class="form-check">
                                        <input onChange={(e) => deficiencySelected(e, q)} class="form-check-input" type="checkbox" value="" id="flexCheckChecked"></input>
                                        <label class="form-check-label" htmlFor="flexCheckChecked">
                                        {makeFirstLetterCapital(q.title)}
                                        </label>
                                        </div>
                                    </div>
                                    {/* <div key={i}>
                                    {i === props.deficiency.length -1 ? <>
                                            <div class="form-check">
                                                <input onChange={(e) => deficiencySelected(e, 'others')} class="form-check-input" type="checkbox" value="" id="flexCheckChecked"></input>
                                                    <label class="form-check-label" htmlFor="flexCheckChecked">
                                                    Others
                                                </label>
                                            </div>
                                            </> : null}
                                    </div> */}
                                    </>
                                )
                            })}
                        </div>
                        </DialogContent>

                    <DialogActions>
                    <button disabled={props.defLength < 1
                    //  && !others
                    } className={
                                    props.isLoading
                                        ? 'btn cst_btn btn_danger btn-wait'
                                        : 'btn cst_btn btn_danger '
                                } onClick={(e)=>{nextClicked()}} >{props.isLoading ? "" : 'Next'}</button>
                    </DialogActions>
                </Dialog>
                </div>
            </div>
        </main>
    )
}

CommentsComponent = reduxForm({
    form: 'commentsForm',
    validate,
    enableReinitialize: true,
    onSubmitFail: (errors) => scrollToFirstError(errors),
})(CommentsComponent);

export const scrollToFirstError = (errors) => {

    let scroolToError = null;

    //  
    scroller.scrollTo(`${scroolToError}`, {
        duration: 500,
        delay: 1,
        isDynamic: true,
        smooth: true,
        // containerId: 'ContainerElementID',
        offset: -100,
    })

}

export default CommentsComponent;
