import React, { Component } from 'react';
import { routes } from '../../../utility/constants/constants';
import PegAssureComponent from '../../component/PegAssureComponent/PegAssureComponent';
import * as actions from '../../../redux/actions/index';
import NewUser from '../../component/user/NewUser/newUser';
import { connect } from 'react-redux';
import { reset } from 'redux-form';
import { animateScroll as scroll} from 'react-scroll'
import { convertDateToDifferentTZ } from '../../../utility/utility';
import cloneDeep from 'clone-deep';
import moment from 'moment';

class PegAssureContainer extends Component {

    // constructor(props) {
    //     super(props);
    //     this.state = {
    //         // activeUsers: localStorage.getItem('user_active') ? localStorage.getItem('user_active') === 'true' ? true : false : true,
    //     }
    // }


    componentDidUpdate(prevProps, prevState) {
    }

    componentDidMount() {
        scroll.scrollToTop();
    }

    previewFeedbackClicked = (feedback) => {
        this.props.previewFeedback(feedback)
    }

    convertTime = (time) => {
        return moment(time, ["hh:mm A"]).format("HH:mm:ss");
    }

    onSearch = (formValues) => {
        const values = cloneDeep(formValues);
        if (values.start_time && values.end_time) {
            
            values.start_time = this.convertTime(values.start_time);
            values.end_time = this.convertTime(values.end_time);
            
        }
        this.props.searchPegassureFeedbacks(values, 10, 1)
    }


    resetClicked = () => { 
        this.props.reset('searchFeedbackForm');
        this.props.searchPegassureFeedbacks({}, 10, 1)
    }

    render() {

        return (
            <PegAssureComponent
                {...this.props}
                previewFeedbackClicked={this.previewFeedbackClicked}
                onSubmit={this.onSearch}
                resetClicked={this.resetClicked}
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        isLoading: state.adminOrSuperAdminReducer.isLoading,
        pegassureFeedbacks: state.adminOrSuperAdminReducer.pegassureFeedbacks, 
        isPegassureLoading: state.adminOrSuperAdminReducer.isPegassureLoading        
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getPegassureFeedbacks: (values, feedbacks_no_of_rows, page, sorted, filtered) => dispatch(actions.getPegassureFeedbacks(values, feedbacks_no_of_rows, page, sorted, filtered)),
        searchPegassureFeedbacks: (values, feedbacks_no_of_rows, page, sorted, filtered) => dispatch(actions.searchPegassureFeedbacks(values, feedbacks_no_of_rows, page, sorted, filtered)),
        reset: (searchFeedbackForm) => dispatch(reset(searchFeedbackForm)),  // requires form name
        previewFeedback: (values) => dispatch(actions.previewFeedback(values)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PegAssureContainer);
