import React, { Component } from 'react';
import * as actions from '../../../../redux/actions/index';
import { connect } from 'react-redux';
import { actionTypes, reset } from 'redux-form';
import { animateScroll as scroll } from 'react-scroll';
import StandardMatrixLayout from '../../../component/companies/StandardMatrixComponent/StandardMatrixLayout';

class StandardMatrixContainer extends Component {
    constructor(props) {
        super(props);
        this.props.getCompaniesMatrox();
    }

    componentDidMount() {
        this.props.getMatrixList({type:'default'}); 
        scroll.scrollToTop();
    }

    render() {
        return (
            <div>
                <StandardMatrixLayout
                    {...this.props} 
                    addNewStandardMatrix={this.addNewStandardMatrix}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isLoading: state.BRM.isLoading,
        isMatrix: state.BRM.isMatrix,
        isMatrixLoading: state.BRM.isMatrixLoading,
        isCustomMatrixModalOpen: state.BRM.isCustomMatrixModalOpen,
        isStandardMatrixModalOpen:state.BRM.isStandardMatrixModalOpen,
        matrixList: state.BRM.matrixList,
        isStandardMatrix:state.BRM.isStandardMatrix,
        matrixCompanies: state.BRM.matrixCompanies,
        isOpen: true,
        deletemodal: state.BRM.openmodal,
        deleteId:state.BRM.deleteId
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getMatrixList: (data) => dispatch(actions.getMatrixList(data)),
        deleteStandardMatrix: (data,actionType) => dispatch(actions.deleteStandardMatrix(data,actionType)),
        onMatrixChange:(isStandardMatrix)=> dispatch(actions.onMatrixChange(isStandardMatrix)),
        onStandardMatrixModalChange:(isOpen)=> dispatch(actions.onStandardMatrixModalChange(isOpen)),
        onCustomMatrixModalChange:(isOpen)=> dispatch(actions.onCustomMatrixModalChange(isOpen)),
        getCompaniesMatrox: () => dispatch(actions.getCompaniesMatrox()),
        resetSelectedMatrix:() => dispatch(actions.ResetSelectedMatrix()),
        Open_delete_popup:(id)=>dispatch(actions.Open_delete_popup(id)),
        close_delete_popup:()=>dispatch(actions.close_delete_popup())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(StandardMatrixContainer);