import React from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../../../redux/actions/index';
import moment from 'moment';
import NewAssetStockForm from '../../../../component/Inventory/Assets/NewAssetStockForm/NewAssetStockForm';
import { ConvertToFormat, tzUTCDateConverterTool_FormAudit } from '../../../../../utility/TimezoneOperations/timezoneUtility';
const cloneDeep = require('clone-deep');

class NewAssetStockContainer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentDidMount() {
        
        if (this.props && this.props.match && this.props.match.params && this.props.match.params.assetId) {
            this.props.getSingleAssetStock(this.props.match.params.assetId);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {}

    componentWillUnmount() {
        this.props.resetInventoryObjects();
    }

    addNewAsset = (values) => {
        let assetData = cloneDeep(values);
        if(assetData.item && assetData.item.name){
            assetData.item.name = assetData.item.name.trim()
        }
        if (this.props && this.props.match && this.props.match.params && this.props.match.params.assetId) {
             // here we need to add changes
            const service_date = tzUTCDateConverterTool_FormAudit(ConvertToFormat(assetData.item.service_date),"to");
            assetData.item.service_date = service_date;

            if(assetData.item && assetData.item.initial_quantity) {
                assetData.item.quantity = assetData.item.initial_quantity;
                delete assetData.item.initial_quantity
            }
            const subAsset = assetData.item
            const id = this.props.match.params.assetId;
            this.props.updateAssetStock({subAsset}, id);

        } else {
             // here we need to add changes
            const service_date = tzUTCDateConverterTool_FormAudit(ConvertToFormat(assetData.item.service_date,"to"));
            assetData.item.service_date = service_date;
            assetData.item.type = 'asset';
            this.props.createAsset(assetData);

        }       
    }

    onCancelClicked = () => {
        this.props.history.goBack();
    }

    render() {

        return (
            <NewAssetStockForm
                {...this.props}
                onSubmit={this.addNewAsset}
                onCancelClicked={this.onCancelClicked}
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.authReducer.user,
        isLoading: state.InventoryReducer.isLoading,
        isEditAsset: state.InventoryReducer.isEditAsset,
        isUpdateAsset: state.InventoryReducer.isUpdateAsset,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        resetInventoryObjects: () => dispatch(actions.resetInventoryObjects()),
        createAsset: (data) => dispatch(actions.createAsset(data)),
        updateAssetStock: (data, id) => dispatch(actions.updateAssetStock(data, id)),
        getSingleAssetStock: (id) => dispatch(actions.getSingleAssetStock(id)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewAssetStockContainer);