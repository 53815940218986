import axios from 'axios';
import storage from './utility/storage';

const accessToken = storage.get('token');

export const API_VERSION = "api/v1/"

const BASE_URL = () => {
  let url;
  console.log("Environment " + process.env.REACT_APP_ENV);
  
  if (process.env.REACT_APP_ENV === 'development') {
    url = 'https://qa-node.pegdesk.com/'
    // https://stage-api.pegdesk.com/
  } else if (process.env.REACT_APP_ENV === 'qa') {
    url = 'https://qa-node.pegdesk.com/'
  } else if (process.env.REACT_APP_ENV === 'staging') {
    url = 'https://qa-node.pegdesk.com/'
  } else if (process.env.REACT_APP_ENV === 'production') {
    url = 'https://api.pegdesk.com/'
  } else if (process.env.REACT_APP_ENV === 'dev') {
    url = 'https://dev-api.pegdesk.com/'
  } else {
    url = 'https://qa-node.pegdesk.com/'
  }

  return url;
};

const TIME_TRACKER_BASE_URL = () => {
  let url;
  console.log("Environment " + process.env.REACT_APP_ENV);
  if (process.env.REACT_APP_ENV === 'development') {
    url = 'https://stage-payroll.pegdesk.com/'
    // https://stage-api.pegdesk.com/
  } else if (process.env.REACT_APP_ENV === 'qa') {
    url = 'https://stage-payroll.pegdesk.com/'
  } else if (process.env.REACT_APP_ENV === 'staging') {
    url = 'https://stage-payroll.pegdesk.com/'
  } else if (process.env.REACT_APP_ENV === 'production') {
    url = 'https://api-payroll.pegdesk.com/'
  } else if (process.env.REACT_APP_ENV === 'dev') {
    url = 'https://dev-payroll.pegdesk.com/'
  } else {
    url = 'https://stage-payroll.pegdesk.com/'
  }

  return url;
};

const FORM_AUDIT_BASE_URL = () => {
  let url;
  console.log("Environment " + process.env.REACT_APP_ENV);
  if (process.env.REACT_APP_ENV === 'development') {
    url = 'https://stage-payroll.pegdesk.com/'
  } else if (process.env.REACT_APP_ENV === 'qa') {
    url = 'https://stage-payroll.pegdesk.com/'
  } else if (process.env.REACT_APP_ENV === 'staging') {
    url = 'https://stage-payroll.pegdesk.com/'
  } else if (process.env.REACT_APP_ENV === 'production') {
    url = 'https://api-payroll.pegdesk.com/'
  } else if (process.env.REACT_APP_ENV === 'dev') {
    url = 'https://dev-payroll.pegdesk.com/'                    
  } else {
    url = 'https://stage-payroll.pegdesk.com/'
  }

  return url;
};

const BASE_ADMIN_URL = () => {
  let url;
  if (process.env.NODE_ENV === 'development') {
    url = 'http://pegasus.bitcotapps.com/';
  } else if (process.env.NODE_ENV === 'staging') {
    url = 'http://pegasus.bitcotapps.com/'
  } else if (process.env.NODE_ENV === 'production') {
    console.log("production if");
    url = 'http://pegasus.bitcotapps.com/'
  } else {
    url = 'http://pegasus.bitcotapps.com/';
  }
  return url;
};

const instance = axios.create({
  baseURL: BASE_URL()
});

const timeTrackerBaseUrl = axios.create({
  baseURL: TIME_TRACKER_BASE_URL()
});

const formAuditBaseUrl = axios.create({
  baseURL: FORM_AUDIT_BASE_URL()
});

const API_ADMIN = axios.create({
  baseURL: BASE_ADMIN_URL()
});

export default instance;
export { accessToken, timeTrackerBaseUrl, API_ADMIN, formAuditBaseUrl };