import React,{useState, useEffect} from 'react';
import { Input } from '../../../../UI/InputElement/InputElement';
import { scanForDeliveryFormValidator as validate } from '../../../../../../utility/validator/validator';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import ScanForDeliveryFormSkeleton from './ScanForDeliveryFormSkeleton';

import { PlusOutlined } from '@ant-design/icons';
import { Modal, Upload } from 'antd';
import { Descriptions } from 'antd';

let ScanForDeliveryForm = (props) => {

  const imageTypes = ['.jpg', '.jpeg', '.png', '.gif'];

  const { handleSubmit, pristine, reset, submitting, error } = props;
  
  if (props.isFormLoading) {
    return (
        <ScanForDeliveryFormSkeleton />
    )
  } else {
      return (
        <main className="app-content add_account_modal add_frontline_pop">
          <div className="appcontent_Inner user_newQuote pt-0">
            <div className="inspection_content_sec pt-0">
              <div className="add_info_block quote_form" style={{maxWidth: '600px'}}>
                <h1 className="section_title text-center" style={{color:'#2a2a2a', fontSize:'33px', fontWeight:'600'}}> Scan For Delivery </h1>
                <div className="addQuote_form">
                  <form onSubmit={handleSubmit}>
                    <div className="cst_tab_content">
                    <label className="form_title" style={{fontWeight: 'bold'}}> Upload Image <img src="/images/icons/attachment_icon.png" alt="Icon" /> <sup className="cst_sup_txt text_danger">*</sup></label>
                    <div className="addQuote_form mb-2 antdImgUpload" style={{maxWidth: '600px', border: 'dashed', borderColor: props.errorThrower && props.errorThrower == true ? '#dd2726bf' : '#71002f73', borderWidth: '3px' }}>
                      <Upload
                        // action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                        listType="picture-card"
                        fileList={props.storedFileList}
                        onPreview={props.handlePreview}
                        onChange={props.handleChange}
                        // maxCount={10}
                        multiple
                        showUploadList={{ showErrorList: false, removeIcon: () => null, }}
                        accept={imageTypes.join(',')}
                        toolTipVisible={false}
                        supportServerRender={false}
                      >
                        <div> <PlusOutlined /> <div style={{ marginTop: 8 }}> Upload </div> </div>
                      </Upload>
                    </div>
                    {props.errorThrower && props.errorThrower == true ? <span style={{color: '#dd2726', fontSize: '13px'}}>This field is required.</span> : null}

                    <div className="form-group mt-4">
                      <div className='pickingTicketId'>
                        <Descriptions size='small' bordered>
                          <Descriptions.Item label="Picking Ticket Id"> {props.pickingTicketId} </Descriptions.Item>
                        </Descriptions>
                      </div>
                    </div>

                    <div className="form-group mt-4">
                      <label className="form_title" style={{fontWeight: 'bold'}}> Are there any additional products in the storage area <sup className="cst_sup_txt text_danger">*</sup></label>
                      <Input type="radio" labelClass="custom-control-label" forLable={"isExtrasYes"} value={"true"} label={"Yes"} className="custom-control-input" id="isExtrasYes" name="deliveryItems[ticket][is_extras]" />
                      <Input type="radio" labelClass="custom-control-label" forLable={"isExtrasNo"} value={"false"} label={"No"} className="custom-control-input" id="isExtrasNo" name="deliveryItems[ticket][is_extras]" />
                    </div>

                    <Modal open={props.previewFileQuery.previewOpen} title={props.previewFileQuery.previewTitle} footer={null} onCancel={props.handleCancel}>
                      <img alt="example" style={{width: '100%'}} src={props.previewFileQuery.previewImage} />
                    </Modal>

                    </div>
                    <div className="modal-footer d-block">
                      <button data-test="submit_button" type="submit" className={props.isButtonLoading ? "btn cst_btn btn_danger btn-wait w-50" : "btn_box cst_btn btn_danger w-50"} >{props.isButtonLoading ? "" : "Scan For Delivery"}</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </main>
      );
  };
};

const mapStateToProps = (state) => {

  let deliveryItems = {};
  deliveryItems = {
    ticket: {
      status: "completed",
      is_extras: 'false'
    },
  }

  return { initialValues: { deliveryItems } };

};


ScanForDeliveryForm = reduxForm({
  form: 'scanForDeliveryForm',
  validate,
  enableReinitialize: true,
})(ScanForDeliveryForm);

ScanForDeliveryForm = connect(mapStateToProps)(ScanForDeliveryForm);

export default ScanForDeliveryForm;