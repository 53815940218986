import React, { useEffect, useState } from 'react';
import { formValueSelector, getFormValues, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Input } from '../../../UI/InputElement/InputElement';
import { convertDateToPickerFormat } from '../../../../../utility/utility';
import { userRoles } from '../../../../../utility/constants/constants';
import ShiftScheduleTable from './ShiftScheduleTable';

let ShiftSchedule = (props) => {

    const { handleSubmit, pristine, reset, submitting, error } = props;

    return (
        <>
            <main className="app-content wraper_content inspection_wraper">
                <div className="tab_header search_filter_cont inspection_tab_content ticket_filter">
                    <form onSubmit={handleSubmit}>
                        <input type="hidden" value="something" />
                        <div className="row">
                            <div className="col-xl-2 col-lg-4 col-md-6 col-sm-6">
                                <div className="app-search">
                                    <Input dataTest="search_by_description" name="job" className="fil_search_input" type="search" placeholder="Job" />
                                    <button className="app-search__button">
                                        <i class="fa fa-search" aria-hidden="true"></i>
                                    </button>
                                </div>
                            </div>

                            <div className="col-xl-2 col-lg-4 col-md-6 col-sm-6" style={{display: 'none'}}>
                                <div className="app-search">
                                    <Input dataTest="search_by_description" name="user" className="fil_search_input" type="text" placeholder="Frontline User" />
                                    <button className="app-search__button">
                                        <i class="fa fa-search" aria-hidden="true"></i>
                                    </button>
                                </div>
                            </div>
                            <div className="col-xl-2 col-lg-4 col-md-6 col-sm-6">
                                <label>
                                    <Input name="fromDate" dateFormat="MM-dd-yyyy" placeholder="From Date" datePickerType={"react-datepicker"} type="date" className="form-control input-modifier" />
                                    <div class="input-group-append cst_group_append">
                                        <span class="input-group-text" id="basic-addon2"><i class="fa fa-calendar"></i></span>
                                    </div>
                                </label>
                            </div>
                            <div class="col-xl-2 col-lg-4 col-md-6 col-sm-6">
                                <label>
                                    <Input name="toDate" minDate={props.formStates?.fromDate} dateFormat="MM-dd-yyyy" placeholder="To Date" datePickerType={"react-datepicker"} type="date" className="form-control input-modifier" />
                                    <div class="input-group-append cst_group_append">
                                        <span class="input-group-text" id="basic-addon2"><i class="fa fa-calendar"></i></span>
                                    </div>
                                </label>
                            </div>

                            <div className="col-xl-2 col-lg-6 col-sm-3 col-12">
                                <button data-test="filter_go_btn" style={{ minWidth: '90px' }} className={"btn cst_btn btn_danger mr-2  account_btn"} id="submit" type="submit">{"Go"} </button>
                                <button data-test="filter_reset_btn" style={{ minWidth: '90px' }} onClick={props.resetClicked} className={"btn cst_btn btn-outline-secondary account_btn"} id="reset" type="reset">{"Reset"}</button>
                            </div>
                            {props.user.role === userRoles.FRONTLINE ? null : <div className="col-xl-2 col-lg-4 col-sm-3 col-12 text-right quotes_btn_wrap">
                                <button type='button' onClick={() => { props.handleNewShiftClicked() }} className="btn btn-secondary cst_btn"><i className="fa fa-plus icn_plus"></i> Add Shift </button>
                            </div>}
                        </div>

                        <div className='row' style={{ marginTop: '10px' }}>

                        </div>
                        <div className="more_filter_content">
                            <div id="morefilterBtn" className="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                                <div className="card-body">
                                    <div className="row">
                                        {/* <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                                            <div className="app-search">
                                                <Input name="region" className="fil_search_input" type="search" placeholder="Search By Region" />
                                                <button className="app-search__button">
                                                    <i class="fa fa-search" aria-hidden="true"></i>
                                                </button>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>


                <section className="account_sec inspection_tab_content appcontent_Inner">
                    {/* <div>
                        <button style={{ float: 'right' }}>Enable Filter</button>
                    </div> */}
                    {/* <CSVLink filename="Accounts.csv" className="csv_btn btn_danger " data={convertAccountsDataToCsvType(props.accountsList)} >Export Csv</CSVLink><br /><br /><br /> */}
                    <div className="quotes_table_content accounts_table_contnet table-responsive">

                        <div className="table quotes_table user_react_table">
                            <ShiftScheduleTable
                                {...props}
                            />
                        </div>

                    </div>
                    {/* <div className="clearfix"></div>
                <div className="modal-footer">
                    <button type="button" className="btn btn_danger cst_btn">Save</button>
                    <button type="button" className="btn btn-outline-secondary cst_btn" data-dismiss="modal">Cancel</button>
                </div> */}
                </section>
            </main>
            <Dialog
                open={props.openFrequentTimeTracker_deleteModal}
                onClose={props.handleFrequentTimeTracker_closeDeleteModal}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title" style={{ fontSize: '13', color: '#06080f' }}>
                    <h3 style={{ color: '#ab385e' }} data-test="modal_delete_title">Are you sure?</h3>
                </DialogTitle>

                <DialogActions>
                    <button data-test="modal_delete" style={{ minHeight: '36px', minWidth: '80px' }} onClick={() => props.deleteMainShift(props.deleteFrequentTimeTracker_object)} disabled={props.isLoading} className={props.isLoading ? "btn cst_btn btn_danger mr-2 btn-wait" : "btn cst_btn btn_danger mr-2"} id="delete" type="delete">{props.isLoading ? "" : "Delete"}</button>
                    <button data-test="modal_cancel" style={{ minHeight: '36px', minWidth: '80px' }} onClick={props.handleFrequentTimeTracker_closeDeleteModal} className={"btn cst_btn btn-outline-secondary"} id="cancel" type="cancel">Cancel</button>
                </DialogActions>
            </Dialog>
        </>

    )
}

const mapStateToProps = (state) => {
    let user = state.TimeTrackerModReducer.shiftSchedule_searchValues && state.TimeTrackerModReducer.shiftSchedule_searchValues.user ? state.TimeTrackerModReducer.shiftSchedule_searchValues.user : "";
    let job = state.TimeTrackerModReducer.shiftSchedule_searchValues && state.TimeTrackerModReducer.shiftSchedule_searchValues.job ? state.TimeTrackerModReducer.shiftSchedule_searchValues.job : "";
    let fromDate = state.TimeTrackerModReducer.shiftSchedule_searchValues && state.TimeTrackerModReducer.shiftSchedule_searchValues.fromDate ? convertDateToPickerFormat(state.TimeTrackerModReducer.shiftSchedule_searchValues.fromDate) : "";
    let toDate = state.TimeTrackerModReducer.shiftSchedule_searchValues && state.TimeTrackerModReducer.shiftSchedule_searchValues.toDate ? convertDateToPickerFormat(state.TimeTrackerModReducer.shiftSchedule_searchValues.toDate) : "";

    return { initialValues: { user, job, fromDate, toDate }, formStates: getFormValues('searchShiftSchedule')(state) }
}

ShiftSchedule = reduxForm({
    form: 'searchShiftSchedule',
    // validate,
    enableReinitialize: true
})(ShiftSchedule);
const selector = formValueSelector('searchShiftSchedule')
ShiftSchedule = connect(state => {
    const { fromDate, toDate } = selector(state, 'fromDate', 'toDate')
    const selectedValues = { fromDate, toDate }
    return {
        selectedValues
    }
})(ShiftSchedule)
ShiftSchedule = connect(mapStateToProps)(ShiftSchedule)

export default ShiftSchedule;