import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { getFormValues, reduxForm, change} from 'redux-form';
import { Input } from '../../UI/InputElement/InputElement';
import { inventoryPullRequestValidator as validate } from '../../../../utility/validator/validator';
import InventoryPullRequestFormSkeleton from './InventoryPullRequestFormSkeleton';
import Stack from '@mui/material/Stack';
import { Badge } from 'antd';
import { makeFirstLetterCapital } from '../../../../utility/utility';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import storage from '../../../../utility/storage';



let InventoryPullRequestForm = (props) => {
    // for triggerring validation for items and quantity field
    useEffect(() => {
        setTimeout(() => {
            let ele = document.getElementById('triggerPoint-0')
            if (ele) {
                ele.click();
            }
        }, 500);
    }, [])
    const dispatch = useDispatch()
    const inventoryRole = useSelector((state) => state.InventoryReducer.inventoryRole)
    useEffect(() => {
        dispatch(change('ticket[supervisor_id]', ''))
    },[inventoryRole])
    

    const getCommonJobsAccountName = (props) => {
        if (props.commonJobsAccountList && props.commonJobsAccountList.length > 0) {
            return props.commonJobsAccountList && props.commonJobsAccountList.map((job) => {
                return {
                    value: job.id,
                    label: job.name
                }
            })
        } else {
            return []
        }
    };

    const getInventoryItemNamesList = (props) => {
        if (props.inventoryItemNamesList && props.inventoryItemNamesList.length > 0) {
            return props.inventoryItemNamesList && props.inventoryItemNamesList.map((item) => {
                return {
                    value: item.id,
                    label: makeFirstLetterCapital(item.name),
                    quantity: item.item.initial_quantity
                }
            })
        } else {
            return []
        }
    };

    const getJobsUsersList = (props) => {
        if (props.jobUsersList && props.jobUsersList.length > 0) {
            return [...new Set(props.jobUsersList.flatMap(e => e.inventory_access).filter(e => e))].reduce((acc, curr) => {debugger
                acc[curr] = props.jobUsersList.filter(e => e.inventory_access && e.inventory_access.includes(curr)).map(user => {debugger
                    return {
                        value: user.id,
                        label: makeFirstLetterCapital(user.first_name) + ' ' + makeFirstLetterCapital(user.last_name)
                    }
                });
                return acc
            }, {});
        } else {
            return {}
        }
    };

    const getInventoryLocationList = (props) => {
        if (props.inventoryLocationList && props.inventoryLocationList.length > 0) {
            return props.inventoryLocationList && props.inventoryLocationList.map((location) => {
                return {
                    value: location.id,
                    label: makeFirstLetterCapital(location.name)
                }
            })
        } else {
            return []
        }
    };
    const router = useHistory()
    const [commonJobsList, setCommonJobsList] = useState(getCommonJobsAccountName(props));
    const [itemNamesList, setItemNamesList] = useState(getInventoryItemNamesList(props));
    const [jobsUserList, setJobsUserList] = useState(getJobsUsersList(props));
    console.log('jobsUserList', jobsUserList);
    
    const [locationList, setLocationList] = useState(getInventoryLocationList(props));
    const [maxQuantity, setMaxQuantity] = useState([])
    const [addExistItemSlots, setAddExistItemSlots] = useState(['']);
    const [isEmergencyRequest, setIsEmergencyRequest] = useState(false);
    const [isBulkstorage, setIsBulkStorage] = useState(false)
    useEffect(() => {
        setCommonJobsList(getCommonJobsAccountName(props))
    }, [props.commonJobsAccountList])

    useEffect(() => {
        setItemNamesList(getInventoryItemNamesList(props))
    }, [props.inventoryItemNamesList])

    useEffect(() => {
        setJobsUserList(getJobsUsersList(props))
    }, [props.jobUsersList])

    useEffect(() => {
        setLocationList(getInventoryLocationList(props))
    }, [props.inventoryLocationList])

    useEffect(() => {
        const updateTitleFromRouterParams = () => {
            const params = router.location.search.split("?")[1]
            if (params) {
                const arrayOfParams = params.split("&")
                let bulkstorage = false
                let bulkParams = arrayOfParams.find((item) => item.includes("bulkstorage"))
                if (bulkParams) {
                    bulkstorage = bulkParams.split("=")[1] == 'true'
                    setIsBulkStorage(bulkstorage)
                } else {
                    setIsBulkStorage(false)
                }

            }
            else {
                setIsBulkStorage(false)
            }
        }
        updateTitleFromRouterParams()
    }, [router.location.search])

    const handleFilterSelectedExistItems = (data, index) => {
        let captureSelectedItem = JSON.parse(JSON.stringify(addExistItemSlots));
        captureSelectedItem[index] = data.value
        setAddExistItemSlots(captureSelectedItem);
    }



    const handleQuantityFinalizer = (data, index) => {
        let captureSelectedItemQuantity = JSON.parse(JSON.stringify(maxQuantity));
        captureSelectedItemQuantity[index] = data.quantity
        setMaxQuantity(captureSelectedItemQuantity);
    };

    const handleCreateExistItemSlots = () => {
        
        let slots = [...addExistItemSlots];
        slots.push({
            itemId: '',
            itemQuantity: ''
        });
        setAddExistItemSlots(slots);
        setTimeout(() => {
            let ele = document.getElementById(`triggerPoint-${slots.length - 1}`)
            if (ele) {
                ele.click();
            }
        }, 100);
    }

    const handleDeleteExistItemSlots = (index) => {
        let itemSlots = [...addExistItemSlots];
        itemSlots.splice(index, 1);
        setAddExistItemSlots(itemSlots);

        let quantitySlots = [...maxQuantity];
        quantitySlots.splice(index, 1);
        setMaxQuantity(quantitySlots);

        if ((itemSlots && itemSlots.length) && (props.formStates.ticket && props.formStates.ticket.items)) {
            let items = props.formStates.ticket.items;
            items.splice(index, 1);
            change("ticket[items]", items);
        } else {
            change("ticket[items]", [{ itemId: '', itemQuantity: '' }]);
        }
    };

    const isEmergencyRequestClicked = (value) => {
        if (props.formStates && props.formStates.ticket && props.formStates.ticket.delivery_at) {
            // delete props.formStates.ticket.delivery_at
        }
        if (value == true) {
            setIsEmergencyRequest(true);
        } else if (value == false) {
            setIsEmergencyRequest(false);
        }
    };

    const noOptionsMessage = (a, b) => {
        return 'No options';
    }
    const { handleSubmit, pristine, reset, submitting, error, submitFailed, change } = props;

    const handleLocationChange = () => {
        setAddExistItemSlots([''])
        change("ticket[items]", [{ id: '', quantity: '',description:"",packaged:"",triggerPoint:"false" }]);
        setMaxQuantity([])
    }

    

    if (props.isFormLoading) {
        return (
            <InventoryPullRequestFormSkeleton />
        )
    } else {
        return (
            <main className="app-content wraper_content user_newQuote add_account_modal add_New_Accont add_newAccount">
                <div className="inspection_content_sec appcontent_Inner">
                    <div className="container-fluids">
                        <div className="quote_form ticket_form" style={{ maxWidth: '900px' }}>
                            <div className="row">
                                <div className="col-sm-12 text-center"> <h2 className="md_title" > {isBulkstorage ? "Bulk Storage Supplies Usage Form" : "Inventory Pull Request Form"}  </h2></div>
                            </div>
                            <div className="addQuote_form">
                                <form className="form_content" onSubmit={handleSubmit} >
                                    <div className="cst_tab_content">

                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="form_title"> Job Name <sup className="cst_sup_txt text_danger">*</sup></label>
                                                    <Input type="inputSelect" placeholder="Select Job Name" noOptionsMessage={noOptionsMessage} onChange={(e) => { props.getJobUsersInfo(e.value) }} options={commonJobsList} name="ticket[account_id]" />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="form_title"> Pickup Date <sup className="cst_sup_txt text_danger">*</sup></label>
                                                    <div className="input-group cst_input_group col-lg-14 pl-0">
                                                        <div style={{ width: '100%' }}>
                                                            <Input
                                                                type="date"
                                                                datePickerType={"react-datepicker"}
                                                                minDate={isEmergencyRequest ? new Date().setDate(new Date().getDate() + 1) : new Date().setDate(new Date().getDate() + 7)}
                                                                dateFormat="MM-dd-yyyy"
                                                                className="form-control input-modifier"
                                                                placeholder="Select Date"
                                                                name="ticket[delivery_at]"
                                                            />
                                                        </div>
                                                        <div className="input-group-append cursor-pointer" style={{ float: "right", position: "absolute", right: 0, height: "45px", top: 0 }}>
                                                            <span class="input-group-text" id="basic-addon2"> <i class="fa fa-calendar"></i> </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="form_title"> Select Fulfillment Manager Location <sup className="cst_sup_txt text_danger">*</sup></label>
                                                    <Input type="inputSelect" placeholder="Select Fulfillment Manager Location" noOptionsMessage={noOptionsMessage} onChange={(e) => { props.getItemsBasedOnLocation(e.value);handleLocationChange()  }} options={locationList} name="ticket[location_id]" />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="form_title"> Select {`${inventoryRole === 'manager' ? 'Supervisor' : 'Manager'}`} <sup className="cst_sup_txt text_danger">*</sup></label>
                                                    <Input type="inputSelect" placeholder="Select Supervisor" noOptionsMessage={noOptionsMessage} options={inventoryRole === 'manager' ? jobsUserList['supervisor'] : jobsUserList['manager'] || []} name="ticket[supervisor_id]" />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="form-group">
                                            {addExistItemSlots.map((time, index) => {
                                                return (
                                                    <>
                                                        <div className="input-group mb-3" key={index}>
                                                            <div className="mb-1 mt-1" style={{ width: '100%', display: 'flex' }}>
                                                                <div className="mr-3" style={{ width: '50%' }}>
                                                                    <label className="form_title"> Item <sup className="cst_sup_txt text_danger">*</sup></label>
                                                                    <Input
                                                                        type="inputSelect"
                                                                        placeholder="Select Item"
                                                                        noOptionsMessage={noOptionsMessage}
                                                                        onChange={(data) => {
                                                                            handleFilterSelectedExistItems(data, index);
                                                                            handleQuantityFinalizer(data, index);
                                                                        }}
                                                                        options={itemNamesList.filter((data) => {
                                                                            return !addExistItemSlots.includes(data.value)
                                                                        })}
                                                                        className="mg-t"
                                                                        name={`ticket[items][${index}][id]`}
                                                                    />
                                                                </div>

                                                                <div className="ml-3" style={{ width: '50%' }}>
                                                                    <Stack direction="row" spacing={1}>
                                                                        <label className="form_title"> Quantity <sup className="cst_sup_txt text_danger">*</sup>&nbsp;
                                                                            {maxQuantity && maxQuantity.length > 0 ?
                                                                                <span className="badge badge-pill badge-warning">
                                                                                    {maxQuantity && maxQuantity[index] == 0 ? (
                                                                                        <div>
                                                                                            <span style={{ color: '#dd2726', fontSize: '13px' }}>Item not available</span>
                                                                                        </div>
                                                                                    ) : (
                                                                                        <div>
                                                                                            <Badge color="#787878" count={maxQuantity ? maxQuantity[index] : 0} showZero />&nbsp;Quantity Available
                                                                                        </div>
                                                                                    )}




                                                                                </span>
                                                                                : <div>
                                                                                    <span style={{ color: '#dd2726', fontSize: '13px' }}> <br /> </span>
                                                                                </div>}
                                                                        </label>
                                                                    </Stack>
                                                                    <Input dataTest="" type="numberWithIncDec" min={1} max={maxQuantity[index] || 0} className={`form-control input-modifier ${maxQuantity.length == 0 ? "mg-tt" : ""}`} placeholder="Enter Quantity" name={`ticket[items][${index}][quantity]`} disabled={maxQuantity && maxQuantity[index] == 0 ? true : false} />
                                                                </div>
                                                                {/* <div style={{ marginTop: '35px' }} role="group" aria-label="Basic example">
                                                                    {index != 0 && <button type="button" class="btn btn-dark" onClick={e => handleDeleteExistItemSlots(index)}><i className="far fa-trash-alt"></i></button>}
                                                                </div> */}
                                                                <div className="ml-4" style={{ width: '50%' }}>
                                                                    <label className="form_title"> Item Description <sup className="cst_sup_txt text_danger">*</sup></label>
                                                                    <Input dataTest="" type="text" className="form-control input-modifier mg-t" placeholder="Type here" name={`ticket[items][${index}][description]`} />
                                                                </div>
                                                                <div className="ml-4" style={{ width: '55%' }}>

                                                                    <label className="form_title"> Packaged <sup className="cst_sup_txt text_danger">*</sup></label>
                                                                    <Input type="inputSelect" placeholder="Select Package" noOptionsMessage={noOptionsMessage} options={[{ value: "1", label: "Carton" }, { value: "2", label: "Case" }, { value: "3", label: "Each" }]} name={`ticket[items][${index}][packaged]`} className="mg-t" />

                                                                </div>
                                                                
                                                                <div style={{ marginTop: '53px' }} className="ml-4 " role="group" aria-label="Basic example">
                                                                    {index != 0 ? <button type="button" class="btn btn-dark" onClick={e => handleDeleteExistItemSlots(index)}><i className="far fa-trash-alt"></i></button> : <button type="button" class="btn " style={{width: "37px",backgroundColor:"transparent"}} onClick={e => handleDeleteExistItemSlots(index)}></button>}
                                                                </div>
                                                            </div>
                                                            
                                                            <div style={{ display: 'none' }}>
                                                                <Input
                                                                    type="radio"
                                                                    forLable={`triggerPoint-${index}`}
                                                                    value={"false"}
                                                                    className="custom-control-input"
                                                                    id={`triggerPoint-${index}`}
                                                                    name={`ticket[items][${index}][triggerPoint]`}
                                                                />
                                                            </div>

                                                        </div>
                                                    </>
                                                )
                                            })
                                            }
                                            {addExistItemSlots && addExistItemSlots.length == itemNamesList.length ? null : (
                                                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                    <button
                                                        type='button'
                                                        className='btn btn-outline-danger'
                                                        style={{ fontSize: '14px', fontWeight: 'bolder', }}
                                                        onClick={() => { handleCreateExistItemSlots(); }}
                                                    >
                                                        + Add More New Item
                                                    </button>
                                                </div>
                                            )}
                                        </div> 

                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="form_title"> Building <sup className="cst_sup_txt text_danger">*</sup></label>
                                                    <Input type="text" placeholder="Building" name="ticket[building]" className="form-control input-modifier" />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="form_title"> Emergency Request <sup className="cst_sup_txt text_danger">*</sup></label>
                                                    <Input type="radio" onClick={() => isEmergencyRequestClicked(true)} labelClass="custom-control-label" forLable={"customRadio1"} value={"true"} label={"Yes"} className="custom-control-input" id="customRadio1" name="ticket[is_emergency]" />
                                                    <Input type="radio" onClick={() => isEmergencyRequestClicked(false)} labelClass="custom-control-label" forLable={"customRadio2"} value={"false"} label={"No"} className="custom-control-input isEmergencyNo" id="customRadio2" name="ticket[is_emergency]" />
                                                </div>
                                            </div>
                                            {
                                                isEmergencyRequest &&
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label className="form_title"> Select Approver <sup className="cst_sup_txt text_danger">*</sup></label>
                                                        <Input type="inputSelect" placeholder="Select approver" noOptionsMessage={noOptionsMessage} options={jobsUserList['approver'] || []} name="ticket[approver_id]" />
                                                    </div>
                                                </div>
                                            }
                                            {/* <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="form_title"> Packaged <sup className="cst_sup_txt text_danger">*</sup></label>
                                                    <Input type="inputSelect" placeholder="Select Supervisor" noOptionsMessage={noOptionsMessage} options={[{ value: "1", label: "Carton" }, { value: "2", label: "Case" }, { value: "3", label: "Each" }]} name="ticket[packaged]" />
                                                </div>
                                            </div> */}
                                        </div>

                                        <div className="row">
                                            {/* <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="form_title"> Emergency Request <sup className="cst_sup_txt text_danger">*</sup></label>
                                                    <Input type="radio" onClick={() => isEmergencyRequestClicked(true)} labelClass="custom-control-label" forLable={"customRadio1"} value={"true"} label={"Yes"} className="custom-control-input" id="customRadio1" name="ticket[is_emergency]" />
                                                    <Input type="radio" onClick={() => isEmergencyRequestClicked(false)} labelClass="custom-control-label" forLable={"customRadio2"} value={"false"} label={"No"} className="custom-control-input isEmergencyNo" id="customRadio2" name="ticket[is_emergency]" />
                                                </div>
                                            </div>
                                            {
                                                isEmergencyRequest &&
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label className="form_title"> Select Approver <sup className="cst_sup_txt text_danger">*</sup></label>
                                                        <Input type="inputSelect" placeholder="Select approver" noOptionsMessage={noOptionsMessage} options={jobsUserList['approver'] || []} name="ticket[approver_id]" />
                                                    </div>
                                                </div>
                                            } */}

                                            {/* <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="form_title"> Reached Minimum <sup className="cst_sup_txt text_danger">*</sup></label>
                                                    <Input type="radio" labelClass="custom-control-label" forLable={"customRadio10"} value={"true"} label={"Yes"} className="custom-control-input" id="customRadio10" name="ticket[isReachedMinimum]" />
                                                    <Input type="radio" labelClass="custom-control-label" forLable={"customRadio20"} value={"false"} label={"No"} className="custom-control-input isEmergencyNo" id="customRadio20" name="ticket[isReachedMinimum]" />
                                                </div>
                                            </div> */}
                                        </div>

                                    </div>
                                    <div className="modal-footer">
                                        <button data-test="submit_button" type="submit" disabled={props.isButtonLoading} className={props.isButtonLoading ? "btn cst_btn btn_danger mr-2 btn-wait" : "btn cst_btn btn_danger mr-2"} >{props.isButtonLoading ? "" : "Submit"}</button>
                                        <button data-test="cancel_button" type="button" className="btn btn-outline-secondary cst_btn" onClick={() => { props.onCancelClicked() }}>Cancel</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </main >
        )
    }
}

const mapStateToProps = (state) => {
    let ticket = {}
    ticket = {
        is_emergency: "false",
    }

    return { initialValues: { ticket }, formStates: getFormValues('inventoryPullRequestForm')(state) }
}

InventoryPullRequestForm = reduxForm({
    form: 'inventoryPullRequestForm',
    validate,
    enableReinitialize: true,
    touchOnBlur : false
})(InventoryPullRequestForm);

InventoryPullRequestForm = connect(mapStateToProps)(InventoryPullRequestForm)

export default InventoryPullRequestForm;