import React, { useState, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
// import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { useHistory, useLocation } from 'react-router-dom'
import { getAllLogbookAreasOccupiedWithPlaybook } from '../../../../redux/actions';
import PegassurePlaybookAreaListComponent from '../../../component/PegassurePlaybookModuleComponent/PegassurePlaybookAreaListComponent/PegassurePlaybookAreaListComponent';

let PegassurePlaybookAreaListContainer = () => {

    const { pathname } = useLocation();
    const history = useHistory()
    const dispatch = useDispatch()
    const { isLoading, selectedPlaybookInfo, playbookAreaList } = useSelector((state) => state.PegassurePlaybookReducer);

    useEffect(() => {
        dispatch(getAllLogbookAreasOccupiedWithPlaybook(pathname.split('/')[2]));
    }, []);

    return (
        <>
            <main className="app-content wraper_content inspection_wraper categories_blc">
                <div className="tab_header search_filter_cont inspection_tab_content appcontent_inner">
                    <form>
                        <input type="hidden" value="something" />
                        <div className="row mt-2">
                            <div class="col">
                                <span className='d-flex justify-content-center' style={{ fontFamily: 'system-ui', fontSize: '230%', color: '#4e4e4e' }}> Playbook's Logbook Areas </span>
                            </div>
                        </div>
                    </form>
                </div>

                <section className="account_sec user_screen inspection_tab_content">
                    <PegassurePlaybookAreaListComponent
                        {...{ isLoading, selectedPlaybookInfo, playbookAreaList }}
                    />
                </section>
            </main>
        </>
    );
};

export default PegassurePlaybookAreaListContainer;