import React, { useEffect } from "react";
import CustomMatrixHoc from "./CustomMatrix";
import { addCustomMatrix, getJobBasedMatrix, updateStandardMatrix } from "../../../../../redux/actions/BrmActions/BrmAction";
import { useDispatch, useSelector } from "react-redux";
import errorHandler from "../../../../../utility/errorHandler/errorHandler";
import {getAllJob } from '../../../../../redux/actions/FormAuditModAction/TeamManagementAction/teamManagementAction'
const AddCustomMatrixContainer = (props) => {
  const  getUpdatedMatrixList = useSelector((state) => state.BRM.updateMatrixList );
  const { matrixCompanies } = useSelector((state) => state.BRM);
  const dispatch = useDispatch();
  
  let defaultForm = {};
  if(props?.isEdit)
    {
       defaultForm = {
        data: {
        frequency: getUpdatedMatrixList?.frequency ? getUpdatedMatrixList?.frequency : "",
        name: getUpdatedMatrixList?.name ? getUpdatedMatrixList?.name : "",
        matrix: getUpdatedMatrixList?.matrix ? getUpdatedMatrixList?.matrix : "0",
        company_id:getUpdatedMatrixList?.companyMatrix?.company_id ? getUpdatedMatrixList?.companyMatrix?.company_id + ""  : "",
        active_status:getUpdatedMatrixList?.active_status =="active" ?"1" :"0",
        account_id:getUpdatedMatrixList?.companyMatrix?.account? getUpdatedMatrixList?.companyMatrix?.account?.id : "",
        }
      };
    }else{
       defaultForm = {
        data: {
        frequency:"",
        name: "",
        matrix: "",
        type: "custom",
        company_id:"",
        account_id:"",
        tier:""
        }
      };
    }
  
  const onSubmit = (data) => {
    
    const Filter_TierArray = Object.keys(data.data).filter((item) =>
      (item.includes("name") || item.includes("frequency")) && data.data[item]=="none"
    );


    if(Filter_TierArray.length){
      errorHandler("You cant choose N/A for custom matrix")
      return false;
    }
    const Company_Tier = matrixCompanies.find(company => company.id === data.data.company_id);
    if (props?.isEdit) {
      
      const customupdteData = {
        data: {
          frequency: data?.data?.frequency ? data?.data?.frequency : "",
          name: data?.data?.name ? data?.data?.name : "",
          matrix: data?.data?.matrix ? data?.data?.matrix : "0",
          active_status:Boolean(+data?.data.active_status) ? "active" :"inactive",
          account_id:data?.data?.account_id ? data?.data?.account_id:""
          }
      }
      
      dispatch(updateStandardMatrix(customupdteData, getUpdatedMatrixList?.id)); 
      setTimeout(()=>{
        
        if(props?.setIsChecked){
          props.setIsChecked(true)
        }
      },2000)
    } else {
      const {tier,...remain}=data.data

      const dataWithCompanyTier = {
        ...remain,
        active_status:"active",
        matrix: Company_Tier ? Company_Tier.company_tier : undefined
      };   

      dispatch(addCustomMatrix({data:dataWithCompanyTier}))
      setTimeout(()=>{
        if(props?.setIsChecked){
          props.setIsChecked(true)
        }
      },2000)
    }
  };

  
  return (
    <CustomMatrixHoc
      formSubmittedCallback={onSubmit}
      elements={defaultForm}
      isEdit={props.isEdit}
      // isView={isView}
      modalUpdate={true}
      handleClose={props.resetSelectedMatrix}
      {...props}
    />
  );
};

export default AddCustomMatrixContainer;