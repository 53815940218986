import React, { useEffect, useState } from 'react';
import { reduxForm, FieldArray, getFormValues } from 'redux-form';
import { RolesOptions, routes, SelectColorOptions } from '../../../../utility/constants/constants';
import { Input } from '../../UI/InputElement/InputElement';
import { newPercentageRatingValidator as validate } from '../../../../utility/validator/validator';
import { RatingTypes } from '../../../../utility/constants/constants';
import isEmpty from '../../../../utility/IsEmptyValidator';
import { connect } from 'react-redux';
import { showConfirmAlert } from '../../../../utility/successAlert/ConfirmAlert';
import ReactTooltip from 'react-tooltip';
import Select from 'react-select';
import '../../../../styles/custom.css';
var moment = require('moment');
const cloneDeep = require('clone-deep');

const LineItems = (props) => {
    var radios = document.querySelectorAll('input[type="radio"]');
for (var i=0; i<radios.length; i++) {
    radios[i].onfocus = function() {
        this.blur();
    }
}
    const { fields } = props;
    const { isEdit } = props[0];
    //  
    const deleteLineItem = (fields, index, lineItem, ratingId) => {
        if (isEdit && props[0].updateRating.rating_options_attributes.length > index) {
            props[0].updateRating.rating_options_attributes.splice(index, 1);
            // props[0].updateInspectionForm.sections_attributes[props[0].index].line_items_attributes.splice([index], 1);
            props[0].setUpdateRating(props[0].updateRating);
            props[0].deleteRatingLineItem(lineItem.id, ratingId);
        }
        fields.remove(index);
    }

    const SelectColorOptions = [
        {
            id: 'orange',
            name:"Orange"
        }, {
            id: 'red',
            name:"Red"
        }, {
            id: 'light_green',
            name:"Light Green"
        }, {
            id: 'green',
            name:"Green"
        }, {
            id: 'yellow',
            name:"Yellow"
        }, {
            id: 'blue',
            name:"Blue"
        }, 
    ]

    

    

      const groupStyles = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      };
      const groupBadgeStyles = {
        backgroundColor: '#EBECF0',
        borderRadius: '5em',
        color: '#172B4D',
        display: 'inline-block',
        fontSize: 12,
        fontWeight: 'normal',
        lineHeight: '1',
        minWidth: 1,
        padding: '0.16666666666667em 0.5em',
        textAlign: 'center',
      };

      

      const formatGroupLabel = data => (
        <div style={groupStyles}>
          <span>{data.label}</span>
          <span style={groupBadgeStyles}>{data.options.length}</span>
        </div>
      );



    return (
        <>
            <div className="card-body rating_card_body" >
                <div className="contact_info rating_info percentage_blc">
                    {fields.map((member, index) => { 
                        return (
                            <div className="row" key={index} data-test="rating_choice_form">
                            <div className="col-sm-6 col-md-6 col-lg-3 col-xl-3 gutter">
                                <div className="form-group">
                                    {/* {index > 0 ? null : <label className="lable_modifier">Line Item</label>} */}
                                    <label className="lable_modifier">Line Item</label>
                                    <Input dataTest={`line_item_input_${index}`} type="text" name={`${member}.name`} className="form-control input-modifier bg_gray" id="" aria-describedby="emailHelp" placeholder="Label Name" />
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-3 col-xl-3 gutter">
                                <div className="form-group">
                                    {/* {index > 0 ? null : <label className="lable_modifier">Percent</label>} */}
                                    <label className="lable_modifier">Percent(≤100)</label>
                                    <Input dataTest={`percent_input_${index}`} type="number" name={`${member}.value`} className="form-control input-modifier bg_gray" id="" aria-describedby="emailHelp" placeholder="eg. 50" />
                                </div>
                            </div>
                            <div className="col-4  col-sm-4 col-md-4 col-lg-1 col-xl-1 gutter">
                                {/* {index > 0 ? null : <label className="lable_modifier">Deficient</label>} */}
                                <div className="option_wrap">
                                    <label className="lable_modifier">Deficient</label>
                                    <div className="card bg_gray">
                                        <div className="card-body icn_block pt-0">
                                            <div className="checkbox_block radius_checkbox">
                                                <div className="custom-control custom-checkbox">
                                                    <Input name={`${member}.deficient`} type="checkbox" className="custom-control-input" id={`${member}.customCheck`} />
                                                    <label data-test={`deficient_input_${index}`} className="custom-control-label" for={`${member}.customCheck`}></label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-4 col-sm-4 col-md-4 col-lg-1 col-xl-1 gutter">
                                <div className="form-group option_wrap">
                                    <label className="lable_modifier">Default</label>
                                    <div className="card bg_gray">
                                        <div className="card-body icn_block pt-0">
                                            <div className="custom-control custom-radio cst_radio_btn">
                                                <Input dataTest={`default_input_${index}`} name={`${member}.default`} radioType="ratingCheckbox" type="radio" id={`${member}.customRadio`} value={"true"} className="custom-control-input" />
                                                <label data-test={`default_input_${index}`} className="custom-control-label" for={`${member}.customRadio`}></label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-4 col-sm-4 col-md-4 col-lg-3 col-xl-3 gutter">
                            <div className="row align-items-center">
                              <div className="col-lg-9 col-xl-9">
                                <div className="form-group" id="percentage_select_color">
                                    <label className="lable_modifier">Select Color</label>
                                    <Input
                                    type="select"
                                    dataTest={`default_input_${index}`} 
                                    name={`${member}.color_code`} 
                                    id=""
                                    placeholder="Select Color"
                                    options={SelectColorOptions} 
                                    className="custom-select input-modifier"
                                    />
                                </div>
                            </div>
                            <div className="col-lg-3 col-xl-3">
                            {props[0].colorChange[index] ?  
                                 <div className="form-group  m-0 pt-3" id="percentage_select_color">
                                     <div className="color_change" id={props[0].colorChange[index].color_code }></div>
                                 </div> : null  }
                             </div> 
                         </div>
                    </div>
                            <div className="col-4 col-sm-4 col-md-4 col-lg-1 col-xl-1 gutter">
                                {/* {index > 0 ? null : <label className="lable_modifier mb_none">&nbsp;</label>} */}
                                <label className="lable_modifier mb_none">&nbsp;</label>
                                <div className="card bg_gray">
                                    <div className="card-body text-center icn_block">
                                        <a onClick={() => deleteLineItem(fields, index, props[0].updateRating.rating_options_attributes[index], props[0].updateRating.id)} href="javascript:void(0)"><i class="fas fa-trash-alt cst_icon mr-2"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        )
                    }
                        
                    )}
                </div>
                <div className="card_footer">
                    <div className="btn_block text-left">
                        <button type="button" className="btn cst_btn btn_danger_outline mr-2" onClick={() => fields.push({})}>ADD ANOTHER CHOICE</button>
                    </div>
                </div>
            </div>

        </>
    )
}


let Percentage = (props) => {
    const { handleSubmit, pristine, reset, submitting, error } = props;
    const [updateRating, setUpdateRating] = useState(cloneDeep(props.initialValues.rating_type));

    const [colorChange, setcolorChange] = useState([]);
      useEffect(() => {
        if(props.formStates) {
            if(props.formStates && props.formStates.rating_type && props.formStates.rating_type.rating_options_attributes[0]) {
                setcolorChange(props.formStates.rating_type.rating_options_attributes);
            } else {
                // setcolorChange(false);
            }
        }
    }, [props.formStates])
    return (
        <div className={RatingTypes.Percentage === props.ratingType ? "tab-pane fade show active percentage_tab" : "tab-pane fade percentage_tab"} id="percentage" role="tabpanel" aria-labelledby="percentage-tab">
            <div className="card setting_form_con edit_rating">
                <div className="card_header d_flex" style={{padding: "0.5rem 1.75rem"}}>
                    <h5 className="sm_title" data-test="rating_inner_title">
                        <a href="javascript:void(0)" style={{ cursor: 'pointer' }}
                            onClick={() => props.history.goBack()} className="mr-2">
                            <img src="/images/icons/icn_back_arrow.png" alt="Icon" /></a>
                        <a href="javascript:void(0)" className="mr-2"></a>
                        {props.isEdit ? "Update Rating" : "New Rating"}</h5>
                        {props.isEdit ? <> <div style={{display: "grid"}}> <span> Created At : {moment(props.rating.created_at).format('MM-DD-YYYY')}</span> <span>Updated At : {moment(props.rating.updated_at).format('MM-DD-YYYY') }</span> <span>Last Edited By : TBD</span> </div>
                                </> : null}
                </div>
                <form onSubmit={handleSubmit}>
                    <div className="card-body inner_card">
                        <div className="form_info">

                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label className="form_title lable_modifier">Name this percentage rating</label>
                                        <Input dataTest="rating_name_input" type="text" name="rating_type[name]" className="form-control input-modifier" placeholder="Rating Name" />
                                        <small id="emailHelp" className="form-text">e.g. 1-3 Rating, Pass/Fail, Clean/Dirty</small>
                                    </div>
                                    <div className="form-group">
                                    <Input type="radio" labelClass="custom-control-label" forLable={"customRadio1"} value={"false"} label={"Active"} className="custom-control-input" id="customRadio1" name="rating_type[hide]" />
                                    <Input type="radio" labelClass="custom-control-label" forLable={"customRadio2"} value={"true"} label={"In-active"} className="custom-control-input" id="customRadio2" name="rating_type[hide]" />
                                </div>
                                </div>
                            </div>

                        </div>
                        <label className="lable_modifier">Rating Choice</label>
                        <hr className="cst_hr" />
                        <h5 className="small_tile">Inspectors will rate line item based on the selections below </h5>
                        <div className="card rating_form_card edit_info_form mb-0">
                            <FieldArray name="rating_type[rating_options_attributes]" component={LineItems}
                                props={[{
                                    isEdit: props.isEdit,
                                    updateRating: updateRating,
                                    setUpdateRating: setUpdateRating,
                                    deleteRatingLineItem: props.deleteRatingLineItem,
                                    colorChange: colorChange
                                }]}
                            />

                        </div>
                    </div>

                    <div className="card_footer">
                        <div className="btn_block text-center">
                            <button data-test="submit_button" type="submit" className={props.isLoading ? "btn cst_btn btn_danger mr-2 btn-wait" : "btn cst_btn btn_danger mr-2"}>{props.isLoading ? "" : "Save"}</button>
                            <button data-test="cancel_button" type="button" onClick={() => props.cancelClicked() } className="btn cst_btn btn-outline-secondary  cancel_btnnew">Cancel</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}


const mapStateToProps = (state) => {
    let rating_type = {};
    if (isEmpty(state.adminOrSuperAdminReducer.rating)) {
        rating_type['rating_options_attributes'] = [{}, {}]
        rating_type['hide'] = 'false'
    } else {
        state.adminOrSuperAdminReducer.rating.hide = state.adminOrSuperAdminReducer.rating.hide.toString()
        if (state.form.newPercentageRatingForm && state.form.newPercentageRatingForm.values.rating_type.id) {
            rating_type = state.form.newPercentageRatingForm.values.rating_type;
        } else {
            const ratings = cloneDeep(state.adminOrSuperAdminReducer.rating);
            ratings.rating_options_attributes.map(r => { 
                if (r.default) {
                    r.default = "true"
                } else {
                    r.default = "false"
                }              
            })

            rating_type = ratings
        }

    }


    return { initialValues: { rating_type }, formStates: getFormValues('newPercentageRatingForm')(state) }
}

Percentage = reduxForm({
    form: 'newPercentageRatingForm',
    validate,
    enableReinitialize: true
})(Percentage);

Percentage = connect(mapStateToProps)(Percentage)

export default Percentage;