import React, { Component } from 'react';
import { connect } from 'react-redux';
import { routes } from '../../../../../utility/constants/constants';
import * as actions from '../../../../../redux/actions/index';
import { decode, encode } from 'base64-arraybuffer';
import { extractExtention, getUser} from '../../../../../utility/utility';
import LogBookFeedback from '../../../../component/Accounts/LogBook Area/LogBook Feedback/LogBookFeedback';
import * as commonService from "../../../../../utility/utility";

class LogBookFeedbackContainer extends Component {

    constructor(props) {
        super(props);

        this.state = {
            sign: [],
            faultImages: 0,
            isImagesEmpty: false,
            imageName: '',
            idsToDelete: [],
            imageUrl: null,
            base64: [],
            savedPortfolioURls: this.props.logbookLog.attachments && this.props.location.search === "?quality_check=true" ?  this.props.logbookLog.attachments :  [],
            signature : this.props.logbookLog.initial_url && this.props.location.search === "?quality_check=true" ?  this.props.logbookLog.initial_url :  "",
            AllFileTypes: [],
            fileType: '',
        }
        this.inputOpenFileRef = React.createRef();
        this.onPortfolioFileChange = this.onPortfolioFileChange.bind(this);
        this.removeImage = this.removeImage.bind(this);
        this.choosePhotoClicked = this.choosePhotoClicked.bind(this);
    }

    componentDidMount () {
          this.props.getLogbookLogQuestions({type : "logbook"})
          if(this.props.location.search === "?quality_check=true" || this.props.location.search === "?quality_rejected=true"){
            this.props.getOneLogBookLogsArea(this.props.match.params.logbookId,this.props.match.params.logId);
          }
    }

    componentDidUpdate (prevProps) {
        if (prevProps.logbookLog !== this.props.logbookLog){
            this.setState({
                savedPortfolioURls: this.props.logbookLog.attachments,
                signature : this.props.logbookLog.initial_url,
            })
        }
    }

    choosePhotoClicked = () => {
        this.inputOpenFileRef.current.click();
    }

    onSignatureAdded = (values) => {
        let image = values.sigImageFile;
        let form = values.logFeedbackValues
        this.setState({
            sign: [image]
        }, ()=>{
            if(this.props.location.search === "?quality_rejected=true"){
                if (this.state.base64.length > 0) {
                    const arrayBuffer = [];
                    
                    this.state.base64.map((url, i) => {
                        if (!url.photo_urls) {
                            let base_photo = null;
                            const image = url.split(',');
                            base_photo = image[1];
                            arrayBuffer.push(decode(base_photo))
                        }
                    })
                    const values = JSON.parse(JSON.stringify(form))
                    if (values.logbook_log.comment.length == 0) {
                        delete values.logbook_log.comment
                    }
                    if (!values.logbook_log.initial) {
                        values.logbook_log.initial = this.props.logbookLog.initial
                    }
                    if(this.state.idsToDelete.length){
                        values.logbook_log.deleteAttachments = this.state.idsToDelete
                       }

                    if(values.logbook_log.answers){
                        delete values.logbook_log.answers
                        }
                    
                    const extentions = extractExtention(this.state.imageName);
                    this.props.getLogbookPresignedUrl(this.props.match.params.logbookId,this.props.match.params.logId, extentions, arrayBuffer, this.props.createLogbookLog, values)
                                   
                }else {
                    const values = JSON.parse(JSON.stringify(form))

                    if (values.logbook_log.comment.length == 0) {
                        delete values.logbook_log.comment
                    }
                    
                    if (!values.logbook_log.initial) {
                        values.logbook_log.initial = this.props.logbookLog.initial
                    }
                    
                   if(this.state.idsToDelete.length){
                    values.logbook_log.deleteAttachments = this.state.idsToDelete
                   }
                   if(values.logbook_log.answers){
                   delete values.logbook_log.answers
                   }
                     this.props.createLogbookLog(this.props.match.params.logbookId,this.props.match.params.logId, values)
                }
            }else {

                if (this.state.sign.length > 0 || this.state.base64.length > 0) {
                    const arrayBuffer = [];
                    this.state.sign.map((url, i) => {
                        if (!url.photo_urls) {
                            let base_photo = null;
                            const image = url.split(',');
                            base_photo = image[1];
                            arrayBuffer.push(decode(base_photo))
                        }
                    })
                    
                    this.state.base64.map((url, i) => {
                        if (!url.photo_urls) {
                            let base_photo = null;
                            const image = url.split(',');
                            base_photo = image[1];
                            arrayBuffer.push(decode(base_photo))
                        }
                    })
                    if (form.logbook_log.comment.length == 0) {
                        delete form.logbook_log.comment
                    }
                    
                    const extentions = extractExtention(['img.jpg',...this.state.imageName]);
                    this.props.getLogbookPresignedUrl(this.props.match.params.logbookId,this.props.match.params.logId, extentions, arrayBuffer, this.props.createLogbookLog, form)
                                   
                }
            }
        })
    }

    onPortfolioFileChange = (e) => {
        this.setState({
            faultImages: 0
        });
        console.log(this.state.base64);
        let files = e.target.files;
        let imageName = [], fileType = [], AllFileTypes = [], base64 = [];
        const that = this;
        // for (let i = 0; i < files.length; i++) {
        async function readFile(index) {
            if (index < files.length) {
                let reader = new FileReader();
                let file = files[index];
                imageName.push(file.name)
                fileType.push(file.type)
                AllFileTypes.push(file.type)

                if (file.type.match('image')) {

                    let fileDate = await commonService.compressImage(file, {});
                    let reader = new FileReader();
                    reader.readAsDataURL(fileDate);
                    reader.onload = function () {
                        // setImageData({ ...imageData, base64: reader.result, file: file });
                        base64.push(reader.result)
                        if (index === files.length - 1) {
                            //   
                            that.setState(prevState => ({
                                base64: [...prevState.base64, ...base64]
                            }))
                        }
                    };
                    reader.onerror = function (error) {
                        console.log("Error: ", error);
                    };
                    if (index === files.length - 1) {
                        //   
                        that.setState(prevState => ({
                            imageName: [...prevState.imageName, ...imageName],
                            fileType: [...prevState.fileType, ...fileType],
                            AllFileTypes: [...prevState.AllFileTypes, ...AllFileTypes],
                            // arrayBuffer: [...prevState.arrayBuffer, decode(reader.result)],
                            // base64: [...prevState.base64, ...base64]
                        }))
                    }
                    readFile(index + 1);

                }
                // reader.readAsArrayBuffer(file)
                // reader.readAsDataURL(file)
            }
        }

        readFile(0);
    }

    removeImage = (url, fileTypeIndex) => {
        let updatedUrls = [...this.state.base64];
        let updatedFileTypes = [...this.state.fileType]
        let updatedImageNames = [...this.state.imageName]

        let urlIndex = this.state.base64.findIndex(function (u) {
            return u === url
        })
        updatedUrls.splice(urlIndex, 1);
        updatedFileTypes.splice(fileTypeIndex, 1)
        updatedImageNames.splice(fileTypeIndex, 1)

        this.setState({
            base64: updatedUrls,
            fileType: updatedFileTypes,
            imageName: updatedImageNames
        })

    }

    removeUrls = (url) => {
        let updatedUrls = [...this.state.savedPortfolioURls];

        if (url.photo_urls) {
            this.setState(prevState => ({
                idsToDelete: [...prevState.idsToDelete, url.id]
            }))
        }

        let urlIndex = this.state.savedPortfolioURls.findIndex(function (u) {
            return u === url
        })

        updatedUrls.splice(urlIndex, 1);

        this.setState({
            savedPortfolioURls: updatedUrls,
        })

    }

    scanLogbookPage = () => {
        if(this.props.location.state?.logId){
            this.props.history.push(routes.LOGBOOK)
        }else {
            this.props.history.push(routes.SCAN_LOGBOOK_QR)
        } 
    }

     qualityCheckHandler = (value,reason) => {
        const logbook_log = {
            status : value,
        }   
        if(reason){
            logbook_log["comment"]=reason
        }
      this.props.createLogbookLog(this.props.match.params.logbookId,this.props.match.params.logId, {logbook_log})
    }
    
    render() {        
        return (
            <div>
                <LogBookFeedback 
                onSignatureAdded={this.onSignatureAdded} 
                scanLogbookPage={this.scanLogbookPage}
                portfolioImages={this.state.base64}
                reference={this.inputOpenFileRef}
                choosePhotoClicked={this.choosePhotoClicked}
                removeImage={this.removeImage}
                onPortfolioFileChange={this.onPortfolioFileChange}
                savedPortfolioURls={this.state.savedPortfolioURls}
                signature={this.state.signature}
                qualityCheckHandler={this.qualityCheckHandler}
                removeUrls={this.removeUrls}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        logbookLog : state.adminOrSuperAdminReducer.logbookLog
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getLogbookPresignedUrl: (logId, logbookId, extentions, arrayBuffer, createLogbookLog, form) => dispatch(actions.getLogbookPresignedUrl(logId,logbookId, extentions, arrayBuffer, createLogbookLog, form )),
        createLogbookLog: (id,logbookId, form) => dispatch(actions.createLogbookLog(id,logbookId, form)),
        getLogbookLogQuestions: (params) => dispatch(actions.getLogbookLogQuestions(params)),
        getOneLogBookLogsArea: (logbookId,logId) => dispatch(actions.getOneLogBookLogsArea({logbookId,logId})),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LogBookFeedbackContainer);