import React, { useState, useEffect } from 'react';
import { Input } from '../../UI/InputElement/InputElement';
import { newInspectionQuoteValidator as validate } from '../../../../utility/validator/validator';
import QuotesSkeleton from '../../../component/Quotes/NewQuotes/QuotesSkeleton';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { userRoles } from '../../../../utility/constants/constants';
import ModalImageGallery from '../../ModalImageGallery/modalImageGallery';
import ImageLoader from 'react-imageloader';
import ReactQuill, { Quill }from 'react-quill'; // ES6
var Link = Quill.import('formats/link');

const cloneDeep = require('clone-deep');

const getAccountsDropDown = (props) => {

    if (props.user.role === userRoles.INSPECTOR || props.user.role === userRoles.CLIENT || props.user.role === userRoles.REQUESTOR) {

        return props.userAccountLists && props.userAccountLists.map(account => {
            return {
                value: account.id,
                label: account.name
            }
        })
    } else {
        return props.accountsList && props.accountsList.map(account => {
            return {
                value: account.id,
                label: account.name
            }
        })
    }
}


const getCustomerDropdown = (props) => {
    return props.dropDownCustomerList && props.dropDownCustomerList.map(customer => {
        return {
            value: customer.id,
            label: customer.first_name + ' ' + customer.last_name
        }
    })
}

let NewInspectionQuote = (props) => {

    const [descriptionError, setDescriptionError] = useState('');
    const [editorHtmlToText, setEditorHtmlToText] = useState('');
    const [editorHtml, setEditorHtml] = useState('');
    const [sigantureImage, setSigantureImage] = useState('');
    const [theme, setTheme] = useState('snow');

    const handleChange = (html, recentAdded, b, editorFunctions) => {

        let text = editorFunctions.getText();
        setEditorHtmlToText(text);
        recentAdded.ops.forEach((a, i) => {
            if (a.insert && a.insert.image) {
                setSigantureImage(a.insert.image)
            }
        })
        var builtInFunc = Link.sanitize;
        Link.sanitize = function customSanitizeLinkInput(linkValueInput) {
             var urlValue= linkValueInput;
             if (urlValue.indexOf("http://") == 0 || urlValue.indexOf("https://") == 0) {
                console.log(urlValue)
                return urlValue
            }
            else{
                
                urlValue = "https://" + urlValue;
                return urlValue
            }
    
        }
        if (text.length > 20) {
            setDescriptionError('');
        } 
        setEditorHtml(html);
    }

    const submitQuote = (values, htmlContent, base64, method) => {
        // props.validate(values);
        // if(values.quote.account_id === '' || values.quote.account_id === null) {

        // }
            // showConfirmAlert("Are you sure that you would like to send this quote to your customer?", 'Please Confirm', () => {
            // submitQuote(values, editorHtml, sigantureImage)
            setDescriptionError('');
            let quote = cloneDeep(values);
            // quote.quote['account_id'] = quote.quote['account_id']['value'];
            quote.quote['description'] = htmlContent;
            // quote.quote['manual'] = true;
            if(method === 'submitLater') {
                props.handelInspectionQuoteSubmitLater(quote);
            } else {
                props.handelInspectionQuoteSubmitNow(quote)
            }
    }

    const EditorModules = {
        toolbar: [
            [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
            [{ size: [] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' },
            { 'indent': '-1' }, { 'indent': '+1' }],
            ['link'],
            // ['link', 'image', 'video'],
            // ['clean']
        ],
        clipboard: {
            // toggle to add extra line breaks when pasting HTML:
            matchVisual: false,
        }
    }

    const EditorFormats = [
        'header', 'font', 'size',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
        'link', 'image', 'video'
    ]

    // const [accounts, setAccounts] = useState(getAccountsDropDown(props));

    // useEffect(() => {
    //     setAccounts(getAccountsDropDown(props))
    // }, [props.accountsList, props.userAccountLists])


    // const noOptionsMessage = (a, b) => {
    //     return 'Account not found';
    // }

    const base64Clicked = (i, urlLength) => {
        setIsImageGallery(true);
        setStartIndex((urlLength + i));
    }
    const photoUrlClicked = (i) => {
    
        setIsImageGallery(true);
        setStartIndex(i);
    }
    
    function preloader() {
        return <img  style={{margin:'0 auto',display: 'block', width: '50%', height: '50%', cursor: 'pointer' }} src="/images/gif/giphy.gif" />;
    }


    const [isImageGallery, setIsImageGallery] = useState(false);
    const [startIndex, setStartIndex] = useState(0);
    const [customer, setCustomer] = useState(getCustomerDropdown(props));
    useEffect(() => {
        setCustomer(getCustomerDropdown(props))
    }, [props.dropDownCustomerList])

    useEffect(() => {
        props.accountChanged(props.inspection.account_id)
    },[!props.dropDownCustomerList])

    const noCustomerMessage = (a, b) => {
        return 'Customer not found';
    }
    const { handleSubmit, pristine, reset, submitting, error } = props;
    if (isImageGallery) {
        return <ModalImageGallery
            isImageGallery={isImageGallery}
            setIsImageGallery={setIsImageGallery}
            startIndex={startIndex}
            base64={props.portfolioImages}
            imageUrls={props.savedPortfolioURls}
        />
    }

    return (
        <div id="addnew_qoutes" className="modal fade add_account_modal edit_profile_modal addnew_account_popup user_newQuote">
            <div className="modal-dialog  modal-dialog-centered">

                <div className="modal-content">

                    <div className="modal-header"> 
                        <button type="button" onClick={props.closeModal} className="close" data-dismiss="modal"><i className="fa fa-times icn_plus"></i></button>
                        <h4 className="modal-title" data-test="new_quote_title">Add a New External Quote</h4>
                    </div>
                    <div className="modal-body">
                        <div className="inspection_content_sec appcontent_Inner">
                            <div className="quote_form">
                                <div className="addQuote_form">
                                    <form onSubmit={handleSubmit((values) => submitQuote(values, editorHtml, sigantureImage))}>
                                        <div className="form-group" data-test="quote_job_select">
                                            <label className="form_title">Job</label>
                                            <Input name="quote[account_id]" className="form-control input-modifier" type="text" readOnly={true} />
                                        </div>
                                        {/* <div className="form-group">
                                                <label className="form_title">Tag Opportunities</label>

                                                <Input type="radio" onClick={props.toggleTagOpportunities} labelClass="custom-control-label" forLable={"customRadio1"} value="true" label={"Yes"} className="custom-control-input" id="customRadio1" name="quote[tag_opportunity]" />

                                                <Input type="radio" onClick={props.toggleTagOpportunities} labelClass="custom-control-label" forLable={"customRadio2"} value="false" label={"No"} className="custom-control-input" id="customRadio2" name="quote[tag_opportunity]" />

                                            </div> */}
                                        <div className="desc_block">
                                            <div className="form-group">
                                                <label className="form_title">Amount</label>
                                                <Input type="number" dataTest="quote_amount_input" min={1} name="quote[total_sale]" className="form-control input-modifier" id="total_sale" />
                                            </div>
                                            <div className="form-group">
                                                <label name="quote[quote_customer_ids]" className="form_title">Customer</label>
                                                <Input name="quote[quote_customer_ids]" readOnly={!props.dropDownCustomerList} noOptionsMessage={noCustomerMessage} isMulti={true} type="inputSelect" options={customer} />
                                            </div>
                                            <ReactQuill
                                            style={{ height: '50%' }}
                                            theme={theme}
                                            onChange={handleChange}
                                            defaultValue={editorHtml}
                                            modules={EditorModules}
                                            formats={EditorFormats}
                                            bounds={'.app'}
                                            placeholder='Detail work to be completed.'
                                        />
                                        {/* <span style={{ color: '#DD2726', fontSize: '13px' }}>{descriptionError}</span>*/}
    
                                           {/* <div className="form-group">
                                                <label className="form_title">Description</label>
                                                <Input type="textarea" dataTest="quote_description_input" name="quote[description]" className="form-control input-modifier" rows="5" placeholder="Type here" id="comment" />
                                        </div> */}

                                            {props.user.role === userRoles.INSPECTOR || props.user.role === userRoles.REQUESTOR ? null :
                                                <div className="checkbox_block radius_checkbox" style={{ marginTop: '15px' }}>
                                                    <div className="custom-control custom-checkbox">
                                                        <Input name="web_preference" type="checkbox" className="custom-control-input" id="customCheck1" checked />
                                                        <label className="custom-control-label" for="customCheck1">Send Approved Mail</label>
                                                    </div>
                                                </div>
                                            }


                                            <div className="file_attachment ticket_form" style={{paddingTop:'10px'}}>
                                        <Input type="file" name="attachment_paths" hidden={true} multiple={true} onSelect={props.onPortfolioFileChange} fileRef={props.reference} accept="image/*" />
                                        <a href="javascript:void(0)" onClick={props.choosePhotoClicked} className="file_title"><img src="/images/icons/attachment_icon.png" alt="Icon" /> Attachments</a>
                                        <p className="size_dec">Up to 10 atachments (10MB each).</p>

                                        <div class="upload_file">
                                            <div class="row">
                                                {props.savedPortfolioURls && props.savedPortfolioURls.map((url, i) => {
                                                    return (
                                                        <div class="col-4 col-sm-4 cst_gutter" key={i}>
                                                            <div class="file_view" onClick={() => photoUrlClicked(i)}>
                                                               { props.setLoader ? <ImageLoader
                                                                    style={{margin:'0 auto',display: 'block', width: '50%', height: '50%', cursor: 'pointer' }}
                                                                    src={url.photo_urls.small}
                                                                    wrapper={React.createFactory('div')}
                                                                    preloader={preloader}>
                                                                    Image load failed!
                                                            </ImageLoader> : null}
                                                            </div>
                                                            <div class="cancel_icon">
                                                                <img src="/images/icons/icn_delete_white.png" onClick={() => props.removeUrls(url, i)} alt="Image" />
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                                {props.portfolioImages && props.portfolioImages.map((url, i) => {
                                                    return (
                                                        <div class="col-4 col-sm-4 cst_gutter" key={i} >
                                                            <div class="file_view">
                                                                <img src={url} alt="Image" onClick={() => base64Clicked(i, props.savedPortfolioURls.length)} />
                                                                <div class="cancel_icon">
                                                                    <img src="/images/icons/icn_delete_white.png" onClick={() => props.removeImage(url, i)} alt="Image" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                }

                                                )}
                                                {props.setLoader ?<ImageLoader
                                                                   style={{margin:'0 auto',display: 'block', width: '50%', height: '50%', cursor: 'pointer' }}
                                                                    src=""
                                                                    wrapper={React.createFactory('div')}
                                                                    preloader={preloader}>
                                                                    Image load failed!
                                                            </ImageLoader> : null}
                                            </div>
                                        </div>
                                    </div>
                                    
                                        </div>
                                        <div className="form-group btn_block mb-0">
                                        {props.user.role === userRoles.INSPECTOR ? null :
                                            <button data-test="submit_later_button" disabled={props.state.submitLater || props.state.submitNow} onClick={handleSubmit(values => submitQuote({ ...values }, editorHtml, sigantureImage, 'submitLater'))} className={props.state.submitLater ? "btn cst_btn btn_danger mr-2 btn-wait" : "btn cst_btn btn_danger mr-2"}>{props.state.submitLater ? "" : "Submit Later"}</button>
                                        }
                                            <button data-test="submit_button" disabled={props.state.submitNow || props.state.submitLater} onClick={handleSubmit(values => submitQuote({ ...values }, editorHtml, sigantureImage, 'submitNow'))} className={props.state.submitNow ? "btn cst_btn btn_danger mr-2 btn-wait" : "btn cst_btn btn_danger mr-2"}>{props.state.submitNow ? "" : "Submit Now"}</button>
                                            <button data-test="cancel_button" id="addnew_qoutes_button" data-dismiss="modal" type="button" onClick={props.closeModal} className="btn cst_btn btn-outline-secondary">Cancel</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


const mapStateToProps = (state) => {

    let quote = {
        account_id: state.adminOrSuperAdminReducer.inspection.account_info.account_name,
        quote_customer_ids: [],
        // inspectionForm: state.adminOrSuperAdminReducer.inspection.inspection_form
        // tag_opportunity: "true"
    };
    return {
        initialValues: { quote }
    }
}

NewInspectionQuote = reduxForm({
    form: 'newQuoteForm',
    validate,
})(NewInspectionQuote);

NewInspectionQuote = connect(mapStateToProps)(NewInspectionQuote);

export default NewInspectionQuote;