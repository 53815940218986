import React from "react";
import { MagicSpinner, PushSpinner, SwishSpinner, WaveSpinner, ClapSpinner } from 'react-spinners-kit';
import Search from "./NewSearch";
import SearchResult from "../SearchResult";

const SearchHistory = (props) => {
  return(
      <main className="app wraper_content qr_app_content qr-app-scroll-v2">
      <Search {...props}/>
        {
          props.isLoading ? <div className="loader_btn_block">
            <ClapSpinner backColor="#89073c" size={40} frontColor="#89073c8a" />
          </div> :
          <SearchResult {...props}/>
        }
      </main>
  )
};

export default SearchHistory;