export const InventoryModuleActionTypes = {

    GET_SINGLE_JOB_CARD:'GET_SINGLE_JOB_CARD',
    GET_SINGLE_JOB_CARD_PENDING: 'GET_SINGLE_JOB_CARD_PENDING',
    GET_SINGLE_JOB_CARD_FULFILLED: 'GET_SINGLE_JOB_CARD_FULFILLED',
    GET_SINGLE_JOB_CARD_REJECTED:'GET_SINGLE_JOB_CARD_REJECTED',

    ADD_SINGLE_JOB_CARD:'ADD_SINGLE_JOB_CARD',
    ADD_SINGLE_JOB_CARD_PENDING: 'ADD_SINGLE_JOB_CARD_PENDING',
    ADD_SINGLE_JOB_CARD_FULFILLED: 'ADD_SINGLE_JOB_CARD_FULFILLED',
    ADD_SINGLE_JOB_CARD_REJECTED:'ADD_SINGLE_JOB_CARD_REJECTED',

    GET_ALL_MONTHLY_REPORT_PRODUCTS:'GET_ALL_MONTHLY_REPORT_PRODUCTS',
    GET_ALL_MONTHLY_REPORT_PRODUCTS_PENDING:'GET_ALL_MONTHLY_REPORT_PRODUCTS_PENDING',
    GET_ALL_MONTHLY_REPORT_PRODUCTS_FULFILLED:'GET_ALL_MONTHLY_REPORT_PRODUCTS_FULFILLED',

    EDIT_SINGLE_JOB_CARD:'EDIT_SINGLE_JOB_CARD',
    EDIT_SINGLE_JOB_CARD_PENDING: 'EDIT_SINGLE_JOB_CARD_PENDING',
    EDIT_SINGLE_JOB_CARD_FULFILLED: 'EDIT_SINGLE_JOB_CARD_FULFILLED',
    EDIT_SINGLE_JOB_CARD_REJECTED:'EDIT_SINGLE_JOB_CARD_REJECTED',

    GET_COST_SUMMARY_ANALYSIS:"GET_COST_SUMMARY_ANALYSIS",
    GET_COST_SUMMARY_ANALYSIS_PENDING:"GET_COST_SUMMARY_ANALYSIS_PENDING",
    GET_COST_SUMMARY_ANALYSIS_FULFILLED:"GET_COST_SUMMARY_ANALYSIS_FULFILLED",

    ADD_SINGLE_DAILY_KITTING:'ADD_SINGLE_DAILY_KITTING',
    ADD_SINGLE_DAILY_KITTING_PENDING: 'ADD_SINGLE_DAILY_KITTING_PENDING',
    ADD_SINGLE_DAILY_KITTING_FULFILLED: 'ADD_SINGLE_DAILY_KITTING_FULFILLED',
    ADD_SINGLE_DAILY_KITTING_REJECTED:'ADD_SINGLE_DAILY_KITTING_REJECTED',

    GET_ALL_INVENTORY_LIST:'GET_ALL_INVENTORY_LIST',
    GET_ALL_INVENTORY_LIST_PENDING:'GET_ALL_INVENTORY_LIST_PENDING',
    GET_ALL_INVENTORY_LIST_FULFILLED:'GET_ALL_INVENTORY_LIST_FULFILLED',

    GET_ALL_DAILY_KITTING:'GET_ALL_DAILY_KITTING',
    GET_ALL_DAILY_KITTING_PENDING:'GET_ALL_DAILY_KITTING_PENDING',
    GET_ALL_DAILY_KITTING_FULFILLED:'GET_ALL_DAILY_KITTING_FULFILLED',

    DAILY_KITTING_PAGE_CLICKED:"DAILY_KITTING_PAGE_CLICKED",

    GET_SINGLE_DAILY_KITTING:'GET_SINGLE_DAILY_KITTING',
    GET_SINGLE_DAILY_KITTING_PENDING: 'GET_SINGLE_DAILY_KITTING_PENDING',
    GET_SINGLE_DAILY_KITTING_FULFILLED: 'GET_SINGLE_DAILY_KITTING_FULFILLED',
    GET_SINGLE_DAILY_KITTING_REJECTED:'GET_SINGLE_DAILY_KITTING_REJECTED',


    EDIT_SINGLE_DAILY_KITTING:'EDIT_SINGLE_DAILY_KITTING',
    EDIT_SINGLE_DAILY_KITTING_PENDING: 'EDIT_SINGLE_DAILY_KITTING_PENDING',
    EDIT_SINGLE_DAILY_KITTING_FULFILLED: 'EDIT_SINGLE_DAILY_KITTING_FULFILLED',
    EDIT_SINGLE_DAILY_KITTING_REJECTED:'EDIT_SINGLE_DAILY_KITTING_REJECTED',


    ADD_MONTHLY_USAGE_REPORTS:"ADD_MONTHLY_USAGE_REPORTS",
    ADD_MONTHLY_USAGE_REPORTS_PENDING:"ADD_MONTHLY_USAGE_REPORTS_PENDING",
    ADD_MONTHLY_USAGE_REPORTS_FULFILLED:"ADD_MONTHLY_USAGE_REPORTS_FULFILLED",
    ADD_MONTHLY_USAGE_REPORTS_REJECTED:"ADD_MONTHLY_USAGE_REPORTS_REJECTED",

    GET_ALL_MONTHLY_REPORT_COST:'GET_ALL_MONTHLY_REPORT_COST',
    GET_ALL_MONTHLY_REPORT_COST_PENDING: 'GET_ALL_MONTHLY_REPORT_COST_PENDING',
    GET_ALL_MONTHLY_REPORT_COST_FULFILLED: 'GET_ALL_MONTHLY_REPORT_COST_FULFILLED',
    GET_ALL_MONTHLY_REPORT_COST_REJECTED:'GET_ALL_MONTHLY_REPORT_COST_REJECTED',


    ADD_BULK_MONTHLY_REPORT_COST:'ADD_BULK_MONTHLY_REPORT_COST',
    ADD_BULK_MONTHLY_REPORT_COST_PENDING: 'ADD_BULK_MONTHLY_REPORT_COST_PENDING',
    ADD_BULK_MONTHLY_REPORT_COST_FULFILLED: 'ADD_BULK_MONTHLY_REPORT_COST_FULFILLED',
    ADD_BULK_MONTHLY_REPORT_COST_REJECTED:'ADD_BULK_MONTHLY_REPORT_COST_REJECTED',

    GET_SINGLE_MONTHLY_SUMMARY_FORM_DATA:'GET_SINGLE_MONTHLY_SUMMARY_FORM_DATA',
    GET_SINGLE_MONTHLY_SUMMARY_FORM_DATA_PENDING: 'GET_SINGLE_MONTHLY_SUMMARY_FORM_DATA_PENDING',
    GET_SINGLE_MONTHLY_SUMMARY_FORM_DATA_FULFILLED: 'GET_SINGLE_MONTHLY_SUMMARY_FORM_DATA_FULFILLED',
    GET_SINGLE_MONTHLY_SUMMARY_FORM_DATA_REJECTED:'GET_SINGLE_MONTHLY_SUMMARY_FORM_DATA_REJECTED',

    RESET_SINGLE_MONTHLY_SUMMARY_FORM_DATA:'RESET_SINGLE_MONTHLY_SUMMARY_FORM_DATA',
    

    RESET_MONTHLY_SUMMARY_FORM_DATA:'RESET_MONTHLY_SUMMARY_FORM_DATA',
    

    

}