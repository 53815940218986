import React from 'react';
import AssetsPreviewTable from './assetsPreviewTable';

let AssetsPreview = (props) => {

  return (
    <>
      <main className="app-content wraper_content inspection_wraper">
        <section className="account_sec user_screen inspection_tab_content">

          <div className="quotes_table_content table-responsive">

            <div className="table quotes_table account_table mt-5">
              <AssetsPreviewTable
                {...props}
              />
            </div>

          </div>
        </section>
      </main>


    </>
  )
}


export default AssetsPreview;