import React from 'react';
import { ClapSpinner } from 'react-spinners-kit';
import storage from '../../../utility/storage';

const Thankyou = (props) => {
    const token = storage.get('token');

    return (
        <body className="thankyou_body">
            {props.quoteApproved ? <main className="app-content  wraper_content inspection_wraper user_quotes">
                {token ? <section className="thankyou_content" style={{marginLeft: '30%'}}>
                    <div className="thankyou_img">
                        <img src="/images/icons/thankyou_logo.png" alt="thankyou" className="img-fluid" />
                    </div>
                    <h1>Thank you for your response.  Your Account Representative will be in touch soon.</h1>
                    <p style={{ color: '#8a2b3c' }}>cs@pegasusclean.com</p>
                    {/* <a href="JavaScript:void(0);">Back</a> */}
                </section> :
                <section className="thankyou_content">
                <div className="thankyou_img">
                    <img src="/images/icons/thankyou_logo.png" alt="thankyou" className="img-fluid" />
                </div>
                <h1>Thank you for your response.  Your Account Representative will be in touch soon.</h1>
                <p style={{ color: '#8a2b3c' }}>cs@pegasusclean.com</p>
                {/* <a href="JavaScript:void(0);">Back</a> */}
            </section>
            }
            </main> :
            <main className="app-content  wraper_content inspection_wraper user_quotes">
                {token ? <section className="thankyou_content" style={{marginLeft: '30%', marginTop: '15%'}}>
                    <h1>{props.quoteErrorMessage}</h1>
                </section> :
                <section className="thankyou_content" style={{ marginTop: '15%'}}>
                <h1>{props.quoteErrorMessage}</h1>
            </section>
            }
            </main>
            }
            {props.isLoading ? <div className="loader_btn_block">
                <ClapSpinner backColor="#89073c" size={40} frontColor="#89073c8a" />
            </div> : ''}
        </body>
    )
}

export default Thankyou;