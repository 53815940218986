import React, { Component } from 'react';
import Settings from '../../component/Settings/settings';
import * as actions from '../../../redux/actions/index';
import { connect } from 'react-redux';
import { animateScroll as scroll} from 'react-scroll'

class SettingsContainer extends Component {

    handelPositionSubmit = (value) => {
            delete value.position.positions_count
            delete value.position.region_count
        if (this.props.isEdit) {
            this.props.updatePosition(value, this.props.position.position.id)
        } else {
            this.props.addPosition(value);
        }
    }
    componentDidMount() {
        scroll.scrollToTop();
      }
    handelRegionSubmit = (value) => {
        if (this.props.isEdit) {
            this.props.updateRegion(value, this.props.region.region.id)
        } else {
            this.props.addRegion(value);
        }
    }

    render() {

        return (
            <Settings
                {...this.props}
                openPopup={this.props.openPopup}
                closePopup={this.props.closePopup}
                isOpenModel={this.props.isOpenModel}
                isEdit={this.props.isEdit}
                handelPositionSubmit={this.handelPositionSubmit}
                handelRegionSubmit={this.handelRegionSubmit}
                emptyObjects={this.props.emptyObjects}
                isLoading={this.props.isLoading}
                totalRegions={this.props.totalRegions}
                totalPositions={this.props.totalPositions}
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        isOpenModel: state.adminOrSuperAdminReducer.isOpenModel,
        isLoading: state.adminOrSuperAdminReducer.isLoading,
        position: state.adminOrSuperAdminReducer.position,
        region: state.adminOrSuperAdminReducer.region,
        isEdit: state.adminOrSuperAdminReducer.isEdit,
        totalRegions: state.adminOrSuperAdminReducer.totalRegions,
        totalPositions: state.adminOrSuperAdminReducer.totalPositions

    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        openPopup: () => dispatch(actions.openPopup()),
        closePopup: () => dispatch(actions.closePopup()),
        addPosition: (position) => dispatch(actions.addPosition(position)),
        updatePosition: (position, id) => dispatch(actions.updatePosition(position, id)),
        addRegion: (region) => dispatch(actions.addRegion(region)),
        updateRegion: (region, id) => dispatch(actions.updateRegion(region, id)),
        emptyObjects: () => dispatch(actions.emptyObjects())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SettingsContainer);