import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { ClapSpinner } from "react-spinners-kit";
import { Input } from "../../UI/InputElement/InputElement";
import { reduxForm } from "redux-form";
import { accountDuplicateFormValidator as validate } from "../../../../utility/validator/validator";

const AccountDuplicateModal = (props) => {
  const { handleSubmit, pristine, reset, submitting, error } = props;
  const closeBtnClicked = () => {
    reset()
}
  return (
    <div
      className="modal fade performance_modal associateUser AccountDuplicateModal"
      id="AccountDuplicateModal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-dialog-centered modal-sm"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-header">
            <h2 className="md_title" id="exampleModalLongTitle">
            Please enter job details to make a copy
            </h2>
            <button
              type="button"
              onClick={closeBtnClicked}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">
                <img
                  src="/images/icons/icn_cancel_white.png"
                  alt="Icon"
                />
              </span>
            </button>
          </div>
          <div className="modal-body">
          <form onSubmit={handleSubmit}>
            <div className="row">
                <div className="col-md-6">
                    <div className="form-group">
                        <label className="label_modifier">Job Name</label>
                        <Input className="form-control input-modifier" name='name' />
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                        <label className="label_modifier">Job Number</label>
                        <Input type='number' className="form-control input-modifier" name='number' />
                    </div>
                </div>
            </div>
            <div style={{textAlign: 'center'}}>
            <button type="submit" disabled={props.isLoading} className={props.isLoading ? "btn cst_btn btn_danger mr-2 btn-wait" : "btn cst_btn btn_danger mr-2"} >{props.isLoading ? "" : "Submit"}</button>
              <button style={{ minHeight: '36px', minWidth: '80px' }} onClick={closeBtnClicked} data-dismiss="modal" aria-label="Close" className={"btn cst_btn btn-outline-secondary"} id='close_duplicate_button' type="cancel">Cancel</button>
          </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default reduxForm({
  form: "accountDuplicateForm",
  validate,
})(AccountDuplicateModal);
