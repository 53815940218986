import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../../redux/actions/index';
import { animateScroll as scroll } from 'react-scroll'
import TicketScheduleHistory from '../../../component/Accounts/AccountUsers/TicketScheduleHistoryComponent/TicketScheduleHistory';

class TicketScheduleHistoryContainer extends Component {

    constructor(props) {
        super(props);
    
        this.state = {
        }
    }

    componentDidMount() {
        scroll.scrollToTop();
    }

    componentDidUpdate() {
        this.props.scheduleTicketHistoryPageClicked(0)
    }

    render() {
        return (
            <div>
                <TicketScheduleHistory
                    {...this.props} 
                />     
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.authReducer.user,
        isLoading: state.adminOrSuperAdminReducer.isLoading,
        scheduleTicketHistory_logList: state.adminOrSuperAdminReducer.scheduleTicketHistory_logList,
        total_no_of_pages: state.adminOrSuperAdminReducer.scheduleHistory_totalPages,
        scheduleTicketHistory_totalListCount: state.adminOrSuperAdminReducer.scheduleTicketHistory_totalListCount,
        scheduleTicketHistoryCurPage: state.adminOrSuperAdminReducer.scheduleTicketHistoryCurPage,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        scheduleTicketHistory: (params, accountId) => dispatch(actions.scheduleTicketHistory(params, accountId)),
        scheduleTicketHistoryPageClicked: (page) => dispatch(actions.scheduleTicketHistoryPageClicked(page)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TicketScheduleHistoryContainer);