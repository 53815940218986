import React, { useState } from 'react';
import PegAssureTable from '../../component/PegAssureComponent/PegAssureTable';
import { Input } from '../UI/InputElement/InputElement';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
// import { userSearchValidator as validate } from '../../../utility/validator/validator';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import { convertUsersDataToCsvType } from '../../../utility/utility';
import { CSVLink, CSVDownload } from "react-csv";
import '../../../styles/custom.css';
import { DateTimeField } from '../UI/DateTimeField/DateTimeField';
import moment from 'moment';

let User = (props) => {
    const { handleSubmit, pristine, reset, submitting,change, error } = props;


const weekDaysOptions = [
    {
        id: 'sunday',
        name: 'Sunday'
    },
    {
        id: 'monday',
        name: 'Monday'
    },
    {
        id: 'tuesday',
        name: 'Tuesday'
    },
    {
        id: 'wednesday',
        name: 'Wednesday'
    }, {
        id: 'thursday',
        name: 'Thursday'
    },
    {
        id: 'friday',
        name: 'Friday'
    },{
        id: 'saturday',
        name: 'Saturday'
    }
]

const reviewOptions = [
    {
        id: 'excellent',
        name: 'Excellent'
    },
    {
        id: 'average',
        name: 'Average'
    },
    {
        id: 'needservice',
        name: 'Need Service'
    }
]

const attachmentOptions = [
    {
        id: 'yes',
        name: 'Yes'
    },
    {
        id: 'no',
        name: 'No'
    }
]




    return (
        <>
            <main className="app-content wraper_content inspection_wraper">
                <div className="tab_header search_filter_cont inspection_tab_content appcontent_inner user_filter" id="user_filter">
                    <form onSubmit={handleSubmit}>
                        <input type="hidden" value="something" />
                        <div className="row" style={{marginBottom: '10px'}}>
                            <div className="col-xl-3 col-lg-4 col-md-12 media_padding">
                                <div className="app-search">
                                    <Input dataTest="creaters_filter" className="fil_search_input input-modifier" name="job_name" type="search" placeholder="Search By Job" />
                                    <button className="app-search__button">
                                        <i class="fa fa-search" aria-hidden="true"></i>
                                    </button>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-12 media_padding">
                                <div className="app-search">
                                    <Input dataTest="email_filter" className="fil_search_input input-modifier" name="area_name" type="search" placeholder="Search By Area" />
                                    <button className="app-search__button">
                                        <i class="fa fa-search" aria-hidden="true"></i>
                                    </button>
                                </div>
                            </div>

                            <div className="col-xl-3 col-lg-4 col-md-12 media_padding">
                                <div className="app-search">
                                <Input name="review" dataTest="select_ticket_status" type="select" options={reviewOptions} placeholder="Search Review" className="custom-select input-modifier add_user_select" />

                                </div>
                            </div>

                            <div className="col-xl-3 col-lg-4 col-md-12 media_padding">
                                <div className="app-search">
                                <Input name="attachment" dataTest="select_ticket_status" type="select" options={attachmentOptions} placeholder="Search By Attachment" className="custom-select input-modifier add_user_select" />

                                </div>
                            </div>

                        </div>
                        <div className='row mb-2'>

                        <div className="col-xl-9 col-lg-6 col-md-6 col-sm-6 mb-3">
                        <div class="row custom_row_card">
                        {/* <div class="col-lg-4">
                        <label className="custom_label m-0">
                                    <Input name="from" maxDate={new Date()} dateFormat="MM-dd-yyyy" placeholder="From Date" datePickerType={"react-datepicker"} type="date" className="form-control input-modifier" />
                                    <div className="input-group-append cst_group_append">
                                        <span className="input-group-text" id="basic-addon2"><i className="fa fa-calendar"></i></span>
                                    </div>
                            </label>
                        </div> */}
                        <div class="col-lg-4">
                        <label className="custom_label m-0"> Day</label>
                        <div className="app-search">
                        <Input name="day" dataTest="select_ticket_status" type="select" options={weekDaysOptions} placeholder="Search By Day" className="custom-select input-modifier add_user_select" />
                        </div>
                        </div>
                        <div class="col-lg-4">
                        <label className="custom_label m-0"> Start Time</label>
                            <div className="time_search_picker">
                            <DateTimeField  timeConstraints={{ minutes: { step: 5 } }}  datePickerType='react-datetime' pickerType="timePicker" dataFormat="hh:mm A" className="form-control Input-modifier m-0" id="areaName" name="start_time" placeholder="Time" />
                            </div>
                        </div>
                        <div class="col-lg-4">
                        <label className="custom_label m-0"> End Time</label>
                            <div className="time_search_picker">
                                <DateTimeField  timeConstraints={{ minutes: { step: 5 } }} datePickerType='react-datetime' pickerType="timePicker" dataFormat="hh:mm A" className="form-control Input-modifier m-0" id="areaName" name="end_time" placeholder="Time" />
                            </div>
                          </div>
                        </div>
                          </div>

                          {/* <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                          <label className="custom_label mb-2">To</label>
                          <div class="row custom_row_card">
                          <div class="col-lg-4">
                          <label className="custom_label m-0">
                          <Input name="to" maxDate={new Date()} dateFormat="MM-dd-yyyy" placeholder="To Date" datePickerType={"react-datepicker"} type="date" className="form-control input-modifier" />
                          <div className="input-group-append cst_group_append">
                                          <span className="input-group-text" id="basic-addon2"><i className="fa fa-calendar"></i></span>
                                      </div>
                              </label>
                          </div>
                          <div class="col-lg-4">
                          <label className="custom_label m-0">
                          <div className="app-search">
                          <Input name="to_day" dataTest="select_ticket_status" type="select" options={weekDaysOptions} placeholder="Search By Day" className="custom-select input-modifier add_user_select" />
                          </div>
                              </label>
                          </div>
                          <div class="col-lg-4">
                            <div className="time_search_picker">
                                <DateTimeField  timeConstraints={{ minutes: { step: 5 } }} datePickerType='react-datetime' pickerType="timePicker" dataFormat="hh:mm:ss" className="form-control Input-modifier m-0" id="areaName" name="end_time" placeholder="Time" />
                            </div>
                          </div>
                          </div>
                            </div> */}
                         <div class="col-xl-2 col-lg-6 col-md-6 col-sm-6 col-6 media_padding" style={{margin: 'auto'}}>
                            <div class="more_filter_block">
                                
                                <button data-test="users_go_btn" style={{ minWidth: '90px' }} className={"btn cst_btn btn_danger mr-2"} id="submit" type="submit">{"Go"} </button>
                                <button data-test="users_reset_btn" style={{ minWidth: '90px' }}  className={"btn cst_btn btn-outline-secondary"} onClick={props.resetClicked} id="reset" type="reset">{"Reset"} </button>
                            </div>
                            </div> 

                        </div>
                    </form>
                </div>


                <section className="account_sec user_screen inspection_tab_content">
                    <div className="quotes_table_content table-responsive">
                        <div className="table quotes_table user_table_contnet user_react_table">
                            <PegAssureTable
                                {...props}
                                previewFeedbackClicked={props.previewFeedbackClicked}
                                onSubmit={props.onSearch}
                                resetClicked={props.resetClicked}
                                />
                        </div>

                    </div>
                </section>
            </main>
           
        </>

    )
}

const mapStateToProps = (state) => {
    let job_name = state.adminOrSuperAdminReducer.searchfeedbacksValues && state.adminOrSuperAdminReducer.searchfeedbacksValues.job_name ? state.adminOrSuperAdminReducer.searchfeedbacksValues.job_name : "";
    let area_name = state.adminOrSuperAdminReducer.searchfeedbacksValues && state.adminOrSuperAdminReducer.searchfeedbacksValues.area_name ? state.adminOrSuperAdminReducer.searchfeedbacksValues.area_name : "";
    let review = state.adminOrSuperAdminReducer.searchfeedbacksValues && state.adminOrSuperAdminReducer.searchfeedbacksValues.review ? state.adminOrSuperAdminReducer.searchfeedbacksValues.review : "";
    let from = state.adminOrSuperAdminReducer.searchfeedbacksValues && state.adminOrSuperAdminReducer.searchfeedbacksValues.from ? new Date(state.adminOrSuperAdminReducer.searchfeedbacksValues.from) : "";
    let to = state.adminOrSuperAdminReducer.searchfeedbacksValues && state.adminOrSuperAdminReducer.searchfeedbacksValues.to ? new Date(state.adminOrSuperAdminReducer.searchfeedbacksValues.to) : "";
    let start_time = state.adminOrSuperAdminReducer.searchfeedbacksValues && state.adminOrSuperAdminReducer.searchfeedbacksValues.start_time ? moment(state.adminOrSuperAdminReducer.searchfeedbacksValues.start_time, ["HH:mm:ss"]).format("hh:mm A") : "";
    let end_time = state.adminOrSuperAdminReducer.searchfeedbacksValues && state.adminOrSuperAdminReducer.searchfeedbacksValues.end_time ? moment(state.adminOrSuperAdminReducer.searchfeedbacksValues.end_time, ["HH:mm:ss"]).format("hh:mm A") : "";
    let from_day = state.adminOrSuperAdminReducer.searchfeedbacksValues && state.adminOrSuperAdminReducer.searchfeedbacksValues.from_day ? state.adminOrSuperAdminReducer.searchfeedbacksValues.from_day : "";
    let to_day = state.adminOrSuperAdminReducer.searchfeedbacksValues && state.adminOrSuperAdminReducer.searchfeedbacksValues.to_day ? state.adminOrSuperAdminReducer.searchfeedbacksValues.to_day : "";
    let attachment = state.adminOrSuperAdminReducer.searchfeedbacksValues && state.adminOrSuperAdminReducer.searchfeedbacksValues.attachment ? state.adminOrSuperAdminReducer.searchfeedbacksValues.attachment : "";



    return { initialValues: { job_name, area_name, review, from, to, start_time, end_time, from_day, to_day, attachment } }
}

User = reduxForm({
    form: 'searchFeedbackForm',
    enableReinitialize: true
})(User);

User = connect(mapStateToProps)(User)

export default User;