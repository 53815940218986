import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../../../../redux/actions/index';
import AssetCheckOutFormComponent from '../../../../../component/Inventory/Assets/AssetScanExamineFormComponent/AssetCheckOutFormComponent/AssetCheckOutFormComponent';
import { animateScroll as scroll} from 'react-scroll'
const cloneDeep = require('clone-deep');

class AssetCheckOutFormContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
        }
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        if(this.props && this.props.match && this.props.match.params && this.props.match.params.assetId) {
            this.props.getIsolatedOneAssetData(this.props.match.params.assetId);
            this.props.getSingleLogAsset(this.props.match.params.assetId);
        };
        this.props.getLocation();
        scroll.scrollToTop();
    }

    handleSubmit = (values) => {debugger
        let checkInItem = cloneDeep(values);
        let checkInItemName = '';
        if(checkInItem && checkInItem.scan) {

            if(this.props.isolatedOneAssetData) {
                checkInItemName = this.props.isolatedOneAssetData.ticket && this.props.isolatedOneAssetData.ticket.name;
            }
            if(checkInItem.scan.location_id) {
                checkInItem.scan.location_id = checkInItem.scan.location_id;
            }

            this.props.engageAssetCheckIn({checkInItem, checkInItemName}, this.props.match.params.assetId);
            
        }
    };

    // componentWillUnmount() {}

    render() {

        return (
            <AssetCheckOutFormComponent
            {...this.props}
            onSubmit={this.handleSubmit}
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        isLoading: state.InventoryReducer.isLoading,
        isolatedOneAssetData: state.InventoryReducer.isolatedOneAssetData,
        locations: state.InventoryReducer.locations,
        assets: state.InventoryReducer.assets,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getIsolatedOneAssetData: (id) => dispatch(actions.getIsolatedOneAssetData(id)),
        getLocation: () => dispatch(actions.getLocation()),
        getSingleLogAsset: (id) => dispatch(actions.getSingleLogAsset(id)),
        engageAssetCheckIn: (data, id) => dispatch(actions.engageAssetCheckIn(data, id)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AssetCheckOutFormContainer);