import React, {useState} from "react";
import moment from "moment";
import { MagicSpinner, PushSpinner, SwishSpinner, WaveSpinner, ClapSpinner } from 'react-spinners-kit';
import Unavailable from "./UnavailableModel";
import Search from "./MobileSearch";
import Pagination from "react-js-pagination";
import "../History/pagination.css";
import { convertForntlineAreaScheduleTime } from "../../../utility/utility";

const SearchHistory = (props) => {
  const [currentSchedule, setCurrentSchedule] = useState(null);
  const defaultForm = {
    status: "not_available",
    reason: '',
    address_attributes: {},
    captured_time: "",
  };

  const getFormattedZone = (timezone) => {
    let zone = ''
    if (timezone === 'America/Los_Angeles') {
      zone = 'PST'
    } else if ( timezone === 'America/Chicago') {
      zone = 'CST'
    }
    return '(' + zone + ')';
  }

  const [form, setForm] = useState(defaultForm);
  return(
      <>
      { props.lat ? <Unavailable {...props}  form={form} setForm={setForm} currentSchedule={currentSchedule} setCurrentSchedule={setCurrentSchedule}></Unavailable> : ""}
      <main class="app-content qr_app_content app_screen_outer schedule-screen ups_schedule">
        <div class="client_login list_view">
          <div class="login_form_content Schedule_con">
            <h4 class="h4_title">Upcoming & Past Schedule <span class="sm_title">(30 Mins.)</span> </h4>
            <Search {...props} currentSchedule={currentSchedule}/>
            {  props.isLoading ? <div className="loader_btn_block">
              <ClapSpinner backColor="#89073c" size={40} frontColor="#89073c8a" />
              </div> :
              <div class="upcoming_schedule schedule-page-min-height">
                { props.areaOfAccount && props.areaOfAccount.future_schedules && props.areaOfAccount.future_schedules.length > 0 ? <span>Upcoming Schedule:</span> : ''}
                {
                  props.areaOfAccount.future_schedules && props.areaOfAccount.future_schedules.map((schedule, index) => {
                    return <div class="input_box input_btn" key={index}>
                      <div class="input_content">
                        <label class="label_modifier">{schedule.area.name}</label>
                        <span ><span
                            class="text_light"> At </span> {moment(moment().format("MM-DD-YYYY") + " " + schedule.start_time_zone, "MM-DD-YYYY HH:mm:ss").format("hh:mm A")} {getFormattedZone(schedule.area.account.timezone)}</span>
                      </div>
                      <div class="btn_block">
                        <a href="JavaScript:void(0);" class="mb-0 btn btn_danger cst_btn" data-toggle="modal"
                           data-target="#setUnavailable"
                           onClick={() => [setCurrentSchedule(schedule), setForm(defaultForm)]}>In-use</a>
                      </div>
                    </div>
                  })
                }
                { props.areaOfAccount && props.areaOfAccount.pending_schedules && props.areaOfAccount.pending_schedules.length > 0 ? <span>Past Schedule:</span> : ''}

              {
                props.areaOfAccount && props.areaOfAccount.pending_schedules && (props.areaOfAccount.pending_schedules.length || props.areaOfAccount.future_schedules.length) ? <>{
                  props.areaOfAccount.pending_schedules && props.areaOfAccount.pending_schedules.map((schedule, index) => {
                    return <div class="input_box input_btn" key={index}>
                      <div class="input_content">
                        <label class="label_modifier">{schedule.area.name}</label>
                        <span ><span
                            class="text_light"> At </span> {moment(moment().format("MM-DD-YYYY") + " " + schedule.start_time_zone, "MM-DD-YYYY HH:mm:ss").format("hh:mm A")} {getFormattedZone(schedule.area.account.timezone)}</span>
                      </div>
                      <div class="btn_block">
                        <a href="JavaScript:void(0);" class="mb-0 btn btn_danger cst_btn" data-toggle="modal"
                           data-target="#setUnavailable"
                           onClick={() => [setCurrentSchedule(schedule), setForm(defaultForm)]}>In-use</a>
                      </div>
                    </div>
                  })
                }
                </> : (props.isLoading ? "" :
                    <div style={{textAlign: "center"}} className="text-align-center">No Upcoming & Past Schedule</div>) }

              </div>
            }
            <div className="row">
              <nav aria-label="Page navigation example" className="history-pagination col-12">

                {
                  props.areaOfAccount && props.areaOfAccount.pending_schedules && props.total_no_of_pages ? <Pagination
                      activePage={props.search.page}
                      className="float-right"
                      itemsCountPerPage={props.search.size}
                      totalItemsCount={props.search.size * props.total_no_of_pages}
                      pageRangeDisplayed={5}
                      onChange={props.handlePageChange}
                      /> : ""
                }
              </nav>

            </div>
            <div className="qr-page btn_block mb-2 left-0">
              <button type="button" className={`btn_danger btn w-100 cst_btn `} onClick={() => props.history.push("/scan_qr")}>Scan QR code</button>
            </div>
          </div>
          </div>

        </main>

      </>
      )
      };

      export default SearchHistory;