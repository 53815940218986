import React, { Component } from 'react';
import * as actions from '../../../../redux/actions/index';
import { routes } from '../../../../utility/constants/constants';
import { connect } from 'react-redux';
import { reset } from 'redux-form';
import { userRoles } from '../../../../utility/constants/constants';
import storage from '../../../../utility/storage';
import { convertDateToDifferentTZ, convertDateToDifferentTZSansUTC, convertLogbookSearchDateTimezone, getUser } from '../../../../utility/utility';
import { animateScroll as scroll} from 'react-scroll'
import LogbookArea from '../../../component/Accounts/LogBook Area/LogBookArea';
import cloneDeep from 'clone-deep';

class LogBookAreaContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // activeJobs: localStorage.getItem('job_active') ? localStorage.getItem('job_active') === 'true' ? true : false : true,
        }
    }

    newAccountClicked = () => {
        this.props.history.push(routes.NEW_ACCOUNTS);
    }

    deleteAccount = (id) => {
        this.props.deleteAccount(id);
    }

    componentDidMount() {
        // if (!this.props.accountsList) {
        //     this.props.getAccounts();
        // }
        // this.setState({activeJobs: localStorage.getItem('job_active') === 'true' ? true : false});
        scroll.scrollToTop();
        const userDetails = getUser();
        this.props.getModeAccounts(userDetails.mode);
    }
    componentDidUpdate (prevProps, prevState) {
        const user = storage.get('user');
        if(user) {
        if(prevProps.user.selected_mode !== user.selected_mode) {
            this.props.logbookAreaPageClicked(0)
        }
    }
    }
    editAccountClicked = (account) => {

        this.props.editAccountClicked(account);
    }

    searchAccountClicked = (val) => {
        debugger
        const values = cloneDeep(val) 
        this.props.logbookAreaPageClicked(0)
        if(values.account_id) {
            values.account_id = values.account_id.value
        }
        if(values.logbook_name) {
            values.logbook_name = values.logbook_name.trim()
        }
        if(values.name) {
            values.name = values.name.trim()
        }
        if (values.fromDate) {
            values.fromDate = convertDateToDifferentTZ(values.fromDate, 'from');
        }
        if (values.toDate) {
            values.toDate = convertDateToDifferentTZ(values.toDate, 'to');
        }
        if(values.status) {
            values.status = values.status.trim()
        }
        delete values.currentDate;
        if(!val.fromDate) {
            delete values.fromDate;
        }
        if(!val.toDate) {
            delete values.toDate;
        }
        delete values.isWeek;
        delete values.isMonth;
        delete values.isYear;
        const page = 1;
        const size = this.props.logbook_rows.trim()
        if(this.props.user.role === 'frontline') {
            this.props.getAllLogbookForFrontline(values,size,page,[]);
        } else {
            this.props.getAllLogbook(values,size,page,[]);
        }
    }

    activeJobsClicked = (e) => {
        this.props.accountActiveChanged()
    }
    // this.props.SearchAreaName(values, this.props.user);

    resetClicked = () => {
        this.props.reset('searchLogbookForm');
        this.props.logbookAreaPageClicked(0)
        const value = {}
        value['status'] = 'pending'
        if(this.props.user.role === 'frontline') {
            this.props.getAllLogbookForFrontline(value,this.props.logbook_rows,1,[]);
        } else {
            this.props.getAllLogbook(value,this.props.logbook_rows,1,[]);
        }
    }

    handleClickDeleteModel = (account) => {

        this.props.handleClickDeleteModel(account);
    }

    handleDeleteClose = () => {
        this.props.handleDeleteClose();
    }

    onAccountExportClicked = () => {
        const userDetails = getUser()
        this.props.getAccountExportData(userDetails.mode).then(response => {
            let ele = document.getElementById('accountExport')
                ele.click()
        })
    }

    onAccountsPageSizeChange = (pageNo) => {
        let rows = {
            account_no_of_rows: pageNo
        }
        // this.props.onAccountsPageSizeChange(rows);
    }

    previewAccountClicked = (account) => {

        this.props.previewAccountUsers(account);
        this.props.history.push(`/accounts/${account.user ? account.user.id : account.id}/account_users?job_users=true`);
    }

    previewLogBookAreaQrScanClicked = (id) => {
        this.props.previewLogBookAreaQrScanClicked(id);
    }

    logbookQRCamera = (account) => {
        storage.set('logScanHistory', this.props.history.location.pathname)
        this.props.history.push({pathname : routes.SCAN_LOGBOOK_QR, state: {logId: account.id }, search: account.reviewed_at ? `?quality_rejected=true` : ''  });
    }

    render() {

        return (
            <LogbookArea
                {...this.props}
                resetClicked={this.resetClicked}
                onSubmit={this.searchAccountClicked}
                previewAccountClicked={this.previewAccountClicked}
                editAccountClicked={this.editAccountClicked}
                deleteAccount={this.deleteAccount}
                onAccountsPageSizeChange={this.onAccountsPageSizeChange}
                accountsList={this.props.accountsList}
                isLoading={this.props.isLoading}
                newAccountClicked={this.newAccountClicked}
                handleDeleteClose={this.handleDeleteClose}
                openDeleteModel={this.props.openDeleteModel}
                handleClickDeleteModel={this.handleClickDeleteModel}
                deleteObject={this.props.deleteObject}
                accounts_rows={this.props.accounts_rows}
                onAccountExportClicked={this.onAccountExportClicked}
                activeJobs={this.props.activeJobs}
                activeJobsClicked={this.activeJobsClicked}
                previewLogBookAreaQrScanClicked={this.previewLogBookAreaQrScanClicked}
                logbookQRCamera={this.logbookQRCamera}
            // handleClose={this.handleClose} 
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        account: state.adminOrSuperAdminReducer.account,
        logbook_rows: state.adminOrSuperAdminReducer.logbook_rows,
        accountsList: state.adminOrSuperAdminReducer.modeAccountsList,
        logbookLogs: state.adminOrSuperAdminReducer.logbookLogs,
        isLoading: state.adminOrSuperAdminReducer.isLoading,
        deleteObject: state.adminOrSuperAdminReducer.deleteObject,
        openDeleteModel: state.adminOrSuperAdminReducer.openDeleteModel,
        accounts_rows: state.adminOrSuperAdminReducer.accounts_rows,
        user: state.authReducer.user,
        accountExportDataList: state.adminOrSuperAdminReducer.accountExportData,
        activeJobs: state.adminOrSuperAdminReducer.activeJobs,
        logbookAreaCurPage: state.adminOrSuperAdminReducer.logbookAreaCurPage,
        viewLogbookQrScan: state.adminOrSuperAdminReducer.viewLogbookQrScan,
        total_reviewed: state.adminOrSuperAdminReducer.total_reviewed,
        total_pending: state.adminOrSuperAdminReducer.total_pending,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        // handleClose: () => dispatch(actions.handleClose()),
        previewLogBookAreaQrScanClicked: (scan) => dispatch(actions.previewLogBookAreaQrScanClicked(scan)),
        handleDeleteClose: () => dispatch(actions.handleDeleteClose()),
        onAccountsPageSizeChange: (rows) => dispatch(actions.onAccountsPageSizeChange(rows)),
        handleClickDeleteModel: (account) => dispatch(actions.handleClickDeleteModel(account)),
        searchAccount: (values, user) => dispatch(actions.searchAccount(values, user)),
        editAccountClicked: (account) => dispatch(actions.editAccountClicked(account)),
        deleteAccount: (id) => dispatch(actions.deleteAccount(id)),
        getAllLogbook: (values,size,page,sorted) => dispatch(actions.getAllLogbook(values,size,page,sorted)),
        getAccounts: (values, account_no_of_rows, page, sorted, filtered) => dispatch(actions.getAccounts(values, account_no_of_rows, page, sorted, filtered)),
        getUserAccounts: (values, account_no_of_rows, page, sorted, filtered, userId) => dispatch(actions.getUserPaginationAccounts(values, account_no_of_rows, page, sorted, filtered, userId)),
        previewAccountUsers: (account) => dispatch(actions.previewAccountUsers(account)),
        reset: (searchLogbookForm) => dispatch(reset(searchLogbookForm)),  // requires form name
        getAccountExportData: (mode) => dispatch(actions.getAccountExportData(mode)),
        accountActiveChanged: () => dispatch(actions.accountActiveChanged()),
        logbookAreaPageClicked: (page) => dispatch(actions.logbookAreaPageClicked(page)),
        getModeAccounts: (mode) => dispatch(actions.getModeAccounts(mode)),
        getAllLogbookForFrontline: (values,size,page,sorted) => dispatch(actions.getAllLogbookForFrontline(values,size,page,sorted)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LogBookAreaContainer);