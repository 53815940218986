import storage from "../../../../utility/storage";
import SuccessAlert from "../../../../utility/successAlert/successAlert";
import { formAuditActionTypes } from "../../../actions/FormAuditModAction/TeamManagementAction/teamManagementTypes";

export const initialState = {
  dataGenerated: false,
  teamManagementToggle: false,
  os1Toggle: false,
  isLoading: false,
  isEditLog: false,
  isUpdateTrainingLog: false,
  trainingLog: null,
  isUpdateSupervisor: false,
  supervisorDetails: null,
  isUpdateManager: false,
  managerDetails: null,
  managersList: [],
  checklistLog: {},
  isChecklistLogLoading: false,
  customersList: [],
  jobList: [],
  employeeList: [],

  ischecklistLog: false,
  checklistLog_list: [],
  checklistLog_total_pages: 1,
  checklistLog_no_of_rows: 10,
  checklistLogCurPage: 0,
  total_checklistLog_count: '',

  ischecklistManager: false,
  checklistManager_list: [],
  checklistManager_total_pages: 1,
  checklistManager_no_of_rows: 10,
  checklistManagerCurPage: 0,
  total_checklistManager_count: '',

  ischecklistSupervisor: false,
  checklistSupervisor_list: [],
  checklistSupervisor_total_pages: 1,
  checklistSupervisor_no_of_rows: 10,
  checklistSupervisorCurPage: 0,
  total_checklistSupervisor_count: '',

  ischecklist: false,
  checklist_list: [],
  checklist_total_pages: 1,
  checklist_no_of_rows: 10,
  checklistCurPage: 0,
  total_checklist_count: '',

  ManagerDetails: null,
  managersList: [],
  checklistLog: {},
  isChecklistLogLoading: false,
  customersList: [],
  jobList: [],
  employeeList: [],

  istrainingLog: false,
  trainingLog_list: [],
  trainingLog_total_pages: 1,
  trainingLog_no_of_rows: 10,
  trainingLogCurPage: 0,
  total_trainingLog_count: '',

  checklist : null,
  isChecklistLoading : false,
  teamChecklistJSON : {},
  usersList:[],
  usersList_with_out_client:[]
};

const updateObject = (oldState, updatedProps) => {
  return {
    ...oldState,
    ...updatedProps,
  };
};

export const FormAuditModReducer = (state = initialState, action) => {
  switch (action.type) {
    case formAuditActionTypes.TEAM_MANAGEMENT_TAB_CLICKED:
      return updateObject(state, {
        teamManagementToggle: !state.teamManagementToggle,
      });

    case formAuditActionTypes.OS1_TAB_CLICKED:
      return updateObject(state, { os1Toggle: !state.os1Toggle });

    case formAuditActionTypes.GET_SINGLE_TRAINING_LOG_PENDING:

      return updateObject(state, {
        isLoading: false,
        isEditLog: false,
        isUpdateTrainingLog: true,
        trainingLog: null,
      });
    case formAuditActionTypes.GET_SINGLE_TRAINING_LOG_FULFILLED:

      return updateObject(state, {
        isLoading: false,
        isEditLog: true,
        isUpdateTrainingLog: false,
        // singleAssetData: action.payload.data,
        trainingLog: action?.payload ? action.payload : null,
        //inventoryLog: action.payload.data.product.status,
      });

    case formAuditActionTypes.GET_SINGLE_TRAINING_LOG_REJECTED:

      return updateObject(state, {
        isLoading: false,
        isEditLog: true,
        isUpdateTrainingLog: false,
        singleAssetData: action.payload.data,
        trainingLog: null,
        //inventoryLog: action.payload.data.product.status,
      });

    case formAuditActionTypes.GET_SINGLE_SUPERVISOR_PENDING:
      return updateObject(state, {
        isLoading: false,
        isEditLog: false,
        isUpdateSupervisor: true,
        supervisorDetails: null,
      });
    case formAuditActionTypes.GET_SINGLE_SUPERVISOR_FULFILLED:

      return updateObject(state, {
        isLoading: false,
        isEditLog: true,
        isUpdateSupervisor: false,
        singleAssetData: action.payload.data,
        supervisorDetails: action.payload,
        //inventoryLog: action.payload.data.product.status,
      });

    case formAuditActionTypes.GET_SINGLE_MANAGER_PENDING:
      return updateObject(state, {
        isLoading: false,
        isEditLog: false,
        isUpdateManager: true,
        ManagerDetails: null,
      });

    case formAuditActionTypes.GET_SINGLE_MANAGER_FULFILLED:
      return updateObject(state, {
        isLoading: false,
        isEditLog: true,
        isUpdateManager: false,
        singleAssetData: action.payload.data,
        ManagerDetails: action.payload,
        //inventoryLog: action.payload.data.product.status,
      });

    case formAuditActionTypes.GET_CUSTOMER_INFO_PENDING:
      return updateObject(state, {
        isLoading: false,
        isEditLog: false,
        isUpdateManager: true,
        // managerDetails: null,
      });

    case formAuditActionTypes.GET_CUSTOMER_INFO_FULFILLED:
       
      return updateObject(state, {
        isLoading: false,
        isEditLog: true,
        isUpdateManager: false,
        customersList: action.payload.data,
        // managerDetails: action.payload,
        //inventoryLog: action.payload.data.product.status,
      });

    case formAuditActionTypes.GET_ALL_JOB_PENDING:
      return updateObject(state, {
        isLoading: false,
        isEditLog: false,
        isUpdateManager: true,
        // managerDetails: null,
      });

    case formAuditActionTypes.GET_ALL_JOB_FULFILLED:
      
      return updateObject(state, {
        isLoading: false,
        isEditLog: true,
        isUpdateManager: false,
        jobList: action.payload.data,
        // managerDetails: action.payload,
        //inventoryLog: action.payload.data.product.status,
      });

    case formAuditActionTypes.ADD_TEAM_MANAGEMENT_PENDING:
      return updateObject(state, { isLoading: true });
    case formAuditActionTypes.ADD_TEAM_MANAGEMENT_FULFILLED:
      return updateObject(state, { isLoading: false });
    case formAuditActionTypes.ADD_TEAM_MANAGEMENT_REJECTED:
      return updateObject(state, { isLoading: false });

    case formAuditActionTypes.ADD_TRAINING_LOG_PENDING:
      return updateObject(state, { isLoading: true });
    case formAuditActionTypes.ADD_TRAINING_LOG_FULFILLED:
      return updateObject(state, { isLoading: false });
    case formAuditActionTypes.ADD_TRAINING_LOG_REJECTED:
      return updateObject(state, { isLoading: false });

    case formAuditActionTypes.ADD_CHECKLIST_LOG_PENDING:
      return updateObject(state, { isLoading: true });
    case formAuditActionTypes.ADD_CHECKLIST_LOG_FULFILLED:
      return updateObject(state, { isLoading: false });
    case formAuditActionTypes.ADD_CHECKLIST_LOG_REJECTED:
      return updateObject(state, { isLoading: false });

    case formAuditActionTypes.ADD_CHECKLIST_SUPERVISOR_PENDING:
      return updateObject(state, { isLoading: true });
    case formAuditActionTypes.ADD_CHECKLIST_SUPERVISOR_FULFILLED:
      return updateObject(state, { isLoading: false });
    case formAuditActionTypes.ADD_CHECKLIST_SUPERVISOR_REJECTED:
      return updateObject(state, { isLoading: false });

    case formAuditActionTypes.EDIT_CHECKLIST_SUPERVISOR_PENDING:
      return updateObject(state, { isLoading: true });
    case formAuditActionTypes.EDIT_CHECKLIST_SUPERVISOR_FULFILLED:
      return updateObject(state, { isLoading: false });
    case formAuditActionTypes.EDIT_CHECKLIST_SUPERVISOR_REJECTED:
      return updateObject(state, { isLoading: false });


    case formAuditActionTypes.EDIT_TRAINING_LOG_PENDING:
      return updateObject(state, { isLoading: true });
    case formAuditActionTypes.EDIT_TRAINING_LOG_FULFILLED:
      return updateObject(state, { isLoading: false });
    case formAuditActionTypes.EDIT_TRAINING_LOG_REJECTED:
      return updateObject(state, { isLoading: false, });

    case formAuditActionTypes.EDIT_TEAM_MANAGEMENT_PENDING:
      return updateObject(state, { isLoading: true });
    case formAuditActionTypes.EDIT_TEAM_MANAGEMENT_FULFILLED:
      return updateObject(state, { isLoading: false });
    case formAuditActionTypes.EDIT_TEAM_MANAGEMENT_REJECTED:
      return updateObject(state, { isLoading: false });

    case formAuditActionTypes.GET_MANAGERS_LIST_PENDING:
      return updateObject(state, {});
    case formAuditActionTypes.GET_MANAGERS_LIST_FULFILLED:
      return updateObject(state, { managersList: action.payload.managersList });

    case formAuditActionTypes.GET_EMPLOYEE_LIST_PENDING:
      return updateObject(state, { employeeList: null });
    case formAuditActionTypes.GET_EMPLOYEE_LIST_FULFILLED:
      return updateObject(state, { employeeList: action.payload.employeeList });

    case formAuditActionTypes.EDIT_CHECKLIST_LOG_PENDING:
      return updateObject(state, { isLoading: true });
    case formAuditActionTypes.EDIT_CHECKLIST_LOG_FULFILLED:
      return updateObject(state, { isLoading: false });
    case formAuditActionTypes.EDIT_CHECKLIST_LOG_REJECTED:
      return updateObject(state, { isLoading: false, checklistLog: null });

    case formAuditActionTypes.GET_SINGLE_CHECKLIST_LOG_PENDING:
      return updateObject(state, {
        checklistLog: null,
        isChecklistLogLoading: true,
      });
    case formAuditActionTypes.GET_SINGLE_CHECKLIST_LOG_FULFILLED:
      return updateObject(state, {
        checklistLog: action.payload.checklistLog,
        isChecklistLogLoading: false,
      });

    case formAuditActionTypes.ADD_MORE_CHECKLIST_LOG_CLICKED:
      return updateObject(state, { checklistLog: null });

    case formAuditActionTypes.GET_ALL_CHECKLIST_LOG_PENDING:
      return updateObject(state, {
        isLoading: true,
        ischecklistLog: true,
        checklistLog_list: [],
      });
    case formAuditActionTypes.GET_ALL_CHECKLIST_LOG_FULFILLED:
      return updateObject(state, {
        isLoading: false,
        dataGenerated: false,
        ischecklistLog: false,
        checklistLog_list: action.payload.checklistLog_list,
        checklistLog_total_pages: action.payload.checklistLog_total_pages,
        checklistLog_no_of_rows: action.payload.checklistLog_no_of_rows,
        checklistLogCurPage: action.payload.checklistLogCurPage,
        total_checklistLog_count: action.payload.total_checklistLog_count,
      });

    case formAuditActionTypes.CHECKLIST_LOG_LIST_PAGE_CLICKED:
      return updateObject(state, { checklistLogCurPage: action.payload });


    case formAuditActionTypes.GET_ALL_CHECKLIST_MANAGER_PENDING:
      return updateObject(state, {
        isLoading: true,
        ischecklistManager: true,
        checklistManager_list: [],
      });
    case formAuditActionTypes.GET_ALL_CHECKLIST_MANAGER_FULFILLED:
      return updateObject(state, {
        isLoading: false,
        dataGenerated: false,
        ischecklistManager: false,
        checklistManager_list: action.payload.checklistManager_list,
        checklistManager_total_pages: action.payload.checklistManager_total_pages,
        checklistManager_no_of_rows: action.payload.checklistManager_no_of_rows,
        checklistManagerCurPage: action.payload.checklistManagerCurPage,
        total_checklistManager_count: action.payload.total_checklistManager_count,
      });

    case formAuditActionTypes.CHECKLIST_MANAGER_LIST_PAGE_CLICKED:
      return updateObject(state, { checklistManagerCurPage: action.payload });

    case formAuditActionTypes.GET_ALL_CHECKLIST_SUPERVISOR_PENDING:
      return updateObject(state, {
        isLoading: true,
        ischecklistSupervisor: true,
        checklistSupervisor_list: [],
      });
    case formAuditActionTypes.GET_ALL_CHECKLIST_SUPERVISOR_FULFILLED:
      return updateObject(state, {
        isLoading: false,
        dataGenerated: false,
        ischecklistSupervisor: false,
        checklistSupervisor_list: action.payload.checklistSupervisor_list,
        checklistSupervisor_total_pages: action.payload.checklistSupervisor_total_pages,
        checklistSupervisor_no_of_rows: action.payload.checklistSupervisor_no_of_rows,
        checklistSupervisorCurPage: action.payload.checklistSupervisorCurPage,
        total_checklistSupervisor_count: action.payload.total_checklistSupervisor_count,
      });

    case formAuditActionTypes.CHECKLIST_SUPERVISOR_LIST_PAGE_CLICKED:
      return updateObject(state, { checklistSupervisorCurPage: action.payload });


    case formAuditActionTypes.GET_ALL_CHECKLIST_PENDING:
      return updateObject(state, {
        isLoading: true,
        ischecklist: true,
        checklist_list: [],
      });
    case formAuditActionTypes.GET_ALL_CHECKLIST_FULFILLED:
      return updateObject(state, {
        isLoading: false,
        dataGenerated: false,
        ischecklist: false,
        checklist_list: action.payload.checklist_list,
        checklist_total_pages: action.payload.checklist_total_pages,
        checklist_no_of_rows: action.payload.checklist_no_of_rows,
        checklistCurPage: action.payload.checklistCurPage,
        total_checklist_count: action.payload.total_checklist_count,
      });

    case formAuditActionTypes.CHECKLIST_PAGE_CLICKED:
      return updateObject(state, { checklistCurPage: action.payload });

    case formAuditActionTypes.GET_ALL_TRAINING_LOG_PENDING:
      return updateObject(state, {
        isLoading: true,
        isTrainingLog: true,
        trainingLog_list: [],
      });
    case formAuditActionTypes.GET_ALL_TRAINING_LOG_FULFILLED:
      return updateObject(state, {
        isLoading: false,
        dataGenerated: false,
        isTrainingLog: false,
        trainingLog_list: action.payload.trainingLog_list,
        trainingLog_total_pages: action.payload.trainingLog_total_pages,
        trainingLog_no_of_rows: action.payload.trainingLog_no_of_rows,
        trainingLogCurPage: action.payload.trainingLogCurPage,
        total_trainingLog_count: action.payload.total_trainingLog_count,
      });

    case formAuditActionTypes.TRAINING_LOG_PAGE_CLICKED:
      return updateObject(state, { trainingLogCurPage: action.payload });

    case formAuditActionTypes.ADD_CHECKLIST_PENDING:
      return updateObject(state, { isLoading: true });
    case formAuditActionTypes.ADD_CHECKLIST_FULFILLED:
      return updateObject(state, { isLoading: false });
    case formAuditActionTypes.ADD_CHECKLIST_REJECTED:
      return updateObject(state, { isLoading: false });

    case formAuditActionTypes.GET_SINGLE_CHECKLIST_PENDING:
      return updateObject(state, {
        checklist: null,
        isChecklistLoading: true,
      });
    case formAuditActionTypes.GET_SINGLE_CHECKLIST_FULFILLED:
      return updateObject(state, {
        checklist: action.payload.checklist,
        isChecklistLoading: false,
      });

      case formAuditActionTypes.ADD_MORE_CHECKLIST_CLICKED:
      return updateObject(state, { checklist: null });

      case formAuditActionTypes.EDIT_CHECKLIST_PENDING:
      return updateObject(state, { isLoading: true });
    case formAuditActionTypes.EDIT_CHECKLIST_FULFILLED:
      return updateObject(state, { isLoading: false });
    case formAuditActionTypes.EDIT_CHECKLIST_REJECTED:
      return updateObject(state, { isLoading: false });

      case formAuditActionTypes.GET_USERS_LIST_PENDING:
        return updateObject(state, {});
      case formAuditActionTypes.GET_USERS_LIST_FULFILLED:
        return updateObject(state, { usersList: action.payload });

        case formAuditActionTypes.GET_USERS_LIST_WITH_OUT_CLIENT_PENDING:
        return updateObject(state, {});
      case formAuditActionTypes.GET_USERS_LIST_WITH_OUT_CLIENT_FULFILLED:
        return updateObject(state, { usersList_with_out_client: action.payload });

        case formAuditActionTypes.GET_CLEANING_WORKERS_PENDING:
          return updateObject(state, { cleaning_worker_list: null });
        case formAuditActionTypes.GET_CLEANING_WORKERS_FULFILLED:
          return updateObject(state, { cleaning_worker_list: action.payload.employeeList });

          case formAuditActionTypes.RESET_JOB_COMPANY:
            
          return updateObject(state, { jobList: [] });

    default:
      return state;
  }
};
