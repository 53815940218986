import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Input } from '../../../../UI/InputElement/reactFormHookInputField'
import { Controller } from 'react-hook-form'
import {} from 'react-bootstrap'
function JobCardModalRestroom({ setValue, dayCount, isView ,modalData,closeModal }) {

  const defaultForm = {
    "table_header": "",
    "lineItems": [
      {
        "notes": "",
        "time": null
      },
      {
        "notes": "",
        "time": null
      },
      {
        "notes": "",
        "time": null
      },
      {
        "notes": "",
        "time": null
      },
      
    ]
  }
  
  const { formState: { errors,defaultValues }, handleSubmit: ModalSubmit, getValues, reset, trigger, register, control,setValue:setValueForm } = useForm({
    defaultValues:useEffect(()=>{
    
      if (modalData) {
        reset(modalData)

      } else {
        reset(defaultForm)
      }
      
    },[modalData]),
    shouldFocusError:false
    
  })

  const controller = Controller
  

  const CloseModalWithReset=()=>{
    reset()
    closeModal()
  }
  
  return (
    <div class="modal-dialog">
      <form  class="modal-content" noValidate style={{width:"45rem"}} onSubmit={ModalSubmit((data)=>{
          data.sort=dayCount
          setValue(`sections[${dayCount-1}]`, data,{ shouldDirty: true })
          CloseModalWithReset()
          
         
      })}>
        <div class="modal-header">
          <div class="form-group m-0">
            {/* <input
              type="text"
              class="form-control"
              id="jobname"
              aria-describedby=""
              placeholder="Title"
            /> */}
                <Input
                    type="text"
                    id={`table_header`}
                    name={`table_header`}
                    formControlClasses="form-control"
                    
                    
                    placeholder="Day"
                    formControlProps={register(
                      `table_header`,
                      {
                        required: "This field is required",
                      }
                    )}
                    validationErrorMessage={
                      errors?.table_header?.message ?? ""
                    }
                    disabled={isView}
                  />
          </div>
          <button
            id='CloseModal'
            type="button"
            class="btn-close btn p-0"
            style={{
              backgroundColor:"#7c2b2b"
          }}
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <img src="/images/icons/cross_icon.svg" alt="cross_icon" />
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-4 col-sm-4">
              <div class="form-group" >

                {
                  dayCount === 1 ? <Input
                    type="text"
                    id={`core_1`}
                    name={`core_1`}
                    formControlClasses="form-control sized-core-utility place_adjust"
                    
                    required
                    placeholder="Clean For Appearance Core I"//Clean for appearance core 1
                    
                    readOnly={true}
                  /> : <Input
                    type="text"
                    id={`core_1`}
                    name={`core_1`}
                    formControlClasses={`form-control ${dayCount !== 1 ? "" :"" } `}
                    // style={{
                    //   marginTop: dayCount !==1 ? {marginTop:"32px"} : {}
                    // }}
                    required
                    placeholder="Core I"
                    
                    
                    readOnly={true}
                  />
                }

              </div>
            </div>
            <div class="col-md-4 col-sm-4">
              <div class="form-group">
                {/* <label for="jobname" class="form-label">Time</label>
                <input
                  type="time"
                  class="form-control"
                  id="jobname"
                  aria-describedby=""
                  placeholder="Time"
                /> */}

                <Input
                  type="date"
                  datePickerType="time"
                  formControlClasses="form-control  "
                  id={`lineItems[0].time`}
                  placeholder="Time"
                  //style={{marginTop:"32px"}}
                  control={control}
                  controller={controller}
                  selectName={`lineItems[0].time`}
                  dateFormat="MM/dd/yyyy"
                  
                  // minDate={new Date().setDate(current.getDate() - 400)}
                  preventReadOnly={false}
                  // label="Time"
                  formControlProps={register(`lineItems[0].time`, {
                    required: "This field is required", maxLength: 80
                  })}
                  validationErrorMessage={errors?.lineItems?.[0]?.time?.message ?? ""}
                  disabled={isView}
                />
                {/* <div class="calendar_icon">
                  <img src="/images/icons/reminder.svg" alt="calendar" />
                </div> */}
              </div>
            </div>
            <div class="col-md-4 col-sm-4">
              <div class="form-group">
                {/* <label for="jobname" class="form-label">Notes</label>
                <input
                  type="text"
                  class="form-control"
                  id="jobname"
                  aria-describedby=""
                  placeholder="Notes"
                /> */}

                <Input
                  type="text"
                  id={`lineItems[0].notes`}
                  name={`lineItems[0].notes`}
                  formControlClasses="form-control "
                  
                  required
                  placeholder="Notes"
                  formControlProps={register(
                    `lineItems[0].notes`,
                    {
                      required: "This field is required",
                    }
                  )}
                  validationErrorMessage={
                    errors?.lineItems?.[0]?.notes?.message ?? ""
                  }
                  disabled={isView}
                />
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-4 col-sm-4">
              <div class="form-group">
                {/* <label for="jobname" class="form-label invisible">Core II</label>
                <input
                  type="text"
                  class="form-control"
                  id="jobname"
                  aria-describedby=""
                  placeholder="Core II"
                /> */}
                {
                  dayCount === 2 ? <Input
                    type="text"
                    id={`core_2`}
                    name={`core_2`}
                    formControlClasses="form-control sized-core-utility place_adjust mtop-job-card"
                    required
                    placeholder="Clean For Appearance Core II"
                    
                    readOnly={true}
                  /> : <Input
                    type="text"
                    id={`core_2`}
                    name={`core_2`}
                    formControlClasses="form-control mtop-job-card"
                    
                    required
                    placeholder="Core II"
                    
                    readOnly={true}
                  />
                }

              </div>
            </div>
            <div class="col-md-4 col-sm-4">
              <div class="form-group">
                {/* <label for="jobname" class="form-label">Time</label>
                <input
                  type="time"
                  class="form-control"
                  id="jobname"
                  aria-describedby=""
                  placeholder="Time"
                  style={{marginTop:"32px"}}
                /> */}

                <Input
                  type="date"
                  datePickerType="time"
                  formControlClasses="form-control mtop-job-card"
                  id={`lineItems[1].time`}
                  placeholder="Time"
                  style={{marginTop:"32px"}}
                  control={control}
                  controller={controller}
                  selectName={`lineItems[1].time`}
                  dateFormat="MM/dd/yyyy"
                  // minDate={new Date().setDate(current.getDate() - 41)}
                  preventReadOnly={false}
                  // label="Time"
                  formControlProps={register(`lineItems[1].time`, {
                    required: "This field is required", 
                  })}
                  validationErrorMessage={errors?.lineItems?.[1]?.time?.message ?? ""}
                  disabled={isView}
                />

                {/* <div class="calendar_icon">
                  <img src="images/icons/reminder.svg" alt="calendar" />
                </div> */}
              </div>
            </div>
            <div class="col-md-4 col-sm-4">
              <div class="form-group">
                {/* <label for="jobname" class="form-label">Notes</label>
                <input
                  type="text"
                  class="form-control"
                  id="jobname"
                  aria-describedby=""
                  placeholder="Notes"
                /> */}
                <Input
                  type="text"
                  id={`lineItems[1].notes`}
                  name={`lineItems[1].notes`}
                  formControlClasses="form-control mtop-job-card"
                  
                  required
                  placeholder="Notes"
                  formControlProps={register(
                    `lineItems[1].notes`,
                    {
                      required: "This field is required",
                    }
                  )}
                  validationErrorMessage={
                    errors?.lineItems?.[1]?.notes?.message ?? ""
                  }
                  disabled={isView}
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4 col-sm-4">
              <div class="form-group">
                {/* <label for="jobname" class="form-label invisible">Core III</label>
                <input
                  type="text"
                  class="form-control"
                  id="jobname"
                  aria-describedby=""
                  placeholder="Core III"
                /> */}
                {
                  dayCount === 3 ? <Input
                    type="text"
                    id={`core_3`}
                    name={`core_3`}
                    formControlClasses="form-control sized-core-utility place_adjust mtop-job-card"
                    
                    required
                    placeholder="Clean For Appearance Core III"
                    
                    readOnly={true}
                  /> : <Input
                    type="text"
                    id={`core_3`}
                    name={`core_3`}
                    formControlClasses="form-control mtop-job-card"
                    
                    required
                    placeholder="Core III"
                    
                    readOnly={true}
                  />
                }

              </div>
            </div>
            <div class="col-md-4 col-sm-4">
              <div class="form-group">
                {/* <label for="jobname" class="form-label">Time</label>
                <input
                  type="time"
                  class="form-control"
                  id="jobname"
                  aria-describedby=""
                  placeholder="Time"
                  style={{marginTop:"32px"}}
                /> */}

                <Input
                  type="date"
                  datePickerType="time"
                  formControlClasses="form-control mtop-job-card"
                  id={`lineItems[2].time`}
                  placeholder="Time"
                  style={{marginTop:"32px"}}
                  control={control}
                  controller={controller}
                  selectName={`lineItems[2].time`}
                  dateFormat="MM/dd/yyyy"
                  // minDate={new Date().setDate(current.getDate() - 42)}
                  preventReadOnly={false}
                  // label="Time"
                  formControlProps={register(`lineItems[2].time`, {
                    required: "This field is required", maxLength: 80
                  })}
                  validationErrorMessage={errors?.lineItems?.[2]?.time?.message ?? ""}
                  disabled={isView}
                />

                {/* <div class="calendar_icon">
                  <img src="images/icons/reminder.svg" alt="calendar" />
                </div> */}
              </div>
            </div>
            <div class="col-md-4 col-sm-4">
              <div class="form-group">
                {/* <label for="jobname" class="form-label">Notes</label>
                <input
                  type="text"
                  class="form-control"
                  id="jobname"
                  aria-describedby=""
                  placeholder="Notes"
                /> */}

                <Input
                  type="text"
                  id={`lineItems[2].notes`}
                  name={`lineItems[2].notes`}
                  formControlClasses="form-control mtop-job-card"
                  
                  required
                  placeholder="Notes"
                  formControlProps={register(
                    `lineItems[2].notes`,
                    {
                      required: "This field is required",
                    }
                  )}
                  validationErrorMessage={
                    errors?.lineItems?.[2]?.notes?.message ?? ""
                  }
                  disabled={isView}
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4 col-sm-4">
              <div class="form-group">
                {/* <label for="jobname" class="form-label invisible">Core IV</label>
                <input
                  type="text"
                  class="form-control"
                  id="jobname"
                  aria-describedby=""
                  placeholder="Core IV"
                /> */}

                {

                  dayCount === 4 ? <Input
                    type="text"
                    id={`core_4`}
                    name={`core_4`}
                    formControlClasses="form-control sized-core-utility place_adjust mtop-job-card"
                    
                    required
                    placeholder="Clean For Appearance Core IV"
                    readOnly={true}
                  /> : <Input
                    type="text"
                    id={`core_4`}
                    name={`core_4`}
                    formControlClasses="form-control mtop-job-card"
                    
                    required
                    placeholder="Core IV"
                    readOnly={true}
                  />
                }


              </div>
            </div>
            <div class="col-md-4 col-sm-4">
              <div class="form-group">
                {/* <label for="jobname" class="form-label">Time</label>
                <input
                  type="time"
                  class="form-control"
                  id="jobname"
                  aria-describedby=""
                  placeholder="Time"
                  style={{marginTop:"32px"}}
                /> */}

                <Input
                  type="date"
                  datePickerType="time"
                  formControlClasses="form-control mtop-job-card"
                  id={`lineItems[3].time`}
                  placeholder="Time"
                  style={{marginTop:"32px"}}
                  control={control}
                  controller={controller}
                  selectName={`lineItems[3].time`}
                  dateFormat="MM/dd/yyyy"
                  // minDate={new Date().setDate(current.getDate() - 43)}
                  preventReadOnly={false}
                  // label="Time"
                  formControlProps={register(`lineItems[3].time`, {
                    required: "This field is required", maxLength: 80
                  })}
                  validationErrorMessage={errors?.lineItems?.[3].time?.message ?? ""}
                  disabled={isView}
                />

                {/* <div class="calendar_icon">
                  <img src="images/icons/reminder.svg" alt="calendar" />
                </div> */}
              </div>
            </div>
            <div class="col-md-4 col-sm-4">
              <div class="form-group">
                {/* <label for="jobname" class="form-label">Notes</label>
                <input
                  type="text"
                  class="form-control"
                  id="jobname"
                  aria-describedby=""
                  placeholder="Notes"
                /> */}

                <Input
                  type="text"
                  id={`lineItems[3].notes`}
                  name={`lineItems[3].notes`}
                  formControlClasses="form-control mtop-job-card"
                  
                  required
                  placeholder="Notes"
                  formControlProps={register(
                    `lineItems[3].notes`,
                    {
                      required: "This field is required",
                    }
                  )}
                  validationErrorMessage={
                    errors?.lineItems?.[3].notes?.message ?? ""
                  }
                  disabled={isView}
                />

              </div>
            </div>
          </div>
          {
            dayCount === 5 ?
              <div className='row'>
                <div class="col-md-4 col-sm-4">
                  <div class="form-group" style={{ justifyItems: "center" }}>
                    <Input
                      type="text"
                      id={`deepclean`}
                      name={`deepclean`}
                      formControlClasses="form-control mtop-job-card"
                      
                      required
                      placeholder="Deep clean"
                      readOnly={true}
                    />

                  </div>
                </div>

                <div class="col-md-4 col-sm-4">
                  <div class="form-group">
                    {/* <label for="jobname" class="form-label">Time</label>
                <input
                  type="time"
                  class="form-control"
                  id="jobname"
                  aria-describedby=""
                  placeholder="Time"
                  style={{marginTop:"32px"}}
                /> */}

                    <Input
                      type="date"
                      datePickerType="time"
                      formControlClasses="form-control"
                      id={`lineItems[4].time`}
                      placeholder="Time"
                      style={{marginTop:"32px"}}
                      control={control}
                      controller={controller}
                      selectName={`lineItems[4].time`}
                      dateFormat="MM/dd/yyyy"
                      // minDate={new Date().setDate(current.getDate() - 400)}
                      preventReadOnly={false}
                      // label="Time"
                      formControlProps={register(`lineItems[4].time`, {
                        required: "This field is required", maxLength: 80
                      })}
                      validationErrorMessage={errors?.lineItems?.[4]?.notes?.message ?? ""}
                      disabled={isView}
                    />

                    {/* <div class="calendar_icon">
                  <img src="images/icons/reminder.svg" alt="calendar" />
                </div> */}
                  </div>
                </div>
                <div class="col-md-4 col-sm-4">
                  <div class="form-group">
                    {/* <label for="jobname" class="form-label">Notes</label>
                <input
                  type="text"
                  class="form-control"
                  id="jobname"
                  aria-describedby=""
                  placeholder="Notes"
                /> */}

                    <Input
                      type="text"
                      id={`lineItems[4].notes`}
                      name={`lineItems[4].notes`}
                      formControlClasses="form-control mtop-job-card"
                      
                      required
                      placeholder="Notes"
                      formControlProps={register(
                        `lineItems[4].notes`,
                        {
                          required: "This field is required",
                        }
                      )}
                      validationErrorMessage={
                        errors?.lineItems?.[4]?.notes?.message ?? ""
                      }
                      disabled={isView}
                    />

                  </div>
                </div>

              </div>

              : null
          }
        </div>
        {
          !isView ? <div class="modal-footer">
          <button
            id='submitM'
            type="submit"
            class="btn theme_def_btn1"
          // data-bs-toggle="modal"
          // data-bs-target="#exampleModal"
          >
            Submit
          </button>
        </div> : null
        }
      </form>

    </div>

  )
}

export default JobCardModalRestroom
