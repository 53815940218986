import React, { Component } from 'react';
import { routes } from '../../../../../utility/constants/constants';
import * as actions from '../../../../../redux/actions/index';
import { connect } from 'react-redux';
import { reset } from 'redux-form';
import { animateScroll as scroll} from 'react-scroll'
import storage from '../../../../../utility/storage';
import { convertDateToDifferentTZ } from '../../../../../utility/utility';
import TeamChecklistlog from '../../../../component/FormAuditModule/TeamManagement/TeamChecklistLog/teamChecklistlog';

var moment = require('moment');
const cloneDeep = require('clone-deep');

class TeamChecklistLogContainer extends Component {

    constructor(props) {
        super(props);

        this.state = {
            
        }
    }

    componentDidMount() {
        scroll.scrollToTop();
    }

    componentDidUpdate(prevProps, prevState) {
        const user = storage.get('user');
        if(user) {
            if(prevProps.user.selected_mode !== user.selected_mode) {
                this.props.ChecklistLogListPageClicked(0)
            }
        }
    }

    // resetClicked = () => {
    //     const page = 1
    //     const limit = this.props.checklistLog_no_of_rows
    //     this.props.reset('searchScheduledTask');
    //     this.props.ChecklistLogListPageClicked(0)
    //     this.props.getAllChecklistLog({page,limit})

    // }

    // searchInventoryItemsListClicked = (value) => {
    //     const values = cloneDeep(value)
    //     let accountId =  null;
    //     let taskName = null;
    //     let companyName = null;
    //     let accountName = null;
    //     let from = null;
    //     let to = null;
    
    //     const page = 1
    //     const limit = this.props.checklistLog_no_of_rows
    //     if(values.id) {
    //         values.id = values.id.trim()
    //         accountId = values.id
    //     }
    //   if(values.name) {
    //       values.name = values.name.trim()
    //       taskName = values.name
    //   }
    //   if(values.company) {
    //     values.company = values.company.trim()
    //     companyName = values.company
    //   }
    //   if(values.job) {
    //     values.job = values.job.trim()
    //     accountName =  values.job 
    //     }  
    //   if(values.fromDate) {
    //     values.fromDate = convertDateToDifferentTZ(values.fromDate, 'from');
    //     from = values.fromDate.split('T')[0];
    //   }  
    //   if(values.toDate) {
    //     values.toDate = convertDateToDifferentTZ(values.toDate, 'to');
    //     to = values.toDate.split('T')[0];
    //   }  
     
    //     this.props.getAllChecklistLog({page,limit,accountId,taskName,companyName,accountName,from,to}, values);
    // }

    addInventoryItemClicked = () => {
        this.props.history.push(routes.NEW_INVENTORY_ITEM);
    }

    deleteInventoryItem = (id) => {
        this.props.deleteInventoryItem(id);
    }

    handleInventoryOpenDeleteModal = (value) => {
        this.props.handleInventoryOpenDeleteModal(value);
    }

    handleInventoryCloseDeleteModal = () => {
        this.props.handleInventoryCloseDeleteModal();
    }

    newTicketClicked = () => {
        this.props.history.push(routes.NEW_TEAM_CHECKLIST_LOG);
        this.props.addMoreChecklistLogClicked()
    }
         
    render() {
        return (
            <TeamChecklistlog
                {...this.props}
                isLoading={this.props.isLoading}
                // onSubmit={this.searchInventoryItemsListClicked}
                // resetClicked={this.resetClicked}
                checklistLog_list={this.props.checklistLog_list ? this.props.checklistLog_list : []}
                newTicketClicked={this.newTicketClicked}
                
            />
        )
    }
}


const mapStateToProps = (state) => {
    return {
        user: state.authReducer.user,
        isLoading: state.FormAuditModReducer.isLoading,
        checklistLog_list: state.FormAuditModReducer.checklistLog_list,
        ischecklistLog: state.FormAuditModReducer.ischecklistLog,
        checklistLog_no_of_rows: state.FormAuditModReducer.checklistLog_no_of_rows,
        checklistLogCurPage: state.FormAuditModReducer.checklistLogCurPage,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        reset: (searchInventoryItemsList) => dispatch(reset(searchInventoryItemsList)),  // requires form name
        ChecklistLogListPageClicked: (page) => dispatch(actions.ChecklistLogListPageClicked(page)),
        getAllChecklistLog: (params, values) => dispatch(actions.getAllChecklistLog(params, values)),
        addMoreChecklistLogClicked: () => dispatch(actions.addMoreChecklistLogClicked()),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(TeamChecklistLogContainer);