import React from 'react';
import { reduxForm } from 'redux-form';
import { Input } from '../../UI/InputElement/InputElement';
import { newCompanyValidator as validate } from '../../../../utility/validator/validator';
import UserSkeleton from '../../user/NewUser/userSkeleton';
import isEmpty from '../../../../utility/IsEmptyValidator';
import { connect } from 'react-redux';
import { getUser, makeFirstLetterCapital } from '../../../../utility/utility';

let NewCompany = (props) => {

    const { handleSubmit, pristine, reset, submitting, error, submitFailed } = props;

    // if ((submitFailed) && (!props.address_attributes || (props.address_attributes && !props.address_attributes.formatted_address))) {
    //     props.handleAddressTouch()
    // }

    const accounts = props.accountsList ? props.accountsList.map(account => {
        return {
            value: account.id,
            label: account.name
        }
    }) : [];

    if (props.isEdit && !isEmpty(props.company)) {
        props.company.accounts.map(
            account => {
                accounts.push({
                    value: account.id,
                    label: account.name
                })
            })
    }

    const noOptionsMessage = (a, b) => {
        return 'Job not found';
    }
    const modeOptions =
        [{
            id: 'Internal',
            name: 'Internal'
        }, {
            id: 'External',
            name: 'External'
        }]

    const tierOptions =
        [{
            id: '0',
            name: 'None Selected'
        }, {
            id: '1',
            name: 'Tier - A'
        }, {
            id: '2',
            name: 'Tier - B'
        }, {
            id: '3',
            name: 'Tier - C'
        }]

    if (!props.accountsList) {
        return (
            <UserSkeleton />
        )
    } else {
        return (
            <main className="app-content wraper_content user_newQuote add_account_modal add_New_Accont">
                <div className="inspection_content_sec appcontent_Inner">
                    <div className="container-fluids">
                        <div className="row">
                            <div className="col-sm-12 text-center">
                                <h4 className="section_title">{props.isEdit ? "Update Customer" : "Add a New Customer"}</h4>
                            </div>
                        </div>
                        <form onSubmit={handleSubmit}>
                            <div className="quote_form">
                                <div className="addQuote_form">
                                    <div className="cst_tab_content">
                                        <div className="profile_img_block">
                                            <div className="circle_img user-pic">
                                                <Input hidden={true} type="file" name="photo_path" onSelect={props.onFileChange} fileRef={props.reference} accept="image/*" />
                                                <div className="user-profile-pic">
                                                    <img style={{ height: '90px', width: '90px', borderRadius: '90px' }} src={props.base64 ? props.base64 : props.s3Url ? props.s3Url : "/customImages/user.png"} />
                                                    <div class="icn_userEdit" onClick={props.choosePhotoClicked}><a href="javascript:void (0)"><i class="fa fa-camera" aria-hidden="true"></i></a></div>
                                                </div>
                                            </div>
                                            <div className="clearfix"></div>
                                            <div className="profile-details">
                                            </div>
                                        </div>
                                        <form className="form_content" onSubmit={handleSubmit}>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label for="email" className="form_title">Customer Name</label>
                                                        <Input type="text" className="form-control input-modifier"
                                                            id="email" placeholder="" name="company[name]" />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label for="email" className="form_title">Customer
                                                            Number</label>
                                                        {/* <Input type="number" readOnly={props.isEdit} className="form-control input-modifier" id="email" placeholder="" name="company[company_number]" /> */}
                                                        <Input type="text" className="form-control input-modifier"
                                                            id="email" placeholder=""
                                                            name="company[company_number]" />
                                                    </div>
                                                </div>

                                            </div>
                                            <div className='row'>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label className="form_title">Job</label>
                                                        <Input name="company[account_ids]" isMulti={true}
                                                            noOptionsMessage={noOptionsMessage} type="inputSelect"
                                                            options={accounts} />
                                                    </div>
                                                </div>

                                                <div className="col-md-6">
                                                    <div className="form-group select-disable-icon">
                                                        <label className="form_title">Mode</label>
                                                        <Input name="company[mode]" type="select" options={modeOptions}
                                                            disabled className="custom-select input-modifier " />
                                                        {/* <Input tbype="text" name="account[mode]" style={{ backgroundColor: '#c7cdd154' }} readOnly={true} className="form-control input-modifier" placeholder="" /> */}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label className="form_title">Status</label>
                                                        <Input type="radio" labelClass="custom-control-label"
                                                            forLable={"customRadio1"} value={"active"}
                                                            label={"Active"} className="custom-control-input"
                                                            id="customRadio1" name="company[active_status]" />
                                                        <Input type="radio" labelClass="custom-control-label"
                                                            forLable={"customRadio2"} value={"inactive"}
                                                            label={"Inactive"} className="custom-control-input"
                                                            id="customRadio2" name="company[active_status]" />
                                                    </div>
                                                </div>

                                                <div className="col-md-6">
                                                    <div className="form-group select-disable-icon">
                                                        <label className="form_title">Customer Tier</label>
                                                        <Input name="company[company_tier]" type="select"
                                                            options={tierOptions}
                                                            className="custom-select input-modifier " />
                                                    </div>
                                                </div>


                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label className="form_title">is OS1</label>
                                                        <Input type="radio" labelClass="custom-control-label"
                                                            forLable={"customRadio3"} value={"1"}
                                                            label={"Yes"} className="custom-control-input"
                                                            id="customRadio3" name="company[os1]" />
                                                        <Input type="radio" labelClass="custom-control-label"
                                                            forLable={"customRadio4"} value={"0"}
                                                            label={"No"} className="custom-control-input"
                                                            id="customRadio4" name="company[os1]" />
                                                    </div>
                                                </div>

                                            </div>
                                            {/*</div>*/}

                                            {/*  <div className="row">
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label for="email" className="label_modifier">Street Address</label>
                                                    <GooglePlacesAutoComplete
                                                        value={props.address_attributes ? props.address_attributes.formatted_address : ''}
                                                        {...props}
                                                    />
                                                </div>
                                            </div>
                                         </div> */}

                                            {/*  <div className="row">
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label for="email" className="label_modifier">City</label>
                                                    <input type="text" style={props.isCityEmpty ? { borderColor: 'rgb(240, 77, 83)', borderLeftWidth: '3px' } : null} onChange={props.handleInputChange}  value={props.address_attributes ? props.address_attributes.city : ''} className="form-control input-modifier" name='city' placeholder="" />
                                                    <span style={{ color: '#DD2726', fontSize: '13px' }}>{props.isCityEmpty ? 'This field is required.' : null}</span>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label className="label_modifier">State</label>
                                                    <input type="text" style={props.isStateEmpty ? { borderColor: 'rgb(240, 77, 83)', borderLeftWidth: '3px' } : null} onChange={props.handleInputChange}  value={props.address_attributes ? props.address_attributes.state : ''} className="form-control input-modifier" name='state' placeholder="" />
                                                    <span style={{ color: '#DD2726', fontSize: '13px' }}>{props.isStateEmpty ? 'This field is required.' : null}</span>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label className="label_modifier">Country</label>
                                                    <input type="text" style={props.isCountryEmpty ? { borderColor: 'rgb(240, 77, 83)', borderLeftWidth: '3px' } : null} onChange={props.handleInputChange} value={props.address_attributes ? props.address_attributes.country : ''} className="form-control input-modifier" name="country"  placeholder="" />
                                                    <span style={{ color: '#DD2726', fontSize: '13px' }}>{props.isCountryEmpty ? 'This field is required.' : null}</span>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label className="label_modifier">Zip</label>
                                                    <input data-test="job_zip_data" style={props.isZipEmpty ? { borderColor: 'rgb(240, 77, 83)', borderLeftWidth: '3px' } : null} type="text" onChange={props.handleInputChange} value={props.address_attributes ? props.address_attributes.zip : ''} className="form-control input-modifier" name="zip" placeholder="" />
                                                    <span style={{ color: '#DD2726', fontSize: '13px' }}>{props.isZipEmpty ? 'This field is required.' : null}</span>
                                                </div>
                                            </div> 
                                        </div> */}
                                            {/* <div className="form-group">
                                                <label className="form_title">Status</label>

                                                <Input type="radio" labelClass="custom-control-label" forLable={"customRadio1"} value={"active"} label={"Active"} className="custom-control-input" id="customRadio1" name="company[active_status]" />

                                                <Input type="radio" labelClass="custom-control-label" forLable={"customRadio2"} value={"inactive"} label={"Inactive"} className="custom-control-input" id="customRadio2" name="company[active_status]" />

                                            </div> */}

                                        </form>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="submit" disabled={props.isLoading ? true : false}
                                            className={props.isLoading ? "btn cst_btn btn_danger mr-2 btn-wait" : "btn cst_btn btn_danger mr-2"}>{props.isLoading ? "" : "Submit"} </button>
                                        <button type="button" className="btn btn-outline-secondary cst_btn"
                                            onClick={() => props.onCancelClicked()}>Cancel
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </main>
        )
    }
}

const mapStateToProps = (state) => {
    let company = {
        // active_status: "active"
    };
    const getAccountIds = (accounts) => {
        return accounts && accounts.length > 0 && accounts.map(a =>
        ({
            value: a.id,
            label: a.name
        })
        )
    }
    const user = getUser();
    if (isEmpty(state.adminOrSuperAdminReducer.company)) {
        // company = {}
        company = {
            active_status: 'active',
            mode: makeFirstLetterCapital(user.mode),
            company_tier: 0
        }
    } else {
        company = {
            name: state.adminOrSuperAdminReducer.company.name,
            company_number: state.adminOrSuperAdminReducer.company.company_number,
            company: state.adminOrSuperAdminReducer.company.company,
            account_ids: getAccountIds(state.adminOrSuperAdminReducer.company.accounts),
            mode: makeFirstLetterCapital(state.adminOrSuperAdminReducer.company.mode),
            active_status: state.adminOrSuperAdminReducer.company.active_status,
            company_tier: state.adminOrSuperAdminReducer.company.company_tier,
            os1:state.adminOrSuperAdminReducer.company?.os1 ? Number(state.adminOrSuperAdminReducer.company?.os1).toString() :"0"
        }
    }
    return { initialValues: { company } }
}

NewCompany = reduxForm({
    form: 'newCompanieForm',
    validate,
    enableReinitialize: true
})(NewCompany);

NewCompany = connect(mapStateToProps)(NewCompany)

export default NewCompany;