import React, { useState, useEffect } from 'react';
import moment from "moment";
import { convertDateFormatV1, convertLogbookDateTimezone, getHoursAndMinutesFormat_V2, makeFirstLetterCapital } from '../../../../../utility/utility';
import { Image } from 'antd';
import { tzDateConverterTool_V1, tzDateConverterTool_V4, tzDateConverterTool_V5, tzInbetweenDaysFinder_V1, tzTimeConversionTool_V1 } from '../../../../../utility/TimezoneOperations/timezoneUtility';
import PreviewLeaveRequestComponentSkeleton from '../../../TimeTrackerModuleComponent/LeavesTrackComponent/PreviewLeaveRequestComponent/PreviewLeaveRequestComponentSkeleton';
import isEmpty from '../../../../../utility/IsEmptyValidator';

let PreviewLogbookLogComponent = (props) => {

    const [leaveRequestData, setLeaveRequestData] = useState({});

    useEffect(() => {
        setLeaveRequestData(props.logbookLog);
    }, [props.logbookLog])

    if (isEmpty(props.logbookLog)) {
        return (
            <>
                <PreviewLeaveRequestComponentSkeleton />
            </>
        );
    } else {
        return (
            <div>

                <main className="app-content add_account_modal add_frontline_pop">
                    <div className="appcontent_Inner user_newQuote pt-0">

                        <div className="container py-5">

                            <div className="row text-center mb-4">
                                <div className="col-lg-7 mx-auto">
                                    <h1 className="mb-0" style={{ color: '#4a4a4a', fontWeight: '500', fontSize: '45px' }}> Logbook Log Details </h1>
                                </div>
                            </div>
                            <div className="addQuote_form">

                                <div className="row">
                                    <div className="col-lg-8 mx-auto">
                                        <ul className="list-group shadow rounded" style={{ marginLeft: '10%', marginRight: '10%' }}>

                                            <li className="list-group-item">
                                                <div className="media align-items-lg-center flex-column flex-lg-row p-3">
                                                    <div className="media-body order-2 order-lg-1">
                                                        <h5 className="mt-0 font-weight-bold mb-2 text-dark"> Logbook Name </h5>
                                                    </div>
                                                    <h5 className="ml-lg-5 order-1 order-lg-2 text-muted"> {leaveRequestData && leaveRequestData.logbook ? leaveRequestData.logbook.name : ''} </h5>
                                                </div>
                                            </li>

                                            <li className="list-group-item">
                                                <div className="media align-items-lg-center flex-column flex-lg-row p-3">
                                                    <div className="media-body order-2 order-lg-1">
                                                        <h5 className="mt-0 font-weight-bold mb-2 text-dark"> Current Status </h5>
                                                    </div>
                                                    <h5 className={leaveRequestData && leaveRequestData.status == 'pending' ? "ml-lg-5 order-1 order-lg-2 text-danger" : leaveRequestData.status == 'quality_reviewed' ? "ml-lg-5 order-1 order-lg-2 text-success" : "ml-lg-5 order-1 order-lg-2 text-warming"}>
                                                        {leaveRequestData ? leaveRequestData.status == 'quality_reviewed' ? 'Quality Reviewed' : makeFirstLetterCapital(leaveRequestData.status) : ''}
                                                    </h5>
                                                </div>
                                            </li>

                                            <li className="list-group-item">
                                                <div className="media align-items-lg-center flex-column flex-lg-row p-3">
                                                    <div className="media-body order-2 order-lg-1">
                                                        <h5 className="mt-0 font-weight-bold mb-2 text-dark"> Scheduled Date </h5>
                                                    </div>
                                                    <h5 className="ml-lg-5 order-1 order-lg-2 text-muted"> {leaveRequestData && leaveRequestData.start_time_utc ? convertLogbookDateTimezone(leaveRequestData.start_time_utc, leaveRequestData?.logbook?.account?.timezone) : ''} </h5>
                                                </div>
                                            </li>

                                            <li className="list-group-item">
                                                <div className="media align-items-lg-center flex-column flex-lg-row p-3">
                                                    <div className="media-body order-2 order-lg-1">
                                                        <h5 className="mt-0 font-weight-bold mb-2 text-dark"> Comments </h5>
                                                    </div>
                                                    <h5 className="ml-lg-5 order-1 order-lg-2 text-muted"> {leaveRequestData && leaveRequestData.comment ? leaveRequestData.comment : '-'} </h5>
                                                </div>
                                            </li>

                                            <li className="list-group-item">
                                                <div className="p-3">
                                                    <h5 className="mt-0 font-weight-bold mb-4 text-dark"> Status Log  </h5>
                                                    <div className="media align-items-lg-center flex-column flex-lg-row ">
                                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%' }}>
                                                            <div className="media-body width_50" style={{ display: 'flex', flexDirection: 'column' }}>
                                                                {leaveRequestData && leaveRequestData.comments?.length > 0 ? leaveRequestData.comments.map((feed, index, array) => {
                                                                    return (
                                                                        <>
                                                                            <div style={{ borderLeft: `5px solid ${feed.status === 'reviewed' ? 'yellow' : feed.status === 'pending' ? 'red' : feed.status === 'quality_reviewed' ? "green" : '#cfcfcf'}`, backgroundColor: '#f0f0f0' }}>
                                                                                <div className='row'>
                                                                                    <div className='col-md-5'>
                                                                                        <h6 className="ml-lg-3 order-1 order-lg-2 text-muted mt-2 mb-1"> {feed.status === 'reviewed' ? 'Reviewed by' : feed.status === 'pending' ? 'Quality Rejected by' : feed.status === 'quality_reviewed' ? "Quality Approved by" : ''} </h6>
                                                                                    </div>
                                                                                    <div className='col-md-2 mt-2'>
                                                                                        :
                                                                                    </div>
                                                                                    <div className='col-md-5 text-left'>
                                                                                        <h6 className="ml-lg-3 order-1 order-lg-2 text-muted mt-2 mb-1"> {`${feed.user.first_name} ${feed.user.last_name}`} </h6>
                                                                                    </div>
                                                                                </div>
                                                                                <div className='row'>
                                                                                    <div className='col-md-5'>
                                                                                        <h6 className="ml-lg-3 order-1 order-lg-2 text-muted mt-2 mb-1"> {feed.status === 'reviewed' ? 'Reviewed at' : feed.status === 'pending' ? 'Quality Rejected at' : feed.status === 'quality_reviewed' ? "Quality Approved at" : ''}  </h6>
                                                                                    </div>
                                                                                    <div className='col-md-2 mt-2'>
                                                                                        :
                                                                                    </div>
                                                                                    <div className='col-md-5'>
                                                                                        <h6 className="ml-lg-3 order-1 order-lg-2 text-muted mt-2 mb-1"> {feed.updated_at ? convertLogbookDateTimezone(feed.updated_at, leaveRequestData?.logbook?.account?.timezone) : '-'} </h6>
                                                                                    </div>
                                                                                </div>
                                                                                {feed.status === 'pending' ? (
                                                                                    <div className='row'>
                                                                                        <div className='col-md-5'>
                                                                                            <h6 className="ml-lg-3 order-1 order-lg-2 text-muted mt-2 mb-1"> Reason </h6>
                                                                                        </div>
                                                                                        <div className='col-md-2 mt-2'>
                                                                                            :
                                                                                        </div>
                                                                                        <div className='col-md-5'>
                                                                                            <h6 className="ml-lg-3 order-1 order-lg-2 text-muted mt-2 mb-1"> {feed.description ? feed.description : '-'} </h6>
                                                                                        </div>
                                                                                    </div>
                                                                                ) : null
                                                                                }
                                                                            </div>
                                                                            {index === array.length - 1 ? null : <br />}
                                                                        </>
                                                                    );

                                                                }) : (<h5 className="ml-lg-0 font-italic text-muted mb-0"> − </h5>)}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>

                                            {leaveRequestData && leaveRequestData.attachments && leaveRequestData.attachments.length > 0 && (
                                                <li className="list-group-item">
                                                    <div className="p-3">
                                                        <h5 className="mt-0 font-weight-bold mb-4 text-dark"> Attachments <img src="/images/icons/attachment_icon.png" alt="Icon" /> </h5>
                                                        <div className="addQuote_form mb-2 antdImgUpload" style={{ border: 'dashed', borderColor: '#71002f73', borderWidth: '3px' }}>
                                                            <div className='p-3' style={{ display: 'flex', flexWrap: 'wrap' }}>
                                                                {leaveRequestData && leaveRequestData.attachments && leaveRequestData.attachments.map((data, index) => {
                                                                    debugger
                                                                    return (
                                                                        <div key={index} className='m-1' style={{ border: 'solid', borderRadius: '2px', borderColor: '#aeaeae', borderWidth: '2px' }}>
                                                                            <Image width={100} src={data.photo_urls.medium} />
                                                                        </div>
                                                                    )
                                                                })}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            )}

                                            {leaveRequestData && leaveRequestData.initial_original_url && (
                                                <li className="list-group-item">
                                                    <div className="p-3">
                                                        <h5 className="mt-0 font-weight-bold mb-4 text-dark"> Signature </h5>
                                                        <div className="addQuote_form mb-2 antdImgUpload" style={{ border: 'dashed', borderColor: '#71002f73', borderWidth: '3px' }}>
                                                            <div className='p-3' style={{ display: 'flex', flexWrap: 'wrap' }}>
                                                                <div className='m-1' style={{ border: 'solid', borderRadius: '2px', borderColor: '#aeaeae', borderWidth: '2px' }}>
                                                                    <Image width={200} src={leaveRequestData.initial_original_url} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            )}

                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </main>
            </div>
        );
    }

};

export default PreviewLogbookLogComponent;