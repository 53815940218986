import moment from 'moment';
export function sort_TimeCard_Based_Upon_status_and_Time(aray){
    return aray.sort((a, b) => {
        const statusOrder = ['clockedOut', 'clockedIn', 'pending'];
        const statusA = statusOrder.indexOf(a.status);
        const statusB = statusOrder.indexOf(b.status);

        if (statusA !== statusB) {
            return statusA - statusB;
        }

        if (a.start_at && a.end_at && a.status == "clockedOut") {
            const date1 = moment(a.start_at);
            const date2 = moment(b.start_at);

            if (date1.isBefore(date2)) {
                return -1
            } else if (!date1.isBefore(date2)) {
                return 1
            } else return 0
        } 
        return 0
    })

    
}


export function sort_TimeCard_Based_Upon_status_and_Time_ReviewForm(aray){
    return aray.sort((a, b) => {

        if(b.shiftDetailJob==null) return -1 
        if(a.shiftDetailJob==null) return 1
        if(a.shiftDetailJob==null && b.shiftDetailJob==null) return 0
        const statusOrder = ['clockedOut', 'clockedIn', 'pending'];
        const statusA = statusOrder.indexOf(a.shiftDetailJob.status);
        const statusB = statusOrder.indexOf(b.shiftDetailJob.status);

        if (statusA !== statusB) {
            return statusA - statusB;
        }


        if (a.start_time && a.end_time && a.status == "clockedOut") {
            const date1 = moment(a.start_time);
            const date2 = moment(b.start_time);

            if (date1.isBefore(date2)) {
                return -1
            } else if (!date1.isBefore(date2)) {
                return 1
            } else return 0
        } 
        return 0
    })

    

}
