import React from 'react';
import { Input } from '../UI/InputElement/InputElement';
import { reduxForm } from 'redux-form';
import { NewHolidayValidator as validate } from '../../../utility/validator/validator';
import { connect } from 'react-redux';
import * as moment from 'moment-timezone';
const cloneDeep = require('clone-deep');

let NewHoliday = (props) => {

    const { handleSubmit, pristine, reset, submitting, error } = props;

    return (
        <div id="holidayModal" className="modal fade pg_common_modal holidayModal">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content add_area_modal">
                    <div className="modal-header">
                        <h2 className="modal-title">{props.isEdit ? "Update Holiday" : "Add a Holiday"}</h2>
                        <button onClick={props.closePopup} type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <form onSubmit={handleSubmit} className="modal-body add_content_block">
                        <div className="form_content">
                            <div className="form-group">
                                <label for="accountID" className="label_modifier">Holiday</label>
                                <Input type="text" className="form-control input-modifier" placeholder="" name="holiday[holiday_reason]" />
                                {/* <input type="text" className="form-control input-modifier" id="" placeholder="Type here" name="" value="Good Friday" /> */}
                            </div>
                            <div className="form-group" include="form-input-select()">
                                <label className="label_modifier">Date</label>
                                <Input name="holiday[holiday_date]" minDate={new Date()} dateFormat="MM-dd-yyyy" placeholder="" datePickerType={"react-datepicker"} type="date" className="form-control input-modifier" />
                            </div>
                            <div className="form-group select_filter_block">
                                <label className="label_modifier display-block">Is Recurring</label>
                                <div className="custom-control custom-radio custom-control-inline">
                                    <Input type="radio" labelClass="custom-control-label" forLable={"customRadio1"} value={"true"} label={"Yes"} className="custom-control-input" id="customRadio1" name="holiday[is_recurring]" />
                                    <Input type="radio" labelClass="custom-control-label" forLable={"customRadio2"} value={"false"} label={"No"} className="custom-control-input" id="customRadio2" name="holiday[is_recurring]" />
                                    {/* <input type="radio" className="custom-control-input input-modifier" id="yes" name="Include Tags" /> */}
                                    {/* <label className="custom-control-label" for="yes">Yes</label> */}
                                </div>
                                {/* <div className="custom-control custom-radio custom-control-inline">

                                    <input type="radio" className="custom-control-input input-modifier" id="no" name="Include Tags" />
                                    <label className="custom-control-label" for="no">No</label>
                                </div> */}
                            </div>
                        </div>

                        <div className="modal-footer">
                            <button type="submit" disabled={props.isHolidaysLoading} className={props.isHolidaysLoading ? "btn btn_danger cst_btn btn-wait mr-2" : "btn btn_danger cst_btn mr-2"}>{props.isHolidaysLoading ? "" : "Add"}</button>
                            <button data-dismiss="modal" aria-label="Close" onClick={props.closePopup} type="button" className="btn btn_outline_secondary cst_btn">Cancel</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}


const mapStateToProps = (state) => {
    let data = cloneDeep(state.adminOrSuperAdminReducer.holiday);
    if (state.adminOrSuperAdminReducer.isEdit) {
        const date = moment(data.holiday_date);
        data.holiday_date = date._d;
        data.is_recurring = data.is_recurring ? "true" : "false";
    } else {
        data.is_recurring = "true"
    }
    let holiday = {
        holiday: data
    }
    return { initialValues: holiday }
}

NewHoliday = reduxForm({
    form: 'newHoliday',
    validate
})(NewHoliday);

NewHoliday = connect(mapStateToProps)(NewHoliday)

export default NewHoliday;