import React, { useState } from 'react';
import { Input } from '../../../UI/InputElement/InputElement';
import { SelectField } from '../../../UI/SelectField/SelectField';
import { DateTimeField } from '../../../UI/DateTimeField/DateTimeField';
//import GooglePlacesAutoComplete from '../../../GooglePlacesAutoComplete/googlePlacesAutoComplete';
// import { newAreaValidator as validate } from '../../../../../utility/validator/newValidator';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
//import AccountSkeleton from './accountSkeleton';
import isEmpty from '../../../../../utility/IsEmptyValidator';
import { FLOOR_NO, DAYS } from "../../../../../utility/constants/constants";
import TimeAttributesField from "./TimeAttributesField";
import { makeFirstLetterCapital } from '../../../../../utility/utility';
const moment = require('moment-timezone');


let AddAreaForm = (props) => { 
  const { handleSubmit, pristine, reset, submitting, error, change, resetDayError } = props;
  const initialTime = { hours: "00", minutes: "00", timeDivision: "am" }
  const [timeSlotes, setTimeSlot] = useState(["12:00 AM"]);
  const removeTimeSlot = (index) => {
    let times = [...timeSlotes];
    times.splice(index, 1);
    setTimeSlot(times);
    if (times.length) {
      let timeslots_attributes = props.areaForm.newAreaForm.values.area.timeslots_attributes;
      timeslots_attributes.splice(index, 1);
      change("area[timeslots_attributes]", timeslots_attributes);
    } else {
      change("area[timeslots_attributes]", [{ start_time: "" }]);
    }
  };


  const getDeficiencyOptions = props.deficiencies && props.deficiencies.reduce((acc,curr,index)=>{
      if(!(curr.id == 1 && curr.title === "others")) {
          acc.push(curr);
      }
      return acc;
  },[]).map(c => {
      return {
          value: c.id,
          label: makeFirstLetterCapital(c.title)
      }
  })

  const addTimeSlot = () => {
    let times = [...timeSlotes];
    //let timeslots_attributes = props.areaForm.AddAreaForm.values.area.timeslots_attributes;
    times.push({
      "start_time": "12:00 AM"
    });
    setTimeSlot(times);
    //props.change("area[timeslots_attributes]",timeslots_attributes)
  };

  const Holidays = props.holidaysList ? props.holidaysList.map(h => {
    return {
      value: h.id,
      label: h.holiday_reason
    }
  }) : [];

  const noOptionsHolidayMessage = () => {
    return 'Holiday not found';
  }
  const getFormattedZone = (timezone) => {
    let zone = ''
    const now = moment.tz(timezone);
    zone = now.format('z');
    return zone;
  }

  return (
    <main className="app-content add_area_modal">
      <div className="appcontent_Inner">
        <div className="add_info_block">
          <h2 className="h2_title" data-test="addArea_head_title">Add an Area</h2>
          <div className="add_content_block">
            <form className="form_content" onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="accountID" className="label_modifier">Job ID</label>
                <Input type="text" readOnly={true} className="form-control Input-modifier" id="accountID" placeholder="Type here" name="area[account_id]" />
              </div>
              <div className="form-group">
                <label htmlFor="areaID" className="label_modifier">Area ID</label>
                <Input type="text" readOnly={true} className=" form-control Input-modifier" id="areaID" placeholder="Type Here" name="area[area_id]" />
              </div>
              <div className="form-group">
                <label htmlFor="areaName" className="label_modifier">Area Name</label>
                <Input dataTest="area_name_input" type="text" className="form-control Input-modifier" id="areaName" placeholder="Type here" name="area[name]" />
              </div>
              <div className="form-group" include="form-Input-select()">
                <label className="label_modifier">Floor No.</label>
                <SelectField
                  dataTest="floor_number"
                  className="custom-select Input-modifier"
                  options={props.floorList}
                  name="area[floor_id]">
                </SelectField>
              </div>
              <div className="form-group" include="form-Input-select()">
                <label className="label_modifier">Area Type</label>
                <SelectField
                  dataTest="area_type"
                  className="custom-select Input-modifier"
                  options={props.areaTypes}
                  name="area[area_type_id]">

                </SelectField>
             </div>
              
             <div className="form-group" include="form-Input-select()">
                <label className="label_modifier">Deficiencies</label>
                <Input isMulti={true} name="area[deficiency_ids]" type="inputSelect" options={getDeficiencyOptions}/>
              </div>
              <div className="form-group">
                <label className="label_modifier">Select Time ({props.account.user ? getFormattedZone(props.account.user.timezone) : ''})</label>
                <button type="button" className="btn btn_danger cst_btn" onClick={() => [addTimeSlot(), setTimeSlot([...timeSlotes, { ...initialTime }])]}>Add</button>
                <div className="w-100" style={{ color: "rgb(221, 39, 38)", fontSize: "13px" }}>{props.areaErrorMessage}</div>
                {
                  timeSlotes.map((time, index) => (
                    <div className="grid_view_block row" key={index}>
                      <div className="col-10">
                        <DateTimeField  timeConstraints={{ minutes: { step: 5 } }} datePickerType='react-datetime' pickerType="timePicker" dataFormat="hh:mm A" className="form-control Input-modifier" id="areaName" name={`area[timeslots_attributes][${index}][start_time]`} />
                      </div>
                      <div className="col-2">
                        <i className="far fa-trash-alt mt-2" onClick={e => removeTimeSlot(index)}></i>
                      </div>

                    </div>
                  ))
                }
              </div>
              {/* <div className="form-group" include="form-Input-select()">
                <label className="label_modifier">Holidays</label>
                <Input name="holiday_ids" isMulti={true} noOptionsMessage={noOptionsHolidayMessage} type="inputSelect" options={Holidays} />

              </div> */}

              {/* <div className="form-group mt-4">
                <label className="label_modifier">Holiday</label>
                <div className="checkbox_block radius_checkbox">
                  <div className="custom-control custom-checkbox custom-control-inline">
                    <input onChange={props.selectAllClicked} checked={props.selectNone ? false : props.selectAll} type="checkbox" className="custom-control-input" id="selectAll" />
                    <label className="custom-control-label" for="selectAll">Select All</label>
                  </div>
                  <div className="custom-control custom-checkbox custom-control-inline">
                    <input onChange={props.selectNoneClicked} checked={props.selectAll ? false : props.selectNone} type="checkbox" className="custom-control-input" id="selectNone" />
                    <label className="custom-control-label" for="selectNone">Select None</label>
                  </div>
                </div>
              </div>
              <div className="form-group">
                {props.holidaysList && props.holidaysList.map(h => {
                  return (
                    <div className="checkbox_block radius_checkbox">
                      <div className="custom-control custom-checkbox mb-2">
                        <input disabled={props.selectNone || props.selectAll} onChange={() => props.holidaySelected(h.id)} checked={props.setCheckValue(h.id)} type="checkbox" className="custom-control-input" id={`holiday${h.id}`} />
                        <label className="custom-control-label" for={`holiday${h.id}`}>{h.holiday_reason}</label>
                      </div>
                    </div>)
                })}
              </div> */}
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <div className="checkbox_block radius_checkbox">
                      {
                        DAYS.map((day, index) => <div className="w-25 mr-0 custom-control custom-checkbox custom-control-inline" onClick={() => props.resetDayError()}>
                          <Input type="checkbox" name={`area[days][${index}]`} className="custom-control-input" id={`customCheck${index}`} />
                          <label data-test="check_box_input" className="custom-control-label" htmlFor={`customCheck${index}`}>{day}</label>
                        </div>)
                      }
                    </div>
                  </div>
                  <div className="w-100" style={{ color: "rgb(221, 39, 38)", fontSize: "13px" }}>{props.areaDayErrorMessage}</div>
                  <div className="input-group cst_input_group">
                    <label className="form_title ">Start Date</label>
                    <div className="input-group cst_input_group">
                      <Input name="area[schedule_at]"
                        minDate={new Date()}
                        preventReadOnly={true}
                        dataTest="calendar_date_picker"
                        dateFormat="MM-dd-yyyy" id="from_date" placeholder="Start Date" datePickerType={"react-datepicker"} type="date" className="form-control from-date-analytics" />
                      {/* <input type="text" className="form-control" placeholder="01.03.2020" aria-label="Recipient's username" aria-describedby="basic-addon2" /> */}
                      <div className="input-group-append cursor-pointer" style={{ float: "right", position: "absolute", right: 0, height: "36px", top: 0 }} onClick={e => document.querySelector(".from-date-analytics").click()}>
                        <span className="input-group-text" id="basic-addon2"><i className="fa fa-calendar" alt="Calendar Icon" ></i></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button data-test="submit_button" type="submit" disabled={props.isLoading} className={props.isLoading ? "btn cst_btn btn_danger mr-2 btn-wait" : "btn btn_danger cst_btn"}>Generate QR Code</button>
                <button type="button" className="btn btn_outline_secondary cst_btn" onClick={() => props.history.goBack()}>Cancel</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </main>

  )
};

const mapStateToProps = (state, props) => {
  let last_ref;
  if (props.account.user) {
    last_ref = `${props.account.user.name.slice(0, 2)}-${props.account.user.id}-${props.account.user.last_ref + 1}`;
  }
  let form = { name: "",
    "floor_id": null,
    "area_type_id": null,
    schedule_at: "",
    "timeslots_attributes": [
      {
        "start_time": "12:00 AM"
      }
    ]
  };
  if (state.form.newAreaForm && state.form.newAreaForm.values) {
    form = state.form.newAreaForm.values.area;
  }
  return {
    initialValues:
    {
      holiday_ids: [],
      area:
      {
       ...form,
       "account_id": props.account && props.account.user && props.account.user.account_number,
        "area_id": last_ref,
      }
    },
    areaForm: state.form
  }
};


AddAreaForm = reduxForm({
  form: 'newAreaForm',
  // validate,
  enableReinitialize: true,
})(AddAreaForm);

AddAreaForm = connect(mapStateToProps)(AddAreaForm);

export default AddAreaForm;
