import React from 'react';
import { CHIReviews } from '../../../utility/constants/constants';
import storage from '../../../utility/storage';

const CHIReviewStep1 = (props) => {
    const token = storage.get('token');

    const allReviews = [
        { class: 'link_zero', value: 1 }, { class: 'link_one', value: 2 }, { class: 'link_two', value: 3 },
        { class: 'link_three', value: 4 }, { class: 'link_four', value: 5 }, { class: 'link_five', value: 6 },
        { class: 'link_six', value: 7 }, { class: 'link_seven', value: 8 }, { class: 'link_eight', value: 9 },
        { class: 'link_nine', value: 10 }];

    return (
        <body class="dashboard_page_body">
            <main className="report_wrapper web_view">
                <section className="chi_content_block appcontent_Inner">
                    {/* <!-- web modals --> */}
                   {token ?  <div className="web_modal" style={{marginLeft: props.chisurveyStarted && props.chisurveyStarted == true ? '0px' : '25%', marginTop: '40px'}}>
                        <div className="web-modal-content">
                            {/* <h4 className="modal_h4">Step 1 of 3</h4> */}
                            <div className="web_inn_container">
                                <figure className="web_logo">
                                    <img src="/images/icons/web_logo.png" alt="Pegasus" />
                                </figure>
                                <div className="web_inn_con">
                                <h3 className="web_h3">Thank you for participating in this brief survey about your experience with Pegasus.  This 
                                    survey consists of 2 questions and should only take a few minutes to complete.  Your 
                                    opinions are important to us.  Thank you for taking the time to offer your feedback</h3>
                                    <h3 className="web_h3">Overall, how satisfied have you been with Pegasus’ services.</h3>                                    <nav aria-label="Page navigation example" className="web_pagination_block">
                                        <ul className="pagination web_pagination">
                                            {allReviews.map((r, i) => {
                                                return (
                                                    <li onClick={() => props.setActiveReview(r.value)}
                                                        className={`page-item ${props.activeReview === r.value ? 'active' : ''}`}>
                                                        <a className={`page-link ${r.class}`} href="#">{r.value}</a></li>
                                                )
                                            })}
                                        </ul>
                                    </nav>
                                    {/* <div className="page_not_likely">
                                        <h4 className="web_h4 link_previous">0 - Not likely</h4>
                                        <h4 className="web_h4 link_next">10 - Not likely</h4>
                                    </div> */}


                                <div className="web_inn_con">
                                    <h3 className="web_h3 text-center" style={{display: 'contents'}}>Use this space to provide additional feedback or general comments about your experience with Pegasus (optional) </h3>
                                    <div className="post_comment">
                                        <div className="form-group">
                                            {/* <label className="label_modifier">Post your comments</label> */}
                                            <textarea onChange={props.changeReviewText} value={props.feed_back} className="form-control textarea_modifier" id="exampleFormControlTextarea1" rows="8" placeholder="Type here" />
                                        </div>
                                        <div className="web_btn_block">
                                            <button disabled={!props.activeReview || props.activeReview === 0} onClick={() => props.submitCHIReview(CHIReviews.chiReview2)} type="button"
                                                className={props.chi_review_loading ? "btn btn_danger_dark cst_btn btn_load btn-wait" : "btn btn_danger_dark cst_btn"}>{props.chi_review_loading ? "" : "Submit"}</button>
                                        </div>
                                    </div>

                                </div>
                                    {/* <div className="web_btn_block">
                                        <button type="button" disabled={!props.activeReview || props.activeReview === 0} onClick={() => props.nextClicked(CHIReviews.chiReview1)} className="btn btn_danger_dark cst_btn">Next</button>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div> :  <div className="web_modal">
                        <div className="web-modal-content" style={{marginTop: '40px'}}>
                            {/* <h4 className="modal_h4">Step 1 of 3</h4> */}
                            <div className="web_inn_container">
                                <figure className="web_logo">
                                    <img src="/images/icons/web_logo.png" alt="Pegasus" />
                                </figure>
                                <div className="web_inn_con">
                                    <h3 className="web_h3">Thank you for participating in this brief survey about your experience with Pegasus.  This 
                                    survey consists of 2 questions and should only take a few minutes to complete.  Your 
                                    opinions are important to us.  Thank you for taking the time to offer your feedback</h3>
                                    <h3 className="web_h3">Overall, how satisfied have you been with Pegasus’ services.</h3>
                                    <nav aria-label="Page navigation example" className="web_pagination_block">
                                        <ul className="pagination web_pagination">
                                            {allReviews.map((r, i) => {
                                                return (
                                                    <li onClick={() => props.setActiveReview(r.value)}
                                                        className={`page-item ${props.activeReview === r.value ? 'active' : ''}`}>
                                                        <a className={`page-link ${r.class}`} href="javascript:void(0)">{r.value}</a></li>
                                                )
                                            })}
                                        </ul>
                                    </nav>
                                    {/* <div className="page_not_likely">
                                        <h4 className="web_h4 link_previous">0 - Not likely</h4>
                                        <h4 className="web_h4 link_next">10 - Not likely</h4>
                                    </div> */}


                                <div className="web_inn_con">
                                    <h3 className="web_h3 text-center" style={{display: 'contents'}}>Use this space to provide additional feedback or general comments about your experience with Pegasus (optional) </h3>
                                    <div className="post_comment">
                                        <div className="form-group">
                                            {/* <label className="label_modifier">Post your comments</label> */}
                                            <textarea onChange={props.changeReviewText} value={props.feed_back} className="form-control textarea_modifier" id="exampleFormControlTextarea1" rows="8" placeholder="Type here" />
                                        </div>
                                        <div className="web_btn_block">
                                            <button disabled={!props.activeReview || props.activeReview === 0} onClick={() => props.submitCHIReview(CHIReviews.chiReview2)} type="button"
                                                className={props.chi_review_loading ? "btn btn_danger_dark cst_btn btn_load btn-wait" : "btn btn_danger_dark cst_btn"}>{props.chi_review_loading ? "" : "Submit"}</button>
                                        </div>
                                    </div>

                                </div>
                                    {/* <div className="web_btn_block">
                                        <button type="button" disabled={!props.activeReview || props.activeReview === 0} onClick={() => props.nextClicked(CHIReviews.chiReview1)} className="btn btn_danger_dark cst_btn">Next</button>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>}
                </section>
            </main>
        </body>
    )
}

export default CHIReviewStep1;