import React, { Component } from "react";
import moment from "moment";
import { connect } from "react-redux";
import * as actions from "../../../redux/actions/index";
import errorHandeler from '../../../utility/errorHandler/errorHandler';
import * as $ from "jquery";
import Schedule from "../../component/Schedule";
//import Geocode from "react-geocode";
//
//Geocode.setApiKey("AIzaSyB6hJZMqg0KA0B6OcO9fJm0nwKUmRjeEYI");
// set response region. Its optional.
// A Geocoding request with region=es (Spain) will return the Spanish city.
//Geocode.setRegion("es");
class SearchHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      result: "No result",
      loading: false,
      lat: "",
      lng: "",
      currentAccount: null,
      accounts: [],
      default_account: null,
      formatted_address: "",
      search: {
        page: 1,
        size: 15,
      }
    };
    this.handlePageChange = this.handlePageChange.bind(this)
    this.onAccountChange = this.onAccountChange.bind(this)
    this.submitSchedule = this.submitSchedule.bind(this)
  }
  componentWillUnmount() {
    $('.login100-more').removeClass("d-none");
    $('body').removeClass("overflow-hidden");
  }

  success = (position) => {
    let address = {
      lat: position.coords.latitude,
      lng: position.coords.longitude,
    };
    this.setState({...address});
    //Geocode.fromLatLng(position.coords.latitude, position.coords.longitude).then(
    //        response => {
    //      const res_address ={...address, formatted_address: response.results[0].formatted_address};
    //      this.setState({...res_address})
    //    },
    //        error => {
    //      console.error(error);
    //    }
    //);
  }

  fail = (error) => {

    errorHandeler("Could not obtain location");
    //closeModel();
    //this.setState({
    //  openPerformInspectionModal: false
    //})
  }

  newPerformInspectionModalClicked = () => {
    if (navigator.geolocation) {
      // Call getCurrentPosition with success and failure callbacks

      navigator.geolocation.getCurrentPosition(this.success, this.fail);
    } else {
      errorHandeler("Please enable your location access.")
    }
  }
  componentDidMount = () => {
    //const {page, size} = this.state.search;
    $('.login100-more').addClass("d-none");
    $('body').addClass("overflow-hidden");
    this.props.getFrontline(this.props.user.id).then(res => {
      if (res.action && res.action.payload) {
        let {accounts, default_account} = res.action.payload.data.user;
        let account_id = default_account ? default_account.id : (accounts && accounts[0] ? accounts[0].id : "");
        if (account_id) {
          this.setState({currentAccount: account_id, accounts, default_account});
          this.props.getAllAreaOfAccount({page: 1, size: 10, account_id});
          this.newPerformInspectionModalClicked();
          this.timer = setInterval(()=> this.getTheData(), 110000); // here...
        } else {
          errorHandeler("No Job Associated. please contact administrator");
          setTimeout(() => this.props.history.push("scan_qr"), 1000);
        }
      }
    })
  };
  getTheData () {
    this.props.getAllAreaOfAccount({page: 1, size: 10, account_id: this.state.currentAccount});
  }

  handleOnChangeSearch (e) {
    this.setState({searched: false});
    let search = this.state.search;
    search[e.target.name] = e.target.value ? e.target.value : null;
    this.setState({search})
  }

  //handleSubmitSearch (e) {
  //  e.preventDefault();
  //  const {area_name, floor_id, area_type_id, size, page} = this.state.search;
  //  this.getHistory(
  //      {
  //        page,
  //        size,
  //        area_name, floor_id, area_type_id
  //      }
  //  );
  //}
  //
  //
  //handleSubmitNewSearch (e) {
  //  if (this.state.search.area_name && this.state.search.area_type_id && this.state.search.floor_id) {
  //    const {area_name, floor_id, area_type_id, size} = this.state.search;
  //    this.props.getSearchHistory(
  //        {
  //          page: 1,
  //          size,
  //          area_name, floor_id, area_type_id
  //        },
  //        this.props.match.params.accountId).
  //        then(res => {
  //          this.props.history.push(`/account/${this.props.match.params.accountId}/scan-result-history`)
  //        });
  //  }
  //}
  onAccountChange (e) {
    this.setState({currentAccount: e.value});
    this.props.getAllAreaOfAccount({page:1, size: 10, account_id: e.value});
  }

  submitSchedule (form, schedule) {
    form.address_attributes = {latitude: this.state.lat, longitude: this.state.lng};
    //let date = new Date();
    //date = date.toUTCString();
    //form.captured_time = moment().format("YYYY-MM-DDTHH:mm:ss");
    //form.captured_time = moment(moment().format("MM-DD-YYYY")+" "+schedule.start_time, "MM-DD-YYYY HH:mm:ss").format("MM-DD-YYYY HH:mm:ss")
    this.props.scheduleStatus({frontline_task: form}, schedule.id).then(res => {
      let account = this.state.currentAccount ? this.state.currentAccount : this.props.user.accounts[0].id
      this.props.getAllAreaOfAccount({page:1, size: 10, account_id: account});
    });
  }

  handlePageChange(pageNumber) {
    console.log(`active page is ${pageNumber}`);
    let search = {...this.state.search};
  search.page = pageNumber;
      this.setState({search});
      this.props.getAllAreaOfAccount({...search, account_id: this.state.currentAccount});
}

render() {
  return (
      <>
      <Schedule
              {...this.props}
              user={{...this.props.user, accounts: this.state.accounts, default_account: this.state.default_account}}
              submitSchedule={this.submitSchedule}
              handlePageChange={this.handlePageChange}
              search={this.state.search}
              searched={this.state.searched}
              total_no_of_pages={this.props.total_no_of_pages}
              onAccountChange={this.onAccountChange}
              lat={this.state.lat}
              lng={this.state.lng}
              formatted_address={this.state.formatted_address}
              />

      </>
  );
}
}

const mapStateToProps = (state) => {
  return {
    user: state.authReducer.user,
    isLoading: state.adminOrSuperAdminReducer.isLoading,
    total_no_of_pages: state.adminOrSuperAdminReducer.total_no_of_pages,
    frontlineUser: state.adminOrSuperAdminReducer.frontlineUser,
    //floorList: state.adminOrSuperAdminReducer.floorList,
    //areaNames: state.adminOrSuperAdminReducer.areaNames,
    //isAccountAreas: state.adminOrSuperAdminReducer.isAccountAreas,
    //areaSearchResult: state.adminOrSuperAdminReducer.areaSearchResult,
    areaOfAccount: state.adminOrSuperAdminReducer.areaOfAccount
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllAreaOfAccount: (params, id) => dispatch(actions.getAllAreaOfAccount(params, id)),
    sideBarClicked: () => dispatch(actions.sideBarClicked()),
    scheduleStatus: (body, id) => dispatch(actions.scheduleStatus(body, id)),
    getFrontline: (id) => dispatch(actions.getFrontline(id)),
    //getAllHistory: (params, id) => dispatch(actions.getAllHistory(params, id)),
    //getSearchHistory: (params, id) =>
    //    dispatch(actions.searchHistory(params, id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchHistory);
//export default SearchHistory;
