import React, { useEffect, useState } from 'react'
import { Input } from '../../../UI/InputElement/InputElement';
import { fetchRateListDropDown } from '../../../../../API/TimeTrackerModApi';
import { change } from 'redux-form'
import { useDispatch, useSelector } from 'react-redux';
import { userRoles } from '../../../../../utility/constants/constants';
const noAccountOptionsMessage = (a, b) => {
    return 'Hours code / type not found';
}


export default function HoursTypeComponent(props) {

    const [options, setOption] = useState([])
    const dispatch = useDispatch()  
    const {user}=useSelector((state)=> state.authReducer)
    const isEditAccess=Boolean(user?.role == userRoles.SUPER_ADMIN || user?.role == userRoles.ADMIN)
    
    useEffect(() => {
        if (props?.job && props?.formStates && props?.formStates?.shiftDetailJobs && !props?.formStates?.shiftDetailJobs?.[props.index]?.rate?.id) {
            //take accountId 

            const acc_id = props.job.account_id
            const params = {
                accountId: acc_id
            }
            helperAsync(params).then((res) => {
                if (res.length) setOption(convertToOptions(res, props.job))
            })

        }
    }, [props])


    useEffect(()=>{
        if(props.formStates?.shiftDetailJobs?.length){
            dispatch(change('newShiftDetailForm', 'validate', "started"));
        }
    },[props.formStates])

    const helperAsync = async (params) => {
        const res = await fetchRateListDropDown(params)
        return res.data.data

    }


    function convertToOptions(data, job) {
        const foundedId = data.find((item) => {
            if (item?.description == job?.rate?.description) {
                return true
            }
        })?.account_rate_id

        if (foundedId) {
            const newStateArray = props.formStates.shiftDetailJobs.map((item, index) => {
                if (props.index == index) {
                    const rate = { ...item.rate, id: foundedId };
                    return { ...item, rate }
                }else{
                    return item;
                }
            })
            console.log("newstatearray",newStateArray);
            // const newState={shiftDetailJobs:newStateArray}
            dispatch(change('newShiftDetailForm', 'shiftDetailJobs', newStateArray));
        }
        
        return data.map((item) => ({
            value: item?.description,
            label: `${item.type_id} - ${item?.description}`,
            id: item?.account_rate_id
        }))
    }

    return (
        <>

            <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between',}}>
                <div style={{ flex: 1.2 }}>
                    <h5>Allocated Job</h5>
                    <h5 className="text-muted mt-2 mb-1" style={{ fontSize: '15px' }}>{props.job && props.job?.account?.name}</h5>
                </div>
                {
                    props?.trade && <div style={{ flex: 1.1 }}>
                    <h5>Address</h5>
                    <h5 className="font-italic text-muted mb-2" style={{ fontSize: '15px' }}>
                        {props.trade?.formatted_address == null ?
                            `${props?.trade?.city !== null ? props?.trade?.city + ', ' : ''}
                                                                                            ${props?.trade.state !== null ? props?.trade.state + ', ' : ''}
                                                                                            ${props?.trade.country !== null ? props?.trade.country + ', ' : ''}
                                                                                            ${props?.trade.zip !== null ? props?.trade.zip : ''}` : props?.trade.formatted_address
                        }
                    </h5>
                </div>
                }
                
                <div style={{ flex: 1.5 }}>
                    <h5>Hours Code / Hours type  </h5>
                    <div className="form-group">
                        <Input  readOnly={!isEditAccess} instanceId="job_select" name={`shiftDetailJobs.[${props.index}].rate`} noOptionsMessage={noAccountOptionsMessage} type="inputSelect" options={options} />
                    </div>
                </div>



                <div className='customDropdownTextColor' style={{ flex: 2 }}>
                    <h5 style={{ marginLeft: '25px' }}>Pay</h5>
                    <div className="form-group" >
                        <div className="custom-control custom-radio custom-control-inline" style={{width:"100%"}}>
                            <Input disabled={!isEditAccess} type="radio" radioType="radioWithErrorMessage" labelClass="custom-control-label" forLable={`customRadio1${props.index}`} value={"true"} label={"Customer Pay"} className="custom-control-input" id={`customRadio1${props.index}`} name={`shiftDetailJobs.[${props.index}].customer_pay`}  />
                            <Input disabled={!isEditAccess} type="radio" radioType="radioWithErrorMessage" labelClass="custom-control-label" forLable={`customRadio2${props.index}`} value={"false"} label={"Pegasus Pay"} className="custom-control-input" id={`customRadio2${props.index}`} name={`shiftDetailJobs.[${props.index}].customer_pay`} />
                        </div>
                    </div>
                </div>
            </div>

            {props?.index === props?.array.length - 1 ? null : <br />}
        </>
    )
}
