import React, { useEffect, useState } from 'react'
import { Input } from '../../../../UI/InputElement/reactFormHookInputField'
import { useSelector } from 'react-redux'
import { makeFirstLetterCapital } from '../../../../../../utility/utility';

function NewDailyKittingSection(props) {
    const { inventoryList } = useSelector((state) => state.InventoryModuleReducer)

    const getInventoryList = (inventory) => {
        if (inventory && inventory.length > 0) {
            return inventory && inventory.map((data) => {
                return {
                    value: data.id,
                    label: makeFirstLetterCapital(data.name),
                }
            })
        } else {
            return []
        }
    };

    const [listofInventory,setListOfInventory]=useState(getInventoryList(inventoryList))

    useEffect(()=>{
      setListOfInventory(getInventoryList(inventoryList))
    },[inventoryList])
    return (
        <>
            <div class="tm_form_wrap">
                <div class="tm_form_innner">
                    <div class="form">
                        <div class="row">
                            <div class="col-md-4 col-sm-12">
                                <div class="form-group">
                                    {/* <label for="disabledSelect" class="form-label"
                                    >Specialist</label>
                                    <select id="disabledSelect" class="form-select">
                                        <option>Please Select</option>
                                        <option>1</option>
                                        <option>2</option>
                                        <option>3</option>
                                        <option>4</option>
                                    </select> */}

                                    <Input
                                        type="inputSelect"
                                        labelClasses="form-label"
                                        control={props.control}
                                        controller={props.controller}
                                        selectLabel="Specialist"
                                        placeholder={"Please select"} 

                                        selectName={`sections.[${props.index}].specialist`}
                                        id={`sections.[${props.index}].specialist`}
                                        selectOptions={[{ value: 'Collections Specialist', label: 'Collections Specialist' }, { value: 'Vacuum Specialist', label: 'Vacuum Specialist' }, { value: 'Restroom Specialist', label: 'Restroom Specialist' }, { value: 'Utility Specialist', label: 'Utility Specialist' }]}
                                        required={props.register(`sections[${props.index}].specialist`, {
                                            required: "This field is required"
                                        })}
                                        validationErrorMessage={props.errors?.sections?.[props?.index]?.specialist?.message}
                                        selectClasses="form-select"
                                        disabled={props?.isView}
                                    />
                                    {/* <div class="dropdown_caret">
                                        <img
                                            src="images/icons/dropdown_caret.svg"
                                            alt="dropdown_caret"
                                        />
                                    </div> */}
                                </div>
                            </div>
                            <div class="col-md-4 col-sm-12">
                                <div class="form-group">
                                    <Input
                                        type="inputSelect"
                                        labelClasses="form-label"
                                        control={props.control}
                                        controller={props.controller}
                                        selectLabel="Item"
                                        placeholder={"Please select"}
                                        selectName={`sections.[${props.index}].item`}
                                        id={`sections.[${props.index}].item`}
                                        selectOptions={listofInventory}
                                        required={props.register(`sections[${props.index}].item`, {
                                            required: "This field is required"
                                        })}
                                        validationErrorMessage={props.errors?.sections?.[props?.index]?.item?.message}
                                        selectClasses="form-select"
                                        disabled={props?.isView}
                                    />

                                </div>
                            </div>
                            <div class="col-md-4 col-sm-12">
                                <div class="form-group mb-0">
                                    {/* <label for="jobname" class="form-label">Count</label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        id="jobname"
                                        aria-describedby=""
                                        placeholder="Type here"
                                    /> */}

                                    <Input
                                        type="number"
                                        id={`sections.[${props.index}].count`}
                                        name={`sections.[${props.index}].count`}
                                        formControlClasses="form-control"
                                        placeholder="Quantity"
                                        required
                                        label="Quantity"
                                        formControlProps={props.register(`sections.[${props.index}].count`, {
                                            required: "This field is required",
                                            // pattern: {
                                            //   value:/^[0-9]*$/ ,
                                            //   message:"Must Be a number"
                                            // },
                                            maxLength: 80,
                                        })}
                                        validationErrorMessage={
                                            props.errors?.sections?.[props?.index]?.count?.message ?? ""
                                        }
                                        disabled={props?.isView}
                                    />

                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </div>
            {
                props?.index > 0 ?
                    <div class="col-md-12 col-sm-12" >
                        <div class="action_button pt-3">
                            <span onClick={props.removeFromTaskList(props.index)}>
                                <img src='/images/icons/trash.svg' />
                            </span>
                        </div>
                    </div> : null
            }
        </>
    )
}

export default NewDailyKittingSection
