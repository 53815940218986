import React, { useEffect, useState } from 'react';

import { Calendar, momentLocalizer } from 'react-big-calendar';
import "react-big-calendar/lib/css/react-big-calendar.css";
import ReactTooltip from 'react-tooltip';

import storage from '../../../../../utility/storage';
import { TimeZones, userRoles } from '../../../../../utility/constants/constants';
import {
    getInbetweenWeekDates,
    getMonthNo,
    getDayBinaryNo,
    getUser,
    convertDateFormatForSchedule,
    startOfWeek,
    endOfWeek,
    makeFirstLetterCapital,
    convertDateFormatV4,
    identifyDayNameOfWeek,
    convertTo12HourFormat,
    getInbetweenWeekDates_V2
} from '../../../../../utility/utility';

import CalendarDateSearch from '../../../../../utility/CalendarUtilities/CalendarDateSearch/CalendarDateSearch';
import moment from 'moment';
import { customTime, toFindPDTAvailability, tzCalendarDateTimeConverterTool, tzDateConverterTool_V5, tzEndOfWeek, tzStartOfWeek, tzTimezoneMarking, tzUTCDateConverterTool_V2, tzUTCDateConverterTool_V3, tzUTCTimeToActualTime } from '../../../../../utility/TimezoneOperations/timezoneUtility';
const localizer = momentLocalizer(moment)
localizer.segmentOffset = 0
var momentTZ = require('moment-timezone');

let AvailabilityCalendarComponent = (props) => {

    let currentUserInfo = getUser();
    let [viewOverDate, setViewOverDate] = useState();

    useEffect(() => {
        let ele = document.getElementsByClassName('rbc-btn-group');
        if (ele) {
            ele[0].childNodes[1].textContent = "Previous"
        }
    }, [props])

    /* For Converting the Date and Time from fetched response to showcase Shift Availability in calendar */
    const handleAvailabilityFixtures = (availabilities, overrides, leaves) => {

        const combinedDateStorage = availabilities.map(availability => {

            /* Removing Seconds from Start time and End time of Availability */
            // let removeSecondsFromStartTime = tzUTCTimeToActualTime(availability.start_time).split(':');
            // let removeSecondsFromEndTime = tzUTCTimeToActualTime(availability.end_time).split(':');
            // removeSecondsFromStartTime.pop();
            // removeSecondsFromEndTime.pop();
            // let availableStartTime = removeSecondsFromStartTime.join(':');
            // let availableEndTime = removeSecondsFromEndTime.join(':');

            let [isOverrideOverLaps, isFulldayLeaveOverLaps, isPartialDayLeaveOverLaps] = Array(3).fill(false); //For triggering the availability overlap scenario
            const fullDayLeaveOverLaps = leaves && leaves.filter((leave) => leave.day_coverage == 'full');
            // const partialDayLeavesOverLaps = leaves && leaves.filter((leave) => leave.day_coverage == 'partial');
            let startCycleOfAvailability = tzDateConverterTool_V5(availability.start_date);
            let endCycleOfAvailability = tzDateConverterTool_V5(availability.end_date);
            let domWeekLabel = document.getElementsByClassName('rbc-toolbar-label')[0] && document.getElementsByClassName('rbc-toolbar-label')[0].textContent.split(' – ');

            if (domWeekLabel && domWeekLabel.length > 0 && availability.available == true) {

                let startOfCalendarWeek = domWeekLabel[0].split(' ')[2] + '-' + getMonthNo(domWeekLabel[0].split(' ')[0]) + '-' + domWeekLabel[0].split(' ')[1];
                let endOfCalendarWeek = domWeekLabel[1].split(' ')[2] + '-' + getMonthNo(domWeekLabel[1].split(' ')[0]) + '-' + domWeekLabel[1].split(' ')[1];

                let onGoingCalendarWeekDates = getInbetweenWeekDates(startOfCalendarWeek, endOfCalendarWeek);

                let entryDateOfAvailabilityArray = onGoingCalendarWeekDates.filter((vdate) => {

                    if (identifyDayNameOfWeek(vdate) == makeFirstLetterCapital(availability.day)) {

                        if (overrides && overrides.length > 0) {
                            isOverrideOverLaps = overrides.some((override) => override.start_date.split('T')[0] == vdate);
                        }
                        if (fullDayLeaveOverLaps && fullDayLeaveOverLaps.length > 0) {

                            isFulldayLeaveOverLaps = fullDayLeaveOverLaps.some((fullLeave) => {

                                let differenceInDays = getInbetweenWeekDates(fullLeave.start_date.split('.')[0], fullLeave.end_date.split('.')[0]);

                                if (differenceInDays.length > 1) {

                                    let leavesData = [];
                                    leavesData.push(fullLeave);

                                    let convertedLeavesDataArrayOfObject = leavesData.reduce((acc, leaveEntry) => {

                                        let datesBetween = getInbetweenWeekDates_V2(leaveEntry.start_date.split('.')[0], leaveEntry.end_date.split('.')[0]);
                                        let entriesForDates = datesBetween.map(date => ({
                                            id: leaveEntry.id,
                                            start_date: date,
                                            end_date: date,
                                            start_time: leaveEntry.start_time,
                                            end_time: leaveEntry.end_time,
                                            day_coverage: leaveEntry.day_coverage,
                                            type: leaveEntry.type
                                        }));

                                        return acc.concat(entriesForDates);

                                    }, []);

                                    return convertedLeavesDataArrayOfObject.some((conLeave) => conLeave.start_date.split('T')[0] == vdate)

                                } else {

                                    return fullLeave.start_date.split('T')[0] == vdate

                                }
                            });

                        }
                        // if (partialDayLeavesOverLaps) {
                        //     isPartialDayLeaveOverLaps = partialDayLeavesOverLaps.some((partialLeave) => moment(partialLeave.start_date).format('YYYY-MM-DD') == moment(vdate).format('YYYY-MM-DD'));
                        // }

                        return vdate;

                    }

                });
                
                let convertedStartDate = entryDateOfAvailabilityArray[0];

                // if(availableStartTime < "07:00" && props.user.timezone === TimeZones.PST){
                //     convertedStartDate = moment(entryDateOfAvailabilityArray[0]).add(1, 'day').format('YYYY-MM-DD');
                // } else if (availableStartTime < "05:00" && props.user.timezone === TimeZones.CST){
                //     convertedStartDate = moment(entryDateOfAvailabilityArray[0]).add(1, 'day').format('YYYY-MM-DD');
                // }else{
                //     convertedStartDate = entryDateOfAvailabilityArray[0];
                // }

                let convertedEndDate = null;
                let startTimeConvert = tzCalendarDateTimeConverterTool(convertedStartDate, availability.start_time);

                if (!(availability.start_time < availability.end_time)) {
                    convertedEndDate = moment(convertedStartDate).add(1, 'day').format('YYYY-MM-DD');
                } else {
                    convertedEndDate = convertedStartDate;
                }

                let endTimeConvert = tzCalendarDateTimeConverterTool(convertedEndDate, availability.end_time);

                if (endTimeConvert.includes('00:00:00')) {
                    endTimeConvert = moment(endTimeConvert).subtract(1, 'second').format('YYYY-MM-DDTHH:mm:ss');
                }
                
                if(startTimeConvert.split('T')[0] !== convertedStartDate && endTimeConvert.split('T')[0] !== convertedStartDate){
                    startTimeConvert = moment(startTimeConvert).add(1, 'day').format('YYYY-MM-DDTHH:mm:ss');
                    endTimeConvert = moment(endTimeConvert).add(1, 'day').format('YYYY-MM-DDTHH:mm:ss');
                }

                let startAvailability = new Date(startTimeConvert);
                let endAvailability = new Date(endTimeConvert);
                
                // if ((convertDateFormatV4(entryDateOfAvailabilityArray[0]) >= convertDateFormatV4(startCycleOfAvailability)) && (convertDateFormatV4(entryDateOfAvailabilityArray[0]) <= convertDateFormatV4(endCycleOfAvailability))) {

                    if (isOverrideOverLaps == false && isFulldayLeaveOverLaps == false && isPartialDayLeaveOverLaps == false) {

                        return {
                            id: availability.id,
                            title: 'Availability',
                            start: startAvailability,
                            end: endAvailability
                        }

                    };

                // }

            }

        });

        return combinedDateStorage;

    }

    // const testingHandleAvailabilityFixtures = (availabilities, overrides, leaves) => {

    //     const combinedDateStorage = availabilities.map((availability) => {

    //     });

    //     return combinedDateStorage;

    // };

    /* For Converting the Date and Time from fetched response to showcase Shift Leave in calendar */
    const handleLeaveFixtures = (leaves) => {

        const combinedDateStorage = leaves.map((leave) => {

            let startLeave, endLeave;

            if (leave.day_coverage) {

                if (leave.day_coverage == 'full') {

                    // let tzCalendarDateConverterTool = (date, type) => {

                    //     let currentUser = getUser();
                    //     let isPacificDaylightTime = toFindPDTAvailability(date);
                    //     let daylightDate = null;
                    //     let currentTimezoneDateObject = null;
                    //     let finalFormattedDate = null;

                    //     /* Condition for Pacific Daylight Time Conversion */
                    //     if (isPacificDaylightTime == true) {
                    //         daylightDate = momentTZ(date).add(0, 'hour')
                    //     } else if (isPacificDaylightTime == false) {
                    //         daylightDate = momentTZ(date).add(1, 'hour')
                    //     }

                    //     /* Condition for Current Timezone Conversion */
                    //     currentTimezoneDateObject = momentTZ(daylightDate).tz(currentUser.timezone);
                    //     let localDate = momentTZ(currentTimezoneDateObject._d);

                    //     if (type == 'from') {
                    //         finalFormattedDate = localDate.startOf('day').format('YYYY-MM-DDTHH:mm:ss'); //'T00:00:00'
                    //     } else if (type == 'to') {
                    //         finalFormattedDate = localDate.endOf('day').format('YYYY-MM-DDTHH:mm:ss'); //'T23:59:59'
                    //     }

                    //     return finalFormattedDate;

                    // }

                    /* This whole functionality is for separating the leave cycle dates like Single leaves dates connected with an array of objects */
                    let differenceInDays = getInbetweenWeekDates(leave.start_date.split('.')[0], leave.end_date.split('.')[0]);

                    if (differenceInDays.length > 1) {

                        let leavesData = [];
                        leavesData.push(leave);

                        let convertedLeavesDataArrayOfObject = leavesData.reduce((acc, leaveEntry) => {

                            let datesBetween = getInbetweenWeekDates_V2(leaveEntry.start_date.split('.')[0], leaveEntry.end_date.split('.')[0]);
                            let entriesForDates = datesBetween.map(date => ({
                                id: leaveEntry.id,
                                start_date: date,
                                end_date: date,
                                start_time: leaveEntry.start_time,
                                end_time: leaveEntry.end_time,
                                day_coverage: leaveEntry.day_coverage,
                                type: leaveEntry.type
                            }));

                            return acc.concat(entriesForDates);

                        }, []).map((leave) => {

                            let convertedStartDate = tzUTCDateConverterTool_V3(leave.start_date.split('.')[0]);
                            let convertedEndDate = tzUTCDateConverterTool_V3(leave.end_date.split('.')[0]);

                            startLeave = new Date(convertedStartDate);
                            endLeave = new Date(convertedEndDate);

                            return {
                                id: leave.id,
                                title: makeFirstLetterCapital(leave.type) + ' / ' + 'Full-Leave',
                                start: startLeave,
                                end: endLeave
                            }

                        });

                        return convertedLeavesDataArrayOfObject;

                    } else {

                        let convertedStartDate = tzUTCDateConverterTool_V3(leave.start_date.split('.')[0]);
                        let convertedEndDate = tzUTCDateConverterTool_V3(leave.end_date.split('.')[0]);

                        startLeave = new Date(convertedStartDate);
                        endLeave = new Date(convertedEndDate);

                        return {
                            id: leave.id,
                            title: makeFirstLetterCapital(leave.type) + ' / ' + 'Full-Leave',
                            start: startLeave,
                            end: endLeave
                        }

                    }

                } else if (leave.day_coverage == 'partial') {

                    // let tzCalendarDateConverterTool = (date, time) => {

                    //     let currentUser = getUser();
                    //     let isPacificDaylightTime = toFindPDTAvailability(date);
                    //     let currentTimezoneDateObject = null;
                    //     let finalFormattedDate = null;

                    //     /* Condition for Pacific Daylight Time Conversion */
                    //     if (isPacificDaylightTime == true) {
                    //         currentTimezoneDateObject = momentTZ(`${date} ${time}`).add(0, 'hour')
                    //     } else if (isPacificDaylightTime == false) {
                    //         currentTimezoneDateObject = momentTZ(`${date} ${time}`).add(1, 'hour')
                    //     }

                    //     /* Condition for Current Timezone Conversion */
                    //     finalFormattedDate = momentTZ(currentTimezoneDateObject).tz(currentUser.timezone);
                    //     let result = momentTZ(finalFormattedDate._d).format('YYYY-MM-DDTHH:mm:ss');

                    //     return result;

                    // }

                    let convertedStartDate = tzCalendarDateTimeConverterTool(leave.start_date.split('T')[0], leave.start_time);
                    let convertedEndDate = tzCalendarDateTimeConverterTool(leave.end_date.split('T')[0], leave.end_time);

                    startLeave = new Date(convertedStartDate);
                    endLeave = new Date(convertedEndDate);

                    return {
                        id: leave.id,
                        title: makeFirstLetterCapital(leave.type) + ' / ' + 'Partial-Leave',
                        start: startLeave,
                        end: endLeave
                    }

                }

            }

        });

        /* This functionality usage for separate the Array of mixed array of objects */
        let flattenedArrayOfObjects = [];
        combinedDateStorage.forEach((obj) => {
            flattenedArrayOfObjects = flattenedArrayOfObjects.concat(Array.isArray(obj) ? obj : [obj]);
        });

        return flattenedArrayOfObjects;

    }

    /* For Converting the Date and Time from fetched response to showcase Shift Override in calendar */
    const handleOverrideFixtures = (overrides) => {

        const combinedDateStorage = overrides.map((override) => {

            let startOverride, endOverride;

            let startUtc = tzCalendarDateTimeConverterTool(override.start_date.split("T")[0], override.start_time );
            let endUtc = tzCalendarDateTimeConverterTool(override.start_date.split("T")[0], override.end_time );

                if (endUtc < startUtc) {
                    endUtc = moment(endUtc).add(1, "day").format("YYYY-MM-DD HH:mm:ss");
                }

            startOverride = new Date(startUtc);
            endOverride = new Date(endUtc);
              
            return {
                id: override.id,
                title: 'Override',
                start: startOverride,
                end: endOverride
            }

        });

        return combinedDateStorage;

    }

    const availabilityListData = handleAvailabilityFixtures(props.getSingleUserAvailabilityPlannerList && props.getSingleUserAvailabilityPlannerList, props.getSingleUserOverrideAvailabilityPlannerList, props.getSingleUserLeavesAvailabilityPlannerList); // Calendar Data for Availability Lists

    const leaveListData = handleLeaveFixtures(props.getSingleUserLeavesAvailabilityPlannerList && props.getSingleUserLeavesAvailabilityPlannerList); // Calendar Data for Leave Lists

    const overrideListData = handleOverrideFixtures(props.getSingleUserOverrideAvailabilityPlannerList && props.getSingleUserOverrideAvailabilityPlannerList); // Calendar Data for Override Lists

    let eventStyleBunker = (event) => {

        var style = {
            backgroundColor: '',
            color: '',
            borderColor: '',
            fontSize: '',
            fontWeight: '',
            borderRadius: '',
            // opacity: 0.8,
            // border: '0px',
            // display: 'block'
        };

        if (event && event.title && event.title.length && event.title.length > 0) {

            if (event.title.includes('Availability')) {

                style = {
                    backgroundColor: '#77DD77',
                    color: '#164829',
                    borderColor: '#77DD77',
                    fontSize: 'larger',
                    fontWeight: '600',
                    borderRadius: '0px',
                };

            } else if (event.title.includes('Leave')) {

                style = {
                    backgroundColor: '#FF6961',
                    color: '#ffffff',
                    borderColor: '#FF6961',
                    fontSize: 'larger',
                    fontWeight: '600',
                    borderRadius: '0px',
                };

            } else if (event.title.includes('Override')) {

                style = {
                    // backgroundImage: 'repeating-linear-gradient(45deg, #999999, #85ffb2 7%, #9d9d9d 10%)',
                    backgroundColor: '#a8ffa8',
                    color: '#ffffff',
                    borderColor: '#a8ffa8',
                    fontSize: 'larger',
                    fontWeight: '600',
                    borderRadius: '0px',
                };

            }

        }

        return {
            style: style
        };

    };

    const calendarCardsEventCustomizer = ({ event }) => {
        return (
            <div data-tip={event.title}>
                {/* {event.title} */}
            </div>
        );
    };

    // For getting the Date label format for Day view
    const customDateLabelFormatter = (date, culture, localizer) => {
        let formattedDate = localizer.format(date, 'MMMM DD dddd YYYY', culture);
        return `${formattedDate}`;
    };

    /* For getting the Date label format for Week view */
    const customWeekLabelFormatter = (date, culture, localizer) => {
        const start = new Date(date.start);
        const end = new Date(date.end);

        const dateFormat = 'MMMM DD YYYY';
        return `${localizer.format(start, dateFormat, culture)} – ${localizer.format(end, dateFormat, culture)}`;
    };

    /* For chaning the customized events for calendar */
    let customizationCalendarEventFormats = {
        // timeGutterFormat: 'HH:mm',
        // dayHeaderFormat: customDateLabelFormatter, /* For getting the Date label format for Day view */
        dayRangeHeaderFormat: customWeekLabelFormatter, /* For getting the Date label format for Week view */
        // Add any other custom formats here
    };

    const handleAvailabilityWeeklySearch = (date) => {

        let searchedFirstWeekDate = tzUTCDateConverterTool_V2(tzStartOfWeek(customTime(date.weekly_startDate)), 'from');
        let searchedLastWeekDate = tzUTCDateConverterTool_V2(tzEndOfWeek(customTime(date.weekly_endDate)), 'to');
        setViewOverDate(date.weekly_startDate);

        if (props && props.user && props.user.role && props.user.role == userRoles.FRONTLINE) {
            props.getAvailabilityCalendarScheduledList(searchedFirstWeekDate, searchedLastWeekDate, currentUserInfo.id);
        } else if (props && props.match && props.match.params && props.match.params.userId) {
            props.getAvailabilityCalendarScheduledList(searchedFirstWeekDate, searchedLastWeekDate, props.match.params.userId);
        }

    };

    return (
        <>

            <main className="app-content wraper_content inspection_wraper ">

                <div className="tab_header search_filter_cont inspection_tab_content job_filter">
                    <form>
                        <div className="row mt-2">
                            <div className="col-xl-12 col-lg-12 text-left quotes_btn_wrap schedulesButtonWrap mw-50per mt-2">
                                <div className="media-body" style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                    <div style={{ width: '50%', backgroundColor: '#555555', border: '1px solid #555555' }}>
                                        <h5
                                            className="mt-0 font-weight-bold mb-3 text-white"
                                            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}
                                        >
                                            User Timezone
                                        </h5>
                                    </div>
                                    <div style={{ width: '50%', border: '1px solid #555555' }}>
                                        <h5
                                            className="mt-0 font-weight-bold mb-3 text-dark"
                                            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}
                                        >
                                            {/* {props.user.role == userRoles.FRONTLINE ? currentUserInfo.zoneMark : tzTimezoneMarking(props.frontlineUser && props.frontlineUser.timezone)} */}
                                            {currentUserInfo.zoneMark}
                                        </h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-12 col-lg-12 text-right quotes_btn_wrap schedulesButtonWrap mw-50per">
                                <div class="dropdown text-right mr-4">
                                    <CalendarDateSearch handleWeeklySearch={handleAvailabilityWeeklySearch} />
                                </div>
                                <div class="dropdown text-right">

                                    {props && props.user && props.user.role && (props.user.role == userRoles.FRONTLINE) || (props.user.role == userRoles.SUPER_ADMIN) || (props.user.role == userRoles.ADMIN) ? (
                                        <>
                                            <button type='button' className="btn btn-secondary cst_btn" style={{ marginRight: '10px' }}><i className="fa fa-calendar"></i> Availability </button>
                                            <div class="dropdown-content createSchedulesButtonDrop">
                                                <a >
                                                    <button type='button' onClick={() => { props.createNewAvailability() }} className="btn btn-secondary" data-test="new_job_account"><i className="fa fa-plus icn_plus"></i> Create Availability </button>
                                                </a>
                                                <a style={{ display: 'grid', textDecoration: 'none' }}>
                                                    <button type='button' onClick={() => { props.overrideAvailability() }} className="btn btn-secondary" data-test="new_job_account"><i className="fa fa-pencil"></i> Override Availability </button>
                                                </a>
                                            </div>
                                        </>
                                    ) : null}

                                </div>
                            </div>
                        </div>
                    </form>
                </div>

                <section className="account_sec inspection_tab_content appcontent_Inner availability_calendar" id="schedule_calender">
                    <div className="quotes_table_content accounts_table_contnet table-responsive overflow-auto">
                        <div className="table quotes_table user_react_table">
                            <div className="mt-4">

                                <Calendar
                                    showMultiDayTimes
                                    localizer={localizer}
                                    startAccessor="start"
                                    endAccessor="end"
                                    events={availabilityListData.concat(leaveListData).concat(overrideListData)}
                                    eventPropGetter={eventStyleBunker}
                                    defaultDate={new Date()}
                                    date={viewOverDate} //Display the SEarch field captured dates
                                    defaultView="week"
                                    views={['week']}
                                    components={{
                                        event: calendarCardsEventCustomizer
                                    }}
                                    formats={customizationCalendarEventFormats} //Using for chaning the events of calendar customwise
                                    onNavigate={(date, dataType) => {
                                        setViewOverDate(date);
                                        if (dataType == 'week') {
                                            setTimeout(() => {

                                                storage.set('liveDay', date);
                                                let firstWeekDate = tzUTCDateConverterTool_V2(tzStartOfWeek(customTime(date)), 'from');
                                                let lastWeekDate = tzUTCDateConverterTool_V2(tzEndOfWeek(customTime(date)), 'to');

                                                if (props && props.user && props.user.role && props.user.role == userRoles.FRONTLINE) {
                                                    props.getAvailabilityCalendarScheduledList(firstWeekDate, lastWeekDate, currentUserInfo.id);
                                                } else if (props && props.match && props.match.params && props.match.params.userId) {
                                                    props.getAvailabilityCalendarScheduledList(firstWeekDate, lastWeekDate, props.match.params.userId);
                                                }

                                            }, 500);
                                        }
                                    }}
                                    style={{ height: 600 }}
                                // timeslots={1}
                                // step={60}
                                />

                            </div>
                        </div>
                    </div>
                </section>

            </main>

        </>
    );
};

export default AvailabilityCalendarComponent;