import React, { Component } from 'react';
import TimeCardCorrectionForm from '../../../component/TimeTrackerModuleComponent/TimeCardCorrectionModuleComponent/TimeCardCorrectionForm';
import { connect } from 'react-redux';
import * as actions from '../../../../redux/actions/index';
import { routes } from '../../../../utility/constants/constants';
import { convertTo24HourFormat, dateFormatConversion_V1, extractExtention } from '../../../../utility/utility';
import { decode, encode } from 'base64-arraybuffer';
import errorHandler from '../../../../utility/errorHandler/errorHandler';
import { tzUTCDateTimeConverterTool_V1 } from '../../../../utility/TimezoneOperations/timezoneUtility';
const cloneDeep = require('clone-deep');

class TimeCardCorrectionModuleContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isShiftDetailId: false,
            isTriggerLoader: false,
            empSignBase64: [],
            
        }

    }

    componentDidMount() {
        this.props.clearSingleShifSchedule()
        this.setState({ isTriggerLoader: true });
        setTimeout(() => {
            this.setState({ isTriggerLoader: false });
        }, 2500);

        if (this?.props?.match?.params?.shiftDetailId) {
            this.setState({ isShiftDetailId: true });
            
            this.props.getSingleShifSchedule(this.props.match.params.shiftDetailId);
        }

        // this.props.getJobAccountSitesList();
        this.props.getUsersListByTheirRoles({ roles: 'frontline' });

    }

    componentWillUnmount() {
        this.props.resetTimeTrackerObjects();
    }

    handleEmployeeSignature = (image) => {

        this.setState({
            empSignBase64: [image.empSignUrl]
        })

    }

    findDirtyFields(initialValues, values) {
        let initialTimecard = initialValues.timecard
        let latestTimecard = values.timecard
        let results = {
            timecard: {

            }
        }
        
        let priorityKeys = ["clockIn", "clockOut", "breakIn", "breakOut","shiftDetailJobs"]
        Object.keys(latestTimecard).map((key, index) => {
            if (priorityKeys.includes(key)) {
                if (Array.isArray(latestTimecard[key]) && (key=="breakIn" || key=="breakOut")) {
                    for (const mealsInfo of latestTimecard[key]) {
                        let mealid = mealsInfo.id
                        let decideKey = key.split("break")[1]
                        let val = mealsInfo[`meal${decideKey}`]
                        if (val !== initialTimecard[key].find((dd) => dd.id == mealid)[`meal${decideKey}`]) {
                            results["timecard"][key] = latestTimecard[key]
                            break;
                        }
                    }

                    // if(initialTimecard)
                }
                if (initialTimecard[key] !== latestTimecard[key]) results["timecard"][key] = latestTimecard[key]

                
            }

        })
        return results

    }

    handleSubmit = (values, initialValues) => {
        
        if (!(values?.timecard?.errorReason?.length > 0)) {
            errorHandler('Reason for Error is required')
            return false;
        }

        let setData = { data: {} };
        let fetchedValue = cloneDeep(values.timecard);
        let arrayBuffer = [];
        let isDirty=false
        let extensions = extractExtention(['img.png']);

        const dirtyFields = this.findDirtyFields(initialValues, values)
        //here making dirtyfield as false while its doesnt 
        
        isDirty= (dirtyFields.timecard.shiftDetailJobs ==null || dirtyFields.timecard.shiftDetailJobs.length ==0 ) && false  
        
        for (const item of dirtyFields.timecard.shiftDetailJobs) {
            
            let id=item.id
            let initialValuesOfJob=values.timecard.initValues[id]
            if(Object.keys((initialValuesOfJob)).length){
                 if(initialValuesOfJob["end_at"] !== item.end_at || initialValuesOfJob["start_at"] !== item.start_at){
                    isDirty=true
                    break;
                 }
            }

        }
        
        if(isDirty==false){
            errorHandler('please change your time field first !!')
            return false;
        }
        
        setData.data['type'] = 'timecard';
        setData.data['shift_detail_id'] = this.props.match.params.shiftDetailId ? this.props.match.params.shiftDetailId : this.props.singleShiftScheduleData.id;
        setData.data['date_error'] = tzUTCDateTimeConverterTool_V1(dateFormatConversion_V1(fetchedValue.errorDate).split('T')[0], dateFormatConversion_V1(fetchedValue.errorDate).split('T')[1], 'none');
        setData.data['clockout_agreement'] = fetchedValue.clkAgreement;
        setData.data["active_status"]="active"
        setData.data["reason"]=fetchedValue.errorReason

        /* Functionalities for corrections Array */
        
        
        
        
        setData.data['corrections'] = Object.entries(dirtyFields.timecard).reduce((acc, [key, value]) => {
            if (key === 'clockIn') {
                acc.push({
                    entity_id: this.props.match.params.shiftDetailId ? this.props.match.params.shiftDetailId : this.props.singleShiftScheduleData.id,
                    entity_type: 'ShiftDetail',
                    start_time: fetchedValue.clockIn ? tzUTCDateTimeConverterTool_V1(dateFormatConversion_V1(fetchedValue.errorDate).split('T')[0], convertTo24HourFormat(fetchedValue.clockIn), 'none') : null,
                    end_time: fetchedValue.clockOut ? tzUTCDateTimeConverterTool_V1(dateFormatConversion_V1(fetchedValue.errorDate).split('T')[0], convertTo24HourFormat(fetchedValue.clockOut), 'none') : null,
                    reason: fetchedValue.errorReason
                });
            } else if (key == 'breakIn') {
                value.forEach((breakObj, index) => {
                    acc.push({
                        entity_id: breakObj.id,
                        entity_type: "Break",
                        start_time: breakObj.mealIn ? tzUTCDateTimeConverterTool_V1(dateFormatConversion_V1(fetchedValue.errorDate).split('T')[0], convertTo24HourFormat(breakObj.mealIn), 'none') : null,
                        end_time: fetchedValue.breakOut[index].mealOut ? tzUTCDateTimeConverterTool_V1(dateFormatConversion_V1(fetchedValue.errorDate).split('T')[0], convertTo24HourFormat(fetchedValue.breakOut[index].mealOut), 'none') : null,
                        reason: fetchedValue.errorReason
                    });
                });
            }
            else if(key=="shiftDetailJobs"){
                value.forEach((shiftObj) => {
                    
                    acc.push({
                        entity_id: shiftObj?.id,
                        entity_type: "ShiftDetailJob",
                        start_time: shiftObj.start_at ? tzUTCDateTimeConverterTool_V1(dateFormatConversion_V1(fetchedValue.errorDate).split('T')[0], convertTo24HourFormat(shiftObj.start_at), 'none') : null,
                        end_time: shiftObj.end_at ? tzUTCDateTimeConverterTool_V1(dateFormatConversion_V1(fetchedValue.errorDate).split('T')[0], convertTo24HourFormat(shiftObj.end_at), 'none') : null,
                        reason: fetchedValue.errorReason
                    });
                });
            }
            
            return acc;
        }, []);


        /* STEP 4 DATA MANIPULATING Employee FLOW */
        if (fetchedValue?.empId?.value) {
            if (fetchedValue.empSignDate) {
                setData.data['status'] = 'requested';
                setData.data['employee_signed_date'] = fetchedValue.empSignDate;
                
            } else {
                errorHandler('Employee Signed Date is required')
                return false;
            }
        }
        
        

        

        if (this.state.empSignBase64.length > 0) {  
            this.state.empSignBase64.map((url, i) => {
                if (!url.photo_urls) {
                    let base_photo = null;
                    const image = url.split(',');
                    base_photo = image[1];
                    arrayBuffer.push(decode(base_photo))
                }
            })

            
            this.props.acquireTimecardCorrectionSignPresignUrl(this.props.match.params.shiftDetailId ? this.props.match.params.shiftDetailId : this.props.singleShiftScheduleData.id, extensions, arrayBuffer, setData, { review: 'empReview' });
        } else {
            errorHandler('Employee Signature is required');
            return false;
        }

    }

    handleCancel = () => {
        this.props.history.push(routes.SHIFT);
    }

    render() {
        return (
            <>

                <TimeCardCorrectionForm
                    {...this.props}
                    isTriggerLoader={this.state.isTriggerLoader}
                    isShiftDetailId={this.state.isShiftDetailId}
                    onSubmit={this.handleSubmit}
                    handleCancel={this.handleCancel}
                    handleEmployeeSignature={this.handleEmployeeSignature}
                />

            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.authReducer.user,
        isLoading: state.TimeTrackerModReducer.isLoading,
        jobAccountSitesList: state.TimeTrackerModReducer.jobAccountSitesList,
        frontlineUsersList: state.TimeTrackerModReducer.usersListBySelectedRole,
        singleShiftScheduleData: state.TimeTrackerModReducer.singleShiftScheduleData,
        getShiftListByparams: state.TimeTrackerModReducer.getShiftListByparams,
        formState: state.form?.frontlineForm?.values
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        clearSingleShifSchedule: () => dispatch(actions.clearSingleShifSchedule()),
        resetTimeTrackerObjects: () => dispatch(actions.resetTimeTrackerObjects()),
        getJobAccountSitesList: () => dispatch(actions.getJobAccountSitesList()),
        getUsersListByTheirRoles: (params) => dispatch(actions.getUsersListByTheirRoles(params)),
        getSingleShifSchedule: (id) => dispatch(actions.getSingleShifSchedule(id)),
        getShiftListByParams: (params) => dispatch(actions.getShiftListByParams(params)),
        acquireTimecardCorrectionSignPresignUrl: (shiftId, extentions, arrayBuffer, formData, review) => dispatch(actions.acquireTimecardCorrectionSignPresignUrl(shiftId, extentions, arrayBuffer, formData, review)),
        createTimecardCorrectionQuery: (body) => dispatch(actions.createTimecardCorrectionQuery(body)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TimeCardCorrectionModuleContainer);