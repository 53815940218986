import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

const Field = (h, w) => {
    return (<Skeleton height={h} width={w} />)
}

const Image = () => {
    return (<Skeleton circle={true} height={100} width={100} />)
}

const userSkeleton = (props) => {
    return (
        <main className="app-content wraper_content user_newQuote add_account_modal add_New_Accont">
            <div className="inspection_content_sec appcontent_Inner">
                <div className="container-fluids">
                    <div className="row">
                        <div className="col-sm-12 text-center">

                            <h4 className="section_title">{Field(30, 200)}</h4>
                        </div>
                    </div>
                    {/* <form onSubmit={handleSubmit}> */}
                    <div className="quote_form">
                        <div className="addQuote_form">
                            <div className="cst_tab_content">
                                <div className="profile_img_block">

                                    {Image()}

                                    <div className="clearfix"></div>
                                    <div className="profile-details">

                                    </div>
                                </div>


                                <div className="row">
                                    <div className="col-md-6" style={{ marginTop: '15px' }}>
                                        <div className="form-group">

                                            {/* <label for="email" className="label_modifier"></label> */}
                                            {Field(20)}
                                            {/* <Input type="text" className="form-control input-modifier" id="email" placeholder="" name="user[first_name]" /> */}
                                        </div>
                                    </div>
                                    <div className="col-md-6" style={{ marginTop: '15px' }}>
                                        <div className="form-group">
                                            {Field(20)}
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6" style={{ marginTop: '15px' }}>
                                        <div className="form-group">
                                            {Field(20)}

                                        </div>
                                    </div>
                                    <div className="col-md-6" style={{ marginTop: '15px' }}>
                                        <div className="form-group">
                                            {Field(20)}

                                        </div>
                                    </div>
                                </div>

                                <div className="row">

                                    <div className="col-md-12">
                                        <div className="form-group" style={{ marginTop: '25px' }}>
                                            {Field(20)}
                                        </div>
                                        <div className="form-group" style={{ marginTop: '25px' }}>

                                            {Field(20)}

                                        </div>
                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="form-group" style={{ marginTop: '15px' }}>
                                                    {Field(20)}
                                                    {/* <input type="text" style={{ backgroundColor: '#c7cdd154' }} readOnly={true} value={props.address_attributes.city} className="form-control input-modifier" placeholder="" /> */}
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group" style={{ marginTop: '15px' }}>
                                                    {/* <label className="label_modifier">State</label> */}
                                                    {Field(20)}
                                                    {/* <input type="text" style={{ backgroundColor: '#c7cdd154' }} readOnly={true} value={props.address_attributes.state} className="form-control input-modifier" placeholder="" /> */}
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group" style={{ marginTop: '15px' }}>
                                                    {/* <label className="label_modifier">Country</label> */}
                                                    {Field(20)}
                                                    {/* <input type="text" style={{ backgroundColor: '#c7cdd154' }} readOnly={true} value={props.address_attributes.country} className="form-control input-modifier" placeholder="" /> */}
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group" style={{ marginTop: '15px' }}>
                                                    {/* <label className="label_modifier">Zip</label> */}
                                                    {Field(20)}
                                                    {/* <input style={props.isZipEmpty ? { borderColor: 'rgb(240, 77, 83)', borderLeftWidth: '3px' } : null} type="text" onChange={props.handleInputChange} value={props.address_attributes.zip} className="form-control input-modifier" name="zip" placeholder="" /> */}
                                                    <span style={{ color: '#DD2726', fontSize: '13px' }}>{props.isZipEmpty ? 'This field is required.' : null}</span>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group" style={{ marginTop: '15px' }}>
                                                    <label className="label_modifier"></label>
                                                    {Field(20)}
                                                    {/* <Input name="user[region_id]" type="select" options={RegionOptions} className="custom-select input-modifier add_user_select" /> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* </form> */}
                            </div>
                            <div className="modal-footer">
                                {Field(20)}
                                {Field(20)}
                                {/* <button type="submit" disabled={props.isLoading ? true : false} className={props.isLoading ? "btn cst_btn btn_danger mr-2 btn-wait" : "btn cst_btn btn_danger mr-2"} >{props.isLoading ? "" : "Submit"} </button>
                                <button type="button" className="btn btn-outline-secondary cst_btn" onClick={props.onCancelClicked}>Cancel</button> */}
                            </div>
                        </div>
                    </div>
                    {/* </form> */}
                </div>
            </div>
        </main>
    )
}

export default userSkeleton;