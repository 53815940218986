import React, { Component } from 'react';
import { routes } from '../../../../../utility/constants/constants';
import * as actions from '../../../../../redux/actions/index';
import { connect } from 'react-redux';
import { reset } from 'redux-form';
import { animateScroll as scroll} from 'react-scroll'
import storage from '../../../../../utility/storage';
import PerformanceAudit from '../../../../component/FormAuditModule/AuditsComponent/PerformanceAudit/performanceAudit';
import { convertDateToDifferentTZ } from '../../../../../utility/utility';

var moment = require('moment');
const cloneDeep = require('clone-deep');

class PerformanceAuditContainer extends Component {

    constructor(props) {
        super(props);

        this.state = {
            
        }
    }

    componentDidMount() {
        scroll.scrollToTop();
    }

    componentDidUpdate(prevProps, prevState) {
        const user = storage.get('user');
        if(user) {
            if(prevProps.user.selected_mode !== user.selected_mode) {
                this.props.scheduledTaskListPageClicked(0)
            }
        }
    }

    resetClicked = () => {
        const page = 1
        const limit = this.props.performanceAuditList_no_of_rows
        this.props.reset('searchPerformanceAudit');
        this.props.performanceAuditListPageClicked(0)
        this.props.getAllPerformanceAudit({page,limit}, {})

    }

    searchDeepChart = (value) => {
        const values = cloneDeep(value)
        let from_date =  null;
        let to_date =  null;
    
        const page = 1
        const limit = this.props.performanceAuditList_no_of_rows
        if (values.fromDate && values.toDate) {
            from_date = moment(values.fromDate).format('DD-MM-YYYY');
            to_date = moment(values.toDate).format('DD-MM-YYYY');
        }
     
        this.props.getAllPerformanceAudit({page,limit,from_date, to_date}, values);
    }

    newTicketClicked = () => {
        this.props.history.push(routes.NEW_PERFORMANCE_AUDIT);
    }
         
    render() {
        return (
            <PerformanceAudit
            {...this.props}
            onSubmit={this.searchDeepChart}
            resetClicked={this.resetClicked}
            isLoading={this.props.isLoading}
            performanceAuditList={this.props.performanceAuditList ? this.props.performanceAuditList : []}
            newTicketClicked={this.newTicketClicked}
                
            />
        )
    }
}




const mapStateToProps = (state) => {
    return {
        user: state.authReducer.user,
        isLoading: state.auditsReducer.isLoading,
        performanceAuditList: state.auditsReducer.performanceAuditList,
        performanceAuditList_no_of_rows: state.auditsReducer.performanceAuditList_no_of_rows,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getAllPerformanceAudit: (params , values) => dispatch(actions.getAllPerformanceAudit(params, values)),
        performanceAuditListPageClicked: (page) => dispatch(actions.performanceAuditListPageClicked(page)),
        reset: (searchPerformanceAudit) => dispatch(reset(searchPerformanceAudit)), 
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(PerformanceAuditContainer);