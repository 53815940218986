import React from 'react';
import { currentData } from '../../../utility/constants/constants';
import {  getYearView } from '../../../utility/utility';



const QuotasYearFilter = (props) => {

  return (
      <div className="row">
        <div className="col-md-12">
          <div className="app_inner_title">
            <div className="calender_block">
              <div className="filter_cal_box">
                <div className="filter_block">
                  <div className="list_filter">
                    <a className="list_link active list_link" href="javascript:void(0)">Year</a>
                  </div>
                </div>
                <div className="cal_block">
                  <ul className="calc_content">
                    <li onClick={props.previousYearCliked}><a href="javascript:void(0)" ><i className="fas fa-chevron-left"></i></a></li>
                    <li onClick={props.nextYearCliked}><a href="javascript:void(0)" ><i className="fas fa-chevron-right"></i></a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="react_times_button time_picker_preview" style={{ textAlign: 'center', width: '200px', WebkitBoxShadow: '2px 2px 15px 0 rgba(0,0,0,.15)', height: '50px' }}>
            <label className=" preview_container without_icon" style={{ backgroundColor: '#e9ecef59', color: '#89073c' }}>
              {getYearView(props.currentDate)}
            </label>
          </div>

        </div>
      </div>
  )
}

export default QuotasYearFilter;