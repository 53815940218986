import React, { useEffect, useState } from 'react';
import ReactTable from 'react-table-v6';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeFirstLetterCapital, CustomNoDataComponent, getUser, convertInventoryDateTimezone } from '../../../../../utility/utility';
import { MagicSpinner, PushSpinner, SwishSpinner, WaveSpinner, ClapSpinner } from 'react-spinners-kit';
import '../../../../../../node_modules/react-table-v6/react-table.css';
import { useSelector, useDispatch } from 'react-redux';
import { FaArrowDown, FaArrowUp } from "react-icons/fa";
import * as actions from '../../../../../redux/actions/index';
import ImageLoader from 'react-imageloader';

const PreviewInventoryTable = (props) => {
  const [pageSize, setPageSize] = useState(10);
  const [openModel, setOpen] = useState(false);

  const dispatch = useDispatch();
  const SearchValue = useSelector(state => state.form.searchPreviewInventoryForm.values);
  const DateGenerate = useSelector(state => state.InventoryReducer.dataGenerated);
  const SearchOrResetFrontLineUsersClicked = useSelector(state => state.InventoryReducer.SearchOrResetFrontLineUsersClicked);
  const No_of_pages = useSelector(state => state.InventoryReducer.pickupTicket_pages);
  const No_of_rows = useSelector(state => state.InventoryReducer.pickupTicket_no_of_rows);
  const pickupTicketCurPage = useSelector(state => state.InventoryReducer.pickupTicketCurPage);
  const inventory = useSelector(state => state.InventoryReducer.inventoryLog);

  const data =  inventory && inventory.map(ticket => {
    debugger;
    return {
      ticket_id: ticket.inventory_ticket_id ? ticket.inventory_ticket_id : '-',
      name: ticket.account ? ticket.account.name : "-",
      created_date: ticket &&  ticket.updated_at ? convertInventoryDateTimezone(ticket.updated_at).toUpperCase() : '' ,
      approver: ticket.user ? `${makeFirstLetterCapital(ticket.user.first_name)} ${makeFirstLetterCapital(ticket.user.last_name)} ` : ''  ,
      reason : ticket.comments ? ticket.comments : '-',
      quantity : ticket.quantity ? ticket.quantity : '-' ,

    }
  })


  const columns = [
    
    {
      Header: <><span>Job Name</span><FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
      accessor: 'name', // String-based value accessors!,
      show: inventory && inventory.length !== 0,
    },
    {
        Header: <><span>Created Date & Time</span><FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
        accessor: 'created_date', // String-based value accessors!,
        show: inventory && inventory.length !== 0,
      },
      {
        Header: <><span>Ticket Id</span><FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
        accessor: 'ticket_id', // String-based value accessors!,
        show: inventory && inventory.length !== 0,
        width: 100
      },
      {
        Header: <><span>Done By</span><FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
        accessor: 'approver', // String-based value accessors!,
        show: inventory && inventory.length !== 0,
      },
      {
        Header: <><span>Reason</span><FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
        accessor: 'reason', // String-based value accessors!,
        show: inventory && inventory.length !== 0,
      },
      {
        Header: <><span>Quantity</span><FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
        accessor: 'quantity', // String-based value accessors!,
        show: inventory && inventory.length !== 0,
        width: 100
      },
  ]

  const [tablePage, setTablePage] = useState(0)
  const tablePageNew =  React.useRef()
  if(pickupTicketCurPage !== tablePageNew.current) {
    tablePageNew.current = pickupTicketCurPage;
  }
  const setTablePageFn = (page) => {
      setTablePage(page)
      props.frontlineUserPageClicked(page)
      tablePageNew.current = page;
  }

  const fetchData = (state, instance) => {
    if (SearchOrResetFrontLineUsersClicked) {
      dispatch(actions.resetSearchOrResetValue())
    } else {
      if (!DateGenerate) {
                state.page = 2;
                const limit = state.pageSize;
                const fl_users_no_of_rows = state.pageSize;
                let page = tablePageNew.current >= 0 ? tablePageNew.current + 1 : pickupTicketCurPage + 1;
                if(No_of_rows != fl_users_no_of_rows) {
                  page = 1;
                  setTablePageFn(0)
              }
                const sorted = state.sorted;
        //const filtered = state.filtered;
        const name = SearchValue.name ? SearchValue.name : '';
        const id = SearchValue.id ? SearchValue.id : '';
        dispatch(actions.getSingleInventoryLogItem(props.match.params.inventoryItemId))
      }
    }
    // }
  }
  const handleConfirm = (confirm) => {
    setOpen(false);
    //props.deleteArea(currentArea.id, props.match.params.accountId)
  };
  return (
    SearchOrResetFrontLineUsersClicked && props.isLoading ?
      <div className="loader_btn_block">
        <ClapSpinner backColor="#89073c" size={40} frontColor="#89073c8a" />
      </div>
      :
      <> <ReactTable
        data={data}
        loading={props.isLoading}
        defaultPageSize={No_of_rows ? No_of_rows : pageSize}
        minRows={0}
        manual
        onFetchData={fetchData}
        pages={No_of_pages}
        gotoPage={pickupTicketCurPage}
        page = { pickupTicketCurPage }
        onPageChange={(page) => {setTablePageFn(page)}}
        onPageSizeChange={(e) => setPageSize(e)}
        showPaginationTop={false}
        showPaginationBottom={false}
        showPagination={inventory && inventory.length !== 0}
        columns={columns}
        NoDataComponent={() => CustomNoDataComponent(props.isLoading, 'No Pickup Tickets Found')}
        getTheadThProps={(state, rowInfo, column, instance) => {
          return {
            style: {
              borderTop: 'none',
              borderBottom: 'none',
              borderRight: 'none',
              textAlign: 'center',
              color: '#566975',
              fontSize: '14px',
              fontWeight: '600',
              whiteSpace: 'nowrap',
              float: 'left',
            }
          }
        }
        }
        LoadingComponent={() =>
          props.isLoading ?
            <div style={{
              display: "block",
              position: "absolute",
              left: 0,
              height: '100%',
              right: 0,
              background: "rgba(255,255,255,0.8)",
              transition: "all .3s ease",
              top: 0,
              bottom: 0,
              textAlign: "center"
            }}
              className="loader_btn_block">
              <ClapSpinner backColor="#89073c" size={40} frontColor="#89073c8a" />
            </div> : null}
        getTrProps={(state, rowInfo, column, instance) => {
          return {
            className: 'react-table-tr-element',
            style: {
              border: '1px solid #c7cdd1',
              transition: '.3s ease-in-out',
              width: '100%',
              marginTop: '5px',
              float: 'left'
            }
          }

        }}
        getTdProps={(state, rowInfo, column, instance) => {

          if (column.id == 'image') {
            return {
              style: {
                marginTop: '0px'
              }
            }
          } else {
            return {
              className: 'react-td-element',
            }
          }
        }
        }
        getProps={() => {
          return {
            style: {
              border: 'none'
            }
          }
        }}
      />
        <Dialog
          open={openModel}

          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title" style={{ fontSize: '13', color: '#06080f' }}>
            <h3 style={{ color: '#ab385e' }}>Are you sure?</h3>
          </DialogTitle>

          <DialogActions>
            <button style={{ minHeight: '36px', minWidth: '80px' }} onClick={() => handleConfirm(true)} disabled={props.isLoading} className={props.isLoading ? "btn cst_btn btn_danger mr-2 btn-wait" : "btn cst_btn btn_danger mr-2"} id="delete" type="delete">{props.isLoading ? "" : "Delete"}</button>
            <button style={{ minHeight: '36px', minWidth: '80px' }} onClick={() => setOpen(false)} className={"btn cst_btn btn-outline-secondary"} id="cancel" type="cancel">Cancel</button>
          </DialogActions>
        </Dialog>

      </>
  )
}

export default PreviewInventoryTable;