import { API_VERSION, formAuditBaseUrl } from '../../config';

const headers = {
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
    },
};

export const getAllPerformanceAudit = (params) => {
    return formAuditBaseUrl.get(API_VERSION + `audit/items`,{params} , headers);
};

export const getAllPerformanceAuditQuestions = () => {
    return formAuditBaseUrl.get(API_VERSION + `audit/items/questions`, headers);
};

export const getAllSelfAuditQuestions = () => {
    return formAuditBaseUrl.get(API_VERSION + `audit/self/questions`, headers);
};

export const AddSelfAuditPage = (body) => {
    return formAuditBaseUrl.post(API_VERSION + `audit/self`, body,headers);
};

export const GetSingleSelfAuditPage = (id) => {
    return formAuditBaseUrl.get(API_VERSION + `audit/self/${id}`,headers);
};

//audit/self
export const EditSelfAuditPage = (body) => {
    return formAuditBaseUrl.put(API_VERSION + `audit/self`, body,headers);
};



export const getAllSelfAuditApi = (params) => {
    return formAuditBaseUrl.get(API_VERSION + `/audit/self`,{params} , headers);
};


export const addPerformanceAudit = (body) => {
    return formAuditBaseUrl.post(API_VERSION + `audit/items` ,body ,headers);
};

export const getSinglePerformanceAudit = (id) => {
    return formAuditBaseUrl.get(API_VERSION + `audit/items/${id}`, headers);
};

export const updatePerformanceAudit = (body) => {
    return formAuditBaseUrl.put(API_VERSION + `audit/items` ,body ,headers);
};