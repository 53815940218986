import React, { useEffect, useState } from 'react'
import withFormHoc from '../../../../../hoc/withReactHookForm/withReactHookForm'
import { Input } from '../../../UI/InputElement/reactFormHookInputField';
import { useFieldArray, useWatch } from 'react-hook-form'
// import CleaningWorkersForm from './cleaningWorkersForm';
import AssetsFormSkeleton from '../../../Inventory/Assets/NewAssetsForm/AssetsFormSkeleton';
import { useSelector } from 'react-redux';
import MeetingAttendeesCompponent from './MeetingAttendeesComponent';



function PreviewEventForm(props) {
    const { fields, append, remove } = useFieldArray({ control: props.control, name: "attedees" })
    const { isLoading, } = useSelector((state) => state.BRM)
    
    const defaultTaskData = {
        first_name: "",
        last_name: "",
        email: "",
    }

    const addTaskToList = (e) => {
        e.preventDefault();
        append(defaultTaskData)
    }

    const removeFromTaskList = (index) => (e) => {
        e.preventDefault();
        remove(index)
    }

    

    

    if ((isLoading && props.isView) || isLoading) {
        return <AssetsFormSkeleton />
    }

    return (
        <main class="app-content wraper_content chart_section">
            <section class="teammanage_formmain">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="team_manage_head">
                                <h1 class="tm_top_head"> Preview Event  </h1>
                            </div>
                        </div>
                        <form class="col-lg-12" noValidate onSubmit={props.handleSubmit((data) => {
                            props.onSubmit(data)
                        })}>

                            <div class="tm_form_wrapper">
                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                    <p>Meeting Attendees</p>

                                    {
                                        !props?.isView && <button onClick={addTaskToList} style={{ border: "none", backgroundColor: "transparent", cursor: "pointer", alignItems: "center" }}>
                                            <img src="/images/icons/plus-icon.svg" />
                                        </button>
                                    }

                                </div>


                                {
                                    fields?.map((field, index) => (
                                        <>
                                            <MeetingAttendeesCompponent setValue={props?.setValue} setFocus={props.setFocus} isView={props?.isView} key={field.id} controller={props.controller} field={field} control={props.control} index={index} register={props.register} errors={props.errors} removeFromTaskList={removeFromTaskList} getValues={props.getValues} />
                                            <hr class="custom-hr" style={{ marginTop: ".5rem", backgroundColor: "#C7CDD198" }} />
                                        </>
                                    ))
                                }


                                <div class="tm_form_wrap">
                                    <div class="tm_form_innner">
                                        <div class="form">
                                            <div class="row">
                                                <div class="col-md-6 col-sm-12">
                                                    <div class="form-group">
                                                        <Input
                                                            type="date"
                                                            datePickerType="date"
                                                            formControlClasses="form-control"
                                                            id="scheduled_at"
                                                            placeholder={"MM /DD/ YYYY"}
                                                            control={props.control}
                                                            controller={props.controller}
                                                            selectName={"scheduled_at"}
                                                            dateFormat="MM/dd/yyyy"
                                                            maxDate={new Date()}
                                                            minDate={new Date('2020-01-01')}
                                                            preventReadOnly={false}
                                                            label="Meeting Held On"
                                                            formControlProps={props.register('scheduled_at', {
                                                                required: "This field is required", maxLength: 80
                                                            })}
                                                            validationErrorMessage={props.errors?.scheduled_at?.message}
                                                            disabled={props?.isView}
                                                        />

                                                    </div>
                                                </div>
                                                <div class="col-md-6 col-sm-12">
                                                    <div class="form-group">
                                                        <Input
                                                            type="date"
                                                            datePickerType="time"
                                                            formControlClasses="form-control"
                                                            id={`time`}
                                                            placeholder="Time"
                                                            control={props.control}
                                                            controller={props.controller}
                                                            selectName={`time`}
                                                            // timeFormat="hh:mm"
                                                            timeConstraints={{
                                                                hours: {
                                                                    min: 0,
                                                                    max: 23,
                                                                    step: 1, // Set the step to 0.5 to allow values like 0, 0.5, 1, 1.5, ..., 23
                                                                },
                                                                minutes: {
                                                                    step: 15
                                                                }
                                                            }}
                                                            label="Time"
                                                            formControlProps={props.register(`time`, {
                                                                required: "This field is required",
                                                                maxLength: 80,
                                                            })}
                                                            validationErrorMessage={
                                                                props.errors?.time?.message ?? ""
                                                            }
                                                            disabled={props?.isView}
                                                        />

                                                    </div>
                                                </div>
                                                <div class="col-md-6 col-sm-12">
                                                    <div class="form-group">

                                                        <Input
                                                            type="textarea"
                                                            id={`notes`}
                                                            name={`notes`}
                                                            formControlClasses="form-control"
                                                            placeholder="Type here"
                                                            required
                                                            label=" Add meeting Notes"
                                                            formControlProps={props.register(
                                                                `notes`,
                                                                {
                                                                    required: "This field is required",
                                                                }
                                                            )}
                                                            validationErrorMessage={
                                                                props.errors?.notes
                                                                    ?.message ?? ""
                                                            }
                                                            disabled={props?.isView}
                                                        />


                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {
                                    !props?.isEdit && !props?.isView ? <div class="action_button" >
                                        <span onClick={addTaskToList}>+ Add More</span>
                                    </div> : null
                                }

                                <div class="row">
                                    <div class="col-sm-12">
                                        <div class="submitcancel_btn">
                                            <button type="button" class="btn cst_btn btn-outline-secondary  cancel_btnnew" onClick={props?.resetForm}>
                                                Cancel
                                            </button>
                                            {!props.isView &&
                                                <button type="submit" className={isLoading ? "btn cst_btn btn_success btn-wait" : "btn cst_btn btn_success"}>
                                                    {isLoading ? "" : "Mark as Completed"}
                                                </button>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        </main>
    )
}

const PreviewEventFormHOC = withFormHoc(PreviewEventForm)
export default PreviewEventFormHOC
