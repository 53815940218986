import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../../../redux/actions/index';
import { reset } from 'redux-form';
import storage from '../../../../../utility/storage';
import { animateScroll as scroll} from 'react-scroll'
import PickingTicket from '../../../../component/Inventory/InventoryTicketsComponents/PickingTicketComponents/PickingTicket';


class PickingTicketContainer extends Component {

  constructor(props) {
    super(props);

    this.state = {
      currentAssosciatedUserEmail: '',
      accountErrorMessage: '',
      inventoryAccessRole: '',
      paramsTicketId: '',
    }
  }

  componentDidMount() {
    this.setState({inventoryAccessRole: storage.get('InventoryAccessRole')});
    if(this.props && this.props.match && this.props.match.params && this.props.match.params.ticketId) {
      this.setState({paramsTicketId: this.props.match.params.ticketId});
      setTimeout(() => {
        this.props.getAllPickingTickets({page: 1,limit: 10, id: this.state.paramsTicketId});
      }, 500);
    };
    scroll.scrollToTop();
  }

  goClicked = (values) => {
    let fulfillment_manager = ''
    let manager = ''
    let approver = ''
    let status = ''
    let id = ''
    let is_emergency = ''


    const page = 1
    const limit = this.props.pickingTicket_no_of_rows
  if(values.manager) {
      values.manager = values.manager.trim()
      manager = values.manager
  }
  if(values.fulfillment_manager) {
    values.fulfillment_manager = values.fulfillment_manager.trim()
    fulfillment_manager = values.fulfillment_manager
  }
  if(values.approver) {
    values.approver = values.approver.trim()
    approver = values.approver
  }  
  if(values.status) {
      status =  values.status 
    }  
    if(values.id) {
      id =  values.id 
    }  
    if(values.is_emergency) {
      is_emergency =  values.is_emergency 
    } 
    this.props.getAllPickingTickets({page, limit, manager, fulfillment_manager, approver, status, id, is_emergency}, values);
  };


  componentWillUnmount() {
    //this.props.resetObjects();
  }

  componentDidUpdate(prevProps, prevState) {
    const user = storage.get('user');
    if(user) {
    if(prevProps.user.selected_mode !== user.selected_mode) {
      this.props.pickingTicketPageClicked(0)
    }
  }
}

  resetClicked = () => {
    this.setState({paramsTicketId: ''});
    const size = this.props.pickingTicket_no_of_rows
    const page = 1;
    this.props.reset('searchPickupListForm');
    this.props.pickingTicketPageClicked(0)
    this.props.getAllPickingTickets({ size, page });
  }

  render() {

    return (
      <PickingTicket
        {...this.props}
        resetClicked={this.resetClicked}
        onSubmit={this.goClicked}
        inventoryAccessRole={this.state.inventoryAccessRole}
        paramsTicketId={this.state.paramsTicketId}
      />
    )
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.authReducer.user,
    pickingTicketList: state.InventoryReducer.pickingTicketList,
    openDeleteModel: state.adminOrSuperAdminReducer.openDeleteModel,
    isLoading: state.InventoryReducer.isLoading,
    pickingTicket_no_of_rows: state.InventoryReducer.pickingTicket_no_of_rows,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllPickingTickets: (params, searchValues) => dispatch(actions.getAllPickingTickets(params, searchValues)),
    handleDeleteClose: () => dispatch(actions.handleDeleteClose()),
    handleClickDeleteModel: (frontline) => dispatch(actions.handleClickDeleteModel(frontline)),
    deleteFrontline: (id) => dispatch(actions.deleteFrontline(id)),
    importFrontline: (body) => dispatch(actions.importFrontline(body)),
    reset: (searchPickupListForm) => dispatch(reset(searchPickupListForm)),
    searchFrontlineUser: (values) => dispatch(actions.searchFrontlineUser(values)),
    pickingTicketPageClicked: (page) => dispatch(actions.pickingTicketPageClicked(page)),
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(PickingTicketContainer);