import React, { Component, useState } from 'react';
import { connect } from 'react-redux';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import EmptyGraph from '../../component/Dashboards/emptyGraph';

const QuotasChart= (props) => {

    // const [achievedPercentage, setAchievedPercentage] = useState(null);

    const dataArray = [];
    const targetQuotas = props.quotasForQuotes.map(q => {
        return q[1].target_quota
    })

    const achievedQuotas = props.quotasForQuotes.map(q => {
        return q[1].achieved_quota
    })

    const achievedPercent = props.quotasForQuotes.map(q => {
        return q[1].achieved_quota_percentage
    })

    const targetPercent = [100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100]
    // const achievedQuotas = [50, 43, 56, 25, 0, 34,78,0,43,68,32,100]
    // const achievedPercent = [40.1, 28.5, 17.6, 50, 0, 70, 88.4,0,43,67,40.6,100]

    const target = {"name": "Target Quota (%)", "data": targetPercent};
    const achieved = {"name": "Achieved Quota (%)", "data": achievedPercent};

    dataArray.push(target, achieved);

    console.log('dataArray', dataArray)

    const type = props.quotasForQuotes.map(t => {
        return t[0]
    })
    console.log('charts', type)

    const options = {
        chart: {
          type: 'column'
        },
        title: {
          text: 'My chart'
        },
        xAxis: {
            min: 0,
            categories: type
        },
        plotOptions: {
            series: {
                zones: [{
                    value: 39,
                    color: 'red'
                }, {
                    value: 69,
                    color: 'orange'
                }, {
                    value: 100,
                    color: 'green'
                }],
            }
        },
        tooltip: {
            formatter: function () {
               return getVal(this.series, this.point, achievedQuotas, achievedPercent, targetQuotas);
            }
        },
        series: dataArray
      };
    return (
        <div className="tab-content" id="pills-tabContent">
            <div className="tab-pane fade show active" id="pills-graph-one" role="tabpanel" aria-labelledby="pills-graph-one">
                    <div className="row">
                        <div className="col">
                            <div className="tile chart-box-botL">
                            <figure class="highcharts-figure">
                            {targetQuotas.length !== 0 ? <HighchartsReact highcharts={Highcharts} options={options} /> : <EmptyGraph />}
                            </figure>
                            </div>
                        </div>
                    </div>
            </div>
        </div>
    )
    
}

const getVal = (val, point, achievedQuotas, achievedPercent, targetQuotas) => {
    const value = val.data[point.x].options.y;
    if (val.name.includes('Achieved')) {
        const amount = achievedQuotas[point.x];
        console.log('series', val);
        return '<b> Achieved Quota </b><br/> Acheived Quota: $ ' +
        amount + '<br/> Achieved Percentage: ' + value + '%';
    } else {
        const amount = targetQuotas[point.x];
        return '<b> Target Quota </b><br/> Target Quota: $ ' +
        amount;
    }
    
}
const mapStateToProps = (state) => {
    return {
        quotasForQuotes: state.adminOrSuperAdminReducer.quotasForQuotes
    }
}
export default connect(mapStateToProps)(QuotasChart);
