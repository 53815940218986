import React, { Component } from 'react';
import { routes } from '../../../../../utility/constants/constants';
import * as actions from '../../../../../redux/actions/index';
import { connect } from 'react-redux';
import { reset } from 'redux-form';
import { animateScroll as scroll} from 'react-scroll'
import storage from '../../../../../utility/storage';
import SelfAudit from '../../../../component/FormAuditModule/AuditsComponent/SelfAudit/SelfAuditPage/selfAudit';

var moment = require('moment');
const cloneDeep = require('clone-deep');

class SelfAuditContainer extends Component {

    constructor(props) {
        super(props);

        this.state = {
            
        }
    }

    componentDidMount() {
        scroll.scrollToTop();
    }

    componentDidUpdate(prevProps, prevState) {
        const user = storage.get('user');
        if(user) {
            if(prevProps.user.selected_mode !== user.selected_mode) {
                this.props.scheduledTaskListPageClicked(0)
            }
        }
    }

    resetClicked = () => {
        const page = 1
        const limit = this.props.performanceAuditList_no_of_rows
        this.props.reset('searchSelfAudit');
        this.props.selfAuditListPageClicked(0)
        this.props.getAllSelfAuditForm({page,limit}, {})

    }

    searchSelfAudit = (value) => {
        const values = cloneDeep(value)
        let from_date =  null;
        let to_date =  null;
    
        const page = 1
        const limit = this.props.selfAuditList_no_of_rows
        if (values.fromDate && values.toDate) {
            from_date = moment(values.fromDate).format('DD-MM-YYYY');
            to_date = moment(values.toDate).format('DD-MM-YYYY');
        }
     
        this.props.getAllSelfAuditForm({page,limit,from_date, to_date}, values);
    }
    
    newTicketClicked = () => {
        this.props.history.push(routes.NEW_SELF_AUDIT);
    }
         
    render() {
        return (
            <SelfAudit
            {...this.props}
            isLoading={this.props.isLoading}
            resetClicked={this.resetClicked}
            selfAuditList={this.props.selfAuditList ? this.props.selfAuditList : []}
            newTicketClicked={this.newTicketClicked}
            onSubmit={this.searchSelfAudit}
            />
        )
    }
}




const mapStateToProps = (state) => {
    return {
        user: state.authReducer.user,
        isLoading: state.auditsReducer.isLoading,
        selfAuditList: state.auditsReducer.selfAuditList,
        selfAuditList_no_of_rows:state.auditsReducer.selfAuditList_no_of_rows
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        reset: (searchInventoryItemsList) => dispatch(reset(searchInventoryItemsList)),  // requires form name
        getAllSelfAuditForm: (params , values)=> dispatch(actions.getAllSelfAuditTable(params,values)),
        restroomPorteringPageClicked: (params) => dispatch(actions.restroomPorteringPageClicked(params)),
        scheduledTaskListPageClicked:  (params) => dispatch(actions.scheduledTaskListPageClicked(params)),
        selfAuditListPageClicked: (page) => dispatch(actions.selfAuditListPageClicked(page)),
    }
}




export default connect(mapStateToProps, mapDispatchToProps)(SelfAuditContainer);
