import React, { useEffect, useState } from 'react'
import withFormHoc from '../../../../../../hoc/withReactHookForm/withReactHookForm'
import { Input } from '../../../../UI/InputElement/reactFormHookInputField';
import { useFieldArray } from 'react-hook-form'
import CleaningWorkersForm from './cleaningWorkersForm';
import AssetsFormSkeleton from '../../../../Inventory/Assets/NewAssetsForm/AssetsFormSkeleton';
import { useSelector } from 'react-redux';
import { EDIT, VIEW } from '../../../../../../utility/constants/constants';

function CheckListSupervisorForm(props) {
  const { fields, append, remove } = useFieldArray({ control: props.control, name: "items" })
  const { isUpdateSupervisor, customersList, jobList, managersList, isLoading, } = useSelector((state) => state.FormAuditModReducer)
  const current = new Date()


  const defaultTaskData = {
    worker_id: "",
    scheduled_date: "",
    completed: "",
    date_of_completed: "",
    comments: ""
  }

  const getCustomersList = (customer) => {
    if (customer && customer.length > 0) {
      return customer && customer.map((data) => {
        return {
          value: data.id,
          label: data.name,
        }
      })
    } else {
      return []
    }
  };

  const getJobList = (job) => {
    if (job && job.length > 0) {
      return job && job.map((data) => {
        return {
          value: data.id,
          label: data.name,
        }
      })
    } else {
      return []
    }
  };

  const getManagersList = (manager) => {
    if (managersList && managersList.length > 0) {
      return manager && manager.map((data) => {
        return {
          value: data.id,
          label: data.first_name + ' ' + data.last_name,
        }
      })
    } else {
      return []
    }
  };



  const [listOfCustomer, setCustomerList] = useState(getCustomersList(customersList))
  const [listOfJob, setJobList] = useState(getJobList(jobList))
  const [listOfManager, setManagerList] = useState(getManagersList(managersList))



  useEffect(() => {
    setCustomerList(getCustomersList(customersList))
  }, [customersList])

  useEffect(() => {
    setJobList(getJobList(jobList))
  }, [jobList])

  useEffect(() => {
    setManagerList(getManagersList(managersList))
  }, [managersList])

  if (isUpdateSupervisor) {
    return (
      <AssetsFormSkeleton />
    )
  }
  const addTaskToList = (e) => {
    e.preventDefault();
    append(defaultTaskData)
  }

  const removeFromTaskList = (index) => (e) => {
    e.preventDefault();
    remove(index)
  }


  return (
    <main class="app-content wraper_content chart_section">
      <section class="teammanage_formmain">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="team_manage_head">
                <h1 class="tm_top_head"> {props?.isEdit ? `${EDIT} Team Checklist Supervisor Summary` : props?.isView ? `${VIEW} Team Checklist Supervisor Summary` : "Team Checklist Supervisor Summary"}   </h1>
              </div>
            </div>
            <form class="col-lg-12" noValidate onSubmit={props.handleSubmit((data) => {
              props.onSubmit(data)
            })}>
              <div class="tm_form_wrapper">
                <div class="tm_form_wrap">
                  <div class="tm_form_innner">
                    <div class="form">
                      <div class="row">
                        <div class="col-md-6 col-sm-12">
                          <div class="form-group">
                            <Input
                              type="inputSelect"
                              labelClasses="form-label"
                              control={props.control}
                              controller={props.controller}
                              selectLabel="Organization"
                              placeholder={"Please Select"}
                              selectName="company_id"
                              id="company_id"
                              selectOptions={listOfCustomer}
                              required={props.register('company_id', {
                                required: "This field is required"
                              })}
                              validationErrorMessage={props.errors['company_id']?.message}
                              selectClasses="form-select"
                              disabled={props?.isView}
                            />
                            {/* <label for="disabledSelect" class="form-label">
                            Customer
                          </label>
                          <select id="disabledSelect" class="form-select">
                            <option>Please Select</option>
                            <option>1</option>
                            <option>2</option>
                            <option>3</option>
                            <option>4</option>
                          </select> */}
                            {/* <div class="dropdown_caret">
                            <img
                              src="/images/icons/dropdown_caret.svg"
                              alt="dropdown_caret"
                            />
                          </div> */}
                          </div>
                        </div>
                        <div class="col-md-6 col-sm-12">
                          <div class="form-group">
                            <Input
                              type="inputSelect"
                              labelClasses="form-label"
                              control={props.control}
                              controller={props.controller}
                              selectLabel="Job"
                              selectName={`account_id`}
                              id={`account_id`}
                              placeholder={"Please Select"}
                              selectOptions={listOfJob}
                              required={props.register(`account_id`, {
                                required: "This field is required"
                              })}
                              validationErrorMessage={props.errors?.account_id?.message}
                              selectClasses="form-select"
                              disabled={props?.isView}
                            />
                            {/* <label for="disabledSelect" class="form-label">
                            Job number
                          </label>
                          <select id="disabledSelect" class="form-select">
                            <option>Please Select</option>
                            <option>1</option>
                            <option>2</option>
                            <option>3</option>
                            <option>4</option>
                          </select> */}
                            {/* <div class="dropdown_caret">
                            <img
                              src="/images/icons/dropdown_caret.svg"
                              alt="dropdown_caret"
                            />
                          </div> */}
                          </div>
                        </div>
                        <div class="col-md-6 col-sm-12">
                          <div class="form-group">

                            <Input
                              type="inputSelect"
                              labelClasses="form-label"
                              control={props.control}
                              controller={props.controller}
                              selectLabel="Supervisor"
                              selectName={`supervisor_id`}
                              id={`supervisor_id`}
                              placeholder={"Please Select"}
                              selectOptions={listOfManager}
                              required={props.register(`supervisor_id`, {
                                required: "This field is required"
                              })}
                              validationErrorMessage={props.errors?.supervisor_id?.message}
                              selectClasses="form-select"
                              disabled={props?.isView}
                            />

                            {/* <label for="disabledSelect" class="form-label">
                            Supervisor
                          </label>
                          <select id="disabledSelect" class="form-select">
                            <option>Please Select</option>
                            <option>1</option>
                            <option>2</option>
                            <option>3</option>
                            <option>4</option>
                          </select> */}
                            {/* <div class="dropdown_caret">
                            <img
                              src="/images/icons/dropdown_caret.svg"
                              alt="dropdown_caret"
                            />
                          </div> */}
                          </div>
                        </div>
                        <div class="col-md-6 col-sm-12">
                          <div class="form-group">
                            <Input
                              type="inputSelect"
                              labelClasses="form-label"
                              control={props.control}
                              controller={props.controller}
                              selectLabel="Manager"
                              selectName={`manager_id`}
                              id={`manager_id`}
                              placeholder={"Please Select"}
                              selectOptions={listOfManager}
                              required={props.register(`manager_id`, {
                                required: "This field is required"
                              })}
                              validationErrorMessage={props.errors?.manager_id?.message}
                              selectClasses="form-select"
                              disabled={props?.isView}
                            />
                            {/* <div class="dropdown_caret">
                            <img
                              src="/images/icons/dropdown_caret.svg"
                              alt="dropdown_caret"
                            />
                          </div> */}
                            {/* <label for="disabledSelect" class="form-label">
                            Manager
                          </label>
                          <select id="disabledSelect" class="form-select">
                            <option>Please Select</option>
                            <option>1</option>
                            <option>2</option>
                            <option>3</option>
                            <option>4</option>
                          </select> */}

                          </div>
                        </div>
                        <div class="col-md-6 col-sm-12">
                          <div class="form-group">


                            <Input
                              type="date"
                              datePickerType="month-year"
                              formControlClasses="form-control"
                              id="month"
                              placeholder="Month and Year"
                              control={props.control}
                              controller={props.controller}
                              selectName={"month"}
                              dateFormat="MMMM - YYYY"
                              minDate={new Date().setDate(current.getDate() - 400)}
                              preventReadOnly={false}
                              label="Month and Year"
                              formControlProps={props.register('month', {
                                required: "This field is required",
                                // validate:handleValidateMonth,

                              })}
                              validationErrorMessage={props.errors?.month?.message ?? ""}
                              disabled={props?.isView}
                            />

                            {/* <label for="jobname" class="form-label">
                            Month
                          </label>
                          <input
                            type="date"
                            class="form-control"
                            id="jobname"
                            aria-describedby=""
                            placeholder="Location"
                          /> */}
                            {/* <div class="calendar_icon">
                            <img
                              src="/images/icons/calendar.svg"
                              alt="calendar"
                            />
                          </div> */}
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>


                {
                  fields?.map((field, index) => (
                    <CleaningWorkersForm setValue={props?.setValue} setFocus={props.setFocus} isView={props?.isView} key={field.id} controller={props.controller} field={field} control={props.control} index={index} register={props.register} errors={props.errors} removeFromTaskList={removeFromTaskList} getValues={props.getValues} />
                  ))
                }
                {
                  !props?.isEdit && !props?.isView ? <div class="action_button" >
                    <span onClick={addTaskToList}>+ Add More</span>
                  </div> : null
                }
                {/* <div class="tm_form_wrap">
                <div class="tm_form_innner">
                  <div class="form">
                    <div class="row">
                      <div class="col-md-6 col-sm-12">
                        <div class="form-group">
                          <label for="disabledSelect" class="form-label">
                            Cleaning worker
                          </label>
                          <select id="disabledSelect" class="form-select">
                            <option>Please Select</option>
                            <option>1</option>
                            <option>2</option>
                            <option>3</option>
                            <option>4</option>
                          </select>
                          <div class="dropdown_caret">
                            <img
                              src="images/icons/dropdown_caret.svg"
                              alt="dropdown_caret"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6 col-sm-12">
                        <div class="form-group">
                          <label for="jobname" class="form-label">
                            Scheduled date
                          </label>
                          <input
                            type="date"
                            class="form-control"
                            id="jobname"
                            aria-describedby=""
                            placeholder=""
                          />
                          <div class="calendar_icon">
                            <img
                              src="images/icons/calendar.svg"
                              alt="calendar"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6 col-sm-12">
                        <div class="form-group">
                          <label for="jobname" class="form-label">
                            Completed
                          </label>
                          <input
                            type="text"
                            class="form-control"
                            id="jobname"
                            aria-describedby=""
                            placeholder="Type here"
                          />
                        </div>
                      </div>
                      <div class="col-md-6 col-sm-12">
                        <div class="form-group">
                          <label for="jobname" class="form-label">
                            Date completed
                          </label>
                          <input
                            type="date"
                            class="form-control"
                            id="jobname"
                            aria-describedby=""
                            placeholder=""
                          />
                          <div class="calendar_icon">
                            <img
                              src="images/icons/calendar.svg"
                              alt="calendar"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12 col-sm-12">
                        <div class="form-group">
                          <label for="floatingTextarea">Comments</label>
                          <textarea
                            class="form-control"
                            placeholder="Type here"
                            id="floatingTextarea"
                          ></textarea>
                        </div>
                      </div>
                      <div class="col-md-12 col-sm-12">
                        <div class="action_button pt-3">
                          <a href="javascript:void(0)">Delete Selection</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}

                <div class="row">
                  <div class="col-sm-12">
                    <div class="submitcancel_btn">
                      <button type="button" class="btn cst_btn btn-outline-secondary  cancel_btnnew" onClick={props?.resetForm}>
                        Cancel
                      </button>
                      {
                        !props?.isView ? <button type="submit" className={isLoading ? "btn cst_btn btn_danger btn-wait" : "btn cst_btn btn_danger"}>
                          {isLoading ? ""  : props.isEdit ?  "Update"  : "Submit"}
                        </button> : null
                      }
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
    </main>
  )
}

const CheckListSupervisorFormHOC = withFormHoc(CheckListSupervisorForm)
export default CheckListSupervisorFormHOC
