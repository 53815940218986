import React from 'react';

const RejectQuote = (props) => {

    return (
        <div className="modal fade request_quote_modal reject_quot" id="rejectQuoteModal" tabindex="-1" role="dialog" aria-labelledby="rejectQuoteModalTitle" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                    <label for="exampleInputEmail1" className="cst-label">Reason for Rejection</label>
                        <button onClick={props.closeRejectModel} type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true"><img src="/images/icons/icn_close_gray.png" alt="Close Icon" /></span>
                        </button>
                    </div>
                    {/* <!-- <h5 className="modal-title" id="rejectQuoteModalLongTitle">Reason for Rejection</h5>--> */}
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="form-group">

                                    <textarea style={props.isRejectError ? { borderColor: 'rgb(240, 77, 83)', borderLeftWidth: '3px' } : null} name="reject_reason" onChange={props.onFieldChange} type="email" rows="4" className="form-control input_modifier textarea_modifier" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Type here"></textarea>
                                    <span style={{ color: '#DD2726', fontSize: '13px' }}>{props.isRejectError ? 'This field is required.' : null}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button disabled={props.isLoading} onChange={props.onChangeReason} type="submit" onClick={props.handelRejectClicked} className={props.isLoading ? "btn cst_btn btn_danger mr-2 btn-wait" : "btn cst_btn btn_danger"}>Submit</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RejectQuote;