import React, { useState } from 'react'
import EditCostSummaryModal from './modal/editModal'



function MonthlyTable(props) {
    
    const data = props.data
    
    return (
        <>
            <table>
                <thead>
                    <tr>
                        <th><h2 class="det_title" style={{ position: "relative" }}>{data.month} <span style={{ color: "white", position: "absolute", left: "1rem" }}>
                            <button
                                type="button"
                                class="btn theme_def_btn1"
                                data-bs-toggle="modal"
                                data-bs-target="#editCost"
                                onClick={()=>{
                                    props.setModalData(data)
                                    props.setIndex(props.index)
                                }}
                            >
                                <i class="fa fa-pencil" style={{ color: "white" }} aria-hidden="true" ></i>
                            </button>
                        </span></h2></th>
                        <th><h2 class="det_title">Cost</h2></th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <div class="month_detail_tab">
                                <div class="monthdet_field">
                                    <p>Total Monthly Cost</p>
                                </div>
                            </div>
                        </td>
                        <td>
                            <div class="cost_detail_tab">
                                <div class="monthdet_field">
                                    <p><span>$</span>{data.total_monthly_cost}</p>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div class="month_detail_tab">
                                <div class="monthdet_field">
                                    <p>Average Cost Per Day</p>
                                    <p>(<span>{data?.working_days}</span> days)</p>
                                </div>
                            </div>
                        </td>
                        <td>
                            <div class="cost_detail_tab">
                                <div class="monthdet_field">
                                    <p><span>$</span>{data?.average_cost_per_day}</p>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div class="month_detail_tab">
                                <div class="monthdet_field">
                                    <p>Monthly Cost Per FTE</p>
                                    <p>(<span>{data?.fte}</span> FTE)</p>
                                </div>
                            </div>
                        </td>
                        <td>
                            <div class="cost_detail_tab">
                                <div class="monthdet_field">
                                    <p><span>$</span>{data?.monthly_cost_per_fte}</p>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div class="month_detail_tab">
                                <div class="monthdet_field">
                                    <p>Monthly Cost Per SF</p>
                                    <p>(<span>{data?.square_footage}</span> SF)</p>
                                </div>
                            </div>
                        </td>
                        <td>
                            <div class="cost_detail_tab">
                                <div class="monthdet_field">
                                    <p><span>$</span>{data?.cost_per_sq_ft}</p>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>


        </>
    )
}

export default MonthlyTable
