import Swal from 'sweetalert2'
import '../sweetAlert2.css';

export const InfoAlert = (message) => {
    Swal.fire({
        title: message,
        type: 'info',
        timer: 2000,
        timerProgressBar: true,
        width: 700,
        padding: '1em',
        showConfirmButton: false,
        color: '#850037',
        background: '#fff',
        backdrop: `
          #000000a1
          left top
          no-repeat
        `
      })
}

export default InfoAlert;