import React, {useState} from "react";
import moment from "moment";
import { MagicSpinner, PushSpinner, SwishSpinner, WaveSpinner, ClapSpinner } from 'react-spinners-kit';
import FrontLineTask from "./FrontLineTask";
import Pagination from "react-js-pagination";
import Unavailable from "./UnavailableModel";
import {convertDateAndTimeTimezonelll} from "../../../utility/utility";

//require("bootstrap/less/bootstrap.less");
import "./pagination.css";
const ScanSearchResult = ({areaSearchResult, searchHistory, scheduleTask, user, history, token,...props}) => {
  const [currentSchedule, setCurrentSchedule] = useState({});
  const [loadMoreIndex, setLoadMoreIndex] = useState(1);
  return(
      <>
      {searchHistory ? <Unavailable {...props} currentSchedule={currentSchedule} setCurrentSchedule={setCurrentSchedule}></Unavailable> : ""}
      {
        !searchHistory || props.isLoading ?
            <div className="loader_btn_block">
              <ClapSpinner backColor="#89073c" size={40} frontColor="#89073c8a" />
            </div>
            :
            <main className={`app-content wraper_content history-main ${user ? "inspection_wraper" :  "mb-2 mt-1 pt-0 qr-app-scroll"}`}>
              <FrontLineTask token={token} history={history} user={user} areaSearchResult={areaSearchResult} searchHistory={searchHistory} scheduleTask={scheduleTask}/>
              <div className="appcontent_Inner">
                <div className="history_block previous-history-block">
                  <div className="add_content_block">
                    <h2 className="h3_title"><span className="title_block">Past Service</span> <span className="mob_border_view"><span className="cst_border"></span></span></h2>
                    <ol className="activity-feed">
                      {
                        searchHistory && (searchHistory.frontline_tasks && Object.keys(searchHistory.frontline_tasks) ? Object.keys(searchHistory.frontline_tasks).map((key, index) => {
                          return index < loadMoreIndex ? searchHistory.frontline_tasks[key]["frontline_tasks"].map((task, index) => {
                            return (<li key={index} className="feed-item timeline_item ">
                              {
                                (task.status === "not_available" && user && ["superadmin", "admin"].includes(user.role)) ?
                                    <span className="list_content">
                                {`on `}
                                <span className="text_md">
                                  {
                                   task.scanned_at ? convertDateAndTimeTimezonelll(task.scanned_at, searchHistory.timezone) : convertDateAndTimeTimezonelll(task.captured_time, searchHistory.timezone) 
                                  }<span className="sm_title text_danger mr-2 ">{(task.status === "not_available" && user && ["superadmin", "admin"].includes(user.role)) ? " Not Available" : "" }</span>
                                </span>
                                {
                                  (task.status === "not_available" && user && ["superadmin", "admin"].includes(user.role)) ?  <span data-toggle="modal" onClick={() => setCurrentSchedule(task)}
                                                                                                                   data-target="#setUnavailable" className="info_icon"><img src="/images/icons/info.png" alt="Image" /></span> : ""
                                }
                              </span> : <span className="list_content">
                                {task.status === "not_cleaned" ? ` not cleaned on ` : ` cleaned on `}
                                <span className="text_md">
                                  {
                                   task.scanned_at ? convertDateAndTimeTimezonelll(task.scanned_at, searchHistory.timezone) : convertDateAndTimeTimezonelll(task.captured_time, searchHistory.timezone) 
                                  }
                                </span>
                              </span> }
                            </li>)
                          }) : ""
                        }) : "No records found")
                      }
                    </ol>
    {searchHistory && searchHistory.frontline_tasks && Object.keys(searchHistory.frontline_tasks).length > loadMoreIndex ? <div className="text-center"><button className="btn btn_danger" onClick={() => setLoadMoreIndex(loadMoreIndex+1)}>Load More</button></div> : ""}
                  </div>
                </div>
                <div className="row">
                  <nav aria-label="Page navigation example" className="history-pagination col-12">

                    {
                      searchHistory &&  searchHistory.frontline_tasks && props.total_no_of_pages ? <Pagination
                          activePage={props.search.page}
                          className="float-right"
                          itemsCountPerPage={props.search.size}
                          totalItemsCount={props.search.size * props.total_no_of_pages}
                          pageRangeDisplayed={5}
                          onChange={props.handlePageChange}
                          /> : ""
                    }
                  </nav>

                </div>
                {(!user || (user && user.role !== "frontline")) && <div className="new_search mt-2 p-1">
                  <button type="button" className="w-100 btn btn_danger cst_btn" onClick={() => history.push(`/account/${token}`)}>New Search
                  </button>
                </div>
                }
              </div>

            </main>
      }
</>
  )
};

export default ScanSearchResult;