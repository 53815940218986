import React, { Component } from 'react';
import Positions from '../../component/Positions/positions';
import * as actions from '../../../redux/actions/index';
import { connect } from 'react-redux';
import { getUser } from '../../../utility/utility';
import { animateScroll as scroll} from 'react-scroll'

class PositionContainer extends Component {
    componentDidMount() {
            this.props.getPositions(
                {hide: false}
            );
        scroll.scrollToTop();
    }

    render() {

        return (
            <Positions
                positionsList={this.props.positionsList ? this.props.positionsList : []}
                openDeleteModel={this.props.openDeleteModel}
                handleDeleteClose={this.props.handleDeleteClose}
                editPositionClicked={this.props.editPositionClicked}
                deletePositon={this.props.deletePositon}
                deleteObject={this.props.deleteObject}
                handleClickDeleteModel={this.props.handleClickDeleteModel}
                isLoading={this.props.isLoading}
                openPopup={this.props.openPopup}
                getPositions= {this.props.getPositions}
                isPositions={this.props.isPositions}
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        positionsList: state.adminOrSuperAdminReducer.positionsList,
        isLoading: state.adminOrSuperAdminReducer.isLoading,
        deleteObject: state.adminOrSuperAdminReducer.deleteObject,
        openDeleteModel: state.adminOrSuperAdminReducer.openDeleteModel,
        isPositions: state.adminOrSuperAdminReducer.isPositions
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        handleDeleteClose: () => dispatch(actions.handleDeleteClose()),
        deletePositon: (id) => dispatch(actions.deletePositon(id)),
        getPositions: (data) => dispatch(actions.getPositions(data)),
        openPopup: () => dispatch(actions.openPopup()),
        editPositionClicked: (position) => dispatch(actions.editPositionClicked(position)),
        handleClickDeleteModel: (account) => dispatch(actions.handleClickDeleteModel(account)),

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PositionContainer);