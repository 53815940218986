import React, { useState } from 'react';
import { convertInventoryDateTimezone, makeFirstLetterCapital } from '../../../../../utility/utility'
import '../../../../../styles/custom.css';
import { Table } from 'antd';
import moment from 'moment';
import ImageLoader from 'react-imageloader';
import ModalImageGallery from '../../../ModalImageGallery/modalImageGallery';


let AuditLogs = (props) => {

    const [isImageGallery, setIsImageGallery] = useState(false);
    const [startIndex, setStartIndex] = useState(0);
    const [photoUrls, setPhotoUrls] = useState([]);

    const data = props.pickingTicketPreviewData && props.pickingTicketPreviewData.items && props.pickingTicketPreviewData.items.map((item) => {
        return {
            key: item.id,
            item: makeFirstLetterCapital(item.name),
            quantity: item.quantity,
            packaged : item.packaged,
            description : item.description,
        }
      });
      const columns = [
        {
          title: 'Items',
          dataIndex: 'item',
          key: 'item',
        },
        {
          title: 'Quantity',
          dataIndex: 'quantity',
          key: 'quantity',
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: 'Packaged',
            dataIndex: 'packaged',
            key: 'packaged',
        },
      ];

      const oldItemReturnData = props.pickingTicketPreviewData && props.pickingTicketPreviewData.returnItems && props.pickingTicketPreviewData.returnItems.map((item) => {
        return {
            key: item.id,
            item: makeFirstLetterCapital(item.product?.name),
            quantity: item.quantity,
        }
      });
      const oldItemReturnColumn = [
        {
          title: 'Items',
          dataIndex: 'item',
          key: 'item',
        },
        {
          title: 'Quantity',
          dataIndex: 'quantity',
          key: 'quantity',
        },
      ];

      const newItemReturnData = props.pickingTicketPreviewData && props.pickingTicketPreviewData.returnNewItems && props.pickingTicketPreviewData.returnNewItems.map((item) => {
        return {
            key: item.id,
            item: makeFirstLetterCapital(item.new_item),
            quantity: item.quantity,
            authorized: item.is_authorized === true ? 'Yes' : 'No',
        }
      });
      const newItemReturnColumn = [
        {
          title: 'Items',
          dataIndex: 'item',
          key: 'item',
        },
        {
          title: 'Quantity',
          dataIndex: 'quantity',
          key: 'quantity',
        },
        {
            title: 'Authorized?',
            dataIndex: 'authorized',
            key: 'authorized',
          },
      ];



    const readyForPickup = (date) =>{
        if(Array.isArray(date)){
            if(date.length){
            const filteredDate =   date.filter((item) => item.status === 'readyForPickup')
            if(filteredDate.length){
                const  updatedDate =  filteredDate[0]?.updated_at
                return updatedDate
            }else{
                return null
            }
            }else{
                return null
            }
            
        }else{
            return null
        }
      
    }

    const picked = (date) => {
        if(Array.isArray(date)){
            if(date.length){
            const filteredDate =   date.filter((item) => item.status === 'picked')
            if(filteredDate.length){
                const  updatedDate =  filteredDate[0]?.updated_at
                return updatedDate
            }else{
                return null
            }
        }
        else{
                return null
            }
            
        }else{
            return null
        }
      }
      const delivered = (date) =>{
        if(Array.isArray(date)){
            if(date.length){
            const filteredDate =   date.filter((item) => item.status === 'delivered' ||item.status === 'completed')
            if(filteredDate.length){
                const  updatedDate =  filteredDate[0]?.updated_at
                return updatedDate
            }else{
                return null
            }
            }else{
                return null
            }
            
        }else{
            return null
        }
      
    }

    const getApprover = (approver) => {
      if(approver){
        const approved = approver.filter((item) => item.status === 'approved')
        if(approved.length){
            return approved.map((item) => {
                return `${makeFirstLetterCapital(item.user.first_name)} ${makeFirstLetterCapital(item.user.last_name)}`
            })
        }else{
            return '-'
        }
      }
    }

    function preloader() {
        return <img style={{ width: '100%', height: '100%' }} src="/images/gif/giphy.gif" />;
    }

    const photoUrlClicked = (urls, i) => {
        setIsImageGallery(true);
        setStartIndex(i);
        setPhotoUrls(urls);
    }

    if (isImageGallery) {
        return <ModalImageGallery
            isImageGallery={isImageGallery}
            setIsImageGallery={setIsImageGallery}
            startIndex={startIndex}
            // base64={props.portfolioImages}
            imageUrls={photoUrls}
        />
    }

            return (  

                <>
                <main className="app-content wraper_content ticket_user">
                    <div className="appcontent_Inner">
                        <div className="tickets_user_profile_body">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-12 col-xl-9 order-2 order-xl-1">
                                        <div className="tickets_user_activity">
                                            <div className="tickets_top_header">
                                                <div className='d-flex viewIns_btn align-items-start justify-content-between'>
                                                <div className='border_bottom_audit_log'>
                                                <div className="preview_comment">
                                                   <h4>Picking Tickets Preview</h4>
                                                </div>
                                                </div>
                                                </div>
                                            </div>
                                            <div className="clearfix"></div>
                                            <div className="user_activity_conent">
                                            <div className='border_bottom_audit_log'>
                                            <div className='row'>
                                            <div className="col-md-6 col-xl-6 col-sm-12">
                                                <div className="misc_section">    
                                                    <h6 style={{lineBreak: 'anywhere'}}>Requested by : {props.pickingTicketPreviewData && props.pickingTicketPreviewData.manager ? `${makeFirstLetterCapital(props.pickingTicketPreviewData.manager.first_name)} ${makeFirstLetterCapital(props.pickingTicketPreviewData.manager.last_name)}` : ''}</h6>
                                                </div>
                                                </div>
                                                <div className="col-md-6 col-xl-6 col-sm-12 text-right">
                                                <div className="misc_section">    
                                                    <h6 style={{lineBreak: 'anywhere'}}>Requested on : {props.pickingTicketPreviewData && props.pickingTicketPreviewData.manager ? convertInventoryDateTimezone(props.pickingTicketPreviewData.created_at) : ''}</h6>
                                                </div>
                                                </div>
                                                </div>
                                                </div>
                                                <div className="misc_section">    
                                                    <h6 style={{lineBreak: 'anywhere', fontWeight : 'bold'}}>Requested Item : </h6>
                                                </div>
                                                <div className="form-group">
                                                  <Table columns={columns} dataSource={data} pagination={false} />
                                                </div>
                                                <div className="clearfix"></div>
                                                {props.pickingTicketPreviewData && props.pickingTicketPreviewData.returnItems && props.pickingTicketPreviewData.returnItems.length ? (
                                                  <>
                                                <div className="misc_section">    
                                                    <h6 style={{lineBreak: 'anywhere', fontWeight : 'bold'}}>Items returned in box : </h6>
                                                </div>
                                                <div className="form-group">
                                                  <Table columns={oldItemReturnColumn} dataSource={oldItemReturnData} pagination={false} />
                                                </div>
                                                </> 
                                                ) : null}
                                                 {props.pickingTicketPreviewData && props.pickingTicketPreviewData.returnNewItems && props.pickingTicketPreviewData.returnNewItems.length ? (
                                                    <>
                                                    <div className="misc_section">    
                                                    <h6 style={{lineBreak: 'anywhere', fontWeight : 'bold'}}> Returned new items : </h6>
                                                </div>
                                                <div className="form-group">
                                                  <Table columns={newItemReturnColumn} dataSource={newItemReturnData} pagination={false} />
                                                </div>
                                                </>
                                                 ) : null}
                                                
                                                </div>
                                                <div className="clearfix"></div>
                                                <div className="attachment_sec mt-5">
                                                    <div className="attachment_title">
                                                        <img className="attachment_icn" src="/images/icons/icn_attch.png" alt="" />
                                                        <h5 className="tickets_user_title1x">Attachments<span className="attachment_size"></span></h5>
                                                    </div>
                                                    {props.pickingTicketPreviewData && props.pickingTicketPreviewData.attachments ? 
                                                        <div className="attachments_files">
                                                        {props.pickingTicketPreviewData.attachments.map((t, i) => {

                                                            return (
                                                                <div className="attachments__file_box" onClick={() => photoUrlClicked(props.pickingTicketPreviewData.attachments, i)}>
                                                                    <ImageLoader
                                                                        style={{ width: '100%', height: '100%', cursor: 'pointer' }}
                                                                        src={t.photo_urls.small}
                                                                        wrapper={React.createFactory('div')}
                                                                        preloader={preloader}>
                                                                        Image load failed!
                                                                     </ImageLoader>
                                                                </div>
                                                            )
                                                        })}

                                                    </div> : null}
                                                </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12 col-xl-3 order-1 order-xl-2 border_user_profile">
                                        <div className="user_profile_details">
                                            <div className="user_profile_details_sec">
                                                <div className="priority_id">
                                                    <div className="row">
                                                        <div className="col-6 col-sm-4 col-md-4 col-lg-4 col-xl-6">
                                                            <div className="priority_box">
                                                                <h6><b>Box Number</b></h6>
                                                                <span>{props.pickingTicketPreviewData && props.pickingTicketPreviewData.delivery ? props.pickingTicketPreviewData.delivery.box?.number : '-'}</span>
                                                            </div>
                                                        </div>
                                                        <div className="col-6 col-sm-8  col-md-4 col-lg-4 col-xl-6">
                                                            <div className="user_id_box">
                                                                <h6><b>Ticket ID</b></h6>
                                                                <span>{props.pickingTicketPreviewData? props.pickingTicketPreviewData.id : '-'}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="assign_location sm_d_none">
                                                <div className="row">
                                                        <div className="col-xs-6 col-sm-6 col-md-6 col-lg-4 col-xl-6">
                                                            <div className="user_location_box">
                                                                <h6><b>Job</b></h6>
                                                            </div>
                                                        </div>
                                                        <div className="col-xs-6 col-sm-6 col-md-6 col-lg-4 col-xl-6 gutter">
                                                            <div className="user_location_box">
                                                                <h6>{props.pickingTicketPreviewData && props.pickingTicketPreviewData.account? makeFirstLetterCapital(props.pickingTicketPreviewData.account.name) : '-'}</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-xs-6 col-sm-6 col-md-6 col-lg-4 col-xl-6">
                                                            <div className="user_location_box">
                                                                <h6><b>Emergency Request</b></h6>
                                                            </div>
                                                        </div>
                                                        <div classNapackagedme="col-xs-6 col-sm-6 col-md-6 col-lg-4 col-xl-6 gutter">
                                                            <div className="user_location_box">
                                                                <h6>{props.pickingTicketPreviewData?.is_emergency? 'yes' : 'No'}</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                   {props.pickingTicketPreviewData && props.pickingTicketPreviewData.is_emergency ? (
                                                    <div className="row">
                                                        <div className="col-xs-6 col-sm-6 col-md-6 col-lg-4 col-xl-6">
                                                            <div className="user_location_box">
                                                                <h6 ><b>Approved by</b></h6>
                                                            </div>
                                                        </div>
                                                        <div className="col-xs-6 col-sm-6 col-md-6 col-lg-4 col-xl-6 gutter">
                                                            <div className="user_location_box">
                                                                <h6 className="d-flex">{props.pickingTicketPreviewData && props.pickingTicketPreviewData.status_log ? getApprover(props.pickingTicketPreviewData.status_log) : '-' }</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                   ) : null} 
                                                </div>

                                                <div className="assign_location mob_view">
                                                <div className="row">
                                                        <div className="col-xs-6 col-sm-6 col-md-6 col-lg-4 col-xl-6">
                                                            <div className="user_location_box">
                                                                <h6>Job</h6>
                                                            </div>
                                                        </div>
                                                        <div className="col-xs-6 col-sm-6 col-md-6 col-lg-4 col-xl-6">
                                                            <div className="user_location_box">
                                                            <h6>{props.pickingTicketPreviewData && props.pickingTicketPreviewData.account? makeFirstLetterCapital(props.pickingTicketPreviewData.account.name) : '-'}</h6>
                                                            </div>
                                                        </div>
                                                        {props.pickingTicketPreviewData && props.pickingTicketPreviewData.is_emergency ? (
                                                            <>
                                                        <div className="col-xs-6 col-sm-6 col-md-6 col-lg-4 col-xl-6 gutter">
                                                            <div className="user_location_box">
                                                            <h6 >Approved by</h6>
                                                            </div>
                                                        </div>
                                                        <div className="col-xs-6 col-sm-6 col-md-6 col-lg-4 col-xl-6 gutter">
                                                            <div className="user_location_box">
                                                            <h6 className="d-flex">{props.pickingTicketPreviewData && props.pickingTicketPreviewData.status_log ? getApprover(props.pickingTicketPreviewData.status_log) : '-' }
                                                                </h6>
                                                            </div>
                                                        </div>
                                                            </>
                                                        ) : null }
                                                        
                                                        <div className="col-xs-6 col-sm-6 col-md-6 col-lg-4 col-xl-6">
                                                            <div className="user_location_box">
                                                                <h6>Emergency request</h6>
                                                            </div>
                                                        </div>
                                                        <div className="col-xs-6 col-sm-6 col-md-6 col-lg-4 col-xl-6">
                                                            <div className="user_location_box">
                                                            <h6>{props.pickingTicketPreviewData?.is_emergency? 'yes' : 'No'}</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="created_lastActivity"> 
                                                <div className="row">
                                                        <div className="col-xs-6 col-sm-6 col-md-6 col-lg-4 col-xl-6">
                                                            <div className="created">
                                                                <h6><b>Pickup Date</b></h6>
                                                            </div>
                                                        </div>
                                                        <div className="col-xs-6 col-sm-6 col-md-6 col-lg-4 col-xl-6 gutter">
                                                            <div className="last_activity">
                                                                <h6>
                                                                    <label className="created_date">{props.pickingTicketPreviewData && props.pickingTicketPreviewData.delivery_at ? `${convertInventoryDateTimezone(props.pickingTicketPreviewData.delivery_at,"MM/DD/YYYY")}` : '–' }</label>
                                                                </h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                <div className="row">
                                                        <div className="col-xs-6 col-sm-6 col-md-6 col-lg-4 col-xl-6">
                                                            <div className="created">
                                                                <h6><b>Fulfillment Manager Location</b></h6>
                                                            </div>
                                                        </div>
                                                        <div className="col-xs-6 col-sm-6 col-md-6 col-lg-4 col-xl-6 gutter">
                                                            <div className="last_activity">
                                                                <h6>
                                                                    <label className="created_date">{props.pickingTicketPreviewData && props.pickingTicketPreviewData.location ? `${makeFirstLetterCapital(props.pickingTicketPreviewData.location.name)}` : '–' }</label>
                                                                </h6>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-xs-6 col-sm-6 col-md-6 col-lg-4 col-xl-6">
                                                            <div className="created">
                                                                <h6><b>Supervisor</b></h6>
                                                            </div>
                                                        </div>
                                                        <div className="col-xs-6 col-sm-6 col-md-6 col-lg-4 col-xl-6 gutter">
                                                            <div className="last_activity">
                                                                <h6>
                                                                    <label className="created_date">{props.pickingTicketPreviewData && props.pickingTicketPreviewData.supervisor ? `${makeFirstLetterCapital(props.pickingTicketPreviewData.supervisor.first_name)} ${makeFirstLetterCapital(props.pickingTicketPreviewData.supervisor.last_name)} ` : '-' }</label>
                                                                </h6>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-xs-6 col-sm-6 col-md-6 col-lg-4 col-xl-6">
                                                            <div className="created">
                                                                <h6><b>Building</b></h6>
                                                            </div>
                                                        </div>
                                                        <div className="col-xs-6 col-sm-6 col-md-6 col-lg-4 col-xl-6 gutter">
                                                            <div className="last_activity">
                                                                <h6>
                                                                    <label className="created_date">{props.pickingTicketPreviewData && props.pickingTicketPreviewData.building ? `${makeFirstLetterCapital(props.pickingTicketPreviewData.building)}` : '-' }</label>
                                                                </h6>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    
                                                    <div className="row">
                                                        <div className="col-xs-6 col-sm-6 col-md-6 col-lg-4 col-xl-6">
                                                            <div className="created">
                                                            <h6><b>Fulfillment Manager Scanned on</b></h6>
                                                            </div>
                                                        </div>
                                                        <div className="col-xs-6 col-sm-6 col-md-6 col-lg-4 col-xl-6 gutter">
                                                            <div className="last_activity">
                                                                <h6>
                                                                    <label className="created_date">{props.pickingTicketPreviewData && props.pickingTicketPreviewData.manager ? convertInventoryDateTimezone(readyForPickup(props.pickingTicketPreviewData.status_log)) : '-'}</label>
                                                                </h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-xs-6 col-sm-6 col-md-6 col-lg-4 col-xl-6">
                                                            <div className="created">
                                                            <h6><b>Supervisor Picked Done on</b></h6>
                                                            </div>
                                                        </div>
                                                        <div className="col-xs-6 col-sm-6 col-md-6 col-lg-4 col-xl-6 gutter">
                                                            <div className="last_activity">
                                                            <h6>
                                                                    <label className="created_date">{props.pickingTicketPreviewData && props.pickingTicketPreviewData.manager ? convertInventoryDateTimezone(picked(props.pickingTicketPreviewData.status_log)) : '-'}</label>
                                                                </h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-xs-6 col-sm-6 col-md-6 col-lg-4 col-xl-6">
                                                            <div className="created">
                                                            <h6><b>Supervisor Delivery Done on</b></h6>
                                                            </div>
                                                        </div>
                                                        <div className="col-xs-6 col-sm-6 col-md-6 col-lg-4 col-xl-6 gutter">
                                                            <div className="last_activity">
                                                            <h6>
                                                                    <label className="created_date">{props.pickingTicketPreviewData && props.pickingTicketPreviewData.manager ? convertInventoryDateTimezone(delivered(props.pickingTicketPreviewData.status_log)) : '-'}</label>
                                                                </h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-xs-6 col-sm-6 col-md-6 col-lg-4 col-xl-6">
                                                            <div className="created">
                                                                <h6><b>Created by</b></h6>
                                                            </div>
                                                        </div>
                                                        <div className="col-xs-6 col-sm-6 col-md-6 col-lg-4 col-xl-6 gutter">
                                                            <div className="last_activity">
                                                                <h6>
                                                                    <label className="created_date">{props.pickingTicketPreviewData && props.pickingTicketPreviewData.createdBy ? `${makeFirstLetterCapital(props.pickingTicketPreviewData.createdBy.first_name)} ${makeFirstLetterCapital(props.pickingTicketPreviewData.createdBy.last_name)}` : '-' }</label>
                                                                </h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main >
            </>
            )
        }

export default AuditLogs;