import React, { Component } from 'react';
import * as actions from '../../../../redux/actions/index';
import { connect } from 'react-redux';
import { reset } from 'redux-form';
import { animateScroll as scroll } from 'react-scroll'
import cloneDeep from 'clone-deep';
import PegassurePlaybookListLayout from '../../../component/PegassurePlaybookModuleComponent/PegassurePlaybookListComponent/PegassurePlaybookListLayout';
import storage from '../../../../utility/storage';

class PegassurePlayBookListContainer extends Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        scroll.scrollToTop();
        this.props.getAllPlaybookPdfLabels();
    }

    componentDidUpdate(prevProps, prevState) {
        const user = storage.get('user');
        if (user) {
            if (prevProps.user.selected_mode !== user.selected_mode) {
                this.props.pegassurePlaybookListPageClicked(0);
            }
        }
    }

    resetClicked = () => {
        const page = 1
        const limit = this.props.pegassurePlaybookList_no_of_rows
        this.props.reset('searchPlaybookPdfList');
        this.props.pegassurePlaybookListPageClicked(0)
        this.props.getPegassurePlaybookPdfList({ page, limit })
    }

    searchPlaybookFilterProperties = (value) => {

        const values = cloneDeep(value)

        const page = 1
        const limit = this.props.pegassurePlaybookList_no_of_rows
        let playbookId = null;
        let playbookTitle = null;

        if (values?.playbookId?.value?.length > 0) {
            playbookId = values.playbookId.value;
        }

        if (values?.playbookTitle?.value?.length > 0) {
            playbookTitle = values.playbookTitle.value;
        }

        let sendGridData = {
            page: page,
            limit: limit,
            id: playbookId,
            title: playbookTitle,
        }

        this.props.getPegassurePlaybookPdfList(sendGridData, values);

    }

    handlePlaybookFormSubmit = (values) => {

        if (this.props.isPlaybookPdfEdit) {
            // Update Flow
            const { setData } = cloneDeep(values);
            this.props.updatePegassurePlaybookPdf(setData, this.props.playbookId);
        } else {
            // Upload Flow
            const { setData, arrayBuffer, extension } = cloneDeep(values);
            this.props.getPegassurePlaybookPdfPresignUrl(setData, arrayBuffer, extension);
        }

    }

    deletePegassurePlaybookPdf = (id) => {
        this.props.deletePegassurePlaybookPdf(id);
    }

    handlePegassurePlaybook_openDeleteModal = (value) => {
        this.props.handlePegassurePlaybook_openDeleteModal(value);
    }

    handlePegassurePlaybook_closeDeleteModal = () => {
        this.props.handlePegassurePlaybook_closeDeleteModal();
    }

    handleUpdatePegassurePlaybookPdfClicked = (id) => {
        this.props.updatePegassurePlaybookPdfClicked(id);
        this.props.getSinglePlaybookPdfInfo(id);
    }

    render() {

        return (
            <PegassurePlaybookListLayout
                {...this.props}
                onSubmit={this.searchPlaybookFilterProperties}
                resetClicked={this.resetClicked}
                handlePlaybookFormSubmit={this.handlePlaybookFormSubmit}
                deletePegassurePlaybookPdf={this.deletePegassurePlaybookPdf}
                handlePegassurePlaybook_openDeleteModal={this.handlePegassurePlaybook_openDeleteModal}
                handlePegassurePlaybook_closeDeleteModal={this.handlePegassurePlaybook_closeDeleteModal}
                handleUpdatePegassurePlaybookPdfClicked={this.handleUpdatePegassurePlaybookPdfClicked}
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.authReducer.user,
        isLoading: state.PegassurePlaybookReducer.isLoading,
        isPegassurePlaybookList: state.PegassurePlaybookReducer.isPegassurePlaybookList,
        pegassurePlaybookList: state.PegassurePlaybookReducer.pegassurePlaybookList,
        pegassurePlaybookList_no_of_rows: state.PegassurePlaybookReducer.timeSheetList_no_of_rows,
        pegassurePlaybookList_curPage: state.PegassurePlaybookReducer.timeSheetList_curPage,
        pegassurePlaybookList_total_pages: state.PegassurePlaybookReducer.timeSheetList_total_pages,

        isPlaybookPdfEdit: state.PegassurePlaybookReducer.isPlaybookPdfEdit,
        playbookId: state.PegassurePlaybookReducer.playbookId,

        soloPlaybookPDFInfo: state.PegassurePlaybookReducer.soloPlaybookPDFInfo,

        openPegPlaybook_deleteModal: state.PegassurePlaybookReducer.openPegPlaybook_deleteModal,
        deletePegPlaybook_object: state.PegassurePlaybookReducer.deletePegPlaybook_object,

        playbookPdfLabelsList: state.PegassurePlaybookReducer.playbookPdfLabelsList
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        reset: (searchInventoryItemsList) => dispatch(reset(searchInventoryItemsList)),  // requires form name
        resetPegassurePlaybookEvents: () => dispatch(actions.resetPegassurePlaybookEvents()),

        pegassurePlaybookListPageClicked: (page) => dispatch(actions.pegassurePlaybookListPageClicked(page)),
        getPegassurePlaybookPdfList: (params, values) => dispatch(actions.getPegassurePlaybookPdfList(params, values)), // GET Playbook List Action for Pegassure Playbook PDF

        getPegassurePlaybookPdfPresignUrl: (pdfDataObject, arrayBuffer, extension) => dispatch(actions.getPegassurePlaybookPdfPresignUrl(pdfDataObject, arrayBuffer, extension)), // POST Create Playbook Pdf Action for Pegassure Playbook PDF

        updatePegassurePlaybookPdfClicked: (id) => dispatch(actions.updatePegassurePlaybookPdfClicked(id)),
        getSinglePlaybookPdfInfo: (id) => dispatch(actions.getSinglePlaybookPdfInfo(id)),
        updatePegassurePlaybookPdf: (body, id) => dispatch(actions.updatePegassurePlaybookPdf(body, id)),

        deletePegassurePlaybookPdf: (id) => dispatch(actions.deletePegassurePlaybookPdf(id)), // DELETE Playbook PDF Action for Pegassure Playbook PDF
        handlePegassurePlaybook_openDeleteModal: (value) => dispatch(actions.handlePegassurePlaybook_openDeleteModal(value)),
        handlePegassurePlaybook_closeDeleteModal: () => dispatch(actions.handlePegassurePlaybook_closeDeleteModal()),

        getAllPlaybookPdfLabels: () => dispatch(actions.getAllPlaybookPdfLabels()), // GET all PDF labels list for dropdown search box
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PegassurePlayBookListContainer);
