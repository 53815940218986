import React, { Component } from 'react';
import * as actions from '../../../redux/actions/index';
import { connect } from 'react-redux';
import { reset } from 'redux-form';
import { getUser } from '../../../utility/utility';
import { animateScroll as scroll } from 'react-scroll'
import ThankyouComponent from '../../component/PegAssureComponent/ThankyouComponent';
const cloneDeep = require('clone-deep');
class ThankyouContainer extends Component {
    componentDidMount() {
        scroll.scrollToTop();
        if(this.props.match.params.feedbackType === 'excellent') {
            const feedback = {};
            feedback['rating'] = 'excellent';
            this.props.submitFeedback({feedback: feedback}, this.props.match.params.areaId, this.props.match.params.feedbackType )
        }
    }


    render() {

        return (
            <ThankyouComponent
                {...this.props}
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {

    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        submitFeedback: (feedback, id) => dispatch(actions.submitFeedback(feedback, id)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ThankyouContainer);