import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../../redux/actions/index';
import { closeModel, getUser } from '../../../../utility/utility';
import { routes } from '../../../../utility/constants/constants';
import { reset } from 'redux-form';
import isEmpty from '../../../../utility/IsEmptyValidator';
import $ from "jquery";
import { validateAccountUsers } from '../../../../utility/validator/submit';
import AcountUsers from '../../../component/Accounts/AccountUsers/accountUsers';
import { animateScroll as scroll} from 'react-scroll'
import PayrollHoliday from '../../../component/Accounts/HourAndHoliday/payrollHolidayModal';
import moment from 'moment';


const cloneDeep = require('clone-deep');

class AccountUsersContainer extends Component {
    
    constructor(props) {
        super(props);

        this.state = {
            currentAssosciatedUserEmail: '',
            accountErrorMessage: '',
            selectedIds: [],
            selectNone: false,
            selectAll: false,
            selectedHolidaysIds: this.props.account && this.props.account.user ? this.props.account.user.holidays.map(h => h.id) : [],
            selectedInspectionFormsIds: this.props.account && this.props.account.user ? this.props.account.user.inspection_forms.map(i => i.id) : [],
            removedInspectionFormsIds : [],
            clearField : 0,
        }
        
    }

    


    componentDidMount() {
        // this.props.getAccountAreas({}, this.props.match.params.accountId);
        // if (!this.props.accountUsersList) {
        //     this.props.getAccountUsers(this.props.match.params.accountId);
        // }
        scroll.scrollToTop();
    }

    componentDidUpdate = (prevProps, prevState) => {
        if ((prevProps.isAreaActiveInActivePending === true && this.props.isAreaActiveInActivePending === false)) {
            //    || prevProps.isAccountAreas === true && this.props.isAccountAreas === false
            this.setState({
                selectedIds: []
            })
        }
        if (this.props.account !== prevProps.account) {
            if(this.props.account && this.props.account.user) {
                this.setState({
                    selectedHolidaysIds: this.props.account && this.props.account.user ? this.props.account.user.holidays.map(h => h.id) : [],
                    selectedInspectionFormsIds: this.props.account && this.props.account.inspection_forms ? this.props.account.inspection_forms.map(i => i.inspection_form.id) : [],
                })
            } else {
                this.setState({
                    selectedHolidaysIds: this.props.account && this.props.account.holidays ? this.props.account.holidays.map(h => h.id) : [],
                    selectedInspectionFormsIds: this.props.account && this.props.account.inspection_forms ? this.props.account.inspection_forms.map(i => i.inspection_form.id) : [],
                })
            }
            this.setState({
                selectedHolidaysIds: this.props.account && this.props.account.holidays ? this.props.account.holidays.map(h => h.id) : [],
                selectedInspectionFormsIds: this.props.account && this.props.account.inspection_forms ? this.props.account.inspection_forms.map(i => i.inspection_form.id) : [],
            })
        }
        if (prevProps.holidaysList !== this.props.holidaysList) {
            if(this.props.account && this.props.account.user) {
                if (this.props.account && this.props.account.user && this.props.account.user.holidays
                    && this.props.account.user.holidays.length === this.props.holidaysList.length) {
                    this.setState({
                        selectAll: true
                    })
                } else if (this.props.account && this.props.account.user && this.props.account.user.holidays
                    && this.props.account.user.holidays.length === 0) {
                    this.setState({
                        selectNone: true
                    })
                }
            } else {
                if (this.props.account && this.props.account.holidays
                    && this.props.account.holidays.length === this.props.holidaysList.length) {
                    this.setState({
                        selectAll: true
                    })
                } else if (this.props.account && this.props.account
                    && this.props.account.holidays.length === 0) {
                    this.setState({
                        selectNone: true
                    })
                }
            }
        }
        if (prevProps.account.inspection_forms !== this.props.account.inspection_forms) {
            if (this.props.account && this.props.account.inspection_forms
                && this.props.account.inspection_forms.length === this.props.inspectionFormsList.length) {
                this.setState({
                    selectAll: true
                })
            } else if (this.props.account && this.props.account.inspection_forms
                && this.props.account.inspection_forms.length === 0) {
                this.setState({
                    selectNone: true
                })
            }
        }
    }

    resetClicked = () => {
        this.props.reset('searchAccountUsersForm');
        
        if (this.props.history.location.search.includes("job_users")) {
            this.props.searchAccountUsers(this.props.match.params.accountId, {active_status: 'active'}, 10, 1);
        } else if (this.props.history.location.search.includes("job_area")) {
            this.props.getAccountAreas({page: 1, size: 10}, this.props.match.params.accountId);
        } else if (this.props.history.location.search.includes("job_holiday")) {
            this.props.getAccountHolidays({}, this.props.match.params.accountId);
        }else if (this.props.history.location.search.includes("job_logbook")) {
            this.props.logbookAreaPageClicked(0)
            this.props.getAllLogbookArea({},this.props.logbook_rows,1, [], this.props.match.params.accountId);
        }
        else if (this.props.history.location.search.includes("job_payroll_holiday")) {
            //this.props.logbookAreaPageClicked(0)
            this.props.getAccountPayrollHollyDay({}, this.props.match.params.accountId);
        }
    }


    handleClickDeleteModel = (user) => {

        this.props.handleClickDeleteModel(user);
    }

    handleDeleteClose = () => {
        this.props.handleDeleteClose();
    }

    deleteCompany = (id) => {
        this.props.deleteCompany(id);
    }

    editCompanyClicked = (account) => {
        this.props.editCompanyClicked(account);
    }

    // openAccountUserModal = () => {
    //     this.props.openAccountUserModal();
    //     this.props.getFilteredAccountUsers();
    // }

    associateAccoutUserClicked = () => {
        const user = getUser();
        this.props.associateAccoutUserClicked();
        this.props.getAllModeAccountUsers(this.props.match.params.accountId, user.mode);
    }

    associateAccoutHolidaysClicked = () => {
        this.props.associateAccoutHolidaysClicked();
        this.props.getHolidaysList();
    }

    associateAccountJobsClicked = () => {
        const user = getUser();
        this.props.associateAccountJobsClicked();
        this.props.getInspectionFormsForJob();
        this.props.getAssociatedInspections(this.props.match.params.accountId, user.mode);
    }

    addAccountUsers = (values, a, b) => {
        const current_account = Object.entries(values['current_account']);
        const selected_accounts = Object.entries(values['selected_accounts']);

        let account_ids = new Array();
        if (current_account[0][1]) {
            if (current_account[0][1]) {
                account_ids.push(current_account[0][0])
            }

            selected_accounts.map(sc => {
                if (sc[1]) {
                    return account_ids.push(sc[0])
                } else {

                    let index = account_ids.findIndex(function (c) {
                        return c === sc[0]
                    });

                    if (index > -1) {
                        account_ids.splice(index, 1);
                    }
                }
                //   
            })

            let error = validateAccountUsers(account_ids);

            if (!error) {
                this.setState({
                    accountErrorMessage: ''
                })
                const body = {
                    emails: values.email.map(e => e.label),
                    account_ids: account_ids
                }

                this.props.addAccountUsers(this.props.match.params.accountId, body);
            } else {
                this.setState({
                    accountErrorMessage: error
                })
            }
        } else {
            closeModel();
        }
    }

    submitAssociateAccountHolidays = () => {

        let holidayIds = {
            holiday_ids: this.state.selectedHolidaysIds
        }

        this.props.submitAssociateAccountHolidays(this.props.match.params.accountId, holidayIds);
    }

    submitAssociateAccountJobs = () => {

        let inspectionIds = {
            inspection_form_ids: this.state.selectedInspectionFormsIds,
            remove_inspection_form_ids : this.state.removedInspectionFormsIds
        }

        this.props.submitAssociateAccountJobs(this.props.match.params.accountId, inspectionIds);
    }

    getCurrentAccount = () => {
        if (isEmpty(this.props.account)) {
            this.props.getAccount(this.props.match.params.accountId);
        }
    }

    componentWillUnmount() {
        this.props.resetObjects();
    }

    handelSubmit = (values) => {
        if (this.props.history.location.search.includes("job_users")) {
            values.email = values.email.trim();
            values.active_status = values && values.active_status && values.active_status.value && values.active_status.value.trim();
            this.props.searchAccountUsers(this.props.match.params.accountId, values, 10, 1);
        } else if (this.props.history.location.search.includes("job_area")) {
            const area_name = values.area_name.trim();
            this.props.getAccountAreas({area_name, page: 1, size: 10}, this.props.match.params.accountId);
        } else if (this.props.history.location.search.includes("job_holiday")) {
            const holiday_name = values.holiday_name.trim();
            this.props.getAccountHolidays({holiday_name}, this.props.match.params.accountId);
        }
        else if (this.props.history.location.search.includes("job_logbook")) {
            if(values.logbook_name){
                values.logbook_name = values.logbook_name.trim();
            }
            this.props.logbookAreaPageClicked(0)
            this.props.getAllLogbookArea(values,this.props.logbook_rows,1,[], this.props.match.params.accountId);
        }
        else if (this.props.history.location.search.includes("job_payroll_holiday")) {
            
            if(values.holiday_name){
                values.holiday_name = values.holiday_name.trim();
            }
            //this.props.logbookAreaPageClicked(0)
            
            let from=null
            let to=null;
            if(values.fromDate) {
                from = moment(values.fromDate).format("YYYY-MM-DD")
              }  
              if(values.toDate) {
                to = moment(values.toDate).format("YYYY-MM-DD")
              }
              
            const params={
                holiday:values.holiday_name,
                from,
                to
            }
            this.props.getAccountPayrollHollyDay(params, this.props.match.params.accountId);
        }
    }

    newAccountUserClicked = () => {
        this.props.history.push(`/accounts/${this.props.match.params.accountId}/new_account_user`)
    }

    deleteAccountUser = (userId) => {
        this.props.deleteAccountUser(userId, this.props.match.params.accountId);
    }
    areaUserClicked = (id) => { 
        this.props.history.push(
            {pathname: `/accounts/${this.props.match.params.accountId}/account_users/areas/newarea`,
            state: { 
                from: this.props.location.pathname
            }
        })
    }
    getAccountSchedule = (id) => { 
        this.props.getFilteredAccountUsers(this.props.match.params.accountId);
    }

    areaLogBookUserClicked = (id) => {
        this.props.history.push(
            {pathname: `/accounts/${this.props.match.params.accountId}/account_users/areas/newlogbookarea`,
            state: { 
                from: this.props.location.pathname
            }
        })
    }

    checkboxClicked = (id) => {
        let selectedIds = cloneDeep(this.state.selectedIds);

        let searchIndex = this.state.selectedIds.findIndex(function (s) {
            return s === id
        });

        if (searchIndex === -1) {
            this.setState({
                selectedIds: [...selectedIds, id]
            })
        } else {
            selectedIds.splice(searchIndex, 1);
            this.setState({
                selectedIds: selectedIds
            })

        }
    }

    allAreaCheckboxClicked = () => {
        let allAreaIds = this.props.accountAreas.map(i => i.id);
        if (allAreaIds.length === this.state.selectedIds.length) {
            let selectedIds = allAreaIds;
            this.setState({
                selectedIds: []
            })
        } else {
            let selectedIds = allAreaIds;
            this.setState({
                selectedIds: selectedIds
            })
        }
    }

    allLogbookAreaCheckboxClicked = () => {
        let allAreaIds = this.props.logbookAreas.map(i => i.id);
        if (allAreaIds.length === this.state.selectedIds.length) {
            let selectedIds = allAreaIds;
            this.setState({
                selectedIds: []
            })
        } else {
            let selectedIds = allAreaIds;
            this.setState({
                selectedIds: selectedIds
            })
        }
    }

    setInActiveorInactiveAreas = (value, {active}, {inactive}) => {
        this.props.setInActiveorInactiveAreas(this.props.match.params.accountId, this.state.selectedIds, value);
        this.setState({active: active, inactive: inactive});
    }

    setCheckValue = (id) => {
        let selectedIds = cloneDeep(this.state.selectedIds);

        let searchIndex = this.state.selectedIds.findIndex(function (s) {
            return s === id
        });
         
        if (searchIndex > -1) {
            return true
        } else {
            return false
        }
    }

    holidaySelected = (id) => {
        let selectedIds = cloneDeep(this.state.selectedHolidaysIds);

        let searchIndex = this.state.selectedHolidaysIds.findIndex(function (s) {
            return s === id
        });

        if (searchIndex === -1) {
            if (this.state.selectedHolidaysIds.length === this.props.holidaysList.length - 1) {
                this.setState({
                    selectAll: true,
                })
            } else if (this.state.selectedHolidaysIds.length === 0) {
                this.setState({
                    selectNone: false,
                })
            }
            this.setState({
                selectedHolidaysIds: [...selectedIds, id]
            })
        } else {
            if (this.state.selectedHolidaysIds.length === this.props.holidaysList.length) {
                this.setState({
                    selectAll: false,
                })
            } else if (this.state.selectedHolidaysIds.length === 1) {
                this.setState({
                    selectNone: true
                })
            }
            selectedIds.splice(searchIndex, 1);
            this.setState({
                selectedHolidaysIds: selectedIds
            })

        }
    }

    setHolidaysCheckValue = (id) => {
        let searchIndex = this.state.selectedHolidaysIds.findIndex(function (s) {
            return s === id
        });
        if (searchIndex > -1) {
            return true
        } else {
            return false
        }
    }

    inspectionFormSelected = (id) => {
        let selectedIds = cloneDeep(this.state.selectedInspectionFormsIds);
        let removedIds = cloneDeep(this.state.removedInspectionFormsIds);

        let searchIndex = this.state.selectedInspectionFormsIds.findIndex(function (s) {
            return s === id
        });

        if (searchIndex === -1) {
            if (this.state.selectedInspectionFormsIds.length === this.props.inspectionFormsList.length - 1) {
                this.setState({
                    selectAll: true,
                })
            } else if (this.state.selectedInspectionFormsIds.length === 0) {
                this.setState({
                    selectNone: false,
                    selectAll: false
                })
            } else {
                this.setState({
                    selectAll: false,
                    selectNone: false,
                })
            }

            let removeIndex = this.state.removedInspectionFormsIds.findIndex(function (s) {
                return s === id
            });

            if(removeIndex > -1){
                removedIds.splice(removeIndex, 1);

             this.setState({
            removedInspectionFormsIds: removedIds
            })
            }

            this.setState({
                selectedInspectionFormsIds: [...selectedIds, id]
            })
        } else {
            if (this.state.selectedInspectionFormsIds.length === this.props.inspectionFormsList.length) {
                this.setState({
                    selectAll: false,
                })
            } else if (this.state.selectedInspectionFormsIds.length === 1) {
                this.setState({
                    selectNone: true
                })
            }
            selectedIds.splice(searchIndex, 1);
            this.setState({
                selectedInspectionFormsIds: selectedIds
            })
            this.setState({
                removedInspectionFormsIds: [...this.state.removedInspectionFormsIds, id]
            })

        }
    }

    setInsCheckValue = (id) => {
        let searchIndex = this.state.selectedInspectionFormsIds.findIndex(function (s) {
            return s === id
        });
        if (searchIndex > -1) {
            return true
        } else {
            return false
        }
    }

    selectAllClicked = (e) => {
        if (e.target.checked) {
            let selectedIds = []
            this.props.holidaysList.map(h => {
                return selectedIds.push(h.id)
            })
            this.setState({
                selectedHolidaysIds: selectedIds,
                selectAll: true,
                selectNone: false,
            })
        }
        else {
            this.setState({
                selectedHolidaysIds: [],
                selectAll: false
            })
        }
    }

    selectNoneClicked = (e) => {
        if (e.target.checked) {
            this.setState({
                selectedHolidaysIds: [],
                selectNone: true,
                selectAll: false
            })
        } else {
            this.setState({
                selectNone: false
            })
        }
    }

    selectAllInspectionFormsClicked = (e) => {
        if (e.target.checked) {
            let selectedIds = []
            this.props.inspectionFormsList.map(h => {
                return selectedIds.push(h.inspection_form.id)
            })
            this.setState({
                selectedInspectionFormsIds: selectedIds,
                selectAll: true,
                selectNone: false,
                removedInspectionFormsIds : []
            })
        }
        else {
            this.setState({
                selectedInspectionFormsIds: [],
                selectAll: false
            })
        }
    }

    selectNoneInspectionFormsClicked = (e) => {
        if (e.target.checked) {
            let removedIds = []
            this.props.inspectionFormsList.map(h => {
                return removedIds.push(h.inspection_form.id)
            })
            this.setState({
                selectedInspectionFormsIds: [],
                selectNone: true,
                selectAll: false,
                removedInspectionFormsIds : removedIds
            })
        } else {
            this.setState({
                selectNone: false
            })
        }
    }

    addNewHoliday = (values) => {
        const body={
            data:{}
        }
        const type=values.holiday.customer_pay =="true" ? "Customer" :"Pegasus"
        body["data"]["account_id"]=+this.props.match.params.accountId;
        body["data"]["date"]=moment(values.holiday.holiday_date).format("YYYY-MM-DD");
        body["data"]["description"]=values.holiday.holiday_reason;
        body["data"]["recurring"]=values.holiday.is_recurring;
        body["data"]["type"]=type;
        this.props.createAccountPayrollHollyDay(body,this.props)
    } 



    resetForm = (value) => {
        this.setState({clearField : value})
    }

    

   
    render() {
      
        return (
            <>
            <AcountUsers
                {...this.props}
                {...this.state}
                onSubmit={this.handelSubmit}
                setCheckValue={this.setCheckValue}
                setHolidaysCheckValue={this.setHolidaysCheckValue}
                setInsCheckValue={this.setInsCheckValue}
                accountId={this.props.match.params.accountId}
                getCurrentAccount={this.getCurrentAccount}
                addAccountUsers={this.addAccountUsers}
                accountErrorMessage={this.state.accountErrorMessage}
                areaUserClicked={this.areaUserClicked}
                areaLogBookUserClicked={this.areaLogBookUserClicked}
                newAccountUserClicked={this.newAccountUserClicked}
                resetClicked={this.resetClicked}
                accountUsersList={this.props.accountUsersList ? this.props.accountUsersList : []}
                // deleteCompany={this.deleteCompany}
                handleDeleteClose={this.handleDeleteClose}
                deleteAccountUser={this.deleteAccountUser}
                // accountUsers_rows={this.props.accountUsers_rows}
                isLoading={this.props.isLoading}
                popupLoading={this.props.popupLoading}
                onAccountUsersPageSizeChange={this.onAccountUsersPageSizeChange}
                account={this.props.account}
                // editAccountUsersClicked={this.editAccountUsersClicked}
                deleteObject={this.props.deleteObject}
                openDeleteModel={this.props.openDeleteModel}
                handleClickDeleteModel={this.props.handleClickDeleteModel}
                openAccountUserModal={this.props.openAccountUserModal}
                // openAssociateCompanyModal={this.props.openAssociateCompanyModal}
                associateAccoutHolidaysClicked={this.associateAccoutHolidaysClicked}
                associateAccountJobsClicked={this.associateAccountJobsClicked}
                associateAccoutUserClicked={this.associateAccoutUserClicked}
                closeAccountModalClicked={this.props.closeAccountModalClicked}
                filterAccountUsers={this.props.filterAccountUsers}
                checkboxClicked={this.checkboxClicked}
                setInActiveorInactiveAreas={this.setInActiveorInactiveAreas}
                holidaySelected={this.holidaySelected}
                selectAllClicked={this.selectAllClicked}
                selectNoneClicked={this.selectNoneClicked}
                inspectionFormSelected={this.inspectionFormSelected}
                selectAllInspectionFormsClicked={this.selectAllInspectionFormsClicked}
                selectNoneInspectionFormsClicked={this.selectNoneInspectionFormsClicked}
                submitAssociateAccountHolidays={this.submitAssociateAccountHolidays}
                submitAssociateAccountJobs={this.submitAssociateAccountJobs}
                getAccountHolidays={this.props.getAccountHolidays}
                getHolidaysList={this.props.getHolidaysList}
                allAreaCheckboxClicked={this.allAreaCheckboxClicked}
                allLogbookAreaCheckboxClicked={this.allLogbookAreaCheckboxClicked}
                makeAccountUserAsOwner={this.props.makeAccountUserAsOwner}
                getAccountSchedule={this.getAccountSchedule}
                scheduleWithAccountId={this.props.scheduleWithAccountId}
                resetForm={this.resetForm}
                getAccountPayrollHollyDay={this.props.getAccountPayrollHollyDay}
                getAccountHoursType={this.props.getAccountHoursType}
                
            // AssociateCompanyClicked={this.associateCompanyClicked}
            />
            
            <PayrollHoliday 
                {...this.props}
                onSubmit={this.addNewHoliday}
                changeField={this.resetForm}
                clearField={this.state.clearField}
                
                />  


            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.authReducer.user,
        logbook_rows: state.adminOrSuperAdminReducer.logbook_rows,
        isAccountAreaTab: state.adminOrSuperAdminReducer.isAccountAreaTab,
        isAccountAreas: state.adminOrSuperAdminReducer.isAccountAreas,
        isLogbookAreas: state.adminOrSuperAdminReducer.isLogbookAreas,
        logsAreaUrl: state.adminOrSuperAdminReducer.logsAreaUrl,
        accountAreas: state.adminOrSuperAdminReducer.accountAreas,
        logbookAreas: state.adminOrSuperAdminReducer.logbookAreas,
        allQRCodes: state.adminOrSuperAdminReducer.allQRCodes,
        account: state.adminOrSuperAdminReducer.account,
        accountUsersList: state.adminOrSuperAdminReducer.accountUsersList,
        isLoading: state.adminOrSuperAdminReducer.isLoading,
        isAccountUsers: state.adminOrSuperAdminReducer.isAccountUsers,
        popupLoading: state.adminOrSuperAdminReducer.popupLoading,
        deleteObject: state.adminOrSuperAdminReducer.deleteObject,
        openDeleteModel: state.adminOrSuperAdminReducer.openDeleteModel,
        account_users_row: state.adminOrSuperAdminReducer.account_users_row,
        openAccountUserModal: state.adminOrSuperAdminReducer.openAccountUserModal,
        openAccountHolidaysModal: state.adminOrSuperAdminReducer.openAccountHolidaysModal,
        openAccountJobsModal: state.adminOrSuperAdminReducer.openAccountJobsModal,
        inspectionFormsList: state.adminOrSuperAdminReducer.searchedInspectionFormsList ? state.adminOrSuperAdminReducer.searchedInspectionFormsList : state.adminOrSuperAdminReducer.inspectionFormsList,
        // openAssociateCompanyModal: state.adminOrSuperAdminReducer.openAssociateCompanyModal,
        isAreaActiveInActivePending: state.adminOrSuperAdminReducer.isAreaActiveInActivePending,
        filterAccountUsers: state.adminOrSuperAdminReducer.filterAccountUsers ? state.adminOrSuperAdminReducer.filterAccountUsers : [],
        holidaysList: state.adminOrSuperAdminReducer.holidaysList,
        isHolidaysLoading: state.adminOrSuperAdminReducer.isHolidaysLoading,
        accountHolidaysList: state.adminOrSuperAdminReducer.accountHolidaysList,
        accountHolidaysLoading: state.adminOrSuperAdminReducer.accountHolidaysLoading,
        scheduleWithAccountId: state.adminOrSuperAdminReducer.scheduleWithAccountId,
        areaImportErrorLogs: state.adminOrSuperAdminReducer.areaImportErrorLogs,
        logbookImportErrorLogs: state.adminOrSuperAdminReducer.logbookImportErrorLogs,
        isErrorAvailable: state.adminOrSuperAdminReducer.isErrorAvailable,
        /////
        payrollHollidayList:state.TimeTrackerModReducer.payrollHollidayList,
        isLoadingHollyday:state.TimeTrackerModReducer.isLoadingHollyday,
        isLoadingList:state.TimeTrackerModReducer.isLoadingList,
        accountHoursType:state.TimeTrackerModReducer.accountHoursType
        // accountData: state.adminOrSuperAdminReducer.accountData
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getHolidaysList: () => dispatch(actions.getHolidaysList()),
        logbookAreaPageClicked: (page) => dispatch(actions.logbookAreaPageClicked(page)),
        getAccountAreas: (params, id) => dispatch(actions.getAccountAreas({ params, id })),
        getAccountPayrollHollyDay:(params, id) => dispatch(actions.getAccountPayrollHollyDay({ ...params,accountId:id,active_status:"active" })),
        getAllLogbookArea: (SearchValue, size, page, sorted, id) => dispatch(actions.getAllLogbookArea(SearchValue, size, page, sorted, id)),
        importArea: (body, id) => dispatch(actions.importArea({ body, id })),
        importCheckArea: (body, id) => dispatch(actions.importCheckArea({ body, id })),
        addAccountUsers: (id, body) => dispatch(actions.addAccountUsers(id, body)),
        deleteArea: (areaId, id) => dispatch(actions.deleteArea({ areaId, id })),
        deleteLogbookArea: (accountId, logbookId) => dispatch(actions.deleteLogbookArea({ accountId, logbookId })),
        getAccountUsers: (accountId, values, user_no_of_rows, page, sorted, filtered) => dispatch(actions.getAccountUsersInfoPage(accountId, values, user_no_of_rows, page, sorted, filtered)),
        associateAccoutUserClicked: () => dispatch(actions.associateAccoutUserClicked()),
        associateAccoutHolidaysClicked: () => dispatch(actions.associateAccoutHolidaysClicked()),
        // associateCompanyModalClicked: () => dispatch(actions.associateCompanyModalClicked()),
        closeAccountModalClicked: () => dispatch(actions.closeAccountModalClicked()),
        associateAccountJobsClicked: () => dispatch(actions.associateAccountJobsClicked()),
        getInspectionForms: () => dispatch(actions.getInspectionForms()),
        getInspectionFormsForJob: () => dispatch(actions.getInspectionFormsForJob()),
        getAllModeAccountUsers: (accountId, mode) => dispatch(actions.getAllModeAccountUsers(accountId, mode)),
        getAccount: (id) => dispatch(actions.getEditAccount(id)),
        resetObjects: () => dispatch(actions.resetObjects()),
        deleteAccountUser: (userId, accountId) => dispatch(actions.deleteAccountUser(userId, accountId)),
        // associateCompanyClicked: () => dispatch(actions.associateCompanyClicked()),
        handleDeleteClose: () => dispatch(actions.handleDeleteClose()),
        handleClickDeleteModel: (user) => dispatch(actions.handleClickDeleteModel(user)),
        // searchUserClicked: (quote) => dispatch(actions.searchUserClicked(quote)),
        // onCompanyPageSizeChange: (rows) => dispatch(actions.onCompanyPageSizeChange(rows)),
        // editCompanyUsersClicked: (user) => dispatch(actions.editCompanyUsersClicked(user)),
        // getCompanyUsers: () => dispatch(actions.getCompanyUsers()),
        searchAccountUsers: (accountId, values, rows, page) => dispatch(actions.searchAccountUsers(accountId, values, rows, page)),
        reset: (searchAccountUsersForm) => dispatch(reset(searchAccountUsersForm)),  // requires form name
        setInActiveorInactiveAreas: (accountid, ids, value) => dispatch(actions.setInActiveorInactiveAreas(accountid, ids, value)),
        submitAssociateAccountHolidays: (accountId, holidaysIds) => dispatch(actions.submitAssociateAccountHolidays(accountId, holidaysIds)),
        submitAssociateAccountJobs: (accountId, inspectionIds) => dispatch(actions.submitAssociateAccountJobs(accountId, inspectionIds)),
        // newUserClicked: () => dispatch(actions.newUserClicked()),
        getAccountHolidays: (params, accountId) => dispatch(actions.getAccountHolidays({params, accountId})),
        getAllQRCodes: (id) => dispatch(actions.getAllQRCodes(id)),
        makeAccountUserAsOwner: (accountId, userId) => dispatch(actions.makeAccountUserAsOwner(accountId, userId)),
        getAssociatedInspections: (accountId, mode) => dispatch(actions.getAssociatedInspections(accountId, mode)),
        getAccountSchedule: (id) => dispatch(actions.getAccountSchedule(id)),
        importCheckLogbookArea: (body, id) => dispatch(actions.importCheckLogbookArea({ body, id })),
        importLogbookArea: (body, id) => dispatch(actions.importLogbookArea({ body, id })),
        createAccountPayrollHollyDay:(body,props)=>dispatch(actions.createAccountPayrollHollyDay(body,props)),
        deleteAccountPayrollHollyDay:(id,props)=>dispatch(actions.deleteAccountPayrollHollyDay(id,props)),
        importHoursTypeCheck: (body, id) => dispatch(actions.importHoursTypeCheck({ body, id })),
        getAccountHoursType:(params, id) => dispatch(actions.getAccountHoursType({ ...params,accountId:id })),
        deleteAccountHoursType:(id,props)=>dispatch(actions.deleteAccountHoursType(id,props)),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(AccountUsersContainer);