import React from 'react';
import MyProfile from '../../container/MyProfileContainer/MyProfileContainer';
import ChangePassword from '../../container/ChangePasswordContainer/ChangePasswordContainer';
import InventoryAccessContainer from '../../container/InventoryContainer/InventoryAccessContainer/InventoryAccessConatiner';
import storage from '../../../utility/storage';
import { userRoles } from '../../../utility/constants/constants';

const UpdateProfile = (props) => {
    
    const user = storage.get('user');

    return (
        <div id="editProfile" className="modal fade add_account_modal edit_profile_modal profile_changes_pic">
            <div className="modal-dialog modal-lg modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <ul className="nav nav-tabs cst_nav_tab">
                            <li data-test="edit_profile_tab" className="nav-item"><a className={props.isProfileActive ? "nav-link active" : "nav-link"} onClick={props.profileClicked} data-toggle="tab" href="#profile">My Profile</a></li>
                            <li data-test="change_pwd_tab" className="nav-item"><a className={props.isChangePasswordActive ? "nav-link active" : "nav-link"} onClick={props.changePasswordClicked} data-toggle="tab" href="#password">Change Password</a></li>
                            {
                                user.role == userRoles.CLIENT || user.role == userRoles.FRONTLINE ||
                                user.inventory_access && !(user.inventory_access.length > 0) ? null : (
                                    <li data-test="choose_inventoryAccess_role" className="nav-item">
                                        <a className={props.isInventoryAccessActive ? "nav-link active" : "nav-link"} onClick={props.inventoryAccessClicked} data-toggle="tab" href="#inventoryAccess">
                                            Inventory Access
                                        </a>
                                    </li>
                                )
                            }
                            <button type="button" className="close close_icn_pic" onClick={props.profileClicked} data-dismiss="modal"><img src="/images/icons/icn_close_gray.png" alt="Close Icon" /></button>
                        </ul>

                    </div>

                    <div className="modal-body">
                        <div className="tab-content">
                            {props.isProfileActive && (!props.isChangePasswordActive) && (!props.isInventoryAccessActive) ? <MyProfile closeProfileModel={props.closeProfileModel} positionsList={props.positionsList}/> :
                            props.isChangePasswordActive && (!props.isProfileActive) && (!props.isInventoryAccessActive) ? <ChangePassword closeProfileModel={props.closeProfileModel} /> :
                            <InventoryAccessContainer closeProfileModel={props.closeProfileModel} triggerInitial ={props.profileClicked}/>
                            }
                        </div>
                    </div>
                    {/* <div className="clearfix"></div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn_danger cst_btn">Save</button>
                        <button type="button" className="btn btn-outline-secondary cst_btn" data-dismiss="modal">Cancel</button>
                    </div> */}
                </div>
            </div>
        </div >
    )
}

export default UpdateProfile;