import React, { Component } from 'react';
import Regions from '../../component/Regions/regions';
import * as actions from '../../../redux/actions/index';
import { connect } from 'react-redux';
import { getUser } from '../../../utility/utility';
import { animateScroll as scroll} from 'react-scroll'

class RegionsContainer extends Component {

    componentDidMount() {
            this.props.getAllRegions({
                hide: false,
            });
        scroll.scrollToTop();
    }

    render() {

        return (
            <Regions
                isLoading={this.props.isLoading}
                regionList={this.props.regionList ? this.props.regionList : []}
                openPopup={this.props.openPopup}
                openDeleteModel={this.props.openDeleteModel}
                handleDeleteClose={this.props.handleDeleteClose}
                deleteRegion={this.props.deleteRegion}
                editRegionClicked={this.props.editRegionClicked}
                deleteObject={this.props.deleteObject}
                handleClickDeleteModel={this.props.handleClickDeleteModel}
                getAllRegions={this.props.getAllRegions}
                isRegions={this.props.isRegions}
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        regionList: state.adminOrSuperAdminReducer.regionList,
        isLoading: state.adminOrSuperAdminReducer.isLoading,
        deleteObject: state.adminOrSuperAdminReducer.deleteObject,
        openDeleteModel: state.adminOrSuperAdminReducer.openDeleteModel,
        isRegions: state.adminOrSuperAdminReducer.isRegions
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getAllRegions: (data) => dispatch(actions.getAllRegions(data)),
        openPopup: () => dispatch(actions.openPopup()),
        editRegionClicked: (region) => dispatch(actions.editRegionClicked(region)),
        handleDeleteClose: () => dispatch(actions.handleDeleteClose()),
        deleteRegion: (id) => dispatch(actions.deleteRegion(id)),
        handleClickDeleteModel: (account) => dispatch(actions.handleClickDeleteModel(account)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RegionsContainer);