import React, { useEffect, useRef, useState } from "react";
import withFormHoc from "../../../../../../hoc/withReactHookForm/withReactHookForm";
import { Input } from "../../../../UI/InputElement/reactFormHookInputField";
import { useFieldArray } from "react-hook-form";
import RestRoomSection from "./RestRoomSection";
import { SHIFTS } from "../../../../../../utility/constants/constants";
import { useSelector } from "react-redux";
import AssetsFormSkeleton from "../../../../Inventory/Assets/NewAssetsForm/AssetsFormSkeleton";
import { error } from "jquery";

function RestRoomPage(props) {
  const { fields, append, remove } = useFieldArray({
    control: props?.control,
    name: "section",
  });

  const { managersList,usersList } = useSelector((state) => state.FormAuditModReducer)
  const {isUpdateRestroom,isLoading}=useSelector((state)=>state.LogBookReducer)
  const trackError=useRef(undefined)
  const defaultData = {
    in_time: "",
        out_time: "",
        restroom_condition: "",
        next_restroom: "",
        initial: "",
        lineItems:[
          {
            "name": "Removed debris",
            "sort": 1,
            "is_checked": false
          },
          {
            "name": "Mopped residual liquids",
            "sort": 2,
            "is_checked": false
          },
          {
            "name": "Mopped floor (show)",
            "sort": 3,
            "is_checked": false
          },
          {
            "name": "Cleaned sinks / counters",
            "sort": 4,
            "is_checked": false
          },
          {
            "name": "Wiped urinals",
            "sort": 5,
            "is_checked": false
          },
          {
            "name": "Wiped toilets",
            "sort": 6,
            "is_checked": false
          },
          {
            "name": "Stocked-seat covers",
            "sort": 7,
            "is_checked": false
          },
          {
            "name": "Stocked-soap",
            "sort": 8,
            "is_checked": false
          }
          ,
          {
            "name": "Stocked-toilets tissue",
            "sort": 9,
            "is_checked": false
          },
          {
            "name": "Stocked-towels",
            "sort": 10,
            "is_checked": false
          },
  
          {
            "name": "Wiped Stainless Steel",
            "sort": 11,
            "is_checked": false
          },
          {
            "name": "Cleaned baby chan. table",
            "sort": 12,
            "is_checked": false
          },
          {
            "name": "Cleaned mirror(s)",
            "sort": 13,
            "is_checked": false
          },
          {
            "name": "Deodorized",
            "sort": 14,
            "is_checked": false
          },
          {
            "name": "Pushed down trash",
            "sort": 15,
            "is_checked": false
          },
          {
            "name": "Dumped trash",
            "sort": 16,
            "is_checked": false
          }
        ]
  }
   
  
  const {
    fields: ShiftFields,
  } = useFieldArray({
    control: props.control,
    name: "shifts",
  });

  const addTaskToList = (e) => {
    e.preventDefault();
    append(defaultData);
  };
  const removeFromTaskList = (index) => (e) => {
    e.preventDefault();
    
    remove(index);
  };

  const ValidateHours=(value)=>{
     if(+value >=1 && +value <=24 ){
      return true
     }
     return "Hours must be between one to twenty four"
  }

  

  const findErrorKeys=(errors)=>{
    const Keys= Object.keys(errors)
    let field=null
    
    if(Array.isArray(errors[Keys[0]])){
      const index=errors[Keys[0]].findIndex((item)=> item !== undefined)
 
      let obj=errors[Keys[0]][index]

      field=`${Keys[0]}[${index}].${Object.keys(obj)[0]}`

      //scroll to that pae
      
      
    }
    else{
       field = Keys[0]
    }
    return field
  }
  

  const getManagersList = (manager) => {
    if (manager && manager.length > 0) {
      return manager && manager.map((data) => {
        return {
          value: data.id,
          label: data.first_name + ' ' + data.last_name,
        }
      })
    } else {
      return []
    }
  };


  const getUsersList = (user) => {
    if (user && user.length > 0) {
      return user && user.map((data) => {
        return {
          value: data.id,
          label: data.first_name + ' ' + data.last_name,
        }
      })
    } else {
      return []
    }
  };




  const [listOfSupervisor, setSupervisorList] = useState(getManagersList(managersList))
  const [listOfUsers,setListOfUsers]=useState(getUsersList(usersList))
  useEffect(() => {

    setSupervisorList(getManagersList(managersList))
  }, [managersList])

  useEffect(()=>{
    setListOfUsers(getUsersList(usersList))
  },[usersList])


  if (isUpdateRestroom) {

    return (
      <AssetsFormSkeleton />
    )
  }

  return (
    <main class="app-content wraper_content chart_section">
    <section class="teammanage_formmain">
      <form
        class="container"
        noValidate
        onSubmit={props.handleSubmit((data) => {
          props.onSubmit(data)
        }, (errors) => {
          
          if(errors && Object.keys(errors).length
          ){
            
             trackError.current=true
             
             const field=  findErrorKeys(errors)
             
             document.getElementsByName(field)[0].scrollIntoView({ behavior: 'smooth',block: 'center', inline: 'center'  })

                // if(field){
                //   props.setFocus(field)
                // }
              
          }
        })}
      >
        <div class="row">
          <div class="col-lg-12">
            <div class="team_manage_head">
              <h1 class="tm_top_head">
                Restroom Portering Frequency Log: Men / Women
              </h1>
            </div>
          </div>
          <div class="col-lg-12">
            <div class="tm_form_wrapper">
              <div class="tm_form_wrap">
                <div class="tm_form_innner">
                  <div class="form">
                    <div class="row">
                      <div class="col-md-6 col-sm-12">
                        <div class="form-group mb-0">
                          <Input
                            type="text"
                            id={`restroom`}
                            name={`restroom`}
                            formControlClasses="form-control"
                            placeholder="Type here"
                            required
                            label="Restroom"
                            formControlProps={props.register(`restroom`, {
                               required: "This field is required",
                              
                              maxLength: 80,
                            })}
                            validationErrorMessage={
                              props.errors?.restroom?.message ?? ""
                            }
                            disabled={props?.isView}
                          />
                          {/* <input
                            type="text"
                            class="form-control"
                            id="jobname"
                            aria-describedby=""
                            placeholder="Type here"
                          /> */}
                        </div>
                      </div>
                      <div class="col-md-6 col-sm-12">
                        <div class="form-group">
                          <Input
                            type="date"
                            datePickerType="date"
                            formControlClasses="form-control"
                            id="day_date"
                            placeholder="MM/DD/YYYY"
                            control={props.control}
                            controller={props.controller}
                            selectName={"day_date"}
                            
                            dateFormat="EEEE, MM/dd/yyyy"

                            label="Day and Date"
                            formControlProps={props.register("day_date", {
                              required: "This field is required",
                              maxLength: 80,
                            })}
                            validationErrorMessage={
                              props.errors?.day_date?.message ?? ""
                            }
                            disabled={props?.isView}
                          />
                        </div>
                      </div>
                      <div class="col-md-6 col-sm-12">
                        <div class="form-group">
                          <Input
                            type="number"
                            // datePickerType="time"
                            formControlClasses="form-control"
                            id="shift_hours"
                            placeholder="Shift hours"
                            control={props.control}
                            controller={props.controller}
                            selectName={"shift_hours"}
                            // timeFormat="MM/dd/yyyy"
                            // minDate={new Date().setDate(current.getDate() - 400)}
                            // preventReadOnly={false}
                            label="Shift hours"
                            formControlProps={props.register("shift_hours", {
                              required: "This field is required",
                              validate:ValidateHours
                            })}
                            validationErrorMessage={
                              props.errors?.shift_hours?.message ?? ""
                            }
                            //   timeConstraints={{
                            //     hours: {
                            //       min: 0,
                            //       max: 23,
                            //       step: 1, 
                            //     },
                            //     minutes: {
                            //       min: 0,
                            //       max: 59,
                            //       step: 30,
                            //     },
                            //   }
                            // }
                            disabled={props?.isView}
                          />

                          {/* <div class="calendar_icon">
                            <img
                              src="/images/icons/reminder.svg"
                              alt="calendar"
                            />
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* first Form */}

              {fields.map((field, index) => (
                <RestRoomSection
                  field={field}
                  index={index}
                  key={field.id}
                  control={props.control}
                  controller={props.controller}
                  register={props.register}
                  errors={props.errors}
                  removeFromTaskList={removeFromTaskList}
                  isView={props.isView}
                  getValues={props.getValues}
                  setError={props.setError}
                  trackError={trackError}
                />
              ))}

              {
                 !props?.isView && !props?.isEdit ?  <div class="action_button">
                <span onClick={addTaskToList}>+ Add More</span>
              </div> : null
              }


              {/* second form */}

              {/* 3rd form */}

              {ShiftFields.map((shifts, index) => {
                return (
                  <>
                    <div class="mid_title_head">
                      <h2 class="mid_title">{SHIFTS[index]}</h2>
                    </div>

                    <div class="tm_form_wrap">
                      <div class="tm_form_innner">
                        <div class="form">
                          <div class="row">
                            <div class="col-md-12 col-sm-12">
                              <div class="form-group">
                                {/* <Input
                                  type="textarea"
                                  id={`shifts[${index}].employee_signature_url`}
                                  name={`shifts[${index}].employee_signature_url`}
                                  formControlClasses="form-control"
                                  placeholder="Type here"
                                  label="Employee Signature"
                                  formControlProps={props.register(
                                    `shifts[${index}].employee_signature_url`,
                                    {
                                      required: "This field is required",
                                      validate: isValidURL,
                                    }
                                  )}
                                  validationErrorMessage={
                                    props.errors?.shifts?.[
                                      index
                                    ]?.employee_signature_url?.message
                                  }
                                  disabled={props?.isView}
                                /> */}

                                <Input
                                  type="inputSelect"
                                  labelClasses="form-label"
                                  control={props.control}
                                  controller={props.controller}
                                  selectLabel="Employee"
                                  id={`shifts[${index}].employee_signature`}
                                  selectName={`shifts[${index}].employee_signature`}
                                  selectOptions={listOfUsers}
                                  required={props.register(`shifts[${index}].employee_signature`, {
                                    required: "This field is required"
                                  })}
                                  validationErrorMessage={
                                    props.errors?.shifts?.[
                                      index
                                    ]?.employee_signature?.message
                                  }
                                  selectClasses="form-select"
                                  disabled={props?.isView}
                                />


                              </div>
                            </div>
                            <div class="col-md-6 col-sm-12">
                              <div class="form-group">
                                <Input
                                  type="date"
                                  datePickerType="time"
                                  formControlClasses="form-control"
                                  id={`shifts[${index}].on_time`}
                                  placeholder="On"
                                  control={props.control}
                                  controller={props.controller}
                                  selectName={`shifts[${index}].on_time`}
                                  dateFormat="MM/dd/yyyy"
                                  // minDate={new Date().setDate(current.getDate() - 400)}
                                  // preventReadOnly={false}
                                  label="On"
                                  formControlProps={props.register(
                                    `shifts[${index}].on_time`,
                                    {
                                      required: "This field is required",
                                      maxLength: 80,
                                    }
                                  )}
                                  timeConstraints={{ minutes: { step: 5 } }}
                                  validationErrorMessage={
                                    props.errors?.shifts?.[
                                      index
                                    ]?.on_time?.message ?? ""
                                  }
                                  disabled={props?.isView}
                                />
                                {/* <div class="calendar_icon">
                            <img
                              src="/images/icons/reminder.svg"
                              alt="calendar"
                            />
                          </div> */}
                              </div>
                            </div>
                            <div class="col-md-6 col-sm-12">
                              <div class="form-group">
                                <Input
                                  type="date"
                                  datePickerType="time"
                                  formControlClasses="form-control"
                                  id={`shifts[${index}].break_1_time`}
                                  placeholder="Break 1"
                                  control={props.control}
                                  controller={props.controller}
                                  selectName={`shifts[${index}].break_1_time`}
                                  dateFormat="MM/dd/yyyy"
                                  // minDate={new Date().setDate(current.getDate() - 400)}
                                  // preventReadOnly={false}
                                  label="Break 1"
                                  formControlProps={props.register(
                                    `shifts[${index}].break_1_time`,
                                    {
                                      required: "This field is required",
                                      maxLength: 80,
                                    }
                                  )}
                                  timeConstraints={{ minutes: { step: 5 } }}
                                  validationErrorMessage={
                                    props.errors?.shifts?.[
                                      index
                                    ]?.break_1_time?.message ?? ""
                                  }
                                  disabled={props?.isView}
                                />

                                {/* <div class="calendar_icon">
                            <img
                              src="/images/icons/reminder.svg"
                              alt="calendar"
                            />
                          </div> */}
                              </div>
                            </div>
                            <div class="col-md-6 col-sm-12">
                              <div class="form-group">
                                <Input
                                  type="date"
                                  datePickerType="time"
                                  formControlClasses="form-control"
                                  id={`shifts[${index}].lunch_time`}
                                  placeholder="Lunch"
                                  control={props.control}
                                  controller={props.controller}
                                  selectName={`shifts[${index}].lunch_time`}
                                  dateFormat="MM/dd/yyyy"
                                  // minDate={new Date().setDate(current.getDate() - 400)}
                                  // preventReadOnly={false}
                                  label="Lunch"
                                  formControlProps={props.register(
                                    `shifts[${index}].lunch_time`,
                                    {
                                     required: "This field is required",
                                      maxLength: 80,
                                    }
                                  )}
                                  timeConstraints={{ minutes: { step: 5 } }}
                                  validationErrorMessage={
                                    props.errors?.shifts?.[
                                      index
                                    ]?.lunch_time?.message ?? ""
                                  }
                                  disabled={props?.isView}
                                />

                                {/* <div class="calendar_icon">
                            <img
                              src="/images/icons/reminder.svg"
                              alt="calendar"
                            />
                          </div> */}
                              </div>
                            </div>
                            <div class="col-md-6 col-sm-12">
                              <div class="form-group">
                                <Input
                                  type="date"
                                  datePickerType="time"
                                  formControlClasses="form-control"
                                  id={`shifts[${index}].break_2_time`}
                                  placeholder="Break 2"
                                  control={props.control}
                                  controller={props.controller}
                                  selectName={`shifts[${index}].break_2_time`}
                                  dateFormat="MM/dd/yyyy"
                                  // minDate={new Date().setDate(current.getDate() - 400)}
                                  // preventReadOnly={false}
                                  label="Break 2"
                                  formControlProps={props.register(
                                    `shifts[${index}].break_2_time`,
                                    {
                                      required: "This field is required",
                                      maxLength: 80,
                                    }
                                  )}
                                  timeConstraints={{ minutes: { step: 5 } }}
                                  validationErrorMessage={
                                    props.errors?.shifts?.[
                                      index
                                    ]?.break_2_time?.message ?? ""
                                  }
                                  disabled={props?.isView}
                                />

                                {/* <div class="calendar_icon">
                            <img
                              src="/images/icons/reminder.svg"
                              alt="calendar"
                            />
                          </div> */}
                              </div>
                            </div>
                            <div class="col-md-6 col-sm-12">
                              <div class="form-group">
                                <Input
                                  type="date"
                                  datePickerType="time"
                                  formControlClasses="form-control"
                                  id={`shifts[${index}].off_time`}
                                  placeholder="Off"
                                  control={props.control}
                                  controller={props.controller}
                                  selectName={`shifts[${index}].off_time`}
                                  dateFormat="MM/dd/yyyy"
                                  // minDate={new Date().setDate(current.getDate() - 400)}
                                  // preventReadOnly={false}
                                  label="Off"
                                  formControlProps={props.register(
                                    `shifts[${index}].off_time`,
                                    {
                                     required: "This field is required",
                                      maxLength: 80,
                                    }
                                  )}
                                  timeConstraints={{ minutes: { step: 5 } }}
                                  validationErrorMessage={
                                    props.errors?.shifts?.[
                                      index
                                    ]?.off_time?.message ?? ""
                                  }
                                  disabled={props?.isView}
                                />

                                {/* <div class="calendar_icon">
                            <img
                              src="/images/icons/reminder.svg"
                              alt="calendar"
                            />
                          </div> */}
                              </div>
                            </div>
                            <div class="col-md-6 col-sm-12">
                              <div class="form-group">

                                <Input
                                  type="inputSelect"
                                  labelClasses="form-label"
                                  control={props.control}
                                  controller={props.controller}
                                  selectLabel="Lead / Supervisor"
                                  id={`shifts[${index}].supervisor_id`}
                                  selectName={`shifts[${index}].supervisor_id`}
                                  selectOptions={listOfSupervisor}
                                  required={props.register(`shifts[${index}].supervisor_id`, {
                                    required: "This field is required"
                                  })}
                                  validationErrorMessage={props.errors?.shifts?.[index]?.supervisor_id?.message}
                                  selectClasses="form-select"
                                  disabled={props?.isView}
                                />

                                {/* <Input
                                  type="text"
                                  id={`RestroomPortingShifts[${props.index}].supervisor_id`}
                                  name={`RestroomPortingShifts[${props.index}].supervisor_id`}
                                  formControlClasses="form-control"
                                  placeholder="Type here"
                                  required
                                  label="Lead / Supervisor"
                                  formControlProps={props.register(
                                    `RestroomPortingShifts[${props.index}].supervisor_id`,
                                    {
                                      required: "This field is required",
                                      // pattern: {
                                      //   value:/^[0-9]*$/ ,
                                      //   message:"Must Be a number"
                                      // },
                                      maxLength: 80,
                                    }
                                  )}
                                  validationErrorMessage={
                                    props.errors?.RestroomPortingShifts?.[
                                      props.index
                                    ]?.supervisor_id?.message ?? ""
                                  }
                                  disabled={props?.isView}
                                /> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}

              <div class="row">
                <div class="col-sm-12">
                  <div class="submitcancel_btn">
                  <button type="button" class="btn cst_btn btn-outline-secondary  cancel_btnnew" onClick={props?.resetForm}>
                      Cancel
                    </button>
                    {
                        !props?.isView ? <button type="submit" className={isLoading ? "btn cst_btn btn_danger btn-wait" : "btn cst_btn btn_danger"}> 
                        {isLoading ? ""  : props.isEdit ?  "Update"  : "Submit"}
                      </button> : null
                      }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </section>
    </main>
  );
}

export default withFormHoc(RestRoomPage);