import React, {useEffect, useState, useRef} from "react";
import {
    DialogActions,
    DialogTitle,
    DialogContent,
    Dialog
} from '@material-ui/core';

import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import * as $ from "jquery";

import AreaQrCodesTable from "./AreaQrCodesTable";
import { qrCodeSize_option } from "../../../../../utility/constants/constants";
import { Select } from 'antd';

const PrintQRCodes = (props) => {
  const buttons = useRef("");
  const columnRef = useRef("");
  const [openQrModel, setQROpen] = useState(false);
  const [qrLoading, setQRLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [pdfDoc, setDoc] = useState(null);
  const [downloadPdf, setDownload] = useState(false);
  const [noArea, setNoArea] = useState(false);

  const [qrCodeSize, setQrCodeSize] = useState([2, 'medium']);
  const [qrCodeSize_MedSmall, setQrCodeSize_MedSmall] = useState([5, null]);

  const handleQrCodeSizeRender = (size) => {
    let columnSize = qrCodeSize_option.filter((e) => {
      return e.value == size;
    })
    if(size == 'medSmall') {
      setQrCodeSize([columnSize[0].columnValue, null]);
      setQrCodeSize_MedSmall([columnSize[0].columnValue, 'small']);
    } else {
      setQrCodeSize_MedSmall([columnSize[0].columnValue, null]);
      setQrCodeSize([columnSize[0].columnValue, size]);
    }
  };

  const getAllQrPdf = () => {
    if (noArea) {
      setDownload(true);
      setQRLoading(true);
    }
  };

  const printQr = () => {
    const input = $('.qr-table-area .rt-tbody')[0];
    html2canvas(input,{useCORS:true, allowTaint : true })
        .then((canvas) => {
          var imgWidth = 150;
          var pageHeight = 295;
          var imgHeight = canvas.height * imgWidth / canvas.width;

          var heightLeft = imgHeight;

          var position = 10;
          const imgData = canvas.toDataURL('image/png');
           let pdf = new jsPDF('p', 'mm',  "Letter");
          //pdf.addImage(imgData, 'PNG', 10, 10);

          pdf.addImage(imgData, 'PNG', 20, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;
          while (heightLeft >= 0) {
            position = heightLeft - imgHeight;
            pdf.addPage();
            pdf.addImage(imgData, 'PNG', 20, position, imgWidth, imgHeight);
            heightLeft -= pageHeight;
          }
          //pdf.output('dataurlnewwindow');
          pdf.save("QrCodes.pdf");
        })

  };

  const printAreaQr = () => {
  $(".pagination-bottom").css("display", "none");
    buttons.current.style.display = "none";
    columnRef.current.style.display = "none";
    window.print();
    setTimeout(() => {
      $(".pagination-bottom").css("display", "block");
      buttons.current.style.display = "block";
      columnRef.current.style.display = "block";
      }, 200);
  };

  useEffect(() => {
    if (!loaded){
      setLoaded(true);
      props.getAllQRCodes(props.match.params.accountId).then(res => {
        var doc = new jsPDF('p', 'pt');
        var allQrCodes = res.value.data.areas;
        if (allQrCodes && allQrCodes.length) {
          setNoArea(true);
          allQrCodes.reduce(async (lastPromise, QrCode, index) => {
            await lastPromise;
            return new Promise((resolve, reject) => {
              var image = new Image();
              image.setAttribute('crossOrigin', 'anonymous'); //getting images from external domain
              //console.log("loops item",QrCode.name);
              image.src = QrCode['qr_code_urls']['medium'];
              image.onload = function () {
                //console.log("loaded item",QrCode.name);
                var canvas = document.createElement('canvas');
                canvas.width = this.naturalWidth;
                canvas.height = this.naturalHeight;
                //next three lines for white background in case png has a transparent background
                var ctx = canvas.getContext('2d');
                ctx.fillStyle = '#fff';  /// set white fill style
                ctx.fillRect(0, 0, canvas.width, canvas.height);
                canvas.getContext('2d').drawImage(this, 0, 0);
                // resolve(canvas.toDataURL('image/jpeg'));
                let img = canvas.toDataURL('image/jpeg');
                let xOffset = (doc.internal.pageSize.width / 2) - (doc.getStringUnitWidth(QrCode.name) * doc.internal.getFontSize() / 2);
                doc.text(xOffset, 50, QrCode.name);
                doc.addImage(
                    img, 'jpeg', 150, 100, 300, 300);
                if (doc.getNumberOfPages() === allQrCodes.length) {
                  setQRLoading(false);
                  setDoc(doc);
                }
                else doc.addPage();
                resolve();
              };
            });
          }, Promise.resolve());
        }
      });
    }

  },[loaded]);

  useEffect(() => {
    if (downloadPdf && pdfDoc) {
      pdfDoc.save(`${props.account.name}.pdf`);
      setQRLoading(false);
      setDownload(false);
    }
  },[downloadPdf, pdfDoc]);

  return (
      <>
      <button type="button" className="btn btn-secondary cst_btn mr-1 mb-1 min-width-btn" onClick={() => setQROpen(true)} data-backdrop="static" data-keyboard="false" data-toggle="modal" data-target="#associateUser" data-test="job_print">Print QR</button>
      <Dialog
          open={openQrModel}
maxWidth={false}
          aria-labelledby="form-dialog-title"
          >
        <DialogContent style={{padding: "20px"}}>
          <AreaQrCodesTable
              {...props}
              qrLoading={qrLoading}
              qrCodeSize={qrCodeSize}
              qrCodeSize_MedSmall={qrCodeSize_MedSmall}
              getAllQrPdf={getAllQrPdf}
              accountId={props.accountId}
              columnRef={columnRef}
              isLoading={props.isLoading}
              onAccountAreasPageSizeChange={props.onAccountAreasPageSizeChange}
              AccountAreasList={props.accountAreas ? props.accountAreas : []}
              editAccountAreasClicked={props.editAccountAreasClicked}
              openDeleteModel={props.openDeleteModel}
              handleClickDeleteModel={props.handleClickDeleteModel}
              // changeColumn={qrCodeSize}
              />
        </DialogContent>
        <div className="p-2 print_qr_btn qrCode_sizeSetup_antd_select" ref={buttons}>
          <button style={{ minHeight: '51px', minWidth: '80px' }} onClick={() => {handleQrCodeSizeRender('medium'); setQROpen(false);}} className={"btn cst_btn btn-outline-secondary mr-2"} id="cancel" type="cancel" data-test="modal_cancel_btn">Cancel</button>
          <button style={{ minHeight: '51px', minWidth: '80px' }} onClick={() => printQr()} className={"btn mr-2 cst_btn btn_danger"} id="cancel" type="cancel" data-test="modal_getpdf_btn">Get PDF</button>
          <Select
              suffixIcon={null}
              className={"btn cst_btn btn_danger mr-2"}
              style={{ minHeight: '51px', minWidth: '80px' }}
              placement={"bottomRight"}
              defaultValue='medium'
              options={qrCodeSize_option}
              onChange={(size)=>{handleQrCodeSizeRender(size);}}
          />
          <button style={{ minHeight: '51px', minWidth: '80px' }} onClick={() => printAreaQr()} disabled={props.isLoading} className={props.isLoading ? "btn cst_btn btn_danger btn-wait" : "float-right btn cst_btn btn_danger mr-2"} id="delete" type="delete">{props.isLoading ? "" : "Print QR Codes"}</button>

        </div>
      </Dialog>
      </>
  )
};

export default PrintQRCodes;