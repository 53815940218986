import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { getFormValues, reduxForm } from "redux-form";
import { Input } from "../../../UI/InputElement/InputElement";
import { makeFirstLetterCapital } from "../../../../../utility/utility";
import { newJobAssosiationFormValidator as validate } from "../../../../../utility/validator/validator";
import InventoryItemFormSkeleton from "../../../../component/Inventory/InventoryListComponent/NewInventoryItemForm/InventoryItemFormSkeleton";

let NewJobAssociationForm = (props) => {

  const [filteredList, setFilteredList] = useState(props.accountsList ?? []);


  const { handleSubmit } = props;

  useEffect(() => {
    // Trigger validation whenever selectedJobIds changes
    props.change("item[selectedJobs]", props.selectedJobIds); // If you want to populate or validate based on this
  }, [props.selectedJobIds]);
  

  useEffect(() => {
    if (
      props?.accountsList &&
      props?.accountsList?.length &&
      filteredList.length == 0
    ) {
      setFilteredList(props.accountsList);
      return;
    }
  }, [props.accountsList]);

  useEffect(() => {
    if (props?.formStates?.item?.searchJobs?.trim().length) {
      // we need to filter and change state
      const searchText = props.formStates?.item?.searchJobs.toLowerCase();
      const filteredList = props.accountsList.filter(
        (acc) => acc.name?.toLowerCase().includes(searchText)
      );

      if (filteredList?.length) setFilteredList(filteredList);
      else {
        setFilteredList([]);
      }
    }

    if (
      !props?.formStates?.item?.searchJobs?.length &&
      props?.accountsList?.length
    ) {
      setFilteredList(props.accountsList);
    }
    return;
  }, [props?.formStates?.item?.searchJobs]);

  if (props.accountsList == null) {
    return <InventoryItemFormSkeleton />;
  } else {
    return (
      <main className="app-content wraper_content user_newQuote add_account_modal add_New_Accont add_newAccount">
        <div className="inspection_content_sec appcontent_Inner">
          <div className="container-fluids">
            <div
              className="quote_form ticket_form"
              style={{ maxWidth: "900px" }}
            >
              <div className="row">
                <div className="col-sm-12 text-center">
                  {" "}
                  <h2 className="md_title">{props.match.params && props.match.params.id ? 'Update Job Association' : 'Creating Job Association'}</h2>
                </div>
              </div>
              <div className="addQuote_form">
                <form className="form_content" onSubmit={handleSubmit}>
                  <div className="cst_tab_content">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group">
                          <label className="form_title font-weight-bold">
                            {" "}
                            Job Group Name{" "}
                            <sup className="cst_sup_txt text_danger">*</sup>
                          </label>
                          <Input
                            dataTest=""
                            type="text"
                            className="form-control input-modifier"
                            placeholder="Job Group Name"
                            name="item[name]"
                          />
                        </div>
                      </div>
                      {/* <div className="col-md-8">
                        <div className="form-group">
                          <label className="form_title">
                            {" "}
                            Jobs{" "}
                            <sup className="cst_sup_txt text_danger">*</sup>
                          </label>
                          
                        </div>
                      </div> */}
                    </div>

                    <div className="row" style={{padding:"5px 0px 11px 0px",justifyContent:"space-between"}}>
                      <div className="col-lg-4">
                        <label className="form_title font-weight-bold">
                          Select Jobs
                        </label>
                      </div>

                      <div
                        className="col-lg-4"
                        style={{ display: "flex", alignItems: "center" }}
                        
                      >
                        <Input
                          dataTest=""
                          type="text"
                          min={1}
                          className="form-control input-modifier"
                          placeholder="Search Jobs"
                          name="item[searchJobs]"
                        />
                      </div>
                    </div>

                    <div className="performance_modal add_account associateUser">
                      <div className="row">
                        <div className="col-lg-12">
                          <div
                            className={
                              props?.formStates &&
                              props?.formStates?.user &&
                              props?.formStates?.user?.account_wide
                                ? "quote_form ticket_form job_wide_disable"
                                : "quote_form ticket_form"
                            }
                            style={{ maxWidth: "100%" }}
                          >
                            {
                              <div
                                className="addQuote_form"
                                style={{
                                  overflowY: "scroll",
                                  maxHeight: "500px",
                                }}
                              >
                                <div className="form-group">
                                  <div className="checkbox_block radius_checkbox"></div>

                                  <div className="form-group checkbox_cst_grid">
                                    {filteredList &&
                                      filteredList.map((account) => {
                                        return (
                                          <div
                                            className={
                                              // props.setOwnerColor(account.id)
                                              "checkbox_block radius_checkbox"
                                              //   : "checkbox_block radius_checkbox"
                                            }
                                            key={account.id}
                                          >
                                            <div className="custom-control custom-checkbox mb-2">
                                              <input
                                                onChange={() =>
                                                  props.jobSelected(account.id)
                                                }
                                                checked={props.setJobCheckValue(
                                                  account.id
                                                )}
                                                type="checkbox"
                                                className="custom-control-input"
                                                id={`account_ids${account.id}`}
                                              />
                                              <label
                                                className="custom-control-label"
                                                for={`account_ids${account.id}`}
                                              >
                                                {account?.name}
                                              </label>
                                            </div>
                                          </div>
                                        );
                                      })}
                                    <div> </div>
                                    {filteredList?.length == 0 &&
                                    props?.formStates?.item?.searchJobs ? (
                                      <h1 style={{ marginLeft: "3rem" }}>
                                        Jobs Not Found
                                      </h1>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                            }
                          </div>
                        </div>
                        <Input
                          dataTest=""
                          type="hidden"
                          min={1}
                          className="form-control input-modifier"
                          placeholder="Jobs"
                          name="item[selectedJobs]"
                        />
                        {/* <Field
                          id="item[selectedJobs]"
                          name="item[selectedJobs]"
                          value={props.selectedJobs}
                          component={({ input, meta: { touched, error, warning } }) => (
                            <div>
                              
                              <input type="hidden" {...input} />
                        
                              {touched && (
                                (error && <span style={{ color: '#DD2726', fontSize: '13px' }}>{error}</span>) ||
                                (warning && <span>{warning}</span>)
                              )}
                            </div>
                          )}
                        
                        /> */}
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      data-test="cancel_button"
                      type="button"
                      className="btn cst_btn btn-outline-secondary cancel_btnnew w-25"
                      onClick={() => {
                        props.onCancelClicked();
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      data-test="submit_button"
                      type="submit"
                      disabled={props.isLoading}
                      className={
                        props.isLoading
                          ? "btn cst_btn btn_danger btn-wait w-25"
                          : "btn cst_btn btn_danger w-25"
                      }
                    >
                      {props.isLoading ? "" : props.match.params && props.match.params.id ? 'Update Link'  : "Genarete Link"}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
};

const mapStateToProps = (state) => {
  let item = {};
  if(state.JobAssociateReducer.singleJobAssociation && Object.keys(state.JobAssociateReducer.singleJobAssociation).length){
    item.name=state.JobAssociateReducer.singleJobAssociation?.name;
    item.selectedJobs=state.JobAssociateReducer?.singleJobAssociation.accounts.map((a)=>a.id)
  }

  else{
    item = {
      name: "",
      selectedJobs: [],
      searchJobs: "",
    };
  }
  

  return {
    initialValues: { item },
    formStates: getFormValues("newJobAssociationForm")(state),
  };
};

NewJobAssociationForm = reduxForm({
  form: "newJobAssociationForm",
  validate,
  enableReinitialize: true,
})(NewJobAssociationForm);

NewJobAssociationForm = connect(mapStateToProps)(NewJobAssociationForm);

export default NewJobAssociationForm;
