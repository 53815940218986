import { decode } from 'base64-arraybuffer';
import cloneDeep from 'clone-deep';
import React from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../redux/actions/index';
import isEmpty from '../../../utility/IsEmptyValidator';
import { extractExtention } from '../../../utility/utility';
import CHIPreview from '../../component/CHIPreview/CHIPreview';

class CHIPreviewContainer extends React.Component {
    // componentDidMount = () => {
    //     this.props.getAllCHIServys();
    // }

    constructor(props) {
        super(props);
        this.state = {
            faultImages: 0,
            isImagesEmpty: false,
            imageName: '',
            delete_image_object: {
                commentIndex: null,
                attachmentIndex: [],
                urlId: []
            },
            imageUrl: null,
            isCommentLoading: false,
            isUpdateComment: false,
            chi: cloneDeep(this.props.chi),
            isCommentAndResolveLoading: false,
            base64: [],
            commentAttributes: this.props.chi ? this.props.chi.comments_attributes : null,
            AllFileTypes: [],
            fileType: '',
            idsToDelete: [],
            accountDropDown: false,
            enableUpdateComment: false
        }
        this.inputOpenFileRef = React.createRef();
        this.onPortfolioFileChange = this.onPortfolioFileChange.bind(this);
        this.removeImage = this.removeImage.bind(this);
        this.choosePhotoClicked = this.choosePhotoClicked.bind(this);
        this.removeUrls = this.removeUrls.bind(this);
        this.cancelEditClicked = this.cancelEditClicked.bind(this);
    }

    componentDidUpdate = (PrevProps, PrevState) => {
        if (this.props.chi !== PrevProps.chi) {
            if (this.props.chi.comments_attributes.length !== 0 && !this.state.isUpdateComment &&
                (this.props.chi.comments_attributes[this.props.chi.comments_attributes.length - 1]
                    .attachments_attributes.length !== 0) && this.state.base64.length !== 0) {

                this.setState({
                    isCommentLoading: false,
                    base64: [],
                    fileType: '',
                    imageName: ''
                })
            } else if (this.state.base64.length === 0) {
                this.setState({
                    isCommentLoading: false
                })
            } else {
                this.setState({
                    isUpdateComment: false
                })
            }

            this.setState({
                chi: this.props.chi
            })
            if (this.state.idsToDelete.length === 0) {
                this.setState({
                    base64: [],
                    imageName: [],
                    arrayBuffer: [],
                    idsToDelete: [],
                    portfolioImages: [],
                    commentAttributes: this.props.chi.comments_attributes
                })
            } else {
                this.setState({
                    // base64: this.state.base64,
                    imageName: [],
                    arrayBuffer: [],
                    idsToDelete: [],
                    portfolioImages: [],
                    commentAttributes: this.props.chi.comments_attributes
                })
            }
        }
    }

    componentDidMount = () => {
        if (isEmpty(this.props.chi) && this.props.match.params.chiId) {
            this.props.getChiPreview(this.props.match.params.chiId);
        }
        if (!this.props.accountAdminList) {
            this.props.getAccountAdminList();
        }
    }

    componentWillUnmount() {
        // this.props.resetObjects();
    }

    choosePhotoClicked = () => {
        this.inputOpenFileRef.current.click()
    }

    onPortfolioFileChange = (e) => {
        this.setState({
            faultImages: 0,
            enableUpdateComment: true
        });
        console.log(this.state.base64);
        let files = e.target.files;
        let imageName = [], fileType = [], AllFileTypes = [], base64 = [];
        const that = this;
        // for (let i = 0; i < files.length; i++) {
        function readFile(index) {
            if (index < files.length) {
                let reader = new FileReader();
                let file = files[index];
                imageName.push(file.name)
                fileType.push(file.type)
                AllFileTypes.push(file.type)
                console.log(file, "file<<<<<<")
                if (file.type.match('image')) {
                    console.log(reader, "reader<<<<<")
                    reader.onload = (e) => {
                        var image = new Image();
                        image.src = reader.result;
                        image.onload = function () {
                            // alert('width' + image.width + 'height' + image.height);
                            // if (image.width >= MinImageResolution.width && image.height >= MinImageResolution.height) {
                            base64.push(e.target.result);
                            if (index === files.length - 1) {
                                //   
                                that.setState(prevState => ({
                                    imageName: [...prevState.imageName, ...imageName],
                                    fileType: [...prevState.fileType, ...fileType],
                                    AllFileTypes: [...prevState.AllFileTypes, ...AllFileTypes],
                                    // arrayBuffer: [...prevState.arrayBuffer, decode(reader.result)],
                                    base64: [...prevState.base64, ...base64]
                                }))
                            }
                            // }
                            // else {
                            //     that.setState(prevState => ({
                            //         faultImages: prevState.faultImages + 1
                            //     }))
                            // }
                            readFile(index + 1);
                        };

                    }
                }

                // reader.readAsArrayBuffer(file)
                reader.readAsDataURL(file)
            }
        }

        readFile(0);
    }

    assignTo = (value) => {
        let values = cloneDeep(value);
        // values['chi']['assign_to'] = values.assignRoles
        let ticket = {};
        ticket['id'] = values.chi.id;
        ticket['user_id'] = values.chi.user_id;
        this.props.updateTicket({ ticket: ticket }, this.state.idsToDelete, []);
        // this.setState({
        //     accountDropDown: false,
        // })

    }

    showDropDown = () => {
        this.setState({
            accountDropDown: true,
        })
    }


    addTicketComment = (values, setEditCommentObject, updateCommentId) => {
        if (setEditCommentObject) {
            this.setState({
                isUpdateComment: true
            })
        }

        let chi = cloneDeep(values);
        // chi.chi.comments_attributes = this.state.commentAttributes;
        if (chi.id) {
            chi.comments_attributes[this.state.delete_image_object.commentIndex]['delete_image_ids'] =
                this.state.delete_image_object.urlId
        }
        // chi.comments_attributes[chi.comments_attributes.length - 1]['user_id'] = this.props.user.id;
        const arrayBuffer = [];

        if (this.state.base64.length > 0) {

            this.state.base64.map((url, i) => {
                if (!url.photo_urls) {
                    let base_photo = null;
                    const image = url.split(',');
                    base_photo = image[1];

                    arrayBuffer.push(decode(base_photo))
                }

            })
        }
        const extentions = extractExtention(this.state.imageName);
        this.setState({
            isCommentLoading: true,
            enableUpdateComment: false
        })
        this.props.resolveTicket(chi, extentions, arrayBuffer, updateCommentId, setEditCommentObject, this.state.idsToDelete);



    }

    addTicketCommentAndResolve = (values, setEditCommentObject, updateCommentId) => {
        if (setEditCommentObject) {
            this.setState({
                isUpdateComment: true
            })
        }
        const chi = cloneDeep(values);
        this.state.delete_image_object.attachmentIndex.forEach(attIndex => {
            chi.chi.comments_attributes[this.state.delete_image_object.commentIndex]
                .attachments_attributes.map((a, i) => {
                    if (i === attIndex) {
                        a['delete_image_ids'].push(this.state.delete_image_object.urlId[attIndex])
                    }
                })
        })

        chi.chi.comments_attributes = this.state.commentAttributes;

        // chi.chi.comments_attributes[chi.chi.comments_attributes.length - 1]['user_id'] = this.props.user.id;
        chi.chi['status'] = 'resolved';
        chi.chi['end_time'] = new Date();
        const arrayBuffer = [];
        if (this.state.base64.length > 0) {

            this.state.base64.map((url, i) => {
                if (!url.photo_urls) {
                    let base_photo = null;
                    const image = url.split(',');
                    base_photo = image[1];

                    arrayBuffer.push(decode(base_photo))
                }

            })
        }
        const extentions = extractExtention(this.state.imageName);
        this.setState({
            isCommentAndResolveLoading: true,
            enableUpdateComment: false
        })
        this.props.resolveTicket(chi, extentions, arrayBuffer, updateCommentId, setEditCommentObject);

    }

    removeUrls = (url, index) => {
        let updatedUrls = cloneDeep(this.state.commentAttributes);

        if (url.photo_urls) {
            this.setState(prevState => ({
                idsToDelete: [...prevState.idsToDelete, url.id]
            }))
        }

        let urlIndex = this.state.commentAttributes[index].attachments_attributes.findIndex(function (attachments) {
            return attachments.id === url.id
        })
        updatedUrls[index].attachments_attributes.splice(urlIndex, 1);

        this.setState({
            commentAttributes: updatedUrls,
            enableUpdateComment: true
        })

        // console.log(this.state.commentAttributes)
    }



    removeImage = (url, fileTypeIndex) => {
        let updatedUrls = [...this.state.base64];
        let updatedFileTypes = [...this.state.fileType]
        let updatedImageNames = [...this.state.imageName]

        let urlIndex = this.state.base64.findIndex(function (u) {
            return u === url
        })
        updatedUrls.splice(urlIndex, 1);
        updatedFileTypes.splice(fileTypeIndex, 1)
        updatedImageNames.splice(fileTypeIndex, 1)

        this.setState({
            base64: updatedUrls,
            fileType: updatedFileTypes,
            imageName: updatedImageNames
        })
    }

    cancelEditClicked = () => {
        this.setState({
            delete_image_object: {
                commentIndex: null,
                attachmentIndex: [],
                urlId: []
            },
            base64: [],
            imageName: [],
            fileType: [],
            AllFileTypes: [],
            chi: this.props.chi,
            commentAttributes: this.props.chi.comments_attributes
        })
    }


    render() {
        return (
            <CHIPreview
                {...this.props}
                addTicketComment={this.addTicketComment}
                addTicketCommentAndResolve={this.addTicketCommentAndResolve}
                choosePhotoClicked={this.choosePhotoClicked}
                onPortfolioFileChange={this.onPortfolioFileChange}
                removeImage={this.removeImage}
                portfolioImages={this.state.base64}
                reference={this.inputOpenFileRef}
                onSubmit={this.assignTo}
                accountDropDown={this.state.accountDropDown}
                showDropDown={this.showDropDown}
                removeUrls={this.removeUrls}
                cancelEditClicked={this.cancelEditClicked}
                isLoading={this.props.isLoading}
                commentAttributes={this.state.commentAttributes}
                enableUpdateComment={this.state.enableUpdateComment}
            />
        )
    }
}


const mapStateToProps = (state) => {
    return {
        chi: state.adminOrSuperAdminReducer.chi,
        user: state.authReducer.user,
        accountAdminList: state.adminOrSuperAdminReducer.accountAdminList,
        isLoading: state.adminOrSuperAdminReducer.isLoading,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getChiPreview: (id) => dispatch(actions.getChiPreview(id)),
        resolveTicket: (ticket, extentions, arrayBuffer, updateCommentId, setEditCommentObject, idsToDelete) => dispatch(actions.resolveTicket(ticket, extentions, arrayBuffer, updateCommentId, setEditCommentObject, idsToDelete)),
        getAccountAdminList: () => dispatch(actions.getAccountAdminList()),
        updateTicket: (ticket, idsToDelete) => dispatch(actions.updateTicket(ticket, idsToDelete)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CHIPreviewContainer);