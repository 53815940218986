import Swal from 'sweetalert2'

export const showConfirmAlert = (title, subtitle, callBack, cancelCallBack) => {
    return Swal.fire({
        title: title,
        text: subtitle,
        icon: '',
        showCancelButton: true,
        // confirmButtonColor: "#850037",
        confirmButtonClass: 'btn cst_btn btn_danger mr-2',
        cancelButtonColor: '#151b26',
        cancelButtonClass: 'btn cst_btn btn-outline-secondary',
        confirmButtonText: 'Confirm'
    }).then((result) => {

        if (result.value) {
            callBack();
        } else if (result.dismiss) {
            // cancelCallBack();
        }
    })
}