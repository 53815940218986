import React, { useEffect, useState } from 'react';
import ReactTable from 'react-table-v6';
import '../../../styles/custom.css';
import { makeFirstLetterCapital, CustomNoDataComponent, getUser, convertFeedbackTime, convertToPst, convertDateAndTimeTimezone } from '../../../utility/utility';
import { MagicSpinner, PushSpinner, SwishSpinner, WaveSpinner, ClapSpinner } from 'react-spinners-kit';
import 'react-table-v6/react-table.css';
import * as actions from '../../../redux/actions/index';
import { useSelector, useDispatch } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { FaArrowDown, FaArrowUp } from "react-icons/fa";
import { userRoles } from '../../../utility/constants/constants';
import ImageLoader from 'react-imageloader';

const PegAssureTable = (props) => {


    const dispatch = useDispatch();
    const SearchValue = useSelector(state => state.adminOrSuperAdminReducer.searchfeedbacksValues);
    const DateGenerate = useSelector(state => state.adminOrSuperAdminReducer.dataGenerated);
    const SearchOrResetPegassureClicked = useSelector(state => state.adminOrSuperAdminReducer.SearchOrResetPegassureClicked);
    const No_of_pages = useSelector(state => state.adminOrSuperAdminReducer.feedbacks_page);
    const No_of_rows = useSelector(state => state.adminOrSuperAdminReducer.feedbacks_no_of_rows);
    const feedbacksPage = useSelector(state => state.adminOrSuperAdminReducer.feedbacksCurPage);
    const feedbacks_rows = useSelector(state => state.adminOrSuperAdminReducer.feedbacks_rows);


    const data =  props.pegassureFeedbacks.map(feedback => {

        return {
            job_name: makeFirstLetterCapital(feedback.accounts.name) ,
            id: feedback.id ,
            area_name: makeFirstLetterCapital(feedback.area.name),
            rating: feedback.rating === 'needservice' ? 'Need Service' : makeFirstLetterCapital(feedback.rating),
            comments: feedback.comment,
            day: makeFirstLetterCapital(feedback.day),
            created_at: convertDateAndTimeTimezone(feedback.created_at, feedback.accounts.timezone),
            attachment: feedback.attachments.length > 0 ? 'Yes' : 'No',
            actions: 
                <>
                    <span data-tip="View Feedback" className="mr_20 account_edit_icn" onClick={() => props.previewFeedbackClicked(feedback)} data-test="edit_eye_icon"><i className="fas fa-eye cst_icon mr-2" aria-hidden="true"></i></span>
                    <ReactTooltip place="top" type="dark" effect="solid" />
                </>
        }
    })


    const columns = [
        {
            Header: <><span>Job</span><FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
            accessor: 'job_name', // String-based value accessors!,
            width: 150,
            show: props.pegassureFeedbacks.length !== 0,
        },
        {
            Header: <><span>Created At</span><FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
            accessor: 'created_at',
            show: props.pegassureFeedbacks.length !== 0,
        }, 
        {
           Header: <><span>Day</span><FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
           accessor: 'day',
           show: props.pegassureFeedbacks.length !== 0,
       },
        {
            // id: 'friendName', // Required because our accessor is not a string
            Header: <><span>Area name</span><FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
            accessor: 'area_name',
            show: props.pegassureFeedbacks.length !== 0, // Custom value accessors!
        }, {
            // id: 'friendName', // Required because our accessor is not a string
            Header: <><span>Feedback</span><FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
            accessor: 'rating',
            show: props.pegassureFeedbacks.length !== 0, // Custom value accessors!
        }, {
            // id: 'friendName', // Required because our accessor is not a string
            Header: <><span>Comments</span><FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
            accessor: 'comments',
            show: props.pegassureFeedbacks.length !== 0, // Custom value accessors!
        },{
            // id: 'friendName', // Required because our accessor is not a string
            Header: <><span>Attachments</span></>,
            accessor: 'attachment',
            show: props.pegassureFeedbacks.length !== 0, // Custom value accessors!
        },
         {
            Header: '',
            accessor: 'actions',
            width: 135,
            filterable: false,
            // show: false
        }
    ]

    const [tablePage, setTablePage] = useState(0)
    const tablePageNew =  React.useRef()

    if(feedbacksPage !== tablePageNew.current) {
        tablePageNew.current = feedbacksPage;
    }
    const setTablePageFn = (page) => {
        setTablePage(page)
        // props.userPageClicked(page)
        tablePageNew.current = page;
    }

    const fetchData = (state, instance) => {

        if (SearchOrResetPegassureClicked) {
            dispatch(actions.resetSearchOrResetValue())
        } else {

            if (!DateGenerate && !props.isPegassureLoading) {
                state.page = 2;
                const feedbacks_no_of_rows = state.pageSize;
                let page = tablePageNew.current >= 0 ? tablePageNew.current + 1 : feedbacksPage + 1;
                if(feedbacks_rows != feedbacks_no_of_rows) {
                    page = 1;
                    setTablePageFn(0)
                }
                const sorted = state.sorted;
                const filtered = state.filtered;
                props.getPegassureFeedbacks(
                    SearchValue ? SearchValue : {},
                    feedbacks_no_of_rows, page,
                    sorted,
                    filtered);
            }
        }
    }

    return (
        SearchOrResetPegassureClicked && props.isPegassureLoading ?
            <div className="loader_btn_block">
                <ClapSpinner backColor="#89073c" size={40} frontColor="#89073c8a" />
            </div>
            :
            <div>
            <ReactTable
                data={data}
                loading={props.isPegassureLoading}
                defaultPageSize={No_of_rows}
                minRows={0}
                manual
                onFetchData={fetchData}
                pages={No_of_pages}
                gotoPage={feedbacksPage}
                page = { feedbacksPage }
                onPageChange={(page) => {setTablePageFn(page)}}
                showPaginationTop={true}
                onPageSizeChange={props.onAccountsPageSizeChange}
                showPagination={props.pegassureFeedbacks.length !== 0}
                columns={columns}
                LoadingComponent={() =>
                    props.isPegassureLoading ?
                        <div style={{
                            display: "block",
                            position: "absolute",
                            left: 0,
                            height: '100%',
                            right: 0,
                            background: "rgba(255,255,255,0.8)",
                            transition: "all .3s ease",
                            top: 0,
                            bottom: 0,
                            textAlign: "center"
                        }}
                            className="loader_btn_block">
                            <ClapSpinner backColor="#89073c" size={40} frontColor="#89073c8a" />
                        </div> : null}
                NoDataComponent={() => CustomNoDataComponent(props.isPegassureLoading, 'No Feedbacks Found')}
                getTheadThProps={(state, rowInfo, column, instance) => {
                    return {
                        style: {
                            borderTop: 'none',
                            borderBottom: 'none',
                            borderRight: 'none',
                            textAlign: 'center',
                            color: '#566975',
                            fontSize: '14px',
                            fontWeight: '600',
                            whiteSpace: 'nowrap',
                            float: 'left',
                        }
                    }
                }
                }
                getTrProps={(state, rowInfo, column, instance) => {
                    console.log(column);
                    return {
                        className: 'react-table-tr-element',
                        style: {
                            border: '1px solid #c7cdd1',
                            transition: '.3s ease-in-out',
                            width: '100%',
                            marginTop: '5px',
                            float: 'left'
                        }
                    }

                }}
                getTdProps={(state, rowInfo, column, instance) => {

                    if (column.id == 'image') {
                        return {
                            style: {
                                marginTop: '0px'
                            }
                        }
                    } else {
                        return {
                            className: 'react-td-element',
                        }
                    }
                }
                }
                getProps={() => {
                    return {
                        style: {
                            border: 'none'
                        }
                    }
                }}
            />
            </div>
    )
}

export default PegAssureTable;
