export const validateCompanyUsers = (companyIds) => {
    let _error = null;
    if (companyIds.length === 0) {
        return _error = 'please select atleast one Company.'
    } else {
        return _error;
    }
}

export const validateAccountUsers = (account_ids) => {
    let _error = null;

    if (account_ids.length === 0) {
        return _error = 'please select atleast one Job.'
    } else {
        return _error;
    }
}