import React, { useState } from 'react';
import ReactTable from 'react-table-v6';
import '../../../../styles/custom.css';
import {  userRoles,  } from '../../../../utility/constants/constants';
import { CustomNoDataComponent,  convertDateTimezone, getUser } from '../../../../utility/utility';
import { FaArrowDown, FaArrowUp } from "react-icons/fa";
import ReactTooltip from 'react-tooltip';
import * as actions from '../../../../redux/actions/index';
import { useSelector, useDispatch } from 'react-redux';

import { ClapSpinner } from 'react-spinners-kit';
import 'react-table-v6/react-table.css';
import moment from 'moment';




let TicketTable = (props) => {

    const [findCopy, setFindCopy] = useState('')
   
    const user = getUser()
    console.log("user,",user);
    
    const dispatch = useDispatch();
    const values = useSelector(state => state.JobAssociateReducer.searchTicketValues);
    const ticketIndex = useSelector(state => state.adminOrSuperAdminReducer.ticketIndex);
    const DateGenerate = useSelector(state => state.adminOrSuperAdminReducer.dataGenerated);
    // const isTicketPending = useSelector(state => state.JobAssociateReducer.isTicketPending);
    const SearchOrResetTicektClicked = useSelector(state => state.adminOrSuperAdminReducer.SearchOrResetTicektClicked);
    const No_of_pages = useSelector(state => state.JobAssociateReducer.ticket_page);
    const No_of_rows = useSelector(state => state.JobAssociateReducer.ticket_no_of_rows);
    const restroomPorteringCurPage = useSelector(state => state.JobAssociateReducer.ticketCurPage);
    const ticketPage = useSelector(state => state.JobAssociateReducer.ticketCurPage);
    const ticket_rows = useSelector(state => state.JobAssociateReducer.ticket_rows);
    const SearchOrResetScheduledTaskListClicked = useSelector(state => state.JobAssociateReducer.SearchOrResetScheduledTaskListClicked);

    const copyLink = (link) =>{
        setFindCopy(link.id)
        if(process.env.REACT_APP_ENV == 'production'){
            navigator.clipboard.writeText('https://app.pegdesk.com' + link.link)
        }else{
            navigator.clipboard.writeText('https://staging-node.pegdesk.com' + link.link)
        }
    }

    const data = props.ticketsList && props.ticketsList.map((ticket,i) => {
        return {
            createdOn: ticket.created_at ? convertDateTimezone(ticket.created_at, user?.timezone) : '-',
            link: ticket?.link,
            jobGroup: ticket?.name ?? "",            // subject: ticket.isDeficient ? `deficiency line item for ${ticket.inspection_form.name}` : ticket.subject,
            id: ticket.id,
            copy : <button   className="btn pegdesk_button" onClick={() => copyLink(ticket)}> {ticket.id === findCopy ? 'Copied !' : 'Copy to clipboard'}</button>,
            createdBy: ticket?.user && ticket?.user?.first_name && ticket?.user?.last_name ? ticket.user.first_name.concat(" ").concat(ticket.user.last_name) :"" ,
            actions:

                <>
                            <span className="mr_20 account_edit_icn" onClick={() => props.history.push(`/job-association/preview-job-association/${ticket.id}`)}  data-test="edit_pencil_icon"><i class="fas fa-eye" aria-hidden="true"></i></span>
                            <span className="mr_20 account_edit_icn" onClick={() => props.history.push(`/job-association/${ticket.id}`)} data-test="edit_pencil_icon"><i class="fa fa-pencil" aria-hidden="true"></i></span>
                            <span className="mr_20 account_edit_icn" onClick={() => props.handleClickDeleteModel(ticket)}  data-test="edit_trash_icon"><i class="fa fa-trash" aria-hidden="true"></i></span>
                            
                </>
                    
        }
    })

    const columns = [
        {
            Header: <><span>ID</span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /></>,
            accessor: 'id',
            show: props.ticketsList.length !== 0,
            sortable:false,
            width: 100,
            // width:100
        },
        {
            Header: <><span> Job Group   </span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /></>,
            accessor: 'jobGroup',
            // width: 135,
            show: props.ticketsList.length !== 0,
            sortable:false,
        },
        {
            Header: <><span>Sharable Link</span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /></>,
            accessor: 'link',
            show: props.ticketsList.length !== 0,
            sortable:false,
            // width: 100,
            // Filter: ({ filter, onChange }) => (
            //     <input
            //         onChange={event => onChange(event.target.value)}
            //         value={filter ? filter.value : ''}
            //         placeholder={'Search Account Number...'}
            //         className={'form-control input-modifier'}
            //     />
            // ), 
            // String-based value accessors!
        },
        {
            Header: '',
            accessor: 'copy',
            show: props.ticketsList.length !== 0,
            sortable:false,
            width: 160,
        },
        {
            Header: <><span>Created By</span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /></>,
            accessor: 'createdBy',
            show: props.ticketsList.length !== 0,
            sortable:false,
            // width: 100,
            // Filter: ({ filter, onChange }) => (
            //     <input
            //         onChange={event => onChange(event.target.value)}
            //         value={filter ? filter.value : ''}
            //         placeholder={'Search Account Number...'}
            //         className={'form-control input-modifier'}
            //     />
            // ), 
            // String-based value accessors!
        },
        {
            Header: <><span>Created On</span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
            accessor: 'createdOn',
            // width: 190,
            show: props.ticketsList.length !== 0,
            sortable:false,
            // show: false
            // Cell: props => <span className='number'>{props.value}</span> // Custom cell components!
        },
        
        {
            Header: '',
            accessor: 'actions',
            // width: 135,
            sortable:false,
            // show: false
        }
    ]
    const [tablePage, setTablePage] = useState(0)
    const tablePageNew = React.useRef()

    if (ticketPage !== tablePageNew.current) {
        tablePageNew.current = ticketPage;
    }

    const setTablePageFn = (page) => {
        setTablePage(page)
        props.ticketPageClicked(page)
        tablePageNew.current = page;
    }
    const fetchData = (state, instance) => {
        if (SearchOrResetScheduledTaskListClicked) {
            dispatch(actions.resetSearchOrResetValue())
        } else {
            if (!DateGenerate && !props.isTicketPending) {
                state.page = 2;
                const limit = state.pageSize;
                let page = tablePageNew.current >= 0 ? tablePageNew.current + 1 : restroomPorteringCurPage + 1;
                const inventoryItemsList_no_of_rows = state.pageSize;
                if(No_of_rows != inventoryItemsList_no_of_rows) {
                    page = 1;
                    setTablePageFn(0)
                }
                const order = state.sorted[0]?.id;
                const sorted = state.sorted;
                const orderBy = state.sorted && state.sorted.length ? state.sorted[0]?.desc === true ? 'desc' : 'asc' : null;
                // const from_date = SearchValue.fromDate ? moment(values.fromDate).format('DD-MM-YYYY') : null;
                // const to_date = SearchValue.toDate ? moment(values.toDate).format('DD-MM-YYYY') : null;
                const filtered = state.filtered;
                const id = values?.id ? values?.id : null;
                const jobGroupName = values?.jobGroupName ? values?.jobGroupName : null;
                const createdby=values?.createdby ? values?.createdby : null;
                const from =values?.from ? moment(values.from).format('YYYY-MM-DD') : null;
                props.getTickets(
                    { page, limit, order, orderBy, id, jobGroupName, createdby, from}, values
                );

                
            }
        }
        
        // }
        // }
    }

    return (
        SearchOrResetTicektClicked && props.isTicketPending ?
            <div className="loader_btn_block">
                <ClapSpinner backColor="#89073c" size={40} frontColor="#89073c8a" />
            </div>
            :
            <div>
                <ReactTable
                    data={data}
                    loading={props.isTicketPending}
                    defaultPageSize={No_of_rows}
                    minRows={0}
                    showPaginationTop={true}
                    manual
                    onFetchData={fetchData}
                    pages={No_of_pages}
                    gotoPage={ticketPage}
                    page={
                        ticketPage
                    }
                    onPageChange={(page) => setTablePageFn(page)}
                    // onPageSizeChange={props.onAccountsPageSizeChange}
                    NoDataComponent={() => CustomNoDataComponent(props.isTicketPending, 'No Tickets Found')}
                    showPagination={props.ticketsList.length !== 0}
                    // filterable={true}
                    columns={columns}
                    LoadingComponent={() =>
                        props?.isTicketPending ?
                            <div style={{
                                display: "block",
                                position: "absolute",
                                left: 0,
                                height: '100%',
                                right: 0,
                                background: "rgba(255,255,255,0.8)",
                                transition: "all .3s ease",
                                top: 0,
                                bottom: 0,
                                textAlign: "center"
                            }}
                                className="loader_btn_block">
                                <ClapSpinner backColor="#89073c" size={40} frontColor="#89073c8a" />
                            </div> : null}
                    // style={{marginTop: '10px'}}
                    getTheadThProps={(state, rowInfo, column, instance) => {

                        return {
                            style: {
                                borderTop: 'none',
                                borderBottom: 'none',
                                borderRight: 'none',
                                textAlign: 'center',
                                color: '#566975',
                                fontSize: '14px',
                                fontWeight: '600',
                                whiteSpace: 'nowrap',
                                float: 'left',
                            }
                        }
                    }
                    }
                    getTrProps={(state, rowInfo, column, instance) => {
                        // onclick = () => userDetail(rowInfo ? rowInfo.row.user : rowInfo);
                        console.log(column, 'testing', rowInfo.original.status);
                        // 1px solid #ec9720
                        if (rowInfo.original.pegassure_feedback_id) {
                            return {
                                className: rowInfo.original.status === 'Open' ? 'react-table-tr-element-open-status  react-table-tr-element-ticket prior-ticket-color' : rowInfo.original.status === 'Pending' ? 'react-table-tr-element-pending-status  react-table-tr-element-ticket prior-ticket-color' : 'react-table-tr-element-close-status react-table-tr-element-ticket prior-ticket-color',
                                style: {
                                    border: '1px solid #c7cdd1',
                                    transition: '.3s ease-in-out',
                                    width: '100%',
                                    // borderRadius: '4px',
                                    // height: '80px',
                                    marginTop: '5px',
                                    float: 'left'
                                }
                            }
                        } else {
                            return {
                                className: rowInfo.original.status === 'Open' ? 'react-table-tr-element-open-status  react-table-tr-element-ticket ' : rowInfo.original.status === 'Pending' ? 'react-table-tr-element-pending-status  react-table-tr-element-ticket' : 'react-table-tr-element-close-status react-table-tr-element-ticket',
                                style: {
                                    border: '1px solid #c7cdd1',
                                    transition: '.3s ease-in-out',
                                    width: '100%',
                                    // borderRadius: '4px',
                                    // height: '80px',
                                    marginTop: '5px',
                                    float: 'left'
                                }
                            }
                        }

                    }}
                    getTdProps={(state, rowInfo, column, instance) => {
                        // if (column.Header == 'Active' || column.Header == 'Actions') {
                        if (column.id == 'image') {
                            return {
                                style: {
                                    marginTop: '0px'
                                }
                            }
                        } else {
                            return {
                                className: 'react-td-element'
                            }
                        }
                        //  {
                        //   style: {
                        //     borderTop: 'none',
                        //     verticalAlign: 'middle',
                        //     fontSize: '14px',
                        //     color: '#2b3034',
                        //     marginTop: '10px !important',
                        //     textAlign: 'center',
                        //   }
                        // }
                    }
                    }
                    // getPaginationProps={() => {
                    //     return {
                    //         style: {
                    //             marginTop: '50px',
                    //             border: 'none',
                    //             boxShadow: 'none'
                    //         }
                    //     }
                    // }
                    // }
                    getProps={() => {
                        return {
                            style: {
                                border: 'none'
                            }
                        }
                    }}
                />
            </div>
    )
}

export default TicketTable;