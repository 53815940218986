import React, { Component } from 'react';
import * as actions from '../../../../redux/actions/index';
import { connect } from 'react-redux';
import { animateScroll as scroll} from 'react-scroll'
import LocationAssociation from '../../../component/Inventory/LocationComponent/LocationAssociation/LocationAssociationList';

class LocationAssociationContainer extends Component {
  componentDidMount() {
    if (!this.props.locationAssociation.length) {
    const associate = {
      isAssociated : true
    }
      this.props.getLocationAssociation(associate);
  }
    scroll.scrollToTop();
  }

  editLocationAssociationClicked = (value) =>{
    this.props.editLocationAssociationClicked(value)
    const name = {
      isAssociated : true
    }
    this.props.getLocationName(name)
  }

  newLocationAssociationClicked = () =>{
    this.props.openPopup()
    const name = {
      nonAssociated : true
    }
    this.props.getLocationName(name)
    this.props.newLocationAssociationClicked()
  }

  render() {
    return (
        <LocationAssociation
            locationAssociation={this.props.locationAssociation ? this.props.locationAssociation : []}
            openDeleteModel={this.props.openDeleteModel}
            handleDeleteClose={this.props.handleDeleteClose}
            editLocationAssociationClicked={this.editLocationAssociationClicked}
            deleteAreaType={this.props.deleteAreaType}
            deleteObject={this.props.deleteObject}
            handleClickDeleteModel={this.props.handleClickDeleteModel}
            isLoading={this.props.isLoading}
            newLocationAssociationClicked={this.newLocationAssociationClicked}
            />
    )
  }
}

const mapStateToProps = (state) => {
  return {
    locationAssociation: state.InventoryReducer.locationAssociation,
    isLoading: state.adminOrSuperAdminReducer.isLoading,
    deleteObject: state.adminOrSuperAdminReducer.deleteObject,
    openDeleteModel: state.adminOrSuperAdminReducer.openDeleteModel,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    handleDeleteClose: () => dispatch(actions.handleDeleteClose()),
    deleteAreaType: (id) => dispatch(actions.deleteAreaType(id)),
    getLocationAssociation: (associate) => dispatch(actions.getLocationAssociation(associate)),
    openPopup: () => dispatch(actions.openPopupInventory()),
    newLocationAssociationClicked: () => dispatch(actions.newLocationAssociationClicked()),
    editLocationAssociationClicked: (location) => dispatch(actions.editLocationAssociationClicked(location)),
    handleClickDeleteModel: (account) => dispatch(actions.handleClickDeleteModel(account)),
    getLocationName: (params) => dispatch(actions.getLocationName(params)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LocationAssociationContainer);