import React from 'react';
import { userRoles, routes } from '../../../utility/constants/constants';
import QuotesTable from './quotesTable';
import RejectQuote from './rejectQuote';
import { Input } from '../UI/InputElement/InputElement';
import { reduxForm, formValueSelector } from 'redux-form';
import { searchQuotes as validate } from '../../../utility/validator/validator';
import { StatusOptions } from '../../../utility/constants/constants';
import { convertQuotesDataToCsvType } from '../../../utility/utility';
import { connect } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import { CSVLink, CSVDownload } from "react-csv";
import ReactTooltip from 'react-tooltip';
import '../../../styles/custom.css';

let Quotes = (props) => {

    let statusOptions = StatusOptions.filter(status => {
        // if (status.id === 'draft') {
        //     if ((props.user.role !== userRoles.ADMIN && props.user.role !== userRoles.SUPER_ADMIN)) {
        //         return status;
        //     }
        // } else 
        if (status.id === 'auto_approved_quote' || status.id === 'draft') {
            if (props.user.role !== userRoles.INSPECTOR) {
                return status;
            }
        }
        else {
            return status;
        }
    })
    console.log('statusOptions', statusOptions);

        ;
    const { handleSubmit, pristine, reset, submitting, error } = props;
    return (
        <main className={props.user.role === userRoles.ADMIN || props.user.role === userRoles.REQUESTOR || props.history.location.pathname === routes.VIEW_FILTERED_QUOTES ? "app-content  wraper_content inspection_wraper admin_quotes" : "app-content  wraper_content inspection_wraper"}>
            <div  className={props.user.role === userRoles.ADMIN || props.user.role === userRoles.REQUESTOR ? "tab_header search_filter_cont inspection_tab_content appcontent_inner quotes_head" : "tab_header search_filter_cont inspection_tab_content appcontent_inner quotes_head"} >
                {/* <div className="tab_header search_filter_cont"> */}
                <form onSubmit={handleSubmit}>
                    <input type="hidden" value="something" />
                    <div className="row">
                        <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                            <div className="app-search">
                                <Input className="fil_search_input" name="name" type="search" placeholder="Search By Job" />
                                <button className="app-search__button">
                                    <i class="fa fa-search" aria-hidden="true"></i>
                                </button>
                            </div>
                        </div>
                        {props.user.role === userRoles.ADMIN || props.user.role === userRoles.REQUESTOR || props.user.role === userRoles.INSPECTOR ? null :
                            <div className="col-xl-2 col-lg-4 col-md-12 col-sm-12">
                                <div className="app-search">
                                    <Input className="fil_search_input" name="region" type="search" placeholder="Region" />
                                    <button className="app-search__button">
                                        <i class="fa fa-search" aria-hidden="true"></i>
                                    </button>
                                </div>
                            </div>
                        }
                        <div className="col-xl-2 col-lg-4 col-md-12 col-sm-12">
                                <div className="app-search">
                                    <Input className="fil_search_input" name="id" type="number" placeholder="Search By ID" />
                                    <button className="app-search__button">
                                        <i class="fa fa-search" aria-hidden="true"></i>
                                    </button>
                                </div>
                            </div>

                        {props.isDashboardFilter ? null :
                            <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                                <Input name="Status" type="select" options={statusOptions} placeholder="Search By Status" className="custom-select input-modifier add_user_select" />

                                {/* <select className="custom-select input-modifier">
                                <option value="All">All</option>
                                <option value="Pending for Approva">Pending for Approval</option>
                                <option value="Sent to Clients">Sent to Clients</option>
                                <option value="Approved by Clients">Approved by Clients</option>
                            </select> */}
                            </div> 
                        }
                        <div className={(props.isDashboardFilter && props.user.role === userRoles.REQUESTOR) ? 'col-xl-4 col-lg-3 col-md-4 col-sm-4 quotes_action_btn' : props.user.role === userRoles.ADMIN || props.user.role === userRoles.INSPECTOR || props.history.location.pathname === routes.VIEW_FILTERED_QUOTES ? "col-xl-6 col-lg-6 col-md-4 col-sm-4 quotes_action_btn" : props.isDashboardFilter ? "col-xl-6 col-lg-6 col-md-4 col-sm-4 quotes_action_btn" : "col-xl-6 col-lg-6 col-md-4 col-sm-4 quotes_action_btn"} >
                            <button style={{ minWidth: '90px' }} className={"btn cst_btn btn_danger mr-2 quotes_btn"} id="submit" type="submit">Go</button>
                            <button style={{ minWidth: '90px' }} onClick={props.resetClicked} className={"btn cst_btn btn-outline-secondary quotes_btn"} id="reset" type="reset">Reset</button>
                        </div>
                        {/* <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                            <a href="javascript:void(0)" className="out_line_link">Go</a>
                            <a href="javascript:void(0)" className="out_line_link out_line_link_rest">Reset</a>
                        </div>  */}
                        {/* {props.user.role === userRoles.REQUESTOR ? */}

                        {/* : null */}
                        {/* } */}
                        {props.user.role === userRoles.INSPECTOR || props.fromDashboard ? null :
                            <div id="quote_tooltip" className={(props.isDashboardFilter && props.user.role === userRoles.REQUESTOR) ? 'col-xl-4 col-lg-5 col-md-8 col-sm-8 text_right quotes_btn_wrap' : props.user.role === userRoles.ADMIN || props.history.location.pathname === routes.VIEW_FILTERED_QUOTES ? "col-xl-6 col-lg-6 col-md-8 col-sm-8 text_right quotes_btn_wrap" :
                                props.isDashboardFilter ? "col-xl-6 col-lg-6 col-md-8 col-sm-8 text_right quotes_btn_wrap" : "col-xl-6 col-lg-6 col-md-8 col-sm-8 text-right quotes_btn_wrap"}>
                                <a onClick={props.newManualQuoteClicked} data-tip="Email will not be sent to customers" href="javascript:void(0)" className="btn btn-secondary cst_btn new_qut_btn" data-test="create_manual_quote_btn"><i className="fa fa-plus icn_plus"></i> Internal Quote</a>
                                <a onClick={props.newQuoteClicked} data-tip="Email will be sent to customers"  href="javascript:void(0)" className="btn btn-secondary cst_btn new_qut_btn" data-test="create_new_quote_btn"><i className="fa fa-plus icn_plus"></i> External Quote</a>
                                <ReactTooltip id="custom_tooltip" class="custom_tooltip" place="top" type="dark" effect="solid" />
                            </div>
                        }
                    </div>

                </form>
            </div>

            <section className="account_sec inspection_tab_content appcontent_Inner">
                <div className="export_csv_block">
                    <div className="row">
                        <div className="col-xl-3 col-lg-4 col-md-4 col-sm-12">
                            <label>
                                <Input name="fromDate" maxDate={props.selectedValues.toDate ? props.selectedValues.toDate : new Date()} dateFormat="MM-dd-yyyy" placeholder="From Date" datePickerType={"react-datepicker"} type="date" className="form-control input-modifier" />
                                <div class="input-group-append cst_group_append">
                                    <span class="input-group-text" id="basic-addon2"><i class="fa fa-calendar"></i></span>
                                </div>
                            </label>
                        </div>
                        <div class="col-xl-3 col-lg-4 col-md-4 col-sm-12">
                            <label>
                                <Input name="toDate" minDate={props.selectedValues.fromDate ? props.selectedValues.fromDate : null} maxDate={new Date()} dateFormat="MM-dd-yyyy" placeholder="To Date" datePickerType={"react-datepicker"} type="date" className="form-control input-modifier" />
                                <div class="input-group-append cst_group_append">
                                    <span class="input-group-text" id="basic-addon2"><i class="fa fa-calendar"></i></span>
                                </div>
                            </label>
                        </div>
                        {props.quotesList && props.quotesList.length === 0 ? null :
                            <div className="col-xl-6 col-lg-4 col-md-4 col-sm-12">
                                <CSVLink filename="Quotes.csv" className="csv_btn btn_danger " data={convertQuotesDataToCsvType(props.quotesList)} >Export CSV</CSVLink>
                            </div>
                        }
                    </div>
                </div>
                <div className="quotes_table_content table-responsive" id="quotes_table_align">
                    <QuotesTable
                        {...props}
                        quotesList={props.quotesList ? props.quotesList : []}
                        isDescFull={props.isDescFull}
                        totalSalesArray={props.totalSalesArray}
                        clickedQuoteId={props.clickedQuoteId}
                        lessDescClicked={props.lessDescClicked}
                        moreDescClicked={props.moreDescClicked}
                        // onQuotesPageSizeChange={props.onQuotesPageSizeChange}
                        user={props.user}
                        isQuotesLoading={props.isQuotesLoading}
                        totalSalesChange={props.totalSalesChange}
                        isLoading={props.isLoading}
                        approvedCliked={props.approvedCliked}
                        openRejectModel={props.openRejectModel}
                        editTotalClicked={props.editTotalClicked}
                        totalSaleClickedQuoteId={props.totalSaleClickedQuoteId}
                        submitTotalSaleClicked={props.submitTotalSaleClicked}
                        openDeleteModel={props.openDeleteModel}
                        handleClickDeleteModel={props.handleClickDeleteModel}
                        deleteObject={props.deleteObject}
                        quotes_rows={props.quotes_rows}
                        resendEmailCliked={props.resendEmailCliked}
                        viewQuoteStatusClicked={props.viewQuoteStatusClicked}
                    />
                </div>
                {/* </div> */}
                {/* <!-- reject quotes Modal --> */}
                {props.openRejectQuoteModel ? <RejectQuote
                    handelRejectClicked={props.handelRejectClicked}
                    isLoading={props.isLoading}
                    onFieldChange={props.onFieldChange}
                    isRejectError={props.isRejectError}
                    closeRejectModel={props.closeRejectModel}
                /> :
                    null}

                <Dialog
                    open={props.openDeleteModel}
                    onClose={props.handleClose}
                    aria-labelledby="form-dialog-title"
                >
                    <DialogTitle id="form-dialog-title" style={{ fontSize: '13', color: '#06080f' }}>
                        <h3 style={{ color: '#ab385e' }}>Are you sure?</h3>
                    </DialogTitle>

                    <DialogActions>
                        <button style={{ minHeight: '36px', minWidth: '80px' }} onClick={() => props.deleteQuote(props.deleteObject.id)} disabled={props.isLoading} className={props.isLoading ? "btn cst_btn btn_danger mr-2 btn-wait" : "btn cst_btn btn_danger mr-2"} id="delete" type="delete">{props.isLoading ? "" : "Delete"}</button>
                        <button style={{ minHeight: '36px', minWidth: '80px' }} onClick={props.handleDeleteClose} className={"btn cst_btn btn-outline-secondary"} id="cancel" type="cancel">Cancel</button>
                        {/* <Button onClick={props.handleClose} variant="contained" color="primary">
                        Cancel
                        </Button>


                    <Button onClick={() => props.deleteAccount(props.deleteObject.id)} disabled={props.isLoading} variant="contained" color="secondary">
                        {props.isLoading ? <CircularProgress size={24} /> : 'Delete'}
                    </Button> */}
                    </DialogActions>
                </Dialog>
                {/* <!-- // reject quotes Modal --> */}

            </section>
        </main >

    )
}

const mapStateToProps = (state) => {
    let Status = state.adminOrSuperAdminReducer.searchQuoteValues && state.adminOrSuperAdminReducer.searchQuoteValues.value && state.adminOrSuperAdminReducer.searchQuoteValues.value.Status ? state.adminOrSuperAdminReducer.searchQuoteValues.value.Status : "";
    let name = state.adminOrSuperAdminReducer.searchQuoteValues && state.adminOrSuperAdminReducer.searchQuoteValues.value && state.adminOrSuperAdminReducer.searchQuoteValues.value.name ? state.adminOrSuperAdminReducer.searchQuoteValues.value.name : "";
    let region = state.adminOrSuperAdminReducer.searchQuoteValues && state.adminOrSuperAdminReducer.searchQuoteValues.value && state.adminOrSuperAdminReducer.searchQuoteValues.value.region ? state.adminOrSuperAdminReducer.searchQuoteValues.value.region : "";
    let id = state.adminOrSuperAdminReducer.searchQuoteValues && state.adminOrSuperAdminReducer.searchQuoteValues.value && state.adminOrSuperAdminReducer.searchQuoteValues.value.id ? state.adminOrSuperAdminReducer.searchQuoteValues.value.id : "";

    return { initialValues: { id, Status, name, region } }
}

Quotes = reduxForm({
    form: 'searchQuoteForm',
    validate,
    enableReinitialize: true
})(Quotes);

const selector = formValueSelector('searchQuoteForm') // <-- same as form name
Quotes = connect(state => {

    const { fromDate, toDate } = selector(state, 'fromDate', 'toDate')
    const selectedValues = { fromDate, toDate }
    return {
        selectedValues
    }
})(Quotes)

Quotes = connect(mapStateToProps)(Quotes)

export default Quotes;