import React from 'react';
import { Input } from '../UI/InputElement/InputElement';
import { reduxForm } from 'redux-form';
import { ForgetOrResetPassword as validate } from '../../../utility/validator/validator';

const ForgetAndResetPasssowrd = (props) => {

    let pathName = window.location.pathname;

    const { handleSubmit, pristine, reset, submitting, error } = props;

    return (
        <form className="login100-form" id="login_form" onSubmit={handleSubmit}>
            <div className="inner_form">
                <div className="fields">

                    {props.token ?
                        <>
                            {pathName && pathName == '/resetpassword' ? (
                                <>
                                    <h1>Welcome!</h1>
                                    <p>Please set up your password below.</p>
                                </>
                            ) : (
                                <>
                                    <h1>{props.token.invite ? 'Set password' : 'Reset password'}</h1>
                                    <p>Please {props.token.invite ? 'set' : 'reset'} your password here.</p>
                                </>
                            )}

                            <div className="form-group">
                                <label for="password" className="label_modifier">New Password</label>
                                <Input type="password" autocomplete="new-password" className="input_modify input_modify_lg" placeholder="" name="user[password]" />
                            </div>
                            <div className="form-group">
                                <label for="password" className="label_modifier">Confirm Password</label>
                                <Input type="password" autocomplete="new-password" className="input_modify input_modify_lg" placeholder="" name="user[password_confirmation]" />
                            </div>
                        </>
                        :
                        <>
                            <h1 data-test="forgot_password_title">Forgot password</h1>
                            <p>Please enter the email address you used when creating your account.</p>

                            <div className="form_group_modify">
                                <label for="password" className="label_modify">ACCOUNT EMAIL ADDRESS</label>
                                <Input dataTest="email" id="email" type="email" className="input_modify input_modify_lg" name="user[email]" />
                                <div data-test="error_msg" className="form-error invisible"> &nbsp;</div>
                            </div>
                        </>
                    }
                </div>
                <button data-test="submit_button" disabled={props.isLoading} className={props.isLoading ? "theme_btn btn_lg btn_load btn-wait button_Disable" : "theme_btn btn_lg btn_load"} id="submit" type="submit" data-test="submit_button">{props.token ? props.isLoading ? "Please wait" : "Submit" : props.isLoading ? "Please wait" : "Send reset email"}</button>
                <br />
                <span style={{ cursor: 'pointer' }} className="underline forgot_pass" onClick={props.signInClicked}>← Go back to sign-in</span>
            </div>
        </form>

    )
}

export default reduxForm({
    form: 'resetAndForgetPasswordForm',
    validate
})(ForgetAndResetPasssowrd);