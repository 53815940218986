import React from 'react';
import TicketScheduleHistoryTable from './TicketScheduleHistoryTable';

const TicketScheduleHistory = (props) => {
    const { handleSubmit, pristine, reset, submitting, error } = props;
    return (
        <>
        <main className="app-content wraper_content inspection_wraper">
            <div className="tab_header search_filter_cont inspection_tab_content appcontent_inner frontlines_filter mb-2 text-center">
                <span style={{fontFamily: 'system-ui', fontSize: '210%', color: '#7e3d5e'}}> Ticket Schedule History </span>
            </div>

            <section className="account_sec user_screen inspection_tab_content">
                <div className="quotes_table_content table-responsive">
                    <div className="table quotes_table user_table_contnet user_react_table account_table">
                    <TicketScheduleHistoryTable
                        {...props}
                    />
                    </div>
                </div>
            </section>
        </main>
        </>
    );
}

export default TicketScheduleHistory;