import React, { useState, useEffect } from 'react';
import { Button } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import errorHandler from '../../../../utility/errorHandler/errorHandler';
import { checkSizeMatch, generateArrayBufferFromPDF, generateFileSizeFormat, generateFileSizeHandler } from '../../../../utility/utility';
import { FilePdfFilled } from '@ant-design/icons';
import { Card, Empty, Skeleton } from 'antd';
const { Meta } = Card;
const cloneDeep = require('clone-deep');

let PegassurePlaybookPdfForm = (props) => {

    const { register, handleSubmit, reset, unregister, watch, setValue, formState: { errors } } = useForm({
        defaultValues: {
            pdfTitle: '',
            pdfDoc: '',
        }
    });

    let isPdfTitle = watch('pdfTitle');
    let isPdfDoc = watch('pdfDoc');
    const [isPdfNotAccept,setPdfAccept]=useState(false)
    const [isPdfFetchLoad, setIsPdfFetchLoad] = useState(false);

    useEffect(() => {
        setValue('pdfTitle', props?.soloPlaybookPDFInfo?.title);
    }, [setValue, props?.isPlaybookPdfEdit && props?.playbookId && props?.soloPlaybookPDFInfo?.id]);


    useEffect(()=>{
        
        if(isPdfDoc && isPdfDoc.length){
            let size=generateFileSizeHandler(isPdfDoc[0]?.size)
            const accept= checkSizeMatch(size)
            if(accept==true) {
            setPdfAccept(true)
             errorHandler("PLease select a pdf with lessthan 19MB.")
             
            }else{

                setPdfAccept(false)
            }
        }
        else{
            setPdfAccept(false)
        }
        
    },[isPdfDoc])
    
    const handlePdfBrowseLoader = (event) => {
        setIsPdfFetchLoad(true);
        
        setTimeout(() => {
            setIsPdfFetchLoad(false);
        }, 7000);
    };


    

    const handlePdfClearAction = () => {
        reset({ pdfDoc: '' });
        isPdfDoc = '';
        
    };

    const handleFormSubmerge = async (fieldValues) => {
        let fetchedValue = cloneDeep(fieldValues);
        let setData = { data: {} };
        let arrayBuffer = null;
        let extension = '.pdf';

        if (props?.isPlaybookPdfEdit && props?.soloPlaybookPDFInfo?.id) {

            if (isPdfTitle?.length > 0) {

                setData.data.title = fetchedValue.pdfTitle;
                setData.data.photoPath = props?.soloPlaybookPDFInfo?.pdf_path;
                props.handlePlaybookFormSubmit({ setData });

            }

        } else {

            if (isPdfTitle?.length > 0 && isPdfDoc?.length > 0) {

                setData.data.title = fetchedValue.pdfTitle;
                setData.data.photoPath = null;
                
                
                try {
                    arrayBuffer = await generateArrayBufferFromPDF(fetchedValue.pdfDoc[0]);
                    props.handlePlaybookFormSubmit({ setData, arrayBuffer, extension });
                } catch (error) {
                    console.error("Error converting PDF to array buffer:", error);
                }

            } else {
                if (!(isPdfTitle?.length > 0)) {
                    errorHandler('Playbook name is required')
                    return false;
                }
                if (!(isPdfDoc?.length > 0)) {
                    errorHandler('Playbook Pdf is required')
                    return false;
                }
            }

        }

    };

    const handleClosePlaybookFormModal = () => {
        
        unregister('pdfTitle'); // Unregister the pdfTitle field
        unregister('pdfDoc'); // Unregister the pdfDoc field
        reset({ pdfTitle: '', pdfDoc: '' }); // Clear both pdfTitle and pdfDoc fields
        isPdfTitle = '';
        isPdfDoc = '';
        setIsPdfFetchLoad(false);
        setPdfAccept(false)
        props.resetPegassurePlaybookEvents();
    }

    return (
        <>
            <div className="modal fade request_quote_modal reject_quot associateUser" id="pegassurePlaybookPdfForm" tabindex="-1" role="dialog" aria-labelledby="rejectQuoteModalTitle" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header d-flex justify-content-center">
                            <label for="exampleInputEmail1" className="cst-label md_title">{props?.isPlaybookPdfEdit ? 'Playbook PDF Form (Update)' : 'Playbook PDF Form (Upload)'}</label>
                            {/* <button type="button" className="close close_icn_pic" data-dismiss="modal" onClick={props.handleFrequentTimeTracker_closeModal}><img src="/images/icons/icn_close_gray.png" alt="Close Icon" /></button> */}
                        </div>

                        <div className="modal-body">
                            <div className="tab-content">
                                <div id="profile" className="profile_tab cst_tab_content tab-pane fade show active edit_profile_modal">
                                    <form className="form_content" onSubmit={handleSubmit(handleFormSubmerge)}>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label className="form_title fw-bold"> Playbook Name <sup className="cst_sup_txt text_danger">*</sup> </label>
                                                    <input type="text" className="form-control input-modifier" id="pdfTitle" name="pdfTitle" {...register("pdfTitle", { required: 'Name is required' })} />
                                                    {errors.pdfTitle && <p class="text-danger fsize_15 text-align-start mt-1">{errors.pdfTitle.message}</p>}
                                                </div>

                                                <div className="form-group playBookPdfBox" style={{ display: 'flex', flexDirection: 'column' }}>
                                                    <label className="form_title fw-bold"> Playbook Pdf File {props?.isPlaybookPdfEdit ? null : (<sup className="cst_sup_txt text_danger">*</sup>)} </label>
                                                    {props?.isPlaybookPdfEdit ? (
                                                        <Card style={{ width: 450, maxWidth: 450, marginTop: 5 }}>
                                                            <Meta
                                                                avatar={<FilePdfFilled key="pdfIcon" />}
                                                                title={props?.soloPlaybookPDFInfo?.id}
                                                                description='Playbook PDF'
                                                            />
                                                        </Card>
                                                    ) : (
                                                        <Card style={{ width: 450, maxWidth: 450, marginTop: 5 }}
                                                            actions={[
                                                                <>
                                                                    <input accept=".pdf" type="file" className='d-none' id="pdfDoc" name="pdfDoc" multiple onClick={(e) => { handlePdfBrowseLoader(e); }}  {...register("pdfDoc", { required: 'PDF is required' })} />
                                                                    <label htmlFor="pdfDoc"> <Button className="btn cst_btn" variant="contained" size='large' fullWidth={true} component="span"> BROWSE </Button> </label>
                                                                </>,
                                                                <>
                                                                    <label> <Button className="btn cst_btn" variant="contained" size='large' fullWidth={true} component="span" onClick={() => { handlePdfClearAction(); }}> Clear </Button> </label>
                                                                </>
                                                            ]}
                                                        >
                                                            <Skeleton loading={isPdfFetchLoad} avatar active>
                                                                {isPdfDoc && isPdfDoc[0]?.name ? (
                                                                    <Meta
                                                                        avatar={<FilePdfFilled key="pdfIcon" />}
                                                                        title={isPdfDoc[0]?.name}
                                                                        description={generateFileSizeFormat(isPdfDoc[0]?.size)}
                                                                    />
                                                                ) : (
                                                                    <Empty description={errors?.pdfDoc?.message ? <p class="text-danger fsize_15 text-align-start mt-1">{errors.pdfDoc.message}</p> : 'Scout PDF'} />
                                                                )}
                                                            </Skeleton>
                                                        </Card>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="clearfix"></div>
                                        <div className="modal-footer">
                                            <button onClick={() => { handleClosePlaybookFormModal(); }} id='closePlaybookPdfUploadModal' type="button" className="btn btn-outline-secondary cst_btn" data-dismiss="modal">Close</button>
                                            <button data-test="submit_button" type="submit" disabled={props.isLoading || isPdfNotAccept || isPdfFetchLoad} className={props.isLoading ? "btn cst_btn btn_danger mr-2 btn-wait" : "btn cst_btn btn_danger mr-2"} >{props.isLoading ? "" : props?.isPlaybookPdfEdit ? 'Update' : "Upload"}</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    );
};

export default PegassurePlaybookPdfForm;