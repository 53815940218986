import React, { Component } from 'react';
import storage from '../../../utility/storage';
import { connect } from 'react-redux';
import { routes, TimeZones } from '../../../utility/constants/constants';
import Quotes from '../../component/Quotes/quotes';
import * as actions from '../../../redux/actions/index';
import { getWeekStartAndEndDay, convertDateToDifferentTZ } from '../../../utility/utility';
import { reset } from 'redux-form';
import QuoteStatusModal from '../../component/Quotes/quoteStatusModal';
import history from '../../../router/history';
import { animateScroll as scroll} from 'react-scroll'
const cloneDeep = require('clone-deep');
var moment = require('moment');


class QuotesContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            quote: null,
            quoteIds: [],
            totalSaleQuoteIds: [],
            totalSalesArray: [],
            isRejectError: false,
            fromDashboard: false,
            pathname: ''
        }
    }

    componentDidMount() {
        if(this.props.history) {
            this.setState({fromDashboard: this.props.history.location.pathname.includes('dashboard'), pathname: this.props.history.location.pathname});
        }
        // if (this.props.history.location.pathname === routes.VIEW_FILTERED_QUOTES) {
        //     if (!this.props.filteredQuotesList) {
        //         this.props.updateWeekMonthYear(this.props.history.location.state.isWeek, this.props.history.location.state.isMonth, this.props.history.location.state.isYear);
        //         this.props.updateCurrentDate(this.props.history.location.state.currentDate);
        //         if (this.props.history.location.state.isWeek) {

        //             const startAndEndDate = getWeekStartAndEndDay(this.props.history.location.state.currentDate);
        //             this.props.viewWeeklyFilterQuotes(this.props.history.location.state.Status, startAndEndDate.firstday, startAndEndDate.lastday, this.props.history.location.state.currentDate);
        //         } else if (this.props.history.location.state.isMonth || this.props.history.location.state.isYear) {
        //             let currentDate = moment(this.props.history.location.state.currentDate, 'MM/DD/YYYY');
        //             const month = currentDate.format('M');

        //             const year = currentDate.format('YYYY');;
        //             this.props.viewMonthlyAndYearlyFilterQuotes(this.props.history.location.state.Status, month, year, this.props.history.location.state.isMonth, this.props.history.location.state.isYear, this.props.history.location.state.currentDate);
        //         }
        //     }
        // } else {
        //     if (!this.props.quotesList) {
        //         this.props.getQuotes();
        //     }
        // }

        scroll.scrollToTop();
    }

    componentWillMount() {
        console.log(this.state);

    }

    componentDidUpdate = (prevProps, prevState) => {
        const user = storage.get('user');
        if(user) {
        if(prevProps.user.selected_mode !== user.selected_mode) {
            this.props.quotePageClicked(0);
        }
    }
        if (this.props.location.pathname === routes.QUOTES && prevProps.location.pathname === routes.VIEW_FILTERED_QUOTES) {
            this.props.resetObjects();
            this.props.reset('searchQuoteForm');
            this.props.searchQuotes({});
        }

        if(prevProps.history.location.pathname !== prevState.pathname && prevProps.history.location.pathname !== this.state.pathname) {
            this.setState({fromDashboard: this.props.history.location.pathname.includes('dashboard'), pathname: this.props.history.location.pathname});
        }
    }

    componentWillUnmount = () => {
         
        if (!this.props.history.location.pathname.includes(`quoteapproved`)) {
            // this.props.resetObjects();
        }
    }

    totalSalesChange = (e, id) => {

        let totalSales = cloneDeep(this.props.totalSalesArray);
        const index = totalSales.findIndex(ts => (ts[0] === id))
        if (index >= 0) {
            totalSales[index] = [id, e.target.value];
            this.props.updateTotalSalesArray(totalSales);
            // this.setState({
            //     totalSalesArray: totalSales
            // })
        } else {
            totalSales = [...this.props.totalSalesArray, [id, e.target.value]];

            this.props.updateTotalSalesArray(totalSales);
            // this.setState({
            //     totalSalesArray: [...this.props.totalSalesArray, [id, e.target.value]]
            // })
        }
    }

    newQuoteClicked = () => {
        this.props.resetObjects();
        this.props.history.push(routes.NEW_QUOTES);
    }

    newManualQuoteClicked = () => {
        this.props.resetObjects();
        this.props.history.push(routes.MANUALL_QUOTE);
    }

    openRejectModel = (quote) => {
        this.props.openRejectModel();
        this.setState({
            quote: quote
        })
    }

    closeRejectModel = () => {
        this.props.closeRejectModel();
    }

    onFieldChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    handelRejectClicked = () => {
        console.log(this.state.quote);
        //   
        if (this.state.reject_reason && this.state.reject_reason !== '') {
            this.setState({
                isRejectError: false
            })
            const body = {
                status: 'rejected',
                reject_reason: this.state.reject_reason,
                rejected_by_user_id: this.props.user.id,
                total_sale: parseFloat(this.state.quote.total_sale.replace(/\,/g, ''))
            }
            this.props.handelApproveOrRejectClicked(this.state.quote.id, body);
        } else {
            this.setState({
                isRejectError: true
            })
        }
    }

    approvedCliked = (quote) => {
        storage.set('UpdatedTotalSale', this.props.totalSalesArray);
        this.props.getQuote(quote.id);
        history.push(`/quotes/${quote.id}/quoteapproved`);

        // this.props.goToApprovedQuote(quote, this.props.totalSalesArray);
    }

    goClicked = (data) => {

        if(data.account_name) {
            data.account_name = data.account_name.trim()
        }
        if(data.region) {
            data.region = data.region.trim()
        }
        this.props.quotePageClicked(0);
        if(this.props.history.location.state && this.props.history.location.state.isWeek) {

        let values = cloneDeep(data);
        if (values.fromDate && values.toDate) {
            values.fromDate = convertDateToDifferentTZ(values.fromDate, 'from');
            values.toDate = convertDateToDifferentTZ(values.toDate, 'to');
        }

        if (this.props.history.location.pathname === routes.VIEW_FILTERED_QUOTES) {
            if (!this.props.filteredQuotesList) {
                this.props.updateWeekMonthYear(this.props.history.location.state.isWeek, this.props.history.location.state.isMonth, this.props.history.location.state.isYear);
            // this.props.updateCurrentDate(this.props.history.location.state.currentDate);
            if (this.props.history.location.state.isWeek) {
                var SearchValue = {};
                SearchValue = this.props.history.location.state;
                SearchValue.firstday = moment(SearchValue.currentDate).startOf('week').format('MM/DD/YYYY');
                SearchValue.lastday = moment(SearchValue.currentDate).endOf('week').format('MM/DD/YYYY');
                SearchValue.currentDate = moment(SearchValue.currentDate).utc().toISOString();
                SearchValue.value = values;
            } else if (this.props.history.location.state.isMonth || this.props.history.location.state.isYear) {
                SearchValue = this.props.history.location.state;
                SearchValue.value = values;
                SearchValue.currentDate = moment(SearchValue.currentDate).utc().toISOString();
            }
                this.props.searchQuotes(SearchValue);
            }
        } else {
            // if (!this.props.quotesList) {
            this.props.searchQuotes(values);
            // }
        }
    }  else { 
        const values = {value: cloneDeep(data)};
        if (values.value.fromDate && values.value.toDate) {
            values.value.fromDate = convertDateToDifferentTZ(values.value.fromDate, 'from');
            values.value.toDate = convertDateToDifferentTZ(values.value.toDate, 'to');
        }
        this.props.searchQuotes(values);
    }
    }

    resetClicked = () => {
        this.props.reset('searchQuoteForm');
        this.props.quotePageClicked(0);
        if (this.props.history.location.pathname === routes.VIEW_FILTERED_QUOTES) {

            // if (!this.props.filteredQuotesList) {
                this.props.updateWeekMonthYear(this.props.history.location.state.isWeek, this.props.history.location.state.isMonth, this.props.history.location.state.isYear);
                // this.props.updateCurrentDate(this.props.history.location.state.currentDate);
                if (this.props.history.location.state.isWeek) {
                    var SearchValue = {};
                    SearchValue = this.props.history.location.state;
                    SearchValue.firstday = moment(SearchValue.currentDate).startOf('week').format('MM/DD/YYYY');
                    SearchValue.lastday = moment(SearchValue.currentDate).endOf('week').format('MM/DD/YYYY');
                    SearchValue.currentDate = moment(SearchValue.currentDate).utc().toISOString();
                    delete SearchValue.value
                } else if (this.props.history.location.state.isMonth || this.props.history.location.state.isYear) {
                    SearchValue = this.props.history.location.state;
                    delete SearchValue.value
                    SearchValue.currentDate = moment(SearchValue.currentDate).utc().toISOString();
                }
                this.props.viewMonthlyAndYearlyFilterQuotes(SearchValue);
            // }
        } else {
            // if (!this.props.quotesList) {
            this.props.searchQuotes({});
            // }
        }

    }

    moreDescClicked = (event, id) => {
        this.setState({
            quoteIds: [...this.state.quoteIds, id],
        })
    }

    lessDescClicked = (event, id) => {
        const array = [...this.state.quoteIds];
        let index = this.state.quoteIds.findIndex(function (i) {
            return i === id
        });
        array.splice(index, 1);

        this.setState({
            quoteIds: array,
        })
    }

    editTotalClicked = (event, id) => {
        this.setState({
            totalSaleQuoteIds: [...this.state.totalSaleQuoteIds, id],
        })
    }

    submitTotalSaleClicked = (event, id) => {
        const array = [...this.state.totalSaleQuoteIds];

        let index = this.state.totalSaleQuoteIds.findIndex(function (i) {
            return i === id
        });
        array.splice(index, 1);
        this.props.updateTotalSaleLists(this.props.totalSalesArray);
        this.setState({
            totalSaleQuoteIds: array,
        });

    }

    handleClickDeleteModel = (quote) => {

        this.props.handleClickDeleteModel(quote);
    }

    editQuoteClicked = (quote) => {
        storage.set('UpdatedTotalSale', this.props.totalSalesArray);
        this.props.history.push(`/quote/${quote.id}/editquotes`);
        // this.props.editQuoteClicked(quote);
        this.props.getQuote(quote.id);
    }

    handleDeleteClose = () => {
        this.props.handleDeleteClose();
    }

    deleteQuote = (id) => {
        this.props.deleteQuote(id);
    }

    onQuotesPageSizeChange = (pageNo) => {
        let rows = {
            quote_no_of_rows: pageNo
        }
        // this.props.onQuotesPageSizeChange(rows);
    }
    viewQuoteStatusClicked = (id) => {
        this.props.viewQuoteStatusClicked();
        this.props.getQuoteLog(id);
    }
    render() {
        return (
            <>
            <Quotes
                {...this.props}
                onFieldChange={this.onFieldChange}
                isDashboardFilter={this.props.location.pathname === routes.VIEW_FILTERED_QUOTES}
                submitTotalSaleClicked={this.submitTotalSaleClicked}
                openRejectQuoteModel={this.props.openRejectQuoteModel}
                totalSalesArray={this.props.totalSalesArray}
                editTotalClicked={this.editTotalClicked}
                closeRejectModel={this.closeRejectModel}
                // onQuotesPageSizeChange={this.onQuotesPageSizeChange}
                resetClicked={this.resetClicked}
                totalSalesChange={this.totalSalesChange}
                isDescFull={this.state.isDescFull}
                lessDescClicked={this.lessDescClicked}
                clickedQuoteId={this.state.quoteIds}
                totalSaleClickedQuoteId={this.state.totalSaleQuoteIds}
                moreDescClicked={this.moreDescClicked}
                onSubmit={this.goClicked}
                quotesList={this.props.quotesList}
                user={this.props.user}
                openRejectModel={this.openRejectModel}
                isRejectError={this.state.isRejectError}
                approvedCliked={this.approvedCliked}
                quote={this.state.quote}
                handleDeleteClose={this.handleDeleteClose}
                openDeleteModel={this.props.openDeleteModel}
                handelRejectClicked={this.handelRejectClicked}
                editQuoteClicked={this.editQuoteClicked}
                handleClickDeleteModel={this.props.handleClickDeleteModel}
                newQuoteClicked={this.newQuoteClicked}
                newManualQuoteClicked={this.newManualQuoteClicked}
                isLoading={this.props.isLoading}
                isQuotesLoading={this.props.isQuotesLoading}
                deleteObject={this.props.deleteObject}
                deleteQuote={this.deleteQuote}
                resendEmailCliked={this.props.resendEmailCliked}
                quotes_rows={this.props.quotes_rows}
                fromDashboard={this.state.fromDashboard}
                viewQuoteStatusClicked={this.viewQuoteStatusClicked}
            />
            {this.props.viewQuoteStatus ? <QuoteStatusModal isLoading={this.props.isQuotesLogLoading} quoteLog={this.props.quoteLog}/> : null}
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        quotesList: state.adminOrSuperAdminReducer.filteredQuotesList ? state.adminOrSuperAdminReducer.filteredQuotesList : state.adminOrSuperAdminReducer.quotesList,
        filteredQuotesList: state.adminOrSuperAdminReducer.filteredQuotesList,
        user: state.authReducer.user,
        totalSalesArray: state.adminOrSuperAdminReducer.totalSalesArray,
        isLoading: state.adminOrSuperAdminReducer.isLoading,
        isQuotesLoading: state.adminOrSuperAdminReducer.isQuotesLoading,
        openRejectQuoteModel: state.adminOrSuperAdminReducer.openRejectQuoteModel,
        deleteObject: state.adminOrSuperAdminReducer.deleteObject,
        openDeleteModel: state.adminOrSuperAdminReducer.openDeleteModel,
        quotes_rows: state.adminOrSuperAdminReducer.quotes_rows,
        quoteCurPage: state.adminOrSuperAdminReducer.quoteCurPage,
        viewQuoteStatus: state.adminOrSuperAdminReducer.viewQuoteStatus,
        quoteLog: state.adminOrSuperAdminReducer.quoteLog,
        isQuotesLogLoading: state.adminOrSuperAdminReducer.isQuotesLogLoading,
        

    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        resetObjects: () => dispatch(actions.resetObjects()),
        deleteQuote: (id) => dispatch(actions.deleteQuote(id)),
        onQuotesPageSizeChange: (rows) => dispatch(actions.onQuotesPageSizeChange(rows)),
        handleDeleteClose: () => dispatch(actions.handleDeleteClose()),
        editQuoteClicked: (quote) => dispatch(actions.editQuoteClicked(quote)),
        handleClickDeleteModel: (quote) => dispatch(actions.handleClickDeleteModel(quote)),
        updateTotalSalesArray: (totalSales) => dispatch(actions.updateTotalSalesArray(totalSales)),
        openRejectModel: () => dispatch(actions.openRejectModel()),
        closeRejectModel: () => dispatch(actions.closeRejectModel()),
        // quotesSearch: (values) => dispatch(actions.quotesSearch(values)),
        goToApprovedQuote: (quote, totalSalesArray) => dispatch(actions.goToApprovedQuote(quote, totalSalesArray)),
        handelApproveOrRejectClicked: (id, body) => dispatch(actions.handelApproveOrRejectClicked(id, body)),
        getQuotes: () => dispatch(actions.getQuotes()),
        searchQuotes: (values) => dispatch(actions.quotesSearch(values)),
        updateTotalSaleLists: (values) => dispatch(actions.updateTotalSaleLists(values)),
        reset: (searchQuoteForm) => dispatch(reset(searchQuoteForm)),
        viewWeeklyFilterQuotes: (SearchValue, quotes_no_of_rows, page, sorted) => dispatch(actions.viewWeeklyFilterQuotes(SearchValue, quotes_no_of_rows, page, sorted)),
        viewMonthlyAndYearlyFilterQuotes: (SearchValue, quotes_no_of_rows, page, sorted) => dispatch(actions.viewMonthlyAndYearlyFilterQuotes(SearchValue, quotes_no_of_rows, page, sorted)),  // requires form name
        updateCurrentDate: (date) => dispatch(actions.updateCurrentDate(date)),
        updateWeekMonthYear: (isWeek, isMonth, isYear) => dispatch(actions.updateWeekMonthYear(isWeek, isMonth, isYear)),
        resendEmailCliked: (quote) => dispatch(actions.resendEmailCliked(quote)),
        quotePageClicked: (page) => dispatch(actions.quotePageClicked(page)),
        viewQuoteStatusClicked: () => dispatch(actions.viewQuoteStatusClicked()),
        getQuoteLog: (id) => dispatch(actions.getQuoteLog(id)),
        getQuote: (quoteId) => dispatch(actions.getQuote(quoteId)),

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(QuotesContainer);