import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import Moment from 'moment';
import * as Datetime from 'react-datetime';
import "react-datetime/css/react-datetime.css";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import YearPicker from 'react-multi-date-picker'
import moment from 'moment';


const MomentStringToHour = (str, format = "hh:mm A",) => {
    let time = moment(str, format);
    return time
  }

let $ = window.jQuery = require('jquery');
const renderField = ({ preventReadOnly, timeConstraints, input, timeCaption, maxDate, label, showTimeSelect, minDate, dateFormat, timeFormat, timeIntervals, datePickerType, placeholder, className, inputGroup, pickerType, dataTest, dataFormat, disabled, onClick, customComponent,selected,showMonthYearPicker, excludeDates, meta: { touched, asyncValidating, error, },open,inputValue }) => {
     let {value,...restinput}=input
     console.log("PROPS",input.value);
    //   const newInputValue= MomentStringToHour(inputValue)
    return (
        datePickerType === 'react-datetime' ?
            <div className="form-group custom-group">
                {label !== null && <label className="form-group__label">{label}</label>}
                <div className={`form-group__field ${inputGroup.length > 0 ? inputGroup[0].inputGroupClass : ''} ${asyncValidating ? 'async-validating' : ''}`}>
                    <div className="date_time_react">
                        {
                            pickerType === 'datePicker' &&
                            <Datetime inputProps={{ ...input, "data-test": dataTest, style: touched && error ? { borderColor: '#f04d53', borderLeft: '3px solid #f04d53', cursor: 'pointer' } : { cursor: 'pointer' }, readOnly: 'true', className: className }}
                                isValidDate={v => v.startOf('day') >= Moment().startOf('day')} onChange={param => input.onChange(param.format(dataFormat))} dateFormat={dataFormat} timeFormat={false} />
                        }
                        {
                            pickerType === 'timePicker' &&
                            <Datetime  dataTest={dataTest} inputProps={{ ...input,onClick:onClick, disabled:disabled,value, "data-test": dataTest, style: touched && error ? { borderColor: '#f04d53', borderLeft: '3px solid #f04d53', cursor: 'pointer' } : { cursor: 'pointer' }, readOnly: 'true' }} onChange={param => input.onChange(param.format(dataFormat))} timeFormat={dataFormat} dateFormat={false} timeConstraints={timeConstraints} defaultValue={input.value} open={open} />
                        }
                        {  
                            
                            pickerType==="timePickerTimecard" &&
                            <Datetime    inputProps={{ ...restinput,onClick:onClick, disabled:disabled, "data-test": dataTest, style: touched && error ? { borderColor: '#f04d53', borderLeft: '3px solid #f04d53', cursor: 'pointer' } : { cursor: 'pointer' }, readOnly: 'true' }} onChange={param => input.onChange(param.format(dataFormat))} timeFormat={dataFormat} dateFormat={false} timeConstraints={timeConstraints}   value={inputValue} open={open}  />
                        }
                        {
                            pickerType === 'yearPicker' &&
                            <Datetime dataTest={dataTest} inputProps={{ ...input, "data-test": dataTest, style: touched && error ? { borderColor: '#f04d53', borderLeft: '3px solid #f04d53', cursor: 'pointer' } : { cursor: 'pointer' }, readOnly: 'true', className: className }} onChange={param => input.onChange(param.format(dataFormat))} dateFormat={dataFormat} timeFormat={false} />
                        }
                        {
                            !pickerType &&
                            <Datetime dataTest={dataTest} inputProps={{ ...input, "data-test": dataTest, style: touched && error ? { borderColor: '#f04d53', borderLeft: '3px solid #f04d53', cursor: 'pointer' } : { cursor: 'pointer' }, placeholder: placeholder, readOnly: 'true', className: className }} onChange={param => input.onChange(param.format(dataFormat))} dateFormat={dataFormat} timeFormat={false} />
                        }

                    </div>
                    {
                        inputGroup.length > 0 &&
                        <div className="input-group-append">
                            <span className="input-group-text bg-input-text"
                                id="basic-addon2" onClick={(e) => { $(e.currentTarget).parents('.form-group').find('input').click() }} ><i className={inputGroup[0].iconClass}></i> </span>
                        </div>

                    }

                </div>
                {touched && error && <span style={{color: "rgb(221, 39, 38)", fontSize: "13px"}} data-test="error_msg">{error}</span>}
            </div>
            :
            datePickerType === 'react-datepicker' ?
                <>
                    <DatePicker
                        selected={input.value ? input.value : selected ? selected : null}
                        onFocus={(e) => e.target.readOnly = !preventReadOnly}
                        onChange={param => input.onChange(param)}
                        showTimeSelect={showTimeSelect}
                        className={(touched && error) ? className + ' ' + 'datePicketError' : className}
                        placeholderText={placeholder}
                        minDate={minDate}
                        maxDate={maxDate}
                        timeFormat={timeFormat}
                        timeCaption={timeCaption}
                        timeIntervals={timeIntervals}
                        dateFormat={dateFormat}
                        disabled={disabled}
                        onClick={onClick}
                        showMonthYearPicker={showMonthYearPicker ? true : false}
                        excludeDates={excludeDates}
                        customInput={customComponent ? customComponent : <input {...input} data-test={dataTest} />} 
                    />
                    {touched && error && <span style={{color: "rgb(221, 39, 38)", fontSize: "13px"}} data-test="error_msg">{error}</span>}
                </>
                :
                datePickerType === 'react-month-datepicker' ?
                <>
                    <DatePicker
                    showMonthYearPicker
                    selected={input.value ? input.value : null}
                    onFocus={(e) => e.target.readOnly = !preventReadOnly}
                    onChange={param => input.onChange(param)}
                    className={(touched && error) ? className + ' ' + 'datePicketError' : className}
                    placeholderText={placeholder}
                    minDate={minDate}
                    maxDate={maxDate}
                    dateFormat={dateFormat}
                    customInput={<input {...input} data-test={dataTest} />} 
                    />
                    {touched && error && <span style={{color: "rgb(221, 39, 38)", fontSize: "13px"}} data-test="error_msg">{error}</span>}
                </>
                :
                datePickerType === 'year-picker' ?
                <>
                    <YearPicker
                    onlyYearPicker
                    ref={input.ref}
                    name={input.name}
                    onChange={param => input.onChange(param)}
                    inputClass={(touched && error) ? className + ' ' + 'datePicketError' : className}
                    placeholder={placeholder}
                    minDate={minDate}
                    maxDate={maxDate}
                    dateFormat={dateFormat}
                    value={input.value ? input.value : null}
                    containerStyle={{ width: "100%" }}
                    editable={false}
                    shouldCloseOnSelect
                    customInput={<input {...input} data-test={dataTest} />} 
                    />
                    {touched && error && <span style={{color: "rgb(221, 39, 38)", fontSize: "13px"}} data-test="error_msg">{error}</span>}
                </>
                : null
    );
}

export const DateTimeField = ({ preventReadOnly, timeConstraints, type, maxDate, name, showTimeSelect, minDate, dateFormat, timeFormat, timeIntervals, datePickerType, label, placeholder, maxLength, value, autoComplete, className, dataTest, inputGroup, pickerType, dataFormat, disabled, onClick, component,selected, showMonthYearPicker, excludeDates,onChange,open,inputValue }) => {
    
    return(
    <Field
        type={type}
        placeholder={placeholder}
        maxDate={maxDate}
        showTimeSelect={showTimeSelect}
        minDate={minDate}
        dateFormat={dateFormat}
        timeFormat={timeFormat}
        timeIntervals={timeIntervals}
        datePickerType={datePickerType}
        name={name}
        label={label}
        maxLength={maxLength}
        autoComplete={autoComplete}
        className={className}
        inputGroup={inputGroup}
        pickerType={pickerType}
        dataFormat={dataFormat}
        timeConstraints={timeConstraints}
        preventReadOnly={preventReadOnly}
        dataTest={dataTest}
        disabled={disabled}
        onClick={onClick}
        showMonthYearPicker={showMonthYearPicker}
        excludeDates={excludeDates}
        selected={selected}
        component={renderField}
        customComponent = {component}
        onChange={onChange}
        open={open}
        value={value}
        inputValue={inputValue}
    />
);
}

DateTimeField.propTypes = {
    type: PropTypes.string,
    name: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    className: PropTypes.string,
    maxLength: PropTypes.number,
    inputGroup: PropTypes.array,
    pickerType: PropTypes.string,
    value: PropTypes.string,
    dataFormat: PropTypes.string,
    autoComplete: PropTypes.oneOf(['on', 'off'])
};

DateTimeField.defaultProps = {
    type: 'text',
    label: null,
    placeholder: '',
    className: '',
    value: '',
    inputGroup: [],
    maxLength: null,
    pickerType: '',
    dataFormat: 'DD-MM-YYYY',
    autoComplete: null
};