import React, { Component } from 'react';
import * as actions from '../../../../redux/actions/index';
import { connect } from 'react-redux';
import { animateScroll as scroll} from 'react-scroll'
import Location from '../../../component/Inventory/LocationComponent/Location/LocationList';

class LocationContainer extends Component {
  componentDidMount() {
    if (!this.props.locations.length) {
      this.props.getLocation();
    }
    scroll.scrollToTop();
  }

  newLocationClicked = () =>{
    this.props.newLocationClicked()
    this.props.openPopup()
  }

  render() {

    return (
        <Location
            locations={this.props.locations ? this.props.locations : []}
            openDeleteModel={this.props.openDeleteModel}
            handleDeleteClose={this.props.handleDeleteClose}
            editLocationClicked={this.props.editLocationClicked}
            deleteLocation={this.props.deleteLocation}
            deleteObject={this.props.deleteObject}
            handleClickDeleteModel={this.props.handleClickDeleteModel}
            isLoading={this.props.isLoading}
            newLocationClicked={this.newLocationClicked}
            />
    )
  }
}

const mapStateToProps = (state) => {
  return {
    locations: state.InventoryReducer.locations,
    isLoading: state.InventoryReducer.isLoading,
    deleteObject: state.InventoryReducer.deleteInventoryObject,
    openDeleteModel: state.InventoryReducer.openInventoryDeleteModal,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    handleDeleteClose: () => dispatch(actions.handleInventoryCloseDeleteModal()),
    deleteLocation: (id) => dispatch(actions.deleteLocation(id)),
    getLocation: () => dispatch(actions.getLocation()),
    openPopup: () => dispatch(actions.openPopupInventory()),
    newLocationClicked: () => dispatch(actions.newLocationClicked()),
    handleClickDeleteModel: (account) => dispatch(actions.handleInventoryOpenDeleteModal(account)),
    editLocationClicked: (location) => dispatch(actions.editLocationClicked(location)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LocationContainer);