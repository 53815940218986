import React, { Component } from 'react';
import SettingsPreferences from '../../component/SettingsPreferences/settingsPreferences';
import * as actions from '../../../redux/actions/index';
import { connect } from 'react-redux';
import { reset, change, getFormValues, initialize } from 'redux-form';
import isEmpty from '../../../utility/IsEmptyValidator';
import { animateScroll as scroll} from 'react-scroll'
const cloneDeep = require('clone-deep');

class SettingsPreferencesContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isUserPreference: this.props.location.pathname === `/settings/preferences/${this.props.match.params.userId}`
        }
    }

    componentDidMount = () => {
        if (this.state.isUserPreference) {
            this.props.getEditUser(this.props.match.params.userId);
        }
        scroll.scrollToTop();
    }

    componentWillUnmount = () => {
        this.props.resetObjects();
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            this.props.resetObjects();
            this.setState({
                isUserPreference: this.props.location.pathname === `/settings/preferences/${this.props.match.params.userId}`
            })
        }
    }

    updatePreferences = (values) => {
        const user = cloneDeep(values);
        if (user.user.all_inspection_email === "true") {
            user.user.all_inspection_email = true;
        } else if (user.user.no_inspection_email === "true") {
            user.user.no_inspection_email = true;
        }

        if (user.user.daily_hours) {
            let daily_hours_data = user.user.daily_hours.split(' ');
            user.user.daily_hours = parseInt(daily_hours_data[0]);
            user.user.daily_hours_unit = daily_hours_data[1];
        }
        if (user.user.escalation_hours) {
            let escalation_hours = user.user.escalation_hours.split(' ');
            user.user.escalation_hours = parseInt(escalation_hours[0]);
            user.user.escalation_hours_unit = escalation_hours[1];
        }
        if (this.state.isUserPreference) {
            this.props.updateUserPreferences(this.props.user.id, user.user, 'notClone');
        } else {
            this.props.updatePreferences(this.props.loginUser.id, user.user);
        }
        // this.onCancelClicked();
    }

    onCancelClicked = () => {
        this.props.history.goBack();
        // this.props.history.push(routes.USER);
    }

    render() {

        return (
            this.state.isUserPreference && isEmpty(this.props.user) ? null :
                <SettingsPreferences
                    {...this.props}
                    {...this.state}
                    onSubmit={this.updatePreferences}
                    onCancelClicked={this.onCancelClicked}
                    isLoading={this.props.isLoading}
                />)
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.adminOrSuperAdminReducer.requestor && !isEmpty(state.adminOrSuperAdminReducer.requestor.user) ? state.adminOrSuperAdminReducer.requestor.user : {},
        loginUser: state.authReducer.user,
        isLoading: state.adminOrSuperAdminReducer.isLoading ? state.adminOrSuperAdminReducer.isLoading : state.authReducer.isLoading,
        formStates: getFormValues('settingsPreferences')(state)
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        resetObjects: () => dispatch(actions.resetObjects()),
        updatePreferences: (id, user) => dispatch(actions.updatePreferences(id, user)),
        updateUserPreferences: (id, user, from) => dispatch(actions.updateUserPreferences(id, user, from)),
        getEditUser: (id) => dispatch(actions.getEditUser(id)),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(SettingsPreferencesContainer);