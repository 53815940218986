import React, { useEffect, useState } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { Input } from '../../../../UI/InputElement/reactFormHookInputField'
import { Controller } from 'react-hook-form'
import { } from 'react-bootstrap'
import { addBulkMonthlyCostReport } from '../../../../../../redux/actions/FormAuditModAction/InventoryAction/InventoryAction'
import { useDispatch, useSelector } from 'react-redux'
import useSelection from 'antd/es/table/hooks/useSelection'
function EditCostSummaryModal({ modalData, setModalData, ...props }) {


  const dispatch = useDispatch()
   const {isLoading}=useSelector((state)=> state.InventoryModuleReducer)

  const { formState: { errors, defaultValues }, handleSubmit: ModalSubmit, reset, register, control, setValue: setValueForm } = useForm({
    defaultValues: useEffect(() => {

      if (modalData) {
        reset(modalData)

      }

    }, [modalData]),
    shouldFocusError: false

  })

  const watchwdm = useWatch({
    name: "working_days",
    control: control
  })

  const watchFTE = useWatch({
    name: "fte",
    control: control
  })

  const watchSQF = useWatch({
    name: "square_footage",
    control: control
  })

  const Calculate = (num, field, total) => {

    if (!num || !total) setValueForm(field, 0)
     
    setValueForm(field, (total / num).toFixed(2))

  }

  const CloseModalWithReset = (dataRemains) => {
    reset()
    props.closeModal()
    props.refresh()

  }

  const structureBody=(data)=>{

    const months={
      "January":1,
      "Feb":2,
      "March":3,
      "April":4,
      "May":5,
      "June":6,
      "July":7,
      "Aug":8,
      "September":9,
      "October":10,
      "November":11,
      "December":12
    }
     
    delete data.created_at;
    delete data.updated_at;
    data.month=months[data.month]
    return{
      data:[data]
    }
  }
  console.log(modalData);
  useEffect(()=>{
       Calculate(watchwdm,"average_cost_per_day",modalData?.total_monthly_cost)
  },[watchwdm])

  useEffect(()=>{
    Calculate(watchSQF,"cost_per_sq_ft",modalData?.total_monthly_cost)
  },[watchSQF])

  useEffect(()=>{
   Calculate(watchFTE,"monthly_cost_per_fte",modalData?.total_monthly_cost)
  },[watchFTE])

  const controller = Controller

  return (
    <div class="modal-dialog">
      <form class="modal-content" style={{ width: "64rem", right: "160px" }} noValidate onSubmit={ModalSubmit((data, e) => {
        const dataRemains = props.getValues('sections')
        dataRemains[props.index] = { ...data, id: dataRemains[props.index].id };
        const updatedData=dataRemains[props.index]
        
        dispatch(addBulkMonthlyCostReport(structureBody(updatedData), CloseModalWithReset,dataRemains))
        
        // props.setValue('sections',dataRemains)
        // CloseModalWithReset()


      })}>
        <div class="modal-header">
          <div class="form-group m-0">
            {/* <input
              type="text"
              class="form-control"
              id="jobname"
              aria-describedby=""
              placeholder="Title"
            /> */}


            <h3 style={{ marginLeft: "21rem" }}>Edit Summary  ({modalData?.month})</h3>





          </div>
          <button
            id='CloseModal'
            type="button"
            class="btn-close btn p-0"
            style={{
              backgroundColor: "#7c2b2b"
            }}
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={(e) => {
              reset()
            }}
          >
            <img src="/images/icons/cross_icon.svg" alt="cross_icon" />
          </button>
        </div>
        <div class="modal-body">


          <div class="row">
            <div class="col-md-12">
              <div class="summary_input_wrapper" style={{ padding: "0px 0px" }}>
                <table>
                  <tbody>
                    {/* <tr>
                                <td class="p-0">
                                    <h2 style={{ color: "black" }}>SUMMARY</h2>
                                </td>
                            </tr> */}
                    <tr>
                      <td>
                        <div class="summary_mu_field">
                          <p>Total Monthly Cost</p>
                          <span class="addsymbol_field">
                            <input style={{color:" #2c2a2a"}} type="number" readOnly id={"total_monthly_cost"} name='total_monthly_cost' {...register(`total_monthly_cost`, {
                              required: true
                            })} />
                            <span class="sym_icon"></span>
                          </span>
                          <span>&divide;</span>
                          <input style={{color:" #2c2a2a"}} type="number" id="working_days" name='working_days' {...register(`working_days`, {
                            required: true
                          })} />

                          <p>working days this month</p>
                          <span></span>
                          <span class="addsymbol_field">
                            <input style={{color:" #2c2a2a"}} type="number" id="average_cost_per_day" name='average_cost_per_day' {...register(`average_cost_per_day`, {
                              required: true
                            })}

                              

                            />
                            <span class="sym_icon"></span>
                          </span>
                          <p>is the average cost per day</p>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="summary_mu_field">
                          <p>Total Monthly Cost</p>
                          <span class="addsymbol_field">
                            <input style={{color:" #2c2a2a"}} type="number" readOnly id='total_monthly_cost' name='total_monthly_cost'  {...register(`total_monthly_cost`, {
                              required: true
                            })} />
                            <span class="sym_icon"></span>
                          </span>
                          <span>&divide;</span>
                          <input style={{color:" #2c2a2a"}} type="number" id="fte" name='fte' {...register(`fte`, {
                            required: true
                          })} />
                          <p>FTE this month</p>
                          <span></span>
                          <span class="addsymbol_field">
                            <input style={{color:" #2c2a2a"}} type="number" id='monthly_cost_per_fte' name='monthly_cost_per_fte' readOnly  {...register(`monthly_cost_per_fte`, {
                              required: true
                            })} />
                            <span class="sym_icon"></span>
                          </span>
                          <p>is the monthly cost per FTE</p>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="summary_mu_field">
                          <p>Total Monthly Cost</p>
                          <span class="addsymbol_field" >
                            <input style={{color:" #2c2a2a"}} type="number" readOnly id="total_monthly_cost" name='total_monthly_cost'  {...register(`total_monthly_cost`, {
                              required: true
                            })} />
                            <span class="sym_icon"></span>
                          </span>
                          <span>&divide;</span>
                          <input style={{color:" #2c2a2a"}} type="number" id="square_footage" name='square_footage'  {...register(`square_footage`, {
                            required: true
                          })} />
                          <p>Square Footage</p>
                          <span></span>
                          <span class="addsymbol_field">
                            <input style={{color:" #2c2a2a"}} type="number" id='cost_per_sq_ft' name='cost_per_sq_ft' readOnly  {...register(`cost_per_sq_ft`, {
                              required: true
                            })} />
                            <span class="sym_icon"></span>
                          </span>
                          <p>is the monthly cost per square foot</p>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>


          </div>
          <div class="row">



          </div>
          <div class="row">



          </div>

        </div>

        
        {
          <div class="modal-footer">
            <button type="submit" id="submitM" className={isLoading ? "btn cst_btn btn_danger btn-wait" : "btn  theme_def_btn1"}>
          {isLoading ? "" : props.isEdit ? "Update" : "Submit"}
        </button>
            {/* <button
              id='submitM'
              type="submit"
              class="btn theme_def_btn1"
            // data-bs-toggle="modal"
            // data-bs-target="#exampleModal"
            >
              Submit
            </button> */}
          </div>
        }

      </form>

    </div>

  )
}

export default EditCostSummaryModal
