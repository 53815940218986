import React, { useState } from 'react';
import DatePicker from "react-multi-date-picker";
import transition from "react-element-popper/animations/transition"
import InputIcon from "react-multi-date-picker/components/input_icon"

import './CalendarDateSearch.css'

let CalendarDateSearch = (props) => {

    const [inputDate, setInputDate] = useState("");

    let handleSubmitSearchedWeeklyDate = (dateArray) => {

        if (dateArray && dateArray.length > 0) {

            let weeklyDateAssets = {
                weekly_startDate: new Date(dateArray[0]),
                weekly_endDate: new Date(dateArray[1]),
            }

            props.handleWeeklySearch(weeklyDateAssets);
        }

    };

    return (
        <div>

            <DatePicker
                // value={inputDate}
                onChange={(e, dateObject) => {
                    handleSubmitSearchedWeeklyDate(dateObject && dateObject.validatedValue);
                }}
                placeholder='Select any Week'
                inputClass="custom-input-weekly-search"
                format="MM/DD/YYYY"
                range
                weekPicker
                showOtherDays
                monthYearSeparator="|"
                editable={false}
                animations={[transition()]}
                calendarPosition='bottom-right'
            />

        </div>
    );
};

export default CalendarDateSearch;