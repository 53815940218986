import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../../../redux/actions/index";
import * as $ from "jquery";
import History from "../../component/History/SchedulePage";
class SearchHistory extends Component {
  constructor(props) {
    super(props);
  }
  componentWillUnmount() {
    $('.login100-more').removeClass("d-none");
  }

  componentDidMount = () => {
  $('.login100-more').addClass("d-none");
      //this.props.sideBarClicked();
    if (!this.props.searchHistory) this.props.history.goBack();

};
render() {
  return (
      <>
      <History
  {...this.props}
/>
</>
);
}
}

const mapStateToProps = (state) => {
  return {
    user: state.authReducer.user,
    areaSearchResult: state.adminOrSuperAdminReducer.areaSearchResult,
    searchHistory: state.adminOrSuperAdminReducer.searchHistory
  };
};

const mapDispatchToProps = (dispatch) => {
  return {

};
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchHistory);
//export default SearchHistory;
