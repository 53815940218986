import React, { Component } from 'react';
import { connect } from 'react-redux';
import { decode, encode } from 'base64-arraybuffer';
import imageCompression from 'browser-image-compression';
import * as actions from '../../../redux/actions/index';
import { address_attributes } from '../../../utility/constants/constants';
import EditFrontlineForm from '../../component/Frontline/FrontlineForm';
import isEmpty from '../../../utility/IsEmptyValidator';
import getDetailAddress from '../../../utility/getDetailAddress';
import { calculateMinutesFromHours_V1, resetOrientation } from '../../../utility/utility';
import { animateScroll as scroll } from 'react-scroll'
import errorHandler from '../../../utility/errorHandler/errorHandler';
const cloneDeep = require('clone-deep');
class EditFrontline extends Component {

  constructor(props) {
    super(props);

    this.state = {
      base64: null,
      extension: null,
      file: null,
      profileImageName: '',

      isAddressEmpty: false,
      isZipEmpty: false,
      address_attributes: isEmpty(this.props.frontlineUser) ? address_attributes : this.props.frontlineUser.address,
      remove_account_ids: [],
    }
    this.inputOpenFileRef = React.createRef();
    this.choosePhotoClicked = this.choosePhotoClicked.bind(this);
    this.onFileChange = this.onFileChange.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleAddressSelect = this.handleAddressSelect.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    if (!this.props.accountsList) {
      this.props.getAccounts();
    }
    if (isEmpty(this.props.frontlineUser) || this.props.frontlineUser.id !== parseInt(this.props.match.params.frontlineId)) {
      this.props.getFrontline(this.props.match.params.frontlineId).then(res => {
        if (res) {
          let base64 = res.value.data.user.photo_urls ? res.value.data.user.photo_urls.small : null;
          this.setState({ base64, address_attributes: res.value.data.user.address })
        }
      })
    }
    this.props.getSkillSetList({ activeStatus: 'active' });
    scroll.scrollToTop();
  }

  componentWillUnmount() {
    //this.props.resetObjects();
  }

  async onFileChange(e) {

    let reader = new FileReader();
    let file = e.target.files[0];
    this.setState({ file })
    const orientation = await imageCompression.getExifOrientation(file);
    reader.onloadend = () => {

      resetOrientation(reader.result, orientation, this);

      this.setState({
        extension: file.name.split('.').pop().toLowerCase(),
        profileImageName: file.name,
        base64: reader.result,
      })
    }
    reader.readAsDataURL(file)

  }

  choosePhotoClicked = () => {
    this.inputOpenFileRef.current.click();
  }

  handleAddressChange = address => {
    let addressAttributes = { ...this.state.address_attributes };
    addressAttributes.formatted_address = address;

    this.setState({ address_attributes: addressAttributes, isAddressEmpty: false });
  };

  async handleAddressSelect(address) {
    const addressFields = await getDetailAddress(address);
    this.setState({
      address_attributes: addressFields,
      isAddressEmpty: false
    })
  };

  handleInputChange = (e) => {
    console.log(this.state);
    if (e.target.name === 'zip') {
      let addressFields = cloneDeep(this.state.address_attributes);
      addressFields.zip = e.target.value;
      this.setState({
        address_attributes: addressFields,
        isZipEmpty: false
      })
    } else {
      this.setState({
        about_me: e.target.value,
      })
    }
  }

  handleSubmit = (values) => {


    // if (this.state.profileImageName == '' && this.state.base64 == null) {
    //   errorHandler('Kindly add profile picture');
    //   return false;
    // }

    if (values.frontline.first_name.length == 0) {
      errorHandler('Please enter First Name');
      return false;
    }
    if (values.frontline.last_name.length == 0) {
      errorHandler('Please enter Last Name');
      return false;
    }

    if (values.frontline.employee_id.length == 0) {
      errorHandler('Please enter Employee Id');
      return false;
    }
    if (values.frontline.dob_password.length == 0) {
      errorHandler('Please enter Password');
      return false;
    }

    // if (values.frontline.skill_ids.length == 0) {
    //   errorHandler('Please select Skill Sets');
    //   return false;
    // }

    // if(values.frontline.account_ids.length == 0) {
    //   errorHandler('Please select Jobs');
    //   return false;
    // }

    if (values.frontline.phone_number.length == 0) {
      errorHandler('Please enter Contact No');
      return false;
    }

    // if(values.frontline.primary_email == null) {
    //   errorHandler('Please enter Primary Email');
    //   return false;
    // }

    if (values.frontline.timezone == null) {
      errorHandler('Please select your Timezone');
      return false;
    }

    if (values.frontline.scan_type.length == 0) {
      errorHandler('Please choose Scan Type');
      return false;
    }

    // if(values.frontline.overtime &&values.frontline.overtime_limit_hours == null ) {
    //   errorHandler('Please fill Over Time Limits');
    //   return false;
    // }

    let setData = { user: {} };
    let fetchedValues = cloneDeep(values);
    let base_photo, arrayBuffer;
    let extension = this.state.profileImageName && '.' + this.state.profileImageName.split('.')[1];

    let findRemovedSkillIds = (array1, array2) => {
      return array1.reduce((acc, item1) => {
        const isMatched = array2.some(item2 => item1.skill_id == item2.value);
        if (!isMatched) {
          acc.push(item1.skill_id);
        }
        return acc;
      }, []);
    };

    let findRemovedAccountIds = (array1, array2) => {
      return array1.reduce((acc, item1) => {
        const isMatched = array2.some(item2 => item1.id == item2.value);
        if (!isMatched) {
          acc.push(item1.id);
        }
        return acc;
      }, []);
    };

    setData["user"]["id"] = this.props.frontlineUser.id;
    setData["user"]["role"] = fetchedValues.frontline.role;
    setData["user"]["first_name"] = fetchedValues.frontline.first_name;
    setData["user"]["last_name"] = fetchedValues.frontline.last_name;
    // setData["user"]["employee_id"] = fetchedValues.frontline.employee_id;
    setData["user"]["dob_password"] = fetchedValues.frontline.dob_password;

    if (fetchedValues.frontline["skill_ids"]) {

      setData["user"]["skill_ids"] = fetchedValues.frontline["skill_ids"].length ? fetchedValues.frontline["skill_ids"].map(e => e.value) : [];

      let removedSkillsIds = findRemovedSkillIds(this.props.frontlineUser.skills, fetchedValues.frontline.skill_ids);

      if (!(removedSkillsIds.length == 0)) {
        setData["user"]["remove_skill_ids"] = removedSkillsIds
      }

    }

    // if (fetchedValues.frontline["account_ids"]) {

    setData["user"]["account_ids"] = fetchedValues.frontline["account_ids"] && fetchedValues.frontline["account_ids"].length ? fetchedValues.frontline["account_ids"].map(e => e.value) : [];
    if (setData["user"]["account_ids"] && setData["user"]["account_ids"].length > 0) {
      setData["user"]["default_account"] = setData["user"]["account_ids"] && setData["user"]["account_ids"].length ? setData["user"]["account_ids"][0] : "";
    }

    if (this.props.frontlineUser.accounts && this.props.frontlineUser.accounts.length !== 0) {
      let removedAccountIds = this.props.frontlineUser.accounts && this.props.frontlineUser.accounts.length > 1 ? findRemovedAccountIds(this.props.frontlineUser.accounts, fetchedValues.frontline.account_ids) : Array(this.props.frontlineUser.accounts[0].id);

      if (!(removedAccountIds && removedAccountIds.length == 0)) {
        setData["user"]["remove_account_ids"] = removedAccountIds
      }
    }

    // }

    setData["user"]["pin_number"] = fetchedValues.frontline.pin_number;
    setData["user"]["phone_number"] = fetchedValues.frontline.phone_number;
    setData["user"]["emergency_contact_number"] = fetchedValues.frontline.emergency_contact_number;
    setData["user"]["primary_email"] = fetchedValues.frontline.primary_email ? fetchedValues.frontline.primary_email : null;
    setData["user"]["secondary_email"] = fetchedValues.frontline.secondary_email;
    setData["user"]["timezone"] = fetchedValues.frontline.timezone && fetchedValues.frontline.timezone;

    setData["user"]["phone_clock_in"] = fetchedValues.frontline.phone_clock_in;
    setData["user"]["overtime"] = fetchedValues.frontline.overtime;

    setData["user"]["membership_type"] = fetchedValues.frontline.membership_type;
    setData["user"]["exemption_type"] = fetchedValues.frontline.exemption_type;
    setData["user"]["scan_type"] = fetchedValues.frontline.scan_type;

    setData["user"]["address_attributes"] = this.state.address_attributes;
    setData["user"]["form_access"] = {
      team_management: {
        checklist: false,
        checklist_log: false,
        checklist_supervisor_summary: false,
        checklist_manager: false,
        training_log: fetchedValues.frontline["form_access"]?.team_management?.training_log
      },
      logbook: {
        porter_log: fetchedValues.frontline["form_access"]?.logbook?.porter_log
      },
      inventory: {
        bulk_storage_supplies_usage: false,
        equipment_safety: false,
        daily_kitting_safety: false,
        monthly_cost_summary: false,
        monthly_usage_report: false
      },
      building_profiles: {
        building_profile: fetchedValues.frontline["form_access"]?.building_profiles?.building_profile,
        job_cards: fetchedValues.frontline["form_access"]?.building_profiles?.job_cards,
      },
      projects: {
        deep_clean_chart: false
      },
      tag: {
        request_form: fetchedValues.frontline["form_access"]?.tag?.request_form
      }
    }

    setData["user"]["daily_limit_minutes"] = calculateMinutesFromHours_V1 (fetchedValues.frontline.daily_limit_hours) ;
    setData["user"]["weekly_limit_minutes"] = calculateMinutesFromHours_V1(fetchedValues.frontline.weekly_limit_hours);

    // if(fetchedValues.frontline.overtime){
    //   setData["user"]["overtime_limit_minutes"] = calculateMinutesFromHours_V1(fetchedValues.frontline.overtime_limit_hours);
    // }



    if (this.state.base64 !== null && this.state.base64 !== '' && this.state.profileImageName) {

      const image = this.state.base64.split(',');
      base_photo = image[1];
      arrayBuffer = decode(base_photo);

      this.props.getEditFLUserProfileImagePresignUrl(setData, arrayBuffer, extension, this.props.match.params.frontlineId)

    } else {

      this.props.editFrontline(setData, this.props.match.params.frontlineId);

    }

  };

  render() {

    return (
      <EditFrontlineForm
        {...this.props}
        isAddressEmpty={this.state.isAddressEmpty}
        isZipEmpty={this.state.isZipEmpty}
        handleAddressChange={this.handleAddressChange}
        handleInputChange={this.handleInputChange}
        handleAddressSelect={this.handleAddressSelect}
        address_attributes={this.state.address_attributes}

        onSubmit={this.handleSubmit}
        areaErrorMessage={this.state.areaErrorMessage}

        base64={this.state.base64}
        onFileChange={this.onFileChange}
        reference={this.inputOpenFileRef}
        choosePhotoClicked={this.choosePhotoClicked}

      />
    )

  }
}

const mapStateToProps = (state) => {
  return {
    accountsList: state.adminOrSuperAdminReducer.dropDownAccountsList,
    skillSet_list: state.TimeTrackerModReducer.skillSet_list,
    isLoading: state.adminOrSuperAdminReducer.isLoading,
    frontlineUser: state.adminOrSuperAdminReducer.frontlineUser,
    weekly_limit_modal: state.adminOrSuperAdminReducer.weekly_limit_modal
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAccounts: () => dispatch(actions.getDropDownAccounts()),
    getSkillSetList: (data) => dispatch(actions.getSkillSetList(data)),
    handleCloseFrontlineForm: () => dispatch(actions.handleCloseFrontlineForm()),
    getFrontline: (id) => dispatch(actions.getFrontline(id)),
    editFrontline: (data, id) => dispatch(actions.editFrontline(data, id)),
    getEditFLUserProfileImagePresignUrl: (data, arrayBuffer, extension, id) => dispatch(actions.getEditFLUserProfileImagePresignUrl(data, arrayBuffer, extension, id)),
    handleWeeklyLimitSet_closeModal: () => dispatch(actions.handleWeeklyLimitSet_closeModal()),
    handleWeeklyLimitSet_openModal: () => dispatch(actions.handleWeeklyLimitSet_openModal())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditFrontline);