import React, { Component } from 'react';
import history from '../../../../../router/history';
import { connect } from 'react-redux';
import { decode, encode } from 'base64-arraybuffer';
import imageCompression from 'browser-image-compression';
import * as actions from '../../../../../redux/actions/index';
import NewShiftSwapComponent from '../../../../component/TimeTrackerModuleComponent/ShiftComponent/NewShiftSwapComponent/NewShiftSwapComponent';
import { animateScroll as scroll } from 'react-scroll'
import { reset } from 'redux-form';
import errorHandler from '../../../../../utility/errorHandler/errorHandler';
import moment from "moment";
import { tzUTCDateTimeConverterTool_V1 } from '../../../../../utility/TimezoneOperations/timezoneUtility';
const cloneDeep = require('clone-deep');

class NewShiftSwapContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoad: false,
        }

    }

    componentDidMount() {

        this.setState({ isLoad: true });
        setTimeout(() => { this.setState({ isLoad: false }); }, 1500);

        if (this.props && this.props.match && this.props.match.params && this.props.match.params.shiftId) {
            this.props.getSingleShifSchedule(this.props.match.params.shiftId);
            
            //accountId: this.props.singleShiftScheduleData.accounts[0].id
            setTimeout(() => {
                this.props.getUsersListByTheirRoles({ roles: 'frontline',shiftDetailId:this.props && this.props?.match && this.props?.match?.params && this.props.match.params.shiftId });
            }, 2000);
        }

        scroll.scrollToTop();

    }

    componentWillMount() {
        this.props.resetTimeTrackerObjects();
    }

    handleSubmit = (values) => {

        if (values) {
            if (values.swap) {
                if (!(values.swap.selectedFlEmp)) {
                    errorHandler('Swap employee field is required');
                    return false;
                }
                if (!(values.swap.choosedSwapDate)) {
                    errorHandler('Swap shift date field is required');
                    return false;
                }
                if (!(values.swap.reason)) {
                    errorHandler('Swap reason field is required');
                    return false;
                }
            }
        }

        values.swap.shiftDetailIdFrom = this.props.match.params.shiftId;

        if (values && values.swap && values.swap.shiftDetailIdTo == '') {

            if (this.props.singleDayElaboratedShiftInfo && !(this.props.singleDayElaboratedShiftInfo.data && this.props.singleDayElaboratedShiftInfo.data.length > 0)) {
                errorHandler('Shift not linked to selected employee/date! Please reselect!');
                return false;
            } else {
                values.swap.shiftDetailIdTo = this.props.singleDayElaboratedShiftInfo && this.props.singleDayElaboratedShiftInfo.data && this.props.singleDayElaboratedShiftInfo.data[0] && this.props.singleDayElaboratedShiftInfo.data[0].id;
            }

        }

        let setData = { data: { shift_defail_id_from: '', swapped_user_id: '', date: '', shift_defail_id_to: '', reason: '' } };
        let fetchedValue = cloneDeep(values.swap);

        setData.data.shift_defail_id_from = fetchedValue.shiftDetailIdFrom;
        setData.data.swapped_user_id = fetchedValue.selectedFlEmp.value;
        setData.data.date = tzUTCDateTimeConverterTool_V1(fetchedValue.choosedSwapDate, '00:00:00', 'from');
        setData.data.shift_defail_id_to = fetchedValue.shiftDetailIdTo;
        setData.data.reason = fetchedValue.reason;

        this.props.createFLUserShiftSwap(setData);

    }

    onCancelClicked = () => {
        this.props.handleCancelClickAction();
        history.goBack();
    }

    render() {
        return (
            <div>
                <NewShiftSwapComponent
                    {...this.props}
                    onSubmit={this.handleSubmit}
                    onCancelClicked={this.onCancelClicked}
                    isLoad={this.state.isLoad}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.authReducer.user,
        isLoading: state.TimeTrackerModReducer.isLoading,
        isCreateSwap: state.TimeTrackerModReducer.isCreateSwap,
        singleShiftScheduleData: state.TimeTrackerModReducer.singleShiftScheduleData,
        usersListBySelectedRole: state.TimeTrackerModReducer.usersListBySelectedRole,
        singleDayGeneralShiftInfo: state.TimeTrackerModReducer.singleDayGeneralShiftInfo,
        singleDayElaboratedShiftInfo: state.TimeTrackerModReducer.singleDayElaboratedShiftInfo,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        resetTimeTrackerObjects: () => dispatch(actions.resetTimeTrackerObjects()),
        handleCancelClickAction: () => dispatch(actions.handleCancelClickAction()),
        getSingleShifSchedule: (id) => dispatch(actions.getSingleShifSchedule(id)),
        getUsersListByTheirRoles: (params) => dispatch(actions.getUsersListByTheirRoles(params)),
        getPerDayFLUserGeneralShiftInfo: (params) => dispatch(actions.getPerDayFLUserGeneralShiftInfo(params)),
        getPerDayFLUserElaboratedShiftInfo: (params, id) => dispatch(actions.getPerDayFLUserElaboratedShiftInfo(params, id)),
        createFLUserShiftSwap: (body) => dispatch(actions.createFLUserShiftSwap(body)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewShiftSwapContainer);