import React, { useEffect, useState } from 'react';
import ImageLoader from 'react-imageloader';
import isEmpty from '../../../../utility/IsEmptyValidator';
import ModalImageGallery from '../../ModalImageGallery/modalImageGallery';
import GoogleMapReact from 'google-map-react';
import { TimeZones, userRoles } from '../../../../utility/constants/constants';
import { convertDateAndTimeTimezone, getUser, makeFirstLetterCapital } from '../../../../utility/utility';
import errorHandler from '../../../../utility/errorHandler/errorHandler';
import history from '../../../../router/history';
import { ClapSpinner } from 'react-spinners-kit';
import '../../../../styles/custom.css';
import storage from '../../../../utility/storage';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import AssetsFormSkeleton from '../../Inventory/Assets/NewAssetsForm/AssetsFormSkeleton';

var moment = require('moment');

function preloader() {
    return <img style={{ width: '100%', height: '100%' }} src="/images/gif/giphy.gif" />;
}

const calculateDuration = (startDate, endDate) => {

    let minutes = moment.duration(moment.tz(endDate, TimeZones.PST)
        .diff(moment.tz(startDate, TimeZones.PST))).minutes();
    let hours = moment.duration(moment.tz(endDate, TimeZones.PST)
        .diff(moment.tz(startDate, TimeZones.PST))).asHours();
    // let days = moment.duration(moment.tz(endDate, TimeZones.PST)
    //     .diff(moment.tz(startDate, TimeZones.PST))).days();
    // hours = hours + (days * 24);
    hours = Math.floor(hours);

    if (isNaN(NaN) && isNaN(hours)) {
        return ''
    } else {
        if (minutes === 0 && hours === 0) {
            return 'half of a minute'
        } else if (hours === 0 && minutes === 1) {
            return minutes + ' ' + 'minute';
        } else if (hours === 0 && minutes > 1) {
            return minutes + ' ' + 'minutes';
        } else if (hours === 1 && minutes === 0) {
            return hours + ' ' + 'hour ';
        } else if (hours === 1 && minutes === 1) {
            return hours + ' ' + 'hour ' + minutes + ' ' + 'minute'
        } else if (hours === 1 && minutes > 1) {
            return hours + ' ' + 'hour ' + minutes + ' ' + 'minutes'
        } else if (hours > 1 && minutes === 0) {
            return hours + ' ' + 'hours'
        }
        else {
            return hours + ' ' + 'hours ' + minutes + ' ' + 'minutes'
        }
    }

}

const lineItemAttributes = (line, imageClicked) => {

    return (

        <div className="card inner_card2 mb-0">

            <div className="card-body ins_card_body">
                <div className="ins_performance border_bottom">
                    <div className="row">
                        <div className="col-lg-7 col-xl-9">
                            <h5 className="small_tile">{line.name}</h5>
                            {line.n_a || line.rating_type.name === 'Name' ? null :
                                <div className="row">
                                    <div className="col-4 col-sm-6 col-xl-4">
                                        <img src="/images/icons/icn_ins_100.png" alt="Image" /><span>{line.score}</span>
                                    </div>
                                    <div className="col-8 col-sm-6 col-xl-8">
                                        <div className="result_title">
                                        <h5 className="small_tile">{line.rating_option && line.score !== "N/A" ? line.rating_option.name : ''}</h5>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                        <div className="col-lg-5 col-xl-3">
                            <div className="room_img_block">
                                <div className="row">
                                    {line.attachments_attributes.map((url, i) => {
                                        return (<div className="col-4 col-sm-4 col-md-4 text-center " style={{border:"1px solid"}} onClick={() => imageClicked(line.attachments_attributes, i)}>
                                            <ImageLoader
                                                // style={{ width: '100%', height: '100%', cursor: 'pointer' }}
                                                src={url.photo_urls.small}
                                                classNameName="room_img"
                                                wrapper={React.createFactory('div')}
                                                preloader={preloader}>
                                                Image load failed!
                                                            </ImageLoader>
                                        </div>)
                                    })}
                                    {/* <div className="col-4 col-sm-4 col-md-4 col-md-4 text-center">
                                        <img src="/images/thumbnails/img_room2.jpg" alt="Image" className="room_img" />
                                    </div>
                                    <div className="col-4 col-sm-4 col-md-4 col-md-4 text-center">
                                        <img src="/images/thumbnails/img_room3.jpg" alt="Image" className="room_img" />
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card_footer">
            <p className="cst_para">{line.n_a ? 'N/A' : line.name.toLowerCase().includes('customer contact') ? makeFirstLetterCapital(line.comment) : NewlineText(line.comment)}</p>
            </div>
        </div>
    )
}

const NewlineText = (props) => {
    if(props){
        const text = makeFirstLetterCapital(props);
        const newText = text.split('\n').map(str => <p>{str}</p>);
    return newText;
    }
  }
const sectionAttributes = (section, imageClicked) => {

    return (
        <div className="card mb-0">
            <div className="card-body ins_card_body">
                <div className="ins_form_btn">
                    <button className="btn cst_btn btn_danger">{section.name}</button>
                </div>
                {section.line_items_attributes.map((line, i) => {

                    return lineItemAttributes(line, imageClicked)

                })}
            </div>
        </div>
    )
}

const ViewInspection = (props) => {
    const isAccountOwner = storage.get('isAccountOwner');
    const AnyReactComponent = ({ text }) => (
        <div className="cst_tooltip">
            <span className="has-tooltip">
                <i className="fa fa-map-marker" style={{ fontSize: '40px', color: '#9b2c59' }}></i>
            </span>
            <span className="tooltip gray" style={{ background: '#000000a3' }}>
                <p>{text}</p>
            </span>
        </div>
        // <div style={{
        //     color: 'white',
        //     background: 'grey',
        //     padding: '15px 10px',
        //     display: 'inline-flex',
        //     textAlign: 'center',
        //     alignItems: 'center',
        //     justifyContent: 'center',
        //     borderRadius: '100%',
        //     transform: 'translate(-10%, -10%)'
        // }}>
        //     "my Lcation"
        //     {/* <i class="fa fa-map-marker" style="font-size:48px;color:red"> my location</i> */}
        // </div>
    );

    const [isImageGallery, setIsImageGallery] = useState(false);
    const [photoUrls, setPhotoUrls] = useState([]);
    const [startIndex, setStartIndex] = useState(0);
    const [currentIns, setCurrentIns] = useState(0);
    const [isCustomerPresent, setIsCustomerPresent] = useState(false);

    useEffect(() => {
        if (props.inspectionIndex) {
            props.inspectionIndex.map((ins, i) => {
                if(props.match.params.inspectionId === ins) {
                    setCurrentIns(i + 1)
                }
            })
        }
    }, [props])
    
    useEffect (() => {
    const valueArray = [];
    if (props.inspection && props.inspection.inspection_form) {
        props.inspection.inspection_form.sections_attributes.map(section => {
            section.line_items_attributes.map(lineAttribute => {
                if(lineAttribute.name.toLowerCase().includes('customer contact')) {
                    if (lineAttribute.comment && lineAttribute.comment !== ' ' && lineAttribute.comment.length > 0 && lineAttribute.customer_rep_available === false) {
                        valueArray.push('yes');
                    }
                }
            })
        })
        if (valueArray.includes('yes')) {
            setIsCustomerPresent(true);
        } else {
            setIsCustomerPresent(false);
        }
    }
}, [props.inspection.inspection_form]) 

if(props.isLoading){
    return <AssetsFormSkeleton />
}

    if (isEmpty(props.inspection)) {
        return null
    } else {

        const imageClicked = (urls, i) => {
            setIsImageGallery(true);
            setStartIndex(i);
            setPhotoUrls(urls);
        }

        // if (isImageGallery) {debugger
        //     return <ModalImageGallery
        //         isImageGallery={isImageGallery}
        //         setIsImageGallery={setIsImageGallery}
        //         startIndex={startIndex}
        //         // base64={props.portfolioImages}
        //         imageUrls={photoUrls}
        //     />
        // } 
            const user = getUser();
            let customerRepresentative = '';
            props.inspection.inspection_form.sections_attributes.forEach(section => {
                section.line_items_attributes.forEach(line => {
                    if (line.name.toLowerCase().includes('customer contact') && line.comment && line.comment !== "" && line.comment !== " ") {
                        if (customerRepresentative === '') {
                            customerRepresentative = line.comment
                        } else {
                            customerRepresentative = customerRepresentative + ', ' + line.comment;
                        }
                    }
                })
            })

            if (props.user.role === 'client' && props.inspection.private) {
                errorHandler('Invalid Request')
                history.push(`/inspections`);
            }

            return (    
                (props.user.role === 'client' && props.inspection.private) ? 
                null : 
                <main className="app-content wraper_content edit_inspection">
                {props.isLoading ? 
                <div className="loader_btn_block">
                <ClapSpinner backColor="#89073c" size={40} frontColor="#89073c8a" />
                </div>: 
                    <section className="appcontent_Inner">
                    {isImageGallery ? 
                        <ModalImageGallery
                            isImageGallery={isImageGallery}
                            setIsImageGallery={setIsImageGallery}
                            startIndex={startIndex}
                            // base64={props.portfolioImages}
                            imageUrls={photoUrls}
                        /> : null}
                        <div className="row align-items-center">
                            <div className="col-md-12">
                            <div className="row">
                            <div className="col-md-6">
                            <div className="font-weight-bold">Inspection Status: <span className="font-weight-normal active_status">{props.inspection.active_status}</span></div>
                            </div>
                            <div className="col-md-6">
                            {
                                props.inspection.active_status === "draft" ? null :
                                <button className="csv_btn btn_danger" onClick={() => props.downloadPdfClicked()} >Resend PDF</button>
                            }
                            { props.inspection.active_status === "pending" && (props.inspection.account_info.owner_user_id === user.id) ? 
                                <button className="csv_btn btn_danger" style={{marginRight: '10px'}} onClick={() => props.markAsCompletedModalClicked()} >Mark As Completed</button>
                                : null }
                            </div>
                            </div>
                           
                                <div className="card edit_ins_content mt-3">
                                    <div className="card_header d_flex">
                                        <h5 className="sm_title">{props.inspection.inspection_form.name}</h5>
                                        {props.inspectionIndex && props.inspectionIndex.length > 0 ? <div className='d-flex viewIns_btn'>
                                        <button className="btn cst_btn custom_hover" disabled={currentIns === 1} onClick={() => props.previousClicked()}>Previous</button>
                                        <h4 style={{margin: '5px'}}>{currentIns}/{props.inspectionIndex ? props.inspectionIndex.length : 0}</h4>
                                        <button className="btn cst_btn custom_hover" disabled={currentIns === props.inspectionIndex.length } onClick={() => props.nextClicked()}>Next</button>
                                        </div> : null}
                                        {/* <div className="dropdown cst_dropdown"> */}
                                            {/* <a href="javascript:void(0)" className="dropdown-toggle" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></a> */}
                                            {/* <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                                        <a className="dropdown-item" >Action</a>
                                        <a className="dropdown-item" >Another action</a>
                                        <a className="dropdown-item" >Something else here</a>
                                    </div> */}
                                       {/*  </div> */}
                                    </div>
                                    <div className="card-body inner_card">
                                        <div className="card">
                                            <div className="card-body ins_card_body">
                                                <div className="ins_form_btn">
                                                    <button className="btn cst_btn btn_danger">Overall Score: {props.inspection.score !== null ? props.inspection.score + ` %` : 'N/A'}</button>
                                                </div>

                                                <div className="overall_score">
                                                    <div className="row">
                                                        <div className="col-lg-5 col-xl-6">
                                                            <div className="inspections">
                                                                <div className="inspection_content_block">
                                                                    <div className="left_list">
                                                                        <h5 className="h5_title">Inspection #:</h5>
                                                                    </div>
                                                                    <div className="right_list">
                                                                        <h6 className="inspection_info">{props.inspection.id}</h6>
                                                                    </div>
                                                                </div>
                                                                <div className="inspection_content_block">
                                                                    <div className="left_list">
                                                                        <h5 className="h5_title">Job :</h5>
                                                                    </div>
                                                                    <div className="right_list">
                                                                        <h6 className="inspection_info">{props.inspection.account_info ? props.inspection.account_info.account_name : ''}</h6>
                                                                    </div>
                                                                </div>
                                                                {props.user.role === userRoles.SUPER_ADMIN || props.user.role === userRoles.ADMIN ?
                                                                    <>
                                                                        <div className="inspection_content_block">
                                                                            <div className="left_list">
                                                                                <h5 className="h5_title">Inspection Location:</h5>
                                                                            </div>
                                                                            <div className="right_list">
                                                                                <h6 className="inspection_info">{props.inspection.address.start_location.formatted_address}</h6>
                                                                            </div>
                                                                        </div>
                                                                        {/* <div className="inspection_content_block">
                                                                            <div className="left_list">
                                                                                <h5 className="h5_title">End Location:</h5>
                                                                            </div>
                                                                            <div className="right_list">
                                                                                <h6 className="inspection_info">{props.inspection.address.end_location.formatted_address}</h6>
                                                                            </div>
                                                                        </div> */}
                                                                    </>
                                                                    :
                                                                    <div className="inspection_content_block">
                                                                        <div className="left_list">
                                                                            <h5 className="h5_title">Location:</h5>
                                                                        </div>
                                                                        <div className="right_list">
                                                                            <h6 className="inspection_info">{props.inspection.address.end_location.formatted_address}</h6>
                                                                        </div>
                                                                    </div>
                                                                }
                                                                <div className="inspection_content_block">
                                                                    <div className="left_list">
                                                                        <h5 className="h5_title">Inspection Date:</h5>
                                                                    </div>
                                                                    <div className="right_list">
                                                                        <h6 className="inspection_info">{props.inspection.created_at !== '' ? convertDateAndTimeTimezone(props.inspection.created_at, props.inspection.account_info.timezone) : ''}</h6>
                                                                    </div>
                                                                </div>
                                                                <div className="inspection_content_block">
                                                                    <div className="left_list">
                                                                        <h5 className="h5_title">Pegasus Representative:</h5>
                                                                    </div>
                                                                    <div className="right_list">
                                                                        <h6 className="inspection_info">{props.inspection.inspected_by ? props.inspection.inspected_by.first_name + ' '
                                                                            + props.inspection.inspected_by.last_name : ''}</h6>
                                                                    </div>
                                                                </div>
                                                                {isCustomerPresent ? <> <div className="inspection_content_block">
                                                                    <div className="left_list">
                                                                        <h5 className="h5_title">Customer present during inspection:</h5>
                                                                    </div>
                                                                    <div className="right_list">
                                                                        <h6 className="inspection_info">{customerRepresentative ? 'Yes' : 'No'}</h6>
                                                                    </div>
                                                                </div>
                                                                <div className="inspection_content_block">
                                                                    <div className="left_list">
                                                                        <h5 className="h5_title">Customer Representative(s):</h5>
                                                                    </div>
                                                                    <div className="right_list">
                                                                        <h6 className="inspection_info">{customerRepresentative ? customerRepresentative : "No Customer Representative"}</h6>
                                                                    </div>
                                                                </div> </>: ''}
                                                                {/* <div className="inspection_content_block">
                                                                    <div className="left_list">
                                                                        <h5 className="h5_title">Duration:</h5>
                                                                    </div>
                                                                    <div className="right_list">
                                                                        <h6 className="inspection_info">{calculateDuration(props.inspection.start_time, props.inspection.end_time)}</h6>
                                                                    </div>
                                                                </div> */}
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-7 col-xl-6">
                                                            <div className="map_part">
                                                                <figure className="map_inner">
                                                                    {props.inspection.address.end_location.latitude && props.inspection.address.end_location.longitude ?
                                                                        <GoogleMapReact

                                                                            bootstrapURLKeys='AIzaSyAUImPEdPk-6idATsr0RVAeDsZpTR5gWvU'
                                                                            defaultCenter={
                                                                                props.inspection.address.end_location.id !== '' ?
                                                                                    { lat: props.inspection.address.end_location.latitude, lng: props.inspection.address.end_location.longitude } :
                                                                                    { lat: 0, lng: 0 }}
                                                                            defaultZoom={15}
                                                                        // style={{width: '10px', height: '10px'}}
                                                                        // size={{ width: '10px', height: '10px' }}
                                                                        >
                                                                            <AnyReactComponent
                                                                                lat={props.inspection.address.end_location.latitude}
                                                                                lng={props.inspection.address.end_location.longitude}
                                                                                text={props.inspection.address.end_location.formatted_address}
                                                                            />
                                                                        </GoogleMapReact>
                                                                        : null}
                                                                    {/* <img src="/images/background/map.jpg" className="map_image" 
                                                                    alt="location" />*/}
                                                                </figure>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {props.inspection.inspection_form.sections_attributes.map((section, i) => {
                                            return sectionAttributes(section, imageClicked)
                                        })}

                                        {props.inspection.feedback_status ? 
                                        <div class="card qqv_answered">
                                        <div class="card-body ins_card_body">
                                            <div class="contact_info">
                                                <h3 class="card_title" style={{fontSize: '2.6rem'}}>Customer Feedback</h3>
                                            </div>
                                            <div class="web_inn_con">
                                                <h3 class="web_h3" style={{fontSize: '22px'}}>On a scale of 0-10, what is the impression of our service?</h3>
                                                <span class="web_h3" style={{fontSize: '18px', lineHeight: '28px', color: '#252525'}}>How would you rate your experience with our services – this includes your experience with management, the employees, the cleanliness, etc.?</span>
                                                <nav aria-label="Page navigation example" class="web_pagination_block">
                                                    {props.inspection.feedback.answer[0] ? <ul class="pagination web_pagination">
                                                        <li class={props.inspection.feedback.answer[0].answer === "0" ? "page-item active" : "page-item"}>
                                                            <a class="page-link view_link_zero" >0
                                                </a>
                                                        </li>
                                                        <li class={props.inspection.feedback.answer[0].answer === "1" ? "page-item active" : "page-item"}>
                                                            <a class="page-link view_link_one" >1
                                                </a>
                                                        </li>
                                                        <li class={props.inspection.feedback.answer[0].answer === "2" ? "page-item active" : "page-item"}>
                                                            <a class="page-link view_link_two" >2
                                                </a>
                                                        </li>
                                                        <li class={props.inspection.feedback.answer[0].answer === "3" ? "page-item active" : "page-item"}>
                                                            <a class="page-link view_link_three" >3
                                                </a>
                                                        </li>
                                                        <li class={props.inspection.feedback.answer[0].answer === "4" ? "page-item active" : "page-item"}>
                                                            <a class="page-link view_link_four" >4
                                                </a>
                                                        </li>
                                                        <li class={props.inspection.feedback.answer[0].answer === "5" ? "page-item active" : "page-item"}>
                                                            <a class="page-link view_link_five" >5
                                                </a>
                                                        </li>
                                                        <li class={props.inspection.feedback.answer[0].answer === "6" ? "page-item active" : "page-item"}>
                                                            <a class="page-link view_link_six" >6
                                                </a>
                                                        </li>
                                                        <li class={props.inspection.feedback.answer[0].answer === "7" ? "page-item active" : "page-item"}>
                                                            <a class="page-link view_link_seven" >7
                                                </a>
                                                        </li>
                                                        <li class={props.inspection.feedback.answer[0].answer === "8" ? "page-item active" : "page-item"}>
                                                            <a class="page-link view_link_eight" >8
                                                </a>
                                                        </li>
                                                        <li class={props.inspection.feedback.answer[0].answer === "9" ? "page-item active" : "page-item"}>
                                                            <a class="page-link view_link_nine" >9
                                                </a>
                                                        </li>
                                                        <li class={props.inspection.feedback.answer[0].answer === "10" ? "page-item active" : "page-item"}>
                                                            <a class="page-link view_link_ten" >10
                                                </a>
                                                        </li>
                                                    </ul> : ''}
                                                </nav>
                                                {/* <div class="page_not_likely">
                                                    <h4 class="web_h4 link_previous">0 - Dissatisfied
                                            </h4>
                                                    <h4 class="web_h4 link_next">10 - Satisfied
                                            </h4>
                                                </div> */}
                                            </div>
                                            <div class="qv_quiz_card">
                                                <h5 class="h5_title" style={{fontSize: '20px', color: 'black'}}>What is the reason for the score of {props.inspection.feedback.answer[0] && props.inspection.feedback.answer[0].answer}. <label class="label_modifier">What do you love about our services ?</label></h5>
                                                <p class="cst_para" style={{fontSize: '17px', color: '#454545'}}>{props.inspection.feedback.answer[1] && props.inspection.feedback.answer[1].answer ? props.inspection.feedback.answer[1].answer : "-"}</p>
                                            </div>
                                            <div class="qv_quiz_card">
                                                <h5 class="h5_title" style={{fontSize: '20px', color: 'black'}}>Where can we improve?</h5>
                                                <p class="cst_para" style={{fontSize: '17px', color: '#454545'}}>{props.inspection.feedback.answer[2] && props.inspection.feedback.answer[2].answer ? props.inspection.feedback.answer[2].answer : '-'}</p>
                                            </div>
                                        </div>
                                    </div> : ''
                                        }
                                        {props.inspection.review_notes ? 
                                        <div class="card qqv_answered">
                                        <div class="card-body ins_card_body row">
                                        <div className="col-lg-7 col-xl-9">
                                        <div class="contact_info">
                                                <h3 style={{fontSize: '16px', fontWeight: 'bolder'}}>Notes:</h3>
                                            </div>
                                            <p>{props.inspection.review_notes}</p>
                                        </div>
                                        <div className="col-lg-5 col-xl-3">
                                            <div className="room_img_block">
                                                <div className="row">
                                                        {props.inspection.attachments && props.inspection.attachments.map((url, i) => {
                                                        return (<div className="col-4 col-sm-4 col-md-4 text-center" onClick={() => imageClicked(props.inspection.attachments, i)}>
                                                            <ImageLoader
                                                                // style={{ width: '100%', height: '100%', cursor: 'pointer' }}
                                                                src={url.photo_urls.small}
                                                                classNameName="room_img"
                                                                wrapper={React.createFactory('div')}
                                                                preloader={preloader}>
                                                                Image load failed!
                                                                            </ImageLoader>
                                                        </div>)
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                        </div>
                                    </div> : ''
                                        }
                                        

                                    </div>
                                    {/* <div className="card_footer">
                                <div className="btn_block text-center">
                                    <button className="btn cst_btn btn_danger mr-2">Save</button>
                                    <button className="btn cst_btn btn-outline-secondary  cancel_btnnew">Cancel</button>
                                </div>
                            </div> */}
                                </div>
                            </div>
                        </div>
                        <>
                        <Dialog
                        open={props.completeApporveModal}
                        onClose={props.handleClose}
                        aria-labelledby="form-dialog-title"
                    >
                        <DialogTitle id="form-dialog-title" style={{ fontSize: '13', color: '#06080f' }}>
                            <h3 style={{ color: '#ab385e' }}>Do you want to Mark this Inspection as Completed?</h3>
                        </DialogTitle>
        
                        <DialogActions>
                            <button style={{ minHeight: '36px', minWidth: '80px' }} onClick={() => props.markAsCompletedClicked()} disabled={props.isLoading} className={props.isLoading ? "btn cst_btn btn_danger mr-2 btn-wait" : "btn cst_btn btn_danger mr-2"} id="delete" type="delete">{props.isLoading ? "" : "Yes"}</button>
                            <button style={{ minHeight: '36px', minWidth: '80px' }} onClick={props.handleCompleteApproveClose} className={"btn cst_btn btn-outline-secondary"} id="approve_inspection_id" type="cancel">Cancel</button>
                            
                        </DialogActions>
                    </Dialog>
                        </>
                    </section>}
                </main >
            ) 
    }
}
export default ViewInspection;
