import React, { Component } from 'react';
import NewQuote from '../../../component/Quotes/NewQuotes/newQuotes';
import $ from 'jquery'
import NewInspectionQuote from '../../../component/Quotes/NewQuotes/newInspectionQuote';
import CreateQuoteManually from '../../../component/Quotes/NewQuotes/createQuoteManually';
import { showConfirmAlert } from '../../../../utility/successAlert/ConfirmAlert';
import * as actions from '../../../../redux/actions/index';
import { routes, userRoles } from '../../../../utility/constants/constants';
import { connect } from 'react-redux';
import QuotesSkeleton from '../../../component/Quotes/NewQuotes/QuotesSkeleton';
import isEmpty from '../../../../utility/IsEmptyValidator';
import { extractExtention, getUser } from '../../../../utility/utility';
import { decode, encode } from 'base64-arraybuffer';
import * as commonService from '../../../../utility/utility';
import { convertTimeStringToUTCFormAudit, ConvertToFormat } from '../../../../utility/TimezoneOperations/timezoneUtility';

const cloneDeep = require('clone-deep');

class NewQuotesContainer extends Component {
    constructor(props) {
        super();
        this.state = {
            submitNow: false,
            submitLater: false,
            faultImages: 0,
            isImagesEmpty: false,
            imageName: '',
            idsToDelete: [],
            imageUrl: null,
            base64: [],
            AllFileTypes: [],
            fileType: '',
            setLoader: false,
        };
        this.inputOpenFileRef = React.createRef();
        this.onPortfolioFileChange = this.onPortfolioFileChange.bind(this);
        this.removeImage = this.removeImage.bind(this);
        this.choosePhotoClicked = this.choosePhotoClicked.bind(this);
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (this.props.isLoading === false && prevProps.isLoading === true) {
            this.setState({
                submitNow: false,
                submitLater: false,
            });
        }
        if (prevProps.quote !== this.props.quote) {
            if (this.state.idsToDelete.length === 0) {
                this.setState({
                    base64: [],
                    imageName: [],
                    arrayBuffer: [],
                    idsToDelete: [],
                    portfolioImages: [],
                    savedPortfolioURls: this.props.quote.attachments,
                });
            } else {
                this.setState({
                    // base64: this.state.base64,
                    imageName: [],
                    arrayBuffer: [],
                    idsToDelete: [],
                    portfolioImages: [],
                    savedPortfolioURls: this.props.quote.attachments,
                });
            }
        }
    };

    choosePhotoClicked = () => {
        this.inputOpenFileRef.current.click();
    };

    onPortfolioFileChange = (e) => {
        this.setState({
            faultImages: 0,
            setLoader: true,
        });
        console.log(this.state.base64);
        let files = e.target.files;
        let imageName = [],
            fileType = [],
            AllFileTypes = [],
            base64 = [];
        const that = this;
        // for (let i = 0; i < files.length; i++) {
        async function readFile(index) {
            if (index < files.length) {
                let reader = new FileReader();
                let file = files[index];
                imageName.push(file.name);
                fileType.push(file.type);
                AllFileTypes.push(file.type);

                if (file.type.match('image')) {
                    let fileDate = await commonService.compressImage(file, {});
                    let reader = new FileReader();
                    reader.readAsDataURL(fileDate);
                    reader.onload = function() {
                        // setImageData({ ...imageData, base64: reader.result, file: file });
                        base64.push(reader.result);
                        if (index === files.length - 1) {
                            //
                            that.setState((prevState) => ({
                                base64: [...prevState.base64, ...base64],
                            }));
                        }
                    };
                    reader.onerror = function(error) {
                        console.log('Error: ', error);
                    };
                    if (index === files.length - 1) {
                        //
                        that.setState((prevState) => ({
                            imageName: [...prevState.imageName, ...imageName],
                            fileType: [...prevState.fileType, ...fileType],
                            AllFileTypes: [...prevState.AllFileTypes, ...AllFileTypes],
                            setLoader: false,
                            // arrayBuffer: [...prevState.arrayBuffer, decode(reader.result)],
                            // base64: [...prevState.base64, ...base64]
                        }));
                    }
                    readFile(index + 1);
                }
                // reader.readAsArrayBuffer(file)
                // reader.readAsDataURL(file)
            }
        }

        readFile(0);
    };
    removeImage = (url, fileTypeIndex) => {
        let updatedUrls = [...this.state.base64];
        let updatedFileTypes = [...this.state.fileType];
        let updatedImageNames = [...this.state.imageName];

        let urlIndex = this.state.base64.findIndex(function(u) {
            return u === url;
        });
        updatedUrls.splice(urlIndex, 1);
        updatedFileTypes.splice(fileTypeIndex, 1);
        updatedImageNames.splice(fileTypeIndex, 1);

        this.setState({
            base64: updatedUrls,
            fileType: updatedFileTypes,
            imageName: updatedImageNames,
        });
    };

    removeUrls = (url) => {
        let updatedUrls = [...this.state.savedPortfolioURls];

        if (url.photo_urls) {
            this.setState((prevState) => ({
                idsToDelete: [...prevState.idsToDelete, url.id],
            }));
        }

        let urlIndex = this.state.savedPortfolioURls.findIndex(function(u) {
            return u === url;
        });

        updatedUrls.splice(urlIndex, 1);

        this.setState({
            savedPortfolioURls: updatedUrls,
        });
    };
    componentDidMount() {
        // if (!this.props.dropDownAccountsList) {

        if (this.props.match && this.props.match.params.quoteId && isEmpty(this.props.quote)) {
            this.props.getQuote(this.props.match.params.quoteId);
        }
        if (
            this.props.history.location.pathname === routes.NEW_QUOTES ||
            this.props.history.location.pathname === routes.MANUALL_QUOTE
        ) {
            if (this.props.user.role === userRoles.REQUESTOR) {
                this.props.getUserAccounts(this.props.user.id);
            } else {
                this.props.getModeAccounts(this.props.user.selected_mode);
            }
        }
        // }
    }

    handelQuoteSubmitLater = (values, a, b, c) => {
        let quote = cloneDeep(values);
        quote.quote['private_quote'] =
            this.props.inspection && this.props.inspection.private ? this.props.inspection.private : false;
        quote.quote['submit_now'] = false;
        // quote.quote['account_id'] = quote.quote['account_id']['value'];
        const usermode = getUser();
        quote.quote['mode'] = usermode.mode;
        if (this.props.user.role === userRoles.REQUESTOR || this.props.user.role === userRoles.INSPECTOR) {
            quote.quote['status'] = 'draft';
        }
        quote.quote['quote_customer_ids'] =
            quote.quote.quote_customer_ids &&
            quote.quote.quote_customer_ids.map((c) => {
                return c.value;
            });
        this.setState({
            submitLater: true,
        });

        const arrayBuffer = [];
        this.state.base64.map((url, i) => {
            if (!url.photo_urls) {
                let base_photo = null;
                const image = url.split(',');
                base_photo = image[1];

                arrayBuffer.push(decode(base_photo));
            }
        });
        const extentions = extractExtention(this.state.imageName);

        if (this.props.match.params.quoteId) {
            delete quote.quote.account_id;
            delete quote.quote.account_timezone;
            // delete quote.quote.quote_customer_ids
            // quote.quote['quote_customer_deleted_ids'] = this.state.idsToDelete
            this.props.getQuotePresignedUrl(quote.quote, extentions, arrayBuffer, this.state.idsToDelete, false);
        } else {
            this.props.addNewQuote(quote, arrayBuffer, extentions, false, this.state.idsToDelete);
        }
    };

    handelQuoteSubmitNow = (values, a, b, c) => {
        let quote = cloneDeep(values);
        quote.quote['private_quote'] =
            this.props.inspection && this.props.inspection.private ? this.props.inspection.private : false;
        // quote.quote['account_id'] = quote.quote['account_id']['value'];
        quote.quote['submit_now'] = true;
        this.setState({
            submitNow: true,
        });
        const usermode = getUser();
        quote.quote['mode'] = usermode.mode;
        delete quote.quote.status;
        quote.quote['quote_customer_ids'] =
            quote.quote.quote_customer_ids &&
            quote.quote.quote_customer_ids.map((c) => {
                return c.value;
            });

        const arrayBuffer = [];
        this.state.base64.map((url, i) => {
            if (!url.photo_urls) {
                let base_photo = null;
                const image = url.split(',');
                base_photo = image[1];

                arrayBuffer.push(decode(base_photo));
            }
        });
        const extentions = extractExtention(this.state.imageName);

        if (this.props.match.params.quoteId) {
            delete quote.quote.account_id;
            delete quote.quote.account_timezone;
            // delete quote.quote.quote_customer_ids
            // quote.quote['quote_customer_deleted_ids'] = this.state.idsToDelete
            this.props.getQuotePresignedUrl(quote.quote, extentions, arrayBuffer, this.state.idsToDelete, false);
        } else {
            this.props.addNewQuote(quote, arrayBuffer, extentions, false, this.state.idsToDelete);
        }
    };

    handelInspectionQuoteSubmitLater = (values) => {
        let quote = cloneDeep(values);
        quote.quote['submit_now'] = false;
        quote.quote['private_quote'] = this.props.inspection.private;
        if (this.props.user.role === userRoles.REQUESTOR || this.props.user.role === userRoles.INSPECTOR) {
            quote.quote['status'] = 'draft';
        }
        quote.quote['quote_customer_ids'] =
            quote.quote.quote_customer_ids &&
            quote.quote.quote_customer_ids.map((c) => {
                return c.value;
            });
        quote.quote['account_id'] = this.props.inspection.account_info.id;
        quote.quote['inspection_id'] = this.props.inspection.id;
        const usermode = getUser();
        quote.quote['mode'] = usermode.mode;
        quote.quote['line_item_id'] = this.props.lineItemId;
        this.setState({
            submitLater: true,
        });
        const arrayBuffer = [];
        this.state.base64.map((url, i) => {
            if (!url.photo_urls) {
                let base_photo = null;
                const image = url.split(',');
                base_photo = image[1];

                arrayBuffer.push(decode(base_photo));
            }
        });
        const extentions = extractExtention(this.state.imageName);

        this.props.addNewQuote(quote, arrayBuffer, extentions, true, this.state.idsToDelete);
    };

    handelInspectionQuoteSubmitNow = (values) => {
        let quote = cloneDeep(values);
        quote.quote['private_quote'] = this.props.inspection.private;
        quote.quote['submit_now'] = true;
        quote.quote['account_id'] = this.props.inspection.account_info.id;
        quote.quote['inspection_id'] = this.props.inspection.id;
        quote.quote['quote_customer_ids'] =
            quote.quote.quote_customer_ids &&
            quote.quote.quote_customer_ids.map((c) => {
                return c.value;
            });
        const usermode = getUser();
        quote.quote['mode'] = usermode.mode;
        quote.quote['line_item_id'] = this.props.lineItemId;
        this.setState({
            submitNow: true,
        });
        const arrayBuffer = [];
        this.state.base64.map((url, i) => {
            if (!url.photo_urls) {
                let base_photo = null;
                const image = url.split(',');
                base_photo = image[1];

                arrayBuffer.push(decode(base_photo));
            }
        });
        const extentions = extractExtention(this.state.imageName);

        this.props.addNewQuote(quote, arrayBuffer, extentions, true, this.state.idsToDelete);
    };

    onCancleClicked = () => {
        this.props.history.push(routes.QUOTES);
    };

    submitManuallQuote = (quote) => {
        
        const usermode = getUser();
        quote.quote['private_quote'] =
            this.props.inspection && this.props.inspection.private ? this.props.inspection.private : false;
        quote.quote['mode'] = usermode.mode;
        quote.quote['confirmed_by'] = quote.quote.confirmed_by.value;
        quote.quote['quote_created_at'] = convertTimeStringToUTCFormAudit(ConvertToFormat(quote.quote.quote_created_at,"YYYY-MM-DDTHH:mm:ss"),"YYYY-MM-DDTHH:mm:ss");
        delete quote.quote.status;
        
        showConfirmAlert('', 'Are you sure that you would like to send this quote to your customer?', () => {
            const arrayBuffer = [];
            this.state.base64.map((url, i) => {
                if (!url.photo_urls) {
                    let base_photo = null;
                    const image = url.split(',');
                    base_photo = image[1];

                    arrayBuffer.push(decode(base_photo));
                }
            });
            const extentions = extractExtention(this.state.imageName);

            this.props.addNewQuote(quote, arrayBuffer, extentions, false, this.state.idsToDelete);
            // this.props.addNewQuote(quote);
        });
        // this.props.addQuoteManually(quote);
    };
    accountChanged = (event) => {
        if (event && event.value) {
            this.props.getCustomers(event.value);
        } else {
            this.props.getCustomers(event);
        }
    };

    inspectionAccountChanged = (event) => {
        this.props.getCustomers(event);
    };

    render() {
        if (this.props.getQuoteLoading) {
            return (
                <main className="app-content  wraper_content inspection_wraper user_newQuote">
                    <div className="inspection_content_sec appcontent_Inner">
                        <div className="container-fluid">
                            <QuotesSkeleton />
                        </div>
                    </div>
                </main>
            );
        } else {
            if (
                this.props.history.location.pathname === routes.NEW_QUOTES ||
                this.props.history.location.pathname.includes('editquotes')
            ) {
                return (
                    <NewQuote
                        match={this.props.match}
                        state={this.state}
                        accountsList={this.props.dropDownAccountsList ? this.props.dropDownAccountsList : []}
                        onCancleClicked={this.onCancleClicked}
                        user={this.props.user}
                        quote={this.props.quote}
                        userAccountLists={this.props.userAccountLists ? this.props.userAccountLists : []}
                        toggleTagOpportunities={this.props.toggleTagOpportunities}
                        isLoading={this.props.isLoading}
                        // onSubmit={this.handelSubmit}
                        dropDownCustomerList={this.props.dropDownCustomerList}
                        accountChanged={this.accountChanged}
                        handelQuoteSubmitLater={this.handelQuoteSubmitLater}
                        handelQuoteSubmitNow={this.handelQuoteSubmitNow}
                        choosePhotoClicked={this.choosePhotoClicked}
                        onPortfolioFileChange={this.onPortfolioFileChange}
                        setLoader={this.state.setLoader}
                        savedPortfolioURls={this.state.savedPortfolioURls}
                        removeImage={this.removeImage}
                        portfolioImages={this.state.base64}
                        idsToDelete={this.state.idsToDelete}
                        removeUrls={this.removeUrls}
                        reference={this.inputOpenFileRef}
                    />
                );
            } else if (this.props.history.location.pathname === routes.MANUALL_QUOTE) {
                return (
                    <CreateQuoteManually
                        match={this.props.match}
                        accountsList={this.props.dropDownAccountsList ? this.props.dropDownAccountsList : []}
                        onCancleClicked={this.onCancleClicked}
                        user={this.props.user}
                        accountChanged={this.accountChanged}
                        userAccountLists={this.props.userAccountLists ? this.props.userAccountLists : []}
                        toggleTagOpportunities={this.props.toggleTagOpportunities}
                        isLoading={this.props.isLoading}
                        submitManuallQuote={this.submitManuallQuote}
                        dropDownCustomerList={this.props.dropDownCustomerList}
                        choosePhotoClicked={this.choosePhotoClicked}
                        onPortfolioFileChange={this.onPortfolioFileChange}
                        setLoader={this.state.setLoader}
                        savedPortfolioURls={this.state.savedPortfolioURls}
                        removeImage={this.removeImage}
                        portfolioImages={this.state.base64}
                        idsToDelete={this.state.idsToDelete}
                        removeUrls={this.removeUrls}
                        reference={this.inputOpenFileRef}
                    />
                );
            } else {
                return (
                    <NewInspectionQuote
                        state={this.state}
                        {...this.props}
                        accountsList={this.props.dropDownAccountsList ? this.props.dropDownAccountsList : []}
                        onCancleClicked={this.onCancleClicked}
                        user={this.props.user}
                        closeModal={this.props.closeModal}
                        userAccountLists={this.props.userAccountLists ? this.props.userAccountLists : []}
                        toggleTagOpportunities={this.props.toggleTagOpportunities}
                        isLoading={this.props.isLoading}
                        handelInspectionQuoteSubmitLater={this.handelInspectionQuoteSubmitLater}
                        handelInspectionQuoteSubmitNow={this.handelInspectionQuoteSubmitNow}
                        choosePhotoClicked={this.choosePhotoClicked}
                        onPortfolioFileChange={this.onPortfolioFileChange}
                        setLoader={this.state.setLoader}
                        savedPortfolioURls={this.state.savedPortfolioURls}
                        dropDownCustomerList={this.props.dropDownCustomerList}
                        accountChanged={this.inspectionAccountChanged}
                        removeImage={this.removeImage}
                        portfolioImages={this.state.base64}
                        idsToDelete={this.state.idsToDelete}
                        removeUrls={this.removeUrls}
                        reference={this.inputOpenFileRef}
                    />
                );
            }
        }
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.authReducer.user,
        userAccountLists: state.adminOrSuperAdminReducer.userAccountLists,
        dropDownAccountsList: state.adminOrSuperAdminReducer.modeAccountsList,
        inspection: state.adminOrSuperAdminReducer.inspection,
        isLoading: state.adminOrSuperAdminReducer.isLoading,
        quote: state.adminOrSuperAdminReducer.quote,
        getQuoteLoading: state.adminOrSuperAdminReducer.getQuoteLoading,
        dropDownCustomerList: state.adminOrSuperAdminReducer.dropDownCustomerList,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getUserAccounts: (id) => dispatch(actions.getUserAccounts(id)),
        getModeAccounts: (mode) => dispatch(actions.getModeAccounts(mode)),
        toggleTagOpportunities: () => actions.toggleTagOpportunities(),
        addNewQuote: (quote, arrayBuffer, extentions, openQuoteModal, idsToDelete) =>
            dispatch(actions.addNewQuote(quote, arrayBuffer, extentions, openQuoteModal, idsToDelete)),
        updateQuote: (quote) => dispatch(actions.updateQuote(quote)),
        getQuote: (quoteId) => dispatch(actions.getQuote(quoteId)),
        addQuoteManually: (quote) => dispatch(actions.addQuoteManually(quote)),
        closeModal: () => dispatch(actions.closePopup()),
        getCustomers: (id) => dispatch(actions.getCustomers(id)),
        getQuotePresignedUrl: (quote, extentions, arrayBuffer, idsToDelete) =>
            dispatch(actions.getQuotePresignedUrl(quote, extentions, arrayBuffer, idsToDelete)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewQuotesContainer);
