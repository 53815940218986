import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

const Field = (h, w) => {
    return (<Skeleton height={h} width={w} />)
}

const Image = () => {
    return (<Skeleton circle={true} height={100} width={100} />)
}

const LogbookFeedbackSkeleton = (props) => {
    return (
        <main className="app-content  wraper_content inspection_wraper user_newQuote">
        <div className="inspection_content_sec appcontent_Inner">
            <div className="container-fluid">
                    {/* <form onSubmit={handleSubmit}> */}
                    <div className="quote_form mt-5">
                        <div className="addQuote_form">
                            <div className="cst_tab_content">


                                <div className="row">
                                    <div className="col-md-6" style={{ marginTop: '25px' }}>
                                        <div className="form-group">

                                            {/* <label for="email" className="label_modifier"></label> */}
                                            {Field(20, 350)}
                                            {/* <Input type="text" className="form-control input-modifier" id="email" placeholder="" name="user[first_name]" /> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6" style={{ marginTop: '25px' }}>
                                        <div className="form-group">
                                            {Field(20, 350)}

                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6" style={{ marginTop: '25px' }}>
                                        <div className="form-group">
                                            {Field(20, 350)}

                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6" style={{ marginTop: '25px' }}>
                                        <div className="form-group">
                                            {Field(20, 350)}

                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6" style={{ marginTop: '25px' }}>
                                        <div className="form-group">
                                            {Field(20, 350)}

                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6" style={{ marginTop: '25px' }}>
                                        <div className="form-group">
                                            {Field(20, 350)}

                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6" style={{ marginTop: '15px' }}>
                                        <div className="form-group">
                                            {Field(30)}

                                        </div>
                                    </div>
                                    <div className="col-md-6" style={{ marginTop: '15px' }}>
                                        <div className="form-group">
                                            {Field(30)}

                                        </div>
                                    </div>
                                </div>
                                
                                <div className="row">
                                    <div className="col-md-6" style={{ marginTop: '15px' }}>
                                        <div className="form-group">
                                            {Field(30, 350)}

                                        </div>
                                    </div>
                                </div>
                                {/* </form> */}
                            </div>
                        </div>
                    </div>
                    {/* </form> */}
                </div>
            </div>
        </main>
    )
}

export default LogbookFeedbackSkeleton;