import React, { useState } from 'react';
import ReactTable from 'react-table-v6';
import { CustomNoDataComponent, makeFirstLetterCapital,} from '../../../../utility/utility';
import { FaArrowDown, FaArrowUp } from "react-icons/fa";
import * as actions from '../../../../redux/actions/index';
import { useSelector, useDispatch } from 'react-redux';
import { ClapSpinner } from 'react-spinners-kit';
import 'react-table-v6/react-table.css';
import { convertDateUTCFormAudit } from '../../../../utility/TimezoneOperations/timezoneUtility';
var moment = require('moment');


const handleChangeName = (name) => {
    if(name === "president_visit_call")
    {
        return "President Visit/Call";
    }
    else if(name === "vice_president_visit")
    {
        return "Vice President Visit";  
    }
    else if(name === "business_review_report")
    {
        return "Business Review Report";
    }
    else if(name === "business_review_meeting")
    {
        return "Business Review Meeting";
    }
    else if( name === "quality_control_visits"){
        return "Quality Control Visits";
    }else{
        return null;
    }
}


const handleChangefrequency = (name) => {
    if(name === "weekly")
        {
            return "Weekly";
        }
        else if(name === "monthly")
        {
            return "Monthly";
        }
        else if(name === "semi_annual")
        {
            return "Semi Annual";
        }
        else if(name === "bi_monthly")
        {
            return "2 x Month"; 
        }
        else if(name === "annual")
        {
            return "Annual";
        }
        else if(name === "none"){
            return "N/A";
        }
        else if(name === "quarterly")
        {
           return "Quarterly";
        }
        else{
            return null;
        }
}


let BrmScheduleTable = (props) => {
    
    const dispatch = useDispatch();
    const SearchValue = useSelector(state => state.form.brmScheduleForm && state.form.brmScheduleForm.values ? state.form.brmScheduleForm.values : {});
    const DateGenerate = useSelector(state => state.BRM.dataGenerated);
    const values = useSelector(state => state.BRM.brmScheduleList_searchValues);
    const No_of_pages = useSelector(state => state.BRM.brmScheduleList_total_pages);
    const No_of_rows = useSelector(state => state.BRM.brmScheduleList_no_of_rows);
    const isBrmScheduleList = useSelector(state => state.BRM.isBrmScheduleList);
    const brmScheduleList_curPage = useSelector(state => state.BRM.brmScheduleList_curPage);
    const SearchOrResetScheduledTaskListClicked = useSelector(state => state.BRM.SearchOrResetScheduledTaskListClicked);

    let isStandard = props.brmScheduleList.length && props.brmScheduleList[0].matrix.type=="default"

    const data = props.brmScheduleList && props.brmScheduleList.map((value) => {
        let matrix_id= value?.matrix ? value.matrix.matrix :""
        return {
            id : value && value.id ? value.id : '-',
            name:  value && value.matrix ? handleChangeName(value.matrix?.name) : '-',
            company: value && value.company ? value.company?.name : '-',
            matrix_id:  value && value.matrix_id ? matrix_id == 1 ? "Tier A": matrix_id == 2 ? "Tier B" : matrix_id == 3 ? "Tier C" : '-' : '-',
            status : value && value.status ? makeFirstLetterCapital(value.status) : '-',
            createdat: value && value?.created_at ? convertDateUTCFormAudit(value?.created_at) : "",
            frequency:value && value?.matrix?.frequency ? handleChangefrequency(value.matrix.frequency) :"",
            account_id:value && value.account?.name ? value.account?.name :"",
            actions:
                <>
                <span className="mr_20 account_edit_icn" onClick={() => props.history.push({pathname:`/preview-event/${value.id}`,state:{isFetch:Boolean(value?.status=="completed")}})} ><i class="fas fa-eye" aria-hidden="true" ></i></span>
                </>
        }
    })
      
    const columns = [
        {
            Header: <><span>ID</span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /></>,
            accessor: 'id',
            show: props.brmScheduleList.length !== 0,
        },
        {
            Header: <><span>Created At</span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
            accessor: 'createdat',
            show: props.brmScheduleList.length !== 0
        },
        {
            Header: <><span>Customer</span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /></>,
            accessor: 'company',
            show: props.brmScheduleList.length !== 0  ,
        },
        {
            Header: <><span>Job</span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /></>,
            accessor: 'account_id',
            show: props.brmScheduleList.length !== 0  && isStandard==false ,
        },
        {
            Header: <><span>Meeting Name</span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /></>,
            accessor: 'name',
            show: props.brmScheduleList.length !== 0,
        },
        {
            Header: <><span>Frequency</span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /></>,
            accessor: 'frequency',
            show: props.brmScheduleList.length !== 0,
        },
        {
            Header: <><span>Tier</span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /></>,
            accessor: 'matrix_id',
            show: props.brmScheduleList.length !== 0,
        },
        {
            Header: <><span>Status</span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
            accessor: 'status',
            show: props.brmScheduleList.length !== 0
        },
        {
            Header: '',
            accessor: 'actions',
            filterable: false,
        }
    ]

    const [tablePage, setTablePage] = useState(0)
    const tablePageNew = React.useRef()
    if (brmScheduleList_curPage !== tablePageNew.current) {
        tablePageNew.current = brmScheduleList_curPage;
    }
    const setTablePageFn = (page) => {
        setTablePage(page)
        props.brmSchedulePageClicked(page)
        tablePageNew.current = page;
    }

    const fetchData = (state, instance) => {
        if (SearchOrResetScheduledTaskListClicked) {
            dispatch(actions.resetSearchOrResetInventory())
        } else {
            if (!DateGenerate && !props.isBrmScheduleList) {
                state.page = 2;
                const limit = state.pageSize;
                let page = tablePageNew.current >= 0 ? tablePageNew.current + 1 : brmScheduleList_curPage + 1;
                const inventoryItemsList_no_of_rows = state.pageSize;
                if (No_of_rows != inventoryItemsList_no_of_rows) {
                    page = 1;
                    setTablePageFn(0)
                }

                const order = state.sorted[0]?.id;
                const orderBy = state.sorted && state.sorted.length ? state.sorted[0]?.desc === true ? 'desc' : 'asc' : null;
                const id = SearchValue.id ? SearchValue.id : null
                const from = SearchValue.fromDate ? moment(values.fromDate).format('YYYY-MM-DD') : null;
                const to = SearchValue.toDate ? moment(values.toDate).format('YYYY-MM-DD') : null;
                const name = SearchValue.meeting ? SearchValue.meeting?.value : null
                const status = SearchValue.status ? SearchValue.status : null
                const matrix = SearchValue.matrix ? SearchValue.matrix : null
                const company_id = SearchValue.customer ? SearchValue.customer?.value : null
                const type= SearchValue.type ? SearchValue.type.value :"default"
                props.getAllBrmSchedule({ page, limit, order, orderBy, id, from, to, name,status, matrix, company_id,type}, values)
            }
        }
    }

    return (
        SearchOrResetScheduledTaskListClicked && props.isBrmScheduleList ?
            <div  >
                <ClapSpinner backColor="#89073c" size={40} frontColor="#89073c8a" />
            </div>
            :
            <ReactTable
                data={data}
                loading={props.isBrmScheduleList}
                minRows={0}
                defaultPageSize={No_of_rows}
                pages={No_of_pages}
                gotoPage={brmScheduleList_curPage}
                page={brmScheduleList_curPage}
                onPageChange={(page) => { setTablePageFn(page) }}
                // onPageSizeChange={props.onUserPageSizeChange}
                manual
                onFetchData={fetchData}
                showPaginationTop={true}
                showPaginationBottom={false}
                showPagination={props.brmScheduleList.length !== 0}
                columns={columns}
                // style={{marginTop: '10px'}}
                NoDataComponent={() => CustomNoDataComponent(isBrmScheduleList, 'No BRM Schedule List Found')}
                LoadingComponent={() =>
                    isBrmScheduleList ?
                        <div style={{
                            display: "block",
                            position: "absolute",
                            left: 0,
                            height: '100%',
                            right: 0,
                            background: "rgba(255,255,255,0.8)",
                            transition: "all .3s ease",
                            top: 0,
                            bottom: 0,
                            textAlign: "center"
                        }}
                            className="loader_btn_block">
                            <ClapSpinner backColor="#89073c" size={40} frontColor="#89073c8a" />
                        </div> : null}
                getTheadThProps={(state, rowInfo, column, instance) => {
                    // console.log(column);
                    return {
                        style: {
                            borderTop: 'none',
                            borderBottom: 'none',
                            borderRight: 'none',
                            textAlign: 'center',
                            color: '#566975',
                            fontSize: '14px',
                            fontWeight: '600',
                            whiteSpace: 'nowrap',
                            float: 'left',
                        }
                    }
                }
                }
                getTrProps={(state, rowInfo, column, instance) => {
                    // onclick = () => userDetail(rowInfo ? rowInfo.row.user : rowInfo);
                    console.log(column);
                    return {
                        className: 'react-table-tr-element',
                        style: {
                            border: '1px solid #c7cdd1',
                            transition: '.3s ease-in-out',
                            width: '100%',
                            // borderRadius: '4px',
                            // height: '80px',
                            marginTop: '5px',
                            float: 'left'
                        }
                    }

                }}
                getTdProps={(state, rowInfo, column, instance) => {

                    if (column.id == 'image') {
                        return {
                            style: {
                                marginTop: '0px'
                            }
                        }
                    } else {
                        return {
                            className: 'react-td-element',
                        }
                        //  {
                        //   style: {
                        //     borderTop: 'none',
                        //     verticalAlign: 'middle',
                        //     fontSize: '14px',
                        //     color: '#2b3034',
                        //     marginTop: '10px !important',
                        //     textAlign: 'center',
                        //   }
                        // }
                    }
                }
                }
                // getPaginationProps={() => {
                //     return {
                //         style: {
                //             marginTop: '50px',
                //             border: 'none',
                //             boxShadow: 'none'
                //         }
                //     }
                // }
                // }
                getProps={() => {
                    return {
                        style: {
                            border: 'none'
                        }
                    }
                }}
            />
    )
}
export default BrmScheduleTable;