import { PegassurePlaybookActionTypes } from "../../actions/PegassurePlaybookModAction/PegassurePlaybookActionTypes";
import storage from "../../../utility/storage";
import SuccessAlert from "../../../utility/successAlert/successAlert";
const cloneDeep = require("clone-deep");

export const initialState = {

    isLoading: false,
    dataGenerated: false,
    SearchOrResetPegassurePlaybookListClicked: false,

    openPegPlaybook_deleteModal: false,
    deletePegPlaybook_object: {},

    // PEGASSURE PLAYBOOK PDF LIST
    isPegassurePlaybookList: false,
    pegassurePlaybookList: [],
    pegassurePlaybookList_total_pages: 1,
    pegassurePlaybookList_no_of_rows: 10,
    pegassurePlaybookList_curPage: 0,
    pegassurePlaybookList_total_tickets: 0,
    pegassurePlaybookList_searchValues: {},

    // PEGASSURE PLAYBOOK PDF LABELS
    playbookPdfLabelsList: [],

    // SOLO PLAYBOOK PDF OVERVIEW
    soloPlaybookPDFInfo: {},

    // Update events
    isPlaybookPdfEdit: false,
    playbookId: null,

    // PEGASSURE PLAYBOOK PDF ATTACHED AREAS
    selectedPlaybookInfo: {},
    playbookAreaList: [],
    playbookAreaHeaderUtils: {},

    playbookInfoByLogNumber: {},

};

const updateObject = (oldState, updatedProps) => {
    return {
        ...oldState,
        ...updatedProps,
    };
};

export const PegassurePlaybookReducer = (state = initialState, action) => {
    switch (action.type) {

        case PegassurePlaybookActionTypes.RESET_PEGASSURE_PLAYBOOK_EVENTS:
            return updateObject(state, {
                // Update events
                isPlaybookPdfEdit: false,
                playbookId: null,
                soloPlaybookPDFInfo: {},
            });

        case PegassurePlaybookActionTypes.RESET_SEARCH_OR_RESET:
            return updateObject(state, {
                SearchOrResetPegassurePlaybookListClicked: false,
            });

        case PegassurePlaybookActionTypes.HANDLE_PEGASSURE_PLAYBOOK_OPEN_DELETE_MODAL:
            return updateObject(state, {
                openPegPlaybook_deleteModal: true,
                deletePegPlaybook_object: action.payload,
            });
        case PegassurePlaybookActionTypes.HANDLE_PEGASSURE_PLAYBOOK_CLOSE_DELETE_MODAL:
            return updateObject(state, { openPegPlaybook_deleteModal: false });

        /* Amazon S3 Bucket Upload Reducer */
        case PegassurePlaybookActionTypes.UPLOAD_IMAGE_TO_S3_PENDING:
            return updateObject(state, { isLoading: true });
        case PegassurePlaybookActionTypes.UPLOAD_IMAGE_TO_S3_FULFILLED:
            return updateObject(state, { isLoading: true });

        /* PATCH Presign URL Reducer for Pegassure Playbook PDF */
        case PegassurePlaybookActionTypes.GET_PEGASSURE_PLAYBOOK_PDF_PRESIGNED_URL_PENDING:
            return updateObject(state, { isLoading: true });
        case PegassurePlaybookActionTypes.GET_PEGASSURE_PLAYBOOK_PDF_PRESIGNED_URL_FULFILLED:
            return updateObject(state, { isLoading: true });

        /* POST Create Playbook Pdf Reducer for Pegassure Playbook PDF */
        case PegassurePlaybookActionTypes.CREATE_PEGASSURE_PLAYBOOK_PDF_PENDING:
            return updateObject(state, { isLoading: true });
        case PegassurePlaybookActionTypes.CREATE_PEGASSURE_PLAYBOOK_PDF_FULFILLED:
            return updateObject(state, { isLoading: false });

        /* PUT Update Playbook Pdf Reducer for Pegassure Playbook PDF */
        case PegassurePlaybookActionTypes.UPDATE_PEGASSURE_PLAYBOOK_PDF_PENDING:
            return updateObject(state, { isLoading: true });
        case PegassurePlaybookActionTypes.UPDATE_PEGASSURE_PLAYBOOK_PDF_FULFILLED:
            return updateObject(state, { isLoading: false });

        /* Trigger call for Update PUT method */
        case PegassurePlaybookActionTypes.UPDATE_PEGASSURE_PLAYBOOK_PDF_CLICKED:
            return updateObject(state, {
                isPlaybookPdfEdit: true,
                playbookId: action.payload,
            })

        /* DELETE Remove Playbook Pdf Reducer for Pegassure Playbook PDF */
        case PegassurePlaybookActionTypes.DELETE_PEGASSURE_PLAYBOOK_PDF_PENDING:
            return updateObject(state, { isLoading: true })
        case PegassurePlaybookActionTypes.DELETE_PEGASSURE_PLAYBOOK_PDF_FULFILLED:
            return updateObject(state, {
                isLoading: false,
                openPegPlaybook_deleteModal: false
            })

        /* GET List of Playbook Pdf Reducer for Pegassure Playbook PDF */
        case PegassurePlaybookActionTypes.GET_PEGASSURE_PLAYBOOK_PDF_LIST_PENDING:
            return updateObject(state, {
                isLoading: true,
                isPegassurePlaybookList: true,
                pegassurePlaybookList: [],
            });
        case PegassurePlaybookActionTypes.GET_PEGASSURE_PLAYBOOK_PDF_LIST_FULFILLED:
            return updateObject(state, {
                isLoading: false,
                dataGenerated: false,
                isPegassurePlaybookList: false,
                pegassurePlaybookList: action.payload?.pegassure_playbookList,
                pegassurePlaybookList_total_pages: action.payload?.pegassure_playbookList_total_pages,
                pegassurePlaybookList_no_of_rows: action.payload?.pegassure_playbookList_no_of_rows,
                pegassurePlaybookList_curPage: action.payload?.pegassure_playbookList_curPage,
                pegassurePlaybookList_total_tickets: action.payload?.pegassure_playbookList_total_tickets,
                pegassurePlaybookList_searchValues: action.payload?.searchValues,
                pegassure_playbookList_default_currentPage: action.payload?.pegassure_playbookList_default_currentPage,
            });

        case PegassurePlaybookActionTypes.PEGASSURE_PLAYBOOK_LIST_PAGE_CLICKED:
            return updateObject(state, { pegassurePlaybookList_curPage: action.payload });

        /* GET List of Playbook Pdf Labels Reducer for Pegassure Playbook PDF */
        case PegassurePlaybookActionTypes.GET_ALL_PLAYBOOK_PDF_LABELS_PENDING:
            return updateObject(state, {
                isLoading: true,
            });
        case PegassurePlaybookActionTypes.GET_ALL_PLAYBOOK_PDF_LABELS_FULFILLED:
            return updateObject(state, {
                isLoading: false,
                playbookPdfLabelsList: action.payload.data,
            });

        /* GET Single Playbook Pdf Info Reducer for Pegassure Playbook PDF */
        case PegassurePlaybookActionTypes.GET_SINGLE_PLAYBOOK_PDF_INFO_PENDING:
            return updateObject(state, {
                isLoading: true,
            });
        case PegassurePlaybookActionTypes.GET_SINGLE_PLAYBOOK_PDF_INFO_FULFILLED:
            return updateObject(state, {
                isLoading: false,
                soloPlaybookPDFInfo: action.payload.data,
            });

        /* GET All Logbook areas list occupied with Playbook PDF Reducer for Pegassure Playbook PDF */
        case PegassurePlaybookActionTypes.GET_ALL_LOGBOOK_AREA_OCCUPIED_WITH_PLAYBOOK_PENDING:
            return updateObject(state, {
                isLoading: true,
            });
        case PegassurePlaybookActionTypes.GET_ALL_LOGBOOK_AREA_OCCUPIED_WITH_PLAYBOOK_FULFILLED:
            return updateObject(state, {
                isLoading: false,
                selectedPlaybookInfo: action.payload.selected_playbook_info,
                playbookAreaList: action.payload.playbook_area_list,
                playbookAreaHeaderUtils: action.payload.playbook_area_header_utils,
            });

        /* GET Playbook PDF Reducer info by logbook area */
        case PegassurePlaybookActionTypes.GET_PLAYBOOK_INFO_BY_LOG_NUMBER_PENDING:
            return updateObject(state, {
                isLoading: true,
            });
        case PegassurePlaybookActionTypes.GET_PLAYBOOK_INFO_BY_LOG_NUMBER_FULFILLED:
            return updateObject(state, {
                isLoading: false,
                playbookInfoByLogNumber: action.payload.accountArea,
            });

        default: return state;
    }
}