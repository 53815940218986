import React from 'react';
import { tzDateConverterTool_V1, tzDateConverterTool_V7, tzTimeConversionTool_V1, tzTimeConversionTool_V2 } from '../../../../utility/TimezoneOperations/timezoneUtility';
import { getOrdinalNumber, getUser, makeFirstLetterCapital } from '../../../../utility/utility';
import { TimeZones } from '../../../../utility/constants/constants';
import { ClapSpinner } from 'react-spinners-kit';
var feedGap = null;

let ViewAttendanceDashboardModal = (props) => {

    const { isLoading, singleShiftScheduleData, attendancePanelType, attendancePanelObj, handleShiftPresencePanel_closeModal } = props;
    let currentUser = getUser();
    let zone = null;
    let shiftComplaintArray = [];
    let mealBreakComplaintArray = [];

    if (currentUser) {
        if (currentUser.timezone && currentUser.timezone == TimeZones.PST) {
            zone = '(PST)'
        } else if (currentUser.timezone && currentUser.timezone == TimeZones.CST) {
            zone = '(CST)'
        }
    }

    /* This functionality for meal breaks Ascending order */
    if (singleShiftScheduleData && singleShiftScheduleData.breaks) {
        feedGap = singleShiftScheduleData?.breaks?.sort((a, b) => {
            return new Date(a.expected_start) - new Date(b.expected_start);
        })
    };

    if (attendancePanelType == 'complaint') {
        attendancePanelObj.complaints.filter((complaint) => {
            if (complaint.entity_type == 'ShiftDetail') {
                return shiftComplaintArray.push(complaint);
            } else if (complaint.entity_type == 'Break') {
                return mealBreakComplaintArray.push(complaint);
            }
        })
    }

    return (
        <>

            <div className="modal fade request_quote_modal reject_quot associateUser" id="attendanceDashboardModal" tabindex="-1" role="dialog" aria-labelledby="rejectQuoteModalTitle" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content max_width_200 min_width_150">
                        <div className={'modal-header d-flex justify-content-center align-items-center'} style={{ height: '4rem' }}>
                            {attendancePanelType && attendancePanelType == 'present' ? (
                                <h5 className="font-weight-bold text-center fontSize_xxlarge" style={{color: '#77dd77'}}> Present </h5>
                            ) : attendancePanelType && attendancePanelType == 'absent' ? (
                                <h5 className="font-weight-bold text-center fontSize_xxlarge" style={{color: '#FF6961'}}> Absent </h5>
                            ) : attendancePanelType && attendancePanelType == 'complaint' ? (
                                <h5 className="font-weight-bold text-center fontSize_xxlarge" style={{color: '#fce50b'}}> Complaint </h5>
                            ) : null}
                        </div>

                        <div className="modal-body">
                            <div className="tab-content">

                                {props.isLoading == true ? (
                                    <div className="loader_btn_block" style={{ height: "300px" }}>
                                        <ClapSpinner backColor="#89073c" size={40} frontColor="#89073c8a" />
                                    </div>
                                ) : (
                                    <div id="profile" className="profile_tab cst_tab_content tab-pane fade show active edit_profile_modal">
                                        <form className="form_content view_attendance_dashboard_calendar_modal" style={{ height: '500px' }}>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <ul className="list-group rounded" style={{ marginLeft: '0%', marginRight: '0%' }}>
                                                        {attendancePanelType && attendancePanelType == 'absent' ? (
                                                            <li className="list-group-item">
                                                                <div className="media align-items-lg-center flex-column flex-lg-row p-3">
                                                                    <div className='text-center' style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%' }}>
                                                                        <div className="media-body order-2 order-lg-1 width_100">
                                                                            <h5 className="mt-0 font-weight-bold mb-2 text-muted">
                                                                                {attendancePanelObj?.assignedTo.id ? makeFirstLetterCapital(attendancePanelObj.assignedTo.first_name) + ' ' + makeFirstLetterCapital(attendancePanelObj.assignedTo.last_name) + ' ' + 'is Absent Today' : '_____'}
                                                                            </h5>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        ) : null}

                                                        <li className="list-group-item">
                                                            <div className="media align-items-lg-center flex-column flex-lg-row p-3">
                                                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%' }}>

                                                                    <div className="media-body order-2 order-lg-1  width_50">
                                                                        <h5 className="mt-0 font-weight-bold mb-2 text-dark"> Date </h5>
                                                                    </div>

                                                                    <h5 className="ml-lg-5 order-1 order-lg-2  width_50 text-muted">
                                                                        {attendancePanelObj?.date ? tzDateConverterTool_V1(attendancePanelObj.date) : '−'}
                                                                    </h5>

                                                                </div>
                                                            </div>
                                                        </li>

                                                        <li className="list-group-item">
                                                            <div className="media align-items-lg-center flex-column flex-lg-row p-3">
                                                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%' }}>

                                                                    <div className="media-body order-2 order-lg-1  width_50">
                                                                        <h5 className="mt-0 font-weight-bold mb-2 text-dark"> Shift ID </h5>
                                                                    </div>

                                                                    <h5 className="ml-lg-5 order-1 order-lg-2  width_50 text-muted">
                                                                        {attendancePanelObj?.shift_id ? attendancePanelObj.shift_id : '−'}
                                                                    </h5>

                                                                </div>
                                                            </div>
                                                        </li>

                                                        <li className="list-group-item">
                                                            <div className="media align-items-lg-center flex-column flex-lg-row p-3">
                                                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%' }}>

                                                                    <div className="media-body order-2 order-lg-1 width_50">
                                                                        <h5 className="mt-0 font-weight-bold mb-2 text-dark"> Shift Timings </h5>
                                                                    </div>

                                                                    <h5 className="ml-lg-5 order-1 order-lg-2 width_50 text-muted">
                                                                        {attendancePanelObj?.shift?.id ? `${tzTimeConversionTool_V2(attendancePanelObj.shift.start_time, attendancePanelObj.shift.start_date.split('T')[0])} − ${tzTimeConversionTool_V2(attendancePanelObj.shift.end_time, attendancePanelObj.shift.end_date.split('T')[0])} ${zone}` : '−'}
                                                                    </h5>

                                                                </div>
                                                            </div>
                                                        </li>

                                                        <li className="list-group-item">
                                                                    <div className="media align-items-lg-center flex-column flex-lg-row p-3">
                                                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%' }}>
                                                                            <div className="media-body width_50" style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                                                                <h5 className="mt-0 font-weight-bold mb-2 text-dark"> Job Name </h5>
                                                                            </div>
                                                                            <div className="media-body width_50" style={{ display: 'flex', flexDirection: 'column' }}>
                                                                                {attendancePanelObj?.accounts?.length > 0 ? attendancePanelObj?.accounts?.map((feed, index, array) => {
                                                                                    return (
                                                                                        <>
                                                                                            <div style={{ borderLeft: '5px solid #cfcfcf', backgroundColor: '#f0f0f0' }}>
                                                                                                <h5 className="ml-lg-3 order-1 order-lg-2 text-muted mt-2 mb-1"> {feed.name} </h5>
                                                                                            </div>
                                                                                            {index === array.length - 1 ? null : <br />}
                                                                                        </>
                                                                                    );

                                                                                }) : (<h5 className="ml-lg-0 font-italic text-muted mb-0"> − </h5>)}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </li>

                                                        {attendancePanelType && attendancePanelType == 'present' || attendancePanelType == 'complaint' ? (
                                                            <>
                                                                <li className="list-group-item">
                                                                    <div className="media align-items-lg-center flex-column flex-lg-row p-3">
                                                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%' }}>

                                                                            <div className="media-body order-2 order-lg-1 width_50">
                                                                                <h5 className="mt-0 font-weight-bold mb-2 text-dark"> Clocked In </h5>
                                                                            </div>

                                                                            <h5 className="ml-lg-5 order-1 order-lg-2 width_50 text-muted">
                                                                                {attendancePanelObj?.check_int_at ? `${tzTimeConversionTool_V1(attendancePanelObj.check_int_at.split('T')[1].split('.')[0], attendancePanelObj.check_int_at.split('T')[0])}` : '−'}
                                                                            </h5>

                                                                        </div>
                                                                    </div>
                                                                </li>

                                                                <li className="list-group-item">
                                                                    <div className="media align-items-lg-center flex-column flex-lg-row p-3">
                                                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%' }}>

                                                                            <div className="media-body order-2 order-lg-1 width_50">
                                                                                <h5 className="mt-0 font-weight-bold mb-2 text-dark"> Clocked Out </h5>
                                                                            </div>

                                                                            <h5 className="ml-lg-5 order-1 order-lg-2 width_50 text-muted">
                                                                                {attendancePanelObj?.check_out_at ? `${tzTimeConversionTool_V1(attendancePanelObj.check_out_at.split('T')[1].split('.')[0], attendancePanelObj.check_out_at.split('T')[0])}` : '−'}
                                                                            </h5>

                                                                        </div>
                                                                    </div>
                                                                </li>

                                                                <li className="list-group-item">
                                                                    <div className="media align-items-lg-center flex-column flex-lg-row p-3">
                                                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%' }}>
                                                                            <div className="media-body width_50" style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                                                                <h5 className="mt-0 font-weight-bold mb-2 text-dark"> Meal Break </h5>
                                                                            </div>
                                                                            <div className="media-body width_50" style={{ display: 'flex', flexDirection: 'column' }}>
                                                                                {feedGap?.length > 0 ? feedGap.map((feed, index, array) => {
                                                                                    return (
                                                                                        <>
                                                                                            <div style={{ borderLeft: '5px solid #cfcfcf', backgroundColor: '#f0f0f0' }}>
                                                                                                <h5 className="ml-lg-3 order-1 order-lg-2 text-muted mt-2 mb-1"> {getOrdinalNumber(index + 1)} meal </h5>
                                                                                                <h5 className="ml-lg-3 font-italic text-muted mb-2" style={{ fontSize: '15px' }}>
                                                                                                    {`${feed?.id && feed.start && feed.end ?
                                                                                                        `${tzTimeConversionTool_V2(feed.start.split('T')[1].split('.')[0], feed.start.split('T')[0])} − ${tzTimeConversionTool_V2(feed.end.split('T')[1].split('.')[0], feed.end.split('T')[0])} ${zone}`
                                                                                                        : 'No meal break data available'}`}
                                                                                                </h5>
                                                                                            </div>
                                                                                            {index === array.length - 1 ? null : <br />}
                                                                                        </>
                                                                                    );

                                                                                }) : (<h5 className="ml-lg-0 font-italic text-muted mb-0"> − </h5>)}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                            </>
                                                        ) : null}

                                                        {attendancePanelType && attendancePanelType == 'complaint' ? (
                                                            <>
                                                                <li className="list-group-item">
                                                                    <div className="media align-items-lg-center flex-column flex-lg-row p-3">
                                                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%' }}>
                                                                            <div className="media-body width_50" style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                                                                <h5 className="mt-0 font-weight-bold mb-2 text-dark"> Shift Complaints </h5>
                                                                            </div>
                                                                            <div className="media-body width_50" style={{ display: 'flex', flexDirection: 'column' }}>
                                                                                {shiftComplaintArray?.length > 0 ? (
                                                                                    <div style={{ borderLeft: '5px solid #cfcfcf', backgroundColor: '#f0f0f0', padding: '5%' }}>
                                                                                        {shiftComplaintArray.map((complaint, index, array) => {
                                                                                            return (
                                                                                                <>
                                                                                                    <h5 className="ml-lg-3 font-italic text-muted" style={{ fontSize: 'small' }}>
                                                                                                        <li key={complaint.id}>{complaint.reason}</li>
                                                                                                        {index === array.length - 1 ? null : <br />}
                                                                                                    </h5>
                                                                                                </>
                                                                                            );
                                                                                        })}
                                                                                    </div>
                                                                                ) : (
                                                                                    <h5 className="ml-lg-0 font-italic text-muted mb-0"> − No complaint found − </h5>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </li>

                                                                <li className="list-group-item">
                                                                    <div className="media align-items-lg-center flex-column flex-lg-row p-3">
                                                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%' }}>
                                                                            <div className="media-body width_50" style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                                                                <h5 className="mt-0 font-weight-bold mb-2 text-dark"> Meal Break Complaints  </h5>
                                                                            </div>
                                                                            <div className="media-body width_50" style={{ display: 'flex', flexDirection: 'column' }}>
                                                                                {mealBreakComplaintArray?.length > 0 ? (
                                                                                    <div style={{ borderLeft: '5px solid #cfcfcf', backgroundColor: '#f0f0f0', padding: '5%' }}>
                                                                                        {mealBreakComplaintArray.map((complaint, index, array) => {
                                                                                            return (
                                                                                                <>
                                                                                                    <h5 className="ml-lg-3 font-italic text-muted" style={{ fontSize: 'small' }}>
                                                                                                        <li key={complaint.id}>{complaint.reason}</li>
                                                                                                        {index === array.length - 1 ? null : <br />}
                                                                                                    </h5>
                                                                                                </>
                                                                                            );
                                                                                        })}
                                                                                    </div>
                                                                                ) : (
                                                                                    <h5 className="ml-lg-0 font-italic text-muted mb-0"> − No complaint found − </h5>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                            </>
                                                        ) : null}
                                                    </ul>
                                                </div>
                                            </div>
                                        </form>
                                        <div className="modal-footer">
                                            <button onClick={handleShiftPresencePanel_closeModal} type="button" className="btn btn-outline-secondary cst_btn" data-dismiss="modal"> Close </button>
                                        </div>
                                    </div>
                                )}

                            </div>
                        </div>
                    </div>
                </div>
            </div >

        </>
    );
};

export default ViewAttendanceDashboardModal;