import React, { useEffect, useState } from 'react';
import { Input } from '../../../UI/InputElement/InputElement';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import AssetStockTable from './assetStockTable';
import { makeFirstLetterCapital } from '../../../../../utility/utility';



let AssetStock = (props) => {

  const getLocationsList = (props) => {
    if (props.locations && props.locations.length > 0) {
      return props.locations && props.locations.map((location) => {
        return {
          id: location.name,
          name: makeFirstLetterCapital(location.name)
        }
      })
    } else {
      return []
    }
  };

  const assetsOptions = [
    {
      id: 'readyForUse',
      name: 'Ready For Use'
    },
    {
      id: 'needsService',
      name: 'Needs Service'
    },
    {
      id: 'underRepair',
      name: 'Under Repair'
    },
    {
      id: 'checkedOut',
      name: 'Checked Out'
    },
  ];

  const [locationsList, setLocationsListList] = useState(getLocationsList(props));

  useEffect(() => {
    setLocationsListList(getLocationsList(props))
  }, [props.locations])

  const { handleSubmit, pristine, reset, submitting, error } = props;
  return (
    <>
      <main className="app-content wraper_content inspection_wraper">
        <div className="tab_header search_filter_cont inspection_tab_content appcontent_inner frontlines_filter mb-2">
          <form onSubmit={handleSubmit} >
            <div className="row mt-2">
              <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
                <div className="app-search">
                  <Input className="fil_search_input input-modifier" name="sku_detail" type="search" placeholder="Search by Serial Name" dataTest="frontline_name_filter" />
                  <button className="app-search__button">
                    <i class="fa fa-search" aria-hidden="true"></i>
                  </button>
                </div>
              </div>
              <div className="col-xl-2 col-lg-3 col-md-6 col-sm-6 col-12">
                <div className="app-search">
                  <Input className="fil_search_input input-modifier" name="name" type="search" placeholder="Search by Name" dataTest="frontline_name_filter" />
                  <button className="app-search__button">
                    <i class="fa fa-search" aria-hidden="true"></i>
                  </button>
                </div>
              </div>
              <div className="col-xl-2 col-lg-3 col-md-6 col-sm-6 col-12">
                <Input name="status" dataTest="select_ticket_status" type="select" options={assetsOptions} placeholder="Search By Status" className="custom-select input-modifier add_user_select" />
              </div>
              <div className="col-xl-2 col-lg-3 col-md-6 col-sm-6 col-12">
                <Input name="location" dataTest="select_ticket_status" type="select" options={locationsList} placeholder="Search By Location" className="custom-select input-modifier add_user_select" />
              </div>
              <div className="col-xl-4 col-lg-3 col-md-6 col-sm-6 col-12 text-left mt-3">
                <div className="frontline_btn">
                  <button data-test="filter_go_btn" style={{ minWidth: '90px' }} className={"btn cst_btn btn_danger mr-2"} id="submit" type="submit">{"Go"} </button>
                  <button data-test="filter_reset_btn" style={{ minWidth: '90px' }} onClick={props.resetClicked} className={"btn cst_btn btn-outline-secondary"} id="reset" type="reset">{"Reset"} </button>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div className='mt-4'>
          {/* <div class=" mr-4 text-left ">
            <button className="btn cst_btn btn_danger float-right" onClick={props.addAssetStockClicked}> Add Asset Stock </button>
          </div> */}
          {/* <div class=" ml-4 text-right ">
            <div className="theme_switch_btn float-left" style={{ display: "inline" }}>
              <span className="switch_title">In-active</span>
              <label>
                <input type="checkbox" checked={props.activeAssets} onChange={(e) => { props.assetActiveStatusToggle(e) }} />
                <span></span>
              </label>
              <span className="ext_title">Active</span>
            </div>
          </div> */}
        </div>
        <section className="account_sec user_screen inspection_tab_content">

          <div className="quotes_table_content table-responsive">

            <div className="table quotes_table account_table">
              <AssetStockTable
                {...props}
              />
            </div>

          </div>
        </section>
      </main>

      <Dialog
        open={props.openInventoryDeleteModal}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title" style={{ fontSize: '13', color: '#06080f' }}>
          <h3 style={{ color: '#ab385e' }}>Are you sure?</h3>
        </DialogTitle>

        <DialogActions>
          <button style={{ minHeight: '36px', minWidth: '80px' }} onClick={() => props.deleteAssetStock(props.deleteInventoryObject.id)} disabled={props.isLoading} className={props.isLoading ? "btn cst_btn btn_danger mr-2 btn-wait" : "btn cst_btn btn_danger mr-2"} id="delete" type="delete">{props.isLoading ? "" : "Delete"}</button>
          <button style={{ minHeight: '36px', minWidth: '80px' }} onClick={props.handleInventoryCloseDeleteModal} className={"btn cst_btn btn-outline-secondary"} id="cancel" type="cancel">Cancel</button>
        </DialogActions>
      </Dialog>

    </>
  )
}

const mapStateToProps = (state) => {
  let sku_detail = state.InventoryReducer.assetStock_searchValues && state.InventoryReducer.assetStock_searchValues.sku_detail ? state.InventoryReducer.assetStock_searchValues.sku_detail : "";
  let name = state.InventoryReducer.assetStock_searchValues && state.InventoryReducer.assetStock_searchValues.name ? state.InventoryReducer.assetStock_searchValues.name : "";
  let status = state.InventoryReducer.assetStock_searchValues && state.InventoryReducer.assetStock_searchValues.status ? state.InventoryReducer.assetStock_searchValues.status : "";
  let location = state.InventoryReducer.assetStock_searchValues && state.InventoryReducer.assetStock_searchValues.location ? state.InventoryReducer.assetStock_searchValues.location : "";
  return { initialValues: { sku_detail, name, status, location } }
}

AssetStock = reduxForm({
  form: 'searchAssetsListForm',
  //validate,
  enableReinitialize: true
})(AssetStock);

AssetStock = connect(mapStateToProps)(AssetStock)

export default AssetStock;