import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { authReducer } from './authReducer/authReducer';
import { adminOrSuperAdminReducer } from './AdminOrSuperAdminReducer/adminOrSuperAdminReducer';
import { InventoryReducer } from './InventoryReducer/InventoryReducers';
import { TimeTrackerModReducer } from './TimeTrackerModReducer/TimeTrackerModReducer';
import { FormAuditModReducer } from './FormAuditModReducer/TeamManagementReducer/teamManagementReducer';
import { LogBookReducer } from './FormAuditModReducer/LogbookReducer/LogbookReducer'
import { BuildingProfileReducer } from './FormAuditModReducer/BuildingProfileReducer/BuildingProfileReducer'
import { InventoryModuleReducer } from './FormAuditModReducer/InventoryReducer/InventoryReducer';
import { TagModuleReducer } from './FormAuditModReducer/TagModuleReducer/TagModuleReducer';
import { projectModuleReducer } from './FormAuditModReducer/ProjectModuleReducer/ProjectModuleReducer';
import { auditsReducer } from './FormAuditModReducer/AuditsReducer/AuditsReducer';
import { PegassurePlaybookReducer } from './PegassurePlaybookReducer/PegassurePlaybookReducer';
import {BRMReducer}  from './BrmReducer/brmReducer'
import {JobAssociateReducer}  from './JobAssoiciateReducer/JobAssociateReducer'
const reducers = combineReducers({
    authReducer: authReducer,
    adminOrSuperAdminReducer: adminOrSuperAdminReducer,
    InventoryReducer: InventoryReducer,
    TimeTrackerModReducer: TimeTrackerModReducer,
    FormAuditModReducer: FormAuditModReducer,
    LogBookReducer: LogBookReducer,
    BuildingProfileReducer: BuildingProfileReducer,
    InventoryModuleReducer: InventoryModuleReducer,
    TagModuleReducer: TagModuleReducer,
    projectModuleReducer: projectModuleReducer,
    auditsReducer: auditsReducer,
    PegassurePlaybookReducer: PegassurePlaybookReducer,
    form: formReducer,
    BRM:BRMReducer,
    JobAssociateReducer:JobAssociateReducer,
    
})

export default reducers;
//adminOrSuperAdminReducer
//companyDropdownList