import React from 'react';
import { routes, address_attributes } from '../../../../utility/constants/constants';
import { connect } from 'react-redux';
import isEmpty from '../../../../utility/IsEmptyValidator';
import { decode, encode } from 'base64-arraybuffer';
import * as actions from '../../../../redux/actions/index';
import NewCompany from '../../../component/companies/newCompanie/newCompany';
import imageCompression from 'browser-image-compression';
import { extractExtention, getUser, resetOrientation } from '../../../../utility/utility';
import getDetailAddress from '../../../../utility/getDetailAddress';
const cloneDeep = require('clone-deep');

class NewCompanieContainer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            profileImageName: '',
            base64: null,
            extension: null,
            // address_attributes: isEmpty(this.props.company) ? address_attributes : this.props.company.address,
            // address_attributes: isEmpty(this.props.user) ? address_attributes : this.props.user.user.address,
            isAddressEmpty: false,
            isZipEmpty: false,
            isStateEmpty:false,
            isCityEmpty: false,
            isCountryEmpty: false,
            account_delete_ids: []
        }
        this.inputOpenFileRef = React.createRef();
        this.choosePhotoClicked = this.choosePhotoClicked.bind(this);
        this.onFileChange = this.onFileChange.bind(this);
        // this.handleInputChange = this.handleInputChange.bind(this);
        // this.handleAddressSelect = this.handleAddressSelect.bind(this);
    }

    onCancelClicked = () => {
        this.props.history.push(routes.COMPANIES);
    }

    async onFileChange(e) {

        let reader = new FileReader();
        let file = e.target.files[0];
        const orientation = await imageCompression.getExifOrientation(file);
        reader.onloadend = () => {

            resetOrientation(reader.result, orientation, this);
            this.setState({
                extension: file.name.split('.').pop().toLowerCase(),
                profileImageName: file.name,
                // base64: reader.result,
            })
        }
        reader.readAsDataURL(file)
        // reader.readAsArrayBuffer(file)
    }

    choosePhotoClicked = () => {
        this.inputOpenFileRef.current.click();
    }

    // handleAddressChange = address => {
    //     let addressAttributes = { ...this.state.address_attributes };
    //     addressAttributes.formatted_address = address;

    //     this.setState({ address_attributes: addressAttributes, isAddressEmpty: false });
    // };

    // async handleAddressSelect(address) {
    //     const addressFields = await getDetailAddress(address);
    //     this.setState({
    //         address_attributes: addressFields,
    //         isAddressEmpty: false
    //     })
    // };

    // handleInputChange = (e) => {
    //     console.log(this.state);
    //     if (e.target.name === 'zip') {
    //         let addressFields = cloneDeep(this.state.address_attributes);
    //         addressFields.zip = e.target.value;
    //         this.setState({
    //             address_attributes: addressFields,
    //             isZipEmpty: false
    //         })
    //     } else if (e.target.name === 'country') {
    //         let addressFields = cloneDeep(this.state.address_attributes);
    //         addressFields.country = e.target.value;
    //         this.setState({
    //             address_attributes: addressFields,
    //             isCountryEmpty: false
    //         })
    //     } else if (e.target.name === 'state') {
    //         let addressFields = cloneDeep(this.state.address_attributes);
    //         addressFields.state = e.target.value;
    //         this.setState({
    //             address_attributes: addressFields,
    //             isStateEmpty: false
    //         })
    //     } else if (e.target.name === 'city') {
    //         let addressFields = cloneDeep(this.state.address_attributes);
    //         addressFields.city = e.target.value;
    //         this.setState({
    //             address_attributes: addressFields,
    //             isCityEmpty: false
    //         })
    //     } else {
    //         this.setState({
    //             about_me: e.target.value,
    //         })
    //     }
    // }

    addNewCompany = (values) => {
        let company = cloneDeep(values);
        // if (this.props.match.params.companyId) {
            // if (this.state.dueDate) {
            //     values.ticket['due_on'] = this.state.dueDate;
            // }
            // values.company.account_id = values.company.account_id.value;

            let base_photo = null;
            let arrayBuffer = null;
            if (this.state.base64 !== null && this.state.base64 !== '') {
                const image = this.state.base64.split(',');
                base_photo = image[1];
                arrayBuffer = decode(base_photo);
                company.company['ext'] = `.${this.state.extension}`
            }
            
        if (company.company['account_ids']) {
            company.company['account_ids'] = company.company['account_ids'].map((a, i) => {
                return a.value
            });
        }
        if(company.company['account_ids'] === null || company.company['account_ids'] === false) {
            delete company.company['account_ids']
        }
                            company.company['mode'] = this.state.mode;
                            company.company["os1"]=Boolean(+company?.company?.os1)
                            if (this.props.match.params.companyId) {
                                var i = 0;
                                var entry1;
                                if(this.props.company.accounts) {
                                    if(company.company.account_ids) {
                                        while (i < this.props.company.accounts.length) {
                                            entry1 = this.props.company.accounts[i];
                                            if (company.company.account_ids.some(function(entry2) { return entry1.id === entry2; })) {
                                                // Found, progress to next
                                                ++i;
                                            } else {
                                                // Not found, store id and splice
                                                this.state.account_delete_ids.push(this.props.company.accounts[i].id)
                                                this.props.company.accounts.splice(i, 1);
                                            }
                                        }
                                    }
                                }
                                if(company.company.account_ids) {
                                    company.company['account_delete_ids'] = this.state.account_delete_ids;
                                } else {
                                    company.company['account_delete_ids'] = this.props.company.accounts.map((a, i) => {
                                        return a.id
                                    });
                                }
                                company.company['id'] = this.props.match.params.companyId;
                                if (this.state.base64 && this.state.base64.length > 0) {
                                    this.props.addNewCompany(company, arrayBuffer,this.state.extension, this.state.idsToDelete, false);
                                } else {
                                    this.props.updateCompany(company, this.state.idsToDelete);
                                }
                                
                            } else {
                    
                                this.props.addNewCompany(company, arrayBuffer, this.state.extension, true);
                    
                            }
                        
    }

    componentDidMount() {
        if (!this.props.accountsList) {
        const user = getUser();
        this.props.getCompanyAccounts(user.mode);
        }

        if (this.props.match.params.companyId) {
            this.props.getEditCompany(this.props.match.params.companyId);
        }
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.company !== prevProps.company) {

            this.setState({
                // address_attributes: this.props.company.address,
                profileImageName: '',
                base64: null,
                extension: null,
            })
        }
    }
    handleAddressTouch = () => {
        if (this.state.address_attributes && this.state.address_attributes.formatted_address) {
            this.setState({ isAddressEmpty: false })
        } else {
            this.setState({ isAddressEmpty: true })
        }
    }

    componentWillUnmount() {
        this.props.resetObjects();
    }

    render() {

        return (
            <NewCompany
                s3Url={isEmpty(this.props.company) ? '' : this.props.company.photo_urls && this.props.company.photo_urls.small}
                onSubmit={this.addNewCompany}
                isEdit={this.props.match.params.companyId ? true : false}
                company={this.props.company}
                isAddressEmpty={this.state.isAddressEmpty}
                isZipEmpty={this.state.isZipEmpty}
                isCityEmpty={this.state.isCityEmpty}
                isStateEmpty={this.state.isStateEmpty}
                isCounutryEmpty={this.state.isCounutryEmpty}                    
                reference={this.inputOpenFileRef}
                choosePhotoClicked={this.choosePhotoClicked}
                base64={this.state.base64}
                onFileChange={this.onFileChange}
                non_associated_company_accounts={this.props.non_associated_company_accounts}
                onCancelClicked={this.onCancelClicked}
                isLoading={this.props.isLoading}
                accountsList={this.props.accountsList}
                // address_attributes={this.state.address_attributes}
                // handleAddressChange={this.handleAddressChange}
                // handleInputChange={this.handleInputChange}
                // handleAddressSelect={this.handleAddressSelect}
                // handleAddressTouch={this.handleAddressTouch}
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        // non_associated_company_accounts: state.adminOrSuperAdminReducer.non_associated_company_accounts,
        company: state.adminOrSuperAdminReducer.company,
        isLoading: state.adminOrSuperAdminReducer.isLoading,
        accountsList: state.adminOrSuperAdminReducer.companyAccounts,
        non_associated_company_accounts: state.adminOrSuperAdminReducer.non_associated_company_accounts
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getNonAssociatedCompanyAccounts: () => dispatch(actions.getNonAssociatedCompanyAccounts()),
        addNewCompany: (company, arrayBuffer, extension, isNewCompany) => dispatch(actions.addNewCompany(company, arrayBuffer, extension, isNewCompany)),
        updateCompany: (company, arrayBuffer, extension) => dispatch(actions.updateCompany(company, arrayBuffer, extension)),
        resetObjects: () => dispatch(actions.resetObjects()),
        getEditCompany: (id) => dispatch(actions.getEditCompany(id)),
        getCompanyAccounts: (mode) => dispatch(actions.getCompanyAccounts(mode)),
        // getCompanyPresignedUrl: (company, extention, arrayBuffer, idsToDelete) => dispatch(actions.getCompanyPresignedUrl(company, extention, arrayBuffer, idsToDelete)),

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewCompanieContainer);