import React, { Component } from 'react';
import { routes } from '../../../utility/constants/constants';
import Rating from '../../component/Ratings/ratings';
import * as actions from '../../../redux/actions/index';
import { connect } from 'react-redux';
import { reset } from 'redux-form';
import { getUser } from '../../../utility/utility';
import storage from '../../../utility/storage';
import { animateScroll as scroll} from 'react-scroll'

class RatingsContainer extends Component {

    newRatingClicked = () => {
        this.props.history.push(routes.NEW_RATING);
    }

    componentDidMount() {
        if (!this.props.ratingList) {
            this.props.getRatings({hide: false});
        }
    scroll.scrollToTop();
    }
    componentDidUpdate(prevProps, prevState) {
        const user = storage.get('user');
        if(user) {
        if(prevProps.user.selected_mode !== user.selected_mode) {
            this.props.ratingPageClicked(0, 10)
        }
    }
    }
    handleClickDeleteModel = (inspectionForm) => {

        this.props.handleClickDeleteModel(inspectionForm);
    }

    deleteRating = (id) => {
        this.props.deleteRating(id);
    }

    handleDeleteClose = () => {
        this.props.handleDeleteClose();
    }

    editRatingClicked = (rating) => {
        this.props.editRatingClicked(rating);
    }

    resetClicked = () => {
        this.props.reset('searchRatingForm');
        this.props.ratingPageClicked(0, '')
        this.props.ratingSearchValidator('')
    }

    onUserPageSizeChange = (e) => {
        this.props.ratingPageClicked(0, e)
    }

    searchRating = (e) => {
        this.props.ratingPageClicked(0, 10)
        this.props.ratingSearchValidator(e.name)
    }

    render() {
        return (
            <Rating
                {...this.props}
                newRatingClicked={this.newRatingClicked}
                resetClicked={this.resetClicked}
                ratingList={this.props.ratingList ? this.props.ratingList : []}
                isLoading={this.props.isLoading}
                editRatingClicked={this.editRatingClicked}
                handleClickDeleteModel={this.props.handleClickDeleteModel}
                handleDeleteClose={this.handleDeleteClose}
                deleteRating={this.deleteRating}
                openDeleteModel={this.props.openDeleteModel}
                deleteObject={this.props.deleteObject}
                onUserPageSizeChange={this.onUserPageSizeChange}
                onSubmit={this.searchRating}
                getRatings={this.props.getRatings}
                changeHideStatus = {this.props.changeHideStatus}

            />
        )
    }
}


const mapStateToProps = (state) => {

    return {
        user: state.authReducer.user,
        ratingList: state.adminOrSuperAdminReducer.searchedRatingList ? state.adminOrSuperAdminReducer.searchedRatingList : state.adminOrSuperAdminReducer.ratingList,
        isLoading: state.adminOrSuperAdminReducer.isLoading,
        deleteObject: state.adminOrSuperAdminReducer.deleteObject,
        openDeleteModel: state.adminOrSuperAdminReducer.openDeleteModel
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        handleDeleteClose: () => dispatch(actions.handleDeleteClose()),
        deleteRating: (id) => dispatch(actions.deleteRating(id)),
        getRatings: (data) => dispatch(actions.getRatings(data)),
        openPopup: () => dispatch(actions.openPopup()),
        reset: (searchRatingForm) => dispatch(reset(searchRatingForm)),  // requires form name
        editRatingClicked: (rating) => dispatch(actions.editRatingClicked(rating)),
        handleClickDeleteModel: (account) => dispatch(actions.handleClickDeleteModel(account)),
        ratingPageClicked: (page, rows) => dispatch(actions.ratingPageClicked(page, rows)),
        ratingSearchValidator: (values) => dispatch(actions.ratingSearchValidator(values)),
        changeHideStatus: (status) => dispatch(actions.changeHideStatus(status))
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(RatingsContainer);