import React, { useEffect } from 'react'
import SelfAuditPage from '../../../../../component/FormAuditModule/AuditsComponent/SelfAudit/SelfAuditPage/selfAuditPage'
import { getAllJob, getCustomerInfos, getManagersList, } from "../../../../../../redux/actions/FormAuditModAction/TeamManagementAction/teamManagementAction";
import { useDispatch, useSelector } from 'react-redux';
import { AddToSelfAudit, EditSelfAudit, getSelfAuditQuestions, getSingleSelfAudit } from '../../../../../../redux/actions/FormAuditModAction/AuditsAction/AuditsAction';
import { DevTools } from '@hookform/devtools';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
function SelfAuditFormContainer() {


  const { location: { pathname } } = useHistory()
  const { id } = useParams();
  const dispatch = useDispatch()
  const isEdit = Boolean(pathname.split("/")[2] !== "view" && id) ? "Edit" : true
  const isView = Boolean(pathname.split("/")[2].includes("view"))
  useEffect(() => {

    //dispatch(getCustomerInfos())
    dispatch(getAllJob())
    dispatch(getCustomerInfos())
    dispatch(getSelfAuditQuestions())

  }, [])

  useEffect(() => {
    if (id) {
      dispatch(getSingleSelfAudit(id))
    }
  }, [id])

  const convertselfAuditQuestion = (auditQuestions, updatedQn) => {
    if (updatedQn !== undefined && (isEdit == "Edit" || isView ) && auditQuestions.length && updatedQn.length) {
      const sections = auditQuestions.map((item, index) => {

        const foundedQuestionArray = updatedQn.find((auditItem) => auditItem.auditorMainTable.id == item.id)

        const auditorSectionsLineItems = item.questions;
        // if we have selected questions in the array we need to change
        if (foundedQuestionArray) {
          return {
            auditor_main_table_id: item.id.toString(),
            active_status: item.active_status,
            sort: item.sort,
            title: item.title,
            id: foundedQuestionArray.id,
            auditor_id: foundedQuestionArray.auditor_id,
            auditorSectionsLineItems: auditorSectionsLineItems.map((q, index) => {
              const find_question = foundedQuestionArray.auditorSectionsLineItems.find((dd) => dd.auditor_main_question_id == q.id)
              if (find_question) {
                
                return {
                  auditor_main_question_id: q.id.toString(),
                  active_status: q.active_status,
                  "sort": q.sort,
                  is_checked: find_question.is_checked ? "true" : "false",
                  notes: null,
                  title: q.question,
                  notes: find_question.notes?find_question.notes:null ,
                  id: find_question.id,
                  auditor_id: foundedQuestionArray.auditor_id,
                }
              } else {
                //we need to return something else here

                return {
                  auditor_main_question_id: q.id.toString(),
                  active_status: q.active_status,
                  sort: q.sort,
                  title: q.question,
                  notes: null,
                  is_checked: "",
                  auditor_id: id.toString(),
                  //auditor_id:item.auditor_id,
                }

              }

            })
          }
        } else {

          return {
            auditor_main_table_id: item.id.toString(),
            active_status: item.active_status,
            sort: item.sort,
            title: item.title,
            auditor_id:id.toString(),
            auditorSectionsLineItems: item.questions.map((q) => (
              {
                auditor_main_question_id: q.id.toString(),
                active_status: q.active_status,
                sort: q.sort,
                is_checked: "",
                notes: null,
                title: q.question,
                notes: null,
                auditor_id:id.toString()
              }
            ))

          }

        }



      })

      return sections;

    } 
    //creating Form
    else {
      const sections = auditQuestions.map((item, index) => {

        const questions = item.questions
        return {
          auditor_main_table_id: item.id.toString(),
          active_status: item.active_status,
          sort: index + 1,
          title: item.title,
          auditorSectionsLineItems: questions.map((q) => (
            {
              auditor_main_question_id: q.id,
              active_status: q.active_status,
              "sort": q.sort,
              is_checked: "",
              notes: null,
              title: q.question,
              notes: null
            }
          ))
        }
      })
      return sections;
    }





  }

  const { self_audit_questions, self_audit_details } = useSelector((state) => state.auditsReducer)



  const defaultForm = {
    customer_id: isView || Boolean(pathname.split("/")[2] !== "view" && id) ? self_audit_details ? self_audit_details.customer_id : "" : "",
    info: isView || Boolean(pathname.split("/")[2] !== "view" && id) ? self_audit_details ? self_audit_details.info : "" : "",
    account_id: isView || Boolean(pathname.split("/")[2] !== "view" && id) ? self_audit_details ? self_audit_details.account_id : "" : "",
    last_step: null,
    active_status: "active",
    location: isView || Boolean(pathname.split("/")[2] !== "view" && id) ? self_audit_details ? self_audit_details.location : "" : "",
    auditorSections: self_audit_questions.length > 0 ? convertselfAuditQuestion(self_audit_questions, self_audit_details?.auditorSections) : []
  }



  const ConvertToBody = (data) => {
    if (isEdit == "Edit") {
      let { auditorSections, ...remainData } = data
      let newData = {
        ...remainData,
        id: self_audit_details.id,
        user_id: self_audit_details.user_id
      }
      delete newData.active_status;
      delete newData.last_step;
      const interactedQuestions = []
      data.auditorSections.forEach((item) => {
        const { auditorSectionsLineItems, ...remainData } = item
        const foundedInteractive = auditorSectionsLineItems.filter((aud) => aud.is_checked !== "")
        if (foundedInteractive.length) {
          interactedQuestions.push({
            ...remainData,
            auditorSectionsLineItems: foundedInteractive
          })
        }
      })
      const _auditorSections = interactedQuestions.map((item, index) => {
        delete item.auditor_id
        delete item.active_status;
        const { title, ...remainItem } = item

        return {
          ...remainItem,
          auditorSectionsLineItems: item.auditorSectionsLineItems.map((aud) => {

            delete aud.title;
            delete aud.active_status;
            return { ...aud }
          })
        }
      })
      
      return { data: { ...newData, auditorSections: _auditorSections } }

    } else {
      const interactedQuestions = []
      data.auditorSections.forEach((item) => {
        const { auditorSectionsLineItems, ...remainData } = item
        const foundedInteractive = auditorSectionsLineItems.filter((aud) => aud.is_checked !== "")
        if (foundedInteractive.length) {
          interactedQuestions.push({
            ...remainData,
            auditorSectionsLineItems: foundedInteractive
          })
        }
      })

      const auditorSections = interactedQuestions.map((item, index) => {
        const { title, ...remainItem } = item
        return {
          ...remainItem,
          auditorSectionsLineItems: item.auditorSectionsLineItems.map((aud) => {
            const { title, ...remainAudit } = aud
            return { ...remainAudit }
          })
        }
      })
      
      return { data: { ...data, auditorSections } }
    }

  }


  const onSubmit = (data) => {
    if (isEdit == "Edit") {
      const body = ConvertToBody(data)
      dispatch(EditSelfAudit(body))
    } else {
      const body = ConvertToBody(data)
      dispatch(AddToSelfAudit(body))
    }



  }



  return (

    <SelfAuditPage formSubmittedCallback={onSubmit} elements={defaultForm} isView={isView} isEdit={true} />

  )
}

export default SelfAuditFormContainer
