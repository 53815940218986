import React, { useState } from "react";
import { routes } from "../../../utility/constants/constants";

const FeedbackComponent = (props) => {
    return (
        <main class="app-content qr_app_content app_screen_outer schedule-screen ups_schedule">
            <div class="client_login list_view">
                <div class="login_form_content Schedule_con">
                    <div className="feedback_inner">
                   
                        <div className="list_items" style={{cursor: 'pointer'}} onClick={() => props.history.push(`/pegassure-thankyou/${props.match.params.areaId}/excellent`)}>
                            <div class="media align-items-center">
                                <img src="/images/icons/happy.png" style={{ height: '70px', width: '70px', borderRadius: '50px', float: 'left' }} />
                                <div class="media-body">
                                    <h5 class="h5_title m-0 pl-3">Excellent</h5>
                                </div>
                            </div>
                        </div>
                        <div className="list_items" style={{cursor: 'pointer'}} onClick={() => {props.history.push(`/pegassure-comments/average/${props.match.params.areaId}`);props.openDeficiencyClicked()}}>
                            <div class="media align-items-center">
                                <img src="/images/icons/confused.png" style={{ height: '70px', width: '70px', borderRadius: '50px', float: 'left' }} />
                                <div class="media-body">
                                    <h5 class="h5_title m-0 pl-3">Average</h5>
                                </div>
                            </div>
                        </div>
                        <div className="list_items" style={{cursor: 'pointer'}} onClick={() => {props.history.push(`/pegassure-comments/needservice/${props.match.params.areaId}`); props.openDeficiencyClicked()}}>
                            <div class="media align-items-center">
                                <img src="/images/icons/sad.png" style={{ height: '70px', width: '70px', borderRadius: '50px', float: 'left' }} />
                                <div class="media-body">
                                    <h5 class="h5_title m-0 pl-3">Need Service</h5>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </main>
    )
}
export default FeedbackComponent;