import React, { useEffect, useState, useRef } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { change, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { Input } from '../../UI/InputElement/InputElement'
import SignatureCanvas from 'react-signature-canvas'
import errorHandler from '../../../../utility/errorHandler/errorHandler';
import storage from '../../../../utility/storage';
var attestationObject = [];

const FeedbackStatusForm = (props) => {

    let { isLoading, assignedTaskTicketInfo, onNext, handlePreviewSubTicket } = props;


    assignedTaskTicketInfo = assignedTaskTicketInfo.length && assignedTaskTicketInfo.map((assignedDetails) => ({ ...assignedDetails, tickets: assignedDetails.tickets.length ? assignedDetails.tickets.filter((ticket) => ticket.status !== "resolved") : [] }))

    /* Decide the Current Form Still Pose */
    const [inHabitancyRecord, setInHabitancyRecord] = useState('beta');

    const assignedTicketStatus = [
        {
            value: 'open',
            label: 'Open'
        },
        {
            value: 'resolved',
            label: 'Resolved'
        }
    ];

    const noOptionsMessage = (a, b) => {
        return 'No options';
    }

    const handleFeedbackStatusForm = () => {
        if (assignedTaskTicketInfo?.length > 0) {
            let pendingTickets = assignedTaskTicketInfo.reduce((acc, curr) => curr.tickets.length + acc, 0)

            const validation = assignedTaskTicketInfo.filter((item, index) => {
                const assignedTicketKey = `assignedTicket${index}`;
                if (assignedTaskTicketInfo.length && Boolean(pendingTickets) && !props.clkOutAssessment.clkFeedback?.tickets) {
                    errorHandler('Please fill all fields');
                    return false
                }
                if (item.tickets.length && item.tickets.length !== props.clkOutAssessment.clkFeedback?.tickets[assignedTicketKey]?.length) {
                    return item
                }
            })
            if (!validation?.length && props.clkOutAssessment?.clkFeedback?.feedback?.length > 0) {
                let clkFeedbackForm = {};
                if (props.clkOutAssessment.clkFeedback.tickets !== undefined) {
                    const tickets = [props.clkOutAssessment.clkFeedback.tickets]
                    const transformedTickets = Object.entries(tickets[0]).map(([key, value]) => ({ [key]: value }));

                    let assignedTicket = transformedTickets.map((item, index) => {
                        const assignedTicketKey = `assignedTicket${index}`;
                        const result = item[assignedTicketKey].map((item) => {
                            return item.tag
                        })
                        return [...result]
                    })
                    const mergedTickets = [].concat(...assignedTicket);

                    clkFeedbackForm.tickets = mergedTickets;

                }
                clkFeedbackForm.feedback = props.clkOutAssessment?.clkFeedback?.feedback;
                localStorage.setItem('clkFeedbackForm', JSON.stringify(clkFeedbackForm));
                storage.set('inHabitancyRecord', 'beta');
                onNext(inHabitancyRecord);
            } else {
                errorHandler('Please fill all fields');
                return false;
            }

        } else {

            if (props.clkOutAssessment?.clkFeedback?.feedback?.length > 0) {
                let clkFeedbackForm = {};
                clkFeedbackForm.feedback = props.clkOutAssessment?.clkFeedback?.feedback;
                localStorage.setItem('clkFeedbackForm', JSON.stringify(clkFeedbackForm));

                storage.set('inHabitancyRecord', 'beta');
                onNext(inHabitancyRecord);
            } else {
                errorHandler('Please fill the feedback field');
                return false;
            }

        }
    }

    return (
        <>
            <div className="addQuote_form">
                <form className="form_content">
                    <div className="row">

                        <div className="col-sm-12 mx-auto">
                            <label className="form_title font-weight-bold mb-4">
                                {assignedTaskTicketInfo?.length > 0 ? 'Please update the Tickets status and your feedback' : 'Please add your Feedback'}
                            </label>

                            <ul className="list-group shadow rounded">

                                <li className="list-group-item">

                                    {assignedTaskTicketInfo?.length > 0 ? assignedTaskTicketInfo?.map((item, i) => (
                                        <>
                                            <h6 className="mt-3 font-weight-bold mb-2 text-dark ml-3">Job - {item.name}</h6>
                                            <div className="media align-items-lg-center flex-column  p-3">
                                                <div className='d-flex flex-direction-row align-items-center width_100'>
                                                    <div className="media-body">
                                                        <h6 className="mt-0  mb-2 text-dark"> Assigned Tickets <sup className="cst_sup_txt text_danger">*</sup> </h6>
                                                    </div>
                                                    <div className="media-body">
                                                        {item?.tickets?.length > 0 ? item?.tickets?.map((assignedTicket, index) => (
                                                            <>
                                                                <div key={assignedTicket.id} className='justify-content-space-between align-items-center width_100'>
                                                                    <a style={{ textDecoration: 'underline', cursor: 'pointer', marginTop: '-15px' }} href="javascript:void(0)" className="font-weight-bold text-color-info" onClick={() => {
                                                                        handlePreviewSubTicket({ subTicketId: assignedTicket.id })
                                                                    }}> {assignedTicket.id} </a>
                                                                    <Input
                                                                        className='mb-3 width_80'
                                                                        type="inputSelect"
                                                                        dataTest="select_leave_type"
                                                                        name={`clkFeedback[tickets][assignedTicket${i}][${index}]`}
                                                                        onChange={(ticket) => {
                                                                            return ticket.tag = { id: assignedTicket.id, status: ticket.value };
                                                                        }}
                                                                        options={assignedTicketStatus}
                                                                        noOptionsMessage={noOptionsMessage}
                                                                        placeholder="Please select"
                                                                    />
                                                                </div>
                                                            </>
                                                        )) : (
                                                            <h5 className="ml-lg-0 font-italic mb-0 text-color-info" style={{ fontSize: '15px' }}> This shift has no tickets associated with it! </h5>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )) : null}
                                </li>

                                <li className="list-group-item">
                                    <div className="media align-items-lg-center flex-column flex-lg-row p-3">
                                        <div className='d-flex flex-direction-row align-items-center width_100'>
                                            <div className="media-body">
                                                <h6 className="mt-0 font-weight-bold mb-2 text-dark"> Add Feedback <sup className="cst_sup_txt text_danger">*</sup> </h6>
                                            </div>
                                            <div className="media-body">
                                                <div className="form-group">
                                                    <Input type="textarea" name="clkFeedback[feedback]" className="form-control textarea_modifier" rows="3" placeholder="Place the feedback here" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>

                            </ul>
                        </div>

                    </div>
                </form>
            </div>

            <div className="modal-footer form-group btn_block p-0" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <button data-test="cancel_button" type="button" className="btn cst_btn btn-outline-secondary cancel_btnnew w-25" style={{ display: 'none' }}> Preview </button>
                <button data-test="submit_button" type="button" onClick={handleFeedbackStatusForm} className={isLoading ? "btn cst_btn btn_danger btn-wait w-25" : "btn cst_btn btn_danger w-25"} > {isLoading ? null : 'Next'} </button>
            </div>
        </>
    );
};


const AttestationForm = (props) => {

    let { isLoading, attestationQQV, handleAttestation_openReasonModal, capturesAttestation_object, onNext, onPrevious } = props;

    /* Decide the Current Form Still Pose */
    const [inHabitancyRecord, setInHabitancyRecord] = useState('gama');
    const [language, setLanguage] = useState(props.language);

    const handleAttestationForm = () => {
        if (props.clkOutAssessment?.clkAttestation?.quest?.filter(e => e !== null).length == 4) {
            let filteredAttestationObject = {};
            // Eliminate repeated objects with the same quiz_id, keeping the last occurrence
            filteredAttestationObject['attestation'] = attestationObject.reduce((accumulator, currentObject) => {
                const existingObjectIndex = accumulator.findIndex(obj => obj.quiz_id === currentObject.quiz_id);

                if (existingObjectIndex !== -1) {
                    accumulator[existingObjectIndex] = currentObject;
                } else {
                    accumulator.push(currentObject);
                }

                return accumulator;
            }, []);
            localStorage.setItem('clkAttestationForm', JSON.stringify(filteredAttestationObject));

            storage.set('inHabitancyRecord', 'gama');
            onNext(inHabitancyRecord);
        } else {
            errorHandler('Please complete all the Attestations');
            return false;
        }
    }

    const handlePreviousAction = () => {
        storage.set('inHabitancyRecord', 'alpha')
        onPrevious('alpha');
    };

    const userModeClicked = (event) => {
        const value = event.target.checked
        setLanguage(value)
        let lang;
        if (value) {
            lang = 'es'
        } else {
            lang = 'en'
        }
        props.languageHandler(lang)
        props.dispatch(change("ClockOutFeedbackComponentForm", "clkAttestation", {}))
    }

    return (
        <>
            <div className="addQuote_form">
                <form className="form_content">
                    <div className="row">
                        <div className="col-sm-12">
                            {/* <!-- toggle btn --> */}
                            <div className="theme_switch_btn" style={{ float: 'right', marginTop: '20px' }}>
                                <span className="switch_title">English</span>
                                <label >
                                    <input type="checkbox" checked={language} onChange={(event) => userModeClicked(event)} />
                                    <span></span>
                                </label>
                                <span className="ext_title">Spanish</span>

                            </div>
                            {/* <!-- toggle btn End --> */}
                        </div>
                    </div>
                    <div className="row">

                        <div className="col-sm-12 mx-auto">
                            <label className="form_title font-weight-bold mb-4" >
                                Attestation
                            </label>
                            {attestationQQV && attestationQQV.map((qqv, index) => (
                                <>
                                    <div key={qqv.id}>
                                        <ul className="list-group shadow rounded">
                                            <li className="list-group-item">
                                                <div className="media align-items-lg-center flex-column flex-lg-row p-3">
                                                    <div className='d-flex flex-direction-row align-items-center width_100'>
                                                        <div className="media-body" style={{ minWidth: '75%' }}>
                                                            <h6 className="mt-0 font-weight-bold mb-2 text-dark"> {`${index + 1}) ${qqv.about}`} </h6>
                                                        </div>
                                                        <div className="d-flex media-body monthly_tab" style={{ minWidth: '25%', justifyContent: 'flex-end' }}>
                                                            <div className="form-group box_input">
                                                                <Input
                                                                    type="radio"
                                                                    className="custom-control-input"
                                                                    labelClass="custom-control-label"
                                                                    forLable={`customTrue${index}`}
                                                                    id={`customTrue${index}`}
                                                                    value={"true"}
                                                                    label={qqv.language === "en" ? "Yes" : "Sí"}
                                                                    name={`clkAttestation[quest][${index}]`}
                                                                    onClick={(attest) => {
                                                                        attestationObject.push({ quiz_id: qqv.id, is_yes: "true" });
                                                                    }}
                                                                />
                                                                <Input
                                                                    type="radio"
                                                                    className="custom-control-input"
                                                                    labelClass="custom-control-label"
                                                                    forLable={`customFalse${index}`}
                                                                    id={`customFalse${index}`}
                                                                    value={"false"}
                                                                    label={"No"}
                                                                    name={`clkAttestation[quest][${index}]`}
                                                                    onClick={(attest) => {
                                                                        handleAttestation_openReasonModal(qqv.id, index)
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </>
                            ))}

                        </div>

                    </div>
                </form>
            </div>

            <div className="d-flex justify-content-space-between modal-footer form-group btn_block p-0">
                <button data-test="cancel_button" type="button" onClick={handlePreviousAction} className="btn cst_btn btn-outline-secondary cancel_btnnew w-25"> Previous </button>
                <button data-test="submit_button" type="button" onClick={handleAttestationForm} className={isLoading ? "btn cst_btn btn_danger btn-wait w-25" : "btn cst_btn btn_danger w-25"} > {isLoading ? null : 'Next'} </button>
            </div>
        </>
    );
};

const SignatureForm = (props) => {

    let clkAttestationForm = JSON.parse(localStorage.getItem('clkAttestationForm'));
    let { isLoading, attestationQQV, onPrevious } = props;
    let signatureShade = useRef({});

    let clearSign = () => {
        signatureShade.clear();
    };

    const handlePreviousAction = () => {
        storage.set('inHabitancyRecord', 'beta')
        onPrevious('beta');
    };

    const handleSignatureForm = () => {
        if (signatureShade.isEmpty() === false) {
            let sigImageFile = signatureShade.getTrimmedCanvas().toDataURL("image/png")
            let onSubmitLogValues = sigImageFile
            props.onSignatureAdded(onSubmitLogValues);
        } else if (signatureShade.isEmpty() === true) {
            errorHandler('Please add your Signature')
        }
    };

    return (
        <>
            <div className="addQuote_form">
                <form className="form_content">
                    <div className="row">

                        <div className="col-sm-12 mx-auto">
                            <label className="form_title font-weight-bold mb-4">
                                Please Sign Below PDF
                            </label>

                            {attestationQQV && attestationQQV.map((qqv, index) => {
                                let filterAnswer = clkAttestationForm?.attestation?.find(attest => qqv.id === attest.quiz_id);
                                let filterQuestion = attestationQQV.filter(attest => attest.id == filterAnswer.quiz_id);
                                return (
                                    <>
                                        <div key={qqv.id}>
                                            <ul className="list-group shadow rounded">
                                                <li className="list-group-item">
                                                    <div className="media align-items-lg-center flex-column flex-lg-row p-3">
                                                        <div className='d-flex flex-direction-row align-items-center width_100'>
                                                            <div className="media-body" style={{ minWidth: '90%' }}>
                                                                <h6 className="mt-0 font-weight-bold mb-2 text-muted"> {`${index + 1}) ${filterQuestion[0]?.about}`} </h6>
                                                            </div>
                                                            <div className="d-flex media-body monthly_tab" style={{ minWidth: '10%', justifyContent: 'flex-end' }}>
                                                                <div className="form-group box_input">
                                                                    <h6 className="mt-0 font-weight-bold mb-2 text-dark"> {filterAnswer.is_yes == 'true' ? qqv.language === "en" ? 'Yes' : "Sí" : 'No'} </h6>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </>
                                );
                            })}

                        </div>

                        <div className="col-sm-12 mx-auto mt-4">
                            <label className="form_title font-weight-bold mb-4"> Add Your Signature </label>
                            <div className="form-group">
                                <SignatureCanvas
                                    ref={(ref) => { signatureShade = ref; }}
                                    penColor='black' backgroundColor='white'
                                    canvasProps={{ width: 500, height: 150, className: 'form-control textarea_modifier' }}
                                />
                                <div style={{ display: 'flex', justifyContent: 'flex-end' }}><button type="button" onClick={clearSign} className="btn mr-2 btn-outline-secondary">Clear</button></div>
                            </div>
                        </div>

                    </div>
                </form>
            </div>

            <div className="d-flex justify-content-space-between modal-footer form-group btn_block p-0">
                <button data-test="cancel_button" type="button" onClick={handlePreviousAction} className="btn cst_btn btn-outline-secondary cancel_btnnew w-25"> Previous </button>
                <button data-test="submit_button" type="button" onClick={handleSignatureForm} className={isLoading ? "btn cst_btn btn_danger btn-wait w-25" : "btn cst_btn btn_danger w-25"} > {isLoading ? null : 'Submit'} </button>
            </div>
        </>
    );

}

let ClockOutFeedbackComponent = (props) => {

    let { isLoading, openAttestation_ReasonModal, handleAttestation_closeReasonModal, capturesAttestation_object } = props;

    /* Decide the Current Form Still Pose */
    const [inHabitancyRecord, setInHabitancyRecord] = useState(storage.get('inHabitancyRecord'));

    const handleNextAction = (data) => {
        setInHabitancyRecord(data);
    };

    const handlePreviousAction = (data) => {
        setInHabitancyRecord(data);
    };

    return (
        <>

            <main className="app-content wraper_content user_newQuote add_account_modal add_New_Accont add_newAccount">
                <div className="inspection_content_sec appcontent_Inner">
                    <div className="container-fluids">
                        <div className="quote_form ticket_form" style={{ maxWidth: '800px' }}>

                            <div className="row">
                                <div className="col-sm-12 text-center"> <h2 className="md_title" > Step {inHabitancyRecord == 'alpha' ? '1' : inHabitancyRecord == 'beta' ? '2' : inHabitancyRecord == 'gama' ? '3' : 0} of 3 </h2></div>
                            </div>

                            {inHabitancyRecord == 'alpha' && <FeedbackStatusForm {...props} onNext={handleNextAction} />}
                            {inHabitancyRecord == 'beta' && <AttestationForm {...props} onNext={handleNextAction} onPrevious={handlePreviousAction} />}
                            {inHabitancyRecord == 'gama' && <SignatureForm {...props} onPrevious={handlePreviousAction} />}

                        </div>
                    </div>
                </div>
            </main>

            <Dialog
                open={openAttestation_ReasonModal}
                // onClose={handleAttestation_closeReasonModal}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">
                    <h3 className='mt-2 font-weight-bold mb-2 text-dark' data-test="modal_delete_title"> Please Explain </h3>
                </DialogTitle>

                <DialogContent dividers>
                    <Input type="textarea" name={`clkAttestation[reason][${capturesAttestation_object?.index}]`} className="form-control textarea_modifier" rows="2" placeholder="Place the feedback here" />
                </DialogContent>

                <DialogActions>
                    <button data-test="modal_cancel" onClick={(e) => {
                        handleAttestation_closeReasonModal();
                        document.getElementById(`customTrue${capturesAttestation_object?.index}`).click();
                    }} className="btn cst_btn btn-outline-secondary" id="cancel" type="cancel">Cancel</button>
                    <button data-test="modal_delete" onClick={(e) => {
                    if(!props?.clkOutAssessment?.clkAttestation?.reason?.[capturesAttestation_object.index]){
                        errorHandler('Please enter the feedback')
                        return false
                    }
                        handleAttestation_closeReasonModal();
                        attestationObject.push({
                            quiz_id: capturesAttestation_object?.id,
                            is_yes: "false",
                            other_reason: props?.clkOutAssessment?.clkAttestation?.reason?.[capturesAttestation_object.index]
                        });
                        delete props?.clkOutAssessment?.clkAttestation?.reason;
                    }} disabled={isLoading} className="btn cst_btn btn_danger mr-2" id="delete" type="delete"> Submit </button>
                </DialogActions>
            </Dialog>

        </>
    );
};

const mapStateToProps = (state, props) => {

    let clkFeedback = {
        feedback: null,
    };
    let clkAttestation = {};

    return {
        initialValues: { clkFeedback, clkAttestation },
        clkOutAssessment: state.form?.ClockOutFeedbackComponentForm?.values || null
    }

};

ClockOutFeedbackComponent = reduxForm({
    form: 'ClockOutFeedbackComponentForm',
    // validate,
    enableReinitialize: true,
})(ClockOutFeedbackComponent);

ClockOutFeedbackComponent = connect(mapStateToProps)(ClockOutFeedbackComponent);

export default ClockOutFeedbackComponent;