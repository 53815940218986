import React from "react";
import { Table } from "antd";
import { useSelector } from "react-redux";
import { makeFirstLetterCapital } from "../../../../utility/utility";

function UnavailableItem() {
  const unavailableItems = useSelector(
    (state) => state.InventoryReducer.unavailableItems
  );

  const unavailableData = unavailableItems.map((item, index) => {
    return {
      key: index,
      name: makeFirstLetterCapital(item.name),
      quantity: item.requested,
      available: item.available,
    };
  });
  const unavailableColumn = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Requested Quantity",
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: "Available Quantity",
      dataIndex: "available",
      key: "available",
    },
  ];

  return (
    <>
      <main className="app-content wraper_content edit_inspection">
      <div class="container">
        <div className="row justify-content-center">
          <div className="col-md-8 col-xl-8 col-sm-12">
            <div className="form-group mt-5">
              <Table
                columns={unavailableColumn}
                dataSource={unavailableData}
                pagination={false}
              />
            </div>
          </div>
        </div>
        </div>
      </main>
    </>
  );
}

export default UnavailableItem;
