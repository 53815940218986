import React from 'react';
import logo from './logo.svg';
import UserApp from './UserApp';
import { Router } from 'react-router-dom';
import './styles/stylesheets/style.css';
import history from './router/history';
import './App.css';
// import '../src/styles/stylesheets';
// import "./styles/stylesheets/"
// src\styles\stylesheets
import ReactGA from "react-ga4";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// import { GOOGLE_MEASUREMENT_ID } from './utility/constants/constants';

ReactGA.initialize("G-ZWKLZKES6D");
// ReactGA.send({ hitType: "pageview", page: window.location.pathname });


const firebaseConfig = {
  apiKey: "AIzaSyBjtZr3t8rrgRgM8DPC4vlDf9uQXFujT-E",
  authDomain: "pegdesk-cbbfb.firebaseapp.com",
  projectId: "pegdesk-cbbfb",
  storageBucket: "pegdesk-cbbfb.appspot.com",
  messagingSenderId: "65722964503",
  appId: "1:65722964503:web:61b8e61bcd80f064cf5a52",
  measurementId: "G-QR14ENXBT5"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
class App extends React.Component {
  render() {
    return (
      <Router history={history}>
        <UserApp />
      </Router>
    );
  }

}
export default App;
