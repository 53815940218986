import React from "react";
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { Input } from '../../UI/InputElement/InputElement';
let Search = (props) => {
  const { onAreaChange} = props;
  const noOptionsMessage = (a, b) => {
    return 'Area not found';
  }
  return(
        <form className="form-inline mobile-view-history">

            <label className="form_title">Area Name</label>
            {/* <Input name="quote[account_id]" noOptionsMessage={noOptionsMessage} options={accounts} /> */}
            <Input name="area_name" type="inputGroupSelect" className="input-modifier" onChange={onAreaChange} noOptionsMessage={noOptionsMessage} options={props.areaNames.map(name => {return {value: name, label: name}})} placeholder="AREA-NAME" />

        </form>
  )
};
Search = reduxForm({
  form: 'historySearch'
})(Search);

Search = connect(null)(Search);
export default Search;