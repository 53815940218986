import React from 'react';
import { connect } from 'react-redux';
import { makeFirstLetterCapital } from '../../../utility/utility';

const UserProfileComponent = (props) => {
    debugger
    return (
        <div>
            <main className="app-content wraper_content inspection_wraper">
                <div className="container" style={{ marginTop: "100px" }}>
                    <div className="FLUP">
                        <div class="padding">
                            <div class="row container d-flex justify-content-center">
                                <div class="col-xl-11 col-md-12">
                                    <div class="card overflow">
                                        <div class="row">
                                            <div class="col-sm-4 user-profile">
                                                <div class="card-block text-center text-white">
                                                    <div class="image_container"><img class="img-flow rm_profile" src={props.user.photo_urls.small ? props.user.photo_urls.original : '/customImages/user.png'} alt="Generic placeholder image" /></div>
                                                    <h1 class="f-w-600">{makeFirstLetterCapital(props.user.first_name + '  ' + props.user.last_name)}</h1><i class=" mdi mdi-square-edit-outline feather icon-edit m-t-10 f-16">{makeFirstLetterCapital(props.user.role)}</i>
                                                </div>
                                            </div>
                                            <div class="col-sm-8">
                                                <div class="card-block">
                                                    <h1 class="m-b-20 p-b-5 b-b-default u_title">USER INFO</h1>
                                                    <div class="row">
                                                        <div class="col-sm-6"><h1 class="m-b-10 key_text">FIRST NAME</h1><h1 class="value_text">{makeFirstLetterCapital(props.user.first_name)}</h1></div>
                                                        <div class="col-sm-6"><h1 class="m-b-10 key_text">LAST NAME</h1><h1 class="value_text">{makeFirstLetterCapital(props.user.last_name)}</h1></div>
                                                    </div>
                                                    <h6 class="m-b-20 m-t-40 p-b-5 b-b-default"></h6>
                                                    <div class="row">
                                                        <div class="col-sm-6"><h1 class="m-b-10 key_text">POSITION</h1><h1 class="value_text">{props.user.position && props.user.position.name ? makeFirstLetterCapital(props.user.position.name) : ''}</h1></div>
                                                        <div class="col-sm-6"><h1 class="m-b-10 key_text">MODE</h1><h1 class="value_text">{makeFirstLetterCapital(props.user.mode)}</h1></div>
                                                    </div>
                                                    <h6 class="m-b-20 m-t-40 p-b-5 b-b-default"></h6>
                                                    <div class="row">
                                                        <div class="col-sm-8"><h1 class="m-b-10 key_text">EMAIL</h1><h1 class="value_text">{makeFirstLetterCapital(props.user.email)}</h1></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.authReducer.user
    }
}

export default connect(mapStateToProps)(UserProfileComponent);