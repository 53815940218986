import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../../redux/actions/index';
import ViewInspection from '../../../component/Inspections/viewInspection/viewInspection';
import isEmpty from '../../../../utility/IsEmptyValidator';
import { animateScroll as scroll} from 'react-scroll'
import cloneDeep from 'clone-deep';

class ViewInspectionContainer extends Component {

    componentDidMount = () => {
            this.props.getInspection(this.props.match.params.inspectionId);
        // if ((!this.props.inspectionIndex) || isEmpty(this.props.inspectionIndex)) {
        //     this.props.getInspectionIndex();
        // }
        scroll.scrollToTop();

    }

    nextClicked = () => {
        this.props.inspectionIndex && this.props.inspectionIndex.map((ins, i)=> {
            if(this.props.match.params.inspectionId === ins) {
            this.props.goToPreviewInspection({id: this.props.inspectionIndex[i+1]});
            this.props.getInspection(this.props.inspectionIndex[i+1]);
            }
        })
    }

    previousClicked = () => {
        this.props.inspectionIndex && this.props.inspectionIndex.map((ins, i)=> {
            if(this.props.match.params.inspectionId === ins) {
            this.props.goToPreviewInspection({id: this.props.inspectionIndex[i-1]});
            this.props.getInspection(this.props.inspectionIndex[i-1]);
            }
        })
    }

    downloadPdfClicked = () => {
        this.props.getInspectionPdf(this.props.match.params.inspectionId);
    }

    componentWillUnmount = () => {
        this.props.resetObjects();
    }

    markAsCompletedClicked = () => {
        let values = {};
        values['inspection'] = cloneDeep(this.props.inspection);
        values['inspection']['inspection_id'] = this.props.inspection.id;
        values['inspection']['active_status'] = 'completed';
       this.props.performInspection(values, [])
    }
    completeModalCloseClicked = () => {
        this.props.handleCompleteApproveClose()
    }
    render() {

        return (
            <ViewInspection
                {...this.props}
                user={this.props.user}
                inspection={this.props.inspection}
                previousClicked={this.previousClicked}
                nextClicked={this.nextClicked}
                downloadPdfClicked={this.downloadPdfClicked}
                markAsCompletedClicked={this.markAsCompletedClicked}
                markAsCompletedModalClicked={this.props.markAsCompletedModalClicked}
                completeModalCloseClicked={this.completeModalCloseClicked}
            />
        )
    }
}


const mapStateToProps = (state) => {
    return {
        user: state.authReducer.user,
        inspection: state.adminOrSuperAdminReducer.inspection,
        isLoading: state.adminOrSuperAdminReducer.isLoading,
        inspectionIndex: state.adminOrSuperAdminReducer.inspectionIndex,
        totalInspections: state.adminOrSuperAdminReducer.totalInspections,
        completeApporveModal: state.adminOrSuperAdminReducer.completeApporveModal,
        handleClose: state.adminOrSuperAdminReducer.handleClose

    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getInspection: (id) => dispatch(actions.getInspection(id)),
        resetObjects: () => dispatch(actions.resetObjects()),
        getInspectionIndex: () => dispatch(actions.getInspectionIndex()),
        goToPreviewInspection: (inspection) => dispatch(actions.goToPreviewInspection(inspection)),
        getInspectionPdf: (id) => dispatch(actions.getInspectionPdf(id)),
        performInspection: (values) => dispatch(actions.performInspection(values, [])),
        markAsCompletedModalClicked: () => dispatch(actions.markAsCompletedModalClicked()),
        handleCompleteApproveClose: () => dispatch(actions.handleCompleteApproveClose()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewInspectionContainer);