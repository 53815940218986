import React, { Fragment, useEffect, useState } from "react";
import { Input } from "../../../../UI/InputElement/reactFormHookInputField";
import { useSelector } from "react-redux";
import { useWatch } from "react-hook-form";

function CheckListTaskForm(props) {
  const { jobList, managersList } = useSelector((state) => state.FormAuditModReducer)

  //FOR STRUCTURING 
  const getJobList = (job) => {
    if (job && job.length > 0) {
      return job && job.map((data) => {
        return {
          value: data.id,
          label: data.name,
        }
      })
    } else {
      return []
    }
  };

  const watchFieldCompleted = useWatch({
    control: props.control,
    name: `items.${props.index}.team_checklist_completed`
  })

  const watchFieldTotalCompleted = useWatch({
    control: props.control,
    name: `items.${props.index}.cleaning_workers`
  })



  const getPercentage = () => {

    const V_cleaningWorkers = props.getValues(`items.${props.index}.cleaning_workers`)
    const V_complted = props.getValues(`items.${props.index}.team_checklist_completed`)

    if (V_cleaningWorkers && V_complted) {
      const percentage = ((+V_complted / +V_cleaningWorkers) * 100).toFixed(0)
      props.setValue(`items.${props.index}.completed`, percentage + "")
      return
    }
    else return ""
  }

  const getManagersList = (manager) => {
    if (manager && manager.length > 0) {
      return manager && manager.map((data) => {
        return {
          value: data.id,
          label: data.first_name + ' ' + data.last_name,
        }
      })
    } else {
      return []
    }
  };


  const [listOfJob, setJobList] = useState(getJobList(jobList))
  const [listOfManager, setManagerList] = useState(getManagersList(managersList))

  useEffect(() => {
    setManagerList(getManagersList(managersList))
  }, [managersList])

  useEffect(() => {
    setJobList(getJobList(jobList))
  }, [jobList])


  return (
    <Fragment key={props.index}>
      <div class="tm_form_wrap">
        <div class="tm_form_innner">
          <div class="form">
            <div class="row">
              <div class="col-md-6 col-sm-12">
                <div class="form-group">

                  <Input
                    type="inputSelect"
                    labelClasses="form-label"
                    control={props.control}
                    controller={props.controller}
                    placeholder="Please Select"
                    selectLabel="Supervisor"
                    selectName={`items.${props.index}.supervisor_id`}
                    id={`items.${props.index}.supervisor_id`}
                    selectOptions={listOfManager}
                    required={props.register(`items.${props.index}.supervisor_id`, {
                      required: "This field is required"
                    })}
                    validationErrorMessage={props.errors?.items?.[props?.index]?.supervisor_id?.message}
                    selectClasses="form-select"
                    disabled={props?.isView}
                  />

                  {/* <label for="disabledSelect" class="form-label">
                    Supervisor
                  </label>
                  <select id="disabledSelect" class="form-select">
                    <option>Please Select</option>
                    <option>1</option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                  </select> */}
                  {/* <div class="dropdown_caret">
                    <img
                      src="/images/icons/dropdown_caret.svg"
                      alt="dropdown_caret"
                    />
                  </div> */}
                </div>
              </div>
              <div class="col-md-6 col-sm-12">
                <div class="form-group">

                  <Input
                    type="inputSelect"
                    labelClasses="form-label"
                    control={props.control}
                    controller={props.controller}
                    placeholder="Please Select"
                    selectLabel="Job"
                    selectName={`items.${props.index}.account_id`}
                    id={`items.${props.index}.account_id`}
                    selectOptions={listOfJob}
                    required={props.register(`items.${props.index}.account_id`, {
                      required: "This field is required"
                    })}
                    validationErrorMessage={props.errors?.items?.[props?.index]?.account_id?.message}
                    selectClasses="form-select"
                    disabled={props?.isView}
                  />

                  {/* <label for="disabledSelect" class="form-label">
                    Job number
                  </label>
                  <select id="disabledSelect" class="form-select">
                    <option>Please Select</option>
                    <option>1990 Rey test</option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                  </select> */}
                  {/* <div class="dropdown_caret">
                    <img
                      src="/images/icons/dropdown_caret.svg"
                      alt="dropdown_caret"
                    />
                  </div> */}
                </div>
              </div>
              <div class="col-md-6 col-sm-12">
                <div class="form-group">
                  <Input
                    type="number"
                    id={`items.${props.index}.cleaning_workers`}
                    formControlClasses="form-control"
                    placeholder="Type here"
                    required
                    name={`items.${props.index}.cleaning_workers`}
                    label="# of Cleaning workers"
                    formControlProps={props.register(
                      `items.${props.index}.cleaning_workers`,
                      {
                        required: "This field is required",
                        pattern: {
                          value: /^(0|[1-9]\d*)$/, // Regular expression for decimal numbers
                          message: 'Decimal  and negative number cannot be accepted ',
                        },

                        // required: "This Field is Required",
                        // valueAsNumber: true,
                        // pattern: {
                        //   value:/[0-9]/,
                        //   message:"Must Be a Number"
                        // },
                        // maxLength: 80,
                      }
                    )}
                    validationErrorMessage={
                      props.errors?.items?.[props.index]?.cleaning_workers
                        ?.message
                    }
                    disabled={props?.isView}
                  />
                </div>
              </div>
              <div class="col-md-6 col-sm-12">
                <div class="form-group mb-0">
                  <Input
                    type="number"
                    id={`items.${props.index}.team_checklist_completed`}
                    name={`items.${props.index}.team_checklist_completed`}
                    formControlClasses="form-control"
                    placeholder="Type here"
                    required
                    label="# of Team checklist completed"
                    formControlProps={props.register(
                      `items.${props.index}.team_checklist_completed`,
                      {
                        required: "This field is required",
                        pattern: {
                          value: /^(0|[1-9]\d*)$/, // Regular expression for decimal numbers
                          message: 'Decimal  and negative number cannot be accepted ',
                        },
                        maxLength: 80,
                      }
                    )}
                    validationErrorMessage={
                      props.errors?.items?.[props.index]?.team_checklist_completed?.message ?? ""
                    }
                    disabled={props?.isView}
                  />
                </div>
              </div>
              <div class="col-md-6 col-sm-12">
                <div class="form-group mb-0">
                  <Input
                    type="text"
                    value={getPercentage()}
                    id={`items.${props.index}.completed`}
                    name={`items.${props.index}.completed`}
                    formControlClasses="form-control"
                    required
                    label="% Completed"
                    formControlProps={props.register(
                      `items.${props.index}.completed`,
                      {
                        required: "This field is required",

                        // min:{
                        //   value:0,
                        //   message:"Greater than 0"
                        // },
                        // max:{
                        //   value:100,
                        //   message:"Lessthan 100"
                        // }


                      }
                    )}
                    validationErrorMessage={
                      props.errors?.items?.[props.index]?.completed
                        ?.message ?? ""
                    }
                    disabled={true}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {
        props?.index > 0 ?
          <div class="col-md-12 col-sm-12" >
            <div class="action_button pt-3">
              <span onClick={props.removeFromTaskList(props.index)}>
                <img src='/images/icons/trash.svg' />
              </span>
            </div>
          </div> : null
      }

</Fragment>
  );
}

export default CheckListTaskForm;
