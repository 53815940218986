import axios, { API_VERSION, accessToken, formAuditBaseUrl } from '../../config';
import storage from '../../utility/storage';
import { getUser } from '../../utility/utility';
const pureAxios = require('axios');

const user = storage.get('user');

const headers = {
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
    },
};


export const getSingleRequestFormDetails = (id) => {
    
    return formAuditBaseUrl.get(API_VERSION+`tagmodule/requestform/${id}`, headers);
}

export const getAllRequestFormDetails = (params) => {
    return formAuditBaseUrl.get(API_VERSION+`tagmodule/requestform`, {params},  headers);
}


export const addRequestFormDetailsAPI = (body) => {
    
    return formAuditBaseUrl.post(API_VERSION+`tagmodule/requestform`,body, headers);
}

export const editRequserFormDetailsAPI = (body) => {
    return formAuditBaseUrl.put(API_VERSION+`tagmodule/requestform`,body, headers);
}



