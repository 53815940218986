import React, { useState, useEffect } from 'react';
import PreviewShiftSwappingRequestInfoSkeleton from './PreviewShiftSwappingRequestInfoSkeleton';
import ShiftSwapRequestReviewForm from './ShiftSwapRequestReviewComponent/ShiftSwapRequestReviewForm';
import PreviewShiftSwappedInfo from '../PreviewShiftSwappedInfo/PreviewShiftSwappedInfo';
import { convertDateFormatV1, convertDateFormatV5, convertStartEndTimeFormat, getConvertTo12Hours, makeFirstLetterCapital } from '../../../../../../utility/utility';
import { userRoles } from '../../../../../../utility/constants/constants';
import moment from "moment";
import cloneDeep from 'clone-deep';
import { tzDateConverterTool_V1, tzDateConverterTool_V2, tzTimeConversionTool_V1 } from '../../../../../../utility/TimezoneOperations/timezoneUtility';

let PreviewShiftSwappingRequestInfo = (props) => {

    const [shiftSwapAllInfo, setShiftSwapAllInfo] = useState(null);
    const [shiftSwapFromShiftInfo, setShiftSwapFromShiftInfo] = useState(null);
    const [shiftSwapToShiftInfo, setShiftSwapToShiftInfo] = useState(null);
    const [fromShiftJobsArray, setFromShiftJobsArray] = useState([]);
    const [passSwapRequestReviewedObject, setPassSwapRequestReviewedObject] = useState({});

    useEffect(() => {

        setShiftSwapAllInfo(props.shiftSwap_allInfo);
        setShiftSwapFromShiftInfo(props.shiftSwap_fromShiftInfo);
        setShiftSwapToShiftInfo(props.shiftSwap_toShiftInfo);
        setFromShiftJobsArray(props.shiftSwap_fromShiftInfo && props.shiftSwap_fromShiftInfo.shift && props.shiftSwap_fromShiftInfo.shift.accounts ? props.shiftSwap_fromShiftInfo.shift.accounts : []);

        return () => {
            // Add functionalities
        }

    }, [props.shiftSwap_allInfo && props.shiftSwap_fromShiftInfo && props.shiftSwap_toShiftInfo]);

    if (props.isLoad) {

        return (
            <>
                <PreviewShiftSwappingRequestInfoSkeleton />
            </>
        );

    } else {

        if (props.shiftSwap_allInfo && props.shiftSwap_allInfo.status && props.shiftSwap_allInfo.status == 'approved') {

            return (
                <>
                    <PreviewShiftSwappedInfo
                        {...props}
                        shiftSwapAllInfo={props.shiftSwap_allInfo}
                        shiftSwapFromShiftInfo={props.shiftSwap_fromShiftInfo}
                        shiftSwapToShiftInfo={props.shiftSwap_toShiftInfo}
                    />
                </>
            );

        } else {

            return (
                <>

                    <main className="app-content add_account_modal add_frontline_pop">
                        <div className="appcontent_Inner user_newQuote pt-0">

                            <div className="container py-5">

                                <div className="row text-center mb-4">
                                    <div className="col-lg-7 mx-auto">
                                        <h1 className="mb-0" style={{ color: '#4a4a4a', fontWeight: '500', fontSize: '37px' }}> {shiftSwapAllInfo && shiftSwapAllInfo.status && shiftSwapAllInfo.status == 'requested' ? 'Swapping Shift Request Info' : 'Rejected Shift Swap Request Info'} </h1>
                                        {/* <p className="lead mb-0" style={{ color: 'black' }}>Create an elegant product list using Bootstrap 4 list group</p> */}
                                    </div>
                                </div>
                                <div className="addQuote_form">
                                    <form>

                                        <div className="row">
                                            <div className="col-lg-9 mx-auto">
                                                <ul className="list-group shadow rounded" style={{ marginLeft: '10%', marginRight: '10%' }}>

                                                    <li className="list-group-item">
                                                        <div className="media align-items-lg-center flex-column flex-lg-row p-3">
                                                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%' }}>
                                                                <div className="media-body">
                                                                    <h5 className="mt-0 font-weight-bold mb-2 text-dark"> Swap Assignee </h5>
                                                                </div>
                                                                <div className="media-body">
                                                                    {shiftSwapFromShiftInfo && shiftSwapFromShiftInfo.shift && shiftSwapFromShiftInfo.shift.assignedTo && shiftSwapFromShiftInfo.shift.assignedTo.first_name && shiftSwapFromShiftInfo.shift.assignedTo.last_name ? (
                                                                        <>
                                                                            <h5 className="ml-lg-0 order-1 order-lg-2 text-muted">
                                                                                {makeFirstLetterCapital(shiftSwapFromShiftInfo.shift.assignedTo.first_name) + '  ' + makeFirstLetterCapital(shiftSwapFromShiftInfo.shift.assignedTo.last_name)}
                                                                            </h5>
                                                                            <h5 className="ml-lg-0 font-italic text-muted mb-0" style={{ fontSize: '15px' }}> {shiftSwapFromShiftInfo.shift.assignedTo.email} </h5>
                                                                        </>
                                                                    ) : '–'}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>

                                                    <li className="list-group-item">
                                                        <div className="media align-items-lg-center flex-column flex-lg-row p-3">
                                                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%' }}>
                                                                <div className="media-body">
                                                                    <h5 className="mt-0 font-weight-bold mb-2 text-dark"> Swap Status </h5>
                                                                </div>
                                                                <div className="media-body">
                                                                    {shiftSwapAllInfo && (
                                                                        <h5 className={shiftSwapAllInfo.status && shiftSwapAllInfo.status == 'rejected' ? "ml-lg-0 order-1 order-lg-2 text-danger" : shiftSwapAllInfo.status == 'approved' ? "ml-lg-0 order-1 order-lg-2 text-success" : "ml-lg-0 order-1 order-lg-2 text-warming"}>
                                                                            {shiftSwapAllInfo.status ? makeFirstLetterCapital(shiftSwapAllInfo.status) : '–'}
                                                                        </h5>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>

                                                    <li className="list-group-item">
                                                        <div className="media align-items-lg-center flex-column flex-lg-row p-3">
                                                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%' }}>
                                                                <div className="media-body">
                                                                    <h5 className="mt-0 font-weight-bold mb-2 text-dark"> Assignee Shift Date </h5>
                                                                </div>
                                                                <div className="media-body">
                                                                    <h5 className="ml-lg-0 order-1 order-lg-2 text-muted"> {shiftSwapFromShiftInfo && shiftSwapFromShiftInfo.date ? tzDateConverterTool_V1(shiftSwapFromShiftInfo.date) : '–'} </h5>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>

                                                    <li className="list-group-item">
                                                        <div className="media align-items-lg-center flex-column flex-lg-row p-3">
                                                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%' }}>
                                                                <div className="media-body">
                                                                    <h5 className="mt-0 font-weight-bold mb-2 text-dark"> Assignee Shift Day </h5>
                                                                </div>
                                                                <div className="media-body">
                                                                    <h5 className="ml-lg-0 order-1 order-lg-2 text-muted"> {shiftSwapFromShiftInfo && shiftSwapFromShiftInfo.date ? tzDateConverterTool_V2(shiftSwapFromShiftInfo.date) : '–'} </h5>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>

                                                    <li className="list-group-item">
                                                        <div className="media align-items-lg-center flex-column flex-lg-row p-3">
                                                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%' }}>
                                                                <div className="media-body">
                                                                    <h5 className="mt-0 font-weight-bold mb-2 text-dark"> Assignee Shift Timings </h5>
                                                                </div>
                                                                <div className="media-body">
                                                                    <h5 className="ml-lg-0 order-1 order-lg-2 text-muted">
                                                                        {shiftSwapFromShiftInfo && shiftSwapFromShiftInfo.shift && shiftSwapFromShiftInfo.shift.start_time && shiftSwapFromShiftInfo.shift.end_time ? tzTimeConversionTool_V1(shiftSwapFromShiftInfo.shift.start_time, shiftSwapFromShiftInfo.date.split('T')[0]) + ' − ' + tzTimeConversionTool_V1(shiftSwapFromShiftInfo.shift.end_time, shiftSwapFromShiftInfo.date.split('T')[0]) : '–'}
                                                                    </h5>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>

                                                    <li className="list-group-item">
                                                        <div className="media align-items-lg-center flex-column flex-lg-row p-3">
                                                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%' }}>
                                                                <div className="media-body">
                                                                    <h5 className="mt-0 font-weight-bold mb-2 text-dark"> Notes </h5>
                                                                </div>
                                                                <div className="media-body">
                                                                    <h5 className="ml-lg-0 order-1 order-lg-2 text-muted"> {shiftSwapFromShiftInfo && shiftSwapFromShiftInfo.shift && shiftSwapFromShiftInfo.shift.notes ? shiftSwapFromShiftInfo.shift.notes : '−'} </h5>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>

                                                    <li className="list-group-item">
                                                        <div className="media align-items-lg-center flex-column flex-lg-row p-3">
                                                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%' }}>
                                                                <div className="media-body" style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                                                    <div style={{ width: '40%', border: '1px solid #555555' }}>
                                                                        <h5
                                                                            className="mt-0 font-weight-bold mb-3 text-dark"
                                                                            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}
                                                                        >
                                                                            {fromShiftJobsArray && fromShiftJobsArray.length > 1 ? 'Assignee Jobs' : 'Assignee Job'}
                                                                        </h5>
                                                                    </div>
                                                                    <div style={{ width: '30%', backgroundColor: '#555555', border: '1px solid #555555' }}>
                                                                        <h5
                                                                            className="mt-0 font-weight-bold mb-3 text-white"
                                                                            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}
                                                                        >
                                                                            Address
                                                                        </h5>
                                                                    </div>
                                                                </div>
                                                                <div className="media-body" style={{ display: 'flex', flexDirection: 'column' }}>
                                                                    {fromShiftJobsArray && fromShiftJobsArray.length > 0 ? fromShiftJobsArray.map((job, index, array) => {

                                                                        let trade = job && job.address;

                                                                        return (
                                                                            <>
                                                                                <div style={{ borderLeft: '5px solid #cfcfcf', backgroundColor: '#f0f0f0' }}>
                                                                                    <h5 className="ml-lg-3 order-1 order-lg-2 text-muted mt-2 mb-1"> {job && job.name} </h5>
                                                                                    <h5 className="ml-lg-3 font-italic text-muted mb-2" style={{ fontSize: '15px' }}>
                                                                                        {
                                                                                            trade && trade.formatted_address == null ?
                                                                                                `${trade.city !== null ? trade.city + ', ' : null}
                                                                                                ${trade.state !== null ? trade.state + ', ' : null}
                                                                                                ${trade.country !== null ? trade.country + ', ' : null}
                                                                                                ${trade.zip !== null ? trade.zip : null}` :
                                                                                                trade.formatted_address
                                                                                        }
                                                                                    </h5>
                                                                                </div>
                                                                                {index === array.length - 1 ? null : <br />}
                                                                            </>
                                                                        );

                                                                    }) : (<h5 className="ml-lg-0 font-italic text-color-info mb-0" style={{ fontSize: '15px' }}> None of Jobs available for this Assignee </h5>)}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>

                                                    <li className="list-group-item">
                                                        <div className="media align-items-lg-center flex-column flex-lg-row p-3">
                                                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%' }}>
                                                                <div className="media-body">
                                                                    <h5 className="mt-0 font-weight-bold mb-2 text-dark"> Swapping Shift Employee </h5>
                                                                </div>
                                                                <div className="media-body">
                                                                    {shiftSwapToShiftInfo && shiftSwapToShiftInfo.shift && shiftSwapToShiftInfo.shift.assignedTo && shiftSwapToShiftInfo.shift.assignedTo.first_name && shiftSwapToShiftInfo.shift.assignedTo.last_name ? (
                                                                        <>
                                                                            <h5 className="ml-lg-0 order-1 order-lg-2 text-muted">
                                                                                {makeFirstLetterCapital(shiftSwapToShiftInfo.shift.assignedTo.first_name) + '  ' + makeFirstLetterCapital(shiftSwapToShiftInfo.shift.assignedTo.last_name)}
                                                                            </h5>
                                                                            <h5 className="ml-lg-0 font-italic text-muted mb-0" style={{ fontSize: '15px' }}> {shiftSwapToShiftInfo.shift.assignedTo.email} </h5>
                                                                        </>
                                                                    ) : '–'}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>

                                                    <li className="list-group-item">
                                                        <div className="media align-items-lg-center flex-column flex-lg-row p-3">
                                                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%' }}>
                                                                <div className="media-body">
                                                                    <h5 className="mt-0 font-weight-bold mb-2 text-dark"> Swapping Shift Date </h5>
                                                                </div>
                                                                <div className="media-body">
                                                                    <h5 className="ml-lg-0 order-1 order-lg-2 text-muted"> {shiftSwapToShiftInfo && shiftSwapToShiftInfo.date ? tzDateConverterTool_V1(shiftSwapToShiftInfo.date) : '–'} </h5>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>

                                                    <li className="list-group-item">
                                                        <div className="media align-items-lg-center flex-column flex-lg-row p-3">
                                                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%' }}>
                                                                <div className="media-body">
                                                                    <h5 className="mt-0 font-weight-bold mb-2 text-dark"> Swapping Shift Day </h5>
                                                                </div>
                                                                <div className="media-body">
                                                                    <h5 className="ml-lg-0 order-1 order-lg-2 text-muted"> {shiftSwapToShiftInfo && shiftSwapToShiftInfo.date ? tzDateConverterTool_V2(shiftSwapToShiftInfo.date) : '–'} </h5>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>

                                                    <li className="list-group-item">
                                                        <div className="media align-items-lg-center flex-column flex-lg-row p-3">
                                                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%' }}>
                                                                <div className="media-body">
                                                                    <h5 className="mt-0 font-weight-bold mb-2 text-dark"> Swapping Shift Time </h5>
                                                                </div>
                                                                <div className="media-body">
                                                                    <h5 className="ml-lg-0 order-1 order-lg-2 text-muted">
                                                                        {shiftSwapToShiftInfo && shiftSwapToShiftInfo.shift && shiftSwapToShiftInfo.shift.start_time && shiftSwapToShiftInfo.shift.end_time ? tzTimeConversionTool_V1(shiftSwapToShiftInfo.shift.start_time, shiftSwapToShiftInfo.date.split('T')[0]) + ' − ' + tzTimeConversionTool_V1(shiftSwapToShiftInfo.shift.end_time, shiftSwapToShiftInfo.date.split('T')[0]) : '–'}
                                                                    </h5>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>

                                                    <li className="list-group-item">
                                                        <div className="media align-items-lg-center flex-column flex-lg-row p-3">
                                                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%' }}>
                                                                <div className="media-body">
                                                                    <h5 className="mt-0 font-weight-bold mb-2 text-dark"> Swapping Reason </h5>
                                                                </div>
                                                                <div className="media-body">
                                                                    <div className="form-group">
                                                                        <h5 className="ml-lg-0 order-1 order-lg-2 text-muted"> {shiftSwapAllInfo && shiftSwapAllInfo.reason ? shiftSwapAllInfo.reason : '−'} </h5>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>

                                                </ul>
                                            </div>
                                        </div>

                                        {!(props.user.role && props.user.role == userRoles.FRONTLINE) && shiftSwapAllInfo && shiftSwapAllInfo.status && shiftSwapAllInfo.status == 'requested' ? (
                                            <div className="modal-footer">

                                                <button data-test="" type="button" className="btn cst_btn pegdesk_button w-25" onClick={() => {
                                                    setPassSwapRequestReviewedObject({ shiftSwapId: props.shiftSwap_allInfo.id, shiftSwapReviewStatus: 'approved' });
                                                    props.handleFrequentTimeTracker_openModal();
                                                }} data-toggle="modal" data-target="#shiftSwapRequestReview" data-backdrop="static" data-keyboard="false">
                                                    Approve
                                                </button>

                                                <button data-test="" type="button" className="btn cst_btn bnt-danger w-25" style={{ fontWeight: '700' }} onClick={() => {
                                                    setPassSwapRequestReviewedObject({ shiftSwapId: props.shiftSwap_allInfo.id, shiftSwapReviewStatus: 'rejected' });
                                                    props.handleFrequentTimeTracker_openModal();
                                                }} data-toggle="modal" data-target="#shiftSwapRequestReview" data-backdrop="static" data-keyboard="false">
                                                    Reject
                                                </button>

                                            </div>
                                        ) : null}

                                    </form>
                                </div>
                            </div>

                        </div >
                    </main >

                    {props.isTimeTrackerModalOpen ? (
                        <ShiftSwapRequestReviewForm {...props} swapRequestReview={passSwapRequestReviewedObject} />
                    ) : null}

                </>
            );

        }

    }

};

export default PreviewShiftSwappingRequestInfo;