import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reset } from 'redux-form';
import { animateScroll as scroll} from 'react-scroll'
import * as actions from '../../../../../redux/actions/index';
import PreviewLogbookArea from '../../../../component/Accounts/LogBook Area/PreviewLogbookArea/PreviewLogbookArea';
import { routes } from '../../../../../utility/constants/constants';
import storage from '../../../../../utility/storage';
import { convertDateToDifferentTZ } from '../../../../../utility/utility';
const cloneDeep = require('clone-deep');

class PreviewContainerLogBookArea extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedIds: [],
            selectNone: false,
            selectAll: false,
            selectedHolidaysIds: this.props.account && this.props.account.user ? this.props.account.user.holidays.map(h => h.id) : [],
            selectedInspectionFormsIds: this.props.account && this.props.account.user ? this.props.account.user.inspection_forms.map(i => i.id) : []
        }
    }

    componentDidMount() {
        scroll.scrollToTop();
    }

    resetClicked = () => {
        this.props.reset('searchPreviewLogbookForm');
        this.props.logbookPreviewPageClicked(0)
        this.props.getLogBookAreaPreview({}, 1,this.props.logbook_rows,[],this.props.match.params.logbookId);
    }

    componentWillUnmount() {
        this.props.resetObjects();
    }

    handelSubmit = (values) => {
        const value = cloneDeep(values)
        this.props.logbookPreviewPageClicked(0)
        if(value.name){
          value.name = value.name.trim()
        }
        if(value.status){
            value.status = value.status.trim()
          }
          if (value.fromDate) {
            value.fromDate = convertDateToDifferentTZ(value.fromDate, 'from');
        }
        if (value.toDate) {
            value.toDate = convertDateToDifferentTZ(value.toDate, 'to');
        }
        this.props.getLogBookAreaPreview(value, 1,this.props.logbook_rows,[], this.props.match.params.logbookId);
    }

    previewLogBookAreaQrScanClicked = (id) => {
        this.props.previewLogBookAreaQrScanClicked(id);
    }

    logbookQRCamera = () => {
        storage.set('logScanHistory', this.props.history.location.pathname)
        this.props.history.push(routes.SCAN_LOGBOOK_QR);
    }

    render() {

        return (
            <PreviewLogbookArea
                {...this.props}
                {...this.state}
                onSubmit={this.handelSubmit}
                accountId={this.props.match.params.accountId}
                resetClicked={this.resetClicked}
                isLoading={this.props.isLoading}
                viewLogbookQrScan={this.props.viewLogbookQrScan}
                previewLogBookAreaQrScanClicked={this.previewLogBookAreaQrScanClicked}
                logbookQRCamera={this.logbookQRCamera}
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        isLoading: state.adminOrSuperAdminReducer.isLoading,
        logbook_rows: state.adminOrSuperAdminReducer.logbook_rows,
        user: state.authReducer.user,
        isLogbookAreas: state.adminOrSuperAdminReducer.isLogbookAreas,
        logbookAreasList: state.adminOrSuperAdminReducer.logbookAreasList,
        viewLogbookQrScan: state.adminOrSuperAdminReducer.viewLogbookQrScan,
        account: state.adminOrSuperAdminReducer.account,
        account_users_row: state.adminOrSuperAdminReducer.account_users_row,
        isAreaActiveInActivePending: state.adminOrSuperAdminReducer.isAreaActiveInActivePending,
        // holidaysList: state.adminOrSuperAdminReducer.holidaysList,
        // inspectionFormsList: state.adminOrSuperAdminReducer.searchedInspectionFormsList ? state.adminOrSuperAdminReducer.searchedInspectionFormsList : state.adminOrSuperAdminReducer.inspectionFormsList,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getLogBookAreaPreview: (SearchValue,page,limit,sorted, logbookId) => dispatch(actions.getLogBookAreaPreview(SearchValue,page,limit,sorted, logbookId )),
        previewLogBookAreaQrScanClicked: (scan) => dispatch(actions.previewLogBookAreaQrScanClicked(scan)),
        getAccountUsers: (accountId, values, user_no_of_rows, page, sorted, filtered) => dispatch(actions.getAccountUsersInfoPage(accountId, values, user_no_of_rows, page, sorted, filtered)),
        searchAccountUsers: (accountId, values, rows, page) => dispatch(actions.searchAccountUsers(accountId, values, rows, page)),
        resetObjects: () => dispatch(actions.resetObjects()),
        reset: (searchPreviewLogbookForm) => dispatch(reset(searchPreviewLogbookForm)),  // requires form name
        logbookPreviewPageClicked: (page) => dispatch(actions.logbookPreviewPageClicked(page)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PreviewContainerLogBookArea);