import React, { useState } from 'react';
import { Input } from '../UI/InputElement/InputElement';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { CriteriaSearchValidator as validate } from '../../../utility/validator/validator';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import { convertUsersDataToCsvType } from '../../../utility/utility';
import { CSVLink, CSVDownload } from "react-csv";
import CriteriesTable from './CriteriaTable';
import NewCriteria from './NewCriteria/newCriteria';
import CriteriaTable from './CriteriaTable';
import InactiveCriteriaTable from './InactiveCriteriaTable';

export let Criteria = (props) => {
    const [activeList, setActiveList] = useState(true);
    const [inactiveList, setinactiveList] = useState(false);
    const activeListCategory = () => {
      props.getCriteriaList({hide: false})
      setActiveList(true)
      setinactiveList(false)
  }
  const inactiveListCategory = () => {
      props.getCriteriaList({hide: true})
      setinactiveList(true)
      setActiveList(false)
            }
    const { handleSubmit, pristine, reset, submitting, error } = props;
    return (
        <>
            <main className="app-content wraper_content inspection_wraper categories_blc">
                <div className="tab_header search_filter_cont inspection_tab_content appcontent_inner">
                    <form onSubmit={handleSubmit}>
                        <input type="hidden" value="something" />
                        <div className="row">
                        <div class="col">
                                <button className="btn cst_btn btn_danger mr-2" onClick={activeListCategory}>Active Criteria</button>
                                <button className="btn cst_btn btn_danger mr-2" onClick={inactiveListCategory}>In-active Criteria</button>
                                </div>
                                <div className="col">
                            <div className="col-xl-12 col-lg-4 col-md-12 col-sm-12 text-right accounts_btn_wrap">
                                <a href="JavaScript:void(0);" className="btn btn-secondary cst_btn" data-toggle="modal" data-target="#addCriteria" data-backdrop="static" data-keyboard="false" onClick={props.openPopup}><i className="fa fa-plus icn_plus" aria-hidden="true"></i>New Criteria</a>
                            </div>
                            </div>
                        </div>
                    </form>
                </div>

                {
                    activeList && 
                <section className="account_sec user_screen inspection_tab_content">
                            <CriteriaTable
                                deleteUser={props.deleteUser}
                                users_rows={props.users_rows}
                                // isLoading={props.isLoading}
                                isCriteria={props.isCriteria}
                                onUserPageSizeChange={props.onUserPageSizeChange}
                                criteries={props.criteries ? props.criteries : []}
                                editCriteriaClicked={props.editCriteriaClicked}
                                openDeleteModel={props.openDeleteModel}
                                handleClickDeleteModel={props.handleClickDeleteModel} />
                </section>
                }
                {
                    inactiveList && 
                <section className="account_sec user_screen inspection_tab_content">
                            <InactiveCriteriaTable
                                deleteUser={props.deleteUser}
                                users_rows={props.users_rows}
                                // isLoading={props.isLoading}
                                isCriteria={props.isCriteria}
                                onUserPageSizeChange={props.onUserPageSizeChange}
                                criteries={props.criteries ? props.criteries : []}
                                editCriteriaClicked={props.editCriteriaClicked}
                                openDeleteModel={props.openDeleteModel}
                                handleClickDeleteModel={props.handleClickDeleteModel} />
                </section>
                }
            </main>
            <Dialog
                open={props.openDeleteModel}
                onClose={props.handleClose}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title" style={{ fontSize: '13', color: '#06080f' }}>
                    <h3 style={{ color: '#ab385e' }}>Are you sure?</h3>
                </DialogTitle>

                <DialogActions>
                    <button style={{ minHeight: '36px', minWidth: '80px' }} onClick={() => props.deleteCriteria(props.deleteObject)} disabled={props.isLoading} className={props.isLoading ? "btn cst_btn btn_danger mr-2 btn-wait" : "btn cst_btn btn_danger mr-2"} id="deleteCriteria" type="delete">{props.isLoading ? "" : "Delete"}</button>
                    <button style={{ minHeight: '36px', minWidth: '80px' }} onClick={props.handleDeleteClose} className={"btn cst_btn btn-outline-secondary"} id="cancel" type="cancel">Cancel</button>
                    {/* <Button onClick={props.handleClose} variant="contained" color="primary">
                        Cancel
                        </Button>


                    <Button onClick={() => props.deleteAccount(props.deleteObject.id)} disabled={props.isLoading} variant="contained" color="secondary">
                        {props.isLoading ? <CircularProgress size={24} /> : 'Delete'}
                    </Button> */}
                </DialogActions>
            </Dialog>
            {props.isOpenModel ?
                <NewCriteria
                    onSubmit={props.addNewCriteria}
                    closePopup={props.closePopup}
                    isLoading={props.isLoading}
                /> : null}
        </>
    )
}


const mapStateToProps = (state) => {
    let email = "";
    let name = "";

    return { initialValues: { email, name } }
}

Criteria = reduxForm({
    form: 'searchUserForm',
    validate,
    enableReinitialize: true
})(Criteria);

Criteria = connect(mapStateToProps)(Criteria)

export default Criteria;