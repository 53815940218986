import React, { useEffect, useState } from 'react';
import '../../../../../../styles/custom.css';
import CheckLIstManagerPage from '../../../../../component/FormAuditModule/TeamManagement/ChecklistManager/NewCheckListManager/CheckLIstManagerPage';
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";
import {  getSingleManagerDetails,getCustomerInfos, getAllJob, addTeamManagementSummary, editTeamManagementSummary, getEmployeeList,getManagersList  } from "../../../../../../redux/actions/FormAuditModAction/TeamManagementAction/teamManagementAction";
import { log } from '@tensorflow/tfjs-core';
import { async } from '@firebase/util';




const CheckListManagerSummaryContainer=()=>{

    const {location:{pathname}}=useHistory()
    const {id} = useParams();
    const dispatch=useDispatch()
    const isEdit=Boolean(pathname.split("/")[2] !== "view" && id)
    const isView=Boolean(pathname.split("/")[2].includes("view"))
    const {ManagerDetails}=useSelector((state)=>state.FormAuditModReducer)
    const current= new Date()
    const formatSectionData=(item)=>{
      
      return [{id:item.id,supervisor_id:item.supervisor_id,account_id:item.account_id,cleaning_workers:item.cleaning_workers,team_checklist_completed:item.team_checklist_completed,completed:item.completed }]
  }

  const formatMonthYear = (monthNumber, year) => {
    // Create a Moment.js object with the given month and year
    const date = new Date(year, monthNumber - 1); // Month is zero-based in Moment.js
    
    // Format the date as desired
    //const formattedDate = date.format('MMMM - YYYY'); // Format to "December - 2024"
  
    return date;
  };

    const defaultForm={
      company_id:isEdit || isView ? ManagerDetails?.company_id ? ManagerDetails.company_id :"" :"" ,
      manager_id:isEdit || isView ? ManagerDetails?.manager_id ? ManagerDetails.manager_id :"" :"",
      month:isEdit || isView ?  ManagerDetails?.month ? formatMonthYear(ManagerDetails.month,ManagerDetails.year) :"":"",
      year:isEdit || isView ? ManagerDetails?.year ?  current.setFullYear(+ManagerDetails.year) :"":"",
      items:isEdit || isView ?  ManagerDetails?.sections ? formatSectionData(ManagerDetails.sections) :[
        {
          supervisor_id:"",
          account_id:"",
          cleaning_workers:"",
          team_checklist_completed:"",
          completed:""

        },
        // {
        //   supervisor:"",
        //   jobNumber:"",
        //   no_of_cleaningWorkers:"",
        //   no_of_team_checklist_completed:"",
        //   percentage_completed:""

        // }
      ]
      :
      [
        {
          supervisor_id:"",
          account_id:"",
          cleaning_workers:"",
          team_checklist_completed:"",
          completed:""

        },]

      // i think this is an array and we need to validate this as an array 
      // supervisor:"",
      // jobNumber:"",
      // no_of_cleaningWorkers:"",
      // no_of_team_checklist_completed:"",
      // percentage_completed:""
    }

    

   

    const structureBody=(data)=>{
      let body=null
      const {items,month,year,...remainData}=data
      const commonBody={
        month:data.month?.month ? (data.month.month.number).toString() : (new Date(data.month).getMonth() + 1).toString() ,
        year:data.month?.year? data.month?.year.toString() : new Date(data.year).getFullYear().toString(),
        ...remainData
      }
      
       if(isEdit){
         body=[{
          ...remainData,
          id:id,
          user_id:ManagerDetails.user_id,
          active_status: "active",
          month:data.month?.month ? (data.month.month.number).toString() : (new Date(data.month).getMonth() + 1).toString() ,
          year:data.month?.year? data.month?.year.toString() : new Date(data.year).getFullYear().toString(),
          sections:{...data.items[0],team_checklist_completed:data.items[0].team_checklist_completed.toString(),completed:data.items[0].completed.split("%")[0],team_checklist_manager_summary_id:id,active_status: "active"},
         }]
       }
       else{
         body =data.items.map((item)=>({...commonBody ,...item,completed: item.completed.split("%")[0]    }))

        // debugger
        //   body={
        //   ...remainData,
        //   month:data.month?.getMonth ? (data.month?.getMonth() +1).toString() : (new Date(data.month).getMonth() + 1).toString() ,
        //   year:data.year?.year? data.year?.year.toString() : new Date(data.year).getFullYear().toString(),
        //   sections:items,

        //  }
          
       }
       
       return {
        data:body
      }
    }

    const onSubmit=(data)=>{
      
      // const {month,year,items,...remainbody}=data  
      // const body={
      //   data:{
      //     month:data.month?.getMonth ? (data.month?.getMonth() +1).toString() : (new Date(data.month).getMonth() + 1).toString() ,
      //     year:data.year?.year? data.year?.year.toString() : new Date(data.year).getFullYear().toString(),
      //     sections: isEdit ? {...data.items[0],team_checklist_completed:data.items[0].team_checklist_completed.toString(),completed:data.items[0].completed.toString(),id,team_checklist_manager_summary_id:id,active_status: "active"} : items,
      //     ...remainbody
      //   }
      // }
      
      // if(isEdit){
      //   body.data.id=id
      //   body.data.user_id=ManagerDetails.user_id
      //   body.data.active_status= "active"
      // }
       
      // let newBody={
      //   data:[body.data]
      // }
      
      if(isEdit){
         dispatch(editTeamManagementSummary(structureBody(data)))
        
      }else{

        dispatch(addTeamManagementSummary(structureBody(data)))
      }
    }

    useEffect(()=>{
        if(isEdit || isView && id ){
           dispatch(getSingleManagerDetails(id))
        }
      },[id])


    

      useEffect(()=>{
         dispatch(getCustomerInfos())
         dispatch(getAllJob())
          dispatch(getManagersList({manager : true}))
        //  dispatch(getEmployeeList({employee:true}))
      },[])

    
    return (
      <>
        <CheckLIstManagerPage 
         elements={defaultForm}
         formSubmittedCallback={onSubmit}
         isEdit={isEdit}
         isView={isView}
         modalUpdate={true}
        />
        
        </>
    )
}



export default CheckListManagerSummaryContainer;