import React, { Component } from 'react';
import { routes } from '../../../../../utility/constants/constants';
import InventoryList from '../../../../component/Inventory/InventoryListComponent/InventoryList';
import * as actions from '../../../../../redux/actions/index';
import { connect } from 'react-redux';
import { reset } from 'redux-form';
import { animateScroll as scroll} from 'react-scroll'
import storage from '../../../../../utility/storage';
import { convertDateToDifferentTZ } from '../../../../../utility/utility';
import ShiftSchedule from '../../../../component/TimeTrackerModuleComponent/ShiftComponent/ShiftScheduleComponent/ShiftSchedule';
import errorHandler from '../../../../../utility/errorHandler/errorHandler';
import { tzUTCDateConverterTool_V2 } from '../../../../../utility/TimezoneOperations/timezoneUtility';

var moment = require('moment');
const cloneDeep = require('clone-deep');

class ShiftScheduleContainer extends Component {

    constructor(props) {
        super(props);

        this.state = {
            
        }
    }

    componentDidMount() {
        scroll.scrollToTop();
    }

    componentDidUpdate(prevProps, prevState) {
        const user = storage.get('user');
        if(user) {
            if(prevProps.user.selected_mode !== user.selected_mode) {
                this.props.shiftScheduleListPageClicked(0)
            }
        }
    }

    resetClicked = () => {
        const page = 1
        const limit = this.props.shiftScheduleList_no_of_rows
        this.props.reset('searchShiftSchedule');
        this.props.shiftScheduleListPageClicked(0)
        this.props.getShiftSchedule({page,limit})

    }

    searchInventoryItemsListClicked = (value) => {
        const values = cloneDeep(value)
        let userName =  null;
        let accountName = null;
        let from = null;
        let to = null;
    
        const page = 1
        const limit = this.props.shiftScheduleList_no_of_rows
        if(values.user) {
            values.user = values.user.trim()
            userName = values.user
        }
      if(values.job) {
        values.job = values.job.trim()
        accountName =  values.job 
        }  
      if(values.fromDate) {
        from = tzUTCDateConverterTool_V2(values.fromDate, 'from');
      }  
      if(values.toDate) {
        to = tzUTCDateConverterTool_V2(values.toDate, 'to');
      }  

      if(from && !to || !from && to ){
        errorHandler(`Both 'From' and 'To' dates are required.`)
        return false
      }
     
        this.props.getShiftSchedule({page,limit,userName,accountName,from,to}, values);
    }

    addInventoryItemClicked = () => {
        this.props.history.push(routes.NEW_INVENTORY_ITEM);
    }

    deleteInventoryItem = (id) => {
        this.props.deleteInventoryItem(id);
    }

    handleInventoryOpenDeleteModal = (value) => {
        this.props.handleInventoryOpenDeleteModal(value);
    }

    handleInventoryCloseDeleteModal = () => {
        this.props.handleInventoryCloseDeleteModal();
    }

    newTicketClicked = () => {
        // this.props.resetObjects();
        // this.props.history.push(routes.NEW_SCHEDULED_TASK);
    }

    handleNewShiftClicked = () => {
        this.props.history.push(routes.FLUSER_NEW_SHIFT);
    }

    handleEditShiftClicked = (id) => {
        this.props.history.push({ pathname: `frontlineUser/edit-shift/${id}` })
    }

    deleteMainShift = (id) => {
        this.props.deleteMainShift(id);
    }

    handleFrequentTimeTracker_openDeleteModal = (value) => {
        this.props.handleFrequentTimeTracker_openDeleteModal(value);
    }

    handleFrequentTimeTracker_closeDeleteModal = () => {
        this.props.handleFrequentTimeTracker_closeDeleteModal();
    }
         
    render() {
        return (
            <ShiftSchedule
                {...this.props}
                isLoading={this.props.isLoading}
                onSubmit={this.searchInventoryItemsListClicked}
                resetClicked={this.resetClicked}
                shiftScheduleList={this.props.shiftScheduleList ? this.props.shiftScheduleList : []}
                addInventoryItemClicked={this.addInventoryItemClicked}
                deleteInventoryItem={this.deleteInventoryItem}
                handleInventoryOpenDeleteModal={this.handleInventoryOpenDeleteModal}
                handleInventoryCloseDeleteModal={this.handleInventoryCloseDeleteModal}
                handleNewShiftClicked={this.handleNewShiftClicked}
                handleEditShiftClicked={this.handleEditShiftClicked}
                deleteMainShift={this.deleteMainShift}
                handleFrequentTimeTracker_openDeleteModal={this.handleFrequentTimeTracker_openDeleteModal}
                handleFrequentTimeTracker_closeDeleteModal={this.handleFrequentTimeTracker_closeDeleteModal}
                
            />
        )
    }
}


const mapStateToProps = (state) => {
    return {
        user: state.authReducer.user,
        isLoading: state.TimeTrackerModReducer.isLoading,
        shiftScheduleList: state.TimeTrackerModReducer.shiftScheduleList,
        isShiftScheduleList: state.TimeTrackerModReducer.isShiftScheduleList,
        shiftScheduleList_no_of_rows: state.TimeTrackerModReducer.shiftScheduleList_no_of_rows,
        shiftScheduleListCurPage: state.TimeTrackerModReducer.shiftScheduleListCurPage,

        openInventoryDeleteModal: state.TimeTrackerModReducer.openInventoryDeleteModal,
        deleteInventoryObject: state.TimeTrackerModReducer.deleteInventoryObject,

        openFrequentTimeTracker_deleteModal: state.TimeTrackerModReducer.openFrequentTimeTracker_deleteModal,
        deleteFrequentTimeTracker_object: state.TimeTrackerModReducer.deleteFrequentTimeTracker_object,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        reset: (searchInventoryItemsList) => dispatch(reset(searchInventoryItemsList)),  // requires form name
        shiftScheduleListPageClicked: (page) => dispatch(actions.shiftScheduleListPageClicked(page)),
        getShiftSchedule: (params, values) => dispatch(actions.getShiftSchedule(params, values)),
        deleteInventoryItem: (id) => dispatch(actions.deleteInventoryItem(id)),
        handleInventoryOpenDeleteModal: (value) => dispatch(actions.handleInventoryOpenDeleteModal(value)),
        handleInventoryCloseDeleteModal: () => dispatch(actions.handleInventoryCloseDeleteModal()),
        previewSingleShiftScheduleClicked: (ticket) => dispatch(actions.previewSingleShiftScheduleClicked(ticket)),
        resetObjects: () => dispatch(actions.resetObjects()),

        deleteMainShift: (id) => dispatch(actions.deleteMainShift(id)),
        handleFrequentTimeTracker_openDeleteModal: (value) => dispatch(actions.handleFrequentTimeTracker_openDeleteModal(value)),
        handleFrequentTimeTracker_closeDeleteModal: () => dispatch(actions.handleFrequentTimeTracker_closeDeleteModal()),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(ShiftScheduleContainer);