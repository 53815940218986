import store from '../../redux/store/store';
import * as actions from '../../redux/actions/index';
import { isTokenValid } from '../utility'
import { API_ADMIN as adminAxios, API_VERSION } from '../../config';
import axios from '../../config';
import storage from '../../utility/storage';

const authInterceptor = (config) => {
    const state = store.getState();

    if (config.url.includes('/logout')) {
        config.headers.common = { 'Authorization': `bearer ${state.authReducer.token}` }
    } else {
        if (state.authReducer.token) {
            
            if (isTokenValid(state.authReducer.token)) {
                
                config.headers.common = { 'Authorization': `bearer ${state.authReducer.token}` }
            } else {
                store.dispatch(actions.logout("Your session has expired."));
                // axios.post(API_VERSION + 'users/refresh_token', {refresh_token: storage.get('refresh_token')}, {
                //     headers: {
                //         'Content-Type': 'application/json',
                //         'Accept': 'application/json',
                //     }
                // }).then(tokenRefreshResponse => {
                //             storage.set('token', tokenRefreshResponse.data.access_token);
                //             storage.set('refresh_token', tokenRefreshResponse.data.refresh_token);
                //             config.headers.common = { 'Authorization': `bearer ${tokenRefreshResponse.data.access_token}` }
                //             return Promise.resolve();
                //         }).catch(error => {
                //             // store.dispatch(actions.logout())
                //         });

            }
        }
    }
    return config;
}

export default authInterceptor;