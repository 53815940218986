import React from 'react';
import { ClapSpinner } from 'react-spinners-kit';
import { makeFirstLetterCapital } from '../../../../utility/utility';
import { Card, Empty, Badge } from 'antd';

let PegassurePlaybookAreaListComponent = (props) => {

    const getRenderedPlaybookAreaLIst = (playbookAreaList) => {
        const groupedAttachedPlaybookArea = playbookAreaList?.reduce((acc, logbook,) => {

            const { logbook: { account, account_id, log_number, name } } = logbook;

            if (acc[account_id + '/' + account.name]) {
                acc[account_id + '/' + account.name].push(log_number + '/' + name);
            } else {
                acc[account_id + '/' + account.name] = [log_number + '/' + name];
            }

            return acc;
        }, {});

        return groupedAttachedPlaybookArea;
    }


    if (props.isLoading) {

        return (
            <div className="loader_btn_block spinner">
                <ClapSpinner backColor="#89073c" size={40} frontColor="#89073c8a" />
            </div>
        );

    } else {
        if (props.playbookAreaList?.length == 0) {
            return (
                <div>
                    <Empty description='No logbook attached with this playbook' />
                </div>
            );
        } else {
            return (
                <>
                    <div className="quotes_table_content table-responsive performance_modal add_account associateUser pbalc">
                        <div className="table quotes_table user_table_contnet user_react_table">
                            <div class="col-lg-12">
                                <div class="quote_form user_quota_form ticket_form">
                                    <div class="addQuote_form">
                                        <div class="form-group">
                                            <div class="form-group checkbox_cst_grid">
                                                {Object.entries(getRenderedPlaybookAreaLIst(props.playbookAreaList)).map(([accountId, logbookNames]) => {
                                                    return (
                                                        <div key={accountId}>
                                                            <Badge.Ribbon text={accountId.split('/')[0]}>
                                                                <Card title={accountId.split('/')[1]} size='default' bordered={true}>
                                                                    <div className='card_bodyContainer'>
                                                                        {logbookNames.map((logbookName, index) => (
                                                                            <Card.Grid className='card_bodyWard'>
                                                                                <p style={{ display: 'inline' }} key={index}>{makeFirstLetterCapital(logbookName.split('/')[1])}</p>
                                                                            </Card.Grid>
                                                                        ))}
                                                                    </div>
                                                                </Card>
                                                            </Badge.Ribbon>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            );
        }
    }

};

export default PegassurePlaybookAreaListComponent;