import React from 'react';
import { FaFile } from "react-icons/fa";
import { emptyData, BarGraphCountOptions, BarGraphSaleOptions } from '../../../utility/constants/constants';
import { Bar } from 'react-chartjs-2';

var data = {
    labels: [],
    datasets: [
        // invisible dataset
        {
            label: "",
            fillColor: "rgba(220,220,220,0.0)",
            strokeColor: "rgba(220,220,220,0)",
            pointColor: "rgba(220,220,220,0)",
            pointStrokeColor: "#fff",
            pointHighlightFill: "#fff",
            pointHighlightStroke: "rgba(220,220,220,1)",
            // change this data values according to the vertical scale
            // you are looking for 
            data: []
        },]
}

const options = {
    title: {
        display: true,
        fontSize: 20,
        padding: 30,
        fontColor: '#962d39',
        text: 'Top 10 Quotes, Denied by Client'
    },
    ...BarGraphCountOptions
}
const graphStype = {
    marginTop: '0px',
    paddingTop: '0px',
    visibility: 'hidden'
}

const emptyGraph = (props) => {

    return (
            <div className="tile chart-box-botL empty-graph_heading" id='emptyChartId'  style={{ marginTop: '0px', paddingTop: '0px', height:'569px'}}>
            <h6 className="chart_title2">{props.title}</h6>
            <div className="char_empty_cart">
                {/* <div className="chart-img" style={{ marginTop: '60px', fontSize: '22px', fontWeight: 'bold', color: '#000000a8' }}> */}
                <div><FaFile color={'#c2c2c2'} size={100} />
                    <div><span style={{ fontWeight: '700', fontSize: '20px', color: 'black' }}>No Data Available!</span></div>
                </div>
            </div>

            <div className="tile chart-box-botL" style={graphStype}>
                {/* <Bar data={data} options={options} /> */}
            </div>

            {/* </div> */}
        </div>
    )
}

export default emptyGraph;