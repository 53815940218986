import React, { useState, useEffect } from 'react';
import { Input } from '../../UI/InputElement/InputElement';
import { useDispatch, useSelector } from "react-redux";
import { reduxForm, getFormValues } from 'redux-form';
import { connect } from 'react-redux';
import { convertDateToPickerFormat, isEmpty, makeFirstLetterCapital } from '../../../../utility/utility';
import { createApiAccessValidator as validate } from '../../../../utility/validator/validator';
import PerformCompanySkeletonModal from './PerformCompanySkeletonModal';

const getCompaniesDropDown = (accounts) => {

    return accounts && accounts.map(a => {
        return {
            value: a.id,
            label: a.name
        }
    })
    
}
let PerformAPIAccessModal = (props) => {
    const [companiesList, setCompaniesList] = useState(getCompaniesDropDown(props.companyDropdownList));

    useEffect(() => {
        setCompaniesList(getCompaniesDropDown(props.companyDropdownList))
    }, [props.companyDropdownList])
    
    const companyAccess = useSelector(state => state.adminOrSuperAdminReducer.companyAccess);
    const { handleSubmit, pristine, reset, submitting, error } = props;
    const [temp, setTemp] = useState(false)
    const accountOptionsMessage = (a, b) => {
        return 'Customers not found';
    }
 
    const accessTypeOptions = [
        {label: 'Read', value :'read'},
        {label: 'Write', value :'write'},
        {label: 'Both', value :'both'},
    ]


    return (

        <div className="modal fade performance_modal add_account latestInspectionModal" id="addAPIAccess" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    {/* {((props.inspectionFormsList.length === 0 || props.accountsList.length === 0) && (props.isLoading || isInspectionForm)) && props.inspectionFormsList.length > 0 ?
                        <PerformInsepctionSkeletonModal />
                        : */}
                        {props.isLoading ?  <PerformCompanySkeletonModal /> : <>
                            <div className="modal-header">
                                <h2 className="md_title" id="exampleModalLongTitle" data-test="perform_inspection_title">{props.isEdit ? 'Update API Access Form' : 'Add API Access Form'}</h2>
                                <button  type="button" onClick={() => props.cancelCompanyModel()} className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <form onSubmit={handleSubmit}>
                               <div className="modal-body">
                                    <div className="quote_form ticket_form">
                                        <div className="addQuote_form">  
                                            <div className="form-group" data-test="inpection_job_form">
                                                <label className="form_title">Customer</label>
                                                {companyAccess && companyAccess.company ? 
                                                <input name="company_access[company_id]" type="text" className="form-control input-modifier" placeholder={companyAccess && companyAccess.company ? companyAccess.company.name : ""} disabled={true} />
                                               :<Input name="company_access[company_id]"  noOptionsMessage={accountOptionsMessage} placeholder={"Select Customer"} type="inputSelect" options={companiesList}  /> }
                                            </div>
                                            <div className="form-group" data-test="inpection_from_form">
                                                <label className="form_title">Expiration Date</label>
                                                <Input name="company_access[token_expires]" minDate={ new Date()} dateFormat="MM-dd-yyyy" placeholder="Select Expiration Date" datePickerType={"react-datepicker"} type="date" className="form-control input-modifier" />
                                            </div>
                                            <div className="form-group" data-test="inpection_from_form">
                                                <label className="form_title">Access Type</label>
                                                <Input  name="company_access[access_type]"  type="inputSelect" placeholder="Select Access Type" options={accessTypeOptions} />
                                                
                                            </div>
                                            <div className="form-group">
                                                    <label className="form_title">Status</label>
                                                 <br />
                                                    <Input type="radio" labelClass="custom-control-label" forLable={"active"} value={"active"} label={"Active"} className="custom-control-input" id="active" name="company_access[active_status]" />

                                                    <Input type="radio" labelClass="custom-control-label" forLable={"inactive"} value={"inactive"} label={"Inactive"} className="custom-control-input" id="inactive" name="company_access[active_status]" />

                                                </div>
                                            <div className="form-group">
                                                    <label className="form_title">Limit</label>
                                                 <br />
                                                    <Input type="radio" labelClass="custom-control-label" forLable={"customRadio1"} value={"true"} label={"Limited"} className="custom-control-input" id="customRadio1" name="company_access[is_limited]" />

                                                    <Input type="radio" labelClass="custom-control-label" forLable={"customRadio2"} value={"false"} label={"Unlimited"} className="custom-control-input" id="customRadio2" name="company_access[is_limited]" />

                                                </div>
                                           { props.formStates && props.formStates.company_access && props.formStates.company_access.is_limited === 'true' ? <div className="form-group" data-test="inpection_from_form">
                                                <label className="form_title">Call Limit</label>
                                                <Input name="company_access[access_limit]" type="number" placeholder='Enter Call Limit' className="form-control input-modifier" />
                                                
                                            </div> : ''}
                                            <div className="form-group">
                                                <div className="btn_block text-center">
                                                    <button type="submit"  className="btn cst_btn btn_danger w-25 mr-2">{props.isEdit ? 'Update':'Submit'}</button>
                                                    <button type="button" className="btn cst_btn btn-outline-secondary cancel_btnnew w-25" data-dismiss="modal" onClick={() => props.cancelCompanyModel()} style={{transition: "0.3s"}} >Cancel</button>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </> }
                        
                    
                </div>
            </div>
        </div>
    )
    // }
}
const mapStateToProps = (state) => {

    // const getCompanyIdList = (c) => {
    //     return {
    //             value: c.id,
    //             label: c.name
    //         }
        
    // }
    const getIsLimit = (c) => {
        return  c.toString()
        
    }
    const getAccessType = (type) => {
        return {
                value: type,
                label: makeFirstLetterCapital(type)
            }     
    }
    let company_access = {}
    if(state.adminOrSuperAdminReducer.companyAccess) {
        company_access['token_expires'] = state.adminOrSuperAdminReducer.companyAccess && state.adminOrSuperAdminReducer.companyAccess.token_expires ? convertDateToPickerFormat(state.adminOrSuperAdminReducer.companyAccess.token_expires) :"";
        company_access['access_type'] = state.adminOrSuperAdminReducer.companyAccess && state.adminOrSuperAdminReducer.companyAccess.access_type? getAccessType(state.adminOrSuperAdminReducer.companyAccess.access_type) :"";
        company_access['access_limit'] = state.adminOrSuperAdminReducer.companyAccess && state.adminOrSuperAdminReducer.companyAccess.access_limit? state.adminOrSuperAdminReducer.companyAccess.access_limit :"";
        company_access['is_limited'] = state.adminOrSuperAdminReducer.companyAccess && state.adminOrSuperAdminReducer.companyAccess.is_limited ? getIsLimit(state.adminOrSuperAdminReducer.companyAccess.is_limited) :"false";
        company_access['active_status'] = state.adminOrSuperAdminReducer.companyAccess && state.adminOrSuperAdminReducer.companyAccess.active_status ? state.adminOrSuperAdminReducer.companyAccess.active_status :"";
    } else {
        company_access['token_expires'] = ''
        company_access['access_type'] = ''
        company_access['access_limit'] = ''
        company_access['is_limited'] = ''
        company_access['active_status'] =''
    }
    
    return {
        initialValues: {company_access},
        formStates: getFormValues('performingCompanyAccessForm')(state)
    }
}
PerformAPIAccessModal = reduxForm({
    form: 'performingCompanyAccessForm',
    validate,
    enableReinitialize: true
})(PerformAPIAccessModal);

PerformAPIAccessModal = connect(mapStateToProps)(PerformAPIAccessModal)
export default PerformAPIAccessModal;