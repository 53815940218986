import { Chart } from "react-google-charts";
import React, { useEffect, useState } from 'react';
// import CHIServysTable from '../../container/CHIServysContainer/CHIServysTable';
import WeekMonthYearLists from "../Dashboards/weekMonthYearLists";
import '../../../styles/custom.css';
import ReactTable from 'react-table-v6';
import { FaArrowDown, FaArrowUp } from "react-icons/fa";
import 'react-table-v6/react-table.css';
import { ClapSpinner } from "react-spinners-kit";
import ImageLoader from 'react-imageloader';
import { CustomNoDataComponent, makeFirstLetterCapital } from "../../../utility/utility";
import { Input } from '../UI/InputElement/InputElement';
import { reduxForm, formValueSelector, getFormValues } from 'redux-form';
import { connect } from "react-redux";
import { KpiDashboardSearchValidator as validate } from '../../../utility/validator/validator';
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';




let KpiTicketDashboard = (props) => {

    const [readMore, setReadMore] = useState(false);
    let ticketCategory = [];
    let ticketLineChart = [];
    let ticketassignee = [];
    let highlyRaisedTickets = [];
    let ticketCrossedDueDates = [];
    let daysOpenTickets = [];
    let ticketJob = [];
    let ticketpriorities = [];
    let ticketRole = [];
    let ticketChart = [];

    //category
    if (props.kpiTicketCountData && props.kpiTicketCountData.categories && props.kpiTicketCountData.categories.length > 0) {
        props.kpiTicketCountData.categories.map((e, i) => {
            ticketCategory.push([e.category_name, parseInt(e.ticket_count)])
        })
    } else {
        ticketCategory.push(['', 0])
    }
    if (ticketCategory.length > 0) {
        ticketCategory.unshift(['Category Name', 'Ticket Count'])
    }
    console.log(ticketCategory, 'category')
    //end of category

    //line
    if (props.kpiTicketCountData && props.kpiTicketCountData.ticket_line_chart && props.kpiTicketCountData.ticket_line_chart.length > 0) {
        props.kpiTicketCountData.ticket_line_chart.map((e, i) => {
            ticketLineChart.push([e[0], e[1] === "" ? 0 : parseInt(e[1])])
        })
    } else {
        ticketLineChart.push(['', 0])
    }
    if (ticketLineChart.length > 0) {
        ticketLineChart.unshift(['Date', 'Ticket Count'])
    }
    console.log(ticketLineChart, 'line')
    //end of line

    // ticket chart
    if (props.kpiTicketGraphData && props.kpiTicketGraphData.ticket_chart && props.kpiTicketGraphData.ticket_chart.length > 0) {
        props.kpiTicketGraphData.ticket_chart.map((e, i) => {
            ticketChart.push([e[0], e[1] === "" ? 0 : parseInt(e[1]), e[2] === "" ? 0 : parseInt(e[2])])
        })
    } else {
        ticketChart.push(['', 0, 0])
    }
    if (ticketChart.length > 0) {
        ticketChart.unshift(['Date', 'Open Count', 'Resolved Count'])
    }
    console.log(ticketChart, 'ticketchart')
    //end of ticket chart

    // ticket crossed_due_dates
    if (props.kpiTicketDaysOpenData && props.kpiTicketDaysOpenData.crossed_due_dates && props.kpiTicketDaysOpenData.crossed_due_dates.length > 0) {
        props.kpiTicketDaysOpenData.crossed_due_dates.map((e, i) => {
            ticketCrossedDueDates.push([e.account_name, parseInt(e.tickets_count)])
        })
    } else {
        ticketCrossedDueDates.push(['', 0])
    }
    if (ticketCrossedDueDates.length > 0) {
        ticketCrossedDueDates.unshift(['Date', 'Due Dates'])
    }
    console.log(ticketCrossedDueDates, 'ticketCrossedDueDates')
    //end of ticket crossed_due_dates
    
    // ticket day_open_tickets
    if (props.kpiTicketDaysOpenData && props.kpiTicketDaysOpenData.day_open_tickets && props.kpiTicketDaysOpenData.day_open_tickets.length > 0) {
        props.kpiTicketDaysOpenData.day_open_tickets.map((e, i) => {
            daysOpenTickets.push([parseInt(e.ticket_count), parseInt(e.open_days)])
        })
    } else {
        daysOpenTickets.push(['', 0])
    }
    if (daysOpenTickets.length > 0) {
        daysOpenTickets.unshift(['Open Days', 'Ticket Count '])
    }
    console.log(daysOpenTickets, 'daysOpenTickets')
    //end of ticket day_open_tickets
    
    // ticket kpiTicketHightUsersData
    if (props.kpiTicketHightUsersData && props.kpiTicketHightUsersData.assignee && props.kpiTicketHightUsersData.assignee.length > 0) {
        props.kpiTicketHightUsersData.assignee.map((e, i) => {
            ticketassignee.push([makeFirstLetterCapital(e.user_name), parseInt(e.ticket_count)])
        })
    } else {
        ticketassignee.push(['', 0])
    }
    if (ticketassignee.length > 0) {
        ticketassignee.unshift(['Name', 'Ticket Count '])
    }
    console.log(ticketassignee, 'ticketassignee')
    //end of ticket kpiTicketHightUsersData
        
    // ticket kpiTicketHightUsersData
    if (props.kpiTicketHightUsersData && props.kpiTicketHightUsersData.highly_raised_tickets && props.kpiTicketHightUsersData.highly_raised_tickets.length > 0) {
        props.kpiTicketHightUsersData.highly_raised_tickets.map((e, i) => {
            highlyRaisedTickets.push([e.user_name, parseInt(e.ticket_count)])
        })
    } else {
        highlyRaisedTickets.push(['', 0])
    }
    if (highlyRaisedTickets.length > 0) {
        highlyRaisedTickets.unshift(['Name', 'Ticket Count '])
    }
    console.log(highlyRaisedTickets, 'highlyRaisedTickets')
    //end of ticket kpiTicketHightUsersData
        
            
    // ticket ticketJob
    if (props.kpiTicketJobData && props.kpiTicketJobData.jobs && props.kpiTicketJobData.jobs.length > 0) {
        props.kpiTicketJobData.jobs.map((e, i) => {
            ticketJob.push([e.account_name, parseInt(e.ticket_count)])
        })
    } else {
        ticketJob.push(['', 0])
    }
    if (ticketJob.length > 0) {
        ticketJob.unshift(['Name', 'Ticket Count '])
    }
    console.log(ticketJob, 'ticketJob')
    //end of ticket ticketJob
       
    // ticket priorities
    if (props.kpiTicketJobData && props.kpiTicketJobData.priorities && props.kpiTicketJobData.priorities.length > 0) {
        props.kpiTicketJobData.priorities.map((e, i) => {
            ticketpriorities.push([e.priority, parseInt(e.ticket_count)])
        })
    } else {
        ticketpriorities.push(['', 0])
    }
    if (ticketpriorities.length > 0) {
        ticketpriorities.unshift(['Priority', 'Ticket Count '])
    }
    console.log(ticketpriorities, 'ticketpriorities')
    //end of ticket priorities
        
           
    // ticket roles
    if (props.kpiTicketJobData && props.kpiTicketJobData.roles && props.kpiTicketJobData.roles.length > 0) {
        props.kpiTicketJobData.roles.map((e, i) => {
            ticketRole.push([makeFirstLetterCapital(e.role), parseInt(e.ticket_count)])
        })
    } else {
        ticketRole.push(['', 0])
    }
    if (ticketRole.length > 0) {
        ticketRole.unshift(['Role', 'Ticket Count '])
    }
    console.log(ticketRole, 'ticketRole')
    //end of ticket roles
        
    const loadMoreGraphList = <div>
        <div class="graph_tabs">
            <div class="row">
                <div class="col-xl-12 chart_cont_box">
                    <div class="tile chart-box-botL">
                        <h6 class="chart_title">Tickets Assignee</h6>
                        <Chart
                        width={'1050px'}
                        height={'500px'}
                        chartType="BarChart"
                        loader={<div>Loading Chart</div>}
                        data={ticketassignee}
                        options={{
                          hAxis: {
                            title: 'Ticket Count',
                            minValue: 0,
                          },
                          vAxis: {
                            title: 'Assignee',
                          },
                          series: {
                            1: { curveType: 'function' },
                        },
                        colors: ["#CFB6F2"]
                        }}
                        // For tests
                        rootProps={{ 'data-testid': '1' }}
                      />
                    </div>
                </div>
                <div class="col-xl-6 chart_cont_box">
                    <div class="tile chart-box-botL">
                        <h6 class="chart_title">Highly Raised Tickets by Users</h6>
                        <Chart
                        width={'500px'}
                        height={'300px'}
                        chartType="Line"
                        loader={<div>Loading Chart</div>}
                        data={highlyRaisedTickets}
                        options={{
                          chart: {
                          },
                          colors: ["#83D0D5"]
                        }}
                        rootProps={{ 'data-testid': '3' }}
                      />
                    </div>
                </div>

                <div class="col-xl-6 chart_cont_box">
                    <div class="tile chart-box-botL" >
                        <h6 class="chart_title">No of Tickets Based on priorities</h6>
                        <Chart
                        width={'500px'}
                        height={'300px'}
                        chartType="Bar"
                        loader={<div>Loading Chart</div>}
                        data={ticketpriorities}
                        options={{
                            colors: ["#C9FDFF"]
                        }}
                        rootProps={{ 'data-testid': '2' }}
                      />
                    </div>

                </div>
            </div>
        </div>


        <div class="graph_tabs">
            <div class="row">
                <div class="col-xl-6 chart_cont_box">
                    <div class="tile chart-box-botL">
                        <h6 class="chart_title">Crossed Due Date</h6>
                        <Chart
                        width={'500px'}
                        height={'300px'}
                        chartType="LineChart"
                        loader={<div>Loading Chart</div>}
                        data={ticketCrossedDueDates}
                        options={{
                            curveType: 'function',
                          title: 'My Daily Activities',
                          colors: ["#51CADC"]
                          // Just add this option
                        }}
                        rootProps={{ 'data-testid': '2' }}
                      />
                    </div>
                </div>

                <div class="col-xl-6 chart_cont_box">
                    <div class="tile chart-box-botL" >
                        <h6 class="chart_title">Days Open</h6>
                        <Chart
                        width={'500px'}
                        height={'300px'}
                        chartType="Bar"
                        loader={<div>Loading Chart</div>}
                        data={daysOpenTickets}
                        options={{
                            colors: ["#FB9592"]
                        }}
                        rootProps={{ 'data-testid': '2' }}
                      />
                    </div>

                </div>
            </div>
        </div>
        <div class="graph_tabs">
            <div class="row">
            <div class="col-xl-6 chart_cont_box">
                <div class="tile chart-box-botL">
                    <h6 class="chart_title">Tickets Based on Jobs</h6>
                    <Chart
                        width={'500px'}
                        height={'300px'}
                        chartType="LineChart"
                        loader={<div>Loading Chart</div>}
                        data={ticketJob}
                        options={{
                            hAxis: {
                                title: 'Job',
                            },
                            vAxis: {
                                title: 'Ticket Count',
                            },
                            colors: ["#F7D8BA"]
                        }}
                        rootProps={{ 'data-testid': '1' }}
                    />
                </div>
            </div>
            </div>
        </div>
    </div>


    const buttonTitle = readMore ? 'Load Less << ' : 'Load More >> '

    const openReadMoreGraph = (readMore) => {
        setReadMore(!readMore)                                                                                          
    }

    return (    
        <>
            <section class="report_content_block appcontent_Inner">
                <div class="admin_dashboard report_content chi_content chi_surveys" id="kpi_ticket_dashboard">
                    <div class="sub_head">
                        <h2 class="h2_title">Overview</h2>
                        <div class="head_btn_block">
                            <WeekMonthYearLists {...props} showYear='show' showWeek='show' />
                        </div>
                    </div>
                 {/*   <div class="chi_grid_view" id="kpi_theme">
                        <div class="row">
                            <div class="col-md-12 col-xl-12">
                                <div class="grid_view">
                                    <div class="grid_card tile">
                                        <h4 class="chart_title">{props.kpiTicketGraphData.open_tickets_count}</h4>
                                        <p class="cst_para">Total no. of Open Tickets</p>
                                    </div>
                                    <div class="grid_card tile">
                                        <h4 class="chart_title">{props.kpiTicketGraphData.resolved_tickets_count}</h4>
                                        <p class="cst_para">Total no. of Resolved Tickets</p>
                                    </div>
                                    <div class="grid_card tile">
                                        <h4 class="chart_title">{props.kpiTicketCountData.auto_generated_tickets_count}</h4>
                                        <p class="cst_para">Total no. Auto Generated Tickets</p>
                                    </div>
                                    <div class="grid_card tile">
                                        <h4 class="chart_title">{props.kpiTicketCountData.private_tickets_count}</h4>
                                        <p class="cst_para">Total no. of Private Tickets</p>
                                    </div>
                                    <div class="grid_card tile">
                                        <h4 class="chart_title">{props.kpiTicketCountData.public_tickets_count}</h4>
                                        <p class="cst_para">Total no. of Public Tickets</p>
                                    </div>
                                </div>
                            </div>
                        </div>

    </div> */}
                    <div class="chi_grid_view" id="kpi_theme">
                        <div class="row">
                            <div class="col-md-12 col-xl-12">
                                <div class="grid_view">
                                <div class="grid_card tile">
                                    <div className="m-auto" style={{ width: 70, height: 70 }}>
                                        <CircularProgressbarWithChildren className="red" value={props.kpiTicketGraphData.open_tickets_count} 
                                        class="geraldine_color"
                                        styles={buildStyles({ 
                                            trailColor: '#ebf0f7',
                                        })}
                                        >
                                        <div style={{ fontSize: 30}}>
                                        <strong>{props.kpiTicketGraphData.open_tickets_count}</strong>
                                      </div>
                                      </CircularProgressbarWithChildren>
                                    </div>
                                    <p class="cst_para mt-3 w-100 text-center">Total no. of Open Tickets</p>
                                    </div>
                                    <div class="grid_card tile">
                                    <div className="m-auto" style={{ width: 70, height: 70 }}>
                                        <CircularProgressbarWithChildren value={props.kpiTicketGraphData.resolved_tickets_count} 
                                       className="wattle_color"
                                        styles={buildStyles({ 
                                            trailColor: '#ebf0f7',
                                        })}
                                        >
                                        <div style={{ fontSize: 30}}>
                                        <strong>{props.kpiTicketGraphData.resolved_tickets_count}</strong>
                                      </div>
                                      </CircularProgressbarWithChildren>
                                    </div>
                                    <p class="cst_para mt-3 w-100 text-center">Total no. of Resolved Tickets</p>
                                    </div>
                                    <div class="grid_card tile">
                                    <div className="m-auto" style={{ width: 70, height: 70 }}>
                                        <CircularProgressbarWithChildren value={props.kpiTicketCountData.auto_generated_tickets_count} 
                                        className="wheatfield_color"
                                        styles={buildStyles({ 
                                            trailColor: '#ebf0f7',
                                        })}
                                        >
                                        <div style={{ fontSize: 30}}>
                                        <strong>{props.kpiTicketCountData.auto_generated_tickets_count}</strong>
                                      </div>
                                      </CircularProgressbarWithChildren>
                                    </div>
                                    <p class="cst_para mt-3 w-100 text-center">Total no. Auto Generated Tickets</p>
                                    </div>
                                    <div class="grid_card tile">
                                    <div className="m-auto" style={{ width: 70, height: 70 }}>
                                        <CircularProgressbarWithChildren value={props.kpiTicketCountData.private_tickets_count} 
                                        className="casablanca_color"
                                        styles={buildStyles({ 
                                            trailColor: '#ebf0f7',
                                        })}
                                        >
                                        <div style={{ fontSize: 30}}>
                                        <strong>{props.kpiTicketCountData.private_tickets_count}</strong>
                                      </div>
                                      </CircularProgressbarWithChildren>
                                    </div>
                                    <p class="cst_para mt-3 w-100 text-center">Total no. of Private Tickets</p>
                                    </div>
                                    <div class="grid_card tile">
                                    <div className="m-auto" style={{ width: 70, height: 70 }}>
                                        <CircularProgressbarWithChildren value={props.kpiTicketCountData.public_tickets_count} 
                                        className="pacific_blue_color"
                                        styles={buildStyles({ 
                                            trailColor: '#ebf0f7',
                                        })}
                                        >
                                        <div style={{ fontSize: 30}}>
                                        <strong>{props.kpiTicketCountData.public_tickets_count}</strong>
                                      </div>
                                      </CircularProgressbarWithChildren>
                                    </div>
                                    <p class="cst_para mt-3 w-100 text-center">Total no. of Public Tickets</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
                <div class="graph_tabs">
                    <div className="row">
                        <div class="col-xl-6 chart_cont_box">
                            <div class="tile chart-box-botL">
                                <h6 class="chart_title">Tickets Based on the Category</h6>
                                <Chart
                                    width={'500px'}
                                    height={'300px'}
                                    chartType="LineChart"
                                    loader={<div>Loading Chart</div>}
                                    data={ticketCategory}
                                    options={{
                                            colors: ['#FCBD9F'],
                                    }}
                                    rootProps={{ 'data-testid': '7' }}
                                />
                            </div>
                        </div>
                        <div class="col-xl-6 chart_cont_box">
                            <div class="tile chart-box-botL">
                                <h6 class="chart_title">Tickets Based on the Role</h6>
                                <Chart
                                    width={'500px'}
                                    height={'300px'}
                                    chartType="Bar"
                                    loader={<div>Loading Chart</div>}
                                    data={ticketRole}
                                    options={{
                                        // Material design options
                                        colors: ['#2DBBE3'],
                                        chart: {
                                        },
                                    }}
                                    // For tests
                                    rootProps={{ 'data-testid': '2' }}
                                />
                            </div>
                        </div>
                    </div>
                </div>



                <div class="graph_tabs">
                    <div class="row">
                        <div class="col-xl-12 chart_cont_box">
                            <div class="tile chart-box-botL">
                                <h6 class="chart_title">Tickets Status</h6>
                                <Chart
                                    width={'1050px'}
                                    height={'300px'}
                                    chartType="Bar"
                                    loader={<div>Loading Chart</div>}
                                    data={ticketChart}
                                    options={{
                                        chartArea: { width: '80%' },
                                        hAxis: {
                                            title: 'Time',
                                        },
                                        vAxis: {
                                            title: 'Popularity',
                                        },
                                        series: {
                                            1: { curveType: 'function' },
                                        },
                                        colors: ['#41D2F2', '#72C0A0']
                                    }}
                                    rootProps={{ 'data-testid': '2' }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="readmore_graph text-center">
                        {readMore && loadMoreGraphList}
                        <button className="btn cst_btn btn_danger mr-2 mb-4 account_btn" onClick={() => openReadMoreGraph(readMore)}>{buttonTitle}</button>
                    </div>

                </div>
            </section>
        </>
    )
}

const mapStateToProps = (state) => {

}
KpiTicketDashboard = reduxForm({
    form: 'searchKpiTicketDashboard',
    validate,
    enableReinitialize: true
})(KpiTicketDashboard);

KpiTicketDashboard = connect(mapStateToProps)(KpiTicketDashboard)


export default KpiTicketDashboard;