import React from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Holidays from '../../container/Peassure/Pegassure';
import NewAreaType from './AreaTypes/newAreaType';
import AreaTypes from '../../container/Insights/AreaTypeContainer';
import Floors from '../../container/Insights/FloorContainer';
import NewFloor from './Floor/NewFloor';
import NewHoliday from '../../../adminOrSuperAdminOrRequestor/component/Holidays/NewHolidayModal';
import NewDeficiency from './Deficiencies/newDeficiency';
import DeficiencyContainer from '../../container/Insights/DeficiencyContainer';

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper
    // width: 1000,
  }
}));

const Settings = (props) => {

  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const [isAreaType, setIsAreaType] = React.useState(true);
  const [isDeficiency, setIsDeficiency] = React.useState(false);
  const [isFloor, setIsFloor] = React.useState(false);
  const [isHolidays, setIsHolidays] = React.useState(false);

  const handleChange = (event, newValue) => {
    if (newValue === 0) {
      setIsAreaType(true);
      setIsDeficiency(false);
      setIsFloor(false);
      setIsHolidays(false);
    } else if (newValue === 1) {
      setIsAreaType(false);
      setIsFloor(false);
      setIsHolidays(false);
      setIsDeficiency(true);
    } else if (newValue === 2) {
      setIsAreaType(false);
      setIsDeficiency(false);
      setIsFloor(true);
      setIsHolidays(false);
    }
    else if (newValue === 3) {
      setIsAreaType(false);
      setIsDeficiency(false);
      setIsHolidays(true);
      setIsFloor(false);
    }
    setValue(newValue);
  }

  const handleChangeIndex = (index) => {
    setValue(index);
  }


  return (
    <>
      <main className="app-content wraper_content approve_screen approve_Quotes_sec">
        <div className="inspection_content_sec appcontent_Inner">
          <div className={classes.root}>
            <AppBar position="static" color="default">
              <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"
                aria-label="full width tabs example"
              >
                <Tab label={"Area Types(" + props.totalAreaTypes+ ")" } />
                <Tab label={"Deficiencies(" + props.totalDeficiencies+ ")" } />
                <Tab label={"Floors(" + props.totalFloors+ ")" } />
                <Tab label={"Holidays(" + props.totalHolidays+ ")" } />
                {/* <Tab label="Item Three" /> */}
              </Tabs>
            </AppBar>
            <SwipeableViews
              axis={theme.direction === "rtl" ? "x-reverse" : "x"}
              index={value}
              onChangeIndex={handleChangeIndex}
            >
              <TabPanel value={value} index={0} dir={theme.direction}>
                {isAreaType ? <AreaTypes /> : null}
              </TabPanel>
              <TabPanel value={value} index={1} dir={theme.direction}>
                {isDeficiency ? <DeficiencyContainer /> : null}
              </TabPanel>
              <TabPanel value={value} index={2} dir={theme.direction}>
                {isFloor ? <Floors /> : null}
              </TabPanel>
              <TabPanel value={value} index={3} dir={theme.direction}>
                {isHolidays ? <Holidays /> : null}
              </TabPanel>
            </SwipeableViews>
          </div>
          {(isAreaType && props.isOpenModel) ?
            <NewAreaType
              onSubmit={props.handelAreaTypesSubmit}
              closePopup={props.closePopup}
              isLoading={props.isLoading}
              isEdit={props.isEdit}
              emptyObjects={props.emptyObjects}
            /> : null}
          {/* {(isAreaType && props.openDeleteModel) ?

           : null
           } */}
           
           {(isDeficiency && props.isOpenModel) ?
            <NewDeficiency
              {...props}
              onSubmit={props.handelDeficiencySubmit}
              closePopup={props.closePopup}
              isLoading={props.isLoading}
              addQuestionToState={props.addQuestionToState}
              addUpdatedQuestionToState={props.addUpdatedQuestionToState}
              isEdit={props.isEdit}
              emptyObjects={props.emptyObjects}
              clearDeficiency={props.clearDeficiency}
              deletedObject={props.deletedObject}
              clearState={props.clearState}
            /> : null}

          {(isFloor && props.isOpenModel) ?
            <NewFloor
              onSubmit={props.handelFloorSubmit}
              closePopup={props.closePopup}
              isEdit={props.isEdit}
              isLoading={props.isLoading}
            /> : null}
          {
            props.isOpenModel ?
              <NewHoliday
                onSubmit={props.addNewHoliday}
                {...props} /> : null
          }
        </div>
      </main>

    </>
  )
}

export default Settings;