import React, { useState, useRef, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import AssetsFormSkeleton from '../../../../Inventory/Assets/NewAssetsForm/AssetsFormSkeleton'
import { Input } from '../../../../UI/InputElement/reactFormHookInputField'
import withFormHoc from '../../../../../../hoc/withReactHookForm/withReactHookForm'

function PerformanceAuditForm(props) {
  const { performance_audit_questions, isLoading, isPerformanceAuditForm, singlePerformanceAuditData } = useSelector((state) => state.auditsReducer)
  const { jobList } = useSelector((state) => state.FormAuditModReducer)
  
  const titleNameHandler = (title) => {
    return title.replace(',', '')
  }

  const getJobList = (job) => {
    if (job && job.length > 0) {
        return job && job.map((data) => {
            return {
                value: data.id,
                label: data.name,
            }
        })
    } else {
        return []
    }
};

const [listOfJob, setJobList] = useState(getJobList(jobList))

useEffect(()=>{
   setJobList(getJobList(jobList))
},[jobList])

  useEffect(() => {
    if ((props.isEdit || props.isView)) {
      if (performance_audit_questions.length && singlePerformanceAuditData.auditorSections) {
        const defaultValues = performance_audit_questions.map((item, mainIndex) => {
          const auidtObj = singlePerformanceAuditData.auditorSections.find((value) => {
            return value.auditor_main_table_id == item.id
          })

          if (auidtObj) {
            props.setAuditorId((pre) => [...pre, auidtObj])
          }
          return {
            mainTableId: item.id,
            [`${titleNameHandler(item.title)}`]: {
              questions: item.questions.map((value, subIndex) => {
                if (auidtObj) {
                  const result = auidtObj.auditorSectionsLineItems.find((line) => {
                    return line.auditor_main_question_id == value.id
                  })

                  if (result) {
                    return {
                      id: result.id,
                      auditor_sections_id: result.auditor_sections_id,
                      mainQuestionId: value.id,
                      [`isChecked${subIndex + 1}`]: result.is_checked?.toString(),
                      [`notes${subIndex + 1}`]: result.notes === null ? '' : result.notes,
                    }
                  } else {
                    return {
                      mainQuestionId: value.id,
                      [`isChecked${subIndex + 1}`]: "",
                      [`notes${subIndex + 1}`]: "",
                    }
                  }
                } else {
                  return {
                    mainQuestionId: value.id,
                    [`isChecked${subIndex + 1}`]: "",
                    [`notes${subIndex + 1}`]: ""
                  }
                }
              })
            },
          }
        })
        setForm(defaultValues)
      }
    }
    else {
      if (performance_audit_questions.length) {
        const defaultValues = performance_audit_questions.map((item, i) => {
          return {
            mainTableId: item.id,
            [`${titleNameHandler(item.title)}`]: {
              questions: item.questions.map((value, i) => {
                return {
                  mainQuestionId: value.id,
                  [`isChecked${i + 1}`]: "",
                  [`notes${i + 1}`]: ""
                }
              })
            },
          }
        })
        setForm(defaultValues)
      }
    }
  }, [performance_audit_questions, singlePerformanceAuditData])

  const history = useHistory()
  const [page, setPage] = useState(1)
  const [form, setForm] = useState(null)
  console.log('form', form);
  const scrollRef = useRef()

  const scrollTop = () => {
    const { current } = scrollRef
    if (current !== null) {
      current.scrollIntoView({ behavior: "smooth", block: "center" })
    }
  }


  const handleSubmit = (e) => {
    e.preventDefault()
    
    props.onSubmit(form)
  }

  const handleChange = (e) => {
    let name = e.target.name
    const nameParts = name.split(".")
    const removeString = nameParts[1]
    const result = removeString.replace("questions[", "").replace("]", "");
    let value = e.target.value
    const values = [...form]
    const updatedValues = values.map((item) => {
      const lineItems = Object.keys(item)
      if (lineItems[1] === nameParts[0]) {
        return {
          ...item,
          [nameParts[0]]: {
            questions: item[nameParts[0]].questions.map((lineItems, i) => {
              if (parseInt(result) === i) {
                return {
                  ...lineItems,
                  [nameParts[2]]: value
                }
              } else {
                return lineItems
              }
            })
          }
        }
      } else {
        return item
      }

    })
    setForm(updatedValues);
  };

  if (isPerformanceAuditForm) {
    return (
      <AssetsFormSkeleton />
    )
  }

  return (
    <main class="app-content wraper_content chart_section">
      <section class="teammanage_formmain">
        <form class="performaudit_steps" 
        onSubmit={props.handleSubmit((data) => {
      
      props.onSubmit({ form:form, account_id: data.account_id })
    })}>
          <div class="container">

            <div class="row" >
              <div class="col-md-12 col-lg-12">


                <div class="tm_form_wrapper">
                  {/* Form */}
                  <div class="tm_form_wrap">
                    <div class="tm_form_innner">
                      <div class="form">
                        <div class="row">
                          {/* <div class="col-md-6 col-sm-12" style={{ position: "relative" }}> */}
                            {/* {
                              !props?.isView ? <button className='btn ' style={{ position: "absolute", top: "-1px", left: "6rem", zIndex: 1, padding: "0px 1rem", backgroundColor: "#863035" }} type='button' onClick={handleRest}>
                               
                                <span style={{ fontSize: "11px", fontWeight: "bolder", color: "white" }}>Reset</span>

                              </button> : null
                            } */}

                            {/* <div class="form-group mb-0">



                              <Input
                                type="inputSelect"
                                required
                                selectLabel="Customer"
                                control={props.control}
                                controller={props.controller}
                                selectName={`customer_id`}
                                id={`customer_id`}
                                isStar={true}
                                placeholder="Select Customer"
                                selectOptions={listOfCustomer}
                                formControlProps={props.register(
                                  `customer_id`,
                                  {
                                    required: "This field is required",
                                    maxLength: 80,
                                  }
                                )}
                                selectClasses="form-select"
                                validationErrorMessage={
                                  props.errors?.customer_id
                                    ?.message ?? ""
                                }
                                disabled={props?.isView}

                              />

                            </div> */}


                          {/* </div> */}
                          {/* <div class="col-md-6 col-sm-12 ">
                            <div class="form-group">

                              <Input
                                type="text"
                                id={`info`}
                                name={`info`}
                                isStar={true}
                                formControlClasses="form-control"
                                placeholder="Info"
                                required
                                label="Info"
                                formControlProps={props.register(`info`, {
                                  required: "This field is required",
                                  // pattern: {
                                  //   value:/^[0-9]*$/ ,
                                  //   message:"Must Be a number"
                                  // },
                                  maxLength: 80,
                                })}
                                validationErrorMessage={
                                  props.errors?.info?.message ?? ""
                                }
                                disabled={props?.isView}
                                isIcon={false}
                              />

                            </div>
                          </div> */}

                          <div class="col-md-6 col-sm-12">
                            <div class="form-group">


                              <Input
                                type="inputSelect"
                                required
                                selectLabel="Job"
                                control={props.control}
                                controller={props.controller}
                                selectName={`account_id`}
                                id={`account_id`}
                                isStar={true}
                                placeholder="Job"
                                selectOptions={listOfJob}
                                formControlProps={props.register(
                                  `account_id`,
                                  {
                                    required: "This field is required",
                                    maxLength: 80,
                                  }
                                )}
                                selectClasses="form-select"
                                validationErrorMessage={
                                  props.errors?.account_id
                                    ?.message ?? ""
                                }
                                disabled={props?.isView}

                              />


                            </div>
                          </div>

                          {/* <div class="col-md-6 col-sm-12">
                            <div class="form-group">

                              <Input
                                type="text"
                                id={`location`}
                                name={`location`}
                                isStar={true}
                                formControlClasses="form-control"
                                placeholder="Location"
                                required
                                label="Location"
                                formControlProps={props.register(`location`, {
                                  required: "This field is required",
                                  // pattern: {
                                  //   value:/^[0-9]*$/ ,
                                  //   message:"Must Be a number"
                                  // },
                                  maxLength: 80,
                                })}
                                validationErrorMessage={
                                  props.errors?.location?.message ?? ""
                                }
                                disabled={props?.isView}
                                isIcon={false}
                              />

                            </div>
                          </div> */}

                        </div>
                      </div>
                    </div>
                  </div>






                  {/* <div class="row">
                    <div class="col-sm-12">
                      <div class="submitcancel_btn">
                        <button type="button" class="btn cst_btn btn-outline-secondary  cancel_btnnew" onClick={props?.resetForm}>
                          Cancel
                        </button>
                        {
                          !props?.isView ? <button type="submit" className={isLoading ? "btn cst_btn btn_danger btn-wait" : "btn cst_btn btn_danger"}>
                            {isLoading ? "" : isEdit ? "Update" : "Submit"}
                          </button> : null
                        }
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>


            </div>

            <div class="row">
              <div class="col-md-12">
                <div class="msform">
                  <ul id="progressbar">
                    <li class="active">Step 1</li>
                    <li class={page >= 2 ? "active" : ""}>Step 2</li>
                    <li class={page >= 3 ? "active" : ""}>Step 3</li>
                    <li class={page >= 4 ? "active" : ""}>Step 4</li>
                    <li class={page >= 5 ? "active" : ""}>Step 5</li>
                    <li class={page >= 6 ? "active" : ""}>Step 6</li>
                    <li class={page >= 7 ? "active" : ""}>Step 7</li>
                    <li class={page >= 8 ? "active" : ""}>Step 8</li>
                    <li class={page >= 9 ? "active" : ""}>Step 9</li>
                    <li class={page >= 10 ? "active" : ""}>Step 10</li>
                    <li class={page >= 11 ? "active" : ""}>Step 11</li>
                  </ul>
                </div>
              </div>
            </div>
            <div >
              <div class="row">

                <div class="col-lg-12">
                  <div class="col-lg-12 team_manage_head ">
                    <div className='row mb-5 '>
                      <div class="col-lg-4 text-left" style={{ textAlign: "center", alignItems: "center", }}>
                        {page !== 1 ? <button type="button" class="btn theme_def_btn2" style={{ marginTop: "1.5rem" }} disabled={page === 1} onClick={() => { setPage((pre) => pre - 1); scrollTop() }}> <span><img src="/images/icons/leftcaret.svg" alt="leftcaret" /></span> Prev</button> : <p></p>}
                      </div>
                      <h1 class="tm_top_head col-lg-4" ref={scrollRef}>{performance_audit_questions && performance_audit_questions[page - 1]?.title}</h1>
                      <div class="col-lg-4 text-right" style={{ textAlign: "center", alignItems: "center", }} >
                        {page !== 11 && <button type="button" class="btn theme_def_btn1" style={{ marginTop: "1.5rem" }} disabled={page === 11} onClick={() => { setPage((pre) => pre + 1); scrollTop() }}> Next <span><img src="/images/icons/rightcaret.svg" alt="leftcaret" /></span></button>}
                      </div>
                    </div>



                  </div>
                </div>

                <div class="col-lg-12">
                  <div class="tm_form_wrapper">
                    <div class="tm_form_wrap">
                      <div class="tm_form_innner">
                        <div class="selfaudit_title">
                          <h2>
                            {performance_audit_questions && performance_audit_questions[page - 1]?.description}
                          </h2>
                        </div>
                        {performance_audit_questions && performance_audit_questions[page - 1]?.questions.map((item, i) => (
                          <div key={i} class="selfaudit_checkpoint">
                            <p>{item.question}</p>
                            <div class="selfaudit_checkboxwrap">
                              <div class="custom_checkbox">
                                <div class="form-group">
                                  <input
                                    type="radio"
                                    id={`Correct_${i + 1}`}
                                    name={`${titleNameHandler(performance_audit_questions && performance_audit_questions[page - 1]?.title)}.questions[${i}].isChecked${i + 1}`}
                                    disabled={props.isView}
                                    onChange={handleChange}
                                    value={'true'}
                                    checked={form ? form[page - 1][`${titleNameHandler(performance_audit_questions && performance_audit_questions[page - 1]?.title)}`].questions[i][`isChecked${i + 1}`] === 'true' : ''}
                                  />
                                  <label htmlFor={`Correct_${i + 1}`}>Yes</label>
                                </div>
                              </div>
                              <div class="custom_checkbox">
                                <div class="form-group">
                                  <input
                                    type="radio"
                                    id={`InCorrect_${i + 1}`}
                                    name={`${titleNameHandler(performance_audit_questions && performance_audit_questions[page - 1]?.title)}.questions[${i}].isChecked${i + 1}`}
                                    disabled={props.isView}
                                    onChange={handleChange}
                                    value={'false'}
                                    checked={form ? form[page - 1][`${titleNameHandler(performance_audit_questions && performance_audit_questions[page - 1]?.title)}`].questions[i][`isChecked${i + 1}`] === 'false' : ''}
                                  />
                                  <label htmlFor={`InCorrect_${i + 1}`}>No</label>
                                </div>
                              </div>
                              <div class="custom_notesbox">
                                <div class="form-group mb-0">
                                  <input
                                    type="text"
                                    placeholder={'Notes'}
                                    value={form ? form[page - 1][`${titleNameHandler(performance_audit_questions && performance_audit_questions[page - 1]?.title)}`].questions[i][`notes${i + 1}`] : ''}
                                    name={`${titleNameHandler(performance_audit_questions && performance_audit_questions[page - 1]?.title)}.questions[${i}].notes${i + 1}`}
                                    disabled={form && !form[page - 1][`${titleNameHandler(performance_audit_questions && performance_audit_questions[page - 1]?.title)}`].questions[i][`isChecked${i + 1}`] || props.isView}
                                    onChange={handleChange}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='row mb-5'>
                <div class="col-lg-2">
                  {page !== 1 && <button type="button" class="btn theme_def_btn2" disabled={page === 1} onClick={() => { setPage((pre) => pre - 1); scrollTop() }}> <span><img src="/images/icons/leftcaret.svg" alt="leftcaret" /></span> Prev</button>}
                </div>
                <div class="col-lg-8 text-center">
                  {
                    page === 11 && <>
                      <button onClick={() => history.goBack()} type="button" className="btn cst_btn btn-outline-secondary  cancel_btnnew">
                        Cancel
                      </button>
                      {
                        !props?.isView ? <button type="submit" style={{ marginLeft: '15px' }} className={isLoading ? "btn cst_btn btn_danger btn-wait" : "btn cst_btn btn_danger"}>
                          {isLoading ? "" : props.isEdit ? "Update" : "Submit"}
                        </button> : null
                      }
                    </>
                  }
                </div>
                <div class="col-lg-2 text-right">
                  {page !== 11 && <button type="button" class="btn theme_def_btn1" disabled={page === 11} onClick={() => { setPage((pre) => pre + 1); scrollTop() }}> Next <span><img src="/images/icons/rightcaret.svg" alt="leftcaret" /></span></button>}
                </div>
              </div>
            </div>
          </div>
        </form>
      </section>
    </main>
  )
}

export default withFormHoc(PerformanceAuditForm)