import React from 'react'
import withFormHoc from '../../../../../../hoc/withReactHookForm/withReactHookForm'
import { useFieldArray } from 'react-hook-form'
import TrainingForm from './TrainingForm'
import AssetsFormSkeleton from '../../../../Inventory/Assets/NewAssetsForm/AssetsFormSkeleton'
import { useSelector } from 'react-redux'
import { EDIT, VIEW } from '../../../../../../utility/constants/constants'
import { Views } from 'react-big-calendar'

function TrainingLogForm(props) {
   const {isUpdateTrainingLog,trainingLog,isLoading}=useSelector((state)=>state.FormAuditModReducer)
   const {fields,append,remove}=useFieldArray({control:props.control,name:"data"})
   
   
   
  const defaultTaskData={
    verification_code:"",
    date:"",
    title:"",
    location:"",
    instructor:""
  }
  const addTaskToList=(e)=>{
    e.preventDefault();
    append(defaultTaskData)
    }

    const removeFromTaskList=(index)=>(e)=>{
      e.preventDefault();
      remove(index)
      }
    
      if (isUpdateTrainingLog) {
        return (
            <AssetsFormSkeleton />
        )
    }
    return (
      <main class="app-content wraper_content chart_section">
        
      <section class="teammanage_formmain">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="team_manage_head">
                <h1 class="tm_top_head">{props?.isEdit ? `${EDIT} Training Log` : props?.isView ? `${VIEW} Training Log` : "Training Log"}</h1>
              </div>
            </div>
          <form class="col-lg-12"  noValidate onSubmit={props.handleSubmit((data) => {
            props.onSubmit(data)
          })}>
              <div class="tm_form_wrapper">
                {
                  fields?.map((field,index)=>(
                    <TrainingForm 
                     isView={props?.isView} key={field.id} controller={props.controller} field={field} control={props.control}  index={index} register={props.register} errors={props.errors} removeFromTaskList={removeFromTaskList} 
                    />
                   ))
                } 

              
                {
                  !props?.isEdit && !props?.isView ? <div class="action_button" >
                  <span onClick={addTaskToList}>+ Add More</span>
                </div> : null
                }
                
                
                <div class="row">
                  <div class="col-sm-12">
                    <div class="submitcancel_btn">
                    <button type="button" class="btn cst_btn btn-outline-secondary  cancel_btnnew" onClick={props?.resetForm}>
                      Cancel
                    </button>
                      {
                        !props?.isView ? <button type="submit" className={isLoading ? "btn cst_btn btn_danger btn-wait" : "btn cst_btn btn_danger"}> 
                        {isLoading ? ""  : props.isEdit ?  "Update"  : "Submit"}
                      </button> : null
                      }
                      
                    </div>
                  </div>
                </div>
                </div>
          </form>
          </div>
        </div>  
      </section>
    </main>
  )
}

const TrainingLogFormHOC= withFormHoc(TrainingLogForm)
export default TrainingLogFormHOC
