import React, { Component } from 'react';
import ClockInClockOutScreenComponent from '../../../component/TimeTrackerModuleComponent/ClockInClockOutScreenComponent/ClockInClockOutScreenComponent';
import { connect } from 'react-redux';
import * as actions from '../../../../redux/actions/index';
import { tzUTCDateTimeConverterTool_V1 } from '../../../../utility/TimezoneOperations/timezoneUtility';
import storage from '../../../../utility/storage';
import { routes } from '../../../../utility/constants/constants';
import errorHandler from '../../../../utility/errorHandler/errorHandler';
import queryString from 'query-string';
import SuccessAlert from '../../../../utility/successAlert/successAlert';
const cloneDeep = require('clone-deep');
var momentTZ = require('moment-timezone');


class ClockInClockOutScreenContainer extends Component {
    constructor(props) {
        super(props);

    }

    componentDidMount() {

        let isClockInEngaged = storage.get('isClockInEngaged') ? storage.get('isClockInEngaged') : false;
        
            this.props.flUserShiftClockChecker({ data: { current_time: tzUTCDateTimeConverterTool_V1(momentTZ().format('YYYY-MM-DDTHH:mm:ss').split('T')[0], momentTZ().format('YYYY-MM-DDTHH:mm:ss').split('T')[1], 'none') } });
        

    }

    componentWillUnmount() {
        this.props.resetTimeTrackerObjects();
    }

    handleShiftClockChecker = () => {
        let setData = { data: {} };

        let fetchedCurrDateTime = momentTZ(new Date()).format('MM-DD-YYYYTHH:mm:ss').split('T');
        let currentTime = tzUTCDateTimeConverterTool_V1(fetchedCurrDateTime[0], fetchedCurrDateTime[1], 'none');

        setData.data.current_time = currentTime;
        this.props.flUserShiftClockChecker(setData);
    }
    // function for checkedIn
    handleShiftClockCheckIn = (values, setAccountId) => {
        let setData = { data: { addresses_attributes: {} } };

        let fetchedCurrDateTime = momentTZ(new Date()).format('MM-DD-YYYYTHH:mm:ss').split('T');
        let checkInTime = tzUTCDateTimeConverterTool_V1(fetchedCurrDateTime[0], fetchedCurrDateTime[1], 'none');

        if(!values.account_id){
            errorHandler("Please select a Job")
            return
        }

        setData.data.check_int_at = checkInTime;
        setData.data.account_id = values.account_id
        setData.data.shift_detail_id = values.shift_detail_id

        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    setData.data.addresses_attributes.latitude = latitude;
                    setData.data.addresses_attributes.longitude = longitude;
                    this.props.flUserShiftClockCheckIn(setData, setAccountId);
                },
                (error) => {
                    errorHandler("Please turn on your location");
                }
            );
        }
    }
    // function for handling next Shift Job 
    handleShiftNextJob = (values, setAccountId) => {
        let setData = { data: { addresses_attributes: {} } };

        let fetchedCurrDateTime = momentTZ(new Date()).format('MM-DD-YYYYTHH:mm:ss').split('T');
        let checkInTime = tzUTCDateTimeConverterTool_V1(fetchedCurrDateTime[0], fetchedCurrDateTime[1], 'none');

        setData.data.punch_in_at = checkInTime;
        setData.data.account_id = values.account_id

        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    setData.data.addresses_attributes.latitude = latitude;
                    setData.data.addresses_attributes.longitude = longitude;
                    const queryParams = queryString.stringify({type:'ShiftJobSwitch', shift_detail_id : values.shift_detail_id  });
                    this.props.history.push({pathname : routes.FRONTLINE_FACE_VERIFICATION, search : `?${queryParams}`, state : {setData, shift_detail_id : values.shift_detail_id }})
                    // this.props.flUserShiftNextJob(setData, values.shift_detail_id, setAccountId);
                },
                (error) => {
                    errorHandler("Please turn on your location");
                }
            );
        }
    }
    //Function For Handling Meal Break CheckIn
    handleShiftMealBreakCheckIn = (dateObject, id,shift_id,type) => {
        let setData = { data: {} };
        let start, end = null

        let fetchedCurrDateTime = momentTZ(new Date()).format('MM-DD-YYYYTHH:mm:ss').split('T');

        if (dateObject && dateObject.start == null) {
            start = tzUTCDateTimeConverterTool_V1(fetchedCurrDateTime[0], fetchedCurrDateTime[1], 'none');
            setData.data.start = start;
        } else {
            end = tzUTCDateTimeConverterTool_V1(fetchedCurrDateTime[0], fetchedCurrDateTime[1], 'none');
            setData.data.end = end;
        }
        const queryParams = queryString.stringify({type:type, shift_detail_id : shift_id  });
        this.props.history.push({pathname : routes.FRONTLINE_FACE_VERIFICATION, search : `?${queryParams}`, state : {setData, shift_detail_id : shift_id,id }})

        //this.props.flUserMealBreakSession(setData, id);
    }
    // Function For Handling MealBreakOut Flow and Modal 
    handleShiftMealBreakCheckOutModal = (shift_id) => {
        
        const queryParams = queryString.stringify({type:'MealOut', shift_detail_id : shift_id  });
        this.props.history.push({pathname : routes.FRONTLINE_FACE_VERIFICATION, search : `?${queryParams}`, state : { shift_detail_id : shift_id,}})

        //handleFrequentTimeTracker_openModal();
    }

    handleCheckOutRecogonization= (shiftId,clockOutTime,clockedInShiftData) => {
        //here we initiate alert message for Initating ClockOut.
        SuccessAlert("Please complete Facial Recognition to initiate Clock-Out.")
        const queryParams = queryString.stringify({type:'clockOut', shift_detail_id : shiftId  });
        this.props.history.push({pathname : routes.FRONTLINE_FACE_VERIFICATION, search : `?${queryParams}`, state : { shift_detail_id : shiftId,clockOutTime,clockedInShiftData}})

        //handleFrequentTimeTracker_openModal();
    }


    handleMyShiftClick = () => {
        this.props.history.push(`/shift-schedule/preview-shift-schedule/${this.props.clockedInShiftData.id}`);
    }

    handleMyTicketsClick = (accountId) => {
        this.props.history.push({pathname : routes.FLUSER_MYTICKET, state : {jobId : accountId}});
    }

    render() {
        return (
            <>

                <ClockInClockOutScreenComponent
                    {...this.props}
                    handleShiftClockChecker={this.handleShiftClockChecker}
                    handleShiftClockCheckIn={this.handleShiftClockCheckIn}
                    handleShiftMealBreakCheckIn={this.handleShiftMealBreakCheckIn}
                    handleMyShiftClick={this.handleMyShiftClick}
                    handleMyTicketsClick={this.handleMyTicketsClick}
                    handleShiftNextJob={this.handleShiftNextJob}
                    handleShiftMealBreakCheckOutModal={this.handleShiftMealBreakCheckOutModal}
                    handleCheckOutRecogonization={this.handleCheckOutRecogonization}
                />

            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.authReducer.user,
        isLoading: state.TimeTrackerModReducer.isLoading,
        isMealPenaltyLoader: state.TimeTrackerModReducer.isMealPenaltyLoader,
        isClockInShiftAvailable: state.TimeTrackerModReducer.isClockInShiftAvailable,
        clockedInShiftData: state.TimeTrackerModReducer.clockedInShiftData,
        clockInCheckedInTime: state.TimeTrackerModReducer.clockInCheckedInTime, // Checked In Time
        isShiftMealBreakTriggered: state.TimeTrackerModReducer.isShiftMealBreakTriggered,
        shiftMealBreakInfo: state.TimeTrackerModReducer.shiftMealBreakInfo,
        isTimeTrackerModalOpen: state.TimeTrackerModReducer.isTimeTrackerModalOpen,
        isNextJobLoading: state.TimeTrackerModReducer.isNextJobLoading,
        triggerClockInTimer: state.TimeTrackerModReducer.triggerClockInTimer,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        resetTimeTrackerObjects: () => dispatch(actions.resetTimeTrackerObjects()),
        flUserShiftClockChecker: (data) => dispatch(actions.flUserShiftClockChecker(data)),
        flUserShiftClockCheckIn: (data, setAccountId) => dispatch(actions.flUserShiftClockCheckIn(data, setAccountId)),
        flUserMealBreakSession: (body, id) => dispatch(actions.flUserMealBreakSession(body, id)),
        getMissedMealPenaltyBreaks: (shiftDetailId, currentTime, clockInData) => dispatch(actions.getMissedMealPenaltyBreaks(shiftDetailId, currentTime, clockInData)),
        handleFrequentTimeTracker_openModal: (data) => dispatch(actions.handleFrequentTimeTracker_openModal(data)),
        handleFrequentTimeTracker_closeModal: (data) => dispatch(actions.handleFrequentTimeTracker_closeModal(data)),
        flUserShiftNextJob: (data, shiftId, setAccountId) => dispatch(actions.flUserShiftNextJob(data, shiftId, setAccountId)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ClockInClockOutScreenContainer);