import SuccessAlert from '../../../../utility/successAlert/successAlert';
import ScanningSuccessAlert from '../../../../utility/ScanningAlert/scanningSuccessAlert';
import { routes } from '../../../../utility/constants/constants';
import { formAuditActionTypes } from './teamManagementTypes';
import * as API from '../../../../API/FormAuditAPI/teamManagementAPI';
import history from '../../../../router/history';
import cloneDeep from 'clone-deep';
import { closeModel, makeFirstLetterCapital } from '../../../../utility/utility';
import storage from '../../../../utility/storage';

export const teamManagementTabClicked = () => {
    return {
        type: formAuditActionTypes.TEAM_MANAGEMENT_TAB_CLICKED,
    };
};

export const os1Clicked = () => {
    return {
        type: formAuditActionTypes.OS1_TAB_CLICKED,
    };
};


export const getSingleSupervisorData = (id) => {
    return (dispatch, getState) => {
        
        dispatch({
            type: formAuditActionTypes.GET_SINGLE_SUPERVISOR,
            payload: API.getSingleSupervisor(id) //146
                .then((response) => {
                    if (response.data.success) {
                        // we need to return data here 
                       return response.data.data
                        
                    };
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};



export const getSingleTrainingLogDetails = (id) => {
    return (dispatch, getState) => {
        
        dispatch({
            type: formAuditActionTypes.GET_SINGLE_TRAINING_LOG,
            payload: API.getSingleTraingLogApi(id) 
                .then((response) => {
                    
                    if (response.data.success) {
                        // we need to return data here 
                        
                        return response.data.data
                        
                    };
                })
                .catch((error) => {
                    
                    console.log(error);
                }),
        });
    };
};


export const getSingleManagerDetails = (id) => {
    return (dispatch, getState) => {
        
        dispatch({
            type: formAuditActionTypes.GET_SINGLE_MANAGER,
            payload: API.getSingleManagerSummary(id) //146
                .then((response) => {
                    
                    if (response.data.success) {
                        // we need to return data here 
                        return response.data.data
                    
                        
                    };
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};




export const getAllJob = () => {
    return (dispatch, getState) => {
        dispatch({
            type: formAuditActionTypes.GET_ALL_JOB,
            payload: API.getAllJobApi() //146
                .then((response) => {
                     
                    if (response.data.success) {
                        // we need to return data here 
                      
                       return response.data
                    }
                }
                )
        })

    }
}

export const getCustomerInfos = (param) => {
    return (dispatch, getState) => {
        
        dispatch({
            type: formAuditActionTypes.GET_CUSTOMER_INFO,
            payload: API.getCustomerInfoAPI(param)
                .then((response) => {
                      
                    if (response.data.success) {
                        // we need to return data here 
                       return response.data
                    }   
                }
                )
        })

    }
}


// export const addTeamManagementSummary=(body)=>{
//         
//         return (dispatch, getState) => {
//             dispatch({
//                 type: formAuditActionTypes.ADD_TEAM_MANAGEMENT,
//                 payload: API.addTeamManagementApi(body)
//                     .then((response) => {
//                         if (response.data.success) {
//                             SuccessAlert('Checklist log created successfully')
//                             history.push(routes.TEAM_CHECKLIST_MANAGER_SUMMARY);
//                             return response.data;
//                         }
//                     })
//                     .catch((error) => {
//                         console.log(error);
//                     }),
//             });
//         };
//     }
                        

export const getManagersList = (params) => {
    return (dispatch, getState) => {
        dispatch({
            type: formAuditActionTypes.GET_MANAGERS_LIST,
            payload: API.getManagersList(params)
                .then((response) => {
                        if (response.data.success) {
                        return {
                            managersList: response.data.data,
                        };
                    };
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
}

export const getEmployeeList = (params) => {
    return (dispatch, getState) => {
        
        dispatch({
            type: formAuditActionTypes.GET_EMPLOYEE_LIST,
            payload: API.getManagersList(params)
                .then((response) => {
                    
                    if (response.data.success) {
                        
                        return {
                            employeeList: response.data.data,
                        };
                    };
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
}



export const editChecklistLog = (params) => {
    return (dispatch, getState) => {
        dispatch({
            type: formAuditActionTypes.EDIT_CHECKLIST_LOG,
            payload: API.editChecklistLog(params)
                .then((response) => {
                    if (response.data.success) {
                        SuccessAlert('Checklist log updated successfully')
                        history.push(routes.TEAM_CHECKLIST_LOG);
                        return response.data;
                    };
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
}

export const getSingleChecklistLog = (id) => {
    return (dispatch, getState) => {
        dispatch({
            type: formAuditActionTypes.GET_SINGLE_CHECKLIST_LOG,
            payload: API.getSingleChecklistLog(id)
                .then((response) => {
                    if (response.data.success) {
                        return {
                            checklistLog : response.data.data,
                        };
                    };
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
}

export const addMoreChecklistLogClicked = () => {
    return {
        type: formAuditActionTypes.ADD_MORE_CHECKLIST_LOG_CLICKED,
    };
};



export const addTrainingLog=(body)=>{
    
    return (dispatch, getState) => {
        dispatch({
            type: formAuditActionTypes.ADD_TRAINING_LOG,
            payload: API.addTrainingLogApi(body)
                .then((response) => {
                    
                    if (response.data.success) {
                        SuccessAlert('Training Log  created successfully')
                        history.push(routes.TRAINING_LOG);
                        return response.data;
                    }
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
}


export const addTeamManagementSummary=(body)=>{
    
    return (dispatch, getState) => {
        dispatch({
            type: formAuditActionTypes.ADD_TEAM_MANAGEMENT,
            payload: API.addTeamManagementApi(body)
                .then((response) => {
                    if (response.data.success) {
                        SuccessAlert('Team Checklist Manager Summary  created successfully')
                        history.push(routes.TEAM_CHECKLIST_MANAGER_SUMMARY);
                        return response.data;
                    }
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
}

export const addTeamSupervisorSummary=(body)=>{
    
    return (dispatch, getState) => {
        dispatch({
            type: formAuditActionTypes.ADD_CHECKLIST_SUPERVISOR,
            payload: API.addTeamSupervisorApi(body)
                .then((response) => {
                    
                    if (response.data.success) {
                        SuccessAlert('Team Checklist Supervisor Summary Created successfully')
                        history.push(routes.TEAM_CHECKLIST_SUPERVISIOR_SUMMARY);
                        return response.data;
                    }
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
}

export const editTeamManagementSummary=(body)=>{
    
    return (dispatch, getState) => {
        dispatch({
            type: formAuditActionTypes.EDIT_TEAM_MANAGEMENT,
            payload: API.editTeamManagementApi(body)
                .then((response) => {
                    
                    if (response.data.success) {
                        SuccessAlert('Team Checklist Manager Summary  Updated successfully')
                        history.push(routes.TEAM_CHECKLIST_MANAGER_SUMMARY);
                        return response.data;
                    }
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
}



export const editTrainingLog=(body)=>{
    
    return (dispatch, getState) => {
        dispatch({
            type: formAuditActionTypes.EDIT_TRAINING_LOG,
            payload: API.editTrainingLogApi(body)
                .then((response) => {
                    
                    if (response.data.success) {
                        SuccessAlert('Training Log Updated successfully')
                        history.push(routes.TRAINING_LOG);
                        return response.data;
                    }
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
}



// export const editTrainingLog=(body)=>{
    
//     return (dispatch, getState) => {
//         dispatch({
//             type: formAuditActionTypes.EDIT_TRAINING_LOG,
//             payload: API.editTrainingLogApi(body)
//                 .then((response) => {
                    
//                     if (response.data.success) {
//                         SuccessAlert('Training Log Updated successfully')
//                         history.push(routes.TRAINING_LOG);
//                         return response.data;
//                     }
//                 })
//                 .catch((error) => {
//                     console.log(error);
//                 }),
//         });
//     };
// }



export const editTeamSupervisorSummary=(body)=>{
    
    return (dispatch, getState) => {
        dispatch({
            type: formAuditActionTypes.EDIT_CHECKLIST_SUPERVISOR,
            payload: API.editTeamSupervisorApi(body)
                .then((response) => {
                    
                    if (response.data.success) {
                        SuccessAlert('Team Checklist Supervisor Summary Updated successfully')
                        history.push(routes.TEAM_CHECKLIST_SUPERVISIOR_SUMMARY);
                        return response.data;
                    }
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
}



export const addChecklistLog = (body) => {
    return (dispatch, getState) => {
        dispatch({
            type: formAuditActionTypes.ADD_CHECKLIST_LOG,
            payload: API.addChecklistLog(body)
                .then((response) => {
                    if (response.data.success) {
                        SuccessAlert('Checklist log created successfully')
                        history.push(routes.TEAM_CHECKLIST_LOG);
                        return response.data;
                    }
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    }
}

export const getAllChecklistLog = (params) => {
    return (dispatch, getState) => {
        dispatch({
            type: formAuditActionTypes.GET_ALL_CHECKLIST_LOG,
            payload: API.getAllChecklistLog(params)
                .then((response) => {
                    if (response.data.success) {
                        return {
                            checklistLog_list: response.data.data,
                            checklistLog_total_pages: response.data.totalPages,
                            checklistLog_no_of_rows: response.data.limit,
                            total_checklistLog_count: response.data.total,
                            checklistLogCurPage: params.page - 1,
                        };
                    };
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};

export const ChecklistLogListPageClicked = (data) => {
    return {
        type: formAuditActionTypes.CHECKLIST_LOG_LIST_PAGE_CLICKED,
        payload: data,
    };
};

export const getAllChecklistManager = (params) => {
    return (dispatch, getState) => {
        dispatch({
            type: formAuditActionTypes.GET_ALL_CHECKLIST_MANAGER,
            payload: API.getAllChecklistManager(params)
                .then((response) => {
                    if (response.data.success) {
                        return {
                            checklistManager_list: response.data.data,
                            checklistManager_total_pages: response.data.totalPages,
                            checklistManager_no_of_rows: response.data.limit,
                            total_checklistManager_count: response.data.total,
                            checklistManagerCurPage: params.page - 1,
                        };
                    };
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};

export const ChecklistManagerListPageClicked = (data) => {
    return {
        type: formAuditActionTypes.CHECKLIST_MANAGER_LIST_PAGE_CLICKED,
        payload: data,
    };
};

export const getAllChecklistSupervisor = (params) => {
    return (dispatch, getState) => {
        dispatch({
            type: formAuditActionTypes.GET_ALL_CHECKLIST_SUPERVISOR,
            payload: API.getAllChecklistSupervisor(params)
                .then((response) => {
                    if (response.data.success) {
                        return {
                            checklistSupervisor_list: response.data.data,
                            checklistSupervisor_total_pages: response.data.totalPages,
                            checklistSupervisor_no_of_rows: response.data.limit,
                            total_checklistSupervisor_count: response.data.total,
                            checklistSupervisorCurPage: params.page - 1,
                        };
                    };
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};

export const ChecklistSupervisorListPageClicked = (data) => {
    return {
        type: formAuditActionTypes.CHECKLIST_SUPERVISOR_LIST_PAGE_CLICKED,
        payload: data,
    };
};

export const getAllChecklist = (params) => {
    return (dispatch, getState) => {
        dispatch({
            type: formAuditActionTypes.GET_ALL_CHECKLIST,
            payload: API.getAllChecklist(params)
                .then((response) => {
                    if (response.data.success) {
                        return {
                            checklist_list: response.data.data,
                            checklist_total_pages: response.data.totalPages,
                            checklist_no_of_rows: response.data.limit,
                            total_checklist_count: response.data.total,
                            checklistCurPage: params.page - 1,
                        };
                    };
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};

export const ChecklistPageClicked = (data) => {
    return {
        type: formAuditActionTypes.CHECKLIST_PAGE_CLICKED,
        payload: data,
    };
};


export const getAllTrainingLog = (params) => {
    return (dispatch, getState) => {
        dispatch({
            type: formAuditActionTypes.GET_ALL_TRAINING_LOG,
            payload: API.getAllTrainingLog(params)
                .then((response) => {
                    if (response.data.success) {
                        return {
                            trainingLog_list: response.data.data,
                            trainingLog_total_pages: response.data.totalPages,
                            trainingLog_no_of_rows: response.data.limit,
                            total_trainingLog_count: response.data.total,
                            trainingLogCurPage: params.page - 1,
                        };
                    };
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};

export const TrainingLogPageClicked = (data) => {
    return {
        type: formAuditActionTypes.TRAINING_LOG_PAGE_CLICKED,
        payload: data,
    };
};

export const addChecklist = (body) => {
    return (dispatch, getState) => {
        dispatch({
            type: formAuditActionTypes.ADD_CHECKLIST,
            payload: API.addChecklist(body)
                .then((response) => {
                    if (response.data.success) {
                        SuccessAlert('Checklist created successfully')
                        history.push(routes.TEAM_CHECKLIST);
                        return response.data;
                    }
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    }
}

export const getSingleChecklist = (id) => {
    return (dispatch, getState) => {
        dispatch({
            type: formAuditActionTypes.GET_SINGLE_CHECKLIST,
            payload: API.getSingleChecklist(id)
                .then((response) => {
                    if (response.data.success) {
                        return {
                            checklist : response.data.data,
                        };
                    };
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
}

export const addMoreChecklistClicked = () => {
    return {
        type: formAuditActionTypes.ADD_MORE_CHECKLIST_CLICKED,
    };
};

export const editChecklist = (params) => {
    return (dispatch, getState) => {
        dispatch({
            type: formAuditActionTypes.EDIT_CHECKLIST,
            payload: API.editChecklist(params)
                .then((response) => {
                    if (response.data.success) {
                        SuccessAlert('Checklist updated successfully')
                        history.push(routes.TEAM_CHECKLIST);
                        return response.data;
                    };
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
}

export const getUsersList = (params) => {
    return (dispatch, getState) => {
        dispatch({
            type: formAuditActionTypes.GET_USERS_LIST,
            payload: API.getUsersList(params)
                .then((response) => {
                    
                    if (response.data.success) {
                           
                             return response.data.data
                        
                    };
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
}

export const getUsersListWithoutClient = (params) => {
    return (dispatch, getState) => {
        dispatch({
            type: formAuditActionTypes.GET_USERS_LIST_WITH_OUT_CLIENT,
            payload: API.getUsersList(params)
                .then((response) => {
                    
                    if (response.data.success) {
                           
                             return response.data.data
                        
                    };
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
}

export const getCleaningWorkersList = (params) => {
    return (dispatch, getState) => {
        
        dispatch({
            type: formAuditActionTypes.GET_CLEANING_WORKERS,
            payload: API.getCleaningWorkersList(params)
                .then((response) => {
                    
                    if (response.data.success) {
                        
                        return {
                            employeeList: response.data.data,
                        };
                    };
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
}


export const getJobRelatedToCompany = (params) => {
    return (dispatch, getState) => {
        
        dispatch({
            type: formAuditActionTypes.GET_ALL_JOB,
            payload: API.getAllJObByCompany(params)
                .then((response) => {
                    
                    if (response.data.success) {
                        return response.data
                    };
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
}

//https://dev-api.pegdesk.com/api/v1/accounts/get_all_account/account_mode?mode=external


export const getAllJobNew = () => {
    return (dispatch, getState) => {
        dispatch({
            type: formAuditActionTypes.GET_ALL_JOB,
            payload: API.getAllJobApiNew() //146
                .then((response) => {
                     
                    if (response.data.success) {
                        // we need to return data here 
                      return {
                        data:response.data.accounts
                      }
                       
                    }
                }
                )
        })

    }
}
