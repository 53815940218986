import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reset } from 'redux-form';
import * as actions from '../../../../../redux/actions/index';
import { routes, address_attributes } from '../../../../../utility/constants/constants';
import { convertDateFormatV4, convertTo24HourFormat, extractExtention, getTotalMinutes_V1, getUser, resetOrientation } from '../../../../../utility/utility';
import NewLeaveForm from '../../../../component/TimeTrackerModuleComponent/LeavesTrackComponent/NewLeaveForm/NewLeaveForm';
import { animateScroll as scroll } from 'react-scroll'
import { decode, encode } from 'base64-arraybuffer';
import moment from 'moment';
import errorHandler from '../../../../../utility/errorHandler/errorHandler';
import { tzUTCDateTimeConverterTool_V1, tzUTCTimeConverterTool_V1 } from '../../../../../utility/TimezoneOperations/timezoneUtility';
const cloneDeep = require('clone-deep');

class NewLeaveContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            storedFileList: [],
            previewFileQuery: {
                previewOpen: false,
                previewTitle: '',
                previewImage: '',
            },
            base64List: [],
            fileNameList: '',
            fileTypeList: '',
            pickingTicketId: null,
        }
        this.handleFileChange = this.handleFileChange.bind(this);
        this.handleFilePreview = this.handleFilePreview.bind(this);
        this.handlePreviewCancel = this.handlePreviewCancel.bind(this);

    }

    componentDidMount() {

        if (this.props && this.props.match && this.props.match.params && this.props.match.params.leaveId) {
            this.props.getSingleLeaveRequest(this.props.match.params.leaveId);
        }

        this.props.getJobAccountSitesList();
        this.props.getDepartmentList({ frontlineLogin: true });

    }

    componentWillUnmount() {
        this.props.resetTimeTrackerObjects();
    }

    handleFileChange = async (imgData) => {
        this.setState({ storedFileList: imgData.fileList });

        let getBase64List = [], getImageName = [], getImageType = [];

        getBase64List = await Promise.all(imgData.fileList.map((file) => {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
                reader.onerror = (error) => reject(error);
            });
        }));

        getImageName = imgData.fileList.map(file => file.name);

        getImageType = imgData.fileList.map(file => file.type);

        this.setState({
            base64List: getBase64List,
            fileNameList: getImageName,
            fileTypeList: getImageType
        });
    }

    handleFilePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
                reader.onerror = (error) => reject(error);
            });
        }
        this.setState({
            previewFileQuery: {
                previewOpen: true,
                previewImage: file.url || file.preview,
                previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
            }
        });
    }

    handlePreviewCancel = () => this.setState({ previewFileQuery: { previewOpen: false } });

    handleSubmit = (values) => {debugger

        // Alternate for Redux Validation forms
        if (values) {
            if (values.leaveUpdate) {

                if (!(values.leaveUpdate.type)) {
                    errorHandler('Please select Leave Type');
                    return false;
                }

                if (values.leaveUpdate.day_coverage == 'true') {

                    if (!(values.leaveUpdate.start_date)) {
                        errorHandler('Please select Start Date');
                        return false;
                    }
                    if (!(values.leaveUpdate.end_date)) {
                        errorHandler('Please select End Date');
                        return false;
                    }

                } else if (values.leaveUpdate.day_coverage == 'false') {

                    if (values.leaveUpdate.start_time == '') {
                        errorHandler('Please select Start Time');
                        return false;
                    }
                    if (values.leaveUpdate.end_time == '') {
                        errorHandler('Please select End Time');
                        return false;
                    }
                    if (values.leaveUpdate.start_time == values.leaveUpdate.end_time) {
                        errorHandler('Kindly avoid to select the same time');
                        return false;
                    }
                    if (!(values.leaveUpdate.start_date)) {
                        errorHandler('Please select Start Date');
                        return false;
                    }

                }

                if (!(values.leaveUpdate.account_id)) {
                    errorHandler('Please select Job');
                    return false;
                }

                if (!(values.leaveUpdate.manager_id)) {
                    errorHandler('Please select Manager Name');
                    return false;
                }

                if (!(values.leaveUpdate.department_id)) {
                    errorHandler('Please select Department');
                    return false;
                }

            }
        }

        let setData = { data: {} };
        let fetchedValue = cloneDeep(values.leaveUpdate);
        let arrayBuffer = [];
        let extensions = extractExtention(this.state.fileNameList);

        if (this.props && this.props.match && this.props.match.params && this.props.match.params.leaveId) {
            /* FOR EDIT FLOW */

            delete fetchedValue.trigger_DayCoverage;

            if (fetchedValue.info && fetchedValue.info.length > 0) {
                setData.data.info = fetchedValue.info;
            }

            if (fetchedValue.day_coverage && fetchedValue.day_coverage == 'false') {

                if (fetchedValue.start_time && fetchedValue.end_time) {

                    if (fetchedValue.start_time.includes('PM') && fetchedValue.end_time.includes('AM')) {
                        errorHandler('Select a time range that falls within a single day');
                        return false;
                    }

                    setData.data.start_time = tzUTCTimeConverterTool_V1(convertDateFormatV4(fetchedValue.start_date), convertTo24HourFormat(fetchedValue.start_time));
                    setData.data.end_time = tzUTCTimeConverterTool_V1(convertDateFormatV4(fetchedValue.start_date), convertTo24HourFormat(fetchedValue.end_time));

                }

                if (fetchedValue.time_duration && fetchedValue.time_duration.includes('hours')) {
                    setData.data.time_duration = getTotalMinutes_V1(fetchedValue.time_duration);
                } else {
                    setData.data.time_duration = fetchedValue.time_duration;
                }
                setData.data.start_date = tzUTCDateTimeConverterTool_V1(fetchedValue.start_date, convertTo24HourFormat(fetchedValue.start_time), 'none');
                setData.data.end_date = tzUTCDateTimeConverterTool_V1(fetchedValue.start_date, convertTo24HourFormat(fetchedValue.end_time), 'none');

            } else if (fetchedValue.day_coverage && fetchedValue.day_coverage == 'true') {
                setData.data.start_date = tzUTCDateTimeConverterTool_V1(fetchedValue.start_date, '00:00:00', 'from');
                setData.data.end_date = tzUTCDateTimeConverterTool_V1(fetchedValue.end_date, '00:00:00', 'to');
            }

            setData.data.type = fetchedValue.type.value;
            setData.data.day_coverage = fetchedValue.day_coverage && fetchedValue.day_coverage == 'true' ? 'full' : 'partial';
            setData.data.account_id = fetchedValue.account_id.value;
            setData.data.department_id = fetchedValue.department_id.value.toString();
            setData.data.manager_id = fetchedValue.manager_id.value;

            if (this && this.state && this.state.base64List && this.state.base64List.length > 0) {

                // For getting images properties
                this.state.base64List.map((url, i) => {
                    if (!url.photo_urls) {
                        let base_photo = null;
                        const image = url.split(',');
                        base_photo = image[1];
                        arrayBuffer.push(decode(base_photo))
                    }
                });

                // if (this.props.singleLeaveRequestData && this.props.singleLeaveRequestData.attachments) {
                //     setData.data.attachments = this.props.singleLeaveRequestData.attachments;
                // }

                this.props.getEditLeaveAttachmentPresignUrl(setData, this.props.match.params.leaveId, arrayBuffer, extensions);

            } else {
                // if (this.props.singleLeaveRequestData && this.props.singleLeaveRequestData.attachments) {
                //     setData.data.attachments = this.props.singleLeaveRequestData.attachments;
                // }
                this.props.editFrontlinerLeaveRequest(setData, this.props.match.params.leaveId);
            }

        } else {
            /* FOR CREATE FLOW */

            if (fetchedValue.info && fetchedValue.info.length > 0) {
                setData.data.info = fetchedValue.info;
            }

            if (fetchedValue.day_coverage && fetchedValue.day_coverage == 'false') {

                if (fetchedValue.start_time && fetchedValue.end_time) {

                    if (fetchedValue.start_time.includes('PM') && fetchedValue.end_time.includes('AM')) {
                        errorHandler('Select a time range that falls within a single day');
                        return false;
                    }

                    setData.data.start_time = tzUTCTimeConverterTool_V1(convertDateFormatV4(fetchedValue.start_date), convertTo24HourFormat(fetchedValue.start_time));
                    setData.data.end_time = tzUTCTimeConverterTool_V1(convertDateFormatV4(fetchedValue.start_date), convertTo24HourFormat(fetchedValue.end_time));

                }
                if (fetchedValue.time_duration && fetchedValue.time_duration.includes('hours')) {

                    setData.data.time_duration = getTotalMinutes_V1(fetchedValue.time_duration);

                }
                setData.data.start_date = tzUTCDateTimeConverterTool_V1(fetchedValue.start_date, convertTo24HourFormat(fetchedValue.start_time), 'none');
                setData.data.end_date = tzUTCDateTimeConverterTool_V1(fetchedValue.start_date, convertTo24HourFormat(fetchedValue.end_time), 'none');

            } else if (fetchedValue.day_coverage && fetchedValue.day_coverage == 'true') {

                setData.data.start_date = tzUTCDateTimeConverterTool_V1(fetchedValue.start_date, '00:00:00', 'from');
                setData.data.end_date = tzUTCDateTimeConverterTool_V1(fetchedValue.end_date, '00:00:00', 'to');

            }

            setData.data.type = fetchedValue.type.value;
            setData.data.day_coverage = fetchedValue.day_coverage && fetchedValue.day_coverage == 'true' ? 'full' : 'partial';
            setData.data.account_id = fetchedValue.account_id.value;
            setData.data.department_id = fetchedValue.department_id.value;
            setData.data.manager_id = fetchedValue.manager_id.value;

            if (this && this.state && this.state.base64List && this.state.base64List.length > 0) {

                // For getting images properties
                this.state.base64List.map((url, i) => {
                    if (!url.photo_urls) {
                        let base_photo = null;
                        const image = url.split(',');
                        base_photo = image[1];
                        arrayBuffer.push(decode(base_photo))
                    }
                });

                this.props.getCreateLeaveAttachmentPresignUrl(setData, arrayBuffer, extensions);

            } else {

                this.props.createFrontlinerLeaveRequest(setData);

            }

        }

    };

    onCancelClicked = () => {
        this.props.handleCancelClickAction();
        this.props.history.push(routes.LEAVE_LIST);
    }

    render() {
        return (
            <>
                <NewLeaveForm
                    {...this.props}
                    onSubmit={this.handleSubmit}
                    handleChange={this.handleFileChange}
                    handlePreview={this.handleFilePreview}
                    handleCancel={this.handlePreviewCancel}
                    onCancelClicked={this.onCancelClicked}
                    storedFileList={this.state.storedFileList}
                    previewFileQuery={this.state.previewFileQuery}
                />
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.authReducer.user,
        isLoading: state.TimeTrackerModReducer.isLoading,
        setStaticTrigger: state.TimeTrackerModReducer.setStaticTrigger,
        isEditLeaveRequestForm: state.TimeTrackerModReducer.isEditLeaveRequestForm,
        isUpdateLeaveRequestForm: state.TimeTrackerModReducer.isUpdateLeaveRequestForm,
        singleLeaveRequestData: state.TimeTrackerModReducer.singleLeaveRequestData,

        jobAccountSitesList: state.TimeTrackerModReducer.jobAccountSitesList,
        department_list: state.TimeTrackerModReducer.department_list,
        leaveUpdate_managersList: state.TimeTrackerModReducer.leaveUpdate_managersList,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        resetTimeTrackerObjects: () => dispatch(actions.resetTimeTrackerObjects()),
        handleCancelClickAction: () => dispatch(actions.handleCancelClickAction()),
        handleStaticTriggerClick: () => dispatch(actions.handleStaticTriggerClick()),

        getJobAccountSitesList: () => dispatch(actions.getJobAccountSitesList()),
        getDepartmentList: (data) => dispatch(actions.getDepartmentList(data)),
        getLeaveUpdateManagersList: (params) => dispatch(actions.getLeaveUpdateManagersList(params)),

        createFrontlinerLeaveRequest: (data) => dispatch(actions.createFrontlinerLeaveRequest(data)),
        editFrontlinerLeaveRequest: (data, id) => dispatch(actions.editFrontlinerLeaveRequest(data, id)),
        getSingleLeaveRequest: (id) => dispatch(actions.getSingleLeaveRequest(id)),

        getCreateLeaveAttachmentPresignUrl: (data, arrayBuffer, extension) =>
            dispatch(actions.getCreateLeaveAttachmentPresignUrl(data, arrayBuffer, extension)),
        getEditLeaveAttachmentPresignUrl: (data, id, arrayBuffer, extension) =>
            dispatch(actions.getEditLeaveAttachmentPresignUrl(data, id, arrayBuffer, extension)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewLeaveContainer);