import React, { useEffect, useState } from 'react';
import ReactTable from 'react-table-v6';
import '../../../styles/custom.css';
import { TicketStatus, userRoles, TimeZones, routes } from '../../../utility/constants/constants';
import { CustomNoDataComponent, trimString, makeFirstLetterCapital, convertDateTimezone, convertDateAndTimeTimezone, getUser, getWeekStartAndEndDay, convertTicketsDataToCsvType } from '../../../utility/utility';
import { FaArrowDown, FaArrowUp } from "react-icons/fa";
import ReactTooltip from 'react-tooltip';
import * as actions from '../../../redux/actions/index';
import { useSelector, useDispatch } from 'react-redux';
import { CSVLink } from "react-csv";
import { MagicSpinner, PushSpinner, SwishSpinner, WaveSpinner, ClapSpinner } from 'react-spinners-kit';
import 'react-table-v6/react-table.css';
import storage from '../../../utility/storage';
import { convertDateTimezoneV1 } from '../../../utility/TimezoneOperations/timezoneUtility';
var moment = require('moment');

let PreviewScheduledTicketTable = (props) => {
    const user = getUser()

    const dispatch = useDispatch();
    var SearchValue = useSelector(state => state.adminOrSuperAdminReducer.previewScheduleSearchTicketValues);
    const ticketIndex = useSelector(state => state.adminOrSuperAdminReducer.previewScheduleTicketIndex);
    const DateGenerate = useSelector(state => state.adminOrSuperAdminReducer.dataGenerated);
    const isTicketPending = useSelector(state => state.adminOrSuperAdminReducer.isTicketPending);
    const SearchOrResetTicektClicked = useSelector(state => state.adminOrSuperAdminReducer.SearchOrResetTicektClicked);
    const No_of_pages = useSelector(state => state.adminOrSuperAdminReducer.preview_schedule_ticket_page);
    const No_of_rows = useSelector(state => state.adminOrSuperAdminReducer.preview_schedule_ticket_no_of_rows);
    const ticketPage = useSelector(state => state.adminOrSuperAdminReducer.previewScheduleTicketCurPage);
    const ticket_rows = useSelector(state => state.adminOrSuperAdminReducer.preview_schedule_ticket_rows);

    const data = props.ticketsList.map(ticket => {
        const ticket_date = ticket.created_at;
        return {
            status: makeFirstLetterCapital(ticket.status),
            due_on: ticket.due_on ? convertDateTimezoneV1(ticket.due_on, ticket.account.timezone) : '',
            elapsedDays: ticket.status === 'resolved' ? ' - ' : ticket.elapsed_days,
            account: ticket.account.name,
            account_id: ticket.account.id,
            priority: ticket.priority,
            created_at: ticket_date ? convertDateAndTimeTimezone(ticket_date, ticket.account.timezone) : '',            // subject: ticket.isDeficient ? `deficiency line item for ${ticket.inspection_form.name}` : ticket.subject,
            id: ticket.id,
            message: trimString(String(ticket.message ? ticket.message : ''), 30) + (ticket.message && ticket.message.length > 30 ? '...' : ''),
            //created_by: ticket.requestor_user?.first_name + ' ' + ticket.requestor_user?.last_name,
            // location: trimString(String(ticket.account.address.formatted_address ? ticket.account.address.formatted_address : ''), 25)
            //     + (ticket.account.address.formatted_address && ticket.account.address.formatted_address.length > 25 ? '...' : ''),
            // category: ticket.category ? ticket.category.name : '',
            // assigned_to: ticket.user ? ticket.user.first_name + ' ' + ticket.user.last_name : '',
            // priority: ticket.priority,


            actions:

                <>
                    {props.user.role === userRoles.SUPER_ADMIN && !(ticket.status == 'canceled' || ticket.status == 'resolved') ? <span data-tip="Edit Ticket" className="mr_20 account_edit_icn" onClick={() => props.editTicketClicked(ticket)} data-test="edit_pencil_icon"><i class="fa fa-pencil" aria-hidden="true"></i></span> : null}
                    {props.user.role !== userRoles.SUPER_ADMIN && ticket.requestor_user.id === user.id && !(ticket.status == 'canceled' || ticket.status == 'resolved') ? <span data-tip="Edit Ticket" className="mr_20 account_edit_icn" onClick={() => props.editTicketClicked(ticket)} data-test="edit_pencil_icon"><i class="fa fa-pencil" aria-hidden="true"></i></span> : null}
                    <span data-tip="View Ticket" className="mr_20 account_edit_icn" onClick={() => {
                        let storedRoute = ''
                        if(props.history.location && props.history.location.state) {
                            if(props.history.location.state.storedRoute) {
                                storedRoute = props.history.location.state.storedRoute;
                            }
                        }
                        props.history.push({pathname: `/schedules/tickets/preview-ticket/${ticket.id}`, state: {storedRoute: storedRoute && storedRoute.length > 0 ? storedRoute : null}});
                    }} data-test="edit_eye_icon"><i className="fas fa-eye cst_icon mr-2" aria-hidden="true"></i></span>
                    <ReactTooltip place="top" type="dark" effect="solid" />
                    { props.fromDashboard || ticket.priority_ticket ? null :
                        <>
                            {props.user.role === userRoles.ADMIN || props.user.role === userRoles.CLIENT || props.user.role === userRoles.INSPECTOR || props.user.role === userRoles.REQUESTOR || props.fromDashboard ? null :
                                <span className="mr_20 account_edit_icn" onClick={() => props.handleClickDeleteModel(ticket)} data-test="edit_trash_icon"><i class="fa fa-trash" aria-hidden="true"></i></span>
                            }
                        </>
                    }
                </>
        }
    })

    console.log(data)

    const columns = [
        {
            Header: <><span>ID</span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /></>,
            accessor: 'id',
            show: props.ticketsList.length !== 0,
        },
        {
            Header: <><span>Date Created</span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /></>,
            accessor: 'created_at',
            // width: 135,
            show: props.ticketsList.length !== 0,
        },
        {
            Header: <><span>Job Name</span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /></>,
            accessor: 'account',
            show: props.ticketsList.length !== 0,
            width: 100,
            Filter: ({ filter, onChange }) => (
                <input
                    onChange={event => onChange(event.target.value)}
                    value={filter ? filter.value : ''}
                    placeholder={'Search Account Number...'}
                    className={'form-control input-modifier'}
                />
            ), // String-based value accessors!
        },
        {
            Header: <><span>Description</span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /></>,
            accessor: 'message',
            show: props.ticketsList.length !== 0,
            width: 100,
            Filter: ({ filter, onChange }) => (
                <input
                    onChange={event => onChange(event.target.value)}
                    value={filter ? filter.value : ''}
                    placeholder={'Search Account Number...'}
                    className={'form-control input-modifier'}
                />
            ), // String-based value accessors!
        },
        {
            Header: <><span>Due Date</span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
            accessor: 'due_on',
            width: 190,
            show: props.ticketsList.length !== 0
            // show: false
            // Cell: props => <span className='number'>{props.value}</span> // Custom cell components!
        },
        // {
        //     Header: <><span>Requestor</span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
        //     accessor: 'created_by',
        //     width: 90,
        //     show: props.ticketsList.length !== 0
        //     // show: false
        //     // Cell: props => <span className='number'>{props.value}</span> // Custom cell components!
        // },
        {
            Header: <><span>Priority</span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /></>,
            accessor: 'priority',
             width: 135,
            show: props.ticketsList.length !== 0,
        },
        {
            Header: <><span>Status</span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
            accessor: 'status',
            width: 79,
            show: props.ticketsList.length !== 0
            // show: false
            // Cell: props => <span className='number'>{props.value}</span> // Custom cell components!
        },
        // {
        //     Header: <><span>Date and time requested</span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /></>,
        //     accessor: 'created_at',
        //     // width: 135,
        //     show: props.ticketsList.length !== 0,
        // },
        {
            Header: <><span>Days Open</span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /></>,
            accessor: 'elapsedDays',
            show: props.ticketsList.length !== 0,
            width: 123,
            Filter: ({ filter, onChange }) => (
                <input
                    onChange={event => onChange(event.target.value)}
                    value={filter ? filter.value : ''}
                    placeholder={'Search Account Number...'}
                    className={'form-control input-modifier'}
                />
            ), // String-based value accessors!
        },
        {
            Header: '',
            accessor: 'actions',
            width: 135,
            filterable: false,
            // show: false
        }
    ]
    const [tablePage, setTablePage] = useState(0)
    const tablePageNew =  React.useRef()
    
    if(ticketPage !== tablePageNew.current) {
        tablePageNew.current = ticketPage;
    }

    const setTablePageFn = (page) => {
        setTablePage(page)
        props.previewScheduleTicketPageClicked(page)
        tablePageNew.current = page;
    }
    const fetchData = (state, instance) => {

        if (SearchOrResetTicektClicked) {
            dispatch(actions.resetSearchOrResetValue())
        } else {
            if (!DateGenerate && !isTicketPending) {
                state.page = 2;
                const ticket_no_of_rows = state.pageSize;
                let page = tablePageNew.current >= 0 ? tablePageNew.current + 1 : ticketPage + 1;
                if(ticket_rows != ticket_no_of_rows) {
                    page = 1;
                    setTablePageFn(0)
                }
                const sorted = state.sorted;
                const filtered = state.filtered;
                if (props.history.location.pathname === routes.VIEW_FILTERED_TICKETS) {
                    // if (!props.filteredQuotesList) {

                    props.updateWeekMonthYear(props.history.location.state.isWeek, props.history.location.state.isMonth, props.history.location.state.isYear);
                    // props.updateCurrentDate(props.history.location.state.currentDate);
                    if (props.history.location.state.isWeek) {

                        // let startAndEndDate = getWeekStartAndEndDay(props.history.location.state.currentDate);
                        SearchValue = props.history.location.state;
                        SearchValue.firstday = moment(SearchValue.currentDate).startOf('week').format('MM/DD/YYYY');
                        SearchValue.lastday = moment(SearchValue.currentDate).endOf('week').format('MM/DD/YYYY');
                        SearchValue.currentDate = moment(SearchValue.currentDate).utc().toISOString();
                        // props.viewWeeklyFilterQuotes(props.history.location.state.Status, startAndEndDate.firstday, startAndEndDate.lastday, props.history.location.state.currentDate);
                    } else if (props.history.location.state.isMonth || props.history.location.state.isYear) {
                        let currentDate = moment(props.history.location.state.currentDate, 'MM/DD/YYYY').startOf('day');
                        // const month = currentDate.format('M');
                        // const year = currentDate.format('YYYY');
                        SearchValue = props.history.location.state;
                        SearchValue.currentDate = moment(currentDate._d).utc().toISOString();
                    }
                    // }
                    if(SearchValue === null || SearchValue === undefined) {
                        SearchValue = {}
                    SearchValue.value = '';
                    }
                } else {
                    if(SearchValue === null || SearchValue === undefined) {
                        SearchValue = {}
                    SearchValue.value = null;
                    }  else {
                        SearchValue.value = SearchValue;
                    }
                }
                    const ticketId = storage.get('ticketId')
                    if (ticketIndex && ticketId) {
                        ticketIndex.map((ins, i) => {
                            if(ticketId === parseInt(ins)) {
                                if(i + 1 > 10) {
                                    props.getPreviewScheduledTickets(
                                        SearchValue,
                                        ticket_no_of_rows, parseInt(((i + 1) / 10).toFixed()) + 1,
                                        sorted,
                                        filtered,parseInt(props.match.params.id));
                                        setTablePageFn(parseInt(((i + 1) / 10).toFixed()))
                                        storage.remove('ticketId')
                                } else {
                                    props.getPreviewScheduledTickets(
                                        SearchValue,
                                        ticket_no_of_rows, 1,
                                        sorted,
                                        filtered,
                                        parseInt(props.match.params.id)
                                    );
                                    storage.remove('ticketId')
                                }
                            }
                        })
                        
                    } else {
                        props.getPreviewScheduledTickets(
                            SearchValue,
                            ticket_no_of_rows, page,
                            sorted,
                            filtered,
                            parseInt(props.match.params.id)
                        );
                    }
                
            }
        }
        // }
        // }
    }

    return (
        SearchOrResetTicektClicked && props.isTicketPending ?
            <div className="loader_btn_block">
                <ClapSpinner backColor="#89073c" size={40} frontColor="#89073c8a" />
            </div>
            :
            <div>
            {/* <div className="row">
                {props.ticketsList && props.ticketsList.length === 0 ? null :
                 <div className="col-xl-12 col-lg-4 col-md-4 col-sm-12">
                 <CSVLink filename="tickets.csv" className="csv_btn btn_danger " data={convertTicketsDataToCsvType(data)} >Export Csv</CSVLink>
                 <button className="csv_btn btn_danger " onClick={() => props.onTicketExportClicked(SearchValue)}>Export CSV</button>
          
                </div>
                }
            </div>  */}
            <ReactTable
                data={data}
                loading={props.isTicketPending}
                defaultPageSize={parseInt(No_of_rows)}
                minRows={0}
                showPaginationTop={true}
                manual
                onFetchData={fetchData}
                pages={No_of_pages}
                gotoPage={ticketPage}
                page = {
                    ticketPage
                }
                onPageChange={(page) => setTablePageFn(page)}
                // onPageSizeChange={props.onAccountsPageSizeChange}
                NoDataComponent={() => CustomNoDataComponent(props.isTicketPending, 'No Tickets Found')}
                showPagination={props.ticketsList.length !== 0}
                // filterable={true}
                columns={columns}
                LoadingComponent={() =>
                    isTicketPending ?
                        <div style={{
                            display: "block",
                            position: "absolute",
                            left: 0,
                            height: '100%',
                            right: 0,
                            background: "rgba(255,255,255,0.8)",
                            transition: "all .3s ease",
                            top: 0,
                            bottom: 0,
                            textAlign: "center"
                        }}
                            className="loader_btn_block">
                            <ClapSpinner backColor="#89073c" size={40} frontColor="#89073c8a" />
                        </div> : null}
                // style={{marginTop: '10px'}}
                getTheadThProps={(state, rowInfo, column, instance) => {

                    return {
                        style: {
                            borderTop: 'none',
                            borderBottom: 'none',
                            borderRight: 'none',
                            textAlign: 'center',
                            color: '#566975',
                            fontSize: '14px',
                            fontWeight: '600',
                            whiteSpace: 'nowrap',
                            float: 'left',
                        }
                    }
                }
                }
                getTrProps={(state, rowInfo, column, instance) => {
                    // onclick = () => userDetail(rowInfo ? rowInfo.row.user : rowInfo);
                    console.log(column, 'testing', rowInfo.original.status);
                    // 1px solid #ec9720
                    return {
                        className: rowInfo.original.status === 'Open' ? 'react-table-tr-element-open-status  react-table-tr-element-ticket ' : rowInfo.original.status === 'Pending' ? 'react-table-tr-element-pending-status  react-table-tr-element-ticket' : 'react-table-tr-element-close-status react-table-tr-element-ticket',
                        style: {
                            border: '1px solid #c7cdd1',
                            transition: '.3s ease-in-out',
                            width: '100%',
                            // borderRadius: '4px',
                            // height: '80px',
                            marginTop: '5px',
                            float: 'left'
                        }
                    }

                }}
                getTdProps={(state, rowInfo, column, instance) => {
                    // if (column.Header == 'Active' || column.Header == 'Actions') {
                    if (column.id == 'image') {
                        return {
                            style: {
                                marginTop: '0px'
                            }
                        }
                    } else {
                        return {
                            className: 'react-td-element'
                        }
                    }
                    //  {
                    //   style: {
                    //     borderTop: 'none',
                    //     verticalAlign: 'middle',
                    //     fontSize: '14px',
                    //     color: '#2b3034',
                    //     marginTop: '10px !important',
                    //     textAlign: 'center',
                    //   }
                    // }
                }
                }
                // getPaginationProps={() => {
                //     return {
                //         style: {
                //             marginTop: '50px',
                //             border: 'none',
                //             boxShadow: 'none'
                //         }
                //     }
                // }
                // }
                getProps={() => {
                    return {
                        style: {
                            border: 'none'
                        }
                    }
                }}
            />
              </div>
    )
}

export default PreviewScheduledTicketTable;