export const AdminOrSuperAdminActionTypes = {

    SIDE_BAR_CLICKED: 'SIDE_BAR_CLICKED',
    SETTINGS_CLICKED: 'SETTINGS_CLICKED',
    KPI_CLICKED: 'KPI_CLICKED',
    GET_UPDATED_DATA: 'GET_UPDATED_DATA',
    CHANGE_HIDE_STATUS: 'CHANGE_HIDE_STATUS',

    GET_DASHBOARD_DATA: 'GET_DASHBOARD_DATA',
    GET_DASHBOARD_DATA_PENDING: 'GET_DASHBOARD_DATA_PENDING',
    GET_DASHBOARD_DATA_FULFILLED: 'GET_DASHBOARD_DATA_FULFILLED',
    PEGASSURE_TAB_CLICKED: 'PEGASSURE_TAB_CLICKED',

    GET_INSPECTION_DASHBOARD_DATA: 'GET_INSPECTION_DASHBOARD_DATA',
    GET_INSPECTION_DASHBOARD_DATA_PENDING: 'GET_INSPECTION_DASHBOARD_DATA_PENDING',
    GET_INSPECTION_DASHBOARD_DATA_FULFILLED: 'GET_INSPECTION_DASHBOARD_DATA_FULFILLED',

    GET_TICKET_DASHBOARD_DATA: 'GET_TICKET_DASHBOARD_DATA',
    GET_TICKET_DASHBOARD_DATA_PENDING: 'GET_TICKET_DASHBOARD_DATA_PENDING',
    GET_TICKET_DASHBOARD_DATA_FULFILLED: 'GET_TICKET_DASHBOARD_DATA_FULFILLED',

    GET_QUOTE_DASHBOARD_DATA: 'GET_QUOTE_DASHBOARD_DATA',
    GET_QUOTE_DASHBOARD_DATA_PENDING: 'GET_QUOTE_DASHBOARD_DATA_PENDING',
    GET_QUOTE_DASHBOARD_DATA_FULFILLED: 'GET_QUOTE_DASHBOARD_DATA_FULFILLED',

    GET_LOGBOOK_DASHBOARD_DATA: 'GET_LOGBOOK_DASHBOARD_DATA',
    GET_LOGBOOK_DASHBOARD_DATA_PENDING: 'GET_LOGBOOK_DASHBOARD_DATA_PENDING',
    GET_LOGBOOK_DASHBOARD_DATA_FULFILLED: 'GET_LOGBOOK_DASHBOARD_DATA_FULFILLED',

    GET_LOGBOOK_HISTORY_DASHBOARD: 'GET_LOGBOOK_HISTORY_DASHBOARD',
    GET_LOGBOOK_HISTORY_DASHBOARD_PENDING: 'GET_LOGBOOK_HISTORY_DASHBOARD_PENDING',
    GET_LOGBOOK_HISTORY_DASHBOARD_FULFILLED: 'GET_LOGBOOK_HISTORY_DASHBOARD_FULFILLED',

    FUTURE_SCHEDULES_ON_AREA: 'FUTURE_SCHEDULES_ON_AREA',
    FUTURE_SCHEDULES_ON_AREA_PENDING: 'FUTURE_SCHEDULES_ON_AREA_PENDING',
    FUTURE_SCHEDULES_ON_AREA_FULFILLED: 'FUTURE_SCHEDULES_ON_AREA_FULFILLED',

    UPDATE_CURRENT_DATE: 'UPDATE_CURRENT_DATE',
    UPDATE_WEEK_MONTH_YEAR: 'UPDATE_WEEK_MONTH_YEAR',

    INSPECTION_PAGE_CLICKED: 'INSPECTION_PAGE_CLICKED',

    RATING_PAGE_CLICKED: 'RATING_PAGE_CLICKED',

    TICKET_PAGE_CLICKED: 'TICKET_PAGE_CLICKED',

    SCHEDULE_TICKET_PAGE_CLICKED: 'SCHEDULE_TICKET_PAGE_CLICKED',

    PREVIEW_SCHEDULE_TICKET_PAGE_CLICKED: 'PREVIEW_SCHEDULE_TICKET_PAGE_CLICKED',

    CHI_PAGE_CLICKED: 'CHI_PAGE_CLICKED',

    COMPANY_PAGE_CLICKED: 'COMPANY_PAGE_CLICKED',

    QUOTE_PAGE_CLICKED: 'QUOTE_PAGE_CLICKED',

    ACCOUNT_PAGE_CLICKED: 'ACCOUNT_PAGE_CLICKED',

    INSPECTION_FORM_PAGE_CLICKED: 'INSPECTION_FORM_PAGE_CLICKED',

    FRONTLINE_USER_PAGE_CLICKED: 'FRONTLINE_USER_PAGE_CLICKED',

    USER_PAGE_CLICKED: 'USER_PAGE_CLICKED',

    VIEW_QUOTE_STATUS_CLICKED: 'VIEW_QUOTE_STATUS_CLICKED',

    UPDATE_PREFERENCES: 'UPDATE_PREFERENCES',
    UPDATE_PREFERENCES_PENDING: 'UPDATE_PREFERENCES_PENDING',
    UPDATE_PREFERENCES_FULFILLED: 'UPDATE_PREFERENCES_FULFILLED',

    GET_CATEGORIES: 'GET_CATEGORIES',
    GET_CATEGORIES_PENDING: 'GET_CATEGORIES_PENDING',
    GET_CATEGORIES_FULFILLED: 'GET_CATEGORIES_FULFILLED',

    ADD_NEW_CATEGORY: 'ADD_NEW_CATEGORY',
    ADD_NEW_CATEGORY_PENDING: 'ADD_NEW_CATEGORY_PENDING',
    ADD_NEW_CATEGORY_FULFILLED: 'ADD_NEW_CATEGORY_FULFILLED',

    EDIT_CATEGORY_CLICKED: 'EDIT_CATEGORY_CLICKED',

    UPDATE_CATEGORY: 'UPDATE_CATEGORY',
    UPDATE_CATEGORY_PENDING: 'UPDATE_CATEGORY_PENDING',
    UPDATE_CATEGORY_FULFILLED: 'UPDATE_CATEGORY_FULFILLED',

    DELETE_CATEGORY: 'DELETE_CATEGORY',
    DELETE_CATEGORY_PENDING: 'DELETE_CATEGORY_PENDING',
    DELETE_CATEGORY_FULFILLED: 'DELETE_CATEGORY_FULFILLED',

    GET_TICKETS: 'GET_TICKETS',
    GET_TICKETS_PENDING: 'GET_TICKETS_PENDING',
    GET_TICKETS_FULFILLED: 'GET_TICKETS_FULFILLED',

    GET_PRIOR_ESCALATION_TICKETS: 'GET_PRIOR_ESCALATION_TICKETS',

    GET_MY_TICKETS: 'GET_MY_TICKETS',
    GET_MY_TICKETS_PENDING: 'GET_MY_TICKETS_PENDING',
    GET_MY_TICKETS_FULFILLED: 'GET_MY_TICKETS_FULFILLED',

    GET_SCHEDULED_TICKET: 'GET_SCHEDULED_TICKET',
    GET_SCHEDULED_TICKET_PENDING: 'GET_SCHEDULED_TICKET_PENDING',
    GET_SCHEDULED_TICKET_FULFILLED: 'GET_SCHEDULED_TICKET_FULFILLED',

    GET_PREVIEW_SCHEDULED_TICKETS: 'GET_PREVIEW_SCHEDULED_TICKETS',
    GET_PREVIEW_SCHEDULED_TICKETS_PENDING: 'GET_PREVIEW_SCHEDULED_TICKETS_PENDING',
    GET_PREVIEW_SCHEDULED_TICKETS_FULFILLED: 'GET_PREVIEW_SCHEDULED_TICKETS_FULFILLED',

    GET_PRIOR_TICKETS: 'GET_PRIOR_TICKETS',
    GET_PRIOR_TICKETS_PENDING: 'GET_PRIOR_TICKETS_PENDING',
    GET_PRIOR_TICKETS_FULFILLED: 'GET_PRIOR_TICKETS_FULFILLED',

    GET_USER_TICKETS: 'GET_USER_TICKETS',
    GET_USER_TICKETS_PENDING: 'GET_USER_TICKETS_PENDING',
    GET_USER_TICKETS_FULFILLED: 'GET_USER_TICKETS_FULFILLED',

    ADD_NEW_TICKET: 'ADD_NEW_TICKET',
    ADD_NEW_TICKET_PENDING: 'ADD_NEW_TICKET_PENDING',
    ADD_NEW_TICKET_FULFILLED: 'ADD_NEW_TICKET_FULFILLED',
    ADD_NEW_TICKET_REJECTED: 'ADD_NEW_TICKET_REJECTED',

    EDIT_TICKET_CLICKED: 'EDIT_TICKET_CLICKED',

    RESET_INSPECTION_SEARCH: 'RESET_INSPECTION_SEARCH',
    RESET_TICKET_SEARCH: 'RESET_TICKET_SEARCH',

    GET_EDIT_TICKET: 'GET_EDIT_TICKET',
    GET_EDIT_TICKET_PENDING: 'GET_EDIT_TICKET_PENDING',
    GET_EDIT_TICKET_FULFILLED: 'GET_EDIT_TICKET_FULFILLED',

    GET_TICKET_PRESIGNED_URL: 'GET_TICKET_PRESIGNED_URL',
    GET_TICKET_PRESIGNED_URL_PENDING: 'GET_TICKET_PRESIGNED_URL_PENDING',
    GET_TICKET_PRESIGNED_URL_FULFILLED: 'GET_TICKET_PRESIGNED_URL_FULFILLED',

    GET_QUOTE_PRESIGNED_URL: 'GET_QUOTE_PRESIGNED_URL',
    GET_QUOTE_PRESIGNED_URL_PENDING: 'GET_QUOTE_PRESIGNED_URL_PENDING',
    GET_QUOTE_PRESIGNED_URL_FULFILLED: 'GET_QUOTE_PRESIGNED_URL_FULFILLED',

    UPDATE_TICKET: 'UPDATE_TICKET',
    UPDATE_TICKET_PENDING: 'UPDATE_TICKET_PENDING',
    UPDATE_TICKET_FULFILLED: 'UPDATE_TICKET_FULFILLED',

    PREVIEW_TICKET: 'PREVIEW_TICKET',
    PREVIEW_SINGLE_TICKET: 'PREVIEW_SINGLE_TICKET',
    PRIOR_ESCALATION_PREVIEW_TICKETS: 'PRIOR_ESCALATION_PREVIEW_TICKETS',
    VIEW_PRIOR_TICKET_STATUS_CLICKED: 'VIEW_PRIOR_TICKET_STATUS_CLICKED',

    DISPLAY_PRIOR_TICKET_STATUS_CLICK: 'DISPLAY_PRIOR_TICKET_STATUS_CLICK',
    DISPLAY_PRIOR_TICKET_STATUS_CLICK_PENDING: 'DISPLAY_PRIOR_TICKET_STATUS_CLICK_PENDING',
    DISPLAY_PRIOR_TICKET_STATUS_CLICK_FULFILLED: 'DISPLAY_PRIOR_TICKET_STATUS_CLICK_FULFILLED',

    DELETE_PORTFORLIO_IMAGES: 'DELETE_PORTFORLIO_IMAGES',
    DELETE_PORTFORLIO_IMAGES_PENDING: 'DELETE_PORTFORLIO_IMAGES_PENDING',
    DELETE_PORTFORLIO_IMAGES_FULFILLED: 'DELETE_PORTFORLIO_IMAGES_FULFILLED',

    DELETE_TICKET: 'DELETE_TICKET',
    DELETE_TICKET_PENDING: 'DELETE_TICKET_PENDING',
    DELETE_TICKET_FULFILLED: 'DELETE_TICKET_FULFILLED',

    RESOLVE_TICKET: 'RESOLVE_TICKET',
    RESOLVE_TICKET_PENDING: 'RESOLVE_TICKET_PENDING',
    RESOLVE_TICKET_FULFILLED: 'RESOLVE_TICKET_FULFILLED',

    CANCEL_TICKET: 'CANCEL_TICKET',
    CANCEL_TICKET_PENDING: 'CANCEL_TICKET_PENDING',
    CANCEL_TICKET_FULFILLED: 'CANCEL_TICKET_FULFILLED',

    GET_INSPECTIONS_LIST: 'GET_INSPECTIONS_LIST',
    GET_INSPECTIONS_LIST_PENDING: 'GET_INSPECTIONS_LIST_PENDING',
    GET_INSPECTIONS_LIST_FULFILLED: 'GET_INSPECTIONS_LIST_FULFILLED',

    GET_PENDING_INSPECTIONS_LIST: 'GET_PENDING_INSPECTIONS_LIST',
    GET_PENDING_INSPECTIONS_LIST_PENDING: 'GET_PENDING_INSPECTIONS_LIST_PENDING',
    GET_PENDING_INSPECTIONS_LIST_FULFILLED: 'GET_PENDING_INSPECTIONS_LIST_FULFILLED',


    GET_MYINSPECTIONS_LIST: 'GET_MYINSPECTIONS_LIST',
    GET_MYINSPECTIONS_LIST_PENDING: 'GET_MYINSPECTIONS_LIST_PENDING',
    GET_MYINSPECTIONS_LIST_FULFILLED: 'GET_MYINSPECTIONS_LIST_FULFILLED',

    GET_DASHBOARD_INSPECTIONS_LIST: 'GET_DASHBOARD_INSPECTIONS_LIST',
    GET_DASHBOARD_INSPECTIONS_LIST_PENDING: 'GET_DASHBOARD_INSPECTIONS_LIST_PENDING',
    GET_DASHBOARD_INSPECTIONS_LIST_FULFILLED: 'GET_DASHBOARD_INSPECTIONS_LIST_FULFILLED',

    GET_SEARCHED_INSPECTIONS_LIST: 'GET_SEARCHED_INSPECTIONS_LIST',
    GET_SEARCHED_INSPECTIONS_LIST_PENDING: 'GET_SEARCHED_INSPECTIONS_LIST_PENDING',
    GET_SEARCHED_INSPECTIONS_LIST_FULFILLED: 'GET_SEARCHED_INSPECTIONS_LIST_FULFILLED',

    RESET_SEARCH_OR_RESET: 'RESET_SEARCH_OR_RESET',

    NEW_INSPECTION_QUOTE_CLICKED: 'NEW_INSPECTION_QUOTE_CLICKED',

    NEW_INSPECTION_TICKET_CLICKED: 'NEW_INSPECTION_TICKET_CLICKED',

    SIGNATURE_PAD_CLICKED: 'SIGNATURE_PAD_CLICKED',

    SEARCH_INSPECTIONS: 'SEARCH_INSPECTIONS',
    SEARCH_INSPECTIONS_PENDING: 'SEARCH_INSPECTIONS_PENDING',
    SEARCH_INSPECTIONS_FULFILLED: 'SEARCH_INSPECTIONS_FULFILLED',

    GET_INSPECTION: 'GET_INSPECTION',
    GET_INSPECTION_PENDING: 'GET_INSPECTION_PENDING',
    GET_INSPECTION_FULFILLED: 'GET_INSPECTION_FULFILLED',

    GET_INSPECTION_REPORTS: 'GET_INSPECTION_REPORTS',
    GET_INSPECTION_REPORTS_PENDING: 'GET_INSPECTION_REPORTS_PENDING',
    GET_INSPECTION_REPORTS_FULFILLED: 'GET_INSPECTION_REPORTS_FULFILLED',


    GET_INSPECTION_REPORTS_CSV: 'GET_INSPECTION_REPORTS_CSV',
    GET_INSPECTION_REPORTS_CSV_PENDING: 'GET_INSPECTION_REPORTS_CSV_PENDING',
    GET_INSPECTION_REPORTS_CSV_FULFILLED: 'GET_INSPECTION_REPORTS_CSV_FULFILLED',

    DELETE_INSPECTION: 'DELETE_INSPECTION',
    DELETE_INSPECTION_PENDING: 'DELETE_INSPECTION_PENDING',
    DELETE_INSPECTION_FULFILLED: 'DELETE_INSPECTION_FULFILLED',

    GET_LINEITEMS_PRESIGNED_URLS: 'GET_LINEITEMS_PRESIGNED_URLS',
    GET_LINEITEMS_PRESIGNED_URLS_PENDING: 'GET_LINEITEMS_PRESIGNED_URLS_PENDING',
    GET_LINEITEMS_PRESIGNED_URLS_FULFILLED: 'GET_LINEITEMS_PRESIGNED_URLS_FULFILLED',

    SET_INSPECTION_FORM: 'SET_INSPECTION_FORM',

    KPI_RESET_CLICKED: 'KPI_RESET_CLICKED',

    ADD_PERFORM_INSPECTION: 'ADD_PERFORM_INSPECTION',
    ADD_PERFORM_INSPECTION_PENDING: 'ADD_PERFORM_INSPECTION_PENDING',
    ADD_PERFORM_INSPECTION_FULFILLED: 'ADD_PERFORM_INSPECTION_FULFILLED',

    UPDATE_INSPECTION: 'UPDATE_INSPECTION',
    UPDATE_INSPECTION_PENDING: 'UPDATE_INSPECTION_PENDING',
    UPDATE_INSPECTION_FULFILLED: 'UPDATE_INSPECTION_FULFILLED',


    UPDATE_PENDING_INSPECTION: 'UPDATE_PENDING_INSPECTION',
    UPDATE_PENDING_INSPECTION_PENDING: 'UPDATE_PENDING_INSPECTION_PENDING',
    UPDATE_PENDING_INSPECTION_FULFILLED: 'UPDATE_PENDING_INSPECTION_FULFILLED',

    SET_INSPECTION: 'SET_INSPECTION',

    SET_LATEST_INSPECTION: 'SET_LATEST_INSPECTION',

    SET_INSPECTION_LOADING_FALSE: 'SET_INSPECTION_LOADING_FALSE',

    SET_CSV_REPORT: 'SET_CSV_REPORT',

    EDIT_INSPECTION_CLICKED: 'EDIT_INSPECTION_CLICKED',

    GET_QUOTES_CSV_REPORTS: 'GET_QUOTES_CSV_REPORTS',
    GET_QUOTES_CSV_REPORTS_PENDING: 'GET_QUOTES_CSV_REPORTS_PENDING',
    GET_QUOTES_CSV_REPORTS_FULFILLED: 'GET_QUOTES_CSV_REPORTS_FULFILLED',

    GET_INSPECTION_FORMS: 'GET_INSPECTION_FORMS',
    GET_INSPECTION_FORMS_PENDING: 'GET_INSPECTION_FORMS_PENDING',
    GET_INSPECTION_FORMS_FULFILLED: 'GET_INSPECTION_FORMS_FULFILLED',

    GET_INSPECTION_FORMS_FOR_JOB: 'GET_INSPECTION_FORMS_FOR_JOB',
    GET_INSPECTION_FORMS_FOR_JOB_PENDING: 'GET_INSPECTION_FORMS_FOR_JOB_PENDING',
    GET_INSPECTION_FORMS_FOR_JOB_FULFILLED: 'GET_INSPECTION_FORMS_FOR_JOB_FULFILLED',

    GET_INSPECTION_FORM_LIST: 'GET_INSPECTION_FORM_LIST',
    GET_INSPECTION_FORM_LIST_PENDING: 'GET_INSPECTION_FORM_LIST_PENDING',
    GET_INSPECTION_FORM_LIST_FULFILLED: 'GET_INSPECTION_FORM_LIST_FULFILLED',

    ADD_NEW_INSPECTION_FORM: 'ADD_NEW_INSPECTION_FORM',
    ADD_NEW_INSPECTION_FORM_PENDING: 'ADD_NEW_INSPECTION_FORM_PENDING',
    ADD_NEW_INSPECTION_FORM_FULFILLED: 'ADD_NEW_INSPECTION_FORM_FULFILLED',

    EDIT_INSPECTION_FORM_CLICKED: 'EDIT_INSPECTION_FORM_CLICKED',

    UPDATE_INSPECTION_FORM: 'UPDATE_INSPECTION_FORM',
    UPDATE_INSPECTION_FORM_PENDING: 'UPDATE_INSPECTION_FORM_PENDING',
    UPDATE_INSPECTION_FORM_FULFILLED: 'UPDATE_INSPECTION_FORM_FULFILLED',

    UPDATE_INSPECTION_FORM_IMAGES: 'UPDATE_INSPECTION_FORM_IMAGES',
    UPDATE_INSPECTION_FORM_IMAGES_PENDING: 'UPDATE_INSPECTION_FORM_IMAGES_PENDING',
    UPDATE_INSPECTION_FORM_IMAGES_FULFILLED: 'UPDATE_INSPECTION_FORM_IMAGES_FULFILLED',

    GET_EDIT_INSPECTION_FORM: 'GET_EDIT_INSPECTION_FORM',
    GET_EDIT_INSPECTION_FORM_PENDING: 'GET_EDIT_INSPECTION_FORM_PENDING',
    GET_EDIT_INSPECTION_FORM_FULFILLED: 'GET_EDIT_INSPECTION_FORM_FULFILLED',

    DELETE_INSPECTION_FORM: 'DELETE_INSPECTION_FORM',
    DELETE_INSPECTION_FORM_PENDING: 'DELETE_INSPECTION_FORM_PENDING',
    DELETE_INSPECTION_FORM_FULFILLED: 'DELETE_INSPECTION_FORM_FULFILLED',

    DELETE_INSPECTION_FORM_SECTION: 'DELETE_INSPECTION_FORM_SECTION',
    DELETE_INSPECTION_FORM_LINE_ITEM: 'DELETE_INSPECTION_FORM_LINE_ITEM',

    REORDER_INSPECTION_FORM: 'REORDER_INSPECTION_FORM',

    GET_RATINGS: 'GET_RATINGS',
    GET_RATINGS_PENDING: 'GET_RATINGS_PENDING',
    GET_RATINGS_FULFILLED: 'GET_RATINGS_FULFILLED',

    ADD_NEW_RATING: 'ADD_NEW_RATING',
    ADD_NEW_RATING_PENDING: 'ADD_NEW_RATING_PENDING',
    ADD_NEW_RATING_FULFILLED: 'ADD_NEW_RATING_FULFILLED',

    EDIT_RATING_CLICKED: 'EDIT_RATING_CLICKED',

    GET_EDIT_RATING: 'GET_EDIT_RATING',
    GET_EDIT_RATING_PENDING: 'GET_EDIT_RATING_PENDING',
    GET_EDIT_RATING_FULFILLED: 'GET_EDIT_RATING_FULFILLED',

    UPDATE_RATING: 'UPDATE_RATING',
    UPDATE_RATING_PENDING: 'UPDATE_RATING_PENDING',
    UPDATE_RATING_FULFILLED: 'UPDATE_RATING_FULFILLED',

    DELETE_RATING_LINE_ITEM: 'DELETE_RATING_LINE_ITEM',
    DELETE_RATING_LINE_ITEM_PENDING: 'DELETE_RATING_LINE_ITEM_PENDING',
    DELETE_RATING_LINE_ITEM_FULFILLED: 'DELETE_RATING_LINE_ITEM_FULFILLED',

    DELETE_RATING: 'DELETE_RATING',
    DELETE_RATING_PENDING: 'DELETE_RATING_PENDING',
    DELETE_RATING_FULFILLED: 'DELETE_RATING_FULFILLED',

    GET_ALL_POSITIONS: 'GET_ALL_POSITIONS',
    GET_ALL_POSITIONS_PENDING: 'GET_ALL_POSITIONS_PENDING',
    GET_ALL_POSITIONS_FULFILLED: 'GET_ALL_POSITIONS_FULFILLED',

    NEW_USER_CLICKED: 'NEW_USER_CLICKED',

    GET_COMPANIES: 'GET_COMPANIES',
    GET_COMPANIES_PENDING: 'GET_COMPANIES_PENDING',
    GET_COMPANIES_FULFILLED: 'GET_COMPANIES_FULFILLED',

    GET_DROPDOWN_COMPANIES: 'GET_DROPDOWN_COMPANIES',
    GET_DROPDOWN_COMPANIES_PENDING: 'GET_DROPDOWN_COMPANIES_PENDING',
    GET_DROPDOWN_COMPANIES_FULFILLED: 'GET_DROPDOWN_COMPANIES_FULFILLED',

    SEARCH_COMPANIE: 'SEARCH_COMPANIE',
    SEARCH_COMPANIE_PENDING: 'SEARCH_COMPANIE_PENDING',
    SEARCH_COMPANIE_FULFILLED: 'SEARCH_COMPANIE_FULFILLED',

    GET_ALL_NON_ASSOCIATED_COMPANY_ACCOUNTS: 'GET_ALL_NON_ASSOCIATED_COMPANY_ACCOUNTS',
    GET_NON_ASSOCIATED_COMPANY_ACCOUNTS: 'GET_NON_ASSOCIATED_COMPANY_ACCOUNTS',
    GET_NON_ASSOCIATED_COMPANY_ACCOUNTS_PENDING: 'GET_NON_ASSOCIATED_COMPANY_ACCOUNTS_PENDING',
    GET_NON_ASSOCIATED_COMPANY_ACCOUNTS_FULFILLED: 'GET_NON_ASSOCIATED_COMPANY_ACCOUNTS_FULFILLED',

    ADD_NEW_COMPANY: 'ADD_NEW_COMPANY',
    ADD_NEW_COMPANY_PENDING: 'ADD_NEW_COMPANY_PENDING',
    ADD_NEW_COMPANY_FULFILLED: 'ADD_NEW_COMPANY_FULFILLED',

    EDIT_COMPANY_CLICKED: 'EDIT_COMPANY_CLICKED',
    UPDATE_COMPANY: 'UPDATE_COMPANY',
    UPDATE_COMPANY_PENDING: 'UPDATE_COMPANY_PENDING',
    UPDATE_COMPANY_FULFILLED: 'UPDATE_COMPANY_FULFILLED',


    GET_EDIT_COMPANY: 'GET_EDIT_COMPANY',
    GET_EDIT_COMPANY_PENDING: 'GET_EDIT_COMPANY_PENDING',
    GET_EDIT_COMPANY_FULFILLED: 'GET_EDIT_COMPANY_FULFILLED',

    DELETE_COMPANY: 'DELETE_COMPANY',
    DELETE_COMPANY_PENDING: 'DELETE_COMPANY_PENDING',
    DELETE_COMPANY_FULFILLED: 'DELETE_COMPANY_FULFILLED',

    PREVIEW_COMPANY_USERS_CLICKED: 'PREVIEW_COMPANY_USERS_CLICKED',

    GET_ALL_COMPANY_USERS: 'GET_ALL_COMPANY_USERS',
    GET_COMPANY_USERS: 'GET_COMPANY_USERS',
    GET_COMPANY_USERS_PENDING: 'GET_COMPANY_USERS_PENDING',
    GET_COMPANY_USERS_FULFILLED: 'GET_COMPANY_USERS_FULFILLED',

    OPEN_ASSOCIATE_COMPANY_USER_CLICKED: 'OPEN_ASSOCIATE_COMPANY_USER_CLICKED',
    // OPEN_ASSOCIATE_COMPANY_MODAL: 'OPEN_ASSOCIATE_COMPANY_MODAL',
    CLOSE_COMPANY_MODAL: 'CLOSE_COMPANY_MODAL',

    UPDATE_FILTERED_COMPANY_USERS: 'UPDATE_FILTERED_COMPANY_USERS',

    GET_FILTERED_COMPANY_USERS: 'GET_FILTERED_COMPANY_USERS',
    GET_FILTERED_COMPANY_USERS_PENDING: 'GET_FILTERED_COMPANY_USERS_PENDING',
    GET_FILTERED_COMPANY_USERS_FULFILLED: 'GET_FILTERED_COMPANY_USERS_FULFILLED',

    ADD_COMPANY_USERS: 'ADD_COMPANY_USERS',
    ADD_COMPANY_USERS_PENDING: 'ADD_COMPANY_USERS_PENDING',
    ADD_COMPANY_USERS_FULFILLED: 'ADD_COMPANY_USERS_FULFILLED',

    DELETE_COMPANY_USER: 'DELETE_COMPANY_USER',
    DELETE_COMPANY_USER_PENDING: 'DELETE_COMPANY_USER_PENDING',
    DELETE_COMPANY_USER_FULFILLED: 'DELETE_COMPANY_USER_FULFILLED',

    GET_USERS: 'GET_USERS',
    GET_USERS_PENDING: 'GET_USERS_PENDING',
    GET_USERS_FULFILLED: 'GET_USERS_FULFILLED',

    GET_DROPDOWN_USERS_LIST: 'GET_DROPDOWN_USERS_LIST',
    GET_DROPDOWN_USERS_LIST_PENDING: 'GET_DROPDOWN_USERS_LIST_PENDING',
    GET_DROPDOWN_USERS_LIST_FULFILLED: 'GET_DROPDOWN_USERS_LIST_FULFILLED',

    GET_USER_PRESIGNED_URL: 'GET_USER_PRESIGNED_URL',
    GET_USER_PRESIGNED_URL_PENDING: 'GET_USER_PRESIGNED_URL_PENDING',

    ADD_NEW_USER: 'ADD_NEW_USER',
    ADD_NEW_USER_PENDING: 'ADD_NEW_USER_PENDING',
    ADD_NEW_USER_FULFILLED: 'ADD_NEW_USER_FULFILLED',

    DELETE_USER: 'DELETE_USER',
    DELETE_USER_PENDING: 'DELETE_USER_PENDING',
    DELETE_USER_FULFILLED: 'DELETE_USER_FULFILLED',

    EDIT_USER_CLICKED: 'EDIT_USER_CLICKED',

    EMAIL_PREFERENCES_CLICKED: 'EMAIL_PREFERENCES_CLICKED',

    UPDATE_USER: 'UPDATE_USER',
    UPDATE_USER_PENDING: 'UPDATE_USER_PENDING',
    UPDATE_USER_FULFILLED: 'UPDATE_USER_FULFILLED',

    GET_EDIT_USER: 'GET_EDIT_USER',
    GET_EDIT_USER_PENDING: 'GET_EDIT_USER_PENDING',
    GET_EDIT_USER_FULFILLED: 'GET_EDIT_USER_FULFILLED',

    UPDATE_USER_PREFERENCES: 'UPDATE_USER_PREFERENCES',
    UPDATE_USER_PREFERENCES_PENDING: 'UPDATE_USER_PREFERENCES_PENDING',
    UPDATE_USER_PREFERENCES_FULFILLED: 'UPDATE_USER_PREFERENCES_FULFILLED',

    GO_TO_PREVIEW_USER: 'GO_TO_PREVIEW_USER',

    UPDATE_USER_PAGE_SIZE_CAHANGE: 'UPDATE_USER_PAGE_SIZE_CAHANGE',
    UPDATE_USER_PAGE_SIZE_CAHANGE_PENDING: 'UPDATE_USER_PAGE_SIZE_CAHANGE_PENDING',
    UPDATE_USER_PAGE_SIZE_CAHANGE_FULFILLED: 'UPDATE_USER_PAGE_SIZE_CAHANGE_FULFILLED',

    GET_ACCOUNTS_PRESIGNED_URL: 'GET_ACCOUNTS_PRESIGNED_URL',
    GET_ACCOUNTS_PRESIGNED_URL_PENDING: 'GET_ACCOUNTS_PRESIGNED_URL_PENDING',

    GET_ACCOUNTS: 'GET_ACCOUNTS',
    GET_ACCOUNTS_PENDING: 'GET_ACCOUNTS_PENDING',
    GET_ACCOUNTS_FULFILLED: 'GET_ACCOUNTS_FULFILLED',

    GET_DROPDOWN_ACCOUNTS: 'GET_DROPDOWN_ACCOUNTS',
    GET_DROPDOWN_ACCOUNTS_PENDING: 'GET_DROPDOWN_ACCOUNTS_PENDING',
    GET_DROPDOWN_ACCOUNTS_FULFILLED: 'GET_DROPDOWN_ACCOUNTS_FULFILLED',

    ADD_NEW_ACCOUNT: 'ADD_NEW_ACCOUNT',
    ADD_NEW_ACCOUNT_PENDING: 'ADD_NEW_ACCOUNT_PENDING',
    ADD_NEW_ACCOUNT_FULFILLED: 'ADD_NEW_ACCOUNT_FULFILLED',

    UPLOAD_IMAGE_TO_S3: 'UPLOAD_IMAGE_TO_S3',
    UPLOAD_IMAGE_TO_S3_PENDING: 'UPLOAD_IMAGE_TO_S3_PENDING',
    UPLOAD_IMAGE_TO_S3_FULFILLED: 'UPLOAD_IMAGE_TO_S3_FULFILLED',

    UPLOAD_LINE_ITEMS_IMAGE_TO_S3: 'UPLOAD_LINE_ITEMS_IMAGE_TO_S3',
    UPLOAD_LINE_ITEMS_IMAGE_TO_S3_PENDING: 'UPLOAD_LINE_ITEMS_IMAGE_TO_S3_PENDING',
    UPLOAD_LINE_ITEMS_IMAGE_TO_S3_FULFILLED: 'UPLOAD_LINE_ITEMS_IMAGE_TO_S3_FULFILLED',

    UPDATE_ACCOUNT: 'UPDATE_ACCOUNT',
    UPDATE_ACCOUNT_PENDING: 'UPDATE_ACCOUNT_PENDING',
    UPDATE_ACCOUNT_FULFILLED: 'UPDATE_ACCOUNT_FULFILLED',

    GET_EDIT_ACCOUNT: 'GET_EDIT_ACCOUNT',
    GET_EDIT_ACCOUNT_PENDING: 'GET_EDIT_ACCOUNT_PENDING',
    GET_EDIT_ACCOUNT_FULFILLED: 'GET_EDIT_ACCOUNT_FULFILLED',

    UPDATE_ACCOUNTS_PAGE_SIZE_CHANGE: 'UPDATE_ACCOUNTS_PAGE_SIZE_CHANGE',
    UPDATE_ACCOUNTS_PAGE_SIZE_CHANGE_PENDING: 'UPDATE_ACCOUNTS_PAGE_SIZE_CHANGE_PENDING',
    UPDATE_ACCOUNTS_PAGE_SIZE_CHANGE_FULFILLED: 'UPDATE_ACCOUNTS_PAGE_SIZE_CHANGE_FULFILLED',

    PREVIEW_ACCOUNT_USERS: 'PREVIEW_ACCOUNT_USERS',

    SET_CURRENT_ACCOUNT: 'SET_CURRENT_ACCOUNT',

    UPDATE_PASSWORD_CLICKED: 'UPDATE_PASSWORD_CLICKED',

    ACCOUNT_DUPLICATE_MODAL_CLICKED: 'ACCOUNT_DUPLICATE_MODAL_CLICKED',

    USER_DUPLICATE_MODAL_CLICKED: 'USER_DUPLICATE_MODAL_CLICKED',

    INSPECTION_DUPLICATE_MODAL_CLICKED: 'INSPECTION_DUPLICATE_MODAL_CLICKED',

    CLOSE_PASSWORD_MODAL_CLICKED: 'CLOSE_PASSWORD_MODAL_CLICKED',

    OPEN_ASSOCIATE_ACCOUNT_USER_CLICKED: 'OPEN_ASSOCIATE_ACCOUNT_USER_CLICKED',

    OPEN_ASSOCIATE_ACCOUNT_HOLIDAYS_CLICKED: 'OPEN_ASSOCIATE_ACCOUNT_HOLIDAYS_CLICKED',

    UPDATE_PASSWORD_CLICKED: 'UPDATE_PASSWORD_CLICKED',

    CLOSE_PASSWORD_MODAL_CLICKED: 'CLOSE_PASSWORD_MODAL_CLICKED',

    CLOSE_ACCOUNT_MODAL: 'CLOSE_ACCOUNT_MODAL',

    OPEN_ASSOCIATE_ACCOUNT_JOBS_CLICKED: 'OPEN_ASSOCIATE_ACCOUNT_JOBS_CLICKED',

    UPDATE_FILTERED_ACCOUNTS_USERS: 'UPDATE_GET_FILTERED_ACCOUNTS_USERS',

    GET_FILTERED_ACCOUNTS_USERS: 'GET_FILTERED_ACCOUNTS_USERS',
    GET_FILTERED_ACCOUNTS_USERS_PENDING: 'GET_FILTERED_ACCOUNTS_USERS_PENDING',
    GET_FILTERED_ACCOUNTS_USERS_FULFILLED: 'GET_FILTERED_ACCOUNTS_USERS_FULFILLED',

    GET_ALL_ACCOUNT_USERS: 'GET_ALL_ACCOUNT_USERS',
    GET_ACCOUNT_USERS: 'GET_ACCOUNT_USERS',
    GET_ACCOUNT_USERS_PENDING: 'GET_ACCOUNT_USERS_PENDING',
    GET_ACCOUNT_USERS_FULFILLED: 'GET_ACCOUNT_USERS_FULFILLED',

    GET_ACCOUNT_USERS_INFO: 'GET_ACCOUNT_USERS_INFO',
    GET_ACCOUNT_USERS_INFO_PENDING: 'GET_ACCOUNT_USERS_INFO_PENDING',
    GET_ACCOUNT_USERS_INFO_FULFILLED: 'GET_ACCOUNT_USERS_INFO_FULFILLED',

    GET_ACCOUNT_USERS_INFO_PAGE: 'GET_ACCOUNT_USERS_INFO_PAGE',
    GET_ACCOUNT_USERS_INFO_PAGE_PENDING: 'GET_ACCOUNT_USERS_INFO_PAGE_PENDING',
    GET_ACCOUNT_USERS_INFO_PAGE_FULFILLED: 'GET_ACCOUNT_USERS_INFO_PAGE_FULFILLED',

    ADD_ACCOUNT_USERS: 'ADD_ACCOUNT_USERS',
    ADD_ACCOUNT_USERS_PENDING: 'ADD_ACCOUNT_USERS_PENDING',
    ADD_ACCOUNT_USERS_FULFILLED: 'ADD_ACCOUNT_USERS_FULFILLED',

    UPDATE_QUOTE_CLINET: 'UPDATE_QUOTE_CLINET',
    UPDATE_QUOTE_CLINET_PENDING: 'UPDATE_QUOTE_CLINET_PENDING',
    UPDATE_QUOTE_CLINET_FULFILLED: 'UPDATE_QUOTE_CLINET_FULFILLED',

    SUBMIT_ASSOCUATE_ACCOUNT_HOLIDAYS: 'SUBMIT_ASSOCUATE_ACCOUNT_HOLIDAYS',
    SUBMIT_ASSOCUATE_ACCOUNT_HOLIDAYS_PENDING: 'SUBMIT_ASSOCUATE_ACCOUNT_HOLIDAYS_PENDING',
    SUBMIT_ASSOCUATE_ACCOUNT_HOLIDAYS_FULFILLED: 'SUBMIT_ASSOCUATE_ACCOUNT_HOLIDAYS_FULFILLED',

    SUBMIT_ASSOCIATE_ACCOUNT_JOBS: 'SUBMIT_ASSOCIATE_ACCOUNT_JOBS',
    SUBMIT_ASSOCIATE_ACCOUNT_JOBS_PENDING: 'SUBMIT_ASSOCIATE_ACCOUNT_JOBS_PENDING',
    SUBMIT_ASSOCIATE_ACCOUNT_JOBS_FULFILLED: 'SUBMIT_ASSOCIATE_ACCOUNT_JOBS_FULFILLED',

    GET_ACCOUNT_HOLIDAYS: 'GET_ACCOUNT_HOLIDAYS',
    GET_ACCOUNT_HOLIDAYS_PENDING: 'GET_ACCOUNT_HOLIDAYS_PENDING',
    GET_ACCOUNT_HOLIDAYS_FULFILLED: 'GET_ACCOUNT_HOLIDAYS_FULFILLED',

    DELETE_ACCOUNT_USERS: 'DELETE_ACCOUNT_USERS',
    DELETE_ACCOUNT_USERS_PENDING: 'DELETE_ACCOUNT_USERS_PENDING',
    DELETE_ACCOUNT_USERS_FULFILLED: 'DELETE_ACCOUNT_USERS_FULFILLED',

    GET_REGION: 'GET_REGION',
    GET_REGION_PENDING: 'GET_REGION_PENDING',
    GET_REGION_FULFILLED: 'GET_REGION_FULFILLED',

    UPDATE_TOTAL_SALE_LISTS: 'UPDATE_TOTAL_SALE_LISTS',

    DELETE_ACCOUNT: 'DELETE_ACCOUNT',
    DELETE_ACCOUNT_PENDING: 'DELETE_ACCOUNT_PENDING',
    DELETE_ACCOUNT_FULFILLED: 'DELETE_ACCOUNT_FULFILLED',

    GET_QUOTES: 'GET_QUOTES',
    GET_QUOTES_PENDING: 'GET_QUOTES_PENDING',
    GET_QUOTES_FULFILLED: 'GET_QUOTES_FULFILLED',

    UPDATE_TOTAL_SALE_ARRAY: 'UPDATE_TOTAL_SALE_ARRAY',

    GET_REFRESH_QUOTE: 'GET_REFRESH_QUOTE',
    GET_REFRESH_QUOTE_PENDING: 'GET_REFRESH_QUOTE_PENDING',
    GET_REFRESH_QUOTE_FULFILLED: 'GET_REFRESH_QUOTE_FULFILLED',

    RESEND_QUOTE_EMAIL_CLICKED: 'RESEND_QUOTE_EMAIL_CLICKED',
    RESEND_QUOTE_EMAIL: 'RESEND_QUOTE_EMAIL',
    RESEND_QUOTE_EMAIL_PENDING: 'RESEND_QUOTE_EMAIL_PENDING',
    RESEND_QUOTE_EMAIL_FULFILLED: 'RESEND_QUOTE_EMAIL_FULFILLED',

    INVENTARY_CSV_CLICKED: 'INVENTARY_CSV_CLICKED',
    INVENTARY_CSV: 'INVENTARY_CSV',
    INVENTARY_CSV_PENDING: 'INVENTARY_CSV_PENDING',
    INVENTARY_CSV_FULFILLED: 'INVENTARY_CSV_FULFILLED',

    GET_ACCOUNTS_DROP_DOWN_LIST: 'GET_ACCOUNTS_DROP_DOWN_LIST',
    GET_ACCOUNTS_DROP_DOWN_LIST_PENDING: 'GET_ACCOUNTS_DROP_DOWN_LIST_PENDING',
    GET_ACCOUNTS_DROP_DOWN_LIST_FULFILLED: 'GET_ACCOUNTS_DROP_DOWN_LIST_FULFILLED',

    GET_CUSTOMERS: 'GET_CUSTOMERS',
    GET_CUSTOMERS_PENDING: 'GET_CUSTOMERS_PENDING',
    GET_CUSTOMERS_FULFILLED: 'GET_CUSTOMERS_FULFILLED',

    GET_QUOTE_LOG: 'GET_QUOTE_LOG',
    GET_QUOTE_LOG_PENDING: 'GET_QUOTE_LOG_PENDING',
    GET_QUOTE_LOG_FULFILLED: 'GET_QUOTE_LOG_FULFILLED',

    ADD_NEW_QUOTE: 'ADD_NEW_QUOTE',
    ADD_NEW_QUOTE_PENDING: 'ADD_NEW_QUOTE_PENDING',
    ADD_NEW_QUOTE_FULFILLED: 'ADD_NEW_QUOTE_FULFILLED',

    EDIT_QUOTE_CLICKED: 'EDIT_QUOTE_CLICKED',

    UPDATE_QUOTE: 'UPDATE_QUOTE',
    UPDATE_QUOTE_PENDING: 'UPDATE_QUOTE_PENDING',
    UPDATE_QUOTE_FULFILLED: 'UPDATE_QUOTE_FULFILLED',

    DELETE_QUOTE: 'DELETE_QUOTE',
    DELETE_QUOTE_PENDING: 'DELETE_QUOTE_PENDING',
    DELETE_QUOTE_FULFILLED: 'DELETE_QUOTE_FULFILLED',

    UPDATE_QUOTES_PAGE_SIZE: 'UPDATE_QUOTES_PAGE_SIZE',
    UPDATE_QUOTES_PAGE_SIZE_PENDING: 'UPDATE_QUOTES_PAGE_SIZE_PENDING',
    UPDATE_QUOTES_PAGE_SIZE_FULFILLED: 'UPDATE_QUOTES_PAGE_SIZE_FULFILLED',

    OPEN_REJECT_MODEL: 'OPEN_REJECT_MODEL',
    CLOSE_REJECT_MODEL: 'CLOSE_REJECT_MODEL',

    APPROVE_OR_REJECT_QUOTE: 'APPROVE_OR_REJECT_QUOTE',
    APPROVE_OR_REJECT_QUOTE_PENDING: 'APPROVE_OR_REJECT_QUOTE_PENDING',
    APPROVE_OR_REJECT_QUOTE_FULFILLED: 'APPROVE_OR_REJECT_QUOTE_FULFILLED',

    GOTO_APPROVE_QUOTE: 'GOTO_APPROVE_QUOTE',

    VIEW_FILTERED_QUOTES: 'VIEW_FILTERED_QUOTES',
    VIEW_FILTERED_QUOTES_PENDING: 'VIEW_FILTERED_QUOTES_PENDING',
    VIEW_FILTERED_QUOTES_FULFILLED: 'VIEW_FILTERED_QUOTES_FULFILLED',

    EDIT_ACCOUNT_CLICKED: 'EDIT_ACCOUNT_CLICKED',
    Add_JOB_USERS_CLICKED: 'Add_JOB_USERS_CLICKED',

    RESET_OBJECTS: 'RESET_OBJECTS',

    SEARCH_QUOTES: 'SEARCH_QUOTES',
    SEARCH_QUOTES_PENDING: 'SEARCH_QUOTES_PENDING',
    SEARCH_QUOTES_FULFILLED: 'SEARCH_QUOTES_FULFILLED',

    SEARCH_COMPANY: 'SEARCH_COMPANY',
    // SEARCH_QUOTES: 'SEARCH_QUOTES',
    SEARCH_USERS: 'SEARCH_USERS',
    SEARCH_USERS_PENDING: 'SEARCH_USERS_PENDING',
    SEARCH_USERS_FULFILLED: 'SEARCH_USERS_FULFILLED',

    SEARCH_TICKETS: 'SEARCH_TICKETS',
    SEARCH_TICKETS_PENDING: 'SEARCH_TICKETS_PENDING',
    SEARCH_TICKETS_FULFILLED: 'SEARCH_TICKETS_FULFILLED',

    SEARCH_PRIOR_ESCALATION_TICKETS: 'SEARCH_PRIOR_ESCALATION_TICKETS',
    SEARCH_PRIOR_ESCALATION_TICKETS_PENDING: 'SEARCH_PRIOR_ESCALATION_TICKETS_PENDING',
    SEARCH_PRIOR_ESCALATION_TICKETS_FULFILLED: 'SEARCH_PRIOR_ESCALATION_TICKETS_FULFILLED',

    SEARCH_ACCOUNTS: 'SEARCH_ACCOUNTS',
    SEARCH_ACCOUNTS_PENDING: 'SEARCH_ACCOUNTS_PENDING',
    SEARCH_ACCOUNTS_FULFILLED: 'SEARCH_ACCOUNTS_FULFILLED',

    SEARCH_ACCOUNT_USERS: 'SEARCH_ACCOUNT_USERS',
    SEARCH_ACCOUNT_USERS_PENDING: 'SEARCH_ACCOUNT_USERS_PENDING',
    SEARCH_ACCOUNT_USERS_FULFILLED: 'SEARCH_ACCOUNT_USERS_FULFILLED',

    SEARCH_COMPANY_USERS: 'SEARCH_COMPANY_USERS',
    SEARCH_COMPANY_USERS_PENDING: 'SEARCH_COMPANY_USERS_PENDING',
    SEARCH_COMPANY_USERS_FULFILLED: 'SEARCH_COMPANY_USERS_FULFILLED',


    SEARCH_INSPECTION_FORM: 'SEARCH_INSPECTION_FORM',
    SEARCH_RATING: 'SEARCH_RATING',
    SEARCH_INSPECTION: 'SEARCH_INSPECTION',

    // SEARCH_CATEGORY: 'SEARCH_CATEGORY',
    // SEARCH_USERS_PENDING: 'SEARCH_USERS_PENDING',
    // SEARCH_USERS_FULFILLED: 'SEARCH_USERS_FULFILLED',

    // SEARCH_ACCOUNT: 'SEARCH_ACCOUNT',
    // SEARCH_ACCOUNT_PENDING: 'SEARCH_ACCOUNT_PENDING',
    // SEARCH_ACCOUNT_FULFILLED: 'SEARCH_ACCOUNT_FULFILLED',

    OPEN_DELETE_MODEL: 'OPEN_DELETE_MODEL',
    OPEN_CONFIRM_DELETE_MODEL: 'OPEN_CONFIRM_DELETE_MODEL',
    OPEN_CONFIRM_SCHEDULE_DELETE_MODEL: 'OPEN_CONFIRM_SCHEDULE_DELETE_MODEL',
    CLOSE_DELETE_CLOSE: 'CLOSE_DELETE_CLOSE',

    OPEN_APPROVE_MODEL: 'OPEN_APPROVE_MODEL',
    CLOSE_APPROVE_CLOSE: 'CLOSE_APPROVE_CLOSE',

    OPEN_MODEL: 'OPEN_MODEL',
    CLOSE_MODEL: 'CLOSE_MODEL',

    ADD_NEW_POSITION: 'ADD_NEW_POSITION',
    ADD_NEW_POSITION_PENDING: 'ADD_NEW_POSITION_PENDING',
    ADD_NEW_POSITION_FULFILLED: 'ADD_NEW_POSITION_FULFILLED',

    EDIT_POSITION_CLICKED: 'EDIT_POSITION_CLICKED',

    UPDATE_POSITION: 'UPDATE_POSITION',
    UPDATE_POSITION_PENDING: 'UPDATE_POSITION_PENDING',
    UPDATE_POSITION_FULFILLED: 'UPDATE_POSITION_FULFILLED',

    DELETE_POSITION: 'DELETE_POSITION',
    DELETE_POSITION_PENDING: 'DELETE_POSITION_PENDING',
    DELETE_POSITION_FULFILLED: 'DELETE_POSITION_FULFILLED',

    ADD_NEW_REGION: 'ADD_NEW_REGION',
    ADD_NEW_REGION_PENDING: 'ADD_NEW_REGION_PENDING',
    ADD_NEW_REGION_FULFILLED: 'ADD_NEW_REGION_FULFILLED',

    EDIT_REGION_CLICKED: 'EDIT_REGION_CLICKED',

    UPDATE_REGION: 'UPDATE_REGION',
    UPDATE_REGION_PENDING: 'UPDATE_REGION_PENDING',
    UPDATE_REGION_FULFILLED: 'UPDATE_REGION_FULFILLED',

    DELETE_REGION: 'DELETE_REGION',
    DELETE_REGION_PENDING: 'DELETE_REGION_PENDING',
    DELETE_REGION_FULFILLED: 'DELETE_REGION_FULFILLED',

    MAKE_OBJECTS_EMPTY: 'MAKE_OBJECTS_EMPTY',

    GET_AREA_TYPES: 'GET_AREA_TYPES',
    GET_AREA_TYPES_PENDING: 'GET_AREA_TYPES_PENDING',
    GET_AREA_TYPES_FULFILLED: 'GET_AREA_TYPES_FULFILLED',

    GET_ALL_DEFICIENCIES: 'GET_ALL_DEFICIENCIES',
    GET_ALL_DEFICIENCIES_PENDING: 'GET_ALL_DEFICIENCIES_PENDING',
    GET_ALL_DEFICIENCIES_FULFILLED: 'GET_ALL_DEFICIENCIES_FULFILLED',

    CREATE_AREA: 'CREATE_AREA',
    CREATE_AREA_PENDING: 'CREATE_AREA_PENDING',
    CREATE_AREA_FULFILLED: 'CREATE_AREA_FULFILLED',

    GET_LOGBOOK_AREA_PREVIEW: 'GET_LOGBOOK_AREA_PREVIEW',
    GET_LOGBOOK_AREA_PREVIEW_PENDING: 'GET_LOGBOOK_AREA_PREVIEW_PENDING',
    GET_LOGBOOK_AREA_PREVIEW_FULFILLED: 'GET_LOGBOOK_AREA_PREVIEW_FULFILLED',

    GET_LOGBOOK_LOGS_AREA_PREVIEW: 'GET_LOGBOOK_LOGS_AREA_PREVIEW',
    GET_LOGBOOK_LOGS_AREA_PREVIEW_PENDING: 'GET_LOGBOOK_LOGS_AREA_PREVIEW_PENDING',
    GET_LOGBOOK_LOGS_AREA_PREVIEW_FULFILLED: 'GET_LOGBOOK_LOGS_AREA_PREVIEW_FULFILLED',

    PREVIEW_LOGBOOK_AREA_QR_SCAN_CLICKED: 'PREVIEW_LOGBOOK_AREA_QR_SCAN_CLICKED',

    GET_AREAS: 'GET_AREAS',
    GET_AREAS_PENDING: 'GET_AREAS_PENDING',
    GET_AREAS_FULFILLED: 'GET_AREAS_FULFILLED',


    GET_ALL_LOGBOOK_AREA: 'GET_ALL_LOGBOOK_AREA',
    GET_ALL_LOGBOOK_AREA_PENDING: 'GET_ALL_LOGBOOK_AREA_PENDING',
    GET_ALL_LOGBOOK_AREA_FULFILLED: 'GET_ALL_LOGBOOK_AREA_FULFILLED',

    GET_ALL_LOGBOOK: 'GET_ALL_LOGBOOK',
    GET_ALL_LOGBOOK_PENDING: 'GET_ALL_LOGBOOK_PENDING',
    GET_ALL_LOGBOOK_FULFILLED: 'GET_ALL_LOGBOOK_FULFILLED',

    LOGBOOK_AREA_PAGE_CLICKED: 'LOGBOOK_AREA_PAGE_CLICKED',

    GET_AREA: 'GET_AREA',
    GET_AREA_PENDING: 'GET_AREA_PENDING',
    GET_AREA_FULFILLED: 'GET_AREA_FULFILLED',

    CLEAR_AREA: 'CLEAR_AREA',

    UPDATE_AREA: 'UPDATE_AREA',
    UPDATE_AREA_PENDING: 'UPDATE_AREA_PENDING',
    UPDATE_AREA_FULFILLED: 'UPDATE_AREA_FULFILLED',

    DELETE_AREA: 'DELETE_AREA',
    DELETE_AREA_PENDING: 'DELETE_AREA_PENDING',
    DELETE_AREA_FULFILLED: 'DELETE_AREA_FULFILLED',

    DELETE_LOGBOOK_AREA: 'DELETE_LOGBOOK_AREA',
    DELETE_LOGBOOK_AREA_PENDING: 'DELETE_LOGBOOK_AREA_PENDING',
    DELETE_LOGBOOK_AREA_FULFILLED: 'DELETE_LOGBOOK_AREA_FULFILLED',

    DELETE_SCHEDULED_TICKET: 'DELETE_SCHEDULED_TICKET',
    DELETE_SCHEDULED_TICKET_PENDING: 'DELETE_SCHEDULED_TICKET_PENDING',
    DELETE_SCHEDULED_TICKET_FULFILLED: 'DELETE_SCHEDULED_TICKET_FULFILLED',

    IMPORT_AREA: 'IMPORT_AREA',
    IMPORT_AREA_PENDING: 'IMPORT_AREA_PENDING',
    IMPORT_AREA_REJECTED: 'IMPORT_AREA_REJECTED',
    IMPORT_AREA_FULFILLED: 'IMPORT_AREA_FULFILLED',

    IMPORT_CHECK_AREA: 'IMPORT_CHECK_AREA',
    IMPORT_CHECK_AREA_PENDING: 'IMPORT_CHECK_AREA_PENDING',
    IMPORT_CHECK_AREA_REJECTED: 'IMPORT_CHECK_AREA_REJECTED',
    IMPORT_CHECK_AREA_FULFILLED: 'IMPORT_CHECK_AREA_FULFILLED',

    GET_AREA_BY_QR: 'GET_AREA_BY_QR',
    GET_AREA_BY_QR_PENDING: 'GET_AREA_BY_QR_PENDING',
    GET_AREA_BY_QR_REJECTED: 'GET_AREA_BY_QR_REJECTED',
    GET_AREA_BY_QR_FULFILLED: 'GET_AREA_BY_QR_FULFILLED',

    SEARCH_HISTORY: 'SEARCH_HISTORY',
    SEARCH_HISTORY_PENDING: 'SEARCH_HISTORY_PENDING',
    SEARCH_HISTORY_REJECTED: 'SEARCH_HISTORY_REJECTED',
    SEARCH_HISTORY_FULFILLED: 'SEARCH_HISTORY_FULFILLED',

    CREATE_AREA_TASK: 'CREATE_AREA_TASK',
    CREATE_AREA_TASK_PENDING: 'CREATE_AREA_TASK_PENDING',
    CREATE_AREA_TASK_FULFILLED: 'CREATE_AREA_TASK_FULFILLED',

    GET_FRONTLINE_LIST: "GET_FRONTLINE_LIST",
    GET_FRONTLINE_LIST_PENDING: 'GET_FRONTLINE_LIST_PENDING',
    GET_FRONTLINE_LIST_FULFILLED: 'GET_FRONTLINE_LIST_FULFILLED',

    SEARCH_FRONTLINE_USERS: 'SEARCH_FRONTLINE_USERS',
    SEARCH_FRONTLINE_USERS_PENDING: 'SEARCH_FRONTLINE_USERS_PENDING',
    SEARCH_FRONTLINE_USERS_FULFILLED: 'SEARCH_FRONTLINE_USERS_FULFILLED',

    GET_FRONTLINE: "GET_FRONTLINE",
    GET_FRONTLINE_PENDING: 'GET_FRONTLINE_PENDING',
    GET_FRONTLINE_FULFILLED: 'GET_FRONTLINE_FULFILLED',

    CREATE_FRONTLINE: "CREATE_FRONTLINE",
    CREATE_FRONTLINE_PENDING: 'CREATE_FRONTLINE_PENDING',
    CREATE_FRONTLINE_REJECTED: "CREATE_FRONTLINE_REJECTED",
    CREATE_FRONTLINE_FULFILLED: 'CREATE_FRONTLINE_FULFILLED',

    UPDATE_FRONTLINE: "UPDATE_FRONTLINE",
    UPDATE_FRONTLINE_PENDING: 'UPDATE_FRONTLINE_PENDING',
    UPDATE_FRONTLINE_REJECTED: "UPDATE_FRONTLINE_REJECTED",
    UPDATE_FRONTLINE_FULFILLED: 'UPDATE_FRONTLINE_FULFILLED',

    DELETE_FRONTLINE: "DELETE_FRONTLINE",
    DELETE_FRONTLINE_PENDING: 'DELETE_FRONTLINE_PENDING',
    DELETE_FRONTLINE_FULFILLED: 'DELETE_FRONTLINE_FULFILLED',

    IMPORT_FRONTLINE: "IMPORT_FRONTLINE",
    IMPORT_FRONTLINE_PENDING: 'IMPORT_FRONTLINE_PENDING',
    IMPORT_FRONTLINE_REJECTED: 'IMPORT_FRONTLINE_REJECTED',
    IMPORT_FRONTLINE_FULFILLED: 'IMPORT_FRONTLINE_FULFILLED',

    ADD_FLOOR: "ADD_FLOOR",
    ADD_FLOOR_PENDING: "ADD_FLOOR_PENDING",
    ADD_FLOOR_REJECTED: "ADD_FLOOR_REJECTED",
    ADD_FLOOR_FULFILLED: "ADD_FLOOR_FULFILLED",

    ADD_DEFICIENCY: "ADD_DEFICIENCY",
    ADD_DEFICIENCY_PENDING: "ADD_DEFICIENCY_PENDING",
    ADD_DEFICIENCY_REJECTED: "ADD_DEFICIENCY_REJECTED",
    ADD_DEFICIENCY_FULFILLED: "ADD_DEFICIENCY_FULFILLED",

    UPDATE_FLOOR: "UPDATE_FLOOR",
    UPDATE_FLOOR_PENDING: "UPDATE_FLOOR_PENDING",
    UPDATE_FLOOR_REJECTED: "UPDATE_FLOOR_REJECTED",
    UPDATE_FLOOR_FULFILLED: "UPDATE_FLOOR_FULFILLED",

    ADD_AREA_TYPE: "ADD_AREA_TYPE",
    ADD_AREA_TYPE_PENDING: "ADD_AREA_TYPE_PENDING",
    ADD_AREA_TYPE_REJECTED: "ADD_AREA_TYPE_REJECTED",
    ADD_AREA_TYPE_FULFILLED: "ADD_AREA_TYPE_FULFILLED",

    UPDATE_AREA_TYPE: "UPDATE_AREA_TYPE",
    UPDATE_AREA_TYPE_PENDING: "UPDATE_AREA_TYPE_PENDING",
    UPDATE_AREA_TYPE_REJECTED: "UPDATE_AREA_TYPE_REJECTED",
    UPDATE_AREA_TYPE_FULFILLED: "UPDATE_AREA_TYPE_FULFILLED",

    UPDATE_DEFICIENCY: "UPDATE_DEFICIENCY",
    UPDATE_DEFICIENCY_PENDING: "UPDATE_DEFICIENCY_PENDING",
    UPDATE_DEFICIENCY_REJECTED: "UPDATE_DEFICIENCY_REJECTED",
    UPDATE_DEFICIENCY_FULFILLED: "UPDATE_DEFICIENCY_FULFILLED",

    GET_DEFICIENCY: "GET_DEFICIENCY",
    GET_DEFICIENCY_PENDING: "GET_DEFICIENCY_PENDING",
    GET_DEFICIENCY_REJECTED: "GET_DEFICIENCY_REJECTED",
    GET_DEFICIENCY_FULFILLED: "GET_DEFICIENCY_FULFILLED",

    GET_FLOORS: "GET_FLOORS",
    GET_FLOORS_PENDING: "GET_FLOORS_PENDING",
    GET_FLOORS_FULFILLED: "GET_FLOORS_FULFILLED",

    EDIT_AREA_TYPE_CLICKED: "EDIT_AREA_TYPE_CLICKED",
    EDIT_AREA_TYPE_CLICKED_PENDING: "EDIT_AREA_TYPE_CLICKED_PENDING",
    EDIT_AREA_TYPE_CLICKED_FULFILLED: "EDIT_AREA_TYPE_CLICKED_FULFILLED",

    EDIT_DEFICIENCY_CLICKED: "EDIT_DEFICIENCY_CLICKED",
    EDIT_DEFICIENCY_CLICKED_PENDING: "EDIT_DEFICIENCY_CLICKED_PENDING",
    EDIT_DEFICIENCY_CLICKED_FULFILLED: "EDIT_DEFICIENCY_CLICKED_FULFILLED",

    EDIT_FLOOR_CLICKED: "EDIT_FLOOR_CLICKED",
    EDIT_FLOOR_CLICKED_PENDING: "EDIT_FLOOR_CLICKED_PENDING",
    EDIT_FLOOR_CLICKED_FULFILLED: "EDIT_FLOOR_CLICKED_FULFILLED",

    DELETE_FLOOR: "DELETE_FLOOR",
    DELETE_FLOOR_PENDING: "DELETE_FLOOR_PENDING",
    DELETE_FLOOR_FULFILLED: "DELETE_FLOOR_FULFILLED",

    DELETE_AREA_TYPE: "DELETE_AREA_TYPE",
    DELETE_AREA_TYPE_PENDING: "DELETE_FLOOR_PENDING",
    DELETE_AREA_TYPE_FULFILLED: "DELETE_AREA_TYPE_FULFILLED",

    GET_ALL_AREA_DETAILS: "GET_ALL_AREA_DETAILS",
    GET_ALL_AREA_DETAILS_PENDING: "GET_ALL_AREA_DETAILS_PENDING",
    GET_ALL_AREA_DETAILS_FULFILLED: "GET_ALL_AREA_DETAILS_FULFILLED",

    SCAN_ANALYTICS: "SCAN_ANALYTICS",
    SCAN_ANALYTICS_PENDING: "SCAN_ANALYTICS_PENDING",
    SCAN_ANALYTICS_FULFILLED: "SCAN_ANALYTICS_FULFILLED",

    AREA_DATA: "AREA_DATA",
    AREA_DATA_PENDING: "AREA_DATA_PENDING",
    AREA_DATA_FULFILLED: "AREA_DATA_FULFILLED",

    NONE_LOGGED_IN_FRONTLINE_DATA: "NONE_LOGGED_IN_FRONTLINE_DATA",
    NONE_LOGGED_IN_FRONTLINE_DATA_PENDING: "NONE_LOGGED_IN_FRONTLINE_DATA_PENDING",
    NONE_LOGGED_IN_FRONTLINE_DATA_FULFILLED: "NONE_LOGGED_IN_FRONTLINE_DATA_FULFILLED",

    AREA_OF_ACCOUNT: "AREA_OF_ACCOUNT",
    AREA_OF_ACCOUNT_PENDING: "AREA_OF_ACCOUNT_PENDING",
    AREA_OF_ACCOUNT_REJECTED: "AREA_OF_ACCOUNT_REJECTED",
    AREA_OF_ACCOUNT_FULFILLED: "AREA_OF_ACCOUNT_FULFILLED",

    SCHEDULE_STATUS: "SCHEDULE_STATUS",
    SCHEDULE_STATUS_PENDING: "SCHEDULE_STATUS_PENDING",
    SCHEDULE_STATUS_REJECTED: "SCHEDULE_STATUS_REJECTED",
    SCHEDULE_STATUS_FULFILLED: "SCHEDULE_STATUS_FULFILLED",

    GET_HOLIDAYS_LIST: 'GET_HOLIDAYS_LIST',
    GET_HOLIDAYS_LIST_PENDING: 'GET_HOLIDAYS_LIST_PENDING',
    GET_HOLIDAYS_LIST_FULFILLED: 'GET_HOLIDAYS_LIST_FULFILLED',

    GET_QUOTA_FOR_QUOTES: 'GET_QUOTA_FOR_QUOTES',
    GET_QUOTA_FOR_QUOTES_PENDING: 'GET_QUOTA_FOR_QUOTES_PENDING',
    GET_QUOTA_FOR_QUOTES_FULFILLED: 'GET_QUOTA_FOR_QUOTES_FULFILLED',


    ADD_NEW_HOLIDAY: 'ADD_NEW_HOLIDAY',
    ADD_NEW_HOLIDAY_PENDING: 'ADD_NEW_HOLIDAY_PENDING',
    ADD_NEW_HOLIDAY_FULFILLED: 'ADD_NEW_HOLIDAY_FULFILLED',

    EDIT_HOLIDAY_CLICKED: 'EDIT_HOLIDAY_CLICKED',

    CLEAR_DEFICIENCY: 'CLEAR_DEFICIENCY',

    UPDATE_HOLIDAY: 'UPDATE_HOLIDAY',
    UPDATE_HOLIDAY_PENDING: 'UPDATE_HOLIDAY_PENDING',
    UPDATE_HOLIDAY_FULFILLED: 'UPDATE_HOLIDAY_FULFILLED',

    DELETE_HOLIDAY: 'DELETE_HOLIDAY',
    DELETE_HOLIDAY_PENDING: 'DELETE_HOLIDAY_PENDING',
    DELETE_HOLIDAY_FULFILLED: 'DELETE_HOLIDAY_FULFILLED',

    SET_ACTIVE_INACTIVE_AREAS: 'SET_ACTIVE_INACTIVE_AREAS',
    SET_ACTIVE_INACTIVE_AREAS_PENDING: 'SET_ACTIVE_INACTIVE_AREAS_PENDING',
    SET_ACTIVE_INACTIVE_AREAS_FULFILLED: 'SET_ACTIVE_INACTIVE_AREAS_FULFILLED',

    SUBMIT_CHI_REVIEW: 'SUBMIT_CHI_REVIEW',
    SUBMIT_CHI_REVIEW_PENDING: 'SUBMIT_CHI_REVIEW_PENDING',
    SUBMIT_CHI_REVIEW_FULFILLED: 'SUBMIT_CHI_REVIEW_FULFILLED',

    LOGIN_USER_MODE_SELECTION: 'LOGIN_USER_MODE_SELECTION',
    LOGIN_USER_MODE_SELECTION_PENDING: 'LOGIN_USER_MODE_SELECTION_PENDING',
    LOGIN_USER_MODE_SELECTION_FULFILLED: 'LOGIN_USER_MODE_SELECTION_FULFILLED',

    GET_MODE_ACCOUNTS: 'GET_MODE_ACCOUNTS',
    GET_MODE_ACCOUNTS_PENDING: 'GET_MODE_ACCOUNTS_PENDING',
    GET_MODE_ACCOUNTS_FULFILLED: 'GET_MODE_ACCOUNTS_FULFILLED',

    GET_COMPANY_ACCOUNTS: 'GET_COMPANY_ACCOUNTS',
    GET_COMPANY_ACCOUNTS_PENDING: 'GET_COMPANY_ACCOUNTS_PENDING',
    GET_COMPANY_ACCOUNTS_FULFILLED: 'GET_COMPANY_ACCOUNTS_FULFILLED',

    GET_ALL_MODE_ACCOUNT_USERS: 'GET_ALL_MODE_ACCOUNT_USERS',
    GET_ALL_MODE_ACCOUNT_USERS_PENDING: 'GET_ALL_MODE_ACCOUNT_USERS_PENDING',
    GET_ALL_MODE_ACCOUNT_USERS_FULFILLED: 'GET_ALL_MODE_ACCOUNT_USERS_FULFILLED',

    GET_INSPECTION_FORM_FOR_ACCOUNT: 'GET_INSPECTION_FORM_FOR_ACCOUNT',
    GET_INSPECTION_FORM_FOR_ACCOUNT_PENDING: 'GET_INSPECTION_FORM_FOR_ACCOUNT_PENDING',
    GET_INSPECTION_FORM_FOR_ACCOUNT_FULFILLED: 'GET_INSPECTION_FORM_FOR_ACCOUNT_FULFILLED',

    CHECK_SCHEDULE: 'CHECK_SCHEDULE',
    CHECK_SCHEDULE_PENDING: 'CHECK_SCHEDULE_PENDING',
    CHECK_SCHEDULE_FULFILLED: 'CHECK_SCHEDULE_FULFILLED',

    GET_ALL_CHI_SERVYS: 'GET_ALL_CHI_SERVYS',
    GET_ALL_CHI_SERVYS_PENDING: 'GET_ALL_CHI_SERVYS_PENDING',
    GET_ALL_CHI_SERVYS_FULFILLED: 'GET_ALL_CHI_SERVYS_FULFILLED',

    GET_CHI_DASHBOARD: 'GET_CHI_DASHBOARD',
    GET_CHI_DASHBOARD_PENDING: 'GET_CHI_DASHBOARD_PENDING',
    GET_CHI_DASHBOARD_FULFILLED: 'GET_CHI_DASHBOARD_FULFILLED',

    GET_ALL_QR_CODES: "GET_ALL_QR_CODES",
    GET_ALL_QR_CODES_PENDING: "GET_ALL_QR_CODES_PENDING",
    GET_ALL_QR_CODES_REJECTED: "GET_ALL_QR_CODES_REJECTED",
    GET_ALL_QR_CODES_FULFILLED: "GET_ALL_QR_CODES_FULFILLED",

    QQV_FEEDBACK: 'QQV_FEEDBACK',
    QQV_FEEDBACK_PENDING: 'QQV_FEEDBACK_PENDING',
    QQV_FEEDBACK_FULFILLED: 'QQV_FEEDBACK_FULFILLED',

    GET_ACCOUNT_EXPORT_DATA: 'GET_ACCOUNT_EXPORT_DATA',
    GET_ACCOUNT_EXPORT_DATA_PENDING: 'GET_ACCOUNT_EXPORT_DATA_PENDING',
    GET_ACCOUNT_EXPORT_DATA_FULFILLED: 'GET_ACCOUNT_EXPORT_DATA_FULFILLED',

    GET_USER_EXPORT_DATA: 'GET_USER_EXPORT_DATA',
    GET_USER_EXPORT_DATA_PENDING: 'GET_USER_EXPORT_DATA_PENDING',
    GET_USER_EXPORT_DATA_FULFILLED: 'GET_USER_EXPORT_DATA_FULFILLED',

    MAKE_ACCOUNT_USER_AS_OWNER: 'MAKE_ACCOUNT_USER_AS_OWNER',
    MAKE_ACCOUNT_USER_AS_OWNER_PENDING: 'MAKE_ACCOUNT_USER_AS_OWNER_PENDING',
    MAKE_ACCOUNT_USER_AS_OWNER_FULFILLED: 'MAKE_ACCOUNT_USER_AS_OWNER_FULFILLED',

    WELCOME_MAIL_FOR_USER: 'WELCOME_MAIL_FOR_USER',
    WELCOME_MAIL_FOR_USER_PENDING: 'WELCOME_MAIL_FOR_USER_PENDING',
    WELCOME_MAIL_FOR_USER_FULFILLED: 'WELCOME_MAIL_FOR_USER_FULFILLED',

    ACCOUNT_ACTIVE_CHANGED: 'ACCOUNT_ACTIVE_CHANGED',
    USERS_ACTIVE_CHANGED: 'USERS_ACTIVE_CHANGED',

    COMPANY_ACTIVE_CHANGED: 'COMPANY_ACTIVE_CHANGED',

    GET_ASSOCIATED_INSPECTIONS: 'GET_ASSOCIATED_INSPECTIONS',
    GET_ASSOCIATED_INSPECTIONS_PENDING: 'GET_ASSOCIATED_INSPECTIONS_PENDING',
    GET_ASSOCIATED_INSPECTIONS_FULFILLED: 'GET_ASSOCIATED_INSPECTIONS_FULFILLED',

    CHI_PREVIEW: 'CHI_PREVIEW',

    GET_CHI_PREVIEW: 'GET_CHI_PREVIEW',
    GET_CHI_PREVIEW_PENDING: 'GET_CHI_PREVIEW_PENDING',
    GET_CHI_PREVIEW_FULFILLED: 'GET_CHI_PREVIEW_FULFILLED',

    GET_ACCOUNT_ADMINLIST: 'GET_ACCOUNT_ADMINLIST',
    GET_ACCOUNT_ADMINLIST_PENDING: 'GET_ACCOUNT_ADMINLIST_PENDING',
    GET_ACCOUNT_ADMINLIST_FULFILLED: 'GET_ACCOUNT_ADMINLIST_FULFILLED',

    GET_INSPECTION_INDEX: 'GET_INSPECTION_INDEX',
    GET_INSPECTION_INDEX_PENDING: 'GET_INSPECTION_INDEX_PENDING',
    GET_INSPECTION_INDEX_FULFILLED: 'GET_INSPECTION_INDEX_FULFILLED',

    GET_INSPECTION_PDF: 'GET_INSPECTION_PDF',
    GET_INSPECTION_PDF_PENDING: 'GET_INSPECTION_PDF_PENDING',
    GET_INSPECTION_PDF_FULFILLED: 'GET_INSPECTION_PDF_FULFILLED',
    GET_COMPANY_PRESIGNED_URL: 'GET_COMPANY_PRESIGNED_URL',
    GET_COMPANY_PRESIGNED_URL_PENDING: 'GET_COMPANY_PRESIGNED_URL_PENDING',
    GET_COMPANY_PRESIGNED_URL_FULFILLED: 'GET_COMPANY_PRESIGNED_URL_FULFILLED',

    GET_KPI_DASHBOARD_DATA: 'GET_KPI_DASHBOARD_DATA',
    GET_KPI_DASHBOARD_DATA_PENDING: 'GET_KPI_DASHBOARD_DATA_PENDING',
    GET_KPI_DASHBOARD_DATA_FULFILLED: 'GET_KPI_DASHBOARD_DATA_FULFILLED',

    GET_KPI_INSPECTION_COUNT: 'GET_KPI_INSPECTION_COUNT',
    GET_KPI_INSPECTION_COUNT_PENDING: 'GET_KPI_INSPECTION_COUNT_PENDING',
    GET_KPI_INSPECTION_COUNT_FULFILLED: 'GET_KPI_INSPECTION_COUNT_FULFILLED',


    GET_KPI_INS_QQV_SCRORE_DASHBOARD_DATA: 'GET_KPI_INS_QQV_SCRORE_DASHBOARD_DATA',
    GET_KPI_INS_QQV_SCRORE_DASHBOARD_DATA_PENDING: 'GET_KPI_INS_QQV_SCRORE_DASHBOARD_DATA_PENDING',
    GET_KPI_INS_QQV_SCRORE_DASHBOARD_DATA_FULFILLED: 'GET_KPI_INS_QQV_SCRORE_DASHBOARD_DATA_FULFILLED',

    GET_KPI_INS_QQV_ROOTCASE_DASHBOARD_DATA: 'GET_KPI_INS_QQV_ROOTCASE_DASHBOARD_DATA',
    GET_KPI_INS_QQV_ROOTCASE_DASHBOARD_DATA_PENDING: 'GET_KPI_INS_QQV_ROOTCASE_DASHBOARD_DATA_PENDING',
    GET_KPI_INS_QQV_ROOTCASE_DASHBOARD_DATA_FULFILLED: 'GET_KPI_INS_QQV_ROOTCASE_DASHBOARD_DATA_FULFILLED',

    GET_KPI_INS_DEFICENCY_DASHBOARD_DATA: 'GET_KPI_INS_DEFICENCY_DASHBOARD_DATA',
    GET_KPI_INS_DEFICENCY_DASHBOARD_DATA_PENDING: 'GET_KPI_INS_DEFICENCY_DASHBOARD_DATA_PENDING',
    GET_KPI_INS_DEFICENCY_DASHBOARD_DATA_FULFILLED: 'GET_KPI_INS_DEFICENCY_DASHBOARD_DATA_FULFILLED',

    GET_KPI_INSPECTION_AVERAGESCRORE: 'GET_KPI_INSPECTION_AVERAGESCRORE',
    GET_KPI_INSPECTION_AVERAGESCRORE_PENDING: 'GET_KPI_INSPECTION_AVERAGESCRORE_PENDING',
    GET_KPI_INSPECTION_AVERAGESCRORE_FULFILLED: 'GET_KPI_INSPECTION_AVERAGESCRORE_FULFILLED',

    GET_KPI_TOP_INS_DASHBOARD_DATA: 'GET_KPI_TOP_INS_DASHBOARD_DATA',
    GET_KPI_TOP_INS_DASHBOARD_DATA_PENDING: 'GET_KPI_TOP_INS_DASHBOARD_DATA_PENDING',
    GET_KPI_TOP_INS_DASHBOARD_DATA_FULFILLED: 'GET_KPI_TOP_INS_DASHBOARD_DATA_FULFILLED',


    GET_KPI_INS_DATE_DASHBOARD_DATA: 'GET_KPI_INS_DATE_DASHBOARD_DATA',
    GET_KPI_INS_DATE_DASHBOARD_DATA_PENDING: 'GET_KPI_INS_DATE_DASHBOARD_DATA_PENDING',
    GET_KPI_INS_DATE_DASHBOARD_DATA_FULFILLED: 'GET_KPI_INS_DATE_DASHBOARD_DATA_FULFILLED',

    GET_KPI_TOP_INSPECTOR: 'GET_KPI_TOP_INSPECTOR',
    GET_KPI_TOP_INSPECTOR_PENDING: 'GET_KPI_TOP_INSPECTOR_PENDING',
    GET_KPI_TOP_INSPECTOR_FULFILLED: 'GET_KPI_TOP_INSPECTOR_FULFILLED',

    GET_KPI_INS_ACCOUNT_DASHBOARD_DATA: 'GET_KPI_INS_ACCOUNT_DASHBOARD_DATA',
    GET_KPI_INS_ACCOUNT_DASHBOARD_DATA_PENDING: 'GET_KPI_INS_ACCOUNT_DASHBOARD_DATA_PENDING',
    GET_KPI_INS_ACCOUNT_DASHBOARD_DATA_FULFILLED: 'GET_KPI_INS_ACCOUNT_DASHBOARD_DATA_FULFILLED',

    GET_KPI_INS_PIECHART_DASHBOARD_DATA: 'GET_KPI_INS_PIECHART_DASHBOARD_DATA',
    GET_KPI_INS_PIECHART_DASHBOARD_DATA_PENDING: 'GET_KPI_INS_PIECHART_DASHBOARD_DATA_PENDING',
    GET_KPI_INS_PIECHART_DASHBOARD_DATA_FULFILLED: 'GET_KPI_INS_PIECHART_DASHBOARD_DATA_FULFILLED',

    GET_KPI_QUALITY_INSPECTION: 'GET_KPI_QUALITY_INSPECTION',
    GET_KPI_QUALITY_INSPECTION_PENDING: 'GET_KPI_QUALITY_INSPECTION_PENDING',
    GET_KPI_QUALITY_INSPECTION_FULFILLED: 'GET_KPI_QUALITY_INSPECTION_FULFILLED',

    GET_KPI_AVERAGE_SECTION_QUALITY: 'GET_KPI_AVERAGE_SECTION_QUALITY',
    GET_KPI_AVERAGE_SECTION_QUALITY_PENDING: 'GET_KPI_AVERAGE_SECTION_QUALITY_PENDING',
    GET_KPI_AVERAGE_SECTION_QUALITY_FULFILLED: 'GET_KPI_AVERAGE_SECTION_QUALITY_FULFILLED',

    GET_KPI_AVERAGE_SECTION_SAFETY: 'GET_KPI_AVERAGE_SECTION_SAFETY',
    GET_KPI_AVERAGE_SECTION_SAFETY_PENDING: 'GET_KPI_AVERAGE_SECTION_SAFETY_PENDING',
    GET_KPI_AVERAGE_SECTION_SAFETY_FULFILLED: 'GET_KPI_AVERAGE_SECTION_SAFETY_FULFILLED',

    GET_AVG_MODAL_DATA: 'GET_AVG_MODAL_DATA',
    GET_AVG_MODAL_DATA_PENDING: 'GET_AVG_MODAL_DATA_PENDING',
    GET_AVG_MODAL_DATA_FULFILLED: 'GET_AVG_MODAL_DATA_FULFILLED',

    GET_KPI_INS_CUSTOMER_DASHBOARD_DATA: 'GET_KPI_INS_CUSTOMER_DASHBOARD_DATA',
    GET_KPI_INS_CUSTOMER_DASHBOARD_DATA_PENDING: 'GET_KPI_INS_CUSTOMER_DASHBOARD_DATA_PENDING',
    GET_KPI_INS_CUSTOMER_DASHBOARD_DATA_FULFILLED: 'GET_KPI_INS_CUSTOMER_DASHBOARD_DATA_FULFILLED',

    GET_KPI_INS_QQV_DASHBOARD_DATA: 'GET_KPI_INS_QQV_DASHBOARD_DATA',
    GET_KPI_INS_QQV_DASHBOARD_DATA_PENDING: 'GET_KPI_INS_QQV_DASHBOARD_DATA_PENDING',
    GET_KPI_INS_QQV_DASHBOARD_DATA_FULFILLED: 'GET_KPI_INS_QQV_DASHBOARD_DATA_FULFILLED',

    GET_COMPANY_LIST: 'GET_COMPANY_LIST',
    GET_COMPANY_LIST_PENDING: 'GET_COMPANY_LIST_PENDING',
    GET_COMPANY_LIST_FULFILLED: 'GET_COMPANY_LIST_FULFILLED',


    GET_KPI_TICKET_COUNT_DASHBOARD_DATA: 'GET_KPI_TICKET_COUNT_DASHBOARD_DATA',
    GET_KPI_TICKET_COUNT_DASHBOARD_DATA_PENDING: 'GET_KPI_TICKET_COUNT_DASHBOARD_DATA_PENDING',
    GET_KPI_TICKET_COUNT_DASHBOARD_DATA_FULFILLED: 'GET_KPI_TICKET_COUNT_DASHBOARD_DATA_FULFILLED',

    GET_KPI_TICKET_GRAPH_DASHBOARD_DATA: 'GET_KPI_TICKET_GRAPH_DASHBOARD_DATA',
    GET_KPI_TICKET_GRAPH_DASHBOARD_DATA_PENDING: 'GET_KPI_TICKET_GRAPH_DASHBOARD_DATA_PENDING',
    GET_KPI_TICKET_GRAPH_DASHBOARD_DATA_FULFILLED: 'GET_KPI_TICKET_GRAPH_DASHBOARD_DATA_FULFILLED',

    GET_KPI_TICKET_DAYSOPEN_DASHBOARD_DATA: 'GET_KPI_TICKET_DAYSOPEN_DASHBOARD_DATA',
    GET_KPI_TICKET_DAYSOPEN_DASHBOARD_DATA_PENDING: 'GET_KPI_TICKET_DAYSOPEN_DASHBOARD_DATA_PENDING',
    GET_KPI_TICKET_DAYSOPEN_DASHBOARD_DATA_FULFILLED: 'GET_KPI_TICKET_DAYSOPEN_DASHBOARD_DATA_FULFILLED',

    GET_KPI_TICKET_HIGHUSERS_DASHBOARD_DATA: 'GET_KPI_TICKET_HIGHUSERS_DASHBOARD_DATA',
    GET_KPI_TICKET_HIGHUSERS_DASHBOARD_DATA_PENDING: 'GET_KPI_TICKET_HIGHUSERS_DASHBOARD_DATA_PENDING',
    GET_KPI_TICKET_HIGHUSERS_DASHBOARD_DATA_FULFILLED: 'GET_KPI_TICKET_HIGHUSERS_DASHBOARD_DATA_FULFILLED',

    GET_KPI_TICKET_JOBS_DASHBOARD_DATA: 'GET_KPI_TICKET_JOBS_DASHBOARD_DATA',
    GET_KPI_TICKET_JOBS_DASHBOARD_DATA_PENDING: 'GET_KPI_TICKET_JOBS_DASHBOARD_DATA_PENDING',
    GET_KPI_TICKET_JOBS_DASHBOARD_DATA_FULFILLED: 'GET_KPI_TICKET_JOBS_DASHBOARD_DATA_FULFILLED',

    GET_QUALITY_MODAL_DATA: 'GET_QUALITY_MODAL_DATA',
    GET_QUALITY_MODAL_DATA_PENDING: 'GET_QUALITY_MODAL_DATA_PENDING',
    GET_QUALITY_MODAL_DATA_FULFILLED: 'GET_QUALITY_MODAL_DATA_FULFILLED',

    GET_AVERAGE_GRAPH_INSPECTIONS: 'GET_AVERAGE_GRAPH_INSPECTIONS',
    GET_AVERAGE_GRAPH_INSPECTIONS_PENDING: 'GET_AVERAGE_GRAPH_INSPECTIONS_PENDING',
    GET_AVERAGE_GRAPH_INSPECTIONS_FULFILLED: 'GET_AVERAGE_GRAPH_INSPECTIONS_FULFILLED',

    GET_CRITERIAS_LIST: 'GET_CRITERIAS_LIST',
    GET_CRITERIAS_LIST_PENDING: 'GET_CRITERIAS_LIST_PENDING',
    GET_CRITERIAS_LIST_FULFILLED: 'GET_CRITERIAS_LIST_FULFILLED',

    GET_CRITERIA: 'GET_CRITERIA',
    GET_CRITERIA_PENDING: 'GET_CRITERIA_PENDING',
    GET_CRITERIA_FULFILLED: 'GET_CRITERIA_FULFILLED',

    EDIT_CRITERIA: 'EDIT_CRITERIA',
    EDIT_CRITERIA_PENDING: 'EDIT_CRITERIA_PENDING',
    EDIT_CRITERIA_FULFILLED: 'EDIT_CRITERIA_FULFILLED',

    DELETE_CRITERIA: 'DELETE_CRITERIA',
    DELETE_CRITERIA_PENDING: 'DELETE_CRITERIA_PENDING',
    DELETE_CRITERIA_FULFILLED: 'DELETE_CRITERIA_FULFILLED',

    CREATE_CRITERIA: 'CREATE_CRITERIA',
    CREATE_CRITERIA_PENDING: 'CREATE_CRITERIA_PENDING',
    CREATE_CRITERIA_FULFILLED: 'CREATE_CRITERIA_FULFILLED',

    EDIT_CRITERIA_CLICKED: 'EDIT_CRITERIA_CLICKED',

    ADD_NEW_SCHEDULE: 'ADD_NEW_SCHEDULE',
    ADD_NEW_SCHEDULE_PENDING: 'ADD_NEW_SCHEDULE_PENDING',
    ADD_NEW_SCHEDULE_FULFILLED: 'ADD_NEW_SCHEDULE_FULFILLED',

    GET_ALL_SCHEDULE_DATA: 'GET_ALL_SCHEDULE_DATA',
    GET_ALL_SCHEDULE_DATA_PENDING: 'GET_ALL_SCHEDULE_DATA_PENDING',
    GET_ALL_SCHEDULE_DATA_FULFILLED: 'GET_ALL_SCHEDULE_DATA_FULFILLED',

    GET_SCHEDULE_EVENTS: 'GET_SCHEDULE_EVENTS',
    GET_SCHEDULE_EVENTS_PENDING: 'GET_SCHEDULE_EVENTS_PENDING',
    GET_SCHEDULE_EVENTS_FULFILLED: 'GET_SCHEDULE_EVENTS_FULFILLED',

    GET_SCHEDULE_EMAIL_EVENTS: 'GET_SCHEDULE_EMAIL_EVENTS',
    GET_SCHEDULE_EMAIL_EVENTS_PENDING: 'GET_SCHEDULE_EMAIL_EVENTS_PENDING',
    GET_SCHEDULE_EMAIL_EVENTS_FULFILLED: 'GET_SCHEDULE_EMAIL_EVENTS_FULFILLED',


    GET_SCHEDULE: 'GET_SCHEDULE',
    GET_SCHEDULE_PENDING: 'GET_SCHEDULE_PENDING',
    GET_SCHEDULE_FULFILLED: 'GET_SCHEDULE_FULFILLED',

    GET_ACCOUNT_SCHEDULE: 'GET_ACCOUNT_SCHEDULE',
    GET_ACCOUNT_SCHEDULE_PENDING: 'GET_ACCOUNT_SCHEDULE_PENDING',
    GET_ACCOUNT_SCHEDULE_FULFILLED: 'GET_ACCOUNT_SCHEDULE_FULFILLED',

    DELETE_SCHEDULE: 'DELETE_SCHEDULE',
    DELETE_SCHEDULE_PENDING: 'DELETE_SCHEDULE_PENDING',
    DELETE_SCHEDULE_FULFILLED: 'DELETE_SCHEDULE_FULFILLED',

    DELETE_EVENT: 'DELETE_EVENT',
    DELETE_EVENT_PENDING: 'DELETE_EVENT_PENDING',
    DELETE_EVENT_FULFILLED: 'DELETE_EVENT_FULFILLED',

    GET_EVENT_DASHBOARD_DATA: 'GET_EVENT_DASHBOARD_DATA',
    GET_EVENT_DASHBOARD_DATA_PENDING: 'GET_EVENT_DASHBOARD_DATA_PENDING',
    GET_EVENT_DASHBOARD_DATA_FULFILLED: 'GET_EVENT_DASHBOARD_DATA_FULFILLED',

    HANDLE_CLICK_SCHEDULE_OCCURRENCE_MODAL: 'HANDLE_CLICK_SCHEDULE_OCCURRENCE_MODAL',
    HANDLE_CLICK_SCHEDULE_OUTLOOK_CONFIRM_MODAL: 'HANDLE_CLICK_SCHEDULE_OUTLOOK_CONFIRM_MODAL',

    SCHEDULE_HISTORY: 'SCHEDULE_HISTORY',
    SCHEDULE_HISTORY_PENDING: 'SCHEDULE_HISTORY_PENDING',
    SCHEDULE_HISTORY_FULFILLED: 'SCHEDULE_HISTORY_FULFILLED',

    SCHEDULE_HISTORY_PAGE_CLICKED: 'SCHEDULE_HISTORY_PAGE_CLICKED',
    INSPECTIONS_CLICKED: 'INSPECTIONS_CLICKED',

    TICKETS_CLICKED: 'TICKETS_CLICKED',

    CHECK_OWNER_INFO: 'CHECK_OWNER_INFO',
    CHECK_OWNER_INFO_PENDING: 'CHECK_OWNER_INFO_PENDING',
    CHECK_OWNER_INFO_FULFILLED: 'CHECK_OWNER_INFO_FULFILLED',

    COMPLETED_APPROVE_MODAL_CLICKED: 'COMPLETED_APPROVE_MODAL_CLICKED',

    CLOSE_COMPLETE_APPROVE_CLOSE: 'CLOSE_COMPLETE_APPROVE_CLOSE',
    GET_LATEST_INSPECTION: 'GET_LATEST_INSPECTION',
    GET_LATEST_INSPECTION_PENDING: 'GET_LATEST_INSPECTION_PENDING',
    GET_LATEST_INSPECTION_FULFILLED: 'GET_LATEST_INSPECTION_FULFILLED',

    CLEAR_LATEST_INSPECTION: 'CLEAR_LATEST_INSPECTION',

    CREATE_LOGBOOK: 'CREATE_LOGBOOK',
    CREATE_LOGBOOK_PENDING: 'CREATE_LOGBOOK_PENDING',
    CREATE_LOGBOOK_FULFILLED: 'CREATE_LOGBOOK_FULFILLED',

    UPDATE_LOGBOOK: 'UPDATE_LOGBOOK',
    UPDATE_LOGBOOK_PENDING: 'UPDATE_LOGBOOK_PENDING',
    UPDATE_LOGBOOK_FULFILLED: 'UPDATE_LOGBOOK_FULFILLED',

    GET_LOGBOOK: 'GET_LOGBOOK',
    GET_LOGBOOK_PENDING: 'GET_LOGBOOK_PENDING',
    GET_LOGBOOK_FULFILLED: 'GET_LOGBOOK_FULFILLED',

    CLEAR_LOGBOOK_AREA: 'CLEAR_LOGBOOK_AREA',

    GET_LOGBOOK_PRESIGNED_URL: 'GET_LOGBOOK_PRESIGNED_URL',
    GET_LOGBOOK_PRESIGNED_URL_PENDING: 'GET_LOGBOOK_PRESIGNED_URL_PENDING',
    GET_LOGBOOK_PRESIGNED_URL_FULFILLED: 'GET_LOGBOOK_PRESIGNED_URL_FULFILLED',

    CREATE_LOGBOOK_LOG: 'CREATE_LOGBOOK_LOG',
    CREATE_LOGBOOK_LOG_PENDING: 'CREATE_LOGBOOK_LOG_PENDING',
    CREATE_LOGBOOK_LOG_FULFILLED: 'CREATE_LOGBOOK_LOG_FULFILLED',

    GET_ALL_LOGHISTORY_AREA_DETAILS: "GET_ALL_LOGHISTORY_AREA_DETAILS",
    GET_ALL_LOGHISTORY_AREA_DETAILS_PENDING: "GET_ALL_LOGHISTORY_AREA_DETAILS_PENDING",
    GET_ALL_LOGHISTORY_AREA_DETAILS_FULFILLED: "GET_ALL_LOGHISTORY_AREA_DETAILS_FULFILLED",

    SEARCH_LOGHISTORY: 'SEARCH_LOGHISTORY',
    SEARCH_LOGHISTORY_PENDING: 'SEARCH_LOGHISTORY_PENDING',
    SEARCH_LOGHISTORY_REJECTED: 'SEARCH_LOGHISTORY_REJECTED',
    SEARCH_LOGHISTORY_FULFILLED: 'SEARCH_LOGHISTORY_FULFILLED',
    OPEN_CAMERA_CLICKED: 'OPEN_CAMERA_CLICKED',
    CLOSE_CAMERA_CLICKED: 'CLOSE_CAMERA_CLICKED',
    CLOSE_DEFICIENCY_CLICKED: 'CLOSE_DEFICIENCY_CLICKED',
    OPEN_DEFICIENCY_CLICKED: 'OPEN_DEFICIENCY_CLICKED',

    SUBMIT_FEEDBACK_PRESIGNED_URL: 'SUBMIT_FEEDBACK_PRESIGNED_URL',
    SUBMIT_FEEDBACK_PRESIGNED_URL_PENDING: 'SUBMIT_FEEDBACK_PRESIGNED_URL_PENDING',
    SUBMIT_FEEDBACK_PRESIGNED_URL_FULFILLED: 'SUBMIT_FEEDBACK_PRESIGNED_URL_FULFILLED',
    SUBMIT_FEEDBACK: 'SUBMIT_FEEDBACK',
    SUBMIT_FEEDBACK_PENDING: 'SUBMIT_FEEDBACK_PENDING',
    SUBMIT_FEEDBACK_FULFILLED: 'SUBMIT_FEEDBACK_FULFILLED',

    GET_PEGASSURE_FEEDBACKS: 'GET_PEGASSURE_FEEDBACKS',
    GET_PEGASSURE_FEEDBACKS_PENDING: 'GET_PEGASSURE_FEEDBACKS_PENDING',
    GET_PEGASSURE_FEEDBACKS_FULFILLED: 'GET_PEGASSURE_FEEDBACKS_FULFILLED',

    SEARCH_PEGASSURE_FEEDBACKS: 'SEARCH_PEGASSURE_FEEDBACKS',
    SEARCH_PEGASSURE_FEEDBACKS_PENDING: 'SEARCH_PEGASSURE_FEEDBACKS_PENDING',
    SEARCH_PEGASSURE_FEEDBACKS_FULFILLED: 'SEARCH_PEGASSURE_FEEDBACKS_FULFILLED',

    GET_FEEDBACK: 'GET_FEEDBACK',
    GET_FEEDBACK_PENDING: 'GET_FEEDBACK_PENDING',
    GET_FEEDBACK_FULFILLED: 'GET_FEEDBACK_FULFILLED',

    PREVIEW_FEEDBACK: 'PREVIEW_FEEDBACK',

    UPDATE_SEARCH_LOGBOOK_VALUES: 'UPDATE_SEARCH_LOGBOOK_VALUES',

    IMPORT_LOGBOOK_AREA: 'IMPORT_LOGBOOK_AREA',
    IMPORT_LOGBOOK_AREA_PENDING: 'IMPORT_LOGBOOK_AREA_PENDING',
    IMPORT_LOGBOOK_AREA_REJECTED: 'IMPORT_LOGBOOK_AREA_REJECTED',
    IMPORT_LOGBOOK_AREA_FULFILLED: 'IMPORT_LOGBOOK_AREA_FULFILLED',

    IMPORT_CHECK_LOGBOOK_AREA: 'IMPORT_CHECK_LOGBOOK_AREA',
    IMPORT_CHECK_LOGBOOK_AREA_PENDING: 'IMPORT_CHECK_LOGBOOK_AREA_PENDING',
    IMPORT_CHECK_LOGBOOK_AREA_REJECTED: 'IMPORT_CHECK_LOGBOOK_AREA_REJECTED',
    IMPORT_CHECK_LOGBOOK_AREA_FULFILLED: 'IMPORT_CHECK_LOGBOOK_AREA_FULFILLED',

    CREATE_NEW_SCHEDULE_TICKET: 'CREATE_NEW_SCHEDULE_TICKET',
    CREATE_NEW_SCHEDULE_TICKET_PENDING: 'CREATE_NEW_SCHEDULE_TICKET_PENDING',
    CREATE_NEW_SCHEDULE_TICKET_FULFILLED: 'CREATE_NEW_SCHEDULE_TICKET_FULFILLED',

    GET_SCHEDULE_TICKET_EVENTS: 'GET_SCHEDULE_TICKET_EVENTS',
    GET_SCHEDULE_TICKET_EVENTS_PENDING: 'GET_SCHEDULE_TICKET_EVENTS_PENDING',
    GET_SCHEDULE_TICKET_EVENTS_FULFILLED: 'GET_SCHEDULE_TICKET_EVENTS_FULFILLED',

    GET_ONE_SCHEDULE_TICKET_EVENT_INFO: 'GET_ONE_SCHEDULE_TICKET_EVENT_INFO',
    GET_ONE_SCHEDULE_TICKET_EVENT_INFO_PENDING: 'GET_ONE_SCHEDULE_TICKET_EVENT_INFO_PENDING',
    GET_ONE_SCHEDULE_TICKET_EVENT_INFO_FULFILLED: 'GET_ONE_SCHEDULE_TICKET_EVENT_INFO_FULFILLED',

    HANDLE_OPEN_SCHEDULE_TICKET_DELETE_MODAL: 'HANDLE_OPEN_SCHEDULE_TICKET_DELETE_MODAL',

    DELETE_CURRENT_SCHEDULE_TICKET_EVENT: 'DELETE_CURRENT_SCHEDULE_TICKET_EVENT',
    DELETE_CURRENT_SCHEDULE_TICKET_EVENT_PENDING: 'DELETE_CURRENT_SCHEDULE_TICKET_EVENT_PENDING',
    DELETE_CURRENT_SCHEDULE_TICKET_EVENT_FULFILLED: 'DELETE_CURRENT_SCHEDULE_TICKET_EVENT_FULFILLED',

    DELETE_ENTIRE_SCHEDULE_TICKET_EVENTS: 'DELETE_ENTIRE_SCHEDULE_TICKET_EVENTS',
    DELETE_ENTIRE_SCHEDULE_TICKET_EVENTS_PENDING: 'DELETE_ENTIRE_SCHEDULE_TICKET_EVENTS_PENDING',
    DELETE_ENTIRE_SCHEDULE_TICKET_EVENTS_FULFILLED: 'DELETE_ENTIRE_SCHEDULE_TICKET_EVENTS_FULFILLED',

    GET_PREVIEW_SINGLE_TICKET: 'GET_PREVIEW_SINGLE_TICKET',
    GET_PREVIEW_SINGLE_TICKET_PENDING: 'GET_PREVIEW_SINGLE_TICKET_PENDING',
    GET_PREVIEW_SINGLE_TICKET_FULFILLED: 'GET_PREVIEW_SINGLE_TICKET_FULFILLED',

    HANDLE_DELETE_SCHEDULE_TICKET_EVENT: 'HANDLE_DELETE_SCHEDULE_TICKET_EVENT',

    HANDLE_DELETE_ENTIRE_SCHEDULE_TICKET_EVENTS: 'HANDLE_DELETE_ENTIRE_SCHEDULE_TICKET_EVENTS',

    GET_SCHEDULE_TICKET_PRESIGNED_URL: 'GET_SCHEDULE_TICKET_PRESIGNED_URL',
    GET_SCHEDULE_TICKET_PRESIGNED_URL_PENDING: 'GET_SCHEDULE_TICKET_PRESIGNED_URL_PENDING',
    GET_SCHEDULE_TICKET_PRESIGNED_URL_FULFILLED: 'GET_SCHEDULE_TICKET_PRESIGNED_URL_FULFILLED',

    SCHEDULE_TICKET_HISTORY: 'SCHEDULE_TICKET_HISTORY',
    SCHEDULE_TICKET_HISTORY_PENDING: 'SCHEDULE_TICKET_HISTORY_PENDING',
    SCHEDULE_TICKET_HISTORY_FULFILLED: 'SCHEDULE_TICKET_HISTORY_FULFILLED',

    SCHEDULE_TICKET_HISTORY_PAGE_CLICKED: 'SCHEDULE_TICKET_HISTORY_PAGE_CLICKED',

    DELETE_PREVIEW_SCHEDULED_TICKET: 'DELETE_PREVIEW_SCHEDULED_TICKET',
    DELETE_PREVIEW_SCHEDULED_TICKET_PENDING: 'DELETE_PREVIEW_SCHEDULED_TICKET_PENDING',
    DELETE_PREVIEW_SCHEDULED_TICKET_FULFILLED: 'DELETE_PREVIEW_SCHEDULED_TICKET_FULFILLED',

    CUSTOMER_CLICKED: 'CUSTOMER_CLICKED',

    GET_API_ACCESS: 'GET_API_ACCESS',
    GET_API_ACCESS_PENDING: 'GET_API_ACCESS_PENDING',
    GET_API_ACCESS_FULFILLED: 'GET_API_ACCESS_FULFILLED',

    ADD_API_ACCESS: 'ADD_API_ACCESS',
    ADD_API_ACCESS_PENDING: 'ADD_API_ACCESS_PENDING',
    ADD_API_ACCESS_FULFILLED: 'ADD_API_ACCESS_FULFILLED',

    COMPANY_ACCESS_PAGE_CLICKED: 'COMPANY_ACCESS_PAGE_CLICKED',

    COMPANY_ACCESS_ACTIVE_CHANGED: 'COMPANY_ACCESS_ACTIVE_CHANGED',

    GET_ONE_COMPANY_ACCESS: 'GET_ONE_COMPANY_ACCESS',
    GET_ONE_COMPANY_ACCESS_PENDING: 'GET_ONE_COMPANY_ACCESS_PENDING',
    GET_ONE_COMPANY_ACCESS_FULFILLED: 'GET_ONE_COMPANY_ACCESS_FULFILLED',

    CANCEL_COMPANY_ACCESS_MODEL: 'CANCEL_COMPANY_ACCESS_MODEL',

    UPDATE_COMPANY_ACCESS_MODEL: 'UPDATE_COMPANY_ACCESS_MODEL',

    UPDATE_API_ACCESS: 'UPDATE_API_ACCESS',
    UPDATE_API_ACCESS_PENDING: 'UPDATE_API_ACCESS_PENDING',
    UPDATE_API_ACCESS_FULFILLED: 'UPDATE_API_ACCESS_FULFILLED',

    DELETE_API_ACCESS: 'DELETE_API_ACCESS',
    DELETE_API_ACCESS_PENDING: 'DELETE_API_ACCESS_PENDING',
    DELETE_API_ACCESS_FULFILLED: 'DELETE_API_ACCESS_FULFILLED',

    LOGBOOK_PREVIEW_PAGE_CLICKED: 'LOGBOOK_PREVIEW_PAGE_CLICKED',

    GET_CHISURVEY_QUARTERLY_CHECK_CHATTELS: 'GET_CHISURVEY_QUARTERLY_CHECK_CHATTELS',
    GET_CHISURVEY_QUARTERLY_CHECK_CHATTELS_PENDING: 'GET_CHISURVEY_QUARTERLY_CHECK_CHATTELS_PENDING',
    GET_CHISURVEY_QUARTERLY_CHECK_CHATTELS_FULFILLED: 'GET_CHISURVEY_QUARTERLY_CHECK_CHATTELS_FULFILLED',

    IMPORT_TICKET: 'IMPORT_TICKET',
    IMPORT_TICKET_PENDING: 'IMPORT_TICKET_PENDING',
    IMPORT_TICKET_REJECTED: 'IMPORT_TICKET_REJECTED',
    IMPORT_TICKET_FULFILLED: 'IMPORT_TICKET_FULFILLED',

    FRONTLINE_CLICKED: 'FRONTLINE_CLICKED',

    UPLOAD_FLUSER_PROFILE_IMAGE_TO_S3: 'UPLOAD_FLUSER_PROFILE_IMAGE_TO_S3',
    UPLOAD_FLUSER_PROFILE_IMAGE_TO_S3_PENDING: 'UPLOAD_FLUSER_PROFILE_IMAGE_TO_S3_PENDING',
    UPLOAD_FLUSER_PROFILE_IMAGE_TO_S3_FULFILLED: 'UPLOAD_FLUSER_PROFILE_IMAGE_TO_S3_FULFILLED',

    GET_CREATE_FLUSER_PROFILE_IMAGE_PRESIGNED_URL: 'GET_CREATE_FLUSER_PROFILE_IMAGE_PRESIGNED_URL',
    GET_CREATE_FLUSER_PROFILE_IMAGE_PRESIGNED_URL_PENDING: 'GET_CREATE_FLUSER_PROFILE_IMAGE_PRESIGNED_URL',
    GET_CREATE_FLUSER_PROFILE_IMAGE_PRESIGNED_URL_FULFILLED: 'GET_CREATE_FLUSER_PROFILE_IMAGE_PRESIGNED_URL_FULFILLED',

    GET_EDIT_FLUSER_PROFILE_IMAGE_PRESIGNED_URL: 'GET_EDIT_FLUSER_PROFILE_IMAGE_PRESIGNED_URL',
    GET_EDIT_FLUSER_PROFILE_IMAGE_PRESIGNED_URL_PENDING: 'GET_EDIT_FLUSER_PROFILE_IMAGE_PRESIGNED_URL',
    GET_EDIT_FLUSER_PROFILE_IMAGE_PRESIGNED_URL_FULFILLED: 'GET_EDIT_FLUSER_PROFILE_IMAGE_PRESIGNED_URL_FULFILLED',

    HANDLE_CLOSE_FRONTLINE_FORM: 'HANDLE_CLOSE_FRONTLINE_FORM',

    CUSTOMER_FEEDBACK_QUESTION: 'CUSTOMER_FEEDBACK_QUESTION',
    CUSTOMER_FEEDBACK_QUESTION_PENDING: 'CUSTOMER_FEEDBACK_QUESTION_PENDING',
    CUSTOMER_FEEDBACK_QUESTION_FULFILLED: 'CUSTOMER_FEEDBACK_QUESTION_FULFILLED',

    INSPECTOR_FEEDBACK: 'INSPECTOR_FEEDBACK',
    INSPECTOR_FEEDBACK_PENDING: 'INSPECTOR_FEEDBACK_PENDING',
    INSPECTOR_FEEDBACK_FULFILLED: 'INSPECTOR_FEEDBACK_FULFILLED',


    PROJECT_MODULE_TICKET_PAGE_CLICKED: 'PROJECT_MODULE_TICKET_PAGE_CLICKED',

    PREVIEW_PROJECT_MODULE_TICKET_PAGE_CLICKED: 'PREVIEW_PROJECT_MODULE_TICKET_PAGE_CLICKED',
    REMOVE_PRESIGNED_URL: 'REMOVE_PRESIGNED_URL',

    /* FL User Shift Attestation Question retrieve GET Call */
    GET_PER_FLUSER_SHIFT_ATTESTATION: 'GET_PER_FLUSER_SHIFT_ATTESTATION',
    GET_PER_FLUSER_SHIFT_ATTESTATION_PENDING: 'GET_PER_FLUSER_SHIFT_ATTESTATION_PENDING',
    GET_PER_FLUSER_SHIFT_ATTESTATION_FULFILLED: 'GET_PER_FLUSER_SHIFT_ATTESTATION_FULFILLED',


    HANDLE_WEEKLY_LIMIT_SET_CLOSE_MODAL:'HANDLE_WEEKLY_LIMIT_SET_CLOSE_MODAL',

    HANDLE_WEEKLY_LIMIT_SET_OPEN_MODAL:'HANDLE_WEEKLY_LIMIT_SET_OPEN_MODAL',

    GET_LOGBOOK_LOGS_QUESTIONS: 'GET_LOGBOOK_LOGS_QUESTIONS',
    GET_LOGBOOK_LOGS_QUESTIONS_PENDING: 'GET_LOGBOOK_LOGS_QUESTIONS_PENDING',
    GET_LOGBOOK_LOGS_QUESTIONS_FULFILLED: 'GET_LOGBOOK_LOGS_QUESTIONS_FULFILLED',

    UPDATE_FRONTLINE_ACTIVE_USER_NAME:"UPDATE_FRONTLINE_ACTIVE_USER_NAME",

    ADD_NEW_TICKET_WITH_AXIOS: 'ADD_NEW_TICKET_WITH_AXIOS',

   RESET_FRONTLINE: "RESET_FRONTLINE",
    ADD_NEW_PCT: 'ADD_NEW_PCT',
    ADD_NEW_PCT_PENDING: 'ADD_NEW_PCT_PENDING',
    ADD_NEW_PCT_FULFILLED: 'ADD_NEW_PCT_FULFILLED',
    ADD_NEW_PCT_REJECTED: 'ADD_NEW_PCT_REJECTED',

}