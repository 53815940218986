import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { ClapSpinner } from "react-spinners-kit";
import { Input } from "../../UI/InputElement/InputElement";
import { reduxForm } from "redux-form";
import { userDuplicateFormValidator as validate } from "../../../../utility/validator/validator";
import Img from "react-fix-image-orientation";

const UserDuplicateModal = (props) => {
  const { handleSubmit, pristine, reset, submitting, error } = props;
  const closeBtnClicked = () => {
    reset()
}
  return (
    <div
      className="modal fade performance_modal associateUser UserDuplicateModal"
      id="UserDuplicateModal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-dialog-centered modal-sm"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-header">
            <h2 className="md_title" id="exampleModalLongTitle">
            Please enter user details to make a copy
            </h2>
            <button
            onClick={closeBtnClicked}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">
                <img
                  src="/images/icons/icn_cancel_white.png"
                  alt="Icon"
                />
              </span>
            </button>
          </div>
          <div className="modal-body add_account_modal">
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-12 profile_img_block">
                <div className="circle_img user-pic">
                    <Input
                      hidden={true}
                      type="file"
                      name="photo_path"
                      onSelect={props.onFileChange}
                      fileRef={props.reference}
                      accept="image/*"
                    />
                    <div className="user-profile-pic">
                      <Img
                        style={{
                          height: "90px",
                          width: "90px",
                          borderRadius: "90px",
                        }}
                        src={
                          props.base64
                            ? props.base64
                            : props.s3Url
                            ? props.s3Url
                            : "/customImages/user.png"
                        }
                      />
                      <div
                        className="icn_userEdit"
                        onClick={props.choosePhotoClicked}
                      >
                        <a href="javascript:void (0)">
                          <i
                            className="fa fa-camera"
                            aria-hidden="true"
                          ></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                        <label className="label_modifier">First Name</label>
                        <Input className="form-control input-modifier" name='first_name' />
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                        <label className="label_modifier">Last Name</label>
                        <Input className="form-control input-modifier" name='last_name' />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <div className="form-group">
                        <label className="label_modifier">Email</label>
                        <Input type='email' className="form-control input-modifier" name='email' />
                    </div>
                </div>
            </div>
            <div style={{textAlign: 'center'}}>
            <button type="submit" disabled={props.isLoading} className={props.isLoading ? "btn cst_btn btn_danger mr-2 btn-wait" : "btn cst_btn btn_danger mr-2"} >{props.isLoading ? "" : "Submit"}</button>
            <button style={{ minHeight: '36px', minWidth: '80px' }} onClick={closeBtnClicked} data-dismiss="modal" aria-label="Close" className={"btn cst_btn btn-outline-secondary"} id='close_duplicate_user_button' type="cancel">Cancel</button>
            </div>
          </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default reduxForm({
  form: "userDuplicateForm",
  validate,
})(UserDuplicateModal);
