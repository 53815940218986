import React, { useEffect, useState } from 'react'
import withFormHoc from '../../../../../../hoc/withReactHookForm/withReactHookForm'
import { Input } from '../../../../UI/InputElement/reactFormHookInputField'
import { useDispatch, useSelector } from 'react-redux'
import { useFieldArray, useWatch } from 'react-hook-form'
import QuestionField from './QuestionField'
import AssetsFormSkeleton from '../../../../Inventory/Assets/NewAssetsForm/AssetsFormSkeleton'
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { getCustomerInfos, getJobRelatedToCompany } from '../../../../../../redux/actions'
import { handleSelfAuditSummaryForm } from '../../../../../../redux/actions/FormAuditModAction/InventoryAction/InventoryAction'
import { change } from 'redux-form'
function SelfAuditPage(props) {
    const { jobList, managersList } = useSelector((state) => state.FormAuditModReducer)
    const { isSelfAuditAuditForm, isLoading, } = useSelector((state) => state.auditsReducer)
    const { customersList } = useSelector((state) => state.FormAuditModReducer)
    const { self_audit_questions, self_audit_details } = useSelector((state) => state.auditsReducer)
    const { fields: auditorSections, } = useFieldArray({
        control: props.control,
        name: "auditorSections"
    })
    const { location: { pathname } } = useHistory()
    const { id } = useParams();

    const jobWatch = useWatch({
        control: props.control,
        name: "account_id"
    })

    const customerWatch = useWatch({
        control: props.control,
        name: "customer_id"
    })
    const dispatch = useDispatch()
    useEffect(() => {
        if (jobWatch && props.isDirty && self_audit_details?.account_id !== jobWatch) {
            getSelectedJobsCompany(jobWatch)
        }
    }, [jobWatch])


    useEffect(() => {
        if (customerWatch && props.isDirty && self_audit_details?.customer_id !== customerWatch) {
            getSelectedCompanyJobs(customerWatch)
        }
    }, [customerWatch])

    const isEdit = Boolean(pathname.split("/")[2] !== "view" && id)
    const getJobList = (job) => {
        if (job && job.length > 0) {
            return job && job.map((data) => {
                return {
                    value: data.id,
                    label: data.name,
                }
            })
        } else {
            return []
        }
    };

    const getCustomerList = (customer) => {
        if (customer && customer.length > 0) {
            return customer && customer.map((data) => {
                return {
                    value: data.id,
                    label: data.name,
                }
            })
        } else {
            return []
        }
    };

    const [listOfJob, setJobList] = useState(getJobList(jobList))
    const [listOfCustomer, setCustomerList] = useState(getCustomerList(customersList))

    const getSelectedJobsCompany = (accountId) => {

        let params = {
            accountId,
            account: true
        }

        dispatch(getCustomerInfos(params))
    }

    const getSelectedCompanyJobs = (companyId) => {

        let params = {
            address: true,
            companyId
        }
        dispatch(getJobRelatedToCompany(params))
    }

    useEffect(() => {
        setCustomerList(getCustomerList(customersList))
    }, [customersList])

    useEffect(() => {
        setJobList(getJobList(jobList))
    }, [jobList])

    if (isSelfAuditAuditForm) {
        return <AssetsFormSkeleton />
    }

    function checkForInvalidIsChecked(auditorSectionsLineItems) {
        for (let item of auditorSectionsLineItems) {

            if (item.is_checked == '') {

                return false;
            }
            // If the item has auditorSectionsLineItems, recursively check them
            if (item.auditorSectionsLineItems) {
                const result = checkForInvalidIsChecked(item.auditorSectionsLineItems);
                if (result !== true) {
                    return result; // If an error is found, return it immediately
                }
            }
        }
        // If all isChecked properties are valid, return null
        return true;
    }



    const handleValidateClick = (data) => {
        if (!data?.length || !data) return
        let error = false;
        for (let entry of data) {
            error = checkForInvalidIsChecked(entry.auditorSectionsLineItems);
            if (error !== true) {
                console.log(error);
                break; // Break the loop if an error is found
            }
        }

        if (error) return true
        else {
            return "Need to answer every questions!!"
        }
    }

    props.register(`auditorSections`, {
        type: "custom",
        validate: handleValidateClick,
        message: "please check one item",

    })

    const handleRest = () => {
        if (isEdit) {
            props.setValue("customer_id", self_audit_details.customer_id)
            props.setValue("account_id", self_audit_details?.account_id)
        } else {
            props.setValue("customer_id", "")
            props.setValue("account_id", "")
        }
        dispatch(handleSelfAuditSummaryForm())
    }


    return (
        <main class="app-content wraper_content chart_section">
            <section class="teammanage_formmain">
                <form class="container" noValidate onSubmit={props.handleSubmit((data) => {

                    props.onSubmit(data)
                })}>
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="team_manage_head">
                                <h1 class="tm_top_head">{props.isView && "View"} {isEdit && "Update"} Self Audit</h1>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div class="tm_form_wrapper">
                                {/* Form */}
                                <div class="tm_form_wrap">
                                    <div class="tm_form_innner">
                                        <div class="form">
                                            <div class="row">
                                                <div class="col-md-6 col-sm-12" style={{ position: "relative" }}>
                                                    {
                                                        !props?.isView ? <button className='btn ' style={{ position: "absolute", top: "-1px", left: "6rem", zIndex: 1, padding: "0px 1rem", backgroundColor: "#863035" }} type='button' onClick={handleRest}>
                                                            {/* <img src="/images/icons/refresh.svg" /> */}
                                                            <span style={{ fontSize: "11px", fontWeight: "bolder", color: "white" }}>Reset</span>

                                                        </button> : null
                                                    }

                                                    <div class="form-group mb-0">



                                                        <Input
                                                            type="inputSelect"
                                                            required
                                                            selectLabel="Customer"
                                                            control={props.control}
                                                            controller={props.controller}
                                                            selectName={`customer_id`}
                                                            id={`customer_id`}
                                                            isStar={true}
                                                            placeholder="Select Customer"
                                                            selectOptions={listOfCustomer}
                                                            formControlProps={props.register(
                                                                `customer_id`,
                                                                {
                                                                    required: "This field is required",
                                                                    maxLength: 80,
                                                                }
                                                            )}
                                                            selectClasses="form-select"
                                                            validationErrorMessage={
                                                                props.errors?.customer_id
                                                                    ?.message ?? ""
                                                            }
                                                            disabled={props?.isView}

                                                        />

                                                    </div>
                                                </div>
                                                <div class="col-md-6 col-sm-12 ">
                                                    <div class="form-group">

                                                        <Input
                                                            type="text"
                                                            id={`info`}
                                                            name={`info`}
                                                            isStar={true}
                                                            formControlClasses="form-control"
                                                            placeholder="Info"
                                                            required
                                                            label="Info"
                                                            formControlProps={props.register(`info`, {
                                                                required: "This field is required",
                                                                // pattern: {
                                                                //   value:/^[0-9]*$/ ,
                                                                //   message:"Must Be a number"
                                                                // },
                                                                maxLength: 80,
                                                            })}
                                                            validationErrorMessage={
                                                                props.errors?.info?.message ?? ""
                                                            }
                                                            disabled={props?.isView}
                                                            isIcon={false}
                                                        />

                                                    </div>
                                                </div>
                                                <div class="col-md-6 col-sm-12">
                                                    <div class="form-group">


                                                        <Input
                                                            type="inputSelect"
                                                            required
                                                            selectLabel="Job"
                                                            control={props.control}
                                                            controller={props.controller}
                                                            selectName={`account_id`}
                                                            id={`account_id`}
                                                            isStar={true}
                                                            placeholder="Job"
                                                            selectOptions={listOfJob}
                                                            formControlProps={props.register(
                                                                `account_id`,
                                                                {
                                                                    required: "This field is required",
                                                                    maxLength: 80,
                                                                }
                                                            )}
                                                            selectClasses="form-select"
                                                            validationErrorMessage={
                                                                props.errors?.account_id
                                                                    ?.message ?? ""
                                                            }
                                                            disabled={props?.isView}

                                                        />


                                                    </div>
                                                </div>

                                                <div class="col-md-6 col-sm-12">
                                                    <div class="form-group">

                                                        <Input
                                                            type="text"
                                                            id={`location`}
                                                            name={`location`}
                                                            isStar={true}
                                                            formControlClasses="form-control"
                                                            placeholder="Location"
                                                            required
                                                            label="Location"
                                                            formControlProps={props.register(`location`, {
                                                                required: "This field is required",
                                                                // pattern: {
                                                                //   value:/^[0-9]*$/ ,
                                                                //   message:"Must Be a number"
                                                                // },
                                                                maxLength: 80,
                                                            })}
                                                            validationErrorMessage={
                                                                props.errors?.location?.message ?? ""
                                                            }
                                                            disabled={props?.isView}
                                                            isIcon={false}
                                                        />

                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>


                                {
                                    auditorSections.length > 0 ? auditorSections.map((auditInfo, firstIndex) => {
                                        return (
                                            <div class="tm_form_wrap" key={firstIndex}>
                                                <div class="tm_form_innner">
                                                    <div class="selfaudit_title">
                                                        <h2>{auditInfo?.title}</h2>
                                                    </div>
                                                    <QuestionField control={props.control} index={firstIndex} controller={props.controller} {...props} />


                                                </div>
                                            </div>
                                        )
                                    }) : null
                                }

                                {
                                    props.errors?.auditorSections?.root?.message ? <p style={{ color: "red" }}>{props.errors?.auditorSections?.root?.message}</p> : null
                                }

                                <div class="row">
                                    <div class="col-sm-12">
                                        <div class="submitcancel_btn">
                                            <button type="button" class="btn cst_btn btn-outline-secondary  cancel_btnnew" onClick={props?.resetForm}>
                                                Cancel
                                            </button>
                                            {
                                                !props?.isView ? <button type="submit" className={isLoading ? "btn cst_btn btn_danger btn-wait" : "btn cst_btn btn_danger"}>
                                                    {isLoading ? "" : isEdit ? "Update" : "Submit"}
                                                </button> : null
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </form>
            </section>


        </main>
    )
}

export default withFormHoc(SelfAuditPage)
