import React from 'react';
import { CHIReviews } from '../../../utility/constants/constants';
import storage from '../../../utility/storage';

const CHIReviewStep3 = (props) => {

    const token = storage.get('token');
    
    setTimeout(() => {
        props.history.push('/');
    }, 5000);

    return (
        <body className="dashboard_page_body">
            <main className="report_wrapper web_view">
                <section className="chi_content_block appcontent_Inner">
                    {/* <!-- web modals --> */}
                    {token ? <div className="web_modal" style={{marginLeft: props.chisurveyStarted && props.chisurveyStarted == true ? '0px' : '25%', marginTop: '40px'}}>
                        <div className="web-modal-content web_three">
                            {/* <h4 className="modal_h4">Step 3 of 3</h4> */}
                            <div className="web_inn_container">
                                <figure className="web_logo">
                                    <img src="/images/icons/web_logo.png" alt="Pegasus" />
                                </figure>
                                <div className="web_inn_con">
                                    <div className="post_comment">
                                        <h5 className="web_h5">Thank you for your feedback. We really appreciate hearing about your experience.</h5>
                                        <p className="web_para">We take your feedback very seriously, knowing that even small changes can have a big impact in the future.  After all, better has no finish line.</p>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div> : <div className="web_modal">
                        <div className="web-modal-content web_three" style={{marginTop: '40px'}}>
                            <h4 className="modal_h4">Step 3 of 3</h4>
                            <div className="web_inn_container">
                                <figure className="web_logo">
                                    <img src="/images/icons/web_logo.png" alt="Pegasus" />
                                </figure>
                                <div className="web_inn_con">
                                    <div className="post_comment">
                                        <h5 className="web_h5">Thank you for your feedback</h5>
                                        <p className="web_para">We really appreciate hearing about your experience.</p>
                                        <p className="web_para">We take your feedback very seriously and will be making sure we improve our service based on your comments. We appreciate the time you have taken to leave feed back for us.</p>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>}
                </section>
            </main>
        </body>
    )
}

export default CHIReviewStep3;