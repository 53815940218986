import React from 'react';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { routes } from '../../../utility/constants/constants';

import { CountdownCircleTimer } from "react-countdown-circle-timer";
import InfoAlert from '../../../utility/infoAlert/infoAlert';
import storage from '../../../utility/storage';

const ChiQuarterlySurveyWaitScreen = (props) => {

    const renderTime = ({ remainingTime }) => {
        if (remainingTime === 0) {
            setTimeout(() => {
                let userId = props.chiSurveyProps && props.chiSurveyProps.id ? props.chiSurveyProps.id : ''
                let chiSurveyId = props.chiSurveyProps && props.chiSurveyProps.user_id ? props.chiSurveyProps.user_id : ''
                if(props.chiSurveyProps) {
                    if(props.isChiSurvey) {
                        props.history.push(`/accounts/chi_review?chi_survey_id=${userId}&user_id=${chiSurveyId}`);
                        InfoAlert('Please do the survey to proceed further!');
                    } else {
                        storage.remove('chisurveyStarted');
                        props.history.push('/');
                    }
                } else {
                    storage.remove('chisurveyStarted');
                    props.history.push('/');
                }
            }, 1500);
          return <h3 className="timer" style={{color:'#78495c', fontWeight:'600', fontFamily:'sans-serif'}}> {props.isChiSurvey ? 'Take the Survey' : 'Continue'} </h3>;
        }
      
        return (
          <div className="timer" style={{color:'#78495c', fontFamily:'sans-serif'}}>
            <h3 className="text-center" style={{fontWeight:'600'}}> Please wait for </h3>
            <h3 className="text-center" style={{fontWeight:'600'}}>a moment</h3>
          </div>
        );
    };

    return (
        <div className='app-content' style={{backgroundColor:'#e9e9e96b', marginTop: '-11px', padding: '300px 100px', marginLeft: '0px'}}>
            <div className="timer-wrapper" style={{display:'flex', justifyContent:'center'}}>
                <CountdownCircleTimer
                isPlaying
                size={250}
                duration={3}
                colors={["#850037", "#850037", "#850037", "#c5b3ba", "#e5e5e5"]}
                colorsTime={[10, 6, 3, 0]}
                onComplete={() => ({ shouldRepeat: false, delay: 1 })}
                >
                {renderTime}
                </CountdownCircleTimer>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        isLoading: state.adminOrSuperAdminReducer.isLoading,
        isChiSurvey: state.adminOrSuperAdminReducer.chiSurveyCheck && typeof(state.adminOrSuperAdminReducer.chiSurveyCheck.chi_survey) == 'boolean' ? state.adminOrSuperAdminReducer.chiSurveyCheck.chi_survey == true ? true : false : null,
        chiSurveyProps: state.adminOrSuperAdminReducer.chiSurveyCheck && state.adminOrSuperAdminReducer.chiSurveyCheck.chiSurvey ? state.adminOrSuperAdminReducer.chiSurveyCheck.chiSurvey : null,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChiQuarterlySurveyWaitScreen);