import React, { useEffect, useState } from 'react'
import { COLLECTIONS, accessories, controlItems,sectionsList } from '../../../../../../utility/constants/constants';
import { makeFirstLetterCapital } from '../../../../../../utility/utility';
import { useSelector } from 'react-redux';

function CollectionsComponent(props) {

    const {checklist }=useSelector((state)=>state.FormAuditModReducer)
    const section = JSON.parse(JSON.stringify(sectionsList.sections))
    const initialSections = checklist ? checklist.sections : section;
    const [sections, setSections] = useState(initialSections)
   
    useEffect(() => {
        props.setValues(initialSections)
    },[])

    const handleChange = (e, cName) => {
        const values = [...sections]
        const findCollection = values.find((item) => item.name === cName)
        let findLineItems = findCollection.lineItems.find((item) => item.name === e.target.name)
        findLineItems.is_checked = e.target.checked
        let updateCount = findCollection.lineItems.filter((item) => item.is_checked === true).length
        findCollection.checked_count = updateCount
        setSections(values)
        props.setValues(values)
        if(updateCount > 0){
            props.setIsAllChecked(false)
        }
    }

    const collectionRenderHandler = (values, collectionName) => {
        if (values) {
            const intialValues = [...values]
            const filterValues = intialValues.find((item) => item.name === collectionName)
            const finalValue = filterValues.lineItems
            return finalValue

        } else {
            return []
        }

    }

    const showCount = (values, cName) => {
        const value = [...values]
        const findCount = value.find((item) => item.name === cName)
        if(findCount.checked_count === 0){
            return null
        }else{
            return findCount.checked_count
        }
        
    }

    const totalCounts = (values, cName) => {
        const value = [...values]
        const findCount = value.find((item) => item.name === cName)
        return findCount.total_count
    }

    return (
        <div class="team_checklist_checkbox_card_wrapper">
            <div class="row">
                <div class="col-lg-6">
                    <div class="team_management_card_wrap">
                        <div class="card_top_wrap">
                            <h4>Collection</h4>
                            <div class="digit_box_wrap">
                                <span class="digit_box">{showCount(sections, COLLECTIONS.collection)}</span>
                                <span class="digit_wrap">of {totalCounts(sections, COLLECTIONS.collection)}</span>
                            </div>
                        </div>
                        <div class="img_chcekbox_wrapper">
                            <div class="left_img_wrap flex-shrink-0">
                                <img src="/images/thumbnails/img-1.png" alt="img 1" />
                            </div>
                            <div class="custom_checkbox_wrap flex-grow-1">
                                {collectionRenderHandler(sections, COLLECTIONS.collection).map((lineItem) => {
                                    return (
                                        <div key={lineItem.name} class="cstm_checkbox_wrap_new">
                                            <label class="cstm_check_container">
                                                {lineItem.name} <input type="checkbox" onChange={(e) => handleChange(e, COLLECTIONS.collection)} name={lineItem.name} checked={lineItem.is_checked} disabled={props.isView} />
                                                <span class="checkmark"></span>
                                            </label>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="team_management_card_wrap">
                        <div class="card_top_wrap">
                            <h4>Vacuum</h4>
                            <div class="digit_box_wrap">
                                <span class="digit_box">{showCount(sections, COLLECTIONS.vaccum)}</span>
                                <span class="digit_wrap">of {totalCounts(sections, COLLECTIONS.vaccum)}</span>
                            </div>
                        </div>
                        <div class="img_chcekbox_wrapper">
                            <div class="left_img_wrap flex-shrink-0">
                                <img
                                    src="/images/thumbnails/vacuum-img.png"
                                    alt="vacuum img"
                                />
                            </div>
                            <div class="custom_checkbox_wrap flex-grow-1">
                                {collectionRenderHandler(sections, COLLECTIONS.vaccum).map((lineItem) => {
                                    return (
                                        <div key={lineItem.name} class="cstm_checkbox_wrap_new">
                                            <label class="cstm_check_container">
                                                {lineItem.name} <input type="checkbox" disabled={props.isView} onChange={(e) => handleChange(e, COLLECTIONS.vaccum)} name={lineItem.name} checked={lineItem.is_checked} />
                                                <span class="checkmark"></span>
                                            </label>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-6">
                    <div class="team_management_card_wrap">
                        <div class="card_top_wrap">
                            <h4>Restroom</h4>
                            <div class="digit_box_wrap">
                                <span class="digit_box">{showCount(sections, COLLECTIONS.restroom)}</span>
                                <span class="digit_wrap">of {totalCounts(sections, COLLECTIONS.restroom)}</span>
                            </div>
                        </div>
                        <div class="img_chcekbox_wrapper">
                            <div class="left_img_wrap flex-shrink-0">
                                <img
                                    src="/images/thumbnails/restroom-img.png"
                                    alt="restroom-img"
                                />
                            </div>
                            <div class="custom_checkbox_wrap flex-grow-1">
                                {collectionRenderHandler(sections, COLLECTIONS.restroom).map((lineItem) => {
                                    return (
                                        <div key={lineItem.name} class="cstm_checkbox_wrap_new">
                                            <label class="cstm_check_container">
                                                {lineItem.name} <input type="checkbox" disabled={props.isView} onChange={(e) => handleChange(e, COLLECTIONS.restroom)} name={lineItem.name} checked={lineItem.is_checked} />
                                                <span class="checkmark"></span>
                                            </label>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="team_management_card_wrap">
                        <div class="card_top_wrap">
                            <h4>Utility</h4>
                            <div class="digit_box_wrap">
                                <span class="digit_box">{showCount(sections, COLLECTIONS.utility)}</span>
                                <span class="digit_wrap">of {totalCounts(sections, COLLECTIONS.utility)}</span>
                            </div>
                        </div>
                        <div class="img_chcekbox_wrapper">
                            <div class="left_img_wrap flex-shrink-0">
                                <img
                                    src="/images/thumbnails/utility_img.png"
                                    alt="utility"
                                />
                            </div>
                            <div class="custom_checkbox_wrap flex-grow-1">
                                {collectionRenderHandler(sections, COLLECTIONS.utility).map((lineItem) => {
                                    return (
                                        <div key={lineItem.name} class="cstm_checkbox_wrap_new">
                                            <label class="cstm_check_container">
                                                {lineItem.name} <input type="checkbox" disabled={props.isView} onChange={(e) => handleChange(e, COLLECTIONS.utility)} name={lineItem.name} checked={lineItem.is_checked} />
                                                <span class="checkmark"></span>
                                            </label>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-6">
                    <div class="team_management_card_wrap">
                        <div class="card_top_wrap">
                            <h4>Daily Kitting</h4>
                            <div class="digit_box_wrap">
                                <span class="digit_box">{showCount(sections, COLLECTIONS.dailyKitting)}</span>
                                <span class="digit_wrap">of {totalCounts(sections, COLLECTIONS.dailyKitting)}</span>
                            </div>
                        </div>
                        <div class="img_chcekbox_wrapper">
                            <div class="left_img_wrap flex-shrink-0">
                                <img
                                    src="/images/thumbnails/daily_kitting_img.png"
                                    alt="daily"
                                />
                            </div>
                            <div class="custom_checkbox_wrap flex-grow-1">
                                {collectionRenderHandler(sections, COLLECTIONS.dailyKitting).map((lineItem) => {
                                    return (
                                        <div key={lineItem.name} class="cstm_checkbox_wrap_new">
                                            <label class="cstm_check_container">
                                                {lineItem.name} <input type="checkbox" disabled={props.isView} onChange={(e) => handleChange(e, COLLECTIONS.dailyKitting)} name={lineItem.name} checked={lineItem.is_checked} />
                                                <span class="checkmark"></span>
                                            </label>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="team_management_card_wrap">
                        <div class="card_top_wrap">
                            <h4>Accessories</h4>
                            <div class="digit_box_wrap">
                                <span class="digit_box">{showCount(sections, COLLECTIONS.accessoris)}</span>
                                <span class="digit_wrap">of {totalCounts(sections, COLLECTIONS.accessoris)}</span>
                            </div>
                        </div>
                        <div class="img_chcekbox_wrapper">
                            <div class="right_checkbox_wrap flex-grow-1">
                                {collectionRenderHandler(sections, COLLECTIONS.accessoris).map((item, i) => {
                                    return (
                                        <div key={i} class="checkbox_list">
                                            <div
                                                class="cstm_checkbox_wrap_new cstm_checkbox_wrap_new_inline_sm"
                                            >
                                                <label class="cstm_check_container">
                                                    <input type="checkbox" disabled={props.isView} checked={item.is_checked} onChange={(e) => handleChange(e, COLLECTIONS.accessoris)} name={item.name} />
                                                    <span class="checkmark"></span>
                                                </label>
                                                <div class="left_img_wrap flex-shrink-0">
                                                    <img
                                                        src={accessories[i].title}
                                                        alt="img 1"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-6">
                    <div class="team_management_card_wrap">
                        <div class="card_top_wrap">
                            <h4>Beyond Compliance</h4>
                            <div class="digit_box_wrap">
                                <span class="digit_box">{showCount(sections, COLLECTIONS.beyondComplaince)}</span>
                                <span class="digit_wrap">of {totalCounts(sections, COLLECTIONS.beyondComplaince)}</span>
                            </div>
                        </div>
                        <div class="img_chcekbox_wrapper">
                            <div class="left_img_wrap flex-shrink-0">
                                <img
                                    src="/images/thumbnails/beyond_compliance.png"
                                    alt="img 1"
                                />
                            </div>
                            <div class="custom_checkbox_wrap flex-grow-1">
                                {collectionRenderHandler(sections, COLLECTIONS.beyondComplaince).map((lineItem) => {
                                    return (
                                        <div key={lineItem.name} class="cstm_checkbox_wrap_new">
                                            <label class="cstm_check_container">
                                                {lineItem.name} <input type="checkbox" disabled={props.isView} onChange={(e) => handleChange(e, COLLECTIONS.beyondComplaince)} name={lineItem.name} checked={lineItem.is_checked} />
                                                <span class="checkmark"></span>
                                            </label>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="team_management_card_wrap">
                        <div class="card_top_wrap">
                            <h4>Control Items</h4>
                            <div class="digit_box_wrap">
                                <span class="digit_box">{showCount(sections, COLLECTIONS.controlItems)}</span>
                                <span class="digit_wrap">of {totalCounts(sections, COLLECTIONS.controlItems)}</span>
                            </div>
                        </div>
                        <div class="img_chcekbox_wrapper">
                            <div class="right_checkbox_wrap flex-grow-1">
                                {collectionRenderHandler(sections, COLLECTIONS.controlItems).map((item, i) => {
                                    return (
                                        <div key={i} class="checkbox_list">
                                            <div
                                                class="cstm_checkbox_wrap_new cstm_checkbox_wrap_new_inline_sm"
                                            >
                                                <label class="cstm_check_container">
                                                    <input type="checkbox" disabled={props.isView} checked={item.is_checked} onChange={(e) => handleChange(e, COLLECTIONS.controlItems)} name={item.name} />
                                                    <span class="checkmark"></span>
                                                </label>
                                                <div class="left_img_wrap flex-shrink-0">
                                                    <img
                                                        src={controlItems[i].title}
                                                        alt="img 1"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-6">
                    <div class="team_management_card_wrap">
                        <div class="card_top_wrap">
                            <h4>Pantry</h4>
                            <div class="digit_box_wrap">
                                <span class="digit_box">{showCount(sections, COLLECTIONS.pantry)}</span>
                                <span class="digit_wrap">of {totalCounts(sections, COLLECTIONS.pantry)}</span>
                            </div>
                        </div>
                        <div class="img_chcekbox_wrapper">
                            <div class="left_img_wrap flex-shrink-0">
                                <img
                                    src="/images/thumbnails/pantry_img.png"
                                    alt="daily"
                                />
                            </div>
                            <div class="custom_checkbox_wrap flex-grow-1">
                                {collectionRenderHandler(sections, COLLECTIONS.pantry).map((lineItem) => {
                                    return (
                                        <div key={lineItem.name} class="cstm_checkbox_wrap_new">
                                            <label class="cstm_check_container">
                                                {lineItem.name} <input type="checkbox" disabled={props.isView} onChange={(e) => handleChange(e, COLLECTIONS.pantry)} name={lineItem.name} checked={lineItem.is_checked} />
                                                <span class="checkmark"></span>
                                            </label>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                props.isAllChecked ? (
                    <div className='row'>
                        <div className='col-lg-12 text-center'>
                            <p style={{ color: 'red' }}>Please select atleast one field</p>
                        </div>
                    </div>
                ) : null
            }
        </div>
    )
}

export default CollectionsComponent
