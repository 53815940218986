import React from 'react';
import { Input } from '../../../UI/InputElement/InputElement';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import EmergencyPickupTable from './emergencyPickupTable';

let EmergencyPickup = (props) => {

  const statusOptions = [
    {
      id: 'open',
      name: 'Open'
    }, {
      id: 'approved',
      name: 'Approved'
    }, {
      id: 'rejected',
      name: 'Rejected'
    }
  ];

  const { handleSubmit, pristine, reset, submitting, error } = props;
  return (
    <>
      <main className="app-content wraper_content inspection_wraper">
        <div className="tab_header search_filter_cont inspection_tab_content appcontent_inner frontlines_filter mb-2">
          <form onSubmit={handleSubmit} >
            <div className="row mt-2">
              <div className="col-xl-2 col-lg-3 col-md-6 col-sm-6 col-12">
                <div className="app-search">
                  <Input className="fil_search_input input-modifier" name="id" type="search" placeholder="Search by ID" dataTest="frontline_name_filter" />
                  <button className="app-search__button">
                    <i class="fa fa-search" aria-hidden="true"></i>
                  </button>
                </div>
              </div>
              <div className="col-xl-4 col-lg-3 col-md-6 col-sm-6 col-12">
                <div className="app-search">
                  <Input className="fil_search_input input-modifier" name="fulfillment_manager_location" type="search" placeholder="Search by Fulfillment Manager Location" dataTest="frontline_name_filter" />
                  <button className="app-search__button">
                    <i class="fa fa-search" aria-hidden="true"></i>
                  </button>
                </div>
              </div>
              <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
                <div className="app-search">
                  <Input className="fil_search_input input-modifier" name="manager" type="search" placeholder="Search by Manager" dataTest="frontline_id_filter" />
                  <button className="app-search__button">
                    <i class="fa fa-search" aria-hidden="true"></i>
                  </button>
                </div>
              </div>
              <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
                <div className="app-search">
                  <Input className="fil_search_input input-modifier" name="approver" type="search" placeholder="Search by Approver" dataTest="frontline_id_filter" />
                  <button className="app-search__button">
                    <i class="fa fa-search" aria-hidden="true"></i>
                  </button>
                </div>
              </div>
            </div>
            <div className='row mt-3' style={{ flexWrap: 'nowrap' }}>
              <div className="col-xl-2 col-lg-4 col-md-6 col-sm-6">
                <Input name="status" dataTest="select_ticket_status" type="select" options={statusOptions} placeholder="Search By Status" className="custom-select input-modifier add_user_select" />
              </div>
              <div className="col-xl-4 col-lg-3 col-md-6 col-sm-6 col-12">
                <div className="frontline_btn">
                  <button data-test="filter_go_btn" style={{ minWidth: '90px' }} className={"btn cst_btn btn_danger mr-2"} id="submit" type="submit">{"Go"} </button>
                  <button data-test="filter_reset_btn" style={{ minWidth: '90px' }} onClick={props.resetClicked} className={"btn cst_btn btn-outline-secondary"} id="reset" type="reset">{"Reset"} </button>
                </div>
              </div>
            </div>
          </form>
        </div>
        <section className="account_sec user_screen inspection_tab_content">

          <div className="quotes_table_content table-responsive">

            <div className="table quotes_table user_table_contnet user_react_table account_table">
              <EmergencyPickupTable
                {...props}
                approveClicked={props.approveClicked}
                rejectClicked={props.rejectClicked}
                isLoading={props.isLoading} />
            </div>

          </div>
        </section>
      </main>

      <Dialog
        open={props.openDeleteModel}
        onClose={props.handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title" style={{ fontSize: '13', color: '#06080f' }}>
          <h3 style={{ color: '#ab385e' }}>Are you sure?</h3>
        </DialogTitle>

        <DialogActions>
          <button style={{ minHeight: '36px', minWidth: '80px' }} onClick={() => props.deleteFrontline(props.deleteObject.id)} disabled={props.isLoading} className={props.isLoading ? "btn cst_btn btn_danger mr-2 btn-wait" : "btn cst_btn btn_danger mr-2"} id="delete" type="delete">{props.isLoading ? "" : "Delete"}</button>
          <button style={{ minHeight: '36px', minWidth: '80px' }} onClick={props.handleDeleteClose} className={"btn cst_btn btn-outline-secondary"} id="cancel" type="cancel">Cancel</button>
        </DialogActions>
      </Dialog>

    </>
  )
}

const mapStateToProps = (state, props) => {
  let id = state.InventoryReducer.emergency_tickets_searchValues && state.InventoryReducer.emergency_tickets_searchValues.id ? state.InventoryReducer.emergency_tickets_searchValues.id : props.paramsTicketId && props.paramsTicketId != '' ? props.paramsTicketId : "";
  let manager = state.InventoryReducer.emergency_tickets_searchValues && state.InventoryReducer.emergency_tickets_searchValues.manager ? state.InventoryReducer.emergency_tickets_searchValues.manager : "";
  let fulfillment_manager_location = state.InventoryReducer.emergency_tickets_searchValues && state.InventoryReducer.emergency_tickets_searchValues.fulfillment_manager_location ? state.InventoryReducer.emergency_tickets_searchValues.fulfillment_manager_location : "";
  let approver = state.InventoryReducer.emergency_tickets_searchValues && state.InventoryReducer.emergency_tickets_searchValues.approver ? state.InventoryReducer.emergency_tickets_searchValues.approver : "";
  let status = state.InventoryReducer.emergency_tickets_searchValues && state.InventoryReducer.emergency_tickets_searchValues.status ? state.InventoryReducer.emergency_tickets_searchValues.status : "open";

  return { initialValues: { manager, fulfillment_manager_location, approver, status, id } }
}

EmergencyPickup = reduxForm({
  form: 'searchEmergencyPickupListForm',
  //validate,
  enableReinitialize: true
})(EmergencyPickup);

EmergencyPickup = connect(mapStateToProps)(EmergencyPickup)

export default EmergencyPickup;