import React, { useState } from 'react';

import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  makeStyles,
  Button,
  IconButton,
  Icon
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      padding: theme.spacing(5),
    },
    textAlign: "center"
  },
  input: {
    display: 'none',
  },
}));

const ImportCSV = (props) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [importCsv, setCsv] = useState(null);

  const handleImport = () => {
    var formData = new FormData();
    formData.append('file', importCsv);
    props.importFrontline(formData).then(res => {
      setOpen(false);
      setCsv(null);
    })
  };
  const handleClose = () => {
    setCsv(null);
    setOpen(false);
  };
  const handleChange = (e) => {
    setCsv(e.target.files[0])
  };
  return (
    <>
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 text-left accounts_btn_wra theme_col_px">
        <button data-test="import_csv" type="button" className="btn cst_btn btn_danger mr-2 float-right" onClick={() => setOpen(true)} data-backdrop="static" data-keyboard="false" data-toggle="modal" data-target="#associateUser">Import CSV</button>
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <h4 id="form-dialog-title" data-test="import_csv_title" className="p-2" style={{ textAlign: "left", fontSize: '13', color: '#ab385e' }}>
          Import CSV
        </h4>
        <DialogContent className="pl-5 pr-5">
          <div className={classes.root}>
            <input
              data-test="upload_csv"
              accept=".csv"
              className={classes.input}
              id="contained-button-file"
              multiple
              type="file"
              onChange={handleChange}
            />

            <input accept="image/*" className={classes.input} id="icon-button-file" type="file" />
            <label htmlFor="contained-button-file">
              <Button variant="contained" component="span">
                UPLOAD CSV
              </Button>
            </label>
          </div>
          <h6 className="text-center" data-test="import_file_name">{importCsv && importCsv.name}</h6>
        </DialogContent>
        <DialogActions>
          <button data-test="import_btn" style={{ minHeight: '36px', minWidth: '80px' }} onClick={() => handleImport()} disabled={props.isLoading} className={`${importCsv ? "" : "disabled pointer-none"} ${props.isLoading ? "btn cst_btn btn_danger mr-2 btn-wait" : "btn cst_btn btn_danger mr-2"}`} id="delete" type="delete">{props.isLoading ? "" : "Import"}</button>
          <button data-test="cancel_button" style={{ minHeight: '36px', minWidth: '80px' }} onClick={() => handleClose()} className={"btn cst_btn btn-outline-secondary"} id="cancel" type="cancel">Cancel</button>
        </DialogActions>
      </Dialog>
    </>
  )
}


export default ImportCSV;