import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

const Field = (h, w) => {
    return (<Skeleton height={h} width={w} />)
}

const Image = () => {
    return (<Skeleton circle={true} height={100} width={100} />)
}

const PCTFormSkeleton = (props) => {
    return (
        <main className="wraper_content user_newQuote add_account_modal add_New_Accont">
            <div className="inspection_content_sec appcontent_Inner">
                <div className="container-fluids">
                    <div className="row">
                        <div className="col-sm-12 text-center">

                            <h4 className="section_title">{Field(30, 200)}</h4>
                        </div>
                    </div>
                    {/* <form onSubmit={handleSubmit}> */}
                    <div className="pct_form ticket_form">
                        <div className="addQuote_form">
                            <div className="cst_tab_content">


                                <div className="row">
                                    <div className="col-md-6" style={{ marginTop: '40px' }}>
                                        <div className="form-group">

                                            {/* <label for="email" className="label_modifier"></label> */}
                                            {Field(30)}
                                            {/* <Input type="text" className="form-control input-modifier" id="email" placeholder="" name="user[first_name]" /> */}
                                        </div>
                                    </div>
                                    <div className="col-md-6" style={{ marginTop: '40px' }}>
                                        <div className="form-group">
                                            {Field(30)}
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6" style={{ marginTop: '40px' }}>
                                        <div className="form-group">
                                            {Field(30)}

                                        </div>
                                    </div>
                                    <div className="col-md-6" style={{ marginTop: '40px' }}>
                                        <div className="form-group">
                                            {Field(30)}

                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6" style={{ marginTop: '40px' }}>
                                        <div className="form-group">
                                            {Field(30)}

                                        </div>
                                    </div>
                                    <div className="col-md-6" style={{ marginTop: '40px' }}>
                                        <div className="form-group">
                                            {Field(30)}

                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6" style={{ marginTop: '40px' }}>
                                        <div className="form-group">
                                            {Field(30)}

                                        </div>
                                    </div>
                                    <div className="col-md-6" style={{ marginTop: '40px' }}>
                                        <div className="form-group">
                                            {Field(100)}

                                        </div>
                                    </div>
                                </div>
                                <div className="row" style={{justifyContent : 'center'}}>
                                    <div className="col-md-2" style={{ marginTop: '40px' }}>
                                        <div className="form-group">
                                            {Field(30)}

                                        </div>
                                    </div>
                                    <div className="col-md-2" style={{ marginTop: '40px' }}>
                                        <div className="form-group">
                                            {Field(30)}

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* </form> */}
                </div>
            </div>
        </main>
    )
}

export default PCTFormSkeleton;