import React from 'react';
import PlacesAutocomplete from 'react-places-autocomplete';
import $ from "jquery";

const googlePlacesAutoComplete = (props) => {
     
    // let className = props.AddressErrorMessage ? 'form-control input-modifier errorStyles' : 'form-control input-modifier inputStyles'
    // if (props.className) {
    //     className = props.className
    // }
    const removeAutoFill = (e) => {
        $(function () {

            $('input[type=text]').disableAutocomplete();
        })
    }

    return (
        <PlacesAutocomplete
            value={props.value}
            onChange={props.handleAddressChange}
            onSelect={props.handleAddressSelect}
        >
            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                <div>
                    <input
                        data-test="address_data"
                        onBlurCapture={props.handleAddressTouch}
                        id="googleAddress"
                        style={props.isAddressEmpty ? { borderColor: 'rgb(240, 77, 83)', borderLeftWidth: '3px' } : null}
                        {...getInputProps({
                            placeholder: 'Add Address',
                            className: "form-control input-modifier",
                            autocomplete: "somedummytexttomakeautofilloff"
                        })}
                    />
                    <span style={{ color: '#DD2726', fontSize: '13px' }}>{props.isAddressEmpty ? 'This field is required.' : null}</span>
                    <div className="autocomplete-dropdown-container" style={suggestions.length !== 0 ? { border: '1px solid #c3c3c3' } : null}>
                        {loading && <div>Loading...</div>}
                        {suggestions.map(suggestion => {
                            const className = suggestion.active
                                ? 'suggestion-item--active'
                                : 'suggestion-item';
                            // inline style for demonstration purpose
                            const style = suggestion.active
                                ? { backgroundColor: '#ebf0f7', cursor: 'pointer', fontSize: '12px', paddingLeft: '5px', fontWeight: 'bold', paddingTop: '10px', paddingBottom: '10px' }
                                : { backgroundColor: '#ffffff', cursor: 'pointer', fontSize: '12px', paddingLeft: '5px', fontWeight: 'bold', paddingTop: '10px', paddingBottom: '10px' };
                            return (
                                <div
                                    {...getSuggestionItemProps(suggestion, {
                                        className,
                                        style,
                                    })}
                                >
                                    <span >{suggestion.description}</span>
                                </div>
                            );
                        })}
                    </div>
                </div>
            )}
        </PlacesAutocomplete>
    )
}

export default googlePlacesAutoComplete;