import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../../../redux/actions/index";
import * as $ from "jquery";
import History from "../../component/History/NewSearch";
class SearchHistory extends Component {
  timer;
  constructor(props) {
    super(props);
    this.state = {
      result: "No result",
      loading: false,
      search: {
        area_type_id: null,
        floor_id: null,
        area_name: null,
        page: 1,
        size: 15,
        totalCount: 0,
        searched: false
      }
    };
    this.handleOnChangeSearch = this.handleOnChangeSearch.bind(this)
    this.handleSubmitSearch = this.handleSubmitSearch.bind(this)
    this.handleSubmitNewSearch = this.handleSubmitNewSearch.bind(this)
    this.handlePageChange = this.handlePageChange.bind(this)
    this.onAreaChange = this.onAreaChange.bind(this)
    this.getTheData = this.getTheData.bind(this)
    this.handleReset = this.handleReset.bind(this)
  }
  componentWillUnmount() {
    $('.login100-more').removeClass("d-none");
    $('body').removeClass("overflow-hidden");
    clearInterval(this.timer) // here...
  }

  componentDidMount = () => {
    const {page, size} = this.state.search;
    $('.login100-more').addClass("d-none");
    $('body').addClass("overflow-hidden");
    if (window && window.innerWidth >= 768) this.props.sideBarClicked();
    //this.props.getSearchHistory({page, size}, this.props.match.params.accountId);
    //this.props.getAreaTypes();
    this.props.getAllAreaDetails(this.props.match.params.accountId);
    this.props.getSearchHistory({page: 1, size: 15}, this.props.match.params.accountId);
    this.timer = setInterval(()=> this.getTheData(), 110000); // here...
  };
  getTheData () {
    this.props.getSearchHistory({page: 1, size: 15}, this.props.match.params.accountId);
  }
  getHistory = (params) => {
    this.props.getSearchHistory(params, this.props.match.params.accountId).then(res=> {
      this.setState({searched: true});
    })
  };

  handleOnChangeSearch (e) {
    this.setState({searched: false});
    let search = this.state.search;
    search[e.target.name] = e.target.value ? e.target.value : null;
    this.setState({search})
  }

  handleSubmitSearch (e) {
    e.preventDefault();
    const {area_name, floor_id, area_type_id, size, page} = this.state.search;
    this.getHistory(
        {
          page,
          size,
          area_name, floor_id, area_type_id
        }
    );
  }


  handleSubmitNewSearch (e) {
    if (this.state.search.area_name && this.state.search.area_type_id && this.state.search.floor_id) {
      const {area_name, floor_id, area_type_id, size} = this.state.search;
      this.props.getSearchHistory(
          {
            page: 1,
            size,
            area_name, floor_id, area_type_id
          },
          this.props.match.params.accountId).
          then(res => {
            this.props.history.push(`/account/${this.props.match.params.accountId}/scan-result-history`)
          });
    }
  }
  onAreaChange (e) {
    this.props.getSearchHistory(
        {
          page: 1,
          size: this.state.search.size,
          area_name: e.value
        },
        this.props.match.params.accountId).
        then(res => {
          this.props.history.push(`/account/${this.props.match.params.accountId}/scan-result-history`)
        });
  }
  handlePageChange(pageNumber) {
    console.log(`active page is ${pageNumber}`);
    let search = {...this.state.search};
  search.page = pageNumber;
      this.setState({search});
      this.getHistory(search)
}

handleReset() {
  let search = {...this.state.search};
search = {...search, area_type_id: null,
  floor_id: null,
  area_name: null,
  page: 1
}
this.setState({search});
this.getHistory(search);
}
render() {
  return (
      <>
      <History
          {...this.props}
          handleSubmitSearch={this.handleSubmitSearch}
          handlePageChange={this.handlePageChange}
          handleSubmitNewSearch={this.handleSubmitNewSearch}
          search={this.state.search}
          searched={this.state.searched}
          total_no_of_pages={this.props.total_no_of_pages}
          handleOnChangeSearch={this.handleOnChangeSearch}
          handleReset={this.handleReset}
          onAreaChange={this.onAreaChange}
          />
      </>
  );
}
}

const mapStateToProps = (state) => {
  return {
    user: state.authReducer.user,
    isLoading: state.adminOrSuperAdminReducer.isLoading,
    total_no_of_pages: state.adminOrSuperAdminReducer.total_no_of_pages,
    areaTypes: state.adminOrSuperAdminReducer.accountAreaTypes,
    floorList: state.adminOrSuperAdminReducer.accountFloorList,
    areaNames: state.adminOrSuperAdminReducer.areaNames,
    isAccountAreas: state.adminOrSuperAdminReducer.isAccountAreas,
    areaSearchResult: state.adminOrSuperAdminReducer.areaSearchResult,
    searchHistory: state.adminOrSuperAdminReducer.searchHistory
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllAreaDetails: (token) => dispatch(actions.getAllAreaDetails(token)),
    sideBarClicked: () => dispatch(actions.sideBarClicked()),
    getAllHistory: (params, id) => dispatch(actions.getAllHistory(params, id)),
    getSearchHistory: (params, id) =>
        dispatch(actions.searchHistory(params, id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchHistory);
//export default SearchHistory;
