import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import '../../../styles/custom.css';
import KpiTicketDashboard from "./KpiTicketDashboard";
import { Input } from '../UI/InputElement/InputElement';
import { reduxForm, formValueSelector, getFormValues } from 'redux-form';
import { KpiDashboardSearchValidator as validate } from '../../../utility/validator/validator';



const getAccountsDropDown = (accounts) => {

  return accounts && accounts.map(a => {
      return {
          value: a.id,
          label: a.name
      }
  })
}

const getUsersDropDown = (users) => { 

  return users && users.map(u => {
      return {
          value: u.id,
          label: u.first_name + ' ' + u.last_name
      }
  })
}


let KpiTicket = (props) => {


  const [accountList, setAccountList] = useState(getAccountsDropDown(props.companyUsersList));
  const [usersList, setUsersList] = useState(getUsersDropDown(props.dropDownUsersList));



  const trimText = (num) => {
      return Number.parseFloat(num).toFixed(2);
  }
  useEffect(() => {
      setAccountList(getAccountsDropDown(props.companyUsersList))
  }, [props.companyUsersList])

  useEffect(() => {
      setUsersList(getUsersDropDown(props.dropDownUsersList))
  }, [props.dropDownUsersList])

 

  const noUserOptionsMessage = (a, b) => {
      return 'User not found';
  }

  const noAccountOptionsMessage = (a, b) => {
      return 'Job not found';
  }

  const { handleSubmit, pristine, reset, submitting, error } = props;

  return (

    
    <div className="kpiTicket" id="kpi_dashboard_tabs">
          <>
          <div className="tab_header search_filter_cont inspection_tab_content">
          <form onSubmit={handleSubmit}>
          <div className="row">
          <div className="col-xl-3 col-lg-4 col-md-12 col-sm-12">
              <Input name="account_id" noOptionsMessage={noAccountOptionsMessage} placeholder="Job" type="inputSelect" options={accountList} />
          </div>
          <div className="col-xl-2 col-lg-4 col-md-12 col-sm-12">
          <div className="app-search">
          <Input dataTest="search_by_description" name="category_id" className="fil_search_input" type="number" placeholder="Category ID" />
          <button className="app-search__button">
              <i class="fa fa-search" aria-hidden="true"></i>
          </button>
          </div>          
         </div> 
         
          <div className="col-xl-2 col-lg-4 col-md-12 col-sm-12">
          <div className="app-search">
          <Input dataTest="search_by_description" name="created_by" className="fil_search_input" type="search" placeholder="Created By" />
          <button className="app-search__button">
              <i class="fa fa-search" aria-hidden="true"></i>
          </button>
      </div>
      </div>
          <div className="col-xl-2 col-lg-4 col-md-12 col-sm-12">
          <div className="app-search">
              <Input dataTest="search_by_description" name="assigned_to" className="fil_search_input" type="search" placeholder="Assigned To" />
                      <button className="app-search__button">
                      <i class="fa fa-search" aria-hidden="true"></i>
                  </button>
               </div>
          </div>
         
          <div class="col-xl-3 col-lg-6 col-sm-6 col-12 text-right">
                  <button style={{ minWidth: '90px' }} className={"btn cst_btn btn_danger mr-2  account_btn"} id="submit" type="submit">{"Go"} </button>
                  <button style={{ minWidth: '90px' }} onClick={props.resetClicked} className={"btn cst_btn btn-outline-secondary account_btn"} id="reset" type="reset">{"Reset"} </button>
          </div>
         </div> 
        </form>
        </div>
        </>
        <KpiTicketDashboard
            {...props}
            nextWeekMonthYearCliked={props.nextWeekMonthYearCliked}
            previousWeekMonthYearCliked={props.previousWeekMonthYearCliked}
            viewModeClicked={props.viewModeClicked}
            kpiInspectionData={props.kpiInspectionData}
            kpiInspectionQQvScore={props.kpiInspectionQQvScore}
            kpiInspectionRootcaseData={props.kpiInspectionRootcaseData}
            kpiInspectionDeficencyData={props.kpiInspectionDeficencyData}
            kpiInspectionDateData={props.kpiInspectionDateData}
            kpiTopInspectionData={props.kpiTopInspectionData}
            kpiInspectionAccountData={props.kpiInspectionAccountData}
            kpiInspectionPieChartData={props.kpiInspectionPieChartData}
            kpiInspectionCustomerData={props.kpiInspectionCustomerData}
            kpiInspectionQQvData={props.kpiInspectionQQvData}
            readMoreClicked={props.readMoreClicked}
            dropDownUsersList={props.dropDownUsersList}
            dropDownAccountsList={props.dropDownAccountsList}
            accountsList={props.accountsList ? props.accountsList : []}
            onSubmit={props.goClicked}
            resetClicked={props.resetClicked}
          />
      
    </div>
  );
};

const mapStateToProps = (state) => { 
  let account = state.adminOrSuperAdminReducer.searchInspectionValues && state.adminOrSuperAdminReducer.searchInspectionValues.value && state.adminOrSuperAdminReducer.searchInspectionValues.value.account ? state.adminOrSuperAdminReducer.searchInspectionValues.value.account : "";
  let category_id = state.adminOrSuperAdminReducer.kpiSearchValues && state.adminOrSuperAdminReducer.kpiSearchValues.value && state.adminOrSuperAdminReducer.kpiSearchValues.value.category_id ? state.adminOrSuperAdminReducer.kpiSearchValues.value.category_id : "";
  let created_by = state.adminOrSuperAdminReducer.kpiSearchValues && state.adminOrSuperAdminReducer.kpiSearchValues.value && state.adminOrSuperAdminReducer.kpiSearchValues.value.created_by ? state.adminOrSuperAdminReducer.kpiSearchValues.value.created_by : "";
  let assigned_to = state.adminOrSuperAdminReducer.kpiSearchValues && state.adminOrSuperAdminReducer.kpiSearchValues.value && state.adminOrSuperAdminReducer.searchInspectionValues.value.assigned_to ? state.adminOrSuperAdminReducer.searchInspectionValues.value.assigned_to : "";
 
  return { initialValues: { account, category_id, created_by, assigned_to} ,
  formStates: getFormValues('searchKpiDashboard')(state)
}
  
}
KpiTicket = reduxForm({
  form: 'searchKpiDashboard',
  validate,
  enableReinitialize: true
})(KpiTicket);

KpiTicket = connect(mapStateToProps)(KpiTicket)


export default KpiTicket;
