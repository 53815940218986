import React, { Component } from 'react';
import NewTicket from '../../../component/Ticket/NewTicket/newTicket';
import * as actions from '../../../../redux/actions/index';
import { RolesOptions, userRoles } from '../../../../utility/constants/constants'
import { routes, address_attributes, MinImageResolution } from '../../../../utility/constants/constants';
import { extractExtention, getUser } from '../../../../utility/utility';
import { connect } from 'react-redux';
import { resetMultipleOrientation } from '../../../../utility/utility';
import isEmpty from '../../../../utility/IsEmptyValidator';
import { decode, encode } from 'base64-arraybuffer';
import imageCompression from 'browser-image-compression';
import getDetailAddress from '../../../../utility/getDetailAddress';
import OpenNewTicketModal from '../../../component/Ticket/NewTicket/openNewTicketModal';
import storage from '../../../../utility/storage';
import moment from "moment";
import * as API from '../../../../API/api';
import * as commonService from "../../../../utility/utility";
import { reset } from 'redux-form';
import { tzUTCDateConverterTool_V4 } from '../../../../utility/TimezoneOperations/timezoneUtility';

const cloneDeep = require('clone-deep');

class NewTicketContainer extends Component {

    constructor(props) {
        super(props);

        this.state = {

            dueDate: this.props.match.params.ticketId ? new Date(this.props.ticket.due_on) : null,
            // portfolio properties
            faultImages: 0,
            isImagesEmpty: false,
            imageName: '',
            idsToDelete: [],
            imageUrl: null,
            base64: [],
            savedPortfolioURls: this.props.ticket.attachments_attributes ? this.props.ticket.attachments_attributes : [],
            AllFileTypes: [],
            fileType: '',
        }
        this.inputOpenFileRef = React.createRef();
        this.onPortfolioFileChange = this.onPortfolioFileChange.bind(this);
        this.removeImage = this.removeImage.bind(this);
        this.choosePhotoClicked = this.choosePhotoClicked.bind(this);
    }

    onCancelClicked = () => {
        this.props.history.goBack();
    }

    onCancelTicketModalClicked = () => {
        this.props.closeModal();
    }

    choosePhotoClicked = () => {
        this.inputOpenFileRef.current.click();
    }

    onPortfolioFileChange = (e) => {
        this.setState({
            faultImages: 0
        });
        console.log(this.state.base64);
        let files = e.target.files;
        let imageName = [], fileType = [], AllFileTypes = [], base64 = [];
        const that = this;
        // for (let i = 0; i < files.length; i++) {
        async function readFile(index) {
            if (index < files.length) {
                let reader = new FileReader();
                let file = files[index];
                imageName.push(file.name)
                fileType.push(file.type)
                AllFileTypes.push(file.type)

                if (file.type.match('image')) {

                    let fileDate = await commonService.compressImage(file, {});
                    let reader = new FileReader();
                    reader.readAsDataURL(fileDate);
                    reader.onload = function () {
                        // setImageData({ ...imageData, base64: reader.result, file: file });
                        base64.push(reader.result)
                        if (index === files.length - 1) {
                            //   
                            that.setState(prevState => ({
                                base64: [...prevState.base64, ...base64]
                            }))
                        }
                    };
                    reader.onerror = function (error) {
                        console.log("Error: ", error);
                    };
                    if (index === files.length - 1) {
                        //   
                        that.setState(prevState => ({
                            imageName: [...prevState.imageName, ...imageName],
                            fileType: [...prevState.fileType, ...fileType],
                            AllFileTypes: [...prevState.AllFileTypes, ...AllFileTypes],
                            // arrayBuffer: [...prevState.arrayBuffer, decode(reader.result)],
                            // base64: [...prevState.base64, ...base64]
                        }))
                    }
                    readFile(index + 1);

                }
                // reader.readAsArrayBuffer(file)
                // reader.readAsDataURL(file)
            }
        }

        readFile(0);
    }


    removeImage = (url, fileTypeIndex) => {
        let updatedUrls = [...this.state.base64];
        let updatedFileTypes = [...this.state.fileType]
        let updatedImageNames = [...this.state.imageName]

        let urlIndex = this.state.base64.findIndex(function (u) {
            return u === url
        })
        updatedUrls.splice(urlIndex, 1);
        updatedFileTypes.splice(fileTypeIndex, 1)
        updatedImageNames.splice(fileTypeIndex, 1)

        this.setState({
            base64: updatedUrls,
            fileType: updatedFileTypes,
            imageName: updatedImageNames
        })

    }

    removeUrls = (url) => {
        let updatedUrls = [...this.state.savedPortfolioURls];

        if (url.photo_urls) {
            this.setState(prevState => ({
                idsToDelete: [...prevState.idsToDelete, url.id]
            }))
        }

        let urlIndex = this.state.savedPortfolioURls.findIndex(function (u) {
            return u === url
        })

        updatedUrls.splice(urlIndex, 1);

        this.setState({
            savedPortfolioURls: updatedUrls,
        })

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.accountUsersList !== this.props.accountUsersList) {
            this.setState({
                userDropDownLoader: false
            })
        }

        if (prevProps.ticket !== this.props.ticket) {
            this.setState({
                dueDate: new Date(this.props.ticket.due_on)
            })
            //  
            if (this.state.idsToDelete.length === 0) {
                this.setState({
                    base64: [],
                    imageName: [],
                    arrayBuffer: [],
                    idsToDelete: [],
                    portfolioImages: [],
                    savedPortfolioURls: this.props.ticket.attachments_attributes
                })
            } else {
                this.setState({
                    // base64: this.state.base64,
                    imageName: [],
                    arrayBuffer: [],
                    idsToDelete: [],
                    portfolioImages: [],
                    savedPortfolioURls: this.props.ticket.attachments_attributes
                })
            }
        }

        if (isEmpty(prevProps.ticket) && !isEmpty(this.props.ticket)) {
            this.props.getAccountUsersInfo(this.props.ticket.account.id);
        }

        // if (prevProps.serviceProviderData.attachments !== this.props.serviceProviderData.attachments) {
        //this.props.newImages ? this.props.newImages :


        // }
    }

    componentDidMount() {
        // if (!this.props.dropDownUsersList) {
        // this.props.getAllUsers();
        // }

        // if (!this.props.categoriesList) {
            this.props.getCategories({hide: false});
        // }

        if (this.props.user.role === userRoles.INSPECTOR
            || this.props.user.role === userRoles.CLIENT
            || this.props.user.role === userRoles.REQUESTOR) {

            // if (!this.props.userAccountLists) {
            this.props.getUserAccounts(this.props.user.id);
            // }

        } else {
            if (!this.props.accountsList) {
                this.props.getModeAccounts(this.props.user.selected_mode);
            }
        }

        if (isEmpty(this.props.ticket) && this.props.match.params.ticketId) {
            this.props.getEditTicket(this.props.match.params.ticketId);
        }
        const account = storage.get("performInspectionData", null);
        if (this.props.openTicketModal && account.inspection.account_info.id) {
            this.setState({
                userDropDownLoader: true
            })
            this.props.getAccountUsersInfo(account.inspection.account_info.id);
        } else if ((this.props.ticket && this.props.ticket.account)) {
            this.props.getAccountUsersInfo(this.props.ticket.account.id);
        }
    }

    componentWillUnmount() {
        // this.props.resetObjects();
    }

    setDueDate = (date) => {

        this.setState({
            dueDate: date
        })
    }

    addNewTicket = (data) => {
        let values = cloneDeep(data);
        //here we need to convert job timezone due date to utc date 
        values.ticket.due_on =tzUTCDateConverterTool_V4(values.ticket.due_on,"to",values.ticket.account_id.timezone) //moment(values.ticket.due_on).format('MM-DD-YYYY') + ' 11:59 PM'  MM-DD-YYYY hh:mm A;
        values.ticket['private_ticket'] = values.ticket.private
        // values.ticket['platform'] = 'web'
        if (this.props.match.params.ticketId) {
            // if (this.state.dueDate) {
            //     values.ticket['due_on'] = this.state.dueDate;
            // }
            values.ticket.account_id = values.ticket.account_id.value;
            values.ticket.user_id = values.ticket.user_id.value;
            delete values.ticket.mode;
            delete values.ticket.ticket_created_for_inspection;
            delete values.ticket.ios_updated_at;
            delete values.ticket.ios_created_at;
            delete values.ticket.app_created_at;
            delete values.ticket.app_updated_at;
            if (this.state.base64.length > 0) {
                const arrayBuffer = [];
                this.state.base64.map((url, i) => {
                    if (!url.photo_urls) {
                        let base_photo = null;
                        const image = url.split(',');
                        base_photo = image[1];

                        arrayBuffer.push(decode(base_photo))
                    }

                })
                const extentions = extractExtention(this.state.imageName);
                values.isScheduleTicketEdit = this.props.history && this.props.location && this.props.location.state && this.props.location.state.isScheduleTicketEdit ? this.props.location.state.isScheduleTicketEdit : false;
                this.props.getTicketPresignedUrl(values, extentions, arrayBuffer, this.state.idsToDelete);
            } else {
                values.isScheduleTicketEdit = this.props.history && this.props.location && this.props.location.state && this.props.location.state.isScheduleTicketEdit ? this.props.location.state.isScheduleTicketEdit : false;
                this.props.updateTicket(values, this.state.idsToDelete);
            }

        } else {
            // values.ticket['due_on'] = this.state.dueDate;
            values.ticket['start_time'] = tzUTCDateConverterTool_V4(values.ticket.due_on,"from",values.ticket.account_id.timezone)
            values.ticket.account_id = values.ticket.account_id.value;
            values.ticket.user_id = values.ticket.user_id ? values.ticket.user_id.value : '';
            
            const usermode = getUser()
            values.ticket['mode'] = usermode.mode;
            if (this.props.openTicketModal) {
                values.ticket['inspection_id'] = this.props.inspection.id;
                values.ticket['line_item_id'] = this.props.lineItemId;
                // values.ticket['section_id'] = this.props.sectionId;
            }

            const arrayBuffer = [];
            this.state.base64.map((url, i) => {
                if (!url.photo_urls) {
                    let base_photo = null;
                    // if (url.includes(ImageTypes.JPEG)) {
                    const image = url.split(',');
                    base_photo = image[1];
                    // } else if (url.includes(ImageTypes.PNG)) {
                    // base_photo = url.replace("data:image/png;base64,", '');
                    // }

                    arrayBuffer.push(decode(base_photo))
                    // arrayBuffer.push(base_photo)
                }

            })
            debugger
            if(this.props.user.role === userRoles.CLIENT){
                delete values.ticket.user_id
            }
            const extentions = extractExtention(this.state.imageName);
            this.props.addNewTicket(values, arrayBuffer, extentions, this.props.openTicketModal)
        }
    }

    getAccountUsers = (account) => {
        this.setState({
            userDropDownLoader: true
        })
        this.props.getAccountUsersInfo(account.value);
        // }
    }

    render() {

        return (
            this.props.openTicketModal ?
                <OpenNewTicketModal
                    user={this.props.user}
                    categoriesList={this.props.categoriesList}
                    dropDownUsersList={this.props.dropDownUsersList}
                    accountsList={this.props.accountsList}
                    dueDate={this.state.dueDate}
                    setDueDate={this.setDueDate}
                    onSubmit={this.addNewTicket}
                    getAccountUsers={this.getAccountUsers}
                    userDropDownLoader={this.state.userDropDownLoader}
                    isEdit={this.props.match.params.ticketId ? true : false}
                    reference={this.inputOpenFileRef}
                    choosePhotoClicked={this.choosePhotoClicked}
                    portfolioImages={this.state.base64}
                    idsToDelete={this.state.idsToDelete}
                    removeUrls={this.removeUrls}
                    accountUsersList={this.props.accountUsersList ? this.props.accountUsersList : []}
                    userAccountLists={this.props.userAccountLists ? this.props.userAccountLists : []}
                    // onFileChange={this.onFileChange}
                    // deleteInspectionFormSection={this.props.deleteInspectionFormSection}
                    // deleteInspectionFormLineItem={this.props.deleteInspectionFormLineItem}
                    onCancelClicked={this.onCancelClicked}
                    isLoading={this.props.isLoading}
                    onPortfolioFileChange={this.onPortfolioFileChange}
                    savedPortfolioURls={this.state.savedPortfolioURls}
                    removeImage={this.removeImage}
                    closeModal={this.props.closeModal}
                />
                :
                <NewTicket
                    user={this.props.user}
                    categoriesList={this.props.categoriesList}
                    dropDownUsersList={this.props.dropDownUsersList}
                    accountsList={this.props.accountsList}
                    dueDate={this.state.dueDate}
                    setDueDate={this.setDueDate}
                    onSubmit={this.addNewTicket}
                    ticket={this.props.ticket}
                    getAccountUsers={this.getAccountUsers}
                    userDropDownLoader={this.state.userDropDownLoader}
                    isEdit={this.props.match.params.ticketId ? true : false}
                    reference={this.inputOpenFileRef}
                    choosePhotoClicked={this.choosePhotoClicked}
                    portfolioImages={this.state.base64}
                    idsToDelete={this.state.idsToDelete}
                    removeUrls={this.removeUrls}
                    accountUsersList={this.props.accountUsersList ? this.props.accountUsersList : []}
                    userAccountLists={this.props.userAccountLists ? this.props.userAccountLists : []}
                    // onFileChange={this.onFileChange}
                    // deleteInspectionFormSection={this.props.deleteInspectionFormSection}
                    // deleteInspectionFormLineItem={this.props.deleteInspectionFormLineItem}
                    onCancelClicked={this.onCancelClicked}
                    isLoading={this.props.isLoading}
                    onPortfolioFileChange={this.onPortfolioFileChange}
                    savedPortfolioURls={this.state.savedPortfolioURls}
                    removeImage={this.removeImage}
                // savePortfolioImages={this.savePortfolioImages}
                />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.authReducer.user,
        categoriesList: state.adminOrSuperAdminReducer.categoriesList,
        isLoading: state.adminOrSuperAdminReducer.isLoading,
        dropDownUsersList: state.adminOrSuperAdminReducer.dropDownUsersList,
        userAccountLists: state.adminOrSuperAdminReducer.userAccountLists,
        ticket: state.adminOrSuperAdminReducer.ticket,
        // ratingList: state.adminOrSuperAdminReducer.ratingList,
        accountUsersList: state.adminOrSuperAdminReducer.accountUsersList ? state.adminOrSuperAdminReducer.accountUsersList : [],
        accountsList: state.adminOrSuperAdminReducer.modeAccountsList,
        inspectionForm: state.adminOrSuperAdminReducer.inspectionForm,
        openTicketModal: state.adminOrSuperAdminReducer.openTicketModal,
        inspection: state.adminOrSuperAdminReducer.inspection,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        addNewTicket: (ticket, arrayBuffer, extentions, openTicketModal) => dispatch(actions.addNewTicket(ticket, arrayBuffer, extentions, openTicketModal)),
        updateInspectionForm: (inspectionForm) => dispatch(actions.updateInspectionForm(inspectionForm)),
        resetObjects: () => dispatch(actions.resetObjects()),
        reset: (newTicketForm) => dispatch(reset(newTicketForm)),  // requires form name
        getUserAccounts: (id) => dispatch(actions.getUserAccounts(id)),
        getAccountUsersInfo: (id) => dispatch(actions.getAccountUsersInfo(id)),
        // getAllUsers: () => dispatch(actions.getAllUsers()),
        getCategories: (data) => dispatch(actions.getCategories(data)),
        getModeAccounts: (mode) => dispatch(actions.getModeAccounts(mode)),
        getEditTicket: (id) => dispatch(actions.getEditTicket(id)),
        updateTicket: (ticket, idsToDelete) => dispatch(actions.updateTicket(ticket, idsToDelete)),
        getTicketPresignedUrl: (ticket, extentions, arrayBuffer, idsToDelete) => dispatch(actions.getTicketPresignedUrl(ticket, extentions, arrayBuffer, idsToDelete)),
        closeModal: () => dispatch(actions.closePopup())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewTicketContainer);