import React, { Component } from 'react';
import queryString from "query-string";
import { connect } from 'react-redux';
import * as actions from '../../../redux/actions/index';
import Thankyou from '../../component/Thankyou/thankyou';

class ThankyouContiner extends Component {

    componentDidMount() {
        const params = queryString.parse(this.props.location.search);
         ;
        const status = {
            status: 'confirmed',
            user_id: params.user_id
        }

        this.props.updateQuoteFormClient(params.accessToken, params.quote_id, status);
    }

    render() {
        // console.log(this)

        return (
            <Thankyou {...this.props} />
        )
    }
}

export const mapStateToProps = (state) => {
    return {
        isLoading: state.adminOrSuperAdminReducer.isLoading,
        quoteApproved: state.adminOrSuperAdminReducer.quoteApproved,
        quoteErrorMessage: state.adminOrSuperAdminReducer.quoteErrorMessage
    }
}

export const mapDispatchToProps = (dispatch) => {
    return {
        updateQuoteFormClient: (accessToken, quote_id, status) => dispatch(actions.updateQuoteFormClient(accessToken, quote_id, status))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ThankyouContiner);