import React, { Component } from 'react';
import * as actions from '../../../../redux/actions/index';
import { connect } from 'react-redux';
import { reset } from 'redux-form';
import { animateScroll as scroll } from 'react-scroll'
import storage from '../../../../utility/storage';
import { convertDateToDifferentTZ } from '../../../../utility/utility';
import errorHandler from '../../../../utility/errorHandler/errorHandler';
import SwapList from '../../../component/TimeTrackerModuleComponent/SwapListComponent/SwapList';

const cloneDeep = require('clone-deep');

class SwapListContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentDidMount() {
        scroll.scrollToTop();
    };

    componentDidUpdate(prevProps, prevState) {
        const user = storage.get('user');
        if (user) {
            if (prevProps.user.selected_mode !== user.selected_mode) {
                this.props.swapListPageClicked(0)
            }
        }
    };

    searchSwapRequestListClicked = (value) => {

        let page = 1
        let limit = this.props.swapList_no_of_rows
        let status = null;

        const values = cloneDeep(value)

        if (values.shiftSwapRequestStatus) {
            values.shiftSwapRequestStatus = values.shiftSwapRequestStatus && values.shiftSwapRequestStatus.value && values.shiftSwapRequestStatus.value.trim();
            status = values.shiftSwapRequestStatus
        }

        this.props.getSwapList({ page, limit, status }, values);

    };

    resetClicked = () => {
        const page = 1
        const limit = 10
        let status = 'requested';
        this.props.reset('searchSwapListForm');
        this.props.swapListPageClicked(0)
        this.props.getSwapList({ page, limit, status })
    };

    handleShiftSwapPreviewClick = (shiftSwap) => {

        if (shiftSwap && shiftSwap.swapStatus == 'approved') {

            this.props.history.push({
                pathname: `/shift-swap/swapped/aspects/${shiftSwap.swapId}`,
                // state: {storedRoute: null}
            });

        } else {

            this.props.history.push({
                pathname: `/shift-swap/swap-request/details/${shiftSwap.swapId}`,
                // state: {storedRoute: null}
            });

        }

    };

    render() {

        return (
            <>
                <SwapList
                    {...this.props}
                    isLoading={this.props.isLoading}
                    onSubmit={this.searchSwapRequestListClicked}
                    resetClicked={this.resetClicked}
                    swapList={this.props.swapList ? this.props.swapList : []}
                    handleShiftSwapPreviewClick={this.handleShiftSwapPreviewClick}
                />
            </>
        )
    }

}


const mapStateToProps = (state) => {
    return {
        user: state.authReducer.user,
        isLoading: state.TimeTrackerModReducer.isLoading,
        isSwapList: state.TimeTrackerModReducer.isSwapList,
        swapList: state.TimeTrackerModReducer.swapList,
        swapList_no_of_rows: state.TimeTrackerModReducer.swapList_no_of_rows,
        swapListCurPage: state.TimeTrackerModReducer.swapListCurPage,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        reset: (searchInventoryItemsList) => dispatch(reset(searchInventoryItemsList)),  // requires form name
        swapListPageClicked: (page) => dispatch(actions.swapListPageClicked(page)),
        getSwapList: (params, values) => dispatch(actions.getSwapList(params, values)),
        resetObjects: () => dispatch(actions.resetObjects()),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(SwapListContainer);