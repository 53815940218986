import React, { useEffect, useState } from "react";
import "../../../styles/custom.css";
import { routes } from "../../../utility/constants/constants";
import { Button } from 'antd';

let LogBookQrButton = (props) => {

  const openLogbookCamera = () => {
    props.history.push(routes.SCAN_LOGBOOK_QR);
  }

  useEffect(() => {

    if (props?.user?.exemption_type == 'exempt') {
      if ((props.isClockInShiftAvailable || props.isClockInShiftExcuse)) {
        if (props?.clockInCheckedInTime == undefined) {
          props.handleFrequentTimeTracker_openDeleteModal();
        } else if (!(props?.clockInCheckedInTime?.length > 0)) {
          props.handleFrequentTimeTracker_openDeleteModal();
        }
      }
    }

  }, [props.isClockInShiftAvailable || props.isClockInShiftExcuse])

  return (
    <div>
      <main className="app-content wraper_content inspection_wraper">
        <div className="container" style={{ marginTop: "100px" }}>

          <div className="row">
            <div className="col-md-12 FLH_col">
              <div className="FLH_button_container">
                <button
                  data-test="filter_go_btn"
                  onClick={openLogbookCamera}
                  className={"btn btn_danger FLH_button"}
                  id="submit"
                  type="submit"
                >
                  {" "}
                  Scan to Review Logbook{" "}
                </button>
              </div>
            </div>
          </div>

          {props.user.exemption_type == 'non-exempt' ? (
            <>
              {(props.isClockInShiftAvailable || props.isClockInShiftExcuse) || props.clockInCheckedInTime ? (
                <div className="row">
                  <div className="col-md-12 FLH_col">
                    <div className="text-align-center mt-3 max_width_100">
                    <button className={"btn btn_danger FLH_button"} type="button"  onClick={() => { props.openTimerScreen(); }} block> Proceed to Clock In </button>
                    </div>
                  </div>
                </div>
              ) : null}
            </>
          ) : null}

        </div>
      </main>
    </div>
  );
};

export default LogBookQrButton;
