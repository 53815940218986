import React from 'react';
import { reduxForm } from 'redux-form';
import { Input } from '../../../UI/InputElement/InputElement';
import { newBoxValidator as validate } from '../../../../../utility/validator/validator';
import isEmpty from '../../../../../utility/IsEmptyValidator';
import { connect } from 'react-redux';
import * as Scroll from 'react-scroll';
import BoxSkeleton from './boxSkeleton';

const cloneDeep = require('clone-deep');


var scroller = Scroll.scroller;

let NewBox = (props) => {
    const { handleSubmit, pristine, reset, submitting, error } = props;

        return (
      props.isUpdateBox ?
      <BoxSkeleton />
      :
            <main className="app-content  wraper_content inspection_wraper user_newQuote">
                <div className="inspection_content_sec appcontent_Inner">
                    <div className="container-fluid">
                        <div className="quote_form ticket_form" style={{maxWidth : '500px'}}>
                            <h2 className="md_title mt-3" data-test="create_ticket_header">{props.isEdit ? 'Update Box' : 'Add Box'}</h2>
                            <div className="addQuote_form">
                                <form onSubmit={handleSubmit}>
                                   
                                    <div className="form-group">
                                        <label className="form_title"><strong>Box Name </strong></label>
                                        <Input dataTest="ticket_subject" name="box[name]" type="text" className="form-control input-modifier" placeholder="Type here" />
                                    </div>
                                    {
                                        props.isEdit ? <div className="form-group">
                                        <label className="form_title"><strong>Box Number</strong> </label>
                                        <input dataTest="ticket_subject" name="box[number]" type="text" className="form-control input-modifier" disabled={true} placeholder={props.box && props.box.number ? props.box.number : ''} />
                                    </div> : <div className="form-group">
                                        <label className="form_title"><strong>Box Number</strong> </label>
                                        <Input dataTest="ticket_subject" name="box[number]" type="text" className="form-control input-modifier" placeholder="Type here" />
                                    </div> 

                                    }
                                    
                                    <div className="form-group btn_block mb-0" style={{maxWidth : '700px'}}>
                                        <button data-test="submit_button" type="submit" className={props.isLoading ? "btn cst_btn btn_danger btn-wait" : "btn_box cst_btn btn_danger"} >{props.isLoading ? "" : props.isEdit ?  "Update QR Code" : "Generate QR Code"}</button>
                                        <button data-test="cancel_button" type="button" onClick={() => props.onCancelClicked()} className="btn cst_btn btn-outline-secondary  cancel_btnnew">Cancel</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        )

    }


const mapStateToProps = (state) => {
    let box = {};
    if (!isEmpty(state.InventoryReducer.box)) {
       
        const values = cloneDeep(state.InventoryReducer.box);
        box['name'] = values.name;
        box['number'] = values.number;

    }
    return { initialValues: { box } }

}

NewBox = reduxForm({
    form: 'boxForm',
    validate,
    enableReinitialize: true,
    onSubmitFail: (errors) => scrollToFirstError(errors),
})(NewBox);

NewBox = connect(mapStateToProps)(NewBox)

export const scrollToFirstError = (errors) => {

    let scroolToError = null;
    if (errors) {
        if (errors.box.name) {
            scroolToError = `box[name]`;
        } else if (errors.box.number) {
            scroolToError = `box[number]`;
        }
    }
    //  
    scroller.scrollTo(`${scroolToError}`, {
        duration: 500,
        delay: 1,
        isDynamic: true,
        smooth: true,
        offset: -100,
    })

}

export default NewBox;