import React, {useState, useEffect} from "react";
import GoogleMapReact from 'google-map-react';
import moment from "moment";
const AnyReactComponent = ({ text }) => (
    <div className="cst_tooltip">
            <span className="has-tooltip">
                <i className="fa fa-map-marker" style={{ fontSize: '40px', color: '#9b2c59' }}></i>
            </span>
            <span className="tooltip gray" style={{ background: '#000000a3' }}>
                <p>{text}</p>
            </span>
    </div>
  // <div style={{
  //     color: 'white',
  //     background: 'grey',
  //     padding: '15px 10px',
  //     display: 'inline-flex',
  //     textAlign: 'center',
  //     alignItems: 'center',
  //     justifyContent: 'center',
  //     borderRadius: '100%',
  //     transform: 'translate(-10%, -10%)'
  // }}>
  //     "my Lcation"
  //     {/* <i className="fa fa-map-marker" style="font-size:48px;color:red"> my location</i> */}
  // </div>
);

const Unavailable = ({ currentSchedule}) => {

  return (
      <div className="modal fade setUnavailable_modal" id="setUnavailable" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle">
        {currentSchedule && currentSchedule.address && <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h6 className="modal-title h6_title" id="exampleModalLongTitle">{currentSchedule.area.name}</h6>
              <h6 className="h6_title"><span className="text_light">At</span> {moment(moment().format("MM-DD-YYYY")+" "+currentSchedule.start_time, "MM-DD-YYYY HH:mm:ss").format("hh:mm A")}</h6>
            </div>
            <div className="modal-body">
              <div className="">
                <label className="label_modifier">Area</label>
                <div className="map_part">
                  <figure className="map_inner area-map-schedule" style={{position: "sticky", width: "100%"}}>

                    <GoogleMapReact
                        bootstrapURLKeys='AIzaSyAUImPEdPk-6idATsr0RVAeDsZpTR5gWvU'
                        defaultCenter={{ lat: currentSchedule.address.latitude , lng: currentSchedule.address.longitude }}
                        defaultZoom={15}
                        style={{}}
                        >
                      <AnyReactComponent
                          lat={currentSchedule.address.latitude}
                          lng={currentSchedule.address.longitude}
                          text={currentSchedule.address.formatted_address}
                          />
                    </GoogleMapReact>
                    {/* <img src="/images/background/map.jpg" className="map_image"
                     alt="location" />*/}
                  </figure>
                </div>
              </div>
              <div className="mb-1">
                <label className="label_modifier">Comments</label>
                <textarea className="form-control textarea_modifier" style={{pointerEvents: "none"}} id="exampleFormControlTextarea1" rows="5" placeholder="Type here" value={currentSchedule.reason}></textarea>
              </div>
              <div>
                <label className="label_modifier">Submitted By</label>
                <textarea className="form-control textarea_modifier" style={{pointerEvents: "none"}} id="exampleFormControlTextarea1" rows="1" placeholder="Type here" value={`${currentSchedule.user.first_name} ${currentSchedule.user.last_name} at ${moment(currentSchedule.captured_time, "MM-DD-YYYY HH:mm A").subtract(7, "hours").format("hh:mm A")}`}></textarea>
              </div>

            </div>
          </div>
        </div>}
      </div>
  )
};

export default Unavailable;