import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../redux/actions/index';
import ReportsCsv from './ReportsCsv';
import ScanAnalytics from './ScanAnalytics';
import { getFormValues } from 'redux-form';
import { TimeZones } from '../../../utility/constants/constants';
import { convertDateToDifferentTZ, sortQuotesCount } from '../../../utility/utility';
import HighCharts from '../highcharts/highCharts';


const ReportsGraphs = (props) => {

    return (
        <div className="tab-content" id="pills-tabContent">
            <div className="tab-pane fade show active" id="pills-graph-one" role="tabpanel" aria-labelledby="pills-graph-one">
                {/* {this.state.activeTab === GrapBars.COLUMN ? */}
                <>
                    <div className="row">
                        <div className="col-xl-6 chart_cont_box">
                            <div className="tile chart-box-botL">
                                {/* <h6 className="chart_title">Total Quote Created</h6> */}

                                <figure className="highcharts-figure">
                                    <div id="TotalQuotesCreated"></div>
                                    <p className="highcharts-description">
                                    </p>
                                </figure>
                                {/* <div className="chart-img"><img src="/images/thumbnails/graph_image.png" alt="Total Quote Created" /></div> */}
                            </div>
                        </div>
                        <div className="col-xl-6 chart_cont_box">
                            <div className="tile chart-box-botL">
                                {/* <h6 className="chart_title">Total Quote Amount (in $)</h6> */}
                                {/* <div className="chart-img"> */}
                                <figure className="highcharts-figure">
                                    <div id="TotalQuotesSales"></div>
                                    <p className="highcharts-description">
                                    </p>
                                </figure>
                                {/* <img src="/images/thumbnails/graph_image.png" alt="Total Quote Created" /> */}
                                {/* </div> */}
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-xl-6 chart_cont_box">
                            <div className="tile chart-box-botL">
                                {/* <h6 className="chart_title">Total Quote Created</h6> */}

                                <figure className="highcharts-figure">
                                    <div id="TotalQuotesPendingForAdminApproval"></div>
                                    <p className="highcharts-description">
                                    </p>
                                </figure>
                                {/* <div className="chart-img"><img src="/images/thumbnails/graph_image.png" alt="Total Quote Created" /></div> */}
                            </div>
                        </div>
                        <div className="col-xl-6 chart_cont_box">
                            <div className="tile chart-box-botL">
                                {/* <h6 className="chart_title">Total Quote Amount (in $)</h6> */}
                                {/* <div className="chart-img"> */}
                                <figure className="highcharts-figure">
                                    <div id="TotalQuotesApprovedByAdmin"></div>
                                    <p className="highcharts-description">
                                    </p>
                                </figure>
                                {/* <img src="/images/thumbnails/graph_image.png" alt="Total Quote Created" /> */}
                                {/* </div> */}
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-xl-6 chart_cont_box">
                            <div className="tile chart-box-botL">
                                {/* <h6 className="chart_title">Total Quote Created</h6> */}

                                <figure className="highcharts-figure">
                                    <div id="TotalQuotesRejectedByAdmin"></div>
                                    <p className="highcharts-description">
                                    </p>
                                </figure>
                                {/* <div className="chart-img"><img src="/images/thumbnails/graph_image.png" alt="Total Quote Created" /></div> */}
                            </div>
                        </div>
                        <div className="col-xl-6 chart_cont_box">
                            <div className="tile chart-box-botL">
                                {/* <h6 className="chart_title">Total Quote Amount (in $)</h6> */}
                                {/* <div className="chart-img"> */}
                                <figure className="highcharts-figure">
                                    <div id="TotalQuotesConfirmedByClient"></div>
                                    <p className="highcharts-description">
                                    </p>
                                </figure>
                                {/* <img src="/images/thumbnails/graph_image.png" alt="Total Quote Created" /> */}
                                {/* </div> */}
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-xl-6 chart_cont_box">
                            <div className="tile chart-box-botL">
                                {/* <h6 className="chart_title">Total Quote Created</h6> */}

                                <figure className="highcharts-figure">
                                    <div id="TotalApprovedSales"></div>
                                    <p className="highcharts-description">
                                    </p>
                                </figure>
                                {/* <div className="chart-img"><img src="/images/thumbnails/graph_image.png" alt="Total Quote Created" /></div> */}
                            </div>
                        </div>
                        <div className="col-xl-6 chart_cont_box">
                            <div className="tile chart-box-botL">
                                {/* <h6 className="chart_title">Total Quote Amount (in $)</h6> */}
                                {/* <div className="chart-img"> */}
                                <figure className="highcharts-figure">
                                    <div id="TotalQuotesWaitingForClientApproval"></div>
                                    <p className="highcharts-description">
                                    </p>
                                </figure>
                                {/* <img src="/images/thumbnails/graph_image.png" alt="Total Quote Created" /> */}
                                {/* </div> */}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-6 chart_cont_box">
                            <div className="tile chart-box-botL">
                                {/* <h6 className="chart_title">Total Quote Created</h6> */}

                                <figure className="highcharts-figure">
                                    <div id="TotalSalesWaitingForApproval"></div>
                                    <p className="highcharts-description">
                                    </p>
                                </figure>
                                {/* <div className="chart-img"><img src="/images/thumbnails/graph_image.png" alt="Total Quote Created" /></div> */}
                            </div>
                        </div>
                    </div>
                </>
                {/* : null} */}
            </div>
            {/* <div className="tab-pane fade" id="pills-graph-two" role="tabpanel" aria-labelledby="pills-graph-two">
                                                {this.state.activeTab === GrapBars.BAR ?
                                                    <div className="row">
                                                        <div className="col-xl-6 chart_cont_box">
                                                            <div className="tile chart-box-botL">
                                                               

                                                                <figure className="highcharts-figure">
                                                                    <div id="quotesCreatedBar1"></div>
                                                                    <p className="highcharts-description">
                                                                    </p>
                                                                </figure>
                                                            </div>
                                                        </div>
                                                        <div className="col-xl-6 chart_cont_box">
                                                            <div className="tile chart-box-botL">
                                                                <figure className="highcharts-figure">
                                                                    <div id="quotesCreatedBar2"></div>
                                                                    <p className="highcharts-description">
                                                                    </p>
                                                                </figure>
                                                            </div>
                                                        </div>
                                                    </div> : null}
                                            </div>
                                            <div className="tab-pane fade" id="pills-pie-chart" role="tabpanel" aria-labelledby="pills-pie-chart">
                                                {this.state.activeTab === GrapBars.PIE ?
                                                    <div className="row">
                                                        <div className="col-xl-6 chart_cont_box">
                                                            <div className="tile chart-box-botL">
                                                                <figure className="highcharts-figure">
                                                                    <div id="quotesCreatedBar3"></div>
                                                                    <p className="highcharts-description">
                                                                    </p>
                                                                </figure>
                                                            </div>
                                                        </div>
                                                    </div> : null}
                                            </div> */}
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        an_account_with_zero_tags_sold: state.adminOrSuperAdminReducer.an_account_with_zero_tags_sold,
        quotes_created_vs_quotes_approved: state.adminOrSuperAdminReducer.quotes_created_vs_quotes_approved,
        quotes_tag_created: state.adminOrSuperAdminReducer.quotes_tag_created,
        quotes_vs_approved_not_approved_by_the_client: state.adminOrSuperAdminReducer.quotes_vs_approved_not_approved_by_the_client,
        quotes_vs_quotes_rejected_by_admin: state.adminOrSuperAdminReducer.quotes_vs_quotes_rejected_by_admin,
        tags_confirmed_by_client: state.adminOrSuperAdminReducer.tags_confirmed_by_client
    }
}

export default connect(mapStateToProps)(ReportsGraphs);