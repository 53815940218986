import React, { Component } from 'react';
import UpdateProfile from '../../component/updateProfile/updateProfile';
import * as actions from '../../../redux/actions/index';
import { connect } from 'react-redux';

class UpdateProfileContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isProfileActive: true,
            isChangePasswordActive: false,
            isInventoryAccessActive: false,
        }
    }
    componentWillUnmount() {

    }

    componentWillMount() {

    }

    closeProfileModel = () => {
        this.props.closeProfileModel();
    }

    profileClicked = () => {
        this.setState({
            isProfileActive: true,
            isChangePasswordActive: false,
            isInventoryAccessActive: false,
        })
    }

    changePasswordClicked = () => {
        this.setState({
            isProfileActive: false,
            isChangePasswordActive: true,
            isInventoryAccessActive: false,
        })
    }

    inventoryAccessClicked = () => {
        this.setState({
            isProfileActive: false,
            isChangePasswordActive: false,
            isInventoryAccessActive: true,
        })
    }

    render() {

        return (
            <UpdateProfile
                profileClicked={this.profileClicked}
                isProfileActive={this.state.isProfileActive}
                changePasswordClicked={this.changePasswordClicked}
                isChangePasswordActive={this.state.isChangePasswordActive}
                inventoryAccessClicked={this.inventoryAccessClicked}
                isInventoryAccessActive={this.state.isInventoryAccessActive}
                closeProfileModel={this.props.closeProfileModel}
                positionsList={this.props.positionsList}
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.authReducer.user,
        // token: state.authReducer.token,
        profileModel: state.authReducer.profileModel
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        closeProfileModel: () => dispatch(actions.closeProfileModel()),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(UpdateProfileContainer);