import React, { useState } from 'react';
import { MagicSpinner, PushSpinner, SwishSpinner, WaveSpinner, ClapSpinner } from 'react-spinners-kit';
import moment from "moment";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import ReactTable from 'react-table-v6';
import '../../../../../styles/custom.css';
import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle
} from '@material-ui/core';
import { makeFirstLetterCapital, CustomNoDataComponent, convertDateToDifferentTZSansUTC, convertAreaScheduleTime, convertUTCtoTimezone } from '../../../../../utility/utility';


import { useSelector, useDispatch } from 'react-redux';
import { FaArrowDown, FaArrowUp } from "react-icons/fa";
import * as actions from '../../../../../redux/actions/index';
import { userRoles } from '../../../../../utility/constants/constants';
import ImageLoader from 'react-imageloader';
import { DAYS } from "../../../../../utility/constants/constants";


import 'react-table-v6/react-table.css';
import '../../../../../styles/custom.css';
import storage from '../../../../../utility/storage';
const momentTZ = require('moment-timezone');

const LogBookAreaTable = (props) => {
  console.log('propsss', props);
  const [openModel, setOpen] = useState(false);
  const [openQrModel, setQROpen] = useState(false);
  const [openScheduleModel, setScheduleOpen] = useState(false);
  const [currentQrArea, setCurrentQrArea] = useState(false);
  const [currentArea, setCurrentArea] = useState(null);
  const dispatch = useDispatch();
  const SearchValue = useSelector(state => state.form.searchAccountUsersForm?.values);
  const DateGenerate = useSelector(state => state.adminOrSuperAdminReducer.dataGenerated);
  const SearchOrResetAccountUsersClicked = useSelector(state => state.adminOrSuperAdminReducer.SearchOrResetAccountUsersClicked);
  const No_of_pages = useSelector(state => state.adminOrSuperAdminReducer.total_no_of_pages);
  const No_of_rows = useSelector(state => state.adminOrSuperAdminReducer.logbook_rows);
  const logbookAreaCurPage = useSelector(state => state.adminOrSuperAdminReducer.logbookAreaCurPage);

  const printQr = () => {
    const input = document.getElementsByClassName("qr-body-single")[0];
    html2canvas(input, { useCORS: true, allowTaint: true })
      .then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF();
        pdf.addImage(imgData, 'PNG', 10, 10);
        //pdf.output('dataurlnewwindow');
        pdf.save("download.pdf");
      })
  };

  function preloader() {
    return <img style={{ height: '35px', width: '35px', borderRadius: '50px', float: 'left' }} src="/images/gif/giphy.gif" />;
  }
  // const deleteAccountArea = (logbook) =>  {
  //   props.deleteArea(props.match.params.accountId,logbook.id);
  // }
  const data = props.logbookAreas.map((logbook, i) => {

    return {
      checkbox: <div className="checkbox_block radius_checkbox">
        <div className="custom-control custom-checkbox custom-control-inline">
          <input onChange={() => props.checkboxClicked(logbook.id)} checked={props.setCheckValue(logbook.id)} type="checkbox" className="custom-control-input" id={`customCheck${logbook.id}`} />
          <label className="custom-control-label" for={`customCheck${logbook.id}`}><span className="check_label">Quotes</span></label>
        </div>
      </div>,
      logbookId: logbook.log_number,
      name: makeFirstLetterCapital(logbook.name),
      floor_no: logbook.floor ? makeFirstLetterCapital(logbook.floor.name) : '',
      area_type: logbook.area_type? makeFirstLetterCapital(logbook.area_type.name) : '',
      status: logbook.turn_off ? "InActive" : "Active",
      actions:
        <>
          {/* <span className="mr_20 account_edit_icn" onClick={() => props.editUserClicked(user)}><i class="fa fa-pencil" aria-hidden="true"></i>
           </span> */}
          <button className="btn mr_20 account_edit_icn" onClick={() => [setQROpen(true), setCurrentQrArea(logbook)]}>Show QR
                    </button>
          <button className="btn mr_20 account_edit_icn" onClick={() => [ setScheduleOpen(true), setCurrentArea(logbook)]}>View Schedule
          </button>
          <span className="mr_20 account_edit_icn" onClick={() => props.history.push(`/accounts/${props.account.id}/account_users/logbookarea/${logbook.id}/edit`)}><i class="fa fa-pencil" aria-hidden="true"></i></span>
          {props.user && props.user.role === "superadmin" ? <span className="mr account_edit_icn" onClick={() => [setOpen(true), setCurrentArea(logbook)]}><i class="fa fa-trash" aria-hidden="true" data-test="area_trash_icon"></i>
          </span> : ""}
          <span className="mr_20 account_edit_icn" onClick={() => {
            storage.set('accountUsersRedirect', props.history.location.pathname + props.history.location.search)
            props.history.push(`/accounts/${props.account.id}/account_users/view_logbookarea/${logbook.log_number}`)}}><i class="fas fa-eye" aria-hidden="true"></i></span>
        </>
    }
  })


  const columns = [
    {
      Header: <><span> <div className="checkbox_block radius_checkbox" style={{marginBottom: "0px"}}>
      <div className="custom-control custom-checkbox custom-control-inline">
        <input onChange={() => props.allLogbookAreaCheckboxClicked()} type="checkbox" className="custom-control-input" id={`customCheck${1}`} />
        <label className="custom-control-label" for={`customCheck${1}`}><span className="check_label">Quotes</span></label>
      </div>
    </div> </span></>,
      accessor: 'checkbox', // String-based value accessors!,
      width: 50,
      show: props.logbookAreas.length !== 0,
    },
    {
      Header: <><span>Logbook ID</span><FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
      accessor: 'logbookId', // String-based value accessors!,
      show: props.logbookAreas.length !== 0,
    }, {
      Header: <><span>Logbook Name</span><FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
      accessor: 'name', // String-based value accessors!,
      show: props.logbookAreas.length !== 0,
    }, {
      Header: <><span>Floor #</span><FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
      accessor: 'floor_no', // String-based value accessors!,
      show: props.logbookAreas.length !== 0,
    },
    {
      Header: <><span>Area Type</span><FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
      accessor: 'area_type',
      show: props.logbookAreas.length !== 0,
     width: 150
    },
    {
      Header: <><span>Status</span><FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
      accessor: 'status',
      show: props.logbookAreas.length !== 0,
     width: 150
    },
    {
      Header: ' ',
      accessor: 'actions',
      width: 450
      // show: false,
    }
  ]

  const [tablePage, setTablePage] = useState(0)
  const tablePageNew =  React.useRef()
  
  if(logbookAreaCurPage !== tablePageNew.current) {
      tablePageNew.current = logbookAreaCurPage;
  }

  const setTablePageFn = (page) => {
      setTablePage(page)
      props.logbookAreaPageClicked(page)
      tablePageNew.current = page;
  }

  const fetchData = (state, instance) => {  
    if (SearchOrResetAccountUsersClicked) {
      dispatch(actions.resetSearchOrResetValue())
    } else {

      if (!DateGenerate) {
        state.page = 2;
        let page = tablePageNew.current >= 0 ? tablePageNew.current + 1 : logbookAreaCurPage + 1;
        const size = state.pageSize;
        if(No_of_rows != size) {
            page = 1;
            setTablePageFn(0)
        }
        const sorted = JSON.stringify(state.sorted);
        if(!sorted.includes('checkbox')) {
            props.getAllLogbookArea(SearchValue, size, page, sorted,
              props.match.params.accountId);
        }
      }
    }
    // }
  }
  const getFormatedTime = (time) => {
    const date = moment().format("MM/DD/YYYY") + " " + time.start_time;
    const st_time = moment.utc(moment(date)).format("hh:mm A");
    return (moment().format("MM-DD-YYYY") + " " + st_time);
  }
  
  const handleConfirm = (confirm) => {
    setOpen(false);
    props.deleteLogbookArea(props.match.params.accountId, currentArea.id)
  };

  const getFormattedZone = (timezone) => {
    let zone = ''
    const now = momentTZ.tz(timezone);
    zone = now.format('z');
    return '(' + zone + ')';
  }

  return (
    SearchOrResetAccountUsersClicked && props.isLogbookAreas?
      <div className="loader_btn_block">
        <ClapSpinner backColor="#89073c" size={40} frontColor="#89073c8a" />
      </div>
      :
      <> <ReactTable
        data={data}
        loading={props.isLogbookAreas}
        defaultPageSize={No_of_rows}
        minRows={0}
        manual
        onFetchData={fetchData}
        pages={No_of_pages}
        gotoPage={logbookAreaCurPage}
        page = { logbookAreaCurPage }
        onPageChange={(page) => {setTablePageFn(page)}}
        onPageSizeChange={props.onUserPageSizeChange}
        showPaginationTop={true}
        showPagination={props.logbookAreas.length !== 0}
        columns={columns}
        NoDataComponent={() => CustomNoDataComponent(props.isLogbookAreas, 'No Logbook Area Found')}
        getTheadThProps={(state, rowInfo, column, instance) => {
          return {
            style: {
              borderTop: 'none',
              borderBottom: 'none',
              borderRight: 'none',
              textAlign: 'center',
              color: '#566975',
              fontSize: '14px',
              fontWeight: '600',
              whiteSpace: 'nowrap',
              float: 'left',
            }
          }
        }
        }
        LoadingComponent={() =>
          props.isLogbookAreas ?
            <div style={{
              display: "block",
              position: "absolute",
              left: 0,
              height: '100%',
              right: 0,
              background: "rgba(255,255,255,0.8)",
              transition: "all .3s ease",
              top: 0,
              bottom: 0,
              textAlign: "center"
            }}
              className="loader_btn_block">
              <ClapSpinner backColor="#89073c" size={40} frontColor="#89073c8a" />
            </div> : null}
        getTrProps={(state, rowInfo, column, instance) => {
          // onclick = () => userDetail(rowInfo ? rowInfo.row.user : rowInfo);
          console.log(column);
          return {
            className: 'react-table-tr-element',
            style: {
              border: '1px solid #c7cdd1',
              transition: '.3s ease-in-out',
              width: '100%',
              // borderRadius: '4px',
              // height: '80px',
              marginTop: '5px',
              float: 'left'
            }
          }

        }}
        getTdProps={(state, rowInfo, column, instance) => {

          if (column.id == 'image') {
            return {
              style: {
                marginTop: '0px'
              }
            }
          } else {
            return {
              className: 'react-td-element',
            }
          }
        }
        }
        getProps={() => {
          return {
            style: {
              border: 'none'
            }
          }
        }}
      />
        <Dialog
          open={openModel}

          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title" style={{ fontSize: '13', color: '#06080f' }}>
            <h3 style={{ color: '#ab385e' }}>Are you sure?</h3>
          </DialogTitle>

          <DialogActions>
            <button data-test="delete_account" style={{ minHeight: '36px', minWidth: '80px' }} onClick={() => handleConfirm(true)} disabled={props.isLoading} className={props.isLoading ? "btn cst_btn btn_danger mr-2 btn-wait" : "btn cst_btn btn_danger mr-2"} id="delete" type="delete">{props.isLoading ? "" : "Delete"}</button>
            <button style={{ minHeight: '36px', minWidth: '80px' }} onClick={() => setOpen(false)} className={"btn cst_btn btn-outline-secondary"} id="cancel" type="cancel">Cancel</button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={openQrModel}

          aria-labelledby="form-dialog-title"
        >
          <div className="row p-0 m-0">
            <div className="col-10">
              <h4 id="form-dialog-title" className="p-2" style={{ textAlign: "left", fontSize: '13', color: '#ab385e' }}>
                QR Code
               </h4></div>
            <div className="col-2">
              <span onClick={() => setQROpen(false)} className="float-right text-right mt-2 cursor-pointer"><i className="fa fa-times"></i></span>
            </div>
          </div>
          <div className="qr-body-single"><img src={currentQrArea && currentQrArea.qr_code_urls.medium} alt="dfdf" /></div>

          <div className=" p-2">
            <button style={{ minHeight: '36px', minWidth: '80px' }} onClick={() => printQr()} className={"float-right btn mr-2 cst_btn btn_danger"} id="cancel" type="cancel">Get PDF</button>
            <button style={{ minHeight: '36px', minWidth: '80px' }} onClick={() => window.print()} disabled={props.isLoading} className={props.isLoading ? "float-right btn cst_btn btn_danger mr-2 btn-wait" : "float-right btn cst_btn btn_danger mr-2"} id="delete" type="delete">{props.isLoading ? "" : "Print QR"}</button>
          </div>
        </Dialog>
        <Dialog
          open={openScheduleModel}
          aria-labelledby="form-dialog-title"
        >
          <div className="row p-2 m-0">
            <div className="col-10">
              <h4 style={{ color: '#ab385e' }}>Schedule Time</h4>
            </div>
            <div className="col-2">
              <span onClick={() => setScheduleOpen(false)} className="float-right text-right mt-0 cursor-pointer"><i className="fa fa-times"></i></span>
            </div>
          </div>
          <DialogContent>
            <>
              <div className="p-3">
                <div>
                  {
                    currentArea && currentArea.days.map((day, index) => <label className="text_danger m-1">{DAYS[day]}</label>)
                  }
                </div>
                <div className="mt-1">
                  {
                  //    currentArea && currentArea.timeslots.map((time, index) =>
                  //    <label key={index} className="m-1 timeslot-btn">{moment(moment().format("MM-DD-YYYY") + " " + time.start_time, "MM-DD-YYYY HH:mm:ss").format("hh:mm A")}</label>
                  //  )
                    currentArea && currentArea.logbook_time_slot.map((time, index) =>
                  <label key={index} className="m-1 timeslot-btn">{convertUTCtoTimezone(time.start_time_utc, props.account.timezone)} {getFormattedZone(props.account.timezone)}</label>
                    )
                  }
                </div>
              </div>
              {/* <div className="row">
                <div className="col-lg-12">
                  <div className="quote_form ticket_form">
                    <div className="addQuote_form">

                      <div className="form-group">
                        <div className="form-group mt-4">
                          <label className="label_modifier">Holiday</label>
                          <div className="checkbox_block radius_checkbox">
                            <div className="custom-control custom-checkbox custom-control-inline">
                              <input onChange={props.selectAllClicked} checked={props.selectNone ? false : props.selectAll} type="checkbox" className="custom-control-input" id="selectAll" />
                              <label className="custom-control-label" for="selectAll">Select All</label>
                            </div>
                            <div className="custom-control custom-checkbox custom-control-inline">
                              <input onChange={props.selectNoneClicked} checked={props.selectAll ? false : props.selectNone} type="checkbox" className="custom-control-input" id="selectNone" />
                              <label className="custom-control-label" for="selectNone">Select None</label>
                            </div>
                          </div>
                        </div>
                        <div className="form-group">
                          {props.holidaysList && props.holidaysList.map(h => {
                            return (
                              <div className="checkbox_block radius_checkbox">
                                <div className="custom-control custom-checkbox mb-2">
                                  <input onChange={() => props.holidaySelected(h.id)} checked={props.setHolidaysCheckValue(h.id)} type="checkbox" className="custom-control-input" id={`holiday${h.id}`} />
                                  <label className="custom-control-label" for={`holiday${h.id}`}>{h.holiday_reason}</label>
                                </div>
                              </div>)
                          })}
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div> */}
            </>
          </DialogContent>
          <div className=" p-2">
            <button style={{ minHeight: '36px', minWidth: '80px' }} onClick={() => setScheduleOpen(false)} className={"float-right btn mr-2 cst_btn btn_danger"} id="cancel" type="cancel">Close</button>
            {/* <button style={{ minHeight: '36px', minWidth: '80px' }} onClick={() => [props.submitAssociateAccountHolidays(), setScheduleOpen(false)]} disabled={props.isLoading} className={props.isLoading ? "float-right btn cst_btn btn_danger mr-2 btn-wait" : "float-right btn cst_btn btn_danger mr-2"} id="delete" type="delete">Submit</button> */}
          </div>
        </Dialog>
      </>
  )
}

export default LogBookAreaTable;