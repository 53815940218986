export const formAuditActionTypes = {

    TEAM_MANAGEMENT_TAB_CLICKED : 'TEAM_MANAGEMENT_TAB_CLICKED',
    OS1_TAB_CLICKED : 'OS1_TAB_CLICKED',
    //////
    GET_SINGLE_TRAINING_LOG:'GET_SINGLE_TRAINING_LOG',
    GET_SINGLE_TRAINING_LOG_PENDING: 'GET_SINGLE_TRAINING_LOG_PENDING',
    GET_SINGLE_TRAINING_LOG_FULFILLED: 'GET_SINGLE_TRAINING_LOG_FULFILLED',
    GET_SINGLE_TRAINING_LOG_REJECTED:'GET_SINGLE_TRAINING_LOG_REJECTED',

    ////
    GET_SINGLE_SUPERVISOR:'GET_SINGLE_SUPERVISOR',
    GET_SINGLE_SUPERVISOR_PENDING: 'GET_SINGLE_SUPERVISOR_PENDING',
    GET_SINGLE_SUPERVISOR_FULFILLED: 'GET_SINGLE_SUPERVISOR_FULFILLED',

    ///
    GET_SINGLE_MANAGER:'GET_SINGLE_MANAGER',
    GET_SINGLE_MANAGER_PENDING: 'GET_SINGLE_MANAGER_PENDING',
    GET_SINGLE_MANAGER_FULFILLED: "GET_SINGLE_MANAGER_FULFILLED",

    ADD_CHECKLIST_LOG:'ADD_CHECKLIST_LOG',
    ADD_CHECKLIST_LOG_PENDING: 'ADD_CHECKLIST_LOG_PENDING',
    ADD_CHECKLIST_LOG_FULFILLED: "ADD_CHECKLIST_LOG_FULFILLED",
    ADD_CHECKLIST_LOG_REJECTED: "ADD_CHECKLIST_LOG_REJECTED",

    GET_MANAGERS_LIST:'GET_MANAGERS_LIST',
    GET_MANAGERS_LIST_PENDING: 'GET_MANAGERS_LIST_PENDING',
    GET_MANAGERS_LIST_FULFILLED: "GET_MANAGERS_LIST_FULFILLED",

    GET_EMPLOYEE_LIST:'GET_EMPLOYEE_LIST',
    GET_EMPLOYEE_LIST_PENDING: 'GET_EMPLOYEE_LIST_PENDING',
    GET_EMPLOYEE_LIST_FULFILLED: "GET_EMPLOYEE_LIST_FULFILLED",

    EDIT_CHECKLIST_LOG:'EDIT_CHECKLIST_LOG',
    EDIT_CHECKLIST_LOG_PENDING: 'EDIT_CHECKLIST_LOG_PENDING',
    EDIT_CHECKLIST_LOG_FULFILLED: "EDIT_CHECKLIST_LOG_FULFILLED",
    EDIT_CHECKLIST_LOG_REJECTED: "EDIT_CHECKLIST_LOG_REJECTED",

    GET_SINGLE_CHECKLIST_LOG:'GET_SINGLE_CHECKLIST_LOG',
    GET_SINGLE_CHECKLIST_LOG_PENDING: 'GET_SINGLE_CHECKLIST_LOG_PENDING',
    GET_SINGLE_CHECKLIST_LOG_FULFILLED: "GET_SINGLE_CHECKLIST_LOG_FULFILLED",

    ADD_MORE_CHECKLIST_LOG_CLICKED : "ADD_MORE_CHECKLIST_LOG_CLICKED",
    
    ////
    GET_CUSTOMER_INFO:"GET_CUSTOMER_INFO",
    GET_CUSTOMER_INFO_PENDING:"GET_CUSTOMER_INFO_PENDING",
    GET_CUSTOMER_INFO_FULFILLED:"GET_CUSTOMER_INFO_FULFILLED",

    ////

    GET_ALL_JOB:"GET_ALL_JOB",
    GET_ALL_JOB_PENDING:"GET_ALL_JOB_PENDING",
    GET_ALL_JOB_FULFILLED:"GET_ALL_JOB_FULFILLED",


    ADD_TEAM_MANAGEMENT:'ADD_TEAM_MANAGEMENT',
    ADD_TEAM_MANAGEMENT_PENDING: 'ADD_TEAM_MANAGEMENT_PENDING',
    ADD_TEAM_MANAGEMENT_FULFILLED: "ADD_TEAM_MANAGEMENT_FULFILLED",
    ADD_TEAM_MANAGEMENT_REJECTED: "ADD_TEAM_MANAGEMENT_REJECTED",

    ADD_TRAINING_LOG:'ADD_TRAINING_LOG',
    ADD_TRAINING_LOG_PENDING: 'ADD_TRAINING_LOG_PENDING',
    ADD_TRAINING_LOG_FULFILLED: "ADD_TRAINING_LOG_FULFILLED",
    ADD_TRAINING_LOG_REJECTED: "ADD_TRAINING_LOG_REJECTED",

    EDIT_TEAM_MANAGEMENT:'EDIT_TEAM_MANAGEMENT',
    EDIT_TEAM_MANAGEMENT_PENDING: 'EDIT_TEAM_MANAGEMENT_PENDING',
    EDIT_TEAM_MANAGEMENT_FULFILLED: "EDIT_TEAM_MANAGEMENT_FULFILLED",
    EDIT_TEAM_MANAGEMENT_REJECTED: "EDIT_TEAM_MANAGEMENT_REJECTED",


    EDIT_TRAINING_LOG:'EDIT_TRAINING_LOG',
    EDIT_TRAINING_LOG_PENDING: 'EDIT_TRAINING_LOG_PENDING',
    EDIT_TRAINING_LOG_FULFILLED: "EDIT_TRAINING_LOG_FULFILLED",
    EDIT_TRAINING_LOG_REJECTED: "EDIT_TRAINING_LOG_REJECTED",



    ADD_CHECKLIST_SUPERVISOR:'ADD_CHECKLIST_SUPERVISOR',
    ADD_CHECKLIST_SUPERVISOR_PENDING: 'ADD_CHECKLIST_SUPERVISOR_PENDING',
    ADD_CHECKLIST_SUPERVISOR_FULFILLED: "ADD_CHECKLIST_SUPERVISOR_FULFILLED",
    ADD_CHECKLIST_SUPERVISOR_REJECTED: "ADD_CHECKLIST_SUPERVISOR_REJECTED",

    EDIT_CHECKLIST_SUPERVISOR:'EDIT_CHECKLIST_SUPERVISOR',
    EDIT_CHECKLIST_SUPERVISOR_PENDING: 'EDIT_CHECKLIST_SUPERVISOR_PENDING',
    EDIT_CHECKLIST_SUPERVISOR_FULFILLED: "EDIT_CHECKLIST_SUPERVISOR_FULFILLED",
    EDIT_CHECKLIST_SUPERVISOR_REJECTED: "EDIT_CHECKLIST_SUPERVISOR_REJECTED",

    GET_ALL_CHECKLIST_LOG:'GET_ALL_CHECKLIST_LOG',
    GET_ALL_CHECKLIST_LOG_PENDING: 'GET_ALL_CHECKLIST_LOG_PENDING',
    GET_ALL_CHECKLIST_LOG_FULFILLED: "GET_ALL_CHECKLIST_LOG_FULFILLED",

    CHECKLIST_LOG_LIST_PAGE_CLICKED : 'CHECKLIST_LOG_LIST_PAGE_CLICKED',

    GET_ALL_CHECKLIST_MANAGER:'GET_ALL_CHECKLIST_MANAGER',
    GET_ALL_CHECKLIST_MANAGER_PENDING: 'GET_ALL_CHECKLIST_MANAGER_PENDING',
    GET_ALL_CHECKLIST_MANAGER_FULFILLED: "GET_ALL_CHECKLIST_MANAGER_FULFILLED",

    CHECKLIST_MANAGER_LIST_PAGE_CLICKED : 'CHECKLIST_MANAGER_LIST_PAGE_CLICKED',

    GET_ALL_CHECKLIST_SUPERVISOR:'GET_ALL_CHECKLIST_SUPERVISOR',
    GET_ALL_CHECKLIST_SUPERVISOR_PENDING: 'GET_ALL_CHECKLIST_SUPERVISOR_PENDING',
    GET_ALL_CHECKLIST_SUPERVISOR_FULFILLED: "GET_ALL_CHECKLIST_SUPERVISOR_FULFILLED",

    CHECKLIST_SUPERVISOR_LIST_PAGE_CLICKED : 'CHECKLIST_SUPERVISOR_LIST_PAGE_CLICKED',

    GET_ALL_CHECKLIST:'GET_ALL_CHECKLIST',
    GET_ALL_CHECKLIST_PENDING: 'GET_ALL_CHECKLIST_PENDING',
    GET_ALL_CHECKLIST_FULFILLED: "GET_ALL_CHECKLIST_FULFILLED",

    CHECKLIST_PAGE_CLICKED : 'CHECKLIST_PAGE_CLICKED',

    GET_ALL_TRAINING_LOG:'GET_ALL_TRAINING_LOG',
    GET_ALL_TRAINING_LOG_PENDING: 'GET_ALL_TRAINING_LOG_PENDING',
    GET_ALL_TRAINING_LOG_FULFILLED: "GET_ALL_TRAINING_LOG_FULFILLED",

    TRAINING_LOG_PAGE_CLICKED : 'TRAINING_LOG_PAGE_CLICKED',

    ADD_CHECKLIST:'ADD_CHECKLIST',
    ADD_CHECKLIST_PENDING: 'ADD_CHECKLIST_PENDING',
    ADD_CHECKLIST_FULFILLED: "ADD_CHECKLIST_FULFILLED",
    ADD_CHECKLIST_REJECTED: "ADD_CHECKLIST_REJECTED",

    GET_SINGLE_CHECKLIST:'GET_SINGLE_CHECKLIST',
    GET_SINGLE_CHECKLIST_PENDING: 'GET_SINGLE_CHECKLIST_PENDING',
    GET_SINGLE_CHECKLIST_FULFILLED: "GET_SINGLE_CHECKLIST_FULFILLED",

    ADD_MORE_CHECKLIST_CLICKED : 'ADD_MORE_CHECKLIST_CLICKED',

    EDIT_CHECKLIST:'EDIT_CHECKLIST',
    EDIT_CHECKLIST_PENDING: 'EDIT_CHECKLIST_PENDING',
    EDIT_CHECKLIST_FULFILLED: "EDIT_CHECKLIST_FULFILLED",
    EDIT_CHECKLIST_REJECTED: "EDIT_CHECKLIST_REJECTED",

    GET_USERS_LIST:'GET_USERS_LIST',
    GET_USERS_LIST_PENDING: 'GET_USERS_LIST_PENDING',
    GET_USERS_LIST_FULFILLED: "GET_USERS_LIST_FULFILLED",

    GET_USERS_LIST_WITH_OUT_CLIENT:'GET_USERS_LIST_WITH_OUT_CLIENT',
    GET_USERS_LIST_WITH_OUT_CLIENT_PENDING: 'GET_USERS_LIST_WITH_OUT_CLIENT_PENDING',
    GET_USERS_LIST_WITH_OUT_CLIENT_FULFILLED: "GET_USERS_LIST_WITH_OUT_CLIENT_FULFILLED",

    GET_CLEANING_WORKERS:'GET_CLEANING_WORKERS',
    GET_CLEANING_WORKERS_PENDING: 'GET_CLEANING_WORKERS_PENDING',
    GET_CLEANING_WORKERS_FULFILLED: "GET_CLEANING_WORKERS_FULFILLED",

    RESET_JOB_COMPANY:"RESET_JOB_COMPANY",

    GET_ALL_JOB_NEW:"GET_ALL_JOB_NEW",
    GET_ALL_JOB_NEW_PENDING:"GET_ALL_JOB_NEW_PENDING",
    GET_ALL_JOB_NEW_FULFILLED:"GET_ALL_JOB_NEW_FULFILLED",

}

