import React, { Component } from 'react';
import * as actions from '../../../redux/actions/index';
import { connect } from 'react-redux';
import { reset } from 'redux-form';
import { animateScroll as scroll } from 'react-scroll'
import CommentsComponent from '../../component/PegAssureComponent/CommentsComponent';
import * as commonService from "../../../utility/utility";
import { decode, encode } from 'base64-arraybuffer';
import { extractFileExtentions } from '../../../utility/extractFileExtentions';
import { extractExtention } from '../../../utility/utility';
import errorHandler from '../../../utility/errorHandler/errorHandler';

const cloneDeep = require('clone-deep');
class CommentsContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            // savedPortfolioURls: this.props.ticket.attachments_attributes ? this.props.ticket.attachments_attributes : [],
            faultImages: 0,
            isImagesEmpty: false,
            imageName: '',
            idsToDelete: [],
            imageUrl: null,
            base64: [],
            savedPortfolioURls: [],
            AllFileTypes: [],
            fileType: '',
            cameraImages: [],
            selectedDeficiencies: [],
        }
        this.onPortfolioFileChange = this.onPortfolioFileChange.bind(this);
        this.removeImage = this.removeImage.bind(this);
        this.choosePhotoClicked = this.choosePhotoClicked.bind(this);
        this.inputOpenFileRef = React.createRef();
    }
    componentDidMount() {
        scroll.scrollToTop();
        this.props.getDeficiency(this.props.match.params.areaId)
    }

    choosePhotoClicked = () => {
        this.inputOpenFileRef.current.click();
    }

    removeImage = (url, fileTypeIndex) => {
        let updatedUrls = [...this.state.base64];
        let updatedFileTypes = [...this.state.fileType]
        let updatedImageNames = [...this.state.imageName]

        let urlIndex = this.state.base64.findIndex(function (u) {
            return u === url
        })
        updatedUrls.splice(urlIndex, 1);
        updatedFileTypes.splice(fileTypeIndex, 1)
        updatedImageNames.splice(fileTypeIndex, 1)

        this.setState({
            base64: updatedUrls,
            fileType: updatedFileTypes,
            imageName: updatedImageNames
        })

    }

    removeUrls = (url) => {
        let updatedUrls = [...this.state.base64];
        let updatedImageNames = [...this.state.imageName]
        let urlIndex = this.state.base64.findIndex(function (u) {
            return u === url
        })

        updatedUrls.splice(urlIndex, 1);
        updatedImageNames.splice(urlIndex, 1)

        this.setState({
            base64: updatedUrls,
            imageName: updatedImageNames
        })

    }
    onPortfolioFileChange = (e) => {
        this.setState({
            faultImages: 0
        });
        console.log(this.state.base64);
        let files = e.target.files;
        let imageName = [], fileType = [], AllFileTypes = [], base64 = [];
        const that = this;
        async function readFile(index) {
            if (index < files.length) {
                let reader = new FileReader();
                let file = files[index];
                imageName.push(file.name)
                fileType.push(file.type)
                AllFileTypes.push(file.type)

                if (file.type.match('image')) {

                    let fileDate = await commonService.compressImage(file, {});
                    let reader = new FileReader();
                    reader.readAsDataURL(fileDate);
                    reader.onload = function () {
                        base64.push(reader.result)
                        if (index === files.length - 1) {
                            //   
                            that.setState(prevState => ({
                                base64: [...prevState.base64, ...base64]
                            }))
                        }
                    };
                    reader.onerror = function (error) {
                        console.log("Error: ", error);
                    };
                    if (index === files.length - 1) {
                        //   
                        that.setState(prevState => ({
                            imageName: [...prevState.imageName, ...imageName],
                            fileType: [...prevState.fileType, ...fileType],
                            AllFileTypes: [...prevState.AllFileTypes, ...AllFileTypes],
                        }))
                    }
                    readFile(index + 1);

                }
            }
        }

        readFile(0);
    }

    openCameraClicked = () => {
        this.props.openCameraClicked()
    }
    closeCameraClicked = () => {
        this.props.closeCameraClicked()
    }
    submitCameraPics = (images) => {
        let imageName = []
        const that = this;
        if(this.state.imageName) {
            imageName = [...this.state.imageName]
        }
        imageName.push( this.state.imageName.length + '.png')
        that.setState(prevState => ({
            base64: [...prevState.base64, images],
            imageName: imageName
        }))
        // this.setState({base64: images, imageName: imageName})
        this.props.closeCameraClicked()
    }
    submitFeedbackClicked = (values) => {
        if(this.props.match.params.type !== 'average') {
            if(!values.comments) {
                errorHandler('Please Enter Comment')
                return
            }
        }
        const arrayBuffer = [];
        let extentions;
        if(this.state.base64.length > 0) {
            this.state.base64.map((url, i) => {
                if (!url.photo_urls) {
                    let base_photo = null;
                    const image = url.split(',');
                    base_photo = image[1];
                    arrayBuffer.push(decode(base_photo))
                }

            })
        extentions = extractExtention(this.state.imageName);
        }
        const feedback = {};
        if(values && values.comments && values.comments.message) {
            feedback['comment'] = values.comments.message;
        }
        feedback['deficiency_ids'] = [];
        if(this.state.selectedDeficiencies.length > 0) {
            this.state.selectedDeficiencies.map((q,i) => {
                feedback['deficiency_ids'].push(q.id)
            })
        }
        feedback['rating'] = this.props.match.params.type;
        if(arrayBuffer.length > 0) {
        this.props.submitFeedbackPresignUrl(feedback, this.props.match.params.areaId, extentions, arrayBuffer)
        } else {
        this.props.submitFeedback({feedback: feedback}, this.props.match.params.areaId)
        }
    }
    selectedDeficiencies = (def) => {
        this.setState({selectedDeficiencies: def})
    }
    render() {

        return (
            <CommentsComponent
                {...this.props}
                onPortfolioFileChange={this.onPortfolioFileChange}
                savedPortfolioURls={this.state.savedPortfolioURls}
                removeImage={this.removeImage}
                removeUrls={this.removeUrls}
                portfolioImages={this.state.base64}
                choosePhotoClicked={this.choosePhotoClicked}
                reference={this.inputOpenFileRef}
                openCameraClicked={this.openCameraClicked}
                closeCameraClicked={this.closeCameraClicked}
                submitCameraPics={this.submitCameraPics}
                cameraImages={this.state.cameraImages}
                onSubmit={this.submitFeedbackClicked}
                closeDeficiencyClicked={this.props.closeDeficiencyClicked}
                selectedDeficiencies={this.selectedDeficiencies}
                defLength={this.state.selectedDeficiencies.length}
                />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        openCameraModal: state.adminOrSuperAdminReducer.openCameraModal,
        isLoading: state.adminOrSuperAdminReducer.isLoading,
        openDeficiencyModal: state.adminOrSuperAdminReducer.openDeficiencyModal,
        deficiency: state.adminOrSuperAdminReducer.deficiency,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        openCameraClicked: () => dispatch(actions.openCameraClicked()),
        closeCameraClicked: () => dispatch(actions.closeCameraClicked()),
        closeDeficiencyClicked: () => dispatch(actions.closeDeficiencyClicked()),
        submitFeedbackPresignUrl: (feedback, id, extentions, arrayBuffer) => dispatch(actions.submitFeedbackPresignUrl(feedback, id, extentions, arrayBuffer)),
        submitFeedback: (feedback, id) => dispatch(actions.submitFeedback(feedback, id)),
        getDeficiency: (id) => dispatch(actions.getDeficiency(id)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CommentsContainer);