import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { reduxForm, change } from 'redux-form';
import { Input } from '../UI/InputElement/InputElement';
import { settingsPreferenceValidator as validate } from '../../../utility/validator/validator';
import { ClientSettingsDailyHours, TicketsEscalationTime } from '../../../utility/constants/constants';
import isEmpty from '../../../utility/IsEmptyValidator';
import { connect } from 'react-redux';
import { ClapSpinner } from 'react-spinners-kit';
const cloneDeep = require('clone-deep');

let SettingsPreferences = (props) => {
    const dispatch = useDispatch();

    const inspectionContentChange = (e) => {

        if (e.target.name === 'user[no_deficiency]') {
            if (props.formStates.user.contains_deficiency && props.formStates.user.has_photos
                && props.formStates.user.has_comments && e.target.value === "false") {
                dispatch(change('settingsPreferences', `user[all_inspection_email]`, "true"))
            } else if (!props.formStates.user.contains_deficiency && !props.formStates.user.has_photos
                && !props.formStates.user.has_comments && e.target.value === "true") {
                dispatch(change('settingsPreferences', `user[no_inspection_email]`, "true"))
            } else {
                dispatch(change('settingsPreferences', `user[no_inspection_email]`, "false"))
                dispatch(change('settingsPreferences', `user[all_inspection_email]`, "false"))
            }
        } else if (e.target.name === 'user[contains_deficiency]') {
            if (props.formStates.user.no_deficiency && props.formStates.user.has_photos
                && props.formStates.user.has_comments && e.target.value === "false") {
                dispatch(change('settingsPreferences', `user[all_inspection_email]`, "true"))
            } else if (!props.formStates.user.no_deficiency && !props.formStates.user.has_photos
                && !props.formStates.user.has_comments && e.target.value === "true") {
                dispatch(change('settingsPreferences', `user[no_inspection_email]`, "true"))
            } else {
                dispatch(change('settingsPreferences', `user[no_inspection_email]`, "false"))
                dispatch(change('settingsPreferences', `user[all_inspection_email]`, "false"))
            }
        } else if (e.target.name === 'user[has_photos]') {
            if (props.formStates.user.no_deficiency && props.formStates.user.contains_deficiency
                && props.formStates.user.has_comments && e.target.value === "false") {
                dispatch(change('settingsPreferences', `user[all_inspection_email]`, "true"))
            } else if (!props.formStates.user.no_deficiency && !props.formStates.user.contains_deficiency
                && !props.formStates.user.has_comments && e.target.value === "true") {
                dispatch(change('settingsPreferences', `user[no_inspection_email]`, "true"))
            } else {
                dispatch(change('settingsPreferences', `user[no_inspection_email]`, "false"))
                dispatch(change('settingsPreferences', `user[all_inspection_email]`, "false"))
            }
        } else if (e.target.name === 'user[has_comments]') {
             
            if (props.formStates.user.no_deficiency && props.formStates.user.contains_deficiency
                && props.formStates.user.has_photos && e.target.value === "false") {
                dispatch(change('settingsPreferences', `user[all_inspection_email]`, "true"))
            } else if (!props.formStates.user.no_deficiency && !props.formStates.user.contains_deficiency
                && !props.formStates.user.has_photos && e.target.value === "true") {
                dispatch(change('settingsPreferences', `user[no_inspection_email]`, "true"))
            } else {
                dispatch(change('settingsPreferences', `user[no_inspection_email]`, "false"))
                dispatch(change('settingsPreferences', `user[all_inspection_email]`, "false"))
            }
        }

        // if (props.formStates.user.no_deficiency && props.formStates.user.contains_deficiency
        //     && props.formStates.user.has_photos && props.formStates.user.has_comments) {
        //     dispatch(change('settingsPreferences', `user[all_inspection_email]`, true))
        //     dispatch(change('settingsPreferences', `user[no_inspection_email]`, false))
        // }
        // if (props.formStates.user.no_deficiency || props.formStates.user.contains_deficiency
        //     || props.formStates.user.has_photos || props.formStates.user.has_comments) {
        //     dispatch(change('settingsPreferences', `user[no_inspection_email]`, false))
        // }
    }

    const inspectionEmailChange = (e) => {

        if (e.target.name === 'user[all_inspection_email]') {
            dispatch(change('settingsPreferences', `user[no_deficiency]`, true));
            dispatch(change('settingsPreferences', `user[contains_deficiency]`, true))
            dispatch(change('settingsPreferences', `user[has_photos]`, true))
            dispatch(change('settingsPreferences', `user[has_comments]`, true))
            dispatch(change('settingsPreferences', `user[no_inspection_email]`, false))
        }
        if (e.target.name === 'user[no_inspection_email]') {
            dispatch(change('settingsPreferences', `user[all_inspection_email]`, false))
            dispatch(change('settingsPreferences', `user[no_deficiency]`, false));
            dispatch(change('settingsPreferences', `user[contains_deficiency]`, false))
            dispatch(change('settingsPreferences', `user[has_photos]`, false))
            dispatch(change('settingsPreferences', `user[has_comments]`, false))
        }
    }

    const { handleSubmit, pristine, reset, submitting, error } = props;
    return (
        <main className="app-content wraper_content inspection_wraper">
            {props.isLoading ? <div className="loader_btn_block">
                <ClapSpinner backColor="#89073c" size={40} frontColor="#89073c8a" />
            </div> : 
            <section className="settings-preference">
            <form onSubmit={handleSubmit}>
                <div className="container">
                <div style={{paddingBottom: "20px"}}>
                {/* <button type="button" className="btn cst_btn btn_danger mr-2" onClick={props.onCancelClicked}>Back</button>  */}
                </div>
                    {/* <!--   <h3>Email Notification</h3> --> */}
                    <div className="preference-email">
                        <aside className="setup-label">
                            summaries
                </aside>
                        <article className="preference-article">
                            <div className="preference-checkbox">
                                <Input type="checkbox" name="user[daily]" />
                                <span className="checkmark-setup"></span>
                                <div className="preference-label"><b>Daily at</b>
                                    <Input type="select" options={ClientSettingsDailyHours} className="custom-select input-modifier preference-select" name="user[daily_hours]" />
                                    Every day when there are new inspections or tickets</div>
                            </div>
                            <div className="preference-checkbox">
                                <Input type="checkbox" name="user[weekly]" />
                                <span className="checkmark-setup"></span>
                                <div className="preference-label"><b>Weekly - </b>Every Monday covering the prior week</div>
                            </div>
                            <div className="preference-checkbox">
                                <Input type="checkbox" name="user[monthly]" />
                                <span className="checkmark-setup"></span>
                                <div className="preference-label"><b>Monthly - </b>Every 1<sup>st</sup> of the month covering the prior month</div>
                            </div>
                        </article>
                    </div>
                    <div className="preference-email">
                        <aside className="setup-label">
                            inspections
                </aside>
                        <article className="preference-article">
                            <div className="preference-checkbox">
                                <Input type="radio" name="user[all_inspection_email]" value={"true"} radioType="settingPreferences" onClick={inspectionEmailChange} />
                                <span className="checkmark-setup radio-preference"></span>
                                <div className="preference-label"><b>All emails - </b> Receive one email for every inspection</div>
                            </div>
                            <div className="preference-checkbox">
                                {/* <Input type="radio" name="user[some_inspection_email]" value={"true"} radioType="settingPreferences" />
                                <span className="checkmark-setup radio-preference"></span>
                                <div className="preference-label"><b>Some emails</b>pick which emails you'd like to receive.</div> */}
                                <div className="some-email-setup">
                                    <div className="preference-checkbox">
                                        <Input type="checkbox" name="user[no_deficiency]" handleChange={inspectionContentChange} />
                                        <span className="checkmark-setup"></span>
                                        <div className="preference-label"> An inspection has no deficiencies</div>
                                    </div>
                                    <div className="preference-checkbox">
                                        <Input type="checkbox" name="user[contains_deficiency]" handleChange={inspectionContentChange} />
                                        <span className="checkmark-setup"></span>
                                        <div className="preference-label">An inspection contains deficiencies</div>
                                    </div>
                                    <div className="preference-checkbox">
                                        <Input type="checkbox" name="user[has_photos]" handleChange={inspectionContentChange} />
                                        <span className="checkmark-setup"></span>
                                        <div className="preference-label">An inspection has photos</div>
                                    </div>
                                    <div className="preference-checkbox">
                                        <Input type="checkbox" name="user[has_comments]" handleChange={inspectionContentChange} />
                                        <span className="checkmark-setup"></span>
                                        <div className="preference-label">An inspection has comments</div>
                                    </div>
                                </div>
                            </div>
                            <div className="preference-checkbox">
                                <Input type="radio" value={"true"} name="user[no_inspection_email]" onClick={inspectionEmailChange} radioType="settingPreferences" />
                                <span className="checkmark-setup radio-preference"></span>
                                <div className="preference-label"><b>No inspections emails</b></div>
                            </div>
                        </article>
                    </div>
                    <div className="preference-email">
                        <aside className="setup-label">
                            Tickets
                </aside>
                        <article className="preference-article">
                            <div className="preference-checkbox">
                                <Input type="checkbox" name="user[all_ticket_activity]" />
                                <span className="checkmark-setup"></span>
                                <div className="preference-label"> <b>All activity - </b> A ticket is created, resolved, or receives a comment
                        </div>
                            </div>
                            <div className="preference-checkbox">
                                <Input type="checkbox" name="user[escalation]" />
                                <span className="checkmark-setup"></span>
                                <div className="preference-label"> <b> Escalation - </b> A ticket has been open without activity for
                          <Input className="custom-select input-modifier preference-select" type="select" options={TicketsEscalationTime} name="user[escalation_hours]" />
                                    {/* <select className="custom-select input-modifier preference-select">
                                        <option value="6PM">10 min</option>
                                        <option value="10AM">30 min</option>
                                        <option value="6PM">1 hour</option>
                                        <option value="10AM">3 hours</option>
                                        <option value="6PM">6 hours</option>
                                        <option value="10AM">12 hours</option>
                                        <option value="6PM">24 hours</option>
                                        <option value="10AM">48 hours</option>
                                        <option value="6PM">3 days</option>
                                        <option value="10AM">5 days</option>
                                    </select> */}
                                </div>
                                
                            </div>
                            <div className="preference-checkbox">
                                <Input type="checkbox" name="user[pegassure_activity]" />
                                <span className="checkmark-setup"></span>
                                <div className="preference-label"> <b> Priority Escalation - </b> Pegassure Feedback PegAssure Tickets SMS Notification
                          {/* <Input className="custom-select input-modifier preference-select" type="select" options={TicketsEscalationTime} name="user[priority_escalation_hours]" /> */}
                                </div>
                                
                            </div>
                        </article>
                    </div>

                    <div className="preference-btn">
                        <button type="submit" disabled={props.isLoading ? true : false} className={props.isLoading ? "btn cst_btn btn_danger mr-2 btn-wait" : "btn cst_btn btn_danger mr-2"} >{props.isLoading ? "" : "Save"}</button>
                        {props.isUserPreference ?
                            <button type="button" className="btn cst_btn btn-outline-secondary" onClick={props.onCancelClicked}>Cancel</button>
                            : null}
                    </div>
                    {/* <div className="preference-email">
                    <aside className="setup-label">
                        Newsletter
                </aside>
                    <article className="preference-article">
                        <div className="preference-checkbox">
                            <input type="checkbox" />
                            <span className="checkmark-setup"></span>
                            <div className="preference-label">Get occasional OrangeQC email updates with tips, ideas, and new features.
                        </div>
                        </div>
                    </article>
                </div> */}
                </div>
            </form>
            {/* </div> */}
        </section>
        }
            
        </main >

    )
}

const mapStateToProps = (state) => {
    let user = null;

    const currentUser = cloneDeep(!isEmpty(state.adminOrSuperAdminReducer.requestor) ? state.adminOrSuperAdminReducer.requestor.user : state.authReducer.user);
    if (isEmpty(currentUser)) {
        user = {}
    } else {
        user = {
            daily: currentUser.summaries.daily,
            weekly: currentUser.summaries.weekly,
            monthly: currentUser.summaries.monthly,
            daily_hours: currentUser.summaries.daily_hours,
            all_inspection_email: currentUser.inspections.all_inspection_email ? "true" : false,
            no_deficiency: currentUser.inspections.no_deficiency,
            contains_deficiency: currentUser.inspections.contains_deficiency,
            has_photos: currentUser.inspections.has_photos,
            has_comments: currentUser.inspections.has_comments,
            no_inspection_email: currentUser.inspections.no_inspection_email ? "true" : false,
            all_ticket_activity: currentUser.tickets.all_ticket_activity,
            escalation_hours: currentUser.tickets.escalation_hours,
            escalation: currentUser.tickets.escalation,
            pegassure_activity: currentUser.pegassure_activity,
        }
        // }
    }
    //  
    return { initialValues: { user } }
}

SettingsPreferences = reduxForm({
    form: 'settingsPreferences',
    validate,
    enableReinitialize: true
})(SettingsPreferences);

SettingsPreferences = connect(mapStateToProps)(SettingsPreferences)

export default SettingsPreferences;