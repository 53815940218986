import axios, { API_VERSION, accessToken, formAuditBaseUrl,timeTrackerBaseUrl } from '../../config';
import storage from '../../utility/storage';
import { getUser } from '../../utility/utility';
const pureAxios = require('axios');

const user = storage.get('user');

const headers = {
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
    },
};


export const getSingleManagerSummary = (id) => {
    
    return formAuditBaseUrl.get(API_VERSION+`team_checklist/manager_summary/${id}`, headers);
}

export const getSingleTraingLogApi = (id) => {
    
    return formAuditBaseUrl.get(API_VERSION+`team_checklist/training_log/${id}`, headers);
}

export const getCustomerInfoAPI=(params)=>{
    return formAuditBaseUrl.get(API_VERSION+`companies/list`,{params}, headers);
}

export const getAllJobApi=()=>{
    return formAuditBaseUrl.get(API_VERSION+`accounts/list`, headers);
}

export const addTeamManagementApi=(body)=>{
    return formAuditBaseUrl.post(API_VERSION+`team_checklist/manager_summary`, body, headers);
}

export const addTrainingLogApi=(body)=>{
    return formAuditBaseUrl.post(API_VERSION+`team_checklist/training_log/`, body, headers);
}

export const addTeamSupervisorApi=(body)=>{
    return formAuditBaseUrl.post(API_VERSION+`team_checklist/supervisor_summary`, body, headers);
}

export const editTeamSupervisorApi=(body)=>{
    return formAuditBaseUrl.put(API_VERSION+`team_checklist/supervisor_summary`, body, headers);
}

export const editTeamManagementApi=(body)=>{
    return formAuditBaseUrl.put(API_VERSION+`team_checklist/manager_summary`, body, headers);
}

export const editTrainingLogApi=(body)=>{
    return formAuditBaseUrl.put(API_VERSION+`team_checklist/training_log`, body, headers);
}


export const addChecklistLog = (body) => {
    return formAuditBaseUrl.post(API_VERSION + `team_checklist/log`, body , headers);
};

export const getManagersList = (params) => {
    return formAuditBaseUrl.get(API_VERSION+`users/list`, {params},  headers);
}

// export const getUsersList = (params) => {
//     return formAuditBaseUrl.get(API_VERSION+`users/list`,  headers);
// }

export const editChecklistLog = (body) => {
    return formAuditBaseUrl.put(API_VERSION + `team_checklist/log`, body , headers);
};

export const getSingleChecklistLog = (id) => {
    return formAuditBaseUrl.get(API_VERSION + `team_checklist/log/${id}` , headers);
};

export const getSingleSupervisor = (id) => {
    return formAuditBaseUrl.get(API_VERSION + `team_checklist/supervisor_summary/${id}` , headers);
};

export const getAllChecklistLog = (params) => {
    return formAuditBaseUrl.get(API_VERSION+`team_checklist/log`, {params},  headers);
}

export const getAllChecklistManager = (params) => {
    return formAuditBaseUrl.get(API_VERSION+`team_checklist/manager_summary`, {params},  headers);
}

export const getAllChecklistSupervisor = (params) => {
    return formAuditBaseUrl.get(API_VERSION+`team_checklist/supervisor_summary`, {params},  headers);
}

export const getAllChecklist = (params) => {
    return formAuditBaseUrl.get(API_VERSION+`team_checklist/team_checklist`, {params},  headers);
}

export const getAllTrainingLog = (params) => {
    return formAuditBaseUrl.get(API_VERSION+`team_checklist/training_log`, {params},  headers);
}

export const addChecklist = (body) => {
    return formAuditBaseUrl.post(API_VERSION + `team_checklist/team_checklist`, body , headers);
};

export const getSingleChecklist = (id) => {
    return formAuditBaseUrl.get(API_VERSION + `team_checklist/team_checklist/${id}` , headers);
};

export const editChecklist = (body) => {
    return formAuditBaseUrl.put(API_VERSION + `team_checklist/team_checklist`, body , headers);
};

export const getUsersList = (params) => {
    return formAuditBaseUrl.get(API_VERSION+`users/list`, {params},  headers);
}

export const getUsersListWithoutClient = () => {
    return formAuditBaseUrl.get(API_VERSION+`users/list?roles=admin&roles=superadmin&roles=requestor&roles=user&roles=inspector&roles=frontline`,  headers);
}

export const getCleaningWorkersList = (params) => {
    return formAuditBaseUrl.get(API_VERSION+`users/list`, {params},  headers);
}

export const getAllJObByCompany = (params) => {
    
    return timeTrackerBaseUrl.get(API_VERSION+`accounts/list`, {params},  headers);
}

export const getAllJobApiNew=()=>{
    return axios.get(API_VERSION+`accounts/get_all_account/account_mode?mode=external`, headers);
}