import React, { useState } from 'react';
import { Input } from '../../UI/InputElement/InputElement';
import { formValueSelector, getFormValues, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { AccountsSearchValidator as validate } from '../../../../utility/validator/validator';
import { reset } from 'redux-form';
import Dialog from '@material-ui/core/Dialog';
import { convertDateToPickerFormat } from '../../../../utility/utility';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import { CSVLink, CSVDownload } from "react-csv";
import { userRoles } from '../../../../utility/constants/constants';
import LogBookAreaTable from './LogBookAreaTable';
import ScanQr from '../../../../adminOrSuperAdminOrRequestor/container/QR';
import { useEffect } from 'react';

let LogbookArea = (props) => {
    // const [activeJobs, setActiveJobs] = useState(true);
    const { handleSubmit, pristine, reset, submitting, error, initial } = props;

    const getAccountsDropDown = (accounts) => {

        return accounts && accounts.map(a => {
            return {
                value: a.id,
                label: a.name
            }
        })
    }
    const [accountList, setAccountList] = useState(getAccountsDropDown(props.accountsList));

    useEffect(() => {
        setAccountList(getAccountsDropDown(props.accountsList))
    }, [props.accountsList])

    const noAccountOptionsMessage = (a, b) => {
        return 'Job not found';
    }

    const filterByStatus = [
        {
          id: 'pending',
          name: 'Pending'
        },
        {
          id: 'reviewed',
          name: 'Reviewed'
        },
        {
            id: 'quality_reviewed',
            name: 'Quality Reviewed'
        },
      ]

    return (
        <>
            <main className="app-content wraper_content inspection_wraper">
                <div className="tab_header search_filter_cont inspection_tab_content job_filter">
                    <form onSubmit={handleSubmit}>
                        <input type="hidden" value="something" />
                        <div className="row">
                            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                                <div className="app-search">
                                    <Input name="name" dataTest="creaters_filter" className="fil_search_input" type="search" placeholder="Search By Logbook Name" />
                                    <button className="app-search__button">
                                        <i class="fa fa-search" aria-hidden="true"></i>
                                    </button>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-12 col-sm-12">
                                <Input name="account_id" noOptionsMessage={noAccountOptionsMessage} placeholder="Job" type="inputSelect" options={accountList} className="input-modifier add_user_select" /> 
                            </div>
                            <div className="col-xl-2 col-lg-4 col-md-12 col-sm-12">
                                <div className="app-search">
                                    <Input name="status" type="select" options={filterByStatus}  placeholder="Select Status" className="custom-select input-modifier add_user_select" />
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6" style={{ maxWidth : '200px' }}>
                                <label>
                                    <Input name="fromDate" maxDate={props.selectedValues.toDate ? props.selectedValues.toDate : new Date()} dateFormat="MM-dd-yyyy" placeholder="From Date" datePickerType={"react-datepicker"} type="date" className="form-control input-modifier" />
                                    <div class="input-group-append cst_group_append"> <span class="input-group-text" id="basic-addon2"><i class="fa fa-calendar"></i></span> </div>
                                </label>
                            </div>
                            <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6" style={{ maxWidth : '200px' }}>
                                <label>
                                    <Input name="toDate" minDate={props.selectedValues.fromDate ? props.selectedValues.fromDate : null} maxDate={new Date()} dateFormat="MM-dd-yyyy" placeholder="To Date" datePickerType={"react-datepicker"} type="date" className="form-control input-modifier" />
                                    <div class="input-group-append cst_group_append"> <span class="input-group-text" id="basic-addon2"><i class="fa fa-calendar"></i></span> </div>
                                </label>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                                <div className="app-search">
                                     <Input name="search" dataTest="creaters_filter" className="fil_search_input" type="search" placeholder="Search By OCR" />
                                     <button className="app-search__button">
                                        <i class="fa fa-search" aria-hidden="true"></i>
                                    </button>
                                </div>
                            </div>
                            <div className="col-xl-2 col-lg-4 col-md-6 col-sm-6 col-12">
                                <button data-test="users_go_btn" style={{ minWidth: '90px' }} className={"btn cst_btn btn_danger mr-2  account_btn"} id="submit" type="submit">{"Go"} </button>
                                <button data-test="users_reset_btn" style={{ minWidth: '90px' }} onClick={props.resetClicked} className={"btn cst_btn btn-outline-secondary account_btn"} id="reset" type="reset">{"Reset"} </button>
                            </div>
                        </div>
                    </form>
                </div>


                <section className="account_sec inspection_tab_content appcontent_Inner accounts_user_btn theme_col_px">
                    <div className="quotes_table_content accounts_table_contnet table-responsive">
                        <div className="table quotes_table user_react_table">
                            <LogBookAreaTable
                                {...props}
                                deleteAccount={props.deleteAccount}
                                onAccountsPageSizeChange={props.onAccountsPageSizeChange}
                                accountsList={props.accountsList ? props.accountsList : []}
                                logbookList={props.logbookLogs ? props.logbookLogs : []}
                                isLoading={props.isLoading}
                                previewAccountClicked={props.previewAccountClicked}
                                editAccountClicked={props.editAccountClicked}
                                openDeleteModel={props.openDeleteModel}
                                handleClickDeleteModel={props.handleClickDeleteModel}
                                accounts_rows={props.accounts_rows}
                                activeJobs={props.activeJobs}
                                previewLogBookAreaQrScanClicked={props.previewLogBookAreaQrScanClicked}
                                logbookQRCamera={props.logbookQRCamera}
                                formStates={props.formStates}
                            />
                        </div>

                    </div>
                    {/* <div className="clearfix"></div>
                <div className="modal-footer">
                    <button type="button" className="btn btn_danger cst_btn">Save</button>
                    <button type="button" className="btn btn-outline-secondary cst_btn" data-dismiss="modal">Cancel</button>
                </div> */}
                </section>
            </main>
            <Dialog open={props.viewLogbookQrScan} aria-labelledby="form-dialog-title">
                <DialogActions>
                    <ScanQr />
                </DialogActions>
            </Dialog>
        </>


    )
}
const mapStateToProps = (state) => {
    let name = state.adminOrSuperAdminReducer.searchLogbookValues ? state.adminOrSuperAdminReducer.searchLogbookValues.name : "";
    let search = state.adminOrSuperAdminReducer.searchLogbookValues ? state.adminOrSuperAdminReducer.searchLogbookValues.search : "";
    let job_name = state.adminOrSuperAdminReducer.searchLogbookValues ? state.adminOrSuperAdminReducer.searchLogbookValues.jobName : "";
    let logbook_name = state.adminOrSuperAdminReducer.searchLogbookValues ? state.adminOrSuperAdminReducer.searchLogbookValues.logbookName : "";
    let status = state.adminOrSuperAdminReducer.searchLogbookValues &&  state.adminOrSuperAdminReducer.searchLogbookValues.status ? state.adminOrSuperAdminReducer.searchLogbookValues.status : "pending"
    let fromDate = state.adminOrSuperAdminReducer.searchLogbookValues && state.adminOrSuperAdminReducer.searchLogbookValues.fromDate ? convertDateToPickerFormat(state.adminOrSuperAdminReducer.searchLogbookValues.fromDate) : "";
    let toDate = state.adminOrSuperAdminReducer.searchLogbookValues &&  state.adminOrSuperAdminReducer.searchLogbookValues.toDate ? convertDateToPickerFormat(state.adminOrSuperAdminReducer.searchLogbookValues.toDate) : "";
    
    return { 
        initialValues: { 
            name, 
            job_name, 
            logbook_name, 
            status, 
            fromDate,
            toDate,
            search
        }, formStates: getFormValues('searchLogbookForm')(state)
    }
}

LogbookArea = reduxForm({
    form: 'searchLogbookForm',
    validate,
    enableReinitialize: true
})(LogbookArea);

const selector = formValueSelector('searchLogbookForm') // <-- same as form name
LogbookArea = connect(state => {
    const { fromDate, toDate } = selector(state, 'fromDate', 'toDate')
    const selectedValues = { fromDate, toDate }
    return {
        selectedValues
    }
}) (LogbookArea)

LogbookArea = connect(mapStateToProps)(LogbookArea)

export default LogbookArea;