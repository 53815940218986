import React, { useEffect } from 'react';
import { Input } from '../../../UI/InputElement/InputElement';
import { reduxForm } from 'redux-form';
import { locationValidation as validate } from '../../../../../utility/validator/validator';
import { connect } from 'react-redux';
import { isEmpty } from '../../../../../utility/utility';
// import React, { useState, useEffect } from 'react';

let NewLocation = (props) => {
  const { handleSubmit, pristine, reset, submitting, error } = props;

  // useEffect(() => {

  //     // Specify how to clean up after this effect:
  //     return function cleanup() {
  //         props.emptyObjects();
  //     };
  // });


  return (
      <div className="modal fade request_quote_modal reject_quot associateUser" id="addAreaType" tabindex="-1" role="dialog" aria-labelledby="rejectQuoteModalTitle" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <label for="exampleInputEmail1" className="cst-label md_title">{props.isEdit ? 'Update Location' : 'New Location'}</label>
              <button type="button" className="close close_icn_pic" data-dismiss="modal" onClick={props.closePopup}><img src="/images/icons/icn_close_gray.png" alt="Close Icon" /></button>
            </div>

            <div className="modal-body">
              <div className="tab-content">
                <div id="profile" className="profile_tab cst_tab_content tab-pane fade show active edit_profile_modal">
                  <form className="form_content" onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group">
                          <label className="label_modifier">Location</label>
                          <Input type="text" className="form-control input-modifier" placeholder="" name="location[name]" />
                        </div>
                      </div>
                    </div>
                    <div className="clearfix"></div>
                    <div className="modal-footer">
                      <button type="submit" disabled={props.isLoading} className={props.isLoading ? "btn cst_btn btn_danger mr-2 btn-wait" : "btn cst_btn btn_danger mr-2"} >{props.isLoading ? "" : "Save"}</button>
                      <button onClick={props.closePopup} type="button" className="btn btn-outline-secondary cst_btn" data-dismiss="modal">Close</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            {/* <div className="clearfix"></div>
             <div className="modal-footer">
             <button type="button" className="btn btn_danger cst_btn">Save</button>
             <button type="button" className="btn btn-outline-secondary cst_btn" data-dismiss="modal">Cancel</button>
             </div> */}
          </div>
        </div>
      </div >
  )
}

const mapStateToProps = (state) => {

  let location = {}

  if (state.InventoryReducer.location) {
    const value = state.InventoryReducer.location
    location['name']= value.name

  }

  return { initialValues: {location} }
}

NewLocation = reduxForm({
  form: 'locationForm',
  validate
})(NewLocation);

NewLocation = connect(mapStateToProps)(NewLocation)

export default NewLocation;