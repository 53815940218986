import React, { Component } from 'react';
import { animateScroll as scroll} from 'react-scroll'
import storage from '../../../../../utility/storage';
import { connect } from 'react-redux';
import * as actions from '../../../../../redux/actions/index';
import { reset } from 'redux-form';
import PickupsTicket from '../../../../component/Inventory/InventoryTicketsComponents/PickupsTicketComponents/PickupsTicket';

class PickupsTicketContainer extends Component {


    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentDidMount() {
        scroll.scrollToTop();
    }

    searchPickupsTicketClicked = (values) => {
        if(values.fulfillment_manager_location) {
            values.fulfillment_manager_location = values.fulfillment_manager_location.trim()
        }
        if(values.approver_name) {
            values.approver_name = values.approver_name.trim()
        }
        if(values.status) {
            values.status = values.status.trim()
        }
        if(values.id) {
            values.id = values.id.trim()
        }
        if(values.is_emergency) {
            values.is_emergency = values.is_emergency.trim()
        }
        this.props.searchAllPickupsTicketList(values);
    };

    componentWillUnmount() {
        this.props.resetInventoryObjects();
    }

    componentDidUpdate(prevProps, prevState) {
        const user = storage.get('user');
        if(user) {
            if(prevProps.user.selected_mode !== user.selected_mode) {
                this.props.pickupsTicketListPageClicked(0)
            }
        }
    }

    resetClicked = () => {
        const page = 1;
        const limit = 10;
        const status = 'readyForPickup'
        this.props.reset('searchPickupsTicketForm');
        this.props.pickupsTicketListPageClicked(0);
        this.props.searchAllPickupsTicketList({status});
        this.props.getAllPickupsTicketList({ page, limit, status});
    }

    render() {
        return (
            <PickupsTicket
                {...this.props}
                onSubmit={this.searchPickupsTicketClicked}
                resetClicked={this.resetClicked}
            />
        )
    }
}

const mapStateToProps = (state) => {
  return {
    user: state.authReducer.user,
    isLoading: state.InventoryReducer.isLoading,
    pickupsTicketList: state.InventoryReducer.pickupsTicketList,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    resetInventoryObjects: () => dispatch(actions.resetInventoryObjects()),
    getAllPickupsTicketList: (params) => dispatch(actions.getAllPickupsTicketList(params)),
    searchAllPickupsTicketList: (values) => dispatch(actions.searchAllPickupsTicketList(values)),
    pickupsTicketListPageClicked: (page) => dispatch(actions.pickupsTicketListPageClicked(page)),
    reset: (searchPickupsTicketForm) => dispatch(reset(searchPickupsTicketForm)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PickupsTicketContainer);