import React, { useEffect, useState } from 'react';
import ReactTable from 'react-table-v6';
import Dialog from '@material-ui/core/Dialog';
import { makeFirstLetterCapital, CustomNoDataComponent, getUser } from '../../../../utility/utility';
import { MagicSpinner, PushSpinner, SwishSpinner, WaveSpinner, ClapSpinner } from 'react-spinners-kit';
import '../../../../../node_modules/react-table-v6/react-table.css';
import { useSelector, useDispatch } from 'react-redux';
import { FaArrowDown, FaArrowUp } from "react-icons/fa";
import * as actions from '../../../../redux/actions/index';
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { Select } from 'antd';
import { qrCodeSize_option } from '../../../../utility/constants/constants';

const BoxTable = (props) => {
  const [pageSize, setPageSize] = useState(10);
  const [openQrModel, setQROpen] = useState(false);
  const [currentQrArea, setCurrentQrArea] = useState({});
  const [qrCodeSize, setQrCodeSize] = useState('medium');
  const [qrCodeSize_MedSmall, setQrCodeSize_MedSmall] = useState(null);
  const [isQrCodeRender, setIsQrCodeRender] = useState(false);

  const dispatch = useDispatch();
  const SearchValue = useSelector(state => state.form.searchBoxListForm && state.form.searchBoxListForm.values ? state.form.searchBoxListForm.values : {});
  const DateGenerate = useSelector(state => state.InventoryReducer.dataGenerated);
  const SearchOrResetFrontLineUsersClicked = useSelector(state => state.InventoryReducer.SearchOrResetFrontLineUsersClicked);
  const No_of_pages = useSelector(state => state.InventoryReducer.box_pages);
  const No_of_rows = useSelector(state => state.InventoryReducer.box_no_of_rows);
  const boxCurPage = useSelector(state => state.InventoryReducer.boxCurPage);

  const printQr = () => {
    const input = document.getElementsByClassName("qr-body-single")[0];
    html2canvas(input, { useCORS: true, allowTaint: true })
      .then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF();
        pdf.addImage(imgData, 'PNG', 10, 10);
        //pdf.output('dataurlnewwindow');
        pdf.save("download.pdf");
      })
  };

  const data = props.boxList.map(box => {

    return {
      number: box.number,
      name: makeFirstLetterCapital(box.name),
      inventory_ticket_id: box.inventory_ticket_id ? box.inventory_ticket_id : '–',
      qr_scanner: <button  type="button" className="btn cst_btn btn_danger" onClick={() => [setQROpen(true), setCurrentQrArea(box)]}>Show QR Code</button>,
      actions: <>
      <span className="mr_20 account_edit_icn" onClick={() => props.history.push(`/box/${box.id}`)}><i class="fa fa-pencil" aria-hidden="true" data-test="frontline_user_edit"></i></span>
      <span className="mr_20 account_edit_icn" ><i class="fa fa-trash" onClick={() => props.handleBoxClickDeleteModel(box)} aria-hidden="true" ></i></span>
      </>

    }
  })


  const columns = [
    {
      Header: <><span>Box Number</span><FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
      accessor: 'number', // String-based value accessors!,
      show: props.boxList.length !== 0,
    },
    {
      Header: <><span>Box Name</span><FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
      accessor: 'name', // String-based value accessors!,
      show: props.boxList.length !== 0,
    },
    {
      Header: <><span>Picked Ticket Id</span><FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
      accessor: 'inventory_ticket_id', // String-based value accessors!,
      show: props.boxList.length !== 0,
    },
    {
      Header: <><span>QR Scanner</span><FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
      accessor: 'qr_scanner', // String-based value accessors!,
      show: props.boxList.length !== 0,
    },
    {
      Header: " ",
      accessor: "actions",
      show: props.boxList.length !== 0,
      width: 100
    }
  ]

  const [tablePage, setTablePage] = useState(0)
  const tablePageNew =  React.useRef()
  if(boxCurPage !== tablePageNew.current) {
    tablePageNew.current = boxCurPage;
  }
  const setTablePageFn = (page) => {
      setTablePage(page)
      props.boxPageClicked(page)
      tablePageNew.current = page;
  }

  const fetchData = (state, instance) => {
    if (SearchOrResetFrontLineUsersClicked) {
      dispatch(actions.resetSearchOrResetValue())
    } else {
      if (!DateGenerate) {
                state.page = 2;
                const limit = state.pageSize;
                const fl_users_no_of_rows = state.pageSize;
                let page = tablePageNew.current >= 0 ? tablePageNew.current + 1 : boxCurPage + 1;
                if(No_of_rows != fl_users_no_of_rows) {
                  page = 1;
                  setTablePageFn(0)
              }
                const sorted = state.sorted;
        //const filtered = state.filtered;
        const name = SearchValue.name ? SearchValue.name : '';
        const number = SearchValue.id ? SearchValue.id : '';
        //const email = SearchValue && SearchValue.email ? SearchValue.email.replace('+', "%2B") : ""
        dispatch(actions.getBoxList({page,limit, sorted, name, number}))
      }
    }
    // }
  }

  const handleQrCodeSizeRender = (size) => {
    setIsQrCodeRender(true);
    if(size == 'medSmall') {
      setQrCodeSize(null);
      setQrCodeSize_MedSmall('small');
    } else {
      setQrCodeSize_MedSmall(null);
      setQrCodeSize(size);
    }
    setTimeout(() => {
      setIsQrCodeRender(false);
    }, 1000);
  };

  return (
    SearchOrResetFrontLineUsersClicked && props.isLoading ?
      <div className="loader_btn_block">
        <ClapSpinner backColor="#89073c" size={40} frontColor="#89073c8a" />
      </div>
      :
      <> <ReactTable
        data={data}
        loading={props.isLoading}
        defaultPageSize={No_of_rows ? No_of_rows : pageSize}
        minRows={0}
        manual
        onFetchData={fetchData}
        pages={No_of_pages}
        gotoPage={boxCurPage}
        page = { boxCurPage }
        onPageChange={(page) => {setTablePageFn(page)}}
        onPageSizeChange={(e) => setPageSize(e)}
        showPaginationTop={true}
        showPaginationBottom={false}
        showPagination={props.boxList.length !== 0}
        columns={columns}
        NoDataComponent={() => CustomNoDataComponent(props.isLoading, 'No Box Found')}
        getTheadThProps={(state, rowInfo, column, instance) => {
          return {
            style: {
              borderTop: 'none',
              borderBottom: 'none',
              borderRight: 'none',
              textAlign: 'center',
              color: '#566975',
              fontSize: '14px',
              fontWeight: '600',
              whiteSpace: 'nowrap',
              float: 'left',
            }
          }
        }
        }
        LoadingComponent={() =>
          props.isLoading ?
            <div style={{
              display: "block",
              position: "absolute",
              left: 0,
              height: '100%',
              right: 0,
              background: "rgba(255,255,255,0.8)",
              transition: "all .3s ease",
              top: 0,
              bottom: 0,
              textAlign: "center"
            }}
              className="loader_btn_block">
              <ClapSpinner backColor="#89073c" size={40} frontColor="#89073c8a" />
            </div> : null}
        getTrProps={(state, rowInfo, column, instance) => {
          return {
            className: 'react-table-tr-element',
            style: {
              border: '1px solid #c7cdd1',
              transition: '.3s ease-in-out',
              width: '100%',
              marginTop: '5px',
              float: 'left'
            }
          }

        }}
        getTdProps={(state, rowInfo, column, instance) => {

          if (column.id == 'image') {
            return {
              style: {
                marginTop: '0px'
              }
            }
          } else {
            return {
              className: 'react-td-element',
            }
          }
        }
        }
        getProps={() => {
          return {
            style: {
              border: 'none'
            }
          }
        }}
      />
      <Dialog
          open={openQrModel}

          aria-labelledby="form-dialog-title"
        >
          <div className="row p-0 m-0">
            <div className="col-10">
              <h4 id="form-dialog-title" className="p-2" style={{ textAlign: "left", fontSize: '13', color: '#ab385e' }}>
                QR Code
               </h4></div>
            <div className="col-2">
              <span onClick={() => {handleQrCodeSizeRender('medium'); setQROpen(false);}} className="float-right text-right mt-2 cursor-pointer"><i className="fa fa-times"></i></span>
            </div>
          </div>
          <div className="qr-body-single" style={{
            display: "flex",
            justifyContent: "center"
            }}>
              {isQrCodeRender ? (
              <ClapSpinner backColor="#89073c" size={40} frontColor="#89073c8a" /> ) : (
                <img src={currentQrArea && currentQrArea.qr_url && currentQrArea.qr_url[qrCodeSize ? qrCodeSize : qrCodeSize_MedSmall]} style={{
                  maxHeight: qrCodeSize == "original" ? "75vh" : "unset",
                  width: qrCodeSize == "original" ? "41vw" : qrCodeSize_MedSmall ? "1.25in" : "unset"
                  }} alt="dfdf" />
              )}
          </div>

          <div className="qrCode_sizeSetup_antd_select p-2">
            <Select
              suffixIcon={null}
              className={"btn cst_btn btn_danger mr-2"}
              style={{ minHeight: '51px', minWidth: '80px' }}
              placement={"bottomRight"}
              defaultValue='medium'
              options={qrCodeSize_option}
              onChange={(size)=>{handleQrCodeSizeRender(size);}}
            />
            <button style={{ minHeight: '51px', minWidth: '80px' }} onClick={() => printQr()} className={"float-right btn mr-2 cst_btn btn_danger"} id="cancel" type="cancel">Get PDF</button>
            <button style={{ minHeight: '51px', minWidth: '80px' }} onClick={() => window.print()} disabled={props.isLoading} className={props.isLoading ? "float-right btn cst_btn btn_danger mr-2 btn-wait" : "float-right btn cst_btn btn_danger mr-2"} id="delete" type="delete">{props.isLoading ? "" : "Print QR"}</button>
          </div>
        </Dialog>
      </>
  )
}

export default BoxTable;