import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../../../redux/actions/index';
import { closeModel, endOfWeek, getUser, startOfWeek } from '../../../../../utility/utility';
import { routes, userRoles } from '../../../../../utility/constants/constants';
import { reset } from 'redux-form';
import isEmpty from '../../../../../utility/IsEmptyValidator';
import $ from "jquery";
import { validateAccountUsers } from '../../../../../utility/validator/submit';
import { animateScroll as scroll } from 'react-scroll'
import AvailabilityCalendarComponent from '../../../../component/TimeTrackerModuleComponent/AvailabilityComponent/AvailabilityCalendarComponent/AvailabilityCalendarComponent';
import { criteria } from '../../../../../utility/validator/validator';
import { convertDateFormatForSchedule } from '../../../../../utility/utility';
import storage from '../../../../../utility/storage';
import { customTime, tzEndOfWeek, tzStartOfWeek, tzUTCDateConverterTool_V2 } from '../../../../../utility/TimezoneOperations/timezoneUtility';
const cloneDeep = require('clone-deep');
var moment = require('moment');

class AvailabilityCalendarContainer extends Component {

    constructor(props) {
        super(props);

        this.state = {
        }
    }

    componentDidMount() {
        const user = getUser();
        storage.set('liveDay', new Date());

        let firstWeekDate = tzStartOfWeek(customTime(new Date()));
        let lastWeekDate = tzEndOfWeek(customTime(new Date()));
        if (this.props && this.props.user && this.props.user.role && this.props.user.role == userRoles.FRONTLINE) {
            this.props.getAvailabilityCalendarScheduledList(tzUTCDateConverterTool_V2(firstWeekDate, 'from'), tzUTCDateConverterTool_V2(lastWeekDate, 'to'), user.id);
        } else if (this.props && this.props.match && this.props.match.params && this.props.match.params.userId) {
            this.props.getFrontline(this.props.match.params.userId);
            this.props.getAvailabilityCalendarScheduledList(tzUTCDateConverterTool_V2(firstWeekDate, 'from'), tzUTCDateConverterTool_V2(lastWeekDate, 'to'), this.props.match.params.userId);
        }

        scroll.scrollToTop();
    }
    componentDidUpdate() {
        
        if(this.props.frontlineUser){
            
            this.props.frontlineUserActiveNameChange(`${this.props.frontlineUser?.first_name} ${this.props.frontlineUser?.last_name} -`)
        }
    }

    createNewAvailability = () => {

        let user_id = ''

        if (this.props.match.params?.userId) {
            user_id = this.props.match.params?.userId
        } else {
            user_id = getUser().id
        }

        this.props.history.push(`/add-availability/${user_id}`)
    }

    overrideAvailability = () => {

        let user_id = ''

        if (this.props.match.params?.userId) {
            user_id = this.props.match.params?.userId
        } else {
            user_id = getUser().id
        }

        this.props.history.push(`/override-availability/${user_id}`)
    }

    render() {

        return (
            <AvailabilityCalendarComponent
                {...this.props}
                createNewAvailability={this.createNewAvailability}
                overrideAvailability={this.overrideAvailability}
            />
        )
    }
}

const mapStateToProps = (state) => {
    
    return {
        user: state.authReducer.user,
        isLoading: state.TimeTrackerModReducer.isLoading,
        frontlineUser: state.adminOrSuperAdminReducer.frontlineUser,
        getSingleUserAvailabilityPlannerList: state.TimeTrackerModReducer.getSingleUserAvailabilityPlannerList,
        getSingleUserLeavesAvailabilityPlannerList: state.TimeTrackerModReducer.getSingleUserLeavesAvailabilityPlannerList,
        getSingleUserOverrideAvailabilityPlannerList: state.TimeTrackerModReducer.getSingleUserOverrideAvailabilityPlannerList,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getFrontline: (id) => dispatch(actions.getFrontline(id)),
        getAvailabilityCalendarScheduledList: (startDate, endDate, id) => dispatch(actions.getAvailabilityCalendarScheduledList(startDate, endDate, id)),
        frontlineUserActiveNameChange:(name)=> dispatch(actions.updateActiveUserName(name))

    }
}


export default connect(mapStateToProps, mapDispatchToProps)(AvailabilityCalendarContainer);