import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../../../../redux/actions/index';
import ScanForDeliveryForm from '../../../../../component/Inventory/InventoryTicketsComponents/PickupsTicketComponents/SearchForDeliveryFormComponent/ScanForDeliveryForm';
import { animateScroll as scroll} from 'react-scroll'
import { extractExtention} from '../../../../../../utility/utility';
import { decode, encode } from 'base64-arraybuffer';
const cloneDeep = require('clone-deep');

class ScanForDeliveryFormContainer extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isFormLoading: false,
      isButtonLoading: false,
      storedFileList: [],
      previewFileQuery: {
        previewOpen: false,
        previewTitle: '',
        previewImage: '',
      },
      base64List: [],
      fileNameList: '',
      fileTypeList: '',
      errorThrower: false,
      pickingTicketId: null,
    }
    this.handleFileChange = this.handleFileChange.bind(this);
    this.handleFilePreview = this.handleFilePreview.bind(this);
    this.handlePreviewCancel = this.handlePreviewCancel.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    if(this.props && this.props.match && this.props.match.params && this.props.match.params.boxId) {
      this.setState({isFormLoading: true})
      setTimeout(() => {
        this.setState({isFormLoading: false})
      }, 500);
    };
    if(this.props && this.props.location && this.props.location.state && this.props.location.state.ticketId) {
      this.setState({pickingTicketId: this.props.location.state.ticketId})
    }
    scroll.scrollToTop();
  }

  handleFileChange = async (imgData) => {
    this.setState({ storedFileList: imgData.fileList });

    let getBase64List = [], getImageName = [], getImageType = [];

    getBase64List = await Promise.all(imgData.fileList.map((file) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file.originFileObj);
          reader.onload = () => resolve(reader.result);
          reader.onerror = (error) => reject(error);
        });
    }));

    getImageName = imgData.fileList.map(file => file.name);

    getImageType = imgData.fileList.map(file => file.type);

    this.setState({
      base64List: getBase64List,
      fileNameList: getImageName,
      fileTypeList: getImageType
    });
    
    if(this && this.state && this.state.base64List && this.state.base64List.length > 0) {
      this.setState({ errorThrower: false });
    }
  }

  handleFilePreview = async (file) => {
    if(!file.url && !file.preview) {
      file.preview = await new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    }
    this.setState({ previewFileQuery: {
      previewOpen: true,
      previewImage: file.url || file.preview,
      previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
    }});
  }

  handlePreviewCancel = () => this.setState({ previewFileQuery: {previewOpen: false} });

  handleSubmit = (values) => {
    debugger
    let deliveryItems = cloneDeep(values.deliveryItems);
    if(this.props && this.props.match && this.props.match.params && this.props.match.params.boxId) {
      deliveryItems.box_id = this.props.match.params.boxId;
      deliveryItems.ticket_id = this.props.location.state.ticketId;
    };
    let arrayBuffer = [];
    let extensions = extractExtention(this.state.fileNameList);

    if(this && this.state && this.state.base64List && this.state.base64List.length == 0) {
      this.setState({ errorThrower: true });
      return false;
    } else if(this && this.state && this.state.base64List && this.state.base64List.length > 0) {
      this.setState({ errorThrower: false });
      this.state.base64List.map((url, i) => {
        if(!url.photo_urls) {
          let base_photo = null;
          const image = url.split(',');
          base_photo = image[1];
          arrayBuffer.push(decode(base_photo))
        }
      });
    }

    if(deliveryItems && deliveryItems.ticket) {

      if(deliveryItems.ticket.is_extras && deliveryItems.ticket.is_extras == 'true') {
        this.setState({isButtonLoading: true})
        deliveryItems.ticket.is_extras = true;
        deliveryItems.ticket.status = 'delivered'
        setTimeout(() => {
          this.props.history.push({
            pathname: `/listedItems-coming-back`,
            state: { storedData: {deliveryItems: deliveryItems, arrayBuffer: arrayBuffer, extensions: extensions} }
          });
        }, 500);
      } else if(deliveryItems.ticket.is_extras && deliveryItems.ticket.is_extras == 'false') {
        deliveryItems.ticket.is_extras = false;
        this.setState({isButtonLoading: true})
        this.props.getDeliveryItemScanPresignedUrl(deliveryItems, arrayBuffer, extensions);
      }

    }
  };

  componentWillUnmount() {
    this.props.resetInventoryObjects();
  }

  render() {

    return (
        <ScanForDeliveryForm
          {...this.props}
          onSubmit={this.handleSubmit}
          isFormLoading={this.state.isFormLoading}
          isButtonLoading={this.state.isButtonLoading}
          handleChange={this.handleFileChange}
          handlePreview={this.handleFilePreview}
          handleCancel={this.handlePreviewCancel}
          storedFileList={this.state.storedFileList}
          previewFileQuery={this.state.previewFileQuery}
          errorThrower={this.state.errorThrower}
          pickingTicketId={this.state.pickingTicketId}
        />
    )
  }
}

const mapStateToProps = (state) => {
  return {
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    resetInventoryObjects: () => dispatch(actions.resetInventoryObjects()),
    getDeliveryItemScanPresignedUrl: (items, arrayBuffer, extension) => dispatch(actions.getDeliveryItemScanPresignedUrl(items, arrayBuffer, extension)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ScanForDeliveryFormContainer);