import React, { Component } from 'react';
import TimecardCorrectionReviewerForm from '../../../component/TimeTrackerModuleComponent/TimeCardCorrectionModuleComponent/TimecardCorrectionReviewerForm';
import { connect } from 'react-redux';
import { reset } from 'redux-form';
import * as actions from '../../../../redux/actions/index';
import { routes } from '../../../../utility/constants/constants';
import { extractExtention } from '../../../../utility/utility';
import { animateScroll as scroll } from 'react-scroll'
import { decode, encode } from 'base64-arraybuffer';
import moment from 'moment';
import errorHandler from '../../../../utility/errorHandler/errorHandler';
import { tzUTCDateTimeConverterTool_V1 } from '../../../../utility/TimezoneOperations/timezoneUtility';
const cloneDeep = require('clone-deep');

class TimecardCorrectionReviewerContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isTriggerLoader: false,
            managerSignBase64: [],
            payrollSignBase64: [],
        }

    }

    componentDidMount() {

        this.setState({ isTriggerLoader: true });
        setTimeout(() => {
            this.setState({ isTriggerLoader: false });
        }, 2500);

        if (this?.props?.match?.params?.timeCardId) {
            this.props.getSingleTimecardListData(this.props.match.params.timeCardId);
        }

        if (this?.props?.location?.state?.shiftDetailId) {
            this.props.getSingleShifSchedule(this?.props.location.state.shiftDetailId);
        }

        this.props.getUsersListByTheirRoles({ roles: 'frontline' });

    }

    componentWillUnmount() {
        this.props.resetTimeTrackerObjects();
    }

    handleManagerSignature = (image) => {

        this.setState({
            managerSignBase64: [image.managerSignUrl]
        })

    }

    handlePayrollSignature = (image) => {

        this.setState({
            payrollSignBase64: [image.payrollSignUrl]
        })

    }

    handleSubmit = (values) => {
        let setData = { data: {} };
        let fetchedExistingValue = cloneDeep(this?.props?.singleTimecardCorrectData);
        let fetchedFormValue = cloneDeep(values.timecard);
        let arrayBuffer = [];
        let extensions = extractExtention(['img.jpg']);

        setData.data['shift_detail_id'] = fetchedExistingValue?.shift_detail_id;

        /* STEP 4 DATA MANIPULATING Supervisor/Manager FLOW */
        if (fetchedExistingValue?.manager_id == null) {
            if (fetchedFormValue?.managerId?.length > 0) {

                if (fetchedFormValue.managerSignDate) {
                    let formatedDate = moment(fetchedFormValue.managerSignDate).format('YYYY-MM-DD');
                    let formatedTime = moment().format('HH:mm:ss');
                    setData.data['status'] = 'reviewed_by_manager';
                    setData.data['manager_id'] = this?.props?.user?.id;
                    setData.data['manager_singed_date'] = tzUTCDateTimeConverterTool_V1(formatedDate, formatedTime, 'none');
                } else {
                    errorHandler('Supervisor/Manager Signed Date is required')
                    return false;
                }

                if (this.state.managerSignBase64.length > 0) {
                    this.state.managerSignBase64.map((url, i) => {
                        if (!url.photo_urls) {
                            let base_photo = null;
                            const image = url.split(',');
                            base_photo = image[1];
                            arrayBuffer.push(decode(base_photo));
                        }
                    })
                    this.props.acquireTimecardCorrectionSignPresignUrl(fetchedExistingValue?.shift_detail_id, extensions, arrayBuffer, setData, { review: 'managerReview' }, fetchedExistingValue?.id);
                } else {
                    errorHandler('Supervisor/Manager Signature is required');
                    return false;
                }

            } else {
                errorHandler('Supervisor/Manager name is required');
                return false;
            }
        }

        /* STEP 4 DATA MANIPULATING Payroll FLOW */
        if ((fetchedExistingValue?.payroll_id == null) && (fetchedExistingValue?.manager_id !== null)) {
            if (fetchedFormValue?.payrollId?.length > 0) {

                if (fetchedFormValue.payrollSignDate) {
                    let formatedDate = moment(fetchedFormValue.payrollSignDate).format('YYYY-MM-DD');
                    let formatedTime = moment().format('HH:mm:ss');
                    setData.data['status'] = 'approved_by_payroll';
                    setData.data['payroll_id'] = this?.props?.user?.id;
                    setData.data['payroll_signed_date'] = tzUTCDateTimeConverterTool_V1(formatedDate, formatedTime, 'none');
                } else {
                    errorHandler('Payroll Signed Date is required')
                    return false;
                }

                if (this.state.payrollSignBase64.length > 0) {
                    this.state.payrollSignBase64.map((url, i) => {
                        if (!url.photo_urls) {
                            let base_photo = null;
                            const image = url.split(',');
                            base_photo = image[1];
                            arrayBuffer.push(decode(base_photo));
                        }
                    })
                    this.props.acquireTimecardCorrectionSignPresignUrl(fetchedExistingValue?.shift_detail_id, extensions, arrayBuffer, setData, { review: 'payrollReview' }, fetchedExistingValue?.id);
                } else {
                    errorHandler('Payroll Signature is required');
                    return false;
                }

            } else {
                errorHandler('Payroll name is required');
                return false;
            }
        }

    }

    handleCancel = () => {
        this.props.reset('updateTimecardCorrectReviewForm');
        if (this.props.user.role == 'frontline') {
            this.props.history.push(routes.FL_TIMECARD_REVIEWED_LIST);
        } else {
            this.props.history.push(routes.TIMECARD_REVIEWED_LIST);
        }
    }

    render() {
        return (
            <>

                <TimecardCorrectionReviewerForm
                    {...this.props}
                    isTriggerLoader={this.state.isTriggerLoader}
                    onSubmit={this.handleSubmit}
                    handleCancel={this.handleCancel}
                    handleManagerSignature={this.handleManagerSignature}
                    handlePayrollSignature={this.handlePayrollSignature}
                />

            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.authReducer.user,
        isLoading: state.TimeTrackerModReducer.isLoading,
        frontlineUsersList: state.TimeTrackerModReducer.usersListBySelectedRole,
        singleShiftScheduleData: state.TimeTrackerModReducer.singleShiftScheduleData,
        singleTimecardCorrectData: state.TimeTrackerModReducer.singleTimecardCorrectData,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        reset: (updateTimecardCorrectReviewForm) => dispatch(reset(updateTimecardCorrectReviewForm)),  // requires form name
        resetTimeTrackerObjects: () => dispatch(actions.resetTimeTrackerObjects()),

        getUsersListByTheirRoles: (params) => dispatch(actions.getUsersListByTheirRoles(params)),
        getSingleShifSchedule: (id) => dispatch(actions.getSingleShifSchedule(id)),
        getSingleTimecardListData: (id) => dispatch(actions.getSingleTimecardListData(id)),

        acquireTimecardCorrectionSignPresignUrl: (shiftId, extentions, arrayBuffer, formData, review, timecardId) => dispatch(actions.acquireTimecardCorrectionSignPresignUrl(shiftId, extentions, arrayBuffer, formData, review, timecardId)),
        createTimecardCorrectionQuery: (body) => dispatch(actions.createTimecardCorrectionQuery(body)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TimecardCorrectionReviewerContainer);