import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import '../../../styles/custom.css';
import { Input } from '../UI/InputElement/InputElement';
import { reduxForm, formValueSelector, getFormValues } from 'redux-form';
import KpiInspectionContainer from "../../container/KpiDashboardContainer/KpiInspectionContainer";
import KpiTicketContainer from "../../container/KpiDashboardContainer/KpiTicketContainer";



const getAccountsDropDown = (accounts) => {

  return accounts && accounts.map(a => {
      return {
          value: a.id,
          label: a.name
      }
  })
}

const getUsersDropDown = (users) => { 

  return users && users.map(u => {
      return {
          value: u.id,
          label: u.first_name + ' ' + u.last_name
      }
  })
}


let KpiTabs = (props) => {
  const [inspectionTabActive, setInspectionTabActive] = useState(true);
  const [ticketTabActive, setTicketTabActive] = useState(false);


  // const [accountList, setAccountList] = useState(getAccountsDropDown(props.company.accounts));
  // const [usersList, setUsersList] = useState(getUsersDropDown(props.dropDownUsersList));



  const trimText = (num) => {
      return Number.parseFloat(num).toFixed(2);
  }
  // useEffect(() => {
  //     setAccountList(getAccountsDropDown(props.company.accounts))
  // }, [props.company.accounts])

  // useEffect(() => {
  //     setUsersList(getUsersDropDown(props.dropDownUsersList))
  // }, [props.dropDownUsersList])

 

  const noUserOptionsMessage = (a, b) => {
      return 'User not found';
  }

  const noAccountOptionsMessage = (a, b) => {
      return 'Job not found';
  }

  const { handleSubmit, pristine, reset, submitting, error } = props;

  return (

    
    <main class="app-content wraper_content report_wrapper inspection_wraper">
    <section className="kpidashboard_tab">
    <div className="kpitab_row custom_row">
    <div className="kpitab_col active" >
      {/*  onClick={() => {
          setInspectionTabActive(true);
          setTicketTabActive(false);
        }}> */} 
        <h4 className="tab_title active"
          >
            Inspections
          </h4>
        </div>
    </div>
    </section>
       {/*  <section className="kpidashboard_tab">
        <div className="kpitab_row custom_row">
        {inspectionTabActive ? 
        <div className="kpitab_col active" 
        onClick={() => {
          setInspectionTabActive(true);
          setTicketTabActive(false);
        }}>
        <h4 className="tab_title active"
          >
            Inspections
          </h4>
        </div> : 
        <div className="kpitab_col" 
        onClick={() => {
          setInspectionTabActive(true);
          setTicketTabActive(false);
        }}>
        <h4 className="tab_title"
          >
            Inspection
          </h4>
        </div> }
      {ticketTabActive ?
        <div className="kpitab_col active"
        onClick={() => {
          setTicketTabActive(true);
          setInspectionTabActive(false);
        }}>
        <h4
          className="tab_title active"
          >
            Tickets
          </h4>
        </div> : 
        <div className="kpitab_col"
        onClick={() => {
          setTicketTabActive(true);
          setInspectionTabActive(false);
        }}>
        <h4
          className="tab_title"
          >
            Tickets
          </h4>
        </div>
      }
        </div>
          </section> */}

          <KpiInspectionContainer
          {...props}
          />  
        {/*   {inspectionTabActive ? (
          <KpiInspectionContainer
          {...props}
          />
        ) : null}
        {ticketTabActive ? (
          <KpiTicketContainer
            {...props}
          />
        ) : null} */}
      
    </main>
  );
};

const mapStateToProps = (state) => { 
  return { initialValues: { }}  
}

KpiTabs = connect(mapStateToProps)(KpiTabs)


export default KpiTabs;
