import React,{useState, useEffect} from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { Input } from '../../../../UI/InputElement/InputElement';
import { assetCheckInFormValidator as validate } from '../../../../../../utility/validator/validator';
import { makeFirstLetterCapital } from '../../../../../../utility/utility';

let AssetCheckInFormComponent = (props) => {

    const getCommonJobsAccountName = (props) => {
        if (props.commonJobsAccountList && props.commonJobsAccountList.length > 0) {
            return props.commonJobsAccountList && props.commonJobsAccountList.map((job) => {
                return {
                    value: job.id,
                    label: job.name
                }
            })
        } else {
            return []
        }
    };

    const getLocationsList = (props) => {
        if (props.locations && props.locations.length > 0) {
            return props.locations && props.locations.map((location) => {
                return {
                    value: location.id,
                    label: makeFirstLetterCapital(location.name)
                }
            })
        } else {
            return []
        }
    };

    const [commonJobsList, setCommonJobsList] = useState(getCommonJobsAccountName(props));
    const [locationsList, setLocationsListList] = useState(getLocationsList(props));

    useEffect(() => {
        setCommonJobsList(getCommonJobsAccountName(props))
    }, [props.commonJobsAccountList])

    useEffect(() => {
        setLocationsListList(getLocationsList(props))
    }, [props.locations])

    const noOptionsMessage = (a, b) => {
        return 'No options';
    }

    const { handleSubmit, pristine, reset, submitting, error, submitFailed } = props;
  
    return (
        <main className="app-content add_account_modal add_frontline_pop">
            <div className="appcontent_Inner user_newQuote pt-0">
                <div className="inspection_content_sec pt-0">
                    <div className="quote_form ticket_form" style={{maxWidth: '700px', marginTop : '25px'}}>
                        <h1 className="section_title text-center mt-5" style={{color:'#2a2a2a', fontSize:'31px', fontWeight:'600'}}> Asset Check Out Form </h1>
                        <div className="addQuote_form">
                            <form onSubmit={handleSubmit}>
                                <div className="cst_tab_content">
                                <div className="form-group">
                                        <div className="row">
                                            <div className="col-lg-6 pl-1">
                                            <label className="form_title font-weight-bold"> Select Job  <sup className="cst_sup_txt text_danger">*</sup></label>
                                        <Input type="inputSelect" placeholder="Select Job" noOptionsMessage={noOptionsMessage}  options={commonJobsList} name="scan[account_id]" />
                                            </div>
                                            <div className="col-lg-6 pr-1">
                                            <label className="form_title font-weight-bold"> Select Location  <sup className="cst_sup_txt text_danger">*</sup></label>
                                        <Input type="inputSelect" placeholder="Select Location" noOptionsMessage={noOptionsMessage}  options={locationsList} name="scan[location_id]" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col-lg-6 pl-1">
                                            <label className="form_title font-weight-bold"> Assigned Area  <sup className="cst_sup_txt text_danger">*</sup></label>
                                            <Input name="scan[assigned_area]" dataTest="ticket_subject" type="text" placeholder="Assigned Area" className="form-control input-modifier" />
                                            </div>
                                            <div className="col-lg-6 pr-1">
                                            <label className="form_title font-weight-bold">Power Cord Safety Check</label>
                                                <Input type="radio" labelClass="custom-control-label" forLable={"customRadio7"} value={"true"} label={"Yes"} className="custom-control-input" id="customRadio7" name="scan[power_cord_safety_check]" />
                                                <Input type="radio" labelClass="custom-control-label" forLable={"customRadio8"} value={"false"} label={"No"} className="custom-control-input" id="customRadio8" name="scan[power_cord_safety_check]" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col-lg-12 pl-1">
                                            <label className="form_title font-weight-bold"> Comments <sup className="cst_sup_txt text_danger">*</sup></label>
                                                <Input type="textarea" name="scan[comments]" className="form-control textarea_modifier" rows="3" placeholder="Type here" />
                                            </div>
                                        </div>
                                    </div>

                                    {/* <div className="form-group">
                                        <label className="form_title font-weight-bold"> Select Job  <sup className="cst_sup_txt text_danger">*</sup></label>
                                        <Input type="inputSelect" placeholder="Select Job" noOptionsMessage={noOptionsMessage}  options={commonJobsList} name="scan[account_id]" />
                                    </div>

                                    <div className="form-group">
                                        <label className="form_title font-weight-bold"> Select Location  <sup className="cst_sup_txt text_danger">*</sup></label>
                                        <Input type="inputSelect" placeholder="Select Location" noOptionsMessage={noOptionsMessage}  options={locationsList} name="scan[location_id]" />
                                    </div> */}

                                </div>
                                <div className="form-group btn_block mb-0">
                                    <button data-test="submit_button" type="submit" className={props.isLoading ? "btn cst_btn btn_danger btn-wait " : "btn_box cst_btn btn_danger "} >{props.isLoading ? "" : "Check Out"}</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
};

const mapStateToProps = (state) => {

  let scan = {}
    
  return { initialValues: { scan } };

};


AssetCheckInFormComponent = reduxForm({
  form: 'assetCheckInForm',
  validate,
  enableReinitialize: true,
})(AssetCheckInFormComponent);

AssetCheckInFormComponent = connect(mapStateToProps)(AssetCheckInFormComponent);

export default AssetCheckInFormComponent;