import React, { Component } from 'react';
import * as actions from '../../../../../redux/actions/index';
import { connect } from 'react-redux';
import { animateScroll as scroll } from 'react-scroll'
import { convertDateFormatV4, convertDateToDifferentTZ, convertTo24HourFormat, getUser } from '../../../../../utility/utility';
import errorHandler from '../../../../../utility/errorHandler/errorHandler';
import OverrideAvailability from '../../../../component/TimeTrackerModuleComponent/AvailabilityComponent/OverrideAvailability/overrideAvailability';
import moment from 'moment';
import { tzUTCDateTimeConverterTool_V1, tzUTCTimeConverterTool_V1 } from '../../../../../utility/TimezoneOperations/timezoneUtility';
const cloneDeep = require('clone-deep');

class OverrideAvailabilityContainer extends Component {

    constructor(props) {
        super(props);

        this.state = {
        }
    }

    onCancelClicked = () => {
        this.props.history.goBack();
    }

    addNewUser = (values) => {

        let value = cloneDeep(values);
        value.data.user_id = this.props.match.params?.userId

        if (value.data.start_time == value.data.end_time) {
            errorHandler("Start Time and End Time can't be identical");
            return false;
        }  

        if (value.data.start_date) {
            value.data.start_date = tzUTCDateTimeConverterTool_V1(value.data.start_date, '00:00:00', 'from');
        }

        if (values && values.data && values.data.start_time && values.data.end_time) {
            if (values.data.start_time.includes('PM') && values.data.end_time.includes('AM')) {
                errorHandler('Select a time range that falls within a single day');
                return false;
            }
        }

        function convert12hrTo24hr(time12hr) {
            // Create a Date object with the 12-hour time string
            const timeParts = time12hr.split(':');
            let hours = parseInt(timeParts[0]);
            const minutes = parseInt(timeParts[1].split(' ')[0]);
            const amPm = timeParts[1].split(' ')[1].toUpperCase();

            // Adjust the hours based on AM/PM
            if (amPm === 'PM' && hours !== 12) {
                hours += 12;
            } else if (amPm === 'AM' && hours === 12) {
                hours = 0;
            }

            // Format the hours and minutes in 24-hour format
            const formattedHours = String(hours).padStart(2, '0');
            const formattedMinutes = String(minutes).padStart(2, '0');

            // Return the time in 24-hour format
            return `${formattedHours}:${formattedMinutes}`;
        }

        const start = convertTo24HourFormat(value.data.start_time).slice(0, 2)
        const end = convertTo24HourFormat(value.data.end_time).slice(0, 2)


        if (value.data.start_time) {
            value.data.start_time = tzUTCTimeConverterTool_V1(convertDateFormatV4(value.data.start_date), convertTo24HourFormat(value.data.start_time));
        }

        if (value.data.end_time) {
            value.data.end_time = tzUTCTimeConverterTool_V1(convertDateFormatV4(value.data.start_date), convertTo24HourFormat(value.data.end_time));
        }

        this.props.overrideAvailability(value);

    }

    componentDidMount() {
        scroll.scrollToTop();
    }

    componentWillUnmount() {
        this.props.resetObjects();
    }


    render() {

        return (
            <>
                <OverrideAvailability
                    {...this.props}
                    onSubmit={this.addNewUser}
                    onCancelClicked={this.onCancelClicked}
                    isLoading={this.props.isLoading}
                />
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        isLoading: state.TimeTrackerModReducer.isLoading,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        resetObjects: () => dispatch(actions.resetObjects()),
        overrideAvailability: (body) => dispatch(actions.overrideAvailability(body)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OverrideAvailabilityContainer);