import React, { useEffect, useState } from 'react';
import { formValueSelector, getFormValues, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Input } from '../../UI/InputElement/InputElement';
import { userRoles, shiftSwapRequestStatusOptions } from '../../../../utility/constants/constants';
import { transformStringToSelectOptionObject } from '../../../../utility/utility';
import SwapListTable from './SwapListTable';

let SwapList = (props) => {

    const { handleSubmit, pristine, reset, submitting, error } = props;

    return (
        <>
            <main className="app-content wraper_content inspection_wraper">
                <div className="tab_header search_filter_cont inspection_tab_content ticket_filter">
                    <form onSubmit={handleSubmit}>
                        <div className="row justify-content-start mt-2">

                            <div className="col-xl-2 col-lg-4 col-md-6 col-sm-6">
                                <Input name="shiftSwapRequestStatus" dataTest="select_leave_type" type="inputSelect" options={shiftSwapRequestStatusOptions} placeholder="Search by Swap Status" />
                            </div>

                            <div className="col-xl-2 col-lg-6 col-sm-3 col-12 d-flex justify-content-center">
                                <button data-test="filter_go_btn" style={{ minWidth: '90px' }} className={"btn cst_btn btn_danger mr-2  account_btn"} id="submit" type="submit">{"Go"} </button>
                                <button data-test="filter_reset_btn" style={{ minWidth: '90px' }} onClick={props.resetClicked} className={"btn cst_btn btn-outline-secondary account_btn"} id="reset" type="reset">{"Reset"}</button>
                            </div>

                        </div>
                    </form>
                </div>

                <section className="account_sec inspection_tab_content appcontent_Inner">
                    <div className="quotes_table_content accounts_table_contnet table-responsive">
                        <div className="table quotes_table user_react_table mt-5">
                            <SwapListTable
                                {...props}
                            />
                        </div>
                    </div>
                </section>
            </main>

            <Dialog
                open={props.openDeleteModel}
                onClose={props.handleClose}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title" style={{ fontSize: '13', color: '#06080f' }}>
                    <h3 style={{ color: '#ab385e' }} data-test="modal_delete_title">Are you sure?</h3>
                </DialogTitle>

                <DialogActions>
                    <button data-test="modal_delete" style={{ minHeight: '36px', minWidth: '80px' }} onClick={() => props.deleteTicket(props.deleteObject.id)} disabled={props.isLoading} className={props.isLoading ? "btn cst_btn btn_danger mr-2 btn-wait" : "btn cst_btn btn_danger mr-2"} id="delete" type="delete">{props.isLoading ? "" : "Delete"}</button>
                    <button data-test="modal_cancel" style={{ minHeight: '36px', minWidth: '80px' }} onClick={props.handleDeleteClose} className={"btn cst_btn btn-outline-secondary"} id="cancel" type="cancel">Cancel</button>
                </DialogActions>
            </Dialog>
        </>

    )
}

const mapStateToProps = (state) => {debugger

    let capturedStatus = 'requested';

    if (state.TimeTrackerModReducer && state.TimeTrackerModReducer.swapList && state.TimeTrackerModReducer.swapList.length) {
        capturedStatus = state.TimeTrackerModReducer.swapList[0].status;
    }

    let shiftSwapRequestStatus = state.TimeTrackerModReducer.swapList_searchValues && state.TimeTrackerModReducer.swapList_searchValues.status ? state.TimeTrackerModReducer.swapList_searchValues.status : transformStringToSelectOptionObject(capturedStatus);
    return { initialValues: { shiftSwapRequestStatus }, formStates: getFormValues('searchSwapListForm')(state) }
}

SwapList = reduxForm({
    form: 'searchSwapListForm',
    // validate,
    enableReinitialize: true
})(SwapList);
SwapList = connect(mapStateToProps)(SwapList)

export default SwapList;